import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getHeaderConfig,
  getGRNOrderAmount,
  getGRNOrderTaxAmount,
  getGRNOrderAmountWithTax,
} from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Button, Chip, IconButton, TextField, Typography } from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { getFileName } from "../../../utils/common-utils";
import { checkIsApprover } from "../../../utils/utils";
import ApprovalStageDetails from "../../approval-stage-details";
import { getOfflineData } from "../../../utils/offline-services";

const GRNDetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const user = getOfflineData("user");
  const approvalId = searchParams.get("approvalId");
  const [rejectionDetails, setRejectionDetails] = useState(null);
  const [reconsiderDetails, setReconsiderDetails] = useState(null);
  const [approvalDetails, setApprovalDetails] = useState({});
  const [grnDetails, setGRNDetails] = useState({});
  const [additionalDocUrls, setAdditionalDocUrls] = useState([]);
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false,
  });

  const { invoiceUrls = [], receivedItems = [] } = grnDetails;
  const purchaseOrderId = grnDetails.purchaseOrderId || {};
  const {
    budget,
    items = [],
    company = {},
    vendorName = "",
    vendorCode = "",
    vendorGst = "",
    vendorPancard = "",
  } = purchaseOrderId || {};

  const { siteCode = "", siteName = "" } = budget || {};

  useEffect(() => {
    loadData();
    approvalId && loadApprovalDetails();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}`,
      null,
      {
        page: 1,
        limit: 10,
        _id: id,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          setGRNDetails(response.results[0] || {});
          setAdditionalDocUrls(response.results[0].additionalDocUrls || []);
        }
      })
      .catch((err) => {});
  };

  const loadApprovalDetails = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_APPROVALS}`,
      null,
      {
        _id: approvalId,
        approvalType: "invoice_approval",
        archive: false,
      }
    )
      .then((response) => {
        if (response.length > 0) {
          setApprovalDetails(response[0]);
        }
      })
      .catch((err) => {});
  };

  const onApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVE}/${approvalDetails.id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to approve");
        } else {
          loadData();
          loadApprovalDetails();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const sendForApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SEND_GRN_FOR_APPROVAL}${id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          navigate("/subway-finance?index=2");
        }
      })
      .catch((err) => {});
  };
  const resendForApproval = () => {
    const { approvalId = {} } = grnDetails;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}${approvalId.id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to resend for approval");
        } else {
          navigate(`/subway-finance/grn-order/${id}`);
        }
      })
      .catch((err) => {});
  };

  const onReject = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REJECT}`,
      null,
      { comment: rejectionDetails.comment, id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setRejectionDetails({});
          loadData();
          loadApprovalDetails();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onReconsider = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}`,
      null,
      { comment: rejectionDetails.comment, id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setReconsiderDetails({});
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };

  const editRecord = () => {};

  return (
    <div className="grn-details-container">
      <div className="grn-details-header">
        <div className="left-container">
          <div className="back-title">
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=2");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className="title">GRN Details</Typography>
          </div>
          <div className="grn-form-container">
            <div className="info-section">
              <div className="info-box">
                <span>GRN Number </span>
                <span>{grnDetails.grnNo}</span>
              </div>
              <div className="info-box">
                <span>PO Number </span>
                <span>{purchaseOrderId.poNumber}</span>
              </div>
              <div className="info-box">
                <span>Site Code</span>
                <span>{siteCode || ""}</span>
              </div>
              <div className="info-box">
                <span>Site Name </span>
                <span>{siteName || ""}</span>
              </div>
              <div className="info-box">
                <span>Company Name </span>
                <span>{(company && company.name) || ""}</span>
              </div>
              <div className="info-box">
                <span>PO Type </span>
                <span>
                  {convertToCamelCaseFromUnderScore(purchaseOrderId.poType)}
                </span>
              </div>
              <div className="info-box">
                <span> PO Date </span>
                <span>
                  {formatDate(
                    purchaseOrderId.poDate,
                    DATE_FORMATS["DD-MM-YYYY"]
                  )}
                </span>
              </div>
              <div className="info-box">
                <span> GRN Status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(grnDetails.status)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="right-section">
          {parseInt(grnDetails?.createdBy) ===
            parseInt(user?._id || user?.id) && (
            <>
              {["draft", "reconsider"].includes(grnDetails.status) && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/subway-finance/grn-order/edit/${id}?poId=${purchaseOrderId.id}`
                    )
                  }
                >
                  Edit
                </Button>
              )}
              {["draft"].includes(grnDetails.status) &&
                invoiceUrls.length > 0 && (
                  <Button variant="outlined" onClick={() => sendForApprove()}>
                    Send For approval
                  </Button>
                )}
              {["reconsider"].includes(grnDetails.status) && (
                <Button variant="outlined" onClick={() => resendForApproval()}>
                  Resend For approval
                </Button>
              )}
            </>
          )}
        </div>

        {approvalId && grnDetails.status === "approval_in_progress" && (
          <div className="right-section">
            <Button
              variant="outlined"
              disabled={!checkIsApprover(approvalDetails)}
              onClick={onApprove}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              color="error"
              disabled={!checkIsApprover(approvalDetails)}
              onClick={() => setRejectionDetails(approvalDetails)}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              color="error"
              disabled={!checkIsApprover(approvalDetails)}
              onClick={() => onReconsider(approvalDetails)}
            >
              Reconsider
            </Button>
          </div>
        )}
      </div>
      <div
        className={
          approvalDetails.id
            ? "po-detail-approval-stage "
            : "po-detail-approval-stage no-approval"
        }
      >
        <div className="po-details">
          <div className="form-container">
            <div className="info-section">
              <div className="info-box">
                <span>Vendor Code </span>
                <span>{vendorCode || ""}</span>
              </div>
              <div className="info-box">
                <span>Vendor Name </span>
                <span>{vendorName || ""}</span>
              </div>
              <div className="info-box">
                <span>Vendor GST </span>
                <span>{vendorGst || ""}</span>
              </div>
              <div className="info-box">
                <span>Vendor PAN Number</span>
                <span>{vendorPancard || ""}</span>
              </div>
              <div className="info-box">
                <span> GRN Amount </span>
                <span>
                  {getGRNOrderAmount(items, receivedItems)}
                  {purchaseOrderId?.currency?.name || "INR"}
                </span>
              </div>
              <div className="info-box">
                <span> GRN Tax Amount </span>
                <span>
                  {getGRNOrderTaxAmount(items, receivedItems)}
                  {purchaseOrderId?.currency?.name || "INR"}
                </span>
              </div>
              <div className="info-box">
                <span> GRN Total Amount</span>
                <span>
                  {getGRNOrderAmountWithTax(items, receivedItems)}
                  {purchaseOrderId?.currency?.name || " INR"}
                </span>
              </div>
              <div className="info-box">
                <span> Vendor </span>
                <span>{grnDetails.vendorCode}</span>
              </div>
              <div className="info-box">
                <span>Invoice Number </span>
                <span>{(grnDetails && grnDetails.invoiceNumber) || ""}</span>
              </div>
              <div className="info-box">
                <span>Invoice Amount </span>
                <span>{(grnDetails && grnDetails.invoiceAmount) || ""}</span>
              </div>
              <div className="info-box">
                <span>Invoice Date </span>
                <span>
                  {(grnDetails &&
                    formatDate(
                      grnDetails.invoiceDate,
                      DATE_FORMATS["DD-MM-YYYY"]
                    )) ||
                    ""}
                </span>
              </div>
              <div className="info-box">
                <span>Material/Service Received date </span>
                <span>
                  {(grnDetails &&
                    formatDate(
                      grnDetails.inwardDate,
                      DATE_FORMATS["DD-MM-YYYY"]
                    )) ||
                    ""}
                </span>
              </div>
              <div className="info-box">
                <span>Financial Year </span>
                <span>{(grnDetails && grnDetails.financialyear) || ""}</span>
              </div>

              <div className="info-box">
                <span>Creator Name </span>
                <span>{(grnDetails && grnDetails.creatorName) || ""}</span>
              </div>

              <div className="info-box">
                <span>Invoice Urls </span>
                <div>
                  {invoiceUrls.map((attachment) => {
                    return (
                      <Chip
                        label={getFileName(attachment)}
                        onClick={() => window.open(attachment, "_blank")}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="info-box">
                <span>Additional Documents </span>
                <div>
                  {additionalDocUrls.map((attachment) => {
                    return (
                      <Chip
                        label={getFileName(attachment)}
                        onClick={() => window.open(attachment, "_blank")}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="form-container">
            <MuiTable
              columnsList={getHeaderConfig(receivedItems, editRecord)}
              dataList={
                items?.slice?.(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(items.length / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
              }}
            />
          </div>
        </div>
        {approvalId && (
          <div className="approval-details">
            <ApprovalStageDetails
              approvalDetails={approvalDetails}
              createdBy={grnDetails.creatorName}
            />
          </div>
        )}
      </div>
      {rejectionDetails && (
        <CustomModal
          title="Reject GRN"
          disabledOutsideClick={true}
          onClose={() => {
            setRejectionDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={rejectionDetails.comment}
              onChange={(e) =>
                setRejectionDetails({
                  ...rejectionDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="comment"
              fullWidth
            />
            <Button
              color="error"
              variant="outlined"
              onClick={onReject}
              fullWidth
              disabled={!rejectionDetails.comment}
            >
              Reject
            </Button>
          </div>
        </CustomModal>
      )}

      {reconsiderDetails && (
        <CustomModal
          title="Reject GRN"
          disabledOutsideClick={true}
          onClose={() => {
            setReconsiderDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={reconsiderDetails.comment}
              onChange={(e) =>
                setReconsiderDetails({
                  ...reconsiderDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="comment"
              fullWidth
            />
            <Button
              color="error"
              variant="outlined"
              onClick={onReconsider}
              fullWidth
              disabled={!reconsiderDetails.comment}
            >
              Reject
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default GRNDetails;
