import "./index.scss";
import React, { useState } from "react";
import {
  TabPanel,
  MainTabContainer,
} from "../../../common-utilities/core/tabs";
import LeaseMaintenanceContainer from "./lease-maintenance";
import LeaseMaintenancePage2Container from "./lease-maintenance-page2";

const LeaseMaintenance = () => {
  const [value, setValue] = useState(0);

  const labels = ["Lease Maintenance", "Lease Maintenance Page 2"];
  return (
    <div className="lease-maintainence-container">
      <MainTabContainer
        mainClass="mainTabContainer"
        tabContainerClass="tab-container"
        tabsClass="custom-tabs"
        value={value}
        setValue={setValue}
        labels={labels}
      />
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <LeaseMaintenanceContainer />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <LeaseMaintenancePage2Container />
        </TabPanel>
      </div>
    </div>
  );
};

export default LeaseMaintenance;
