import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../routes/endpoints";
import { intranetService } from "../../common-utilities/utils/end-points";

/** @format */
const commonRoutePart = `/v1/qnet-service/`;
export const REST_URLS = {
  LOGIN: "/v1/qnet-service/auth/login",
  LOGOUT: `${commonRoutePart}auth/logout`,
  REFRESH_TOKEN: "/v1/qnet-service/auth/refresh-tokens",
  REGISTER: "/v1/qnet-service/auth/register",
  CASES: "/v1/qnet-service/case/list-cases",
  FETCH_CASE: "/v1/qnet-service/case/fetch-case/",
  CASE_STATUS: "/v1/qnet-service/case/map-labels",
  PRODUCTS: "/v1/qnet-service/admin/product",
  PRODUCT_BY_FILTER: `${commonRoutePart}admin/product-by-filter`,
  PRODUCT_CATEGORY: `${commonRoutePart}admin/product-category`,
  COMPLAINT_TYPES: "/v1/qnet-service/admin/complaint-type",
  CREATE_CASE: "/v1/qnet-service/case/create-case",
  STORE: "/v1/qnet-service/store/list",
  UPLOAD: "/v1/qnet-service/case/upload/attachment",
  TIME_LINE: "/v1/qnet-service/case/view/",
  UPDATE_CASE: "/v1/qnet-service/case/update-case/",
  UPDATE_BASIC_CASE_DETAILS: "/v1/qnet-service/case/update-basic-case-details/",
  DASHBOARD: "/v1/qnet-service/case/get-dashboard-stats",
  TICKET_REPORTS: "/v1/qnet-service/case/get-reports/by-status",
  COMPLAINT_TYPES_REPORTS:
    "/v1/qnet-service/case/get-reports/by-complaint-type/",
  OTHER_REPORTS: "/v1/qnet-service/case/get-reports",
  DETAILED_REPORT: "/v1/qnet-service/case/get-detailed-report",
  DASH_STATS_BY_ADMIN: "/v1/qnet-service/case/get-dashboard-stats-by-admin",
  LIST_ROLES: "/v1/qnet-service/auth/list-role",
  USERS: "/v1/qnet-service/users",
  ADD_COMMENT: "/v1/qnet-service/case/add-comment",
  USER_DASHBOARD_STATUS: "/v1/qnet-service/case/get-user-dashboard-stats",
  STORE_LIST: "/v1/qnet-service/admin/all-store-names",
  CHANGE_SEVERITY: "/v1/qnet-service/case/change-sev",
  ADOPT_CASE: "/v1/qnet-service/case/adopt-case/",
  CREDIT_CONFIRMATION: "/v1/qnet-service/case/credit-confirmation/",
  FAULT_RESPONSE: "/v1/qnet-service/case/fault-response",
  HANDLE_MEDIATION: "/v1/qnet-service/case/mediation",
  GET_STORE_BY_ID: "/v1/qnet-service/admin/store/",
  COMPLAINT_TYPE_LIST: "/v1/qnet-service/admin/complaint-type-list",
  GET_ALL_USER_NAME: "/v1/qnet-service/users/all-names",
  UPDATE_STORE_BY_ID: "/v1/qnet-service/admin/store/",
  EDIT_USER: "/v1/qnet-service/users/",
  CREATE_USER: "/v1/qnet-service/users/",
  GET_ALL_USER_ROLES: "/v1/qnet-service/users/all-user-roles",
  GET_ALL_PRODUCT_CATEGORY_NAMES: `${commonRoutePart}admin/product-category/all-names`,
  GET_ALL_PRODUCT_NAMES_BY_CATEGORY: `${commonRoutePart}admin/product/all-names/`,
  GET_ALL_PRODUCT_NAMES: `${commonRoutePart}admin/product/all-names`,
  CHANGE_STATUS: `${commonRoutePart}case/change-status`,
  REASSIGN_VENDOR: `${commonRoutePart}case/reassign-vendor`,
  REOPEN_CASE: `${commonRoutePart}case/reopen-case`,
  DC_LIST: `${commonRoutePart}dc/dc-list`,
  VENDOR_LIST: `${commonRoutePart}vendor/vendor-list`,
  QUERY_DCS: `${intranetService}dc`,
  QUERY_VENDORS: `${intranetService}vendor`,
  UPDATE_DC_BY_ID: `${intranetService}dc/`,
  UPDATE_VENDOR_BY_ID: `${intranetService}vendor/`,
  CREATE_DC: `${intranetService}dc/`,
  CREATE_VENDOR: `${intranetService}vendor`,
  VENDOR_BY_ID: `${intranetService}vendor/`,
};

export const qnetEndPoint = getProjectEndPoint(endPointsForProject.qnet);

export {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../common-utilities/utils/end-points";
