import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { downloadReports } from "./utils";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
import PoFilters from "../../purchase-order/po-filters";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { getOfflineData } from "../../../utils/offline-services";
import ConfirmHodGrn from "./confirm-hod-grn";

const GRNApprovalList = ({
  status,
  approvalType,
  invoiceType = "grn_invoice",
}) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState([]);
  const [selectedGrn, setSelectedGrn] = useState([]);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [bulkComment, setBulkComment] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // const [searchKey, setSearchKey] = useState('');
  const user = getOfflineData("user");
  const [filters, setFilters] = useState({
    approvalType: approvalType,
    invoiceType: invoiceType || "grn_invoice",
    sortBy: "-createdAt",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (download) => {
    let params = {
      ...filters,
    };

    // if (searchKey) {
    //   params.searchKey = searchKey;
    // }
    if (status) {
      params.status = status;
    }
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }

    delete params.page;
    params.populateSite = true;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (typeof download === "boolean") {
            downloadReports(response);
            return;
          }
          setBudgetList(response);
        }
      })
      .catch((err) => {});
  };
  const handleRowSelection = (event, data, type) => {
    const isChecked = event.target.checked;
    if (type === "selectAll") {
      let selectedGrn = [];

      if (isChecked) {
        selectedGrn = [...budgetList];
      }

      setSelectedGrn(selectedGrn);
    } else {
      let updatedSelectedGrn = [...selectedGrn];
      if (isChecked) {
        updatedSelectedGrn.push(data);
      } else {
        updatedSelectedGrn = updatedSelectedGrn.filter(
          (ele) => ele.id !== data.id
        );
      }
      setSelectedGrn(updatedSelectedGrn);
    }
  };

  const bulkApprove = () => {
    const approvalIds = selectedGrn?.map((ele) => parseInt(ele.id));
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_APPROVE}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedGrn([]);
          loadData(filters);
          setBulkComment("");
          setIsBulkModalOpen(false);
          setIsConfirmModalOpen(false);
          toast.success("Approved Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  const bulkReconsider = () => {
    const approvalIds = selectedGrn?.map((ele) => parseInt(ele.id));
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    } else {
      return toast.error("Comment is required", { autoClose: 2000 });
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_RECONSIDER}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedGrn([]);
          loadData(filters);
          setBulkComment("");
          setIsBulkModalOpen(false);
          toast.success("Reconsidered Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  const bulkReject = () => {
    const approvalIds = selectedGrn?.map((ele) => ele.id);
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    } else {
      return toast.error("Comment is required", { autoClose: 2000 });
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_REJECT}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedGrn([]);
          setBulkComment("");
          loadData(filters);
          setIsBulkModalOpen(false);
          toast.success("Rejected Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  const viewBudget = (data) => {
    const { document } = data;
    navigate(
      `/subway-finance/invoice-data/${document._id}?approvalId=${data.id}&from=GRN`
    );
  };

  return (
    <div className="grn-approval-list">
      <div className="filter-row">
        {/* <TextField
          placeholder="Search"
          value={searchKey}
          size="small"
          onChange={(e) => setSearchKey(e.target.value)}
        /> */}
        <TextField
          placeholder="Search by PO/GRN Number"
          value={filters?.poGrnNo}
          size="small"
          onChange={(e) => {
            setProperty("poGrnNo", e.target.value, setFilters);
          }}
        />
        <PoFilters
          filters={filters}
          setFilters={setFilters}
          showStatus={false}
        />
        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />
        <Button variant="outlined" onClick={loadData}>
          Search
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            loadData(true);
          }}
        >
          Download
        </Button>
        {selectedGrn.length > 0 && (
          <Button variant="outlined" onClick={() => setIsBulkModalOpen(true)}>
            Bulk Approval
          </Button>
        )}
      </div>
      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          handleRowSelection,
          selectedGrn,
          budgetList
        )}
        dataList={
          budgetList.slice(
            ((filters.page || 1) - 1) * 10,
            ((filters.page || 1) - 1) * 10 + 10
          ) || []
        }
        nestedValue={true}
        filters={filters}
        pageCount={Math.ceil(budgetList.length / 10)}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
        }}
      />
      {isBulkModalOpen && (
        <CustomModal
          title="Bulk Approve/Reject/Reconsider"
          onClose={() => setIsBulkModalOpen(false)}
        >
          <TextareaAutosize
            placeholder="Comments"
            minRows={6}
            value={bulkComment}
            style={{ width: "100%" }}
            onChange={(e) => setBulkComment(e.target.value)}
          />
          <div className="approve-btn-con finance-bulk-btn">
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => {
                if (user?.role.includes("HOD")) {
                  setIsConfirmModalOpen(true);
                } else {
                  bulkApprove();
                }
              }}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={bulkReconsider}
              color="warning"
            >
              Reconsider
            </Button>
            <Button variant="outlined" color="error" onClick={bulkReject}>
              Reject
            </Button>
          </div>
        </CustomModal>
      )}
      {isConfirmModalOpen && (
        <ConfirmHodGrn
          bulkApprove={bulkApprove}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      )}
    </div>
  );
};

export default GRNApprovalList;
