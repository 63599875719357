import CustomModal from "../../../common-utilities/core/modal";
import Select from "react-select";
import "./index.scss";
import { useEffect, useState } from "react";
import { loadUsers } from "./config";
import { getValue } from "../../utils";
import { setFormDetailsOnChange } from "../../utils/set-details-for-form";
import { AcceptRejectButtonContainer } from "../../../common-utilities/components/Custom Modal Buttons/accept-reject";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { useParams } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
export const ChangeVendorModal = ({ closeModal, onSuccess }) => {
  const [vendorList, setVendorList] = useState([]);
  const [formDetails, setFormDetails] = useState({});

  const { caseId } = useParams();
  useEffect(() => {
    loadUsers(
      {
        dcCumVendor: true,
        userRoleType: "VENDOR",
      },
      (response) => {
        if (!response?.message) {
          const results = response.map((item) => {
            return { label: getValue(item.name, true), value: item.id };
          });
          setVendorList(results);
        }
      }
    );
  }, []);

  const disableUserFromSaving = () => {
    return !formDetails?.vendor;
  };

  const assignedNewVendorForCase = () => {
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.REASSIGN_VENDOR}`, {
      caseId,
      vendorId: formDetails?.vendor?.value,
    })
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }
        toast.success("Vendor Reassigned Successfully");
        onSuccess();
        closeModal(null);
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };
  return (
    <>
      <CustomModal
        title="Select vendor"
        onClose={() => {
          closeModal(null);
        }}
      >
        <div className="select-vendor-modal">
          <div>Select vendor</div>
          <Select
            options={vendorList}
            placeholder="Vendor"
            className="input"
            classNamePrefix="subway-select"
            name="vendor"
            value={formDetails.vendor || null}
            onChange={(e, details) => {
              setFormDetailsOnChange(e, details, setFormDetails);
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
          <AcceptRejectButtonContainer
            buttonContainerClass="accept-reject-button-container"
            disabledSave={disableUserFromSaving}
            acceptFunction={assignedNewVendorForCase}
            rejectFunction={() => {
              closeModal(null);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
