export const getFiltersForLoadData = (filters, download, tableData) => {
  let allFilters = {};
  const { limit, page, storeType } = filters;
  const isRestaurantIdPresent = !!filters?.restaurantId?.value;
  const isStatePresent = !!filters?.state?.value;
  const isCityPresent = !!filters?.city?.value;

  if (isRestaurantIdPresent) {
    allFilters.store = filters?.restaurantId?.value;
  }

  if (isStatePresent) {
    allFilters.state = filters?.state?.value;
  }
  if (isCityPresent) {
    allFilters.city = filters?.city?.value;
  }
  if (limit) {
    allFilters.limit = limit;
  }
  if (page) {
    allFilters.page = page;
  }
  if (storeType) {
    allFilters.storeType = storeType;
  }
  if (download) {
    allFilters.page = 1;
    allFilters.download = true;
    allFilters.limit = tableData?.totalResults || 0;
  }
  return allFilters;
};
