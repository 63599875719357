import { Autocomplete, TextField, IconButton } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
export const headerConfig = (allowedRoleList) => {
  const config = [
    {
      label: 'StageNumber',
      id: 'StageNumber',
    },
    {
      label: 'Stage Name',
      id: 'stageName',
      render: (data, onClick, _, mainIndex) => {
        return (
          <TextField
            label="Stage Name"
            name="stageName"
            value={data?.stageName}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            variant="outlined"
            onChange={(e, val) => {
              onClick(e.target, mainIndex, 'stageName');
            }}
          />
        );
      },
    },
    {
      label: 'Stage Role Name',
      id: 'stageRoleName',
      render: (data, onClick, _, mainIndex) => {
        return (
          <TextField
            label="Stage Role Name"
            name="stageRoleName"
            value={data?.stageRoleName}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            variant="outlined"
            onChange={(e, val) => {
              onClick(e.target, mainIndex, 'stageRoleName');
            }}
          />
        );
      },
    },

    {
      label: 'Role',
      id: 'allowedRole',
      render: (data, onClick, _, mainIndex) => {
        return (
          <Autocomplete
            value={
              (data?.allowedRole || data?.allowedRole === 0)
                ? allowedRoleList.find(
                    (item) =>
                      parseInt(item.value) === parseInt(data?.allowedRole)
                  )
                : null
            }
            onChange={(e, val) => {
              onClick(val, mainIndex, 'allowedRole');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Allowed Role"
                InputLabelProps={{ shrink: true }}
                sx={{
                  minWidth: 220,
                }}
              />
            )}
            options={allowedRoleList}
          />
        );
      },
    },
    {
      label: 'Role User',
      id: 'user',
      render: (data) => {
        return (
          <>
            {allowedRoleList.find(
              (role) => parseInt(role?.value) === data?.allowedRole
            )?.user || null}
          </>
        );
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data, onClick, _, mainIndex) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onClick(data, mainIndex, 'deleteStage')
              }}
              sx={{ p: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return config;
};
