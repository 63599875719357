import { useState, useEffect } from "react";
import CustomModal from "../../core/modal";
import { TextField } from "@mui/material";
import "./index.scss";
import FileDetails from "./sub-components/file-details";
import CommonBtn from "../../core/common-button";
import { disableDocSave, setDocData } from "./utils";
import { createUpdateDoc } from "./apis";
import { getFieldList } from "../main-apis";
import { MainTrainingManualFilter } from "../Home/filter";
import { setFilterFunc } from "../utils";
const CreateUpdateDocument = ({
  showModal,
  data,
  title,
  closeModal,
  slug,
  onSuccess,
  allowedFormats,
  mainFolderLabel,
  subFolderLabel,
}) => {
  const [formDetails, setFormDetails] = useState({});
  const [lists, setLists] = useState({
    mainFolders: [],
    subFolders: [],
  });
  const setFilterList = (name, list) => {
    setLists((prevList) => ({ ...prevList, [name]: list }));
  };
  useEffect(() => {
    setDocData(
      data,
      setFormDetails,
      (list) => setFilterList("subFolders", list),
      slug
    );
  }, [data, slug]);

  useEffect(() => {
    if (!showModal) {
      setFormDetails({});
      setFilterList("subFolders", []);
      return;
    }
    getFieldList(slug, { parentNull: true }, "select", (list) =>
      setFilterList("mainFolders", list)
    );
  }, [showModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  return (
    <>
      {showModal && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title={title}
          onClose={closeModal}
          contentClassName={{
            headerBackgroundColor: "#008952",
          }}
        >
          <div className="create-update-document">
            <MainTrainingManualFilter
              trainingManualList={lists.mainFolders}
              setFilterOnChange={(e, value) => {
                setFilterFunc(
                  e,
                  value,
                  slug,
                  (list) => setFilterList("subFolders", list),
                  setFormDetails,
                  null,
                  false
                );
              }}
              folderList={lists.subFolders}
              filter={formDetails}
              className="auto-completes"
              mainFolderLabel={mainFolderLabel}
              subFolderLabel={subFolderLabel}
            />

            <TextField
              label="File Name"
              name="fileName"
              value={formDetails.fileName || ""}
              onChange={handleChange}
              fullWidth
              className="new-textfield modal-field"
            />
            <FileDetails
              formDetails={formDetails}
              setFormDetails={setFormDetails}
              slug={slug}
              allowedFormats={allowedFormats}
            />
            <div className="end-btns">
              <div>
                <CommonBtn
                  disabled={disableDocSave(formDetails)}
                  onClick={() => {
                    createUpdateDoc(formDetails, slug, onSuccess);
                  }}
                  showSaveIcon={true}
                />
              </div>
              <div>
                <CommonBtn
                  variant="outlined"
                  text="Cancel"
                  onClick={closeModal}
                />
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default CreateUpdateDocument;
