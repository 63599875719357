import Select from '../../../../packages/common-utilities/core/select-component';
import Grid from '@mui/material/Grid';
import AutoCompleteMultiSelect from '../../../../packages/common-utilities/core/autocomplete-multiselect';
import { setProperty } from '../../../../packages/common-utilities/utils/misc';

const SelectApps = ({
  selectedRoles,
  appList,
  selectedApps,
  setSelectedRoles,
  setSelectedApps,
  config,
  setConfig,
}) => {
  const handleRoleChange = (selectedRole, selectedApp) => {
    setSelectedRoles({
      ...selectedRoles,
      [selectedApp.value]: selectedRole,
    });
  };
  const appOptions = appList.reduce((allApps, role) => {
    role.apps.forEach((app) => {
      if (!allApps.find((a) => a.value === app._id)) {
        allApps.push({ value: app._id, label: app.name });
      }
    });
    return allApps;
  }, []);
  const handleAppChange = (e, selectedApps) => {
    let showFinanceConfig = false;
    selectedApps.forEach((app) => {
      if (parseInt(app.value) === parseInt(config.financeAppId)) {
        showFinanceConfig = true;
      }
    });
    setProperty(['showFinanceConfig'], [showFinanceConfig], setConfig);
    setSelectedApps(selectedApps);
  };

  return (
    <>
      <Grid
        container
        md={12}
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      >
        <Grid item md={12}>
          <div>
            <p className="select-title">Select Apps</p>
            <div>
              <AutoCompleteMultiSelect
                options={appOptions}
                onChange={handleAppChange}
                value={selectedApps}
                placeholder=""
              />
            </div>

            <Grid container md={12} sx={{ mt: 0.2, gridGap: '1em' }}>
              {selectedApps.map((selectedApp) => {
                let appName;

                const roleOptions = appList
                  .flatMap((role) => {
                    return role?.apps?.flatMap((app) => {
                      if (selectedApp.value === app._id) {
                        appName = `${app.name} Role`;
                        return { value: role._id, label: role.name };
                      }
                    });
                  })
                  .filter((option) => option);

                return (
                  <Grid item md={3} style={{ pt: '10px' }}>
                    <p className="select-title">{appName}</p>
                    <Select
                      options={roleOptions}
                      onChange={(e) => {
                        const selected =
                          roleOptions.find(
                            (item) => item.value === parseInt(e.target.value)
                          ) || null;
                        handleRoleChange(selected, selectedApp);
                      }}
                      value={
                        selectedRoles[selectedApp.value]
                          ? selectedRoles[selectedApp.value]?.value
                          : null
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectApps;
