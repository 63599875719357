import { pick } from "../../../common-utilities/utils";
import { downloadReport } from "./apis";
import { types } from "./config";

export const actionHandler = (type, data, additionalFilters) => {
  switch (type) {
    case "download":
      {
        let params = pick(data, ["cluster", "type", "limit"], true);
        let params2 = pick(
          additionalFilters,
          ["storeType", "startDate", "endDate"],
          true
        );
        if (params.type === types.approved) {
          params.showOnlyApproved = true;
        } else {
          params.showNonApproved = true;
        }

        delete params.type;
        downloadReport({ ...params, ...params2, page: 1, nofmtChg: true });
      }
      break;
    default:
      break;
  }
};
