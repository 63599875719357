import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Button, Divider, TextField } from "@mui/material";
import Select from "react-select";
import { LISTING_TYPE } from "./config";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import { validAddressTypes } from "../../utils/constants";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const PersonAddressCommunication = () => {
  const [searchField, setSearchField] = useState({
    listingType: "",
  });
  const [data, setData] = useState([]);
  const { personInfo, setPersonInfo } = useContext(GlobalStoreContext);
  const [personOptions, setPersonOptions] = useState([]);
  const previousController = useRef();

  useEffect(() => {
    if (personInfo.personId) {
      setDataHandler();
    }
  }, []);

  const columns = [
    { id: "addressType", label: "Address Type" },
    { id: "attn", label: "Attention" },
    { id: "address2", label: "Located In" },
    { id: "address1", label: "Address" },
    { id: "address3", label: "Street/PO Box" },
    { id: "country", label: "Country" },
    { id: "zipCode", label: "Postal Code" },
  ];
  const communicationColumn = [
    { id: "communicationType", label: "Type" },
    { id: "value", label: "Value" },
  ];

  const setDataHandler = () => {
    if (searchField.listingType === "address") {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_ADDRESS_LIST}${validAddressTypes.person}/${personInfo.personId}`
      )
        .then((res) => {
          if (res.messsage) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setData(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (searchField.listingType === "communication") {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_COMMUNICATION_LIST}${validAddressTypes.person}/${personInfo.personId}`
      )
        .then((res) => {
          if (res.messsage) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setData(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions(updatedOptions);
      setPersonInfo({ personId: searchTerm });
    });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setPersonOptions([]);
      setPersonInfo({ personId: "" });
      setData([]);
    }
  };

  return (
    <div className="person-communication-container">
      <div>
        <Grid container md={12} className="search-container">
          <Grid item md={1.5}>
            <Autocomplete
              freeSolo
              className="subs-autocomplete"
              id="free-solo-2-demo"
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              options={personOptions}
              onInputChange={onInputChange}
              inputValue={personInfo.personId}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setPersonInfo({ personId: "" });
                    setPersonOptions([]);
                    setData([]);
                  }}
                />
              }
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Person" />
              )}
              onChange={(e, val) => {
                if (val) {
                  setPersonInfo({ personId: val.id });
                }
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Listing Type"
              options={LISTING_TYPE}
              onChange={(e) => {
                setSearchField((prevVal) => ({
                  ...prevVal,
                  listingType: e.value,
                }));
                setData([]);
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Button
              size="small"
              variant="contained"
              onClick={setDataHandler}
              disabled={
                !Number.isInteger(parseInt(personInfo.personId)) ||
                searchField.listingType === ""
                  ? true
                  : false
              }
            >
              Get Data
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className="memo-list-container">
        {searchField.listingType === "communication" ? (
          <StickyHeaderCon
            columnsList={communicationColumn}
            containerHeight="table-con"
            dataList={data}
          />
        ) : (
          <StickyHeaderCon
            columnsList={columns}
            containerHeight="table-con"
            dataList={data}
          />
        )}
      </div>
    </div>
  );
};
export default PersonAddressCommunication;
