import React, { useState, useEffect, useCallback } from "react";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import DocumentPreview from "./document-preview";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Grid, TextField } from "@mui/material";
import { getHeaderConfig } from "./helper";
import { applicableFor } from "./helper";
import "./index.scss";
import { TableFilesModal } from "../../../common-utilities/core/custom-modals/table-files.modal";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";

const CompanyDocuments = () => {
  const { id } = useParams();
  const [docs, setDocs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const [showCreateCompanyForm, setShowCreateCompanyForm] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    applicableFor: "",
  });

  useEffect(() => {
    if (!showCreateCompanyForm) {
      setData({ name: "", description: "", applicableFor: "" });
    }
  }, [showCreateCompanyForm]);

  const closeForm = () => {
    setShowCreateCompanyForm(false);
    setUpdateDocument(false);
  };
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const loadData = useCallback(
    (filter) => {
      const filterparams =
        typeof filter === "object" ? { ...filter } : { ...filters };
      if (filterparams.limit) {
        filterparams.limit = filters.limit;
      }
      if (filterparams.sortBy) {
        filterparams.sortBy = filters.sortBy;
      }
      if (filterparams.page) {
        filterparams.page = filters.page;
      }
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_COMPANY_DOCS}`,
        null,
        filterparams
      )
        .then((response) => {
          setTableData(response);
        })
        .catch((err) => {
          toast.error(err, { autoClose: 2000 });
        });
    },
    [filters]
  );

  const saveUpload = () => {
    let payload = {
      details: { ...data, applicableFor: data.applicableFor.value, docs },
      restaurantId: id,
      type: "basic-details",
    };
    if (updateDocument) {
      delete payload.docs;
    }
    const method = updateDocument ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
    const url = updateDocument
      ? `${HOSTNAME}${REST_URLS.UPDATE_COMPANY_DOCUMENT}${data._id}`
      : `${HOSTNAME}${REST_URLS.SAVE_COMPANY_DOCUMENTS}`;

    if (updateDocument) {
      payload = { ...data, applicableFor: data.applicableFor.value };
      delete payload._id;
    }

    invokeApi(method, url, payload)
      .then((response) => {
        if (response.message) {
          setData({
            name: "",
            description: "",
            applicableFor: "",
          });
          setDocs([]);
          setClearFiles(true);
          setShowCreateCompanyForm(false);
          toast.error("Failed to create", { autoClose: 2000 });
        } else {
          setData({
            name: "",
            description: "",
            applicableFor: "",
          });
          setDocs([]);
          setClearFiles(true);
          toast.success(
            `Document ${updateDocument ? "Updated" : "Created"} Successfully`,
            { autoClose: 2000 }
          );
          setShowCreateCompanyForm(false);
          loadData();
        }

        if (updateDocument) {
          closeForm();
          loadData();
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 2000 });
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const nameHandler = (event) => {
    const { value } = event.target;
    setData((prevVal) => ({
      ...prevVal,
      name: value,
    }));
  };
  const deschandler = (event) => {
    const { value } = event.target;
    setData((prevVal) => ({
      ...prevVal,
      description: value,
    }));
  };

  const setDataForTables = (data, type) => {
    if (data) {
      switch (type) {
        case "View Files":
          setViewDetails(data?.files || []);
          break;

        case "Edit":
          setData({
            name: data?.Name || "",
            description: data?.Description,
            applicableFor: {
              label: data?.["Applicable For"],
              value: data?.["Applicable For"],
            },
            _id: data?._id,
          });

          const files =
            data?.files?.map((item) => {
              const { link, type, fileName } = item;
              return { document: link, type, originalName: fileName };
            }) || [];

          setDocs(files);
          setShowCreateCompanyForm(true);
          setUpdateDocument(true);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {!showCreateCompanyForm && (
        <div className="main-doc-con">
          <div className="create-new">
            <span className="label">Documents By Company</span>
          </div>
          <div className="btn-div ">
            <CommonBtn
              onClick={() => {
                setShowCreateCompanyForm(true);
              }}
              variant="contained"
            >
              Create Document
            </CommonBtn>
          </div>
          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData.results || []}
            filters={filters}
            pageCount={tableData?.totalPages}
            onClick={setDataForTables}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></MuiTable>
          {/* <SiTable
            header={getHeaderConfig()}
            data={tableData.results || []}
            filters={filters}
            pageCount={tableData.totalPages}
            onClick={setDataForTables}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable> */}
        </div>
      )}

      {showCreateCompanyForm && (
        <div>
          <Grid container md={10} sm={12} className="company-doc form-header">
            <h3>Basic Details</h3>
            <CancelIcon
              onClick={() => closeForm()}
              fontSize="large"
              color="error"
              sx={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid container md={10} sm={12} className="company-doc">
            <Grid item md={6} xs={12}>
              <div className="doc-data-div">
                <div className="label">Name*</div>
                <TextField
                  fullWidth
                  value={data.name}
                  size="small"
                  placeholder="Name"
                  onChange={nameHandler}
                  className="new-textfield"
                />
              </div>
              <div className="doc-data-div upload-doc-div">
                <div className="label">Description*</div>
                <TextareaAutosize
                  value={data.description}
                  placeholder="Description"
                  minRows={10}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "0.5em",
                    fontSize: "medium",
                  }}
                  className="new-textfield"
                  onChange={deschandler}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className="doc-data-div">
                <AutocompleteComponent
                  options={applicableFor}
                  value={
                    data?.applicableFor?.value ? data?.applicableFor : null
                  }
                  label="Applicable For*"
                  onChange={(e, value) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      applicableFor: value,
                    }));
                  }}
                  disableClear={true}
                />
              </div>
              {updateDocument ? (
                <div></div>
              ) : (
                <div className="doc-data-div upload-doc-div">
                  <div className="label">Upload Documents</div>
                  <DocumentPreview
                    docs={docs}
                    documentSavingKey="document"
                    documentType="subway documents"
                    setDocs={(data) => {
                      setDocs(data);
                    }}
                    clearFiles={clearFiles}
                    setClearFiles={setClearFiles}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container md={10} sm={12} className="btn-con">
            <CommonBtn
              onClick={() => {
                closeForm();
              }}
              sx={{ mr: 5 }}
              variant="outlined"
            >
              Cancel
            </CommonBtn>
            <CommonBtn
              variant="contained"
              color="primary"
              disabled={
                data.name === "" ||
                data.description === "" ||
                data.applicableFor === ""
              }
              onClick={saveUpload}
            >
              Save details
            </CommonBtn>
          </Grid>
        </div>
      )}
      {viewDetails && (
        <TableFilesModal
          title="Files"
          closeModal={setViewDetails}
          data={viewDetails}
        />
      )}
    </>
  );
};
export default CompanyDocuments;
