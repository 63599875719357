import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";

import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import ClearIcon from "@mui/icons-material/Clear";
import {
  balanceType,
  getHeaderConfig,
  imprestReason,
  paymentMode,
} from "./config";
import { toast } from "react-toastify";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import MuiTable from "../../../common-utilities/core/mui-table";
import CustomModal from "../../../common-utilities/core/modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
const StoreImprest = () => {
  const [storeId, setStoreId] = useState();
  const [tableData, setTableData] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});
  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState({ isShow: false, data: [] });
  const [isCreate, setIsCreate] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [imprestDetails, setImprestDetails] = useState({
    reason: "",
    amount: "",
    date: "",
    paymentMode: "",
    referenceNo: "",
    remarks: "",
    balanceType: "bookBalance",
  });
  const [storeList, setStoreList] = useState([]);
  const createImprestHandler = () => {
    if (
      (storeDetails?.properties?.pettyExpense?.physicalBalance === 0 &&
        imprestDetails?.reason === "Imprest Decrease" &&
        imprestDetails?.balanceType === "physicalBalance") ||
      (storeDetails?.properties?.pettyExpense?.bookBalance === 0 &&
        imprestDetails?.reason === "Imprest Decrease" &&
        imprestDetails?.balanceType === "bookBalance")
    ) {
      toast.error("Balance Can't be descreased from 0", { autoClose: 2000 });
    } else {
      const payload = {
        store: storeId,
        reason: imprestDetails?.reason,
        amount: imprestDetails?.amount,
        date: imprestDetails?.date,
        paymentMode: imprestDetails?.paymentMode,
        referenceNo: imprestDetails?.referenceNo,
        remarks: imprestDetails?.remarks,
        balanceType: imprestDetails?.balanceType,
        attachment: files,
      };
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.IMPREST}`, payload)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Imprest Updated Successfully", { autoClose: 2000 });
            getStoreDetails({ _id: storeId });
            setImprestDetails({
              reason: "",
              amount: "",
              date: "",
              paymentMode: "",
              referenceNo: "",
              remarks: "",
              balanceType: "bookBalance",
            });
            setFiles([]);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    const storeNo = searchTerm?.split("-")[0];
    clearTimeout(timeoutId);

    const payload = {};

    if (!isNaN(parseInt(storeNo))) {
      payload.storeNumber = storeNo;
    } else if (searchTerm && typeof searchTerm === "string") {
      payload.restaurantName = searchTerm;
    }

    if (Object.keys(payload).length > 0) {
      setTimeout(() => {
        plainApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
          null,
          payload
        ).then((res) => {
          const storeList = res?.results?.map((ele) => ({
            storeNo: ele?.storeNumber,
            satelliteNo: ele?.satelliteNumber,
            sequenceNo: ele?.sequenceNumber,
            restaurantName: ele?.restaurantName,
            id: ele?.id,
          }));
          setStoreList(storeList);
        });
      }, 300);
    }
  };
  const viewfilesHandler = (data, type) => {
    switch (type) {
      case "view files":
        setShowFiles({ isShow: true, data: data?.attachment });
        break;
      default:
        break;
    }
  };
  const loadData = (filters) => {
    const payload = { ...filters, store: storeId };
    if (payload.store) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.IMPREST}`,
        null,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            const result = res.results.map((ele) => ({
              ...ele,
            }));
            setTableData({ ...res, results: result });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const getStoreDetails = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      filter
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const handleDateChange = (name) => (date) => {
    setImprestDetails({
      ...imprestDetails,
      [name]: moment(date).toDate(),
    });
  };
  useEffect(() => {
    if (storeId) {
      loadData(filters);
    }
  }, [storeId]);
  return (
    <div className="imprest-container">
      {isCreate ? (
        <div>
          <div className="imprest-btn-con">
            <Button
              onClick={() => {
                setIsCreate(false);
                loadData(filters);
              }}
              size="small"
              variant="outlined"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={createImprestHandler}
              disabled={
                !storeId ||
                !imprestDetails.reason ||
                !imprestDetails.amount ||
                !imprestDetails.date ||
                !imprestDetails.paymentMode ||
                !imprestDetails.referenceNo ||
                !imprestDetails.remarks ||
                !imprestDetails.balanceType
              }
            >
              Submit
            </Button>
          </div>
          <Grid container md={12}>
            <Grid item md={3}>
              <h5>Select Store Number</h5>
              <Autocomplete
                getOptionLabel={(option) =>
                  option
                    ? `${option.storeNo}-${option.sequenceNo}-${
                        option.satelliteNo
                      }${
                        option.restaurantName ? `-${option.restaurantName}` : ""
                      }`
                    : ""
                }
                className="poper-select"
                clearIcon={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      setStoreId("");
                      setStoreDetails({});
                      setStoreList([]);
                      setImprestDetails({
                        reason: "",
                        amount: "",
                        date: "",
                        paymentMode: "",
                        referenceNo: "",
                        remarks: "",
                        balanceType: "bookBalance",
                      });
                      setFiles([]);
                    }}
                  />
                }
                options={storeList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Store"
                    sx={{ backgroundColor: "white", mt: 1 }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                onInputChange={storeInputChange}
                onChange={(event, value) => {
                  if (value) {
                    getStoreDetails({ _id: value?.id });
                    setStoreId(value?.id);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container md={12} spacing={3} sx={{ mt: 1 }}>
            <Grid item md={6}>
              <Card sx={{ p: 2 }}>
                <h4>Payment Details</h4>
                <CardContent>
                  <div>
                    <Grid container md={12} spacing={2}>
                      <Grid item md={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Type*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={imprestDetails.balanceType || ""}
                            label="Reason"
                            onChange={(e) => {
                              setImprestDetails((prevVal) => ({
                                ...prevVal,
                                balanceType: e.target.value,
                              }));
                            }}
                          >
                            {balanceType.map((type) => {
                              return (
                                <MenuItem value={type.value}>
                                  {type.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Reason*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={imprestDetails.reason || ""}
                            label="Reason"
                            onChange={(e) => {
                              setImprestDetails((prevVal) => ({
                                ...prevVal,
                                reason: e.target.value,
                              }));
                            }}
                          >
                            {imprestReason.map((reason) => {
                              return (
                                <MenuItem value={reason.value}>
                                  {reason.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container md={12} spacing={2} sx={{ mt: 1 }}>
                      <Grid item md={6}>
                        {" "}
                        <TextField
                          label="Amount"
                          required
                          type="Number"
                          fullWidth
                          value={imprestDetails?.amount}
                          size="small"
                          onChange={(e) => {
                            setImprestDetails((prevVal) => ({
                              ...prevVal,
                              amount: e.target.value,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        {" "}
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Payment Mode*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={imprestDetails.paymentMode || ""}
                            label="Payment Mode"
                            onChange={(e) => {
                              setImprestDetails((prevVal) => ({
                                ...prevVal,
                                paymentMode: e.target.value,
                              }));
                            }}
                          >
                            {paymentMode.map((reason) => {
                              return (
                                <MenuItem value={reason.value}>
                                  {reason.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container md={12} spacing={2} sx={{ mt: 1 }}>
                      <Grid item md={6}>
                        {" "}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            label="Date*"
                            inputFormat="DD/MM/YYYY"
                            value={imprestDetails?.date || null}
                            name="date"
                            onChange={handleDateChange("date")}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                fullWidth
                                sx={{ mr: 1 }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          label="Payment Reference No."
                          required
                          fullWidth
                          value={imprestDetails?.referenceNo}
                          size="small"
                          onChange={(e) => {
                            setImprestDetails((prevVal) => ({
                              ...prevVal,
                              referenceNo: e.target.value,
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card>
                <CardContent>
                  <div>
                    <h4>Imprest Details</h4>
                    <div className="details-row">
                      <h6>Format</h6>
                      <h5>{storeDetails?.subFormat}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Location Code</h6>
                      <h5>{storeDetails?.pinCode}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Name</h6>
                      <h5>{storeDetails?.restaurantName}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Region</h6>
                      <h5>{storeDetails?.state}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Physical Balance</h6>
                      <h5>
                        {
                          storeDetails?.properties?.pettyExpense
                            ?.physicalBalance
                        }
                      </h5>
                    </div>
                    <div className="details-row">
                      <h6>Book Balance</h6>
                      <h5>
                        {storeDetails?.properties?.pettyExpense?.bookBalance}
                      </h5>
                    </div>
                    <div className="details-row">
                      <h6>Inprocess Balance</h6>
                      <h5>
                        {storeDetails?.properties?.pettyExpense?.bookBalance ===
                          0 ||
                        storeDetails?.properties?.pettyExpense
                          ?.physicalBalance === 0
                          ? 0
                          : storeDetails?.properties?.pettyExpense
                              ?.bookBalance -
                              storeDetails?.properties?.pettyExpense
                                ?.physicalBalance || ""}
                      </h5>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container md={12} sx={{ mt: 2 }}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <h4>Remarks</h4>
                  <TextareaAutosize
                    className="new-textfield"
                    minRows={4}
                    placeholder="Reason For Payment*"
                    required
                    style={{ width: "100%" }}
                    value={imprestDetails.remarks}
                    onChange={(e) => {
                      setImprestDetails((prevVal) => ({
                        ...prevVal,
                        remarks: e.target.value,
                      }));
                    }}
                  />
                  <Grid md={12} item sx={{ mt: 2 }}>
                    <SubwayFileUpload
                      title="Attachment"
                      url={`${HOSTNAME}${REST_URLS.UPLOAD}`}
                      tokenKey={tokenMainKeys.pettyExpense}
                      slug={appSlugs.pettyExpense}
                      callBack={(newFile) => {
                        setFiles([
                          ...files,
                          {
                            url: newFile[0].url,
                            title: newFile[0].originalname,
                          },
                        ]);
                      }}
                      providedLimit={15728640}
                      lmtMsg="File size cannot be more than 15 MB"
                    />
                    {files.length > 0 && (
                      <div className="file-con">
                        {files.map((ele) => {
                          return <div className="file-name">{ele?.title}</div>;
                        })}
                      </div>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <div className="imprest-btn-con">
            <Button
              onClick={() => {
                setIsCreate(true);
              }}
              size="small"
              variant="outlined"
            >
              Create Imprest
            </Button>
          </div>
          <Grid container md={12}>
            <Grid item md={3}>
              <h5>Select Store Number</h5>
              <Autocomplete
                getOptionLabel={(option) =>
                  option
                    ? `${option.storeNo}-${option.sequenceNo}-${
                        option.satelliteNo
                      }${
                        option.restaurantName ? `-${option.restaurantName}` : ""
                      }`
                    : ""
                }
                className="poper-select"
                clearIcon={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      setStoreId("");
                      setStoreDetails({});
                      setStoreList([]);
                      setTableData([]);
                    }}
                  />
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                options={storeList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Store"
                    sx={{ backgroundColor: "white", mt: 1 }}
                  />
                )}
                onInputChange={storeInputChange}
                onChange={(event, value) => {
                  if (value) {
                    getStoreDetails({ _id: value?.id });
                    setStoreId(value?.id);
                  }
                }}
              />
            </Grid>
          </Grid>
          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            pageCount={tableData?.totalPages}
            onClick={viewfilesHandler}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData(
                {
                  ...filters,
                  page,
                },
                true
              );
            }}
          ></MuiTable>
          {showFiles?.isShow && (
            <CustomModal
              title="Files"
              onClose={() => {
                setShowFiles({ isShow: false, data: [] });
              }}
            >
              {showFiles?.data.length > 0 ? (
                showFiles.data.map((item, index) => {
                  return (
                    <div className="downloadgrid" key={index}>
                      <div>{item?.title}</div>
                      <div>
                        <a target="_blank" download={true} href={item?.url}>
                          <DownloadForOfflineIcon color="action" />
                        </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No Files Attached</p>
              )}
            </CustomModal>
          )}
        </div>
      )}
    </div>
  );
};

export default StoreImprest;
