import { pick } from "../../../packages/common-utilities/utils";
import { INTRANETHOSTNAME } from "../../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../../packages/common-utilities/utils/end-points/intranet";
import { HTTP_METHODS } from "../../../utils/http-service";
import { getOfflineData } from "../../../utils/offline-services";

export const redirect = (navigate) => {
  const user = getOfflineData("user");
  if (user?.role !== "admin") {
    navigate("/");
  }
};

export const getCreateUpdateApiDetails = (data) => {
  const payload = { ...data };
  payload.head = payload.head?.value;
  payload.roles =
    payload?.roles?.map?.((item) => {
      return item.value;
    }) || [];
  const method = payload.id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const url = payload.id
    ? `${INTRANETHOSTNAME}${REST_URLS.UPDATE_DEPARTMENT}${payload.id}`
    : `${INTRANETHOSTNAME}${REST_URLS.CREATE_DEPARTMENT}`;
  const message = payload.id
    ? "Department Updated Successfully"
    : "Department Created succesfully";
  delete payload.id;

  return { payload, method, url, message };
};

export const getUpdateModalDetails = (data) => {
  const details = pick(data, ["roles", "head", "name", "id"]);
  if (details.head) {
    details.head = { label: details?.head?.name, value: details?.head?.id };
  }

  details.roles =
    details?.roles?.map((item) => {
      return { label: item?.name, value: item?.id };
    }) || [];

  return details;
};
