import { ArrowBack, CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SiFileUpload from "../../core/file-uploader";
import {
  DOCUMENT_TYPES,
  FINANCE_USER_ID,
  NEW_VENDOR,
  RM_APPROVE_REQUIRED,
  VENDOR_INVOICE_SUBMISSION_ROLES,
} from "../../utils/constants";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { getOfflineData } from "../../utils/offline-services";
import { getCrtDetailParams, isFormValid } from "./helper";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import "./index.scss";
import {
  INTRANETHOSTNAME,
  HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";
import { getRedirectPointBySlug } from "../../utils/common-utils";
import { StoreAutoCompleteComponent } from "../common-components/store-autocomplete";
import {
  clearProperty,
  setProperty,
} from "../../../common-utilities/utils/misc";
import { getKeysBySlug } from "../../utils/keys";
import { getVendorStateList } from "./apis";

const InvoiceForm = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  const navigate = useNavigate();
  const user = getOfflineData("user", token) || {};
  const { userId } = user;
  const [formDetails, setFormDetails] = useState({});
  const [storeDetails, setStoreDetails] = useState({});
  const [formList, setFormLsts] = useState({
    vendorList: [],
    stateList: [],
  });
  const [storeError, setStoreError] = useState(false);
  const keys = getKeysBySlug(slug);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const user = getOfflineData("user", token) || {};

    slug === appSlugs.vendorInvoiceSubmission &&
      user?.role === VENDOR_INVOICE_SUBMISSION_ROLES.VENDOR &&
      getVendorStateList(
        (list) => setProperty("stateList", list, setFormLsts),
        slug,
        token,
        true
      ); // Getting state list for vendor invoice submission vendor depending on the vendorsd mapped against it
    if (slug !== appSlugs.vendorInvoiceSubmission) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.VENDOR}`,
        null,
        {
          page: 0,
          limit: 10000,
        },
        null,
        slug,
        token
      )
        .then((response) => {
          if (response && response.results) {
            const { results = [] } = response;
            let updateVendors = results.map((result) => {
              return {
                label: result.name,
                value: result.code,
                id: result.id,
              };
            });
            // updateVendors.push({
            //   label: 'New Vendor',
            //   value: 'NEW_VENDOR',
            // });
            setProperty("vendorList", updateVendors, setFormLsts);
          }
        })
        .catch((err) => {});

      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.FRANCHISE_STORE}`,
        null,
        null,
        null,
        slug,
        token
      )
        .then((response) => {
          if (response?.hasOwnProperty?.("storeNumber")) {
            setStoreDetails(response);
            setStoreError(false);
            return;
          }
          toast.error("Store not found.Please contact support", {
            autoClose: 2000,
          });
          setStoreError(true);
        })
        .catch((err) => {
          toast.error("Store not found.Please contact support", {
            autoClose: 2000,
          });
          setStoreError(true);
        });
    }
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onFormChange = (value, dataLabel) => {
    setFormDetails({
      ...formDetails,
      [dataLabel]: value,
    });
  };

  const onSubmit = () => {
    const { payload, url } = getCrtDetailParams(
      formDetails,
      storeDetails,
      slug
    );
    invokeApi(HTTP_METHODS.POST, url, payload, null, null, slug, token)
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to create invoice");
        } else {
          navigate(getRedirectPointBySlug(slug));
        }
      })
      .catch((err) => {
        toast.error("Failed to create invoice a");
      });
  };

  const maxDate = new Date().toISOString().split("T")[0];

  return (
    <div className="invoice-form-container">
      <div className="header-section">
        <IconButton
          variant="contained"
          onClick={() => navigate(getRedirectPointBySlug(slug))}
        >
          <ArrowBack />
        </IconButton>
        <span className="title">Submit Invoice </span>
      </div>
      <Card>
        <CardContent className="invoice-card">
          <div className="invoice-form">
            <div className="form-row">
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <TextField
                  value={storeDetails.name || ""}
                  size="small"
                  label="Store Name"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}

              {slug === appSlugs.vendorInvoiceSubmission && (
                <StoreAutoCompleteComponent
                  token={token}
                  slug={slug}
                  clearValue={() => {
                    clearProperty("store", setFormDetails);
                  }}
                  callBack={(_, storeId) => {
                    setProperty("store", storeId, setFormDetails);
                  }}
                />
              )}
              {slug !== appSlugs.vendorInvoiceSubmission ? (
                <Autocomplete
                  disablePortal
                  options={formList.vendorList}
                  className="form-autocomplete"
                  size="small"
                  onChange={(event, values) =>
                    onFormChange(values || {}, "vendor")
                  }
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Vendor Name" />
                  )}
                />
              ) : (
                <>
                  <Autocomplete
                    disablePortal
                    options={formList.stateList}
                    className="form-autocomplete"
                    size="small"
                    onChange={(event, values) => {
                      onFormChange(values || {}, "vendor");
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="State and City"
                      />
                    )}
                  />
                  <TextField
                    label="Vendor Code"
                    size="small"
                    disabled={true}
                    value={formDetails.vendor?.code || ""}
                    // onChange={onInputChange}
                  />
                </>
              )}

              <TextField
                label="PO Number"
                name="poNumber"
                size="small"
                value={formDetails.poNumber}
                onChange={onInputChange}
              />

              {slug !== appSlugs.vendorInvoiceSubmission && (
                <TextField
                  label="GRN Number"
                  name="grnNumber"
                  size="small"
                  value={formDetails.grnNumber}
                  onChange={onInputChange}
                />
              )}
            </div>
            <div className="form-row">
              {slug === appSlugs.vendorInvoiceSubmission && (
                <TextField
                  label="Delivery Date"
                  type="date"
                  InputProps={{ inputProps: { max: maxDate } }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="deliveryDate"
                  value={formDetails.deliveryDate}
                  onChange={onInputChange}
                />
              )}
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <Autocomplete
                  disablePortal
                  options={DOCUMENT_TYPES}
                  className="form-autocomplete"
                  size="small"
                  onChange={(event, values) =>
                    onFormChange(values || {}, "invoiceType")
                  }
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Invoice type" />
                  )}
                />
              )}

              <TextField
                label="Invoice No"
                name="invoiceNumber"
                size="small"
                className="form-autocomplete"
                value={formDetails.invoiceNumber}
                onChange={onInputChange}
              />
              <TextField
                label="Invoice Date"
                type="date"
                InputProps={{
                  inputProps: {
                    max:
                      slug === appSlugs.vendorInvoiceSubmission
                        ? maxDate
                        : "9999-12-31",
                  },
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                name="invoiceDate"
                value={formDetails.invoiceDate}
                onChange={onInputChange}
              />
              <TextField
                label={keys.amount}
                size="small"
                name="amount"
                value={formDetails.amount}
                onChange={onInputChange}
              />
            </div>
            <div className="form-row attachments">
              {formDetails.invoiceAttachment ? (
                <div>
                  <span>{formDetails.invoiceAttachment.blobName}</span>
                  <IconButton
                    onClick={() => onFormChange("", "invoiceAttachment")}
                  >
                    <CloseOutlined color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title="Invoice Attachment *"
                  callBack={(value) =>
                    onFormChange(value[0], "invoiceAttachment")
                  }
                  token={token}
                  slug={slug}
                />
              )}

              {formDetails.grnAttachment ? (
                <div>
                  <span>{formDetails.grnAttachment.blobName}</span>
                  <IconButton onClick={() => onFormChange("", "grnAttachment")}>
                    <CloseOutlined color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title={keys.grnAttachment}
                  callBack={(value) => onFormChange(value[0], "grnAttachment")}
                  token={token}
                  slug={slug}
                />
              )}

              {formDetails.poAttachment ? (
                <div>
                  <span>{formDetails.poAttachment.blobName}</span>
                  <IconButton onClick={() => onFormChange("", "poAttachment")}>
                    <CloseOutlined color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title="PO Attachment"
                  callBack={(value) => onFormChange(value[0], "poAttachment")}
                  token={token}
                  slug={slug}
                />
              )}
            </div>
            <div className="form-row">
              <TextareaAutosize
                minRows="4"
                placeholder="Remarks"
                className="is-remarks form-autocomplete"
                name="remarks"
                value={formDetails.remarks}
                onChange={onInputChange}
              />
            </div>

            <div className="footer-buttons">
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={isFormValid(formDetails, storeError, slug)}
              >
                Submit
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default InvoiceForm;
