import { useState, useEffect } from "react";
import "./index.scss";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys } from "../../../utils/constants";
import OtherAppSideMenu from "../../common-utilities/core/other-app-side-menu";
import SideMenu from "../core/side-menu";
import { BellCorpStudioLogoContainer } from "../../common-utilities/Bellcorp-Studio-Logo";
const MainContainer = ({ children }) => {
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const [open, setOpen] = useState(window.innerWidth > 480);

  return (
    <>
      <div className="subway-app-main-container">
        <div className="subway-app-main-header">
          <IntranetHeader />
        </div>
        <div className="subway-app-current-app-container">
          <div className="main-right-container">
            <OtherAppSideMenu
              appName="Docustorage"
              SideNavCom={SideMenu}
              keyName={tokenMainKeys.docuStorage}
            />
          </div>
          <div className="main-left-container">
            <div className="content-div">
              <div>{children}</div>
              <BellCorpStudioLogoContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
