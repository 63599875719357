import { actions } from "../config";
import { getUpdateModalDetails } from "./misc";

export const actionHandler = (data, type, setModalData) => {
  switch (type) {
    case actions.createDepartment:
      setModalData({
        data: null,
        type: actions.createDepartment,
        title: "Create Department",
      });
      break;
    case actions.viewRoles:
      setModalData({
        data:
          data?.roles?.map?.((item) => {
            return { name: item.name, key: item.id };
          }) || [],
        type: actions.viewRoles,
        title: "View Roles",
      });
      break;
    case actions.editDepartments:
      setModalData({
        data: getUpdateModalDetails(data),
        type: actions.editDepartments,
        title: "Update Department",
      });
      break;
    default:
      break;
  }
};

export const disableSaveButton = (data) => {
  return !data?.head || !data?.name;
};
