import {
  agreementAndPostAgreementDocsLabelsAndValues,
} from '../../../../../utils/coco-built.config';
import { validDocumentTypes } from '../../../../../utils/document-constants';
import { leaseAndOptionAgreementConfig } from './lease-agreeement-options-agreement';

export const agreementAndPostAgreementDocsConfig = (
  formDetails,
  config,
  errors
) => {
  if (!formDetails?.documentType) {
    return;
  }

  if (formDetails?.notApplicable) {
    delete formDetails.startDate;
    delete formDetails.endDate;
  } else {
    config[validDocumentTypes.agreementAndPostAgreementDocs].splice(1, 0, {
      label: 'Start Date*',
      name: 'startDate',
      type: 'date',
      value: formDetails.startDate,
      error: errors.startDate,
      helperText: errors.startDate ? 'Required' : '',
    });
  }
  if (
    formDetails?.documentType?.value ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    formDetails?.documentType?.value ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement
  ) {
    config[validDocumentTypes.agreementAndPostAgreementDocs].push(
      ...leaseAndOptionAgreementConfig(
        formDetails,
        errors,
        formDetails?.documentType?.value
      )
    );
  } else {
    config[validDocumentTypes.agreementAndPostAgreementDocs] = [
      ...config[validDocumentTypes.agreementAndPostAgreementDocs],
      {
        label: 'Upload Document',
        name: 'docs',
        type: 'upload',
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? 'Required' : '',
        documentSavingKey: 'document',
        uploadType: `${formDetails?.documentType?.value}`,
        notUploadCheckBox: true,
        notUploadKey: 'notApplicable',
      },
    ];
  }

  if (
    !formDetails?.notApplicable &&
    formDetails?.documentType?.value !==
      agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy
  ) {
    config[validDocumentTypes.agreementAndPostAgreementDocs].splice(2, 0, {
      label: 'Expiry Date*',
      name: 'endDate',
      type: 'date',
      value: formDetails.endDate,
      error: errors.endDate,
      helperText: errors.endDate ? 'Required' : '',
    });
  }
};
