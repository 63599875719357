export const deleteFalsyProperties = (obj, properties) => {
  properties?.forEach?.((property) => {
    const value = obj?.[property];
    if ([NaN, null, undefined, ""].includes(value)) {
      delete obj?.[property];
    }
  });
};

export const addValueToObj = (
  key,
  obj,
  value,
  customLogic,
  beforeParams,
  afterParams
) => {
  if (!key || !obj || typeof obj !== "object") return;
  if ([null, undefined, NaN].includes(value)) return;
  let allArgs = [];
  if (typeof customLogic === "function") {
    if (Array.isArray(beforeParams)) {
      allArgs = [...beforeParams];
    }
    allArgs.push(value);
    if (Array.isArray(afterParams)) {
      allArgs = [...allArgs, ...afterParams];
    }
    obj[key] = customLogic(...allArgs);
    return;
  }
  obj[key] = value;
};

export const fieldValidation = (value) => {
  if ([null, undefined, NaN, ""].includes(value)) {
    return true;
  }
  return false;
};

export const replaceArrElement = (arr, value, newValue) => {
  if (!Array.isArray(arr)) {
    return;
  }
  const index = arr.indexOf(value);
  if (index != -1) {
    arr[index] = newValue;
  }
};
export const daysExcludingWeekends = (date1, date2) => {
  if (
    !date1 ||
    !date2 ||
    new Date(date1) == "Invalid Date" ||
    new Date(date2) == "Invalid Date"
  ) {
    return "";
  }
  let startDate = new Date(date1);
  let endDate = new Date(date2);

  if (startDate.getTime() > endDate.getTime()) {
    startDate = new Date(date2);
    endDate = new Date(date1);
  }
  startDate.setHours(5, 30, 0, 0);
  endDate.setHours(5, 30, 0, 0);

  const ifThen = function (a, b, c) {
    return a == b ? c : a;
  };

  let elapsed = endDate - startDate;
  elapsed /= 86400000;

  let daysBeforeFirstSunday = (7 - startDate.getDay()) % 7;
  let daysAfterLastSunday = endDate.getDay();

  elapsed -= daysBeforeFirstSunday + daysAfterLastSunday;
  elapsed = (elapsed / 7) * 5;
  elapsed +=
    ifThen(daysBeforeFirstSunday - 1, -1, 0) +
    ifThen(daysAfterLastSunday, 6, 5);

  return Math.ceil(elapsed);
};

export const convertObjValuesToStr = (
  object,
  keys,
  checkFalsyValues = false,
  deleteKey = false
) => {
  return keys.reduce((obj, key) => {
    const value = object[key];
    const falsyCheck = [null, undefined, NaN].includes(value);
    if (
      object &&
      Object.prototype.hasOwnProperty.call(object, key) &&
      (checkFalsyValues ? !falsyCheck : true)
    ) {
      obj[key] = `${value}`;
    }
    if (
      deleteKey &&
      object &&
      Object.prototype.hasOwnProperty.call(object, key) &&
      falsyCheck
    ) {
      delete object[key];
    }
    return obj;
  }, {});
};
export const deleteAtPath = (obj, path, index) => {
  if (!obj || typeof obj != "object") return;
  if (index === path.length - 1) {
    delete obj[path[index]];
    return;
  }
  deleteAtPath(obj[path[index]], path, index + 1);
};
export const clearProperty = (property, setDataFunc, isNested = true) => {
  if (typeof setDataFunc != "function") return;
  setDataFunc((prevData) => {
    if (Array.isArray(property)) {
      property.forEach((prop) => {
        const splitedProperty = prop?.split?.(".") || [];
        if (isNested && splitedProperty?.length > 1) {
          deleteAtPath(prevData, splitedProperty, 0);
        }
        else {
          delete prevData?.[prop];
        }
      });
    } else {
      const splitedProperty = property?.split?.(".") || [];
      if (splitedProperty?.length > 1 && isNested) {
        deleteAtPath(prevData, splitedProperty, 0);
      } else {
        delete prevData?.[property];
      }
    }

    return { ...prevData };
  });
};

export const setAtPath = (obj, path, index, val, createPath) => {
  let curPath = path?.[index];
  if (!curPath) return;
  if (createPath && !obj[curPath]) {
    obj[curPath] = {};
  }
  if (index === path.length - 1) {
    obj[curPath] = val;
    return;
  }
  setAtPath(obj[path[index]] || {}, path, index + 1, val, createPath);
};

export const setProperty = (property, value, setDataFunc, createPath = true) => {
  if (typeof setDataFunc !== "function") {
    return;
  }
  setDataFunc((prevData) => {
    if (Array.isArray(property) && Array.isArray(value)) {
      property?.forEach((item, index) => {
        const val = value[index];
        const splitedProperty = item?.split?.(".") || [];
        if (splitedProperty?.length > 1 && createPath) {
          setAtPath(prevData, splitedProperty, 0, val, createPath);
        } else {
          prevData[item] = val;
        }
      });

      return { ...prevData };
    } else {
      const splitedProperty = property?.split?.(".") || [];
      if (splitedProperty?.length > 1 && createPath) {
        setAtPath(prevData, splitedProperty, 0, value, createPath);
        return { ...prevData };
      }
    }

    return { ...prevData, [property]: value };
  });
};

export const deleteObjectProperties = (obj, properties) => {
  properties?.forEach?.((property) => {
    const splittedProperty = property?.split?.(".");
    if (splittedProperty?.length > 1) {
      deleteAtPath(obj, splittedProperty, 0);
      return;
    }
    delete obj?.[property];
  });
};
