import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import { downloadReports } from "./utils";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
import { checkIsApprover } from "../../../utils/utils";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";

const POApprovalList = ({
  approvalType,
  status,
  usedIn,
  searchPlaceHolder = "Search",
}) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState([]);
  const [selectedPo, setSelectedPo] = useState([]);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [bulkComment, setBulkComment] = useState("");
  const [filters, setFilters] = useState({
    approvalType: approvalType,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (providedFilters, download) => {
    if (status) {
      providedFilters.status = status;
    }
    const params = providedFilters ? { ...providedFilters } : { ...filters };
    delete params.page;
    params.populateSite = true;
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (download) {
            downloadReports(response);
            return;
          }
          setBudgetList(response);
        }
      })
      .catch((err) => {});
  };
  const handleRowSelection = (event, data, type) => {
    const isChecked = event.target.checked;
    if (type === "selectAll") {
      let selectedPos = [];

      if (isChecked) {
        selectedPos = [...budgetList];
      }

      setSelectedPo(selectedPos);
    } else {
      let updatedSelectedPos = [...selectedPo];
      if (isChecked) {
        updatedSelectedPos.push(data);
      } else {
        updatedSelectedPos = updatedSelectedPos.filter(
          (ele) => ele.id !== data.id
        );
      }
      setSelectedPo(updatedSelectedPos);
    }
  };
  const viewBudget = (data) => {
    const { document } = data;
    navigate(`purchase-order/${data.documentId}?approvalId=${data.id}`);
  };
  const bulkApprove = () => {
    const approvalIds = selectedPo?.map((ele) => parseInt(ele.id));
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_APPROVE}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedPo([]);
          loadData(filters);
          setBulkComment("");
          setIsBulkModalOpen(false);
          toast.success("Approved Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  const bulkReconsider = () => {
    const approvalIds = selectedPo?.map((ele) => parseInt(ele.id));
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    } else {
      return toast.error("Comment is required", { autoClose: 2000 });
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_RECONSIDER}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedPo([]);
          loadData(filters);
          setBulkComment("");
          setIsBulkModalOpen(false);
          toast.success("Reconsidered Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  const bulkReject = () => {
    const approvalIds = selectedPo?.map((ele) => ele.id);
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    } else {
      return toast.error("Comment is required", { autoClose: 2000 });
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_REJECT}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedPo([]);
          setBulkComment("");
          loadData(filters);
          setIsBulkModalOpen(false);
          toast.success("Rejected Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };

  return (
    <div className="approval-list">
      <div className="filter-row">
        <TextField
          placeholder={`${searchPlaceHolder}`}
          value={filters?.searchKey || ""}
          size="small"
          onChange={(e) => setProperty("searchKey", e.target.value, setFilters)}
        />
        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />
        <Button
          variant="outlined"
          onClick={() => {
            loadData({
              ...filters,
            });
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          disabled={!budgetList.length}
          onClick={() => {
            loadData(filters, true);
          }}
        >
          Download
        </Button>
        {selectedPo.length > 0 && (
          <Button variant="outlined" onClick={() => setIsBulkModalOpen(true)}>
            Bulk Approval
          </Button>
        )}
      </div>
      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          status,
          usedIn,
          handleRowSelection,
          selectedPo,
          budgetList
        )}
        dataList={
          budgetList.slice(
            ((filters.page || 1) - 1) * 10,
            ((filters.page || 1) - 1) * 10 + 10
          ) || []
        }
        filters={filters}
        pageCount={Math.ceil(budgetList.length / 10)}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
        }}
      />
      {isBulkModalOpen && (
        <CustomModal
          title="Bulk Approve/Reject/Reconsider"
          onClose={() => setIsBulkModalOpen(false)}
        >
          <TextareaAutosize
            placeholder="Comments"
            minRows={6}
            value={bulkComment}
            style={{ width: "100%" }}
            onChange={(e) => setBulkComment(e.target.value)}
          />
          <div className="approve-btn-con finance-bulk-btn">
            <Button variant="outlined" sx={{ mr: 2 }} onClick={bulkApprove}>
              Approve
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={bulkReconsider}
              color="warning"
            >
              Reconsider
            </Button>
            <Button variant="outlined" color="error" onClick={bulkReject}>
              Reject
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default POApprovalList;
