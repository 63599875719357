import { deleteObjectProperties } from "../../../../common-utilities/utils/misc";
import {
  investmentOptions as cocoBuiltInvestmentOptions,
  occupanyYear1Options as cocoBuiltOccupanyYear1Options,
  profitAndLossOptions as cocoBuiltProfitAndLossOptions,
} from "../constants/coco-built.js";

import {
  investmentOptions as cocoAcquiredInvestmentOptions,
  occupanyYear1Options as cocoAcquiredOccupanyYear1Options,
  profitAndLossOptions as cocoAcquiredProfitAndLossOptions,
  ttmOptions as cocoAcquiredTTMOptions,
} from "../constants/coco-acquired";
import { storeTypes } from "../../../utils/constants";

export const setDashboardFltrs = (name, prevFilters, val) => {
  switch (name) {
    case "state":
      delete prevFilters.city;
      break;
    case "storeType":
      deleteObjectProperties(prevFilters, [
        "storeDetails",
        "investment",
        "profitLoss",
        "occupanyYear1",
      ]);
      switch (val) {
        case storeTypes.cocoBuild:
          prevFilters.investment = [cocoBuiltInvestmentOptions[0]];
          prevFilters.profitLoss = [cocoBuiltProfitAndLossOptions[0]];
          prevFilters.occupanyYear1 = [cocoBuiltOccupanyYear1Options[0]];
          break;
        case storeTypes.cocoAcquired:
          prevFilters.investment = [cocoAcquiredInvestmentOptions[0]];
          prevFilters.profitLoss = [cocoAcquiredProfitAndLossOptions[0]];
          prevFilters.occupanyYear1 = [cocoAcquiredOccupanyYear1Options[0]];
          prevFilters.ttmVsF1Y = [cocoAcquiredTTMOptions[0]];
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
};
