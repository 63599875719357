import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
export const applicableFor = [
  { value: "Franchise", label: "Franchise" },
  { value: "Employees", label: "Employees" },
  { value: "All", label: "All" },
];
export const getHeaderConfig = () => {
  return [
    {
      label: "Name",
      id: "Name",
      render: (data) => {
        return (
          <div className="si-cell">
            {data?.Name?.length > 15
              ? data.Name.slice(0, 15) + "..."
              : data.Name}
          </div>
        );
      },
    },
    {
      label: "Description",
      id: "Description",
      render: (data) => {
        return (
          <div className="si-cell">
            {data?.Description?.length > 15
              ? data.Description.slice(0, 15) + "..."
              : data.Description}
          </div>
        );
      },
    },
    {
      label: "Applicable For",
      id: "Applicable For",
    },
    {
      label: "Files",
      id: "files",
      render: (data, onClick) => {
        return (
          <div className="si-cell center-eye-icon">
            <RemoveRedEyeIcon
              onClick={() => onClick(data, "View Files")}
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <div className="si-cell edit-icon">
            <EditIcon
              onClick={() => onClick(data, "Edit")}
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
