import React, { useState, useEffect } from "react";
import "./index.scss";
import { getLimitsForUserLoggedIn } from "../../../utils/common-apis";
import DocustorageReportsSection from "../../docu-storage/components/Reports";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import TechNetReports from "../../tech-net/components/reports";
import BdLeadDocument from "../../bd-tool/components/document";
import CfApprovalReports from "../../cf-approval/components/reports";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Reports from "../../qnet/components/reports";
import { getOfflineData } from "../../../utils/offline-services";
import { checkAndGrantUserAccess } from "../../common-utilities/utils/common-utils";
import { setUserToken } from "../../qnet/utils/common-utils";
import ReportCard from "./reports-card";
import { Grid } from "@mui/material";
import SubsReports from "../../subs-erp/components/reports";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { APP_IDS, PROD_APP_IDS } from "./config";

const CentralReports = () => {
  const [listAllowedApps, setAllowedApps] = useState([]);
  const [appIdForReports, setAppIdForReports] = useState("");
  const [showReports, setShowReports] = useState(false);
  const [appIdObject, setAppIdObject] = useState(PROD_APP_IDS);
  const checkAppIds = () => {
    const isLocal = INTRANETHOSTNAME.includes("localhost");
    if (isLocal) {
      setAppIdObject(APP_IDS);
    } else {
      setAppIdObject(PROD_APP_IDS);
    }
  };
  useEffect(() => {
    checkAppIds();
  }, []);

  useEffect(() => {
    getLimitsForUserLoggedIn((limits) => {
      //here we're looping over allowed apps of user and return some selective app for which we need to show reports
      const allowedAppsOptions = limits
        ?.map((app) => {
          if (
            //In docustorage case, role In the docu app should be admin for accessing its reports
            (app.id === appIdObject.docustorage &&
              app?.role?.name === "admin") ||
            app?.id === appIdObject.techCare ||
            app?.id === appIdObject.bdLead ||
            app?.id === appIdObject.cfApproval ||
            app?.id === appIdObject.qNet ||
            app?.id === appIdObject.subs
          ) {
            return app;
          }
        })
        .filter((item) => item && item);
      setAllowedApps(allowedAppsOptions);
    });
  }, [appIdObject]);

  const appChangeHandler = (appId) => {
    if (appIdObject?.qNet === appId) {
      const tokens = getOfflineData("tokens");
      checkAndGrantUserAccess(
        tokenMainKeys.qnet,
        appSlugs.qnet,
        tokens,
        () => {
          setAppIdForReports(appId);
          setShowReports(true);
        },
        setUserToken
      );
      return;
    }
    setAppIdForReports(appId);
    setShowReports(true);
  };
  return (
    <div>
      {!showReports && (
        <div className="central-reports">
          <h3>Reports Section</h3>
          <Grid
            md={12}
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              gridGap: "2rem",
            }}
          >
            {listAllowedApps.map((app) => {
              return (
                <Grid md={3} item>
                  <ReportCard
                    appInfo={app}
                    appChangeFun={() => appChangeHandler(app?.id)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      {showReports && (
        <div className="central-reports-con">
          <div className="cancel-con" onClick={() => setShowReports(false)}>
            <ArrowBackIcon fontSize="medium" />
            <p>Go Back</p>
          </div>
          {appIdForReports === appIdObject?.docustorage && (
            <DocustorageReportsSection tokenKey={tokenMainKeys.intranet} />
          )}
          {appIdForReports === appIdObject?.techCare && (
            <TechNetReports tokenKey={tokenMainKeys.intranet} />
          )}
          {appIdForReports === appIdObject?.bdLead && (
            <BdLeadDocument tokenKey={tokenMainKeys.intranet} />
          )}
          {appIdForReports === appIdObject?.cfApproval && (
            <CfApprovalReports tokenKey={tokenMainKeys.intranet} />
          )}
          {appIdForReports === appIdObject?.qNet && <Reports />}
          {appIdForReports === appIdObject?.subs && (
            <SubsReports tokenKey={tokenMainKeys.intranet} />
          )}
        </div>
      )}
    </div>
  );
};
// "tokenKey" means we're going to use Intranet token, for getting reports from other apps
export default CentralReports;
