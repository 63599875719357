import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "../../../common-utilities/core/modal";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/pl-report";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { getOfflineData } from "../../utils/offline-services";
import { getPLTableHeader, MONTHS_LIST } from "./helper";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { downloadFileFromUrl } from "../../utils/common-utils";
import { convertCamelCaseToDisplayText } from "../../../common-utilities/utils";
import { toast } from "react-toastify";

const itemsPerPage = 10;

const PLTable = ({
  token = tokenMainKeys.plReport,
  slug = appSlugs.plReport,
}) => {
  const userDetails = getOfflineData("user", token) || {};
  const { role = "" } = userDetails;
  const [documentsList, setDocumentsList] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [areaManagers, setAreaManagers] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(false);
  const [dateRanges, setDateRanges] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    storeNames: [],
  });
  const [filters, setFilters] = useState({
    page: 1,
  });

  // Total number of pages
  const totalPages = Math.ceil(documentsList.length / itemsPerPage);

  useEffect(() => {
    loadData("json");
    loadManager();
  }, []);

  const loadManager = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.AM_USERS}`).then(
      (response) => {
        if (response?.length > 0) {
          setAreaManagers(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.name,
              };
            })
          );
        }
      }
    );
  };
  const loadData = (reportType) => {
    let params = {};
    let payload = {
      dateRange: {},
    };

    if (reportType) {
      params.reportType = reportType;
    }

    if (filters.storeCode) {
      params.storeCode = filters.storeCode;
    }
    if (filters.type) {
      params.type = filters.type;
    }
    if (filters.am) {
      params.am = filters.am;
    }
    if (filters.region) {
      params.region = filters.region;
    }
    if (filters.city) {
      params.city = filters.city;
    }
    if (filters?.am?.id) {
      params.am = filters.am.value;
    }

    if (filters.startMonth) {
      payload.dateRange.startMonth = filters.startMonth?.value;
    }
    if (filters.startYear) {
      payload.dateRange.startYear = filters.startYear;
    }
    if (filters.endMonth) {
      payload.dateRange.endMonth = filters.endMonth?.value;
    }
    if (filters.endMonth) {
      payload.dateRange.endYear = filters.endYear;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.DOWNLOAD_PL}`,
      Object.keys(payload.dateRange).length > 0 ? payload : null,
      params,
      null,
      slug,
      token
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        }
        if (reportType === "json") {
          setDocumentsList(response.rowsData || []);
          setColumnsList(getPLTableHeader(response.headers || []));
          setFilters({
            ...filters,
            page: 1,
          });

          let options = filterOptions;

          if (!(filterOptions?.storeCodes?.length > 0)) {
            options.storeCodes = [
              ...new Set(response.rowsData.map((rr) => rr["Store Code"])),
            ];
          }

          if (!(filterOptions?.types?.length > 0)) {
            options.types = [
              ...new Set(response.rowsData.map((rr) => rr["Type"])),
            ];
          }

          if (!(filterOptions?.regions?.length > 0)) {
            options.regions = [
              ...new Set(response.rowsData.map((rr) => rr["Region"])),
            ];
          }
          if (!(filterOptions?.cities?.length > 0)) {
            options.cities = [
              ...new Set(response.rowsData.map((rr) => rr["City"])),
            ];
          }

          setFilterOptions(options);
        } else if (response.url) {
          downloadFileFromUrl(response.url);
        }
      })
      .catch((err) => {});
  };

  const downloadData = () => {
    loadData();
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
  };

  // Get the current items to display
  const currentItems = useMemo(() => {
    const indexOfLastItem = filters.page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return (documentsList || []).slice(indexOfFirstItem, indexOfLastItem);
  }, [filters.page, documentsList]);

  return (
    <div className="pl-report-container">
      <div className="pl-filter-container">
        <Autocomplete
          disablePortal
          options={areaManagers || []}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              am: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Area Manager" />
          )}
        />
        <Autocomplete
          disablePortal
          options={filterOptions.storeCodes || []}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              storeCode: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Store Code" />
          )}
        />
        <Autocomplete
          disablePortal
          options={filterOptions.types || []}
          getOptionLabel={(l) => convertCamelCaseToDisplayText(l)}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              type: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Type" />
          )}
        />
        <Autocomplete
          disablePortal
          options={filterOptions.regions || []}
          className="dropdown-list"
          size="small"
          getOptionLabel={(l) => convertCamelCaseToDisplayText(l)}
          onChange={(event, values) => {
            setFilters({
              ...filters,
              region: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Region" />
          )}
        />

        <Autocomplete
          disablePortal
          options={filterOptions.cities || []}
          getOptionLabel={(l) => convertCamelCaseToDisplayText(l)}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              city: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="City" />
          )}
        />
        <Autocomplete
          disablePortal
          options={MONTHS_LIST}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              startMonth: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Start Month" />
          )}
        />
        <Autocomplete
          disablePortal
          options={[2021, 2022, 2023, 2024, 2025, 2026]}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              startYear: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Start Year" />
          )}
        />

        <Autocomplete
          disablePortal
          options={MONTHS_LIST}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              endMonth: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="End Month" />
          )}
        />
        <Autocomplete
          disablePortal
          options={[2021, 2022, 2023, 2024, 2025, 2026]}
          className="dropdown-list"
          size="small"
          onChange={(event, values) => {
            setFilters({
              ...filters,
              endYear: values,
            });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="End Year" />
          )}
        />

        {/* <Button variant="outlined" onClick={() => setFilters({ page: 1 })}>
          Clear Filters
        </Button> */}
        <Button variant="outlined" onClick={() => loadData("json")}>
          SEARCH
        </Button>
        <Button variant="outlined" onClick={() => downloadData()}>
          Download Report
        </Button>
        {role?.toLowerCase() === "admin" && (
          <Button variant="outlined" onClick={() => setUploadDocument(true)}>
            Upload Documents
          </Button>
        )}
      </div>

      <div className="invoice-table">
        <MuiTable
          nestedValue={true}
          columnsList={columnsList}
          dataList={currentItems}
          filters={filters}
          pageCount={totalPages}
          onChange={onPageChange}
        />
      </div>

      {uploadDocument && (
        <CustomModal
          open={!!uploadDocument}
          title="Profit And Loss"
          contentClassName="pl-report-form-modal"
          onClose={() => setUploadDocument(false)}
        >
          <div className="pl-report-form">
            {/* <div style={{ display: "flex", gap: "10px" }}>
              <Autocomplete
                disablePortal
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                className="dropdown-list"
                fullWidth={true}
                size="small"
                onChange={(event, values) => {
                  setDateRanges({
                    ...dateRanges,
                    startMonth: values
                  });
                }}
                renderInput={params => (
                  <TextField size="small" {...params} label="Month" />
                )}
              />
              <Autocomplete
                disablePortal
                options={[2021, 2022, 2023, 2024, 2025, 2026]}
                className="dropdown-list"
                fullWidth={true}
                size="small"
                onChange={(event, values) => {
                  setDateRanges({
                    ...dateRanges,
                    startYear: values
                  });
                }}
                renderInput={params => (
                  <TextField size="small" {...params} label="Year" />
                )}
              />
            </div> */}
            <FileUpload
              uploadText="Upload PL Actual"
              id="pl-actual"
              fullWidth={true}
              clsName="pl-spidle-upload-file"
              url={`${HOSTNAME}${REST_URLS.PL_ACTUAL}`}
              callBack={() => loadData("json")}
            />
            <FileUpload
              uploadText="Upload PL AOP"
              id="pl-aop"
              clsName="pl-spidle-upload-file"
              url={`${HOSTNAME}${REST_URLS.PL_AOP}`}
              callBack={() => loadData("json")}
            />
            <FileUpload
              uploadText="Upload PL UEM"
              id="pl-uem"
              clsName="pl-spidle-upload-file"
              url={`${HOSTNAME}${REST_URLS.PL_UEM}`}
              callBack={() => loadData("json")}
            />
            {/* <Button variant="outlined" onClick={() => loadData()}>
              Submit
            </Button> */}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PLTable;
