import { profitAndLossSelectValues } from "../../constants";
import { getConfigFields, getSlicedNum, formatedPercentageRender } from "../../utils";

export const profitlossConfig = (filters, subHeader, mainHeader) => {
  const config = [];

  switch (subHeader) {
    case profitAndLossSelectValues.year1ProfitAndLoss:
      config.push(
        {
          label: "Yearly 1Revenue",
          id: "yearly1Revenue",
          render: (data) => getSlicedNum(data, "yearly1Revenue"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Cost of Sales",
          id: "yearly1CostOfSales",
          render: (data) => getSlicedNum(data, "yearly1CostOfSales"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Gross Profit",
          id: "yearly1GrossProfit",
          render: (data) => getSlicedNum(data, "yearly1GrossProfit"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Royalty",
          id: "yearly1Royalty",
          render: (data) => getSlicedNum(data, "yearly1Royalty"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Occupancy",
          id: "yearly1Occupancy",
          render: (data) => getSlicedNum(data, "yearly1Occupancy"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Labour",
          id: "yearly1Labour",
          removeDecimal: true,
          render: (data) => getSlicedNum(data, "yearly1Labour"),
        },
        {
          label: "Yearly 1Tech (allocation)",
          id: "yearly1TechAllocation",
          removeDecimal: true,
          render: (data) => getSlicedNum(data, "yearly1Commission"),
        },
        {
          label: "Yearly 1Utilities",
          id: "yearly1Utilities",
          removeDecimal: true,
          render: (data) => getSlicedNum(data, "yearly1Utilities"),
        },
        {
          label: "Yearly 1Commission",
          id: "yearly1Commission",
          render: (data) => getSlicedNum(data, "yearly1Commission"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Staff incentive",
          id: "yearly1StaffIncentive",
          render: (data) => getSlicedNum(data, "yearly1StaffIncentive"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Marketing",
          id: "yearly1Marketing",
          render: (data) => getSlicedNum(data, "yearly1Marketing"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Other Opex",
          id: "yearly1OtherOpex",
          render: (data) => getSlicedNum(data, "yearly1OtherOpex"),
          removeDecimal: true,
        },
        {
          label: "Yearly 1Store EBITDA",
          id: "yearly1StoreEbitda",
          render: (data) => getSlicedNum(data, "yearly1StoreEbitda"),
          removeDecimal: true,
        }
      );
      break;
    case profitAndLossSelectValues.profitAndLossAll:
      config.push(
        {
          label: "Yearly Annual Sales Yr1",
          id: "yearlyAnnualSalesYr1",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr1"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr2",
          id: "yearlyAnnualSalesYr2",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr2"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr3",
          id: "yearlyAnnualSalesYr3",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr3"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr4",
          id: "yearlyAnnualSalesYr4",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr4"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr5",
          id: "yearlyAnnualSalesYr5",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr5"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr6",
          id: "yearlyAnnualSalesYr6",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr6"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr7",
          id: "yearlyAnnualSalesYr7",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr7"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr8",
          id: "yearlyAnnualSalesYr8",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr8"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr9",
          id: "yearlyAnnualSalesYr9",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr9"),
          removeDecimal: true,
        },
        {
          label: "Yearly Annual Sales Yr10",
          id: "yearlyAnnualSalesYr10",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr10"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr1",
          id: "yearlyEbitdaYr1",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr1"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr2",
          id: "yearlyEbitdaYr2",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr2"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr3",
          id: "yearlyEbitdaYr3",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr3"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr4",
          id: "yearlyEbitdaYr4",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr4"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr5",
          id: "yearlyEbitdaYr5",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr5"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr6",
          id: "yearlyEbitdaYr6",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr6"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr7",
          id: "yearlyEbitdaYr7",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr7"),
        },
        {
          label: "Yearly EBITDA Yr8",
          id: "yearlyEbitdaYr8",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr8"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr9",
          id: "yearlyEbitdaYr9",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr9"),
          removeDecimal: true,
        },
        {
          label: "Yearly EBITDA Yr10",
          id: "yearlyEbitdaYr10",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr10"),
          removeDecimal: true,
        }
      );
      break;
    case profitAndLossSelectValues.paybackYears:
      config.push({
        label: "Payback (Years)",
        id: "paybackYears",
        render: (data) => getSlicedNum(data, "paybackYears"),
        removeDecimal: true,
      });
      break;
    case profitAndLossSelectValues.irr5years:
      config.push({
        label: "IRR (5 years)",
        id: "irr5Years",
        render: (data) => formatedPercentageRender(data, "irr5Years"),
        percentageConversion: true,
      });
      break;
    default:
      break;
  }
  return getConfigFields(config, filters, "profitLoss", mainHeader);
};
