// Name of the template file for different type of templates
export const uploadTemplates = {
  item: 'item',
  category: 'category',
  subCategory: 'subcategory',
  vendorItemMapping: 'vendor-mapping',
  company: 'company',
  location: 'locations',
  'po-items': 'po-items',
  budgetLineItems: 'budget-line-items',
  grn: 'grn-items',
  foreign_currency: 'foreign_currency',
  payment_details: 'payment-details',
};

export const singleLineTemplates = {
  [uploadTemplates['po-items']]: [
    [
      'Item Code',
      'UOM',
      'HSN Code',
      'Quantity',
      'Tax Percentage',
      'Price',
      'Description',
      'Remarks',
    ],
  ],
  [uploadTemplates.budgetLineItems]: [
    // ['amount', 'specification', 'categoryCode'],
    ['amount', 'Item Main Category', 'categoryCode'],
  ],
  [uploadTemplates.payment_details]: [
    [
      'Invoice Document Number',
      'Payment Amount',
      'UTR',
      'TDS Amount',
      'Date Of Payment',
    ],
  ],
};

export const defaultTemplates = {
  [uploadTemplates.category]: [
    {
      Id: null,
      Name: null,
      Capex: null,
    },
  ],
  [uploadTemplates.subCategory]: [
    {
      Id: null,
      Name: null,
      Category: null,
    },
  ],
  [uploadTemplates.item]: [
    {
      Id: null,
      'Item Name': null,
      Price: null,
      'Tax Percentage': null,
      Specifications: null,
      Category: null,
      Subcategory: null,
      'HSN Code': null,
      UOM: null,
    },
  ],
  [uploadTemplates.vendorItemMapping]: [
    {
      Id: null,
      'Vendor Code': null,
      Item: null,
      Price: null,
    },
  ],
  [uploadTemplates.company]: [
    {
      Id: null,
      Name: null,
      GSTIN: null,
      Address: null,
      Parent: null,
    },
  ],
  [uploadTemplates.location]: [
    {
      Id: null,
      'Location Name': null,
      'Location Code': null,
      'Location Type': null,
      'Location Address': null,
      GSTIN: null,
      City: null,
      State: null,
      Status: null,
    },
  ],
  [uploadTemplates.foreign_currency]: [
    {
      Id: null,
      Name: null,
    },
  ],
};
