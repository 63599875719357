import React, { useEffect, useState } from "react";
import CustomModal from "../../../common-utilities/core/modal";
import { Box, TextField, Stack } from "@mui/material";
import { nearbySubwayDataConfig } from "./config";
import { FormatSelect } from "./common-components/format-select";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import SaveIcon from "@mui/icons-material/Save";
const NearbySubwayData = ({ data, setNearbyInformation }) => {
  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);

  const [modalDetails, setModalDetails] = useState({
    modalTitle: "",
    data: null,
    type: "",
  });

  const setModalDataOnChange = (e) => {
    const { name, value } = e.target;
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      data: { ...prevDetails.data, [name]: value },
    }));
  };

  const editDeleteAdditionModalHandler = (data, type) => {
    switch (type) {
      case "edit":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Edit details",
          data,
          type: "edit",
        }));
        break;
      case "create":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Create",
          data,
          type: "create",
        }));
        break;
      case "delete":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Delete",
          data,
          type: "delete",
        }));
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      modalTitle: "",
      data: null,
      type: "",
    }));
  };

  useEffect(() => {
    const { nearbySubway = [] } = data;
    const allData = nearbySubway.map((item, index) => {
      return { ...item, dataIndex: index };
    });
    setTableData(allData);
  }, [data]);

  const saveAddorDeleteModalData = () => {
    const {} = modalDetails;
    const {
      restaurant = "",
      format = "",
      ads = "",
      del = "",
      distance = "",
      dataIndex,
      location,
      cluster,
      adt,
      apc,
    } = modalDetails.data;

    const allDetails = {};
    if (restaurant) {
      allDetails.restaurant = restaurant;
    }
    if (location) {
      allDetails.location = location;
    }
    if (cluster) {
      allDetails.cluster = cluster;
    }
    if (format) {
      allDetails.format = format;
    }
    if (ads) {
      allDetails.ads = ads;
    }
    if (del) {
      allDetails.del = del;
    }
    if (distance) {
      allDetails.distance = distance;
    }

    if (adt) {
      allDetails.adt = adt;
    }
    if (apc) {
      allDetails.apc = apc;
    }

    switch (modalDetails.type) {
      case "edit":
        setNearbyInformation((prevDetails) => {
          const { nearbySubway = [] } = prevDetails;
          const newNearByDetails = [...nearbySubway];
          newNearByDetails[dataIndex] = allDetails;
          return {
            ...prevDetails,
            nearbySubway: [...newNearByDetails],
          };
        });

        break;
      case "create":
        setNearbyInformation((prevDetails) => {
          const { nearbySubway = [] } = prevDetails;
          return {
            ...prevDetails,
            nearbySubway: [...nearbySubway, allDetails],
          };
        });
        break;
      case "delete":
        setNearbyInformation((prevDetails) => {
          const { nearbySubway = [] } = prevDetails;

          return {
            ...prevDetails,
            nearbySubway: nearbySubway.filter((_, index) => {
              return index !== dataIndex;
            }),
          };
        });
        break;
      default:
        break;
    }

    closeModal();
  };

  return (
    <div className="nearby-subway-data-section">
      <div className="add-others-button-container">
        <CommonBtn
          onClick={() => {
            editDeleteAdditionModalHandler({}, "create");
          }}
          sx={{ mr: 1 }}
          variant="contained"
          size="small"
          text="Add New"
        />
      </div>
      <MuiTable
        columnsList={nearbySubwayDataConfig()}
        dataList={tableData.slice((page - 1) * 10, (page - 1) * 10 + 10)}
        filters={page}
        pageCount={Math.ceil(tableData.length / 10)}
        onClick={editDeleteAdditionModalHandler}
        onChange={(page) => {
          setPage(page);
        }}
        customTableClass="nearby-subway-table"
        customTableConClass="nearby-subway-class"
      ></MuiTable>
      {modalDetails.data && (
        <CustomModal
          classesToPreventClosing={["poper-select", "MuiBackdrop-root"]}
          title={modalDetails.modalTitle}
          onClose={closeModal}
        >
          {modalDetails.type === "delete" && (
            <>
              <h3>Are you sure ?</h3>
            </>
          )}
          {modalDetails.type !== "delete" && (
            <Box
              className="poper-select"
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                name="restaurant"
                sx={{ my: 1 }}
                label="Restaurant Number"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.restaurant || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="location"
                sx={{ my: 1 }}
                label="Location"
                className="new-textfield"
                multiline
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.location || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="cluster"
                sx={{ my: 1 }}
                label="Cluster"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.cluster || ""}
                size="small"
              />

              <FormatSelect
                value={modalDetails?.data?.format || ""}
                name="format"
                handleChange={setModalDataOnChange}
                removeValueFromMainData={() => {
                  setModalDetails((prevDetails) => {
                    delete prevDetails?.data?.format;
                    return prevDetails;
                  });
                }}
              />
              <TextField
                fullWidth
                name="distance"
                type="number"
                sx={{ my: 1 }}
                label="Distance(in kms)"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.distance || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="ads"
                sx={{ my: 1 }}
                label="ADS"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.ads || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="adt"
                sx={{ my: 1 }}
                label="ADT"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.adt || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="apc"
                sx={{ my: 1 }}
                label="APC"
                multiline
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.apc || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="del"
                sx={{ my: 1 }}
                label="Del% "
                type="number"
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.del || ""}
                size="small"
              />
            </Box>
          )}

          <Stack direction="row" spacing={2} sx={{ my: 2 }}>
            <div className="end-container">
              {modalDetails.type === "delete" ? (
                <div>
                  <CommonBtn
                    onClick={saveAddorDeleteModalData}
                    variant="contained"
                    color="error"
                    text="Delete"
                    size="small"
                  />
                </div>
              ) : (
                <div>
                  <CommonBtn
                    variant="contained"
                    startIcon={<SaveIcon />}
                    className="button-color"
                    fullWidth
                    onClick={saveAddorDeleteModalData}
                    text="Save"
                    size="small"
                  />
                </div>
              )}

              <div>
                <CommonBtn
                  variant="outlined"
                  fullWidth
                  onClick={closeModal}
                  text="Cancel"
                  size="small"
                />
              </div>
            </div>
          </Stack>
        </CustomModal>
      )}
    </div>
  );
};

export default NearbySubwayData;
