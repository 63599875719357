import { toast } from "react-toastify";

// These are the formats that are supported for we can restrict to these
// Please add more as per requirements
export const allowedFormats = {
  pdf: "pdf",
  images: "images",
  video: "video",
  wordDocument: "word document",
  excelFile: "excel",
};

export const disableFileUpload = (file, allowedFmtLst) => {
  if (!allowedFmtLst?.length) {
    return;
  }
  let disabledUpload = true;
  allowedFmtLst?.forEach?.((item) => {
    switch (item) {
      case allowedFormats.pdf:
        if (file?.type === "application/pdf") {
          disabledUpload = false;
        }
        break;
      case allowedFormats.images:
        if (file?.type?.startsWith?.("image/")) {
          disabledUpload = false;
        }
        break;
      case allowedFormats.video:
        if (file?.type?.startsWith?.("video/")) {
          disabledUpload = false;
        }
        break;
      case allowedFormats.wordDocument:
        if (
          [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(file?.type?.trim?.())
        ) {
          disabledUpload = false;
        }
        break;
      case allowedFormats.excelFile:
        if (
          [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ].includes(file?.type?.trim?.())
        ) {
          disabledUpload = false;
        }

      default:
        break;
    }
  });

  if (disabledUpload) {
    const fmtList =
      allowedFmtLst?.reduce?.((string, format, index) => {
        if (index && index === allowedFormats.length - 1) {
          return `${string} and ${format}`;
        }
        if (!index) {
          return format;
        }

        return `${string}, ${format}`;
      }, "") || "";
    toast.error(`You can only upload only files which are ${fmtList}`, {
      autoClose: 2000,
    });
    return true;
  }

  return false;
};

export const getInputAccept = (formats) => {
  return (
    formats?.reduce?.((allowedFmts, format) => {
      switch (format) {
        case allowedFormats.wordDocument:
          allowedFmts +=
            ".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,";
          break;

        case allowedFormats.excelFile:
          allowedFmts +=
            ".csv, .xlsx, .xls, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,";
          break;
        default:
          break;
      }
      return allowedFmts;
    }, "") || ""
  );
};
