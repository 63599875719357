export const startDateCell = (
  valueKey,
  callBack,
  checkNa = false,
  label = "Start Date"
) => {
  return {
    label,
    [valueKey]: "startDate",
    render: (data) => {
      const naCondition =
        typeof callBack === "function" ? callBack(data) : checkNa;
      return (
        <span className="si-cell">
          {naCondition
            ? !data?.additionalDetails?.na && data?.additionalDetails?.startDate
            : data?.additionalDetails?.startDate}
        </span>
      );
    },
  };
};

export const endDateCell = (valueKey, callBack, checkNa = false) => {
  return {
    label: "End Date",
    [valueKey]: "endDate",
    render: (data) => {
      const naCondition =
        typeof callBack === "function" ? callBack(data) : checkNa;
      return (
        <span className="si-cell">
          {naCondition
            ? !data?.additionalDetails?.na && data?.additionalDetails?.endDate
            : data?.additionalDetails?.endDate}
        </span>
      );
    },
  };
};
