export const checkValidStoreCombination = (
  storeNo,
  sequenceNo,
  satelliteNum
) => {

  return (
    Number.isInteger(parseInt(storeNo)) &&
    Number.isInteger(parseInt(sequenceNo)) &&
    Number.isInteger(parseInt(satelliteNum))
  );
};
