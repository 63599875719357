/** @format */

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import "./index.scss";
import {
  getOfflineData,
  clearOffllineData,
} from "../../utils/offline-services";
import SideMenu from "./side-menu";
import { tokenMainKeys } from "../../../../utils/constants";
import { mainRedirectToIntranetPortal } from "../../../common-utilities/utils/common-utils";
import Intranetlogout from "../../../../core/header/logout";

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    let user = getOfflineData("user");
    setUserDetails(user);
  }, []);
  const logout = () => {
    clearOffllineData("user");
    clearOffllineData("tokens");
    Intranetlogout(navigate);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="header-container-subs">
      <div className="r-c">
        <SideMenu />
        <img
          onClick={() => {
            mainRedirectToIntranetPortal(tokenMainKeys.subs);
          }}
          src={require("../../../common-utilities/resources/logo.png")}
          width="120"
        />
      </div>

      <div className="menu">
        <Avatar>
          <AccountCircleIcon />
        </Avatar>
        <Button
          size="small"
          style={{
            padding: "10px",
            height: "35px",
            fontSize: "24px",
            textTransform: "none",
          }}
          variant="text"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {userDetails?.firstName}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout font />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
