import { Button } from "@mui/material";

export const getHeaderConfig = (editVendor) => {
  const headers = [
    {
      label: "Code",
      id: "code",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Created on",
      id: "createdAt",
      render: (data) => {
        return new Date(data.createdAt).toLocaleDateString();
      },
    },
    {
      label: "Actions",
      id: "name",
      render: (data) => {
        return (
          <Button variant="text" onClick={() => editVendor(data, "EDIT")}>
            Edit
          </Button>
        );
      },
    },
  ];
  return headers;
};

export const FILTER_DATE_RANGE_TYPES = [
  {
    label: "Created Date",
    value: "createdAt",
  },
  {
    label: "Invoice Date",
    value: "invoiceDate",
  },
];

export const isFilterDisabled = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;
  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return !startDate && !endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return false;
};

export const clearFilter = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;

  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return startDate && endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return true;
};
