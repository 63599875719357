import { Button } from "@mui/material";
export const AcceptRejectButtonContainer = ({
  disabledSave,
  acceptFunction,
  acceptedText,
  rejectFunction,
  rejectText,
  buttonContainerClass,
}) => {
  const disabledUserFromSaving =
    typeof disabledSave === "function"
      ? disabledSave()
      : typeof disabledSave === "boolean"
      ? disabledSave
      : false;
  return (
    <>
      <div className={`${buttonContainerClass ? buttonContainerClass : ""}`}>
        <Button
          variant="outlined"
          disabled={disabledUserFromSaving}
          onClick={() => {
            if (typeof acceptFunction === "function") {
              acceptFunction();
            }
          }}
        >
          {acceptedText ? acceptedText : "Save"}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (typeof rejectFunction === "function") {
              rejectFunction();
            }
          }}
        >
          {rejectText ? rejectText : "Cancel"}
        </Button>
      </div>
    </>
  );
};
