import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
export const getHeaderConfig = () => {
  const config = [
    {
      label: "Store No.",
      id: "storeNum",
    },
    {
      label: "Vendor Id",
      id: "vendorId",
    },
    {
      label: "Vendor Name",
      id: "vendorName",
    },
    {
      label: "Vendor Country",
      id: "vendorCountry",
    },

    {
      label: "Sales",
      id: "sales",
    },
    {
      label: "Customer Type",
      id: "customerType",
    },
    {
      label: "Number Of Transactions",
      id: "numberOfTransactions",
    },
    {
      label: "Date",
      id: "date",
      render: (data) => {
        const date = moment(data.date).format("DD-MM-YYYY");
        return date;
      },
    },
    {
      label: "View",
      id: "view",
      render: (data, onClick) => {
        return (
          <VisibilityIcon
            onClick={() => onClick(data, "view")}
            style={{ cursor: "pointer" }}
          ></VisibilityIcon>
        );
      },
    },
  ];
  return config;
};
