import "./index.scss";
import React, { useState } from "react";
import StoreInsuranceDetails from "./store-insurance-details";
import StoreInsuranceHistory from "./store-insurance-history";
import StoreWorkersCompInsuranceDetails from "./store-workers-comp-insurance-details";
import { TabPanel, MainTabContainer } from "../../../common-utilities/core/tabs";

const StoreInsurance = () => {
  const [value, setValue] = useState(0);

  const labels = [
    "Store Insurance Detail",
    "Store Workers Comp. Insurance Details",
    "Store Insurance History",
  ];
  return (
    <div className="store-insurance-container">
      <MainTabContainer
        mainClass=""
        tabContainerClass=""
        tabsClass=""
        value={value}
        setValue={setValue}
        labels={labels}
      />
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <StoreInsuranceDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StoreWorkersCompInsuranceDetails />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StoreInsuranceHistory />
        </TabPanel>
      </div>
    </div>
  );
};

export default StoreInsurance;
