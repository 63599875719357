// Function for checking which tab user is currently on
export const checkForForUserSelectedTab = (value) => {
  //allUsersFirstTab - this is first tab in users section with value 0
  //distirbutorsTab - this is second tab in users section with value 1
  //suppliersTab - this is third tab in users section with value 2
  const userSelectedTab = {
    allUsersFirstTab: false,
    distirbutorsTab: false,
    suppliersTab: false,
  };
  if (value === 0) {
    userSelectedTab.allUsersFirstTab = true;
  }
  if (value === 1) {
    userSelectedTab.distirbutorsTab = true;
  }
  if (value === 2) {
    userSelectedTab.suppliersTab = true;
  }
  return userSelectedTab;
};
