import { useState } from "react";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import { getFieldList } from "./api";
import { clearProperty } from "../../../common-utilities/utils/misc";
import { setProperty } from "../../../common-utilities/utils/misc";

const FieldAutoComplete = ({
  filter,
  label,
  fieldName,
  setDataFunc,
  keyName,
}) => {
  const [options, setOptions] = useState([]);
  return (
    <>
      <AutocompleteComponent
        options={options}
        label={label}
        onChange={(e, val) => {
          if (typeof setDataFunc !== "function" || !keyName) {
            return;
          }
          if (val?.value) {
            setProperty(keyName, val?.value, setDataFunc);
            return;
          }
          clearProperty(keyName, setDataFunc);
        }}
        onInputChange={(e, val) => {
          if (val) {
            getFieldList({ ...filter, fieldName, fieldValue: val }, setOptions);
            return;
          }
          setOptions([]);
        }}
        noValue={true}
      />
    </>
  );
};

export default FieldAutoComplete;
