import React, { useState } from "react";
import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";
import LeaseRentCom from "./lease-rent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const LeaseRent = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-person-container">
      <div className="mainTabContainer">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tab-container"
        >
          <Grid container spacing={0}>
            <Grid item md={12} xs={12} sm={7} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Lease Rent" />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <LeaseRentCom />
        </TabPanel>
      </div>
    </div>
  );
};

export default LeaseRent;
