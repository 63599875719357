import { toast } from 'react-toastify';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';

export const getApprovalConfigList = (setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVAL_CONFIG_ROLE_LIST}`
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      const results = res?.map?.((item) => {
        return {
          label: item?.name,
          value: item?._id,
          user:
            item?.roleType === 'AM' || item?.roleType === 'RM'
              ? ''
              : `${item?.user?.name } - ${item?.user?.email}` || '',
        };
      });

      setDataFunc([...results]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateCreateConfig = (data, onSuccess) => {
  const payload = { ...data };
  const { id } = payload;
  delete payload.id;
  delete payload.curId;
  payload.stages = payload.stages.map((item) => {
    delete item._id;
    return item;
  });

  const url = id
    ? `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPDATE_APPROVAL_CONFIG_BY_ID}${id}`
    : `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CREATE_APPROVAL_CONFIG}`;
  const method = id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const message = id
    ? 'Config Updated Successfully'
    : 'Config Created Successfully';
  invokeApi(method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      typeof onSuccess === 'function' && onSuccess();
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 2000 });
    });
};

export const deleteApprovalConfigById = (id, onSuccess) => {
  invokeApi(
    HTTP_METHODS.DELETE,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.DELETE_APPROVAL_CONFIG_BY_ID}${id}`
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success('Config Deleted Successfully', { autoClose: 2000 });
      typeof onSuccess === 'function' && onSuccess();
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 2000 });
    });
};

export const createFinanceRole = (body, onSuccess) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CREATE_FINANCE_ROLE}`,
    body
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success('Role created successfully', { autoClose: 2000 });
      typeof onSuccess === 'function' && onSuccess();
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 2000 });
    });
};
