import { Button } from '@mui/material';

export const getHeaderConfig = () => {
  const headers = [
    {
      label: 'Id',
      id: '_id'
    },
    {
      label: 'Name',
      id: 'name'
    }     
  ];
  return headers;
};
