import { monthNumberMap } from './constants';

export const convertDDMMYYYdateToIsoString = (date, separator = '-') => {
  if (!date) {
    return '';
  }
  const splittedDate = date.split(separator);
  const convertedDate = new Date(
    parseInt(splittedDate[2]),
    parseInt(splittedDate[1]) - 1,
    parseInt(splittedDate[0])
  );

  return convertedDate.toISOString();
};

export const dateFormats = {
  yymmdd: 'YYYY-MM-DD',
  dateMonthYear: 'Date Month Year',
};

export const convertDateToIsoString = (date, format, separator = '-') => {
  if (!date || date?.split?.(separator)?.length != 3) {
    return '';
  }
  const splittedDate = date.split(separator);
  let convertedDate;
  switch (format) {
    case dateFormats.yymmdd:
      convertedDate = new Date(
        parseInt(splittedDate[0]),
        parseInt(splittedDate[1]) - 1,
        parseInt(splittedDate[2])
      );

      break;
    default:
      convertedDate = new Date(
        parseInt(splittedDate[2]),
        parseInt(splittedDate[1]) - 1,
        parseInt(splittedDate[0])
      );
      break;
  }

  return convertedDate.toISOString();
};

export const convertDateMonthDateYear = (date) => {

  const convertedDate = new Date(date);

  if (
    !date ||
    !convertedDate?.toDateString?.() ||
    convertedDate?.toDateString() === 'Invalid Date'
  ) {
    return '';
  }
  const day = convertedDate.getDate();
  const month = convertedDate.getMonth() + 1;
  const year = convertedDate.getFullYear();
  return `${day}-${monthNumberMap[month]}-${year}`;
};
