import { Button, TextField } from '@mui/material';
import { setProperty } from '../../../../../common-utilities/utils/misc';
import { queryForeignCurrencies } from '../apis';
import FileUpload from '../../../../../common-utilities/core/file-uploader';
import { FINANCE_HOSTNAME } from '../../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../../common-utilities/utils/end-points/finance';
import DownloadTemplateButton from '../../common-components/upload-template';
import { uploadTemplates } from '../../constants';
import { isCreateAllowed } from '../../../../utils/common-utils';
import { modalActions } from '../utils';

const HeaderSection = ({
  filters,
  setFilters,
  setTableData,
  tableData,
  setModalDetails,
}) => {
  return (
    <div className="cl-header-section">
      <div className="left-container">
        <TextField
          placeholder="Search"
          value={filters?.searchKey || ''}
          size="small"
          onChange={(e) => {
            setProperty('searchKey', e.target.value, setFilters);
          }}
        />
        <Button
          variant="outlined"
          onClick={() => {
            queryForeignCurrencies(
              {
                ...filters,
                page: 1,
                limit: 10,
              },
              setTableData
            );
          }}
        >
          Search
        </Button>
      </div>
      <div className="left-container">
        {isCreateAllowed('master_data') && (
          <>
            <FileUpload
              buttonBackground="#fff"
              buttonColor="#008938"
              mainButtonClass="subway-finance-bulk-upload"
              borderColor="rgba(0, 138, 82, 0.5)"
              uploadText="BULK UPLOAD"
              clsName="finance-master-data-uploader"
              url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}${uploadTemplates.foreign_currency}`}
              callBack={() => {
                queryForeignCurrencies(
                  {
                    ...filters,
                    page: filters.page,
                    limit: 10,
                  },
                  setTableData
                );
              }}
              formDataKey="file"
            />
            <DownloadTemplateButton type={uploadTemplates.foreign_currency} />
          </>
        )}

        <Button
          variant="outlined"
          disabled={!tableData.totalResults}
          onClick={() => {
            queryForeignCurrencies(
              {
                page: 1,
                limit: tableData.totalResults,
              },
              null,
              true
            );
          }}
        >
          Download
        </Button>
        {isCreateAllowed('master_data') && (
          <Button
            variant="outlined"
            onClick={() => {
              modalActions(null, 'CREATE', setModalDetails);
            }}
          >
            Create
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
