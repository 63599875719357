import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { ticketActions } from "./utils";
export const HeaderConfig = [
  {
    label: "Ticket ID",
    id: "id",
    onClick: true,
    render: (data, onClick) => {
      return (
        <span className="clickable userClickable" onClick={() => onClick(data)}>
          {data?.id?.substr?.(0, 8)}
        </span>
      );
    },
  },
  {
    label: "Created On",
    id: "createdAt",
    render: (data) => {
      return (
        <span>
          {data?.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
          {data?.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Created By",
    id: "createdBy",
    render: (data) => {
      return <span id="createdBy">{data?.complaintForm?.createdBy?.name}</span>;
    },
  },
  {
    label: "Category",
    id: "category",
    render: (data) => {
      return <span id="category">{data?.category?.name}</span>;
    },
  },
  {
    label: "Comments",
    id: "comments",
    render: (data, onClick) => {
      return (
        <span id="comments">
          <IconButton
            onClick={() => onClick(data?.comments, ticketActions.commentModal)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </span>
      );
    },
  },
  {
    label: "Closed at",
    id: "createdAt",
    render: (data) => {
      return (
        <span>
          {data?.closedAt && new Date(data.closedAt).toLocaleDateString()}{" "}
          {data?.closedAt && new Date(data.closedAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Store Number",
    id: "store",
    render: (data) => {
      return <span>{data?.store?.storeNumber}</span>;
    },
  },
  {
    label: "Contact Person",
    id: "complaintFormPerson",
    render: (data) => {
      return <span>{data?.complaintForm?.contactPerson}</span>;
    },
  },
  {
    label: "Contact Number",
    id: "complaintFormNumber",
    render: (data) => {
      return <span>{data?.complaintForm?.storeContact}</span>;
    },
  },
  {
    label: "Email",
    id: "email",
    render: (data) => {
      return <span>{data?.complaintForm?.email}</span>;
    },
  },
  {
    label: "Assigned to",
    id: "assignedTo",
    render: (data) => {
      let assignedTo = "Not assigned";
      if (data?.assignedTo && data?.assignedTo?.name) {
        assignedTo = data.assignedTo.name;
      }
      return <span>{assignedTo}</span>;
    },
  },
  {
    label: "Status",
    id: "status",
  },
];
export const STORE_TYPES = [
  { label: "COCO_BUILT", value: "COCO_BUILT" },
  { label: "COCO_ACQUIRED", value: "COCO_ACQUIRED" },
  { label: "FOFO", value: "FOFO" },
];
