import { getLabelsAndValuesForSelect } from "../../../../common-utilities/utils";
import { yesNoOptions } from "../../../../common-utilities/utils/constants";
import { validDocumentTypes, validStoreTypes } from "../../../utils/document-constants";
import { leaseTypeDropdown } from "../utils/fofo-utils";

export const fofoInputsForm = (formDetails, errors, storeType) => {
  if (storeType !== validStoreTypes.fofo) {
    return {};
  }
  const leaseTypes = Object.values(leaseTypeDropdown);
  const leaseSelect = getLabelsAndValuesForSelect(leaseTypes, leaseTypes);
  let config = {
    [validDocumentTypes.franchiseAgreement]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.franchiseAgreement,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.subleaseAgreement]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },
      {
        label: "Licensor(Landlord)*",
        name: "licensor",
        type: "text",
        value: formDetails.licensor,
        error: errors.licensor,
        helperText: errors.licensor ? "Required" : "",
      },
      {
        label: "Licensee-Firm*",
        name: "licenseeFirm",
        type: "text",
        value: formDetails.licenseeFirm,
        error: errors.licenseeFirm,
        helperText: errors.licenseeFirm ? "Required" : "",
      },
      {
        label: "Licensee-Partners*",
        name: "licenseePartners",
        type: "text",
        value: formDetails.licenseePartners,
        error: errors.licenseePartners,
        helperText: errors.licenseePartners ? "Required" : "",
      },
      {
        label: "Type of lease*",
        name: "leaseType",
        placeholder: "Select lease type",
        type: "select",
        value: formDetails.leaseType,
        error: errors.leaseType,
        options: leaseSelect,
        helperText: errors.leaseType ? "Required" : "",
      },
      {
        label: "Lease Sign Date*",
        name: "leaseSignDate",
        type: "date",
        value: formDetails.leaseSignDate,
        error: errors.leaseSignDate,
        helperText: errors.leaseSignDate ? "Required" : "",
      },
      {
        label: "Lease Effective Date*",
        name: "leaseEffectiveDate",
        type: "date",
        value: formDetails.leaseEffectiveDate,
        error: errors.leaseEffectiveDate,
        helperText: errors.leaseEffectiveDate ? "Required" : "",
      },
      {
        label: "Lease End Date*",
        name: "leaseEndDate",
        type: "date",
        value: formDetails.leaseEndDate,
        error: errors.leaseEndDate,
        helperText: errors.leaseEndDate ? "Required" : "",
      },
      {
        label: "Area in Sq. Ft.",
        name: "area",
        type: "Number",
        value: formDetails.area,
        error: errors.area,
        helperText: errors.area ? "Required" : "",
      },
      {
        label: "Current rent per month (INR.)*",
        name: "currentRent",
        type: "Number",
        value: formDetails.currentRent,
        error: errors.currentRent,
        helperText: errors.currentRent ? "Required" : "",
      },
      {
        label: "Rent Escalation / Computation formula*",
        name: "rentEscalation",
        type: "text",
        value: formDetails.rentEscalation,
        error: errors.rentEscalation,
        helperText: errors.rentEscalation ? "Required" : "",
      },
      {
        label: "Security Deposit (INR)*",
        name: "securityDeposit",
        type: "Number",
        value: formDetails.securityDeposit,
        error: errors.securityDeposit,
        helperText: errors.securityDeposit ? "Required" : "",
      },
      {
        label: "Location Address*",
        name: "locationAddress",
        type: "text",
        value: formDetails.locationAddress,
        error: errors.locationAddress,
        helperText: errors.locationAddress ? "Required" : "",
      },
      {
        label: "Lock in period in Months",
        name: "lockInPeriod",
        type: "Number",
        value: formDetails.lockInPeriod,
        error: errors.lockInPeriod,
        helperText: errors.lockInPeriod ? "Required" : "",
      },
      {
        label: "Lock in expiry date",
        name: "lockInExpiryDate",
        type: "date",
        value: formDetails.lockInExpiryDate,
        error: errors.lockInExpiryDate,
        helperText: errors.lockInExpiryDate ? "Required" : "",
      },
      {
        label: "Renewal clause*",
        name: "renewalClause",
        type: "text",
        value: formDetails.renewalClause,
        error: errors.renewalClause,
        helperText: errors.renewalClause ? "Required" : "",
      },
      {
        label: "Stamped & Registered*",
        name: "stampedAndRegistered",
        placeholder: "Stamped & Registered",
        type: "select",
        value: formDetails.stampedAndRegistered,
        error: errors.stampedAndRegistered,
        options: yesNoOptions,
        helperText: errors.stampedAndRegistered ? "Required" : "",
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.subleaseAgreement,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.operatingAssignment]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },

      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.operatingAssignment,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
  };
  return config;
};

// [validDocumentTypes.leaseAgreement]: [
//     {
//       label: "Lease Type*",
//       placeholder: "Lease Type",
//       name: "leaseType",
//       type: "select",
//       value: formDetails.leaseType,
//       error: errors.leaseType,
//       options: fofoLeaseAgreementLeaseTypes,
//       helperText: errors.leaseType ? "Required" : "",
//     },
//     {
//       label: "Lease Start*",
//       name: "leaseStart",
//       type: "date",
//       value: formDetails.dateOfLeaseInitiation,
//       error: errors.dateOfLeaseInitiation,
//       helperText: errors.dateOfLeaseInitiation ? "Required" : "",
//     },
//     {
//       label: "Lease End*",
//       name: "leaseEnd",
//       type: "date",
//       value: formDetails.dateOfLeaseEnd,
//       error: errors.dateOfLeaseEnd,
//       helperText: errors.dateOfLeaseEnd ? "Required" : "",
//     },
//     {
//       label: "Monthly Rent - MG - Start year*",
//       name: "monthlyRentMGStartYear",
//       type: "number",
//       value: formDetails.monthlyRentMGStartYear,
//       error: errors.monthlyRentMGStartYear,
//       helperText: errors.monthlyRentMGStartYear ? "Required" : "",
//     },
//     {
//       label: "Monthly Rent -  Revenue Share on Dine in - Start year*",
//       name: "monthlyRentRevenueShareOnDineInStartYear",
//       type: "number",
//       value: formDetails.monthlyRentRevenueShareOnDineInStartYear,
//       error: errors.monthlyRentRevenueShareOnDineInStartYear,
//       helperText: errors.monthlyRentRevenueShareOnDineInStartYear
//         ? "Required"
//         : "",
//     },
//     {
//       label: "Monthly Rent -  Revenue Share on Delivery - Start year*",
//       name: "monthlyRentRevenueShareOnDeliveryStartYear",
//       type: "number",
//       value: formDetails.monthlyRentRevenueShareOnDeliveryStartYear,
//       error: errors.monthlyRentRevenueShareOnDeliveryStartYear,
//       helperText: errors.monthlyRentRevenueShareOnDeliveryStartYear
//         ? "Required"
//         : "",
//     },
//     {
//       label: "Escalation Clause -Brief Commentry*",
//       name: "escalationClause",
//       type: "text",
//       value: formDetails.escalationClause,
//       error: errors.escalationClause,
//       helperText: errors.escalationClause ? "Required" : "",
//     },
//     {
//       label: "Escalation Clause -Estimated YoY increase(%)",
//       name: "escalationClauseYOYpercentageIncrease",
//       type: "number",
//       value: formDetails.escalationClauseYOYpercentageIncrease,
//       error: errors.escalationClauseYOYpercentageIncrease,
//       helperText: errors.escalationClauseYOYpercentageIncrease
//         ? "Required"
//         : "",
//     },
//     {
//       label: "Franchise Name/Enity(operating the restaurant) Name*",
//       name: "franchiseName",
//       type: "text",
//       value: formDetails.franchiseName,
//       error: errors.franchiseName,
//       helperText: errors.franchiseName ? "Required" : "",
//     },
//     {
//       label: "Franchise GST*",
//       name: "franchiseGst",
//       type: "text",
//       value: formDetails.franchiseGst,
//       error: errors.franchiseGst,
//       helperText: errors.franchiseGst ? "Required" : "",
//     },
//     {
//       label: "Franchise PAN*",
//       name: "franchisePan",
//       type: "text",
//       value: formDetails.franchisePan,
//       error: errors.franchisePan,
//       helperText: errors.franchisePan ? "Required" : "",
//     },
//     {
//       label: "Landlord*",
//       name: "landlordName",
//       subForm: [
//         {
//           placeholder: "Landlord Name",
//           label: "Landlord Name/Company",
//           name: "landlordName",
//           type: "text",
//           value: formDetails.landlordName,
//           error: errors.landlordName,
//           helperText: errors.landlordName ? "Required" : "",
//         },
//         {
//           placeholder: "Landlord GST",
//           label: "Landlord GST*",
//           name: "landlordGst",
//           type: "text",
//           value: formDetails.landlordGst,
//           error: errors.landlordGst,
//           helperText: errors.landlordGst ? "Required" : "",
//         },
//         {
//           placeholder: "Landlord PAN",
//           label: "Landlord PAN*",
//           name: "landlordPan",
//           type: "text",
//           value: formDetails.landlordPan,
//           error: errors.landlordPan,
//           helperText: errors.landlordPan ? "Required" : "",
//         },
//       ],
//     },
//     {
//       label: "Lease Address*",
//       name: "leasedAddress",
//       subForm: [
//         {
//           name: "leasedAddress",
//           placeholder: "Leased Address",
//           type: "text",
//           value: formDetails.leasedAddress,
//           error: errors.leasedAddress,
//           helperText: errors.leasedAddress ? "Required" : "",
//         },
//         {
//           label: "Lease Country*",
//           placeholder: "Leased Country",
//           name: "leasedCountry",
//           type: "select",
//           value: formDetails.leasedCountry,
//           error: errors.leasedCountry,
//           options: COUNTRY,
//           helperText: errors.leasedCountry ? "Required" : "",
//         },
//         {
//           label: "Lease State*",
//           name: "leasedState",
//           placeholder: "Leased State",
//           type: "select",
//           value: formDetails.leasedState,
//           error: errors.leasedState,
//           options: states,
//           helperText: errors.leasedState ? "Required" : "",
//         },
//         {
//           label: "Lease City*",
//           name: "leasedCity",
//           placeholder: "Leased City",
//           type: "select",
//           value: formDetails.leasedCity,
//           error: errors.leasedCity,
//           options: cities,
//           helperText: errors.leasedCity ? "Required" : "",
//         },
//         {
//           placeholder: "Leased Pincode",
//           label: "Lease Pincode*",
//           name: "leasedPincode",
//           type: "number",
//           value: formDetails.leasedPincode,
//           error: errors.leasedPincode,
//           helperText: errors.leasedPincode ? "Required" : "",
//         },
//       ],
//     },
//     {
//       label: "Upload Document",
//       name: "docs",
//       type: "upload",
//       value: formDetails.leaseAgreementUpload,
//       error: errors.leaseAgreementUpload,
//       helperText: errors.leaseAgreementUpload ? "Required" : "",
//       documentSavingKey: "document",
//       uploadType: `${validDocumentTypes.leaseAgreement}`,
//     },
//   ],
//   [validDocumentTypes.faAgreement]: [
//     {
//       label: "Primary Owner Name*",
//       name: "primaryOwnerName",
//       type: "text",
//       value: formDetails.primaryOwnerName,
//       error: errors.primaryOwnerName,
//       helperText: errors.primaryOwnerName ? "Required" : "",
//     },
//     {
//       label: "FA Start Date",
//       name: "faStartDate",
//       type: "date",
//       value: formDetails.faStartDate,
//       error: errors.faStartDate,
//       helperText: errors.faStartDate ? "Required" : "",
//     },
//     {
//       label: "FA End Date",
//       name: "faEndDate",
//       type: "date",
//       value: formDetails.faEndDate,
//       error: errors.faEndDate,
//       helperText: errors.faEndDate ? "Required" : "",
//     },
//     {
//       label: "Upload Document",
//       name: "docs",
//       type: "upload",
//       value: formDetails.faAgreement,
//       error: errors.faAgreement,
//       helperText: errors.faAgreement ? "Required" : "",
//       documentSavingKey: "document",
//       uploadType: `${validDocumentTypes.faAgreement}`,
//     },
//   ],
