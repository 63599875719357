import React, { useState, useEffect } from 'react';
import { INTRANETHOSTNAME } from '../../packages/common-utilities/utils/end-points';
import { REST_URLS } from '../../packages/common-utilities/utils/end-points/intranet';
import { HTTP_METHODS, invokeApi } from '../../utils/http-service';
import { useNavigate } from 'react-router-dom';
import {
  clearOfflineData,
  clearTokenDataAndRedirectToLogin,
  getOfflineData
} from '../../utils/offline-services';
const Intranetlogout = navigate => {
  const refreshToken = getOfflineData('tokens')?.refresh?.token || '';
  const accessToken = getOfflineData('tokens')?.access?.token || '';
  invokeApi(HTTP_METHODS.POST, `${INTRANETHOSTNAME}${REST_URLS.LOGOUT}`, {
    refreshToken,
    accessToken
  })
    .then(() => {
      clearTokenDataAndRedirectToLogin(navigate);
    })
    .catch(err => {
      clearTokenDataAndRedirectToLogin(navigate);
    });
};
export default Intranetlogout;

export const logoutApplication = navigate => {
  const refreshToken = getOfflineData('tokens')?.refresh?.token || '';
  const accessToken = getOfflineData('tokens')?.access?.token || '';
  invokeApi(HTTP_METHODS.POST, `${INTRANETHOSTNAME}${REST_URLS.LOGOUT}`, {
    refreshToken,
    accessToken
  })
    .then(() => {
      clearTokenDataAndRedirectToLogin(navigate);
    })
    .catch(err => {
      clearTokenDataAndRedirectToLogin(navigate);
    });
};
