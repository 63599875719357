import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import { toast } from "react-toastify";
import { getOfflineData } from "../../utils/offline-services";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getHeaderConfig } from "./config";
import CustomModal from "../../../common-utilities/core/modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ViewCertificate from "./view-certificate";
import CertificateWarning from "./certificate-warning";

const CashCertificate = () => {
  const [totalDenomination, setTotalDenomination] = useState(0);
  const [storeDetails, setStoreDetails] = useState({});
  const [files, setFiles] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [tableData, setTableData] = useState([]);
  const [showFiles, setShowFiles] = useState({ isShow: false, data: [] });
  const [viewCrt, setViewCrt] = useState({ isShow: false, data: [] });
  const [certificateDetails, setCertificateDetails] = useState({
    cashAtBank: 0,
    cashAtLocation: 0,
    physicalBalance: 0,
    remarks: "",
    certificationDate: moment().subtract(1, "month").endOf("month"),
  });
  const [denominations, setDenominations] = useState([
    { denomination: 1, quantity: 0 },
    { denomination: 2, quantity: 0 },
    { denomination: 5, quantity: 0 },
    { denomination: 10, quantity: 0 },
    { denomination: 20, quantity: 0 },
    { denomination: 50, quantity: 0 },
    { denomination: 100, quantity: 0 },
    { denomination: 200, quantity: 0 },
    { denomination: 500, quantity: 0 },
    { denomination: 2000, quantity: 0 },
  ]);
  const [isDateEndCertificate, setIsDateEndCertificate] = useState(true);
  const userData = getOfflineData("user");
  const handleQuantityChange = (index, event) => {
    const { value } = event.target;
    const updatedDenominations = [...denominations];
    updatedDenominations[index].quantity = parseInt(value);
    setDenominations(updatedDenominations);
  };
  const getStoreDetails = () => {
    const store = userData?.userId?.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      {
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      }
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const handleDateChange = (name) => (date) => {
    setCertificateDetails({
      ...certificateDetails,
      [name]: moment(date).toDate(),
    });
  };
  const viewfilesHandler = (data, type) => {
    switch (type) {
      case "view files":
        setShowFiles({ isShow: true, data: data?.attachments });
        break;
      case "view certificate":
        setViewCrt({ isShow: true, data: data });
        break;
      default:
        break;
    }
  };
  const loadData = (filters) => {
    const payload = { ...filters };

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.CASH_CERTIFICATE}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => ({
            ...ele,
          }));
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createCashCertificateHandler = () => {
    const payload = {
      cashAtLocation: certificateDetails.cashAtLocation,
      remarks: certificateDetails.remarks,
      certificationDate: certificateDetails.certificationDate,
      currentStorePhysicalBalance:
        storeDetails?.properties?.pettyExpense?.physicalBalance,
      currentStoreBookBalance:
        storeDetails?.properties?.pettyExpense?.bookBalance,
    };
    if (certificateDetails.cashAtBank) {
      payload["cashAtBank"] = certificateDetails.cashAtBank;
    }
    if (certificateDetails.physicalBalance) {
      payload["physicalBalance"] = certificateDetails.physicalBalance;
    }
    if (files.length > 0) {
      payload["attachments"] = files;
    }
    const filteredData = denominations.filter(
      (item) =>
        item.quantity !== 0 && !isNaN(item.quantity) && item.quantity !== null
    );
    if (filteredData.length > 0) {
      payload["cashDenominations"] = filteredData;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CASH_CERTIFICATE}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Cash Certificate Created Successfully", {
            autoClose: 2000,
          });
          setCertificateDetails({
            cashAtBank: 0,
            cashAtLocation: 0,
            physicalBalance: 0,
            remarks: "",
            certificationDate: moment().subtract(1, "month").endOf("month"),
          });
          setFiles([]);
          setDenominations([
            { denomination: 1, quantity: 0 },
            { denomination: 2, quantity: 0 },
            { denomination: 5, quantity: 0 },
            { denomination: 10, quantity: 0 },
            { denomination: 20, quantity: 0 },
            { denomination: 50, quantity: 0 },
            { denomination: 100, quantity: 0 },
            { denomination: 200, quantity: 0 },
            { denomination: 500, quantity: 0 },
            { denomination: 2000, quantity: 0 },
          ]);
          if (!isDateEndCertificate) {
            window.location.reload();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const checkCertificate = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CHECK_CERTIFICATE}`)
      .then((res) => {
        if (res) {
          setIsDateEndCertificate(true);
        } else {
          setIsDateEndCertificate(false);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let totalAmount = 0;
    denominations.forEach((denomination) => {
      totalAmount += isNaN(denomination.denomination * denomination.quantity)
        ? 0
        : denomination.denomination * denomination.quantity;
    });
    setTotalDenomination(totalAmount);
    setCertificateDetails((prevVal) => ({
      ...prevVal,
      cashAtLocation: totalAmount,
    }));
  }, [denominations]);
  useEffect(() => {
    let physicalBalanceAtLocation;

    physicalBalanceAtLocation = isNaN(certificateDetails.cashAtBank)
      ? 0 + certificateDetails.cashAtLocation
      : certificateDetails.cashAtBank + certificateDetails.cashAtLocation;

    setCertificateDetails((prevVal) => ({
      ...prevVal,
      physicalBalance: physicalBalanceAtLocation,
    }));
  }, [certificateDetails.cashAtBank, certificateDetails.cashAtLocation]);
  useEffect(() => {
    getStoreDetails();
  }, []);
  useEffect(() => {
    loadData(filters);
  }, []);
  useEffect(() => {
    checkCertificate();
  }, []);
  return (
    <div className="cash-certificate">
      {!isDateEndCertificate && <CertificateWarning />}
      {isCreate && !viewCrt.isShow ? (
        <div>
          <Button
            onClick={() => {
              setIsCreate(false);
              loadData(filters);
            }}
            size="small"
            variant="outlined"
            sx={{ mr: 2, float: "right", mb: 0.2 }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={createCashCertificateHandler}
            disabled={
              !storeDetails.id ||
              // !certificateDetails.cashAtLocation ||
              !certificateDetails.remarks ||
              !certificateDetails.certificationDate ||
              files.length < 1
                ? true
                : false
            }
            sx={{ float: "right", mb: 0.2, mr: 1 }}
          >
            Submit
          </Button>
          <Grid container md={12} spacing={2}>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Card>
                <CardContent className="certified-date">
                  <TextField
                    label="Store"
                    value={storeDetails.name}
                    disabled
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Certificated On*"
                      inputFormat="DD/MM/YYYY"
                      value={certificateDetails?.certificationDate || null}
                      name="date"
                      onChange={handleDateChange("certificationDate")}
                      renderInput={(params) => (
                        <TextField size="small" {...params} sx={{ ml: 3 }} />
                      )}
                    />
                  </LocalizationProvider>
                </CardContent>
              </Card>
              <Card className="balance">
                <CardContent>
                  <h5>Balance</h5>
                  <div className="balance-con">
                    <div>
                      <div className="item-div">
                        <p className="div-label">Book Balance</p>
                        <p>
                          <span>&#8377;</span>
                          {(
                            storeDetails?.properties?.pettyExpense
                              ?.bookBalance || 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="item-div">
                        <p className="div-label">In Process Amount</p>
                        <p>
                          <span>&#8377;</span>
                          {isNaN(
                            storeDetails?.properties?.pettyExpense
                              ?.bookBalance -
                              storeDetails?.properties?.pettyExpense
                                ?.physicalBalance
                          )
                            ? 0
                            : (
                                storeDetails?.properties?.pettyExpense
                                  ?.bookBalance -
                                  storeDetails?.properties?.pettyExpense
                                    ?.physicalBalance || 0
                              ).toFixed(2)}
                        </p>
                      </div>
                      <div className="item-div">
                        <p className="div-label">
                          Physical Balance (As per System)
                        </p>
                        <p>
                          <span>&#8377;</span>
                          {(
                            storeDetails?.properties?.pettyExpense
                              ?.physicalBalance || 0
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="balance-con">
                    <div>
                      <div className="item-div">
                        <p className="div-label">Cash At Bank</p>
                        <TextField
                          size="small"
                          value={certificateDetails?.cashAtBank}
                          sx={{
                            "& input": {
                              height: "10px",
                              width: "40px",
                            },
                            mt: 1,
                          }}
                          type="number"
                          onChange={(e) => {
                            setCertificateDetails((prevVal) => ({
                              ...prevVal,
                              cashAtBank: parseInt(e.target.value),
                            }));
                          }}
                        />
                      </div>
                      <div className="item-div">
                        <p className="div-label">Cash At Location</p>
                        <p>
                          <span>&#8377;</span>
                          {certificateDetails.cashAtLocation?.toFixed(2)}
                        </p>
                      </div>
                      <div className="item-div">
                        <p className="div-label">
                          Physical Balance (Available at Location)
                        </p>
                        <p>
                          <span>&#8377;</span>
                          {certificateDetails.physicalBalance?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="balance-con">
                    <div>
                      <div className="item-div remove-border ">
                        <p className="div-label">Difference</p>
                        <p>
                          <span>&#8377;</span>
                          {(
                            storeDetails?.properties?.pettyExpense
                              ?.physicalBalance -
                              certificateDetails.physicalBalance || 0
                          )?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <h4>Remarks</h4>
                  <TextareaAutosize
                    className="new-textfield"
                    minRows={4}
                    placeholder="Remarks"
                    required
                    style={{ width: "100%" }}
                    value={certificateDetails.remarks}
                    onChange={(e) => {
                      setCertificateDetails((prevVal) => ({
                        ...prevVal,
                        remarks: e.target.value,
                      }));
                    }}
                  />

                  <Grid md={12} item sx={{ mt: 2 }}>
                    <SubwayFileUpload
                      title="Attachment"
                      url={`${HOSTNAME}${REST_URLS.UPLOAD}`}
                      tokenKey={tokenMainKeys.pettyExpense}
                      slug={appSlugs.pettyExpense}
                      callBack={(newFile) => {
                        setFiles([
                          ...files,
                          {
                            url: newFile[0].url,
                            title: newFile[0].originalname,
                          },
                        ]);
                      }}
                      providedLimit={15728640}
                      lmtMsg="File size cannot be more than 15 MB"
                    />
                    {files.length > 0 && (
                      <div className="file-con">
                        {files.map((ele) => {
                          return <div className="file-name">{ele?.title}</div>;
                        })}
                      </div>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card className="store-details">
                <CardContent>
                  <div>
                    <h4>Imprest Details</h4>
                    <div className="details-row">
                      <h6>Format</h6>
                      <h5>{storeDetails?.subFormat}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Location Code</h6>
                      <h5>{storeDetails?.pinCode}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Name</h6>
                      <h5>{storeDetails?.restaurantName}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Region</h6>
                      <h5>{storeDetails?.state}</h5>
                    </div>
                    <div className="details-row">
                      <h6>Currency</h6>
                      <h5>INR</h5>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="denomination">
                <CardContent>
                  <h5>Cash Denomination</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>DENOMINATION</th>
                        <th></th>
                        <th>QUANTITY</th>
                        <th></th>
                        <th>AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {denominations.map((denomination, index) => (
                        <tr key={index}>
                          <td>{denomination.denomination}</td>
                          <td>*</td>
                          <td>
                            <TextField
                              size="small"
                              type="number"
                              sx={{
                                "& input": {
                                  height: "10px",
                                  width: "80px",
                                },
                                mt: 1,
                              }}
                              value={denomination.quantity}
                              onChange={(event) =>
                                handleQuantityChange(index, event)
                              }
                            />
                          </td>
                          <td>=</td>
                          <td>
                            <TextField
                              size="small"
                              type="number"
                              disabled
                              sx={{
                                "& input": {
                                  height: "10px",
                                  width: "80px",
                                },
                                mt: 1,
                              }}
                              value={
                                isNaN(
                                  denomination.denomination *
                                    denomination.quantity
                                )
                                  ? 0
                                  : denomination.denomination *
                                    denomination.quantity
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="total-amount">
                    <p>Total Amount</p>

                    <p>
                      <span>&#8377;</span>
                      {totalDenomination?.toFixed(2)}
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : viewCrt.isShow && !isCreate ? (
        <ViewCertificate
          data={viewCrt.data}
          setViewCrt={setViewCrt}
          storeDetails={storeDetails}
        />
      ) : (
        <div>
          <div>
            <div className="imprest-btn-con">
              <Button
                onClick={() => {
                  setIsCreate(true);
                }}
                size="small"
                variant="outlined"
                sx={{ float: "right", mb: 1 }}
              >
                Cash Certificate
              </Button>
            </div>
            <Grid container md={12}></Grid>

            <MuiTable
              columnsList={getHeaderConfig()}
              dataList={tableData?.results || []}
              filters={filters}
              pageCount={tableData?.totalPages}
              onClick={viewfilesHandler}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
                loadData(
                  {
                    ...filters,
                    page,
                  },
                  true
                );
              }}
            ></MuiTable>
            {showFiles?.isShow && (
              <CustomModal
                title="Files"
                onClose={() => {
                  setShowFiles({ isShow: false, data: [] });
                }}
              >
                {showFiles?.data.length > 0 ? (
                  showFiles.data.map((item, index) => {
                    return (
                      <div className="downloadgrid" key={index}>
                        <div>{item?.title}</div>
                        <div>
                          <a target="_blank" download={true} href={item?.url}>
                            <DownloadForOfflineIcon color="action" />
                          </a>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No Files Attached</p>
                )}
              </CustomModal>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CashCertificate;
