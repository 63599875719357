/** @format */

import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Typography from "@mui/material/Typography";

const getIcon = (type) => {
  switch (type) {
    case "LOI":
      return <BookmarkAddedIcon />;
    case "LEASE_AGREEMENT":
      return <BusinessCenterIcon />;
    case "LICENSES":
      return <SwapHorizontalCircleIcon />;
    case "ACQUISITION":
      return <CancelPresentationIcon />;
    case "NEW_STORE_APPROVAL":
      return <BusinessCenterIcon />;
    case "basic-details":
      return <BusinessCenterIcon />;
  }
};

const getDate = (type, details = {}) => {
  switch (type) {
    case "LOI":
      return `LOI initiation on ${new Date(
        details.leaseStart
      ).toLocaleDateString()}`;
    case "LEASE_AGREEMENT":
      return `Lease initiation on  ${new Date(
        details.leaseStart
      ).toLocaleDateString()}`;
    case "LICENSES":
      return `Starts on ${new Date(details.startDate).toLocaleDateString()}`;
    case "ACQUISITION":
      return `Termination on ${new Date(
        details.dateOfTermination
      ).toLocaleDateString()}`;
  }
};

const getDetails = (data = {}) => {
  const { details, type } = data;
  switch (type) {
    case "LOI":
      return (
        <>
          <Typography>{details.type}</Typography>
          <Typography>
            <span>Store Type : </span>
            {data.storeType}
          </Typography>
        </>
      );
    case "LEASE_AGREEMENT":
      return (
        <>
          <Typography>{details.type}</Typography>
          <Typography>
            <span>Store Type : </span>
            {data.storeType}
          </Typography>
        </>
      );
    case "LICENSES":
      return (
        <>
          <Typography>{details.type}</Typography>
          <Typography>
            <span>Store Type : </span>
            {data.storeType}
          </Typography>
          <Typography>
            <span>License Type : </span>
            {data.details.licenseType}
          </Typography>
        </>
      );

    case "ACQUISITION":
      return (
        <>
          <Typography>{details.type}</Typography>
          <Typography>
            <span>Store Type : </span>
            {data.storeType}
          </Typography>
        </>
      );
    case "NEW_STORE_APPROVAL":
      return (
        <>
          <Typography> Email: {details.CFApprovalEmail}</Typography>
          <Typography>
            <span>Store Type : </span>
            {data.storeType}
          </Typography>
          {details.CFApprovalmailattachments.map((doc, index) => {
            return (
              <Typography>
                <span>
                  {index + 1}.{" "}
                  <a target="_blank" href={doc.document}>
                    {doc.originalName}
                    <br />
                  </a>
                </span>
              </Typography>
            );
          })}
        </>
      );
    default:
      <Typography>{details.type}</Typography>;
  }
};

export default function CustomizedTimeline({
  data,
  hasMore,
  setPages,
  setRefresh,
}) {
  const observer = React.useRef();

  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );
  return (
    <Timeline position="alternate">
      {data.map((record, index) => {
        const { details = {}, type } = record.details;
        const { docs = [], documentType } = details;
        if (index + 1 === data.length && hasMore) {
          return (
            <div
              ref={lastItemRef}
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            ></div>
          );
        } else {
          return (
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography component="span">
                  {new Date(record.createdAt).toLocaleDateString()}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  {getIcon(record.details.type)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {record.type !== "basic-details"
                    ? record.details.type.replaceAll("_", " ")
                    : record.details.name}
                </Typography>
                <p>{documentType ? documentType : ""}</p>
                {getDetails(record.details)}
                {type !== "ACQUISITION" && (
                  <Typography component="span">
                    {getDate(type, details)}
                  </Typography>
                )}

                <Typography>
                  {docs.map((doc, index) => {
                    return (
                      <div>
                        {type === "ACQUISITION" && (
                          <Typography>{doc.type}</Typography>
                        )}
                        <span>
                          {index + 1}.{" "}
                          <a target="_blank" href={doc.document}>
                            {doc.originalName}
                            <br />
                          </a>
                        </span>
                      </div>
                    );
                  })}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        }
      })}
    </Timeline>
  );
}
