import { exportToExcel } from '../../../../../utils';
import { getPurchaseOrderAmount } from '../../purchase-order/helper';

export const downloadReports = (data) => {
  if (!data.length) {
    return;
  }

  const results = data.map((item) => {
    const document = item?.document || {};
    const poId = document?.poId || {};
    const items = poId?.items || [];
    const site = item.site

    return {
      'GRN NO': document?.grnNo || null,
      'PO Number': document?.poNumber || null,
      'Site Code': site?.code || null,
      'Site Name': site?.name || null,
      'Vendor Code': document?.vendorCode || null,
      'Vendor Name': poId?.vendorName || null,
      'Pending With': item?.pendingWith || null,
      'Total PO Value': getPurchaseOrderAmount(items),
      'Total Invoice Value': document?.hasOwnProperty?.('invoiceAmount')
        ? Math.round(document.invoiceAmount).toFixed(2)
        : null,
    };
  });
  exportToExcel(results, "pending_grn_actions");
};
