import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TableCell from "@mui/material/TableCell";
import { dateFun } from "../../../common-utilities/utils";
import { Chip, Box, Checkbox } from "@mui/material";
import { getOfflineData } from "../../utils/offline-services";
const user = getOfflineData("user");
export const getPendingVoucherHeaderConfig = (
  type,
  handleRowSelection,
  selectedVouchersList,
  dataList,
  isApproveAll
) => {
  const list = [
    {
      label: "Store",
      key: "store",
      render: (data) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {data?.storeDetails?.name}
            {data?.storeDetails?.restaurantName &&
              " -" + data.storeDetails.restaurantName}
          </div>
        );
      },
    },
    {
      label: "Id",
      key: "voucherId",
      render: (data, onClick, index) => {
        return (
          <div
            onClick={() => {
              onClick(data, "view voucher");
            }}
            style={{ cursor: "pointer", color: "darkblue" }}
          >
            {data?.voucherId}
          </div>
        );
      },
    },
    {
      label: "Assigned To",
      key: "assignedTo",
      render: (data, onClick, index) => {
        return <div>{data?.assignedTo}</div>;
      },
    },
    {
      label: "Voucher Date",
      key: "voucherDate",
      render: (data, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {dateFun(data?.voucherDate, true)}
          </div>
        );
      },
    },
    {
      label: "Expense Period From",
      key: "expensePeriodFrom",
      render: (data, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {dateFun(data?.expensePeriodFrom, true)}
          </div>
        );
      },
    },
    {
      label: "Expense Period To",
      key: "expensePeriodTo",
      render: (data, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {dateFun(data?.expensePeriodTo, true)}
          </div>
        );
      },
    },
    {
      label: "Bill Date",
      key: "billDate",
      render: (data, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {dateFun(data?.billDate, true)}
          </div>
        );
      },
    },
    {
      label: "Vendor",
      key: "vendor.name",
      render: (data, index) => {
        return <div>{data?.vendor?.name}</div>;
      },
    },
    {
      label: "Amount",
      key: "amount",
      render: (data, index) => {
        return <div>{data?.amount}</div>;
      },
    },
    {
      label: "Remarks",
      key: "remarks",
      render: (data, index) => {
        return <div>{data?.remarks}</div>;
      },
    },

    {
      label: "View files",
      key: "view files",
      render: (data, onClick, index) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "view files")}
            sx={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];
  if (type !== "draft") {
    list.push({
      label: "View Comments",
      key: "view comments",
      render: (data, onClick, index) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "view comments")}
            sx={{ cursor: "pointer" }}
          />
        );
      },
    });
  }
  if (type === "pending") {
    list.unshift({
      id: "checkbox",
      label: (
        <Checkbox
          onChange={(event) => handleRowSelection(event, null, "selectAll")}
          checked={
            selectedVouchersList.length === dataList.length &&
            dataList.length !== 0
          }
        />
      ),
      sortable: false,
      render: (data, index) => {
        return (
          <Checkbox
            checked={
              selectedVouchersList.find(
                (ele) => ele.voucherId === data.voucherId
              )
                ? true
                : false
            }
            onChange={(event) => handleRowSelection(event, data)}
          />
        );
      },
    });
    list.push({
      label: "Actions",
      key: "actions",
      render: (data, onClick, index) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              label="Approved"
              component="span"
              variant="outlined"
              size="small"
              color="success"
              disabled={isApproveAll}
              sx={{ mr: 2 }}
              onClick={() => onClick(data, "Approved")}
            />
            <Chip
              label="Reconsider"
              component="span"
              variant="outlined"
              size="small"
              disabled={isApproveAll}
              color="error"
              onClick={() => onClick(data, "Reconsider")}
            />
          </Box>
        );
      },
    });
  }
  if (type === "draft") {
    list.push({
      label: "Actions",
      key: "actions",
      render: (data, onClick, index) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              label="Process"
              component="span"
              variant="outlined"
              size="small"
              color="success"
              sx={{ mr: 2 }}
              onClick={() => onClick(data, "process")}
            />
            <Chip
              label="Reject"
              component="span"
              variant="outlined"
              size="small"
              color="error"
              onClick={() => onClick(data, "reject")}
            />
          </Box>
        );
      },
    });
  }
  if (type === "reconsider" && user.role === "STORE") {
    list.push({
      label: "Actions",
      key: "actions",
      render: (data, onClick, index) => {
        return (
          <>
            <Chip
              label="Reject"
              component="span"
              variant="outlined"
              size="small"
              color="error"
              onClick={() => onClick(data, "reject")}
            />
          </>
        );
      },
    });
  }
  return list;
};
