export const setOfflineData = (key, data, tokenKey) => {
  if (data !== "" && data !== null && data !== undefined) {
    localStorage.setItem(
      `${tokenKey ? tokenKey : "subway-invoice-tracking"}:${key}`,
      JSON.stringify(data)
    );
  }
};

export const getOfflineData = (key, tokenKey) => {
  const data = localStorage.getItem(
    `${tokenKey ? tokenKey : "subway-invoice-tracking"}:${key}`
  );
  if (data !== "" && data !== null && data !== undefined) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const clearOfflineData = (key, tokenKey) => {
  localStorage.removeItem(
    `${tokenKey ? tokenKey : "subway-invoice-tracking"}:${key}`
  );
};
