export const getNestedPathValue = (key, obj) => {
  if (!key || !obj || typeof key !== 'string' || typeof obj !== 'object') {
    return;
  }
  const splittedPath = key.split('.');
  const getVal = (path, index, obj) => {
    const val = obj?.[path[index]];
    if (index === path.length - 1) {
      return val;
    }
    return getVal(path, index + 1, val);
  };

  return getVal(splittedPath, 0, obj);
};

export const lakhConversion = (value, convertToLakh = true, toDigit) => {
  let finalValue;

  if (convertToLakh) {
    finalValue = value * 100000;
  } else {
    finalValue = value / 100000;
  }
  if (!finalValue) {
    return value;
  }
  if (toDigit) {
    return finalValue.toFixed(2);
  }
  return finalValue;
};

export function truncateWords(text, maxWords) {
  const words = text?.split(' ');
  if (words.length <= maxWords) {
    return text;
  }
  return words.slice(0, maxWords).join(' ') + '...';
}
