import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';
import { formatDate } from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../common-utilities/utils/constants';
import { toast } from 'react-toastify';

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: 'Id',
      id: 'id',
    },
    {
      label: 'Store Status',
      id: 'status',
    },

    {
      label: 'Creation Date',
      id: 'createdAt',
      valueConversionLogic: (val) =>
        formatDate(val, DATE_FORMATS['DD-MM-YYYY']),
    },
    {
      label: 'Location Name',
      id: 'name',
    },
    {
      label: 'Location Code',
      id: 'code',
    },
    {
      label: 'Location Type',
      id: 'locationType',
    },
    {
      label: 'Location Address',
      id: 'address',
    },
    {
      label: 'GSTIN',
      id: 'gstNumber',
    },
    {
      label: 'City',
      id: 'city',
    },
    {
      label: 'State',
      id: 'state',
    },
    {
      label: 'Store Email',
      id: 'email',
    },
    {
      label: 'Store Name',
      id: 'storeName',
    },
    {
      label: 'Store Number',
      id: 'storeNumber',
    },
    {
      label: 'Actions',
      id: 'actions',
      nonDownloadHeader: true,
      render: (data) => {
        return (
          <>
            <Button
              disabled={!isUpdateAllowed('master_data')}
              variant="text"
              onClick={() => {
                if (data?.storeId) {
                  toast.info(
                    'Store Location data cannot be updated from Location Master',
                    {
                      autoClose: 2000,
                    }
                  );
                  return;
                }
                editVendor(data, 'EDIT');
              }}
            >
              Edit
            </Button>
            <Button
              disabled={!isDeleteAllowed('master_data')}
              variant="text"
              onClick={() => {
                if (data?.storeId) {
                  toast.info(
                    'Store Location cannot be deleted from Location Master',
                    {
                      autoClose: 2000,
                    }
                  );
                  return;
                }
                setDeleteDetails(data);
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};
