import { DateRangePicker } from 'rsuite';
import { clearProperty } from '../../utils/misc';

const DateRangeFilter = ({
  startDate,
  endDate,
  cleanable = true,
  startDateKey = 'startDate',
  endDateKey = 'endDate',
  setDateRange,
}) => {
  const onDateRangeChange = (e) => {
    if (!e?.[0]) {
      clearProperty([startDateKey, endDateKey], setDateRange);
      return;
    }
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: e?.[0] ? new Date(`${e[0]}`).toISOString() : null,
      endDate: e?.[1] ? new Date(`${e[1]}`).toISOString() : null,
    }));
  };

  return (
    <DateRangePicker
      cleanable={cleanable}
      placeholder="Select Date Range"
      onChange={onDateRangeChange}
      value={[
        startDate ? new Date(startDate) : null,
        endDate ? new Date(endDate) : null,
      ]}
    />
  );
};

export default DateRangeFilter;
