import { useState, useEffect } from "react";
import { MainTrainingManualFilter } from "../filter";
import { pick } from "../../../utils";
import CommonBtn from "../../../core/common-button";
import { getOfflineData } from "../../../utils/offline-services";
import { modalActions } from "../../constants";
import { downloadReports } from "../apis";
import { actionHandler, setFilterFunc } from "../../utils";
import { CardsTable } from "../cards-table";
import { getAppDocuments, getFieldList } from "../../main-apis";
import CreateUpdateDocument from "../../modals/create-update-document";

export const FirstTab = ({
  appDetails,
  slug,
  closeModal,
  filter,
  setFilter,
  tokenKey,
  modalDetails,
  setModalDetails,
  mainFolderLabel,
  subFolderLabel,
}) => {
  const [trainingManualList, setTrainingManualList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const user = getOfflineData(tokenKey, "user");
  useEffect(() => {
    // Setting main dropdown list
    getFieldList(
      slug,
      { parentNull: true, getAll: true },
      "select",
      setTrainingManualList
    );
  }, []);
  // This is folders list on the basis of training amnual selected
  const [folderList, setFolderList] = useState([]);

  return (
    <>
      {user?.role === "admin" && (
        <div className="end-crt-btn">
          {appDetails?.properties?.document?.allowConfirm &&
            filter.mainFolder && (
              <CommonBtn
                onClick={() => {
                  downloadReports(filter.mainFolder, slug);
                }}
                text="Download Reports"
              />
            )}

          <CommonBtn
            onClick={() => {
              actionHandler(null, modalActions.createDocument, setModalDetails);
            }}
            text="Create Document"
          />
        </div>
      )}
      <MainTrainingManualFilter
        trainingManualList={trainingManualList}
        setFilterOnChange={(e, value) => {
          setFilterFunc(e, value, slug, setFolderList, setFilter, setTableData);
        }}
        mainFolderLabel={mainFolderLabel}
        subFolderLabel={subFolderLabel}
        folderList={folderList}
        filter={filter}
      />

      {filter.mainFolder && (
        <CardsTable
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
          closeModal={closeModal}
          // data={getDataAccordingToLimitAndPageSelectedInFilters(
          //   filter,
          //   tableData
          // )}
          data={tableData}
          filters={filter}
          onChange={(e, page) => {
            setFilter((prevFilter) => ({ ...prevFilter, page }));
          }}
          slug={slug}
          showPdfReader={!!appDetails?.properties?.document?.showPdf}
          tokenKey={tokenKey}
          allowDownload={!!appDetails?.properties?.document?.allowDownload}
          allowConfirm={!!appDetails?.properties?.document?.allowConfirm}
          confirmRoles={appDetails?.properties?.document?.confirmRoles || []}
        />
      )}
      <CreateUpdateDocument
        showModal={
          modalDetails?.type === modalActions.updateDocument ||
          modalDetails?.type === modalActions.createDocument
        }
        data={modalDetails?.data}
        title={modalDetails?.title}
        closeModal={closeModal}
        slug={slug}
        onSuccess={() => {
          const appFilters = pick(filter, ["mainFolder", "subFolder"]);
          if (appFilters.subFolder) {
            getAppDocuments(
              { documentCategory: appFilters.subFolder },
              slug,
              setTableData
            );
          }

          closeModal();
        }}
        allowedFormats={appDetails?.properties?.document?.uploadFormats || []}
        mainFolderLabel={mainFolderLabel}
        subFolderLabel={subFolderLabel}
      />
    </>
  );
};
