import { Button } from '@mui/material';
import { convertToCamelCaseFromUnderScore } from '../../../../common-utilities/utils';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: 'Id',
      id: 'id',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Category',
      id: 'categoryType',
      render: (data) => {
        const { category } = data;
        return category?.name;
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        return (
          <>
            <Button variant="text"  disabled={!isUpdateAllowed('master_data')} onClick={() => editVendor(data, 'EDIT')}>
              Edit
            </Button>
            <Button variant="text" disabled={!isDeleteAllowed('master_data')} onClick={() => setDeleteDetails(data)}>
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};
