//These are the Database Ids
export const APP_IDS = {
  docustorage: 5,
  techCare: 6,
  bdLead: 2,
  cfApproval: 1,
  qNet: 8,
  subs: 9,
};
export const PROD_APP_IDS = {
  docustorage: 5,
  techCare: 6,
  bdLead: 2,
  cfApproval: 1,
  qNet: 8,
  subs: 9,
};
