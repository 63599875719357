import { Button, TextField } from "@mui/material";
import { getGRNOrderItemsAmount } from "../helper";

export const getGRNCreateHeaderConfig = (onInputChange) => {
  const headers = [
    {
      label: "Line Item Name",
      id: "name",
      render: (data) => {
        const { lineItem = {} } = data;
        return lineItem && lineItem.name;
      },
    },
    {
      label: "Item Name",
      id: "name",
      render: (data) => {
        const { item = {} } = data;
        return item && item.name;
      },
    },
    {
      label: "HSN Code",
      id: "hsnCode",
      render: (data) => {
        return (
          <TextField
            sx={{
              maxWidth: 150,
            }}
            value={data?.hsnCode || ""}
            size="small"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length > 8) {
                e.target.value = inputValue.slice(0, 8);
              }
              onInputChange(e, data);
            }}
            name="hsnCode"
          />
        );
      },
    },
    {
      label: "Order quantity",
      id: "quantity",
    },
    {
      label: "Inwarded Quantity",
      id: "inwardedQuantity",
    },
    {
      label: "UOM",
      id: "uom",
    },
    {
      label: "Received Quantity",
      id: "receivedQuantity",
      render: (data) => {
        return (
          <TextField
            sx={{
              maxWidth: 100,
            }}
            value={data.receivedQuantity || ""}
            size="small"
            type="number"
            onChange={(event) => {
              event.persist();

              let quantity = Math.abs(Number(data.quantity));
              let inwardedQuantity = Math.abs(Number(data.inwardedQuantity));
              if (
                data.quantity > 0 &&
                Number(event.target.value) <= 0 &&
                !!event.target.value
              ) {
                return;
              }
              if (
                data.quantity < 0 &&
                Number(event.target.value) >= 0 &&
                !!event.target.value
              ) {
                return;
              }
              if (
                Math.abs(+event.target.value) + Math.abs(inwardedQuantity) >
                quantity
              ) {
                return;
              }
              onInputChange(event, data);
            }}
            name="receivedQuantity"
          />
        );
      },
    },
    {
      label: "Base Amt",
      id: "baseAmount",
      render: (data) => {
        const { receivedQuantity = 0, rate = 0 } = data;
        return receivedQuantity * rate;
      },
    },
    {
      label: "Tax Amt",
      id: "taxAmount",
      render: (data) => {
        const { receivedQuantity = 0, rate, taxRate = 0 } = data;
        return rate * receivedQuantity * (taxRate / 100);
      },
    },
    {
      label: "Total Amt",
      id: "totalAmount",
      render: (data) => {
        const { receivedQuantity = 0, rate, taxRate = 0 } = data;
        const taxAmount = rate * receivedQuantity * (taxRate / 100);
        return receivedQuantity * rate + taxAmount;
      },
    },
    {
      label: "Comments",
      id: "comments",
      render: (data) => {
        return (
          <TextField
            sx={{
              minWidth: 250,
            }}
            value={data.comments}
            size="small"
            onChange={(e) => onInputChange(e, data)}
            name="comments"
          />
        );
      },
    },
  ];
  return headers;
};

export const createPayloadMapper = (
  poDetails,
  grnItems,
  attachments,
  invoiceUrls,
  formDetails
) => {
  const { id } = poDetails;
  let payload = {
    purchaseOrderId: id,
    inwardDate: new Date().toISOString(),
    invoiceDate: formDetails.invoiceDate,
    financialyear: formDetails.financialyear,
    invoiceNumber: formDetails.invoiceNumber,
    invoiceAmount: formDetails.invoiceAmount,
    receivedItems: grnItems
      .filter((i) => i.receivedQuantity)
      .map((item) => {
        return {
          poItemId: item._id,
          receivedQuantity: item.receivedQuantity || 0,
          comments: item.comments || "",
          hsnCode: item?.hsnCode != undefined ? item.hsnCode : "",
        };
      }),
  };

  if (attachments.length > 0) {
    payload.additionalDocUrls = attachments.map((a) => a.docUrl);
  }
  if (invoiceUrls.length > 0) {
    payload.invoiceUrls = invoiceUrls.map((a) => a.docUrl);
  }
  if (formDetails.remarks) {
    payload.remarks = formDetails.remarks;
  }

  if (formDetails.inwardDate) {
    payload.inwardDate = formDetails.inwardDate;
  }

  return payload;
};

export const availableBudgetAmount = (lineItems) => {
  let sum = 0;
  lineItems
    .filter((i) => ["approval_in_progress", "approved"].includes(i.status))
    .forEach((item) => {
      sum = sum + +item.availableAmount;
    });

  return Math.round(sum).toFixed(2);
};

export const getPOLineItemAmount = (lineItems) => {
  let sum = 0;
  lineItems.forEach((item) => {
    sum = sum + getGRNOrderItemsAmount(item);
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNReceivedQuantityBaseAmount = (lineItems) => {
  let sum = 0;
  (lineItems || []).forEach((item) => {
    const { receivedQuantity = 0, rate } = item;
    sum = sum + receivedQuantity * rate;
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNReceivedQuantityAmount = (lineItems) => {
  let sum = 0;
  (lineItems || []).forEach((item) => {
    const { receivedQuantity = 0, rate, taxRate = 0 } = item;
    const taxAmount = rate * receivedQuantity * (taxRate / 100);
    sum = sum + (receivedQuantity * rate + taxAmount);
  });

  // return Math.round(sum).toFixed(2);
  return sum;
};

export const getGRNReceivedQuantityTaxAmount = (lineItems) => {
  let sum = 0;
  (lineItems || []).forEach((item) => {
    const { receivedQuantity = 0, rate, taxRate = 0 } = item;
    const taxAmount = rate * receivedQuantity * (taxRate / 100);
    sum = sum + taxAmount;
  });

  return Math.round(sum).toFixed(2);
};
