import { useEffect, useState } from "react";
import { Pagination, Typography, Drawer, Grid } from "@mui/material";
import PdfView from "./pdfView";
import { convertedData } from "../utils";
import AppDocumentCard from "../document-card";

export const CardsTable = ({
  data,
  pageCount,
  filters,
  onChange,
  setModalDetails,
  closeModal,
  slug,
  showPdfReader = true,
  tokenKey,
  allowDownload,
  allowConfirm,
  confirmRoles,
}) => {
  const [cardListData, setCardListData] = useState([]);
  const [showPdf, setShowpdf] = useState({});
  const [showData, setShowData] = useState(true);
  const [page, setPage] = useState(1);
  const [openSlider, setOpenSlider] = useState(false);
  const screenWidth = window.innerWidth;

  let mdValue = 3;
  if (screenWidth <= 1280) {
    mdValue = 4;
  }
  useEffect(() => {
    setShowData(false);
    if (data) {
      const finalData = convertedData(data);
      setCardListData(finalData);
      setTimeout(() => {
        setShowData(true);
      }, 100);
    }
  }, [data]);
  useEffect(() => {
    if (Object.keys(showPdf).length > 0) {
      setOpenSlider(true);
      return;
    }
    setOpenSlider(false);
  }, [showPdf]);
  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters?.page]);

  return (
    <div className="main-documents-table-container">
      <div>
        <Grid container md={12} spacing={4} sx={{ mt: 2 }}>
          {showData &&
            cardListData.map((item, index) => {
              return (
                <Grid md={mdValue} item>
                  <AppDocumentCard
                    setModalDetails={setModalDetails}
                    appDetails={item}
                    editInfo={setShowpdf}
                    noEditStyle={true}
                    closeModal={closeModal}
                    showPdfReader={showPdfReader}
                    tokenKey={tokenKey}
                    allowDownload={allowDownload}
                    allowConfirm={allowConfirm}
                    confirmRoles={confirmRoles}
                    slug={slug}

                    // downloadOnClick={true}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
      <div>
        {Object.keys(showPdf).length > 0 && (
          <Drawer
            onClose={() => {
              setShowpdf({});
            }}
            anchor="right"
            open={openSlider}
          >
            <div
              className="pdf-preview-section"
              style={{ maxWidth: "80vw", minWidth: "50vw" }}
            >
              {/* <Typography className="title">{showPdf.name}</Typography> */}
              <PdfView
                allowConfirm={allowConfirm}
                confirmRoles={confirmRoles}
                pdf={showPdf?.link}
                pdfDetails={showPdf}
                tokenKey={tokenKey}
                setPdfDetails={setShowpdf}
                slug={slug}
              />
              {pageCount > 1 && (
                <Pagination
                  count={pageCount}
                  color="primary"
                  page={page}
                  onChange={(e, page) => {
                    setPage(page);
                    if (onChange) {
                      onChange(e, page);
                    }
                  }}
                ></Pagination>
              )}
            </div>
          </Drawer>
        )}
      </div>
    </div>
  );
};
