import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import PersonIcon from "@mui/icons-material/Person";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AttributionIcon from "@mui/icons-material/Attribution";
import { subsEndPoint } from "../../../common-utilities/utils/end-points/subway-subs";
export const SIDE_MENU = () => {
  let options = [
    {
      label: "Person",
      url: "/",
      value: "/",
      logo: <PersonIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Store",
      url: "/store",
      value: "/store",
      logo: <StoreIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Store Attributes",
      url: "/store-attributes",
      value: "/store-attributes",
      logo: <AttributionIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Store Insurance",
      url: "/store-insurance",
      value: "/store-insurance",
      logo: <AssuredWorkloadIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Franchise Agreement",
      url: "/franchise-agreement",
      value: "/franchise-agreement",
      logo: <HandshakeIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Lease",
      url: "/lease",
      value: "/lease",
      logo: <ReceiptLongIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Waiver",
      url: "/waiver",
      value: "/waiver",
      logo: <HourglassBottomIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Transfer",
      url: "/transfer",
      value: "/transfer",
      logo: <TransferWithinAStationIcon sx={{ mr: 1 }} />,
    },

    {
      label: "Setup",
      url: "/setup",
      value: "/setup",
      logo: <SettingsIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Reports",
      url: "/reports",
      value: "/reports",
      logo: <ArrowCircleDownIcon sx={{ mr: 1 }} />,
    },
  ];

  options = options.map((item) => {
    const { url } = item;
    item.url = `${subsEndPoint}${url}`;
    return item;
  });

  return options;
};
