import React, { useState } from "react";
import "./index.scss";
import Select from "react-select";
import { memoCategoryModels } from "./config";
import { Button, TextField } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";

const MemoCategory = () => {
  const [data, setData] = useState({});
  const mainCategoryHandler = (e) => {
    setData((prevVal) => ({ ...prevVal, typeModel: e.value }));
  };
  const categoryNameHandler = (e) => {
    setData((prevVal) => ({ ...prevVal, category: e.target.value }));
  };
  const submitHandler = () => {
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`, data)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Created Successfully", { autoClose: 2000 });
          setData({});
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="memo-category-con">
      <div className="g-r-2">
        <Select
          classNamePrefix="custom_select"
          placeholder="Select Category"
          options={memoCategoryModels}
          onChange={mainCategoryHandler}
        />
        {data?.typeModel && (
          <TextField
            className="subs-text-field"
            disabled={!data.typeModel ? true : false}
            label="Category Name"
            variant="outlined"
            onChange={categoryNameHandler}
          />
        )}
      </div>
      <Button
        variant="contained"
        size="small"
        sx={{ margin: "1em", float: "right" }}
        disabled={!data?.typeModel || !data?.category ? true : false}
        onClick={submitHandler}
      >
        Save
      </Button>
    </div>
  );
};

export default MemoCategory;
