import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "../index.scss";
import {
  allvalidDocumentsForTypeofDocumentAndStoreType,
  getAllDocumentsDetailsForStoreTypeAndDocumentType,
  newNames,
  reportLabelKeys,
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import { getLabelsAndValuesForSelect } from "../../../../common-utilities/utils";
import { replaceArrElement } from "../../../../common-utilities/utils/misc";
import { accquiredPreAgreementDocsLabelsAndValues } from "../../../utils/coco-acquired.config";

export const getHeaderConfig = (tableType, storeType, documentType) => {
  const docsList =
    allvalidDocumentsForTypeofDocumentAndStoreType[storeType]?.[documentType] ||
    [];
  const uploadDocumentLabelAndKeys = [...docsList];
  const uploadValues = [...docsList];

  if (
    storeType === validStoreTypes.cocoAcquired &&
    documentType === validDocumentTypes.preAgreementDocs
  ) {
    replaceArrElement(
      uploadDocumentLabelAndKeys,
      accquiredPreAgreementDocsLabelsAndValues.projectFeasibility,
      newNames.tearSheet
    );
    replaceArrElement(
      uploadDocumentLabelAndKeys,
      accquiredPreAgreementDocsLabelsAndValues.licenseFeasibility,
      newNames.taxReport
    );
  }

  const allDocumentKeys = getLabelsAndValuesForSelect(
    uploadDocumentLabelAndKeys,
    uploadValues,
    "id"
  );


  if (
    storeType === validStoreTypes.fofo &&
    documentType === validDocumentTypes.subleaseAgreement
  ) {
    allDocumentKeys[0].label = validDocumentTypes.leaseAgreement
      .split("_")
      .join(" ");
  }

  let informationalDataKeyAndLabels =
    getAllDocumentsDetailsForStoreTypeAndDocumentType(
      storeType,
      documentType,
      "key"
    );
  informationalDataKeyAndLabels = informationalDataKeyAndLabels.map((item) => {
    const finalItem = {
      ...item,
      render: (data) => {
        return (
          <div className="si-cell">{data?.additionalDetails?.[item.key]}</div>
        );
      },
    };
    if (item.render) {
      finalItem.render = item.render;
    }
    return finalItem;
  });
  let commonData = [
    {
      label: "Owner",
      id: "Owner",
      render: (data) => {
        let finalData = data?.restaurantId?.ownerName;
        if (tableType === "Upload Status Reports") {
          finalData = data.Owner;
        }
        if (finalData?.length > 15) {
          finalData = finalData.split("").splice(0, 15).join("") + "...";
        }
        return <div className="si-cell">{finalData}</div>;
      },
    },
  ];

  if (tableType !== reportLabelKeys.informational) {
    commonData = [
      ...commonData,
      {
        label: "Satellite Number",
        id: "satellitenum",
        render: (data) => {
          let finalData = data?.restaurantId?.satelliteNumber;
          if (tableType === "Upload Status Reports") {
            finalData = data["Satellite Number"];
          }
          return <div className="si-cell">{finalData}</div>;
        },
      },
      {
        label: "Sequence Number",
        id: "sequenceNum",
        render: (data) => {
          let finalData = data?.restaurantId?.sequenceNumber;
          if (tableType === "Upload Status Reports") {
            finalData = data["Sequence Number"];
          }
          return <div className="si-cell">{finalData}</div>;
        },
      },
    ];
  }

  commonData = [
    ...commonData,
    {
      label: "Store Number",
      id: "storeNum",
      render: (data) => {
        let finalData = data?.restaurantId?.storeNumber;
        if (tableType === "Upload Status Reports") {
          finalData = data["Store Number"];
        }
        return <div className="si-cell">{finalData}</div>;
      },
    },
  ];

  const showFilesOption = {
    label: "Files",
    id: "files",
    render: (data, onClick) => {
      return (
        <div className="si-cell">
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "View Files")}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    },
  };

  let config = {
    [reportLabelKeys.general]: [
      ...commonData,
      {
        label: "Store Type",
        id: "storeType",
      },
      {
        label: "Type",
        id: "type",
        render: (data) => {
          if (data.type) {
            let type = data?.type?.split?.("_")?.join?.(" ") || "";

            switch (data?.storeType) {
              case validStoreTypes.fofo:
                if (data?.type === validDocumentTypes.subleaseAgreement) {
                  type = validDocumentTypes.leaseAgreement
                    .split?.("_")
                    ?.join?.(" ");
                }
                break;

              default:
                const additionalType = data?.additionalDetails?.licenseType
                  ? ` - ${data?.additionalDetails?.licenseType}`
                  : data?.additionalDetails?.documentType
                  ? ` - ${data?.additionalDetails?.documentType}`
                  : "";
                type += additionalType;
                break;
            }
            if (
              data?.storeType === validStoreTypes.fofo &&
              data?.type === validDocumentTypes.subleaseAgreement
            ) {
            }

            return <div className="si-cell">{type}</div>;
          }
        },
      },
      {
        label: "Details",
        id: "details",
        render: (data, onClick) => {
          return (
            <div className="si-cell">
              <RemoveRedEyeIcon
                onClick={() => onClick(data, "View Details")}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
      },
      showFilesOption,
    ],
    [reportLabelKeys.upload]: [...commonData, ...allDocumentKeys],
    [reportLabelKeys.informational]: [
      ...commonData,
      ...informationalDataKeyAndLabels,
      showFilesOption,
    ],
  };

  if (tableType !== reportLabelKeys.general && (!storeType || !documentType)) {
    return [];
  }

  return config[tableType] || [];
};
