import CustomRadioGroup from "../../../common-utilities/core/radio-group";
import { RESU_RESPONSE_OPTIONS } from "./helper";
import {
    TextField,
  } from "@mui/material";
export const ContactedStoreOption = ({ formDetails, setFormDetails }) => {
  return (
    <>
      <CustomRadioGroup
        title="Contacted"
        options={RESU_RESPONSE_OPTIONS}
        value={formDetails.isContacted || ""}
        onChange={(e) => {
          setFormDetails({
            ...formDetails,
            isContacted: e.target.value,
          });
        }}
      />

      {formDetails.isContacted === "true" ? (
        <>
          <TextField
            label="Contacted Person"
            size="small"
            className="mi-input"
            value={formDetails.contactedPerson || ""}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                contactedPerson: e.target.value,
              });
            }}
          />
          <TextField
            size="small"
            type="date"
            label="Contact Date"
            className="mi-input"
            value={formDetails.contactDate || null}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                contactDate: e.target.value,
              });
            }}
          />
          <TextField
            label="Contact Number"
            size="small"
            className="mi-input"
            value={formDetails.contact}
            onChange={(e) => {
              setFormDetails({
                ...formDetails,
                contact: e.target.value,
              });
            }}
          />
        </>
      ) : null}
    </>
  );
};
