import { Button } from "@mui/material";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import {
  getPurchaseOrderWithoutTaxAmount,
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
} from "../helper";
import { Checkbox } from "@mui/material";

export const getHeaderConfig = (
  editVendor,
  status,
  loadData,
  handleRowSelection,
  selectedPoList,
  dataList
) => {
  const headers = [
    {
      id: "checkbox",
      label: (
        <Checkbox
          onChange={(event) => handleRowSelection(event, null, "selectAll")}
          checked={
            selectedPoList.length === dataList.length && dataList.length !== 0
          }
        />
      ),
      sortable: false,
      render: (data, index) => {
        return (
          <Checkbox
            checked={
              selectedPoList.find((ele) => ele.id === data.id) ? true : false
            }
            onChange={(event) => handleRowSelection(event, data)}
          />
        );
      },
    },
    {
      label: "PO Number",
      id: "poNumber",
      render: (data) => {
        const { document = {} } = data;
        const { poNumber = "" } = document || {};
        return poNumber;
      },
    },
    {
      label: "Site Code & Name",
      id: "siteCode",
      render: (data) => {
        const { site = {} } = data;
        return (site && `${site.code || ""} - ${site.name || ""} `) || "";
      },
    },
    {
      label: "Vendor code",
      id: "vendorCode",
      render: (data) => {
        const { document = {} } = data;
        const { vendorCode = "" } = document || {};
        return vendorCode;
      },
    },
    {
      label: "Vendor Name",
      id: "vendorName",
      render: (data) => {
        const { document = {} } = data;
        const { vendorName = "" } = document || {};
        return vendorName;
      },
    },
    {
      label: "PO Type",
      id: "poType",
      render: (data) => {
        const { document = {} } = data;
        const { poType = "" } = document || {};
        return convertToCamelCaseFromUnderScore(poType);
      },
    },
    {
      label: "Base Amt",
      id: "baseAMount",
      render: (data) => {
        const { document = {} } = data;
        const { items = [] } = document;
        return getPurchaseOrderWithoutTaxAmount(items);
      },
    },
    {
      label: "GST Amt",
      id: "gstAMount",
      render: (data) => {
        const { document = {} } = data;
        const { items = [] } = document;
        return getPurchaseOrderTaxAmount(items);
      },
    },
    {
      label: "Total Amt",
      id: "totalAMount",
      render: (data) => {
        const { document = {} } = data;
        const { items = [] } = document;
        return getPurchaseOrderAmount(items);
      },
    },
    {
      label: "Pending With",
      id: "pendingWith",
    },
    {
      label: "Created By",
      id: "creatorName",
      render: (data) => {
        const { document = {} } = data;
        const { creatorName = "" } = document || {};
        return creatorName;
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <Button variant="text" onClick={() => editVendor(data, "VIEW")}>
            View
          </Button>
        );
      },
    },
  ];
  return headers;
};
