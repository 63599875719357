import { useState } from 'react';
import MuiTable from '../../../../common-utilities/core/mui-table';

import { getHeaderConfig } from './history-header-config';
import { setProperty } from '../../../../common-utilities/utils/misc';
const LineItemCostHistory = ({ lineItemHistory, loadData }) => {
  const [filters, setFilters] = useState({
    page: 1,
  });

  return (
    <>
      <MuiTable
        dataList={lineItemHistory?.results || []}
        columnsList={getHeaderConfig()}
        pageCount={lineItemHistory?.totalPages}
        filters={filters}
        onChange={(page) => {
          setProperty('page', page, setFilters);
          loadData(lineItemHistory.mainData, { page });
        }}
      />
    </>
  );
};
export default LineItemCostHistory;
