import { pick } from '../../../../common-utilities/utils';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS } from '../../../utils/http-service';

export const getModalData = (data, setData) => {
  if (data) {
    const modalData = pick(data, ['name', 'id']);
    setData(modalData);
  }
};

export const modalActions = (data, type, setDataFunc) => {
  switch (type) {
    case 'EDIT':
      setDataFunc({
        type,
        data,
        title: 'Update Currency',
      });
      break;

    case 'CREATE':
      setDataFunc({
        type,
        data: null,
        title: 'Create Currency',
      });
      break;

    case 'DELETE':
      setDataFunc({
        type,
        data,
      });
      break;

    default:
      break;
  }
};

export const getCreateUpdateDetails = (data) => {
  const { id } = data;
  const method = id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const payload = pick(data, ['name']);
  const url = id
    ? `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.FOREIGN_CURRENCY}/${id}`
    : `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.FOREIGN_CURRENCY}`;
  const message = id
    ? 'Currency updated successfully'
    : 'Currency created successfully';
  
  return {
    method,
    payload,
    url,
    message,
  };
};
