import React, { useCallback, useRef } from "react";
import "./index.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
const StickyHeaderCon = ({
  dataList,
  columnsList,
  containerHeight,
  edit,
  hasMore,
  setPages,
  onClick,
  setRefresh,
  maxWords,
  showAllFunc,
}) => {
  let tableHeight = containerHeight
    ? containerHeight
    : "sticky-table-container";
  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => parseInt(pages + 1));
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );
  function truncateWords(text, maxWords) {
    const words = text?.split(" ");
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return (
    <div>
      <TableContainer className={`${tableHeight} con-scroll`}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead stickyHeader>
            <TableRow>
              {columnsList?.map((head) => (
                <TableCell
                  className="table-cell"
                  key={head.id}
                  align="center"
                  padding="none"
                >
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList?.map((ele, index) =>
              index + 1 === dataList.length && hasMore ? (
                <div
                  ref={lastItemRef}
                  style={{
                    textAlign: "center",
                    padding: "10px",
                  }}
                ></div>
              ) : (
                <TableRow
                  key={ele.id ? ele.id : index}
                  style={{ cursor: edit ? "pointer" : "context-menu" }}
                  className="mui-table-row"
                  onClick={() => {
                    if (edit) edit(ele);
                  }}
                >
                  {columnsList?.map((col, index) => {
                    const value = ele[col.id];
                    if (col.render) {
                      return col.render(ele, onClick, index);
                    }

                    return (
                      <TableCell
                        key={col.id ? col.id : index}
                        align="center"
                        padding="none"
                        className="body-table-cell"
                      >
                        {/* {value} */}
                        {maxWords &&
                        value?.toString()?.split(" ").length > maxWords ? (
                          <p className="read-more-text">
                            {truncateWords(value, maxWords)}{" "}
                            <span
                              className="show-more"
                              onClick={() =>
                                showAllFunc({ isOpen: true, text: value })
                              }
                            >
                              Read More
                            </span>
                          </p>
                        ) : (
                          value
                        )}
                        {typeof value === "boolean" && (
                          <Checkbox checked={value} size="small" />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StickyHeaderCon;
