import { HOSTNAME } from '../../../common-utilities/utils/end-points';
import { REST_URLS } from '../../../common-utilities/utils/end-points/cf-approval';
import {
  getEndOfDay,
  getStartOfDay,
} from '../../../common-utilities/utils/time';
import { invokeApi, HTTP_METHODS } from '../../utils/http-service';
import { downloadSumryRept } from '../document/report-formatter';
import { defaultData } from './config';

export const getDashboardData = (params, setDataFunc) => {
  const finalParams = { ...params };
  if (finalParams?.startDate) {
    finalParams.startDate = getStartOfDay(finalParams.startDate);
  }
  if (finalParams?.endDate) {
    finalParams.endDate = getEndOfDay(finalParams.endDate);
  }
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.DASHBOARD}`,
    null,
    finalParams
  )
    .then((res) => {
      const allData = [...defaultData];
      const approved = res?.approved || {};
      const pending = res?.pending || {};
      allData[0] = { ...allData[0], ...approved };
      allData[1] = { ...allData[1], ...pending };
      setDataFunc(allData);
    })
    .catch((err) => {
      setDataFunc(defaultData);
    });
};

export const downloadReport = (params) => {
  const finalParams = { ...params };
  if (finalParams?.startDate) {
    finalParams.startDate = getStartOfDay(finalParams.startDate);
  }
  if (finalParams?.endDate) {
    finalParams.endDate = getEndOfDay(finalParams.endDate);
  }
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.REPORTS}`,
    null,
    finalParams
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      downloadSumryRept(res?.results);
    })
    .catch((err) => {
      console.log(err);
    });
};
