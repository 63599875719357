import ActionIcons from "../actionIcons";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TableCell from "@mui/material/TableCell";
import { storeTypes } from "../../utils/constants";
export const getHeaderConfig = (loadData) => {
  let headerConfig = [
    {
      label: "Region",
      id: "cluster",
    },
    {
      label: "LegalPOC",
      id: "LegalPOC",
    },
    {
      label: "ProjectsPOC",
      id: "ProjectsPOC",
    },
    {
      label: "Format",
      id: "format",
    },
    {
      label: "Restaurant Id",
      id: "restaurantId",
      render: (data) => {
        return <div>{data?.restaurantId}</div>;
      },
    },
    {
      label: "Restaurant Name",
      id: "restaurantName",
      render: (data) => {
        return <div>{data?.restaurantName}</div>;
      },
    },
    {
      label: "View files",
      id: "view files",
      render: (data, onClick) => {
        return (
          <div>
            <RemoveRedEyeIcon
              onClick={() => onClick(data)}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      label: "Action",
      id: "actions",
      render: (data) => {
        let showEditIcon =
          data?.isApproved1 && data?.isApproved2 && data?.isApproved3;
        if (data?.storeType === storeTypes.cocoAcquired && showEditIcon) {
          showEditIcon =
            data?.isApproved4 && data?.isApproved5 && data?.isApproved6;
        }

        return (
          <div>
            {showEditIcon && (
              <ActionIcons
                loadData={loadData}
                id={data.id}
                resId={data.restaurantId}
                modalType="DOCUMENT"
              />
            )}
          </div>
        );
      },
    },
  ];

  return headerConfig;
};

export const excelDateColumns = [
  "Creation Date",
  "Approved 1 Date",
  "Approved 2 Date",
  "Approved 3 Date",
  "Approved 4 Date",
  "Approved 5 Date",
  "Approved 6 Date",
  "Approver 1 Date (Rajesh M)",
  "Approver 2 Date (Nitin M)",
  "Approver 3 Date (Dev)",
  "Approver 1 Date (Pulkit)",
  "Approver 2 Date (Regional Ops Head)",
  "Approver 3 Date (Rajat)",
  "Approver 4 Date (Rajesh M)",
  "Approver 5 Date (Nitin M)",
  "Approver 6 Date (Dev)",
  "Rejected At",
];
