/** @format */
const commonPrefix = "/v1/pl-report/";
const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  LOGIN: `${commonPrefix}auth/login`,
  // REFRESH_TOKEN: `${commonPrefix}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGOUT: `${commonPrefix}auth/logout`,
  PL_ACTUAL: `${intranetService}pl-report/upload-pl-actual`,
  PL_AOP: `${intranetService}pl-report/upload-pl-AOP`,
  PL_UEM: `${intranetService}pl-report/upload-pl-uem`,
  DOWNLOAD_PL: `${intranetService}pl-report/download-pl-report`,
  AM_USERS: `${intranetService}users/user-list?additionalRole=Area Manager`,   
};

export const redirectToRoute = "/pl-report";
