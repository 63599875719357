import {
  convertToCamelCaseFromUnderScore,
  exportToExcel,
} from '../../../../common-utilities/utils';
import {
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount,
} from '../helper';

export const downloadPO = (poData) => {
  const data =
    poData?.items?.map?.((item) => {
      return {
        'Main Category': item?.item?.category?.name || null,
        'Sub Category': item?.item?.subCategory?.name || null,
        'Item Name': item?.item?.name || null,
        Description: item?.item?.description || null,
        UOM: item?.uom || null,
        Quantity: item?.quantity != undefined ? item?.quantity : null,
        'Inward Qty':
          item?.inwardedQuantity != undefined ? item?.inwardedQuantity : null,
        Price: item?.rate != undefined ? item?.rate : null,
        Amount: item?.amount != undefined ? item?.amount : null,
        'Tax rate': item?.taxRate != undefined ? item?.taxRate : null,
        'Tax Amount': item?.taxAmount != undefined ? item?.taxAmount : null,
        'Total Amount':
          item?.totalAmount != undefined ? item?.totalAmount : null,
      };
    }) || [];

  exportToExcel(data, 'po', null, true);
};

export const downloadReports = (data, status) => {
  if (!data?.length) {
    return;
  }
  const results = data?.map?.((item) => {
    const site = item?.site;
    let finalItem = {
      'PO Number': item?.poNumber || null,
      'Site Code & Name':
        `${site?.code || ''}${site?.code ? ' - ' : ''}${site?.name || ''}` ||
        null,
      'Vendor Code': item?.vendorCode || null,
      'Vendor Name': item?.vendorName || null,
      'PO Type': convertToCamelCaseFromUnderScore(item?.poType),
      'Base Amt': getPurchaseOrderWithoutTaxAmount(item?.items || []),
      'GST Amt': getPurchaseOrderTaxAmount(item?.items || []),
      'Total Amt': getPurchaseOrderAmount(item?.items),
    };
    if (status === 'approval_in_progress' || status === 'all') {
      finalItem['Pending With'] = item?.approvalId?.pendingWith || null;
    }

    if (status !== 'approval_in_progress') {
      finalItem['PO Status'] = convertToCamelCaseFromUnderScore(
        item?.status || ''
      );
    }
    finalItem['Created By'] = item?.creatorName || null;

    return finalItem;
  });

  exportToExcel(results, 'po_report');
};
