export const Theme = {
  typography: {
    fontFamily: ["DMSans-Regular"],
  },
  palette: {
    primary: {
      main: "#008a52",
    },
    secondary: {
      main: "#fef200",
    },
    light: {
      main: "white",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        // maxWidth: 300
        color: "#008a52",
      },
      containedPrimary: {
        color: "#008a52",
      },
      text: {
        color: "#fff",
      },
    },
    MuiInputBase: {
      input: {
        background: "#fff",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "#fff",
      },
    },
  },
};
