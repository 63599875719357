import { ArrowBack, CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentView from "../../core/document-viewer";
import SiFileUpload from "../../core/file-uploader";
import CustomModal from "../../../common-utilities/core/modal";
import {
  DOCUMENT_TYPES,
  NEW_VENDOR,
  VENDOR_INVOICE_SUBMISSION_ROLES,
} from "../../utils/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { getOfflineData } from "../../utils/offline-services";
import { formatFormDetails, getUpdatePayload, isFormValid } from "./helper";
import "./index.scss";
import { downloadFile, getRedirectPointBySlug } from "../../utils/common-utils";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";

const InvoiceEditForm = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = getOfflineData("user", token) || {};
  const userId = user?.userId;
  const role = user?.role;
  const [formDetails, setFormDetails] = useState({});
  const [storeDetails, setStoreDetails] = useState({});
  const [vendors, setVendors] = useState([]);
  const [documentUrl, setDocumentUrl] = useState(null);
  const vendor = formDetails?.vendor;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (slug !== appSlugs.vendorInvoiceSubmission) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.VENDOR}`,
        null,
        {
          page: 0,
          limit: 10000,
        },
        null,
        slug,
        token
      )
        .then((response) => {
          if (response && response.results) {
            const { results = [] } = response;
            let updateVendors = results.map((result) => {
              return {
                label: result.name,
                value: result.code,
                id: result.id,
              };
            });
            // updateVendors.push({
            //   label: 'New Vendor',
            //   value: 'NEW_VENDOR',
            // });
            setVendors(updateVendors);
          }
        })
        .catch((err) => {});
    }

    invokeApi(
      HTTP_METHODS.GET,
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}/${id}`
        : `${HOSTNAME}${REST_URLS.INVOICE}/${id}`,
      null,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        setFormDetails(formatFormDetails(response, slug));
      })
      .catch((err) => {});

    {
      slug !== appSlugs.vendorInvoiceSubmission &&
        invokeApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
          null,
          {
            storeNumber: userId.split("-")[0],
          },
          null,
          slug,
          token
        )
          .then((response) => {
            if (response) {
              setStoreDetails(response);
            }
          })
          .catch((err) => {});
    }
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onFormChange = (value, dataLabel) => {
    setFormDetails({
      ...formDetails,
      [dataLabel]: value,
    });
  };

  const onSubmit = (isCancelled) => {
    const payload = getUpdatePayload(formDetails, storeDetails, slug);
    invokeApi(
      HTTP_METHODS.PUT,
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}/${formDetails.id}`
        : `${HOSTNAME}${REST_URLS.INVOICE}/${formDetails.id}`,
      isCancelled === true ? { cancelled: true } : payload,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to resubmit invoice");
        } else {
          navigate(getRedirectPointBySlug(slug));
          toast.info("Submitted invoice request");
        }
      })
      .catch((err) => {
        toast.error("Failed to create invoice ");
      });
  };

  const maxDate = new Date().toISOString().split("T")[0];

  return (
    <div className="invoice-edit-form-container">
      <div className="header-section">
        <div>
          <IconButton
            variant="contained"
            onClick={() => navigate(getRedirectPointBySlug(slug))}
          >
            <ArrowBack />
          </IconButton>
          <span className="title">Invoice Details {formDetails.id} </span>
        </div>
      </div>

      <Card>
        <CardContent>
          <div className="invoice-form">
            <div>
              <div className="comments-form-row">
                <div className="i-key-value">
                  <span className="i-key">Rejected By : </span>
                  <span className="i-value">{formDetails.rejectedBy}</span>
                </div>
                <div className="i-key-value">
                  <span className="i-key">Reviewer Comments : </span>
                  <span className="i-value">{formDetails.comments}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              {slug === appSlugs.vendorInvoiceSubmission && (
                <TextField
                  value={formDetails?.store || ""}
                  disabled={true}
                  size="small"
                  label="Store"
                />
              )}
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <Autocomplete
                  disablePortal
                  options={vendors}
                  className="form-autocomplete"
                  size="small"
                  onChange={(event, values) =>
                    onFormChange(values || {}, "vendor")
                  }
                  value={formDetails.vendor || ""}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Vendor Name" />
                  )}
                />
              )}

              {formDetails &&
                slug !== appSlugs.vendorInvoiceSubmission &&
                formDetails.vendor &&
                formDetails.vendor.value === NEW_VENDOR && (
                  <TextField
                    label="New Vendor Name"
                    name="vendorName"
                    size="small"
                    value={formDetails.vendorName || ""}
                    onChange={onInputChange}
                  />
                )}
              <TextField
                label="PO Number"
                name="poNumber"
                size="small"
                value={formDetails.poNumber || ""}
                onChange={onInputChange}
              />
              {slug !== appSlugs.vendorInvoiceSubmission ? (
                <TextField
                  label="GRN Number"
                  name="grnNumber"
                  size="small"
                  value={formDetails.grnNumber || ""}
                  onChange={onInputChange}
                />
              ) : (
                <>
                  <Autocomplete
                    disablePortal
                    disabled={true}
                    options={[
                      {
                        label: vendor?.city
                          ? `${vendor?.state} - ${vendor?.city}`
                          : vendor?.state || "",
                        value: vendor?.code || "",
                      },
                    ]}
                    className="form-autocomplete"
                    size="small"
                    value={{
                      label: vendor?.city
                        ? `${vendor?.state} - ${vendor?.city}`
                        : vendor?.state || "",
                      value: vendor?.code || "",
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="State and City"
                      />
                    )}
                  />
                  <TextField
                    label="Vendor Code"
                    size="small"
                    disabled={true}
                    value={formDetails.vendor?.code || ""}
                    // onChange={onInputChange}
                  />
                </>
              )}
            </div>
            <div className="form-row">
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <Autocomplete
                  disablePortal
                  options={DOCUMENT_TYPES}
                  className="form-autocomplete"
                  size="small"
                  onChange={(event, values) =>
                    onFormChange(values || {}, "invoiceType")
                  }
                  value={formDetails.invoiceType || ""}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Invoice type" />
                  )}
                />
              )}

              <TextField
                label="Invoice No"
                name="invoiceNumber"
                size="small"
                value={formDetails.invoiceNumber || ""}
                onChange={onInputChange}
              />
              <TextField
                label="Invoice Date"
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max:
                      slug === appSlugs.vendorInvoiceSubmission
                        ? maxDate
                        : "9999-12-31",
                  },
                }}
                name="invoiceDate"
                value={formDetails.invoiceDate || ""}
                onChange={onInputChange}
              />
              <TextField
                label="Amount"
                size="small"
                name="amount"
                value={formDetails.amount || ""}
                onChange={onInputChange}
              />
            </div>
            <div className="form-row attachments">
              {formDetails.invoiceAttachment ? (
                <div>
                  <span>{formDetails.invoiceAttachment.blobName}</span>
                  <IconButton
                    onClick={() => onFormChange("", "invoiceAttachment")}
                  >
                    <CloseOutlined color="primary" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      downloadFile(formDetails.invoiceAttachment.url)
                    }
                  >
                    <RemoveRedEyeIcon color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title="Invoice Attachment"
                  callBack={(value) =>
                    onFormChange(value[0], "invoiceAttachment")
                  }
                  token={token}
                  slug={slug}
                />
              )}

              {formDetails.grnAttachment ? (
                <div>
                  <span>{formDetails.grnAttachment.blobName}</span>
                  <IconButton onClick={() => onFormChange("", "grnAttachment")}>
                    <CloseOutlined color="primary" />
                  </IconButton>
                  <IconButton
                    onClick={() => downloadFile(formDetails.grnAttachment.url)}
                  >
                    <RemoveRedEyeIcon color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title="GRN Attachment"
                  callBack={(value) => onFormChange(value[0], "grnAttachment")}
                  token={token}
                  slug={slug}
                />
              )}

              {formDetails.poAttachment ? (
                <div>
                  <span>{formDetails.poAttachment.blobName}</span>
                  <IconButton onClick={() => onFormChange("", "poAttachment")}>
                    <CloseOutlined color="primary" />
                  </IconButton>
                  <IconButton
                    onClick={() => downloadFile(formDetails.poAttachment.url)}
                  >
                    <RemoveRedEyeIcon color="primary" />
                  </IconButton>
                </div>
              ) : (
                <SiFileUpload
                  title="PO Attachment"
                  callBack={(value) => onFormChange(value[0], "poAttachment")}
                  token={token}
                  slug={slug}
                />
              )}
            </div>
            <div className="form-row">
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <TextField
                  value={storeDetails.name || ""}
                  size="small"
                  label="Store Name"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              <TextareaAutosize
                minRows="4"
                placeholder="Remarks"
                className="form-autocomplete"
                value={formDetails.remarks}
                onChange={(event) =>
                  onFormChange(event.target.value, "remarks")
                }
              />
            </div>
            <div className="footer-buttons">
              {slug === appSlugs.vendorInvoiceSubmission &&
                role === VENDOR_INVOICE_SUBMISSION_ROLES.VENDOR && (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      onSubmit(true);
                    }}
                  >
                    Cancel
                  </Button>
                )}
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={isFormValid(formDetails)}
              >
                Re-Submit
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {documentUrl && (
        <CustomModal
          open={!!documentUrl}
          title="Invoice Document"
          contentClassName="invoice-tracking-document-modal"
          onClose={() => setDocumentUrl(null)}
        >
          <DocumentView url={documentUrl} />
        </CustomModal>
      )}
    </div>
  );
};

export default InvoiceEditForm;
