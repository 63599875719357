import { getOfflineData } from "../../utils/offline-services";
import { homeTabs } from "../constants";

export const setTabsConfig = (
  setPanelDetails,
  setTabsList,
  tokenKey
) => {
  const user = getOfflineData(tokenKey, "user");
  const newInfo = { showPanel: false, indexes: {} };
  let lastIndex = 1;
  if (
    user?.role !== "admin"
  ) {
    setPanelDetails(newInfo);
    return;
  }
  newInfo.showPanel = true;
  const tablist = ["Home"];
  newInfo.indexes[homeTabs.category] = lastIndex;
  lastIndex += 1;
  tablist.push("Category");

  setTabsList(tablist);
  setPanelDetails(newInfo);
};
