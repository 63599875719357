import { agreementAndPostAgreementDocsLabelsAndValues as cocoAcqAgrAndPostAgrValues } from '../../../utils/coco-acquired.config';

export const agrAndPostAgrCocoAcqDateCheck = (formDetails) => {
  if (formDetails.notApplicable) {
    return false;
  }
  const documentType = formDetails?.documentType?.value;
  switch (documentType) {
    case cocoAcqAgrAndPostAgrValues.bta:
    case cocoAcqAgrAndPostAgrValues.franchiseTermination:
    case cocoAcqAgrAndPostAgrValues.deliveryMemo:
    case cocoAcqAgrAndPostAgrValues.leaseTermination:
      return !formDetails.startDate;

    case cocoAcqAgrAndPostAgrValues.leaseAgreement:
    case cocoAcqAgrAndPostAgrValues.registration:
    case cocoAcqAgrAndPostAgrValues.optionAgreement:
      return formDetails.notApplicable
        ? false
        : !formDetails.startDate || !formDetails.endDate;

    case cocoAcqAgrAndPostAgrValues.submissionOfRegHardCopy:
      return formDetails.notApplicable ? false : !formDetails.startDate;

    default:
      return false;
  }
};
