import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Divider, TextField } from "@mui/material";
import Select from "react-select";
import { auditModelNames } from "../../utils/constants";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { invokeApi, plainApi } from "../../utils/http-service";
import Grid from "@mui/material/Unstable_Grid2";

import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";

import { GlobalStoreContext } from "../../global-states/Context";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
const AttributesHistory = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const previousController = useRef();
  const [storeOptions, setStoreOptions] = useState([]);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [satelliteList, setSatelliteList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [sequenceList, setSequenceList] = useState([]);

  useEffect(() => {
    const validStoreCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (totalPages === null) {
      if (validStoreCombination) {
        getTableData(
          storeInfo.storeNo,
          storeInfo.satelliteNo,
          storeInfo.sequenceNo,
          pages
        );
      }
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          if (validStoreCombination) {
            getTableData(
              storeInfo.storeNo,
              storeInfo.satelliteNo,
              storeInfo.sequenceNo,
              pages
            );
          }
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  const dataLabelValues = [
    { label: "Building Type", id: "buildingType", type: "" },
    { label: "Population Estimate", id: "populationEst", type: "" },
    { label: "Customer Area", id: "customerArea", type: "Checkbox" },
    { label: "Seasonal1", id: "seasonal", type: "Checkbox" },
    { label: "Front Line Unit", id: "frontLineUnit", type: "" },
    { label: "Drive Through Unit", id: "driveThroughUnit", type: "" },
    { label: "Windows", id: "windows", type: "" },
    { label: "Outdoor Banner", id: "outdoorBanner", type: "Checkbox" },
    { label: "Vertical Banner", id: "verticalBanner", type: "Checkbox" },
    { label: "Store Locator", id: "storeLocator", type: "Checkbox" },
    { label: "Panini Press 1", id: "paniniPress1", type: "" },
    { label: "Panini Press 2", id: "paniniPress2", type: "" },
    { label: "Panini Press 3", id: "paniniPress3", type: "" },
    { label: "Oven Manufacture", id: "ovenManufacture", type: "" },
    { label: "Building Sign1", id: "signageInfo.buildingSign1", type: "" },
    { label: "Building Sign2", id: "signageInfo.buildingSign2", type: "" },
    { label: "Pole Sign", id: "signageInfo.poleSign", type: "" },
    {
      label: "Outdoor Pennants",
      id: "signageInfo.outDoorPennants",
      type: "Checkbox",
    },
    { label: "Door Decals", id: "signageInfo.doorDecals", type: "Checkbox" },
    { label: "Maker1", id: "signageInfo.maker1", type: "" },
    { label: "Maker2", id: "signageInfo.maker2", type: "" },
    { label: "Pole Sign Maker", id: "signageInfo.poleSignMaker", type: "" },
    { label: "Logo1", id: "signageInfo.logo1", type: "" },
    { label: "Logo2", id: "signageInfo.logo2", type: "" },
    { label: "Pole Sign Logo", id: "signageInfo.poleSignLogo", type: "" },
    {
      label: "Last Remodel Date",
      id: "remodelDatesInfo.lastRemodelDate",
      type: "",
    },
    {
      label: "Next Remodel Date",
      id: "remodelDatesInfo.nextRemodelDate",
      type: "",
    },
    {
      label: "Last Relocation Date",
      id: "remodelDatesInfo.lastRelocationDate",
      type: "",
    },
    {
      label: "Remodel Extension Expiration Date",
      id: "remodelDatesInfo.remodelExtensionExpirationDate",
      type: "",
    },
    { label: "Fresh Image", id: "remodelDatesInfo.freshImage", type: "" },
    { label: "Speed Oven1", id: "speedOvensInfo.speedOven1", type: "" },
    { label: "Speed Oven2", id: "speedOvensInfo.speedOven2", type: "" },
    { label: "Speed Oven3", id: "speedOvensInfo.speedOven3", type: "" },
    { label: "Speed Oven", id: "speedOvensInfo.speedOven4", type: "" },
    { label: "Of Speed Oven", id: "speedOvensInfo.ofSpeedOven", type: "" },
    { label: "Soda Info Type", id: "sodaInfo.type", type: "" },
    { label: "Date", id: "sodaInfo.date", type: "" },
    { label: "Self Serve", id: "sodaInfo.selfServe", type: "Checkbox" },
    { label: "Bottled", id: "sodaInfo.bottled", type: "Checkbox" },
    { label: "Bubbler", id: "sodaInfo.bubbler", type: "" },
    { label: "Menu Info Type", id: "menuInfo.type", type: "" },
    { label: "Menu Orientation", id: "menuInfo.menuOrientation", type: "" },
    { label: "Menu Info Language", id: "menuInfo.language", type: "" },
    { label: "Menu Info Sodium", id: "menuInfo.sodium", type: "Checkbox" },
    {
      label: "Menu Info Calories Menu",
      id: "menuInfo.caloriesMenus",
      type: "Checkbox",
    },
    {
      label: "Menu Info Set Calories Date",
      id: "menuInfo.setCalorieDate",
      type: "",
    },
    {
      label: "Manu Manager Opt Out",
      id: "menuInfo.menuManagerOptOut",
      type: "Checkbox",
    },
    {
      label: "Panel Info Point of order 1",
      id: "panelInfo.pointOfOrder1",
      type: "",
    },
    {
      label: "Panel Info Point of order 2",
      id: "panelInfo.pointOfOrder2",
      type: "",
    },
    { label: "Panel Info PO1 Footer", id: "panelInfo.PO1Footer", type: "" },
    { label: "Panel Info PO2 Footer", id: "panelInfo.PO2Footer", type: "" },
    { label: "Driver Thru", id: "panelInfo.driveThru", type: "" },
    { label: "Driver Thru 1", id: "panelInfo.driveThru1", type: "" },
    { label: "Driver Thru 2", id: "panelInfo.driveThru2", type: "" },
    {
      label: "Electricity Info Cycles",
      id: "electricityInfo.cycles",
      type: "",
    },
    { label: "Voltage", id: "electricityInfo.voltage", type: "" },
    { label: "Lines", id: "electricityInfo.lines", type: "" },
    { label: "Wires", id: "electricityInfo.wires", type: "" },
    { label: "Amps", id: "electricityInfo.amps", type: "" },
    { label: "Main Panel", id: "electricityInfo.mainPanel", type: "" },
    { label: "Sub Panel", id: "electricityInfo.subPanel", type: "" },
    { label: "Mps Breaker 60", id: "electricityInfo.mpsbreaker60", type: "" },
    { label: "Mps Breaker 50", id: "electricityInfo.mpsbreaker50", type: "" },
    { label: "Mps Breaker 40", id: "electricityInfo.mpsbreaker40", type: "" },
    { label: "Mps Breaker 30", id: "electricityInfo.mpsbreaker30", type: "" },
    { label: "Mps Breaker 20", id: "electricityInfo.mpsbreaker20", type: "" },
    { label: "Mps Breaker 15", id: "electricityInfo.mpsbreaker15", type: "" },
    {
      label: "Spare And Blank",
      id: "electricityInfo.spareAndBlank",
      type: "",
    },
    {
      label: "Additional Amp Size1",
      id: "electricityInfo.additionalAmpSize1",
      type: "",
    },
    {
      label: "Additonal Amp Size2",
      id: "electricityInfo.additionalAmpSize2",
      type: "",
    },
    {
      label: "Additional Amp Quantity1",
      id: "electricityInfo.additionalAmpQty1",
      type: "",
    },
    {
      label: "Additional Amp Quantity 2",
      id: "electricityInfo.additionalAmpQty2",
      type: "",
    },
    {
      label: "Marketting Info Number of cookie unit",
      id: "marketingInfo.numOfCookieUnit",
      type: "",
    },
    {
      label: "Marketting Info Line Stanchions",
      id: "marketingInfo.lineStanchions",
      type: "Checkbox",
    },
    {
      label: "Marketting Info Purse Rail",
      id: "marketingInfo.purseRail",
      type: "",
    },
    {
      label: "Marketting Info Television",
      id: "marketingInfo.television",
      type: "",
    },
    { label: "Breakfast", id: "programsInfo.breakfast", type: "" },
    { label: "Omelet", id: "programsInfo.omelet", type: "Checkbox" },
    { label: "Speciality", id: "programsInfo.speciality", type: "" },
    {
      label: "Scl Lunch Expiratiom Date",
      id: "programsInfo.sclLunchExpirationDate",
      type: "",
    },
    { label: "Frizzle", id: "programsInfo.frizzle", type: "" },
  ];

  const getTableData = (storeNo, satelliteNo, sequenceNo, page, hasToReset) => {
    const invalidCombination = !checkValidStoreCombination(
      storeNo,
      satelliteNo,
      sequenceNo
    );
    if (invalidCombination) {
      return;
    }
    let checkingCondition = !pageArray.includes(page);
    if (hasToReset) {
      checkingCondition = true;
    }
    if (checkingCondition) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_HISTORY}`, null, {
        modelName: auditModelNames.storeAttributes,
        storeNo,
        satelliteNo,
        sortBy: "-createdAt",
        sequenceNo,
        page,
        limit: 50,
      })
        .then((res) => {
          if (!res?.message) {
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              const { details = {} } = item;
              let allDetails = {};
              for (let detail in details) {
                if (typeof details[detail] === "object") {
                  for (let nestedDetail in details[detail]) {
                    allDetails[`${detail}.${nestedDetail}`] =
                      details[detail][nestedDetail];
                  }
                } else {
                  allDetails[detail] = details[detail];
                }
              }

              return allDetails;
            });
            if (page == 1) {
              setData(results);
              setPageArray([1]);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              setData([...data, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          } else {
            clearData(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getTableData(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        1,
        true
      );
    }
  }, []);

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.storeNo,
          // satelliteNum: p.satelliteNum,
          // sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const clearData = (reset = true) => {
    if (reset) setStoreInfo({ storeNo: "", satelliteNo: "", sequenceNo: "" });
    setTotalPages(null);
    setStoreOptions([]);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setData([]);
  };

  const onInputChange = (event, value, reason) => {
    setSequenceList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({
        satelliteNo: "",
        sequenceNo: "",
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  return (
    <div className="store-memos-container">
      <div className="top-section">
        <Grid container spacing={1}>
          <Grid xs={3}>
            <Autocomplete
              id="free-solo-demo"
              className="subs-autocomplete"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              inputValue={storeInfo.storeNo + "" || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  getAllSequenceNumber(val.storeNo);
                }
              }}
            />
          </Grid>
          <Grid xs={3}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Satellite"
              className="sel-item"
              options={satelliteList}
              value={
                ![undefined, null, ""].includes(storeInfo.satelliteNo)
                  ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  satelliteNo: val?.value,
                }));
                getTableData(
                  storeInfo.storeNo,
                  val?.value,
                  storeInfo.sequenceNo,
                  1,
                  true
                );
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>

          <Grid xs={3}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence Number"
              className="sel-item"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val?.value,
                }));
                getTableData(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value,
                  1,
                  true
                );
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className="memo-list-container">
        <StickyHeaderCon
          dataList={data}
          columnsList={dataLabelValues}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          // containerHeight="table-con"
        />
      </div>
    </div>
  );
};
export default AttributesHistory;
