import { TextField, Button, Typography, Box } from "@mui/material";
import React from "react";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/electricity-consumption";
import { toast } from "react-toastify";

const EditModal = ({
  editReading,
  setEditReading,
  loadData,
  setReadingModal,
}) => {
  const { data } = editReading;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditReading((prevVal) => ({
      ...prevVal,
      data: { ...editReading.data, [name]: value },
    }));
  };
  const editHandler = () => {
    const payload = {
      startReading: data?.startReading,
      endReading: data?.endReading,
      date: new Date(data?.date),
      storeId: data?.storeId,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${INTRANETHOSTNAME}${REST_URLS.ELECTRICITY_CONSUMPTION}/update-previous-data`,
      payload
    ).then((res) => {
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success("Updated Successfully");
        setReadingModal({ isOpen: false, data: {} });
        loadData({ page: 1 });
      }
    });
  };
  return (
    <div>
      <Typography sx={{ mb: 2 }}>Date: {data?.date}</Typography>

      <TextField
        label="Today Start Reading"
        fullWidth
        type="number"
        name="startReading"
        value={data?.startReading}
        onChange={handleChange}
        size="small"
      />
      <TextField
        label="Today End Reading"
        fullWidth
        type="number"
        value={data?.endReading}
        name="endReading"
        sx={{ mt: 2 }}
        onChange={handleChange}
        size="small"
      />
      <Box sx={{ display: "flex", gridGap: "20px", mt: 2 }}>
        <Button
          variant="outlined"
          size="small"
          color="error"
          fullWidth
          onClick={() => setEditReading({ isOpen: false, data: {} })}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          fullWidth
          size="small"
          onClick={editHandler}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default EditModal;
