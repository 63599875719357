import CustomModal from "../../../packages/common-utilities/core/modal";

export const ViewDepartmentRoles = ({ showModal, closeModal, data }) => {
  return (
    <>
      {showModal && (
        <CustomModal
          title="Department Roles"
          onClose={closeModal}
          contentClassName={{
            headerBackgroundColor: "#008952",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {data.length > 0 ? (
              data.map((item, index) => {
                return <p key={item?.key || index}>{item?.name}</p>;
              })
            ) : (
              <p>No Departments Found</p>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
};
