import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button, Box, Stack, TextareaAutosize, Grid } from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AutoCompleteMultiSelect from "../../packages/common-utilities/core/autocomplete-multiselect";
import SaveIcon from "@mui/icons-material/Save";
const CreateRole = ({ closeModal, loadData }) => {
  const [roleData, setRoleData] = useState({
    name: "",
    apps: [],
    comments: "",
  });
  const [appList, setAppList] = useState([]);
  const [appValue, setAppValue] = useState([]);
  const handleChange = (e) => {
    setRoleData((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }));
  };
  const handleAppChange = (event) => {
    const appIdList = event.map((app) => app.value);
    setRoleData((prevVal) => ({ ...prevVal, apps: appIdList }));
    setAppValue(event);
  };
  const getApps = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_ROLES_AND_APPS}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          // let results = res?.map((item) => {
          //   return { label: item.name, value: parseInt(item.id) }
          // }) || []
          //APP_NAME_LIST
          // setAppList(results)
          let results = [];
          res.map((ele) => {
            if (ele?.apps) {
              for (let app of ele.apps) {
                const checkAppInResult = results.filter(
                  (ele) => ele?.value == app._id
                );
                if (checkAppInResult.length > 0) {
                  return;
                } else {
                  results.push({ label: app.name, value: app._id });
                }
              }
            }
          });
          setAppList(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formSubmitHandler = () => {
    const payload = Object.fromEntries(
      Object.entries(roleData).filter(([key, value]) => value)
    );
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.CREATE_ROLE}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("New Role Created Successfully", { autoClose: 2000 });
          loadData();
          closeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getApps();
  }, []);
  return (
    <div className="create-role-con">
      <div className="cancel-div">
        <h1>Create New Role</h1>
        <CancelIcon
          color="error"
          onClick={() => closeModal(false)}
          sx={{ cursor: "pointer" }}
          fontSize="large"
        />
      </div>
      <Grid spacing={2} container md={12} sx={{ my: 2 }}>
        <Grid item md={6}>
          <TextField
            label="Role Name"
            name="name"
            size="small"
            fullWidth
            className="new-textfield"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6}>
          {" "}
          <AutoCompleteMultiSelect
            options={appList}
            value={appValue}
            placeholder="Select Apps"
            onChange={(e, val) => {
              handleAppChange(val);
            }}
          />
        </Grid>
      </Grid>
      <TextareaAutosize
        className="new-textfield"
        label="Comments"
        placeholder="Comments"
        name="comments"
        value={roleData.comments}
        style={{ width: "100%", maxWidth: "100%" }}
        onChange={handleChange}
        maxRows={10}
        minRows={7}
      />
      <Stack direction="row" spacing={2} sx={{ my: 2 }}>
        <div className="common-btn-ctr">
          <div>
            <Button
              variant="contained"
              fullWidth
              startIcon={<SaveIcon />}
              className="common-modal-crt-btn"
              onClick={formSubmitHandler}
              disabled={
                !roleData.name || roleData.apps.length < 0 ? true : false
              }
            >
              Save
            </Button>
          </div>

          <div>
            <Button
              variant="outlined"
              fullWidth
              className="common-modal-crt-btn"
              onClick={() => closeModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default CreateRole;
