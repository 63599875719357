import SubwayFileUpload from "../../../core/file-uploader/subway-file-upload";
import { INTRANETHOSTNAME } from "../../../utils/end-points";
import { REST_URLS } from "../../../utils/end-points/app-document";
import { clearProperty, setProperty } from "../../../utils/misc";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  disableFileUpload,
  getMainSubType,
  getExt,
  getFileName,
} from "../utils";

const FileDetails = ({ formDetails, setFormDetails, slug, allowedFormats }) => {
  return (
    <div className="end-con">
      {!formDetails.fileName && (
        <>
          <SubwayFileUpload
            title="Attachment"
            url={`${INTRANETHOSTNAME}${REST_URLS.UPLOAD_DOCUMENT}`}
            slug={slug}
            providedLimit={15000000}
            preventUpload={(file) => disableFileUpload(file, allowedFormats)}
            tokenKey="subway"
            callBack={(newFile) => {
              setProperty(
                ["fileName", "link", "mainType", "subType"],
                [
                  getFileName(newFile?.originalname),
                  newFile?.url,
                  ...getMainSubType(newFile?.originalname, newFile?.mimetype),
                ],
                setFormDetails
              );
            }}
          />
        </>
      )}
      {formDetails.fileName && (
        <>
          <span>
            {formDetails.fileName}.{getExt(formDetails?.link)}
          </span>
          <IconButton
            color="primary"
            onClick={() => {
              clearProperty(["fileName", "link"], setFormDetails);
            }}
          >
            <ClearIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default FileDetails;
