import AutocompleteComponent from "../../core/styled-autocomplete";

export const MainTrainingManualFilter = ({
  trainingManualList,
  setFilterOnChange,
  folderList,
  filter,
  mainFolderLabel = "Select Category",
  subFolderLabel = "Select Folder",
  mainFolder = "mainFolder",
  subFolder = "subFolder",
  className = "main-filter"
}) => {
  return (
    <>
      <div className={`${className}`}>
        <div>
          <AutocompleteComponent
            label={`${mainFolderLabel ? mainFolderLabel : ""}`}
            onChange={setFilterOnChange}
            options={trainingManualList}
            name={`${mainFolder}`}
            value={
              trainingManualList.find((e) => e.value === filter.mainFolder) ||
              null
            }
          />
        </div>

        <div>
          <AutocompleteComponent
            label={`${subFolderLabel ? subFolderLabel : ""}`}
            onChange={setFilterOnChange}
            options={folderList}
            name={`${subFolder}`}
            value={folderList.find((e) => e.value === filter.subFolder) || null}
          />
        </div>
      </div>
    </>
  );
};
