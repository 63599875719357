import React from "react";
import { useState, useEffect } from "react";
import "./index.scss";
import PendingVouchers from "./pendingVouchers";
import PaidVouchers from "./paidVouchers";
import InProcessVouchers from "./inProcessVouchers";
import AllVouchers from "./allVouchers";
import { Box, Badge, Tab, Tabs, Grid, Card, CardContent } from "@mui/material";
import { getOfflineData } from "../../utils/offline-services";
import Draft from "./draft";
import Reconsider from "./reconsider";
import Reject from "./reject";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <p>{children}</p>
        </Box>
      )}
    </div>
  );
}
const PettyExpense = () => {
  const [value, setValue] = useState(0);
  const [storeDetails, setStoreDetails] = useState({});

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const user = getOfflineData("user");
  const getStoreDetails = () => {
    if (user?.role === "STORE") {
      const store = user?.userId.split("-");
      invokeApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
        null,
        {
          storeNumber: store[0],
          sequenceNumber: store[1],
          satelliteNumber: store[2],
        }
      )
        .then((response) => {
          if (response) {
            setStoreDetails(response);
          }
        })
        .catch((err) => {});
    }
  };
  const storeTabPannel = () => {
    return (
      <div>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={0}>
            <Grid item md={9} xs={12} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label={<span className="badgeMargin">In Process</span>} />
                <Tab label={<span className="badgeMargin">Paid</span>} />
                <Tab label={<span className="badgeMargin">Draft</span>} />
                <Tab label={<span className="badgeMargin">Reconsider</span>} />
                <Tab label={<span className="badgeMargin">Reject</span>} />
                <Tab label={<span className="badgeMargin">All</span>} />
              </Tabs>
            </Grid>
          </Grid>
        </Box>

        <TabPanel value={value} index={0}>
          <InProcessVouchers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaidVouchers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Draft />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Reconsider />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Reject />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <AllVouchers />
        </TabPanel>
      </div>
    );
  };
  const tabPannel = () => {
    return (
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid container spacing={0}>
            <Grid item md={9} xs={12} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label={<span className="badgeMargin">Pending</span>} />
                <Tab label={<span className="badgeMargin">In Process</span>} />
                <Tab label={<span className="badgeMargin">Paid</span>} />
                <Tab label={<span className="badgeMargin">Reconsider</span>} />
                <Tab label={<span className="badgeMargin">All</span>} />
              </Tabs>
            </Grid>
          </Grid>
        </Box>

        <TabPanel value={value} index={0}>
          <PendingVouchers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InProcessVouchers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaidVouchers />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Reconsider />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AllVouchers />
        </TabPanel>
      </div>
    );
  };
  const adminTabPannel = () => {
    return (
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid container spacing={0}>
            <Grid item md={9} xs={12} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label={<span className="badgeMargin">In Process</span>} />
                <Tab label={<span className="badgeMargin">Paid</span>} />
                <Tab label={<span className="badgeMargin">Reconsider</span>} />
                <Tab label={<span className="badgeMargin">All</span>} />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
        <TabPanel value={value} index={0}>
          <InProcessVouchers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaidVouchers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Reconsider />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AllVouchers />
        </TabPanel>
      </div>
    );
  };
  useEffect(() => {
    getStoreDetails();
  }, []);
  return (
    <>
      {user?.role === "STORE" && (
        <Grid
          container
          md={8}
          sx={{
            backgroundColor: "#f9f9f9",
            p: 3,
            display: "flex",
            justifyContent: "space-around",
            float: "right",
            marginRight: 2,
            mt: 2,
          }}
        >
          <div className="balance-card">
            <h6 className="physical-balance-title">Physical Balance</h6>
            <h5>
              &#8377;
              {storeDetails?.properties?.pettyExpense?.physicalBalance
                ? `${storeDetails.properties.pettyExpense.physicalBalance}`
                : 0}
            </h5>
          </div>
          <div className="balance-card">
            <h6 className="inprocess-balance">Inprocess Balance</h6>
            <h5>
              &#8377;{" "}
              {storeDetails?.properties?.pettyExpense?.bookBalance -
                storeDetails?.properties?.pettyExpense?.physicalBalance || 0}
            </h5>
          </div>
          <div className="balance-card">
            <h6 className="book-balance-title">Book Balance</h6>
            <h5>
              &#8377;{" "}
              {storeDetails?.properties?.pettyExpense?.bookBalance
                ? storeDetails.properties.pettyExpense.bookBalance
                : 0}
            </h5>
          </div>
        </Grid>
      )}

      {user?.role === "STORE"
        ? storeTabPannel()
        : user?.role === "admin"
        ? adminTabPannel()
        : tabPannel()}
    </>
  );
};

export default PettyExpense;
