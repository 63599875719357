export const paniniPressType = [{ label: "None", value: "None" }];
export const buildingSignMaker = [
  { label: "EM Signs", value: "EM Signs" },
  { label: "Everbrite", value: "Everbrite" },
  { label: "Local", value: "Local" },
  { label: "None", value: "None" },
];
export const buildingSign = [
  { label: "Awning", value: "Awning" },
  { label: "Box", value: "Box" },
  { label: "Channel", value: "Channel" },
  { label: "Channel Halo Lit", value: "Channel Halo Lit" },
  {
    label: "Channel Halo Lit Brushed Aluminium",
    value: "Channel Halo Lit Brushed Aluminium",
  },
  { label: "Contour", value: "Contour" },
  { label: "None", value: "None" },
  { label: "Other", value: "Other" },
];
export const buildingSignLogo = [
  { label: "Old (Prior to 2002)", value: "Old (Prior to 2002)" },
  { label: "Slant (2002)", value: "Slant (2002)" },
  { label: "None", value: "None" },
];
export const poleSign = [
  { label: "Channel Halo Lit", value: "Channel Halo Lit" },
  { label: "Pole", value: "Pole" },
  { label: "Tenant/Pylon", value: "Tenant/Pylon" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];
export const poleSignMaker = [
  { label: "Local", value: "None" },
  { label: "None", value: "None" },
];
export const poleSignLogo = [
  { label: "Slant", value: "Slant" },
  { label: "None", value: "None" },
];
export const speedOven = [
  { label: "Merrychef 402s", value: "Merrychef 402s" },
  { label: "Merrychef e4", value: "Merrychef e4" },
  { label: "Merrychef e4s", value: "Merrychef e4s" },
  { label: "TurboChef Bullet", value: "TurboChef Bullet" },
  { label: "TurboChef Encore", value: "TurboChef Encore" },
  { label: "TurboChef Tornado", value: "TurboChef Tornado" },
  { label: "None", value: "None" },
];
export const sodaType = [
  { label: "Coke", value: "Coke" },
  { label: "Pepsi", value: "Pepsi" },
  { label: "Pepsi_Dr.Pepper", value: "Pepsi_Dr.Pepper" },
  { label: "None", value: "None" },
];
export const bubbler = [{ label: "None", value: "None" }];
export const menuType = [
  { label: "Backlit", value: "Backlit" },
  { label: "Combination", value: "Combination" },
  { label: "Digital", value: "Digital" },
  {
    label: "Digital and Traditional Mixed",
    value: "Digital and Traditional Mixed",
  },
  { label: "Frontlit", value: "Frontlit" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const menuOrientationType = [
  { label: "Left to Right", value: "Left to Right" },
  { label: "Right to Left", value: "Right to Left" },
  { label: "None", value: "None" },
];
export const menuLanguageType = [
  { label: "India English", value: "India English" },
  { label: "United States English", value: "United States English" },
  { label: "None", value: "None" },
];
export const electricityCycleType = [
  { label: "50 Hertz", value: "50 Hertz" },
  { label: "60 Hertz", value: "60 Hertz" },
  { label: "None", value: "None" },
];
export const electricityVoltage = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 4, value: 4 },
  { label: 208, value: 208 },
  { label: 220, value: 220 },
  { label: 240, value: 240 },
  { label: 380, value: 380 },
  { label: 415, value: 415 },
];
export const electricityLine = [
  { label: "Single Phase", value: "Single Phase" },
  { label: "Three Phase", value: "Three Phase" },
  { label: "None", value: "None" },
];
export const electricityWires = [
  { label: 0, value: 0 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
];
export const electricityAmps = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 12, value: 12 },
  { label: 100, value: 100 },
  { label: 150, value: 150 },
  { label: 200, value: 200 },
  { label: 300, value: 300 },
  { label: 400, value: 400 },
];
export const drivThru = [
  { label: "Viewable Drive Thru", value: "Viewable Drive Thru" },
  { label: "None", value: "None" },
];
export const television = [
  { label: "Customer Area", value: "Customer Area" },
  { label: "Service Area", value: "Service Area" },
  { label: "None", value: "None" },
];
export const fruizle = [
  { label: "Fruizle Conversion", value: "Fruizle Conversion" },
  { label: "Fruizle Express", value: "Fruizle Express" },
  { label: "Full Fruizle", value: "Full Fruizle" },
  { label: "Simplified Fruizle", value: "Simplified Fruizle" },
  { label: "None", value: "None" },
];
export const decorType = [
  { label: "Fresh Forward", value: "Fresh Forward" },
  { label: "Metro", value: "Metro" },
  { label: "Tuscany", value: "Tuscany" },
  { label: "Tuscany II", value: "Tuscany II" },
  { label: "None", value: "None" },
];
export const wallArtType = [
  { label: "Tuscany II Framed Square", value: "Tuscany II Framed Square" },
  { label: "Tuscany II Vertical", value: "Tuscany II Vertical" },
  {
    label: "Tuscany II Vertical and Framed Square",
    value: "Tuscany II Vertical and Framed Square",
  },
  { label: "Tuscany Wall Art", value: "Tuscany Wall Art" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];
export const upholsteredFurniture = [
  {
    label: "Upholstered with BLK Chairs,LoveSeats,Couches",
    value: "Upholstered with BLK Chairs,LoveSeats,Couches",
  },
  {
    label: "Upholstered with BRW Chairs,LoveSeats,Couches",
    value: "Upholstered with BRW Chairs,LoveSeats,Couches",
  },
  { label: "None", value: "None" },
];
export const soffit = [
  { label: "1 X 4 Hardwood Stained", value: "1 X 4 Hardwood Stained" },
  { label: "4 X 4 Hardwood Stained", value: "4 X 4 Hardwood Stained" },
  { label: "Built - Out", value: "Built - Out" },
  { label: "Pre-fabricated Straight", value: "Pre-fabricated Straight" },
  { label: "Pre-fabricated Wave", value: "Pre-fabricated Wave" },
  { label: "None", value: "None" },
];
export const paintedCeiling = [
  { label: "Black Bean", value: "Black Bean" },
  { label: "Blonde", value: "Blonde" },
  { label: "Wheat Grass", value: "Wheat Grass" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];
export const flooringCustomerArea = [
  { label: "(Metro) Ceramic Wood/Slate", value: "(Metro) Ceramic Wood/Slate" },
  { label: "(Metro) Vinyl Wood/Slate", value: "(Metro) Vinyl Wood/Slate" },
  { label: "Carpeting", value: "Carpeting" },
  { label: "NT Other", value: "NT Other" },
  {
    label: "Porcelain Stone - Curved Pattern",
    value: "Porcelain Stone - Curved Pattern",
  },
  {
    label: "Porcelain Stone - Perimeter Green",
    value: "Porcelain Stone - Perimeter Green",
  },
  {
    label: "Porcelain Stone - Random Pattern",
    value: "Porcelain Stone - Random Pattern",
  },
  { label: "Porcelain Tile", value: "Porcelain Tile" },
  { label: "Quarry Tile", value: "Quarry Tile" },
  { label: "Vinyl", value: "Vinyl" },
  { label: "Wood", value: "Wood" },
  { label: "None", value: "None" },
];
export const ceilingCustomerArea = [
  { label: "Acoustical Drop-in 2X2", value: "Acoustical Drop-in 2X2" },
  { label: "Acoustical Drop-in 2X4", value: "Acoustical Drop-in 2X4" },
  { label: "Decorative", value: "Decorative" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const ceilingServiceArea = [
  { label: "Acoustical Drop-in 2X2", value: "Acoustical Drop-in 2X2" },
  { label: "Acoustical Drop-in 2X4", value: "Acoustical Drop-in 2X4" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const wallArt = [
  { label: "Adjustable Recessed Down", value: "Adjustable Recessed Down" },
  { label: "NT Other", value: "NT Other" },
  { label: "Track Lighting (Banners)", value: "Track Lighting (Banners)" },
  { label: "None", value: "None" },
];
export const menuboard = [
  { label: "Attached Top Lighting", value: "Attached Top Lighting" },
  { label: "NT Other", value: "NT Other" },
  { label: "Recessed Wall Washers", value: "Recessed Wall Washers" },
  { label: "T-5 Track", value: "T-5 Track" },
  { label: "None", value: "None" },
];
export const lightingInfoServiceAea = [
  { label: "2 X 2 Acrylics", value: "2 X 2 Acrylics" },
  { label: "Bank", value: "Bank" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const counterFrontType = [
  { label: "Aster Technologies", value: "Aster Technologies" },
  { label: "Duke", value: "Duke" },
  { label: "Duke Tri-Channel", value: "Duke Tri-Channel" },
  { label: "Local Vendor", value: "Local Vendor" },
  { label: "NT Other", value: "NT Other" },
  { label: "Sudhir Shetty Design", value: "Sudhir Shetty Design" },
  { label: "None", value: "None" },
];
export const frontFinish = [
  {
    label: "(Metro) Wheat Decor Package",
    value: "(Metro) Wheat Decor Package",
  },
  { label: "Nt Other", value: "NT Other" },
  { label: "T2 Tiled Decor Pkg", value: "T2 Tiled Decor Pkg" },
  { label: "T2 Vacuform Decor Pkg", value: "T2 Vacuform Decor Pkg" },
  { label: "Veggie Panel/Ceramic Tile", value: "Veggie Panel/Ceramic Tile" },
  { label: "Veggie Panel/Laminate", value: "Veggie Panel/Laminate" },
  {
    label: "Veggie Panel Framed by Light Cherry Ceramic Tile",
    value: "Veggie Panel Framed by Light Cherry Ceramic Tile",
  },
  {
    label: "Veggie Panel Framed in Fonthill Pear Laminate",
    value: "Veggie Panel Framed in Fonthill Pear Laminate",
  },
  { label: "None", value: "None" },
];
export const frontGlass = [
  { label: "Curved", value: "Curved" },
  { label: "Double Bend", value: "Double Bend" },
  { label: "Hinged Day Cover", value: "Hinged Day Cover" },
  { label: "Lavi Flat Sneeze Guard", value: "Lavi Flat Sneeze Guard" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const backType = [
  { label: "Non Refrigerated", value: "Non Refrigerated" },
  { label: "NT Other", value: "NT Other" },
  { label: "Refrigerated", value: "Refrigerated" },
  {
    label: "Refrigerated and Non Refrigerated",
    value: "Refrigerated and Non Refrigerated",
  },
  { label: "None", value: "None" },
];
export const backDecor = [
  { label: "Fonthill Pear Laminate", value: "Fonthill Pear Laminate" },
  { label: "Hunter Green/Stainless ", value: "Hunter Green/Stainless" },
  { label: "Irish Maple/Stainless", value: "Irish Maple/Stainless" },
  { label: "NT Other", value: "NT Other" },
  { label: "Verde Legacy/Stainless", value: "Verde Legacy/Stainless" },
  { label: "None", value: "None" },
];
export const selfServeBeverage = [
  {
    label: "Fonthill Pear w/ Stainless Steel Top",
    value: "Fonthill Pear w/ Stainless Steel Top",
  },
  {
    label: "Irish Maple w/Stainless Steel Top",
    value: "Irish Maple w/Stainless Steel Top",
  },
  { label: "NT Other", value: "NT Other" },
  {
    label: "Verde Legacy w/Stainless Steel Top",
    value: "Verde Legacy w/Stainless Steel Top",
  },
  { label: "None", value: "None" },
];
export const booth = [
  {
    label: "BLK Quest Bar height stools/chairs w/Pear Tus 2",
    value: "BLK Quest Bar height stools/chairs w/Pear Tus 2",
  },
  {
    label: "Tuscany Wave Pattern Booths",
    value: "Tuscany Wave Pattern Booths",
  },
  { label: "Verde Legacy Green Booth", value: "Verde Legacy Green Booth" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const tableAndChairs = [
  {
    label: "Blk Quest chr w/cushions_Pear Tbl Tops - Tusc 2",
    value: "Blk Quest chr w/cushions_Pear Tbl Tops - Tusc 2",
  },
  {
    label: "Blk Quest Chr w/Fonthill Pear Tbl Tops-Tscany 2",
    value: "Blk Quest Chr w/Fonthill Pear Tbl Tops-Tscany 2",
  },
  {
    label: "Hunter Green Chairs w/ Hunter Green Table Tops",
    value: "Hunter Green Chairs w/ Hunter Green Table Tops",
  },
  {
    label: "Oak Hoop Yell Chairs w/ Yell Table Top",
    value: "Oak Hoop Yell Chairs w/ Yell Table Top",
  },
  {
    label: "Quest/Blk Par Chair w/ Col Cush w/ Tusc Wave Tbl",
    value: "Quest/Blk Par Chair w/ Col Cush w/ Tusc Wave Tbl",
  },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const counter = [
  {
    label: "Bar Stools or Quest Chairs w/ Tuscany Counter",
    value: "Bar Stools or Quest Chairs w/ Tuscany Counter",
  },
  {
    label: "BLK Quest Bar Height stools/chairs w/Pear Tus 2",
    value: "BLK Quest Bar Height stools/chairs w/Pear Tus 2",
  },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const wallCoveringServiceArea = [
  { label: "(Metro) Threads Tile", value: "(Metro) Threads Tile" },
  {
    label: "8X8 Grimaldi Tile in Musco Green_Curry Yellow",
    value: "8X8 Grimaldi Tile in Musco Green_Curry Yellow",
  },
  { label: "Brick Textured", value: "Brick Textured" },
  { label: "Butternut Brick", value: "Butternut Brick" },
  { label: "Quentin w/Tedlar", value: "Quentin w/Tedlar" },
  { label: "Tile Tuscany", value: "Tile Tuscany" },
  {
    label: "White 4 1/4 X 4 1/4 Ceramic Tile",
    value: "White 4 1/4 X 4 1/4 Ceramic Tile",
  },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const customerAreaLower = [
  { label: "(Metro) Red Rock", value: "(Metro) Red Rock" },
  { label: "(Metro) Spanish Moss", value: "(Metro) Spanish Moss" },
  {
    label: "(Metro) Spanish Moss _Red Rock",
    value: "(Metro) Spanish Moss _Red Rock",
  },
  { label: "Tuscan Stucco", value: "Tuscan Stucco" },
  { label: "Other", value: "Other" },
  { label: "NT Other", vaue: "NT Other" },
  { label: "None", value: "None" },
];
export const custAreaUpper = [
  { label: "(Metro) Light Green", value: "(Metro) Light Green" },
  { label: "(Metro) Light Green Red", value: "(Metro) Light Green Red" },
  { label: "(Metro) Red", value: "(Metro) Red" },
  { label: "Cityscape - Brown _Cream", value: "Cityscape - Brown _Cream" },
  {
    label: "Cityscape - Maize (glod on gold)",
    value: "Cityscape - Maize (glod on gold)",
  },
  { label: "NT Other", value: "NT Other" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];
export const lightingCustomerCaegory = [
  { label: "2X2 Acoustic", value: "2X2 Acoustic" },
  { label: "2X2 LED", value: "2X2 LED" },
  { label: "2X2 Parabolic", value: "2X2 Parabolic" },
  { label: "2X4 Acoustic", value: "2X4 Acoustic" },
  { label: "2X4 LED", value: "2X4 LED" },
  { label: "2X4 Parabolic", value: "2X4 Parabolic" },
  { label: "None", value: "None" },
  { label: "NT Other", value: "NT Other" },
];
export const customerPendant = [
  { label: "(Metro) Amber Cloud", value: "(Metro) Amber Cloud" },
  { label: "(Metro) Rose Shade", value: "(Metro) Rose Shade" },
  {
    label: "(Metro) Rose Shade/Amber Cloud",
    value: "(Metro) Rose Shade/Amber Cloud",
  },
  { label: "Amber Pendant", value: "Amber Pendant" },
  { label: "Brass and Glass Hanging", value: "Brass and Glass Hanging" },
  { label: "Standard Tuscany Pendant", value: "Standard Tuscany Pendant" },
  { label: "NT Other", value: "NT Other" },
  { label: "None", value: "None" },
];
export const broadband = [
  { label: "Cable", value: "Cable" },
  { label: "DSL", value: "DSL" },
  { label: "Satellite", value: "Staellite" },
  { label: "None", value: "None" },
];
export const provider = [
  { label: "DLSNet", value: "DLSNet" },
  { label: "Earthlink", value: "Earthlink" },
  { label: "New Edge Networks", value: "New Edge Networks" },
  { label: "Qwest", value: "Qwest" },
  { label: "WilTel Communications", vaue: "WilTel Communications" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];
export const system1OS = [
  { label: 2000, value: 2000 },
  { label: "7 Pro", value: "7 Pro" },
  { label: "8 Pro", value: "8 Pro" },
  { label: "Vista", value: "Vista" },
  { label: "Win 10", value: "Win 10" },
  { label: "XP Pro", value: "XP Pro" },
  { label: "Other", value: "Other" },
];
export const system1makeOrModel = [
  { label: "Generic", value: "Generic" },
  { label: "HP 7800", value: "HP 7800" },
  { label: "J2 680(Asia)", value: "J2 680(Asia)" },
  {
    label: "Micros 2010",
    value: "Micros 2010",
  },
  { label: "Micros 2015", value: "Micros 2015" },
  { label: "PAR 6000", value: "PAR 6000" },
  { label: "PAR 7000", value: "PAR 7000" },
  { label: "INVALID", value: "INVALID" },
  { label: "None", value: "None" },
];
