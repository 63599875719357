import React, { useState, useRef, useContext } from "react";
import "./index.scss";
import { Divider, TextField, Button } from "@mui/material";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import Select from "react-select";
import { invokeApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { LISTING_TYPE } from "./config";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { validAddressTypes } from "../../utils/constants";
import { GlobalStoreContext } from "../../global-states/Context";
const columns = [
  { id: "addressType", label: "Address Type" },
  { id: "attn", label: "Attention" },
  { id: "address2", label: "Located In" },
  { id: "address1", label: "Address" },
  { id: "address3", label: "Street/PO Box" },
  { id: "country", label: "Country" },
  { id: "zipCode", label: "Postal Code" },
];
const communicationColumn = [
  { id: "communicationType", label: "Type" },
  { id: "value", label: "Value" },
];
const StoreAddressCommunication = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [listingType, setListingType] = useState("");
  const previousController = useRef();
  const [storeOptions, setStoreOptions] = useState([]);
  const [data, setData] = useState([]);
  const setDataHandler = () => {
    if (listingType === "address") {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_ADDRESS_LIST}${validAddressTypes.store}/${storeInfo.storeNo}`
      )
        .then((res) => {
          if (res.messsage) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setData(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (listingType === "communication") {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_COMMUNICATION_LIST}${validAddressTypes.store}/${storeInfo.storeNo}`
      )
        .then((res) => {
          if (res.messsage) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setData(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.storeNo,
          satelliteNum: p.satelliteNum,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    if (value?.trim()) {
      getData(value);
    } else {
      setStoreOptions([]);
    }
    setStoreInfo({
      sequenceNo: "",
      satelliteNo: "",
      storeNo: value ? value : "",
    });
  };
  return (
    <div className="store-memos-container">
      <Grid container md={12} className="search-container">
        <Grid item md={1.5}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            className="subs-autocomplete"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setData([]);
                  setStoreInfo((prevDetails) => ({
                    ...prevDetails,
                    storeNo: null,
                  }));
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                const { storeNo } = val;
                setStoreInfo((prevDetails) => ({ ...prevDetails, storeNo }));
              }
            }}
          />
        </Grid>

        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Listing Type"
            options={LISTING_TYPE}
            onChange={(e) => {
              setListingType(e.value);
              setData([]);
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Button
            size="small"
            variant="contained"
            onClick={setDataHandler}
            disabled={
              storeInfo.storeNo == null ||
              storeInfo.storeNo == "" ||
              listingType === ""
                ? true
                : false
            }
          >
            Get Data
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div className="memo-list-container">
        {listingType === "communication" ? (
          <StickyHeaderCon
            columnsList={communicationColumn}
            containerHeight="table-con"
            dataList={data}
          />
        ) : (
          <StickyHeaderCon
            columnsList={columns}
            containerHeight="table-con"
            dataList={data}
          />
        )}
      </div>
    </div>
  );
};
export default StoreAddressCommunication;
