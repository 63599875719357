import moment from "moment";
import { dateFun } from "../../../common-utilities/utils";

export const getHeaderConfig = () => {
  const list = [
    {
      label: "Store",
      key: "store",
      render: (data) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {data?.name} {data?.restaurantName && " -" + data.restaurantName}
          </div>
        );
      },
    },

    {
      label: "Store Type",
      key: "storeType",
      render: (data) => {
        return <div>{data?.storeType}</div>;
      },
    },
    {
      label: "Reason",
      key: "reason",
      render: (data, index) => {
        return <div>{data?.reason}</div>;
      },
    },

    {
      label: "Voucher Id",
      key: "voucherId",
      render: (data) => {
        return <div>{data?.voucher?.voucherId}</div>;
      },
    },
    {
      label: "Date",
      key: "createdAt",
      render: (data, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data?.createdAt).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
    },
    {
      label: "Amount",
      key: "amount",
      render: (data, index) => {
        return <div>{data?.amount}</div>;
      },
    },
    {
      label: "Physical Balance",
      key: "physicalBalance",
      render: (data, index) => {
        return <div>{data?.physicalBalance}</div>;
      },
    },
    {
      label: "Book Balance",
      key: "bookBalance",
      render: (data, index) => {
        return <div>{data?.bookBalance}</div>;
      },
    },
  ];
  return list;
};
