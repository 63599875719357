import React, { useState, useEffect } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { dateFun } from "../../../common-utilities/utils";

const Catering = ({ cateringAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(cateringAttributes);
    }
  }, [cateringAttributes]);

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    onChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onCheckboxChange = (value, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: value,
    });
    onChange({
      ...formData,
      [dataLabel]: value,
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    callCenterInfo = {},
    orderMethodInfo = {},
    refrigeratedStorageCapacityInfo = {},
    leadTimeHoursInfo = {},
  } = formData;

  return (
    <div className="basic-fixed-container">
      <Grid container>
        <Grid xs={6}>
          <div className="title">Catering</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Certified"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(formData.certifiedDate) || ""}
                name="certifiedDate"
                onChange={onInputChange}
              />
              <TextField
                label="Removed"
                type="date"
                className="subs-text-field"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(formData.removedDate) || ""}
                name="removedDate"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Removed Reason"
                className="subs-text-field"
                fullWidth
                value={formData.removedReason || ""}
                name="removedReason"
                onChange={onInputChange}
              />
              <TextField
                label="Delivery Method"
                className="subs-text-field"
                fullWidth
                value={formData.deliveryMethod || ""}
                name="deliveryMethod"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Catering Evaluation"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(formData.cateringEvaluation) || ""}
                name="cateringEvaluation"
                onChange={onInputChange}
              />
              <TextField
                label="EZ Cater URL"
                className="subs-text-field"
                fullWidth
                value={formData.EZCaterURL || ""}
                name="EZCaterURL"
                onChange={onInputChange}
              />
            </div>

            <div className="g-r-2">
              <TextField
                label="Call Center Signed On"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(callCenterInfo?.signedOndate) || ""}
                name="signedOndate"
                onChange={(event) =>
                  onInnerInputChange(event, "callCenterInfo")
                }
              />
              <TextField
                label="Call Center Removed"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(callCenterInfo?.removedDate) || ""}
                name="removedDate"
                onChange={(event) =>
                  onInnerInputChange(event, "callCenterInfo")
                }
              />
            </div>
            <div className="title">Order Method</div>
            <div className="form-details">
              <div className="g-r-2">
                <div className="g-r-2">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={orderMethodInfo?.isEmail || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !orderMethodInfo.isEmail,
                            "orderMethodInfo",
                            "isEmail"
                          );
                        }}
                      />
                    }
                    label="Email"
                    labelPlacement="end"
                  />
                  <TextField
                    fullWidth
                    value={orderMethodInfo?.email || ""}
                    className="subs-text-field"
                    name="email"
                    onChange={(event) =>
                      onInnerInputChange(event, "orderMethodInfo")
                    }
                  />
                </div>
                <div className="g-r-2">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={orderMethodInfo?.isFax || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !orderMethodInfo.isFax,
                            "orderMethodInfo",
                            "isFax"
                          );
                        }}
                      />
                    }
                    label="Fax"
                    labelPlacement="end"
                  />
                  <TextField
                    fullWidth
                    value={orderMethodInfo?.fax || ""}
                    className="subs-text-field"
                    name="fax"
                    onChange={(event) =>
                      onInnerInputChange(event, "orderMethodInfo")
                    }
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div className="g-r-2">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={orderMethodInfo?.isPhone || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !orderMethodInfo.isPhone,
                            "orderMethodInfo",
                            "isPhone"
                          );
                        }}
                      />
                    }
                    label="Phone"
                    labelPlacement="end"
                  />
                  <TextField
                    fullWidth
                    value={orderMethodInfo?.phone || ""}
                    className="subs-text-field"
                    name="phone"
                    onChange={(event) =>
                      onInnerInputChange(event, "orderMethodInfo")
                    }
                  />
                </div>
                <div className="g-r-2">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={orderMethodInfo?.POS || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !orderMethodInfo.POS,
                            "orderMethodInfo",
                            "POS"
                          );
                        }}
                      />
                    }
                    label="POS"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={orderMethodInfo?.receiptPrinter || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !orderMethodInfo.receiptPrinter,
                            "orderMethodInfo",
                            "receiptPrinter"
                          );
                        }}
                      />
                    }
                    label="Receipt Printer"
                    labelPlacement="end"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Refrigerated Storage Capacity</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Sandwich Platters"
                fullWidth
                value={refrigeratedStorageCapacityInfo?.sandwichPlatters || ""}
                name="sandwichPlatters"
                className="subs-text-field"
                onChange={(event) =>
                  onInnerInputChange(event, "refrigeratedStorageCapacityInfo")
                }
              />
              <TextField
                label="Giant Subs(3 foot)"
                fullWidth
                className="subs-text-field"
                value={refrigeratedStorageCapacityInfo?.giantSubs || ""}
                name="giantSubs"
                onChange={(event) =>
                  onInnerInputChange(event, "refrigeratedStorageCapacityInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="FootLongs"
                className="subs-text-field"
                fullWidth
                value={refrigeratedStorageCapacityInfo?.footlongs || ""}
                name="footlongs"
                onChange={(event) =>
                  onInnerInputChange(event, "refrigeratedStorageCapacityInfo")
                }
              />
              <TextField
                label="Boxed Lunches"
                className="subs-text-field"
                fullWidth
                value={refrigeratedStorageCapacityInfo?.boxedLunches || ""}
                name="boxedLunches"
                onChange={(event) =>
                  onInnerInputChange(event, "refrigeratedStorageCapacityInfo")
                }
              />
            </div>
            <div>Lead Time Hours</div>
            <div className="g-r-2">
              <TextField
                label="Sandwich Platters"
                className="subs-text-field"
                fullWidth
                value={leadTimeHoursInfo?.sandwichPlatters || ""}
                name="sandwichPlatters"
                onChange={(event) =>
                  onInnerInputChange(event, "leadTimeHoursInfo")
                }
              />
              <TextField
                label="Boxed Lunches"
                fullWidth
                className="subs-text-field"
                value={leadTimeHoursInfo?.boxedLunches || ""}
                name="boxedLunches"
                onChange={(event) =>
                  onInnerInputChange(event, "leadTimeHoursInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Cookie Platters"
                fullWidth
                className="subs-text-field"
                value={leadTimeHoursInfo?.cookiePlatters || ""}
                name="cookiePlatters"
                onChange={(event) =>
                  onInnerInputChange(event, "leadTimeHoursInfo")
                }
              />
              <TextField
                label="Giant Subs(3 foot)"
                fullWidth
                className="subs-text-field"
                value={leadTimeHoursInfo?.giantSubs || ""}
                name="giantSubs"
                onChange={(event) =>
                  onInnerInputChange(event, "leadTimeHoursInfo")
                }
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Catering;
