import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { invokeApi } from "../../utils/http-service";

export const getAllTicketCategoriesForaParent = (
  parent,
  setDataFunction,
  store
) => {
  const params = { parent, showZeroEscalation: true };
  if (store) {
    params.store = store;
  }
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.LIST_ALL_CATEGORY_NAMES}`,
    null,
    params
  )
    .then((res) => {
      const noErrorOccurred = !res?.message;
      if (noErrorOccurred && setDataFunction) {
        setDataFunction(
          res?.map((item) => {
            return {
              label: item.name,
              value: item.id,
              attachments: item.attachments || [],
            };
          }) || []
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
