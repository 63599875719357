import React, { useState, useEffect } from "react";
import { tokenMainKeys } from "../../../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import AdminMemos from "./admin-memo";
import Memos from "./memos";
import HomeRedirect from "../../../common-utilities/core/home-redirect";

const FranchiseMemo = () => {
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    const role = getOfflineData(tokenMainKeys.intranet, "user")?.role;
    setUserRole(role);
  }, []);
  return (
    <div>
      {userRole === "franchise" && <Memos />}
      {userRole === "admin" && <AdminMemos />}
    </div>
  );
};

export default FranchiseMemo;
