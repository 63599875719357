// Utils specific for creating and updating app documents categories modal dialog

import { HTTP_METHODS } from "../../../../utils/http-service";
import { pick, deleteObjProp } from "../../utils";
import { INTRANETHOSTNAME } from "../../utils/end-points";
import { REST_URLS } from "../../utils/end-points/app-document";
import { getFieldList } from "../main-apis";

export const setData = (data, setFormDetails) => {
  if (data) {
    const parent = data?.parent?.id || null;
    const isSubcategory = parent ? true : false;
    const allData = pick({ ...data, parent, isSubcategory }, [
      "name",
      "parent",
      "id",
      "isSubcategory",
      "departments",
    ]);

    allData.departments =
      allData?.departments?.map?.((item) => {
        return { label: item.name, value: item.id };
      }) || [];

    setFormDetails(allData);
  }
};

export const disableDocSave = (formDetails) => {
  return !formDetails.name || formDetails?.isSubcategory
    ? !formDetails.parent
    : false;
};

export const getCatApiDetails = (data) => {
  const payload = pick(data, ["parent", "name", "id", "departments"]);
  if (payload?.departments?.length) {
    payload.departments = payload.departments.map((item) => item.value);
  }
  const method = payload.id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const { id } = payload;
  const url = id
    ? `${INTRANETHOSTNAME}${REST_URLS.UPDATE_APP_DOCUMENT_CATEGORY}${id}`
    : `${INTRANETHOSTNAME}${REST_URLS.CREATE_APP_DOCUMENT_CATEGORY}`;
  const message = id
    ? "Category Updated Successfully"
    : "Category created successfully";
  deleteObjProp(["id"], payload);
  return { method, url, payload, message };
};

export const getParentList = (showModal, formDetails, slug, setParentList) => {
  if (!showModal) {
    return;
  }
  let additionalFilter = formDetails?.id
    ? { excludeCategories: JSON.stringify([formDetails?.id]) }
    : {};
  getFieldList(
    slug,
    { parentNull: true, ...additionalFilter },
    "select",
    setParentList
  );
};
