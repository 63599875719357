import { pick } from "../../../common-utilities/utils";
import { setProperty } from "../../../common-utilities/utils/misc";
import { dateFun } from "../../utils";
import { downloadReport, getStatusData } from "./apis";
import { pageView } from "./constants";
import { defaultFilters as mainFilters } from "./constants";
const defaultFilters = {
  excludeStatuses: JSON.stringify(["Dropped"]),
};

export const actionHandler = (
  type,
  data,
  setFilters,
  setTableData,
  setFltrsAfrClk
) => {
  switch (type) {
    case "download":
      {
        let params = pick(
          data,
          ["region", "status", "limit", "startDate", "endDate"],
          true
        );
        if (!params.limit) {
          return;
        }
        if (params.status === "Grand Total") {
          delete params.status;
        }

        if (params.region === "total") {
          delete params.region;
        }
        params = { ...params, ...defaultFilters };
        downloadReport(params);
      }
      break;
    case pageView.statusView:
      {
        let params = pick(
          data,
          ["region", "status", "limit", "startDate", "endDate"],
          true
        );
        if (params.status === "Grand Total" || !params.limit) {
          return;
        }

        if (params.region === "total") {
          params.region = null;
        }
        const keys = ["view", "status", "limit", "page", "region"];
        const values = [
          pageView.statusView,
          params.status,
          mainFilters.limit,
          1,
          params.region,
        ];
        setProperty(keys, values, setFilters);
        setProperty(keys, values, setFltrsAfrClk);
        params.limit = mainFilters.limit;
        params.sortBy = mainFilters.sortBy;
        getStatusData(params, setTableData);
      }
      break;
    default:
      break;
  }
};

export const getActualStageDate = (
  stageGates,
  stageName,
  formatDate = true
) => {
  const stage =
    stageGates?.filter?.((item) => {
      return item?.stage?.stageName === stageName;
    })?.[0] || {};
  if (formatDate) {
    return dateFun(stage?.actualDate);
  }
  return stage?.actualDate;
};
