import { Autocomplete, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { DeleteOutline } from '@mui/icons-material';
import { UOM_LIST } from '../helper';

const AddItemModal = ({
  poItem,
  lineItem,
  updateItem,
  onDelete,
  vendorCode,
  isItemsUploaded,
  isItemDeleted,
}) => {
  const [formDetails, setFormDetails] = useState({});
  const [items, setItems] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const { category = {} } = lineItem;
    if (category && category.id) {
      loadSubcategories(category);
    }
  }, []);

  useEffect(() => {
    if ((isItemsUploaded && !formDetails.item) || isItemDeleted) {
      const newFormDetails = {
        ...poItem,
      };

      if (!newFormDetails.uom) {
        newFormDetails.uom = UOM_LIST[0];
      } else if (typeof newFormDetails.uom === 'string') {
        newFormDetails.uom =
          UOM_LIST.find((item) => item.value === newFormDetails.uom) ||
          UOM_LIST[0];
      }
      if (isItemsUploaded) {
        setItems([{ ...poItem.item }]);
      }
      setFormDetails(newFormDetails);
    }
  }, [poItem, isItemDeleted]);

  const loadItems = (subcategory) => {
    const { id = '' } = subcategory || {};
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_ITEMS_BY_VENDOR}`,
      null,
      {
        page: 1,
        subCategory: id,
        vendorCode,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setItems(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadSubcategories = (category) => {
    const { id = '' } = category || {};
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}`,
      null,
      {
        page: 1,
        category: id,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setSubcategories(response.results || []);
        }
      })
      .catch((err) => {});
  };

  const getTaxAmount = () => {
    const { rate = 0, quantity = 0, taxPercentage = 0 } = formDetails;
    return (rate * quantity * (taxPercentage / 100)).toFixed(2);
  };

  const getAmount = () => {
    const { rate = 0, quantity = 0 } = formDetails;
    return (rate * quantity).toFixed(2);
  };

  const getTotalAmount = () => {
    const { rate = 0, quantity = 0, taxPercentage = 0 } = formDetails;
    let taxAmount = rate * quantity * (taxPercentage / 100);
    return (rate * quantity + +taxAmount).toFixed(2);
  };

  const onItemChange = (item) => {
    if (item) {
      setFormDetails({
        ...formDetails,
        item: item,
        rate: item.price,
        subCategory: item.subCategory,
        refRate: item.price,
        hsnCode: item.hsnCode || '',
        quantity: 1,
        taxPercentage: item.taxPercentage,
        uom: UOM_LIST.find((uom) => item.uom == uom.value) || UOM_LIST[0],
        itemId: item.id,
        lineItemId: lineItem.id,
      });

      onFormChange({
        ...formDetails,
        item: item,
        rate: item.price,
        refRate: item.price,
        subCategory: item.subCategory,
        itemId: item.id,
        lineItemId: lineItem.id,
        hsnCode: item.hsnCode || '',
        quantity: 1,
        taxPercentage: item.taxPercentage,
        uom: UOM_LIST.find((uom) => item.uom == uom.value) || UOM_LIST[0],
      });
    } else {
      setFormDetails({
        ...formDetails,
        item: {},
        subCategory: {},
        rate: 0,
        refRate: 0,
        hsnCode: '',
        quantity: 0,
        taxPercentage: 0,
      });
      onFormChange({
        ...formDetails,
        item: {},
        subCategory: {},
        rate: 0,
        refRate: 0,
        quantity: 0,
        hsnCode: '',
        taxPercentage: 0,
      });
    }
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });

    onFormChange({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onFormChange = (newFormDetails) => {
    const {
      category = {},
      subCategory = {},
      item = {},
      uom = {},
    } = newFormDetails;

    updateItem({
      id: `${lineItem.id}-${category.id}-${item.id}`,
      mainCategoryName: category.name,
      mainCategoryId: category.id,
      subcategoryName: subCategory.name,
      subcategoryId: subCategory.id,
      subCategory,
      name: item.name,
      uom: uom.value,
      quantity: newFormDetails.quantity,
      rate: newFormDetails.rate,
      item,
      taxRate: newFormDetails.taxPercentage,
      taxPercentage: newFormDetails.taxPercentage,
      hsnCode: newFormDetails.hsnCode || '',
      specification: item.specification,
      itemId: item.id,
      lineItemId: lineItem.id,
      refRate: item.price,
      lineItemName: lineItem.name,
      availableAmount: lineItem.availableAmount,
      description: newFormDetails.description,
      remarks: newFormDetails.remarks,
    });
  };

  return (
    <div className="po-add-items-row">
      <Autocomplete
        value={formDetails.subCategory || {}}
        onChange={(e, val) => {
          if (val) {
            setFormDetails({ ...formDetails, subCategory: val });
            loadItems(val);
          } else {
            setFormDetails((prevDetails) => ({ subCategory: null }));
          }
          onFormChange({
            category: formDetails.category,
            subCategory: val || {},
          });
        }}
        getOptionLabel={(op) => op.name || ''}
        renderInput={(params) => (
          <TextField {...params} size="small" label="Subcategory" />
        )}
        options={subcategories}
      />

      <Autocomplete
        value={formDetails.item || {}}
        onChange={(e, val) => {
          onItemChange(val);
        }}
        getOptionLabel={(op) => `${op.itemCode || ''} - ${op.name || ''}`}
        renderInput={(params) => (
          <TextField {...params} size="small" label="Select Item" />
        )}
        options={items}
      />
      <Autocomplete
        value={formDetails.uom || ''}
        onChange={(e, val) => {
          setFormDetails({ ...formDetails, uom: val || '' });
          onFormChange({ ...formDetails, uom: val || '' });
        }}
        renderInput={(params) => (
          <TextField {...params} size="small" label="UOM" />
        )}
        options={UOM_LIST}
      />

      <TextField
        size="small"
        label="HSN Code"
        name="hsnCode"
        value={formDetails.hsnCode || ''}
        onChange={onInputChange}
      />

      <TextField
        size="small"
        label="Quantity"
        type="number"
        name="quantity"
        value={formDetails.quantity || ''}
        onChange={onInputChange}
      />
      <TextField
        size="small"
        label="Rate"
        type="number"
        name="rate"
        value={formDetails.rate || ''}
        onChange={onInputChange}
      />
      <TextField
        size="small"
        label="Amount"
        type="number"
        value={getAmount() || ''}
        disabled
      />
      <TextField
        size="small"
        label="Tax Rate"
        type="number"
        name="taxPercentage"
        value={
          formDetails?.hasOwnProperty('taxPercentage')
            ? formDetails.taxPercentage
            : ''
        }
        onChange={onInputChange}
      />

      <TextField
        size="small"
        label="Tax Amount"
        type="number"
        value={getTaxAmount() || ''}
        disabled
      />
      <TextField
        size="small"
        label="Ref Rate"
        type="number"
        value={(formDetails.item && formDetails.item.price) || 0}
        disabled
      />
      <TextField
        size="small"
        label="Total Amount"
        type="number"
        value={getTotalAmount(formDetails) || 0}
        disabled
      />
      <TextField
        size="small"
        label="Description"
        name="description"
        value={formDetails.description || ''}
        onChange={onInputChange}
      />
      <TextField
        size="small"
        label="Remarks"
        name="remarks"
        value={formDetails.remarks || ''}
        onChange={onInputChange}
      />
      <IconButton
        disabled={lineItem.availableAmount <= 0}
        onClick={(event) => {
          event.stopPropagation();
          onDelete(lineItem);
        }}
      >
        <DeleteOutline color="primary" />
      </IconButton>
    </div>
  );
};

export default AddItemModal;
