export const formatDownloadReports = (data) => {
  return (
    data?.map?.((item) => {
      return {
        Id: item?.id,
        Code: item?.code,
        Name: item?.name,
        'Vendor Gst': item?.gst,
        'Vendor PAN Number': item?.pan,
        'Mobile Number': item?.vendorOnboardDetails?.mobile,
        'Pin Code': item?.pinCode,
        Email: item?.vendorOnboardDetails?.email,
        'Bank Name': item?.vendorOnboardDetails?.bankDetails?.bankName,
        'IFSC-A/C No.': item?.vendorOnboardDetails?.bankDetails?.accountNumber,
        'MSME Status': item?.vendorOnboardDetails?.msme,
      };
    }) || []
  );
};
