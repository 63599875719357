import { validDocumentTypes } from '../../../../utils/document-constants';

export { agreementAndPostAgreementDocsConfig } from './agreement-and-post-agreement-docs';

export const mfaRiderConfig = (formDetails, config, errors) => {
  if (!formDetails?.documentType) {
    return;
  }

  config[validDocumentTypes.mfaRider] = [
    ...config[validDocumentTypes.mfaRider],
    {
      label: 'Upload Document',
      name: 'docs',
      type: 'upload',
      value: formDetails.docs,
      error: errors.docs,
      helperText: errors.docs ? 'Required' : '',
      documentSavingKey: 'document',
      uploadType: validDocumentTypes.mfaRider,
      notUploadCheckBox: true,
      notUploadKey: 'notApplicable',
    },
  ];

  if (!formDetails.notApplicable) {
    config[validDocumentTypes.mfaRider].splice(
      1,
      0,
      {
        label: 'Start Date*',
        name: 'startDate',
        type: 'date',
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? 'Required' : '',
      },
      {
        label: 'Expiry Date*',
        name: 'endDate',
        type: 'date',
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? 'Required' : '',
      }
    );
  } else {
    delete formDetails.startDate;
    delete formDetails.endDate;
  }
};

export const preAgreementDocsConfig = (formDetails, config, errors) => {
  if (!formDetails?.documentType) {
    return;
  }
  config[validDocumentTypes.preAgreementDocs] = [
    ...config[validDocumentTypes.preAgreementDocs],

    {
      label: 'Upload Document',
      name: 'docs',
      type: 'upload',
      value: formDetails.docs,
      error: errors.docs,
      helperText: errors.docs ? 'Required' : '',
      documentSavingKey: 'document',
      uploadType: `${formDetails?.documentType?.value}`,
      notUploadCheckBox: true,
      notUploadKey: 'notApplicable',
    },
  ];

  if (!formDetails.notApplicable) {
    config[validDocumentTypes.preAgreementDocs].splice(1, 0, {
      label: 'Document Date*',
      name: 'startDate',
      type: 'date',
      value: formDetails.startDate,
      error: errors.startDate,
      helperText: errors.startDate ? 'Required' : '',
    });
  } else {
    delete formDetails.startDate;
  }
};
export const nocForGstConfig = (formDetails, config, errors) => {
  if (!formDetails?.documentType) {
    return;
  }

  config[validDocumentTypes.nocForGst] = [
    ...config[validDocumentTypes.nocForGst],
    {
      label: 'Name of the Landload*',
      name: 'nameOfLandload',
      type: 'text',
      value: formDetails.nameOfLandload,
      error: errors.nameOfLandload,
      helperText: errors.nameOfLandload ? 'Required' : '',
    },

    {
      label: 'Address*',
      name: 'address',
      type: 'text',
      value: formDetails.address,
      error: errors.address,
      helperText: errors.address ? 'Required' : '',
    },
    {
      label: 'Upload Document',
      name: 'docs',
      type: 'upload',
      value: formDetails.docs,
      error: errors.docs,
      helperText: errors.docs ? 'Required' : '',
      documentSavingKey: 'document',
      uploadType: validDocumentTypes.nocForGst,
    },
  ];
};
export const electricityBillInNameOfLandloadConfig = (
  formDetails,
  config,
  errors
) => {
  if (!formDetails?.documentType) {
    return;
  }

  config[validDocumentTypes.electricityBillInNameOfLandload] = [
    ...config[validDocumentTypes.electricityBillInNameOfLandload],
    {
      label: 'Name of the Landload*',
      name: 'nameOfLandload',
      type: 'text',
      value: formDetails.nameOfLandload,
      error: errors.nameOfLandload,
      helperText: errors.nameOfLandload ? 'Required' : '',
    },

    {
      label: 'Address*',
      name: 'address',
      type: 'text',
      value: formDetails.address,
      error: errors.address,
      helperText: errors.address ? 'Required' : '',
    },
    {
      label: 'Upload Document',
      name: 'docs',
      type: 'upload',
      value: formDetails.docs,
      error: errors.docs,
      helperText: errors.docs ? 'Required' : '',
      documentSavingKey: 'document',
      uploadType: validDocumentTypes.electricityBillInNameOfLandload,
    },
  ];
};
