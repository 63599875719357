export const updateGRNPayloadMapper = (
  poDetails,
  grnItems,
  attachments,
  invoiceUrls,
  formDetails
) => {
  const { id } = poDetails;
  let payload = {
    purchaseOrderId: +id,
    inwardDate: new Date().toISOString(),
    invoiceDate: formDetails.invoiceDate,
    financialyear: formDetails.financialyear,
    invoiceNumber: formDetails.invoiceNumber,
    invoiceAmount: formDetails.invoiceAmount,
    receivedItems: grnItems
      .filter((i) => i.receivedQuantity && i.receivedQuantity != 0)
      .map((item) => {
        return {
          poItemId: item.poItemId,
          receivedQuantity: item.receivedQuantity || 0,
          comments: item.comments || '',
          hsnCode: item?.hsnCode != undefined ? item.hsnCode : '',
        };
      }),
  };

  if (attachments.length > 0) {
    payload.additionalDocUrls = attachments.map((a) => a.docUrl);
  }
  if (invoiceUrls.length > 0) {
    payload.invoiceUrls = invoiceUrls.map((a) => a.docUrl);
  }
  if (formDetails.remarks) {
    payload.remarks = formDetails.remarks;
  }

  if (formDetails.inwardDate) {
    payload.inwardDate = formDetails.inwardDate;
  }

  return payload;
};
