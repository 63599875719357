import { Button, Chip, IconButton, TextField, Typography } from '@mui/material';
import { getOfflineData } from '../../../utils/offline-services';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  endPointsForProject,
  getProjectEndPoint,
} from '../../../../../routes/endpoints';

const CancelPo = ({ poDetails, setPODetails, navigateToDraft }) => {
  const user = getOfflineData('user');
  const navigate = useNavigate();
  const cancelPo = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CANCEL_PO}${poDetails?.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        toast.success('Purchase order cancelled successfully', {
          autoClose: 2000,
        });
        if (navigateToDraft) {
          navigate(
            `${getProjectEndPoint(endPointsForProject.subwayFinance)}?index=1`
          );
        }
        setPODetails((prevDetails) => ({
          ...prevDetails,
          status: 'cancelled',
        }));
      })
      .catch((err) => {
        toast.error(err.message, { autoClose: 2000 });
      });
  };

  return (
    <>
      {poDetails?.status === 'draft' &&
        parseInt(user?.id) === parseInt(poDetails?.createdBy) && (
          <Button variant="outlined" onClick={cancelPo}>
            Cancel
          </Button>
        )}
    </>
  );
};

export default CancelPo;
