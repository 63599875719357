import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export const nearbySubwayDataConfig = () => {
  const config = [
    { label: "Restaurant Number #", id: "restaurant", key: "restaurant" },
    { label: "Format", id: "format", key: "format" },
    { label: "Distance(in kms)", id: "distance", key: "distance" },
    { label: "Location", id: "location", key: "location" },
    { label: "Cluster", id: "cluster", key: "cluster" },
    { label: "ADS", id: "ads", key: "ads" },
    { label: "ADT", id: "adt", key: "adt" },
    { label: "APC", id: "apc", key: "apc" },
    { label: "Del %", id: "del", key: "del" },
    {
      label: "Actions",
      id: "Actions",
      render: (data, onclick) => {
        return (
          <div id={Math.random()}>
            <IconButton
              onClick={() => {
                onclick(data, "edit");
              }}
              sx={{ p: 0 }}
            >
              <EditIcon id="edit" />
            </IconButton>

            {Number.isInteger(parseInt(data.dataIndex)) && (
              <IconButton
                onClick={() => {
                  onclick(data, "delete");
                }}
                sx={{ p: 0 }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  return config;
};
