/** @format */

import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/subs-erp/routes/private-route";
import Setup from "../packages/subs-erp/components/setup";
import SubsReports from "../packages/subs-erp/components/reports";
import ProfileDetails from "../packages/subs-erp/components/profile-details";
import Store from "../packages/subs-erp/components/Store";
import Waiver from "../packages/subs-erp/components/waiver";
import WaiverDetails from "../packages/subs-erp/components/waiver-details";
import Transfer from "../packages/subs-erp/components/transfer";
import StoreInsurance from "../packages/subs-erp/components/store-insurance";
import StoreDrawingDetails from "../packages/subs-erp/components/store-drawings";
import FranchiseAgreement from "../packages/subs-erp/components/franchise-agreement";
import StoreAttributes from "../packages/subs-erp/components/store-attributes";
import LeaseRent from "../packages/subs-erp/components/lease-rent";
import Lease from "../packages/subs-erp/components/lease";
import LeaseMaintenance from "../packages/subs-erp/components/lease-maintainence";
import {
  StoreContext,
  PersonContext,
  LeaseContext,
  GlobalStoreContext,
} from "../packages/subs-erp/global-states/Context";

const SubsRoutes = () => {
  const [storeInfo, setStoreInfo] = StoreContext();
  const [personInfo, setPersonInfo] = PersonContext();
  const [leaseInfo, setLeaseInfo] = LeaseContext();
  return (
    <GlobalStoreContext.Provider
      value={{
        storeInfo,
        setStoreInfo,
        personInfo,
        setPersonInfo,
        leaseInfo,
        setLeaseInfo,
      }}
    >
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<ProfileDetails />} />
          <Route exact path="/store" element={<Store />} />
          <Route exact path="/store-attributes" element={<StoreAttributes />} />
          <Route exact path="/setup" element={<Setup />} />
          <Route exact path="/reports" element={<SubsReports />} />
          <Route exact path="/store-insurance" element={<StoreInsurance />} />
          <Route
            exact
            path="/store-drawing-details"
            element={<StoreDrawingDetails />}
          />
          <Route
            exact
            path="/franchise-agreement"
            element={<FranchiseAgreement />}
          />
          <Route exact path="/waiver" element={<Waiver />} />
          {/* <Route exact path="/waiver-details" element={<WaiverDetails />} /> */}
          <Route exact path="/transfer" element={<Transfer />} />
          <Route exact path="/lease-rent" element={<LeaseRent />} />
          <Route exact path="/lease" element={<Lease />} />
          {/* <Route exact path="/corporation" element={<Corporation />} /> */}
          <Route
            exact
            path="/lease-maintenance"
            element={<LeaseMaintenance />}
          />
        </Route>
      </Routes>
    </GlobalStoreContext.Provider>
  );
};

export default SubsRoutes;
