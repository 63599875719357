export const dayDifference = (date1, date2) => {
  if (
    !date1 ||
    !date2 ||
    `${new Date(date1)}` === 'Invalid Date' ||
    `${new Date(date2)}` === 'Invalid Date'
  ) {
    return 0;
  }

  const convertedDate1 = new Date(date1);
  const convertedDate2 = new Date(date2);
  const diffTime = Math.abs(convertedDate1 - convertedDate2);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  return diffDays.toFixed(2);
};

export const getStartOfDay = (date, convertToIsoString) => {
  if (!date || `${new Date(date)}` === 'Invalid Date') {
    return null;
  }
  const convertedDate = new Date(date);
  convertedDate.setHours(0, 0, 0, 0);
  if (convertToIsoString) {
    return convertedDate.toISOString();
  }
  return convertedDate;
};

export const getEndOfDay = (date, convertToIsoString) => {
  if (!date || `${new Date(date)}` === 'Invalid Date') {
    return null;
  }
  const convertedDate = new Date(date);
  convertedDate.setDate(convertedDate.getDate() + 1);
  convertedDate.setHours(0, 0, 0, 0);
  if (convertToIsoString) {
    return convertedDate.toISOString();
  }
  return convertedDate;
};

export const timeDifferenceInHours = (time1, time2, round = true) => {
  if (
    !time1 ||
    `${new Date(time1)}` === 'Invalid Date' ||
    !time2 ||
    `${new Date(time2)}` === 'Invalid Date'
  ) {
    return null;
  }
  const date1 = new Date(time1);
  const date2 = new Date(time2);
  let diff = (date2.getTime() - date1.getTime()) / (1000 * 60 * 60);
  if (round) {
    diff = diff.toFixed(2);
  }
  return diff;
};

export const addCorrectStartEndDate = (
  params,
  startDateKey = 'startDate',
  endDateKey = 'endDate'
) => {
  if (params?.[startDateKey]) {
    params[startDateKey] = getStartOfDay(params?.[startDateKey]);
  }
  if (params?.[endDateKey]) {
    params[endDateKey] = getEndOfDay(params?.[endDateKey]);
  }
};
