import { profitAndLossSelectValues } from ".";

export const storeDetails = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Format",
    value: "format",
  },
  {
    label: "Term of Lease (Yrs)",
    value: "termOfLeaseYrs",
  },
  {
    label: "Lockin Period (Yrs)",
    value: "lockinPeriodYrs",
  },
  {
    label: "Fit-out Period",
    value: "fitOutPeriod",
  },
  {
    label: "Chargeable Area (Sqft)",
    value: "chargeableAreaSqft",
  },
  {
    label: "Carpet Area (Sq ft)",
    value: "carpetAreaSqft",
  },
  {
    label: "No. of Seaters",
    value: "noOfSeaters",
  },
];

export const investmentOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: "Total Invt Incl SD",
    value: "totalInvtInclSd",
  },
  {
    label: "Total Invt Excl SD",
    value: "totalInvtExclSd",
  },
  {
    label: "R. Sec Deposit",
    value: "rSecDeposit",
  },
  {
    label: "Hardcost",
    value: "hardcost",
  },
  {
    label: "Softcost",
    value: "softcost",
  },
  {
    label: "LHI GC",
    value: "lhiGc",
  },
  {
    label: "LHI BO",
    value: "lhiBo",
  },
  {
    label: "Genset/DG",
    value: "gensetDg",
  },
  {
    label: "Servo",
    value: "servo",
  },
  {
    label: "Décor Range",
    value: "decorRange",
  },
  {
    label: "Fabrication",
    value: "fabrication",
  },
  {
    label: "BO Equipments",
    value: "boEquipments",
  },
  {
    label: "POS + Printer + IT + POSIST",
    value: "posPrinterItPosist",
  },
  {
    label: "Small ware",
    value: "smallWare",
  },
  {
    label: "Site Brokerage",
    value: "siteBrokerage",
  },
  {
    label: "Freight Charges",
    value: "freightCharges",
  },
  {
    label: "License cost",
    value: "licenseCost",
  },
  {
    label: "Design + Architect cost",
    value: "designArchitectCost",
  },
  {
    label: "LL Investment",
    value: "llInvestment",
  },
  {
    label: "Stamp duty & Registration",
    value: "stampDutyRegistration",
  },
  {
    label: "Franchise Fees",
    value: "franchiseFees",
  },
  {
    label: "Pre Opening",
    value: "preOpening",
  },
  {
    label: "Pre-opening Launch expense",
    value: "preOpeningLaunchExpense",
  },
  {
    label: "Compliance & Hygiene",
    value: "complianceHygiene",
  },
  {
    label: "Lawyer Fees for DD",
    value: "lawyerFeesForDd",
  },
];

export const profitAndLossOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: profitAndLossSelectValues.profitAndLossAll,
    value: profitAndLossSelectValues.profitAndLossAll,
  },
  {
    label: profitAndLossSelectValues.year1ProfitAndLoss,
    value: profitAndLossSelectValues.year1ProfitAndLoss,
  },
  {
    label: profitAndLossSelectValues.rocePreTax,
    value: profitAndLossSelectValues.rocePreTax,
  },
  {
    label: profitAndLossSelectValues.paybackWithSdYears,
    value: profitAndLossSelectValues.paybackWithSdYears,
  },
  {
    label: profitAndLossSelectValues.paybackwithoutSDyears,
    value: profitAndLossSelectValues.paybackwithoutSDyears,
  },
  {
    label: profitAndLossSelectValues.irr5years,
    value: profitAndLossSelectValues.irr5years,
  },
];

export const occupanyYear1Options = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: "MG Yr1 permonth",
    value: "mgYr1Permonth",
  },
  {
    label: "RS Dine In Yr1 %",
    value: "rsDineInYr1Percentage",
  },
  {
    label: "RS Del In Yr1 %",
    value: "rsDelInYr1Percentage",
  },
  {
    label: "CAM Yr1 pm in Rs",
    value: "camYr1PmInRs",
  },
  {
    label: "Other Property charges Yr1 pm",
    value: "otherPropertyChargesYr1Pm",
  },
];
