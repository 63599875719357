import { useState } from 'react';
import { truncateWords } from '../../utils/misc2';
import CustomModal from '../modal';
import './index.scss';
const LargeTextHandler = ({
  maxWords = 150,
  text,
  bodyClass = '',
  readMoreText = 'Read More',
  showTruncatedWords = true,
  showMoreClass = 'show-more',
}) => {
  const [largeText, setLargeText] = useState('');

  return (
    <>
      {maxWords && text?.toString()?.split(' ').length > maxWords ? (
        <span className="read-more-text">
          {showTruncatedWords && truncateWords(text, maxWords)}{' '}
          <span className={showMoreClass} onClick={() => setLargeText(text)}>
            {readMoreText}
          </span>
        </span>
      ) : (
        text
      )}
      {largeText && (
        <CustomModal
          bodyClass={bodyClass}
          onClose={() => {
            setLargeText('');
          }}
        >
          {largeText}
        </CustomModal>
      )}
    </>
  );
};

export default LargeTextHandler;
