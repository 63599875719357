import React, { useEffect, useState } from 'react';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { Autocomplete, Button, TextField } from '@mui/material';
import CustomModal from '../../../../common-utilities/core/modal';
import './index.scss';
import { exportToExcel } from '../../../../common-utilities/utils';
import { isCreateAllowed } from '../../../utils/common-utils';
import ConfirmModal from '../../../../common-utilities/core/confirm-modal';
import FileUpload from '../../../../common-utilities/core/file-uploader';
import DownloadTemplateButton from '../common-components/upload-template';
import { uploadTemplates } from '../constants';
import { toast } from 'react-toastify';
import { formatReports } from './utils';
import { setProperty } from '../../../../common-utilities/utils/misc';

const SubcategoryList = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    loadData(filters);
    loadCategory();
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty('page', response?.totalPages, setFilters);
          }
          setSubcategoryList(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadCategory = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}`,
      null,
      {
        limit: 10000,
        page: 1,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setCategoryList(
            (response.results || []).map((c) => {
              return { label: c.name, value: c.id, id: c.id };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    const { category } = formDetails;
    let payload = {
      name: formDetails.name,
      category: category?.value,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        setBudgetModal(false);
        loadData(filters);
        setFormDetails({});
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    const { category } = formDetails;
    let payload = {
      name: formDetails.name,
      category: category?.id,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        setBudgetModal(false);
        loadData(filters);
        setFormDetails({});
      })
      .catch((err) => {});
  };

  const editRecord = (data) => {
    const { category = {} } = data;
    setBudgetModal(true);
    setFormDetails(data);
    if (category.id) {
      setInputValue(category.name || '');
    }
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}`,
      null,
      { page: 1, limit: subcategoryList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(formatReports(response.results), 'subcategory-list');
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        loadData(filters);
        setDeleteDetails(null);
      })
      .catch((err) => {});
  };
  return (
    <div className="subcategory-list">
      <div className="scl-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <div className="left-container">
          {isCreateAllowed('master_data') && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#008938"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}subcategory`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />
              <DownloadTemplateButton type={uploadTemplates.subCategory} />
            </>
          )}

          <Button
            variant="outlined"
            disabled={!subcategoryList.totalResults}
            onClick={downloadData}
          >
            Download
          </Button>
          {isCreateAllowed('master_data') && (
            <Button variant="outlined" onClick={() => setBudgetModal(true)}>
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editRecord, setDeleteDetails)}
        dataList={subcategoryList.results || []}
        filters={filters}
        pageCount={subcategoryList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? 'Update Subcategory' : 'Create Subcategory'}
          disabledOutsideClick={true}
          onClose={() => {
            setBudgetModal(false);
          }}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name}
              name="name"
              onChange={onInputChange}
              size="small"
              label="Subcategory Name"
              fullWidth
            />

            <Autocomplete
              options={categoryList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Category" />
              )}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  category: val,
                });
              }}
            />
            {formDetails.id ? (
              <Button fullWidth variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={onSave}>
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default SubcategoryList;
