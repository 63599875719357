import React, { useEffect, useState } from 'react';
import { INTRANETHOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { Button, TextField } from '@mui/material';
import './index.scss';
import { exportToExcel } from '../../../../common-utilities/utils';

const DepartmentList = ({}) => {
  const [budgetList, setBudgetList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (params) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.DEPARTMENTS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          setBudgetList(response || []);
        }
      })
      .catch((err) => {});
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.DEPARTMENTS}`,
      null
    )
      .then((response) => {
        if (response.message) {
        } else {
          const results = response?.map?.((item) => {
            return {
              Id: item._id,
              Name: item?.name,
            };
          });
          if (results?.length) {
            exportToExcel(results, 'department-list');
          }

        }
      })
      .catch((err) => {});
  };

  const getDisplayData = () => {
    let offset = (filters.page - 1) * filters.limit;
    if (searchKey) {
      return budgetList
        .filter((b) =>
          (b.name || '').toLowerCase().includes(searchKey.toLowerCase())
        )
        .slice(offset, offset + filters.limit);
    } else {
      return budgetList.slice(offset, offset + filters.limit);
    }
  };

  return (
    <div className="department-list">
      <div className="dl-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <Button variant="outlined" onClick={downloadData}>
          Download
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={getDisplayData()}
        filters={filters}
        pageCount={Math.round(budgetList.length / filters.limit)}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default DepartmentList;
