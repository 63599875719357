import { Button, IconButton, TextField, Drawer } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

export const AddCommunicationAddressType = ({
  onClose,
  addressFlag,
  id,
  name,
  onSuccess
}) => {
  const [addressType, setAddressType] = useState(name ? name : "");
  const setAddressTypeDetailsOnChanges = (e) => {
    setAddressType(e.target.value);
  };

  const createAddressType = () => {
    if (!addressType || !addressType.trim()) {
      toast.error("Name is required", { autoClose: 2000 });
      return;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION_ADDRESS_TYPE}`,
      {
        name: addressType,
      }
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        } else {
          toast.success("Address Type created successfully", {
            autoClose: 2000,
          });
          if (onSuccess) {
            onSuccess()
          }
          onClose();
        }
      })
      .catch((err) => {
        toast.error(err.message, { autoClose: 2000 });
      });
  };
  const editAddressType = () => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_COMMUNICATION_ADDRESS_TYPE}${id}`,
      { name: addressType }
    ).then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      } else {
        onClose();
      }
    });
  };
  return (
    <>
      <Drawer anchor="right" open={addressFlag} onClose={() => onClose()}>
        <div className="person-add-corporation">
          <div>
            <div className="drawer-header">
              <span className="title">
                {id ? "Edit Address Type" : "Create Address Type"}
              </span>
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <CloseOutlined />
              </IconButton>
            </div>

            <div className="f-r-2">
              <div>
                <TextField
                  className="subs-text-field"
                  label="Name"
                  value={addressType}
                  name="name"
                  onChange={setAddressTypeDetailsOnChanges}
                />
              </div>
              <div>
                <Button
                  size="small"
                  onClick={id ? editAddressType : createAddressType}
                  variant="contained"
                  fullWidth
                  disabled={addressType === "" ? true : false}
                  sx={{ mt: 3 }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
