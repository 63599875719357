import React, { useState } from "react";
import "./index.scss";
import { TextareaAutosize, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const ShowMemo = ({ showMemoText, onClose }) => {
  const [memo, setMemo] = useState(showMemoText?.text);

  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Memo Description</span>

          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <TextareaAutosize
            minRows={30}
            placeholder="Memo"
            value={memo}
            style={{ width: "70rem", minWidth: "70rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowMemo;
