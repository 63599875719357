import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";

export const loadUsers = (parameters = {}, customLogic) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.GET_ALL_USER_NAME}`,
    null,
    parameters
  ).then((response) => {
    if (typeof customLogic === "function") {
      customLogic(response);
    }
  });
};
