import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TableCell from "@mui/material/TableCell";
import { actions } from "./actions";
export const mainTableConfig = () => {
  const config = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Head of the Department",
      id: "head",
      render: (data, onClick, index) => {
        return <div>{data?.head?.name}</div>;
      },
    },
    {
      label: "Roles",
      id: "roles",
      render: (data, onClick, index) => {
        return (
          <div key={data.id || index}>
            <RemoveRedEyeIcon
              onClick={() => onClick(data, actions.viewRoles)}
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick, index) => {
        return (
          <EditIcon
            onClick={() => onClick(data, actions.editDepartments)}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];
  return config;
};
