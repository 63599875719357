/** @format */

import * as React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { SHOW_CERTIFICATE, SIDE_MENU } from "./config";
import {
  REST_URLS,
  redirectToRoute,
} from "../../../common-utilities/utils/end-points/petty-expense";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";

export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(redirectToRoute);
  const [isCertificate, setIsCertificate] = React.useState(true);
  const user = getOfflineData("petty-expense", "user");
  const pettyExpenseEndpoint = getProjectEndPoint(
    endPointsForProject.pettyExpense
  );
  React.useEffect(() => {
    setActiveMenu(
      SIDE_MENU().find((m) => m.url === window.location.pathname) || {}
    );
  }, [SIDE_MENU, window.location.pathname]);
  const onMenuClick = () => {};
  const checkCertificate = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CHECK_CERTIFICATE}`)
      .then((res) => {
        if (res) {
          setIsCertificate(true);
        } else {
          setIsCertificate(false);
          navigate(`${pettyExpenseEndpoint}/cash-certificate`);
        }
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (user.role === "STORE") {
      checkCertificate();
    }
  }, []);
  return (
    <div>
      <div className="side-menu-v2-items">
        {isCertificate
          ? SIDE_MENU().map((menu, index) => {
              let showMenuOption = true;
              return (
                <>
                  {
                    <div
                      key={menu.mapkey}
                      className={
                        menu.value === activeMenu.value
                          ? "menu-item menu-item-active"
                          : "menu-item"
                      }
                      onClick={() => {
                        onMenuClick();
                        setActiveMenu(menu);
                        navigate(menu.url);
                      }}
                    >
                      <div className="menu-name align-center">
                        <span>{menu.logo}</span>
                        <span className="side-bar-menu-label">
                          {menu.label}
                        </span>
                      </div>
                    </div>
                  }
                </>
              );
            })
          : SHOW_CERTIFICATE().map((menu, index) => {
              let showMenuOption = true;
              return (
                <>
                  {
                    <div
                      key={menu.mapkey}
                      className={
                        menu.value === activeMenu.value
                          ? "menu-item menu-item-active"
                          : "menu-item"
                      }
                      onClick={() => {
                        onMenuClick();
                        setActiveMenu(menu);
                        navigate(menu.url);
                      }}
                    >
                      <div className="menu-name align-center">
                        <span>{menu.logo}</span>
                        <span className="side-bar-menu-label">
                          {menu.label}
                        </span>
                      </div>
                    </div>
                  }
                </>
              );
            })}
      </div>
    </div>
  );
}
