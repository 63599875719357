export const pageView = {
  home: "Home",
  statusView: "Status View",
};

export const defaultFilters = {
  view: pageView.home,
  limit: 10,
  sortBy: "-createdAt",
};
