import { useState, useEffect } from "react";
import "./index.scss";
import { getAppDetails } from "../main-apis";
import { Divider, Typography } from "@mui/material";
import HomeRedirect from "../../core/home-redirect";
import { FirstTab } from "./sub-components/first-tabs";
import { MainTabContainer, TabPanel } from "../../core/tabs";
import { setTabsConfig } from "./utils";
import { homeTabs } from "../constants";
import { CategoriesTab } from "./sub-components/categories";
import { defaultFilter } from "./config";

export const Home = ({
  slug,
  title = "Select Training Manual",
  mainFolderLabel = "Select Category",
  subFolderLabel = "Select Folder",
  tokenKey,
}) => {
  const [appDetails, setAppDetails] = useState({});
  const [value, setValue] = useState(0);
  const [tabsList, setTabsList] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [panelDetails, setPanelDetails] = useState({});
  const [modalDetails, setModalDetails] = useState({});

  const closeModal = () => {
    setModalDetails(null);
  };

  useEffect(() => {
    getAppDetails(slug, setAppDetails);
  }, [slug]);

  useEffect(() => {
    setTabsConfig(setPanelDetails, setTabsList, tokenKey);
  }, [tokenKey]);

  useEffect(() => {
    setFilter(defaultFilter);
  }, [value]);

  return (
    <div>
      <div className="training-manual-home">
        <HomeRedirect />
        <Typography
          variant="h5"
          color="primary"
          sx={{ textTransform: "uppercase" }}
        >
          {title}
        </Typography>
        <Divider color="primary" style={{ backgroundColor: "#008938" }} />
        {panelDetails?.showPanel && (
          <MainTabContainer
            value={value}
            setValue={setValue}
            labels={tabsList}
          />
        )}
        <TabPanel value={value} index={0}>
          <FirstTab
            appDetails={appDetails}
            slug={slug}
            closeModal={closeModal}
            filter={filter}
            setFilter={setFilter}
            tokenKey={tokenKey}
            modalDetails={modalDetails}
            setModalDetails={setModalDetails}
            mainFolderLabel={mainFolderLabel}
            subFolderLabel={subFolderLabel}
          />
        </TabPanel>

        {panelDetails?.showPanel &&
          panelDetails?.indexes?.[homeTabs.category] && (
            <TabPanel
              value={value}
              index={panelDetails?.indexes?.[homeTabs.category]}
            >
              {/* 
              departmentMapping
              If departmentMapping is true, then we allow department mapping against the categories
              */}
              <CategoriesTab
                slug={slug}
                setModalDetails={setModalDetails}
                modalDetails={modalDetails}
                closeModal={closeModal}
                departmentMapping={
                  !!appDetails?.properties?.document?.departmentMapping
                }
              />
            </TabPanel>
          )}
      </div>
    </div>
  );
};
