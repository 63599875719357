import { toast } from 'react-toastify';
import { FINANCE_HOSTNAME } from '../../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../../utils/http-service';

export const updateInvoiceData = (id, body, onSucess) => {
  invokeApi(
    HTTP_METHODS.PUT,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.INVOICE_DATA}/${id}`,
    body
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success('Payment Details updated successfully', {
        autoClose: 2000,
      });
      typeof onSucess === 'function' && onSucess(res);
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};
