import { profitAndLossSelectValues } from "../constants";
import {
  investmentConfig,
  occupancy1Config,
  storeDetailsConfig,
} from "../sub-config/coco-built/coco-built";

import { profitlossConfig } from "../sub-config/coco-built/profit-and-loss.config";

import {
  formattedDateRender,
  getSlicedNum,
  formatedPercentageRender,
} from "../utils";

export const cocoBuiltConfig = (filters) => {
  const config = [
    {
      label: "Approval Id",
      id: "id",
    },
    {
      label: "Opening Date",
      id: "openingDate",
      render: (data) => formattedDateRender(data, "openingDate"),
      isDateLabel: true,
    },
    {
      label: "Type",
      id: "storeType",
    },
    {
      label: "Store Code",
      id: "restaurantId",
    },
    {
      label: "State",
      id: "state",
    },
    {
      label: "City details",
      id: "city",
    },
    {
      label: "Location",
      id: "location",
    },
    {
      label: "Region",
      id: "cluster",
    },
    ...storeDetailsConfig(filters),
    ...investmentConfig(filters),
    ...occupancy1Config(filters),
    {
      label: "R. SD in months",
      id: "rSdInMonths",
      render: (data) => getSlicedNum(data, "rSdInMonths"),
    },
    {
      label: "Nearest Subway 1",
      id: "nearestSubway1",
    },
    {
      label: "Nearest Subway 2",
      id: "nearestSubway2",
    },
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.year1ProfitAndLoss,
      profitAndLossSelectValues.year1ProfitAndLoss
    ),
    ...profitlossConfig(
      filters,
      `${profitAndLossSelectValues.profitAndLossAll}-1`,
      profitAndLossSelectValues.profitAndLossAll
    ),
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.rocePreTax,
      profitAndLossSelectValues.rocePreTax
    ),
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.paybackWithSdYears,
      profitAndLossSelectValues.paybackWithSdYears
    ),
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.paybackwithoutSDyears,
      profitAndLossSelectValues.paybackwithoutSDyears
    ),
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.irr5years,
      profitAndLossSelectValues.irr5years
    ),
    ...profitlossConfig(
      filters,
      `${profitAndLossSelectValues.profitAndLossAll}-2`,
      profitAndLossSelectValues.profitAndLossAll
    ),
  ];

  return config;
};
