import AutoCompleteMultiSelect from "../../../../common-utilities/core/autocomplete-multiselect";
import { selectStoreType } from "../../Store/config";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { useEffect } from "react";

export const NewTicketCatFields = ({ mainSetData, data }) => {
  useEffect(() => {
    if (data?.storeType?.length && typeof data?.storeType?.[0] === "string") {
      const properStoreTypes = data?.storeType?.map((type) => {
        const storeType = selectStoreType.find((item) => item.value === type);
        return storeType;
      });
      setProperty("storeType", properStoreTypes, mainSetData);
    }
  }, [data?.storeType]);

  return (
    <>
      <AutoCompleteMultiSelect
        options={selectStoreType}
        value={data?.storeType || []}
        placeholder="Select Store Type"
        onChange={(e, val) => {
          setProperty("storeType", val, mainSetData);
        }}
      />
    </>
  );
};
