import { getValue } from "../../common-utilities/utils";
import { HOSTNAME, REST_URLS } from "./endpoints";
import { HTTP_METHODS, invokeApi } from "./http-service";

export const getSelectDcList = async (intranetTokenKey, label, value) => {
  const labelKey = label ? label : "label";
  const valueKey = value ? value : "value";
  let dcList = [];
  try {
    const results = await invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.DC_LIST}`,
      null,
      null,
      null,
      intranetTokenKey
    );
    dcList =
      results?.map?.((item) => {
        return {
          [valueKey]: item.id,
          [labelKey]: getValue(item.name, true),
          ...item,
        };
      }) || [];
  } catch (err) {
    console.log("Error occcurred while getting DC list", err);
  }
  return dcList;
};
