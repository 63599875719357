export const getHeaderConfig = () => {
  const config = [
    {
      label: "Status",
      id: "type",
    },
    {
      label: "North",
      id: "North",
      render: (data, onClick) => (
        <div
          key="North"
          onClick={() =>
            data["North"] > 0 &&
            onClick("download", {
              cluster: "North",
              type: data.type,
              limit: data["North"],
            })
          }
        >
          <span className={`${data["North"] && "pointer"}`}>
            {data && data["North"] > 0 ? data["North"] : 0}
          </span>
        </div>
      ),
    },
    {
      label: "South",
      id: "South",
      render: (data, onClick) => (
        <div
          key="South"
          onClick={() =>
            data["South"] > 0 &&
            onClick("download", {
              cluster: "South",
              type: data.type,
              limit: data["South"],
            })
          }
        >
          <span className={`${data["South"] && "pointer"}`}>
            {data && data["South"] > 0 ? data["South"] : 0}
          </span>
        </div>
      ),
    },
    {
      label: "East",
      id: "East",
      render: (data, onClick) => (
        <div
          key="East"
          onClick={() =>
            data["East"] > 0 &&
            onClick("download", {
              cluster: "East",
              type: data.type,
              limit: data["East"],
            })
          }
        >
          <span className={`${data["East"] && "pointer"}`}>
            {data && data["East"] > 0 ? data["East"] : 0}
          </span>
        </div>
      ),
    },
    {
      label: "West",
      id: "West",
      render: (data, onClick) => (
        <div
          key="West"
          onClick={() =>
            data["West"] > 0 &&
            onClick("download", {
              cluster: "West",
              type: data.type,
              limit: data["West"],
            })
          }
        >
          <span className={`${data["West"] && "pointer"}`}>
            {data && data["West"] > 0 ? data["West"] : 0}
          </span>
        </div>
      ),
    },
    {
      label: "Central",
      id: "Central",
      render: (data, onClick) => (
        <div
          key="Central"
          onClick={() =>
            data["Central"] > 0 &&
            onClick("download", {
              cluster: "Central",
              type: data.type,
              limit: data["Central"],
            })
          }
        >
          <span className={`${data["Central"] && "pointer"}`}>
            {data && data["Central"] > 0 ? data["Central"] : 0}
          </span>
        </div>
      ),
    },
    {
      label: "Total",
      id: "total",
      render: (data, onClick) => (
        <div
          key="total"
          onClick={() =>
            data["total"] > 0 &&
            onClick("download", {
              cluster: null,
              type: data.type,
              limit: data["total"],
            })
          }
        >
          <span className={`${data["total"] && "pointer"}`}>
            {data && data["total"] > 0 ? data["total"] : 0}
          </span>
        </div>
      ),
    },
  ];

  return config;
};

export const types = {
  approved: "CF Approved",
  pending: "CF Pending",
};

export const defaultData = [
  {
    type: types.approved,
    North: 0,
    South: 0,
    East: 0,
    West: 0,
    Central: 0,
    total: 0,
  },
  {
    type: types.pending,
    North: 0,
    South: 0,
    East: 0,
    West: 0,
    Central: 0,
    total: 0,
  },
];
