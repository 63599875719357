import { getPOAmountWithoutTax } from '../helper';

export const createPayloadMapper = (
  formDetails,
  items,
  budgetDetails,
  poType,
  documentLinks
) => {
  const {
    vendor = {},
    department = {},
    currency = {},
    deliveryLocation = {},
    billingLocation = {},
    company = {},
  } = formDetails;

  const { site = '' } = budgetDetails;
  let orderItems = [];
  Object.keys(items || {}).map((key) => {
    items[key].forEach((item) => {
      const { rate, quantity = 0, taxRate = 0 } = item;
      if (quantity) {
        let taxAmount = Math.round(rate * quantity * (taxRate / 100)).toFixed(
          2
        );
        orderItems.push({
          lineItem: key,
          item: item.itemId,
          uom: item.uom,
          quantity: item.quantity,
          rate: item.rate,
          amount: Math.round(rate * quantity).toFixed(2),
          taxRate: item.taxRate || 0,
          taxAmount: taxAmount,
          totalAmount: rate * quantity + +taxAmount,
          description: item.description,
          remarks: item.remarks,
          hsnCode: item.hsnCode || '',
        });
      }
    });
  });

  let payload = {
    company: company?.id,
    department: department?._id,
    site: site,
    vendorCode: vendor?.code,
    vendorName: vendor?.name,
    vendorPancard: vendor?.pan || 'Not found',
    vendorGst: vendor?.gst || 'Not found',
    poType: poType,
    currency: currency?.value,
    budget: budgetDetails?.id,
    poDate: formDetails?.poDate,
    remarks: formDetails?.remarks,
    termsAndConditions: formDetails.termsAndConditions,
    paymentTerms: formDetails.paymentTerms,
    agreement: formDetails.agreement,
    advance: {
      advancePercentage: formDetails.advancePercent || 0,
      advanceAmount: getAdvanceAmount(
        formDetails.advancePercent || 0,
        getPOAmountWithoutTax(items)
      ),
    },
    deliveryLocationId: deliveryLocation?.id,
    billingLocationId: billingLocation?.id,
    forex: formDetails.forex,
    items: orderItems,
  };

  if (documentLinks.length > 0) {
    payload.documentLinks = documentLinks.map((d) => d.docUrl);
  }

  return payload;
};

export const getAdvanceAmount = (poAmount, percentage) => {
  if (percentage > 0) {
    return (percentage * poAmount) / 100;
  } else {
    return 0;
  }
};

export const mapPoFileUploadResponse = (uploadedItems) => {
  return uploadedItems?.reduce?.((group, uploadedItem) => {
    const { lineItemId } = uploadedItem;
    uploadedItem.item = {...uploadedItem}
    uploadedItem.itemId = uploadedItem.id;
    uploadedItem.taxRate = uploadedItem.taxPercentage || 0;
    group[lineItemId] = group[lineItemId] ?? [];
    group[lineItemId].push(uploadedItem);

    return group;
  }, {}) || {};
};
