import { isValidEmail } from "../../../common-utilities/utils";

export const DEFAULT_VENDOR_FORM_EMAIL_TEMPLATE = `
<p>Dear Sir/Madam,</p>
<p>Greetings from Eversub India Private Limited!</p>
<p>Request you to please fill in vendor information form and enclosed scan copies of documents .</p>
<p>Thanks,</p>
<p>Subway-Team</p>
`;

export const isFormValid = (formDetails) => {
  const { email, subject, department } = formDetails;

  return !email || !subject || !isValidEmail(email) || !department;
};

export const DEFAULT_VENDOR_FORM_SUBJECT =
  "Submission of Vendor Onboarding Form";
