import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Button, Grid, TextareaAutosize, TextField } from "@mui/material";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { invokeApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { checkValidStoreCombination } from "../../utils";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const yearsOption = Array(51)
  .fill()
  .map((_, index) => index + 1)
  .map((iter, index) => {
    return { label: index.toString(), value: index };
  });

const monthsOption = Array(13)
  .fill()
  .map((_, index) => index + 1)
  .map((iter, index) => {
    return { label: index.toString(), value: index };
  });

const daysOption = Array(32)
  .fill()
  .map((_, index) => index + 1)
  .map((iter, index) => {
    return { label: index.toString(), value: index };
  });

const Lease3 = () => {
  const [respData, setRespData] = useState(null);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [storeOptions, setStoreOptions] = useState([]);
  const [leaseDetails, setLeaseDetails] = useState({ store: null });
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const [leaseIdList, setLeaseIdList] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const previousController = useRef();

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });

    setStoreOptions([]);
    setLeaseDetails({});
    setLeaseIdList([]);
    setSatteliteList([]);
    if (isCreate) {
      setIsCreate(false);
    }
  };

  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      militaryContract: leaseDetails.militaryContract,
      indicators: {
        initialTerm: {
          years: leaseDetails.initialYears,
          months: leaseDetails.initialMonths,
          days: leaseDetails.initialDays,
        },
        commencementDate: leaseDetails.commencementDate,
        leaseSignDate: leaseDetails.leaseSignDate,
        expirationDate: leaseDetails.expirationDate,
        noticeInMonths: leaseDetails.noticeInMonths,
        automaticRenewal: leaseDetails.automaticRenewal,
        noticeSentDate: leaseDetails.noticeSentDate,
        nonRenewApproval: leaseDetails.nonRenewApproval,
        nonRenewLetterSent: leaseDetails.nonRenewLetterSent,
      },
      status: leaseDetails.status,
      type: leaseDetails.type,
      misc: {
        nextRemodelDate: leaseDetails.nextRemodelDate,
        landLordToProvideNotice: leaseDetails.landLordToProvideNotice,
        radius: leaseDetails.radius,
        dateOfPossession: leaseDetails.dateOfPossession,
        breakClauseDate: leaseDetails.breakClauseDate,
      },
      options: {
        date: leaseDetails.optionDate,
        type: leaseDetails.optionType,
      },
      liabilityInfo: {
        clause: leaseDetails.liabilityClause,
        leaseSection: leaseDetails.liabilityLeaseSection,
        excessiveLiabilityApproval: leaseDetails.excessiveLiabilityApproval,
        effectiveDate: leaseDetails.liabilityEffectiveDate,
        cost: leaseDetails.liabilityCost,
        months: leaseDetails.liabilityMonths,
      },
      kickOutInfo: {
        clause: leaseDetails.kickOutClause,
        cost: leaseDetails.kickOutCost,
        effectiveDate: leaseDetails.kickOutEffectiveDate,
        leaseSection: leaseDetails.kickOutLeaseSection,
        months: leaseDetails.kickOutMonths,
        receivedDate: leaseDetails.kickOutReceivedDate,
      },
    };

    if (Number.isInteger(parseInt(leaseInfo.leaseId))) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_LEASE + leaseInfo.leaseId,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully");
            clearData(false);
          } else toast.error("Update Failed");
        })
        .catch((error) => {
          toast.error("Update Failed");
        });
    }
  };

  const createLeaseHandler = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      militaryContract: leaseDetails.militaryContract,
      indicators: {
        initialTerm: {
          years: leaseDetails.initialYears,
          months: leaseDetails.initialMonths,
          days: leaseDetails.initialDays,
        },
        commencementDate: leaseDetails.commencementDate,
        leaseSignDate: leaseDetails.leaseSignDate,
        expirationDate: leaseDetails.expirationDate,
        noticeInMonths: leaseDetails.noticeInMonths,
        automaticRenewal: leaseDetails.automaticRenewal,
        noticeSentDate: leaseDetails.noticeSentDate,
        nonRenewApproval: leaseDetails.nonRenewApproval,
        nonRenewLetterSent: leaseDetails.nonRenewLetterSent,
      },
      status: leaseDetails.status,
      type: leaseDetails.type,
      misc: {
        nextRemodelDate: leaseDetails.nextRemodelDate,
        landLordToProvideNotice: leaseDetails.landLordToProvideNotice,
        radius: leaseDetails.radius,
        dateOfPossession: leaseDetails.dateOfPossession,
        breakClauseDate: leaseDetails.breakClauseDate,
      },
      options: {
        date: leaseDetails.optionDate,
        type: leaseDetails.optionType,
      },
      liabilityInfo: {
        clause: leaseDetails.liabilityClause,
        leaseSection: leaseDetails.liabilityLeaseSection,
        excessiveLiabilityApproval: leaseDetails.excessiveLiabilityApproval,
        effectiveDate: leaseDetails.liabilityEffectiveDate,
        cost: leaseDetails.liabilityCost,
        months: leaseDetails.liabilityMonths,
      },
      kickOutInfo: {
        clause: leaseDetails.kickOutClause,
        cost: leaseDetails.kickOutCost,
        effectiveDate: leaseDetails.kickOutEffectiveDate,
        leaseSection: leaseDetails.kickOutLeaseSection,
        months: leaseDetails.kickOutMonths,
        receivedDate: leaseDetails.kickOutReceivedDate,
      },
    };
    invokeApi(HTTP_METHODS.POST, HOSTNAME + REST_URLS.POST_LEASE, body)
      .then((res) => {
        if (res.id) {
          toast.success("Created Successfully", { autoClose: 2000 });
          clearData(false);
        } else toast.error("Create Failed", { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error("Create Failed", { autoClose: 2000 });
      });
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const stateUpdate = (updatedValues) => {
    setLeaseDetails((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  const checkBoxChange = (name) => {
    let tempState = { ...leaseDetails };
    tempState[name] = tempState[name] === true ? false : true;
    setLeaseDetails(tempState);
  };

  const apply = (id) => {
    if (Number.isInteger(parseInt(id))) {
      invokeApi(
        HTTP_METHODS.GET,
        HOSTNAME + REST_URLS.GET_LEASE + id + "/" + storeInfo.storeNo
      ).then((res) => {
        if (res.code === 404) {
          toast.info("No Lease is Avaialble for the selected Store");
        } else {
          setRespData(res);
          let obj = {};
          obj["militaryContract"] = res?.storeLease?.militaryContract;
          obj["initialYears"] = res?.storeLease?.indicators?.initialTerm?.years;
          obj["initialMonths"] =
            res?.storeLease?.indicators?.initialTerm?.months;
          obj["initialDays"] = res?.storeLease?.indicators?.initialTerm?.days;
          obj["leaseSignDate"] = dateFun(
            res?.storeLease?.indicators?.leaseSignDate
          );
          obj["commencementDate"] = dateFun(
            res?.storeLease?.indicators?.commencementDate
          );
          obj["expirationDate"] = dateFun(
            res?.storeLease?.indicators?.expirationDate
          );
          obj["automaticRenewal"] =
            res?.storeLease?.indicators?.automaticRenewal;
          obj["noticeInMonths"] = res?.storeLease?.indicators?.noticeInMonths;
          obj["noticeSentDate"] = dateFun(
            res?.storeLease?.indicators?.noticeSentDate
          );
          obj["nonRenewApproval"] = dateFun(
            res?.storeLease?.indicators?.nonRenewApproval
          );
          obj["nonRenewLetterSent"] = dateFun(
            res?.storeLease?.indicators?.nonRenewLetterSent
          );
          obj["status"] = res?.storeLease?.status;
          obj["type"] = res?.storeLease?.type;
          obj["radius"] = res?.storeLease?.misc?.radius;
          obj["nextRemodelDate"] = dateFun(
            res?.storeLease?.misc?.nextRemodelDate
          );
          obj["landLordToProvideNotice"] =
            res?.storeLease?.misc?.landLordToProvideNotice;
          obj["dateOfPossession"] = dateFun(
            res?.storeLease?.misc?.dateOfPossession
          );
          obj["breakClauseDate"] = dateFun(
            res?.storeLease?.misc?.breakClauseDate
          );
          obj["optionDate"] = dateFun(res?.storeLease?.options?.date);
          obj["optionType"] = res?.storeLease?.options?.type;
          obj["liabilityClause"] = res?.storeLease?.liabilityInfo?.clause;
          obj["liabilityLeaseSection"] =
            res?.storeLease?.liabilityInfo?.leaseSection;
          obj["liabilityEffectiveDate"] = dateFun(
            res?.storeLease?.liabilityInfo?.effectiveDate
          );
          obj["excessiveLiabilityApproval"] = dateFun(
            res?.storeLease?.liabilityInfo?.excessiveLiabilityApproval
          );
          obj["liabilityCost"] = res?.storeLease?.liabilityInfo?.cost;
          obj["liabilityMonths"] = res?.storeLease?.liabilityInfo?.months;
          obj["kickOutClause"] = res?.storeLease?.kickOutInfo?.clause;
          obj["kickOutCost"] = res?.storeLease?.kickOutInfo?.cost;
          obj["kickOutEffectiveDate"] = dateFun(
            res?.storeLease?.kickOutInfo?.effectiveDate
          );
          obj["kickOutLeaseSection"] =
            res?.storeLease?.kickOutInfo?.leaseSection;
          obj["kickOutMonths"] = res?.storeLease?.kickOutInfo?.months;
          obj["kickOutReceivedDate"] = dateFun(
            res?.storeLease?.kickOutInfo?.receivedDate
          );
          obj["storeStatus"] = res?.store?.status;
          stateUpdate(obj);
        }
      });
    }
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...leaseDetails };
    tempState[key] = value;
    setLeaseDetails(tempState);
  };

  const onInputChange = (event, value, reason) => {
    setLeaseInfo({ leaseId: null });
    setSatteliteList([]);
    setSequenceList([]);
    setLeaseIdList([]);
    if (value) {
      getData(value);
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  const selectHandle = (name, value, fieldsToClear = {}) => {
    const clearedData = fieldsToClear ? fieldsToClear : {};
    let tempState = { ...leaseDetails, ...clearedData };
    tempState[name] = value;
    setLeaseDetails(tempState);
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumberAndSatelliteNumbersForStoreNumber(storeInfo.storeNo);
      listingLeaseHandler(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
      apply(leaseInfo.leaseId);
    }
  }, []);

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const listingLeaseHandler = (id, satelliteNo, sequenceNo) => {
    setStoreInfo({
      storeNo: id,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
    });
    const invalidStoreCombination = !checkValidStoreCombination(
      id,
      satelliteNo,
      sequenceNo
    );

    if (invalidStoreCombination) {
      return;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_LEASES}${id}`,
      null,
      {
        satelliteNo,
        sequenceNo,
      }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
          setLeaseIdList(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSequenceNumberAndSatelliteNumbersForStoreNumber = (id) => {
    if (Number.isInteger(parseInt(id))) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            const { satelliteNumbers, sequenceNumbers } = res;
            const satelliteLabelAndValuesForSelect =
              getLabelsAndValuesForSelect(satelliteNumbers, satelliteNumbers);

            const sequenceNumberLabelAndValuesForSelect =
              getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
            setSatteliteList(satelliteLabelAndValuesForSelect);
            setSequenceList(sequenceNumberLabelAndValuesForSelect);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="lease">
      <Grid container md={12} sx={{ mt: 5, mb: 0 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            id="free-solo-demo"
            className="subs-autocomplete"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={<ClearIcon font onClick={clearData} />}
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumberAndSatelliteNumbersForStoreNumber(val?.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("satelliteNo", val.value, { leaseId: null });
              listingLeaseHandler(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo
              );
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("sequenceNo", val.value, { leaseId: null });
              if (!isCreate) {
                listingLeaseHandler(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="lease Id"
              className="sel-item"
              options={leaseIdList}
              value={
                ![undefined, null, ""].includes(leaseInfo.leaseId)
                  ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                  : ""
              }
              onChange={(val) => {
                setLeaseInfo({ leaseId: val?.value });
                apply(val.value);
              }}
            />
          )}
        </Grid>
        <Grid item md={2} xs={6} style={{ height: "35px" }}>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2, float: "right" }}
                onClick={() => {
                  clearData(false);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("lease_details_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container md={12} xs={12}>
        <Grid item md={3}>
          <div className="title">Indicators</div>
          <div className="g-r-2">
            <div className="flex">
              <span className="input-title-paragrah">Spec Loc Rider</span>
              <Checkbox className="check" disabled />
            </div>
            <div className="flex">
              <span className="input-title-paragrah">Audit Hold</span>
              <Checkbox className="check" disabled />
            </div>
          </div>
          <div>
            <div>
              <span className="input-title-paragrah">Initial Term</span>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Years(s)"
                  options={yearsOption}
                  value={
                    ![undefined, null].includes(leaseDetails.initialYears)
                      ? yearsOption.find(
                          (i) => i.value == leaseDetails.initialYears
                        )
                      : ""
                  }
                  onChange={(val) => {
                    selectHandle("initialYears", val.value);
                  }}
                />
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Months(s)"
                  options={monthsOption}
                  value={
                    ![undefined, null].includes(leaseDetails.initialMonths)
                      ? monthsOption.find(
                          (i) => i.value == leaseDetails.initialMonths
                        )
                      : ""
                  }
                  onChange={(val) => {
                    selectHandle("initialMonths", val.value);
                  }}
                />
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Days(s)"
                  options={daysOption}
                  value={
                    ![undefined, null].includes(leaseDetails.initialDays)
                      ? daysOption.find(
                          (i) => i.value == leaseDetails.initialDays
                        )
                      : ""
                  }
                  onChange={(val) => {
                    selectHandle("initialDays", val.value);
                  }}
                />
              </Grid>
            </div>
          </div>
          <Grid className="g-r-2" sx={{ mt: 2 }}>
            <div>
              <p className="input-title-paragrah">Lease Sign Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.leaseSignDate) || ""}
                name="leaseSignDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title-paragrah">Commencement Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                value={dateFun(leaseDetails.commencementDate) || ""}
                name="commencementDate"
                onChange={handleChange}
                type="date"
              />
            </div>
          </Grid>
          <Grid className="g-r-2" sx={{ mt: 2 }}>
            <div>
              <p className="input-title-paragrah">Expiration Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.expirationDate) || ""}
                name="expirationDate"
                onChange={handleChange}
              />
            </div>
            <div className="flex">
              <span className="input-title-paragrah">Automatic Renewal</span>
              <Checkbox
                className="check"
                checked={leaseDetails.automaticRenewal || false}
                onChange={() => {
                  checkBoxChange("automaticRenewal");
                }}
              />
            </div>
          </Grid>
          <Grid className="g-r-2" sx={{ mt: 2 }}>
            <Select
              classNamePrefix="custom_select"
              className="sel-item"
              placeholder={
                <div style={{ fontSize: "12px" }}>Notice (Months)</div>
              }
              options={monthsOption}
              value={
                ![undefined, null].includes(leaseDetails.noticeInMonths)
                  ? monthsOption.find(
                      (i) => i.value == leaseDetails.noticeInMonths
                    )
                  : ""
              }
              onChange={(val) => {
                selectHandle("noticeInMonths", val.value);
              }}
            />
            <div className="flex">
              <span className="input-title-paragrah">Notice By Email</span>
              <Checkbox className="check" disabled />
            </div>
          </Grid>
          <Grid className="g-r-2" sx={{ mt: 2 }}>
            <div>
              <p className="input-title-paragrah">Notice Sent Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.noticeSentDate) || ""}
                name="noticeSentDate"
                onChange={handleChange}
              />
            </div>
            <div className="flex">
              <span className="input-title-paragrah">Month To Month</span>
              <Checkbox className="check" disabled />
            </div>
          </Grid>
          <Grid className="g-r-2" sx={{ mt: 2 }}>
            <div>
              <p className="input-title-paragrah">NonRenew.Approval</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.nonRenewApproval) || ""}
                name="nonRenewApproval"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title-paragrah">NonRenew. Letter Sent</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.nonRenewLetterSent) || ""}
                name="nonRenewLetterSent"
                onChange={handleChange}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={4} sx={{ ml: 5 }}>
          <div className="title">Types</div>
          <Grid sx={{ mt: 1, mb: 1 }}>
            <div className="input-form-container">
              <div className="content-container">
                <div className="g-r-2">
                  <TextField
                    label="Store Status Type"
                    className="sel-item subs-text-field"
                    variant="outlined"
                    value={leaseDetails.storeStatus || ""}
                    name="storeStatus"
                    onChange={handleChange}
                    disabled
                  />
                  <TextField
                    label="Lease Status Type"
                    className="sel-item subs-text-field"
                    variant="outlined"
                    value={leaseDetails.status || ""}
                    name="status"
                    onChange={handleChange}
                  />
                  {/* <Select classNamePrefix="custom_select"
                    className="sel-item"
                    placeholder="Lease Status Type"
                  /> */}
                </div>
                <div className="g-r-2 mt">
                  {/* <Select className="sel-item" placeholder="Lease Type"  classNamePrefix="custom_select" /> */}
                  <TextField
                    label="Lease Type"
                    className="sel-item subs-text-field"
                    variant="outlined"
                    value={leaseDetails.type || ""}
                    name="type"
                    onChange={handleChange}
                  />
                  <div className="flex">
                    <span className="input-title-paragrah">
                      Military Contract
                    </span>
                    <Checkbox
                      className="check"
                      checked={leaseDetails.militaryContract || false}
                      onChange={() => {
                        checkBoxChange("militaryContract");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <div className="title">Misc</div>
          <div className="g-r-2">
            <div>
              <p className="input-title-paragrah">Next Remodel Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.nextRemodelDate) || ""}
                name="nextRemodelDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title-paragrah">Breakclause Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.breakClauseDate) || ""}
                name="breakClauseDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2 mt">
            <div>
              <p className="input-title-paragrah">Date Of Possession</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.dateOfPossession) || ""}
                name="dateOfPossession"
                onChange={handleChange}
              />
            </div>
            <div className="flex">
              <span className="input-title-paragrah">
                Landloard To Provide Notice
              </span>
              <Checkbox
                className="check"
                checked={leaseDetails.landLordToProvideNotice || false}
                onChange={() => {
                  checkBoxChange("landLordToProvideNotice");
                }}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div className="flex">
              <span className="input-title-paragrah">Radius</span>
              <Checkbox
                className="check"
                checked={leaseDetails.radius || false}
                onChange={() => {
                  checkBoxChange("radius");
                }}
              />
            </div>
            <TextField
              label="Buildout Time(Days)"
              className="sel-item subs-text-field"
              variant="outlined"
              disabled
            />
          </div>
          <div className="g-r-2 mt">
            <div className="flex">
              <span className="input-title-paragrah">Gurantee</span>
              <Checkbox className="check" disabled />
            </div>
            <div className="flex">
              <span className="input-title-paragrah">
                Continuous Operation Clause
              </span>
              <Checkbox className="check" disabled />
            </div>
          </div>
          <Grid sx={{ mt: 2 }}>
            <div className="title">Options</div>
            <div className="g-r-2">
              {/* <Select placeholder="Option Type" classNamePrefix="custom_select" /> */}
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="text"
                label="Option Type"
                value={leaseDetails.optionType || ""}
                name="optionType"
                onChange={handleChange}
              />
              <div style={{ marginTop: "-25px" }}>
                <p className="input-title-paragrah">Date</p>
                <TextField
                  fullWidth
                  className="sel-item subs-text-field"
                  type="date"
                  value={dateFun(leaseDetails.optionDate) || ""}
                  name="optionDate"
                  onChange={handleChange}
                />
              </div>
            </div>
            <TextareaAutosize
              minRows={4}
              style={{ marginTop: 10, width: "93%" }}
              value={leaseDetails.comments || ""}
              name="comments"
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item md={4} sx={{ ml: 5 }}>
          <div className="title">Liability Information</div>
          <Grid sx={{ mt: 1, mb: 1 }}>
            <div className="input-form-container">
              <div className="content-container">
                <div className="g-r-2">
                  <div className="flex">
                    <span className="input-title-paragrah">Clause</span>
                    <Checkbox
                      className="check"
                      checked={leaseDetails.liabilityClause || false}
                      onChange={() => {
                        checkBoxChange("liabilityClause");
                      }}
                      disabled
                    />
                  </div>
                  <TextField
                    label="Lease Section"
                    className="sel-item subs-text-field"
                    variant="outlined"
                    value={leaseDetails.liabilityLeaseSection || ""}
                    name="liabilityLeaseSection"
                    onChange={handleChange}
                  />
                </div>
                <div className="g-r-2 mt">
                  <div>
                    <p className="input-title-paragrah">Effective Date</p>
                    <TextField
                      fullWidth
                      className="sel-item subs-text-field"
                      type="date"
                      value={dateFun(leaseDetails.liabilityEffectiveDate) || ""}
                      name="liabilityEffectiveDate"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <p className="input-title-paragrah">
                      Excessive Liability Approval
                    </p>
                    <TextField
                      fullWidth
                      className="sel-item subs-text-field"
                      type="date"
                      value={
                        dateFun(leaseDetails.excessiveLiabilityApproval) || ""
                      }
                      name="excessiveLiabilityApproval"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="g-r-2 mt">
                  <Select
                    classNamePrefix="custom_select"
                    placeholder="Liability Months"
                    options={monthsOption}
                    value={
                      ![undefined, null].includes(leaseDetails.liabilityMonths)
                        ? monthsOption.find(
                            (i) => i.value == leaseDetails.liabilityMonths
                          )
                        : ""
                    }
                    onChange={(val) => {
                      selectHandle("liabilityMonths", val.value);
                    }}
                  />
                  <TextField
                    label="Liability Cost"
                    className="sel-item subs-text-field"
                    type="number"
                    variant="outlined"
                    value={leaseDetails.liabilityCost || ""}
                    name="liabilityCost"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <div className="title">Kickout Information </div>
          <div className="g-r-2">
            <div className="flex">
              <span className="input-title-paragrah">Clause</span>
              <Checkbox
                className="check"
                checked={leaseDetails.kickOutClause || false}
                onChange={() => {
                  checkBoxChange("kickOutClause");
                }}
                disabled
              />
            </div>
            <TextField
              label="lease Section"
              fullWidth
              variant="outlined"
              className="sel-item subs-text-field"
              value={leaseDetails.kickOutLeaseSection || ""}
              name="kickOutLeaseSection"
              onChange={handleChange}
            />
          </div>
          <div className="g-r-2 mt">
            <div>
              <p className="input-title-paragrah">Effective Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.kickOutEffectiveDate) || ""}
                name="kickOutEffectiveDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title-paragrah">Received Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={dateFun(leaseDetails.kickOutReceivedDate) || ""}
                name="kickOutReceivedDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2 mt">
            <Select
              classNamePrefix="custom_select"
              placeholder="Kickout Months"
              options={monthsOption}
              value={
                ![undefined, null].includes(leaseDetails.kickOutMonths)
                  ? monthsOption.find(
                      (i) => i.value == leaseDetails.kickOutMonths
                    )
                  : ""
              }
              onChange={(val) => {
                selectHandle("kickOutMonths", val.value);
              }}
            />
            <TextField
              label="Kickout Cost"
              fullWidth
              variant="outlined"
              className="sel-item subs-text-field"
              type="number"
              value={`${
                ![null, undefined, NaN].includes(leaseDetails.kickOutCost)
                  ? leaseDetails.kickOutCost
                  : ""
              }`}
              name="kickOutCost"
              onChange={handleChange}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item md={2} xs={6} style={{ height: "35px" }}>
        <span
          style={{
            display: "flex",
            padding: "5px",
            borderRadius: "5px",
            float: "right",
            marginTop: "-5px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => {
              clearData(isCreate ? false : true);
            }}
          >
            {isCreate ? "Cancel" : "Reset"}
          </Button>
          {!isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                ![null, ""].includes(storeInfo.satelliteNo) &&
                ![null, ""].includes(storeInfo.sequenceNo) &&
                ![null, ""].includes(leaseInfo.leaseId)
                  ? false
                  : true
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button>
          )}

          {isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                storeInfo.satelliteNo !== null &&
                storeInfo.sequenceNo !== null
                  ? false
                  : true
              }
              onClick={() => {
                createLeaseHandler();
              }}
            >
              Save
            </Button>
          )}
        </span>
      </Grid>
    </div>
  );
};

export default Lease3;
