import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/tech-net/routes/private-route";
import Dashboard from "../packages/tech-net/components/Dashboard";
import Setup from "../packages/tech-net/components/Setup";
import {
  PastTickets,
  TicketDetails,
  CreateTicket,
} from "../packages/tech-net/components";
import TechNetReports from "../packages/tech-net/components/reports";
import InwardSales from "../packages/tech-net/components/inward-sales";

const TechnetRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoute />}>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/setup" element={<Setup />} />
        <Route exact path="/tickets" element={<PastTickets />} />
        <Route exact path="/details/:id" element={<TicketDetails />} />
        <Route exact path="/create" element={<CreateTicket />} />
        <Route exact path="/reports" element={<TechNetReports />} />
        <Route exact path="/sales" element={<InwardSales />} />
      </Route>
    </Routes>
  );
};

export default TechnetRoutes;
