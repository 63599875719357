import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import "./index.scss";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import moment from "moment";
import { Button, TextareaAutosize, Grid } from "@mui/material";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../../../utils/http-service";
import { toast } from "react-toastify";
import CommentIcon from "@mui/icons-material/Comment";
import PagesIcon from "@mui/icons-material/Pages";
const MemoTimeline = ({ timelineData, getTimelineData, memo }) => {
  const [data, setData] = useState([]);
  const [comment, setComment] = useState("");
  useEffect(() => {
    setData(timelineData);
  }, [timelineData]);
  const addComment = () => {
    if (memo?.id) {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.Add_MEMO_COMMENT}`, {
        memoId: memo.id,
        comment,
      })
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            getTimelineData(memo?.id);
            setComment("");
          }
        })
        .catch((err) => toast.error(err, { autoClose: 2000 }));
    }
  };
  return (
    <div className="memo-timeline">
      <Grid container md={12} spacing={2} className="memo-timeline-details">
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <Grid item md={6}>
            <p className="p-header">Applied Date</p>
          </Grid>
          <Grid item md={6} sx={{ minWidth: "fit-content" }}>
            <p className="p-header content">{memo?.appliedDate}</p>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <Grid item md={6}>
            <p className="p-header">Category</p>
          </Grid>
          <Grid item md={6} sx={{ minWidth: "fit-content" }}>
            <p className="p-header content">{memo?.category}</p>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <Grid item md={6}>
            <p className="p-header">Deposition</p>
          </Grid>
          <Grid item md={6} sx={{ minWidth: "fit-content" }}>
            <p className="p-header content">{memo?.deposition}</p>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <Grid item md={6}>
            <p className="p-header">Memo Date</p>
          </Grid>
          <Grid item md={6} sx={{ minWidth: "fit-content" }}>
            <p className="p-header content">{memo?.memoDate}</p>
          </Grid>
        </Grid>
      </Grid>

      <div className="timeline-con">
        <Timeline position="alternate">
          {data.map((ele) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  {moment(ele?.updatedAt).format("DD-MM-YYYY")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    {ele?.reason === "Comment added" ? (
                      <CommentIcon />
                    ) : (
                      <PagesIcon />
                    )}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {ele?.reason === "Comment added" ? (
                    <div>
                      <p className="content-reason">{ele?.reason}</p>
                      <div className="timeline-content">{ele?.comment}</div>
                    </div>
                  ) : (
                    <div>
                      <p className="content-reason">{ele?.reason}</p>
                      <div className="timeline-content">
                        {ele?.reasonDetails}
                      </div>
                    </div>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
      <div className="comments-section">
        <TextareaAutosize
          minRows={5}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={memo?.isClosed}
          style={{
            width: "99%",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={addComment}
          disabled={!comment}
          sx={{ float: "right" }}
        >
          Add Comment
        </Button>
      </div>
    </div>
  );
};

export default MemoTimeline;
