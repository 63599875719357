import React, { useState, useRef, useContext, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Select from "react-select";
import MainAttributes from "./main-attributes";
import AttributesPage2 from "./attributes-page2";
import DecAttributes from "./dec-attributes";
import PosTechAttributes from "./pos-tech-attributes";
import Catering from "./catering";
import Delivery from "./delivery";
import DigitalDirectDelivery from "./digital-direct-delivery";
import SaveIcon from "@mui/icons-material/Save";
import { invokeApi } from "../../utils/http-service";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";
import { GlobalStoreContext } from "../../global-states/Context";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import { deleteObjectProperties } from "../../../common-utilities/utils/misc";

const displayContainer = {
  display: "flex",
  gap: 20,
  alignItems: "center",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "75vh" }}
    >
      {value === index && (
        <Box style={{ padding: index == 1 ? "0px 10px" : "10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Attributes = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [value, setValue] = useState(0);
  const [storeAttributes, setStoreAttributes] = useState({});
  const [storeDeliveryAttributes, setstoreDeliveryAttributes] = useState({});
  const [decorAttributes, setDecorAttributes] = useState({});
  const [techAttributes, setTechAttributes] = useState({});
  const [cateringAttributes, setCateringAttributes] = useState({});
  const [satteliteList, setSatteliteList] = useState([]);
  const [attributeDetails, setAttributeDetails] = useState({});
  const [sequenceList, setSequenceList] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const previousController = useRef();
  const [isCreate, setIsCreate] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [storeId, setStoreId] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // clearDataOnTabChange(true);
  };
  useEffect(() => {
    if (storeInfo.storeNo || storeInfo.satelliteNo || storeInfo.sequenceNo) {
      onSearch(storeInfo.storeNo, storeInfo.satelliteNo, storeInfo.sequenceNo);
    }
  }, [value]);
  const clearDataOnTabChange = (removeCreateMode = true) => {
    setStoreOptions([]);
    setStoreAttributes({});
    setDecorAttributes({});
    setAttributeDetails({});
    setCateringAttributes({});
    setstoreDeliveryAttributes({});
    setTechAttributes({});
    setResetData(true);
    setTimeout(() => {
      setResetData(false);
    }, 500);
    if (removeCreateMode) {
      setIsCreate(false);
    }
    setRespData(null);
    clearData(true, false);
  };
  const checkBeforeStoreCombinationBeforeCreatingOrUpdate = () => {
    if (!Number.isInteger(parseInt(storeInfo.storeNo))) {
      toast.error("Please select store number", { autoClose: 2000 });
      return true;
    }
    if (!Number.isInteger(parseInt(storeInfo.satelliteNo))) {
      toast.error("Please select satellite number", { autoClose: 2000 });
      return true;
    }
    if (!Number.isInteger(parseInt(storeInfo.sequenceNo))) {
      toast.error("Please select sequence number", { autoClose: 2000 });
      return true;
    }
    return false;
  };

  const createNewDecorAttribute = () => {
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }

    const payload = {
      ...decorAttributes,
      storeNo:
        typeof storeInfo.storeNo === "string"
          ? storeInfo.storeNo?.trim()
          : storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    delete payload.createdAt;
    delete payload.updatedAt;
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_DECOR_ATTRIBUTES}`,
      payload
    ).then((response) => {
      if (!response?.message) {
        toast.success("Created successfully", {
          autoClose: 2000,
        });
        clearData(true, false);
      } else {
        toast.error(response?.message, { autoClose: 2000 });
        clearData(true, false);
      }
    });
  };

  const createNewTechAttributes = () => {
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }

    const payload = {
      ...techAttributes,
      storeNo:
        typeof storeInfo.storeNo === "string"
          ? storeInfo.storeNo?.trim()
          : storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    delete payload.createdAt;
    delete payload.updatedAt;

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_TECH_ATTRIBUTES}`,
      payload
    )
      .then((response) => {
        if (!response?.message) {
          toast.success("Created successfully", {
            autoClose: 2000,
          });
          clearData(true, false);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
          clearData(true, false);
        }
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
        clearData(true, false);
      });
  };

  const createNewDeliveryAttribute = () => {
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }
    const payload = removeEmptyElements(storeDeliveryAttributes);
    delete payload.id;
    payload.store =
      typeof storeInfo.storeNo === "string"
        ? storeInfo.storeNo?.trim()
        : storeInfo.storeNo;
    payload.satelliteNo = storeInfo.satelliteNo;
    payload.sequenceNo = storeInfo.sequenceNo;
    delete payload.createdAt;
    delete payload.updatedAt;
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_DELIVERY_ATTRIBUTE}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
          clearData(true, false);
          return;
        }
        toast.success("Created successfully", {
          autoClose: 2000,
        });
        clearData(true, false);
      })
      .catch((err) => {
        toast.err(err?.message, { autoClose: 2000 });
        clearData(true, false);
      });
  };

  const createNewStoreAttribute = () => {
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }

    const payload = {
      ...storeAttributes,
      storeNo:
        typeof storeInfo.storeNo === "string"
          ? storeInfo.storeNo?.trim()
          : storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    delete payload.createdAt;
    delete payload.updatedAt;

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_STORE_ATTRIBUTES}`,
      payload
    )
      .then((response) => {
        if (!response?.message) {
          toast.success("Store attriibute created successfully", {
            autoClose: 2000,
          });
          clearData(true, false);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
          clearData(true, false);
        }
      })
      .catch((err) => {
        clearData(true, false);
      });
  };
  const createNewCateringAttributes = () => {
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }

    const payload = {
      ...cateringAttributes,
      storeNo:
        typeof storeInfo.storeNo === "string"
          ? storeInfo.storeNo?.trim()
          : storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    delete payload.createdAt;
    delete payload.updatedAt;

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_CATERING_ATTRIBUTES}`,
      payload
    )
      .then((response) => {
        if (!response?.message) {
          toast.success("Created successfully", {
            autoClose: 2000,
          });
          clearData(true, false);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
          clearData(true, false);
        }
      })
      .catch((err) => {
        clearData(true, false);
      });
  };
  const getStoreDetailsById = (id, satelliteNo, sequenceNo) => {
    if (sequenceNo) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_STORE_ATTRIBUTES}${id}`,
        null,
        {
          satelliteNo: satelliteNo,
          sequenceNo: sequenceNo,
        }
      ).then((response) => {
        if (response?.message) {
          // toast.error(response?.message, { autoClose: 2000 });
          clearData(true, false);
          return;
        } else {
          setRespData(response);
          setStoreAttributes(response);
        }
      });
    }
  };

  const getDecorAttribute = (id, satelliteNo, sequenceNo) => {
    if (sequenceNo) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_DECOR_ATTRIBUTE}${id}`,
        null,
        {
          satelliteNo,
          sequenceNo,
        }
      ).then((response) => {
        if (response?.message) {
          // toast.error(response.message, { autoClose: 2000 });
          clearData(true, false);
          return;
        }
        setRespData(response);
        setDecorAttributes(response);
      });
    }
  };

  const getTechAttribute = (id, satelliteNo, sequenceNo) => {
    if (sequenceNo) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_TECH_ATTRIBUTE}${id}`,
        null,
        {
          satelliteNo: satelliteNo,
          sequenceNo: sequenceNo,
        }
      ).then((response) => {
        if (response?.message) {
          // toast.error(response.message, { autoClose: 2000 });
          clearData(true, false);
          return;
        }
        setRespData(response);
        setTechAttributes(response);
      });
    }
  };

  const getCateringAttribute = (id, satelliteNo, sequenceNo) => {
    if (sequenceNo) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_CATERING_ATTRIBUTES}${id}`,
        null,
        {
          satelliteNo: satelliteNo,
          sequenceNo: sequenceNo,
        }
      ).then((response) => {
        if (response?.message) {
          // toast.error(response.message, { autoClose: 2000 });
          clearData(true, false);
          return;
        }
        setRespData(response);
        setCateringAttributes(response);
      });
    }
  };

  function removeEmptyElements(obj) {
    if (Array.isArray(obj)) {
      obj.forEach((element, index) =>
        obj.splice(index, 1, removeEmptyElements(element))
      );
      return obj;
    }
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, v]) =>
          Array.isArray(v)
            ? v.length !== 0
            : v !== null && v !== "" && v !== undefined
        )
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyElements(v) : v])
    );
  }
  const updateCateringDetails = () => {
    let payload = removeEmptyElements(cateringAttributes);

    delete payload.id;
    delete payload.createdAt;
    delete payload.updatedAt;
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }
    if (cateringAttributes?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.UPDATE_CATERING_ATTRIBUTES}${cateringAttributes.id}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message, { autoClose: 2000 });
            return;
          } else {
            toast.success("Catering details updated successfully", {
              autoClose: 2000,
            });
          }
          // clearData(true, false);
        })
        .catch((err) => {
          clearData(true, false);
          toast.error(err?.message, { autoClose: 2000 });
          console.log(err);
        });
    } else {
      toast.error("Create Attribute before Update", { autoClose: 2000 });
    }
  };
  const updateDetails = () => {
    let payload = removeEmptyElements(storeAttributes);
    delete payload.id;
    delete payload.createdAt;
    delete payload.updatedAt;

    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }
    if (storeAttributes.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.UPDATE_STORE_ATTRIBUTES}${storeAttributes.id}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message, { autoClose: 2000 });
            clearData(true, false);
          } else {
            toast.success("Store attributes updated successfully", {
              autoClose: 2000,
            });
            // clearData(true, false);
          }
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
          clearData(true, false);
        });
    } else {
      toast.error("Create Attribute before Update", { autoClose: 2000 });
    }
  };

  const updateDecorDetails = () => {
    let payload = removeEmptyElements(decorAttributes);
    delete payload.id;
    delete payload.createdAt;
    delete payload.updatedAt;
    if (decorAttributes?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.UPDATED_DECOR_ATTRIBUTE}${decorAttributes.id}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message, { autoClose: 2000 });
          } else {
            toast.success("Decor attributes updated successfully", {
              autoClose: 2000,
            });
          }
          // clearData(true, false);
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
          clearData(true, false);
        });
    } else {
      toast.error("Create Attribute before Update", { autoClose: 2000 });
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        setStoreId(p.id);
        return {
          title: p.storeNo,
          id: p.storeNo,
          satelliteNum: p.satelliteNum,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };
  const onInputChange = (event, value) => {
    setSatteliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
    } else {
      clearData();
    }
    setStoreInfo({
      storeNo: value ? value : "",
      satelliteNo: "",
      sequenceNo: "",
    });
  };

  const updateTechDetails = () => {
    let payload = removeEmptyElements(techAttributes);
    deleteObjectProperties(payload, [
      "id",
      "POSSoftware",
      "POSSystem",
      "POSLicensingInfoInfo",
      "createdAt",
      "updatedAt",
      "posSystemInfo.system1OS",
      "posSystemInfo.system1makeOrModel",
      "posSystemInfo.system2OS",
      "posSystemInfo.system2makeOrModel",
      "posSystemInfo.system3OS",
      "posSystemInfo.system3makeOrModel",
      "posSystemInfo.system4OS",
      "posSystemInfo.system4makeOrModel",
    ]);
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }
    if (techAttributes?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.UPDATED_TECH_ATTRIBUTE}${techAttributes.id}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message, { autoClose: 2000 });
          } else {
            toast.success("Technology attributes updated successfully", {
              autoClose: 2000,
            });
          }
          // clearData(true, false);
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
          clearData(true, false);
        });
    } else {
      toast.error("Create Attribute before Update", { autoClose: 2000 });
    }
  };

  const updateDeliveryDetails = () => {
    const allDetails = removeEmptyElements(storeDeliveryAttributes);
    let payload = {
      ...allDetails,
    };
    delete payload.id;
    delete payload.store;
    delete payload.createdAt;
    delete payload.updatedAt;
    const invalidCombination =
      checkBeforeStoreCombinationBeforeCreatingOrUpdate();
    if (invalidCombination) {
      return;
    }
    if (storeDeliveryAttributes?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.UPDATE_DELIVERY_ATTRIBUTES}${storeDeliveryAttributes.id}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message, { autoClose: 2000 });
          } else {
            toast.success("Delivery details updated successfully", {
              autoClose: 2000,
            });
          }
          // clearData(true, false);
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
          console.log(err);
          clearData(true, false);
        });
    } else {
      toast.error("Create Attribute before Update", { autoClose: 2000 });
    }
  };

  const getDeliveryAttributesByStoreId = (id, satelliteNo, sequenceNo) => {
    if (sequenceNo) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_DELIVERY_ATTRIBUTES_BY_STORE_ID}${id}`,
        null,
        {
          satelliteNo: satelliteNo,
          sequenceNo: sequenceNo,
        }
      )
        .then((response) => {
          if (response?.message) {
            // toast.error(response?.message, { autoClose: 2000 });
            clearData(true, false);
            return;
          } else {
            setRespData(response);
            setstoreDeliveryAttributes(response);
          }
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
        });
    }
  };
  const onSearch = (id, satelliteNo, sequenceNo) => {
    if (value === 2) {
      getDecorAttribute(id, satelliteNo, sequenceNo);
    } else if (value === 3) {
      getTechAttribute(id, satelliteNo, sequenceNo);
    } else if (value === 4) {
      getCateringAttribute(id, satelliteNo, sequenceNo);
    } else if (value === 5) {
      getDeliveryAttributesByStoreId(id, satelliteNo, sequenceNo);
    } else if (value === 6) {
      getDeliveryAttributesByStoreId(id, satelliteNo, sequenceNo);
    } else {
      getStoreDetailsById(id, satelliteNo, sequenceNo);
    }
  };
  const onUpdated = () => {
    if (isCreate && value === 2) {
      createNewDecorAttribute();
    } else if (isCreate && value == 3) {
      createNewTechAttributes();
    } else if (isCreate && value == 4) {
      createNewCateringAttributes();
    } else if (isCreate && (value == 0 || value == 1)) {
      createNewStoreAttribute();
      return;
    } else if ((isCreate && value === 5) || (isCreate && value === 6)) {
      createNewDeliveryAttribute();
      return;
    }

    if (!isCreate && value === 2) {
      updateDecorDetails();
    } else if (!isCreate && value === 3) {
      updateTechDetails();
    } else if (!isCreate && value === 4) {
      updateCateringDetails();
    } else if (value === 5) {
      updateDeliveryDetails();
    } else if (value === 6) {
      updateDeliveryDetails();
    } else if (!isCreate && (value == 0 || value == 1)) {
      updateDetails();
    }
  };

  const clearData = (removeCreateMode = true, reset = true) => {
    setStoreOptions([]);
    setStoreAttributes({});
    if (reset) {
      setStoreInfo({ storeNo: "", sequenceNo: "", satelliteNo: "" });
      setSatteliteList([]);
      setSequenceList([]);
    }
    setDecorAttributes({});
    setAttributeDetails({});
    setCateringAttributes({});
    setstoreDeliveryAttributes({});
    setTechAttributes({});
    setResetData(true);
    setTimeout(() => {
      setResetData(false);
    }, 500);
    if (removeCreateMode) {
      setIsCreate(false);
    }
  };

  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);

          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (storeInfo.storeNo) {
      listingLeaseHandler(storeInfo.storeNo);
    }
  }, []);
  return (
    <div className="store-details-container-att">
      <div style={displayContainer}>
        <Grid
          container
          md={12}
          spacing={1}
          // sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item md={4}>
            <Autocomplete
              className="w-100 subs-autocomplete"
              id="free-solo-demo"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              inputValue={storeInfo.storeNo + "" || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  const { storeNo } = val;
                  listingLeaseHandler(storeNo);
                  setStoreInfo({ ...storeInfo, storeNo: storeNo });
                  setStoreId(storeId);
                }
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Satellite"
              className="sel-item"
              options={satteliteList}
              value={
                ![undefined, null, ""].includes(storeInfo.satelliteNo)
                  ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo({
                  ...storeInfo,
                  satelliteNo: val?.value,
                });
                const validCombination = checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  val?.value
                );
                if (validCombination) {
                  if (!isCreate) {
                    onSearch(
                      storeInfo.storeNo,
                      val?.value,
                      storeInfo.sequenceNo
                    );
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence Number"
              className="sel-item"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo({
                  ...storeInfo,
                  sequenceNo: val?.value,
                });
                const validCombination = checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
                if (validCombination) {
                  if (!isCreate) {
                    onSearch(
                      storeInfo.storeNo,
                      storeInfo.satelliteNo,
                      val?.value
                    );
                  }
                }
              }}
            />
          </Grid>
        </Grid>
        <div className="v-divider"></div>
        {/* {!isCreate && (
          <Button
            size="small"
            variant="contained"
            onClick={() => clearData()}
            startIcon={<AutorenewIcon />}
          >
            Reset
          </Button>
        )} */}

        <div className="create-new-button-container">
          <Button
            size="small"
            variant="contained"
            onClick={onUpdated}
            sx={{ mr: 2 }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {isCreate && (
            <Grid
              xs={1}
              sx={{
                pr: 5,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsCreate(false);
                  clearData(true, false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          )}
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  clearData(false, false);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload(
                    "attribute_details_" + storeInfo.storeNo,
                    respData
                  );
                }}
                style={{ marginLeft: "20px" }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </div>
      </div>

      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ minHeight: "20px" }}
          >
            <Tab label="Main Attributes" className="tab" />
            <Tab label="Attribute Page 2" className="tab" />
            <Tab label="Decor Attributes" className="tab" />
            <Tab label="POS/Technology attributes" className="tab" />
            <Tab label="Catering" className="tab" />
            <Tab label="Delivery" className="tab" />
            {/* <Tab
              disabled={true}
              label="Delivery Provider Attributes"
              className="tab"
            /> */}
            <Tab label="Digital Direct Delivery" className="tab" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <MainAttributes
            storeAttributes={storeAttributes}
            onChange={(details) => setStoreAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AttributesPage2
            storeAttributes={storeAttributes}
            onChange={(details) => setStoreAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DecAttributes
            decorAttributes={decorAttributes}
            onChange={(details) => setDecorAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PosTechAttributes
            techAttributes={techAttributes}
            onChange={(details) => setTechAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Catering
            cateringAttributes={cateringAttributes}
            onChange={(details) => setCateringAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Delivery
            storeDeliveryAttributes={storeDeliveryAttributes}
            onChange={(details) => setstoreDeliveryAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          <DeliveryProvider resetData={resetData} />
        </TabPanel> */}
        <TabPanel value={value} index={6}>
          <DigitalDirectDelivery
            storeDeliveryAttributes={storeDeliveryAttributes}
            onChange={(details) => setstoreDeliveryAttributes(details)}
            resetData={resetData}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Attributes;
