import { TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.scss";
import { useState, useEffect } from "react";
export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e2e2e2; /* Replace with your desired border color */
    }
  }
`;

const AutocompleteComponent = ({
  label,
  onChange,
  onInputChange,
  options,
  disableClear,
  defaultValue = "",
  fullWidth = false,
  name = "default",
  value = null,
  noValue = false,
  className,
  disabled = false,
  textClass,
}) => {
  const [textVal, setTextVal] = useState("");
  const [autoCompVal, setAutoCompVal] = useState(null);
  useEffect(() => {
    if (typeof defaultValue === "string") {
      setTextVal(defaultValue);
    }
  }, [defaultValue]);

  // const getOptionLabel = (option) => {
  //   return option[labelKey];
  // };

  // const getOptionValue = (option) => {
  //   return option[valueKey];
  // };

  return (
    <>
      <StyledAutocomplete
        popupIcon={<KeyboardArrowDownIcon />}
        options={options}
        className={`${className ? className : ""}`}
        onChange={(e, val) => {
          setAutoCompVal(val);
          onChange && onChange({ ...e, target: { ...e.target, name } }, val);
        }}
        onInputChange={(e, val) => {
          setTextVal(val);
          onInputChange && onInputChange(e, val);
        }}
        disabled={disabled}
        value={noValue ? autoCompVal : value || null}
        inputValue={textVal}
        fullWidth={fullWidth}
        disableClearable={!!disableClear}
        name={`${name}`}
        // getOptionLabel={getOptionLabel}
        // getOptionValue={getOptionValue}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            className={`styled-text-field ${textClass ? textClass : ""}`}
            size="small"
          />
        )}
      />
    </>
  );
};

export default AutocompleteComponent;
