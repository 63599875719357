import { Autocomplete, TextField } from '@mui/material';
import {
  clearProperty,
  setProperty,
} from '../../../../common-utilities/utils/misc';
import Grid from '@mui/material/Grid';
import {
  approvalTypes,
  locationTypes,
  workFlowTypes,
} from '../config/constants';

const Filters = ({ config, filters, setFilters }) => {
  const autoCompleteLists = [
    { list: config.departmentList, key: 'department', label: 'Department' },
    { list: approvalTypes, key: 'approvalType', label: 'Approval Type' },
    { list: workFlowTypes, key: 'workFlowType', label: 'Work Flow Type' },
    { list: locationTypes, key: 'locationType', label: 'Location Type' },
  ];
  return (
    <>
      <Grid
        container
        md={12}
        sx={{
          display: 'flex',
          mt: 2,
          mb: 2,
          justifyContent: 'space-between',
          [`@media (max-width: 768px)`]: {
            gridGap: '20px',
          },
        }}
      >
        {autoCompleteLists.map((listConfig) => {
          const { list, key, label } = listConfig;
          return (
            <Grid item md={2} key={key}>
              <Autocomplete
                value={
                  filters?.[key] || filters?.[key] === 0
                    ? list.find((item) => item.value === filters?.[key])
                    : null
                }
                onChange={(e, val) => {
                  if (val?.value || val?.value === 0) {
                    setProperty([key, 'page'], [val?.value, 1], setFilters);
                    return;
                  }
                  setProperty('page', 1, setFilters);
                  clearProperty(key, setFilters);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={`${label}`}
                    sx={{
                      minWidth: 220,
                    }}
                  />
                )}
                options={list}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Filters;
