export const chartCategory = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const getTrendChartData = (yearlyTrends) => {
  const {
    overallMonthWise = [],
    overallMonthWiseByDC = [],
    overallMonthWiseByStore = [],
    overallMonthWiseByVendor = [],
  } = yearlyTrends || {};

  let overallMonthWiseChartData = Array(12).fill(0);
  let overallMonthWiseByDCChartData = Array(12).fill(0);
  let overallMonthWiseByStoreChartData = Array(12).fill(0);
  let overallMonthWiseByVendorChartData = Array(12).fill(0);

  overallMonthWise?.forEach((iter) => {
    overallMonthWiseChartData[chartCategory.indexOf(Object.keys(iter)[0])] =
      iter[Object.keys(iter)[0]];
  });

  overallMonthWiseByDC?.forEach((iter) => {
    overallMonthWiseByDCChartData[chartCategory.indexOf(Object.keys(iter)[0])] =
      iter[Object.keys(iter)[0]];
  });

  overallMonthWiseByStore?.forEach((iter) => {
    overallMonthWiseByStoreChartData[
      chartCategory.indexOf(Object.keys(iter)[0])
    ] = iter[Object.keys(iter)[0]];
  });

  overallMonthWiseByVendor?.forEach((iter) => {
    overallMonthWiseByVendorChartData[
      chartCategory.indexOf(Object.keys(iter)[0])
    ] = iter[Object.keys(iter)[0]];
  });

  return {
    overallMonthWiseChartData,
    overallMonthWiseByDCChartData,
    overallMonthWiseByStoreChartData,
    overallMonthWiseByVendorChartData,
  };
};

export const getTopIssues = (topIssues = {}) => {
  let issues = {
    store: {
      count: 0,
    },
    creditNotesStore: { count: 0 },
    creditNotesVendor: { count: 0 },
    faultAccepted: { count: 0 },
    faultRejected: { count: 0 },
    unresolvedTicketsByX: 0,
  };
  const {
    countByStore = [],
    countByCreditNotes = {},
    countByDcFaultAcceptanceAndRejection = {},
    countByVendorFaultAcceptanceAndRejection = {},
    unresolvedTicketsByX = 0,
  } = topIssues;

  const { creditNotesByStore = [], creditNotesByVendor = [] } =
    countByCreditNotes;

  const { faultAccepted, faultRejected } =
    countByVendorFaultAcceptanceAndRejection;

  const { faultAccepted: faultDCAccepted, faultRejected: faultDCRejected } =
    countByDcFaultAcceptanceAndRejection;

  if (countByStore && countByStore.length > 0) {
    issues.store = countByStore[0] || {};
  }
  if (creditNotesByStore && creditNotesByStore.length > 0) {
    issues.creditNotesStore = creditNotesByStore[0] || {};
  }

  if (creditNotesByVendor && creditNotesByVendor.length > 0) {
    issues.creditNotesVendor = creditNotesByVendor[0] || {};
  }

  if (faultAccepted && faultAccepted.length > 0) {
    issues.faultAccepted = faultAccepted[0] || {};
  }

  if (faultRejected && faultRejected.length > 0) {
    issues.faultRejected = faultRejected[0] || {};
  }

  if (faultDCAccepted && faultDCAccepted.length > 0) {
    issues.faultDCAccepted = faultDCAccepted[0] || {};
  }

  if (faultDCRejected && faultDCRejected.length > 0) {
    issues.faultDCRejected = faultDCRejected[0] || {};
  }
  if (unresolvedTicketsByX) {
    issues.unresolvedTicketsByX = unresolvedTicketsByX;
  }

  return issues;
};

const getTopIssuesByEntity = (byEntities) => {
  const issues = {
    admin: { count: 0 },
    complaintType: { count: 0 },
    dc: { count: 0 },
    byVendor: { count: 0 },
    byStore: { count: 0 },
  };

  const {
    byAdmin = [],
    byComplaintType = [],
    byDc = [],
    byStore = [],
    byVendor,
  } = byEntities;

  if (byAdmin && byAdmin.length > 0) {
    issues.admin = byAdmin[0] || {};
  }

  if (byComplaintType && byComplaintType.length > 0) {
    issues.complaintType = byComplaintType[0] || {};
  }

  if (byDc && byDc.length > 0) {
    issues.dc = byDc[0] || {};
  }

  if (byStore && byStore.length > 0) {
    issues.byStore = byStore[0] || {};
  }

  if (byVendor && byVendor.length > 0) {
    issues.byVendor = byVendor[0] || {};
  }

  return issues;
};

export const formatDashboardResults = (response) => {
  const {
    yearlyTrends = {},
    topIssues = {},
    top5CaseStatuses = {},
    byEntities,
  } = response;

  return {
    trendData: getTrendChartData(yearlyTrends),
    topIssues: getTopIssues(topIssues),
    top5CaseStatuses: top5CaseStatuses,
    byEntities: getTopIssuesByEntity(byEntities),
    byEntitiesOrginal:byEntities
  };
};
