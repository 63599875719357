import React, { useEffect, useState } from "react";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Button, Chip, IconButton, TextField, Typography } from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useHistory,
} from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { getFileName } from "../../../utils/common-utils";
import { checkIsApprover, checkUserRight } from "../../../utils/utils";
import InitAdvPayment from "./initi-adv-payment";
import {
  getHeaderPOItemsDetailsConfig,
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount,
} from "../helper";
import ApprovalInfo from "./approval-info";
import ApprovalStageDetails from "../../approval-stage-details";
import CancelPo from "../common-components/cancel-po-button";
import { getOfflineData } from "../../../utils/offline-services";
import { uploadAttachmentTypes } from "../../../utils/attachments";

const PODetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = getOfflineData("user");
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalId = searchParams.get("approvalId");
  const [approveRejectDetails, setApproveRejectionDetails] = useState(null);
  const [reconsiderDetails, setReconsiderDetails] = useState(null);
  const [approvalDetails, setApprovalDetails] = useState({});
  const [poDetails, setPODetails] = useState({});
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false,
  });
  const [advPaymentFlag, setAdvPaymentFlag] = useState(false);
  const [sentPoDetails, setSentPoDetails] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const {
    budget = {},
    items = [],
    advance = {},
    company = {},
    documentLinks = [],
    currency = {},
  } = poDetails;

  const { siteCode = "", siteName = "" } = budget || {};
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    approvalId && loadApprovalDetails();
  }, [approvalId]);

  useEffect(() => {
    if (
      (poDetails?.approvalId?.id && !approvalId) ||
      (poDetails?.approvalId?.id && approvalId != poDetails?.approvalId?.id)
    ) {
      setSearchParams({
        approvalId: poDetails?.approvalId?.id,
      });
    }
  }, [poDetails?.approvalId?.id]);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      null,
      {
        page: 1,
        limit: 10,
        _id: id,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          setPODetails(response.results[0] || {});
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.DEPARTMENTS}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setDepartmentList(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadApprovalDetails = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_APPROVALS}`,
      null,
      {
        _id: approvalId,
        archive: false,
      }
    )
      .then((response) => {
        if (response.length > 0) {
          setApprovalDetails(response[0]);
        }
      })
      .catch((err) => {});
  };

  const onApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVE}/${approvalDetails.id}`,
      null,
      {
        comment: approveRejectDetails.comment || "",
      }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          loadData();
          loadApprovalDetails();
          setApproveRejectionDetails(null);
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onReject = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REJECT}`,
      null,
      { comment: approveRejectDetails.comment || "", id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setApproveRejectionDetails(null);
          loadData();
          loadApprovalDetails();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onReconsider = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RECONSIDER}`,
      null,
      { comment: reconsiderDetails.comment, id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reconsider");
        } else {
          setReconsiderDetails(null);
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };

  const shortClose = (url, itemId) => {
    let params = {};
    if (itemId) {
      params.itemId = itemId;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${url}/${id}`,
      null,
      params
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          toast.info(response.response);
          loadData();
        }
      })
      .catch((err) => {});
  };

  const initAdvPayment = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.INITIATE_ADV_PAYMENT}${id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to initiate advance amount");
        } else {
          toast.info("Advance payment for po is initiated");
          loadData();
          setAdvPaymentFlag(false);
        }
      })
      .catch((err) => {});
  };

  const getPOasPdf = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_PO_PDF}${id}`,
      null,
      null,
      null,
      "blob"
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to print PO");
        } else {
          const _url = window.URL.createObjectURL(response);
          window.open(_url, "_blank");
        }
      })
      .catch((err) => {});
  };

  const sendForApproval = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SEND_FOR_APPROVAL}${id}`,
      null
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to send for approval");
        } else {
          toast.info("PO sent for approval");
          setSentPoDetails(response);
        }
      })
      .catch((err) => {});
  };

  const resendForApproval = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}${approvalDetails?.id}`,
      null
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to resend for approval");
        } else {
          toast.info("PO sent for approval");
          setSentPoDetails(response);
        }
      })
      .catch((err) => {});
  };

  const getDepartmentName = () => {
    if (poDetails && poDetails.department) {
      return (departmentList.find((d) => d._id === poDetails.department) || {})
        .name;
    }

    return "-";
  };

  return (
    <div className="po-details-container">
      <div className="po-details-header">
        <div className="back-title">
          <IconButton
            onClick={() => {
              navigate(-1);
              // navigate("/subway-finance?index=1&subIndex=5");
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography className="title">
            PO Details for {poDetails.poNumber}
          </Typography>
        </div>
        <div className="right-section">
          {parseInt(poDetails?.createdBy) ===
            parseInt(user?.id || user?._id) && (
            <>
              <CancelPo poDetails={poDetails} setPODetails={setPODetails} />
              {["draft"].includes(poDetails.status) && (
                <Button variant="outlined" onClick={sendForApproval}>
                  Send for Approval
                </Button>
              )}
              {["reconsider"].includes(poDetails.status) && (
                <Button variant="outlined" onClick={resendForApproval}>
                  Resend for Approval
                </Button>
              )}
              {["draft", "reconsider"].includes(poDetails.status) && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/subway-finance/purchase-order/edit/${id}`)
                  }
                >
                  Edit
                </Button>
              )}
            </>
          )}

          {/* {(poDetails.status === "approved" ||
            poDetails.status === "short_close") && (
            <Button variant="outlined" onClick={getPOasPdf}>
              Print PO
            </Button>
          )} */}
          <Button variant="outlined" onClick={getPOasPdf}>
            Print PO
          </Button>
          {getPurchaseOrderAmount(items) > 0 &&
            checkUserRight("advancePayment.create") &&
            advance &&
            advance.advancePercentage > 0 &&
            !advance?.grnReceived &&
            ["approved", "short_close"].includes(poDetails.status) &&
            ["not_initiated", "rejected"].includes(advance.advanceStatus) && (
              <Button
                variant="outlined"
                onClick={() => setAdvPaymentFlag(true)}
              >
                Initiate Advance Payment
              </Button>
            )}
          {["approval_in_progress"].includes(poDetails.status) &&
            approvalDetails.id && (
              <div className="right-section">
                <Button
                  variant="outlined"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() =>
                    setApproveRejectionDetails({
                      ...approvalDetails,
                      toApprove: true,
                    })
                  }
                >
                  Approve
                </Button>

                <Button
                  variant="outlined"
                  color="warning"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() => setReconsiderDetails(approvalDetails)}
                >
                  Reconsider
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() => setApproveRejectionDetails(approvalDetails)}
                >
                  Reject
                </Button>
              </div>
            )}

          {poDetails.status === "approved" && checkUserRight("po.update") && (
            <Button
              variant="outlined"
              onClick={() => shortClose(FINANCE_REST_URLS.SHORT_CLOSE_PO)}
              disabled={!!poDetails.isShortClosed || !poDetails.allowInward}
            >
              Short Close
            </Button>
          )}
        </div>
      </div>
      <div
        className={
          approvalDetails.id
            ? "po-detail-approval-stage "
            : "po-detail-approval-stage no-approval"
        }
      >
        <div className="po-details">
          <div className="form-container">
            <div className="info-section">
              <div className="info-box">
                <span>Site Name</span>
                <span>{siteName || ""}</span>
              </div>
              <div className="info-box">
                <span>Site Code</span>
                <span>{siteCode || ""}</span>
              </div>
              <div className="info-box">
                <span>Company Name </span>
                <span>{(company && company.name) || ""}</span>
              </div>
              <div className="info-box">
                <span>Base Amt </span>
                <span>
                  {getPurchaseOrderWithoutTaxAmount(items) || "0"}{" "}
                  {currency.name}
                </span>
              </div>
              <div className="info-box">
                <span>GST Amt </span>
                <span>
                  {getPurchaseOrderTaxAmount(items) || "0"} {currency.name}
                </span>
              </div>
              <div className="info-box">
                <span>Total Amt </span>
                <span>
                  {getPurchaseOrderAmount(items) || "0"} {currency.name}
                </span>
              </div>
              <div className="info-box">
                <span>PO Type </span>
                <span>
                  {convertToCamelCaseFromUnderScore(poDetails.poType)}
                </span>
              </div>
              <div className="info-box">
                <span> PO Date </span>
                <span>
                  {formatDate(poDetails.poDate, DATE_FORMATS["DD-MM-YYYY"])}
                </span>
              </div>
              <div className="info-box">
                <span> Department </span>
                <span>{getDepartmentName()}</span>
              </div>
              <div className="info-box">
                <span> Vendor </span>
                <span>{poDetails.vendorCode}</span>
              </div>
              <div className="info-box">
                <span> Vendor GST</span>
                <span>{poDetails.vendorGst}</span>
              </div>
              <div className="info-box">
                <span> Vendor Name</span>
                <span>{poDetails.vendorName}</span>
              </div>
              <div className="info-box">
                <span> Agreement </span>
                <span>{poDetails.agreement || "-"}</span>
              </div>
              <div className="info-box">
                <span> Remarks </span>
                <span>{poDetails.remarks || "-"}</span>
              </div>
              <div className="info-box">
                <span>Terms and Conditions </span>
                <span>{poDetails.termsAndConditions || "-"}</span>
              </div>
              <div className="info-box">
                <span>Status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(poDetails.status) || "-"}
                </span>
              </div>
              <div className="info-box">
                <span> Delivery Location </span>
                <span>
                  {poDetails.billingLocationId &&
                    poDetails.billingLocationId.name}
                </span>
              </div>
              <div className="info-box">
                <span> Billing Location </span>
                <span>
                  {poDetails.billingLocationId &&
                    poDetails.billingLocationId.name}
                </span>
              </div>
              <div className="info-box">
                <span>Payment Terms </span>
                <span>{poDetails.paymentTerms || "-"}</span>
              </div>
              <div className="info-box">
                <span>Advance Percent </span>
                <span>{advance.advancePercentage || "-"}</span>
              </div>
              <div className="info-box">
                <span>Advance Amount </span>
                <span>{advance.advanceAmount || "-"}</span>
              </div>
              <div className="info-box">
                <span>Forex Rate </span>
                <span>{poDetails.forex || "-"}</span>
              </div>
              <div className="info-box">
                <span>Created By </span>
                <span>{poDetails.creatorName || "-"}</span>
              </div>
              <div className="info-box">
                <span>Additional Documents </span>
                <span>
                  {documentLinks.map((attachment) => {
                    return (
                      <Chip
                        label={getFileName(attachment)}
                        onClick={() => window.open(attachment, "_blank")}
                      />
                    );
                  })}
                  {documentLinks.length === 0 && "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="form-container">
            <MuiTable
              columnsList={getHeaderPOItemsDetailsConfig(
                (itemId) =>
                  shortClose(FINANCE_REST_URLS.SHORT_CLOSE_ITEM, itemId),
                poDetails
              )}
              dataList={
                items?.slice(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(items.length / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
              }}
            />
          </div>
        </div>
        {approvalDetails.id && (
          <div className="approval-details">
            <ApprovalStageDetails
              approvalDetails={approvalDetails}
              createdBy={poDetails.creatorName}
            />
          </div>
        )}
      </div>
      {approveRejectDetails && (
        <CustomModal
          title={`${
            approveRejectDetails.toApprove ? "Approve PO" : "Reject PO"
          } `}
          disabledOutsideClick={true}
          onClose={() => {
            setApproveRejectionDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={approveRejectDetails.comment}
              onChange={(e) =>
                setApproveRejectionDetails({
                  ...approveRejectDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="Comment"
              fullWidth
            />
            <Button
              color={`${approveRejectDetails.toApprove ? "primary" : "error"}`}
              variant="outlined"
              onClick={() => {
                if (approveRejectDetails.toApprove) {
                  onApprove();
                  return;
                }
                onReject();
              }}
              fullWidth
              disabled={
                approveRejectDetails.toApprove
                  ? false
                  : !approveRejectDetails.comment
              }
            >
              {approveRejectDetails.toApprove ? "Approve" : "Reject"}
            </Button>
          </div>
        </CustomModal>
      )}

      {reconsiderDetails && (
        <CustomModal
          title="Reconsider PO"
          disabledOutsideClick={true}
          onClose={() => {
            setReconsiderDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={reconsiderDetails.comment || ""}
              onChange={(e) =>
                setReconsiderDetails({
                  ...reconsiderDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="Comment"
              fullWidth
            />
            <Button
              color="warning"
              variant="outlined"
              onClick={onReconsider}
              fullWidth
              disabled={!reconsiderDetails.comment}
            >
              Reconsider PO
            </Button>
          </div>
        </CustomModal>
      )}

      {advPaymentFlag && (
        <CustomModal
          title="Initiate Advance Payment"
          disabledOutsideClick={true}
          onClose={() => {
            setAdvPaymentFlag(false);
          }}
        >
          <InitAdvPayment
            attachmentData={{
              poNumber: poDetails.poNumber,
              attachmentType: "Advance Payment Attachment",
              status: poDetails.status,
            }}
            attachmentType={uploadAttachmentTypes.initiateAdvancePayment}
            onSave={(payload) => {
              initAdvPayment(payload);
            }}
            invoiceUrlCheck={true}
            advanceAmount={advance.advanceAmount}
          />
        </CustomModal>
      )}
      {sentPoDetails && (
        <CustomModal title="PO Sent for approval" disabledOutsideClick={true}>
          <ApprovalInfo
            poDetails={poDetails}
            pendingWith={(sentPoDetails && sentPoDetails.pendingWith) || ""}
            siteCode={siteCode || ""}
            siteName={siteName || ""}
            onOk={() => {
              navigate("/subway-finance?index=1");
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default PODetails;
