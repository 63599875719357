import { Button } from '@mui/material';
import { getPurchaseOrderAmount } from '../helper';

const ApprovalInfo = ({ poDetails, siteCode, siteName, pendingWith, onOk }) => {
  const { items = [] } = poDetails;
  return (
    <div className='po-info-container'>
      <div className='po-info-section'>
        <span>Site Name </span>
        <span>{siteName}</span>
      </div>

      <div className='po-info-section'>
        <span>Site Code </span>
        <span>{siteCode}</span>
      </div>
      <div className='po-info-section'>
        <span>PO Number </span>
        <span>{poDetails.poNumber}</span>
      </div>
      <div className='po-info-section'>
        <span>PO Amount </span>
        <span>{getPurchaseOrderAmount(items)}</span>
      </div>
      <div className='po-info-section'>
        <span>Pending With </span>
        <span>{pendingWith}</span>
      </div>
      <Button fullWidth variant='outlined' onClick={onOk} size='small'>
        Ok
      </Button>
    </div>
  );
};

export default ApprovalInfo;
