import { profitAndLossSelectValues, ttmSelectValues } from "../constants";
import {
  investmentConfig,
  occupancy1Config,
  storeDetailsConfig,
} from "../sub-config/coco-acquired/coco-acquired";
import { profitlossConfig } from "../sub-config/coco-acquired/profit-and-loss.config";
import { ttmConfig } from "../sub-config/coco-acquired/ttm.config";

import {
  formattedDateRender,
  getSlicedNum,
  formatedPercentageRender,
} from "../utils";

export const cocoAcquiredConfig = (filters) => {
  const config = [
    {
      label: "Approval Id",
      id: "id",
    },
    {
      label: "Opening Date",
      id: "openingDate",
      render: (data) => formattedDateRender(data, "openingDate"),
      isDateLabel: true,
    },
    {
      label: "Type",
      id: "storeType",
    },
    {
      label: "Store Code",
      id: "restaurantId",
    },
    {
      label: "State",
      id: "state",
    },
    {
      label: "City",
      id: "city",
    },
    {
      label: "Location / Catchment",
      id: "location",
    },
    {
      label: "Region",
      id: "cluster",
    },
    ...storeDetailsConfig(filters, 0),
    {
      label: "Franchisee",
      id: "franchisee",
    },
    {
      label: "Initial Open Date",
      id: "initialOpenDate",
      render: (data) => formattedDateRender(data, "initialOpenDate"),
      isDateLabel: true,
    },
    {
      label: "Décor Type",
      id: "decorType",
    },
    {
      label: "Remodel Due Date",
      id: "remodelDueDate",
      render: (data) => formattedDateRender(data, "remodelDueDate"),
      isDateLabel: true,
    },
    ...storeDetailsConfig(filters, 1),
    ...investmentConfig(filters),
    ...occupancy1Config(filters),
    {
      label: "ADT",
      id: "adt",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "adt"),
    },
    {
      label: "APC Rs",
      id: "apcRs",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "apcRs"),
    },
    {
      label: "Delivery %",
      id: "deliveryPercentage",
      render: (data) => formatedPercentageRender(data, "deliveryPercentage"),
      value: (data) =>
        formatedPercentageRender(data, "deliveryPercentage", true),
      percentageConversion: true,
    },
    {
      label: "ADS - Rs",
      id: "ads",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "ads"),
    },
    {
      label: "Month Sale - Rs' Mn",
      id: "monthSaleRsMn",
      render: (data) => getSlicedNum(data, "monthSaleRsMn"),
      removeDecimal: true,
    },
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.year1ProfitAndLoss,
      profitAndLossSelectValues.year1ProfitAndLoss
    ),
    {
      label: "Yearly 1Depreciation",
      id: "yearly1Depreciation",
      render: (data) => getSlicedNum(data, "yearly1Depreciation"),
      removeDecimal: true,
    },
    {
      label: "Yearly 1Store EBIT",
      id: "yearly1StoreEbit",
      render: (data) => getSlicedNum(data, "yearly1StoreEbit"),
      removeDecimal: true,
    },
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.paybackYears,
      profitAndLossSelectValues.paybackYears
    ),
    ...ttmConfig(
      filters,
      ttmSelectValues.allTTMVsF1Y,
      ttmSelectValues.allTTMVsF1Y
    ),
    {
      label: "Remarks",
      id: "remarks",
    },
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.irr5years,
      profitAndLossSelectValues.irr5years
    ),
    ...profitlossConfig(
      filters,
      profitAndLossSelectValues.profitAndLossAll,
      profitAndLossSelectValues.profitAndLossAll
    ),
  ];

  return config;
};
