import Grid from '@mui/material/Grid';
import AutoCompleteMultiSelect from '../../../../packages/common-utilities/core/autocomplete-multiselect';
import { setProperty } from '../../../../packages/common-utilities/utils/misc';
import { useEffect } from 'react';

const FinanceAppConfig = ({
  config,
  userRoleHandler,
  formData,
  setFormData,
}) => {
  useEffect(() => {
    // For clearing out finance config all together when finance role is not selected all together
    if (!config?.showFinanceConfig) {
      setProperty(['financeRoles'], [[]], setFormData);
    }
  }, [config?.showFinanceConfig]);

  return (
    <>
      {config?.showFinanceConfig ? (
        <>
          <Grid
            container
            md={12}
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              [`@media (max-width: 768px)`]: {
                gridGap: '5px',
              },
            }}
          >
            <Grid item md={5.5}>
              <AutoCompleteMultiSelect
                options={config.financeRoleList}
                onChange={(e, val) => {
                  userRoleHandler(e, val, 'financeRoles');
                }}
                value={formData?.financeRoles || []}
                placeholder="Select Finance Roles"
                useDefaultVal={true}
                isValValArr={true}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default FinanceAppConfig;
