import React, { useState } from "react";
import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";
import LeaseCom from "./lease";
import Lease2 from "./lease2";
import Lease3 from "./lease3";
import Lease4 from "./lease4";
import StartFile from "./start-file";
import LeaseDateHistory from "./lease-date-history";
import LeaseMemos from "./lease-memos";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Lease = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="main-person-container">
      <div className="subs-mainTabContainer">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="subs-tab-container"
        >
          <Grid container spacing={0}>
            <Grid item md={12} xs={12} sm={7} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Lease 1" />
                <Tab label="Lease 2" />
                <Tab label="Lease 3" />
                <Tab label="Lease 4" />
                {/* <Tab label="Start File" /> */}
                <Tab label="Lease Date History" />
                <Tab label="Lease Memos" />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <LeaseCom />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Lease2 />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Lease3 />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Lease4 />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <StartFile />
        </TabPanel> */}
        <TabPanel value={value} index={4}>
          <LeaseDateHistory />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <LeaseMemos />
        </TabPanel>
      </div>
    </div>
  );
};

export default Lease;
