import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { INTRANETHOSTNAME } from "../utils/end-points";
import { REST_URLS } from "../utils/end-points/app-document";

export const getFieldList = async (slug, filter, convertType, setDataFunc) => {
  let data = [];

  try {
    const results = await invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.FIELD_LIST}`,
      null,
      filter,
      slug
    );
    if (Array.isArray(results)) {
      data = [...results];
    }
  } catch (err) {
    console.log(err);
  }
  switch (convertType) {
    case "select":
      data = data.map((item) => {
        return { label: item.name, value: item.id };
      });

      break;
    default:
      break;
  }
  if (typeof setDataFunc === "function") {
    setDataFunc(data);
  }
  return data;
};

export const getAppDocuments = (filter, slug, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.APP_DOCUMENTS}`,
    null,
    filter,
    slug
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      setDataFunc(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAppDetails = (slug, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.APP_DETAILS}`,
    null,
    null,
    slug
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc({});
        return;
      }
      setDataFunc(res);
    })
    .catch((err) => {
      setDataFunc({});
      console.log(err);
    });
};

export const checkUserConfirmation = (
  editInfo,
  appDetails,
  allowConfirm,
  user,
  confirmRoles,
  slug
) => {
  if (
    !allowConfirm ||
    !confirmRoles?.includes?.(user?.role) ||
    !appDetails?.id
  ) {
    editInfo(appDetails);
    return;
  }

  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.CONFIRMATION_STATUS}${appDetails.id}`,
    null,
    null,
    slug
  )
    .then((res) => {
      editInfo({ ...appDetails, notConfirmed: !!res?.notConfirmed });
    })
    .catch((err) => {
      editInfo(appDetails);
      console.log(err);
    });
};
