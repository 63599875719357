import React, { useEffect, useState } from 'react';
import {
  FINANCE_HOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { Autocomplete, Button, TextField } from '@mui/material';
import CustomModal from '../../../../common-utilities/core/modal';
import './index.scss';
import { exportToExcel } from '../../../../common-utilities/utils';
import { isCreateAllowed } from '../../../utils/common-utils';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../../common-utilities/core/confirm-modal';
import FileUpload from '../../../../common-utilities/core/file-uploader';
import DownloadTemplateButton from '../common-components/upload-template';
import { uploadTemplates } from '../constants';
import { formatReport } from './utils';
import { setProperty } from '../../../../common-utilities/utils/misc';

const VendorItemMap = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [budgetList, setBudgetList] = useState([]);
  const [items, setItems] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [vendorInputValue, setVendorInputValue] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    loadData(filters);
    loadItems();
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.VENDOR_ITEM_MAPPING}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty('page', response?.totalPages, setFilters);
          }
          setBudgetList(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadItems = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}`,
      null,
      { page: 1, limit: 1000 }
    )
      .then((response) => {
        if (response.code) {
        } else {
          setItems(
            (response.results || []).map((c) => {
              return { label: c.name, value: c.id, id: c.id };
            })
          );
        }
      })
      .catch((err) => {});
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`
    )
      .then((response) => {
        if (response.code) {
        } else {
          setVendorList(response || []);
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    const { item = {}, vendor = {} } = formDetails;
    let payload = {
      vendorCode: vendor?.code,
      name: vendor?.name,
      item: item?.id,
      price: formDetails?.price,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.VENDOR_ITEM_MAPPING}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        if (response.code) {
          toast.error('Failed to create');
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
          setInputValue('');
          setVendorInputValue('');
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    const { item = {}, vendor = {} } = formDetails;
    let payload = {
      vendorCode: vendor?.code,
      name: vendor?.name,
      item: item?.id,
      price: formDetails?.price,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.VENDOR_ITEM_MAPPING}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        if (response.code) {
          toast.error('Failed to create');
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
          setInputValue('');
          setVendorInputValue('');
        }
      })
      .catch((err) => {});
  };

  const editRecord = (data) => {
    const { item = {} } = data;

    const vendor = vendorList.find((item) => item.code === data.vendorCode) || {
      name: data.name,
      code: data.vendorCode,
    };
    data.name = vendor.name;
    setVendorInputValue(vendor.name);

    setBudgetModal(true);
    setFormDetails({ ...data, vendor });
    setInputValue(item.name);
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.VENDOR_ITEM_MAPPING}`,
      null,
      { page: 1, limit: budgetList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(formatReport(response.results), 'vendor-item-mapping');
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.VENDOR_ITEM_MAPPING}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        loadData(filters);
        setDeleteDetails(null);
      })
      .catch((err) => {});
  };

  return (
    <div className="vm-list">
      <div className="vm-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>

        <div className="left-container">
          {isCreateAllowed('master_data') && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#008938"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}vendor-mapping`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />
              <DownloadTemplateButton
                type={uploadTemplates.vendorItemMapping}
              />
            </>
          )}

          <Button variant="outlined" disabled={!budgetList.totalResults} onClick={downloadData}>
            Download
          </Button>
          {isCreateAllowed('master_data') && (
            <Button variant="outlined" onClick={() => setBudgetModal(true)}>
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editRecord, setDeleteDetails)}
        dataList={budgetList.results || []}
        filters={filters}
        nestedValue={true}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title="Vendor Item Map"
          disabledOutsideClick={true}
          onClose={() => {
            setBudgetModal(false);
          }}
        >
          <div className="create-form">
            <Autocomplete
              options={vendorList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Vendor" />
              )}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue(newInputValue);
                }
              }}
              value={formDetails.vendor || ''}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendor: val,
                });
              }}
            />

            <Autocomplete
              options={items}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Item" />
              )}
              inputValue={inputValue}
              value={formDetails.item || ''}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  item: val,
                });
              }}
            />
            <TextField
              value={formDetails.price}
              name="price"
              type="number"
              onChange={onInputChange}
              size="small"
              label="Item Price"
              fullWidth
            />

            {formDetails.id ? (
              <Button fullWidth variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={onSave}>
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default VendorItemMap;
