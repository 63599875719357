import { createSlice } from "@reduxjs/toolkit";

const initialState = "Users";

export const adminSideMenu1 = createSlice({
  name: "admin-1",
  initialState,
  reducers: {
    changeMainAdminPage: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { changeMainAdminPage } = adminSideMenu1.actions;

export default adminSideMenu1.reducer;
