import { useEffect } from "react";
import "./index.scss";
import IntranetHeader from "../../../core/header";
import { BellCorpStudioLogoContainer } from "../Bellcorp-Studio-Logo";
import DefaultHeader from "../core/header";
import HomeRedirect from "../core/home-redirect";
import { useLocation } from "react-router-dom";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../routes/endpoints";
import { useState } from "react";

const MainContainer = ({ Header, headerProps = {}, children }) => {
  const { pathname } = useLocation();
  const [shwIntnrtHeader, setShowIntranetHeader] = useState(true);
  useEffect(() => {
    const subsEndPoint = getProjectEndPoint(endPointsForProject.subs);

    const docuStorageEnpoint = getProjectEndPoint(
      endPointsForProject.docuStorage
    );
    const qnetNetEndPoint = getProjectEndPoint(endPointsForProject.qnet);
    if (
      pathname.startsWith(subsEndPoint) ||
      pathname.startsWith(docuStorageEnpoint) ||
      pathname.startsWith(qnetNetEndPoint)
    ) {
      setShowIntranetHeader(false);
      return;
    }
    setShowIntranetHeader(true);
  }, [pathname]);
  return (
    <>
      {shwIntnrtHeader && <IntranetHeader />}

      <HomeRedirect localstorageKey={headerProps.tokenKey} />
      <div
        className={!shwIntnrtHeader ? "white-main-container" : "main-container"}
      >
        <div
          className={
            !shwIntnrtHeader
              ? "white-main-right-container"
              : "main-right-container"
          }
        >
          {Header ? (
            <Header {...headerProps} />
          ) : (
            <DefaultHeader {...headerProps} />
          )}

          <div className="content-div">
            <div>{children}</div>
          </div>
        </div>
        <BellCorpStudioLogoContainer />
      </div>
    </>
  );
};

export default MainContainer;
