import { useEffect, useState } from "react";
import {
  clearProperty,
  setProperty,
} from "../../../../common-utilities/utils/misc";
import SiFileUpload from "../../../core/file-uploader";
import Stack from "@mui/material/Stack";
import { HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../../common-utilities/utils/end-points/tech-net";
import { IconButton } from "@mui/material";

import {
  allowedFormats,
  disableFileUpload,
  getInputAccept,
} from "../../../../common-utilities/utils/file-upload.utils";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { setData } from "./utils";
export const CategoryDoc = ({
  showModal,
  mainSetData,
  data,
  fileDetails,
  errorMsg,
  attachmentKey="attachments"
}) => {
  const [crtData, setCrtData] = useState({});

  useEffect(() => {
    if (!showModal) {
      setCrtData({});
      clearProperty(attachmentKey, mainSetData);
      return;
    }
  }, [showModal]);

  useEffect(() => {
    setData(data, setCrtData, attachmentKey);
  }, [data, attachmentKey]);

  return (
    <>
      <Stack
        sx={{ my: 2 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack spacing={2}>
          {fileDetails && (
            <>
              <p>Please click here on the below file to download and fill it</p>
              <a href={fileDetails?.url} download={true}>
                {fileDetails?.name}
              </a>
              {!crtData?.[attachmentKey]?.[0] && (
                <div className="danger-text">{errorMsg}</div>
              )}
            </>
          )}
        </Stack>

        <Stack alignItems="flex-end" spacing={2}>
          {crtData?.[attachmentKey]?.[0]?.url && (
            <>
              <span>
                {crtData?.[attachmentKey]?.[0]?.name}
                <IconButton
                  color="primary"
                  onClick={() => {
                    clearProperty(attachmentKey, setCrtData);
                    setProperty(attachmentKey, [], mainSetData);
                  }}
                  title="Clear"
                >
                  <HighlightOffIcon />
                </IconButton>
              </span>
            </>
          )}
          {!crtData?.[attachmentKey]?.[0]?.url && (
            <SiFileUpload
              lmtMsg="*Max upload file size is 10 MB."
              label="Upload Document"
              customLmt={10000000}
              disableUpload={(file) =>
                disableFileUpload(file, [
                  allowedFormats.wordDocument,
                  allowedFormats.excelFile,
                ])
              }
              accept={getInputAccept([
                allowedFormats.wordDocument,
                allowedFormats.excelFile,
              ])}
              noSuccessMsg={true}
              customUrl={`${HOSTNAME}${REST_URLS.DOCUMENT_UPLOAD}`}
              callBack={(response) => {
                const data = response?.data;

                if (data?.url && data?.originalname) {
                  const attachments = [
                    {
                      name: data?.originalname,
                      url: data?.url,
                    },
                  ];
                  setProperty(attachmentKey, attachments, setCrtData);
                  setProperty(attachmentKey, attachments, mainSetData);
                }
              }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
