import React, { useState } from "react";
import CustomModal from "../../../../common-utilities/core/modal";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Box,
} from "@mui/material";

const ConfirmHodGrn = ({ bulkApprove, setIsConfirmModalOpen }) => {
  const [isChecked, setIsChecked] = useState(false);
  const submitHandler = () => {
    if (isChecked) {
      bulkApprove();
    }
  };
  return (
    <div>
      <CustomModal
        title="Confirmation"
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isChecked}
                onChange={(event) => setIsChecked(event.target.checked)}
              />
            }
            label="I/We hereby confirm that we have received Material or Services as mentioned in the invoice for which GRN is submitted"
          />
        </FormGroup>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            disabled={!isChecked}
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
};

export default ConfirmHodGrn;
