import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { Grid, Drawer, TextField } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "react-select";
import ShowMemo from "./show-memo";
import TableCell from "@mui/material/TableCell";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { invokeApi, plainApi } from "../../../../utils/http-service";
import MemoTimeline from "./memo-timeline";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { StyledAutocomplete } from "../../../common-utilities/core/styled-autocomplete";
import SelectComponent from "../../../common-utilities/core/select-component";
const memoCols = [
  { id: "category", label: "Category" },
  { id: "enteredBy", label: "Entered By" },
  { id: "memoDate", label: "Memo Date" },
  { id: "memo", label: "Memo" },
  { id: "appliedDate", label: "Applied Date" },
  { id: "deposition", label: "Deposition" },
  { id: "closedAt", label: "Completed Date" },
  { id: "isClosed", label: "Completed" },
  {
    label: "Timline",
    id: "timeline",
    render: (data, onClick) => {
      return (
        <TableCell align="center" padding="none" disabled={true}>
          <QueryStatsIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick(data, "timeline");
            }}
          />
        </TableCell>
      );
    },
  },
];
const AdminMemos = () => {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [category, setCategory] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [storeInfo, setStoreInfo] = useState({
    storeNo: "",
    sequenceNo: "",
    satelliteNo: "",
  });
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
    isClosed: false,
    isEdit: false,
    appliedDate: "",
    id: "",
    deposition: null,
  });
  const [timelineData, setTimelineData] = useState([]);
  const [showTimeLine, setShowTimeline] = useState(false);
  const [currentMemo, setCurrentMemo] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [memoCategoryList, setMemoCategoryList] = useState([]);
  const previousController = useRef();
  const checkForValidStoreNumberSequenceNumberAndSatelliteNumber = (
    storeNo,
    sequenceNo,
    satelliteNum
  ) => {
    return (
      Number.isInteger(parseInt(storeNo)) &&
      Number.isInteger(parseInt(sequenceNo)) &&
      Number.isInteger(parseInt(satelliteNum))
    );
  };
  const getTableData = (page, hasToReset, seqNo) => {
    let checkingCondition = !pageArray.includes(page);
    let params = {
      page: page,
      limit: 20,
      sortBy: "-createdAt",
      typeModel: "Store",
      storeNo: parseInt(storeInfo?.storeNo),
      satelliteNo: storeInfo?.satelliteNo,
      sequenceNo: storeInfo?.sequenceNo,
    };
    if (hasToReset) {
      checkingCondition = true;
    }
    if (seqNo) {
      params["sequenceNo"] = seqNo;
    }
    if (category) {
      params["category"] = category;
    }
    const checkCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo?.storeNo,
        storeInfo?.sequenceNo || seqNo,
        storeInfo?.satelliteNo
      );
    if (checkingCondition && checkCombination) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMOS}`, null, params)
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
            return;
          }
          setTotalPages(res.totalPages + 1);
          const results = res?.results?.map((item) => {
            let details = {};
            details["id"] = item?.id;
            if (item?.createdBy) {
              details[
                "enteredBy"
              ] = `${item.createdBy?.firstName} ${item.createdBy?.lastName}`;
            }
            if (item?.createdAt) {
              details["memoDate"] = moment(item.createdAt).format("DD-MM-YYYY");
            }
            if (item?.appliedDate) {
              details["appliedDate"] = moment(item.appliedDate).format(
                "DD-MM-YYYY"
              );
              details["isoFormatAppliedDate"] = item.appliedDate;
            }
            if (item?.closedAt) {
              details["closedAt"] = moment(item.closedAt).format("DD-MM-YYYY");
            }
            if (item?.memo) {
              details["memo"] = item.memo;
            }
            if (item?.deposition) {
              details["deposition"] = item.deposition;
            }
            details["isClosed"] = item.isClosed;
            if (item?.category?.category) {
              details["category"] = item.category.category;
            }
            return details;
          });
          let array = [...pageArray];
          array.push(page);
          if (hasToReset) {
            setPageArray([1]);
            setData(results);
            if (res.totalPages === 1) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setPageArray(array);
            setData((prevData) => [...prevData, ...results]);
          }
        })
        .catch((err) => {
          console.log(err);
          setData([]);
        });
    }
  };
  const getTimelineData = (memoId) => {
    if (memoId) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_TIMELINE}${memoId}`
      )
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setTimelineData(res);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const editMemoHandler = (data, action) => {
    switch (action) {
      case "timeline":
        getTimelineData(data?.id);
        setShowTimeline(true);
        setCurrentMemo(data);
      default:
        break;
    }
  };
  const onInputChange = (event, value, reason) => {
    setSatteliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
    } else {
      clearData();
    }
    setStoreInfo({
      storeNo: value ? value : "",
      satelliteNo: null,
      sequenceNo: null,
    });
  };
  const clearData = () => {
    setData([]);
    setStoreInfo({
      ...storeInfo,
      storeNo: "",
      satelliteNo: "",
      sequenceNo: "",
    });
    setSatteliteList([]);
    setSequenceList([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setCategory("");
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.id,
          satelliteNum: p.satelliteNo,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });

      setStoreOptions(updatedOptions);
    });
  };
  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTableData(1, true);
  }, [category]);
  useEffect(() => {
    if (totalPages === null) {
      getTableData(pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`, null, {
      typeModel: "Store",
    }).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        const results = res.map((ele) => ({
          label: ele.category,
          value: ele.id,
        }));
        setMemoCategoryList(results);
      }
    });
  }, []);
  return (
    <div className="franchise-memo-con ">
      <h3>Memos</h3>
      <Grid container md={12} spacing={1}>
        <Grid item md={2}>
          <StyledAutocomplete
            id="free-solo-demo"
            freeSolo
            className="styled-text-field"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                label="Store"
                size="small"
              />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                const { storeNo } = val;
                listingLeaseHandler(storeNo);
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <SelectComponent
            onChange={(e) => {
              const value = parseInt(e.target.value);
            
              setStoreInfo({ ...storeInfo, satelliteNo: value });
              const satelliteNo = value;
              const validStoreCombination =
                checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  storeInfo.sequenceNo
                );
              if (validStoreCombination) {
                getTableData(1, true, null);
              }
            }}
            label="Satellite"
            options={satteliteList}
            value={
              ![null, undefined].includes(storeInfo?.satelliteNo)
                ? `${storeInfo?.satelliteNo}`
                : ""
            }
          />
        </Grid>
        <Grid item md={2}>
          <SelectComponent
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setStoreInfo({ ...storeInfo, sequenceNo: value });
              const sequenceNo = value;
              const validStoreCombination =
                checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  sequenceNo
                );
              if (validStoreCombination) {
                getTableData(1, true, sequenceNo);
              }
            }}
            label="Sequence"
            options={sequenceList}
            value={
              ![null, undefined].includes(storeInfo?.sequenceNo)
                ? `${storeInfo?.sequenceNo}`
                : ""
            }
          />
        </Grid>
        <Grid item md={2}>
          <SelectComponent
            onChange={(e) => {
              const value = e.target.value;
              setCategory(value);
            }}
            label="Select Category"
            options={memoCategoryList}
            value={category || ""}
          />
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ display: "flex", flexDirection: "column", mt: "1em" }}
      >
        <StickyHeaderCon
          columnsList={memoCols}
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          maxWords={15}
          onClick={editMemoHandler}
          showAllFunc={setShowLargeText}
        />
      </Grid>
      <Drawer
        anchor="right"
        open={showLargeText.isOpen}
        onClose={() => setShowLargeText({ isOpen: false, text: "" })}
      >
        <ShowMemo
          showMemoText={showLargeText}
          onClose={() => setShowLargeText({ isOpen: false, text: "" })}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showTimeLine}
        onClose={() => {
          setShowTimeline(false);
          setTimelineData([]);
          setCurrentMemo("");
        }}
      >
        <MemoTimeline
          timelineData={timelineData}
          getTimelineData={getTimelineData}
          memo={currentMemo}
        />
      </Drawer>
    </div>
  );
};
export default AdminMemos;
