export const VENDOR_ONBOARD_USERS = {
  HOD: 'HOD',
  FINANCE_USER: 'FINANCE_USER',
  VIVO_CREATOR: 'VIVO_CREATOR'
};

export const userStates = {
  isProcessing: 'Checking'
};

export const VENDOR_FORM_STATUS = {
  OPEN: 'OPEN',
  REOPENED: 'REOPENED',
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  RESUBMITTED: 'RESUBMITTED'
};

export const APPROVAL_TYPES = {
  BUDGET_APPROVAL: 'budget_approval',
  PO_APPROVAL: 'po_approval',
  LIME_ITEM_APPROVAL: 'lineitem_approval'
};


export const BUDGET_TYPES = [
  { label: 'Capex', value: 'capex_budget' },
  { label: 'Opex', value: 'opex_budget' }
];
