import { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import './index.scss';
import { dateFilter } from './date-filter';
import AutocompleteComponent from '../../../common-utilities/core/styled-autocomplete';
import { setProperty } from '../../../common-utilities/utils/misc';
import { DateRangePicker } from 'rsuite';
import CommonBtn from '../../../common-utilities/core/common-button';
import { getDashboardData, getStatusData } from './apis';
import MuiTable from '../../../common-utilities/core/mui-table';
import { getHeaderConfig as homeHeaderConfig } from './config/home-config';
import { getHeaderConfig as statusTableConfig } from './config/status-config';
import { actionHandler } from './utils';
import { defaultFilters, pageView } from './constants';
import {useLocation} from "react-router-dom"
const Dashboard = () => {
  const [timePerdLst, setTimePerdLst] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [fltrsAfrClk, setFltrsAfrClk] = useState(defaultFilters);
  const [tableData, setTableData] = useState({});
  const {pathname} = useLocation(); 
  useEffect(() => {
    const timePeriods = dateFilter();
    setTimePerdLst(timePeriods);
  }, []);
  // When the component is mounted initial view will be the home view showing dashboard
  useEffect(() => {
    setProperty('view', pageView.home, setFilters);
  }, [pathname])

  useEffect(() => {
    if (filters?.view === pageView.home) {
      getDashboardData(fltrsAfrClk, setTableData);
    }
  }, [filters.view]);

  const dateRangeHandler = (e) => {
    setFilters((prevVal) => ({
      ...prevVal,
      startDate: e?.[0] ? new Date(`${e[0]}`).toISOString() : null,
      endDate: e?.[1] ? new Date(`${e[1]}`).toISOString() : null,
      timePeriod: null,
    }));
  };

  return (
    <div className="bd-lead-dashobard">
      {filters?.view === pageView.home && (
        <div className="filters">
          <AutocompleteComponent
            label="Select Date Period"
            options={timePerdLst}
            onChange={(e, val) => {
              setProperty(
                ['timePeriod', 'startDate', 'endDate'],
                [val, val?.timePeriod?.startDate, val?.timePeriod?.endDate],
                setFilters
              );
            }}
            value={filters?.timePeriod || null}
          />

          <DateRangePicker
            cleanable={true}
            placeholder="Select Date Range"
            onChange={dateRangeHandler}
            value={[
              filters.startDate ? new Date(filters.startDate) : null,
              filters.endDate ? new Date(filters.endDate) : null,
            ]}
          />

          <div>
            <CommonBtn
              onClick={() => {
                setFltrsAfrClk({ ...filters });
                getDashboardData(filters, setTableData);
              }}
            >
              Apply
            </CommonBtn>
          </div>
        </div>
      )}

      {filters?.view !== pageView.home && (
        <ArrowBackIosIcon
          className="back-arrow"
          onClick={() => {
            setProperty(['view', 'page'], [pageView.home, 1], setFilters);
          }}
        />
      )}

      <div>
        <MuiTable
          columnsList={
            filters.view === pageView.home
              ? homeHeaderConfig()
              : statusTableConfig(filters.status)
          }
          filters={filters}
          dataList={tableData?.results || []}
          onClick={(type, data) => {
            actionHandler(
              type,
              { ...fltrsAfrClk, ...data },
              setFilters,
              setTableData,
              setFltrsAfrClk
            );
          }}
          pageCount={tableData?.totalPages || 1}
          onChange={(page) => {
            setProperty('page', page, setFilters);
            getStatusData({ ...fltrsAfrClk, page }, setTableData);
          }}
        ></MuiTable>
      </div>
    </div>
  );
};

export default Dashboard;
