import { INTRANETHOSTNAME } from "../../../packages/common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { REST_URLS } from "../../../packages/common-utilities/utils/end-points/intranet";

export const getDepartmentsData = (filters, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.GET_DEPARTMENTS}`,
    null,
    filters
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      setDataFunc(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
