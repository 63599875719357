import { dateFun } from "../../../common-utilities/utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export const getHeaderConfig = () => [
  {
    label: "Certificate Id",
    key: "certificateId",
    render: (data, onClick) => {
      return (
        <div
          onClick={() => {
            onClick(data, "view certificate");
          }}
          style={{ cursor: "pointer", color: "darkblue" }}
        >
          {data?.certificateId}
        </div>
      );
    },
  },
  {
    label: "Certificate Date",
    key: "certificationDate",
    render: (data) => {
      return <div>{dateFun(data?.certificationDate, true)}</div>;
    },
  },
  {
    label: "Cash At Location",
    key: "cashAtLocation",
    render: (data, index) => {
      return <div>{data?.cashAtLocation}</div>;
    },
  },
  {
    label: "Cash At Bank",
    key: "cashAtBank",
    render: (data, index) => {
      return <div>{data?.cashAtBank}</div>;
    },
  },
  {
    label: "Created At",
    key: "createdAt",
    render: (data, index) => {
      return <div>{dateFun(data?.createdAt, true)}</div>;
    },
  },
  {
    label: "Remarks",
    key: "remarks",
    render: (data, index) => {
      return <div>{data?.remarks}</div>;
    },
  },

  {
    label: "View files",
    key: "view files",
    render: (data, onClick, index) => {
      return (
        <RemoveRedEyeIcon
          onClick={() => onClick(data, "view files")}
          sx={{ cursor: "pointer" }}
        />
      );
    },
  },
];
