export const setFormDetailsOnChange = (e, details, setFormDetails) => {
  if (e.target) {
    const { name, value } = e.target;
    const splittedName = name.split(".");
    if (splittedName.length == 2) {
      setFormDetails((prevDetails) => {
        const prevKeyValue = prevDetails?.[splittedName[0]] || {};
        return {
          ...prevDetails,
          [splittedName[0]]: { ...prevKeyValue, [splittedName[1]]: value },
        };
      });
      return;
    }
    setFormDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    return;
  }
  const { name } = details;
  const splittedName = name.split(".");
  const mainKey = splittedName[0];
  const subKey = splittedName[1];
  if (splittedName.length == 2) {
    setFormDetails((prevDetails) => {
      const prevKeyValue = prevDetails?.[mainKey] || {};
      return {
        ...prevDetails,
        [mainKey]: { ...prevKeyValue, [subKey]: e },
      };
    });
    return;
  }
  setFormDetails((prevDetails) => ({ ...prevDetails, [splittedName[0]]: e }));
};
