import React, { useState, useRef, useEffect, useContext } from "react";
// import Grid from "@mui/material/Unstable_Grid2";
import { auditModelNames } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import { invokeApi, plainApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import Select from "react-select";
import { checkValidStoreCombination } from "../../utils";

import { GlobalStoreContext } from "../../global-states/Context";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const StoreInsuranceHistory = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [data, setData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [satelliteList, setSatelliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const previousController = useRef();

  useEffect(() => {
    if (totalPages === null) {
      getTableData(pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh, storeInfo.satelliteNo, storeInfo.sequenceNo, storeInfo.storeNo]);

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getTableData(1, true);
    }
  }, []);
  const dataKeyValue = [
    { label: "Store Status", id: "storeStatus", type: "text" },
    { label: "Company Name", id: "companyName", type: "text" },
    { label: "Broker", id: "broker", type: "text" },
    { label: "Effective Date", id: "effectiveDate", type: "text" },
    { label: "Policy Number", id: "policyNumber", type: "text" },
    { label: "Total Liability", id: "totalLiability", type: "text" },
    { label: "Renewal Date", id: "renewalDate", type: "text" },
    { label: "Currency", id: "currency", type: "text" },
    { label: "Required Liability", id: "requiredLiability", type: "text" },
    { label: "Cancelled Date", id: "cancelledDate", type: "text" },
    { label: "Comments", id: "comments", type: "text" },
    { label: "Verification", id: "verification", type: "text" },
    { label: "Last Updated By", id: "lastUpdatedBy", type: "text" },
  ];

  const getTableData = (page, hasToReset) => {
    let curStore = storeInfo.storeNo;
    if (typeof curStore === "string") {
      curStore = parseInt(curStore);
    }
    const isValidCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    let dataCheckingCondition = !pageArray.includes(page);
    if (hasToReset) {
      dataCheckingCondition = true;
    }
    if (dataCheckingCondition && isValidCombination) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_HISTORY}`, null, {
        modelName: auditModelNames.storeInsurance,
        storeNo: storeInfo.storeNo,
        satelliteNo: storeInfo.satelliteNo,
        sortBy: "-createdAt",
        page,
        sequenceNo: storeInfo.sequenceNo,
        limit: 20,
      })
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
            return;
          }
          setTotalPages(res.totalPages + 1);
          const results = res?.results?.map((item) => {
            const { details } = item;
            delete details._id;
            delete details.__v;
            delete details.updatedAt;
            delete details.satelliteNum;
            delete details.sequenceNo;
            delete details.storeNo;
            return details;
          });
          if (page == 1) {
            setData(results);
            setPageArray([1]);
            if (res.totalPages === 1) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setData([...data, ...results]);
          }

          let array = [...pageArray];
          array.push(page);
          setPageArray(array);
        })
        .catch((err) => {
          setData([]);
        });
    } else {
      setData([]);
    }
  };

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
      setStoreInfo((prevVal) => ({ ...prevVal, storeNo: searchTerm }));
    });
  };

  const clearData = () => {
    setStoreOptions([]);
    setData([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value) {
      getData(value);
      setStoreInfo({ storeNo: value, sequenceNo: null, satelliteNo: null });
    } else {
      setStoreInfo({ storeNo: "", sequenceNo: null, satelliteNo: null });
      clearData();
    }
  };

  return (
    <div className="storeInsuranceDetails">
      <div className="title">Store Selection</div>
      <div style={displayContainer}>
        <Grid spacing={1} container md={6}>
          <Grid item md={4}>
            <Autocomplete
              id="free-solo-demo"
              className="subs-autocomplete"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              // sx={{ width: "12em" }}
              inputValue={storeInfo.storeNo + ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  setStoreInfo((prevVal) => ({ ...prevVal, storeNo: val.id }));
                  getAllSequenceNumber(val.id);
                }
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Satellite"
              className="sel-item"
              options={satelliteList}
              value={
                ![undefined, null].includes(storeInfo.satelliteNo)
                  ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  satelliteNo: val?.value,
                }));
                getTableData(1, true);
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>

          <Grid item md={4}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence Number"
              className="sel-item"
              options={sequenceList}
              value={
                ![undefined, null].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val?.value,
                }));
                getTableData(1, true);
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Divider style={{ paddingBottom: 10 }} />
      <StickyHeaderCon
        dataList={data}
        columnsList={dataKeyValue}
        setPages={setPages}
        setRefresh={setRefresh}
        hasMore={hasMore}
      />
    </div>
  );
};
export default StoreInsuranceHistory;
