export const cocoBuiltDateFields = [
  "Creation Date",
  "'Approver 1 Date",
  "'Approver 2 Date",
  "Approver 3 Date",
  "Approver 4 Date",
  "Approver 5 Date",
  "Approver 6 Date",
  "Rejected At",
];

export const cocoAcquiredDateFields = [
  "Creation Date",
  "Approver 1 Date",
  "Approver 2 Date",
  "Approver 3 Date",
  "Approver 4 Date",
  "Approver 5 Date",
  "Approver 6 Date",
  "Rejected At",
];
