import {
  startDateCellWithoutNa,
  keyDateCell,
  startDateCell,
  storeFormatCell,
} from "../../utils/report-table.utils";

export const COCO_ACQUIRED_COLUMNS = [
  {
    id: "master",
    Header: "Master",
    columns: [
      {
        id: "masterStoreCode",
        Header: "Store Code",
        accessor: "restaurant.storeNumber",
      },
      {
        id: "masterStoreName",
        Header: "Store Name as per Posist",
        accessor: "restaurant.ownerName",
      },
      {
        id: "masterStoreRegion",
        Header: "State",
        accessor: "restaurant.state",
      },
      {
        id: "masterStoreCity",
        Header: "City",
        accessor: "restaurant.city",
      },
      {
        id: "masterStoreType",
        Header: "Store Type",
        accessor: "restaurant.storeType",
      },
      {
        id: "masterStoreFormat",
        Header: "Store Format",
        accessor: "restaurant",
        Cell: storeFormatCell,
      },
      {
        id: "masterStoreOpeningDate",
        Header: "Opening Date",
        accessor: "restaurantId.openingDate",
      },
    ],
  },
  {
    id: "user",
    Header: "User",
    columns: [
      {
        id: "userProjectStatus",
        Header: "Project Status",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.status",
      },
      {
        id: "userName",
        Header: "User Name",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.userName",
      },
      {
        id: "superBuiltUpArea",
        Header: "Super Built-up Area sq ft",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.superBuiltUpArea",
      },
      {
        id: "carpetArea",
        Header: "Carpet Area sq ft",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.carpetArea",
      },
      {
        id: "lockInPeriod",
        Header: "Lock-in period (In Months)",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.lockInPeriod",
      },
      {
        id: "deposit",
        Header: "R Sec Deposit",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.deposit",
      },
      {
        id: "tenure",
        Header: "Tenure in Months",
        accessor:
          "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.tenure",
      },
    ],
  },
  {
    id: "preAgreementDocs",
    Header: "Pre Agreement Docs",
    columns: [
      {
        id: "preAgreementDocsProjectFeasibility",
        Header: "Tear Sheet",
        columns: [
          {
            id: "projectFeasibilityDate",
            Header: "Document Date",
            accessor: "PRE_AGREEMENT_DOCS.Project Feasibility.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "preAgreementDocsLoi",
        Header: "LOI",
        columns: [
          {
            id: "loiDate",
            Header: "LOI Signing Date",
            accessor: "PRE_AGREEMENT_DOCS.LOI.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "preAgreementDocsTitleDeligenceReport",
        Header: "Title deligence report",
        columns: [
          {
            id: "titleDeligenceReportDate",
            Header: "Report Date",
            accessor: "PRE_AGREEMENT_DOCS.Title Deligence Report.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "preAgreementDocsLeaseFeasibility",
        Header: "Tax Report",
        columns: [
          {
            id: "leaseFeasibilityDate",
            Header: "Report Date",
            accessor: "PRE_AGREEMENT_DOCS.License Feasibility.dateFile",
            Cell: startDateCell,
          },
        ],
      },
    ],
  },
  {
    id: "agreementandPostAgreementDocs",
    Header: "Agreement & Post Agreement Documents",
    columns: [
      {
        id: "agreementandPostAgreementDocsAgreement",
        Header: "BTA",
        columns: [
          {
            id: "agreementandPostAgreementDocsAgreementdateFile",
            Header: "Document Date",
            accessor: "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.BTA.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsRegistration",
        Header: "Registration",
        columns: [
          {
            id: "agreementandPostAgreementDocsRegistrationDate",
            Header: "Registration Date",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Registration.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsDateOfSubmission",
        Header: "Submission of Registration Hard Copy",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfSub",
            Header: "Date of Submission",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Submisssion of Reg Hard copy.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsFranchise",
        Header: "Franchise Termination",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfFranchise",
            Header: "Date of Franchise Termination",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Franchise Termination.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsLeaseAgreement",
        Header: "Lease Agreement",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfLeaseAgreement",
            Header: "Date of Lease Agreement",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Agreement.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsDeliveryMemo",
        Header: "Delivery Memo",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfDelivery Meno",
            Header: "Document Date",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Delivery Memo.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsLeaseTermination",
        Header: "Lease Termination",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfLeaseTermination",
            Header: "Document Date",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Lease Termination.dateFile",
            Cell: startDateCell,
          },
        ],
      },
      {
        id: "agreementandPostAgreementDocsOptionAgreement",
        Header: "Option Agreement",
        columns: [
          {
            id: "agreementandPostAgreementDocsDateOfOptionAgreement",
            Header: "Document Date",
            accessor:
              "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS.Option Agreement.dateFile",
            Cell: startDateCell,
          },
        ],
      },
    ],
  },
  {
    id: "LICENSES",
    Header: "Licences",
    columns: [
      {
        id: "licencesFssai",
        Header: "FSSAI",
        columns: [
          {
            id: "licencesFssaidateFile",
            Header: "Start Date",
            accessor: "LICENSES.FSSAI.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesFssaiEndDate",
            Header: "End Date",
            accessor: "LICENSES.FSSAI.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
      {
        id: "licencesTrade",
        Header: "Trade",
        columns: [
          {
            id: "licencesTradedateFile",
            Header: "Start Date",
            accessor: "LICENSES.Trade.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesTradeEndDate",
            Header: "End Date",
            accessor: "LICENSES.Trade.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
      {
        id: "licencesChangeOfUser",
        Header: "Change Of User",
        columns: [
          {
            id: "licencesChangeOfUserdateFile",
            Header: "Start Date",
            accessor: "LICENSES.Change of User.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesChangeOfUserEndDate",
            Header: "End Date",
            accessor: "LICENSES.Change of User.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
      {
        id: "licencesFire",
        Header: "Fire",
        columns: [
          {
            id: "licencesFiredateFile",
            Header: "Start Date",
            accessor: "LICENSES.Fire NOC.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesFireEndDate",
            Header: "End Date",
            accessor: "LICENSES.Fire NOC.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
      {
        id: "licencesShopEstablishment",
        Header: "Shop Establishment",
        columns: [
          {
            id: "licencesShopEstablishmentdateFile",
            Header: "Start Date",
            accessor: "LICENSES.Shop Establishment.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesShopEstablishmentEndDate",
            Header: "End Date",
            accessor: "LICENSES.Shop Establishment.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
      {
        id: "licencesOthers",
        Header: "Others",
        columns: [
          {
            id: "licencesOtherdateFile",
            Header: "Start Date",
            accessor: "LICENSES.Others.dateFile",
            Cell: startDateCell,
          },
          {
            id: "licencesOtherEndDate",
            Header: "End Date",
            accessor: "LICENSES.Others.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
    ],
  },
  {
    id: "rider",
    Header: "Rider",
    columns: [
      {
        id: "riderMfaRider",
        Header: "MFA Rider",
        columns: [
          {
            id: "riderMfaRiderdateFile",
            Header: "Start Date",
            accessor: "MFA_RIDER.Riders.dateFile",
            Cell: startDateCell,
          },
          {
            id: "riderMfaRiderEndDate",
            Header: "End Date",
            accessor: "MFA_RIDER.Riders.endDateFile",
            Cell: keyDateCell,
          },
        ],
      },
    ],
  },
];
