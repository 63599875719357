import { toast } from "react-toastify";
import { HTTP_METHODS, invokeApi } from "../../../../utils/http-service";
import { INTRANETHOSTNAME } from "../../utils/end-points";
import { REST_URLS } from "../../utils/end-points/app-document";
import { exportToExcel } from "../../utils";
export const confirmDocument = (pdfDetails, setPdfDetails, slug) => {
  if (!pdfDetails?.id) {
    return;
  }
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${REST_URLS.CONFIRM_DOCUMENT}${pdfDetails.id}`,
    null,
    null,
    slug
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success("Policy confirmed successfully", { autoClose: 2000 });
      setPdfDetails((prevDetails) => {
        delete prevDetails.notConfirmed;
        return {
          ...prevDetails,
        };
      });
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};

export const downloadReports = (mainFolder, slug) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.DOWNLOAD_REPORTS}${mainFolder}`,
    null,
    null,
    slug
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      if (!res?.length) {
        toast.info("No document is confirmed by any user", { autoClose: 2000 });
        return;
      }
      exportToExcel(res, "Confirmation Reports", null, true);
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};

export const queryDocumentCategories = (params, setDataFunc, slug) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.QUERY_DOCUMENT_CATEGORIES}`,
    null,
    params,
    slug
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      setDataFunc(res);
    })
    .catch((err) => {
      setDataFunc({});
    });
};
