import { Button } from '@mui/material';

export const getHeaderConfig = (receivedItems, editRecord) => {
  const headers = [
    {
      label: 'Category',
      id: 'mainCategory',
      render: (data) => {
        const { lineItem = {} } = data;
        return (lineItem && lineItem.name) || '';
      },
    },
    {
      label: 'Subcategory',
      id: 'categoryName',
      render: (data) => {
        const { item = {} } = data;
        const { subCategory = {} } = item;
        return (subCategory && subCategory.name) || '';
      },
    },
    {
      label: 'Item Name',
      id: 'name',
      render: (data) => {
        const { item = {} } = data;
        return (item && item.name) || '';
      },
    },
    {
      label: 'HSN Code',
      id: 'hsnCode',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return (item && item.hsnCode) || '';
      },
    },
    {
      label: 'UOM',
      id: 'uom',
    },
    {
      label: 'Qty',
      id: 'quantity',
    },
    {
      label: 'Inwarded Qty',
      id: 'inwardedQuantity',
    },
    {
      label: 'Received Qty',
      id: 'receivedItems',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return (item && item.receivedQuantity) || '';
      },
    },
    {
      label: 'Base Amount',
      id: 'receivedAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return getGRNOrderAmount([data], [item]);
      },
    },
    {
      label: 'Tax Amount',
      id: 'taxAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return getGRNOrderTaxAmount([data], [item]);
      },
    },
    {
      label: 'Total Amount',
      id: 'totalAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return getGRNOrderAmountWithTax([data], [item]);
      },
    },
  ];
  return headers;
};

export const getGRNOrderAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      // let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderTaxAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderAmountWithTax = (items, receivedItems) => {
  let sum = 0;
  items?.forEach?.((item) => {
    let receivedItem = receivedItems?.find?.((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};
