import React, { useState, useEffect } from "react";
import "./index.scss";
import HomeIcon from "@mui/icons-material/Home";
import { mainRedirectToIntranetPortal } from "../../utils/common-utils";
import { useLocation } from "react-router-dom";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";

const HomeRedirect = ({ localstorageKey }) => {
  const { pathname } = useLocation();
  const [showHome, setShowHome] = useState(true);

  useEffect(() => {
    const subsEndPoint = getProjectEndPoint(endPointsForProject.subs);
    const qnetNetEndPoint = getProjectEndPoint(endPointsForProject.qnet);
    if (
      pathname.startsWith(subsEndPoint) ||
      pathname.startsWith(qnetNetEndPoint)
    ) {
      setShowHome(false);
      return;
    }
    setShowHome(true);
  }, [pathname]);
  return (
    <>
      {showHome ? (
        <div className="home-redirect-con">
          <HomeIcon
            sx={{ color: "grey", cursor: "pointer" }}
            onClick={() => mainRedirectToIntranetPortal(localstorageKey)}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HomeRedirect;
