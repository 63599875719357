import {
  convertToCamelCaseFromUnderScore,
  exportToExcel,
} from '../../../../common-utilities/utils';
import {
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount,
} from '../helper';

export const downloadReports = (data) => {
  if (!data?.length) {
    return;
  }
  const results =
    data?.map?.((item) => {
      const site = item?.site;
      const document = item?.document;
      const items = document?.items || [];
      return {
        'PO Number': document?.poNumber || null,
        'Site Code & Name': `${site?.code || ''}${site?.code && ' - '}${
          site?.name || ''
        }`,
        'Vendor code': document?.vendorCode || null,
        'Vendor Name': document?.vendorName || null,
        'PO Type':
          convertToCamelCaseFromUnderScore(document?.poType || '') || null,
        'Base Amt': getPurchaseOrderWithoutTaxAmount(items),
        'GST Amt': getPurchaseOrderTaxAmount(items),
        'Total Amt': getPurchaseOrderAmount(items),
        'Pending With': item?.pendingWith || null,
        'Created By': item?.creatorName,
      };
    }) || [];

  exportToExcel(results, 'pending_po_actions');
};
