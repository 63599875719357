import { Button, Chip, Radio } from '@mui/material';
import { checkIsApprover } from '../../../utils/utils';

export const getHeaderConfig = (
  onApproveReject,
  approvalDetails,
  selectedRow,
  setSelectedRow
) => {
  const { document = {} } = approvalDetails || {};
  const { updatedPrice = 0, lineItemId = 0 } = document;
  const headers = [
    {
      label: '',
      id: 'selected',
      render: (data) => (
        <Radio
          checked={data.id === selectedRow.id}
          onChange={() => {
            setSelectedRow(data);
          }}
          value={data.id}
        />
      ),
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Modify Amount',
      id: 'currentAmount',
    },

    {
      label: 'Update Amount',
      id: 'price',
      render: (data) => {
        if (+data.id === lineItemId) {
          return updatedPrice;
        }
        return data.price;
      },
    },
    {
      label: 'Comments',
      id: 'comment',
      render: (data) => {
        const { document = {} } = approvalDetails;
        if (+data.id === lineItemId) {
          return document && document.comment;
        }
        return '';
      },
    },
    {
      label: 'Attachments',
      id: 'attachments',
      render: (data) => {
        const { document = {} } = approvalDetails;
        const { additionalDocs = [] } = document;
        if (+data.id === lineItemId) {
          return additionalDocs.map((attachment, index) => {
            return (
              <Chip
                label={`attachments ${index + 1}`}
                onClick={() => {
                  window.open(attachment, '_blank');
                }}
              />
            );
          });
        }
        return '';
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        const { status } = data;
        if (status === 'approval_in_progress') {
          return (
            <>
              <Button
                variant="text"
                onClick={() => onApproveReject({ ...data, toApprove: true })}
                disabled={
                  !(status === 'approval_in_progress') ||
                  data.id !== selectedRow.id ||
                  !checkIsApprover(approvalDetails)
                }
              >
                Approve
              </Button>
              <Button
                variant="text"
                onClick={() => onApproveReject({ ...data })}
                color="error"
                disabled={
                  !(status === 'approval_in_progress') ||
                  data.id !== selectedRow.id ||
                  !checkIsApprover(approvalDetails)
                }
              >
                Reject
              </Button>
            </>
          );
        } else {
          return data.status;
        }
      },
    },
  ];
  return headers;
};

export const getBudgetCost = (lineItems) => {
  let sum = 0;
  lineItems.forEach((item) => {
    sum = sum + item.currentAmount;
  });

  return sum;
};
