import { getAllExcelColumns, processExcelDates } from "../../../../common-utilities/utils/excel";
import { storeTypes } from "../../../utils/constants";
import {
  cocoAcquiredDateFields,
  cocoBuiltDateFields,
} from "../config/download-report.config";

const getDateColumns = (headers, columns, type) => {
  const dateHeaders = headers.reduce((columnsList, header, index) => {
    const column = columns[index];
    switch (type) {
      case storeTypes.cocoBuild:
        if (cocoBuiltDateFields.includes(header)) {
          columnsList.push(column);
        }
        break;
      case storeTypes.cocoAcquired:
        if (cocoAcquiredDateFields.includes(header)) {
          columnsList.push(column);
        }
        break;
      default:
        break;
    }
    return columnsList;
  }, []);

  return dateHeaders;
};

export const processExcelReport = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data,
  type
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateColumns = getDateColumns(headers, columns, type);
  const convertedWorkSheet = processExcelDates(data, worksheet, dateColumns);
  workbook.Sheets[sheetName] = convertedWorkSheet;
  workbook.SheetNames.push(sheetName);
};
