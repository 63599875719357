import React from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { useState, useEffect } from "react";
import { userStates } from "../../common-utilities/utils/constants";
import MainContainer from "../../../container";
import { tokenMainKeys } from "../../../utils/constants";

const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(userStates.isProcessing);
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = getOfflineData(tokenMainKeys.intranet, "user");
    if (userDetails) {
      setUser(userDetails);
      setSearchParams({});
    } else {
      navigate("/");
    }
  }, [access, refresh]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
