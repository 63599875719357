import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { DATE_FORMATS, storeTypes } from './constants';
// import * as XLSX from "xlsx";
export const appendZeroToTime = (number) => {
  if (number >= 0 && number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDate = (isoDateString, type) => {
  if (!isoDateString) {
    return '-';
  }
  try {
    const dateObj = new Date(isoDateString);
    if (Number.isNaN(dateObj.getTime())) {
      return '-';
    }
    switch (type) {
      case 'dateString':
        return isoDateString === '' ? '-' : dateObj.toLocaleDateString();
      case 'timeString':
        return dateObj.toLocaleTimeString();
      case DATE_FORMATS['YYYY-MM-DD']: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [date.getFullYear(), monthDisplay, dayDisplay].join('-');
      }
      case DATE_FORMATS['DD-MM-YYYY']: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [dayDisplay, monthDisplay, date.getFullYear()].join('-');
      }
      default:
        return `${dateObj.toLocaleDateString()} 
                  ${dateObj.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}`;
    }
  } catch (err) {
    return 'Invalid Date';
  }
};

export const convertCamelCaseToDisplayText = (txt) => {
  let strTmp = txt.replace(/([a-z])([A-Z])/g, '$1 $2');
  if (strTmp && strTmp.length > 0) {
    strTmp = `${strTmp[0].toUpperCase()}${strTmp.slice(1)}`;
  }
  return strTmp;
};

export const convertToCamelCaseFromUnderScore = (str) => {
  return convertCamelCaseToDisplayText(
    (str || '').toLowerCase().replace(/_+([a-z])/g, (m, w) => w.toUpperCase())
  );
};

export const removeUnderScore = (value) => {
  if (!value || !value.includes('_')) {
    return value;
  }
  return value.split('_').join(' ');
};

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const exportToExcel = (data, name) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = {
    Sheets: {
      data: ws,
    },
    SheetNames: ['data'],
  };
  const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(blob, 'file_' + name + EXCEL_EXTENSION);
};

export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};

export const getLabelsAndValuesForSelect = (
  labels,
  values,
  valueKey = 'value',
  splitVal = true
) => {
  return labels.map((item, index) => {
    if (typeof item === 'object') {
      const { label, value } = item;
      return { label, [valueKey]: value };
    }
    let finalLabel = item;
    if (splitVal) {
      finalLabel = item.split('_').join(' ');
    }

    return { label: finalLabel, [valueKey]: values[index] };
  });
};

export const defaultFltrDates = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const startYear = today.getMonth() >= 3 ? currentYear : currentYear - 1;
  const endYear = startYear + 1;

  return [
    new Date(startYear, 3, 1).toISOString(),
    new Date(endYear, 2, 31).toISOString(),
  ];
};
export const renderApproverStatus = (label, approverNo, dataApproverKey) => {
  const approverKey = `isApproved${approverNo}`;
  return {
    label,
    id: approverKey,
    render: (data) => {
      if (approverNo > 3 && data?.storeType == storeTypes.cocoBuild) {
        return '-';
      }
      return dataApproverKey
        ? data?.[dataApproverKey]
        : data?.[approverKey]
        ? 'Yes'
        : 'Pending';
    },
  };
};
