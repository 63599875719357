import React from "react";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import { dateFun } from "../../../common-utilities/utils";

const ViewComment = ({ data, setViewComments }) => {
  return (
    <div>
      <CustomModal
        title="Comments"
        onClose={() => {
          setViewComments({ isView: false, data: {} });
        }}
      >
        <div className="view-comment">
          <table>
            <thead>
              <tr>
                <th>Comment By</th>
                <th>Comment</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>AM</td>
                <td>{data?.amComments || "-"}</td>
                <td>{dateFun(data?.amApprovedAt, true) || "-"}</td>
              </tr>
              {data?.rmApprovalRequired && (
                <tr>
                  <td>RM</td>
                  <td>{data?.rmComments || "-"}</td>
                  <td>{dateFun(data?.rmApprovedAt, true) || "-"}</td>
                </tr>
              )}

              <tr>
                <td>Verification</td>
                <td>{data?.verificationAccountComment || "-"}</td>
                <td>
                  {dateFun(data?.verificationAccountApprovedAt, true) || "-"}
                </td>
              </tr>
              <tr>
                <td>Payment</td>
                <td>{data?.paymentAccountComment || "-"}</td>
                <td>{dateFun(data?.paymentAccountApprovedAt, true) || "-"}</td>
              </tr>
              <tr>
                <td>Finance</td>
                <td>{data?.financeAccountComment || "-"}</td>
                <td>{dateFun(data?.financeAccountApprovedAt, true) || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CustomModal>
    </div>
  );
};

export default ViewComment;
