import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BudgetReport from "./budget";
import PoCapexReport from "./poCapex";
import PoMaster from "./master-po";
import GrnReport from "./grn";
import { getOfflineData } from "../../utils/offline-services";
import AdvancePayment from "./advance-payment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Reports = () => {
  const [value, setValue] = useState(0);
  const [financeValue, setFinanceValue] = useState(2);
  const [role, setRole] = useState("");
  const user = getOfflineData("user");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const financeHandleChange = (event, newValue) => {
    setFinanceValue(newValue);
  };
  const financeTabs = () => {
    return (
      <div>
        <div className="rm-pt tabs-section">
          <Box>
            <Tabs value={financeValue} onChange={financeHandleChange}>
              <Tab label="Master" />
              <Tab label="Budget" />
              <Tab label="Purchase Order" />
              <Tab label="GRN" />
              <Tab label="Advance Payment" />
            </Tabs>
          </Box>
        </div>
        <TabPanel value={financeValue} index={0}>
          <PoMaster />
        </TabPanel>
        <TabPanel value={financeValue} index={1}>
          <BudgetReport />
        </TabPanel>
        <TabPanel value={financeValue} index={2}>
          <PoCapexReport />
        </TabPanel>
        <TabPanel value={financeValue} index={3}>
          <GrnReport />
        </TabPanel>
        <TabPanel value={financeValue} index={4}>
          <AdvancePayment />
        </TabPanel>
      </div>
    );
  };
  useEffect(() => {
    setRole(user?.role?.toUpperCase());
  }, [user]);
  return (
    <div>
      {role.includes("FINANCE") || role.includes("ADMIN") ? (
        financeTabs()
      ) : (
        <div className="rm-pt">
          <div className="tabs-section">
            <Box>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Purchase Order" />
                <Tab label="GRN" />
                <Tab label="Advance Payment" />
              </Tabs>
            </Box>
          </div>

          <TabPanel value={value} index={0}>
            <PoCapexReport />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GrnReport />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AdvancePayment />
          </TabPanel>
        </div>
      )}
    </div>
  );
};

export default Reports;
