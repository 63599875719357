import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { validAddressTypes, validAddressLabels } from "../../utils/constants";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { allRegions } from "../../../common-utilities/utils/region";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";

const Address = () => {
  const [addressType, setaddressType] = useState([]);
  const [type, setTypes] = useState([]);
  const [addressDetails, setAddressDetails] = useState({});
  const selectDropDownStyles = { menu: (styles) => ({ ...styles, zIndex: 2 }) };
  const previousController = useRef();
  const [autoCompleteOptions, setautoCompleteOptions] = useState([]);
  const [regionList, setRegionList] = useState({
    country: [],
    state: [],
  });

  const getEntityNameForInput = () => {
    return addressDetails?.type?.value === validAddressTypes.person
      ? "personId"
      : "storeNo";
  };

  useEffect(() => {
    const countries = Object.keys(allRegions);
    const countriesLabelsAndSelect = getLabelsAndValuesForSelect(
      countries,
      countries
    );
    setRegionList((prevVal) => ({
      ...prevVal,
      country: countriesLabelsAndSelect,
    }));
    const addressLabels = [validAddressLabels.person, validAddressLabels.store];
    const addressTypes = [validAddressTypes.person, validAddressTypes.store];
    const allTypeLabels = getLabelsAndValuesForSelect(
      addressLabels,
      addressTypes
    );
    setTypes(allTypeLabels);
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.LIST_ADDRESS_TYPE}`)
      .then((response) => {
        if (response && !response?.message) {
          const data = getLabelsAndValuesForSelect(response, response);
          setaddressType(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const setAddressDetailsOnChange = (e) => {
    const { name, value } = e.target;
    const newAddressDetails = { ...addressDetails };
    if (name === "country") {
      delete newAddressDetails.state;
      const states = allRegions[value?.value];
      if (states) {
        const allStates = Object.keys(states);
        const stateLabels = getLabelsAndValuesForSelect(allStates, allStates);
        setRegionList((prevVal) => ({ ...prevVal, state: stateLabels }));
        setAddressDetails({ ...newAddressDetails, [name]: value });
      } else {
        setRegionList((prevVal) => ({ ...prevVal, state: [] }));
        setAddressDetails({ ...newAddressDetails, [name]: value });
      }
      return;
    }
    setAddressDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const disableInputCheck = () => {
    if (!addressDetails?.type?.value) {
      return true;
    }
    if (!addressDetails.personId && !addressDetails.storeNo) {
      return true;
    }
    return false;
  };

  const getPayload = () => {
    const payload = {
      type: addressDetails?.type?.value,
    };

    const personId = addressDetails?.personId;
    const storeNo = addressDetails?.storeNo;
    const addressType = addressDetails?.addressType?.value;
    const address1 = addressDetails?.address1;
    const address2 = addressDetails?.address2;
    const address3 = addressDetails?.address3;
    const city = addressDetails?.city;
    const state = addressDetails?.state?.value;
    const country = addressDetails?.country?.value;
    const county = addressDetails?.county;
    const attn = addressDetails?.attn;
    const zipCode = addressDetails?.zipCode;

    if (personId) {
      payload.personId = personId;
    }

    if (storeNo) {
      payload.storeNo = storeNo;
    }
    if (addressType) {
      payload.addressType = addressType;
    }
    if (address1) {
      payload.address1 = address1;
    }
    if (address2) {
      payload.address2 = address2;
    }
    if (address3) {
      payload.address3 = address3;
    }
    if (city) {
      payload.city = city;
    }
    if (state) {
      payload.state = state;
    }
    if (country) {
      payload.country = country;
    }
    if (county) {
      payload.county = county;
    }
    if (zipCode) {
      payload.zipCode = zipCode;
    }

    if (attn) {
      payload.attn = attn;
    }

    return payload;
  };

  const saveAddress = () => {
    if (!addressDetails?.type?.value) {
      return;
    }
    if (
      !Number.isInteger(parseInt(addressDetails.personId)) &&
      !Number.isInteger(parseInt(addressDetails.storeNo))
    ) {
      return;
    }

    const payload = getPayload();
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_ADDRESS}`,
      payload
    )
      .then((response) => {
        if (response && !response.message) {
          toast.success("Address created successfully", { autoClose: 2000 });
          setAddressDetails({});
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast.error(error?.message, { autoClose: 2000 });
      });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const axiosUrl =
      addressDetails?.type?.value === validAddressTypes.person
        ? HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm
        : HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm;
    const controller = new AbortController();
    const id =
      addressDetails?.type?.value === validAddressTypes.person
        ? "id"
        : "storeNo";
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: axiosUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p[id],
          id: p[id],
        };
      });
      setautoCompleteOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    if (value?.trim()) {
      getData(value);
    } else {
      setautoCompleteOptions([]);
    }
    const name = getEntityNameForInput();
    setAddressDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value ? value : "",
    }));
  };

  return (
    <div>
      <div className="main-add-con">
        <div className="g-r-3 add-type-div">
          <div>
            <p className="input-title-paragrah">Select Address Type</p>
            <Select
              classNamePrefix="custom_select"
              placeholder="Address Type"
              name="type"
              options={type}
              value={addressDetails.type || ""}
              onChange={(value, e) => {
                let allDetails = { ...addressDetails };
                delete allDetails.personId;
                delete allDetails.storeNo;
                setautoCompleteOptions([]);
                allDetails = { ...allDetails, [e.name]: value };
                setAddressDetails(allDetails);
              }}
            />
          </div>
          <div>
            {addressDetails?.type && (
              <>
                <p className="input-title-paragrah">
                  Select
                  {addressDetails?.type?.value === validAddressTypes.person
                    ? " Person Num"
                    : " Store Number"}
                </p>

                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  getOptionLabel={(option) => {
                    return option.title ? option.title.toString() : "";
                  }}
                  className="subs-autocomplete"
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        const name = getEntityNameForInput();
                        setAddressDetails((prevDetails) => ({
                          ...prevDetails,
                          [name]: "",
                        }));
                      }}
                    />
                  }
                  options={autoCompleteOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="sel-item"
                      label={
                        addressDetails?.type?.value === validAddressTypes.person
                          ? "Person Num"
                          : "Store Num"
                      }
                    />
                  )}
                  inputValue={
                    addressDetails?.type?.value === validAddressTypes.person
                      ? addressDetails.personId
                        ? addressDetails.personId
                        : ""
                      : addressDetails.storeNo
                      ? addressDetails.storeNo
                      : ""
                  }
                  onInputChange={onInputChange}
                  onChange={(e, val) => {
                    if (val) {
                      const name = getEntityNameForInput();
                      setAddressDetails((prevDetails) => ({
                        ...prevDetails,
                        [name]: val.id,
                      }));
                    }
                  }}
                />
              </>
            )}
          </div>
          <div>
            <Button
              size="small"
              variant="contained"
              endIcon={<SaveIcon />}
              disabled={disableInputCheck()}
              sx={{ mt: 2.5, ml: "80%", float: "right", width: "20%" }}
              onClick={saveAddress}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="title">Address Details</div>
        <div className="input-form-container">
          <div className="address-content-container">
            <div className="g-r-2">
              <Select
                classNamePrefix="custom_select"
                disabled={disableInputCheck()}
                name="addressType"
                onChange={(value, e) => {
                  const { name } = e;
                  setAddressDetailsOnChange({ target: { name, value } });
                }}
                styles={selectDropDownStyles}
                value={addressDetails.addressType || ""}
                options={addressType}
                getOptionLabel={(op) => op.label}
                getOptionValue={(op) => op.value}
                isSearchable
                placeholder="Type"
              />
              <TextField
                id="attention"
                label="Attention"
                name="attn"
                className="subs-text-field"
                value={addressDetails.attn || ""}
                onChange={setAddressDetailsOnChange}
                fullWidth
                variant="outlined"
                disabled={disableInputCheck()}
              />
            </div>
            <div className="g-r-2">
              <TextField
                id="located-in"
                label="Located In"
                className="subs-text-field"
                name="address3"
                value={addressDetails.address3 || ""}
                onChange={setAddressDetailsOnChange}
                fullWidth
                variant="outlined"
                disabled={disableInputCheck()}
              />
              <TextField
                id="street/po-box"
                label="Street/PO Box"
                name="address2"
                className="subs-text-field"
                value={addressDetails.address2 || ""}
                onChange={setAddressDetailsOnChange}
                disabled={disableInputCheck()}
                fullWidth
                variant="outlined"
              />
            </div>
            <div className="g-r-2">
              <TextField
                id="address1"
                label="Address 1"
                fullWidth
                variant="outlined"
                className="subs-text-field"
                name="address1"
                value={addressDetails.address1 || ""}
                onChange={setAddressDetailsOnChange}
                disabled={disableInputCheck()}
              />
              <TextField
                id="county"
                label="county"
                fullWidth
                variant="outlined"
                className="subs-text-field"
                name="county"
                value={addressDetails.county || ""}
                onChange={setAddressDetailsOnChange}
                disabled={disableInputCheck()}
              />
            </div>
            <div className="g-r-2">
              <TextField
                id="city"
                label="City"
                fullWidth
                variant="outlined"
                className="subs-text-field"
                name="city"
                value={addressDetails.city || ""}
                onChange={setAddressDetailsOnChange}
                disabled={disableInputCheck()}
              />
              <TextField
                id="postal-code"
                label="Postal Code"
                fullWidth
                type="number"
                className="subs-text-field"
                variant="outlined"
                name="zipCode"
                value={addressDetails.zipCode || ""}
                onChange={setAddressDetailsOnChange}
                disabled={disableInputCheck()}
              />
            </div>
            <div className="g-r-2">
              <Select
                classNamePrefix="custom_select"
                isSearchable
                name="country"
                options={regionList.country}
                value={addressDetails.country || null}
                onChange={(value, e) => {
                  const { name } = e;
                  setAddressDetailsOnChange({ target: { value, name } });
                }}
                disabled={disableInputCheck()}
                placeholder="Country"
              />
              <Select
                classNamePrefix="custom_select"
                isSearchable
                name="state"
                options={regionList.state}
                value={addressDetails.state ? addressDetails.state : null}
                onChange={(value, e) => {
                  const { name } = e;
                  setAddressDetailsOnChange({ target: { value, name } });
                }}
                disabled={disableInputCheck()}
                placeholder="State/Prov"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
