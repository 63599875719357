import { setProperty } from '../../../../common-utilities/utils/misc';
import { modalConstants } from '../config/constants';

export const modalHandler = (data, type, setConfig) => {
  switch (type) {
    case modalConstants.update:
      setProperty(
        'modalDetails',
        { type, data, title: 'Update Config' },
        setConfig
      );
      break;
    case modalConstants.delete:
      setProperty(
        'modalDetails',
        { type, data, title: 'Delete Config' },
        setConfig
      );
      break;
    case modalConstants.create:
      setProperty(
        'modalDetails',
        { type, data: null, title: 'Create Config' },
        setConfig
      );
      break;
    case modalConstants.createFinanceRole:
      setProperty(
        'modalDetails',
        { type, data: null, title: 'Create Finance Role' },
        setConfig
      );
      break;
    default:
      break;
  }
};
