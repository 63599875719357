import React, { useState, useEffect } from "react";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getPendingVoucherHeaderConfig } from "./config";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import { getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import ViewVoucher from "./viewVoucher";
import CustomModal from "../../../common-utilities/core/modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Drawer } from "@mui/material";

const Draft = () => {
  const [tableData, setTableData] = useState([]);
  const [showFiles, setShowFiles] = useState({ isShow: false, data: [] });
  const [storeDetails, setStoreDetails] = useState({});
  const [viewVoucher, setViewVoucher] = useState({ isView: false, data: {} });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const userData = getOfflineData("user");
  const getStoreDetails = () => {
    const store = userData?.userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      {
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      }
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const processDraftVoucherHandler = (data) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.PROCESS_DRAFT}${data?._id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          return;
        } else {
          toast.success("Voucher Process Successfully", { autoClose: 2000 });
          loadData(filters);
        }
      })
      .catch((err) => console.log(err));
  };
  const rejectVoucher = (data) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.REJECT_VOUCHER}${data?._id}`
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.info("Voucher Rejected Successfully", { autoClose: 2000 });
          loadData(filters);
        }
      })
      .catch((err) => console.log(err));
  };
  const viewVoucherHandler = (data, type) => {
    switch (type) {
      case "view files":
        setShowFiles({ isShow: true, data: data?.attachment });
        break;
      case "view voucher":
        setViewVoucher({ isView: true, data: data });
        break;
      case "process":
        processDraftVoucherHandler(data);
        break;
      case "reject":
        rejectVoucher(data);
        break;
      default:
        break;
    }
  };
  const loadData = (filters) => {
    const payload = { ...filters, status: "Draft" };
    if (storeDetails?.id) {
      payload["store"] = storeDetails.id;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.VOUCHER}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => ({
            ...ele,
          }));
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (userData?.role === "STORE") {
      getStoreDetails();
    }
  }, []);
  useEffect(() => {
    loadData(filters);
  }, [storeDetails]);
  return (
    <div>
      <MuiTable
        columnsList={getPendingVoucherHeaderConfig("draft")}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onClick={viewVoucherHandler}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
      {showFiles?.isShow && (
        <CustomModal
          title="Files"
          onClose={() => {
            setShowFiles({ isShow: false, data: [] });
          }}
        >
          {showFiles?.data.length > 0 ? (
            showFiles.data.map((item, index) => {
              return (
                <div className="downloadgrid" key={index}>
                  <div>{item?.title}</div>
                  <div>
                    <a target="_blank" download={true} href={item?.url}>
                      <DownloadForOfflineIcon color="action" />
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Files Attached</p>
          )}
        </CustomModal>
      )}
      <Drawer
        anchor="right"
        open={viewVoucher?.isView}
        onClose={() => setViewVoucher({ isView: false, data: {} })}
      >
        <ViewVoucher data={viewVoucher.data} setViewVoucher={setViewVoucher} />
      </Drawer>
    </div>
  );
};

export default Draft;
