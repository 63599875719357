import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { modalActions } from "../constants";

export const getCategoryHeaderConfig = () => {
  const config = [
    {
      label: "Main Category",
      id: "name",
      render: (data) => {
        return (
          <div key="main category">
            {data?.parent ? data?.parent?.name : data?.name}
          </div>
        );
      },
    },
    {
      label: "Subcategory",
      id: "parent",
      render: (data) => {
        return (
          <div key="subcategory">
            {data?.parent ? data?.name : data?.parent?.name}
          </div>
        );
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <IconButton
            key="actions"
            onClick={() => {
              onClick(data, modalActions.updateCategory);
            }}
            sx={{ pt: 0.5, pb: 0.5 }}
          >
            <EditIcon id="edit" color="primary" />
          </IconButton>
        );
      },
    },
  ];

  return config;
};


export const defaultFilter = { page: 1, limit: 30}
