export const setOfflineData = (key, data) => {
  if (data !== "" && data !== null && data !== undefined) {
    localStorage.setItem(`subway-vendor-onboard:${key}`, JSON.stringify(data));
  }
};

export const getOfflineData = (key) => {
  const data = localStorage.getItem(`subway-vendor-onboard:${key}`);
  if (data !== "" && data !== null && data !== undefined) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const clearOfflineData = (key) => {
  localStorage.removeItem(`subway-vendor-onboard:${key}`);
};
