import AutoCompleteMultiSelect from "../../../../common-utilities/core/autocomplete-multiselect";
import {
  investmentOptions as cocoBuiltInvestmentOptions,
  occupanyYear1Options as cocoBuiltOccupanyYear1Options,
  profitAndLossOptions as cocoBuiltProfitAndLossOptions,
  storeDetails as cocoBuiltStoreDetails,
} from "../constants/coco-built.js";

import {
  investmentOptions as cocoAcquiredInvestmentOptions,
  occupanyYear1Options as cocoAcquiredOccupanyYear1Options,
  profitAndLossOptions as cocoAcquiredProfitAndLossOptions,
  storeDetails as cocoAcquiredStoreDetails,
  ttmOptions as cocoAcqTTMOptions,
} from "../constants/coco-acquired";
import { storeTypes } from "../../../utils/constants";

export const SelectFilters = ({ filters, setFilters }) => {
  const onChange = (e, val) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [e.target.name]: val,
      };
    });
  };

  return (
    <>
      <div className="filters-2">
        <AutoCompleteMultiSelect
          placeholder="Store details*"
          value={filters?.storeDetails || []}
          options={
            !filters?.storeType
              ? []
              : filters?.storeType === storeTypes.cocoBuild
              ? cocoBuiltStoreDetails
              : cocoAcquiredStoreDetails
          }
          name="storeDetails"
          onChange={onChange}
          className="mutilSelect"
        />

        {filters?.storeType === storeTypes.cocoAcquired && (
          <AutoCompleteMultiSelect
            placeholder="TTM Vs F1Y"
            value={filters?.ttmVsF1Y || []}
            options={cocoAcqTTMOptions}
            name="ttmVsF1Y"
            onChange={onChange}
            className="mutilSelect"
          />
        )}

        <AutoCompleteMultiSelect
          placeholder="INVESTMENT"
          value={filters?.investment || []}
          options={
            !filters?.storeType
              ? []
              : filters?.storeType === storeTypes.cocoBuild
              ? cocoBuiltInvestmentOptions
              : cocoAcquiredInvestmentOptions
          }
          name="investment"
          onChange={onChange}
          className="mutilSelect"
        />

        <AutoCompleteMultiSelect
          placeholder="P & L"
          value={filters?.profitLoss || []}
          options={
            !filters?.storeType
              ? []
              : filters?.storeType === storeTypes.cocoBuild
              ? cocoBuiltProfitAndLossOptions
              : cocoAcquiredProfitAndLossOptions
          }
          name="profitLoss"
          onChange={onChange}
          className="mutilSelect"
        />

        <AutoCompleteMultiSelect
          placeholder="Occupancy Year1"
          value={filters?.occupanyYear1 || []}
          options={
            !filters?.storeType
              ? []
              : filters?.storeType === storeTypes.cocoBuild
              ? cocoBuiltOccupanyYear1Options
              : cocoAcquiredOccupanyYear1Options
          }
          name="occupanyYear1"
          onChange={onChange}
          className="mutilSelect"
        />
      </div>
    </>
  );
};
