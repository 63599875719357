import { pick } from "../../../common-utilities/utils";
import { BD_LEAD_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/bd-lead";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";
import { invokeApi } from "../../utils/http-service";
import { fmtDwndRpt } from "../document/utils/report-format.utils";

export const getDashboardData = (filter, setDataFunc) => {
  const params = pick(filter, ["startDate", "endDate"], true);
  addCorrectStartEndDate(params);
  invokeApi(
    HTTP_METHODS.GET,
    `${BD_LEAD_HOSTNAME}${REST_URLS.DASHBOARD}`,
    null,
    params
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc({});
        return;
      }
      setDataFunc({ results: res });
    })
    .catch((err) => {
      setDataFunc({});
    });
};

export const downloadReport = (filter) => {
  const allFilter = { ...filter };
  addCorrectStartEndDate(allFilter);
  invokeApi(
    HTTP_METHODS.GET,
    `${BD_LEAD_HOSTNAME}${REST_URLS.LEADS_LIST}`,
    null,
    allFilter,
    null
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      fmtDwndRpt(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getStatusData = (filter, setTableData) => {
  const params = pick(
    filter,
    ["status", "limit", "page", "sortBy", "startDate", "endDate", "region"],
    true
  );
  addCorrectStartEndDate(params);
  invokeApi(
    HTTP_METHODS.GET,
    `${BD_LEAD_HOSTNAME}${REST_URLS.LEADS_LIST}`,
    null,
    {
      ...params,
    },
    null
  )
    .then((res) => {
      if (res?.message) {
        setTableData({});
        return;
      }
      setTableData(res);
    })
    .catch((err) => {
      setTableData({});
    });
};
