import { dateFun, exportToExcel } from "../../../../common-utilities/utils";
import { storeTypes } from "../../../../common-utilities/utils/constants";

import { getStateList } from "../../document/utils";
import { cocoAcquiredConfig } from "../config/coco-acquired";
import { cocoBuiltConfig } from "../config/coco-built";
import {
  getAllExcelColumns,
  getExcelColumns,
  processExcelDates,
  processExcelPercentage,
} from "../../../../common-utilities/utils/excel";
import { getNestedPathValue } from "../../../../common-utilities/utils/misc2";

export const startDashboard = (setSelectVals) => {
  const statesSelectList = getStateList("India", false);

  setSelectVals((prevData) => ({
    ...prevData,
    state: statesSelectList,
  }));
};

export const showTable = (filters) => {
  return (
    !!filters?.storeType &&
    !!filters?.cluster &&
    !!filters?.storeDetails?.length
  );
};

export const checkForAll = (selectArr) => {
  if (!selectArr?.length) {
    return true;
  }

  return selectArr?.filter?.((item) => item.value === "All")?.length;
};

// For checking for None option being selected in any of the dropdowns
export const checkForNone = (selectArr) => {
  if (!selectArr?.length) {
    return true;
  }

  return selectArr?.filter?.((item) => item.value === "None")?.length;
};

export const checkOverall = (filters) => {
  let overall = true;
  const overallKeys = [
    "storeDetails",
    "profitLoss",
    "investment",
    "occupanyYear1",
  ];
  overallKeys.forEach((item) => {
    overall = overall && checkForAll(filters?.[item]);
  });

  return overall;
};

export const getConfigFields = (config, filters, key, mainKey) => {
  const allSelected = checkForAll(filters?.[key]);
  if (allSelected) {
    return config;
  }
  const nonSelected = checkForNone(filters?.[key]);

  if (nonSelected) {
    return [];
  }
  // This logic is specific to profit and loss(can be used elsewhere also), there were many sub groupings
  // mainKey is used to check that the main group is selected by the user in the dropdown
  // If it is selected by the user, then we return the whole configuration
  // Otherwise we return empty array

  if (mainKey) {
    const isKeyPresent = filters?.[key]?.find((item) => item.value === mainKey);
    if (isKeyPresent) {
      return config;
    }
    return [];
  }
  const valueMap = filters?.[key]?.reduce?.((unqiueMap, item) => {
    unqiueMap[item.value] = true;
    return unqiueMap;
  }, {});

  return config.filter((item) => !!valueMap[item.id]);
};

export const downloadReports = (filters, results) => {
  const format =
    filters?.storeType === storeTypes.cocoBuild
      ? cocoBuiltConfig(filters)
      : cocoAcquiredConfig(filters);
  const allLabels = {
    dataLabels: {},
    percentageLabels: {},
    noDecimalLabels: {},
    xLabels: {},
  };
  const convertedData =
    results?.map?.((item) => {
      return format.reduce((obj, data) => {
        const {
          label,
          id,
          value,
          isDateLabel,
          percentageConversion,
          removeDecimal,
          xConversion,
        } = data;
        if (typeof value === "function") {
          obj[label] = value(item);
        } else {
          obj[label] = item[id];
        }

        if (isDateLabel) {
          allLabels.dataLabels[label] = true;
        }
        if (percentageConversion) {
          allLabels.percentageLabels[label] = true;
        }
        if (removeDecimal) {
          allLabels.noDecimalLabels[label] = true;
        }
        if (xConversion) {
          allLabels.xLabels[label] = true;
        }
        return obj;
      }, {});
    }) || [];
  const dateFieldList = Object.keys(allLabels.dataLabels);
  exportToExcel(
    convertedData,
    "Flat Sheet Report",
    null,
    true,
    (workbook, worksheet, sheetName, headers, data) => {
      const columns = getAllExcelColumns(worksheet, headers);
      const dateColumns = getExcelColumns(headers, dateFieldList, columns);
      const convertedWorkSheet = processExcelDates(
        convertedData,
        worksheet,
        dateColumns
      );

      const percentageHeaders = Object.keys(allLabels.percentageLabels);
      const percentageColumns = getExcelColumns(
        headers,
        percentageHeaders,
        columns
      );

      const withPecentage = processExcelPercentage(
        convertedData,
        convertedWorkSheet,
        percentageColumns
      );

      workbook.Sheets[sheetName] = withPecentage;
      workbook.SheetNames.push(sheetName);
    },
    Object.keys(allLabels.noDecimalLabels)
  );
};

export const getSlicedNum = (data, key, addPercenSymbal, callback) => {
  let value = getNestedPathValue(key, data);
  if (typeof callback === "function") {
    value = callback(value);
  }
  if (typeof value === "number") {
    value = `${value}`;
  }

  const [first, second] = value?.split?.(".") || [];
  const finalSecond = second ? `.${second.slice(0, 2)}` : "";
  return (
    <span key={key}>
      {first}
      {finalSecond}
      {first && addPercenSymbal ? "%" : ""}
    </span>
  );
};

export const formattedDateRender = (data, key) => {
  let date = data?.[key];
  if (date) {
    date = new Date(date);
    try {
      date = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        // hour: "2-digit",
        // minute: "2-digit",
        // second: "2-digit",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (date == "Invalid Date") {
        date = "";
      }
    } catch (e) {
      date = "";
    }
  }
  return (
    <span key={key} align="center">
      {date}
    </span>
  );
};

export const formatedPercentageRender = (data, key, getValue) => {
  if (getValue) {
    return data?.[key];
  }
  let num = data?.[key];
  if (num) {
    num = `${num * 100}`;
    num = num.split(".");
    if (num[1]) {
      num[1] = num[1].slice(0, 2);
    }
    num = num.join(".");
  }

  return (
    <span key={key} align="center">
      {data?.[key] && `${num}%`}
    </span>
  );
};
