import { Button } from '@mui/material';
import { convertToCamelCaseFromUnderScore } from '../../../common-utilities/utils';
export const getBudgetTableColumns = editVendor => {
  const headers = [
    {
      label: 'Budget Id',
      id: 'id'
    },
    {
      label: 'Site Code',
      id: 'site.code'
    },
    {
      label: 'Site Name',
      id: 'site.name'
    },
    {
      label: 'Budget Type',
      id: 'budgetType',
      render: data => {
        const { budgetType = '' } = data;
        return convertToCamelCaseFromUnderScore(budgetType);
      }
    },
    {
      label: 'Initial Budget',
      id: 'initial',
      render: data => {
        const { lineItems = [] } = data;
        return getInitialBudgetCost(lineItems);
      }
    },
    {
      label: 'Modified',
      id: 'modified',
      render: data => {
        const { lineItems = [] } = data;
        return getModifiedBudgetCost(lineItems);
      }
    },
    {
      label: 'Available Budget',
      id: 'available',
      render: data => {
        const { lineItems = [] } = data;
        return getAvailableBudgetCost(lineItems);
      }
    },
    {
      label: 'Actions',
      id: 'actions',
      render: data => {
        const { status } = data;
        return (
          <>
            <Button variant='text' onClick={() => editVendor(data, 'VIEW')}>
              View
            </Button>
            {status === 'inactive' && (
              <Button
                variant='text'
                onClick={() => editVendor(data, 'EDIT')}
                disabled={true}
              >
                Edit
              </Button>
            )}
          </>
        );
      }
    }
  ];
  return headers;
};


export const getAvailableBudgetCost = lineItems => {
  let sum = 0;
  lineItems.forEach(item => {
    sum = sum + +item.availableAmount;
  });

  return sum.toFixed(2);
};

export const getInitialBudgetCost = lineItems => {
  let sum = 0;
  lineItems.forEach(item => {
    sum = sum + +item.initialAmount;
  });

  return sum.toFixed(2);
};

export const getModifiedBudgetCost = lineItems => {
  let sum = 0;
  lineItems.forEach(item => {
    sum = sum + +item.currentAmount;
  });

  return sum.toFixed(2);
};
