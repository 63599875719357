import React, { useState, useEffect } from "react";
import "./index.scss";

import { getHeaderConfig } from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import CreateRole from "./create-role";
import CustomModal from "../../packages/common-utilities/core/modal";
import MuiTable from "../../packages/common-utilities/core/mui-table";
import SaveIcon from "@mui/icons-material/Save";
const Roles = () => {
  const [tableData, setTableData] = useState({});
  const [isCreateUserRole, setIsCreateUserRole] = useState(false);
  const [userRoleName, setUserRoleName] = useState("");
  const [isCreateRole, setIsCreateRole] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 4,
    sortBy: "-createdAt",
  });
  const loadData = (passedFilters) => {
    const roleFilter =
      passedFilters && typeof passedFilters === "object"
        ? { ...passedFilters }
        : { ...filters };
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ROLES}`,
      null,
      roleFilter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const results =
            res?.results?.map((item) => {
              const { name, roles } = item;
              const roleList = roles?.map((item) => {
                return item?.name;
              });
              return { name, roles: roleList };
            }) || [];

          setTableData({ ...res, results });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   loadData();
  // }, []);
  useEffect(() => {
    loadData(filters);
  }, []);
  const userRoleHandler = (e) => {
    setUserRoleName(e.target.value);
  };
  const userRoleFormSubmitHandler = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.CREATE_USER_ROLE}`,
      { name: userRoleName }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("User Role Created Successfully", { autoClose: 2000 });
          setUserRoleName("");
          setIsCreateUserRole(false);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="roles-con">
      {!isCreateRole && (
        <div>
          <div className="btn-con">
            <Button
              variant="outlined"
              className="common-rnd-btn"
              onClick={() => {
                setIsCreateUserRole(true);
              }}
              sx={{ mr: 2 }}
            >
              Create User Role
            </Button>
            <Button
              variant="contained"
              className="common-rnd-btn"
              onClick={() => {
                setIsCreateRole(true);
              }}
            >
              Create Role
            </Button>
          </div>
          <div className="si-table-con">
            <MuiTable
              columnsList={getHeaderConfig()}
              dataList={tableData.results || []}
              filters={filters}
              pageCount={tableData.totalPages}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
                loadData({
                  ...filters,
                  page,
                });
              }}
            ></MuiTable>
          </div>
        </div>
      )}

      {isCreateRole && (
        <CreateRole closeModal={setIsCreateRole} loadData={loadData} />
      )}
      {isCreateUserRole && (
        <CustomModal
          title="Create New Role for User"
          onClose={() => {
            setIsCreateUserRole(false);
            setUserRoleName("");
          }}
        >
          <TextField
            label="Name"
            className="new-textfield"
            onChange={userRoleHandler}
            fullWidth
            size="small"
          />
          <div className="common-btn-ctr">
            <div>
              <Button
                variant="contained"
                className="common-modal-crt-btn"
                onClick={userRoleFormSubmitHandler}
                fullWidth
                startIcon={<SaveIcon />}
                disabled={!userRoleName}
                sx={{ mt: 3 }}
              >
                Save
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Roles;
