import { Button, Chip } from '@mui/material';
import { formatDate } from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../../utils/constants';

export const getHeaderConfig = (receivedItems, editRecord) => {
  const headers = [
    {
      label: 'Category',
      id: 'mainCategory',
      render: (data) => {
        const { lineItem = {} } = data;
        return (lineItem && lineItem.name) || '';
      },
    },
    {
      label: 'Subcategory',
      id: 'categoryName',
      render: (data) => {
        const { item = {} } = data;
        const { subCategory = {} } = item;
        return (subCategory && subCategory.name) || '';
      },
    },
    {
      label: 'Item Name',
      id: 'name',
      render: (data) => {
        const { item = {} } = data;
        return (item && item.name) || '';
      },
    },
    {
      label: 'HSN Code',
      id: 'receivedItems',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return (item && item.hsnCode) || '';
      },
    },
    {
      label: 'UOM',
      id: 'uom',
    },
    {
      label: 'Quantity',
      id: 'quantity',
    },
    {
      label: 'inwardedQuantity',
      id: 'inwardedQuantity',
    },
    {
      label: 'Received Quantity',
      id: 'receivedItems',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return (item && item.receivedQuantity) || '';
      },
    },
    {
      label: 'Tax Amount',
      id: 'receivedAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return getGRNOrderTaxAmount([data], [item]);
      },
    },
    {
      label: 'Amount',
      id: 'receivedAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return getGRNOrderAmount([data], [item]);
      },
    },
    {
      label: 'Total Amount',
      id: 'receivedAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.poItemId === data._id) || {};
        return Math.round(
          +getGRNOrderAmount([data], [item]) +
            +getGRNOrderTaxAmount([data], [item])
        ).toFixed(2);
      },
    },
  ];
  return headers;
};

export const getGRNOrderAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      // let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderTaxAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getPaymentDetails = () => {
  return [
    {
      label: 'Payment Amount',
      id: 'paymentAmount',
    },
    {
      label: 'UTR',
      id: 'utr',
    },
    {
      label: 'Date Of Payment',
      id: 'dateOfPayment',
      render: (data) => {
        return (
          (data &&
            formatDate(data.dateOfPayment, DATE_FORMATS['DD-MM-YYYY'])) ||
          ''
        );
      },
    },
    {
      label: 'TDS Amount',
      id: 'tdsAmount',
    },
    {
      label: 'Attachments',
      id: 'name',
      render: (data) => {
        const { paymentDocUrls = [] } = data;
        return (paymentDocUrls || []).map((attachment, i) => {
          return (
            <Chip
              label={'attachment' + (i + 1)}
              onClick={() => window.open(attachment, '_blank')}
            />
          );
        });
      },
    },
  ];
};
