import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VendorOnboardHome from '../components/home';
import VendorOnboardForm from '../components/vendor-onboard-form';
import VendorRequest from '../components/vendor-request';
import VendorRequestDetails from '../components/vendor-request-details';
import VendorOnboardPrivateRoute from './private-route';
import VendorOnboardEditForm from '../components/vendor-onboard-edit-form';

const VendorOnboardRoutes = () => {  
  return (
    <Routes>
      <Route exact path='/' element={<VendorOnboardPrivateRoute />}>
        <Route exact path='/' element={<VendorOnboardHome />} />
        <Route exact path='/:id' element={<VendorRequestDetails />} />
        <Route exact path='/update/:id' element={<VendorOnboardEditForm />} />
        <Route exact path='/send' element={<VendorRequest />} />
      </Route>
      <Route
        exact
        path='/form'
        element={<VendorOnboardForm />}
      />
    </Routes>
  );
};

export default VendorOnboardRoutes;
