/** @format */

import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {
  getOfflineData,
  setOfflineData,
  clearOfflineData,
} from "./offline-services";
import { REST_URLS } from "../../common-utilities/utils/end-points/invoice-tracking";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
const redirectToIntranetPortal = (tokenKey) => {
  clearOfflineData("user-invoice-tracking", tokenKey);
  clearOfflineData("subway-invoice-tracking", tokenKey);
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const HTTP_METHODS = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

const getToken = async (slug, tokenKey) => {
  let tokens = getOfflineData("tokens", tokenKey);
  if (tokens === "") {
    return;
  }
  let expiredAt =
    (tokens && tokens.access && new Date(tokens.access.expires)) ||
    new Date(1970);
  expiredAt.setMinutes(expiredAt.getMinutes() - 1);
  if (expiredAt > new Date()) {
    return (tokens && tokens.access && tokens.access.token) || "";
  } else {
    return await getRefreshToken(tokens.refresh.token, tokenKey, slug);
  }
};

const getRefreshToken = async (refreshToken, tokenKey, slug) => {
  try {
    const response = await trackPromise(
      fetch(`${INTRANETHOSTNAME}${REST_URLS.REFRESH_TOKEN}`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
          slug: slug ? slug : "invoice_tracking",
        },
      })
    );
    const tokens = await response.json();
    if (tokens.code === 401 || tokens.code) {
      // toast.error('Token expired');
      redirectToIntranetPortal(tokenKey);
    } else {
      setOfflineData("tokens", tokens, tokenKey);
    }
    return tokens.access.token || "";
  } catch (error) {
    console.error("Error:", error);
    redirectToIntranetPortal(tokenKey);
    return "";
  }
};

export const invokeApi = async (
  method,
  url,
  data,
  params,
  Authorization,
  slug,
  token
) => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (Authorization) {
    headers["Authorization"] = `Bearer ${Authorization}`;
    headers["slug"] = slug ? slug : "invoice_tracking";
  } else {
    headers["Authorization"] = `Bearer ${await getToken(slug, token)}`;
    headers["slug"] = slug ? slug : "invoice_tracking";
  }
  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return trackPromise(
    fetch(url, {
      method: method,
      body: (data && JSON.stringify(data)) || undefined,
      headers: headers,
      params: params,
    }).then((response) => {
      if (
        response.status === 401 &&
        url !== `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`
      ) {
        redirectToIntranetPortal(token);
      }

      if (response.status === 204) {
        return Promise.resolve("ok");
      }
      return response.json();
    })
  );
};

export const progressiveInvokeApi = async (
  method,
  url,
  data,
  params,
  slug,
  token
) => {
  let headers = {
    Authorization: `Bearer ${await getToken(slug, token)}`,
    slug: slug ? slug : "invoice_tracking",
  };

  return axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
    params: params,
  }).then((response) => {
    return response;
  });
};

export const invokeUploadFile = async (url, formData, slug, token) => {
  const config = {
    timeout: 100000 * 3,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${await getToken(slug, token)}`,
    },
  };
  if (slug) {
    config.headers.slug = slug;
  }
  return axios.post(url, formData, config);
};
export const plainApi = async (method, url, data, params, slug, token) => {
  let headers = {
    Authorization: `Bearer ${await getToken(slug, token)}`,
    slug: slug ? slug : "invoice_tracking",
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return fetch(url, {
    method: method,
    body: (data && JSON.stringify(data)) || undefined,
    headers: headers,
    params: params,
  }).then((response) => {
    if (
      response.status === 401 &&
      url !== `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`
    ) {
      redirectToIntranetPortal(token);
    }
    if (response.status === 204) {
      return Promise.resolve("ok");
    } else {
      return response.json();
    }
  });
};
