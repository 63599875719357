export const formatDownloadReports = (data) => {
  return (
    data?.map?.((item) => {
      return {
        ID: item?.id,
        'Category Code': item?.categoryCode,
        Name: item?.name,
        Capex: item?.capex ? 'Yes' : 'No',
      };
    }) || []
  );
};
