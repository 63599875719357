import { trackPromise } from "react-promise-tracker";

export const downloadFileFromUrl = (url, name) => {
  trackPromise(
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name || "sample_pdf.pdf";
        alink.click();
      });
    })
  );
};
