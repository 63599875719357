import React, { useState, useEffect } from "react";
import "./index.scss";
import AppCard from "../../core/cards";
import BulletinComponent from "../../components/display-blogs";
import { Grid, Menu, TextField, Button, Typography } from "@mui/material";
import { getOfflineData } from "../../utils/offline-services";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import ArTable from "../AR-table";
import { getLimitsForUserLoggedIn } from "../../utils/common-apis";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Select from "react-select";
import { toast } from "react-toastify";
const AllowedApps = () => {
  const [listAllowedApps, setAllowedApps] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [showArData, setShowArData] = useState(false);
  const [showArApp, setShowArApp] = useState(false);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [blogFilter, setBlogFilter] = useState({
    category: "",
    startDate: "",
    endDate: "",
  });
  const openFilters = Boolean(anchorFilter);
  const loadData = () => {
    const params = {
      sortBy: "-createdAt",
      limit: 50,
    };
    if (blogFilter?.category) {
      params["category"] = blogFilter.category;
    }
    if (blogFilter?.startDate) {
      params["startDate"] = blogFilter.startDate;
    }
    if (blogFilter?.endDate) {
      params["endDate"] = blogFilter.endDate;
    }
    plainApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_BLOGS}`, null, params)
      .then((response) => {
        if (response) {
          const role = getOfflineData("user")?.role;
          const results = response.results
            .map((ele) => {
              if (role === "franchise") {
                if (ele.showToFranchise) {
                  return ele;
                }
              } else {
                return ele;
              }
            })
            .filter((ele) => ele && ele);
          setBlogs(results);
        } else {
          console.log("Fetching blogs failed");
        }
      })
      .catch((err) => {
        console.log("Fetching blogs failed");
      });
  };
  useEffect(() => {
    getLimitsForUserLoggedIn((limits) => {
      setAllowedApps(limits);
    });
  }, []);
  const getStoreData = () => {
    const userId = getOfflineData("user")?.userId;
    const [storeNumber, sequenceNumber, satelliteNumber] = userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_BY_STORE_NUMBER}`,
      null,
      { storeNumber, sequenceNumber, satelliteNumber }
    )
      .then((res) => {
        setShowArApp(res?.storeType === "FOFO");
      })
      .catch((err) => {
        setShowArApp(false);
      });
  };

  useEffect(() => {
    const role = getOfflineData("user")?.role;
    if (role === "franchise") {
      getStoreData();
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);
  const handleClickFilter = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFilter(null);
  };
  const initializeCall = () => {
    invokeApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_CATEGORY}`)
      .then((response) => {
        if (response) {
          let cat = response.map((iter) => {
            return {
              label: iter.name,
              value: iter.id,
            };
          });
          setCategoryList(cat);
        }
      })
      .catch((err) => {
        toast.error("Fetching category list failed");
      });
  };
  useEffect(() => {
    initializeCall();
  }, []);
  return (
    <div>
      {!showArData && (
        <div>
          <h6 className="title-header allowed-apps ">Subway Broadcast</h6>
          {blogs?.length ? (
            <BulletinComponent
              data={blogs}
              setBlogFilter={setBlogFilter}
              blogFilter={blogFilter}
            />
          ) : (
            <>
              <p className="no-broadcast">No Broadcast</p>
            </>
          )}

          <h6 className="title-header allowed-apps ">My Access</h6>
          <div className="allowed-apps">
            <Grid container md={12}>
              <Grid container md={12}>
                <div className="apps-con">
                  {/* {showArApp && (
                    <AppCard
                      appDetails={{
                        name: "AR Data",
                        img: "https://idev.blob.core.windows.net/technetv2/ar-data.png",
                      }}
                      clickFun={setShowArData}
                    />
                  )} */}
                  {listAllowedApps?.length > 0 &&
                    listAllowedApps.map((app) => {
                      return <AppCard appDetails={app} />;
                    })}
                  {!showArApp && listAllowedApps?.length < 1 && (
                    <h2>No Apps Available</h2>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {showArData && <ArTable showArDataFun={setShowArData} />}
    </div>
  );
};

export default AllowedApps;
