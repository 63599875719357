import { dateFun } from "../../../common-utilities/utils";
import { addValueToObj } from "../../../common-utilities/utils/misc";
import { agreementAndPostAgreementDocsLabelsAndValues } from "../../utils/coco-acquired.config";
import {
  validDocumentTypes,
  validStoreTypes,
} from "../../utils/document-constants";

const addStartEndDates = (
  documentType,
  subDocumentType,
  startDate,
  endDate,
  obj
) => {
  switch (documentType) {
    case validDocumentTypes.preAgreementDocs:
      addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
      return;
    case validDocumentTypes.agreementAndPostAgreementDocs:
      switch (subDocumentType) {
        case agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          return;
        case agreementAndPostAgreementDocsLabelsAndValues.bta:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          return;
        case agreementAndPostAgreementDocsLabelsAndValues.franchiseTermination:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          return;
        case agreementAndPostAgreementDocsLabelsAndValues.deliveryMemo:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          return;
        case agreementAndPostAgreementDocsLabelsAndValues.leaseTermination:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          return;
        default:
          addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
          addValueToObj("endDate", obj, endDate, dateFun, null, [true]);
          return;
      }
    default:
      addValueToObj("startDate", obj, startDate, dateFun, null, [true]);
      addValueToObj("endDate", obj, endDate, dateFun, null, [true]);
  }
};

export const getFormDetailsFromGetDocumentApi = (
  data,
  setDocs,
  setIsEditingDoc,
  documentType,
  storeType
) => {
  const {
    startDate,
    endDate,
    docs,
    monthlyRentMgStartYear,
    monthlyRentRevenueShareOnDineInStartYear,
    landlord,
    monthlyRentRevenueShareOnDeliveryInStartYear,
    leaseAddress,
    fitOutPeriod,
    cam,
    lockInPeriod,
    landlordInvestment,
    rsecdeposit,
    rentRevShareDeliveryExcludesAggregatorsComission,
    rsecdepositinmonths,
    licenseName,
    isApplied,
    na,
    licensor,
    licenseeFirm,
    licenseePartners,
    leaseType,
    leaseEffectiveDate,
    leaseEndDate,
    area,
    currentRent,
    rentEscalation,
    securityDeposit,
    locationAddress,
    renewalClause,
    stampedAndRegistered,
    lockInExpiryDate,
    leaseSignDate,
    address,
    nameOfLandload,
  } = data;
  const allDetails = { docs };

  addStartEndDates(
    documentType,
    data?.documentType,
    startDate,
    endDate,
    allDetails
  );
  if (![null, undefined].includes(monthlyRentMgStartYear)) {
    allDetails.monthlyRentMgStartYear = `${monthlyRentMgStartYear}`;
  }
  if (![null, undefined].includes(monthlyRentRevenueShareOnDineInStartYear)) {
    allDetails.monthlyRentRevenueShareOnDineInStartYear = `${monthlyRentRevenueShareOnDineInStartYear}`;
  }
  if (![null, undefined].includes(landlord)) {
    allDetails.landlord = `${landlord}`;
  }
  if (
    ![null, undefined].includes(monthlyRentRevenueShareOnDeliveryInStartYear)
  ) {
    allDetails.monthlyRentRevenueShareOnDeliveryInStartYear = `${monthlyRentRevenueShareOnDeliveryInStartYear}`;
  }
  if (![null, undefined].includes(leaseAddress)) {
    allDetails.leaseAddress = `${leaseAddress}`;
  }
  if (
    ![null, undefined].includes(fitOutPeriod) &&
    storeType !== validStoreTypes.cocoAcquired
  ) {
    allDetails.fitOutPeriod = `${fitOutPeriod}`;
  }
  if (![null, undefined].includes(cam)) {
    allDetails.cam = `${cam}`;
  }
  if (![null, undefined].includes(lockInPeriod)) {
    allDetails.lockInPeriod = `${lockInPeriod}`;
  }
  if (![null, undefined].includes(landlordInvestment)) {
    allDetails.landlordInvestment = `${landlordInvestment}`;
  }
  if (![null, undefined].includes(rsecdeposit)) {
    allDetails.rsecdeposit = `${rsecdeposit}`;
  }
  if (
    ![null, undefined].includes(
      rentRevShareDeliveryExcludesAggregatorsComission
    )
  ) {
    allDetails.rentRevShareDeliveryExcludesAggregatorsComission = {
      label: rentRevShareDeliveryExcludesAggregatorsComission,
      value: rentRevShareDeliveryExcludesAggregatorsComission,
    };
  }
  if (![null, undefined].includes(rsecdepositinmonths)) {
    allDetails.rsecdepositinmonths = {
      label: rsecdepositinmonths,
      value: rsecdepositinmonths,
    };
  }
  if (licenseName) {
    allDetails.licenseName = licenseName;
  }
  if (nameOfLandload) {
    allDetails.nameOfLandload = nameOfLandload;
  }
  if (address) {
    allDetails.address = address;
  }
  if (typeof isApplied === "boolean") {
    if (isApplied === true) {
      allDetails.isApplied = {
        label: "Yes",
        value: true,
      };
    }
    if (isApplied === false) {
      allDetails.isApplied = {
        label: "No",
        value: false,
      };
    }
  }

  addValueToObj("licensor", allDetails, licensor);
  addValueToObj("licenseeFirm", allDetails, licenseeFirm);
  addValueToObj("licenseePartners", allDetails, licenseePartners);
  addValueToObj("leaseType", allDetails, leaseType, (value) => ({
    label: value,
    value,
  }));
  addValueToObj(
    "leaseEffectiveDate",
    allDetails,
    leaseEffectiveDate,
    dateFun,
    null,
    [true]
  );

  addValueToObj("leaseEndDate", allDetails, leaseEndDate, dateFun, null, [
    true,
  ]);
  addValueToObj(
    "lockInExpiryDate",
    allDetails,
    lockInExpiryDate,
    dateFun,
    null,
    [true]
  );
  addValueToObj("leaseSignDate", allDetails, leaseSignDate, dateFun, null, [
    true,
  ]);
  addValueToObj("area", allDetails, area);
  addValueToObj("currentRent", allDetails, currentRent);
  addValueToObj("rentEscalation", allDetails, rentEscalation);
  addValueToObj("securityDeposit", allDetails, securityDeposit);
  addValueToObj("locationAddress", allDetails, locationAddress);
  addValueToObj("renewalClause", allDetails, renewalClause);
  addValueToObj(
    "stampedAndRegistered",
    allDetails,
    stampedAndRegistered,
    (value) => {
      if (value) {
        return { label: "Yes", value: true };
      }
      return { label: "No", value: false };
    }
  );

  if (na) {
    allDetails.notApplicable = true;
  } else {
    allDetails.notApplicable = false;
  }

  setDocs(docs);
  setIsEditingDoc(true);
  return allDetails;
};
