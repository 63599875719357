import React, { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import errorImage from "../../../common-utilities/resources/images/error.jpg";
const InvalidResponse = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/petty-expense");
    }, 1000);
  }, []);
  return (
    <div className="invalid-res-div">
      <div className="voucher-thank-you-screen">
        <img src={errorImage} className="error-img" />
        <p>Ooops! Something Went Wrong</p>
      </div>
    </div>
  );
};

export default InvalidResponse;
