import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  COCO_ACQUIRED_COLUMNS,
  COCO_BUILT_COLOUMNS,
  FOFO_COLUMNS,
} from "./config";

import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import {
  redirectToRoute,
  REST_URLS,
} from "../../../common-utilities/utils/end-points/docu-storage";
import { StoreLabels } from "../../utils/document-constants";
import { Grid, Button } from "@mui/material";
import { getStateList, setSelectFiltersOnChange } from "./utils/region.utils";

import { getFiltersForLoadData } from "./utils";
import { useNavigate } from "react-router-dom";
import { redirection } from "../../utils/navigate";
import { USERROLES } from "../../utils/constants";
import GroupHeadersTable from "../../../common-utilities/core/grouped-header-table";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { exportToExcel } from "../../../common-utilities/utils";
import { tokenMainKeys } from "../../../../utils/constants";
import {
  processRestaurantDetailsForDropdown,
  setGetStoreInBatchParams,
} from "../../utils";
import { processTatDates } from "./helper";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";

const ReportTable = () => {
  const navigate = useNavigate();
  const [storeType, setStoreType] = useState("");
  const [listIds, setlistIds] = useState([]);
  const [allStateList, setallStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    restaurantId: { label: "", value: null },
  });
  useEffect(() => {
    const user = getOfflineData(tokenMainKeys.docuStorage, "user");
    redirection(navigate, user?.role !== USERROLES.admin, redirectToRoute);
    const stateSelectlist = getStateList("India");
    setallStateList(stateSelectlist);
  }, []);

  const [tableData, setTableData] = useState({});
  const restaurantIds = () => {
    const body = { storeType };
    setGetStoreInBatchParams(body);
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.LIST_ALL_RESTAURANT_IDS}`,
      body
    )
      .then((response) => {
        if (!response?.message) {
          const allSelectValues = processRestaurantDetailsForDropdown(response);
          setlistIds(allSelectValues);
        }
      })
      .catch((error) => {
        setlistIds([]);
      });
  };

  useEffect(() => {
    if (storeType) {
      const filterCopy = { ...filters };
      delete filterCopy.city;
      delete filterCopy.state;
      delete filterCopy.restaurantId;
      filterCopy.storeType = storeType;
      filterCopy.page = 1;
      setFilters((prevVal) => {
        delete prevVal.city;
        delete prevVal.state;
        return {
          ...prevVal,
          page: 1,
          storeType: storeType,
          restaurantId: { label: "", value: null },
        };
      });
      loadTabledata({
        ...filterCopy,
      });
      restaurantIds();
    }
  }, [storeType]);
  useEffect(() => {
    const isRestaurantIdPresent = filters?.restaurantId.value;
    const isStoreTypePresent = !!storeType;
    if (isRestaurantIdPresent || isStoreTypePresent) {
      loadTabledata({
        ...filters,
        storeType: storeType,
      });
    }
  }, [filters]);

  const loadTabledata = (filters, download) => {
    const allFilters = getFiltersForLoadData(filters, download, tableData);
    if (storeType) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_REPORTS}`,
        null,
        { ...allFilters, storeType: storeType }
      )
        .then((res) => {
          if (!res.message) {
            if (download) {
              exportToExcel(
                res?.results || [],
                "TAT",
                false,
                false,
                (workbook, worksheet, sheetName, headers) => {
                  processTatDates(
                    workbook,
                    worksheet,
                    sheetName,
                    headers,
                    res?.results || [],
                    storeType
                  );
                }
              );
              return;
            }
            setTableData(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const showTable = () => {
    if (storeType === "COCO ACQUIRED") {
      return (
        <GroupHeadersTable
          columns={COCO_ACQUIRED_COLUMNS}
          data={tableData.results}
          filters={filters}
          pageCount={tableData.totalPages}
          onChange={(event, page) => {
            setFilters({
              ...filters,
              page,
            });
          }}
        />
      );
    } else if (storeType === "COCO BUILT") {
      return (
        <GroupHeadersTable
          columns={COCO_BUILT_COLOUMNS}
          data={tableData.results}
          filters={filters}
          pageCount={tableData.totalPages}
          onChange={(event, page) => {
            setFilters({
              ...filters,
              page,
            });
          }}
        />
      );
    } else if (storeType === "FOFO") {
      return (
        <GroupHeadersTable
          columns={FOFO_COLUMNS}
          data={tableData.results}
          filters={filters}
          pageCount={tableData.totalPages}
          onChange={(event, page) => {
            setFilters({
              ...filters,
              page,
            });
          }}
        />
      );
    }
  };
  return (
    <div className="reports-contain">
      <Grid container md={12} className="report-filter-con">
        <Grid item md={5}>
          <AutocompleteComponent
            label="Store Type"
            options={StoreLabels}
            onChange={(event, e) => {
              setStoreType(e.value);
            }}
            disableClear={true}
            noValue={true}
          />
        </Grid>
        <Grid item md={5}>
          <AutocompleteComponent
            label="Restaurant ID"
            options={listIds}
            value={filters.restaurantId?.value ? filters.restaurantId : null}
            onChange={(e, event) => {
              if (!event) {
                setFilters((prevVal) => ({
                  ...prevVal,
                  restaurantId: { label: "", value: null },
                  page: 1,
                }));
                return;
              }

              setFilters((prevVal) => ({
                ...prevVal,
                restaurantId: { label: event.label, value: event.value },
                page: 1,
              }));
            }}
          />
        </Grid>
      </Grid>

      <Grid container md={12} className="report-filter-con">
        <Grid item md={5}>
          <AutocompleteComponent
            label="State"
            options={allStateList}
            value={filters.state?.value ? filters.state : null}
            name="state"
            onChange={(event, e) =>
              setSelectFiltersOnChange(
                e,
                { name: "state" },
                setFilters,
                setCityList
              )
            }
          />
        </Grid>
        <Grid item md={5}>
          <AutocompleteComponent
            label="City"
            options={cityList}
            value={filters.city?.value ? filters.city : null}
            name="city"
            onChange={(event, e) =>
              setSelectFiltersOnChange(e, { name: "city" }, setFilters)
            }
          />
        </Grid>
      </Grid>
      <div className="downloadReportsbutton">
        {(tableData.results || []).length !== 0 && (
          <DownloadBtn
            onClick={() => {
              loadTabledata(filters, true);
            }}
          />
        )}
      </div>
      <div className="table-div">{storeType && showTable()}</div>
    </div>
  );
};

export default ReportTable;
