import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import { deleteFalsyProperties } from "../../../common-utilities/utils/misc";
import { USER_ROLES, getUserRole } from "../../utils";

export const getValueFromSelect = (value) => {
  if (typeof value === "object") {
    return typeof value?.value === "number" ? value?.value : value?.value || "";
  }
  return value;
};
export const disableSaveButton = (
  restaurantDetails,
  formDetails,
  originalCaseDetails,
  files
) => {
  let productInfoCheck = false;
  //Since we have DC selected in form product info will come while creating case
  if (formDetails?.productInfo) {
    productInfoCheck =
      !formDetails?.productInfo?.unitsAffected ||
      !formDetails?.productInfo?.unitOfMeasure;
  }

  // This means we are editing the case
  const isEditingCase = Object.keys(originalCaseDetails || {}).length > 0;
  let attachmentsCheck = false;
  if (isEditingCase) {
    const isServiceTypeCase = originalCaseDetails?.type?.value === "SERVICE";
    const isCurrentProductCase = formDetails?.type?.value === "PRODUCT";

    if (isCurrentProductCase && isServiceTypeCase) {
      productInfoCheck =
        !formDetails?.productInfo?.unitsAffected ||
        !formDetails?.productInfo?.unitOfMeasure;
    }
  } else {
    attachmentsCheck = !files?.length;
  }

  return (
    !restaurantDetails?.primaryContact?.name ||
    !restaurantDetails?.primaryContact?.email ||
    !restaurantDetails?.primaryContact?.phone ||
    !restaurantDetails?.alternateContact?.name ||
    !restaurantDetails?.alternateContact?.email ||
    !restaurantDetails?.alternateContact?.phone ||
    !formDetails?.additionalInformation?.dcInvoice ||
    !formDetails?.complaintNotes ||
    !formDetails?.details ||
    !formDetails?.additionalInformation?.lotCode ||
    !formDetails?.additionalInformation?.deliveryDate ||
    productInfoCheck
    // ||
    // attachmentsCheck
  );
};

export const checkIfProductInformationIsDisabledForEditing = (
  caseId,
  originalCaseDetails
) => {
  const isCreatingCase = !Number.isInteger(parseInt(caseId));
  if (isCreatingCase) {
    return false;
  }
  const roleDetails = getUserRole();
  if (roleDetails?.type === USER_ROLES.ADMIN) {
    return false;
  }
  const isServiceTypeCase = originalCaseDetails?.type?.value === "SERVICE";
  if (isServiceTypeCase) {
    return false;
  }
  return true;
};

export const getCaseCreationUpdationPayload = (
  formDetails,
  caseId,
  complaintType,
  files
) => {
  const isEditingCase = Number.isInteger(parseInt(caseId));
  const payload = { ...formDetails };
  if (payload?.vendor) {
    payload.vendor = getValueFromSelect(payload.vendor);
  }
  if (isEditingCase) {
    if (payload?.type) {
      payload.type = getValueFromSelect(payload.type);
    }
  } else {
    payload.type = complaintType;
  }
  if (payload?.productInfo?.code) {
    payload.productInfo.code = getValueFromSelect(payload.productInfo.code);
  }

  if (payload?.complaintType) {
    payload.complaintType = getValueFromSelect(payload?.complaintType);
  }
  if (payload?.complaintCategory) {
    payload.complaintCategory = getValueFromSelect(payload?.complaintCategory);
  }
  if (payload?.severity) {
    payload.severity = getValueFromSelect(payload?.severity);
  }
  if (payload?.productInfo?.unitsAffected === "") {
    delete payload.productInfo.unitsAffected;
  }
  if (payload?.productInfo?.dc) {
    payload.productInfo.dc = getValueFromSelect(payload?.productInfo.dc);
  }
  if (payload?.productInfo?.unitOfMeasure) {
    payload.productInfo.unitOfMeasure = getValueFromSelect(
      payload?.productInfo.unitOfMeasure
    );
  }

  if (payload?.dc) {
    payload.dc = getValueFromSelect(payload.dc);
  }
  if (
    payload?.productInfo ||
    payload?.additionalInformation ||
    payload.vendorList
  ) {
    payload.caseDetails = {};
    if (payload?.productInfo && payload.type === "PRODUCT") {
      payload.caseDetails.productInfo = { ...payload.productInfo };
    }
    if (payload.vendorList) {
      payload.caseDetails.vendorList = [...payload.vendorList];
    }

    if (payload?.additionalInformation) {
      payload.caseDetails.additionalInformation = {
        ...payload.additionalInformation,
      };
    }
    delete payload?.productInfo;
    delete payload?.additionalInformation;
    delete payload?.vendorList;
    payload.attachments = files;
  }

  if (isEditingCase) {
    delete payload.attachments;
    delete payload.dc;
    delete payload?.caseDetails?.productInfo?.dc;
  }

  return [payload];
};

export const clearFormDetails = (setFormDetails, setFiles) => {
  setFormDetails({});
  setFiles([]);
};

export const validateFormFieldsBeforeSaving = (
  id,
  isEditingCase,
  casePayload,
  storePayload
) => {
  if (!Number.isInteger(parseInt(id))) {
    toast.error("Please select store number", { autoClose: 2000 });
    return true;
  }

  if (!Number.isInteger(parseInt(casePayload.dc)) && !isEditingCase) {
    toast.error("DC is not mapped.Please contact support", { autoClose: 2000 });
    return true;
  }
  if (
    !Number.isInteger(parseInt(casePayload.vendor)) &&
    casePayload.type === "PRODUCT"
  ) {
    toast.error("Please select vendor", { autoClose: 2000 });
    return true;
  }
  if (!casePayload.type) {
    toast.error("Please select type", { autoClose: 2000 });
    return true;
  }
  if (!casePayload.complaintType) {
    toast.error("Please select complaint Type", { autoClose: 2000 });
    return true;
  }
  if (!casePayload.details) {
    toast.error("Please select details", { autoClose: 2000 });
    return true;
  }
  const haveToCheckPrimaryAndAlternateContactEmails =
    storePayload && !isEditingCase;
  if (haveToCheckPrimaryAndAlternateContactEmails) {
    const primaryContactEmail = storePayload?.primaryContact?.email;
    const secondaryContactEmail = storePayload?.alternateContact?.email;
    if (
      !primaryContactEmail ||
      typeof primaryContactEmail !== "string" ||
      !isEmail(primaryContactEmail)
    ) {
      toast.error("Primary Contact Email is not valid", { autoClose: 2000 });
      return true;
    }

    if (
      !secondaryContactEmail ||
      typeof secondaryContactEmail !== "string" ||
      !isEmail(secondaryContactEmail)
    ) {
      toast.error("Secondary Contact Email is not valid", { autoClose: 2000 });
      return true;
    }
  }
  return false;
};

export const getStoreDetailsPayload = (restaurantDetails) => {
  const payload = { ...restaurantDetails };
  if (payload?.country) {
    payload.country = getValueFromSelect(payload.country);
  }

  delete payload.dc;
  if (payload?.state) {
    payload.state = getValueFromSelect(payload.state);
  }
  deleteFalsyProperties(payload, [
    "address",
    "address2",
    "country",
    "city",
    "pinCode",
  ]);

  const { id } = payload;
  delete payload.id;
  delete payload.storeNumber;
  return [payload, id?.value];
};
export const COMPLAINT_CATEGORY_LIST = [
  { label: "Short Product in Case", value: "Short Product in Case" },
  { label: "Food- Quality Issue", value: "Food- Quality Issue" },
  {
    label: "Fresh Produce- Quality issue",
    value: "Fresh Produce- Quality issue",
  },
  { label: "DC Issue", value: "DC Issue" },
  { label: "Packaging Issue", value: "Packaging Issue" },
  { label: "Foreign Object", value: "Foreign Object" },
];
