import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/common-utilities/app-documents/private.route";
import { appSlugs, tokenMainKeys } from "../utils/constants";
import { Home } from "../packages/common-utilities/app-documents/Home";

const PoliciesRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PrivateRoute
            tokenKey={tokenMainKeys.policies}
            slug={appSlugs.policies}
          />
        }
      >
        <Route
          path="/"
          element={
            <Home
              slug={appSlugs.policies}
              title="POLICIES"
              showPdfReader={true}
              subFolderLabel="Select Sub Category"
              tokenKey={tokenMainKeys.policies}
              noDownload={true}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default PoliciesRoutes;
