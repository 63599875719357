/** @format */

import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/docu-storage/routes/private-route";
import CompanyDocuments from "../packages/docu-storage/components/company-document";
import Chronology from "../packages/docu-storage/components/chronology";
import SelectRestaurant from "../packages/docu-storage/components/upload-document/selectRestaurant";
import DocustorageReportsSection from "../packages/docu-storage/components/Reports";
import ReportTable from "../packages/docu-storage/components/reports-table";
import Thankyou from "../packages/docu-storage/components/Thankyou";

const DocuStorageRoutes = () => {
  return (
    <Routes>
      <Route exact path="/response-recorded" element={<Thankyou />}></Route>
      <Route exact path="/" element={<PrivateRoute />}>
        {/* <Route exact path="/" element={<Resturants />} /> */}
        <Route exact path="/upload" element={<SelectRestaurant />} />
        <Route exact path="/chronology" element={<Chronology />} />

        <Route
          exact
          path="/upload-company-documents/:id"
          element={<CompanyDocuments />}
        />
        <Route exact path="/reports" element={<DocustorageReportsSection />} />
        <Route exact path="/tat" element={<ReportTable />} />
      </Route>
    </Routes>
  );
};

export default DocuStorageRoutes;
