import "./index.scss";
import { useState, useEffect } from "react";
import { BellCorpStudioLogoContainer } from "../../common-utilities/Bellcorp-Studio-Logo";
import Header from "../core/header";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys } from "../../../utils/constants";

const MainContainer = ({
  children,
  reloadPendingApprovals,
  setReloadPendingApprovals,
}) => {
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const [open, setOpen] = useState(window.innerWidth > 480);

  return (
    <>
      <div className="main-container">
        <IntranetHeader />
        <div className="side-nav-header">
          <HomeRedirect localstorageKey={tokenMainKeys.storeMaster} />
        </div>

        <div className="main-right-container">
          <Header />
          <div className="content-div">
            <div>{children}</div>
            <BellCorpStudioLogoContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
