import React, { useState, useEffect, useRef } from "react";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getPendingVoucherHeaderConfig } from "./config";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import { getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import ViewVoucher from "./viewVoucher";
import CustomModal from "../../../common-utilities/core/modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Autocomplete, Button, Drawer, Grid, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { exportToExcel } from "../../../common-utilities/utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import ViewComment from "./view-comment";

const PaidVouchers = () => {
  const [tableData, setTableData] = useState([]);
  const [showFiles, setShowFiles] = useState({ isShow: false, data: [] });
  const [storeDetails, setStoreDetails] = useState({});
  const [viewVoucher, setViewVoucher] = useState({ isView: false, data: {} });
  const [searchVoucherId, setSearchVoucherId] = useState("");
  const [voucherIdList, setVoucherIdList] = useState([]);
  const [searchVendorCode, setSearchVendorCode] = useState("");
  const [vendorOptions, setVendorOptions] = useState([]);
  const [viewComments, setViewComments] = useState({ isView: false, data: {} });
  const [storeList, setStoreList] = useState([]);
  const [searchStore, setSearchStore] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const buttonRef = useRef(null);
  const userData = getOfflineData("user");
  const getStoreDetails = () => {
    const store = userData?.userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      {
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      }
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const viewVoucherHandler = (data, type) => {
    switch (type) {
      case "view files":
        setShowFiles({ isShow: true, data: data?.attachment });
        break;
      case "view voucher":
        setViewVoucher({ isView: true, data: data });
        break;
      case "view comments":
        setViewComments({ isView: true, data: data });
        break;
      default:
        break;
    }
  };
  const loadData = (filters) => {
    const payload = { ...filters, status: "Paid" };
    if (storeDetails?.id) {
      payload["store"] = storeDetails.id;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.VOUCHER}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => ({
            ...ele,
          }));
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getVoucherIds = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_VOUCHER_IDS}`,
        null,
        {
          page: 1,
          limit: 1000,
          voucherId: searchCode,
        }
      ).then((res) => {
        const voucherList = res?.results?.map((ele) => ele.voucherId);

        setVoucherIdList(voucherList);
      });
    }, 300);
  };
  const voucherInputChange = (e, value) => {
    if (value?.trim()) {
      getVoucherIds(value);
    }
  };
  const getVendor = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.VENDOR}`, null, {
        page: 0,
        limit: 1000,
        name: searchCode,
      }).then((res) => {
        const vendorList = res?.results?.map((ele) => ({
          vendor: `${ele?.code}-${ele?.name}`,
          id: ele?.id,
        }));
        setVendorOptions(vendorList);
      });
    }, 300);
  };
  const inputChange = (e, value) => {
    if (value?.trim()) {
      getVendor(value);
    }
  };
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    const storeNo = searchTerm?.split("-")[0];
    clearTimeout(timeoutId);

    const payload = {};

    if (!isNaN(parseInt(storeNo))) {
      payload.storeNumber = storeNo;
    } else if (searchTerm && typeof searchTerm === "string") {
      payload.restaurantName = searchTerm;
    }

    if (Object.keys(payload).length > 0) {
      setTimeout(() => {
        plainApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
          null,
          payload
        ).then((res) => {
          const storeList = res?.results?.map((ele) => ({
            storeNo: ele?.storeNumber,
            satelliteNo: ele?.satelliteNumber,
            sequenceNo: ele?.sequenceNumber,
            restaurantName: ele?.restaurantName,
            id: ele?.id,
          }));
          setStoreList(storeList);
        });
      }, 300);
    }
  };
  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const onFilterChange = (value, dataLabel) => {
    setFilters({
      ...filters,
      [dataLabel]: value,
    });
  };
  const searchHandler = (pg) => {
    let payload = { ...filters, page: pg };
    if (searchVoucherId) {
      payload["voucherId"] = searchVoucherId;
    }
    if (searchVendorCode) {
      payload["vendor"] = searchVendorCode;
    }
    if (searchStore) {
      payload["store"] = searchStore;
    }
    loadData(payload);
  };
  const downloadVouchersInExcel = () => {
    const payload = { ...filters, status: "Paid" };
    if (storeDetails?.id) {
      payload["store"] = storeDetails.id;
    }
    if (searchVoucherId) {
      payload["voucherId"] = searchVoucherId;
    }
    if (searchVendorCode) {
      payload["vendor"] = searchVendorCode;
    }
    if (searchStore) {
      payload["store"] = searchStore;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.DOWNLOAD_VOUCHERS}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          exportToExcel(res, "Paid Vouchers");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);
  useEffect(() => {
    if (userData?.role === "STORE") {
      getStoreDetails();
    }
  }, []);
  useEffect(() => {
    loadData(filters);
  }, [storeDetails]);
  return (
    <div>
      <Grid md={12} container spacing={1}>
        <Grid md={2} item>
          <Autocomplete
            getOptionLabel={(option) => option?.toString()}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setSearchVoucherId("");
                  setVoucherIdList([]);
                }}
              />
            }
            options={voucherIdList}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Voucher Id"
                sx={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
            onInputChange={voucherInputChange}
            onChange={(event, value) => {
              if (value) {
                setSearchVoucherId(value);
              }
            }}
          />
        </Grid>
        <Grid md={2} item>
          <Autocomplete
            getOptionLabel={(option) => option?.vendor?.toString()}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setSearchVendorCode("");
                  setVendorOptions([]);
                }}
              />
            }
            options={vendorOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Vendor Name"
                sx={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
            onInputChange={inputChange}
            onChange={(event, value) => {
              if (value) {
                setSearchVendorCode(value?.id);
              }
            }}
          />
        </Grid>
        {userData?.role !== "STORE" && (
          <Grid md={2} item>
            <Autocomplete
              getOptionLabel={(option) =>
                option
                  ? `${option.storeNo}-${option.sequenceNo}-${
                      option.satelliteNo
                    }${
                      option.restaurantName ? `-${option.restaurantName}` : ""
                    }`
                  : ""
              }
              className="poper-select"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    if (filters?.store) {
                      delete filters?.store;
                    }
                    setStoreList([]);
                    setSearchStore("");
                  }}
                />
              }
              options={storeList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Store"
                  sx={{ backgroundColor: "white" }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onInputChange={storeInputChange}
              onChange={(event, value) => {
                if (value) {
                  setSearchStore(value?.id);
                }
              }}
            />
          </Grid>
        )}
        <Grid md={2} item>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label=" From"
            fullWidth
            sx={{ backgroundColor: "white" }}
            value={filters.startDate || ""}
            onChange={(event) =>
              onFilterChange(event.target.value, "startDate")
            }
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label="to"
            value={filters.endDate || ""}
            fullWidth
            sx={{ backgroundColor: "white" }}
            onChange={(event) => onFilterChange(event.target.value, "endDate")}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            ref={buttonRef}
            sx={{ borderRadius: "15px" }}
            variant="outlined"
            onClick={() => searchHandler(1)}
            startIcon={<SearchIcon sx={{ cursor: "pointer" }} />}
          >
            Search
          </Button>
        </Grid>
        {tableData?.results?.length > 0 && (
          <Grid
            item
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <DownloadBtn onClick={downloadVouchersInExcel} />
          </Grid>
        )}
      </Grid>
      <MuiTable
        columnsList={getPendingVoucherHeaderConfig()}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onClick={viewVoucherHandler}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          searchHandler(page);
        }}
      ></MuiTable>
      {showFiles?.isShow && (
        <CustomModal
          title="Files"
          onClose={() => {
            setShowFiles({ isShow: false, data: [] });
          }}
        >
          {showFiles?.data.length > 0 ? (
            showFiles.data.map((item, index) => {
              return (
                <div className="downloadgrid" key={index}>
                  <div>{item?.title}</div>
                  <div>
                    <a target="_blank" download={true} href={item?.url}>
                      <DownloadForOfflineIcon color="action" />
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Files Attached</p>
          )}
        </CustomModal>
      )}
      {viewComments?.isView && (
        <ViewComment
          data={viewComments.data}
          setViewComments={setViewComments}
        />
      )}
      <Drawer
        anchor="right"
        open={viewVoucher?.isView}
        onClose={() => setViewVoucher({ isView: false, data: {} })}
      >
        <ViewVoucher data={viewVoucher.data} setViewVoucher={setViewVoucher} />
      </Drawer>
    </div>
  );
};

export default PaidVouchers;
