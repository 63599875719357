import { Button } from '@mui/material';
import {
  getPurchaseOrderWithoutTaxAmount,
} from '../../purchase-order/helper';
import {
  formatDate,
} from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../../utils/constants';

export const getHeaderConfig = (editVendor, invoiceType) => {
  const headers = [
    {
      label: 'PO Number',
      id: 'poNumber',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { poNumber = '' } = poId || {};
        return poNumber;
      },
    },
    {
      label: 'Site Code & Name',
      id: 'siteCode',
      render: (data) => {
        const {  site } = data;
        return  site && `${site.code} - ${site.name}`;
      },
    },
    {
      label: 'Vendor code',
      id: 'vendorCode',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { vendorCode = '' } = poId || {};
        return vendorCode;
      },
    },
    {
      label: 'Vendor Name',
      id: 'vendorName',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { vendorName = '' } = poId || {};
        return vendorName;
      },
    },
    {
      label: 'PO Base Amt',
      id: 'baseAMount',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { items = [] } = poId || {};
        return getPurchaseOrderWithoutTaxAmount(items);
      },
    },
    {
      label: 'Advance %',
      id: 'gstAMount',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { advance = {} } = poId || {};
        return advance && advance.advancePercentage;
      },
    },
    {
      label: 'PO Advance Amt',
      id: 'totalAMount',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        const { advance = {} } = poId || {};
        return advance && advance.advanceAmount;
      },
    },
    {
      label: 'Actual Advance Amount',
      id: 'document.invoiceAmount',
    },
    {
      label: 'Pending With',
      id: 'pendingWith',
    },
    {
      label: 'Created on',
      id: 'createdAt',
      render: (data) => {
        return formatDate(data?.document?.createdAt, DATE_FORMATS['DD-MM-YYYY']);
      },
    },
    {
      label: 'Created By',
      id: 'creatorName',
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document || {};
        return (poId && poId.creatorName) || '-';
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        return (
          <>
            <Button variant="text" onClick={() => editVendor(data, 'VIEW')}>
              View
            </Button>
          </>
        );
      },
    },
  ];

  return headers;
};
