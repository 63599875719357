import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./index.scss";
import logo from "../../../../resources/images/logo.png";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import { redirectToIntranetPortal } from "../../utils/common-utils";
import Intranetlogout from "../../../../core/header/logout";

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    let user = getOfflineData("user");
    setUserDetails(user);
  }, []);

  const logout = () => {
    clearOfflineData("user");
    clearOfflineData("tokens");
    Intranetlogout(navigate);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="header-container">
      <div className="logo-con"></div>
      {/* <div className="endLoginContainer">
        <div className="menu">
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
          <Button
            type="small"
            variant="text"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {userDetails.name}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
