import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import {
  mainInvokeApi,
  mainInvokeUploadFile,
  mainProgressiveInvokeApi,
  mainPlainApi,
} from "../../common-utilities/utils/http-service";

export const invokeApi = async (
  method,
  url,
  data,
  params,
  Authorization,
  additionalKey
) => {
  const key = additionalKey ? additionalKey : tokenMainKeys.subs;
  return mainInvokeApi(
    key,
    appSlugs.subs,
    method,
    url,
    data,
    params,
    Authorization
  );
};

export const progressiveInvokeApi = async (method, url, data, params) => {
  return mainProgressiveInvokeApi(
    tokenMainKeys.subs,
    appSlugs.subs,
    method,
    url,
    data,
    params
  );
};

export const invokeUploadFile = async (url, formData) => {
  return mainInvokeUploadFile(tokenMainKeys.subs, appSlugs.subs, url, formData);
};

export const plainApi = async (method, url, data, params) => {
  return mainPlainApi(
    tokenMainKeys.subs,
    appSlugs.subs,
    method,
    url,
    data,
    params
  );
};
