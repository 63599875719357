import "./index.scss";
import React, { useState } from "react";

import FranchiseAgreementDetails from "./franchise-agreement-details";
import Transactions from "./transactions";
import FranchiseAgreementHistory from "./franchise-agreement-history";
import {
  TabPanel,
  MainTabContainer,
} from "../../../common-utilities/core/tabs";
const FranchiseAgreement = () => {
  const [value, setValue] = useState(0);

  const labels = [
    "Franchise Agreement Details",
    // "Transactions",
    // "Sales Commission Splits",
    "Franchise Agreement History",
    // "Sales Referrals",
    // "Franchise Agreement Voting",
  ];
  return (
    <div className="franchise-agreement-container">
      <MainTabContainer
        mainClass="subs-mainTabContainer"
        tabContainerClass="subs-tab-container"
        tabsClass="custom-tabs"
        value={value}
        setValue={setValue}
        labels={labels}
      />
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <FranchiseAgreementDetails />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <Transactions />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <FranchiseAgreementHistory />
        </TabPanel>
      </div>
    </div>
  );
};

export default FranchiseAgreement;
