import React from "react";
import { MenuItem, TextField } from "@mui/material";
import "./index.scss";

const Select = ({ onChange, value, label, options, disabled = false }) => {
  return (
    <>
      <TextField
        className="styled-textfield"
        style={{ width: "100%" }}
        disabled={disabled}
        variant="outlined"
        value={value}
        onChange={onChange}
        select
        label={label || ""}
        size="small"
      >
        {options?.map?.((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

export default Select;
