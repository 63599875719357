const caseDetails = {
  caseDetails: {
    mainKey: "Case Details",
    productInfo: {
      mainKey: "Product Information",
      code: "Code",
      unitsAffected: "Units Affected",
      unitOfMeasure: "Unit Of Measure",
      dc: "DC",
    },
    additionalInformation: {
      mainKey: "Additonal Information",
      lotCode: "Lot Code",
      dcInvoice: "DC Invoice",
      deliveryDate: "Delivery Date",
      productionDate: "Production Date",
      expirationDate: "Expiration Date",
      packDate: "Pack Date",
    },
  },
};

const faultDetails = {
  fault: {
    mainKey: "Fault Details",
    acceptedBy: "Accepted by",
    cause: "Cause",
    correctiveAction: "Corrective Action",
    notes: "Notes",
    credit: "Credit",
    conversationWithStore: "Conversation with store",
    contactedPerson: "Contacted Person",
    contactDate: "Contacted Date",
    contact: "Contact",
    rejectedBy: "Rejected By",
    rejectedAgainst: "Rejected Against",
  },
};

export const nestedBasicUpdateDateKeys = [
  "Delivery Date",
  "Production Date",
  "Expiration Date",
  "Pack Date",
];

export const basicUpdateKeysToLabels = {
  type: "Case Type",
  complaintType: "Complaint Type",
  complaintNotes: "Complaint Notes",
  details: "Details",
  vendor: "Vendor",
  severity: "Severity",
  ...caseDetails,
  "Updated By": "Updated By",
};

const basicLabels = {
  dc: "DC",
  "Updated By": "Updated By",
  status: "Status",
  admin: "Admin",
  vendor: "Vendor",
  createdBy: "Created By",
  adoptedBy: "Adopted By",
  finalFaultDetermination: "Final Fault Determination",
  finalCreditAssignment: "Final Credit Assignment",
  mediationNotes: "Mediation Notes",
};

const creditInfO = {
  creditInfo: {
    mainKey: "Credit Information",
    store: "Store Number",
    vendor: "Vendor",
    creditAmount: "Credit Amount",
    creditMethod: "Credit Method",
    dc: "DC",
    invoiceNumber: "Invoice Number",
    creditMethod: "Credit Method",
    creditAmount: "Credit Amount",
    creditCurrency: "Credit Currency",
    creditNotes: "Credit Notes",
    creditNotesIssuanceDate: "Credit Issuance Date",
    creditNoteNumber: "Credit Note Number",
  },
};

const caseClosed = {
  closedAt: "Case closing time",
  closingMessage: "Closing Message",
  creditConfirmationByStore: "Credit Confirmation by store",
  ttc: "Total days for closing case",
};
const caseReopened = {
  "ReOpened Date": "Re-Opened At",
  "ReOpened By": "Re-Opened By",
};

export const statusUpdateLabels = {
  ...basicLabels,
  ...faultDetails,
  ...creditInfO,
  ...caseClosed,
  ...caseReopened,
};

export const createCaseLabels = {
  ...basicUpdateKeysToLabels,
  ...basicLabels,
  store: "Store Number",
};
