import { useState } from 'react';
import CustomModal from '../../../../../common-utilities/core/modal';
import InitAdvPayment from '../../../purchase-order/po-details/initi-adv-payment';
import { Button } from '@mui/material';
import { formatDate } from '../../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../../common-utilities/utils/constants';
import { updateInvoiceData } from './apis';
import { uploadAttachmentTypes } from '../../../../utils/attachments';

// Modal specific to updating po advance details only for reconsideration

const ResendAdvPaymentDetails = ({
  invoiceDataDetails,
  setInvoiceDataDetails,
}) => {
  const [modalDetails, setModalDetails] = useState(null);
  const closeModal = () => {
    setModalDetails(null);
  };

  return (
    <>
      {modalDetails?.type === 'edit_payment_details' && (
        <CustomModal
          title="Edit Advance Payment"
          disabledOutsideClick={true}
          onClose={() => {
            closeModal();
          }}
        >
          <InitAdvPayment
            attachmentData={{
              invoiceDocumentNumber: invoiceDataDetails?.invoiceDocumentNumber,
              poNumber: invoiceDataDetails?.poNumber,
              id: invoiceDataDetails.id,
              invoiceType: invoiceDataDetails.invoiceType,
              attachmentType: 'Advance Payment Attachment',
            }}
            attachmentType={uploadAttachmentTypes.resendPoAdvance}
            onSave={(payload) => {
              updateInvoiceData(invoiceDataDetails?.id, payload, () => {
                setInvoiceDataDetails((prevDetails) => ({
                  ...prevDetails,
                  ...payload,
                }));
                closeModal();
              });
            }}
            advanceAmount={invoiceDataDetails?.poId?.advance?.advanceAmount}
            data={modalDetails?.data}
          />
        </CustomModal>
      )}

      {invoiceDataDetails?.invoiceType === 'advance_invoice' && (
        <Button
          variant="outlined"
          onClick={() => {
            setModalDetails({
              type: 'edit_payment_details',
              data: {
                invoiceNumber: invoiceDataDetails?.invoiceNumber || '',
                invoiceUrls:
                  invoiceDataDetails?.invoiceUrls?.map?.((item, index) => {
                    return {
                      docUrl: item,
                      originalName: `Attachment ${index + 1}`,
                    };
                  }) || [],
                additionalDocUrls:
                  invoiceDataDetails?.additionalDocUrls?.map?.(
                    (item, index) => {
                      return {
                        docUrl: item,
                        originalName: `Attachment ${index + 1}`,
                      };
                    }
                  ) || [],
                invoiceAmount: invoiceDataDetails?.invoiceAmount,
                invoiceDate: formatDate(
                  invoiceDataDetails?.invoiceDate,
                  DATE_FORMATS['YYYY-MM-DD']
                ),
              },
            });
          }}
        >
          Edit
        </Button>
      )}
    </>
  );
};

export default ResendAdvPaymentDetails;
