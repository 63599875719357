export const setCaseFilterForQueryingData = (caseFilter, mainFilter) => {
  const { complaintId, month, year, sortingOrder, storeId } = caseFilter;
  if (complaintId) {
    mainFilter.caseIds = complaintId;
  }
  if (month?.length) {
    mainFilter.month = JSON.stringify(month.map((item) => item.bl));
  }

  if (year?.length) {
    mainFilter.year = JSON.stringify(year.map((item) => item.bl));
  }
  if (sortingOrder) {
    mainFilter.sortingOrder = sortingOrder?.value;
  }
  if (storeId) {
    mainFilter.storeId = storeId;
  }
};
