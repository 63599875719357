import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, TextField, Grid, IconButton, Drawer } from "@mui/material";
import "./index.scss";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import AddressDetails from "./address-details";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import Attributes from "./attributes";
import CommunicationDetails from "./communication-details";
import DemographicDetails from "./demographic-details";
import FirstFranchiseInfo from "./first-franchise";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { TYPES, OFFICE_STAFF_JOB_TITLES, OTHERS_JOB_TITLES } from "./config";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { AddCorporation } from "./add-corporation";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const PersonNameDetails = () => {
  const [respData, setRespData] = useState(null);
  const { personInfo, setPersonInfo } = useContext(GlobalStoreContext);
  const data = {
    personDetails: {
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      type: "",
      jobTitle: "",
      corporation: "",
    },
    communicationDetails: [],
    franchiseDetails: {
      franchiseNum: "",
      firstFranchiseDate: "",
    },
    // addressDetails: {
    //   addressType: "",
    //   attn: "",
    //   address1: "",
    //   county: "",
    //   city: "",
    //   zipCode: "",
    //   country: "",
    //   address2: "",
    //   state: "",
    //   address3: "",
    //   id: "",
    // },
    addressDetails: [],
    attributesDetails: {
      ndaDate: "",
      optOutDate: "",
      ofacDate: "",
      disclosureOptOut: false,
      hwUsername: "",
      media: "",
    },
    demographicDetails: {
      nationalId: "",
      gender: "",
      status: "",
      deceasedDate: "",
      primaryCitizenship: "",
      residencyProof: "",
      secondaryCitizenship: "",
    },
  };
  const [isCorporation, setIsCorporation] = useState(false);
  const [pNum, setpNum] = useState("");
  const [corporationList, setCorporationList] = useState([]);
  const [personData, setPersonData] = useState(data);
  const [personNum, setPersonNum] = useState("");
  const [updateValue, setUpdateValue] = useState([]);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [personOptions, setPersonOptions] = useState([]);
  const [lastNameOptions, setLastNameOptions] = useState([]);
  const [firstNameOptions, setFirstNameOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const [isPersonData, setIsPersonData] = useState(false);
  const [password, setPassword] = useState("");
  const [communicationUpdateValue, setCommunicationUpdateValue] = useState({});
  const previousController = useRef();

  const inputSearchFields = {
    personNum: "Person Num",
    lastName: "lastName",
    firstName: "firstName",
    email: "email",
  };

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_CORPORATION_NAMES}`
    )
      .then((res) => {
        const result = res?.map((ele) => ({
          label: ele.name,
          value: ele.id,
        }));

        setCorporationList(result);
      })
      .catch((err) => console.log(err));
  }, []);
  const searchByPersonNum = (num) => {
    if (num !== "") {
      loadPersonDataById(num);
    }
  };
  const loadPersonDataById = (perNum) => {
    setpNum(perNum);
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_PERSON_DETAILS}${perNum}`
    )
      .then((res) => {
        if (res?.message) {
          setPersonData(data);
        } else {
          setRespData(res);
          setPersonData((prevVal) => ({
            personDetails: {
              lastName: res?.personDetails?.lastName
                ? res.personDetails.lastName
                : "",
              firstName: res?.personDetails?.firstName
                ? res.personDetails.firstName
                : "",
              middleName: res?.personDetails?.middleName
                ? res.personDetails.middleName
                : "",
              email: res?.personDetails?.email ? res.personDetails.email : "",
              type: res?.personDetails?.type,
              jobTitle: res?.personDetails?.jobTitle,
              corporation: res?.personDetails?.corporation,
            },
            communicationDetails: res?.communicationDetails,
            franchiseDetails: {
              franchiseNum: res?.franchiseDetails[0]?.storeNo,
              firstFranchiseDate: res?.franchiseDetails[0]?.firstFranchiseDate,
            },
            addressDetails: res?.addressDetails,
            // addressDetails: {
            //   addressType: setDatas(res?.addressDetails?.addressType),
            //   attn: res?.addressDetails?.attn,
            //   address1: res?.addressDetails?.address1,
            //   county: res?.addressDetails?.county,
            //   city: res?.addressDetails?.city,
            //   zipCode: res?.addressDetails?.zipCode,
            //   country: setDatas(res?.addressDetails?.country),
            //   state: setDatas(res?.addressDetails?.state),
            //   address2: res?.addressDetails?.address2,
            //   address3: res?.addressDetails?.address3,
            //   id: res?.addressDetails?.id,
            // },
            attributesDetails: {
              ndaDate: res?.personDetails?.attributes?.ndaDate,
              optOutDate: res?.personDetails?.attributes?.optOutDate,
              ofacDate: res?.personDetails?.attributes?.ofacDate,
              disclosureOptOut:
                res?.personDetails?.attributes?.disclosureOptOut,
              hwUsername: res?.personDetails?.attributes?.hwUsername,
            },
            demographicDetails: {
              nationalId: res?.personDetails?.demographics?.nationalId,
              gender: res?.personDetails?.demographics?.gender,
              status: res?.personDetails?.demographics?.status,
              deceasedDate: res?.personDetails?.demographics?.deceasedDate,
              primaryCitizenship:
                res?.personDetails?.demographics?.primaryCitizenship,
              residencyProof: res?.personDetails?.demographics?.residencyProof,
              secondaryCitizenship:
                res?.personDetails?.demographics?.secondaryCitizenship,
            },
          }));
          setIsSaveDisable(false);
          setIsPersonData(true);
          setPersonNum(res?.personDetails?.id);
          setPersonInfo({ personId: res?.personDetails?.id + "" });
        }
      })
      .catch((err) => console.log(err));
  };

  const setDatas = (data) => {
    if (data) {
      return data;
    } else {
      return "";
    }
  };

  const updateDetailsHandler = (perNum) => {
    let nonEmptyData = {};
    for (let ele in personData) {
      if (ele === "personDetails") {
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            nonEmptyData[data] = personData[ele][data];
          }
        }
      }
      if (ele === "attributesDetails") {
        let obj = {};
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            obj[data] = personData[ele][data];
          }
        }
        nonEmptyData["attributes"] = obj;
      }
      if (ele === "demographicDetails") {
        let obj = {};
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            obj[data] = personData[ele][data];
          }
        }
        nonEmptyData["demographics"] = obj;
      }
    }
    if (password) {
      nonEmptyData["password"] = password;
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.GET_PERSON_DETAILS}${perNum}`,
      nonEmptyData
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
          setPersonData(data);
        } else {
          toast.success("Updated Successfully", { autoClose: 2000 });
          setPersonData(data);
          setPersonOptions([]);
          setPersonNum("");
        }
      })
      .catch((err) => console.log(err));
  };

  const createNewHandler = () => {
    setIsCreate(true);
    setPersonData(data);
    setPersonNum("");
    setPersonInfo({ personId: "" });
    setIsPersonData(false);
    setFirstNameOptions([]);
    setLastNameOptions([]);
    setEmailOptions([]);
    setRespData(null);
  };
  const createInsuranceHandler = () => {
    let nonEmptyData = {};
    for (let ele in personData) {
      if (ele === "personDetails") {
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            nonEmptyData[data] = personData[ele][data];
          }
        }
      }
      if (ele === "attributesDetails") {
        let obj = {};
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            obj[data] = personData[ele][data];
          }
        }
        nonEmptyData["attributes"] = obj;
      }
      if (ele === "demographicDetails") {
        let obj = {};
        for (let data in personData[ele]) {
          if (personData[ele][data]) {
            obj[data] = personData[ele][data];
          }
        }
        nonEmptyData["demographics"] = obj;
      }
    }
    const person = {
      ...nonEmptyData,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_PERSON}`,
      person
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Created Successfully", { autoClose: 2000 });
          setPersonData(data);
          setPersonNum("");
          setIsCreate(false);
        }
      })
      .catch((err) => console.log(err));
    setIsPersonData(false);
  };
  const getData = (searchTerm, searchField) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    let searchID;
    if (searchField === inputSearchFields.personNum) {
      searchID = "_id/";
    } else {
      searchID = searchField + "/";
    }
    if (searchTerm) {
      axios({
        method: HTTP_METHODS.GET,
        url: HOSTNAME + REST_URLS.PERSON_SEARCH + searchID + searchTerm,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        signal: signal,
      }).then((response) => {
        const updatedOptions = response.data.map((p) => {
          let title;
          if (searchField === inputSearchFields.personNum) {
            title = p.id;
          } else {
            if (searchField === "firstName" || searchField === "lastName") {
              title = `${p.firstName} ${p.lastName}`;
            } else {
              title = p[searchField];
            }
          }
          return { title, id: p.id };
        });
        switch (searchField) {
          case inputSearchFields.personNum:
            setPersonOptions(updatedOptions);
            setPersonInfo({ personId: searchTerm });
            break;
          case inputSearchFields.lastName:
            setLastNameOptions(updatedOptions);
            break;
          case inputSearchFields.firstName:
            setFirstNameOptions(updatedOptions);
            break;
          case inputSearchFields.email:
            setEmailOptions(updatedOptions);
            break;
          default:
            break;
        }
      });
    }
  };
  const onInputChange = (event, value, fieldName) => {
    if (value) {
      if (!isCreate) {
        getData(value, fieldName);
      }

      if (fieldName === inputSearchFields.personNum) {
        setPersonNum(value);
      } else {
        setPersonData((prevVal) => ({
          ...prevVal,
          personDetails: {
            ...prevVal.personDetails,
            [fieldName]: value,
          },
        }));
      }
    } else {
      switch (fieldName) {
        case inputSearchFields.personNum:
          setPersonInfo({ personId: "" });
          setPersonData(data);
          setIsSaveDisable(true);
          setPersonOptions([]);
          setLastNameOptions([]);
          setEmailOptions([]);
          setFirstNameOptions([]);
          break;
        case inputSearchFields.lastName:
          setLastNameOptions([]);
          break;
        case inputSearchFields.firstName:
          setFirstNameOptions([]);
          break;
        case inputSearchFields.email:
          setEmailOptions([]);
          break;
        default:
          break;
      }
      if (fieldName !== inputSearchFields.personNum) {
        setPersonData((prevData) => ({
          ...prevData,
          personDetails: { ...prevData.personDetails, [fieldName]: "" },
        }));
      }
    }
  };

  const clearData = () => {
    setPersonOptions([]);
    setPersonNum("");
    setPersonData(data);
    setIsPersonData(false);
    setLastNameOptions([]);
    setFirstNameOptions([]);
    setEmailOptions([]);
    setRespData(null);
  };

  useEffect(() => {
    if (personData?.personDetails?.corporation) {
      setIsCorporation(true);
    }
  }, [personData?.personDetails?.corporation]);

  useEffect(() => {
    if (personInfo.personId) {
      searchByPersonNum(personInfo.personId);
    }
  }, []);

  return (
    <div>
      <Grid
        container
        md={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "white",
          mt: isCreate && "1.5em",
        }}
      >
        {!isCreate && (
          <>
            <Button
              variant="contained"
              sx={{ mr: 5, zIndex: 100 }}
              onClick={createNewHandler}
              size="small"
            >
              Create New
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                excelDownload("person_details_" + pNum, respData);
              }}
              disabled={respData === null}
              sx={{ mr: 5, zIndex: 100 }}
              startIcon={
                <DownloadRoundedIcon
                  style={{ fontSize: "20px", marginRight: "-5px" }}
                />
              }
            >
              Download
            </Button>
          </>
        )}
      </Grid>
      <div className="profile-details-container">
        <div className="left-s">
          <div className="input-form-container">
            <div className="pd-title">Person Name Details</div>
            <div className="content-container">
              <div className="g-r-2">
                <Autocomplete
                  className="subs-autocomplete"
                  freeSolo
                  id="free-solo-2-demo"
                  getOptionLabel={(option) => {
                    return option.title ? option.title.toString() : "";
                  }}
                  disabled={isCreate}
                  options={personOptions}
                  onInputChange={(event, value) =>
                    onInputChange(event, value, "Person Num")
                  }
                  inputValue={personInfo.personId}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setPersonInfo({ personId: "" });
                        clearData();
                      }}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="sel-item"
                      label="Person"
                    />
                  )}
                  onChange={(e, val) => {
                    if (val) {
                      searchByPersonNum(val.id);
                    }
                  }}
                />

                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  className="subs-autocomplete"
                  getOptionLabel={(option) => {
                    return option.title ? option.title.toString() : "";
                  }}
                  options={lastNameOptions}
                  onInputChange={(event, value) =>
                    onInputChange(event, value, "lastName")
                  }
                  inputValue={personData.personDetails.lastName}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setPersonInfo({ personId: "" });
                        clearData();
                      }}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="sel-item"
                      label="Last Name"
                    />
                  )}
                  onChange={(e, val) => {
                    if (val) {
                      searchByPersonNum(val.id);
                    }
                  }}
                />
              </div>
              <div className="g-r-2">
                <Autocomplete
                  freeSolo
                  className="subs-autocomplete"
                  id="free-solo-2-demo"
                  getOptionLabel={(option) => {
                    return option.title ? option.title.toString() : "";
                  }}
                  options={firstNameOptions}
                  onInputChange={(event, value) =>
                    onInputChange(event, value, "firstName")
                  }
                  inputValue={personData.personDetails.firstName}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setPersonInfo({ personId: "" });
                        clearData();
                      }}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="sel-item"
                      label="First Name"
                    />
                  )}
                  onChange={(e, val) => {
                    if (val) {
                      searchByPersonNum(val.id);
                    }
                  }}
                />

                <TextField
                  id="outlined-basic"
                  value={personData.personDetails.middleName}
                  label="Middle Name"
                  className="subs-text-field"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setPersonData((prevVal) => ({
                      ...prevVal,
                      personDetails: {
                        ...prevVal.personDetails,
                        middleName: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="g-r-2-multi">
                <Select
                  classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Person Type"
                  options={TYPES}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                  value={
                    personData?.personDetails?.type && {
                      label: personData.personDetails.type,
                      value: personData.personDetails.type,
                    }
                  }
                  onChange={(e) => {
                    setPersonData((prevVal) => ({
                      ...prevVal,
                      personDetails: {
                        ...personData.personDetails,
                        type: e.value,
                      },
                    }));
                  }}
                />
                {/* <Select
                  classNamePrefix={
                    personData.personDetails.type.length > 1
                      ? "custom_select_multi"
                      : "custom_select"
                  }
                  isSearchable
                  isMulti={true}
                  placeholder="Person Type"
                  options={TYPES}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                  value={personData.personDetails.type.map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  onChange={(e) => {
                    setPersonData((prevVal) => ({
                      ...prevVal,
                      personDetails: {
                        ...personData.personDetails,
                        type: e.map((ele) => ele.value),
                      },
                    }));
                  }}
                /> */}
                <Select
                  classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Job Title"
                  isDisabled={
                    personData.personDetails.type !== "" ? false : true
                  }
                  options={
                    (personData.personDetails.type === "Office Staff" &&
                      OFFICE_STAFF_JOB_TITLES) ||
                    (personData.personDetails.type === "Others" &&
                      OTHERS_JOB_TITLES)
                  }
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                  value={
                    personData?.personDetails?.jobTitle && {
                      label: personData.personDetails.jobTitle,
                      value: personData.personDetails.jobTitle,
                    }
                  }
                  onChange={(e) => {
                    setPersonData((prevVal) => ({
                      ...prevVal,
                      personDetails: {
                        ...personData.personDetails,
                        jobTitle: e.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="g-r-2">
                <Autocomplete
                  freeSolo
                  className="subs-autocomplete"
                  id="free-solo-2-demo"
                  getOptionLabel={(option) => {
                    return option.title ? option.title.toString() : "";
                  }}
                  options={emailOptions}
                  onInputChange={(event, value) =>
                    onInputChange(event, value, "email")
                  }
                  inputValue={personData.personDetails.email}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setPersonInfo({ personId: "" });
                        clearData();
                      }}
                    />
                  }
                  renderInput={(params) => (
                    <TextField {...params} className="sel-item" label="Email" />
                  )}
                  onChange={(e, val) => {
                    if (val) {
                      searchByPersonNum(val.id);
                    }
                  }}
                />
                <div>
                  {!isCorporation && (
                    <div>
                      <span className="input-title-paragrah">
                        Is Corporation
                      </span>
                      <Checkbox
                        onChange={(e) => {
                          setIsCorporation(!isCorporation);
                        }}
                        checked={isCorporation}
                      />
                    </div>
                  )}

                  {isCorporation && (
                    <div className="corporation-drawer">
                      <Grid container md={12} sx={{ alignItems: "center" }}>
                        <Grid item md={12}>
                          <Select
                            classNamePrefix="custom_select"
                            isSearchable
                            isClearable
                            options={corporationList}
                            placeholder="Corporation"
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 2 }),
                            }}
                            value={corporationList.filter(function (option) {
                              return (
                                option.value ==
                                personData.personDetails.corporation
                              );
                            })}
                            onChange={(e) => {
                              if (e) {
                                setPersonData((prevVal) => ({
                                  ...prevVal,
                                  personDetails: {
                                    ...personData.personDetails,
                                    corporation: e.value,
                                  },
                                }));
                              } else {
                                setIsCorporation(false);
                                setPersonData((prevVal) => ({
                                  ...prevVal,
                                  personDetails: {
                                    ...personData.personDetails,
                                    corporation: "",
                                  },
                                }));
                              }
                            }}
                          />
                        </Grid>
                        {/* <Grid item md={1}>
                          <IconButton onClick={() => setCorporationFlag(true)}>
                            <ArrowCircleRightIcon
                              color="primary"
                              size="large"
                              fontSize="60"
                            />
                          </IconButton>
                        </Grid> */}
                      </Grid>

                      {/* <AddCorporation
                        corporationFlag={corporationFlag}
                        onClose={() => setCorporationFlag(false)}
                        setPersonData={setPersonData}
                        setCorporationList={setCorporationList}
                      /> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <AddressDetails
            addressData={personData.addressDetails}
            personNum={personNum}
            setPersonData={setPersonData}
            setUpdateValue={setUpdateValue}
            isCreate={isCreate}
            isPersonData={isPersonData}
            loadPersonDataById={loadPersonDataById}
          />
          <CommunicationDetails
            communicationData={personData.communicationDetails}
            setPersonData={setPersonData}
            isCreate={isCreate}
            setCommunicationUpdateValue={setCommunicationUpdateValue}
            personNum={personNum}
            isPersonData={isPersonData}
            loadPersonDataById={loadPersonDataById}
          />
        </div>
        <div className="right-s">
          <DemographicDetails
            demographicData={personData.demographicDetails}
            setPersonData={setPersonData}
            personId={personInfo.personId}
            setPassword={setPassword}
          />

          <Attributes
            attributeData={personData.attributesDetails}
            setPersonData={setPersonData}
          />

          <FirstFranchiseInfo
            franchiseData={personData.franchiseDetails}
            setPersonData={setPersonData}
          />
        </div>
      </div>

      {!isCreate && (
        <Grid
          container
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "white",
            paddingTop: "10px",
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ mr: 5, zIndex: 100 }}
            disabled={isSaveDisable || personNum === ""}
            onClick={() => {
              updateDetailsHandler(personNum);
              setIsPersonData(false);
              setPersonData(data);
              setPersonInfo({ personId: "" });
              clearData();
            }}
          >
            Save
          </Button>
        </Grid>
      )}

      {isCreate && (
        <Grid
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "white",
            pr: 5,
            paddingTop: "10px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setIsCreate(false);
              setPersonData(data);
              setPersonNum("");
              setIsPersonData(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={
              !personData.personDetails.firstName?.trim() ||
              !personData.personDetails.lastName?.trim() ||
              !personData.personDetails.email?.trim() ||
              personData.personDetails.type === []
                ? true
                : false
            }
            onClick={createInsuranceHandler}
            sx={{ ml: 5 }}
          >
            Save
          </Button>
        </Grid>
      )}
    </div>
  );
};
export default PersonNameDetails;
