import { Button, Chip, TextField } from "@mui/material";
import { useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from "../../../utils/attachments";

const InvoiceDataPaymentDetails = ({
  onSave,
  invoiceAmount,
  attachmentData,
}) => {
  const [formDetails, setFormDetails] = useState({});
  const [attachments, setAttachments] = useState([]);

  const [config, setConfig] = useState({
    disableSave: false,
  });

  const onDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const onInputChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  const isDisabled = () => {
    return (
      !formDetails.dateOfPayment ||
      !formDetails.utr ||
      !formDetails.paymentAmount
    );
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div className="comment-attachments-container">
      <TextField
        label="Date Of Payment *"
        fullWidth
        size="small"
        type="date"
        name="dateOfPayment"
        // inputProps={{ max: "2123-05-31" }}
        inputProps={{ max: currentDate }}
        value={formDetails.dateOfPayment}
        InputLabelProps={{ shrink: true }}
        onChange={onInputChange}
      />
      <TextField
        label="UTR *"
        fullWidth
        size="small"
        name="utr"
        value={formDetails.utr}
        onChange={onInputChange}
      />
      <TextField
        label="Payment Amount *"
        type="number"
        fullWidth
        size="small"
        name="paymentAmount"
        value={formDetails.paymentAmount}
        onChange={onInputChange}
        error={
          Math.abs(formDetails.paymentAmount) > Math.abs(invoiceAmount) ||
          (formDetails.paymentAmount > 0 && invoiceAmount < 0) ||
          (formDetails.paymentAmount < 0 && invoiceAmount > 0)
        }
        helperText={`Max Invoice amount ${invoiceAmount}`}
      />
      <TextField
        label="TDS Amount"
        type="number"
        fullWidth
        size="small"
        name="tdsAmount"
        value={formDetails.tdsAmount}
        onChange={onInputChange}
      />
      <FileUpload
        uploadText="Payment documents"
        id="invoice-additional-doc"
        clsName="add-additional-doc"
        url={`${FINANCE_HOSTNAME}${
          FINANCE_REST_URLS.UPLOAD_DOCUMENT
        }${getUploadAttachmentQueryParams(
          { ...attachmentData, attachmentKey: "paymentDocUrls" },
          uploadAttachmentTypes.enterPaymentDetails
        )}`}
        callBack={(response) => {
          const { data } = response;
          setAttachments([...attachments, data]);
        }}
        formDataKey="file"
      />
      {attachments.map((attachment, index) => {
        return (
          <Chip
            label={attachment.originalName}
            onDelete={() => onDelete(index)}
            onClick={() => window.open(attachment?.docUrl, "_blank")}
          />
        );
      })}
      <Button
        fullWidth
        disabled={isDisabled() || config.disableSave}
        onClick={() => {
          setConfig({
            disableSave: true,
          });
          onSave({
            ...formDetails,
            paymentDocUrls: attachments.map((a) => a.docUrl),
          });
          setTimeout(() => {
            setConfig({
              disableSave: false,
            });
          }, 5000);
        }}
        variant="contained"
      >
        Save Changes
      </Button>
    </div>
  );
};

export default InvoiceDataPaymentDetails;
