import {
  convertToCamelCaseFromUnderScore,
  exportToExcel,
} from '../../../../common-utilities/utils';
import { getPurchaseOrderAmount } from '../../purchase-order/helper';
import { getGRNOrderAmountWithTax } from '../grn-details/helper';

export const downloadReports = (data, status) => {
  if (!data?.length) {
    return;
  }
  const statusLabel =
    status === 'approval_in_progress' ? 'Pending With' : 'GRN Status';
  const results =
    data?.map?.((item) => {
      const purchaseOrderId = item?.purchaseOrderId;
      const items = purchaseOrderId?.items || [];
      const receivedItems = item?.receivedItems || [];
      let statusValue = convertToCamelCaseFromUnderScore(item.status);
      if (status === 'approval_in_progress') {
        const { approvalId = {} } = item;
        statusValue = `${approvalId && approvalId.pendingWith} `;
      }

      const site = item?.site;

      let convertedItem = {
        'GRN NO': item?.grnNo || null,
        'PO Number': purchaseOrderId?.poNumber || null,
        'Site Code': site?.code || null,
        'Site Name': site?.name || null,
        'Vendor Code': purchaseOrderId?.vendorCode || null,
        'Vendor Name': purchaseOrderId?.vendorName || null,
        [statusLabel]: statusValue,
      };
      if (status !== 'approval_in_progress') {
        convertedItem = {
          ...convertedItem,
          'Pending With': item?.approvalId?.pendingWith || null,
        };
      }

      convertedItem = {
        ...convertedItem,
        'Total PO Value': getPurchaseOrderAmount(items),
        'Total GRN Value': getGRNOrderAmountWithTax(items, receivedItems),
      };
      if (status === 'approved') {
        convertedItem['Payment Status'] = convertToCamelCaseFromUnderScore(
          item.paymentStatus || ''
        );
      }
      convertedItem['Created By'] = item?.creatorName;
      return convertedItem;
    }) || [];

  exportToExcel(results, 'grn_reports');
};
