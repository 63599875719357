import React from 'react';
import { Typography } from '@mui/material';
import StageDetailsCard from './stage-details-card';
import './index.scss';

const ApprovalStageDetails = ({ approvalDetails, createdBy }) => {
  const { stages = [] } = approvalDetails;

  const getCurrentStageStatus = (stage, index) => {
    const { status, StageNumber = 0 } = stage;
    if (status === 'approval_in_progress' && StageNumber === 0) {
      return {
        status: `Pending with creator : ${createdBy}`,
        stageStatusClass: 'warning',
        dateLabel: '',
        createdBy: createdBy || '',
      };
    } else if (status === 'approval_in_progress') {
      return {
        status: `Pending With : ${
          approvalDetails?.pendingWith || stage.stageName
        }`,
        stageStatusClass: 'warning',
        dateLabel: '',
        createdBy: createdBy || '',
      };
    } else if (status === 'approved') {
      return {
        status: `Approved By : ${stage.approverName}`,
        name: stage.approverName,
        stageStatusClass: 'approved',
        dateLabel: 'Approved On',
        createdBy: createdBy || '',
        comment: stage.approvalComments || '',
      };
    } else if (status === 'rejected') {
      return {
        status: `Rejected By : ${stage?.rejectedByName || ''}`,
        name: stage.rejectedByName,
        stageStatusClass: 'rejected',
        dateLabel: 'Rejected On',
        comment: stage.rejectionReason || '',
        createdBy: createdBy || '',
      };
    } else if (status === 'reconsider') {
      return {
        status: `Reconsider By : ${stage.rejectedByName}`,
        name: stage.rejectedByName || '',
        stageStatusClass: 'reconsider',
        dateLabel: 'Reconsider On',
        createdBy: createdBy || '',
        comment: stage?.reconsideredComments || '',
      };
    }
  };

  const stageDetails = stages;
  return (
    <div className="approval-stage-details-container">
      <div className="details-header">
        <Typography className="title">Approval details</Typography>
      </div>
      <div className="approval-card-list">
        {stageDetails.map((stage, index) => {
          const updateStage = getCurrentStageStatus(stage, index);
          return (
            <StageDetailsCard
              stageDetails={{ ...stage, ...updateStage }}
              lastStage={stages.length === index + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ApprovalStageDetails;
