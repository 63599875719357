import React, { useState, useEffect } from "react";
import { getOfflineData } from "../../utils/offline-services";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import "./index.scss";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import { toast } from "react-toastify";
import { getHeaderConfig } from "./config";
import MuiTable from "../../../common-utilities/core/mui-table";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Grid, TextField } from "@mui/material";

const BalanceHistory = () => {
  const [storeId, setStoreId] = useState();
  const [storeDetails, setStoreDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const userData = getOfflineData("user");
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    const storeNo = searchTerm?.split("-")[0];
    clearTimeout(timeoutId);

    const payload = {};

    if (!isNaN(parseInt(storeNo))) {
      payload.storeNumber = storeNo;
    } else if (searchTerm && typeof searchTerm === "string") {
      payload.restaurantName = searchTerm;
    }

    if (Object.keys(payload).length > 0) {
      setTimeout(() => {
        plainApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
          null,
          payload
        ).then((res) => {
          const storeList = res?.results?.map((ele) => ({
            storeNo: ele?.storeNumber,
            satelliteNo: ele?.satelliteNumber,
            sequenceNo: ele?.sequenceNumber,
            restaurantName: ele?.restaurantName,
            id: ele?.id,
          }));
          setStoreList(storeList);
        });
      }, 300);
    }
  };
  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const getStoreDetails = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      filter
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const loadData = (filters) => {
    if (storeId) {
      const payload = { ...filters, storeId };
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_BALANCE_HISTORY}`,
        null,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            const result = res.results.map((ele) => ({
              ...storeDetails,
              ...ele,
            }));
            setTableData({ ...res, results: result });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (userData.role === "STORE") {
      const store = userData?.userId.split("-");
      getStoreDetails({
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      });
    }
  }, []);
  useEffect(() => {
    if (Object.keys(storeDetails).length > 0) {
      setStoreId(storeDetails.id);
    }
  }, [storeDetails]);
  useEffect(() => {
    loadData(filters);
  }, [storeId, storeDetails]);

  return (
    <div className="balance-history">
      <Grid container md={12}>
        {userData?.role !== "STORE" && (
          <Grid item md={3}>
            <Autocomplete
              getOptionLabel={(option) =>
                option
                  ? `${option.storeNo}-${option.sequenceNo}-${
                      option.satelliteNo
                    }${
                      option.restaurantName ? `-${option.restaurantName}` : ""
                    }`
                  : ""
              }
              className="poper-select"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setTableData([]);
                    setStoreId("");
                    setStoreDetails({});
                    setStoreList([]);
                  }}
                />
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              options={storeList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Store"
                  sx={{ backgroundColor: "white" }}
                />
              )}
              onInputChange={storeInputChange}
              onChange={(event, value) => {
                if (value) {
                  getStoreDetails({ _id: value?.id });
                  setStoreId(value?.id);
                }
              }}
            />
          </Grid>
        )}
      </Grid>

      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
    </div>
  );
};

export default BalanceHistory;
