import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { userRoles } from '../../../utils/constants';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getOfflineData } from '../../../utils/offline-services';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CommonBtn from '../../../../common-utilities/core/common-button';

export const mainDataTableConfig = (tokenKey) => {
  const user = getOfflineData('user');
  const deleteRights = !!user?.properties?.bdLead?.deleteLeadRight;

  const config = [
    {
      label: 'Lead Id',
      id: 'id',
      key: 'id',
    },
    {
      label: 'Created by',
      id: 'createdBy',
      render: (data, _, index) => {
        return <div key={index}>{data?.createdBy?.name}</div>;
      },
    },
    {
      label: 'Status',
      id: 'status',
      key: 'status',
      render: (data, _, index) => {
        return (
          <div key={index}>{data?.locationInfo?.status?.currentStatus}</div>
        );
      },
    },
    {
      label: 'Expected Rent(In Rs)',
      id: 'expectedRent',
      key: 'expectedRent',
      render: (data, _, index) => {
        return <div key={index}>{data?.commercials?.expectedRent}</div>;
      },
    },
    {
      label: 'CAM',
      id: 'cam',
      key: 'cam',
      render: (data, _, index) => {
        return <div key={index}>{data?.commercials?.cam}</div>;
      },
    },
    {
      label: 'RS% Del',
      id: 'del',
      key: 'del',
      render: (data, _, index) => {
        return <div key={index}>{data?.commercials?.del}</div>;
      },
    },
    {
      label: 'RS% Dine in',
      id: 'dinein',

      render: (data, _, index) => {
        return <div key={index}>{data?.commercials?.dineIn}</div>;
      },
    },
    {
      label: 'MG(In Rs)',
      id: 'mg',

      render: (data, _, index) => {
        return <div key={index}>{data?.commercials?.mg}</div>;
      },
    },
    {
      label: 'Built-Up Area (Sq. Ft.)',
      id: 'builtArea',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.builtArea}</div>;
      },
    },
    {
      label: 'Carpet Area (Sq. Ft.)',
      id: 'carper',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.carperArea}</div>;
      },
    },
    {
      label: 'Catchment',
      id: 'catchment',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.catchment}</div>;
      },
    },
    {
      label: 'City',
      id: 'city',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.city}</div>;
      },
    },
    {
      label: 'Format',
      id: 'format',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.format}</div>;
      },
    },
    {
      label: 'Region',
      id: 'region',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.region}</div>;
      },
    },
    // {
    //   label: "Size",
    //   value: "size",
    //   render: (data, _, index) => {
    //     return <div key={index}>{data?.locationInfo?.size}</div>;
    //   },
    // },
    {
      label: 'State',
      id: 'state',
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.state}</div>;
      },
    },
    {
      label: 'Status Information',
      id: 'Status Infomation',
      render: (data, onClick, index) => {
        return (
          <div key={index}>
            <RemoveRedEyeIcon
              onClick={() => onClick(data, 'show status information')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Competitor Information',
      id: 'Competitor Information',
      render: (data, onClick, index) => {
        return (
          <div key={index}>
            <RemoveRedEyeIcon
              onClick={() => onClick(data, 'show competitor information')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Nearby Subway Data',
      id: 'Nearby Subway Data',
      render: (data, onClick, index) => {
        return (
          <div key={index}>
            <RemoveRedEyeIcon
              onClick={() => onClick(data, 'show nearby subway data')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Stage Gates',
      id: 'stageGates',
      render: (data, onClick, index) => {
        return (
          <div key={index}>
            <RemoveRedEyeIcon
              onClick={() => onClick(data, 'show stage gates')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Store Number',
      id: 'storeNumber',
      render: (data, onClick) => {
        return (
          <>
            {data?.storeNumber}
            {!tokenKey &&
              user.role === 'admin' &&
              data?.allowStoreCreation &&
              !data?.storeNumber && (
                <CommonBtn
                  onClick={() => {
                    onClick(data, 'create-lead-store');
                  }}
                >
                  Create Store
                </CommonBtn>
              )}
          </>
        );
      },
    },
  ];
  if (!tokenKey) {
    config.push({
      label: 'Actions',
      id: 'Actions',
      render: (data, onclick, index) => {
        return (
          <div className="lead-icons">
            {data?.locationInfo?.status?.currentStatus !== 'Dropped' && (
              <IconButton
                key={index}
                onClick={() => {
                  onclick(data, 'drop lead');
                }}
                sx={{ p: 0 }}
              >
                <RemoveCircleIcon id="droplead" />
              </IconButton>
            )}

            {data?.locationInfo?.status?.currentStatus !== 'Dropped' && (
              <IconButton
                key={index}
                onClick={() => {
                  onclick(data, 'edit');
                }}
                sx={{ p: 0 }}
              >
                <EditIcon id="edit" />
              </IconButton>
            )}

            {deleteRights && (
              <IconButton
                key={index}
                onClick={() => {
                  onclick(data, 'delete lead');
                }}
                sx={{ p: 0 }}
              >
                <DeleteIcon id="delete" />
              </IconButton>
            )}
          </div>
        );
      },
    });
  }
  return config;
};
