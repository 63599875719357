import React, { useState, useEffect } from "react";
import "./index.scss";
import LOGO from "../../../../../src/resources/logo.png";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { actionHandler, downloadDoc } from "../utils";
import { modalActions } from "../constants";
import { getExt } from "../modals/utils";
import { getOfflineData } from "../../utils/offline-services";
import { checkUserConfirmation } from "../main-apis";
import { downloadFileFromUrl } from "../../../common-utilities/utils/pdfDownload";

const AppDocumentCard = ({
  appDetails,
  setModalDetails,
  editInfo,
  showPdfReader,
  tokenKey,
  allowDownload,
  allowConfirm,
  confirmRoles,
  slug,
}) => {
  const user = getOfflineData(tokenKey, "user");

  const [cardData, setCardData] = useState({
    name: "",
    url: "",
    image: "",
    role: "",
  });
  useEffect(() => {
    if (appDetails) {
      setCardData({ ...appDetails });
      return;
    }
    setCardData({});
  }, [appDetails]);
  const { Icon } = appDetails;
  return (
    <div
      onClick={() => {
        if (!editInfo || !showPdfReader) {
          return;
        }
        const ext = getExt(appDetails?.link);
        if (ext === "pdf") {
          checkUserConfirmation(
            editInfo,
            appDetails,
            allowConfirm,
            user,
            confirmRoles,
            slug
          );
        } else {
          editInfo({});
        }
      }}
      className={`app-document-details-con`}
    >
      <div className={"app-card edit-card"}>
        <div className="con-div">
          <div className="app-logo">
            {cardData.showIcon ? (
              <Icon />
            ) : (
              <img
                src={cardData.image ? cardData.image : LOGO}
                alt="Card"
                className="card-app-image"
              />
            )}
          </div>
          <p className="title">
            {cardData.name.split(" ").length > 7
              ? `${cardData.name.split(" ").slice(0, 7).join(" ")}...`
              : cardData.name}
          </p>
        </div>

        {user?.role === "admin" && (
          <EditIcon
            onClick={(e) => {
              e.stopPropagation();
              actionHandler(
                appDetails,
                modalActions.updateDocument,
                setModalDetails
              );
            }}
            className="edit-icon"
            fontSize="medium"
          />
        )}
        {allowDownload && (
          <DownloadIcon
            onClick={(e) => {
              e.stopPropagation();
              downloadFileFromUrl(appDetails.link, appDetails.name);
            }}
            className="download-icon"
            fontSize="medium"
          />
        )}
      </div>
    </div>
  );
};

export default AppDocumentCard;
