import './index.scss';
const FilterMenu = ({ options, onChange, value }) => {
  return (
    <div className='filter-menu-container'>
      {(options || []).map(option => {
        if (typeof option === 'string') {
          return (
            <div
              className={value === option ? 'active-menu menu' : 'menu'}
              onClick={() => onChange(option)}
            >
              {option}
            </div>
          );
        }
        return (
          <div
            className={
              value.value === option.value ? 'active-menu menu' : 'menu'
            }
            onClick={() => onChange(option)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

export default FilterMenu;
