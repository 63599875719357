export const poActions = (data, type, setDataFunc) => {
  switch (type) {
    case 'viewPaymentDetails':
      setDataFunc({
        type: 'viewPaymentDetails',
        data: data?.['Payment Details'],
        title: 'Payment Details',
      });
      break;
    default:
      break;
  }
};
