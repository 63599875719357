import React, { useState, useEffect } from "react";
import { TextField, IconButton, Drawer } from "@mui/material";
import "./index.scss";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddAddress from "./add-address";
import { invokeApi } from "../../utils/http-service";
import Select from "react-select";
import { COUNTRY } from "./config";

import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { allRegions } from "../../../common-utilities/utils/region";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const AddressDetails = ({
  addressData,
  setPersonData,
  setUpdateValue,
  isCreate,
  personNum,
  isPersonData,
  loadPersonDataById,
}) => {
  const [addressTypes, setAddressTypes] = useState([]);
  const [addressFlag, setAddressFlag] = useState(false);
  const [addressValue, setAddressValue] = useState({});
  const [states, setStates] = useState([]);
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.LIST_ADDRESS_TYPE}`)
      .then((res) => {
        const result = res.map((ele) => ({ label: ele, value: ele }));
        setAddressTypes(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (!addressData.length) {
      setAddressValue({});
    }
  }, [addressData]);
  useEffect(() => {
    setUpdateValue(addressValue);
  }, [addressValue]);
  useEffect(() => {
    if (addressData === []) {
      setAddressValue({});
    }
  }, []);
  const countryHandler = (e) => {
    setStates([]);
    setAddressValue((prevVal) => {
      delete prevVal?.state;
      return { ...prevVal, country: e.value };
    });
    if (e !== null) {
      let allStates = allRegions[e.value] || {};
      allStates = Object.keys(allStates).sort();
      allStates = getLabelsAndValuesForSelect(allStates, allStates);
      setStates(allStates);
    }
  };
  useEffect(() => {
    if (addressData.length > 0) {
      let storeAddressValue = addressData.filter(
        (ele) => ele.addressType === "Home Address"
      );
      if (storeAddressValue.length > 0) {
        setAddressValue(storeAddressValue);
      } else {
        setAddressValue({});
      }
    }
  }, [addressData]);
  return (
    <div style={{ marginTop: "2em" }}>
      <div className="pd-title g-r-2">
        Address Details
        {!isCreate && (
          <span>
            <IconButton onClick={() => setAddressFlag(true)}>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </span>
        )}
      </div>

      <div className="input-form-container">
        <div className="content-container">
          <div className="g-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Type"
              isDisabled={isCreate}
              onChange={(e) => {
                let addValue = addressData.filter((ele) => {
                  return ele.addressType === e.value;
                });
                if (addValue.length > 0) {
                  setAddressValue(addValue);
                } else {
                  setAddressValue([
                    {
                      addressType: e.value,
                    },
                  ]);
                }
                setUpdateValue((prevVal) => ({
                  ...prevVal,
                  addressType: e.value,
                }));
              }}
              options={addressTypes}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              value={
                addressValue[0]?.addressType
                  ? {
                      label: addressValue[0]?.addressType,
                      value: addressValue[0]?.addressType,
                    }
                  : null
              }
            />
            <TextField
              className="subs-text-field"
              id="attention"
              disabled={isCreate}
              label="Attention"
              value={addressValue[0]?.attn ? addressValue[0].attn : ""}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="located-in"
              className="subs-text-field"
              disabled={isCreate}
              label="Located In"
              value={addressValue[0]?.address2 ? addressValue[0].address2 : ""}
              fullWidth
              variant="outlined"
            />
            <TextField
              id="street/po-box"
              className="subs-text-field"
              label="Street/PO Box"
              disabled={isCreate}
              fullWidth
              value={addressValue[0]?.address3 ? addressValue[0].address3 : ""}
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="address1"
              className="subs-text-field"
              label="Address 1"
              disabled={isCreate}
              fullWidth
              value={addressValue[0]?.address1 ? addressValue[0].address1 : ""}
              variant="outlined"
            />
            <TextField
              id="county"
              className="subs-text-field"
              disabled={isCreate}
              label="county"
              value={addressValue[0]?.county ? addressValue[0]?.county : ""}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Country"
              isDisabled={isCreate}
              options={COUNTRY}
              value={
                addressValue[0]?.country && {
                  label: addressValue[0].country,
                  value: addressValue[0].country,
                }
              }
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="State/Prov"
              isDisabled={isCreate}
              value={
                addressValue[0]?.state
                  ? {
                      label: addressValue[0].state,
                      value: addressValue[0].state,
                    }
                  : null
              }
              options={states}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </div>
          <div className="g-r-2">
            <TextField
              className="subs-text-field"
              id="city"
              label="City"
              disabled={isCreate}
              value={addressValue[0]?.city ? addressValue[0]?.city : ""}
              fullWidth
              variant="outlined"
            />
            <TextField
              className="subs-text-field"
              id="postal-code"
              label="Postal Code"
              fullWidth
              disabled={isCreate}
              value={addressValue[0]?.zipCode ? addressValue[0]?.zipCode : ""}
              type="number"
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={addressFlag}
        onClose={() => setAddressFlag(false)}
      >
        <AddAddress
          onSave={(details) => {
            loadPersonDataById(personNum);
            setAddressValue({});
            setAddressFlag(false);
          }}
          countries={COUNTRY}
          addressTypes={addressTypes}
          isPersonData={isPersonData}
          personNum={personNum}
          addressDetails={addressData}
          onClose={() => setAddressFlag(false)}
        />
      </Drawer>
    </div>
  );
};
export default AddressDetails;
