import {
  countryDropdownList,
  getCityDropdownList,
  getStateDropdownList,
} from "../../../common-utilities/utils/region-utils";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/store-master";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { STORE_TYPE_MAP } from "./config";
import { pick } from "../../../common-utilities/utils";
import { toast } from "react-toastify";
export const setRegionsData = (
  data,
  countryList,
  setCountryList,
  setStateList,
  setCityList
) => {
  let setRegionData = countryList.length === 0;
  if (data) {
    setRegionData = true;
  }

  if (!setRegionData) {
    return;
  }

  let countries = countryDropdownList();

  if (
    data?.country &&
    !countries.filter((e) => e.value === data?.country).length
  ) {
    countries.push({
      label: data?.country,
      value: data?.country,
    });
  }
  let states = getStateDropdownList(data?.country);

  if (data?.state && !states.filter((e) => e.value === data?.state).length) {
    states.push({
      label: data?.state,
      value: data?.state,
    });
  }
  let cities = getCityDropdownList(data?.country, data?.state);
  if (data?.city && !cities.filter((e) => e.value === data?.city).length) {
    cities.push({
      label: data?.city,
      value: data?.city,
    });
  }

  states.sort();
  cities.sort();
  setCountryList(countries);
  setStateList(states);
  setCityList(cities);
};

export const userListSelectList = async (filter, tokenKey) => {
  let users = [];
  try {
    const data = await invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.USER_LIST}`,
      null,
      filter,
      null,
      tokenKey
    );

    users =
      data?.map?.((item) => {
        return {
          label: `${item?.email ? item?.email + "-" : ""}${item.name}`,
          value: item.id,
        };
      }) || [];
  } catch (err) {
    console.log("Error occcurred getting user list", err);
  }
  return users;
};

export const getSelectDcList = async (label, value, tokenKey) => {
  const labelKey = label ? label : "label";
  const valueKey = value ? value : "value";
  let dcList = [];
  try {
    const results = await invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.DC_LIST}`,
      null,
      null,
      null,
      tokenKey
    );

    dcList =
      results?.map?.((item) => {
        return {
          [valueKey]: item.id,
          [labelKey]: `${item.id}-${item?.name ? `${item?.name}-` : ""}${
            item.email || ""
          }`,
          ...item,
        };
      }) || [];
  } catch (err) {
    console.log("Error occcurred while getting DC list", err);
  }
  return dcList;
};

export const disableSaveButton = (storeData) => {
  let isFormDisabled =
    !storeData.dc ||
    !storeData.storeNumber ||
    !storeData.satelliteNumber ||
    !storeData.sequenceNumber ||
    !storeData.storeType ||
    !storeData.name ||
    !storeData.ownerName ||
    !storeData.email ||
    !storeData.format ||
    !storeData.subFormat ||
    !storeData.status ||
    !storeData.address ||
    !storeData.state ||
    !storeData.region ||
    !storeData.country ||
    !storeData.tierCity ||
    !storeData.electricityChargePerUnit;

  if (storeData?.storeType !== STORE_TYPE_MAP.FOFO) {
    isFormDisabled = isFormDisabled || !storeData.rm || !storeData.am;
  }
  if (storeData?.status === "Active") {
    isFormDisabled =
      isFormDisabled || !storeData.storeOpeningDate || !storeData.posistKey;
  }
  if (storeData?.status === "Inactive") {
    isFormDisabled = isFormDisabled || !storeData.storeClosureDate;
  }
  return isFormDisabled;
};

export const createUpdateStore = (
  storeData,
  setShowForm,
  onSuccess,
  providedUrl,
  tokenKey
) => {
  let mandatoryFields = pick(storeData, [
    "storeNumber",
    "sequenceNumber",
    "satelliteNumber",
    "dc",
    "storeType",
  ]);
  if (mandatoryFields?.storeType !== STORE_TYPE_MAP.FOFO) {
    const amRm = pick(storeData, ["am", "rm"]);
    mandatoryFields = { ...mandatoryFields, ...amRm };
  }
  const additionalFields = pick(
    storeData,
    [
      "name",
      "ownerName",
      "format",
      "subFormat",
      "status",
      "address",
      "country",
      "state",
      "city",
      "region",
      "pinCode",
      "latitude",
      "longitude",
      "email",
      "fzName",
      "circle",
      "pan",
      "gstn",
      "franchiseBusinessName",
      "am",
      "rm",
      "contactNumber",
      "firstLevelEscalation",
      "restaurantName",
      "storeOpeningDate",
      "storeHours",
      "storeLink",
      "tier",
      "posistKey",
      "cat",
      "area",
      "storeTransferDate",
      "tierCity",
      "lsmStatus",
      "coffeeStatus",
      "storeClosureDate",
      "remodelDate",
      "typeOfDecor",
      "electricityChargePerUnit",
      "dmbType",
      "countOfScreens",
      "screensFormat",
      "storeCovers",
    ],
    true
  );
  const updatedBody = {
    ...mandatoryFields,
    ...additionalFields,
  };
  const storeId = storeData?.id;
  const url = storeId
    ? `${REST_URLS.UPDATE_STORE}${storeId}`
    : REST_URLS.CREATE_STORE;
  const method = storeId ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  invokeApi(
    method,
    providedUrl ? providedUrl : `${INTRANETHOSTNAME}${url}`,
    updatedBody,
    null,
    null,
    tokenKey
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      const message = storeId
        ? "Store details updated successfully"
        : "Store created successfully";
      toast.success(message, { autoClose: 2000 });
      onSuccess && onSuccess();
      setShowForm(null);
    })
    .catch((error) => {
      toast.error(error?.message, { autoClose: 2000 });
    });
};
