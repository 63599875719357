/** @format */

import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";

import { getHeaderConfig } from "./config";

import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";

const Store = () => {
  const [store, setStore] = useState({});
  const [deptList, setDeptList] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const loadData = useCallback(
    (params) => {
      const isParamsValid = typeof params === "object" && params !== null;
      const filterParams = isParamsValid ? { ...params } : { ...filters };
      const maxLen = 20;
      if (!filterParams.limit) {
        params.limit = filters.limit;
      }
      if (!filterParams.page) {
        params.page = filters.page;
      }
      if (!filterParams.sortBy) {
        params.sortBy = filters.sortBy;
      }
      if (filterParams.storeName) {
        filterParams.name = filterParams.storeName;
        delete filterParams.storeName;
      } else {
        delete filterParams.storeName;
      }

      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_STORES}`,
        null,
        filterParams
      )
        .then((response) => {
          if (response) {
            const results = response.results.map((ele) => ({
              ...ele,
              type: ele.storeType,
              owner: ele.owner?.[0]?.name,
              ownerId: ele.owner?.[0]?.id,
              useremail: ele?.email
                ? ele?.email?.length > maxLen
                  ? `${ele.email.substring(0, maxLen)}...`
                  : ele.email
                : "",
              ownername: ele.ownerName
                ? ele.ownerName > maxLen
                  ? `${ele?.ownerName.substring(0, maxLen)}...`
                  : ele?.ownerName
                : "",
            }));
            setStore({ ...response, results });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [filters]
  );

  const navigate = useNavigate();
  useEffect(() => {
    const userCredentials = getOfflineData(tokenMainKeys.technet, "user");
    if (!userCredentials) {
      navigate("/login");
    } else {
      loadData(filters);
    }
  }, [filters, navigate, loadData]);

  const getAllDepartmentNamesByStoreType = (storeType) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`,
      null,
      { storeType }
    )
      .then((response) => {
        if (response) {
          response = response.map((user) => ({
            label: user.name,
            value: user.id,
          }));
          setDeptList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="store-container">
        <div className="button-div mainsearchfield">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by store name"
              onChange={(e) => setStoreName(e?.target?.value)}
              value={storeName}
              size="small"
            />

            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  storeName,
                }));
              }}
            />
          </div>
        </div>

        <div>
          <MuiTable
            columnsList={getHeaderConfig(
              loadData,
              deptList,
              getAllDepartmentNamesByStoreType
            )}
            dataList={store.results || []}
            filters={filters}
            pageCount={store.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></MuiTable>
        </div>
      </div>
    </>
  );
};

export default Store;
