import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import { STORE_MASTER_ROUTE } from "../../common-utilities/utils/end-points/store-master";
import StoreMaster from "../components/store-master";

export const storeMasterRouteConfig = {
  mainPath: {
    path: "/",
    // element: <PrivateRoute />,
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: STORE_MASTER_ROUTE,
  routes: [
    {
      path: "/",
      Element: StoreMaster,
      element: <StoreMaster />,
      exact: true,
    },
  ],
};
