import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FranchiseMemo from "../packages/franchise-memos/components/franchise-memo";
import PrivateRoute from "../packages/franchise-memos/routes/private-route";
const FranchiseMemoRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoute />}>
        <Route path="/" element={<FranchiseMemo />} />
      </Route>
    </Routes>
  );
};

export default FranchiseMemoRoutes;
