import { getLabelsAndValuesForSelect } from '../../../../common-utilities/utils';
import { cfStoreTypeList } from './constants';

export const createUpdateModalConfig = (categoryList) => {
  const config = [
    { label: 'Flat Sheet Header', name: 'label', type: 'text' },
    {
      label: 'Flat Sheet Backend Key',
      name: 'cfKey',
      type: 'text',
    },
    {
      label: 'Store Type',
      options:getLabelsAndValuesForSelect(cfStoreTypeList, cfStoreTypeList) ,
      key: 'storeType',
      type: 'autocomplete',
    },
    {
      label: 'Category',
      key: 'category',
      options: categoryList,
      type: 'autocomplete',
    },
  ];
  return config;
};
