import React from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { useState, useEffect } from "react";
import { SIDE_MENU } from "../core/side-menu/config";
import { userStates } from "../../common-utilities/utils/constants";
import {
  checkAndGrantUserAccess,
  makeTokenObject,
} from "../../common-utilities/utils/common-utils";
import { technetEndPoint } from "../../common-utilities/utils/end-points/tech-net";
import { redirectToIntranetPortal, setUserToken } from "../utils/common-utils";
import MainContainer from "../container";

const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [user, setUser] = useState(userStates.isProcessing);
  const navigate = useNavigate();
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const redirectForInvalidPath = () => {
    const userDetails = getOfflineData(tokenMainKeys.technet, "user");
    const { pathname } = location;
    if (userDetails) {
      setUser(userDetails);
      const { type } = userDetails;
      if (type === "STORE_OWNER") {
        const invalidPathForUser = pathname.includes("report");

        if (invalidPathForUser) {
          navigate(`${technetEndPoint}/tickets`);
        }
      } else {
        // if (pathname.includes("create")) {
        //   navigate(`${technetEndPoint}/tickets`);
        // }
      }
    }
    if (typeof userDetails === "string") {
      redirectToIntranetPortal();
    }
  };

  const setUserRemoveParams = (userDetails) => {
    if (userDetails && !userDetails?.message) {
      setUser(userDetails);
    }
    setSearchParams({});
    redirectForInvalidPath();
    const franchiseRole = userDetails?.role
      ?.toUpperCase?.()
      ?.includes?.("FRANCHISE");
    navigate(`${technetEndPoint}/tickets`);
    // if (franchiseRole) {
    //   navigate(`${technetEndPoint}/tickets`);
    // }
  };

  useEffect(() => {
    const areTokensPresent = !!access && !!refresh;
    const userDetails = getOfflineData(tokenMainKeys.technet, "user");
    if (areTokensPresent) {
      const tokens = makeTokenObject(
        access,
        accessTokenExpiry,
        refresh,
        refreshTokenExpiry
      );
      checkAndGrantUserAccess(
        tokenMainKeys.technet,
        appSlugs.technet,
        tokens,
        setUserRemoveParams,
        setUserToken
      );
    } else if (userDetails) {
      redirectForInvalidPath();
    } else {
      redirectToIntranetPortal();
    }
  }, [access, refresh, location, navigate]);
  return user && user !== userStates.isProcessing ? (
    <MainContainer>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
