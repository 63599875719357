import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { DATE_FORMATS } from "./constants";
import moment from "moment";
import {
  convertTimeForSheet,
  excelWorksheetDates,
  getAllExcelColumns,
  removeExcelDecimal,
} from "./excel";

export const appendZeroToTime = (number) => {
  if (number >= 0 && number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDate = (isoDateString, type) => {
  if (!isoDateString) {
    return "-";
  }
  try {
    const dateObj = new Date(isoDateString);
    if (Number.isNaN(dateObj.getTime())) {
      return "-";
    }
    switch (type) {
      case "dateString":
        return isoDateString === "" ? "-" : dateObj.toLocaleDateString();
      case "timeString":
        return dateObj.toLocaleTimeString();
      case DATE_FORMATS["YYYY-MM-DD"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [date.getFullYear(), monthDisplay, dayDisplay].join("-");
      }
      case DATE_FORMATS["DD-MM-YYYY"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [dayDisplay, monthDisplay, date.getFullYear()].join("-");
      }
      case DATE_FORMATS["DD.MM.YYYY hh:MM"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return `${[dayDisplay, monthDisplay, date.getFullYear()].join(
          "-"
        )} ${date.getHours()}:${date.getMinutes()} `;
      }
      case DATE_FORMATS["DD/MM/YYYY hh:MM"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return `${[dayDisplay, monthDisplay, date.getFullYear()].join(
          "/"
        )} ${date.getHours()}:${date.getMinutes()} `;
      }
      default:
        return `${dateObj.toLocaleDateString()} 
                  ${dateObj.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`;
    }
  } catch (err) {
    return "Invalid Date";
  }
};

export const convertCamelCaseToDisplayText = (txt) => {
  let strTmp = txt.replace(/([a-z])([A-Z])/g, "$1 $2");
  if (strTmp && strTmp.length > 0) {
    strTmp = `${strTmp[0].toUpperCase()}${strTmp.slice(1)}`;
  }
  return strTmp;
};

export const convertToCamelCaseFromUnderScore = (str) => {
  return convertCamelCaseToDisplayText(
    (str || "").toLowerCase().replace(/_+([a-z])/g, (m, w) => w.toUpperCase())
  );
};

export const removeUnderScore = (value) => {
  if (!value || !value.includes("_")) {
    return value;
  }
  return value.split("_").join(" ");
};

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

// noDecimalHeaders - When we have viewing a number decimal part should not be visible but when we try to edit it should be visible
export const exportToExcel = (
  data,
  name,
  mutliple,
  removeDefaultFileNameStart,
  processData,
  noDecimalHeaders,
  dateColumns
) => {
  const wb = {
    Sheets: {},
    SheetNames: [],
  };

  if (!mutliple) {
    let ws = XLSX.utils.json_to_sheet(data);
    const headers = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
    const columns = getAllExcelColumns(ws, headers);
    // When headers have to be shown without decimal
    if (noDecimalHeaders) {
      ws = removeExcelDecimal(columns, headers, data, ws, noDecimalHeaders);
    }
    if (typeof processData === "function") {
      processData(wb, ws, "data", headers);
    } else {
      if (dateColumns) {
        excelWorksheetDates(wb, ws, "data", headers, data, dateColumns);
      } else {
        wb.Sheets.data = ws;
        wb.SheetNames.push("data");
      }
    }
  } else {
    data.forEach((item) => {
      const { sheetName, details, noDecimalHeaders, dateColumns } = item;
      let ws = XLSX.utils.json_to_sheet(details);
      const headers = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
      const columns = getAllExcelColumns(ws, headers);
      if (dateColumns) {
        ws = convertTimeForSheet(details, ws, headers, dateColumns);
      }
      if (noDecimalHeaders) {
        ws = removeExcelDecimal(
          columns,
          headers,
          details,
          ws,
          noDecimalHeaders
        );
      }
      if (typeof processData === "function") {
        const headers = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
        processData(wb, ws, sheetName, headers, details);
      } else {
        wb.Sheets[sheetName] = ws;
        wb.SheetNames.push(sheetName);
      }
    });
  }
  const eb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(
    blob,
    `${removeDefaultFileNameStart ? "" : "file_"}` + name + EXCEL_EXTENSION
  );
};

export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};

export const getValue = (value, na) => {
  if (value && value !== 0) {
    return value;
  }
  return na ? "N/A" : "";
};

export const dateFun = (val, convertToDDMMYYFormat = false) => {
  if (val) {
    if (new Date(val) == "Invalid Date") {
      let split = val?.split("-");
      let d =
        split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
      if (d) {
        if (convertToDDMMYYFormat) {
          return moment(d).format("DD-MM-yyyy");
        }
        return moment(d).format("yyyy-MM-DD");
      } else {
        return "";
      }
    } else {
      if (convertToDDMMYYFormat) {
        return moment(val).format("DD-MM-yyyy");
      }
      return moment(val).format("yyyy-MM-DD");
    }
  }
};

export const getLabelsAndValuesForSelect = (
  labels,
  values,
  valueKey = "value",
  splitLabel = true,
  lowerCaseLabel,
  additionalDetails
) => {
  return (
    labels?.map?.((item, index) => {
      if (typeof item === "object") {
        const { label, value } = item;
        return { label, [valueKey]: value };
      }
      let finalLabel = `${item}`;
      if (splitLabel) {
        finalLabel = finalLabel.split("_").join(" ");
      }
      if (lowerCaseLabel) {
        finalLabel = finalLabel
          .split("_")
          .map((splittedItem) => {
            return (
              splittedItem[0].toUpperCase() +
              splittedItem.slice(1).toLowerCase()
            );
          })
          .join(" ");
      }
      let otherDetails = {};
      if (additionalDetails) {
        otherDetails = { ...additionalDetails[index] };
      }
      return { label: finalLabel, [valueKey]: values[index], ...otherDetails };
    }) || []
  );
};

export const getEndDate = (date) => {
  if (new Date(date) == "Invalid Date") {
    return "-";
  }
  const prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);
  if (prevDay < new Date(date) && new Date() > new Date(date)) {
    return new Date().toUTCString();
  }
  return new Date(date).toUTCString();
};

export const getStartDate = (date) => {
  if (new Date(date) == "Invalid Date") {
    return "-";
  }
  const prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);
  if (prevDay < new Date(date) && new Date() > new Date(date)) {
    const curDate = new Date();
    curDate.setHours(0);
    curDate.setMinutes(0);
    curDate.setMilliseconds(0);
    return curDate.toUTCString();
  }
  return new Date(date).toUTCString();
};

export const pick = (object, keys, checkFalsyValue) => {
  return keys.reduce((obj, key) => {
    if (
      object &&
      Object.prototype.hasOwnProperty.call(object, key) &&
      (!!checkFalsyValue
        ? ![null, undefined, NaN, ""].includes(object[key])
          ? true
          : false
        : true)
    ) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

export const zeroFieldValidation = (value) => {
  if (value === "0" || value === 0) {
    return false;
  }
  return !value;
};

export const PHONE_NO_REG_EX = /^\d{10}$/;

export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidMobile = (phoneNumber) => {
  return !phoneNumber || !phoneNumber.match(PHONE_NO_REG_EX);
};

export const deleteObjProp = (properties, obj) => {
  properties?.forEach?.((item) => {
    delete obj?.[item];
  });
};

export const exportToExcelArray = (data, name) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // workbook.Sheets.data = worksheet;
  // workbook.SheetNames.push('data');
  XLSX.utils.book_append_sheet(workbook, worksheet, "data");
  const eb = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(blob, name + EXCEL_EXTENSION);
};
