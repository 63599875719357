export const DEPOSITIONS = [
  { value: "AR", label: "AR" },
  { value: "Remodel", label: "Remodel" },
  { value: "Relocation", label: "Relocation" },
  { value: "SAP", label: "SAP" },
  { value: "Restaurant Status", label: "Restaurant Status" },
  { value: "Lease", label: "Lease" },
  { value: "Operating Assignment", label: "Operating Assignment" },
  { value: "Restaurant Transfer", label: "Restaurant Transfer" },
  { value: "REV", label: "REV" },
  { value: "Others", label: "Others" },
];
