const service = '/v1/bd-leads-service/';
const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  LOGIN: `${service}auth/login`,
  LOGOUT: `${intranetService}auth/logout`,
  REGISTER: `${service}auth/register`,
  DELETE_LEAD: `${service}lead/`,
  // REFRESH_TOKEN: `${service}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  ALL_STAGE_NAMES: `${service}lead/stage/all-names`,
  CREATE_LEASE: `${service}lead`,
  LEADS_LIST: `${service}lead`,
  GET_LEAD_BY_ID: `${service}lead/`,
  UPDATE_LEAD_BY_ID: `${service}lead/`,
  DROP_LEAD_BY_ID: `${service}lead/drop-lead/`,
  USERS_LIST: `${intranetService}other-app/all-user-names`,
  LIST_ALL_USERS: `${intranetService}other-app/all-user-names`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  DASHBOARD: `${service}lead/dashboard`,
  FIELDLIST: `${service}lead/field-list`,
  CREATE_LEAD_STORE: `${service}lead/create-store/`,
};
export const BD_LEAD_ROUTE = '/bd-lead';
export const redirectToRoute = `${BD_LEAD_ROUTE}`;
export const SLUG = 'bd-lead';
