import React, { useState, useEffect } from "react";
import "./index.scss";
import { Card, CardContent, Grid } from "@mui/material";
import { dateFun } from "../../../common-utilities/utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import jsPDF from "jspdf";
import logo from "../../../common-utilities/resources/images/logo.png";
const ViewCertificate = ({ data, storeDetails, setViewCrt }) => {
  const content = `
  <img src=${logo} style="width: 150px; height: 100px;" alt="Logo"/>
  <div style="display: flex;flex-direction:column; justify-content: center; font-family: Arial; font-size: 14px;white-space: nowrap;">

  <p style="font-family: Arial; font-size: 18px; color: black; white-space: nowrap; text-align: center; margin-right:40%">Cash Certificate</p>
  <div>
  <table style="border-collapse: collapse; border:1px solid black ;font-size: 12px;margin-top:20px">
  <tr style="border-bottom:1px solid black;">
  <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Store No.</td>
  <td style="border-right:1px solid black;padding-right:30px; font-weight:300 ;padding-left:10px;">${
    storeDetails?.storeNumber
  }</td>
  <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Print Date</td>
  <td style="solid black;padding-right:30px;font-weight:300;padding-left:10px;">${dateFun(
    new Date(),
    true
  )}</td>
</tr>


    <tr style="border-bottom:1px solid black;">
      <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Name</td>
      <td style="border-right:1px solid black;padding-right:30px; font-weight:300 ;padding-left:10px;">${
        storeDetails?.restaurantName
      } </td>
      <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Location Code</td>
      <td style="solid black;padding-right:30px;font-weight:300;padding-left:10px;">${
        storeDetails?.pinCode
      }</td>
    </tr>
    <tr style="border-bottom:1px solid black;">
    <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Region</td>
    <td style="border-right:1px solid black;padding-right:30px; font-weight:300 ;padding-left:10px;"> ${
      storeDetails?.state
    }</td>
    <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Creation Date</td>
    <td style="solid black;padding-right:30px;font-weight:300;padding-left:10px;">${dateFun(
      data?.createdAt,
      true
    )}</td>
  </tr>
  <tr>
  <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Certification Date</td>
  <td style="border-right:1px solid black;padding-right:30px; font-weight:300 ;padding-left:10px;"> ${dateFun(
    data?.certificationDate,
    true
  )}</td>
  <td style="font-weight: bold; border-right:1px solid black;padding-right:30px;padding-left:10px;">Currency</td>
  <td style="solid black;padding-right:30px;font-weight:300;padding-left:10px;">Indian Rupee</td>
</tr>
  </table>

  <table style="border-collapse: collapse; border:1px solid black ;font-size: 12px;margin-top:20px">
  <tr style="border-bottom:1px solid black;">
  <td style="font-weight: bold; border-right:1px solid black;padding-right:80px;padding-left:10px;">Cash At Bank</td>
  <td style="border-right:1px solid black;padding-right:80px; font-weight:300 ;padding-left:10px;">${(
    data?.cashAtBank || 0
  )?.toFixed(2)}</td>
</tr>
<tr style="border-bottom:1px solid black;">

<td style="font-weight: bold; border-right:1px solid black;padding-right:80px;padding-left:10px;">Cash At Location</td>
<td style="solid black;padding-right:80px;font-weight:300;padding-left:10px;">${data?.cashAtLocation?.toFixed(
    2
  )}</td>
</tr>
  </table>
<p style="margin-top:15px;text-align:center;font-size:14px">Name & Signature</p>
</div>
`;
  const handleDownloadPDF = () => {
    // Create a new jsPDF instance with the title
    const doc = new jsPDF("p", "pt", "a4");
    const title = "Certificate Details";

    doc.html(content, {
      callback: () => {
        // Save the PDF with the specified title
        doc.save(`${title}.pdf`);
      },
      x: 15,
      y: 15,
    });
  };

  return (
    <div className="view-certificate">
      <div className="header-certificate-con">
        <div className="back-con">
          <ArrowBackIosIcon
            onClick={() => setViewCrt({ isShow: false, data: [] })}
            sx={{ cursor: "pointer" }}
          />
          <p>Cash Certificate</p>
        </div>
        <DownloadBtn onClick={handleDownloadPDF} />
      </div>
      <Grid container md={12} spacing={3}>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <div>
                <h4>Imprest Details</h4>
                <div className="details-row">
                  <h6>Format</h6>
                  <h5>{storeDetails?.subFormat}</h5>
                </div>
                <div className="details-row">
                  <h6>Location Code</h6>
                  <h5>{storeDetails?.pinCode}</h5>
                </div>
                <div className="details-row">
                  <h6>Name</h6>
                  <h5>{storeDetails?.restaurantName}</h5>
                </div>
                <div className="details-row">
                  <h6>Region</h6>
                  <h5>{storeDetails?.state}</h5>
                </div>
                <div className="details-row">
                  <h6>Physical Balance</h6>
                  <h5>
                    {storeDetails?.properties?.pettyExpense?.physicalBalance}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Book Balance</h6>
                  <h5>{storeDetails?.properties?.pettyExpense?.bookBalance}</h5>
                </div>
                <div className="details-row">
                  <h6>Inprocess Balance</h6>
                  <h5>
                    {storeDetails?.properties?.pettyExpense?.bookBalance ===
                      0 ||
                    storeDetails?.properties?.pettyExpense?.physicalBalance ===
                      0
                      ? 0
                      : storeDetails?.properties?.pettyExpense?.bookBalance -
                          storeDetails?.properties?.pettyExpense
                            ?.physicalBalance || ""}
                  </h5>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <div>
                <h4>Certificate Details</h4>
                <div className="details-row">
                  <h6>Created On</h6>
                  <h5>{dateFun(data?.createdAt, true)}</h5>
                </div>
                <div className="details-row">
                  <h6>Certificated On</h6>
                  <h5>{dateFun(data?.certificationDate, true)}</h5>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <div>
                <h4>Balance</h4>
                {/* <div className="details-row">
                  <h6>Book Balance</h6>
                  <h5>
                    <span>&#8377;</span>
                    {data?.currentStoreBookBalance?.toFixed(2)}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>In Process Amount</h6>
                  <h5>
                    <span>&#8377;</span>
                    {(
                      data?.currentStoreBookBalance -
                      data?.currentStorePhysicalBalance
                    )?.toFixed(2)}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Physical Balance(As Per System)</h6>
                  <h5>
                    <span>&#8377;</span>
                    {data?.currentStorePhysicalBalance?.toFixed(2)}
                  </h5>
                </div> */}
                <div className="details-row">
                  <h6>Cash At Bank</h6>
                  <h5>
                    <span>&#8377;</span>
                    {(data?.cashAtBank || 0)?.toFixed(2)}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Cash At Location</h6>
                  <h5>
                    <span>&#8377;</span>
                    {data?.cashAtLocation?.toFixed(2)}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Physical Balance (Available At Location)</h6>
                  <h5>
                    <span>&#8377;</span>
                    {data?.physicalBalance?.toFixed(2)}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Difference</h6>
                  <h5>
                    <span>&#8377;</span>
                    {(
                      data.physicalBalance -
                        storeDetails?.properties?.pettyExpense
                          ?.physicalBalance || 0
                    )?.toFixed(2)}
                  </h5>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCertificate;
