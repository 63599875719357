/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import "./index.scss";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BackupIcon from "@mui/icons-material/Backup";
import { invokeUploadFile } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";

const SubwayFileUpload = ({
  title,
  clsName,
  callBack,
  acceptType,
  documentTypeConfig,
  customLimits,
}) => {
  const [file, setFile] = useState(null);
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };

  const onChange = (e) => {
    const isFilterFormatLimitationProvided = !!documentTypeConfig?.fileFormats;
    const uploadFilePreventionMessage = documentTypeConfig?.message
      ? documentTypeConfig?.message
      : "File provided is not of correct format";
    const fileformatAllowed = documentTypeConfig?.fileFormats;
    const fileType = e.target.files[0].type.trim();
    if (isFilterFormatLimitationProvided) {
      if (!fileformatAllowed?.includes(fileType)) {
        toast.error(uploadFilePreventionMessage, {
          autoClose: 2000,
        });
        return;
      }
    }
    setFile(e.target.files[0]);
  };

  const fileUpload = () => {
    const sizeLimit = customLimits?.customSizeLimit
      ? customLimits?.customSizeLimit
      : 5000000;

    if (file.size > sizeLimit) {
      toast.error(
        `File size should not exceed ${
          customLimits?.customMbSize ? customLimits?.customMbSize : "5MB"
        }`
      );
      return;
    }

    const formData = new FormData();
    formData.append("upload", file);
    return invokeUploadFile(`${HOSTNAME}${REST_URLS.UPLOAD_DOC}`, formData)
      .then((response) => {
        if (response.status === 200) {
          setFile(null);
          callBack && callBack(response.data);
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("File too large");
      });
  };

  return (
    <div className="relativePostion">
      <form className={`subway-upload-file ${clsName}`}>
        {file === null ? (
          <>
            <label
              htmlFor="file-upload"
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
            >
              <span className="fr-hide-mobile">{`${title}`}</span>
              <BackupIcon />
              <input
                id="file-upload"
                type="file"
                accept={
                  documentTypeConfig?.acceptType
                    ? documentTypeConfig?.acceptType
                    : acceptType
                }
                onChange={(event) => {
                  onChange(event);
                  event.target.value = null;
                }}
              />
            </label>
          </>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        <div className="relativePostion"></div>

        {file !== null && (
          <span>
            <IconButton color="primary" onClick={onFormSubmit} title="Upload">
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => setFile(null)}
              title="Clear"
            >
              <ClearIcon />
            </IconButton>
          </span>
        )}
      </form>
      <div className="file-Upload-Warning">
        *File size should not exceed{" "}
        {customLimits?.customMbSize ? customLimits?.customMbSize : "5 MB"}
      </div>
    </div>
  );
};

export default SubwayFileUpload;
