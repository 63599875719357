import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { modalConstants } from './constants';

export const getHeaderConfig = () => {
  const headers = [
    { label: 'Flat Sheet Header', id: 'label' },
    {
      label: 'Flat Sheet Backend Key',
      id: 'cfKey',
    },
    {
      label: 'Store Type',
      id: 'storeType',
    },
    {
      label: 'Category',
      id: 'category.name',
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data, onclick) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onclick(data, modalConstants.update);
              }}
              sx={{ p: 0 }}
            >
              <EditIcon id="edit" />
            </IconButton>
            <IconButton
              onClick={() => {
                onclick(data, modalConstants.delete);
              }}
              sx={{ p: 0 }}
            >
              <DeleteIcon id="edit" />
            </IconButton>
          </>
        );
      },
    },
  ];
  return headers;
};
