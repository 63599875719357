import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/common-utilities/app-documents/private.route";
import { appSlugs, tokenMainKeys } from "../utils/constants";
import { Home } from "../packages/common-utilities/app-documents/Home";

const FranchisePolicyRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PrivateRoute
            tokenKey={tokenMainKeys.franchisePolicy}
            slug={appSlugs.franchisePolicy}
          />
        }
      >
        <Route
          path="/"
          element={
            <Home
              slug={appSlugs.franchisePolicy}
              title="Document Repository"
              tokenKey={tokenMainKeys.franchisePolicy}
              noDownload={true}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default FranchisePolicyRoutes;
