import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import Select from "react-select";
import { CloseOutlined } from "@mui/icons-material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { validAddressTypes } from "../../utils/constants";
import { allRegions } from "../../../common-utilities/utils/region";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const AddAddress = ({
  addressTypes,
  storeNo,
  addressDetails = {},
  onClose,
  onSave,
  countries,
}) => {
  const [address, setAddress] = useState([]);
  const [addressValue, setAddressValue] = useState({});
  const [buttonType, setButtonType] = useState("create");
  const [addressType, setAddressType] = useState({});
  const [states, setStates] = useState([]);
  useEffect(() => {
    setAddress(addressDetails);
  }, [addressDetails]);
  const onChange = (event) => {
    setAddressValue((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
    // setAddress({
    //   ...address,
    //   [event.target.name]: event.target.value,
    // });
  };

  const saveAddress = () => {
    let payload = {
      addressType: addressValue?.addressType,
      type: validAddressTypes.store,
      attn: addressValue?.attn,
      address1: addressValue?.address1,
      address2: addressValue?.address2,
      address3: addressValue?.address3,
      city: addressValue?.city,
      country: addressValue?.country,
      state: addressValue?.state,
      storeNo: storeNo,
      zipCode: addressValue?.zipCode,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_ADDRESS}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add address");
        } else {
          onSave(address);
        }
        setAddressValue({});
        setButtonType("create");
      })
      .catch((err) => console.log(err));
  };

  const updateAddress = () => {
    let payload = {
      addressType: addressValue?.addressType,
      type: validAddressTypes.store,
      attn: addressValue?.attn,
      address1: addressValue?.address1,
      address2: addressValue?.address2,
      address3: addressValue?.address3,
      city: addressValue?.city,
      country: addressValue?.country,
      zipCode: addressValue?.zipCode,
      state: addressValue?.state,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_ADDRESS}${addressValue.id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add address");
        } else {
          onSave(address);
        }
        setAddressValue({});
        setButtonType("create");
      })
      .catch((err) => console.log(err));
  };
  const countryHandler = (e) => {
    setStates([]);

    setAddressValue((prevVal) => {
      delete prevVal.state;
      return {
        ...prevVal,
        country: e.value,
      };
    });

    if (e !== null) {
      const states = allRegions[e.value];
      if (states) {
        const allStates = Object.keys(states);
        const stateLabels = getLabelsAndValuesForSelect(allStates, allStates);
        setStates(stateLabels);
      }
    }
  };

  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Address Details</span>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <div className="f-r-2">
            <Select
              classNamePrefix="custom_select"
              options={addressTypes}
              value={
                address?.addressType && {
                  label: address.addressType,
                  value: address.addressType,
                }
              }
              onChange={(e) => {
                let addValue = address.filter((ele) => {
                  return ele.addressType === e.value;
                });
                if (addValue.length > 0) {
                  setButtonType("update");
                  setAddressValue(addValue[0]);
                } else {
                  setAddressValue({});
                  setButtonType("create");
                  setAddressValue((prevVal) => ({
                    ...prevVal,
                    addressType: e.value,
                  }));
                }
                // setAddress({
                //   ...address,
                //   addressType: e.value,
                // });
              }}
              placeholder="Address Type"
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Attention"
              className="subs-text-field"
              value={addressValue.attn || ""}
              name="attn"
              onChange={onChange}
            />
            <TextField
              label="Located In"
              className="subs-text-field"
              value={addressValue.address1 || ""}
              name="address1"
              onChange={onChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Street/PO Box"
              className="subs-text-field"
              value={addressValue.address2 || ""}
              name="address2"
              onChange={onChange}
            />
            <TextField
              label="Add'l Address"
              className="subs-text-field"
              value={addressValue.address3 || ""}
              name="address3"
              onChange={onChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="City"
              className="subs-text-field"
              value={addressValue.city || ""}
              name="city"
              onChange={onChange}
            />
            {/* <TextField
              label="Country"
              value={address.country || ""}
              name="country"
              onChange={onChange}
            /> */}
            <Select
              isSearchable
              placeholder="Country"
              options={countries}
              value={
                addressValue?.country
                  ? {
                      label: addressValue.country,
                      value: addressValue.country,
                    }
                  : null
              }
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onChange={countryHandler}
            />
          </div>
          <div className="g-r-2">
            <Select
              isSearchable
              placeholder="State/Prov"
              value={
                addressValue?.state
                  ? {
                      label: addressValue.state,
                      value: addressValue.state,
                    }
                  : null
              }
              onChange={(e) => {
                setAddressValue((prevVal) => ({
                  ...prevVal,

                  state: e.value,
                }));
              }}
              options={states}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {/* <TextField
              label="State/Prov"
              value={address.state || ""}
              name="state"
              onChange={onChange}
            /> */}
            <TextField
              label="Postal Code"
              className="subs-text-field"
              value={addressValue.zipCode || ""}
              name="zipCode"
              onChange={onChange}
            />
          </div>
          <div>
            <Button
              size="small"
              fullWidth
              variant="contained"
              disabled={!storeNo ? true : false}
              onClick={() => {
                buttonType === "update" ? updateAddress() : saveAddress();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAddress;
