import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import "./index.scss";

export const HTMLTable = ({
  header,
  data,
  pageCount,
  onClick,
  onChange,
  filters,
}) => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters?.page]);
  return (
    <div className="html-table-container">
      <table className="table-container">
        <tr className="table-header-row">
          {header.map((h, index) => {
            return (
              <td key={index} className="status-table-cell">
                {h.label}
              </td>
            );
          })}
        </tr>
        {data.map((rowData, index) => {
          return (
            <tr key={index}>
              {header.map((h, i) => {
                if (h.render) {
                  return (
                    <td key={i} className="table-body-cell">
                      {h.render(rowData, onClick)}
                    </td>
                  );
                }
                return <td className="table-body-cell">{rowData[h.key]}</td>;
              })}
            </tr>
          );
        })}
      </table>
      <div>
        {data.length === 0 && <span className="no-records">No records</span>}
      </div>
      <div className="si-pagination">
        {pageCount > 1 && (
          <Pagination
            count={pageCount}
            color="primary"
            page={page}
            onChange={(e, page) => {
              setPage(page);
              if (onChange) {
                onChange(e, page);
              }
            }}
          ></Pagination>
        )}
      </div>
    </div>
  );
};
