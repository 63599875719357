import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import GRNList from "./grn-list";
import { isCreateAllowed } from "../../utils/common-utils";
import GRNApprovalList from "./grn-approval-list";
import InvoiceDataList from "../invoices-data/invoice-data-list";
import { checkUserRight } from "../../utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const GoodsReceiveNotes = () => {
  const navigate = useNavigate();
  const [poModal, setPOModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const mainTabIndex = searchParams.get("index");
  const openTabIndex = searchParams.get("subIndex");
  const [noOfPendingGrn, setNoOfPendingGrn] = useState(0);
  const [noOfReconsiderGrn, setNoOfReconsiderGrn] = useState(0);
  const [value, setValue] = useState(+openTabIndex || 0);
  const [formDetails, setFormDetails] = useState({});
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue,
    });
    setValue(newValue);
  };

  const onCreate = () => {
    const { po } = formDetails;
    navigate(`grn-order/create/${po.id}`);
  };
  const loadPurchaseOrders = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      null,
      {
        page: 1,
        limit: 1000,
        sortBy: "-createdAt",
        status: "approved",
        allowInward: true,
        // checkAdvPayment: true,
        isShortClosed: false,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setPurchaseOrders(response.results || []);
        }
      })
      .catch((err) => {});
  };
  const getPendingGrn = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "invoice_approval",
        invoiceType: "grn_invoice",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingGrn(response?.length);
        }
      })
      .catch((err) => {});
  };
  const getReconsiderGrn = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}`,
      null,
      { status: "reconsider", limit: 1000 }
    )
      .then((response) => {
        if (!response.message) {
          setNoOfReconsiderGrn(response?.results?.length || 0);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const openTabIndex = searchParams.get("subIndex");
    if (+openTabIndex === 5 && !checkUserRight("grn.approveReject")) {
      setValue(4);
    }
  }, []);
  useEffect(() => {
    getReconsiderGrn();
    getPendingGrn();
  }, []);
  return (
    <div className="rm-pt budget-list">
      <div className="tabs-section">
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange}>
            <Tab label="Draft GRN orders" />
            <Tab label="Open GRN orders" />
            <Tab label="Approved  GRN orders " />
            <Tab
              label={
                <Badge badgeContent={noOfReconsiderGrn} color="primary">
                  Reconsider GRN orders
                </Badge>
              }
            />
            <Tab label="All  GRN orders " />

            <Tab
              label={
                <Badge badgeContent={noOfPendingGrn} color="primary">
                  Pending Actions
                </Badge>
              }
            />
            <Tab label="Finance" />
          </Tabs>
        </Box>
        {isCreateAllowed("grn") && (
          <Button
            variant="outlined"
            onClick={() => {
              setPOModal(true);
              loadPurchaseOrders();
            }}
          >
            Create GRN
          </Button>
        )}
      </div>
      <TabPanel value={value} index={0}>
        <GRNList status="draft" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GRNList status="approval_in_progress" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GRNList status="approved" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GRNList status="reconsider" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <GRNList status="all" />
      </TabPanel>
      <TabPanel value={value} index={5}>
        {checkUserRight("grn.approveReject") ? (
          <GRNApprovalList
            approvalType="invoice_approval"
            usedIn="GRN"
            status="approval_in_progress"
          />
        ) : (
          "Not authorized to take action on GRN"
        )}
      </TabPanel>

      <TabPanel value={value} index={6}>
        {checkUserRight("paymentDetails.view") ? (
          <InvoiceDataList poGrnNo={true} status="approved" usedIn="GRN" />
        ) : (
          "No access to finance"
        )}
      </TabPanel>

      {poModal && (
        <CustomModal
          title="Create GRN"
          disabledOutsideClick={true}
          onClose={() => {
            setPOModal(false);
          }}
        >
          <div className="po-modal-content">
            <Autocomplete
              value={formDetails.po || ""}
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({ po: val });
                } else {
                  setFormDetails({});
                }
              }}
              getOptionLabel={(op) => {
                const { budget } = op;
                const { siteName } = budget || {};

                return `${op.poNumber || ""} - ${siteName || ""}` || "";
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Select PO" />
              )}
              options={purchaseOrders}
            />
            <Button
              variant="outlined"
              onClick={onCreate}
              disabled={!formDetails.po}
            >
              Create Goods Receive Note
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default GoodsReceiveNotes;
