import "./index.scss";
import React, { useState } from "react";
import StoreDetails from "./drawing-details";
import DrawingHistory from "./drawing-history";
import {
  TabPanel,
  MainTabContainer,
} from "../../../common-utilities/core/tabs";

const StoreDrawingDetails = () => {
  const [value, setValue] = useState(0);

  const labels = ["Drawing Details", "Drawing History"];
  return (
    <div className="store-drawing-container">
      <MainTabContainer
        mainClass=""
        tabContainerClass=""
        tabsClass=""
        value={value}
        setValue={setValue}
        labels={labels}
      />
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <StoreDetails />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <DrawingHistory />
        </TabPanel>
      </div>
    </div>
  );
};

export default StoreDrawingDetails;
