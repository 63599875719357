import { ArrowBack, CloseOutlined, RemoveRedEye } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import "./index.scss";
import { INVOICE_TRACKING_HOSTNAME } from "../../../common-utilities/utils/end-points";
import CustomModal from "../../../common-utilities/core/modal";
import { toast } from "react-toastify";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { VENDOR_ONBOARD_REST_URLS } from "../../../common-utilities/utils/end-points/vendor-onboard";
import {
  REJECT_SUBJECT,
  VENDOR_DOCUMENT_TYPES,
  VENDOR_FORM_REJECT_EMAIL_TEMPLATE,
  displayApproverActions,
  getCreatorApprovalData,
  getFinanceApprovalData,
  getHODApprovalData,
} from "./helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SiFileUpload from "../../../invoice-tracking/core/file-uploader";
import { getOfflineData } from "../../utils/offline-services";
import {
  VENDOR_FORM_STATUS,
  VENDOR_ONBOARD_USERS,
} from "../../utils/constants";

const VendorRequestDetails = () => {
  const { id } = useParams();
  const user = getOfflineData("user") || {};
  const { role = "" } = user;
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});
  const [submittedDetails, setSubmittedDetails] = useState({});
  const [approvedModalType, setApprovedModalType] = useState("");
  const [rejectFormDetails, setRejectFormDetails] = useState({});
  const [emailBody, setEmailBody] = useState(VENDOR_FORM_REJECT_EMAIL_TEMPLATE);
  const [comment, setComment] = useState("");
  const [panCard, setPanCard] = useState("");
  const [attachment, setAttachment] = useState("");
  const [gstDocument, setGSTDocument] = useState("");
  const [msmeDocument, setMSMEDocument] = useState("");
  const { status = "", approvers = [], comments = [] } = formDetails;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS}/${id}`,
      null
    )
      .then((response) => {
        setFormDetails(response);
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_ONBOARD}/form/${id}`,
      null
    )
      .then((response) => {
        setSubmittedDetails(response);
      })
      .catch((err) => {});
  };

  const onApproveAction = (status) => {
    let payload = {
      status,
    };

    if (approvedModalType === "Rejected") {
      payload = {
        ...payload,
        ...rejectFormDetails,
        template: emailBody,
      };
    }

    if (comment) {
      payload.comment = comment;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS_ACTIONS}/${id}`,
      payload
    )
      .then((response) => {
        if (
          [
            VENDOR_FORM_STATUS.APPROVED,
            VENDOR_FORM_STATUS.REJECTED,
            VENDOR_FORM_STATUS.REOPENED,
            VENDOR_FORM_STATUS.CANCELED,
            VENDOR_FORM_STATUS.IN_PROGRESS,
          ].includes(response.message)
        ) {
          setComment("");
          setRejectFormDetails({});
          setApprovedModalType("");
          loadData();
        } else {
          toast.error("Approved / Reject request failed");
        }
      })
      .catch((err) => {});
  };

  const onInputChange = (event) => {
    setRejectFormDetails({
      ...rejectFormDetails,
      [event.target.name]: event.target.value,
    });
  };

  const { address = {}, bankDetails = {}, attachments = [] } = submittedDetails;
  const creatorStatus = getCreatorApprovalData(approvers, status);
  const hodStatus = getHODApprovalData(approvers, status);
  const financeApprovalStatus = getFinanceApprovalData(approvers, status);

  const getAttachment = (type) => {
    const doc = attachments.find((a) => a.type === type);
    if (doc) {
      return doc.url;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (attachments.length > 0) {
      setGSTDocument(getAttachment(VENDOR_DOCUMENT_TYPES.GST_DOC));
      setPanCard(getAttachment(VENDOR_DOCUMENT_TYPES.PAN_CARD));
      setMSMEDocument(getAttachment(VENDOR_DOCUMENT_TYPES.MSME_DOC));
      setAttachment(getAttachment(VENDOR_DOCUMENT_TYPES.ATTACHMENT));
    }
  }, [attachments]);

  const uploadAttachments = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.ADD_ATTACHMENTS_TO_FORM}/${id}`,
      payload
    )
      .then((response) => {
        if (response.message === "Updated") {
          if (payload.type === VENDOR_DOCUMENT_TYPES.PAN_CARD) {
            setPanCard(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.GST_DOC) {
            setGSTDocument(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.MSME_DOC) {
            setMSMEDocument(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.CANCEL_CHEQUE) {
            setSubmittedDetails({
              ...submittedDetails,
              cancelledCheque: payload.url,
            });
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.ATTACHMENT) {
            setAttachment(payload.url);
          }
        } else {
          toast.error("Failed to add attachment");
        }
      })
      .catch((err) => {});
  };

  const sendForm = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.RESEND_FORM}/${id}`
    )
      .then((response) => {
        if (response.email) {
          toast.info(`Send form to email :  ${response.email} `);
        } else {
          toast.error("Failed to add attachment");
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="invoice-details-container">
      <div className="header-section">
        <div>
          <IconButton
            variant="contained"
            onClick={() => navigate("/vendor-onboard")}
          >
            <ArrowBack />
          </IconButton>
          <span className="title">Vendor Details : {id} </span>
        </div>
        <div className="right-section">
          <Chip
            label={`Current Status : ${financeApprovalStatus.status}`}
            component="span"
            variant="outlined"
          />

          {/* <Button size="small" variant="outlined" onClick={sendForm}>
            RESEND FORM
          </Button> */}
          {/* {[VENDOR_FORM_STATUS.OPEN, VENDOR_FORM_STATUS.REOPENED].includes(
            status
          ) && (
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                setApprovedModalType("Cancel");
              }}
            >
              Cancel
            </Button>
          )} */}

          {![
            VENDOR_FORM_STATUS.OPEN,
            VENDOR_FORM_STATUS.REOPENED,
            VENDOR_FORM_STATUS.APPROVED,
            VENDOR_FORM_STATUS.REJECTED,
          ].includes(status) &&
            role !== VENDOR_ONBOARD_USERS.HOD &&
            !approvers.some(
              (approver) =>
                approver.userId === user.id &&
                [
                  VENDOR_FORM_STATUS.APPROVED,
                  VENDOR_FORM_STATUS.REJECTED,
                ].includes(approver.status)
            ) && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  navigate(`/vendor-onboard/update/${id}`);
                }}
              >
                Edit
              </Button>
            )}

          {displayApproverActions(approvers, role) && (
            <>
              {[
                VENDOR_FORM_STATUS.RESUBMITTED,
                VENDOR_FORM_STATUS.IN_PROGRESS,
                VENDOR_FORM_STATUS.APPROVED,
                VENDOR_FORM_STATUS.SUBMITTED,
              ].includes(status) && (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setApprovedModalType("Rejected");
                      setRejectFormDetails({
                        email: submittedDetails.email,
                        subject: REJECT_SUBJECT,
                        status: VENDOR_FORM_STATUS.REOPENED,
                      });
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setApprovedModalType("Approved")}
                  >
                    Approve
                  </Button>
                </>
              )}
            </>
          )}
          {status === VENDOR_FORM_STATUS.REJECTED && (
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                setApprovedModalType("Rejected");
                setRejectFormDetails({
                  email: submittedDetails.email,
                  subject: REJECT_SUBJECT,
                  status: VENDOR_FORM_STATUS.REOPENED,
                });
              }}
            >
              Reopen Form
            </Button>
          )}
        </div>
      </div>
      <div className="invoice-details">
        <Card>
          <CardContent>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Name of Legal Entity</span>
                <span className="i-value">
                  {submittedDetails.nameOfLegalEntity}
                </span>
              </div>

              <div className="i-key-value">
                <span className="i-key">Contact Person</span>
                <span className="i-value">
                  {submittedDetails.focalPointOfContact || ""}
                </span>
              </div>

              <div className="i-key-value">
                <span className="i-key">Mobile Number</span>
                <span className="i-value">{submittedDetails.mobile}</span>
              </div>

              <div className="i-key-value">
                <span className="i-key">Email</span>
                <span className="i-value">{submittedDetails.email}</span>
              </div>
            </div>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Alternate Mobile Number</span>
                <span className="i-value">
                  {submittedDetails.alternativeMobile}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Credit Period</span>
                <span className="i-value">{submittedDetails.creditPeriod}</span>
              </div>

              <div className="i-key-value">
                <span className="i-key">Type Of Vendor</span>
                <span className="i-value">{submittedDetails.typeOfVendor}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">PAN</span>
                <span className="i-value">{submittedDetails.pan || ""}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">PAN Card doc</span>
                <span className="i-value">
                  <div>
                    {panCard ? (
                      <div>
                        <span>Pan Card</span>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <IconButton onClick={() => setPanCard("")}>
                            <CloseOutlined color="primary" />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() => window.open(panCard, "_blank").focus()}
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <SiFileUpload
                            id="pan-card"
                            title="PAN card"
                            callBack={(value) =>
                              uploadAttachments({
                                type: VENDOR_DOCUMENT_TYPES.PAN_CARD,
                                url: value[0].url,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Vendor Tenure</span>
                <span className="i-value">{submittedDetails.vendorTenure}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Attachments</span>
                <span className="i-value">
                  <div>
                    {attachment ? (
                      <div>
                        <span>Attachment</span>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <IconButton onClick={() => setAttachment("")}>
                            <CloseOutlined color="primary" />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() =>
                            window.open(attachment, "_blank").focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <SiFileUpload
                            id="attachment"
                            title="Attachment"
                            callBack={(value) =>
                              uploadAttachments({
                                type: VENDOR_DOCUMENT_TYPES.ATTACHMENT,
                                url: value[0].url,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">GST NO</span>
                <span className="i-value">{submittedDetails.gst || ""}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">GST Doc</span>
                <span className="i-value">
                  <div>
                    {gstDocument ? (
                      <div>
                        <span>GST Document</span>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <IconButton onClick={() => setGSTDocument("")}>
                            <CloseOutlined color="primary" />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() =>
                            window.open(gstDocument, "_blank").focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <SiFileUpload
                            id="gst-doc"
                            title="GST Document"
                            callBack={(value) =>
                              uploadAttachments({
                                type: VENDOR_DOCUMENT_TYPES.GST_DOC,
                                url: value[0].url,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">MSME Type & Number</span>
                <span className="i-value">
                  {submittedDetails.msmeType} - {submittedDetails.msmeNumber}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">MSME Doc</span>
                <span className="i-value">
                  <div>
                    {msmeDocument ? (
                      <div>
                        <span>MSME Document</span>
                        <IconButton onClick={() => setMSMEDocument("")}>
                          <CloseOutlined color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            window.open(msmeDocument, "_blank").focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <SiFileUpload
                            id="gst-doc"
                            title="MSME Document"
                            callBack={(value) =>
                              uploadAttachments({
                                type: VENDOR_DOCUMENT_TYPES.MSME_DOC,
                                url: value[0].url,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="address-bank-details-form-row">
              <div className="i-key-value">
                <span className="i-key">Address</span>
                <span className="i-value">
                  {`${address.line || ""},${address.street || ""},${
                    address.city || ""
                  },${address.state || ""},${address.pincode || ""}`}
                  {address.landline && <div>Landline : {address.landline}</div>}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Bank Details</span>
                <span className="i-value">
                  <div>Account Number : {bankDetails.accountNumber}</div>
                  <div>Bank Name : {bankDetails.bankName}</div>
                  <div>IFSC : {bankDetails.ifsc}</div>

                  <div>
                    {submittedDetails.cancelledCheque ? (
                      <div>
                        <span>Cancelled Cheque</span>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <IconButton
                            onClick={() =>
                              setSubmittedDetails({
                                ...submittedDetails,
                                cancelledCheque: "",
                              })
                            }
                          >
                            <CloseOutlined color="primary" />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() =>
                            window
                              .open(submittedDetails.cancelledCheque, "_blank")
                              .focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        {status !== VENDOR_FORM_STATUS.APPROVED && (
                          <SiFileUpload
                            id="cancelled-cheque"
                            title="Cancelled Cheque"
                            callBack={(value) =>
                              uploadAttachments({
                                type: VENDOR_DOCUMENT_TYPES.CANCEL_CHEQUE,
                                url: value[0].url,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </span>
              </div>
              <div className="i-key-value">
                <div className="approval-status">
                  <div className="i-key">Approval status</div>
                  <div className="approval-key-value">
                    <span className="i-key">Creator Status</span>
                    <Chip
                      label={creatorStatus.status}
                      color={creatorStatus.color || "primary"}
                    />
                  </div>
                  <div className="approval-key-value">
                    <span className="i-key">Creator Comment</span>
                    <span className="i-value">{creatorStatus.comment}</span>
                  </div>
                  <div className="approval-key-value">
                    <span className="i-key">HOD Status</span>
                    <Chip
                      label={hodStatus.status}
                      color={hodStatus.color || "primary"}
                    />
                  </div>
                  <div className="approval-key-value">
                    <span className="i-key">HOD Comment</span>
                    <span className="i-value">{hodStatus.comment}</span>
                  </div>
                  <div className="approval-key-value">
                    <span className="i-key">Finance User Status</span>
                    <Chip
                      label={financeApprovalStatus.status}
                      color={financeApprovalStatus.color || "primary"}
                    />
                  </div>
                  <div className="approval-key-value">
                    <span className="i-key">Finance User Comment</span>
                    <span className="i-value">
                      {financeApprovalStatus.comment}
                    </span>
                  </div>
                  {comments.length > 0 && (
                    <span className="comment-list">
                      Comment History :{" "}
                      {(comments || []).map((c) => (
                        <div className="comment">
                          <span>
                            {c.createOn &&
                              new Date(c.createOn).toLocaleDateString()}
                          </span>
                          <span>{c.comment}</span>
                        </div>
                      ))}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {approvedModalType === "Approved" && (
          <CustomModal
            open={!!approvedModalType}
            title=" Approve Vendor Form"
            contentClassName="approve-invoice-modal"
            onClose={() => {
              setApprovedModalType("");
            }}
          >
            <div>
              <TextareaAutosize
                placeholder="Approver comment"
                minRows={6}
                value={comment}
                style={{ width: "100%" }}
                onChange={(e) => setComment(e.target.value)}
              />

              <div className="footer-buttons">
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!comment ? true : false}
                  onClick={() => {
                    onApproveAction(VENDOR_FORM_STATUS.APPROVED);
                  }}
                >
                  Approve
                </Button>
              </div>
            </div>
          </CustomModal>
        )}

        {approvedModalType === "Cancel" && (
          <CustomModal
            open={!!approvedModalType}
            title="Cancel Vendor Form"
            contentClassName="approve-invoice-modal"
            onClose={() => {
              setApprovedModalType("");
            }}
          >
            <div>
              <TextareaAutosize
                placeholder="Cancel  reason"
                minRows={6}
                value={comment}
                style={{ width: "100%" }}
                onChange={(e) => setComment(e.target.value)}
              />

              <div className="footer-buttons">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    onApproveAction(VENDOR_FORM_STATUS.CANCELED);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </CustomModal>
        )}

        {approvedModalType === "Rejected" && (
          <CustomModal
            open={!!approvedModalType}
            title="Reject Vendor Form"
            contentClassName="approve-invoice-modal"
            onClose={() => {
              setApprovedModalType("");
            }}
          >
            <div>
              <div className="reject-form-row">
                <FormControl disabled={status === "REJECTED"}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Do you want to send form after reject ?
                  </FormLabel>
                  <RadioGroup
                    row
                    value={rejectFormDetails.status || ""}
                    onChange={onInputChange}
                    name="status"
                  >
                    <FormControlLabel
                      value={VENDOR_FORM_STATUS.REOPENED}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="REJECTED"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {rejectFormDetails.status === VENDOR_FORM_STATUS.REOPENED && (
                  <>
                    <TextField
                      label="Vendor Email"
                      name="email"
                      size="small"
                      value={rejectFormDetails.email}
                      onChange={onInputChange}
                    />
                    <TextField
                      label="Email Subject"
                      name="subject"
                      size="small"
                      value={rejectFormDetails.subject}
                      onChange={onInputChange}
                    />
                    <CKEditor
                      editor={ClassicEditor}
                      data={emailBody}
                      onChange={(event, editor) => {
                        setEmailBody(editor.getData());
                      }}
                    />
                  </>
                )}
                {rejectFormDetails.status !== VENDOR_FORM_STATUS.REOPENED && (
                  <TextareaAutosize
                    placeholder="Reject comment "
                    minRows={6}
                    value={comment}
                    style={{ width: "100%" }}
                    onChange={(e) => setComment(e.target.value)}
                  />
                )}

                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={() => {
                    onApproveAction(VENDOR_FORM_STATUS.REOPENED);
                  }}
                  disabled={
                    rejectFormDetails.status !== VENDOR_FORM_STATUS.REOPENED &&
                    !comment
                      ? true
                      : false
                  }
                >
                  {rejectFormDetails.status === VENDOR_FORM_STATUS.REJECTED
                    ? "Reject"
                    : "Send reject request"}
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default VendorRequestDetails;
