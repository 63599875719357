import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import { useState, useRef, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SyncIcon from "@mui/icons-material/Sync";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import { Divider } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import moment from "moment";
import { checkValidStoreCombination } from "../../utils";

import Select from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import { GlobalStoreContext } from "../../global-states/Context";
import { allRegions } from "../../../common-utilities/utils/region";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";
import {
  dateFun,
  formatDate,
  getLabelsAndValuesForSelect,
} from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const Transactions = () => {
  const countries = Object.keys(allRegions);
  const countrySelectValues = getLabelsAndValuesForSelect(countries, countries);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const previousController = useRef();
  const [satelliteList, setSatelliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [statuses, setStatuses] = useState({
    storeStatus: "",
    leaseStatus: "",
  });
  const [allFields, setAllFields] = useState({
    amendAgreement: false,
    cancellationDate: "",
    cancellationReason: "",
    terminationDate: null,
    terminationReason: "",
    status: "",
    slrExpiry: "",
    reusable: "",
    assigned: "",
    amended: "",
    renewal: "",
    expiry: "",
    termsInMonths: "",
    agreement: "",
    ofac: "",
    disclosure: "",
    pledgeForm: false,
    franchiseXref: "",
    afLanguage: false,
    paymentProgram: "",
    drinkOption: "",
    country: "",
    sequenceType: "",
    paymentDate: "",
    currency: "",
    fee: "",
    taxID: "",
    businessName: "",
    franchisor: "",
    developerContract: "",
    locationType: "",
  });
  const buttonStatuses = { create: "create", save: "save" };
  const [buttonStatus, setbuttonStatus] = useState(buttonStatuses.save);

  const resetAllDetails = (
    resetStoreDetails,
    resetFieldDetails,
    partialStoreDetails,
    resetButtonStatus
  ) => {
    if (resetStoreDetails) {
      setStoreInfo({
        satelliteNo: null,
        sequenceNo: null,
        storeNo: "",
      });
    }

    if (partialStoreDetails) {
      setStoreInfo((prevDetails) => ({
        ...prevDetails,
        ...partialStoreDetails,
      }));
    }

    if (resetFieldDetails) {
      setAllFields({
        amendAgreement: false,
        cancellationDate: "",
        cancellationReason: "",
        terminationDate: "",
        terminationReason: "",
        slrExpiry: "",
        reusable: "",
        status: "",
        assigned: "",
        amended: "",
        renewal: "",
        expiry: "",
        termsInMonths: "",
        agreement: "",
        ofac: "",
        disclosure: "",
        pledgeForm: false,
        franchiseXref: "",
        afLanguage: false,
        paymentProgram: "",
        drinkOption: "",
        country: "",
        sequenceType: "",
        paymentDate: "",
        currency: "",
        fee: "",
        taxID: "",
        businessName: "",
        franchisor: "",
        developerContract: "",
        locationType: "",
      });
      setStatuses({
        storeStatus: "",
        leaseStatus: "",
      });
    }

    if (resetButtonStatus) {
      setbuttonStatus(buttonStatuses.save);
    }
  };

  const getFranchiseDetailsForStoreNumberAndSequence = (
    satelliteNo,
    sequenceNo
  ) => {
    const isValidCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      satelliteNo,
      sequenceNo
    );
    if (isValidCombination) {
      const params = { satelliteNo, sequenceNo };
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_FRANCHISE_AGREEMENT_FOR_STORE_NO}${storeInfo.storeNo}`,
        null,
        params
      )
        .then((response) => {
          if (response && !response.message) {
            const { franchise = {}, statuses, store } = response;
            if (franchise) {
              setRespData(franchise);
              const {
                developerContract = "",
                franchisor = "",
                businessName = "",
                fee = "",
                paymentDate = "",
                sequenceType = "",
                country = "",
                drinkOption = "",
                paymentProgram = "",
                amendAgreement = false,
                afLanguage = false,
                franchiseXref = "",
                pledgeForm = false,
                disclosure = "",
                ofac = "",
                agreement = "",
                termsInMonths = "",
                expiry = "",
                renewal = "",
                amended = "",
                assigned = "",
                reusable = "",
                slrExpiry = "",
                termination = "",
                cancellation = "",
                status = "",
                id = "",
              } = franchise;
              setAllFields((prevVal) => ({
                ...prevVal,
                id,
                developerContract,
                franchisor,
                businessName,
                fee,
                paymentDate: !paymentDate
                  ? ""
                  : formatDate(paymentDate, DATE_FORMATS["YYYY-MM-DD"]),
                sequenceType,
                country,
                drinkOption,
                paymentProgram,
                status,
                amendAgreement,
                afLanguage,
                franchiseXref,
                pledgeForm,
                disclosure: !disclosure
                  ? ""
                  : formatDate(disclosure, DATE_FORMATS["YYYY-MM-DD"]),
                ofac: !ofac ? "" : formatDate(ofac, DATE_FORMATS["YYYY-MM-DD"]),
                agreement: !agreement
                  ? ""
                  : formatDate(agreement, DATE_FORMATS["YYYY-MM-DD"]),
                termsInMonths,
                expiry: !expiry
                  ? ""
                  : formatDate(expiry, DATE_FORMATS["YYYY-MM-DD"]),
                renewal,
                amended: !amended
                  ? ""
                  : formatDate(amended, DATE_FORMATS["YYYY-MM-DD"]),
                assigned: !assigned
                  ? ""
                  : formatDate(assigned, DATE_FORMATS["YYYY-MM-DD"]),
                reusable: !reusable
                  ? ""
                  : formatDate(reusable, DATE_FORMATS["YYYY-MM-DD"]),
                slrExpiry: !slrExpiry
                  ? ""
                  : formatDate(slrExpiry, DATE_FORMATS["YYYY-MM-DD"]),
                cancellationDate: !cancellation?.date
                  ? ""
                  : formatDate(cancellation?.date, DATE_FORMATS["YYYY-MM-DD"]),
                cancellationReason: cancellation?.reason || "",
                terminationDate: !termination?.date
                  ? ""
                  : formatDate(termination?.date, DATE_FORMATS["YYYY-MM-DD"]),
                terminationReason: termination?.reason || "",
              }));
            } else {
              toast.info(
                "Franchise Agreement not found. Please create franchise agreement",
                { autoClose: 2000 }
              );
            }

            setStatuses((prevVal) => ({
              ...prevVal,
              storeStatus: statuses?.status || "",
              leaseStatus: statuses?.lease || "",
            }));
            setAllFields((prevVal) => ({
              ...prevVal,
              locationType: store?.locationType,
              taxID: store?.gst,
              currency: store?.currency,
            }));
            setbuttonStatus(buttonStatuses.save);
          } else {
            if (response?.message === "FranchiseAgreement not found") {
              resetAllDetails(false, true);
              // setbuttonStatus(buttonStatuses.create);
              toast.info("Please add franchise details for sequence number", {
                autoClose: 2000,
              });
            } else {
              setbuttonStatus(buttonStatuses.save);
              toast.error(response?.message, { autoClose: 2000 });
            }
          }
        })
        .catch((error) => {
          setbuttonStatus(buttonStatuses.save);
          // resetAllDetails(false, true);
          toast.error("Sorry something went wrong", { autoClose: 2000 });
        });
    }
  };

  useEffect(() => {
    if (allFields.expiry) {
      let date = moment(allFields.expiry, "YYYY-MM-DD");
      let agreementDate = date.subtract(240, "months").format("YYYY-MM-DD");
      setAllFields((prevVal) => ({ ...prevVal, agreement: agreementDate }));
    }
  }, [allFields.expiry]);

  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };

  const setValuesOnChange = (e) => {
    const { name, value } = e.target;
    setAllFields((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  const setStoreDetailsOnChange = (e) => {
    const { name, value } = e.target;
    setStoreInfo((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const zeroFieldValidation = (value) => {
    if (value === "0" || value === 0) {
      return false;
    }
    return !value;
  };

  const checkStoreDetailsForErrors = (showToast) => {
    let storeNumber = storeInfo.storeNo;
    let satelliteNo = storeInfo.satelliteNo;
    let sequenceNumber = storeInfo.sequenceNo;

    if (!Number.isInteger(parseInt(storeNumber))) {
      if (showToast) {
        toast.error("Please select store number", { autoClose: 2000 });
      }

      return true;
    }
    if (!Number.isInteger(parseInt(satelliteNo))) {
      if (showToast) {
        toast.error("Please select satellite number number", {
          autoClose: 2000,
        });
      }

      return true;
    }
    if (!Number.isInteger(parseInt(sequenceNumber))) {
      if (showToast) {
        toast.error("Please select sequence number", { autoClose: 2000 });
      }
      return true;
    }
    return false;
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.storeNo,
          satelliteNo: p.satelliteNo,
          sequenceNo: p.sequenceNo,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    setStoreInfo({
      storeNo: value ? value : "",
      satelliteNo: null,
      sequenceNo: null,
    });
    if (value) {
      getData(value);
    } else {
      resetAllDetails(true, true);
    }
  };

  const getCreateUpdatePayloadAndId = () => {
    const {
      id,
      developerContract,
      franchisor,
      businessName,
      fee,
      paymentDate,
      sequenceType,
      country,
      drinkOption,
      paymentProgram,
      amendAgreement,
      afLanguage,
      franchiseXref,
      pledgeForm,
      disclosure,
      ofac,
      agreement,
      termsInMonths,
      expiry,
      renewal,
      amended,
      assigned,
      reusable,
      slrExpiry,
      status,
      cancellationDate,
      cancellationReason,
      terminationDate,
      terminationReason,
    } = allFields;
    let payload = {
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    if (!!developerContract) {
      payload.developerContract = developerContract;
    }
    if (!!franchisor) {
      payload.franchisor = franchisor;
    }
    if (!!sequenceType) {
      payload.sequenceType = sequenceType;
    }
    if (!!businessName) {
      payload.businessName = businessName;
    }
    if (!!fee) {
      payload.fee = fee;
    }
    if (!!paymentDate) {
      payload.paymentDate = paymentDate;
    }
    if (!!country) {
      payload.country = country;
    }
    if (!!drinkOption) {
      payload.drinkOption = drinkOption;
    }
    if (!!paymentProgram) {
      payload.paymentProgram = paymentProgram;
    }
    if (!!status) {
      payload.status = status;
    }
    payload.amendAgreement = amendAgreement;

    payload.afLanguage = afLanguage;

    if (!!franchiseXref) {
      payload.franchiseXref = franchiseXref;
    }

    payload.pledgeForm = pledgeForm;

    if (!!disclosure) {
      payload.disclosure = disclosure;
    }
    if (!!ofac) {
      payload.ofac = ofac;
    }
    if (!!agreement) {
      payload.agreement = agreement;
    }
    if (!!termsInMonths) {
      payload.termsInMonths = termsInMonths;
    }
    if (!!expiry) {
      payload.expiry = expiry;
    }
    if (!!renewal) {
      payload.renewal = renewal;
    }
    if (!!amended) {
      payload.amended = amended;
    }
    if (!!assigned) {
      payload.assigned = assigned;
    }
    if (!!reusable) {
      payload.reusable = reusable;
    }
    if (!!slrExpiry) {
      payload.slrExpiry = slrExpiry;
    }
    if (!!terminationDate || !!terminationReason) {
      payload.termination = {};
    }

    if (!!terminationDate) {
      payload.termination = { date: terminationDate };
    }
    if (!!terminationReason) {
      payload.termination = {
        ...payload.termination,
        reason: terminationReason,
      };
    }
    if (!!cancellationDate || !!cancellationReason) {
      payload.cancellation = {};
    }
    if (!!cancellationDate) {
      payload.cancellation = { date: cancellationDate };
    }
    if (!!cancellationReason) {
      payload.cancellation = {
        ...payload.cancellation,
        reason: cancellationReason,
      };
    }

    return [payload, id];
  };

  const updateFranchiseAgreementDetails = () => {
    const storeDetailsErros = checkStoreDetailsForErrors();
    if (storeDetailsErros) {
      return;
    }

    const [payload] = getCreateUpdatePayloadAndId();
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.FRANCHISE_AGREEMENT_BASE_URL}${storeInfo.storeNo}${REST_URLS.UPDATE_FRANCHISE_AGREEMENT}`,
      payload,
      null
    )
      .then((response) => {
        if (response && !response.message) {
          toast.success("Franchise Agreement updated successfully", {
            autoClose: 2000,
          });
          resetAllDetails(false, false, null, true);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast.error(error?.message, { autoClose: false });
      });
  };

  const createNewFranchiseAgreement = () => {
    const storeDetailsErros = checkStoreDetailsForErrors();
    if (storeDetailsErros) {
      return;
    }

    const [payload] = getCreateUpdatePayloadAndId();

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.FRANCHISE_AGREEMENT_BASE_URL}${storeInfo.storeNo}${REST_URLS.CREATE_FRANCHISE_AGREEMENT}`,
      payload,
      null
    )
      .then((response) => {
        if (response && !response.message) {
          resetAllDetails(false, false, null, true);
          toast.success("Franchise Agreement created successfully", {
            autoClose: 2000,
          });
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast.error(error?.message, { autoClose: false });
      });
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getFranchiseDetailsForStoreNumberAndSequence(
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
      getSequenceNumberForStoreAndSatelliteNumber(storeInfo.storeNo);
    }
  }, []);

  const getSequenceNumberForStoreAndSatelliteNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="mainFranchiseDetails">
      <Grid container spacing={1}>
        <Grid md={3}>
          <div style={displayContainer}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              className="w-100 subs-autocomplete"
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    resetAllDetails(true, true, null, true);
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  const { storeNo } = val;
                  resetAllDetails(
                    false,
                    true,
                    { satelliteNo: null, sequenceNo: null, storeNo: storeNo },
                    true
                  );
                  getSequenceNumberForStoreAndSatelliteNumber(storeNo);
                }
              }}
            />

            {/* <IconButton
              onClick={() => {
                getDataForStoreNumberAndSequenceNumber(true);
              }}
            >
              <SearchIcon color="primary" size="large" fontSize="60" />
            </IconButton> */}
          </div>
        </Grid>
        <Grid md={3}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevDetails) => ({
                ...prevDetails,
                satelliteNo: val?.value,
              }));

              if (buttonStatus === buttonStatuses.save) {
                getFranchiseDetailsForStoreNumberAndSequence(
                  val?.value,
                  storeInfo.sequenceNo
                );
              }
            }}
          />
        </Grid>

        <Grid md={3}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevDetails) => ({
                ...prevDetails,
                sequenceNo: val?.value,
              }));
              if (buttonStatus === buttonStatuses.save) {
                getFranchiseDetailsForStoreNumberAndSequence(
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </Grid>

        <Grid md={3}>
          <div className="d-flex flex-end">
            {buttonStatus === buttonStatuses.save && (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setbuttonStatus(buttonStatuses.create);
                    resetAllDetails(false, false);
                  }}
                  className="franchise-agreement-DetailButton-active"
                  startIcon={<FileCopyIcon />}
                >
                  Create
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    excelDownload(
                      "franchise_agreement_details_" + storeInfo.storeNo,
                      respData
                    );
                  }}
                  disabled={respData === null}
                  startIcon={
                    <DownloadRoundedIcon
                      style={{ fontSize: "20px", marginRight: "-5px" }}
                    />
                  }
                >
                  Download
                </Button>
              </div>
            )}
          </div>
        </Grid>
        <Grid xs={12}>
          <Divider style={{ paddingBottom: 10, marginBottom: 10 }} />
        </Grid>
        <Grid container xs={3}>
          <TextField
            value={allFields.developerContract}
            name="developerContract"
            type="number"
            onChange={setValuesOnChange}
            label="Developer#"
            className="w-75 subs-text-field"
          />
          {/* <div style={displayContainer}> */}

          {/* <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>

            <div>SSSIPL/INDIA</div> */}
          {/* </div> */}
        </Grid>
        <Grid container xs={3}>
          <TextField
            value={allFields.sequenceType}
            name="sequenceType"
            onChange={setValuesOnChange}
            label="Sequence Type"
            className="w-75 subs-text-field"
          />
        </Grid>

        <Grid container xs={3}>
          <TextField
            value={allFields.franchisor}
            name="franchisor"
            onChange={setValuesOnChange}
            label="Franchisor"
            className="w-75 subs-text-field"
          />
        </Grid>
        <Grid container xs={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Country"
            className="w-75"
            getOptionLabel={(op) => op.label}
            getOptionValue={(op) => op.value}
            options={countrySelectValues}
            name="country"
            value={
              !allFields.country
                ? null
                : { label: allFields.country, value: allFields.country }
            }
            onChange={(e, fields) => {
              const { name } = fields;
              const value = e?.value || "";
              setValuesOnChange({ target: { name, value } });
            }}
          ></Select>
        </Grid>

        <Divider style={{ paddingBottom: 10 }} />
        <Grid xs={3.8} container spacing={1}>
          <div className="title">Statuses</div>
          <Grid container xs={12}>
            <Grid xs={6}>
              <TextField
                label="Agreement"
                className="w-75 subs-text-field"
                name="status"
                value={allFields.status}
                onChange={setValuesOnChange}
              />
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Store"
                  className="w-75 subs-text-field"
                  disabled
                  value={statuses.storeStatus}
                  onChange={setValuesOnChange}
                />

                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                  />
                </IconButton> */}
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Lease"
                  className="w-75 subs-text-field"
                  disabled
                  value={statuses.leaseStatus || ""}
                  onChange={setValuesOnChange}
                />

                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                  />
                </IconButton> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1} xs={12}>
            <Grid xs={6}>
              <TextField
                value={allFields.businessName}
                name="businessName"
                onChange={setValuesOnChange}
                label="Business Name"
                className="w-75 subs-text-field"
              />
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="GST"
                  value={allFields.taxID}
                  name="gst"
                  disabled
                  // onChange={setValuesOnChange}
                  className="w-75 subs-text-field"
                />
                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                    className=" gray"
                  />
                </IconButton> */}
              </div>
            </Grid>

            <Grid xs={6}>
              <TextField
                value={allFields.fee}
                name="fee"
                onChange={setValuesOnChange}
                label="Fee"
                className="w-75 subs-text-field"
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                value={allFields.currency}
                name="currency"
                disabled
                // onChange={setValuesOnChange}
                label="Currency"
                className="w-75 subs-text-field"
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Payment Date"
                type="date"
                value={dateFun(allFields.paymentDate) || ""}
                name="paymentDate"
                onChange={setValuesOnChange}
                className="w-75 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Location Type"
                value={allFields.locationType}
                className="w-75 subs-text-field"
                disabled
              />
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Payment Program"
                  className="w-75 subs-text-field"
                  value={allFields.paymentProgram}
                  name="paymentProgram"
                  onChange={setValuesOnChange}
                />
                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                    className="gray"
                  />
                </IconButton> */}
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  value={allFields.drinkOption}
                  onChange={setValuesOnChange}
                  name="drinkOption"
                  label="Drink Options"
                  className="w-75 subs-text-field"
                />
                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                    className="gray"
                  />
                </IconButton> */}
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                Amend Agreement
                <Checkbox
                  name="amendAgreement"
                  checked={allFields.amendAgreement}
                  onChange={() => {
                    setAllFields((prevVal) => ({
                      ...prevVal,
                      amendAgreement: !allFields.amendAgreement,
                    }));
                  }}
                ></Checkbox>
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                AF Language
                <Checkbox
                  checked={allFields.afLanguage}
                  onChange={(e) => {
                    setAllFields((prevFields) => ({
                      ...prevFields,
                      afLanguage: !allFields.afLanguage,
                    }));
                  }}
                ></Checkbox>
              </div>
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Franchise Xref"
                value={allFields.franchiseXref}
                onChange={setValuesOnChange}
                name="franchiseXref"
                className="w-75 subs-text-field"
              />
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                Pledge Form
                <Checkbox
                  checked={allFields.pledgeForm}
                  onChange={() => {
                    setAllFields((prevVal) => ({
                      ...prevVal,
                      pledgeForm: !allFields.pledgeForm,
                    }));
                  }}
                ></Checkbox>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="grid-with-0-height" xs={3.8} container spacing={1}>
          <div className="title">Related Dates/ Time Periods</div>

          <Grid container xs={12}>
            <Grid xs={5}>
              <TextField
                label="Disclosure"
                type="date"
                value={dateFun(allFields.disclosure) || ""}
                name="disclosure"
                onChange={setValuesOnChange}
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid xs={1}></Grid>

            <Grid xs={5}>
              <TextField
                label="OFAC"
                value={dateFun(allFields.ofac) || ""}
                name="ofac"
                onChange={setValuesOnChange}
                type="date"
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid xs={5}>
              <TextField
                label="Agreement"
                value={dateFun(allFields.agreement) || ""}
                name="agreement"
                onChange={setValuesOnChange}
                type="date"
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={5}>
              <TextField
                type="number"
                value={allFields.termsInMonths}
                name="termsInMonths"
                onChange={setValuesOnChange}
                label="Term in months"
                className="w-100 subs-text-field"
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={5}>
              <TextField
                label="Expiration"
                type="date"
                value={dateFun(allFields.expiry) || ""}
                name="expiry"
                onChange={setValuesOnChange}
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid xs={1}>
              <Checkbox />
            </Grid>

            <Grid xs={5}>
              <TextField
                name="renewal"
                value={allFields.renewal}
                onChange={setValuesOnChange}
                label="Renewal"
                type="number"
                className="w-100 subs-text-field"
              />
            </Grid>

            <Grid xs={5}>
              <TextField
                label="Agree Assigned"
                value={dateFun(allFields.assigned) || ""}
                name="assigned"
                onChange={setValuesOnChange}
                type="date"
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={1}></Grid>

            <Grid xs={5}>
              <TextField
                label="Amended"
                type="date"
                name="amended"
                value={dateFun(allFields.amended) || ""}
                onChange={setValuesOnChange}
                className="w-100 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={1}>
              <div className="d-flex">
                {/* <IconButton>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                    className="gray"
                  />
                </IconButton> */}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="grid-with-0-height" xs={3.8} container spacing={1}>
          <div className="title">Termination</div>

          <Grid container xs={12}>
            <Grid xs={6}>
              <TextField
                label="Date"
                type="date"
                value={dateFun(allFields.terminationDate) || ""}
                name="terminationDate"
                onChange={setValuesOnChange}
                className="w-75 subs-text-field"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Reason"
                value={allFields.terminationReason}
                name="terminationReason"
                onChange={setValuesOnChange}
                className="w-75 subs-text-field"
              />
            </Grid>

            <div className="title">Cancellation</div>
            <Grid xs={12} container spacing={1}>
              <Grid xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  className="w-75 subs-text-field"
                  InputLabelProps={{ shrink: true }}
                  value={dateFun(allFields.cancellationDate) || ""}
                  name="cancellationDate"
                  onChange={setValuesOnChange}
                />
              </Grid>

              <Grid xs={6}>
                <div style={displayContainer}>
                  Override:{" "}
                  <Checkbox
                    name="override"
                    checked={allFields.override}
                    onChange={() => {
                      setAllFields((prevVal) => ({
                        ...prevVal,
                        override: !allFields.override,
                      }));
                    }}
                  />
                </div>
              </Grid>

              <Grid xs={12}>
                <div style={displayContainer}>
                  <TextField
                    label="Reason"
                    value={allFields.cancellationReason}
                    name="cancellationReason"
                    onChange={setValuesOnChange}
                    className="w-75 subs-text-field"
                  />
                  <Checkbox />
                  {/* <IconButton>
                    <ArrowCircleRightIcon
                      color="primary"
                      size="large"
                      fontSize="60"
                      className="gray"
                    />
                  </IconButton> */}
                </div>
              </Grid>
            </Grid>

            <Grid xs={12} container spacing={1}>
              <Grid xs={6}>
                <TextField
                  label="Reusable"
                  type="date"
                  value={dateFun(allFields.reusable) || ""}
                  name="reusable"
                  onChange={setValuesOnChange}
                  className="w-75 subs-text-field"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid xs={6}>
                <TextField
                  label="SLR Expiration"
                  type="date"
                  value={dateFun(allFields.slrExpiry) || ""}
                  name="slrExpiry"
                  onChange={setValuesOnChange}
                  className="w-75 subs-text-field"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ width: "100%" }} className="d-flex flex-end">
          {buttonStatus !== buttonStatuses.create && (
            <Button
              className="franchise-agreement-DetailButton-active"
              startIcon={<SyncIcon />}
              onClick={() => {
                resetAllDetails(true, true, null, true);
              }}
            >
              Reset
            </Button>
          )}

          {buttonStatus === buttonStatuses.create && (
            <Button
              className="franchise-agreement-DetailButton-active"
              startIcon={<SyncIcon />}
              onClick={() => {
                resetAllDetails(false, false, null, true);
                setbuttonStatus(buttonStatuses.save);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={() => {
              if (buttonStatus === buttonStatuses.save) {
                updateFranchiseAgreementDetails();
              }
              if (buttonStatus === buttonStatuses.create) {
                createNewFranchiseAgreement();
              }
            }}
            className={`${
              !checkStoreDetailsForErrors()
                ? "franchise-agreement-DetailButton-active"
                : "franchise-agreement-DetailButton"
            }`}
            disabled={checkStoreDetailsForErrors()}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      </Grid>
    </div>
  );
};
export default Transactions;
