import { TextField, Button } from '@mui/material';
import CustomModal from '../../../../../common-utilities/core/modal';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { createFinanceRole } from '../apis';
const FinanceRole = ({ showModal, title, onClose, onSuccess }) => {
  const [modalData, setModalData] = useState({});
  useEffect(() => {
    if (!showModal) {
      setModalData({});
      return;
    }
  }, [showModal]);
  const onInputChange = (e) => {
    setModalData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <>
      {showModal && (
        <CustomModal title={title} onClose={onClose}>
          <TextField
            label="Name"
            name="name"
            value={modalData?.name || ''}
            className="new-textfield"
            onChange={onInputChange}
            fullWidth
            size="small"
          />
          <div className="common-btn-ctr">
            <div>
              <Button
                variant="contained"
                className="common-modal-crt-btn"
                onClick={() => {
                  createFinanceRole(modalData, onSuccess);
                }}
                fullWidth
                startIcon={<SaveIcon />}
                disabled={!modalData?.name}
                sx={{ mt: 3 }}
              >
                Save
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default FinanceRole;
