/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import { userTypes } from "../../utils/constants";
import { getHeaderConfig, getMobileHeaderConfig } from "./config";
import Button from "@mui/material/Button";
import { selectStoreType } from "../Store/config";
import CustomModal from "../../../common-utilities/core/modal";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";

const Department = () => {
  const [store, setStore] = useState({});
  const [addDepartment, setAddDepartment] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [showCreateDepartmentOption, setshowCreateDepartmentOption] =
    useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const navigate = useNavigate();
  useEffect(() => {
    const userCredentials = getOfflineData(tokenMainKeys.technet, "user");
    if (!userCredentials) {
      navigate("/login");
    } else {
      const { type } = userCredentials;
      if (type === userTypes.STORE_OWNER) {
        setshowCreateDepartmentOption(false);
      } else {
        setshowCreateDepartmentOption(true);
      }
      loadData(filters);
    }
  }, [navigate, filters]);
  const addDepartmentFormHandler = () => {
    setAddDepartment(true);
  };

  const addNameHandler = (event) => {
    setName(event.target.value);
  };
  const addStoreTypeHandler = (event) => {
    setType(event);
  };
  const payload = {
    allowedStoreTypes: type.map((item) => item.value),
    name: name,
  };

  //Add New Department
  const formSubmitHandler = () => {
    if (!name?.trim() || type.length === 0) {
      toast.error("Empty Fields Not Allowed", { autoClose: 2000 });
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_DEPARTMENT}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message);
          } else {
            toast.success("Department Created Successfully");
            loadData(filters);
            setName("");
            setType([]);
            setAddDepartment(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //get departments
  const loadData = (params) => {
    const filterParams =
      params && typeof params === "object" ? { ...params } : { ...filters };

    if (!filterParams.page) {
      filterParams.page = filters.page;
    }
    if (!filterParams.limit) {
      filterParams.limit = filters.limit;
    }
    if (!filterParams.sortBy) {
      filterParams.sortBy = filters.sortBy;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_DEPARTMENT}`,
      null,
      filterParams
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        if (response) {
          setStore(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="department-container">
        <div className="button-div">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by department name"
              onChange={(e) => setDepartmentName(e?.target?.value)}
              value={departmentName}
              size="small"
              className="new-textfield"
            />

            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  departmentName,
                }));
              }}
            />
          </div>
          {showCreateDepartmentOption && (
            <Button
              variant="contained"
              className="button"
              onClick={addDepartmentFormHandler}
            >
              Create Department
            </Button>
          )}
        </div>
        <MuiTable
          columnsList={getHeaderConfig(loadData)}
          dataList={store.results || []}
          filters={filters}
          pageCount={store.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData({
              ...filters,
              page,
            });
          }}
        ></MuiTable>
        {/* <SiTable
            header={getHeaderConfig(loadData)}
            data={store.results || []}
            pageCount={store.totalPages}
            filters={filters}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable> */}
      </div>
      {addDepartment && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title="New Department"
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setAddDepartment(false);
            setName("");
            setType([]);
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="name"
              label="Name"
              required
              multiline
              sx={{ my: 1 }}
              onChange={addNameHandler}
              value={name}
              size="small"
              className="new-textfield"
            />
            <AutoCompleteMultiSelect
              value={type}
              options={selectStoreType}
              onChange={(e, val) => {
                addStoreTypeHandler(val);
              }}
              placeholder="Type"
            />

            <Stack
              alignItems="flex-end"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
              sx={{ my: 2 }}
            >
              <CommonBtn
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                fullWidth
              >
                Create
              </CommonBtn>
              <CommonBtn
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAddDepartment(false);
                  setName("");
                  setType([]);
                }}
                fullWidth
              >
                Cancel
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default Department;
