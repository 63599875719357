import { Grid } from '@mui/material';
import AutocompleteComponent from '../../../../common-utilities/core/styled-autocomplete';
import { storeList } from '../../../utils/constants';
import {
  setProperty,
  clearProperty,
} from '../../../../common-utilities/utils/misc';

const CommonFilters = ({ filters, setFilters }) => {
  return (
    <div className="approval-container">
      <Grid sx={12} container spacing={0}>
        <Grid item md={4}>
          <AutocompleteComponent
            label="Select Type"
            options={storeList}
            onChange={(e, value) => {
              if (value) {
                setProperty('storeType', value?.value, setFilters);
              } else {
                clearProperty('storeType', setFilters);
              }
            }}
            fullWidth={true}
            value={storeList.find((item) => item.value === filters.storeType)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default CommonFilters;
