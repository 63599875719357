export const DOCUMENT_TYPES = [
  {
    label: "Raw Material",
    value: "RAW_MATERIAL",
  },
  {
    label: "Electricity",
    value: "ELECTRICITY",
  },
  {
    label: "Water",
    value: "WATER",
  },
  {
    label: "Rent",
    value: "RENT",
  },
  {
    label: "Cam",
    value: "CAM",
  },
  {
    label: "Others",
    value: "OTHER",
  },
];

export const NEW_VENDOR = "NEW_VENDOR";

export const userStates = {
  isProcessing: "Checking",
};

export const RM_APPROVE_REQUIRED = ["ELECTRICITY", "WATER", "OTHER"];

export const INVOICE_TRACKING_USERS = {
  RM: "RM",
  AM: "AM",
  STORE: "STORE",
  FINANCE_USER: "FINANCE_USER",
};

export const VENDOR_INVOICE_SUBMISSION_ROLES = {
  VENDOR: "VENDOR",
  FINANCE_USER: "FINANCE_USER",
};

export const vendorInvoicePaymentStatuses = {
  Pending:"pending",
  Paid:"paid",
}

export const FINANCE_USER_ID = 1007;
