import TableCell from "@mui/material/TableCell";
export const getHeaderConfig = () => {
  return [
    {
      label: "App",
      id: "name",
    },
    {
      label: "Roles",
      id: "roles",
      render: (data, onClick) => {
        const dataLen = data?.roles?.length;
        return (
          <ul style={{ width: "20px", marginLeft: "35%" }}>
            {data?.roles?.map((ele, index) => (
              <li>{ele}</li>
            ))}
          </ul>
        );
      },
    },
  ];
};
