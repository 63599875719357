/** @format */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import "./index.scss";
import { formatDate } from "../../../common-utilities/utils";
import { TICKET_STAGES } from "../../utils/constants";
import { Grid } from "@mui/material";
import TimeLine from "../../../common-utilities/core/time-line";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import LargeTextHandler from "../../../common-utilities/core/large-text";

const TimeLineInfo = ({ ticketDetails, openForm, loadData, timeline }) => {
  const { id } = useParams();
  const [modalDetails, setModalDetils] = useState(null);
  const updateDateOfInspection = (date) => {
    let payload = {
      dateOfInspection: new Date(date).toISOString(),
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.TICKETS}/${id}`,
      payload
    )
      .then(() => {
        loadData();
      })
      .catch((err) => console.log(err));
  };

  const getTimeLine = (timelineDetails, index) => {
    const {
      stage,
      stageDetails,
      createdAt,
      description,
      comment,
      ticket,
      attachments,
      tags,
    } = timelineDetails;
    let stageDetailsObj = {};

    try {
      let assignedToRole = "";
      if (ticket && ticket.assignedTo && ticket.assignedTo.type) {
        ticket.assignedTo.type.split("_").forEach((item) => {
          assignedToRole +=
            item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase() + " ";
        });
      }

      stageDetailsObj = {
        ...JSON.parse(stageDetails),
        createdAt,
        comment,
        commentdescription: description,
        assignedToRole: assignedToRole,
      };

      stageDetailsObj.attachmentClass = "";

      stageDetailsObj.attachments = attachments;
      if (
        stage === TICKET_STAGES.COMPLAINT_FORM_SUBMITTED &&
        stageDetailsObj.attachments &&
        stageDetailsObj.attachments.length > 0
      ) {
        stageDetailsObj.attachmentClass = "attachmentColor";
        switch (attachments.length) {
          case 1:
            stageDetailsObj.attachmentClass += " one-attachment";
            break;
          case 2:
            stageDetailsObj.attachmentClass += " two-attachment";
            break;
          case 3:
            stageDetailsObj.attachmentClass += " three-attachment";
            break;
          case 4:
            stageDetailsObj.attachmentClass += " four-attachment";
            break;
          case 5:
            stageDetailsObj.attachmentClass += " five-attachment";
            break;

          default:
            break;
        }
      }
    } catch (err) {}

    switch (stage) {
      case TICKET_STAGES.COMPLAINT_FORM_SUBMITTED:
        return (
          <TimeLine
            className={`${stageDetailsObj?.attachmentClass}`}
            displayLine={index !== timeline.length - 1}
            key={index}
          >
            <Grid container md={12} sx={12}>
              <Grid item md={12} sm={12} xl={12}>
                <span className="line1">
                  Created on{" "}
                  {formatDate(
                    stageDetailsObj.createdAt,
                    DATE_FORMATS["DD/MM/YYYY hh:MM"]
                  )}{" "}
                </span>
              </Grid>
              <Grid item md={12} sm={12} xl={12}>
                <div className="line2 clickable" onClick={openForm}>
                  View form
                </div>
              </Grid>
              <Grid item md={12} sm={12} xl={12}>
                {stageDetailsObj.attachments &&
                  Array.isArray(stageDetailsObj.attachments) && (
                    <>
                      {stageDetailsObj.attachments.map((item, index) => {
                        return (
                          <div key={index}>
                            <span className="line1">Attachment</span>
                            <div className="line2">
                              <a href={item} target="_blank">
                                Open attachment
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
              </Grid>
            </Grid>
          </TimeLine>
        );
      case TICKET_STAGES.ASSIGN_SE:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">
              {stageDetailsObj.description} ({stageDetailsObj.assignedToRole})
            </span>
            <div className="line2">{stageDetailsObj.name || "-"}</div>
          </TimeLine>
        );

      case TICKET_STAGES.COMMENT:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">
              {stageDetailsObj.commentdescription || "-"}
            </span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(
                stageDetailsObj.createdAt,
                DATE_FORMATS["DD/MM/YYYY hh:MM"]
              )}
            </span>
            <div className="line2">{stageDetailsObj.comment || "-"}</div>
          </TimeLine>
        );

      case TICKET_STAGES.ATTACHMENT:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">Attachment</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">
              <a href={stageDetailsObj.url} target="_blank" className="line2">
                Open attachment
              </a>
            </div>
          </TimeLine>
        );
      case TICKET_STAGES.SEVERITY:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">Severity Update</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">{stageDetails}</div>
          </TimeLine>
        );
      case TICKET_STAGES.STATUS_UPDATE:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">Status Update</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">
              {stageDetails}{" "}
              {tags?.length > 0 && (
                <>
                  <LargeTextHandler
                    showTruncatedWords={false}
                    readMoreText="Click to view tags "
                    showMoreClass="showTags"
                    bodyClass="tag-modal-body"
                    maxWords={1}
                    text={`${tags.map((item) => item.name).join(", ")}`}
                  />
                </>
              )}
            </div>
          </TimeLine>
        );
      case TICKET_STAGES.REASSIGN:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">Reassign Update</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">{stageDetails}</div>
          </TimeLine>
        );

      case TICKET_STAGES.HOLD_ESCALATIONS:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">Escalations Update</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">{stageDetails}</div>
          </TimeLine>
        );
      case TICKET_STAGES.TICKET_ESCALATION:
        return (
          <TimeLine displayLine={index !== timeline.length - 1} key={index}>
            <span className="line1">{stageDetailsObj?.reason}</span>
            <span style={{ color: "gray" }}>
              {" "}
              - {"   "}
              {formatDate(createdAt, DATE_FORMATS["DD/MM/YYYY hh:MM"])}
            </span>
            <div className="line2">{stageDetailsObj?.description || "-"}</div>
          </TimeLine>
        );

      default:
        return null;
    }
  };

  const onDateChange = (event, b) => {
    if (compareDates(event)) {
      return;
    }
    updateDateOfInspection(event);
  };

  const compareDates = (date) => {
    if (ticketDetails.dateOfInspection) {
      let date1 = formatDate(
        ticketDetails.dateOfInspection,
        DATE_FORMATS["MMM DD YYYY hh:MM"]
      );
      let date2 = formatDate(date, DATE_FORMATS["MMM DD YYYY hh:MM"]);
      return date1 === date2 || !date;
    }
    return false;
  };
  return (
    <div className="time-line-info-container">
      <div className="inspection-date">
        <span>Timeline</span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            inputFormat="dd/MM/yyyy"
            value={ticketDetails.dateOfInspection || null}
            onChange={(a, b, c) => {
              console.log(a, b, c);
            }}
            minDate={new Date()}
            onClose={(event) => {}}
            onAccept={(event) => onDateChange(event, "accept")}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </div>
      <div className="time-line">
        {timeline.map((timelineDetails, index) =>
          getTimeLine(timelineDetails, index)
        )}
      </div>
    </div>
  );
};

export default TimeLineInfo;
