import { BD_LEAD_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../../common-utilities/utils/end-points/bd-lead";
import { invokeApi, HTTP_METHODS } from "../../../utils/http-service";

export const getFieldList = (fieldName, fieldValue, tokenKey, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${BD_LEAD_HOSTNAME}${REST_URLS.FIELDLIST}`,
    null,
    {
      fieldName,
      fieldValue,
    },
    null,
    tokenKey
  )
    .then((res) => {
      setDataFunc && setDataFunc(res);
    })
    .catch((err) => {
      setDataFunc && setDataFunc([]);
    });
};
