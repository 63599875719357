import React, { useEffect, useState } from 'react';
import MuiTable from '../../../../common-utilities/core/mui-table';
import './index.scss';
import ConfirmModal from '../../../../common-utilities/core/confirm-modal';
import { deleteForeignCurrency, queryForeignCurrencies } from './apis';
import HeaderSection from './components/header-section';
import { getHeaderConfig } from './config';
import { modalActions } from './utils';
import CreateUpdateModal from './components/modal.js';

const ForeignCurrency = () => {
  const [modalDetails, setModalDetails] = useState({});
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });
  const closeModal = () => {
    setModalDetails({});
  };

  const modalSuccess = () => {
    queryForeignCurrencies(filters, setTableData, false, setFilters);
    closeModal();
  };

  useEffect(() => {
    queryForeignCurrencies(filters, setTableData);
  }, []);

  return (
    <div className="foreign-currency-list">
      <HeaderSection
        filters={filters}
        setFilters={setFilters}
        setTableData={setTableData}
        tableData={tableData}
        setModalDetails={setModalDetails}
      />
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onClick={(data, action) => {
          modalActions(data, action, setModalDetails);
        }}
        onChange={() => {
          queryForeignCurrencies(
            {
              ...filters,
              page: 1,
            },
            setTableData
          );
        }}
      />
      {modalDetails?.type === 'DELETE' && (
        <ConfirmModal
          onYes={() => {
            deleteForeignCurrency(modalDetails?.data?.id, () => {
              modalSuccess();
            });
          }}
          onNo={closeModal}
        />
      )}
      <CreateUpdateModal
        showModal={
          modalDetails?.type === 'CREATE' || modalDetails?.type === 'EDIT'
        }
        title={modalDetails?.title}
        closeModal={closeModal}
        data={modalDetails?.data}
        onSuccess={modalSuccess}
      />
    </div>
  );
};

export default ForeignCurrency;
