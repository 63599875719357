import { useEffect, useState } from "react";
import "./index.scss";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { actionHandler, redirect } from "./utils";
import MuiTable from "../../packages/common-utilities/core/mui-table";
import { actions, mainTableConfig } from "./config";
import { getDepartmentsData } from "./apis";
import { ViewDepartmentRoles } from "./modals";
import CreateUpdateDepartment from "./create-update-department";
const DepartmentSection = () => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    redirect(navigate); // redirect if the user is not not admin
    getDepartmentsData(filters, setTableData);
  }, []);

  const [modalDetails, setModalDetails] = useState(null);

  const closeModal = () => {
    setModalDetails(null);
  };
  return (
    <>
      {modalDetails?.type !== actions.createDepartment &&
        modalDetails?.type !== actions.editDepartments && (
          <div className="department-container">
            <Grid conatiner md={12} sx={{ display: "flex", mb: 3 }}>
              <Grid container md={9}>
                <p className="title-header">Departments</p>
              </Grid>
              <Grid
                container
                md={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    variant="contained"
                    className="rounded-btn"
                    onClick={() => {
                      actionHandler(
                        null,
                        actions.createDepartment,
                        setModalDetails
                      );
                    }}
                  >
                    Create Department
                  </Button>
                </div>
              </Grid>
            </Grid>
            <MuiTable
              columnsList={mainTableConfig()}
              dataList={tableData.results || []}
              filters={filters}
              pageCount={tableData?.totalPages}
              onClick={(data, type) => {
                actionHandler(data, type, setModalDetails);
              }}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
                getDepartmentsData(
                  {
                    ...filters,
                    page,
                  },
                  setTableData
                );
              }}
            ></MuiTable>

            <ViewDepartmentRoles
              showModal={modalDetails?.type === actions.viewRoles}
              closeModal={closeModal}
              data={modalDetails?.data}
            />
          </div>
        )}

      <CreateUpdateDepartment
        showModal={
          modalDetails?.type === actions.createDepartment ||
          modalDetails?.type === actions.editDepartments
        }
        data={modalDetails?.data}
        mainTitle={modalDetails?.title}
        closeModal={closeModal}
        onSuccess={() => {
          getDepartmentsData(filters, setTableData);
          closeModal();
        }}
      />
    </>
  );
};

export default DepartmentSection;
