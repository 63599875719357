import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import Chart from 'react-apexcharts';
import './index.scss';
import { IconButton } from '@mui/material';
import { exportToExcel } from '../../utils';

const CustomLineChart = ({ title, data = [], xDataKey, categories }) => {
  const width = window.innerWidth > 420 ? 500 : 300;
  const state = {
    zoom: {
      enabled: true,
    },
    series: data,
    options: {
      chart: {
        height: 'auto',
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F',
          },
          selection: {
            background: '#90CAF9',
            border: '#0D47A1',
          },
        },
      },
      colors: ['#4FC2F7', 'red', 'blue', 'green'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: '',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories || [],
      },
    },
  };

  return (
    <div className='over-view-line-chart'>
      <div className='title'>
        {title}
        <IconButton
          disabled={data.length === 0}
          onClick={() => {
            let obj = {};
            data[0]?.data.forEach((iter, index) => {
              obj[categories[index]] = iter;
            });
            exportToExcel([obj], xDataKey);
          }}
        >
          <DownloadIcon />
        </IconButton>
      </div>
      {data.length > 0 ? (
        <div>
          <Chart
            options={state.options}
            series={state.series}
            type='area'
            height='360px'
          />
        </div>
      ) : (
        <div
          style={{
            width: `${width}px`,
          }}
        >
          No data to display
        </div>
      )}
    </div>
  );
};

export default CustomLineChart;
