import { pick } from "../../../../common-utilities/utils";

export const cocoAcquiredData = (results) => {
  return (
    results?.map?.((item) => {
      const mainKeys = pick(item, [
        "id",
        "storeType",
        "restaurantId",
        "totalCapex",
        "state",
        "city",
        "cluster",
        "format",
        "ads",
        "remarks",
      ]);

      const businessDetails = pick(item?.businessDetails || {}, [
        "openingDate",
        "location",
        "franchisee",
        "initialOpenDate",
        "decorType",
        "remodelDueDate",
        "carpetAreaSqft",
        "leaseType",
        "leaseStartDate",
        "leaseEndDate",
        "grossOfferExtendedA",
        "remodelAdjToFranchisee",
        "netOfferExtended",
        "eiplRemodelAdjCost",
        "lcmFeesInclLegal",
        "titleDD",
        "valuationFees",
        "stampDutyRgstnCost",
        "transferFee",
        "changeLicensingCosts",
        "totalOtherCost",
        "mgYr1Permonth",
        "camYr1PmInRs",
        "rsDineInYr1Percentage",
        "rsDelInYr1Percentage",
        "otherPropertyChargesYr1Pm",
        "adt",
        "apcRs",
        "deliveryPercentage",
        "monthSaleRsMn",
        "yearly1Revenue",
        "yearly1CostOfSales",
        "yearly1GrossProfit",
        "yearly1Royalty",
        "yearly1Occupancy",
        "yearly1Labour",
        "yearly1TechAllocation",
        "yearly1Utilities",
        "yearly1Commission",
        "yearly1StaffIncentive",
        "yearly1Marketing",
        "yearly1OtherOpex",
        "yearly1StoreEbitda",
        "yearly1Depreciation",
        "yearly1StoreEbit",
        "paybackYears",
        "irr5Years",
        "yearlyAnnualSalesYr1",
        "yearlyAnnualSalesYr2",
        "yearlyAnnualSalesYr3",
        "yearlyAnnualSalesYr4",
        "yearlyAnnualSalesYr5",
        "yearlyAnnualSalesYr6",
        "yearlyAnnualSalesYr7",
        "yearlyAnnualSalesYr8",
        "yearlyAnnualSalesYr9",
        "yearlyAnnualSalesYr10",
        "yearlyEbitdaYr1",
        "yearlyEbitdaYr2",
        "yearlyEbitdaYr3",
        "yearlyEbitdaYr4",
        "yearlyEbitdaYr5",
        "yearlyEbitdaYr6",
        "yearlyEbitdaYr7",
        "yearlyEbitdaYr8",
        "yearlyEbitdaYr9",
        "yearlyEbitdaYr10",
        "forward1yrPeriod",
        "forward1YearEbitdaMultiple",
        "ttmPeriod",
        "ttmAdjustedEbitdaMultiple",
        "ttmAnnualEbitda",
        "adsTtm",
      ]);
      return {
        ...mainKeys,
        ...businessDetails,
      };
    }) || []
  );
};
