import { exportToExcel } from "../../../common-utilities/utils";

export const overviewReports = (data) => {
  const overview = data?.overview || {};
  const topIssueCausingStore = overview?.topIssueCausingStore;
  const topIssueCausingDept = overview?.topIssueCausingDept;
  const topIssueCausingCategory = overview?.topIssueCausingCategory;
  const topSLABreachedDept = overview?.topSLABreachedDept;
  const topPerformer = overview?.topPerformer;
  const monthWiseTicketCounts = overview?.monthWiseTicketCounts;
  let chartCategory = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthMap = chartCategory.reduce((uniqueMap, item) => {
    uniqueMap[item] = 0;
    return uniqueMap;
  }, {});

  monthWiseTicketCounts?.forEach?.((item) => {
    const key = Object.keys(item || {})?.[0];
    if (monthMap.hasOwnProperty(key)) {
      monthMap[key] = item[key];
    }
  });
  const excelData = [
    {
      sheetName: "Ticket Summary",
      details: [
        {
          Open: overview?.open,
          "Without resolution": overview?.withoutResolution,
          Reopened: overview?.reOpen,
          "Total Tickets": overview?.total,
        },
      ],
    },

    {
      sheetName: "Ticket Mean Time",
      details: [
        {
          Resolve:
            typeof overview?.mttc === "number" ? overview.mttc.toFixed(2) : 0,
          Close:
            typeof overview?.mttr === "number" ? overview.mttr.toFixed(2) : 0,
        },
      ],
    },
    {
      sheetName: "Top Issue Causing",
      details: [
        {
          "Top Issue Raising Store":
            topIssueCausingStore?.name &&
            typeof topIssueCausingStore?.count === "number"
              ? `${topIssueCausingStore?.name} - ${topIssueCausingStore?.count}`
              : "",
          "Top Complaints Raised Department":
            topIssueCausingDept?.name &&
            typeof topIssueCausingDept?.count === "number"
              ? `${topIssueCausingDept?.name} - ${topIssueCausingDept?.count}`
              : "",
          "Top Complaints Raised Category & Department":
            topIssueCausingCategory?.name &&
            typeof topIssueCausingCategory?.count === "number"
              ? `${topIssueCausingCategory?.name} - ${topIssueCausingCategory?.count}`
              : ``,
          "Max SLA breached department":
            topSLABreachedDept?.name && typeof topSLABreachedDept?.count
              ? `${topSLABreachedDept?.name} - ${topSLABreachedDept?.count}`
              : ``,
          "New Issues Raised": overview?.raisedCount,
          "Top Performer of the Month": topPerformer?.personName
            ? `${topPerformer?.personName} - ${topPerformer?.count}`
            : "",
        },
      ],
    },
    {
      sheetName: "Tickets Raised",
      details: [monthMap],
    },
  ];
  exportToExcel(excelData, "Overview", true, false);
};
