import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../../utils/constants";
import { getOfflineData } from "../../../utils/offline-services";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { lakhConversion } from "../../../../common-utilities/utils/misc2";
import { getSlicedNum } from "../../Financial Dashboard/utils";
import { dateFun } from "../../../../common-utilities/utils";

export const ApproverConfig = (approverNo) => {
  return {
    label: `Approver ${approverNo}`,
    id: `Approver ${approverNo}`,
    render: (data, onClick) => {
      return (
        <RemoveRedEyeIcon
          onClick={() => onClick(data, "Approver", approverNo)}
          sx={{ cursor: "pointer" }}
        />
      );
    },
  };
};

export const getHeaderConfig = (storeType) => {
  const user = getOfflineData("user");
  const deleteRights = !!user?.properties?.cfApproval?.approvalDeletionRights;
  const isCocoAcq = storeType === storeTypes.cocoAcquired;
  const keys = isCocoAcq ? { ...cocoAcqKeyNames } : { ...cocoBuiltKeyNames };
  let headerConfig = [
    {
      label: "Approval Id",
      id: "id",
    },
  ];

  headerConfig.push(
    {
      label: isCocoAcq ? "Restaurant Id" : "Store",
      id: "Store",
    },
    {
      label: "Restaurant Name",
      id: "Restaurant Name",
    }
  );
  headerConfig.push(
    {
      label: "Creation Date",
      id: "Created At",
      valueConversionLogic: (date) => dateFun(date, true),
    },
    {
      label: "Type",
      id: "Type",
    }
  );
  if (!isCocoAcq) {
    headerConfig.push(
      ApproverConfig(1),
      ApproverConfig(2),
      ApproverConfig(3),
      ApproverConfig(4),
      ApproverConfig(5),
      ApproverConfig(6),
      {
        label: "Rejected By",
        id: "Rejected By",
      },
      {
        label: "Rejected At",
        id: "Rejected At",
        valueConversionLogic: (date) => dateFun(date, true),
      }
    );
  } else {
    headerConfig.push(
      ApproverConfig(1),
      ApproverConfig(2),
      ApproverConfig(3),
      ApproverConfig(4),
      ApproverConfig(5),
      ApproverConfig(6),
      {
        label: "Rejected By",
        id: "Rejected By",
      },
      {
        label: "Rejected At",
        id: "Rejected At",
        valueConversionLogic: (date) => dateFun(date, true),
      },
      {
        label: cocoAcqKeyNames.ads,
        id: "ADS (Avg Daily Sales)",
      },
      {
        label: cocoAcqKeyNames.deliveryPercentage,
        id: "Delivery %",
      },
      {
        label: cocoAcqKeyNames.occupancyPercentage,
        id: "Occupancy %",
      },
      {
        label: cocoAcqKeyNames.labourPercentage,
        id: "Labour %",
      },
      {
        label: cocoAcqKeyNames.ebitdaPercentage,
        id: "EBITDA %",
      },
      {
        label: cocoAcqKeyNames.totalCapex,
        id: "Total Acquistion Cost (INR lacs)",
      },
      {
        label: "Payback (in months)",
        id: "Payback (in months)",
        render: (data) => getSlicedNum(data, "Payback (in months)"),
      },
      {
        label: cocoAcqKeyNames.adsTtm,
        id: "businessDetails.adsTtm",
      },
      {
        label: cocoAcqKeyNames.ttmEbitdaPercentage,
        id: "businessDetails.ttmEbitdaPercentage",
      },
      {
        label: cocoAcqKeyNames.ttmAdjustedEbitdaMultiple,
        id: "businessDetails.ttmAdjustedEbitdaMultiple",
      },
      {
        label: cocoAcqKeyNames.forward1YearEbitdaMultiple,
        id: "businessDetails.forward1YearEbitdaMultiple",
      }
    );
  }
  let capex = "Total Capex (INR lacs)";

  if (!isCocoAcq) {
    headerConfig.push(
      {
        label: keys.ads,
        id: "ADS (Avg Daily Sales)",
        render: (data) => getSlicedNum(data, "ADS (Avg Daily Sales)"),
      },
      {
        label: keys.deliveryPercentage,
        id: "Delivery %",
        render: (data) => getSlicedNum(data, "Delivery %", true),
      },
      {
        label: keys.occupancyPercentage,
        id: "Occupancy %",
        render: (data) => getSlicedNum(data, "Occupancy %", true),
      },
      {
        label: keys.labourPercentage,
        id: "Labour %",
        render: (data) => getSlicedNum(data, "Labour %", true),
      },
      {
        label: keys.ebitdaPercentage,
        id: "EBITDA %",
        render: (data) => getSlicedNum(data, "EBITDA %", true),
      }
    );
  }

  if (!isCocoAcq) {
    headerConfig.push(
      {
        label: keys.totalCapex,
        id: capex,
        render: (data) => getSlicedNum(data, capex),
      },
      {
        label: "Payback (in months)",
        id: "Payback (in months)",
        render: (data) => getSlicedNum(data, "Payback (in months)"),
      }
    );
  }

  if (isCocoAcq) {
    headerConfig.push({
      label: keys.grossOfferExtendedA,
      id: "businessDetails.grossOfferExtendedA",
      valueConversionLogic: (val) => lakhConversion(val, false, true),
    });
  }

  headerConfig.push({
    label: "View files",
    id: "View files",
    render: (data, onClick) => {
      return (
        <RemoveRedEyeIcon
          onClick={() => onClick(data)}
          sx={{ cursor: "pointer" }}
        />
      );
    },
  });

  if (deleteRights) {
    headerConfig.push({
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <div>
            <div>
              <IconButton
                onClick={() => onClick(data, "delete approval")}
                sx={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        );
      },
    });
  }

  return headerConfig;
};
export const getMobileHeaderConfig = () => {
  const headerConfig = [
    {
      label: "Store",
      id: "Store",
    },
    {
      label: "Approver 1",
      id: "Approver 1",
    },
    {
      label: "Approver 2",
      id: "Approver 2",
    },
  ];
  return headerConfig;
};
