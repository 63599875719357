import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { formatFileObj } from "./helper";
import { Card } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const CaseAttachments = ({ caseTimeLines }) => {
  const [attachments, setAttachments] = useState([]);

  const downloadFileFromUrl = (url, name) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name || "sample_pdf.pdf";
        alink.click();
      });
    });
  };

  useEffect(() => {
    if (caseTimeLines && caseTimeLines.length > 0) {
      const listOfAttachments = caseTimeLines.filter(
        (t) => t.stage === "ATTACHMENT"
      );
      let attachments = [];
      listOfAttachments.forEach((element) => {
        attachments = [...attachments, ...element.attachments];
      });
      attachments = attachments.map((a) => formatFileObj(a));

      setAttachments(attachments);
    }
  }, [caseTimeLines]);

  const getFileCard = (file) => {
    const { title = "", type = "", url = "" } = file;

    switch (type.toLowerCase()) {
      case "png":
      case "jpeg":
      case "jpg":
        return (
          <Card sx={{ maxWidth: 300 }}>
            <div className="photo-card">
              <img src={url} className="image" />
              <div className="name">
                <a
                  onClick={() => downloadFileFromUrl(url, title)}
                  // href={url}
                  download={false}
                  // target="_blank"
                >
                  {title}
                </a>
              </div>
            </div>
          </Card>
        );

      case "pdf":
        return (
          <Card sx={{ maxWidth: 300 }}>
            <div className="photo-card">
              <img
                src={require("../../../common-utilities/resources/pdf_icon.png")}
                className="image"
              />
              <div className="name">
                <a
                  onClick={() => downloadFileFromUrl(url, title)}
                  // href={url}
                  download={false}
                  // target="_blank"
                >
                  {title}
                </a>
              </div>
            </div>
          </Card>
        );
      default:
        return (
          <Card sx={{ maxWidth: 300 }}>
            <div className="photo-card">
              <AttachFileIcon className="image" />
              <div className="name">
                <a
                  onClick={() => downloadFileFromUrl(url, title)}
                  // href={url}
                  download={false}
                  // target="_blank"
                >
                  {title}
                </a>
              </div>
            </div>
          </Card>
        );
    }
  };

  return (
    <div className="case-attachments-container">
      <div className="doc-cards">
        {attachments.map((file) => {
          return getFileCard(file);
        })}
      </div>
    </div>
  );
};

export default CaseAttachments;
