import { INTRANETHOSTNAME } from "../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../packages/common-utilities/utils/end-points/intranet";
import { HTTP_METHODS, invokeApi } from "./http-service";

export const getLimitsForUserLoggedIn = (customLogic) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.USER_LIMITS}`
  ).then((res) => {
    if (res?.message) {
      return;
    }
    if (typeof customLogic === "function") {
      customLogic(res);
    }
  });
};
