import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";

const commonRoutePart = "/v1/subs-service/";

export const REST_URLS = {
  REFRESH_TOKEN: "/v1/subs-service/auth/refresh-tokens",
  LOGIN: "/v1/subs-service/auth/login",
  LOGOUT: `${commonRoutePart}auth/logout`,
  REGISTER: "/v1/subs-service/auth/register",
  GET_FRANCHISE_AGREEMENT_FOR_STORE_NO:
    "/v1/subs-service/stores/franchise-agreement/view/",
  GET_STORE_DETAILS_BY_STORE_NUMBER:
    "/v1/subs-service/stores/fetch-store-by-store-number/",
  ALL_STORE_NUMBERS: `${commonRoutePart}stores/all-store-numbers`,
  FRANCHISE_AGREEMENT_BASE_URL: "/v1/subs-service/stores/franchise-agreement/",
  CREATE_FRANCHISE_AGREEMENT: "/create-agreement",
  UPDATE_FRANCHISE_AGREEMENT: "/update-agreement/",
  STORE_DRAWING_BASE_URL: "/v1/subs-service/stores/store-drawing/",
  GET_STORE_DRAWING_FOR_STORE_NO: "view/",
  UPDATE_STORE_DRAWING: "/update-drawing/",
  CREATE_STORE_DRAWING: "/create-drawing",
  GET_RESTAURANTS: "/v1/subs-service/restaurants",
  SAVE_DOCUMENT: "/v1/subs-service/docs/save-document",
  UPLOAD_DOC: "/v1/subs-service/docs/upload-document",
  GET_PERSON_DETAILS: "/v1/subs-service/users/fetch-or-modify-person/",
  LIST_ADDRESS_TYPE: "/v1/subs-service/users/address/types",
  LIST_COMMUNICATION_TYPE: "/v1/subs-service/users/communication/types",
  LIST_COUNTRY_DATA:
    "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json",
  GET_STORES: "/v1/subs-service/stores/fetch-store/",
  GET_INSURANCE: "/v1/subs-service/store-insurance/",
  GET_WORKER_INSURANCE: "/v1/subs-service/store-insurance/worker-insurance/",
  CREATE_INSURANCE: "/v1/subs-service/store-insurance/create-store-insurance",
  CREATE_WORKER_INSURANCE:
    "/v1/subs-service/store-insurance/create-store-worker-insurance",
  UPDATE_ADDRESS: "/v1/subs-service/users/modify-address/",
  UPDATE_COMMUNICATION: "/v1/subs-service/users/modify-comm/",
  GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER:
    "/v1/subs-service/stores/get-all-seq-and-sat-for-store/",
  CREATE_PERSON: "/v1/subs-service/users/create-person",
  CREATE_ADDRESS: "/v1/subs-service/users/add-address",
  CREATE_COMMUNICATION: "/v1/subs-service/users/add-communication",
  GET_ADDRESS_LIST: "/v1/subs-service/users/address/list-by-entity-type/",
  GET_COMMUNICATION_LIST:
    "/v1/subs-service/users/communication/list-by-entity-type/",
  UPDATE_STORE: "/v1/subs-service/stores/update-store/",
  GET_ADDRESS_TYPES: "/v1/subs-service/users/address/types",
  GET_COMMUNICATION_TYPES: "/v1/subs-service/users/communication/types",
  STORE_ADDRESS_COMM_LIST:
    "/v1/subs-service/users/address/list-by-entity-type/{TYPE}/",
  CREATE_STORE_ATTRIBUTES:
    "/v1/subs-service/store-attribute/create-store-attribute",
  CREATE_DECOR_ATTRIBUTES:
    "/v1/subs-service/decor-attribute/create-decor-Attribute",
  CREATE_TECH_ATTRIBUTES:
    "/v1/subs-service/pos-tech-attribute/create-pos-tech-Attribute",
  CREATE_CATERING_ATTRIBUTES:
    "/v1/subs-service/catering-attribute/create-catering-Attribute",

  GET_STORE_ATTRIBUTES:
    "/v1/subs-service/store-attribute/fetch-store-attribute/",
  UPDATE_STORE_ATTRIBUTES:
    "/v1/subs-service/store-attribute/update-store-attribute/",
  STORE_SEARCH: "/v1/subs-service/stores/search/",
  PERSON_SEARCH: "/v1/subs-service/users/search/",
  LEASE_SEARCH: "/v1/subs-service/stores/search/lease/",
  GET_WAIVERS: "/v1/subs-service/waivers/retrieve-waiver",
  WAIVER_ASSIGNMENT_POST: "/v1/subs-service/waivers/create-waiver",
  WAIVER_ASSIGNMENT_PUT: "/v1/subs-service/waivers/update-waiver/",
  STORE_TRANSFER:
    "/v1/subs-service/store-transfer/fetch-store-transfer-by-store/",
  STORE_TRANSFER_DETAILS:
    "/v1/subs-service/store-transfer/fetch-store-transfer-details-by-store/",
  GET_STORE: "/v1/subs-service/stores/fetch-store/",
  STORE_FRANCHISE: "/v1/subs-service/stores/franchise-agreement/view/",
  GET_LEASE: "/v1/subs-service/stores/lease/get-lease/",
  STORE_TRANSFER_IDS: "/v1/subs-service/store-transfer/retrive-store-transfer",
  TRANSFER_DETAILS: "/v1/subs-service/store-transfer/fetch-store-transfer/",
  POST_TRANSFER_DETAILS:
    "/v1/subs-service/store-transfer/create-store-transfer",
  PUT_TRANSFER_DETAILS:
    "/v1/subs-service/store-transfer/update-store-transfer/",
  WAIVER_IDS: "/v1/subs-service/waivers/retrieve-all-waivers",
  WAIVER_DETAILS: "/v1/subs-service/waivers/fetch-waiver/",
  PUT_WAIVER_DETAILS: "/v1/subs-service/waivers/update-waiver/",
  POST_WAIVER_DETAILS: "/v1/subs-service/waivers/create-waiver",
  CHECK_FILE_NAME: "/v1/subs-service/inventory/check-file-name",
  GET_DECOR_ATTRIBUTE:
    "/v1/subs-service/decor-attribute/fetch-decor-Attribute/",
  UPDATED_DECOR_ATTRIBUTE:
    "/v1/subs-service/decor-attribute/update-decor-Attribute/",
  GET_TECH_ATTRIBUTE:
    "/v1/subs-service/pos-tech-attribute/fetch-pos-tech-Attribute/",
  UPDATED_TECH_ATTRIBUTE:
    "/v1/subs-service/pos-tech-attribute/update-pos-tech-Attribute/",
  GET_CATERING_ATTRIBUTES:
    "/v1/subs-service/catering-attribute/fetch-catering-Attribute/",
  UPDATE_CATERING_ATTRIBUTES:
    "/v1/subs-service/catering-attribute/update-catering-Attribute/",
  GET_DELIVERY_ATTRIBUTES:
    "/v1/subs-service/delivery-attribute/fetch-delivery-Attribute/",
  GET_DELIVERY_ATTRIBUTES_BY_STORE_ID:
    "/v1/subs-service/delivery-attribute/fetch-delivery-Attribute-by-storeId/",
  UPDATE_DELIVERY_ATTRIBUTES:
    "/v1/subs-service/delivery-attribute/update-delivery-Attribute/",
  GET_LEASE_MAINTENANCE: "/v1/subs-service/stores/lease/get-lease-maintenance/",
  UPDATE_LEASE_MAINTENANCE:
    "/v1/subs-service/stores/lease/update-lease-maintenance/",
  CREATE_LEASE_MAINTENANCE:
    "/v1/subs-service/stores/lease/create-lease-maintenance",
  POST_LEASE: "/v1/subs-service/stores/lease/create-lease",
  PUT_LEASE: "/v1/subs-service/stores/lease/update-lease/",
  GET_HISTORY: "/v1/subs-service/history/get-history",
  GET_BASIC_FIXED: "/v1/subs-service/store-attribute/basic-fixed/",
  UPDATE_BASIC_FIXED: "/v1/subs-service/store-attribute/basic-fixed/",
  CREATE_BASIC_FIXED: "/v1/subs-service/store-attribute/basic-fixed",
  GET_STORE_HOURS: "/v1/subs-service/store-attribute/store-hours/",
  UPDATE_STORE_HOURS: "/v1/subs-service/store-attribute/store-hours/",
  CREATE_STORE_HOURS: "/v1/subs-service/store-attribute/store-hours",
  QUERY_STORES: "/v1/subs-service/stores/query-stores",
  GET_LEASE_DETAILS: "/v1/subs-service/stores/lease/get-lease-details/",
  LIST_LEASES: "/v1/subs-service/stores/lease/list-leases/",
  CREATE_CONTACTS: "/v1/subs-service/stores/add-contact",
  UPDATE_CONTACTS: "/v1/subs-service/stores/update-contact/",
  CREATE_DELIVERY_ATTRIBUTE:
    "/v1/subs-service/delivery-attribute/create-delivery-Attribute",
  GET_CORPORATION_LIST: "/v1/subs-service/corporation/c",
  CREATE_CORPORATION: "/v1/subs-service/corporation/c",
  GET_ALL_CORPORATION_NAMES: "/v1/subs-service/corporation/c/all-names",
  LIST_ADDRESS_TYPE_WITH_ID: "/v1/subs-service/users/address-type-list",
  UPDATE_ADDRESS_TYPE: "/v1/subs-service/users/address-type/",
  GET_ADDRESS_TYPE_BY_ID: "/v1/subs-service/users/address-type/",
  CREATE_ADDRESS_TYPE: "/v1/subs-service/users/address-type",
  LIST_COMMUNICATION_ADDRESS_TYPE_WITH_ID:
    "/v1/subs-service/users/communication-address-type-list",
  UPDATE_COMMUNICATION_ADDRESS_TYPE:
    "/v1/subs-service/users/communication-address-type/",
  GET_COMMUNICATION_ADDRESS_BY_ID:
    "/v1/subs-service/users/communication-address-type/",
  CREATE_COMMUNICATION_ADDRESS_TYPE:
    "/v1/subs-service/users/communication-address-type",
  MEMOS: "/v1/subs-service/memo",
  MEMO_CATEGORY: "/v1/subs-service/memo-category",
  GET_REPORTS: "/v1/subs-service/reports",
  GET_WAIVER_TYPES: "/v1/subs-service/waivers/get-all-waiver-type",
  CREATE_WAIVER_TYPE: "/v1/subs-service/waivers/create-waiver-type",
  GET_NOTICES: "/v1/subs-service/notice-id-tracker/query-notices",
  UPLOAD_NOTICES: "/v1/subs-service/notice-id-tracker/upload-notice-data",
  GET_NOTICE_TYPES: "/v1/subs-service/notice-id-tracker/notice-type-list",
  GET_TIMELINE: "/v1/subs-service/memo/timeline-view/",
  Add_MEMO_COMMENT: "/v1/subs-service/memo/add-comment",
};

export const subsEndPoint = getProjectEndPoint(endPointsForProject.subs);
