export const formatReport = (results) => {
  return (
    results?.map?.((item) => {
      return {
        'Vendor Name': item?.name,
        'Vendor Code': item?.vendorCode,
        'Item Name': item?.item?.name,
        'Item Code': item?.item?.itemCode || null,
        Price: item?.price,
      };
    }) || []
  );
};
