import React from "react";
import "./index.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StoreMemos from "./store-memos";
import StoreDetails from "./store-details";
import StoreHistory from "./store-history";
import StoreContacts from "./store-contacts";
import StoreAddressCommunication from "./store-address-communication";
import { TabPanel } from "../../../common-utilities/core/tabs";
import Notices from "./notices";

const Store = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={"store"}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Store Details" className="tab" />

          <Tab label="Store History" className="tab" />
          {/* <Tab label="Store HQ Contact" className="tab" disabled /> */}
          <Tab label="Store Address/Communication list" className="tab" />
          <Tab label="Store Memos" className="tab" />
          <Tab label="Notices" className="tab" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StoreDetails />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <StoreHistory />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <StoreContacts />
      </TabPanel> */}
      <TabPanel value={value} index={2}>
        <StoreAddressCommunication />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StoreMemos />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Notices />
      </TabPanel>
    </Box>
  );
};

export default Store;
