import { tokenMainKeys } from "../../../../../utils/constants";
import { fieldValidation } from "../../../../common-utilities/utils/misc";
import { getOfflineData } from "../../../../common-utilities/utils/offline-services";
import { validCocoAcquiredLicenses } from "../../../utils/coco-acquired.config";
import { validCocoBuiltLicenses } from "../../../utils/coco-built.config";
import {
  agreementAndPostAgreementDocsCocoAcquired,
  mfaRiderCocoAcquired,
  nocForGstCocoAcquired,
  preAgreementDocsCocoAcquired,
  electricityBillInNameOfLandloadCocoAcquired,
} from "./coco-acquired";
import {
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import {
  cocoBuiltAgreementAndPostAgreementDocsValidation,
  cocoBuiltMfaRiderValidation,
  cocoBuiltPreAgreementDocsValidation,
  nocForGstCocoBuilt,
  electricityBillInNameOfLandloadCocoBuilt,
} from "./coco-built";

export const isFormValid = (
  formDetails,
  docs,
  storeType,
  isEditingDoc,
  disableEditing
) => {
  const user = getOfflineData(tokenMainKeys.docuStorage, "user");
  if (!user) {
    return true;
  }

  const preventFromSaving = disableEditing && isEditingDoc;
  if (preventFromSaving) {
    return true;
  }
  const { type = {} } = formDetails;
  const { value } = type;
  let docsCheck = docs.length === 0;
  if (formDetails.notApplicable) {
    docsCheck = false;
  }

  switch (storeType) {
    case validStoreTypes.fofo:
      switch (value) {
        case !value:
          return true;
        case validDocumentTypes.subleaseAgreement:
          return (
            !formDetails.endDate ||
            !formDetails.startDate ||
            docsCheck ||
            !formDetails.licensor ||
            !formDetails.licenseeFirm ||
            !formDetails.licenseePartners ||
            !formDetails.leaseType ||
            !formDetails.leaseSignDate ||
            !formDetails.leaseEffectiveDate ||
            !formDetails.leaseEndDate ||
            // fieldValidation(formDetails.area) ||
            fieldValidation(formDetails.currentRent) ||
            !formDetails.rentEscalation ||
            fieldValidation(formDetails.securityDeposit) ||
            !formDetails.locationAddress ||
            // fieldValidation(formDetails.lockInPeriod) ||
            // !formDetails.lockInExpiryDate ||
            !formDetails.renewalClause ||
            !formDetails.stampedAndRegistered
          );
        default:
          if (value !== "FRANCHISE_AGREEMENT") {
            docsCheck = false;
          }
          return !formDetails.endDate || !formDetails.startDate || docsCheck;
      }
    case validStoreTypes.cocoAcquired:
      switch (value) {
        case validDocumentTypes.preAgreementDocs:
          return preAgreementDocsCocoAcquired(formDetails, docsCheck);
        case validDocumentTypes.agreementAndPostAgreementDocs: {
          return agreementAndPostAgreementDocsCocoAcquired(
            formDetails,
            docsCheck
          );
        }
        case validDocumentTypes.license: {
          let dateCheck = !formDetails.startDate || !formDetails.endDate;
          let cocoAcquiredLicenseNameCheck = false;
          const isCocoAcquiredOthersLicense =
            formDetails?.licenseType?.value ===
            validCocoAcquiredLicenses.others;
          if (isCocoAcquiredOthersLicense) {
            cocoAcquiredLicenseNameCheck = !formDetails.licenseName?.trim();
          }

          if (formDetails?.notApplicable) {
            dateCheck = false;
          } else if (formDetails?.isApplied?.value) {
            dateCheck = !formDetails.startDate;
          }

          return (
            !formDetails.licenseType ||
            cocoAcquiredLicenseNameCheck ||
            docsCheck ||
            dateCheck
            // ||
            // !formDetails.isApplied
          );
        }
        case validDocumentTypes.mfaRider:
          return mfaRiderCocoAcquired(formDetails, docsCheck);
        case validDocumentTypes.nocForGst:
          return nocForGstCocoAcquired(formDetails, docsCheck);
        case validDocumentTypes.electricityBillInNameOfLandload:
          return electricityBillInNameOfLandloadCocoAcquired(
            formDetails,
            docsCheck
          );
        case !value:
          return true;
        default:
          return false;
      }
    case validStoreTypes.cocoBuilt:
      switch (value) {
        case validDocumentTypes.preAgreementDocs:
          return cocoBuiltPreAgreementDocsValidation(formDetails, docsCheck);

        case validDocumentTypes.agreementAndPostAgreementDocs: {
          return cocoBuiltAgreementAndPostAgreementDocsValidation(
            formDetails,
            docsCheck
          );
        }
        case validDocumentTypes.license: {
          let cocoBuiltLicenseNameCheck = false;
          const isCocoBuiltOthersLicense =
            formDetails?.licenseType?.value === validCocoBuiltLicenses.others;
          if (isCocoBuiltOthersLicense) {
            cocoBuiltLicenseNameCheck = !formDetails.licenseName?.trim();
          }
          let dateCheck = !formDetails.startDate || !formDetails.endDate;
          if (formDetails?.notApplicable) {
            dateCheck = false;
          } else if (formDetails?.isApplied?.value) {
            dateCheck = !formDetails.startDate;
          }

          return (
            !formDetails.licenseType ||
            cocoBuiltLicenseNameCheck ||
            docsCheck ||
            dateCheck
            // ||
            // !formDetails.isApplied
          );
        }

        case validDocumentTypes.mfaRider:
          return cocoBuiltMfaRiderValidation(formDetails, docsCheck);
        case validDocumentTypes.nocForGst:
          return nocForGstCocoBuilt(formDetails, docsCheck);
        case validDocumentTypes.electricityBillInNameOfLandload:
          return electricityBillInNameOfLandloadCocoBuilt(
            formDetails,
            docsCheck
          );
        case !value:
          return true;
        default:
          return false;
      }
    default:
      break;
  }
};
