import React, { useState } from "react";
import PrivateRoute from "./private-route";
import { PETTY_EXPENSE_ROUTE } from "../../common-utilities/utils/end-points/petty-expense";
import PettyExpense from "../components/petty-expense";
import CreateVoucher from "../components/create-voucher";
import BalanceHistory from "../components/balance-history";
import StoreImprest from "../components/store-imprest";
import CashCertificate from "../components/cash-certificate";

export const pettyExpenseRouteConfig = {
  mainPath: {
    path: "/",
    element: <PrivateRoute />,
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: PETTY_EXPENSE_ROUTE,
  routes: [
    {
      path: "/",
      Element: PettyExpense,
      element: <PettyExpense />,
      exact: true,
    },
    {
      path: "/create-voucher",
      Element: CreateVoucher,
      element: <CreateVoucher />,
      exact: true,
    },
    {
      path: "/balance-history",
      Element: BalanceHistory,
      element: <BalanceHistory />,
      exact: true,
    },
    {
      path: "/store-imprest",
      Element: StoreImprest,
      element: <StoreImprest />,
      exact: true,
    },
    {
      path: "/cash-certificate",
      Element: CashCertificate,
      element: <CashCertificate />,
      exact: true,
    },
  ],
};
