import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Theme } from "./utils/theme-config";
import { ToastContainer } from "react-toastify";
import Loader from "./packages/common-utilities/core/loader";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./routes";
import { store } from "./store";
import { Provider } from "react-redux";
const theme = createTheme(Theme);
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Loader />
        <ToastContainer autoClose={2000} />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
