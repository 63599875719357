import { getLabelsAndValuesForSelect } from ".";
import { allRegions } from "./region";

export const countryDropdownList = () => {
  const countries = Object.keys(allRegions);
  const countriesSelectList = getLabelsAndValuesForSelect(countries, countries);
  return countriesSelectList;
};

export const getStateDropdownList = (country) => {
  if (!country || typeof country != "string") {
    return [];
  }
  const lowerCaseCountry = country.toLowerCase();
  let stateList = [];
  if (lowerCaseCountry.includes("lanka") || lowerCaseCountry.includes("sri")) {
    stateList = Object.keys(allRegions["Sri Lanka"]);
  } else if (
    lowerCaseCountry.includes("desh") ||
    lowerCaseCountry.includes("ban")
  ) {
    stateList = Object.keys(allRegions["Bangladesh"]);
  } else {
    stateList = Object.keys(allRegions["India"]);
  }
  return getLabelsAndValuesForSelect(stateList, stateList);
};

export const getCityDropdownList = (country, state) => {
  const cityValues = allRegions?.[country]?.[state] || [];
  return getLabelsAndValuesForSelect(cityValues, cityValues);
};

export const clearRegionKeys = (
  details,
  curKey,
  curValue,
  countryKey,
  stateKey,
  cityKey,
  setStateList,
  setCityList
) => {
  if (!details || typeof details !== "object") {
    return;
  }
  switch (curKey) {
    case countryKey:
      delete details[stateKey];
      delete details[cityKey];
      const stateList = getStateDropdownList(curValue);
      if (typeof setStateList === "function") {
        setStateList(stateList);
      }
      if (typeof setCityList === "function") {
        setCityList([]);
      }
      return;
    case stateKey:
      delete details[cityKey];
      const cityList = getCityDropdownList(details[countryKey], curValue);
      if (typeof setCityList === "function") {
        setCityList(cityList);
      }
      return;
    default:
      return;
  }
};
