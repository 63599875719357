import Typography from "@mui/material/Typography";
import { daysExcludingWeekends } from "../../../common-utilities/utils/misc";
import { dateFun } from "../../utils";
import { nestedBasicUpdateDateKeys } from "../../utils/keys-to-labels";

export const NestedTimeLineDetailsComponent = ({
  details,
  detailLabels,
  caseDetails,
}) => {
  return (
    <>
      {Object.keys(details || {}).map((item, detailsIndex) => {
        if (
          detailLabels?.[item] === undefined ||
          detailLabels?.[item] === null
        ) {
          return null;
        }
        if (typeof details?.[item] === "object") {
          return (
            <>
              <Typography>
                <h6>{detailLabels?.[item]?.mainKey}</h6>
              </Typography>

              {Object.keys(details?.[item] || {}).map((subItem, subIndex) => {
                if (
                  detailLabels?.[item]?.[subItem] === undefined ||
                  detailLabels?.[item]?.[subItem] === null
                ) {
                  return null;
                }

                if (typeof details?.[item]?.[subItem] === "object") {
                  return (
                    <>
                      <Typography>
                        <h6>{detailLabels?.[item]?.[subItem]?.mainKey}</h6>
                      </Typography>
                      {Object.keys(details?.[item]?.[subItem] || {}).map(
                        (nestedSubItem, nestedIndex) => {
                          if (
                            detailLabels?.[item]?.[subItem]?.[nestedSubItem] ===
                              undefined ||
                            detailLabels?.[item]?.[subItem]?.[nestedSubItem] ===
                              null
                          ) {
                            return null;
                          }
                          return (
                            <Typography key={nestedIndex}>
                              {detailLabels?.[item]?.[subItem]?.[nestedSubItem]}{" "}
                              :{" "}
                              {nestedBasicUpdateDateKeys?.includes(
                                detailLabels?.[item]?.[subItem]?.[nestedSubItem]
                              )
                                ? dateFun(
                                    details?.[item]?.[subItem]?.[nestedSubItem]
                                  )
                                : details?.[item]?.[subItem]?.[nestedSubItem]}
                            </Typography>
                          );
                        }
                      )}
                    </>
                  );
                }
                return (
                  <Typography key={subIndex}>
                    {detailLabels?.[item]?.[subItem]} :{" "}
                    {details?.[item]?.[subItem]}
                  </Typography>
                );
              })}
            </>
          );
        }

        let value = ![null, undefined, "", NaN].includes(details?.[item])
          ? details?.[item]
          : "";
        if (`${item}` == "ttc") {
          value = daysExcludingWeekends(
            caseDetails?.closedAt,
            caseDetails?.createdAt
          );
          value = ![null, undefined, "", NaN].includes(details?.[item])
            ? `${value} days`
            : "";
        }
        return (
          <Typography key={detailsIndex}>
            {detailLabels?.[item]} : {value}
          </Typography>
        );
      })}
    </>
  );
};
