import { Autocomplete, Button, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckMarks from "../../core/checkmark-select";
import DocumentView from "../../core/document-viewer";
import CustomModal from "../../../common-utilities/core/modal";
import {
  DOCUMENT_TYPES,
  INVOICE_TRACKING_USERS,
  VENDOR_INVOICE_SUBMISSION_ROLES,
} from "../../utils/constants";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import ClearIcon from "@mui/icons-material/Clear";
import {
  HTTP_METHODS,
  invokeApi,
  invokeUploadFile,
  plainApi,
} from "../../utils/http-service";
import { getOfflineData } from "../../utils/offline-services";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import {
  clearFilter,
  FILTER_DATE_RANGE_TYPES,
  getHeaderConfig,
  getOptions,
  isFilterDisabled,
  transformInvoiceReport,
  transformVendorInvoiceReport,
} from "./helper";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import FilterMenu from "../../../common-utilities/components/filter-menu";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";
import { StoreAutoCompleteComponent } from "../common-components/store-autocomplete";
import { getPendingInvoices } from "./apis";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { toast } from "react-toastify";
import { exportToExcel } from "../../../common-utilities/utils";
import { VendorInvoiceFilters } from "./sub-components/vendor-invoice";
import { getLast15DaysDateRange } from "../../../finance/components/reports/utils/utils";

const InvoiceList = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const userDetails = getOfflineData("user", token) || {};
  const { role = "" } = userDetails;

  const [invoiceList, setInvoiceList] = useState({});

  const [invoiceDocument, setInvoiceDocument] = useState(null);
  const [searchVendorCode, setSearchVendorCode] = useState("");
  const [vendorOptions, setVendorOptions] = useState([]);

  const [filters, setFilters] = useState({
    limit: 10,
    page: 0,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    switch (slug) {
      case appSlugs.vendorInvoiceSubmission:
        loadData(true);
        break;
      default:
        break;
    }
  }, [slug]);

  useEffect(() => {
    switch (slug) {
      case appSlugs.vendorInvoiceSubmission:
        break;
      default:
        loadData(true);
        break;
    }
  }, [filters, searchVendorCode, slug]);

  const loadData = (firstPage, providedActiveTab) => {
    for (let key in filters) {
      if (!filters[key]) {
        delete filters[key];
      }
    }
    let params = { ...filters };

    if (searchVendorCode) {
      params["vendor"] = searchVendorCode;
    }
    const curActiveTab =
      typeof providedActiveTab === "number" ? providedActiveTab : activeTab;
    if (curActiveTab !== 2) {
      params.pending = curActiveTab === 0;
    }

    if (filters.invoiceType && filters.invoiceType.length > 0) {
      params.invoiceType = filters.invoiceType.map((i) => i.value).join(",");
    } else {
      delete params.invoiceType;
    }

    if (filters.dateType && filters.dateType.length > 0) {
      params.dateType = filters.dateType.map((i) => i.value).join(",");
    } else {
      delete params.dateType;
    }

    if (filters.store) {
      const { value } = filters.store;
      if (value) {
        params.store = value;
      }
    } else {
      delete params.store;
    }
    if (slug === appSlugs.vendorInvoiceSubmission) {
      if (filters.vendor?.length) {
        params.vendor = JSON.stringify(
          filters.vendor.map((item) => item.value)
        );
      } else {
        delete params.vendor;
      }
    }

    if (firstPage) {
      filters.page = 1;
    }

    invokeApi(
      HTTP_METHODS.GET,
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}`
        : `${HOSTNAME}${REST_URLS.INVOICE}`,
      null,
      params,
      null,
      slug,
      token
    )
      .then((response) => {
        setInvoiceList(response);
      })
      .catch((err) => {});
  };

  const onFilterChange = (value, dataLabel) => {
    setFilters({
      ...filters,
      [dataLabel]: value,
    });
  };

  const viewInvoice = (data, type) => {
    if (type === "VIEW") {
      navigate(`invoice-details/${data.id}`);
    } else if (type === "EDIT") {
      navigate(`invoice-edit/${data.id}`);
    } else {
      const { invoiceAttachment } = data;
      if (invoiceAttachment) {
        setInvoiceDocument(invoiceAttachment);
      }
    }
  };

  const searchDisabled = isFilterDisabled(filters);

  const downloadData = () => {
    let params = { ...filters };

    if (searchVendorCode) {
      params["vendor"] = searchVendorCode;
    }

    if (activeTab !== 2) {
      params.pending = activeTab === 0;
    }

    if (filters.invoiceType && filters.invoiceType.length > 0) {
      params.invoiceType = filters.invoiceType.map((i) => i.value).join(",");
    } else {
      delete params.invoiceType;
    }

    if (filters.dateType && filters.dateType.length > 0) {
      params.dateType = filters.dateType.map((i) => i.value).join(",");
    } else {
      delete params.dateType;
    }

    if (
      !params?.startDate &&
      !params?.endDate &&
      Object.keys(filters).length <= 3
    ) {
      const { startDate, endDate } = getLast15DaysDateRange();
      params["startDate"] = startDate;
      params["endDate"] = endDate;
      params["dateType"] = "createdAt";
    }

    if (filters.store) {
      const { value } = filters.store;
      if (value) {
        params.store = value;
      }
    } else {
      delete params.store;
    }
    if (slug === appSlugs.vendorInvoiceSubmission) {
      if (filters.vendor?.length) {
        params.vendor = JSON.stringify(
          filters.vendor.map((item) => item.value)
        );
      } else {
        delete params.vendor;
      }
    }

    invokeApi(
      HTTP_METHODS.GET,
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}`
        : `${HOSTNAME}${REST_URLS.INVOICE}`,
      null,
      {
        ...params,
        limit: invoiceList.totalPages * 10,
        isDownload: true,
      },
      null,
      slug,
      token
    )
      .then((response) => {
        if (response && response.results.length > 0) {
          if (slug !== appSlugs.vendorInvoiceSubmission) {
            const finalData = transformInvoiceReport(response.results || []);
            exportToExcel(finalData, "invoice_list");
          } else {
            const finalData = transformVendorInvoiceReport(response);
            exportToExcel(finalData, "vendor_invoice_list");
          }
          // exportToExcel(
          //   response.results,
          //   "invoice_list",
          //   null,
          //   null,
          //   null,
          //   null,
          //   response.dateFields
          // );
        }
      })
      .catch((err) => {});
  };

  const handleChange = (newValue) => {
    setActiveTab(newValue);
    loadData(true, newValue);
  };
  const getVendor = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.VENDOR}`,
        null,
        {
          page: 0,
          limit: 1000,
          name: searchCode,
        },
        slug,
        token
      ).then((res) => {
        const vendorList = res?.results?.map((ele) => ({
          vendor: `${ele?.code}-${ele?.name}`,
          id: ele?.id,
        }));
        setVendorOptions(vendorList);
      });
    }, 300);
  };
  const inputChange = (e, value) => {
    if (value?.trim()) {
      getVendor(value);
    }
  };

  return (
    <div className="invoice-list-container">
      <div className="header-section">
        <span className="title">Invoice List</span>

        {slug !== appSlugs.vendorInvoiceSubmission &&
          role === INVOICE_TRACKING_USERS.STORE && (
            <Button
              variant="contained"
              onClick={() => navigate("invoice")}
              size="small"
            >
              Submit Invoice
            </Button>
          )}
      </div>

      {slug === appSlugs.vendorInvoiceSubmission && (
        <div className="download-vendor-invoices">
          {role === VENDOR_INVOICE_SUBMISSION_ROLES.FINANCE_USER && (
            // vendor-invoice-btns
            <>
              <Button
                onClick={() => getPendingInvoices(slug, token)}
                variant="outlined"
                size="small"
                className="small-button"
              >
                Payment Details
              </Button>
              <FileUpload
                componentClass="small-button"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                fileFormats={[
                  ".csv",
                  ".xls",
                  ".xlsx",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "application/vnd.ms-excel",
                ]}
                uploadText="Upload Payment Details"
                uploadCallback={(data, success, failure) => {
                  invokeUploadFile(
                    `${HOSTNAME}${REST_URLS.UPLOAD_PAYMENT_INVOICES}`,
                    data,
                    slug,
                    token
                  )
                    .then((res) => {
                      if (res?.status === 200) {
                        success && success();
                      } else {
                        failure && failure();
                        toast.error(
                          (res.data && res.data.message) || "Upload Failed"
                        );
                      }
                    })
                    .catch((err) => {
                      toast.error("Upload Failed");
                      failure && failure();
                    });
                }}
              />
            </>
          )}

          {role === VENDOR_INVOICE_SUBMISSION_ROLES.VENDOR && (
            <Button variant="contained" onClick={() => navigate("invoice")}>
              Submit Invoice
            </Button>
          )}

          <DownloadBtn onClick={() => downloadData()} />
        </div>
      )}

      <div className="invoice-filter-container">
        <TextField
          label="Search Invoice ID"
          onChange={(e) => {
            onFilterChange(e?.target?.value, "_id");
          }}
          size="small"
        />
        <TextField
          label="Invoice Number"
          onChange={(e) => {
            onFilterChange(e?.target?.value, "invoiceNumber");
          }}
          size="small"
        />
        {slug !== appSlugs.vendorInvoiceSubmission && (
          <Autocomplete
            getOptionLabel={(option) => option?.vendor?.toString()}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setSearchVendorCode("");
                  setVendorOptions([]);
                }}
              />
            }
            options={vendorOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Vendor Name"
                className="filter-width"
              />
            )}
            onInputChange={inputChange}
            onChange={(event, value) => {
              if (value) {
                setSearchVendorCode(value?.id);
              }
            }}
          />
        )}

        {role !== INVOICE_TRACKING_USERS.STORE && (
          <div className="invoice-filter-container">
            <StoreAutoCompleteComponent
              callBack={(storeNo, storeId) => {
                onFilterChange(
                  slug !== appSlugs.vendorInvoiceSubmission ? storeNo : storeId,
                  "store"
                );
              }}
              token={`${token}`}
              slug={`${slug}`}
              clearValue={() => {
                if (filters?.store) {
                  delete filters?.store;
                }
              }}
            />
          </div>
        )}
        {slug !== appSlugs.vendorInvoiceSubmission && (
          <MultipleSelectCheckMarks
            options={DOCUMENT_TYPES}
            multiple={true}
            className="dropdown-list"
            label="Invoice Type"
            selectedOptions={filters.invoiceType || []}
            onChange={(value) => onFilterChange(value, "invoiceType")}
          />
        )}

        <MultipleSelectCheckMarks
          options={FILTER_DATE_RANGE_TYPES}
          multiple={false}
          className="dropdown-list"
          label="Date Type"
          selectedOptions={filters.dateType || []}
          onChange={(value) => onFilterChange(value, "dateType")}
        />
        <TextField
          size="small"
          InputLabelProps={{ shrink: true }}
          type="date"
          label=" From"
          value={filters.startDate || ""}
          onChange={(event) => onFilterChange(event.target.value, "startDate")}
        />
        <TextField
          size="small"
          InputLabelProps={{ shrink: true }}
          type="date"
          label="to"
          value={filters.endDate || ""}
          onChange={(event) => onFilterChange(event.target.value, "endDate")}
        />
        <VendorInvoiceFilters
          slug={slug}
          token={token}
          filters={filters}
          setFilters={setFilters}
          role={role}
        />
        {!searchDisabled && slug !== appSlugs.invoiceTracking && (
          <SearchIcon onClick={() => loadData(true)} />
        )}

        {slug !== appSlugs.vendorInvoiceSubmission && (
          <DownloadBtn onClick={() => downloadData()} />
        )}

        {!clearFilter(filters) && (
          <Button
            variant="outlined"
            onClick={() =>
              setFilters({
                limit: 10,
                page: 0,
                sortBy: "-createdAt",
              })
            }
          >
            Clear Filter
          </Button>
        )}
      </div>

      <div className="invoice-table-filters">
        <FilterMenu
          value={{ value: activeTab }}
          options={getOptions(role)}
          onChange={(option) => handleChange(option.value)}
        />
        <div
          className={`invoice-table ${
            role === INVOICE_TRACKING_USERS.STORE ? "store" : ""
          }`}
        >
          <MuiTable
            nestedValue={true}
            columnsList={getHeaderConfig(role, token, slug)}
            dataList={invoiceList.results || []}
            filters={filters}
            pageCount={invoiceList?.totalPages}
            onClick={viewInvoice}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          />
        </div>
      </div>
      {invoiceDocument && (
        <CustomModal
          open={!!invoiceDocument}
          title="Invoice Document"
          contentClassName="invoice-tracking-document-modal"
          onClose={() => setInvoiceDocument(null)}
        >
          <DocumentView url={invoiceDocument} />
        </CustomModal>
      )}
    </div>
  );
};

export default InvoiceList;
