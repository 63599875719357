const commonPrefix = "electricity-consumption";
const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  LOGIN: `${intranetService}auth/login`,
  // REFRESH_TOKEN: `${commonPrefix}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGOUT: `${intranetService}auth/logout`,
  ELECTRICITY_CONSUMPTION: `${intranetService}${commonPrefix}`,
  ELECTRICITY_CONSUMPTION_REPORT: `${intranetService}${commonPrefix}/report`,
  GET_ALL_STORES: `${intranetService}stores`,
  AM_USERS: `${intranetService}users/user-list?additionalRole=Area Manager`,
  CHECK_TODAYS_DATA: `${intranetService}${commonPrefix}/check-todays-value`,
};

export const redirectToRoute = "/pl-report";
