export const mainLeadDates = [
  "Site identification post discussion with Ops Date",
  "Site Feasibility Date",
  "CF Approval Date",
  "LOI Signing Date",
  "Sec Deposit Payment Tranche1 Date",
  "Layout Design Date",
  "Possession Date",
  "Title Diligence Date",
  "FSSAI License receipt Date",
  "Handover to operations Date",
  "Store Opened Date",
];

export const stageGatesDates = [
  "Initial Estimated",
  "Revised Projected Date",
  "Actual Date",
];
