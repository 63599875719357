import React, { useEffect } from "react";
import "./index.scss";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import { storeTypes } from "../../utils/constants";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";

const Approver = ({
  approver1,
  userList,
  approver1Handler,
  approver2,
  approver2Handler,
  approver3,
  approver3Handler,
  setDocumentData,
  documentData,
  approver4,
  approver4Handler,
  approver5,
  approver5Handler,
  approver6,
  approver6Handler,
}) => {
  const showOtherApprovers =
    documentData?.storeType?.value === storeTypes.cocoAcquired;

  return (
    <div>
      <Grid container md={12} className="grid-con">
        <Grid item md={4} xs={12}>
          <div className="input-con">
            <p className="label">Approver 1*</p>

            <AutocompleteComponent
              disableClear={true}
              label={!approver1 ? "Approver 1" : ""}
              options={userList}
              onChange={(e, val) => {
                approver1Handler(val);
              }}
              defaultValue={approver1 || ""}
              value={
                userList.find(
                  (item) => item.value === documentData.approver1
                ) || null
              }
            />
          </div>

          <div className="input-con">
            <p className="label">Approver 2*</p>

            <AutocompleteComponent
              disableClear={true}
              label={!approver2 ? "Approver 2" : ""}
              options={userList}
              onChange={(e, val) => {
                approver2Handler(val);
              }}
              defaultValue={approver2 || ""}
              value={
                userList.find(
                  (item) => item.value === documentData.approver2
                ) || null
              }
            />
          </div>

          <div className="input-con">
            <p className="label">Approver 3*</p>
            <AutocompleteComponent
              disableClear={true}
              label={!approver3 ? "Approver 3" : ""}
              options={userList}
              onChange={(e, val) => {
                approver3Handler(val);
              }}
              defaultValue={approver3 || ""}
              value={
                userList.find(
                  (item) => item.value === documentData.approver3
                ) || null
              }
            />
          </div>

          <>
            <div className="input-con">
              <p className="label">Approver 4*</p>
              <AutocompleteComponent
                disableClear={true}
                label={!approver4 ? "Approver 4" : ""}
                options={userList}
                onChange={(e, val) => {
                  approver4Handler(val);
                }}
                defaultValue={approver4 || ""}
                value={
                  userList.find(
                    (item) => item.value === documentData.approver4
                  ) || null
                }
              />
            </div>
            <div className="input-con">
              <p className="label">Approver 5*</p>

              <AutocompleteComponent
                disableClear={true}
                label={!approver5 ? "Approver 5" : ""}
                options={userList}
                onChange={(e, val) => {
                  approver5Handler(val);
                }}
                defaultValue={approver5 || ""}
                value={
                  userList.find(
                    (item) => item.value === documentData.approver5
                  ) || null
                }
              />
            </div>
            <div className="input-con">
              <p className="label">Approver 6*</p>
              <AutocompleteComponent
                disableClear={true}
                label={!approver6 ? "Approver 6" : ""}
                options={userList}
                onChange={(e, val) => {
                  approver6Handler(val);
                }}
                defaultValue={approver6 || ""}
                value={
                  userList.find(
                    (item) => item.value === documentData.approver6
                  ) || null
                }
              />
            </div>
          </>

          <div className="input-con">
            <p className="label">Approver Email Subject</p>
            <TextField
              name="approverEmailSubject"
              fullWidth
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              size="small"
              id="approverEmailSubject"
              value={documentData.approverEmailSubject}
              placeholder="Pending CF Approval"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <div className="input-con">
            <p className="label">CC Emails</p>
            <AutoCompleteMultiSelect
              value={documentData.ccEmails || []}
              onChange={(e, val) => {
                setDocumentData((prevValue) => ({
                  ...prevValue,
                  ccEmails: val,
                }));
              }}
              options={userList}
              label="CC Emails"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Approver;
