import { useEffect, useState } from 'react';
import CustomModal from '../../../../../common-utilities/core/modal';
import { createUpdateForeignCurrency } from '../apis';
import { getModalData } from '../utils';
import { Button, TextField } from '@mui/material';

const CreateUpdateModal = ({
  showModal,
  title,
  closeModal,
  data,
  onSuccess,
}) => {
  const [formDetails, setFormDetails] = useState({});

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    getModalData(data, setFormDetails);
  }, [data]);

  useEffect(() => {
    if (!showModal) {
      setFormDetails({});
    }
  }, [showModal]);
  return (
    <>
      {showModal && (
        <CustomModal
          title={title}
          disabledOutsideClick={true}
          onClose={closeModal}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name || ''}
              name="name"
              onChange={onInputChange}
              size="small"
              label="Name"
              fullWidth
            />
            <Button
              fullWidth
              variant="contained"
              disabled={!formDetails.name}
              onClick={() => {
                createUpdateForeignCurrency(formDetails, onSuccess);
              }}
            >
              {formDetails?.id ? 'Update' : 'Create'}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default CreateUpdateModal;
