import React, { useState, useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getOfflineData } from "../../packages/common-utilities/utils/offline-services";
import { Button, Grid, TextField } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../packages/common-utilities/utils/http-service";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { toast } from "react-toastify";
import userProfile from "../../resources/images/user-profile.png";
import changePassword from "../../resources/images/change-password.png";
const UserProfile = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
    userId: "",
    id: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const goBackHandler = () => {
    navigate("/");
  };
  const handleChange = (e) => {
    setUserDetails((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    const userInfo = getOfflineData("subway", "user");
    setUserDetails({
      name: userInfo?.name,
      email: userInfo?.email,
      userId: userInfo?.userId,
      id: userInfo?.id,
    });
  }, []);
  const changePasswordHandler = () => {
    if (!userDetails.password) {
      toast.error("Please enter password", { autoClose: 2000 });
      return;
    }
    if (userDetails.password !== confirmPassword) {
      toast.error("Password and confirm password do not match", {
        autoClose: 2000,
      });
    } else {
      const id = getOfflineData("subway", "user")?.id;
      invokeApi(
        HTTP_METHODS.PUT,
        `${INTRANETHOSTNAME}${REST_URLS.UPDATE_INTRANET_USER}${id}`,
        { password: userDetails.password }
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Password Reset Successfully", { autoClose: 2000 });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="user-account-con">
      <div className="go-back" onClick={goBackHandler}>
        <ArrowBackIcon fontSize="medium" />
        <p>Go Back</p>
      </div>
      <div className="profile-con">
        <h3>My Profile</h3>

        <Grid md={12} container>
          <Grid item md={6}>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  src={userProfile}
                  className="profile-picture si-hide-mobile"
                />
              </div>
            </Grid>
            <Grid item md={12} sx={{ paddingLeft: "30%" }}>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  gridGap: "2rem",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <h4>Name</h4>
                <h5>{userDetails?.name}</h5>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  gridGap: "2rem",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <h4>Email</h4>
                <h5 className="title">{userDetails?.email}</h5>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  gridGap: "2rem",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <h4>User Id</h4>
                <h5>{userDetails?.userId}</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            sx={{
              display: "flex",
              alignItems: "center",
              paddingX: "5rem",
              flexDirection: "column",
              justifyContent: "space-between",
              borderLeft: "1px solid black",
              gridGap: "10px",
            }}
          >
            <div>
              <img
                src={changePassword}
                className="profile-picture si-hide-mobile"
              />
            </div>
            <h4>Change Password</h4>
            <TextField
              label="Password"
              value={userDetails.password}
              size="small"
              type="password"
              name="password"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              value={confirmPassword}
              type="password"
              size="small"
            />
            <Button
              fullWidth
              variant="contained"
              onClick={changePasswordHandler}
              sx={{ mb: 5 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>

        <br />
      </div>
    </div>
  );
};

export default UserProfile;
