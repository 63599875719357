import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { validAddressTypes, validAddressLabels } from "../../utils/constants";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
const Communication = () => {
  const [type, setTypes] = useState([]);
  const [communicationDetails, setCommunicationDetails] = useState({});
  const [communicationTypes, setCommunicationTypes] = useState([]);
  const previousController = useRef();
  const [autoCompleteOptions, setautoCompleteOptions] = useState([]);
  useEffect(() => {
    const addressLabels = [validAddressLabels.person, validAddressLabels.store];
    const addressTypes = [validAddressTypes.person, validAddressTypes.store];
    const allTypeLabels = getLabelsAndValuesForSelect(
      addressLabels,
      addressTypes
    );
    setTypes(allTypeLabels);
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_COMMUNICATION_TYPES}`
    )
      .then((response) => {
        if (response && !response.message) {
          const data = getLabelsAndValuesForSelect(response, response);
          setCommunicationTypes(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const setCommunicationDetailsOnChange = (e) => {
    const { name, value } = e.target;

    setCommunicationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const disableInputCheck = () => {
    if (!communicationDetails?.type?.value) {
      return true;
    }
    if (!communicationDetails.entityId) {
      return true;
    }
    return false;
  };

  const getPayload = () => {
    let payload = {};
    const type = communicationDetails?.type?.value;
    const entityId = communicationDetails?.entityId;
    const communicationType = communicationDetails?.communicationType?.value;
    const value = communicationDetails?.value;
    if (!!type) {
      payload.type = type;
    }
    if (!!entityId) {
      payload.entityId = entityId;
    }
    if (!!communicationType) {
      payload.communicationType = communicationType;
    }
    if (!!value) {
      payload.value = value;
    }
    return payload;
  };

  const createCommunicationDetails = () => {
    if (!communicationDetails?.type || !communicationDetails?.entityId) {
      return;
    }
    const payload = getPayload();
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION}`,
      payload
    )
      .then((response) => {
        if (response && !response.message) {
          toast.success("Communication details created successfully", {
            autoClose: 2000,
          });
          setCommunicationDetails({});
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast.error(error?.message, { autoClose: 2000 });
      });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const axiosUrl =
      communicationDetails?.type?.value === validAddressTypes.person
        ? HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm
        : HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm;
    const controller = new AbortController();
    const id =
      communicationDetails?.type?.value === validAddressTypes.person
        ? "id"
        : "storeNo";
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: axiosUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p[id],
          id: p[id],
        };
      });
      setautoCompleteOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    if (value?.trim()) {
      getData(value);
    } else {
      setautoCompleteOptions([]);
    }

    setCommunicationDetailsOnChange({
      target: { name: "entityId", value: value ? value : "" },
    });
  };

  return (
    <div>
      <div className="main-add-con">
        <div className="g-r-3 add-type-div">
          <div>
            <p className="input-title-paragrah">Select Address Type</p>
            <Select
              classNamePrefix="custom_select"
              placeholder="Address Type"
              name="type"
              options={type}
              value={communicationDetails.type || null}
              onChange={(value, e) => {
                setautoCompleteOptions([]);
                const { name } = e;
                setCommunicationDetailsOnChange({ target: { name, value } });
              }}
            />
          </div>

          <div>
            <p className="input-title-paragrah">Select Entity Id</p>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              className="subs-autocomplete"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setCommunicationDetailsOnChange({
                      target: { name: "entityId", value: "" },
                    });
                  }}
                />
              }
              options={autoCompleteOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Entity Id" />
              )}
              inputValue={communicationDetails.entityId || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  const { id } = val;
                  setCommunicationDetailsOnChange({
                    target: { name: "entityId", value: id ? id : "" },
                  });
                }
              }}
            />
          </div>

          <div>
            <Button
              size="small"
              variant="contained"
              endIcon={<SaveIcon />}
              disabled={disableInputCheck()}
              onClick={createCommunicationDetails}
              sx={{ mt: 2.5, ml: "80%", float: "right", width: "20%" }}
            >
              Save
            </Button>
          </div>
        </div>

        <div>
          <div className="title">Communication Details</div>
          <div className="input-form-container">
            <div className="g-r-2">
              <Select
                classNamePrefix="custom_select"
                isDisabled={disableInputCheck()}
                isSearchable
                placeholder="Type"
                options={communicationTypes}
                value={communicationDetails.communicationType || null}
                name="communicationType"
                onChange={(value, e) => {
                  const { name } = e;
                  setCommunicationDetailsOnChange({ target: { name, value } });
                }}
              />
              <TextField
                id="details"
                label="Details"
                name="value"
                className="subs-text-field"
                value={communicationDetails.value || ""}
                onChange={setCommunicationDetailsOnChange}
                fullWidth
                variant="outlined"
                disabled={disableInputCheck()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communication;
