import moment from "moment";
import { getFileName } from "../../utils/common-utils";
import {
  DOCUMENT_TYPES,
  FINANCE_USER_ID,
  RM_APPROVE_REQUIRED,
} from "../../utils/constants";
import { appSlugs } from "../../../../utils/constants";
import { pick } from "../../../common-utilities/utils";
import { deleteObjectProperties } from "../../../common-utilities/utils/misc";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
export const isFormValid = (formDetails) => {
  let flag = false;

  return flag;
};

export const formatFormDetails = (response, slug) => {
  const {
    invoiceAttachment,
    grnAttachment,
    poAttachment,
    vendor = {},
    invoiceType,
    vendorName,
    rmApprovalRequired,
    rmComments,
    financeUserComments,
    invoiceDate,
    rmApproved,
    financeUserApproved,
    amApprovalRequired,
    amApproved,
    amComments,
  } = response;

  let formDetails = {
    ...response,
    invoiceType: DOCUMENT_TYPES.find((d) => d.value === invoiceType),
    vendor: {
      label: vendor.name || vendorName || "",
      value: vendor.code || "NEW_VENDOR",
      id: vendor.id || "NEW_VENDOR",
    },
    invoiceDate: moment(invoiceDate).format("YYYY-MM-DD"),
    invoiceAttachment: {
      url: invoiceAttachment,
      blobName: getFileName(invoiceAttachment),
    },
  };

  if (slug === appSlugs.vendorInvoiceSubmission) {
    formDetails.vendor = { ...vendor, ...formDetails.vendor };
  }

  if (rmApprovalRequired && rmApproved === false) {
    formDetails.rejectedBy = "RM";
    formDetails.comments = rmComments;
  } else if (financeUserApproved === "Rejected") {
    formDetails.rejectedBy = "Finance";
    formDetails.comments = financeUserComments;
  } else if (amApprovalRequired && amApproved === "Rejected") {
    formDetails.rejectedBy = "AM";
    formDetails.comments = amComments;
  }

  if (grnAttachment) {
    formDetails.grnAttachment = {
      url: grnAttachment,
      blobName: getFileName(grnAttachment),
    };
  }

  if (poAttachment) {
    formDetails.poAttachment = {
      url: poAttachment,
      blobName: getFileName(poAttachment),
    };
  }

  if (slug === appSlugs.vendorInvoiceSubmission) {
    const additionalDetails = pick(formDetails, [
      "paymentStatus",
      "utr",
      "paymentDate",
      "deliveryDate",
      "amountPaid",
      "store",
    ]);
    additionalDetails.paymentDate = moment(
      additionalDetails.paymentDate
    ).format("YYYY-MM-DD");
    additionalDetails.deliveryDate = moment(
      additionalDetails.deliveryDate
    ).format("YYYY-MM-DD");
    formDetails = { ...formDetails, ...additionalDetails };
  }

  return formDetails;
};

export const getUpdatePayload = (formDetails, storeDetails, slug) => {
  const {
    vendor = {},
    invoiceAttachment = {},
    invoiceType = {},
    poAttachment = {},
    grnAttachment = {},
  } = formDetails;

  const { rm = {}, am = {} } = storeDetails;

  let payload = {
    poNumber: formDetails.poNumber,
    grnNumber: formDetails.grnNumber,
    invoiceNumber: formDetails.invoiceNumber,
    invoiceType: invoiceType.value,
    invoiceDate: formDetails.invoiceDate,
    amount: formDetails.amount,
    invoiceAttachment: invoiceAttachment.url,
    grnAttachment: grnAttachment.url,
    poAttachment: poAttachment.url,
    remarks: formDetails.remarks,
    resubmit: true,
    rmApprovalRequired: RM_APPROVE_REQUIRED.includes(invoiceType.value),
    amApprovalRequired: RM_APPROVE_REQUIRED.includes(invoiceType.value),
  };

  if (vendor.value === "NEW_VENDOR") {
    payload.vendorName = formDetails.vendorName;
  } else {
    payload.vendor = vendor.id;
  }

  if (RM_APPROVE_REQUIRED.includes(invoiceType.value)) {
    payload.am = am.id;
    payload.rm = rm.id;
  } else {
    payload.financeUser = FINANCE_USER_ID;
  }

  if (slug === appSlugs.vendorInvoiceSubmission) {
    deleteObjectProperties(payload, [
      "financeUser",
      "rm",
      "am",
      "invoiceType",
      "rmApprovalRequired",
      "amApprovalRequired",
      "vendor",
    ]);

    payload = { ...payload };
  }

  return payload;
};
