import { cocoAcqKeyNames } from "../../../../utils/constants";
import { ttmSelectValues } from "../../constants";
import { getConfigFields } from "../../utils";

export const ttmConfig = (filters, subHeader, mainHeader) => {
  const config = [];
  switch (subHeader) {
    case ttmSelectValues.allTTMVsF1Y:
      config.push(
        {
          label: "TTM ADS",
          id: "adsTtm",
          removeDecimal: true,
        },
        {
          label: "TTM Annual EBITDA",
          id: "ttmAnnualEbitda",
          removeDecimal: true,
        },
        {
          label: "TTM EBITDA MULTIPLE",
          id: "ttmAdjustedEbitdaMultiple",
        },
        {
          label: "TTM Period",
          id: "ttmPeriod",
        },
        {
          label: cocoAcqKeyNames.ads,
          id: "ads",
          removeDecimal: true,
        },
        {
          label: "Fwd 1 Yr Annual EBITDA",
          id: "yearly1StoreEbitda",
          removeDecimal: true,
        },
        {
          label: "Fwd 1 Yr EBITDA MULTIPLE",
          id: "forward1YearEbitdaMultiple",
          xConversion: true,
        },
        {
          label: "Fwd 1 Yr Period",
          id: "forward1yrPeriod",
        }
      );
      break;
  }

  return getConfigFields(config, filters, "ttmVsF1Y", mainHeader);
};
