import { tokenMainKeys } from "../../../utils/constants";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { redirectToIntranetPortal } from "./common-utils";

export const redirection = (navigate, routeChangingCondition, otherRoute) => {
  const user = getOfflineData(tokenMainKeys.docuStorage, "user");
  if (!user && navigate) {
    redirectToIntranetPortal();
    return;
  }

  if (routeChangingCondition && otherRoute && navigate) {
    navigate(otherRoute);
  }
};
