import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.scss";
import Select from "react-select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { TextField, Grid, Button } from "@mui/material";

import { invokeApi, plainApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { checkValidStoreCombination } from "../../utils";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import { CLOSING_REASON } from "../Store/config";
const columns = [
  { id: "storeType", label: "Type" },
  { id: "territory", label: "Territory" },
  { id: "coordinator", label: "Coordinator" },
  { id: "waiverType", label: "Waiver Type" },
  { id: "issueDate", label: "Issue Date" },
  { id: "expiryDate", label: "Expiry Date" },
  { id: "reminderDate", label: "Reminder Date" },
];

const WaiverAssignment = () => {
  const [waiverTypeOptions, setWaiverTypeOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [waiver, setWaiver] = useState({ store: null });
  const [data, setData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [waiverAssignmentId, setWaiverAssignmentId] = useState(null);
  const previousController = useRef();
  const namePreviousController = useRef();
  const [satelliteList, setSatelliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [firstNameOptions, setFirstNameOptions] = useState([]);
  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...waiver };
    tempState[key] = value;
    setWaiver(tempState);
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
    setWaiver({});
    setWaiverAssignmentId(null);
    setData([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setRespData(null);
  };

  const apply = (data) => {
    let obj = {};
    obj["actionTaken"] = data.actionTaken;
    obj["coordinator"] = data.coordinator;
    obj["expiryDate"] = data.expiryDate;
    obj["issueDate"] = data.issueDate;
    obj["remarks"] = data.remarks;
    obj["reminderDate"] = data.reminderDate;
    obj["storeType"] = data.storeType;
    obj["territory"] = data.territory;
    obj["waiverType"] = data.waiverType;
    setWaiverAssignmentId(data.id);
    setWaiver(obj);
    setRespData(data);
  };

  const listWaiverIds = () => {
    invokeApi(HTTP_METHODS.GET, HOSTNAME + REST_URLS.GET_WAIVER_TYPES)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          let data = res.map((iter) => {
            return {
              value: iter.id,
              label: iter.name,
            };
          });
          setWaiverTypeOptions(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    listWaiverIds();
  }, []);

  const update = () => {
    let body = {
      storeType: waiver.storeType,
      store: parseInt(storeInfo.storeNo),
      sequenceNo: storeInfo.sequenceNo,
      satelliteNo: storeInfo.satelliteNo,
      territory: waiver.territory,
      coordinator: waiver.coordinator,
      issueDate: waiver.issueDate,
      expiryDate: waiver.expiryDate,
      reminderDate: waiver.reminderDate,
      remarks: waiver.remarks,
      actionTaken: waiver.actionTaken,
    };
    if (typeof waiver.waiverType !== "number") {
      const type = waiverTypeOptions.find(
        (ele) => ele.label === waiver.waiverType
      )?.value;
      body["waiverType"] = type;
    } else {
      body["waiverType"] = waiver.waiverType;
    }
    if (Number.isInteger(parseInt(waiverAssignmentId))) {
      invokeApi(
        HTTP_METHODS.PATCH,
        HOSTNAME + REST_URLS.WAIVER_ASSIGNMENT_PUT + waiverAssignmentId,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully");
            setPageArray([]);
            getAssignment(1, storeInfo.sequenceNo, null, true);
          } else {
            toast.error("Update Failed");
          }
        })
        .catch((error) => {
          toast.error("Update Failed");
        });
    }
  };

  const createHandler = () => {
    let body = {
      storeType: waiver.storeType,
      store: parseInt(storeInfo.storeNo),
      sequenceNo: storeInfo.sequenceNo,
      satelliteNo: storeInfo.satelliteNo,
      territory: waiver.territory,
      coordinator: waiver.coordinator,
      issueDate: waiver.issueDate,
      expiryDate: waiver.expiryDate,
      reminderDate: waiver.reminderDate,
      remarks: waiver.remarks,
      actionTaken: waiver.actionTaken,
      waiverType: waiver.waiverType,
    };
    invokeApi(
      HTTP_METHODS.POST,
      HOSTNAME + REST_URLS.WAIVER_ASSIGNMENT_POST,
      body
    )
      .then((res) => {
        if (res.id) {
          toast.success("Created Successfully", { autoClose: 2000 });
          getAssignment(1, storeInfo.sequenceNo, null, true);
          setIsCreate(false);
        }
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          setIsCreate(false);
        }
      })
      .catch((error) => {
        toast.error("Create Failed", { autoClose: 2000 });
      });
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: `${p.storeNo}`,
          id: p.storeNo,
          satellite: p.satelliteNum,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const getAssignment = (pg, seqNo, type, isEmptyArray) => {
    const params = {
      sortBy: "-createdAt",
      page: pg,
      limit: 50,
      store: storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
    };
    if (seqNo || storeInfo.sequenceNo) {
      params["sequenceNo"] = seqNo || storeInfo.sequenceNo;
    }
    if (type) {
      params["waiverType"] = type;
    }
    if (!pageArray.includes(pg) || isEmptyArray) {
      if (params?.store || params?.satelliteNo || params?.sequenceNo) {
        plainApi(
          HTTP_METHODS.GET,
          `${HOSTNAME}${REST_URLS.GET_WAIVERS}`,
          null,
          params
        )
          .then((response) => {
            setTotalPages(response.totalPages + 1);
            const result = response.results.map((iter) => ({
              id: iter?.id,
              storeType: iter?.storeType,
              territory: iter?.territory,
              coordinator: iter?.coordinator,
              waiverType: iter?.waiverType?.name,
              issueDate: dateFun(iter?.issueDate),
              expiryDate: dateFun(iter?.expiryDate),
              reminderDate: dateFun(iter?.reminderDate),
              remarks: iter?.remarks,
              actionTaken: iter?.actionTaken,
            }));
            if (pg == 1) {
              setData(result);
              setPageArray([1]);
            } else {
              setData([...data, ...result]);
            }

            let array = [...pageArray];
            array.push(pg);
            setPageArray(array);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getAssignment(1);
    }
  }, []);

  useEffect(() => {
    if (totalPages === null) {
      getAssignment(pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getAssignment(pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        sequenceNo: "",
        satelliteNo: "",
      });
    } else {
      clearData();
    }
  };

  const selectHandle = (name, value) => {
    let tempState = { ...waiver };
    tempState[name] = value;
    setWaiver(tempState);
  };
  const getNames = (searchTerm) => {
    if (namePreviousController.current) {
      namePreviousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    namePreviousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "firstName/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map(
        (p) => `${p.firstName} ${p.lastName}`
      );
      setFirstNameOptions(updatedOptions);
    });
  };
  const onNameChange = (value) => {
    if (value) {
      getNames(value);
      setWaiver((prevVal) => ({ ...prevVal, coordinator: value }));
    } else {
      setWaiver((prevVal) => ({ ...prevVal, coordinator: "" }));
    }
  };

  return (
    <div className="waiver-main-con">
      <Grid container md={12} sx={{ mt: 1, mb: 2 }} spacing={3}>
        <Grid item md={2} xs={4} style={{ paddingTop: "5px" }}>
          <Autocomplete
            className="subs-autocomplete"
            id="free-solo-demo"
            freeSolo
            style={{ height: "20px" }}
            getOptionLabel={(option) => {
              return option.title ? option.title : "";
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                type="number"
                className="sel-item"
                label="Store"
              />
            )}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                let temp = {};
                temp["store"] = val.id;
                setWaiver(temp);
                if (val) {
                  getAllSequenceNumber(val.id);
                }
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4} style={{ paddingTop: "5px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : null
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        <Grid item md={2} xs={4} style={{ paddingTop: "5px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : null
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));
              if (!isCreate) {
                getAssignment(1, val?.value);
              }
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        {!isCreate && (
          <Grid item md={2} style={{ paddingTop: "5px" }}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Waiver Type"
              className="sel-item mr"
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 10000000 }),
              }}
              options={waiverTypeOptions}
              onChange={(val) => {
                clearData(false);
                getAssignment(1, storeInfo?.sequenceNo, val?.value, true);
              }}
            />
          </Grid>
        )}

        <Grid item md={4} xs={12} style={{ height: "35px", paddingTop: "0px" }}>
          {!isCreate && (
            <div style={{ display: "flex", float: "right" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  setWaiver({ store: null });
                  setWaiverAssignmentId(null);
                  setData([]);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload(
                    "waiver_details_" + storeInfo.storeNo,
                    respData
                  );
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        spacing={3}
        sx={{ mt: 0, mb: 2, display: "flex", alignItems: "flex-end" }}
      >
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: "0px 20px 0px 20px",
            height: "25px",
            margin: "-15px 0px 0px 0px",
          }}
        >
          <p className="title header">Waiver Details</p>
        </Grid>

        <Grid item md={3} xs={6}>
          <p className="input-title-paragrah">Waiver Type</p>
          <Select
            classNamePrefix="custom_select"
            placeholder="Waiver Type"
            className="sel-item mr"
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 20 }),
            }}
            options={waiverTypeOptions}
            value={
              waiver.waiverType
                ? waiverTypeOptions.find((i) => i.label == waiver.waiverType)
                : ""
            }
            onChange={(val) => {
              selectHandle("waiverType", val.value);
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <p className="input-title-paragrah">Type</p>
          <Select
            placeholder="Select Type"
            classNamePrefix="custom_select"
            options={CLOSING_REASON}
            value={
              waiver.storeType
                ? { label: waiver.storeType, value: waiver.storeType }
                : ""
            }
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 20 }),
            }}
            onChange={(e) =>
              setWaiver((prevVal) => ({ ...prevVal, storeType: e?.value }))
            }
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            label="Territory"
            fullWidth
            type="number"
            className="sel-item subs-text-field"
            value={waiver.territory || ""}
            name="territory"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            className="subs-autocomplete"
            getOptionLabel={(option) => {
              return option ? option.toString() : "";
            }}
            options={firstNameOptions}
            onInputChange={(event, value) => onNameChange(value)}
            inputValue={waiver.coordinator || ""}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setWaiver((prevVal) => ({ ...prevVal, coordinator: "" }));
                }}
              />
            }
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Coordinator" />
            )}
            onChange={(e, val) => {
              if (val) {
                setWaiver((prevVal) => ({ ...prevVal, coordinator: val }));
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container md={12} spacing={3} sx={{ mt: 2 }}>
        <Grid item md={4} xs={6}>
          <TextField
            id="expiration-date"
            type="date"
            fullWidth
            variant="outlined"
            label="Expiation Date"
            className="sel-item subs-text-field"
            value={waiver.expiryDate || ""}
            name="expiryDate"
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <TextField
            id="reminder-date"
            type="date"
            fullWidth
            variant="outlined"
            label="Reminder Date"
            className="sel-item subs-text-field"
            value={waiver.reminderDate || ""}
            name="reminderDate"
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <p className="input-title-paragrah"></p>
          <TextField
            id="issue-date"
            type="date"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            label="Issue Date"
            value={waiver.issueDate || ""}
            name="issueDate"
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container md={12} spacing={3} sx={{ mt: 1 }}>
        <Grid item md={6} xs={6}>
          <p className="input-title-paragrah">Action Taken</p>
          <TextareaAutosize
            cols={20}
            maxRows={7}
            minRows={3}
            placeholder="Action Taken"
            style={{ width: "100%" }}
            value={waiver.actionTaken || ""}
            name="actionTaken"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <p className="input-title-paragrah">Remarks</p>
          <TextareaAutosize
            cols={20}
            maxRows={7}
            minRows={3}
            placeholder="comments"
            style={{ width: "100%" }}
            value={waiver.remarks || ""}
            name="remarks"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {!isCreate && (
        <div>
          <p className="title header">Waiver Listing </p>
          <div>
            <StickyHeaderCon
              dataList={data}
              columnsList={columns}
              containerHeight="waiver-listing-con"
              setPages={setPages}
              setRefresh={setRefresh}
              hasMore={hasMore}
              edit={(data) => {
                apply(data);
              }}
            />
          </div>
        </div>
      )}

      <Grid item md={2} xs={12} style={{ height: "35px", paddingTop: "0px" }}>
        <span
          style={{
            // backgroundColor: "#80808033",
            display: "flex",
            padding: "5px",
            borderRadius: "5px",
            float: "right",
          }}
        >
          {isCreate && (
            <Button
              size="small"
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => {
                setWaiver({ store: null });
                setWaiverAssignmentId(null);
                setData([]);
                setIsCreate(false);
              }}
            >
              Cancel
            </Button>
          )}
          {!isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                !checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  storeInfo.satelliteNo
                )
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button>
          )}
          {isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                !checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  storeInfo.satelliteNo
                )
              }
              onClick={() => {
                createHandler();
              }}
            >
              Save
            </Button>
          )}
        </span>
      </Grid>
    </div>
  );
};

export default WaiverAssignment;
