import { formatDate } from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../../utils/constants';
import { getAdvanceAmount, getPOAmountWithoutTax } from '../helper';

export const createPayloadMapper = (
  formDetails,
  items,
  budgetDetails,
  poType,
  documentLinks
) => {
  const {
    vendor = {},
    department = {},
    currency = '',
    deliveryLocation = {},
    billingLocation = {},
    company = {},
  } = formDetails;

  const { site = '' } = budgetDetails;
  let orderItems = [];

  Object.keys(items || {}).map((key) => {
    items[key].forEach((item) => {
      const { rate, quantity, taxPercentage = 0 } = item;
      if (!quantity) {
        return
      }
      let taxAmount = Math.round(
        rate * quantity * (taxPercentage / 100)
      ).toFixed(2);

      orderItems.push({
        lineItem: key,
        item: item?.itemId,
        uom: item?.uom,
        quantity: item?.quantity,
        rate: item.rate,
        amount: Math.round(rate * quantity).toFixed(2),
        taxRate: item.taxPercentage || 0,
        taxAmount: taxAmount,
        totalAmount: rate * quantity + +taxAmount,
        description: item.description,
        remarks: item.remarks,
        hsnCode:item.hsnCode || '',
      });
    });
  });

  let payload = {
    company: company?.id,
    department: department?._id,
    site: site,
    vendorCode: vendor?.code,
    vendorName: vendor?.name,
    vendorPancard: vendor?.pan || 'Not found',
    vendorGst: vendor?.gst || 'Not found',
    poType: poType,
    currency: currency?.value,
    budget: budgetDetails?.id,
    poDate: formDetails?.poDate,
    remarks: formDetails?.remarks,
    termsAndConditions: formDetails?.termsAndConditions,
    paymentTerms: formDetails?.paymentTerms,
    agreement: formDetails?.agreement,
    advance: {
      advancePercentage: formDetails.advancePercent || 0,
      advanceAmount: getAdvanceAmount(
        formDetails.advancePercent || 0,
        getPOAmountWithoutTax(items)
      ),
    },
    deliveryLocationId: deliveryLocation?.id,
    billingLocationId: billingLocation?.id,
    forex: formDetails.forex,
    items: orderItems,
  };

  if (documentLinks?.length > 0) {
    payload.documentLinks = documentLinks.map((d) => d.docUrl);
  }

  return payload;
};

export const mapPoFileUploadResponse = (uploadedItems) => {
  return uploadedItems.reduce((group, uploadedItem) => {
    const { lineItemId } = uploadedItem;
    uploadedItem.itemId = uploadedItem.id;
    group[lineItemId] = group[lineItemId] ?? [];
    group[lineItemId].push(uploadedItem);
    return group;
  }, {});
};

export const mapPOResponseToFormDetails = (
  response,
  departmentList,
  vendors
) => {
  const {
    budget = {},
    advance = {},
    company = {},
    poType = '',
    vendorCode = '',
    department,
    currency = {},
    poDate = '',
    items = [],
    poNumber = '',
    termsAndConditions = '',
    creatorName = '',
    approvalId = {},
  } = response;

  const selectedVendor = vendors.find((v) => v.code === vendorCode) || {};

  const poItems = items.reduce((group, itemObject) => {
    const { lineItem = {}, item = {} } = itemObject;

    const { id } = lineItem;
    const { subCategory = {} } = item || {};
    itemObject.itemId = item?.id;
    itemObject.itemCode = item?.itemCode;
    itemObject.taxPercentage = itemObject?.hasOwnProperty('taxRate')
      ? itemObject?.taxRate
      : item?.taxPercentage;
    // itemObject.hsnCode = item.hsnCode || ''
    itemObject.name = item?.name;
    itemObject.subcategoryName = subCategory?.name;
    itemObject.subcategoryId = subCategory?.id;
    itemObject.subCategory = subCategory;
    group[id] = group[id] ?? [];
    group[id].push(itemObject);
    return group;
  }, {});

  return {
    mappedResponse: {
      department: departmentList.find((d) => d._id === department) || {},
      vendor: selectedVendor,
      currency: {
        value: currency?.id,
        label: currency?.name,
      },
      poDate: formatDate(poDate, DATE_FORMATS['YYYY-MM-DD']),
      agreement: response?.agreement || '',
      remarks: response?.remarks || '',
      deliveryLocation: response?.deliveryLocationId || {},
      billingLocation: response?.billingLocationId || {},
      company: company,
      paymentTerms: response?.paymentTerms || '',
      advancePercent: advance?.advancePercentage,
      poType,
      budgetDetails: budget,
      forex: response?.forex || 0,
      selectedLineItemIds: items?.map?.((i) => (i.lineItem && i.lineItem.id) || 0),
      poNumber,
      termsAndConditions,
      creatorName,
      approvalId,
    },
    mappedAutoCompleteInputValues: {
      vendor: selectedVendor?.name,
      company: company?.name,
      deliveryLocation: (response.deliveryLocationId || {}).name,
      billingLocation: (response.billingLocationId || {}).name,
      currency: currency?.name,
    },
    poItems,
  };
};
