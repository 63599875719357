import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  CloseOutlined,
  LogoutOutlined,
  NotificationsNone,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./index.scss";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
import SideMenu from "../side-menu";
import Badge from "@mui/material/Badge";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { CF_APPROVAL_COMMON_ROUTE } from "../../../common-utilities/utils/end-points/cf-approval";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import Intranetlogout from "../../../../core/header/logout";
import { tokenMainKeys } from "../../../../utils/constants";
import Logout from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "rsuite";
import SideMenuV2 from "../side-menu-v2";
import HomeRedirect from "../../../common-utilities/core/home-redirect";

const HeaderV2 = ({
  appName,
  reloadPendingApprovals,
  setReloadPendingApprovals,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [unapprovedFormsCount, setunapprovedFormsCount] = useState(0);
  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.UNAPPROVED_FORMS}`,
      null,
      { page: 1, limit: 10, sortBy: "-createdAt" }
    )
      .then((response) => {
        const count = response?.totalResults || 0;
        setunapprovedFormsCount(count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (reloadPendingApprovals) {
      setReloadPendingApprovals(false);
      loadData();
    }
  }, [reloadPendingApprovals]);

  useEffect(() => {
    let user = getOfflineData("user");
    setUserDetails(user);
  }, []);
  return (
    <div className="side-nav-con">
      <div className="side-nav-header">
        <HomeRedirect localstorageKey={tokenMainKeys.cfApproval} />
        <h4 className="app-name">{appName}</h4>
      </div>

      <div className="subway-current-app-header-container">
        <div></div>
        <SideMenuV2 />
      </div>
    </div>
  );
};

export default HeaderV2;
