import React, { useEffect, useState } from 'react';
import { INVOICE_TRACKING_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { Button, TextField } from '@mui/material';
import './index.scss';
import { exportToExcel } from '../../../../common-utilities/utils';
import { formatDownloadReports } from './utils';

const DepartmentList = ({}) => {
  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (params) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.QUERY_VENDORS}`,
      null,
      { ...params, addOnBoardDetails: true }
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (!response?.message) {
            setBudgetList(response);
          }
        }
      })
      .catch((err) => {});
  };

  const downloadData = () => {
    const params = { ...filters };
    params.page = 1;
    params.limit = budgetList.totalResults;
    params.searchKey = searchKey;
    params.addOnBoardDetails = true;
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.QUERY_VENDORS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(
            formatDownloadReports(response.results),
            'vendor-reports'
          );
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="item-list">
      <div className="il-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                ...filters,
                page: 1,
                searchKey,
              })
            }
          >
            Search
          </Button>
        </div>

        <Button
          disabled={!budgetList.totalResults}
          variant="outlined"
          onClick={downloadData}
        >
          Download
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig()}
        nestedValue={true}
        dataList={budgetList.results}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          loadData({
            ...filters,
            page,
            searchKey,
          });
          setFilters({
            ...filters,
            page,
            
          });
        }}
      />
    </div>
  );
};

export default DepartmentList;
