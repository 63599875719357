import { dateFun } from "../../../common-utilities/utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
export const imprestReason = [
  { label: "Imprest Increase", value: "Imprest Increase" },
  { label: "Imprest Decrease", value: "Imprest Decrease" },
];
export const paymentMode = [
  { label: "Bank Transfer", value: "Bank Transfer" },
  { label: "Credit Card", value: "Credit Card" },
];
export const balanceType = [
  { label: "Book Balance", value: "bookBalance" },
  { label: "Physical Balance", value: "physicalBalance" },
];
export const getHeaderConfig = () => [
  {
    label: "Reason",
    key: "reason",
    render: (data) => {
      return <div>{data?.reason}</div>;
    },
  },
  {
    label: "Date",
    key: "date",
    render: (data) => {
      return <div>{dateFun(data?.date, true)}</div>;
    },
  },
  {
    label: "Payment Mode",
    key: "paymentMode",
    render: (data, index) => {
      return <div>{data?.paymentMode}</div>;
    },
  },
  {
    label: "Payment Reference No.",
    key: "referenceNo",
    render: (data, index) => {
      return <div>{data?.referenceNo}</div>;
    },
  },
  {
    label: "Amount",
    key: "amount",
    render: (data, index) => {
      return <div>{data?.amount}</div>;
    },
  },
  {
    label: "Remarks",
    key: "remarks",
    render: (data, index) => {
      return <div>{data?.remarks}</div>;
    },
  },

  {
    label: "View files",
    key: "view files",
    render: (data, onClick, index) => {
      return (
        <RemoveRedEyeIcon
          onClick={() => onClick(data, "view files")}
          sx={{ cursor: "pointer" }}
        />
      );
    },
  },
];
