export const brandKeysToLabelsForCompetitorInfo = {
  dominos: "Domino's Pizza",
  burgerKing: "Burger King",
  kfc: "KFC",
  pizzaHut: "Pizza Hut",
  mcd: "McDonald's",
  others:"Others"
};

export const regions = {
  north: "North",
  south: "South",
  east: "East",
  west: "West",
  central: "Central",
};

export const regionsList = Object.values(regions);
