import * as React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./index.scss";
import CustomPiChart from "./pi-chart";

const SupportPerson = (props) => {
  const [personDetails, setPersonDetails] = React.useState(null);
  const { closed, opened, reopened, resolved } = props.data;
  let temp = {};
  closed.forEach((iter) => {
    temp[iter.personName] = {
      ...iter,
      closed: iter.closed ? iter.closed + iter.count : iter.count,
    };
  });

  reopened.forEach((iter) => {
    temp[iter.personName] = {
      ...iter,
      reopened: iter.reopened ? iter.reopened + iter.count : iter.count,
    };
  });

  opened.forEach((iter) => {
    temp[iter.personName] = {
      ...iter,
      opened: iter.opened ? iter.opened + iter.count : iter.count,
    };
  });

  resolved.forEach((iter) => {
    temp[iter.personName] = {
      ...iter,
      resolved: iter.resolved ? iter.resolved + iter.count : iter.count,
    };
  });

  let totalList = Object.keys(temp).map((iter) => {
    let val = temp[iter];
    return {
      name: val.personName,
      closed: val.closed ? val.closed : 0,
      opened: val.opened ? val.opened : 0,
      reopened: val.reopened ? val.reopened : 0,
      resolved: val.resolved ? val.resolved : 0,
    };
  });

  let closeChart = resolved.map((iter) => {
    return {
      name: iter.personName,
      count: iter.count,
    };
  });

  let openChart = opened
    .map((iter) => {
      return {
        name: iter.personName,
        count: iter.count,
      };
    })
    .filter((ele) => ele.name && ele);

  let openTotal = openChart.reduce((sum, iter) => {
    return sum + iter.count;
  }, 0);

  let closeTotal = closeChart.reduce((sum, iter) => {
    return sum + iter.count;
  }, 0);

  return (
    <div className="p10">
      <Grid container spacing={2} style={{ marginTop: "3px" }}>
        <Grid item xs={12} md={6} className="p10">
          Support Person Overview
        </Grid>
        <Grid item md={6} xs={12} className="pt0 flex">
          {
            <div className="leftAuto flex">
              <div className="flex pt5">
                {personDetails !== null && (
                  <Avatar
                    alt={"consultantData.consultant.name"}
                    src="/static/images/avatar/1.jpg"
                    className="name-avatar"
                  />
                )}
                <Autocomplete
                  id="sp"
                  size="small"
                  sx={{ width: 250 }}
                  options={totalList}
                  getOptionLabel={(option) => option.name}
                  autoHighlight
                  onChange={(e, val) => {
                    setPersonDetails(val);
                  }}
                  value={personDetails}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Support Person"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </div>
            </div>
          }
        </Grid>
        <Grid item xs={12} md={10}>
          <div className="other-info-container">
            <CustomPiChart
              title={"Top Closed Tickets"}
              data={closeChart}
              xDataKey={"name"}
              bDataKey={"count"}
              enableDownload={true}
              convertKeys={{ name: "Name", count: "Count" }}
              name="Closed Tickets"
            />
            <CustomPiChart
              title={"Top Open Tickets"}
              data={openChart}
              xDataKey={"name"}
              bDataKey={"count"}
              enableDownload={true}
              convertKeys={{ name: "Name", count: "Count" }}
              name="Open Tickets"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className="support-person-container">
            <br />
            {personDetails !== null ? (
              <center>
                <Avatar
                  alt={personDetails.name}
                  src="/static/images/avatar/1.jpg"
                  className="hw100"
                />
                <div className="f22 pt15">{personDetails.name}</div>
              </center>
            ) : (
              <center className="f22 pt50">Ticket Count Overview</center>
            )}
            <br />
            <Grid
              container
              spacing={2}
              className="support-person-value-container"
            >
              <Grid item xs={12} md={6} className="support-person-value-label">
                <center>
                  {personDetails ? personDetails.opened : openTotal}
                </center>
              </Grid>
              <Grid item xs={12} md={6} className="support-person-value-label">
                <center>
                  {personDetails ? personDetails.resolved : closeTotal}
                </center>
              </Grid>
              <Grid item xs={12} md={6} className="support-person-value">
                <center> Total Open Tickets </center>
              </Grid>
              <Grid item xs={12} md={6} className="support-person-value pl10">
                <center> Total Closed Tickets </center>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupportPerson;
