import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Button } from "@mui/material";
import Select from "react-select";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";


import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import SyncIcon from "@mui/icons-material/Sync";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, Divider } from "@mui/material";
import { toast } from "react-toastify";
import { invokeApi } from "../../utils/http-service";
import { validAddressTypes } from "../../utils/constants";

import {
  formatDate,
  zeroFieldValidation,
} from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import {
  TabPanel,
  MainTabContainer,
} from "../../../common-utilities/core/tabs";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";

const StoreDetails = () => {
  const [value, setValue] = useState(0);
  const labels = ["Drawing", "Store", "Lease"];

  const [buttonStatus, setbuttonStatus] = useState("");

  const buttonStatuses = {
    create: "create",
    save: "save",
  };
  const [data, setData] = useState([
    {
      drawingVersion: "One Page",
      drawingDate: "02-May-2019",
      designer: "Nitin K Dhureja",
    },
    {
      drawingVersion: "Full Set",
      drawingDate: "29-May-2019",
      designer: "Nitin K Dhureja",
    },
    {
      drawingVersion: "Full Set Revision",
      drawingDate: "11-June-2019",
      designer: "Nitin K Dhureja",
    },
    {
      drawingVersion: "Full Set Revision",
      drawingDate: "30-Jul-2019",
      designer: "Nitin K Dhureja",
    },
  ]);
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };

  const [allFields, setAllFields] = useState({
    type: "",
    requestDate: "",
    locationType: "",
  });

  const [storeDetails, setstoreDetails] = useState({
    storeNo: "",
    satelliteNum: "",
  });

  const [addressDetails, setAddressDetails] = useState({
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    county: "",
    country: "",
    attn: "",
    zipCode: "",
  });

  const resetAllDetails = (
    resetStoreDetails,
    resetFieldDetails,
    partialStoreDetails,
    resetAddressDetails
  ) => {
    if (resetStoreDetails) {
      setstoreDetails({
        storeNo: "",
        satelliteNum: "",
      });
    }

    if (partialStoreDetails) {
      setstoreDetails((prevDetails) => ({
        ...prevDetails,
        ...partialStoreDetails,
      }));
    }

    if (resetFieldDetails) {
      setAllFields({
        type: "",
        requestDate: "",
        locationType: "",
      });
    }

    if (resetAddressDetails) {
      setAddressDetails({
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        country: "",
        county: "",
        attn: "",
        zipCode: "",
      });
    }
  };

  const checkForAddressErrors = () => {
    const {
      address1,
      address2,
      address3,
      city,
      state,
      country,
      county,
      attn,
      zipCode,
    } = addressDetails;

    if (zeroFieldValidation(address1) || `${address1}`.trim() === "") {
      toast.error("Please select Located in in address details", {
        autoClose: 2000,
      });
      return true;
    }

    if (zeroFieldValidation(county) || `${county}`.trim() === "") {
      toast.error("Please select county in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(address2) || `${address2}`.trim() === "") {
      toast.error("Please select Street/PO Box in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(address3) || `${address3}`.trim() === "") {
      toast.error("Please select Additional Address in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(city) || `${city}`.trim() === "") {
      toast.error("Please select City in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(state) || `${state}`.trim() === "") {
      toast.error("Please select State/Prov in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(country) || `${country}`.trim() === "") {
      toast.error("Please select Country in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(attn) || `${attn}`.trim() === "") {
      toast.error("Please select Attention in address details", {
        autoClose: 2000,
      });
      return true;
    }
    if (zeroFieldValidation(zipCode) || `${zipCode}`.trim() === "") {
      toast.error("Please select Postal Code in address details", {
        autoClose: 2000,
      });
      return true;
    }

    return false;
  };

  const checkStoreDetailsForErrors = () => {
    let storeNumber = storeDetails.storeNo;
    if (typeof storeNumber === "string") {
      storeNumber = storeNumber.trim();
    }

    if (zeroFieldValidation(storeNumber)) {
      toast.error("Please select store number");
      return true;
    }

    return false;
  };

  const checkStoreDrawingDetails = () => {
    const { type, requestDate, locationType } = allFields;

    if (zeroFieldValidation(type) || `${type}`.trim() === "") {
      toast.error("Please select type", { autoClose: 2000 });
      return true;
    }
    if (zeroFieldValidation(requestDate) || `${requestDate}`.trim() === "") {
      toast.error("Please select Request Date", { autoClose: 2000 });
      return true;
    }
    if (zeroFieldValidation(locationType) || `${locationType}`.trim() === "") {
      toast.error("Please select Location type", { autoClose: 2000 });
      return true;
    }
    return false;
  };

  const setValuesOnChange = (e) => {
    const { name, value } = e.target;
    setAllFields((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  const setStoreDetailsOnChange = (e) => {
    const { name, value } = e.target;
    setstoreDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const setAddressDetailsOnChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const getStoreDrawingDetails = (storeNumber) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.STORE_DRAWING_BASE_URL}${REST_URLS.GET_STORE_DRAWING_FOR_STORE_NO}${storeNumber}`,
      null,
      null
    )
      .then((response) => {
        if (response && !response.message) {
          let address = response?.address;
          if (!address) {
            address = {};
          }

          let storeDrawing = response?.storeDrawing;
          if (!storeDrawing) {
            storeDrawing = {};
          }

          const {
            id,
            requestDate = "",
            locationType,
            type = "",
          } = storeDrawing;
          const {
            address1 = "",
            address2 = "",
            address3 = "",
            city = "",
            state = "",
            country = "",
            attn = "",
            county = "",
            zipCode = "",
          } = address;
          setAddressDetails((prevDetails) => ({
            ...prevDetails,
            address1,
            address2,
            address3,
            city,
            state,
            country,
            county,
            attn,
            zipCode,
          }));
          setAllFields((prevVal) => ({
            ...prevVal,
            id,
            locationType,
            type,
            requestDate: formatDate(requestDate, DATE_FORMATS["YYYY-MM-DD"]),
          }));
          setbuttonStatus(buttonStatuses.save);
        } else {
          if (response?.message === "Store Drawing not found") {
            setbuttonStatus(buttonStatuses.create);
            resetAllDetails(false, true, null, true);
            toast.info("Please create store drawing details", {
              autoClose: 2000,
            });
          } else {
            setbuttonStatus("");
            toast.error(response?.message, { autoClose: 2000 });
          }
        }
      })
      .catch((error) => {
        setbuttonStatus("");
        console.log(error);
        // resetAllDetails(false, true, null, true);
        toast.error("Sorry something went wrong", { autoClose: 2000 });
      });
  };

  const getCreateUpdatePayload = () => {
    const {
      address1,
      address2,
      address3,
      city,
      state,
      country,
      county,
      attn,
      zipCode,
    } = addressDetails;

    const { type, requestDate, locationType, id } = allFields;

    return {
      type,
      requestDate,
      locationType,
      id,
      address: {
        address1,
        address2,
        address3,
        city,
        state,
        country,
        county,
        attn,
        zipCode,
        type: validAddressTypes.drawing,
      },
    };
  };

  const getStoreDetails = (storeNumber) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_STORE_DETAILS_BY_STORE_NUMBER}${storeNumber}`,
      null,
      null
    )
      .then((response) => {
        if (response && !response.code) {
          const { storeNo, satelliteNum } = response;
          setstoreDetails((prevVal) => ({
            ...prevVal,
            storeNo: `${storeNo}`,
            satelliteNum: `${satelliteNum}`,
          }));
          getStoreDrawingDetails(storeNumber);
        } else {
          setbuttonStatus("");
          toast.error(response?.message, { autoClose: 2000 });
          resetAllDetails(false, false, { satelliteNum: "" }, false);
        }
      })
      .catch((error) => {
        setbuttonStatus("");
        // resetAllDetails(true, true, null, true);
        resetAllDetails(false, false, { satelliteNum: "" }, false);
        toast.error("Sorry something went wrong", { autoClose: 2000 });
      });
  };

  const getDataByStorennumber = () => {
    const storeErrors = checkStoreDetailsForErrors();
    if (storeErrors) {
      return;
    }
    getStoreDetails(storeDetails.storeNo);
  };

  const updatedStoreDrawingsById = () => {
    const storeErrors = checkStoreDetailsForErrors();
    if (storeErrors) {
      return;
    }

    const storeDrawingDetailsErrors = checkStoreDrawingDetails();
    if (storeDrawingDetailsErrors) {
      return;
    }

    const addressErrors = checkForAddressErrors();
    if (addressErrors) {
      return;
    }

    const payload = getCreateUpdatePayload();
    const { id } = payload;
    delete payload.id;
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.STORE_DRAWING_BASE_URL}${storeDetails.storeNo}${REST_URLS.UPDATE_STORE_DRAWING}${id}`,
      payload,
      null
    )
      .then((response) => {
        if (response && !response.message) {
          toast.success("Store Drawing updated successfully", {
            autoClose: 2000,
          });
          setbuttonStatus(buttonStatuses.save);
        } else {
          setbuttonStatus("");
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        setbuttonStatus("");
        toast.error("Sorry something went wrong", { autoClose: 2000 });
      });
  };

  const createNewStoreDrawings = () => {
    const storeErrors = checkStoreDetailsForErrors();
    if (storeErrors) {
      return;
    }

    const storeDrawingDetailsErrors = checkStoreDrawingDetails();
    if (storeDrawingDetailsErrors) {
      return;
    }

    const addressErrors = checkForAddressErrors();
    if (addressErrors) {
      return;
    }

    const payload = getCreateUpdatePayload();
    delete payload.id;
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.STORE_DRAWING_BASE_URL}${storeDetails.storeNo}${REST_URLS.CREATE_STORE_DRAWING}`,
      payload,
      null
    )
      .then((response) => {
        if (response && !response.message) {
          setAllFields((prevVal) => ({
            ...prevVal,
            id: response?.storeDrawing?.id,
          }));
          toast.success("Store Drawing created successfully", {
            autoClose: 2000,
          });
          setbuttonStatus(buttonStatuses.save);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Sorry something went wrong", { autoClose: 2000 });
      });
  };

  return (
    <div className="storeDrawingDetails">
      <div className="title">Drawing Selection</div>

      <Grid xs={12} container>
        <Grid xs={9}>
          <div style={displayContainer}>
            <TextField
              value={storeDetails.storeNo}
              name="storeNo"
              onChange={setStoreDetailsOnChange}
              label="Store"
            />

            <TextField
              value={storeDetails.satelliteNum}
              name="satelliteNum"
              disabled={true}
              label="Satellite"
            />
            <IconButton>
              <InfoIcon color="primary" size="large" fontSize="60" />
            </IconButton>
            <IconButton onClick={getDataByStorennumber}>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
            <Select placeholder="Lease" classNamePrefix="custom_select" />
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
            <Select placeholder="Drawing" classNamePrefix="custom_select" />
            <IconButton>
              <ImagesearchRollerIcon
                color="primary"
                size="large"
                fontSize="60"
                className="rotate-roller"
              />
            </IconButton>
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </div>
        </Grid>
        <Grid xs={3}>
          <div className="last-buttons">
            <Button
              size="small"
              className={`${
                buttonStatus === buttonStatuses.create
                  ? "storeDrawingDetailsButton-active"
                  : "storeDrawingDetailsButton"
              }`}
              onClick={() => {
                if (buttonStatus === buttonStatuses.create) {
                  createNewStoreDrawings();
                }
              }}
              startIcon={<FileCopyIcon />}
            >
              Create
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider style={{ paddingBottom: 10 }} />

      <Grid container spacing={1}>
        <Grid xs={5}>
          <div>
            <span className="title">Drawing Information </span>
          </div>
          <div className="g-r-2">
            <TextField
              value={allFields.type}
              name="type"
              onChange={setValuesOnChange}
              label="Type:"
            />
            <TextField
              label="Request Date:"
              type="date"
              value={allFields.requestDate}
              name="requestDate"
              onChange={setValuesOnChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>

        <Grid xs={7}>
          <div>
            <span className="title">Store Information </span>
          </div>
          <div className="g-r-2">
            <TextField
              label="Location Type:"
              InputLabelProps={{ shrink: true }}
              value={allFields.locationType}
              name="locationType"
              onChange={setValuesOnChange}
            />
            <div>
              <Button
                size="small"
                className="storeDrawingDetailsButton"
                startIcon={<ReceiptLongIcon />}
              >
                Assign To Store Or Lease
              </Button>
            </div>
          </div>
          <br />
          <div className="g-r-2">
            <div>
              <TextField
                label="Developer:"
                InputLabelProps={{ shrink: true }}
              />
              <IconButton>
                <InfoIcon color="primary" size="large" fontSize="60" />
              </IconButton>
              <IconButton>
                <ArrowCircleRightIcon
                  color="primary"
                  size="large"
                  fontSize="60"
                />
              </IconButton>
            </div>
            <div>
              <span style={{ position: "relative", top: "5px" }}>
                SSIPL/INDIA
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ height: "1rem" }}></div>

      <Grid container spacing={1}>
        <Grid xs={5}>
          <div className="store-drawing-container">
            <MainTabContainer
              mainClass=""
              tabContainerClass=""
              tabsClass="custom-tabs"
              value={value}
              setValue={setValue}
              labels={labels}
            />
            <div className="tab-panel-container">
              <TabPanel value={value} index={0}>
                <div>
                  <span className="title">Address Information</span>
                </div>
                <div className="g-r-2 mb-1">
                  <TextField
                    value="Drawing Address"
                    label="Type"
                    disabled={true}
                  />
                  <TextField
                    value={addressDetails.attn}
                    name="attn"
                    onChange={setAddressDetailsOnChange}
                    label="Attention"
                  />
                </div>

                <div className="g-r-2 mb-1">
                  <TextField
                    value={addressDetails.address1}
                    name="address1"
                    onChange={setAddressDetailsOnChange}
                    label="Located In"
                  />
                  <TextField
                    value={addressDetails.address2}
                    name="address2"
                    onChange={setAddressDetailsOnChange}
                    label="Street/ PO Box"
                  />
                </div>

                <div className="g-r-2 mb-1">
                  <TextField
                    value={addressDetails.address3}
                    name="address3"
                    onChange={setAddressDetailsOnChange}
                    label="Additional Address"
                  />
                  <TextField
                    value={addressDetails.city}
                    name="city"
                    onChange={setAddressDetailsOnChange}
                    label="City"
                  />
                </div>

                <div className="g-r-2 mb-1">
                  <TextField
                    value={addressDetails.county}
                    name="county"
                    onChange={setAddressDetailsOnChange}
                    label="County"
                  />
                  <div>
                    <TextField
                      className="w-80"
                      value={addressDetails.state}
                      name="state"
                      onChange={setAddressDetailsOnChange}
                      label="State/Prov"
                    />
                    <IconButton>
                      <ArrowCircleRightIcon
                        color="primary"
                        size="large"
                        fontSize="60"
                        className="gray"
                      />
                    </IconButton>
                  </div>
                </div>

                <div className="mb-1"></div>

                <div className="g-r-2">
                  <TextField
                    value={addressDetails.country}
                    name="country"
                    onChange={setAddressDetailsOnChange}
                    label="Country"
                  />
                  <div>
                    <TextField
                      value={addressDetails.zipCode}
                      name="zipCode"
                      onChange={setAddressDetailsOnChange}
                      label="Postal Code:"
                    />
                    <IconButton>
                      <ImagesearchRollerIcon
                        color="primary"
                        size="large"
                        fontSize="60"
                        className="rotate-roller"
                      />
                    </IconButton>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Grid>

        {/* <Grid xs={7}>
          <div>
            <span className="title">Drawing Dates</span>
          </div>

          <Grid container spacing={1}>
            <Grid xs={9}>
              <div>Drag a column header here to group by that column</div>
              <div>4 record(s)</div>
              <div className="table-con">
                <TableContainer component={Paper}>
                  <Table >
                    <TableHead
                      sx={{ backgroundColor: "#008a52", color: "white" }}
                    >
                      <TableRow>
                        <TableCell className="table-cell">
                          Drawing Version
                        </TableCell>
                        <TableCell className="table-cell">
                          Drawing Date
                        </TableCell>
                        <TableCell className="table-cell">Designer</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((ele, index) => (
                        <TableRow key={index}>
                          <TableCell>{ele.drawingVersion}</TableCell>
                          <TableCell>{ele.drawingDate}</TableCell>
                          <TableCell>{ele.designer}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="header">Comments</div>

                <TextareaAutosize
                  className="commentText"
                  aria-label="minimum height"
                  minRows={8}
                  placeholder="Policy Number updated with the certificate number"
                />
              </div>
            </Grid>
            <Grid xs={3}>
              <div>
                <Button
                  className="storeDrawingDetailsButton"
                  startIcon={<FileCopyIcon />}
                >
                  New
                </Button>
              </div>
              <div>
                <Button
                  className="storeDrawingDetailsButton"
                  startIcon={<CloseIcon />}
                >
                  Delete
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid xs={12} container>
          <div className="last-buttons">
            <Button
              size="small"
              className="storeDrawingDetailsButton"
              startIcon={<SyncIcon />}
            >
              Reset
            </Button>
            <Button
              size="small"
              className={`${
                buttonStatus === buttonStatuses.save
                  ? "storeDrawingDetailsButton-active"
                  : "storeDrawingDetailsButton"
              }`}
              onClick={() => {
                if (buttonStatus === buttonStatuses.save) {
                  updatedStoreDrawingsById();
                }
              }}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            <Button
              size="small"
              className="storeDrawingDetailsButton"
              startIcon={<CloseIcon />}
            >
              Delete
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default StoreDetails;
