import React, { useState } from "react";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CommonBtn from "../../../common-utilities/core/common-button";

const EscalationMatrix = (escalationData) => {
  const [showEscalation, setShowEscalation] = useState(false);
  return (
    <div>
      <p
        onClick={() => {
          setShowEscalation(true);
        }}
        className="show_esc"
      >
        Show Escalation
      </p>
      {showEscalation && (
        <CustomModal
          title="Escalations"
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setShowEscalation(false);
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "#bdbdbd" }}>
                  <TableRow>
                    <TableCell>SLA</TableCell>
                    <TableCell align="center">Level</TableCell>
                    <TableCell align="right">Sla Escalation Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {escalationData.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{row.sla}h</TableCell>
                      <TableCell align="center">L{row.no}</TableCell>
                      <TableCell className="slaEmail" align="right">
                        {row?.escalateTo?.email}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <CommonBtn
                fullWidth={true}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowEscalation(false);
                }}
              >
                Close
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </div>
  );
};

export default EscalationMatrix;
