import { getHeaderConfig } from "./helper";
import { getStateDropdownList } from "../../../../common-utilities/utils/region-utils";
import { pick } from "../../../../common-utilities/utils";

export const statusTypes = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
];

export const locationTypes = [
  { label: "HO", value: "HO" },
  { label: "STORE", value: "STORE" },
];

export const getCreatedUpdatePayload = (formDetails) => {
  const data = pick(formDetails, [
    "name",
    "address",
    "city",
    "state",
    "gstNumber",
    "code",
    "locationType",
    "status",
  ]);
  return data;
};

export const defaultLists = {
  stateList: getStateDropdownList("India"),
  cityList: [],
};

export const formatDownloadReports = (data) => {
  const downloaderHeaders = getHeaderConfig().filter(
    (item) => !item.nonDownloadHeader
  );
  return (
    data?.map?.((item) => {
      const finalData = downloaderHeaders.reduce(
        (locationData, tableItemConfig) => {
          const { label, id, valueConversionLogic } = tableItemConfig;
          locationData[label] = item?.[id] != undefined ? item?.[id] : null;
          if (valueConversionLogic) {
            locationData[label] = valueConversionLogic(item?.[id]);
          }
          return locationData;
        },
        {}
      );
      return finalData;
    }) || []
  );
};
