import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckBoxSelect = ({
  options,
  value,
  onChange,
  labelKey,
  valueKey,
  label,
  id,
}) => {
  const handleChange = (option) => {
    let index = value.findIndex(
      (v) => v[valueKey] === option[valueKey] || v === option
    );
    if (index === -1) {
      onChange([...value, option]);
    } else {
      value.splice(index, 1);
      onChange([...value]);
    }
  };

  const isChecked = (op) => {
    return !!value.find((v) => v[valueKey] === op[valueKey] || v === op);
  };

  const valueText = () => {
    return value.map((v) => v[labelKey] || v);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel size="small" id={id}>
          {label}
        </InputLabel>
        <Select
          labelId={id}
          id="multiple-checkbox"
          multiple
          value={valueText()}
          size="small"
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option[valueKey] || option}
              value={option[labelKey] || option}
              onClick={() => handleChange(option)}
            >
              <Checkbox checked={isChecked(option)} />
              <ListItemText primary={option[labelKey] || option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CheckBoxSelect;
