import { allRegions } from "../../../../common-utilities/utils/region";
import { getLabelsAndValuesForSelect } from "../../../utils";

export const getStateList = (country, convertToSelectValues = true) => {
  if (!country || !allRegions[country]) {
    return [];
  }
  const countryDataWithStateKeys = allRegions[country];
  let states = Object.keys(countryDataWithStateKeys);
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(states, states);
  }
  return states;
};

export const getCityListByStateAndCountry = (
  country,
  state,
  convertToSelectValues = true
) => {
  if (!allRegions?.[country]?.[state]) {
    return [];
  }
  const cityValues = allRegions?.[country]?.[state];
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(cityValues, cityValues);
  }
  return cityValues;
};
