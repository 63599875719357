import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {
  getOfflineData,
  setOfflineData,
  clearOfflineData,
} from "./offline-services";
import { REST_URLS, INTRANETHOSTNAME } from "./end-points";

export const HTTP_METHODS = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "PATCH",
};

const redirectToIntranetPortal = (tokenKey) => {
  clearOfflineData(tokenKey, "user");
  clearOfflineData(tokenKey, "tokens");
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

const mainGetToken = async (tokenKey, slug) => {
  let tokens = getOfflineData(tokenKey, "tokens");
  if (tokens === "") {
    return;
  }
  let expiredAt =
    (tokens && tokens.access && new Date(tokens.access.expires)) ||
    new Date(1970);

  expiredAt.setMinutes(expiredAt.getMinutes() - 1);
  if (expiredAt > new Date()) {
    return (tokens && tokens.access && tokens.access.token) || "";
  } else {
    return await mainGetRefreshToken(tokenKey, slug, tokens.refresh.token);
  }
};

const mainGetRefreshToken = async (tokenKey, slug, refreshToken) => {
  try {
    const response = await trackPromise(
      fetch(`${INTRANETHOSTNAME}${REST_URLS.REFRESH_TOKEN}`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
          slug,
        },
      })
    );
    const tokens = await response.json();
    if (tokens.code === 401 || tokens.code) {
      redirectToIntranetPortal(tokenKey);
    } else {
      setOfflineData(tokenKey, "tokens", tokens);
    }
    return tokens.access.token || "";
  } catch (error) {
    redirectToIntranetPortal(tokenKey);
    return "";
  }
};

export const mainInvokeApi = async (
  tokenKey,
  slug,
  method,
  url,
  data,
  params,
  Authorization
) => {
  let headers = {
    "Content-Type": "application/json",
    slug,
  };
  if (Authorization) {
    headers["Authorization"] = `Bearer ${Authorization}`;
  } else {
    headers["Authorization"] = `Bearer ${await mainGetToken(tokenKey, slug)}`;
  }
  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return trackPromise(
    fetch(url, {
      method: method,
      body: (data && JSON.stringify(data)) || undefined,
      headers: headers,
      params: params,
    }).then((response) => {
      if (
        response.status === 401 &&
        url !== `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`
      ) {
        redirectToIntranetPortal(tokenKey);
        return "";
      }

      if (response.status === 204) {
        return Promise.resolve("ok");
      }
      return response.json();
    })
  );
};

export const mainProgressiveInvokeApi = async (
  tokenKey,
  slug,
  method,
  url,
  data,
  params
) => {
  let headers = {
    Authorization: `Bearer ${await mainGetToken(tokenKey, slug)}`,
    slug,
  };

  return axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
    params: params,
  }).then((response) => {
    return response;
  });
};

export const mainInvokeUploadFile = async (tokenKey, slug, url, formData) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${await mainGetToken(tokenKey, slug)}`,
      slug,
    },
  };
  return trackPromise(axios.post(url, formData, config));
};

export const mainPlainApi = async (
  tokenKey,
  slug,
  method,
  url,
  data,
  params
) => {
  let headers = {
    "Content-Type": "application/json",
    "Content-Length": Number.MAX_SAFE_INTEGER,
    Authorization: `Bearer ${await mainGetToken(tokenKey, slug)}`,
    slug,
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return fetch(url, {
    method: method,
    body: (data && JSON.stringify(data)) || undefined,
    headers: headers,
    params: params,
  }).then((response) => {
    if (response.status === 204) {
      return response;
    } else {
      return response.json();
    }
  });
};
