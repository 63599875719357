import { formatDate } from "../../../utils";
import { DATE_FORMATS } from "../../../utils/constants";

export const statusInformationConfig = () => {
  const config = [
    {
      label: "Current Status",
      value: "currentStatus",
    },
    {
      label: "Lead",
      value: "lead",
      convertedValue: (data) => {
        let value = formatDate(data?.lead, DATE_FORMATS["DD-MM-YYYY"]);
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
    {
      label: "Site Evaluation",
      value: "siteEvaluation",
      convertedValue: (data) => {
        let value = formatDate(
          data?.siteEvaluation,
          DATE_FORMATS["DD-MM-YYYY"]
        );
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
    {
      label: "Commercial Discussion",
      value: "commercialDiscussion",
      convertedValue: (data) => {
        let value = formatDate(
          data?.commercialDiscussion,
          DATE_FORMATS["DD-MM-YYYY"]
        );
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },

    {
      label: "Team Sheet Discussion",
      value: "teamSheetDiscussion",
      convertedValue: (data) => {
        let value = formatDate(
          data?.teamSheetDiscussion,
          DATE_FORMATS["DD-MM-YYYY"]
        );
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
    {
      label: "Team Sheet Signed",
      value: "teamSheetSigned",
      convertedValue: (data) => {
        let value = formatDate(
          data?.teamSheetSigned,
          DATE_FORMATS["DD-MM-YYYY"]
        );
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
    {
      label: "Under construction",
      value: "underConstruction",
      convertedValue: (data) => {
        let value = formatDate(
          data?.underConstruction,
          DATE_FORMATS["DD-MM-YYYY"]
        );
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
    {
      label: "Store Opened",
      value: "storeOpened",
      convertedValue: (data) => {
        let value = formatDate(data?.storeOpened, DATE_FORMATS["DD-MM-YYYY"]);
        if (value === "-") {
          value = "";
        }
        return value;
      },
    },
  ];
  return config;
};
