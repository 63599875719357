import MaterialUITable from "../../../common-utilities/core/material-ui-table";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getManageSupplierPlantHeaderConfig } from "./config";

const ManageSupplierPlantTable = ({
  users,
  filters,
  setFilters,
  loadData,
  getData,
}) => {
  return (
    <>
      <MuiTable
        columnsList={getManageSupplierPlantHeaderConfig(true)}
        dataList={users.results || []}
        filters={filters}
        pageCount={users.totalPages || 0}
        onClick={loadData}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          getData(
            {
              ...filters,
              page,
            },
            true,
            2
          );
        }}
      ></MuiTable>
      {/* <MaterialUITable
        columnsList={getManageSupplierPlantHeaderConfig(true)}
        dataList={users.results || []}
        pageCount={users.totalPages || 0}
        filters={filters}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          getData(
            {
              ...filters,
              page,
            },
            true,
            2
          );
        }}
        onClick={loadData}
      /> */}
    </>
  );
};

export default ManageSupplierPlantTable;
