import { pick } from '../../../../common-utilities/utils';

export const pickData = (
  data,
  setFormDetails,
  setInvoiceUrls,
  setAttachments
) => {
  if (!data) {
    return;
  }
  const formDetails = pick(data, [
    'invoiceDate',
    'invoiceNumber',
    'invoiceAmount',
  ]);
  setFormDetails(formDetails);
  setInvoiceUrls(data?.invoiceUrls || []);
  setAttachments(data?.additionalDocUrls || []);
};
