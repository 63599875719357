import { Button } from '@mui/material';
import { exportToExcel } from '../../../../common-utilities/utils';

const DownloadBulkTemplate = ({ siteList }) => {
  return (
    <>
      <div className="payment-details-btn">
        <Button
          variant="outlined"
          onClick={() => {
            const results =
              siteList?.map?.((item) => {
                return {
                  'Site': item.id,
                  'Site Code': item.code,
                  'Site Name': item.name,
                  'Amount': '',
                  'Item Main': '',
                  'Category Code': ''                
                };
              }) || [];
            if (results.length) {
              exportToExcel(results, 'site-details', null, true);
            }
          }}
        >
          Download Template
        </Button>
      </div>
    </>
  );
};
export default DownloadBulkTemplate;
