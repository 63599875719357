import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Badge, Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import MasterData from "../master-data";
import PurchaseOrder from "../purchase-order";
import GoodsReceiveNotes from "../goods-receive-notes";
import { isActionAllowed, isViewAllowed } from "../../utils/common-utils";
import InvoicesData from "../invoices-data";
import Reports from "../reports";
import Budget from "../budget";
import ApprovalConfig from "../approval-config";
import { getOfflineData } from "../../utils/offline-services";
import CfApprovalMapping from "../cf-approval-mapping";
import MyPendingActions from "../my-pending-actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [isFinanceUser, setIsFinanceUser] = useState(false);
  const [userValue, setUserValue] = useState(+openTabIndex || 0);
  const [user, setUser] = useState({});
  const [noOfPendingRequest, setNoOfPendingRequest] = useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setSearchParams({
        index: newValue,
        subIndex: 2,
      });
    } else if (newValue === 0) {
      setSearchParams({
        index: newValue,
        subIndex: 0,
      });
    } else if (newValue === 2) {
      setSearchParams({
        index: newValue,
        subIndex: 5,
      });
    } else if (newValue === 3) {
      setSearchParams({
        index: newValue,
        subIndex: 5,
      });
    } else if (newValue === 5) {
      setSearchParams({
        index: newValue,
        subIndex: 4,
      });
    } else {
      setSearchParams({
        index: newValue,
        subIndex: 0,
      });
    }

    setValue(newValue);
  };
  const userHandleChange = (event, newValue) => {
    {
      if (newValue === 1) {
        setSearchParams({
          index: newValue,
          subIndex: 5,
        });
      } else if (newValue === 0) {
        setSearchParams({
          index: newValue,
          subIndex: 0,
        });
      } else if (newValue === 2) {
        setSearchParams({
          index: newValue,
          subIndex: 5,
        });
      } else if (newValue === 3) {
        setSearchParams({
          index: newValue,
          subIndex: 4,
        });
      } else {
        setSearchParams({
          index: newValue,
          subIndex: 0,
        });
      }
      setUserValue(newValue);
    }
  };

  useEffect(() => {
    const role = user?.role?.toUpperCase();
    if (
      role?.includes("FINANCE") ||
      role?.includes("ADMIN") ||
      role?.includes("BUDGET")
    ) {
      setIsFinanceUser(true);
      if (!openTabIndex) {
        handleChange(null, 0);
      }
    } else {
      if (!openTabIndex) {
        userHandleChange(null, 0);
      }
    }
  }, [user]);

  useEffect(() => {
    setUser(getOfflineData("user"));
  }, []);
  const financeTabs = () => {
    const masterRole = user?.role?.toUpperCase();

    return (
      <div>
        <div className="tabs-section">
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              display: "flex",
            }}
          >
            <div style={{ minWidth: "fit-content" }}>
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{
                  width: "100%",
                  "& button": {
                    color: "black",
                    borderBottom: "1.5px solid #008a52",
                  },
                  "& .Mui-selected": {
                    borderBottom: "none",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label={
                    <Badge badgeContent={noOfPendingRequest} color="primary">
                      My Pending Actions
                    </Badge>
                  }
                  className={value == 0 && "active-tab"}
                />
                <Tab
                  label="Budgets"
                  className={value == 1 && "active-tab"}
                  style={{ height: "32px" }}
                />
                <Tab label="PO" className={value == 2 && "active-tab"} />
                <Tab
                  label="Goods Receive Notes"
                  className={value == 3 && "active-tab"}
                />

                <Tab
                  label="Advance Payment Details"
                  className={value == 4 && "active-tab"}
                />
                <Tab label="Reports" className={value == 5 && "active-tab"} />
                {(masterRole?.includes("FINANCE") ||
                  masterRole?.includes("ADMIN")) && (
                  <Tab label="Masters" className={value == 6 && "active-tab"} />
                )}

                {user?.properties?.finance?.approvalConfigRights && (
                  <Tab
                    label="Approval Config"
                    className={value == 7 && "active-tab"}
                  />
                )}
                {user?.properties?.finance?.cfMappingRights && (
                  <Tab
                    label="CF Approval Mapping"
                    className={value == 8 && "active-tab"}
                  />
                )}
              </Tabs>
            </div>
            <div
              style={{ width: "100%", borderBottom: "1.5px solid #008a52" }}
            ></div>
          </Box>
        </div>
        <TabPanel value={value} index={0}>
          <MyPendingActions
            getCountOfPendingRequest={getCountOfPendingRequest}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isViewAllowed("budget") ? <Budget /> : "No access to view budgets"}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {isViewAllowed("po") ? (
            <PurchaseOrder />
          ) : (
            "No access to view Purchase orders "
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {isViewAllowed("grn") ? (
            <GoodsReceiveNotes />
          ) : (
            "No access to view grn"
          )}
        </TabPanel>

        <TabPanel value={value} index={4}>
          {isViewAllowed("advancePayment") ? (
            <InvoicesData />
          ) : (
            "No access to Advance Payment"
          )}
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Reports />
        </TabPanel>
        <TabPanel value={value} index={6}>
          {isViewAllowed("master_data") ? (
            <MasterData />
          ) : (
            "No access to Master"
          )}
        </TabPanel>
        {user?.properties?.finance?.approvalConfigRights && (
          <TabPanel value={value} index={7}>
            <ApprovalConfig />
          </TabPanel>
        )}
        {user?.properties?.finance?.cfMappingRights && (
          <TabPanel value={value} index={8}>
            <CfApprovalMapping />
          </TabPanel>
        )}
      </div>
    );
  };
  const getCountOfPendingRequest = (totalCount) => {
    setNoOfPendingRequest(totalCount);
  };

  useEffect(() => {
    if (
      !isActionAllowed("budget") &&
      !isActionAllowed("po") &&
      !isActionAllowed("grn") &&
      !isActionAllowed("advancePayment")
    ) {
      if (!openTabIndex) {
        handleChange(null, 1);
        userHandleChange(null, 1);
      }
    }
  }, []);
  return (
    <div className="home-container">
      {isFinanceUser ? (
        financeTabs()
      ) : (
        <div>
          <div className="tabs-section">
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                display: "flex",
              }}
            >
              <div style={{ minWidth: "fit-content" }}>
                <Tabs
                  variant="scrollable"
                  value={userValue}
                  onChange={userHandleChange}
                  sx={{
                    width: "100%",
                    "& button": {
                      color: "black",
                      borderBottom: "1.5px solid #008a52",
                    },
                    "& .Mui-selected": {
                      borderBottom: "none",
                    },
                  }}
                  TabIndicatorProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                >
                  <Tab
                    label={
                      <Badge badgeContent={noOfPendingRequest} color="primary">
                        My Pending Actions
                      </Badge>
                    }
                    className={userValue == 0 && "active-tab"}
                  />
                  <Tab label="PO" className={userValue == 1 && "active-tab"} />
                  <Tab
                    label="Goods Receive Notes"
                    className={userValue == 2 && "active-tab"}
                  />
                  <Tab
                    label="Advance Payment Details"
                    className={userValue == 3 && "active-tab"}
                  />
                  <Tab
                    label="Reports"
                    className={userValue == 4 && "active-tab"}
                  />
                  {user?.properties?.finance?.approvalConfigRights && (
                    <Tab
                      label="Approval Config"
                      className={userValue == 5 && "active-tab"}
                    />
                  )}
                  {user?.properties?.finance?.cfMappingRights && (
                    <Tab
                      label="CF Approval Mapping"
                      className={userValue == 6 && "active-tab"}
                    />
                  )}
                </Tabs>
              </div>
              <div
                style={{ width: "100%", borderBottom: "1.5px solid #008a52" }}
              ></div>
            </Box>
          </div>

          <TabPanel value={userValue} index={0}>
            <MyPendingActions
              getCountOfPendingRequest={getCountOfPendingRequest}
            />
          </TabPanel>
          <TabPanel value={userValue} index={1}>
            {isViewAllowed("po") ? (
              <PurchaseOrder />
            ) : (
              "No access to view Purchase orders "
            )}
          </TabPanel>
          <TabPanel value={userValue} index={2}>
            {isViewAllowed("grn") ? (
              <GoodsReceiveNotes />
            ) : (
              "No access to view grn"
            )}
          </TabPanel>
          <TabPanel value={userValue} index={3}>
            {isViewAllowed("advancePayment") ? (
              <InvoicesData />
            ) : (
              "No access to Advance Payment"
            )}
          </TabPanel>
          <TabPanel value={userValue} index={4}>
            <Reports />
          </TabPanel>
          {user?.properties?.finance?.approvalConfigRights && (
            <TabPanel value={userValue} index={5}>
              <ApprovalConfig />
            </TabPanel>
          )}
          {user?.properties?.finance?.cfMappingRights && (
            <TabPanel value={userValue} index={6}>
              <CfApprovalMapping />
            </TabPanel>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
