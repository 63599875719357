import React, { useState } from "react";
import "./index.scss";
import Box from "@mui/material/Box";
import Select from "react-select";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import moment from "moment";

const SubsReports = ({ tokenKey }) => {
  const [schema, setSchema] = useState("");
  const [memoCategoryList, setMemoCategoryList] = useState([]);
  const [memoType, setMemoType] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [memoCategory, setMemoCategory] = useState("");
  const [isCocoAcquiredAdded, setIsCocoAcquiredAdded] = useState(false);
  const dropDownOptions = [
    { label: "Person", value: "Person" },
    { label: "Store", value: "Store" },
    { label: "Memos", value: "Memo" },
    { label: "Notices", value: "NoticeIdTracker" },
    { label: "Address", value: "Address" },
    { label: "Communication", value: "Communication" },
    { label: "Store Insurance", value: "StoreInsurance" },
    { label: "Store Worker Insurance", value: "StoreWorkerInsurance" },
    { label: "Franchise Agreement", value: "FranchiseAgreement" },
    { label: "Store Lease", value: "StoreLease" },
    { label: "Waivers", value: "Waivers" },
    { label: "Store Transfer", value: "StoreTransfer" },
    { label: "Store Attribute", value: "StoreAttribute" },
    { label: "Decor Attributes", value: "DecorAttribute" },
    { label: "POS/Technology Attributes", value: "POSTechAttribute" },
    { label: "Catering", value: "CateringAttribute" },
    { label: "Delivery", value: "DeliveryAttribute" },
    { label: "Basic Fixed", value: "BasicFixed" },
    { label: "Store Hours", value: "StoreHours" },
  ];
  const memoTypes = [
    { label: "Person", value: "Person" },
    { label: "Store", value: "Store" },
    { label: "Store Transfer", value: "StoreTransfer" },
    { label: "Store Lease", value: "StoreLease" },
  ];
  let temp = [];

  const camelcaseToSpace = (str) => {
    let newStr = "";
    let wasUpper = false;
    for (let i = 0; i < str.length; i++) {
      if (!wasUpper && str[i] == str.toUpperCase()[i] && str[i] !== " ") {
        newStr = newStr + " ";
        wasUpper = true;
      } else {
        wasUpper = false;
      }
      let tempStr = str[i];
      if (i === 0 || str[i - 1] === " ") tempStr = tempStr.toUpperCase();
      newStr = newStr + tempStr;
    }
    return newStr.trim();
  };

  const ConvertToCSV = (objArray, header, data, rec = true) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    if (!Array.isArray(array)) array = [array];
    for (let i = 0; i < array.length; i++) {
      for (let index in array[i]) {
        let item = array[i][index];
        // if (index === "owner" && Array.isArray(array[i][index])) {
        //   const ownerArray = array[i][index];
        //   if (ownerArray.length > 0) {
        //     const primaryOwner = ownerArray.find(
        //       (ele) => ele?.primary === true
        //     );
        //     item = `${primaryOwner?.id?.firstName} ${primaryOwner?.id?.lastName}`;
        //   }
        // }
        if (typeof item === "object" && item !== null) {
          ConvertToCSV(item, header, data, false);
        } else {
          if (header.includes("createdBy")) {
            const createdByIndex = header.indexOf("createdBy");
            header?.splice(createdByIndex, 1);
          }
          if (!header.includes(index)) header.push(index);
          const isDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(
            item
          );
          const formattedDate = isDate && moment(item).format("DD-MM-YYYY");
          temp[header.indexOf(index)] =
            item?.toString().toLowerCase() === "true"
              ? "Yes"
              : item?.toString().toLowerCase() === "false"
              ? "No"
              : formattedDate
              ? formattedDate
              : item;
        }
      }
      if (rec) {
        data.push(temp);
        temp = [];
      }
    }
    return { data: data, header: header };
  };

  const downloadCall = (res) => {
    return new Promise((resolve) => {
      let template = ConvertToCSV(res, [], []);
      let header = template.header.map((i) => camelcaseToSpace(i));
      template.data.unshift(header);
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        let sheetName = workbook.sheet(0).name(schema);
        sheetName.cell("A1").value(template.data);
        return workbook.outputAsync().then((res) => {
          saveAs(res, schema + ".xlsx");
          resolve(true);
        });
      });
    });
  };

  const apiCall = () => {
    let payload = { model: schema };
    if (schema === "Memo") {
      if (!memoType && !memoCategory) {
        toast.error("Select Memo Type and Category", { autoClose: 2000 });
        return;
      } else {
        payload = {
          ...payload,
          memoType: memoType,
          memoCategory: memoCategory,
        };
      }
    }
    if (dateRange?.startDate) {
      payload["startDate"] = dateRange.startDate;
    }
    if (dateRange?.endDate) {
      payload["endDate"] = dateRange.endDate;
    }
    if (schema === "Store" && isCocoAcquiredAdded) {
      payload["isAddCocoAcquired"] = isCocoAcquiredAdded;
    }
    invokeApi(
      HTTP_METHODS.POST,
      HOSTNAME + REST_URLS.GET_REPORTS,
      null,
      payload,
      null,
      tokenKey
    )
      .then((res) => {
        if (res.message) {
          toast.error("Download Failed", { autoClose: 2000 });
          setDateRange({ startDate: "", endDate: "" });
        } else {
          if (res.length > 0) {
            downloadCall(res);
            console.log(res);
            toast.success("Downloaded Successfully", { autoClose: 2000 });
            setDateRange({ startDate: "", endDate: "" });
          } else {
            toast.info("No Data Available", { autoClose: 2000 });
            setDateRange({ startDate: "", endDate: "" });
          }
        }
      })
      .catch((error) => {
        toast.error("Download Failed", { autoClose: 2000 });
        setDateRange({});
      });
  };
  const getCategoryHandler = (type) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`,
      null,
      {
        typeModel: type,
      },
      null,
      tokenKey
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const results = res?.map((ele) => ({
            label: ele.category,
            value: ele.id,
          }));
          setMemoCategoryList(results);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box sx={{ backgroundColor: "white", padding: "20px" }}>
      <h3>Reports</h3>
      <Grid
        container
        md={12}
        sx={{ display: "flex", alignItems: "flex-end" }}
        spacing={4}
      >
        <Grid item md={3}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Reports"
            className="sel-item"
            options={dropDownOptions}
            value={
              ![undefined, null, ""].includes(schema)
                ? dropDownOptions.find((i) => i.value === schema)
                : ""
            }
            onChange={(val) => {
              setSchema(val?.value);
              setMemoCategoryList([]);
              setMemoCategory("");
              setMemoType("");
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        {schema === "Memo" && (
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              options={memoTypes}
              className="sel-item"
              placeholder="Select Type"
              onChange={(e) => {
                setMemoType(e?.value);
                getCategoryHandler(e?.value);
                setMemoCategory("");
              }}
            />
          </Grid>
        )}
        {schema === "Memo" && memoCategoryList.length > 0 && (
          <Grid
            item
            md={5}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={4.5}>
              <Select
                classNamePrefix="custom_select"
                options={memoCategoryList}
                className="sel-item"
                value={
                  memoCategoryList.find(
                    (cate) => cate.value === memoCategory
                  ) || null
                }
                placeholder="Select Memo Category"
                onChange={(e) => {
                  setMemoCategory(e?.value);
                }}
              />
            </Grid>
            <Grid item md={7}>
              <p className="notice-date-heading">Applied Date</p>
              <Grid item md={12} sx={{ display: "flex" }}>
                <Grid item md={6}>
                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    className="subs-text-field subs-text-date"
                    label=" From"
                    value={dateRange?.startDate || ""}
                    onChange={(event) => {
                      setDateRange((prevVal) => ({
                        ...prevVal,
                        startDate: event.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    className="subs-text-field subs-text-date"
                    label="To"
                    value={dateRange?.endDate || ""}
                    onChange={(event) => {
                      setDateRange((prevVal) => ({
                        ...prevVal,
                        endDate: event.target.value,
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {schema === "NoticeIdTracker" && (
          <Grid item md={3}>
            <p className="notice-date-heading">Notice Date</p>
            <Grid item md={12} sx={{ display: "flex" }}>
              <Grid item md={6}>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  className="subs-text-field subs-text-date"
                  label=" From"
                  value={dateRange?.startDate || ""}
                  onChange={(event) => {
                    setDateRange((prevVal) => ({
                      ...prevVal,
                      startDate: event.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  className="subs-text-field subs-text-date"
                  label="To"
                  value={dateRange?.endDate || ""}
                  onChange={(event) => {
                    setDateRange((prevVal) => ({
                      ...prevVal,
                      endDate: event.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {schema === "Store" && (
          <FormGroup sx={{ ml: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCocoAcquiredAdded}
                  onChange={(event) => {
                    setIsCocoAcquiredAdded(event.target.checked);
                  }}
                />
              }
              label="Include COCO Acquired Stores"
            />
          </FormGroup>
        )}
        <Grid item md={1}>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              apiCall();
            }}
            disabled={
              [undefined, null, ""].includes(schema) ||
              (schema === "Memo" && !memoCategory)
            }
            startIcon={
              <DownloadRoundedIcon
                style={{ fontSize: "20px", marginRight: "-5px" }}
              />
            }
          >
            Download
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SubsReports;
