import { INTRANETHOSTNAME } from '../../../packages/common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { REST_URLS } from '../../../packages/common-utilities/utils/end-points/intranet';
import { USER_ROLES } from '../../../utils/roles';
import { getCreateUpdateApiDetails } from '../utils';
import { toast } from 'react-toastify';

export const getAllUsersList = (setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.USER_LIST}`,
    null,
    {
      excludeRoles: `${USER_ROLES.contentCreator},${USER_ROLES.franchise}`,
    }
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      const results =
        res?.map?.((item) => {
          return { label: item?.name || '', value: item.id };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllDepartmentRoleList = (setDataFunc, params) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.GET_DEPARTMENT_ROLE_LIST}`,
    null,
    params
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      const results =
        res?.map?.((item) => {
          return { label: item?.name || '', value: item.id };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createUpdateDepartment = (data, onSuccess) => {
  const { payload, method, url, message } = getCreateUpdateApiDetails(data);
  invokeApi(method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      onSuccess && onSuccess();
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};
