import React, { useState, useEffect, useRef, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Checkbox from "@mui/material/Checkbox";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { IconButton, Divider } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";

import moment from "moment";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import {
  dateFun,
  getLabelsAndValuesForSelect,
} from "../../../common-utilities/utils";
const StoreInsuranceDetails = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const dateFields = ["effectiveDate", "renewalDate", "cancelledDate"];
  const [respData, setRespData] = useState(null);
  const [data, setData] = useState({
    storeStatus: "",
    companyName: "",
    broker: "",
    effectiveDate: "",
    policyNumber: "",
    totalLiability: "",
    renewalDate: "",
    requiredLiability: "",
    currency: "",
    cancelledDate: "",
    comments: "",
    verification: "",
    lastUpdatedBy: "",
    franchisor: "",
  });
  const [storeOptions, setStoreOptions] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [id, setId] = useState("");
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [sequenceList, setSequenceList] = useState([]);
  const previousController = useRef();
  useEffect(() => {
    const checkValidation = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (checkValidation) {
      listingLeaseHandler(storeInfo.storeNo);
    }
  }, []);

  const clearData = (clearIsCreate, clearStoreInfo) => {
    setId("");
    setData({
      storeStatus: "",
      companyName: "",
      broker: "",
      effectiveDate: "",
      policyNumber: "",
      totalLiability: "",
      renewalDate: "",
      requiredLiability: "",
      currency: "",
      cancelledDate: "",
      comments: "",
      verification: "",
      lastUpdatedBy: "",
      franchisor: "",
    });
    setStoreOptions([]);
    if (clearIsCreate) {
      setIsCreate(false);
    }
    if (clearStoreInfo) {
      setStoreInfo((prevVal) => ({
        ...prevVal,
        satelliteNo: "",
        sequenceNo: "",
      }));
    }
  };

  const updateInsuranceHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele]) {
        if (ele !== "franchisor" && data[ele] !== "Invalid date") {
          payload[ele] = data[ele];
        }
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.GET_INSURANCE}${id}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          clearData(false, true);
        } else {
          toast.success("Saved Successfully", { autoClose: 2000 });
          clearData();
        }
      })
      .catch((err) => console.log(err));
  };

  const loadDataById = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_INSURANCE}${id}`,
      null,
      { satelliteNo: storeInfo.satelliteNo, sequenceNo: storeInfo.sequenceNo }
    )
      .then((res) => {
        if (res.message) {
          if (res.message === "Store Insurance not found") {
            toast.info("Store insurance not available. Please create one", {
              autoClose: 2000,
            });
          } else {
            toast.error(res.message, { autoClose: 2000 });
          }
        } else {
          setRespData(res);
          setIsSaveDisable(false);
          setId(res?.storeInsurances?.id);
          setData((prevVal) => ({
            ...prevVal,
            storeStatus: res?.store?.status,
            companyName: res?.storeInsurances?.companyName,
            broker: res?.storeInsurances?.broker,
            effectiveDate: moment(
              res?.storeInsurances?.effectiveDate
                ? res.storeInsurances.effectiveDate
                : ""
            ).format("yyyy-MM-DD"),
            policyNumber: res?.storeInsurances?.policyNumber,
            totalLiability: res?.storeInsurances?.totalLiability,
            renewalDate: moment(
              res?.storeInsurances?.renewalDate
                ? res.storeInsurances.renewalDate
                : ""
            ).format("yyyy-MM-DD"),
            requiredLiability: res?.storeInsurances?.requiredLiability,
            currency: res?.storeInsurances?.currency,
            cancelledDate: moment(
              res?.storeInsurances?.cancelledDate
                ? res.storeInsurances.cancelledDate
                : ""
            ).format("yyyy-MM-DD"),
            comments: res?.storeInsurances?.comments,
            verification: res?.storeInsurances?.verification,
            lastUpdatedBy: res?.storeInsurances?.lastUpdatedBy,
            franchisor: res?.franchiseAgreementDetails?.franchisor,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const createNewHandler = () => {
    setIsCreate(true);
    clearData();
  };

  const createInsuranceHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele]) {
        if (ele !== "franchisor") {
          payload[ele] = data[ele];
        }
      }
    }
    payload["satelliteNo"] = storeInfo.satelliteNo;
    payload["sequenceNo"] = storeInfo.sequenceNo;
    payload["storeNo"] = storeInfo.storeNo;
    if (data.renewalDate) {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_INSURANCE}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Created Successfully", { autoClose: 2000 });
            clearData(true);
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Renewal Date is required", { autoClose: 2000 });
    }
  };
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
      setStoreInfo((prevVal) => ({ ...prevVal, storeNo: searchTerm }));
    });
  };
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      setStoreInfo({ storeNo: value, satelliteNo: "", sequenceNo: "" });
    } else {
      setStoreOptions([]);
      setIsSaveDisable(true);
      setId("");
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
      setData({
        storeStatus: "",
        companyName: "",
        broker: "",
        effectiveDate: "",
        policyNumber: "",
        totalLiability: "",
        renewalDate: "",
        requiredLiability: "",
        currency: "",
        cancelledDate: "",
        comments: "",
        verification: "",
        lastUpdatedBy: "",
        franchisor: "",
      });
      setStoreOptions([]);
    }
  };
  useEffect(() => {
    if (
      Number.isInteger(parseInt(storeInfo.storeNo)) &&
      Number.isInteger(parseInt(storeInfo.satelliteNo)) &&
      Number.isInteger(parseInt(storeInfo.sequenceNo))
    ) {
      if (!isCreate) {
        loadDataById(storeInfo.storeNo);
      }
    }
  }, [storeInfo.satelliteNo, storeInfo.sequenceNo]);
  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="storeInsuranceDetails">
      <div className="title">Store Selection</div>
      <div>
        <Grid style={displayContainer} container md={12}>
          <Grid item md={2}>
            <Autocomplete
              className="subs-autocomplete"
              id="free-solo-demo"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setStoreOptions([]);
                    setId("");
                    setStoreInfo({
                      storeNo: "",
                      satelliteNo: "",
                      sequenceNo: "",
                    });
                    setData({
                      storeStatus: "",
                      companyName: "",
                      broker: "",
                      effectiveDate: "",
                      policyNumber: "",
                      totalLiability: "",
                      renewalDate: "",
                      requiredLiability: "",
                      currency: "",
                      cancelledDate: "",
                      comments: "",
                      verification: "",
                      lastUpdatedBy: "",
                      franchisor: "",
                    });
                    setIsSaveDisable(true);
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              sx={{ width: "12em" }}
              inputValue={storeInfo.storeNo + "" || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  listingLeaseHandler(val.id);
                  setStoreInfo((prevVal) => ({ ...prevVal, storeNo: val.id }));
                }
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              className="sel-item"
              placeholder="Satellite"
              options={satteliteList}
              value={
                ![undefined, null, ""].includes(storeInfo.satelliteNo)
                  ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  satelliteNo: val.value,
                }));
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val.value,
                }));
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              className="subs-text-field"
              label="Store status"
              value={data.storeStatus}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item md={2}>
            {!isCreate && (
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={createNewHandler}
                >
                  Create New
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    excelDownload(
                      "store_insurance_details_" + storeInfo.storeNo,
                      respData
                    );
                  }}
                  disabled={respData === null}
                  startIcon={
                    <DownloadRoundedIcon
                      style={{ fontSize: "20px", marginRight: "-5px" }}
                    />
                  }
                >
                  Download
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <Divider style={{ paddingBottom: 10 }} />
      <div className="title">Insurance Coverage Details</div>
      <Grid md={12} container>
        <Grid container spacing={1} md={12}>
          <Grid xs={3}>
            <TextField
              className="w-80 subs-text-field"
              label="Company Name"
              value={data.companyName || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  companyName: e.target.value,
                }));
              }}
            />
          </Grid>

          {/* <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
                className="gray"
              />
            </IconButton> */}
          {/* <IconButton>
              <SearchIcon
                color="primary"
                size="large"
                fontSize="60"
                className="gray"
              />
            </IconButton> */}
          {/* <span className="red">Non-Gold Standard</span> */}

          <Grid xs={3}>
            <TextField
              label="Broker"
              className="w-80 subs-text-field"
              value={data.broker || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  broker: e.target.value,
                }));
              }}
            />
            {/* <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton> */}
            {/* <IconButton>
              <SearchIcon color="primary" size="large" fontSize="60" />
            </IconButton> */}
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Effective Date"
              type="date"
              className="w-80 subs-text-field"
              InputLabelProps={{ shrink: true }}
              value={dateFun(data.effectiveDate) || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  effectiveDate: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Policy Number"
              className="w-80 subs-text-field"
              value={data.policyNumber || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  policyNumber: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Total Liability"
              className="w-80 subs-text-field"
              type="number"
              value={data.totalLiability || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  totalLiability: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Renewal Date"
              type="date"
              className="w-80 subs-text-field"
              InputLabelProps={{ shrink: true }}
              value={dateFun(data.renewalDate) || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  renewalDate: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Currency"
              className="w-80 subs-text-field"
              value={data.currency || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  currency: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Required Liability"
              className="w-80 subs-text-field"
              type="number"
              value={data.requiredLiability || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  requiredLiability: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={3}>
            <TextField
              label="Cancelled Date"
              type="date"
              className="w-80 subs-text-field"
              InputLabelProps={{ shrink: true }}
              value={dateFun(data.cancelledDate) || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  cancelledDate: e.target.value,
                }));
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ paddingBottom: 10 }} />
        <div className="title">Additional Insured</div>

        <Grid container spacing={1}>
          <Grid xs={4}>
            <TextField
              label="Franchisor"
              className="w-80 subs-text-field"
              value={data.franchisor || ""}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid xs={1}>
            <div>Named</div>
            <Checkbox labelPlacement="top" label="Named" />
          </Grid>

          <Grid xs={1}>
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </Grid>

          <Grid xs={4}>
            <TextField label="DA" className="w-80 subs-text-field" />
          </Grid>
          <Grid xs={1}>
            <div>Named</div>
            <Checkbox labelPlacement="top" label="Named" />
          </Grid>

          <Grid xs={1}>
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </Grid>

          <Grid xs={4}>
            <TextField label="Tenant Corp" className="w-80 subs-text-field" />
          </Grid>

          <Grid xs={1}>
            <Checkbox labelPlacement="top" label="Named" />
          </Grid>

          <Grid xs={1}>
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </Grid>

          <Grid xs={4}>
            <TextField label="Landlord" className="w-80 subs-text-field" />
          </Grid>

          <Grid xs={1}>
            <Checkbox />
          </Grid>

          <Grid xs={1}>
            <IconButton>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </Grid>

          <Grid xs={4}>Franchise World Headquarters.LLC</Grid>
          <Grid xs={1}>
            <Checkbox />
          </Grid>
        </Grid>

        <Divider style={{ paddingBottom: 10 }} />
        <div className="title">Comments</div>

        <Grid xs={12} container spacing={1}>
          <Grid xs={12}>
            <TextareaAutosize
              className="commentText subs-text-area"
              aria-label="minimum height"
              minRows={3}
              placeholder="Policy Number updated with the certificate number"
              value={data.comments || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  comments: e.target.value,
                }));
              }}
            />
          </Grid>
        </Grid>

        <Divider style={{ paddingBottom: 10 }} />
        <div className="title">Modification</div>

        <Grid xs={12} container spacing={1}>
          <Grid xs={4}>
            <TextField
              label="Verification"
              className="w-80 subs-text-field"
              value={data.verification || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  verification: e.target.value,
                }));
              }}
            />
          </Grid>

          <Grid xs={4}>
            <TextField
              label="Last Updated By"
              className="w-80 subs-text-field"
              value={data.lastUpdatedBy || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  lastUpdatedBy: e.target.value,
                }));
              }}
            />
          </Grid>
          {!isCreate && (
            <Grid xs={3.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                startIcon={<SaveIcon />}
                variant="contained"
                disabled={isSaveDisable}
                onClick={updateInsuranceHandler}
              >
                Save
              </Button>
            </Grid>
          )}
          {isCreate && (
            <Grid xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsCreate(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                startIcon={<SaveIcon />}
                variant="contained"
                disabled={
                  storeInfo.storeNo === "" ||
                  storeInfo.satelliteNo === null ||
                  storeInfo.sequenceNo === null
                    ? true
                    : false
                }
                onClick={createInsuranceHandler}
                sx={{ ml: 5 }}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default StoreInsuranceDetails;
