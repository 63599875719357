export const getFormConfig = (
  ticketDetails,
  storeList,
  ticketCategoriesList,
  departmentsList,
  subCategoryList,
  errors
) => {
  // Attachment are shown only when
  // 1. We select sub category and there are mandatory attachments with it
  // 2. We select  category and there are mandatory attachments with it
  // Hence we also add the details of the attachment in the configuration
  const { ticketcategory, ticketsubcategory } = ticketDetails;

  const fileDetails = ticketsubcategory
    ? ticketsubcategory?.attachments?.[0]
    : ticketcategory
    ? ticketcategory?.attachments?.[0]
    : null;
  const showAttachment = !!fileDetails;
  const config = [
    {
      label: "Stores",
      name: "StoresList",
      type: "select",
      value: ticketDetails.StoresList,
      error: errors.storeslist,
      options: storeList,
      disabled: false,
      helperText: errors.storeslist ? "Required" : "",
    },
    {
      label: "Departments",
      name: "department",
      type: "select",
      value: ticketDetails.department,
      error: errors.department,
      options: departmentsList,
      disabled: false,
      helperText: errors.department ? "Required" : "",
    },
    {
      label: "Ticket Category",
      name: "ticketcategory",
      type: "select",
      value: ticketDetails.ticketcategory,
      error: errors.category,
      options: ticketCategoriesList,
      disabled: false,
      helperText: errors.category ? "Required" : "",
    },
    {
      label: "Ticket Sub Category",
      name: "ticketsubcategory",
      type: "select",
      value: ticketDetails.ticketsubcategory,
      error: errors.ticketsubcategory,
      options: subCategoryList,
      disabled: false,
      helperText: errors.subCategoryList ? "Required" : "",
    },
    {
      label: "Contact Person",
      name: "contactPerson",
      type: "text",
      value: ticketDetails.contactPerson,
      error: errors.contactPerson,
      disabled: false,
      helperText: errors.contactPerson ? "Required" : "",
    },
    {
      label: "Contact Number",
      name: "storeContact",
      type: "number",
      value: ticketDetails.storeContact,
      error: errors.storeContact,
      disabled: false,
      helperText: errors.storeContact ? "Required" : "",
    },
    {
      label: "Email",
      name: "email",
      type: "text",
      value: ticketDetails.email,
      error: errors.email,
      disabled: false,
      helperText: errors.email ? "Required" : "",
    },
    {
      label: "Details",
      name: "details",
      type: "multiline",
      value: ticketDetails.details,
      error: errors.details,
      disabled: false,
      helperText: errors.details ? "Required" : "",
    },
  ];

  if (showAttachment) {
    config.splice(4, 0, {
      type: "categoryAttachments",
      error: errors.categoryAttachments,
      details: fileDetails,
    });
  }

  return config;
};

export const isFormValid = (ticketDetails) => {
  let errors = {};
  const {
    StoresList,
    department,
    details,
    ticketcategory,
    ticketsubcategory,
    categoryAttachments,
    email
  } = ticketDetails;
  if (!StoresList) {
    errors.storeslist = true;
  }
  if (!department) {
    errors.department = true;
  }
  if (!details) {
    errors.details = true;
  }
  if (!ticketcategory) {
    errors.category = true;
  }
  // Category Attachment are mandatory when
  // 1. We select sub category and there are mandatory attachments with it
  // 2. We select  category and there are mandatory attachments with it
  // Hence we also add the details of the attachment in the configuration
  const mandatoryAttachment = ticketsubcategory
    ? !!ticketsubcategory?.attachments?.[0]
    : !!ticketcategory
    ? ticketcategory?.attachments?.[0]
    : false;
  if (mandatoryAttachment && !categoryAttachments?.length) {
    errors.categoryAttachments = true;
  }

  if (!email) {
    errors.email = true;
  }
  return errors;
};

export const convertedEventNamesToErrorObjectKeys = {
  StoresList: "storeslist",
  department: "department",
  details: "details",
  ticketcategory: "category",
  email: "email",
};
