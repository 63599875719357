import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Button, Grid, TextField, Drawer, InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import { invokeApi, plainApi } from "../../utils/http-service";
import axios from "axios";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { Popover } from "@mui/material";
import CreateMemo from "./create-memo";
import { GlobalStoreContext } from "../../global-states/Context";
import Select from "react-select";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShowMemo from "./show-memo";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import {
  convertDateToIsoString,
  convertDDMMYYYdateToIsoString,
  dateFormats,
} from "../../../common-utilities/utils/date";

const memoCols = [
  { id: "category", label: "Category" },
  { id: "enteredBy", label: "Entered By" },
  { id: "memoDate", label: "Created At" },
  { id: "memoDate", label: "memo Date" },
  { id: "memo", label: "Memo" },
  { id: "appliedDate", label: "Applied Date" },
  { id: "deposition", label: "Deposition" },
  { id: "closedAt", label: "Completed Date" },
  { id: "isClosed", label: "Completed" },
  {
    label: "Action",
    id: "action",
    render: (data, onClick) => {
      return (
        <TableCell align="center" padding="none" disabled={true}>
          {!data?.isClosed ? (
            <EditIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                onClick(data);
              }}
            />
          ) : (
            "-"
          )}
        </TableCell>
      );
    },
  },
];

const PersonMemo = () => {
  const [data, setData] = useState([]);
  const { personInfo, setPersonInfo } = useContext(GlobalStoreContext);
  const [dateRange, setDateRange] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [memoCategoryList, setMemoCategoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [memoFlag, setMemoFlag] = useState(false);
  const [personOptions, setPersonOptions] = useState([]);
  const [category, setCategory] = useState("");
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
    isClosed: false,
    isEdit: false,
    appliedDate: "",
    id: "",
    deposition: null,
  });
  const previousController = useRef();
  const clearData = () => {
    setPersonInfo({ personId: "" });
    setPersonOptions([]);
    setData([]);
    setPageArray([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setCategory("");
  };

  useEffect(() => {
    if (personInfo.personId) {
      getTableData(1, true, personInfo.personId);
    }
  }, []);
  useEffect(() => {
    if (category) {
      getTableData(1, true);
    }
  }, [category]);
  const onInputChange = (event, value, reason) => {
    if (!value) {
      clearData();
    }
    if (value) {
      getData(value);
      setPersonInfo({ personId: value });
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions(updatedOptions);
      setPersonInfo({ personId: searchTerm });
    });
  };

  useEffect(() => {
    if (totalPages === null) {
      if (personInfo.personId) {
        getTableData(pages);
      }
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          if (personInfo.personId) {
            getTableData(pages);
          }
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`, null, {
      typeModel: "Person",
    }).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        const results = res.map((ele) => ({
          label: ele.category,
          value: ele.id,
        }));
        setMemoCategoryList(results);
      }
    });
  }, []);
  const getTableData = (page, hasToReset, personNo) => {
    let checkingCondition = !pageArray.includes(page);
    let params = {
      page: page,
      limit: 50,
      sortBy: "-createdAt",
      typeModel: "Person",
      category: category,
    };
    if (personNo) {
      params["type"] = personNo;
    } else if (personInfo.personId) {
      params["type"] = personInfo.personId;
    }
    if (dateRange?.startDate) {
      params["startDate"] = convertDateToIsoString(
        dateRange.startDate,
        dateFormats.yymmdd
      );
    }
    if (dateRange?.endDate) {
      params["endDate"] = convertDateToIsoString(
        dateRange.endDate,
        dateFormats.yymmdd
      );
    }
    if (searchWord) {
      params["word"] = searchWord;
    }
    if (hasToReset) {
      checkingCondition = true;
    }
    if (checkingCondition && category && params.type) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMOS}`, null, params)
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
            return;
          }
          setTotalPages(res.totalPages + 1);
          const results = res?.results?.map((item) => {
            let details = {};
            details["id"] = item?.id;
            if (item?.createdBy) {
              details[
                "enteredBy"
              ] = `${item.createdBy?.firstName} ${item.createdBy?.lastName}`;
            }
            if (item?.createdAt) {
              details["memoDate"] = moment(item.createdAt).format("DD-MM-YYYY");
            }
            if (item?.appliedDate) {
              details["appliedDate"] = moment(item.appliedDate).format(
                "DD-MM-YYYY"
              );
              details["isoFormatAppliedDate"] = item.appliedDate;
            }
            if (item?.createdAt) {
              details["createdAt"] = moment(item.createdAt).format(
                "DD-MM-YYYY"
              );
            }
            if (item?.closedAt) {
              details["closedAt"] = moment(item.closedAt).format("DD-MM-YYYY");
            }
            if (item?.memo) {
              details["memo"] = item.memo;
            }
            if (item?.deposition) {
              details["deposition"] = item.deposition;
            }
            details["isClosed"] = item.isClosed;
            if (item?.category?.category) {
              details["category"] = item.category.category;
            }
            return details;
          });
          let array = [...pageArray];
          array.push(page);
          if (hasToReset) {
            setPageArray([1]);
            setData(results);
            if (res.totalPages === 1) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setPageArray(array);
            setData((prevData) => [...prevData, ...results]);
          }
        })
        .catch((err) => {
          console.log(err);
          setData([]);
        });
    }
  };
  const handleClose = () => setAnchorEl(null);

  const editMemoHandler = (data) => {
    setShowLargeText({
      isOpen: true,
      text: data.memo,
      id: data.id,
      isClosed: data.isClosed,
      isEdit: true,
      appliedDate: data?.isoFormatAppliedDate || "",
      deposition: data?.deposition,
    });
  };
  return (
    <div className="trasfer-con">
      <Grid container spacing={1}>
        <Grid item md={3}>
          <Autocomplete
            className="subs-autocomplete"
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            options={personOptions}
            onInputChange={onInputChange}
            inputValue={personInfo.personId}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInfo({ personId: "" });
                  setPersonOptions([]);
                  setData([]);
                }}
              />
            }
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Person" />
            )}
            onChange={(e, val) => {
              if (val) {
                setPersonInfo({ personId: val.id });
                getTableData(1, true, val.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2.7}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Select Category"
            options={memoCategoryList}
            value={
              memoCategoryList.find((ele) => ele.value === category) || null
            }
            onChange={(val) => {
              setCategory(val?.value);
            }}
          />
        </Grid>
        <Grid item md={3} sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            disabled={!personInfo?.personId || !category ? true : false}
            onClick={() => {
              setMemoFlag(true);
            }}
          >
            Create New
          </Button>
        </Grid>
      </Grid>
      <p className="memo-search-title">Searchable items</p>

      <Grid container spacing={1}>
        <Grid spacing={3} item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            className="subs-text-field subs-text-date"
            label=" From"
            value={dateRange?.startDate || null}
            onChange={(event) => {
              setDateRange((prevVal) => ({
                ...prevVal,
                startDate: event.target.value,
              }));
            }}
          />
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            className="subs-text-field subs-text-date"
            label="To"
            value={dateRange?.endDate || null}
            onChange={(event) => {
              setDateRange((prevVal) => ({
                ...prevVal,
                endDate: event.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            id="outlined-basic"
            label="Memo"
            className="sel-item subs-text-field"
            variant="outlined"
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mr: 5 }}
            size="small"
            onClick={() => {
              if (personInfo.personId) {
                getTableData(1, true, personInfo.personId);
              }
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ display: "flex", flexDirection: "column", mt: "1em" }}
      >
        <div className="title header">Memos</div>
        <StickyHeaderCon
          containerHeight="table-con"
          columnsList={memoCols}
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          maxWords={15}
          showAllFunc={setShowLargeText}
          onClick={editMemoHandler}
        />
      </Grid>
      <Drawer anchor="right" open={memoFlag} onClose={() => setMemoFlag(false)}>
        <CreateMemo
          onSave={(details) => {
            setMemoFlag(false);
            getTableData(1, true, personInfo.personId);
            toast.success("Memo Added Successfully", { autoClose: 2000 });
          }}
          onClose={() => setMemoFlag(false)}
          details={{
            typeModel: "Person",
            type: personInfo.personId,
            category: category,
          }}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showLargeText.isOpen}
        onClose={() => setShowLargeText({ isOpen: false, text: "" })}
      >
        <ShowMemo
          showMemoText={showLargeText}
          onClose={() => {
            setShowLargeText({
              isOpen: false,
              text: "",
              isClosed: false,
              isEdit: false,
              id: "",
              appliedDate: "",
              deposition: null,
            });
            getTableData(1, true, personInfo.personId);
          }}
        />
      </Drawer>
    </div>
  );
};

export default PersonMemo;
