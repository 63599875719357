import React, { useState, useEffect } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

const Delivery = ({ storeDeliveryAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(storeDeliveryAttributes);
    }
  }, [storeDeliveryAttributes]);

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const { deliverySourceInfo = {} } = formData;

  return (
    <div className="delivery-container">
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.zomato || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.zomato,
                "deliverySourceInfo",
                "zomato"
              );
            }}
          />
        }
        label="Zomato"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.swiggy || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.swiggy,
                "deliverySourceInfo",
                "swiggy"
              );
            }}
          />
        }
        label="Swiggy"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.none || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.none,
                "deliverySourceInfo",
                "none"
              );
            }}
          />
        }
        label="None"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.franchiseeSelfDelivery || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.franchiseeSelfDelivery,
                "deliverySourceInfo",
                "franchiseeSelfDelivery"
              );
            }}
          />
        }
        label="Franchise Self Delivery"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.grubHubSeamless || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.grubHubSeamless,
                "deliverySourceInfo",
                "grubHubSeamless"
              );
            }}
          />
        }
        label="GrubHub/Seamless"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.doorDash || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.doorDash,
                "deliverySourceInfo",
                "doorDash"
              );
            }}
          />
        }
        label="DoorDash"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.postmates || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.postmates,
                "deliverySourceInfo",
                "postmates"
              );
            }}
          />
        }
        label="Postmates"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.uberEats || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.uberEats,
                "deliverySourceInfo",
                "uberEats"
              );
            }}
          />
        }
        label="Uber Eats"
        labelPlacement="end"
      />
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={deliverySourceInfo?.foodPanda || false}
            onChange={() => {
              onInnerCheckboxChange(
                !deliverySourceInfo.foodPanda,
                "deliverySourceInfo",
                "foodPanda"
              );
            }}
          />
        }
        label="Food Panda"
        labelPlacement="end"
      />
      <div style={{ width: 300 }}>
        <TextField
          variant="outlined"
          fullWidth
          value={deliverySourceInfo?.orderhandlingCapacity || ""}
          name="orderhandlingCapacity"
          className="subs-text-field"
          onChange={(event) => onInnerInputChange(event, "deliverySourceInfo")}
          label="Order Handling capacity per 5 mins"
        />
      </div>
    </div>
  );
};
export default Delivery;
