import React, { useEffect, useState } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import Select from "react-select";
import { dateFun } from "../../../common-utilities/utils";
import {
  drivThru,
  electricityAmps,
  electricityCycleType,
  electricityLine,
  electricityVoltage,
  electricityWires,
  fruizle,
  menuLanguageType,
  menuOrientationType,
  menuType,
  television,
} from "./config";

const AttributesPage2 = ({ storeAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(storeAttributes);
    }
  }, [storeAttributes]);

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    onChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    speedOvensInfo = {},
    sodaInfo = {},
    electricityInfo = {},
    menuInfo = {},
    panelInfo = {},
    marketingInfo = {},
    programsInfo = {},
  } = formData;
  return (
    <div className="basic-fixed-container-attribute-page-2">
      <Grid container>
        <Grid xs={6}>
          <div className="title">Menu</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Type</p>
                <Select
                  placeholder="Type"
                  classNamePrefix="custom_select"
                  value={
                    formData?.menuInfo?.type
                      ? {
                          label: formData?.menuInfo?.type,
                          value: formData?.menuInfo?.type,
                        }
                      : null
                  }
                  options={menuType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        type: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        type: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Menu Orientation</p>
                <Select
                  placeholder="Menu Orientation"
                  classNamePrefix="custom_select"
                  value={
                    formData?.menuInfo?.menuOrientation
                      ? {
                          label: formData?.menuInfo?.menuOrientation,
                          value: formData?.menuInfo?.menuOrientation,
                        }
                      : null
                  }
                  options={menuOrientationType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        menuOrientation: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        menuOrientation: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Language</p>
                <Select
                  placeholder="Language"
                  classNamePrefix="custom_select"
                  value={
                    formData?.menuInfo?.language
                      ? {
                          label: formData?.menuInfo?.language,
                          value: formData?.menuInfo?.language,
                        }
                      : null
                  }
                  options={menuLanguageType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        language: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      menuInfo: {
                        ...formData.menuInfo,
                        language: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <TextField
                label="Manually set Calorie Date"
                type="date"
                className="subs-text-field"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(menuInfo.setCalorieDate) || ""}
                name="setCalorieDate"
                onChange={(e) => onInnerInputChange(e, "menuInfo")}
              />
            </div>
            <div className="g-r-2">
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={menuInfo?.sodium || false}
                      style={{ padding: "0px 9px" }}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !menuInfo?.sodium,
                          "menuInfo",
                          "sodium"
                        );
                      }}
                    />
                  }
                  label="Sodium"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={menuInfo?.caloriesMenus || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !menuInfo?.caloriesMenus,
                          "menuInfo",
                          "caloriesMenus"
                        );
                      }}
                    />
                  }
                  label="Calories Menus"
                  labelPlacement="end"
                />
              </div>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={menuInfo?.menuManagerOptOut || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !menuInfo?.menuManagerOptOut,
                        "menuInfo",
                        "menuManagerOptOut"
                      );
                    }}
                  />
                }
                label="Menu Manager Opt Out"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className="title">Electricity</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Cycles</p>
                <Select
                  placeholder="Cycles"
                  classNamePrefix="custom_select"
                  value={
                    formData?.electricityInfo?.cycles
                      ? {
                          label: formData?.electricityInfo?.cycles,
                          value: formData?.electricityInfo?.cycles,
                        }
                      : null
                  }
                  options={electricityCycleType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        cycles: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        cycles: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Voltage</p>
                <Select
                  placeholder="Voltage"
                  classNamePrefix="custom_select"
                  value={
                    formData?.electricityInfo?.voltage
                      ? {
                          label: formData?.electricityInfo?.voltage,
                          value: formData?.electricityInfo?.voltage,
                        }
                      : null
                  }
                  options={electricityVoltage}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        voltage: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        voltage: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Lines</p>
                <Select
                  placeholder="Lines"
                  classNamePrefix="custom_select"
                  value={
                    formData?.electricityInfo?.lines
                      ? {
                          label: formData?.electricityInfo?.lines,
                          value: formData?.electricityInfo?.lines,
                        }
                      : null
                  }
                  options={electricityLine}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        lines: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        lines: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Wires</p>
                <Select
                  placeholder="Wires"
                  classNamePrefix="custom_select"
                  value={
                    formData?.electricityInfo?.wires
                      ? {
                          label: formData?.electricityInfo?.wires,
                          value: formData?.electricityInfo?.wires,
                        }
                      : null
                  }
                  options={electricityWires}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        wires: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        wires: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Amps</p>
                <Select
                  placeholder="Amps"
                  classNamePrefix="custom_select"
                  value={
                    formData?.electricityInfo?.amps
                      ? {
                          label: formData?.electricityInfo?.amps,
                          value: formData?.electricityInfo?.amps,
                        }
                      : null
                  }
                  options={electricityAmps}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        amps: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      electricityInfo: {
                        ...formData.electricityInfo,
                        amps: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2">
              <TextField
                label="No Of Panels Main"
                fullWidth
                type="number"
                className="subs-text-field"
                value={electricityInfo.mainPanel || ""}
                name="mainPanel"
                onChange={(e) => onInnerInputChange(e, "electricityInfo")}
              />
              <TextField
                label="No Of Panels Sub"
                fullWidth
                className="subs-text-field"
                value={electricityInfo.subPanel || ""}
                type="number"
                name="subPanel"
                onChange={(e) => onInnerInputChange(e, "electricityInfo")}
              />
            </div>
          </div>
          <div className="title">Number of Breakers</div>
          <div className="form-details">
            <div className="g-r-2">
              <div className="g-r-2">
                <TextField
                  label="60 Amps"
                  fullWidth
                  className="subs-text-field"
                  type="number"
                  value={electricityInfo.mpsbreaker60 || ""}
                  name="mpsbreaker60"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <TextField
                  label="50 Amps"
                  fullWidth
                  type="number"
                  className="subs-text-field"
                  value={electricityInfo.mpsbreaker50 || ""}
                  name="mpsbreaker50"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  label="40 Amps"
                  fullWidth
                  type="number"
                  value={electricityInfo.mpsbreaker40 || ""}
                  name="mpsbreaker40"
                  className="subs-text-field"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <TextField
                  label="30 Amps"
                  fullWidth
                  type="number"
                  className="subs-text-field"
                  value={electricityInfo.mpsbreaker30 || ""}
                  name="mpsbreaker30"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
              </div>
            </div>
            <div className="g-r-2">
              <div className="g-r-2">
                <TextField
                  label="20 Amps"
                  fullWidth
                  type="number"
                  className="subs-text-field"
                  value={electricityInfo.mpsbreaker20 || ""}
                  name="mpsbreaker20"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <TextField
                  label="15 Amps"
                  fullWidth
                  type="number"
                  className="subs-text-field"
                  value={electricityInfo.mpsbreaker15 || ""}
                  name="mpsbreaker15"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  label="Spare/Blanks"
                  fullWidth
                  type="number"
                  className="subs-text-field"
                  value={electricityInfo.spareAndBlank || ""}
                  name="spareAndBlank"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <div></div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Panel</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Point of order 1"
                fullWidth
                className="subs-text-field"
                value={panelInfo?.pointOfOrder1 || ""}
                name="pointOfOrder1"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
              <TextField
                label="Point of order 2"
                fullWidth
                className="subs-text-field"
                value={panelInfo?.pointOfOrder2 || ""}
                name="pointOfOrder2"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="PO Footer 1"
                fullWidth
                className="subs-text-field"
                value={panelInfo?.PO1Footer || ""}
                name="PO1Footer"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
              <TextField
                label="PO Footer 2"
                fullWidth
                className="subs-text-field"
                value={panelInfo?.PO2Footer || ""}
                name="PO2Footer"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Drive Thru</p>
                <Select
                  placeholder="Drive Thru"
                  classNamePrefix="custom_select"
                  value={
                    formData?.panelInfo?.driveThru
                      ? {
                          label: formData?.panelInfo?.driveThru,
                          value: formData?.panelInfo?.driveThru,
                        }
                      : null
                  }
                  options={drivThru}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      panelInfo: {
                        ...formData.panelInfo,
                        driveThru: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      panelInfo: {
                        ...formData.panelInfo,
                        driveThru: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>

            <div className="g-r-2">
              <TextField
                label="DriveThru 1"
                fullWidth
                value={panelInfo?.driveThru1 || ""}
                name="driveThru1"
                className="subs-text-field"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
              <TextField
                label="DriveThru 2"
                className="subs-text-field"
                fullWidth
                value={panelInfo?.driveThru2 || ""}
                name="driveThru2"
                onChange={(event) => onInnerInputChange(event, "panelInfo")}
              />
            </div>
            {/* <div className="title" style={{ marginBottom: "-20px" }}>
              Speed Ovens
            </div>
            <div className="g-r-2">
              <TextField
                label="No Of Overs"
                fullWidth
                value={speedOvensInfo?.ofSpeedOven || ""}
                name="ofSpeedOven"
                onChange={(event) =>
                  onInnerInputChange(event, "speedOvensInfo")
                }
              />
              <Select
                placeholder="Speed Oven 1"
                classNamePrefix="custom_select"
              />
            </div>
            <div className="g-r-2">
              <Select
                placeholder="Speed Oven 2"
                classNamePrefix="custom_select"
              />
              <Select
                placeholder="Speed Oven 3"
                classNamePrefix="custom_select"
              />
            </div>
            <div className="g-r-2">
              <Select
                placeholder="Speed Oven 4"
                classNamePrefix="custom_select"
              />
            </div>
            <div className="g-r-2">
              <Select placeholder="Soda Type" classNamePrefix="custom_select" />
              <TextField
                label="Soda Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={sodaInfo?.date || ""}
                name="date"
                onChange={(event) => onInnerInputChange(event, "sodaInfo")}
              />
            </div>
            <div className="g-r-2">
              <Select placeholder="Bubbler" classNamePrefix="custom_select" />
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={sodaInfo?.selfServe || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !sodaInfo.selfServe,
                          "sodaInfo",
                          "selfServe"
                        );
                      }}
                    />
                  }
                  label="Self Serve"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={sodaInfo?.bottled || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !sodaInfo.bottled,
                          "sodaInfo",
                          "bottled"
                        );
                      }}
                    />
                  }
                  label="Bottled"
                  labelPlacement="end"
                />
              </div>
            </div> */}
            <Grid xs={12}>
              <div className="title">Marketing</div>
              <div className="form-details">
                <div className="g-r-2">
                  <TextField
                    label="Cookies units"
                    fullWidth
                    className="subs-text-field"
                    value={formData.driveThroughUnit || ""}
                    name="driveThroughUnit"
                    onChange={onInputChange}
                  />

                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        style={{ padding: "0px 9px" }}
                        checked={marketingInfo?.lineStanchions || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !marketingInfo.lineStanchions,
                            "marketingInfo",
                            "lineStanchions"
                          );
                        }}
                      />
                    }
                    label="Line Stanchions"
                    labelPlacement="end"
                  />
                </div>
                <div className="g-r-2 m-b">
                  <TextField
                    label="Purse Rail for Chip Display"
                    fullWidth
                    className="subs-text-field"
                    value={marketingInfo.purseRail || ""}
                    name="purseRail"
                    onChange={(e) => onInnerInputChange(e, "marketingInfo")}
                  />
                  <div>
                    <p className="dropdown-title ">Television</p>
                    <Select
                      placeholder="Television"
                      classNamePrefix="custom_select"
                      value={
                        formData?.marketingInfo?.television
                          ? {
                              label: formData?.marketingInfo?.television,
                              value: formData?.marketingInfo?.television,
                            }
                          : null
                      }
                      options={television}
                      onChange={(e) => {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          marketingInfo: {
                            ...formData.marketingInfo,
                            television: e?.value,
                          },
                        }));
                        onChange((prevVal) => ({
                          ...prevVal,
                          marketingInfo: {
                            ...formData.marketingInfo,
                            television: e?.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="title">Programs</div>
              <div className="form-details">
                <div className="g-r-2">
                  <TextField
                    label="Breakfast"
                    fullWidth
                    className="subs-text-field"
                    value={programsInfo.breakfast || ""}
                    name="breakfast"
                    onChange={(event) =>
                      onInnerInputChange(event, "programsInfo")
                    }
                  />

                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        style={{ padding: "0px 9px" }}
                        checked={programsInfo?.omelet || false}
                        onChange={() => {
                          onInnerCheckboxChange(
                            !programsInfo.omelet,
                            "programsInfo",
                            "omelet"
                          );
                        }}
                      />
                    }
                    label="Omelet"
                    labelPlacement="end"
                  />
                </div>
                <div className="g-r-2">
                  <TextField
                    label="Specialty"
                    fullWidth
                    className="subs-text-field"
                    value={programsInfo.speciality || ""}
                    name="speciality"
                    onChange={(event) =>
                      onInnerInputChange(event, "programsInfo")
                    }
                  />
                  <div>
                    <p className="dropdown-title ">Fruizle</p>
                    <Select
                      classNamePrefix="custom_select"
                      placeholder="Fruizle"
                      value={
                        formData?.programsInfo?.frizzle
                          ? {
                              label: formData?.programsInfo?.frizzle,
                              value: formData?.programsInfo?.frizzle,
                            }
                          : null
                      }
                      options={fruizle}
                      onChange={(e) => {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          programsInfo: {
                            ...formData.programsInfo,
                            frizzle: e?.value,
                          },
                        }));
                        onChange((prevVal) => ({
                          ...prevVal,
                          programsInfo: {
                            ...formData.programsInfo,
                            frizzle: e?.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Additional Breakers</div>
          <div className="form-details">
            <div className="g-r-2">
              <div className="g-r-2">
                <TextField
                  label="Amp Size"
                  fullWidth
                  value={electricityInfo.additionalAmpSize1 || ""}
                  name="additionalAmpSize1"
                  type="number"
                  className="subs-text-field"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <TextField
                  label="Quantity"
                  fullWidth
                  value={electricityInfo.additionalAmpQty1 || ""}
                  name="additionalAmpQty1"
                  className="subs-text-field"
                  type="number"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  label="Amp Size"
                  fullWidth
                  className="subs-text-field"
                  value={electricityInfo.additionalAmpSize2 || ""}
                  name="additionalAmpSize2"
                  type="number"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
                <TextField
                  label="Quantity"
                  fullWidth
                  className="subs-text-field"
                  value={electricityInfo.additionalAmpQty2 || ""}
                  name="additionalAmpQty2"
                  type="number"
                  onChange={(e) => onInnerInputChange(e, "electricityInfo")}
                />
              </div>
            </div>
          </div>
        </Grid>

        <Grid xs={6}>
          <div className="title"></div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="School Lunch Expiration"
                type="date"
                className="subs-text-field"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={dateFun(programsInfo.sclLunchExpirationDate) || ""}
                name="sclLunchExpirationDate"
                onChange={(event) => onInnerInputChange(event, "programsInfo")}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default AttributesPage2;
