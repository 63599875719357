import React from "react";
import "./index.scss";
import SelectRestaurant from "../upload-document/selectRestaurant";

const Chronology = () => {
  return (
    <div>
      <SelectRestaurant menuType="chronology" />
    </div>
  );
};
export default Chronology;
