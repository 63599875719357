import CustomModal from "../../../common-utilities/core/modal";
import "./index.scss";
import { useState } from "react";
import { HTMLTable } from "../../../common-utilities/core/table/html-table";
import ConfirmModal from "../../../common-utilities/core/confirm-modal";
import { Button } from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import CommonBtn from "../../../common-utilities/core/common-button";

export const InfomationModal = ({
  title,
  onClose,
  useSiTable,
  data,
  allLabelsAndKeys,
  onSuccess,
}) => {
  const [page, setPage] = useState(1);
  const deleteData = () => {
    const deleteUrl = data?.url;
    const method = data?.method || HTTP_METHODS.DELETE;
    if (deleteUrl) {
      invokeApi(method, deleteUrl)
        .then((res) => {
          if (res?.message) {
            toast.error(res?.message, { autoClose: 2000 });
            return;
          }
          const message = data?.message ? data?.message : "Delete successfully";
          toast.success(message, { autoClose: 2000 });
          onSuccess && onSuccess();
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: 2000 });
        });
    }
  };
  return (
    <div className="information-modal">
      <CustomModal title={title} onClose={onClose}>
        {data?.delete ? (
          <>
            {/* <ConfirmModal /> */}
            <div className="btn-container">
              <CommonBtn variant="contained" size="small" onClick={deleteData} text="Yes" />
              <CommonBtn variant="outlined" size="small" onClick={onClose} text="No" />
            </div>
          </>
        ) : (
          <>
            {useSiTable ? (
              <HTMLTable
                header={allLabelsAndKeys}
                filters={page}
                data={data.slice((page - 1) * 10, (page - 1) * 10 + 10)}
                pageCount={Math.ceil(data.length / 10)}
                onChange={(_, page) => {
                  setPage(page);
                }}
              />
            ) : (
              <>
                {allLabelsAndKeys.map((item, index) => {
                  return (
                    <div className="item-label-value" key={index}>
                      <div>{item.label}</div>
                      <div>
                        -
                        {item.convertedValue
                          ? item?.convertedValue(data)
                          : data[item.value]}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </CustomModal>
    </div>
  );
};
