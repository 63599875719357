import UploadIcon from "@mui/icons-material/Upload";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleIcon from "@mui/icons-material/Article";
import { USERROLES } from "../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import { endPointsForProject, getProjectEndPoint } from "../../../../routes/endpoints";

export const SIDE_MENU = () => {
  const user = getOfflineData(tokenMainKeys.docuStorage, "user");
  const docuStorageEndpoint = getProjectEndPoint(endPointsForProject.docuStorage)

  let sideMenuOptions = [
    // {
    //   label: "Dasboard",
    //   url: "/",
    //   value: "/",
    //   logo: <DashboardIcon sx={{ mr: 1 }} />,
    // },
    {
      label: "Document Upload",
      url: "/upload",
      value: "/upload",
      logo: <UploadIcon sx={{ mr: 1 }} />,
      mapkey: 1,
    },
    {
      label: "Chronology",
      url: "/chronology",
      value: "/chronology",
      logo: <TimelineIcon sx={{ mr: 1 }} />,
      mapkey: 2,
    },
    {
      label: "Documents By Company",
      url: "/upload-company-documents/mysubway-c9d07e26-0146-4678-9f5b-f8abe9f25a76",
      value:
        "/upload-company-documents/mysubway-c9d07e26-0146-4678-9f5b-f8abe9f25a76",
      logo: <ArticleIcon sx={{ mr: 1 }} />,
      mapkey: 3,
    },
    {
      label: "Reports",
      url: "/reports",
      value: "/reports",
      logo: <AssessmentIcon sx={{ mr: 1 }} />,
      mapkey: 4,
    },
  ];
  if (user?.role === USERROLES.admin) {
    sideMenuOptions = [
      ...sideMenuOptions,
      {
        label: "TAT",
        url: "/tat",
        value: "/tat",
        logo: <AssessmentIcon sx={{ mr: 1 }} />,
        mapkey: 5,
      },
     
    ];
  }
  sideMenuOptions = sideMenuOptions.map((item) => {
    const {url} = item
    return {...item, url:`${docuStorageEndpoint}${url}`}
  })
  return sideMenuOptions;
};

export const MOBILE_MENU = [
  {
    label: "Details",
    url: "/",
    value: "/",
  },
  {
    label: "Upload ",
    url: "/upload",
    value: "/upload",
  },
  {
    label: "Documents By Company",
    url: "/upload-company-documents/mysubway-c9d07e26-0146-4678-9f5b-f8abe9f25a76",
    value:
      "/upload-company-documents/mysubway-c9d07e26-0146-4678-9f5b-f8abe9f25a76",
  },
];
