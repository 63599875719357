import { Button } from "@mui/material";
import { getPurchaseOrderAmount } from "../../purchase-order/helper";
import { Checkbox } from "@mui/material";
export const getHeaderConfig = (
  editVendor,
  handleRowSelection,
  selectedGrnList,
  dataList
) => {
  const headers = [
    {
      id: "checkbox",
      label: (
        <Checkbox
          onChange={(event) => handleRowSelection(event, null, "selectAll")}
          checked={
            selectedGrnList.length === dataList.length && dataList.length !== 0
          }
        />
      ),
      sortable: false,
      render: (data, index) => {
        return (
          <Checkbox
            checked={
              selectedGrnList.find((ele) => ele.id === data.id) ? true : false
            }
            onChange={(event) => handleRowSelection(event, data)}
          />
        );
      },
    },
    {
      label: "GRN NO",
      id: "grnNo",
      render: (data) => {
        const { document = {} } = data;
        const { grnNo = "", grnId = "" } = document || {};
        return grnNo || grnId;
      },
    },
    {
      label: "PO Number",
      id: "poNumber",
      render: (data) => {
        const { document = {} } = data;
        const { poNumber = "" } = document || {};
        return poNumber;
      },
    },
    {
      label: "Site Code",
      id: "site.code",
    },
    {
      label: "Site Name",
      id: "site.name",
    },
    {
      label: "Vendor Code",
      id: "vendorCode",
      render: (data) => {
        const { document = {} } = data;
        const { vendorCode = "" } = document || {};
        return vendorCode;
      },
    },
    {
      label: "Vendor Name",
      id: "vendorName",
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document;
        return (poId && poId.vendorName) || "";
      },
    },
    {
      label: "Pending With",
      id: "pendingWith",
    },
    {
      label: "Total PO Value",
      id: "poValue",
      render: (data) => {
        const { document = {} } = data;
        const { poId = {} } = document;
        const { items = [] } = poId || {};
        return getPurchaseOrderAmount(items);
      },
    },
    {
      label: "Total Invoice Value",
      id: "invoiceValue",
      render: (data) => {
        const { document = {} } = data;
        const { grnId = {} } = document;
        const { items = [] } = grnId || {};
        return document.invoiceAmount.toFixed(2);
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        const { status } = data;
        return (
          <>
            <Button variant="text" onClick={() => editVendor(data, "VIEW")}>
              View
            </Button>
          </>
        );
      },
    },
  ];

  return headers;
};
