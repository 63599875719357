import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Select from "react-select";
import InfoIcon from "@mui/icons-material/Info";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { toast } from "react-toastify";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { invokeApi } from "../../utils/http-service";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { IconButton, Divider } from "@mui/material";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { dateFun } from "../../../common-utilities/utils";
const initialData = {
  storeNo: "",
  satelliteNo: "",
  sequenceNo: "",
  leaseId: "",
  leaseRadius: {
    radius: false,
    distance: "",
    comment: "",
    lastModifiedBy: "",
  },
  tenantImprovementCheck: {
    amount: "",
    recordDate: "",
  },
  dates: {
    subleaseSent: "",
    subleaseRecd: "",
    subleaseSigned: "",
    annulMailingSent: "",
    annulMailingRecd: "",
    subleaseRefundReturn: "",
    leaseRecorded: "",
    recordingFee: "",
    candAssignmentSent: "",
    candAssignmentRecd: "",
    startFileLetterSent: "",
    expirationLetterSent: "",
    missingItemsFollowUp: "",
    threeMthLetterSent: "",
    oneYearRenewalLetterSent: "",
  },
  subletNoticeRequired: "",
  franchiseInfoRequested: false,
  copyOfSubleaseRequested: false,
  comments: "",
  scan: "",
};
const LeaseMaintenancePage2Container = () => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState(initialData);
  const labels = ["Lease Maintenance", "Lease Maintenance Page 2"];
  const [tableData, setTableData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [leaseInput, setLeaseInput] = useState("");
  const [leaseOptions, setLeaseOptions] = useState([]);
  const previousController = useRef();
  const [tableData2, setTableData2] = useState([
    { label: "Current Rent Invoice", checked: false },
    { label: "Document Extending Lease Term", checked: false },
    { label: "Insurance", checked: false },
    { label: "Recording Fee", checked: false },
  ]);
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };
  const loadDataHandler = (id) => {
    if (id) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_LEASE_MAINTENANCE}${id}`
      )
        .then((res) => {
          if (res.message) {
            setData(initialData);
            setLeaseInput("");
            setTableData2([
              { label: "Current Rent Invoice", checked: false },
              { label: "Document Extending Lease Term", checked: false },
              { label: "Insurance", checked: false },
              { label: "Recording Fee", checked: false },
            ]);
          } else {
            setData((prevVal) => ({
              ...prevVal,
              storeNo: res?.store?.storeNo,
              satelliteNo: res?.store?.satelliteNum,
              sequenceNo: res?.store?.sequenceNo,
              leaseRadius: {
                radius: res?.leaseMaintenance?.leaseRadius?.radius,
                distance: res?.leaseMaintenance?.leaseRadius?.distance,
                comment: res?.leaseMaintenance?.leaseRadius?.comment,
                lastModifiedBy:
                  res?.leaseMaintenance?.leaseRadius?.lastModifiedBy,
              },
              tenantImprovementCheck: {
                amount: res?.leaseMaintenance?.tenantImprovementCheck?.amount,
                recordDate:
                  res?.leaseMaintenance?.tenantImprovementCheck?.recordDate?.split(
                    "T"
                  )[0],
              },
              dates: {
                subleaseSent:
                  res?.leaseMaintenance?.dates?.subleaseSent?.split("T")[0],
                subleaseRecd:
                  res?.leaseMaintenance?.dates?.subleaseRecd?.split("T")[0],
                subleaseSigned:
                  res?.leaseMaintenance?.dates?.subleaseSigned?.split("T")[0],
                annulMailingSent:
                  res?.leaseMaintenance?.dates?.annulMailingSent?.split("T")[0],
                annulMailingRecd:
                  res?.leaseMaintenance?.dates?.annulMailingRecd?.split("T")[0],
                subleaseRefundReturn:
                  res?.leaseMaintenance?.dates?.subleaseRefundReturn?.split(
                    "T"
                  )[0],
                leaseRecorded:
                  res?.leaseMaintenance?.dates?.leaseRecorded?.split("T")[0],
                recordingFee:
                  res?.leaseMaintenance?.dates?.recordingFee?.split("T")[0],
                candAssignmentSent:
                  res?.leaseMaintenance?.dates?.candAssignmentSent?.split(
                    "T"
                  )[0],
                candAssignmentRecd:
                  res?.leaseMaintenance?.dates?.candAssignmentRecd?.split(
                    "T"
                  )[0],
                startFileLetterSent:
                  res?.leaseMaintenance?.dates?.startFileLetterSent?.split(
                    "T"
                  )[0],
                expirationLetterSent:
                  res?.leaseMaintenance?.dates?.expirationLetterSent?.split(
                    "T"
                  )[0],
                missingItemsFollowUp:
                  res?.leaseMaintenance?.dates?.missingItemsFollowUp?.split(
                    "T"
                  )[0],
                threeMthLetterSent:
                  res?.leaseMaintenance?.dates?.threeMthLetterSent?.split(
                    "T"
                  )[0],
                oneYearRenewalLetterSent:
                  res?.leaseMaintenance?.dates?.oneYearRenewalLetterSent?.split(
                    "T"
                  )[0],
              },
              subletNoticeRequired: res?.leaseMaintenance?.subletNoticeRequired,
              franchiseInfoRequested:
                res?.leaseMaintenance?.franchiseInfoRequested,
              copyOfSubleaseRequested:
                res?.leaseMaintenance?.copyOfSubleaseRequested,
              comments: res?.leaseMaintenance?.comments,
              scan: res?.leaseMaintenance?.scan,
            }));
            if (res?.leaseMaintenance?.dates?.leasingDocumentsNeeded) {
              setTableData2(res.leaseMaintenance.dates.leasingDocumentsNeeded);
            }
            setIsDisabled(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const editHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele] !== "" && data[ele] !== undefined && data[ele] !== null) {
        payload[ele] = data[ele];
      }
      if (ele === "leaseRadius") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["leaseRadius"] = obj;
      }
      if (ele === "tenantImprovementCheck") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["tenantImprovementCheck"] = obj;
      }
      if (ele === "dates") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }
        obj["leasingDocumentsNeeded"] = tableData2;
        payload["dates"] = obj;
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_LEASE_MAINTENANCE}${data.leaseId}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Updated Successfully", { autoClose: 2000 });
          setData(initialData);
          setLeaseInput("");
          setTableData2([
            { label: "Current Rent Invoice", checked: false },
            { label: "Document Extending Lease Term", checked: false },
            { label: "Insurance", checked: false },
            { label: "Recording Fee", checked: false },
          ]);
        }
      })
      .catch((err) => console.log(err));
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.LEASE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.leaseId, id: p.leaseId };
      });
      setLeaseOptions(updatedOptions);
      setLeaseInput(searchTerm);
    });
  };
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      setData((prevVal) => ({ ...prevVal, leaseId: value }));
    } else {
      setLeaseOptions([]);
      setLeaseInput("");
      setData(initialData);
      setIsDisabled(true);
    }
  };
  return (
    <div className="lease-maintenance-details">
      <div style={displayContainer}>
        <TextField
          label="Store"
          onChange={(e) => {
            setData((prevVal) => ({ ...prevVal, storeNo: e.target.value }));
          }}
          value={data.storeNo}
        />
        <TextField label="Satellite" value={data.satelliteNo} disabled={true} />
        <TextField label="Sequence" value={data.sequenceNo} disabled={true} />
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          getOptionLabel={(option) => {
            return option.title ? option.title.toString() : "";
          }}
          options={leaseOptions}
          onInputChange={onInputChange}
          inputValue={leaseInput}
          sx={{ width: "12em" }}
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                setLeaseInput("");
                setLeaseOptions([]);
                setData(initialData);
                setTableData2([
                  { label: "Current Rent Invoice", checked: false },
                  { label: "Document Extending Lease Term", checked: false },
                  { label: "Insurance", checked: false },
                  { label: "Recording Fee", checked: false },
                ]);
              }}
            />
          }
          renderInput={(params) => (
            <TextField {...params} className="sel-item" label="Lease Id" />
          )}
          onChange={(e, val) => {
            if (val) {
              loadDataHandler(val.id);
            }
          }}
        />
        {/* <TextField
          label="Lease ID"
          onChange={(e) => {
            setData((prevVal) => ({ ...prevVal, leaseId: e.target.value }));
          }}
          value={data.leaseId}
        /> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "blue",
          }}
        >
          <div>Pending Leases:0</div>
          <div>Legacy Leases:0</div>
        </div> */}
        <div
          style={{
            display: "flex",
            color: "blue",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={isDisabled || data.leaseId == ""}
            onClick={editHandler}
          >
            Save
          </Button>
        </div>
      </div>
      <Divider style={{ paddingBottom: 10 }} />
      <Grid container spacing={1}>
        <Grid spacing={1} xs={6}>
          <Grid xs={12}>
            <div className="title">Lease Radius Information</div>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={6}>
              <div style={displayContainer}>
                <span>Radius</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      leaseRadius: {
                        ...data.leaseRadius,
                        radius: !data.leaseRadius.radius,
                      },
                    }));
                  }}
                  checked={data.leaseRadius.radius}
                />
              </div>
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Distance"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseRadius: {
                      ...data.leaseRadius,
                      distance: e.target.value,
                    },
                  }));
                }}
                value={data.leaseRadius.distance}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Comments"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseRadius: {
                      ...data.leaseRadius,
                      comment: e.target.value,
                    },
                  }));
                }}
                value={data.leaseRadius.comment}
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                label="Last Modified By"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseRadius: {
                      ...data.leaseRadius,
                      lastModifiedBy: e.target.value,
                    },
                  }));
                }}
                value={data.leaseRadius.lastModifiedBy}
              />
            </Grid>
          </Grid>

          {/* <Grid xs={12}>
            <MainTabContainer
              mainClass="mainTabContainer2"
              tabContainerClass="tab-container"
              tabsClass="custom-tabs"
              value={value}
              setValue={setValue}
              labels={labels}
            />
            <div className="tab-panel-container">
              <TabPanel value={value} index={0}>
                <div style={{ width: "100%" }} className="table-con">
                  <TableContainer component={Paper}>
                    <Table >
                      <TableHead
                        sx={{ backgroundColor: "#008a52", color: "white" }}
                      >
                        <TableRow>
                          <TableCell className="table-cell">
                            Store Number
                          </TableCell>
                          <TableCell className="table-cell">
                            Satellite Number
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.map((ele, index) => (
                          <TableRow key={index}>
                            <TableCell>{ele.storeNumber}</TableCell>
                            <TableCell>{ele.satelliteNumber}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            </div>
          </Grid> */}

          <Divider style={{ paddingBottom: 10 }} />

          <div className="title">Tenant Improvement Check</div>

          <Grid container xs={12}>
            <Grid xs={6}>
              <TextField
                label="Amount"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    tenantImprovementCheck: {
                      ...data.tenantImprovementCheck,
                      amount: e.target.value,
                    },
                  }));
                }}
                value={data.tenantImprovementCheck.amount}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Record Date"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    tenantImprovementCheck: {
                      ...data.tenantImprovementCheck,
                      recordDate: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.tenantImprovementCheck.recordDate) || ""}
              />
            </Grid>
          </Grid>

          <Divider style={{ paddingBottom: 10 }} />
          <div className="title">Sublet Information </div>

          <Grid container xs={12}>
            <Grid xs={12}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                placeholder="Sublet Notice Required"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    subletNoticeRequired: e.value,
                  }));
                }}
                value={data.subletNoticeRequired}
              />
            </Grid>
            <Grid xs={6}>
              <div style={displayContainer}>
                <span>Franchisee Info Requested</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      franchiseInfoRequested: !data.franchiseInfoRequested,
                    }));
                  }}
                  checked={data.franchiseInfoRequested}
                />
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <span>Copy of Sublease Requested</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      copyOfSubleaseRequested: !data.copyOfSubleaseRequested,
                    }));
                  }}
                  checked={data.copyOfSubleaseRequested}
                />
              </div>
            </Grid>
            <Grid xs={12}>
              <div style={displayContainer}>
                <span> Lease Comments</span>
                <TextareaAutosize
                  className="commentText"
                  aria-label="minimum height"
                  minRows={2}
                  placeholder="2 Months notice required for kickout"
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      comments: e.target.value,
                    }));
                  }}
                  value={data.comments}
                />
                <IconButton>
                  <InfoIcon color="primary" size="large" fontSize="60" />
                </IconButton>
                <span> Scan:</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      scan: !data.scan,
                    }));
                  }}
                  checked={data.scan}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid spacing={1} xs={6}>
          <div className="title">Dates</div>

          <Grid container xs={12}>
            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Sublease Sent"
                  type="date"
                  className="w-80"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      dates: {
                        ...data.dates,
                        subleaseSent: e.target.value,
                      },
                    }));
                  }}
                  value={dateFun(data.dates.subleaseSent) || ""}
                />
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Sublease Rec'd"
                  type="date"
                  className="w-80"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      dates: {
                        ...data.dates,
                        subleaseRecd: e.target.value,
                      },
                    }));
                  }}
                  value={dateFun(data.dates.subleaseRecd) || ""}
                />
              </div>
            </Grid>

            <Grid xs={6}>
              <div style={displayContainer}>
                <TextField
                  label="Sublease Signed"
                  type="date"
                  className="w-80"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      dates: {
                        ...data.dates,
                        subleaseSigned: e.target.value,
                      },
                    }));
                  }}
                  value={dateFun(data.dates.subleaseSigned) || ""}
                />
              </div>
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Annual SREC Mailing Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      annulMailingSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.annulMailingSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Annual SREC Mailing Rec'd"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      annulMailingRecd: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.annulMailingRecd) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Sublease Refund Return"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      subleaseRefundReturn: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.subleaseRefundReturn) || ""}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Lease Recorded"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      leaseRecorded: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.leaseRecorded) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Recording Fee Rec'd:"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      recordingFee: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.recordingFee) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Cond. Assignment Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      candAssignmentSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.candAssignmentSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Cond. Assignment Rec'd"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      candAssignmentRecd: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.candAssignmentRecd) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Start File Letter Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      startFileLetterSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.startFileLetterSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Three Mth LL Letter Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      threeMthLetterSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.threeMthLetterSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="One Year Renewal Letter Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      oneYearRenewalLetterSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.oneYearRenewalLetterSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Expiration Letter Sent"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      expirationLetterSent: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.expirationLetterSent) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Missing Items Follow-up"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dates: {
                      ...data.dates,
                      missingItemsFollowUp: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.dates.missingItemsFollowUp) || ""}
              />
            </Grid>

            <Grid xs={12} sx={{ display: "flex" }}>
              <div>Leasing Documents Needed:</div>
              <div style={{ width: "100%" }} className="table-con">
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {tableData2.map((ele, index) => (
                        <div>
                          <Checkbox
                            className="check"
                            checked={ele.checked}
                            onChange={(e) => {
                              setTableData(
                                (tableData2[index] = {
                                  ...tableData2[index],
                                  checked: !ele.checked,
                                })
                              );
                            }}
                          />
                          {ele.label}
                        </div>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaseMaintenancePage2Container;
