import CustomModal from "../../../common-utilities/core/modal";
import {
  Button,
  Box,
  TextField,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useState, useEffect } from "react";
import { USER_ROLES, belongsToInUserRoles } from "../../utils";
import {
  editUserHandler,
  createUser,
  disabledCheckForModal,
} from "./user-modal-utils";
import Select from "react-select";
import { checkForForUserSelectedTab } from "./user-modal-utils-2";
const CreateEditUserModal = ({
  addUser,
  editUser,
  modelDetails,
  closeCreateModal,
  value,
  userDetails,
  setuserDetails,
  loadData,
  dcCumVendorCheckbox,
  setDcCumVendorCheckbox,
  vendorList,
  dcList,
  userRoles,
  filters,
}) => {
  const tabsChecker = checkForForUserSelectedTab(value);
  const isUserQA = userDetails?.role?.type === USER_ROLES.QA;
  const isUserActionTakerOrCreditIssuer =
    userDetails?.role?.type === USER_ROLES.ACTION_TAKER ||
    userDetails?.role?.type === USER_ROLES.CREDIT_ISSUER;
  const [showRole, setShowRole] = useState(true);
  useEffect(() => {
    const doNotShowRole =
      !!userDetails?.role?.label?.includes("STORE") ||
      !!userDetails?.role?.label?.includes("Store");
    if (doNotShowRole) {
      setShowRole(false);
    } else {
      setShowRole(true);
    }
  }, [userDetails]);

  const setuserDetailsOnChange = (e, details) => {
    if (e.target) {
      const { name, value } = e.target;
      const splittedName = name.split(".");
      const [mainKey, subKey] = splittedName;
      setuserDetails((prevDetails) => {
        if (splittedName.length === 1) {
          return { ...prevDetails, [name]: value };
        }
        const mainDetails = prevDetails?.[mainKey]
          ? { ...prevDetails?.[mainKey] }
          : {};

        mainDetails[subKey] = value;
        return { ...prevDetails, [mainKey]: mainDetails };
      });
      return;
    }
    const { name } = details;
    const belongsTo = e?.belongsTo;
    const type = e?.type;
    const splittedName = name.split(".");
    const [mainKey, subKey] = splittedName;

    let allDetails = { ...userDetails };
    if (name === "role") {
      switch (belongsTo) {
        case belongsToInUserRoles.vendor:
          if (type !== "QA") {
            allDetails.dcCumVendor = true;
          }
          setDcCumVendorCheckbox(false);
          break;
        case belongsToInUserRoles.dc:
          if (type === USER_ROLES.DC) {
            allDetails.dcCumVendor = false;
            setDcCumVendorCheckbox(true);
          } else {
            setDcCumVendorCheckbox(false);
          }
          break;
        default:
          setDcCumVendorCheckbox(false);
          break;
      }

      delete allDetails.dc;
      delete allDetails.vendor;
      delete allDetails.distributors;
    }

    setuserDetails(() => {
      if (splittedName.length === 1) {
        return {
          ...allDetails,
          [name]: e,
        };
      }

      const mainDetails = allDetails?.[mainKey]
        ? { ...allDetails?.[mainKey] }
        : {};
      mainDetails[subKey] = e;

      return {
        ...allDetails,
        [mainKey]: mainDetails,
      };
    });
  };
  return (
    <>
      {(addUser || editUser) && (
        <CustomModal
          title={modelDetails.mainTitle}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={closeCreateModal}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="name"
              sx={{ my: 1 }}
              label={`${
                value === 0
                  ? "Name"
                  : value === 1
                  ? "DC Name"
                  : "Manufacturer Name"
              }`}
              name="name"
              multiline
              required
              onChange={setuserDetailsOnChange}
              value={userDetails.name || ""}
              size="small"
            />
            <TextField
              fullWidth
              id="email"
              sx={{ my: 1 }}
              required
              label="Email"
              name="email"
              onChange={setuserDetailsOnChange}
              value={userDetails.email || ""}
              type="email"
              size="small"
            />
            {tabsChecker.allUsersFirstTab && (
              <TextField
                fullWidth
                id="password"
                label={`${addUser ? "Password" : "New Password"}`}
                required
                sx={{ my: 1 }}
                type="password"
                name="password"
                onChange={setuserDetailsOnChange}
                value={userDetails.password || ""}
                size="small"
              />
            )}

            {tabsChecker.distirbutorsTab && (
              <TextField
                fullWidth
                label="DC Code"
                sx={{ my: 1 }}
                name="dcCode"
                onChange={setuserDetailsOnChange}
                value={userDetails?.dcCode || ""}
                size="small"
              />
            )}

            {tabsChecker.suppliersTab && (
              <TextField
                fullWidth
                label="Manufacturer Number"
                sx={{ my: 1 }}
                name="manufacturerNumber"
                onChange={setuserDetailsOnChange}
                value={userDetails?.manufacturerNumber || ""}
                size="small"
              />
            )}

            {tabsChecker.suppliersTab && (
              <TextField
                fullWidth
                label="Plant Location"
                sx={{ my: 1 }}
                name="plantLocation"
                onChange={setuserDetailsOnChange}
                value={userDetails?.plantLocation || ""}
                size="small"
              />
            )}

            {tabsChecker.suppliersTab && (
              <TextField
                fullWidth
                label="SPL Code"
                sx={{ my: 1 }}
                name="splCode"
                onChange={setuserDetailsOnChange}
                value={userDetails?.splCode || ""}
                size="small"
              />
            )}

            {(tabsChecker.distirbutorsTab || tabsChecker.suppliersTab) && (
              <TextField
                fullWidth
                label="Phone"
                sx={{ my: 1 }}
                name="phone"
                onChange={setuserDetailsOnChange}
                value={userDetails?.phone || ""}
                size="small"
              />
            )}

            {(tabsChecker.distirbutorsTab || tabsChecker.suppliersTab) && (
              <TextField
                fullWidth
                label="Address"
                sx={{ my: 1 }}
                name="address"
                onChange={setuserDetailsOnChange}
                value={userDetails?.address}
                size="small"
              />
            )}

            {(tabsChecker.distirbutorsTab || tabsChecker.suppliersTab) && (
              <TextField
                fullWidth
                label="City"
                sx={{ my: 1 }}
                name="city"
                onChange={setuserDetailsOnChange}
                value={userDetails?.city || ""}
                size="small"
              />
            )}
            {userDetails?.role?.label?.includes("STORE")}
            {tabsChecker.allUsersFirstTab && showRole && (
              <div>
                Role
                <Select
                  options={userRoles.filter(
                    (item) =>
                      item.type !== "STORE_OWNER" && item.type !== "STORE"
                  )}
                  className="create-edit-select"
                  placeholder="Select Roles"
                  classNamePrefix="subway-select"
                  name="role"
                  value={userDetails.role || null}
                  onChange={setuserDetailsOnChange}
                />
              </div>
            )}

            {(isUserQA || isUserActionTakerOrCreditIssuer) &&
              userDetails?.role?.belongsTo === "DC" && (
                <div>
                  Distribution Center
                  <Select
                    options={dcList}
                    placeholder="Select Distributor Center"
                    classNamePrefix="subway-select"
                    isMulti={isUserActionTakerOrCreditIssuer}
                    name={`${isUserQA ? "dc" : "distributors"}`}
                    value={
                      isUserQA
                        ? userDetails.dc || null
                        : userDetails.distributors || null
                    }
                    onChange={setuserDetailsOnChange}
                  />
                </div>
              )}

            {userDetails?.role?.type === "QA" &&
              userDetails?.role?.belongsTo === "VENDOR" && (
                <div>
                  Supplier
                  <Select
                    options={vendorList}
                    placeholder="Select Supplier"
                    classNamePrefix="subway-select"
                    name="vendor"
                    value={userDetails.vendor || null}
                    onChange={setuserDetailsOnChange}
                  />
                </div>
              )}

            {dcCumVendorCheckbox && (
              <FormControlLabel
                value="start"
                checked={userDetails.dcCumVendor || false}
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      setuserDetails((prevDetails) => ({
                        ...prevDetails,
                        dcCumVendor: !userDetails.dcCumVendor,
                      }));
                    }}
                  />
                }
                label="Is DC a Vendor?"
                labelPlacement="start"
              />
            )}

            {tabsChecker.suppliersTab && (
              <FormControlLabel
                value="start"
                checked={userDetails.isVegetableVendor || false}
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      setuserDetails((prevDetails) => ({
                        ...prevDetails,
                        isVegetableVendor: !userDetails.isVegetableVendor,
                      }));
                    }}
                  />
                }
                label="Is a Vegetable Vendor?"
                labelPlacement="start"
              />
            )}

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={disabledCheckForModal(
                  userDetails,
                  addUser,
                  editUser,
                  value
                )}
                onClick={() => {
                  if (addUser) {
                    createUser(
                      userDetails,
                      closeCreateModal,
                      loadData,
                      value,
                      filters
                    );
                  }

                  if (editUser) {
                    editUserHandler(
                      userDetails,
                      closeCreateModal,
                      loadData,
                      value,
                      filters
                    );
                  }
                }}
                fullWidth
              >
                {modelDetails.buttonTitle}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  closeCreateModal();
                }}
                fullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default CreateEditUserModal;
