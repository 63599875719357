import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Divider, IconButton, TextField } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { getOfflineData } from "../../../utils/offline-services";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import ApprovalStageDetails from "../../approval-stage-details";
import {
  getAvailableBudgetCost,
  getInitialBudgetCost,
  getModifiedBudgetCost,
} from "../budget-details/helper";

const BudgetApprovalDetails = () => {
  const { id } = useParams();
  const userDetails = getOfflineData("user");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalId = searchParams.get("approvalId");
  const [approveRejectDetails, setApproveRejectDetails] = useState(null);
  const [budgetDetails, setBudgetDetails] = useState({});
  const [approvalDetails, setApprovalDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const { lineItems = [], siteName = "", siteCode = "" } = budgetDetails;

  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (selectedRow.approvalId) {
      setSearchParams({
        approvalId: selectedRow.approvalId,
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (parseInt(approvalId)) {
      loadApprovalDetails(approvalId);
    }
  }, [approvalId]);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      {
        _id: id,
      }
    )
      .then((response) => {
        const { results = [] } = response;
        if (results.length > 0) {
          setBudgetDetails(results[0]);
          const { lineItems = [] } = results[0];
          if (!selectedRow.approvalId) {
            setSelectedRow(
              lineItems.find((l) => l.approvalId === +approvalId) || {}
            );
          }
        }
      })
      .catch((err) => {});
  };

  const loadApprovalDetails = (selectedId) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_APPROVALS}`,
      null,
      {
        _id: selectedId,
        archive: false,
      }
    )
      .then((response) => {
        if (response.length > 0) {
          setApprovalDetails(response[0]);
        }
      })
      .catch((err) => {});
  };

  const onFormChange = (event, data) => {
    let index = lineItems.findIndex((l) => l.id === data.id);
    if (index !== -1) {
      lineItems[index][event.target.name] = event.target.value;

      setBudgetDetails({
        ...budgetDetails,
        lineItems: lineItems,
      });
      setSelectedRow(lineItems[index]);
    }
  };

  const onApprove = () => {
    const id = approvalDetails.id;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVE}/${id}`,
      null,
      { userId: userDetails.id, comment: approveRejectDetails.comment || "" }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          loadData();
          setTimeout(() => {
            loadApprovalDetails(id);
          }, 1500);
          setApproveRejectDetails();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onReject = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REJECT}`,
      null,
      { comment: approveRejectDetails.comment || "", id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setApproveRejectDetails();
          loadData();
          loadApprovalDetails(selectedRow.approvalId);
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const getDisplayData = () => {
    let offset = ((filters.page || 1) - 1) * filters.limit;
    if (lineItems && lineItems.length > 0) {
      return lineItems
        .filter((l) => l.status === "approval_in_progress")
        .slice(offset, offset + filters.limit);
    }
    return [];
  };

  return (
    <div className="budget-approval-details-container">
      <div className="bd-header">
        <div className="bd-r-container">
          <IconButton
            onClick={() => {
              navigate(-1);
              // navigate("/subway-finance?index=0&subIndex=2");
            }}
          >
            <ArrowBack />
          </IconButton>
          <span className="info-c">
            <span className="label">Budget Id </span>
            <span className="value">{budgetDetails.id}</span>
          </span>
          <span className="info-c">
            <span className="label">Site Code </span>
            <span className="value">{siteCode}</span>
          </span>

          <span className="info-c">
            <span className="label">Site Name </span>
            <span className="value">{siteName}</span>
          </span>
          <span className="info-c">
            <span className="label">Budget Status </span>
            <span className="value">{budgetDetails.status || "-"}</span>
          </span>

          <span className="info-c">
            <span className="label">Initial Budget Cost </span>
            <span className="value">{getInitialBudgetCost(lineItems)}</span>
          </span>

          <span className="info-c">
            <span className="label">Modified Budget Cost </span>
            <span className="value">{getModifiedBudgetCost(lineItems)}</span>
          </span>

          <span className="info-c">
            <span className="label">Modified Budget Cost </span>
            <span className="value">{getAvailableBudgetCost(lineItems)}</span>
          </span>
        </div>
        {/* <div className='bd-r-container'>
          <Button variant='outlined' onClick={onApprove} disabled>
            Approve All
          </Button>
        </div> */}
      </div>
      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <div className="approvals-line-items">
        <div>
          <MuiTable
            columnsList={getHeaderConfig(
              setApproveRejectDetails,
              approvalDetails,
              selectedRow,
              setSelectedRow
            )}
            dataList={getDisplayData()}
            filters={filters}
            pageCount={Math.ceil(
              lineItems.filter((l) => l.status === "approval_in_progress")
                .length / filters.limit
            )}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
        <div className="approval-details">
          <ApprovalStageDetails approvalDetails={approvalDetails} />
        </div>
      </div>
      {approveRejectDetails && (
        <CustomModal
          title={`${
            approveRejectDetails.toApprove
              ? "Approve  Line item"
              : "Reject  Line item"
          }`}
          disabledOutsideClick={true}
          onClose={() => {
            setApproveRejectDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={approveRejectDetails.comment}
              onChange={(e) =>
                setApproveRejectDetails({
                  ...approveRejectDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="Comment"
              fullWidth
            />
            <Button
              color={`${approveRejectDetails.toApprove ? "primary" : "error"}`}
              variant="outlined"
              onClick={() => {
                if (approveRejectDetails.toApprove) {
                  onApprove();
                  return;
                }
                onReject();
              }}
              fullWidth
              disabled={
                approveRejectDetails.toApprove
                  ? false
                  : !approveRejectDetails.comment
              }
            >
              {approveRejectDetails.toApprove ? "Approve" : "Reject"}
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default BudgetApprovalDetails;
