import CustomModal from "../../../common-utilities/core/modal";
import "./index.scss";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useEffect } from "react";

export const DownloadModal = ({
  title,
  closeModal,
  data = [],
  customModalContentClass,
}) => {
  return (
    <CustomModal
      title={title}
      onClose={() => closeModal(null)}
      contentClassName={{
        headerBackgroundColor: "#008938",
        customClass: `${
          customModalContentClass ? customModalContentClass : ""
        }`,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {data.map((item, index) => {
          return (
            <div className="downloadgrid" key={index}>
              <div>{item?.label}</div>
              <div>
                <span>
                  <a target="_blank" download={true} href={item?.value}>
                    <DownloadForOfflineIcon color="action" />
                  </a>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export const ApproverDetailsModal = ({
  title,
  closeModal,
  data = [],
  customModalContentClass,
}) => {
  return (
    <CustomModal
      title={title}
      onClose={() => closeModal(null)}
      contentClassName={{
        headerBackgroundColor: "#008938",
        customClass: `${
          customModalContentClass ? customModalContentClass : ""
        }`,
      }}
    >
      <div
        className="approver-details-modal"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {data.map((item, index) => {
          return (
            <div className="approver-details" key={index}>
              <div>{item[0]}</div>
              <div className="approver-content">{item[1] || "NA"}</div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};
