export const Satellites = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
];
export const LISTING_TYPE = [
  {
    value: "address",
    label: "Address",
  },
  {
    value: "communication",
    label: "Communication",
  },
];
export const CLOSING_REASON = [
  { value: "Access Restriction", label: "Access Restriction" },
  { value: "Remodel", label: "Remodel" },
  { value: "Relocation", label: "Relocation" },
  { value: "Termination", label: "Termination" },
  { value: "Lease Expired", label: "Lease Expired" },
  { value: "Disaster", label: "Disaster" },
  { value: "Repairs", label: "Repairs" },
  { value: "Abundant", label: "Abundant" },
  { value: "Financial", label: "Financial" },
  { value: "Others", label: "Others" },
];
export const STORE_STATUS = [
  { value: "Never Opened", label: "Never Opened" },
  { value: "Open & Operating", label: "Open & Operating" },
  { value: "Store Closed", label: "Store Closed" },
  { value: "Franchise Sold", label: "Franchise Sold" },
  { value: "Under Construction", label: "Under Construction" },
  { value: "Permanently Closed", label: "Permanently Closed" },
];
export const DEPOSITIONS = [
  { value: "AR", label: "AR" },
  { value: "Remodel", label: "Remodel" },
  { value: "Relocation", label: "Relocation" },
  { value: "SAP", label: "SAP" },
  { value: "Restaurant Status", label: "Restaurant Status" },
  { value: "Lease", label: "Lease" },
  { value: "Operating Assignment", label: "Operating Assignment" },
  { value: "Restaurant Transfer", label: "Restaurant Transfer" },
  { value: "REV", label: "REV" },
  { value: "Others", label: "Others" },
];
export const CONTACTS = [
  {
    value: "storeDesigner",
    label: "Store Designer",
  },
  {
    value: "constructionAssistant",
    label: "Construction Assistant",
  },
  {
    value: "coordinator",
    label: "Coordinator",
  },
  {
    value: "collectionsRepresentative",
    label: "Collections Representative",
  },
  {
    value: "auditor",
    label: "Auditor",
  },
  {
    value: "franchiseSalesManager",
    label: "Franchise Sales Manager",
  },
];
export const STORE_TYPE = [
  { label: "FOFO", value: "FOFO" },
  { label: "COCO_BUILT", value: "COCO_BUILT" },
  { label: "COCO_ACQUIRED", value: "COCO_ACQUIRED" },
];
