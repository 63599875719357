/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import Select from "react-select";
import { getHeaderConfig, TICKET_REPORT_STATUS } from "./helper";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import "./index.scss";
import {
  convertToCamelCaseFromUnderScore,
  exportToExcel,
  pick,
} from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import {
  getEndOfDay,
  getStartOfDay,
  timeDifferenceInHours,
} from "../../../common-utilities/utils/time";
export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};
const TicketReports = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState({});
  const [departmentsList, setDepartmentsList] = useState([]);
  const [department, setDepartment] = useState({});
  const [status, setStatus] = useState({});
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: null,
    endDate: null,
    status: null,
    statusLabel: "",
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  useEffect(() => {
    if ((reportData.results || []).length !== 0) {
      setShowDownloadBtn(true);
    } else {
      setShowDownloadBtn(false);
    }
  }, [reportData]);

  const otherOptions = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((response) => {
        if (response) {
          setDepartmentsList(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,

      status: filterAfterClickingOnApply.status,
      startDate: filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null,
    };
    if (!params.startDate) {
      if (params.status !== "ALL") {
        delete params.startDate;
      } else {
        params["startDate"] = lastMonthDate();
      }
    }
    if (!params.endDate) {
      if (params.status !== "ALL") {
        delete params.endDate;
      } else {
        params["endDate"] = new Date().toISOString();
      }
    }
    if (params.status === "ALL") {
      delete params.status;
    }

    if (filterAfterClickingOnApply.dept) {
      params.dept = filterAfterClickingOnApply.dept;
    }

    const response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const Status =
          item?.status.slice(0, 1).toUpperCase() +
          item?.status.slice(1).toLowerCase();
        const {
          id,
          sla,
          reopened: Reopened,
          closed: Closed,
          resolveMessage,
          holdEscalations,
          dept,
          category,
          store,
          assignedTo,
          severity: Severity,
          createdAt,
          complaintForm,
        } = item;
        const additionalDetails = {};

        if (
          filterAfterClickingOnApply.status === "ALL" ||
          filterAfterClickingOnApply.status === "CLOSED"
        ) {
          additionalDetails["Closed At"] = item?.closedAt
            ? `${new Date(item.closedAt).toLocaleDateString()} ${new Date(
                item.closedAt
              ).toLocaleTimeString()}`
            : "";

          additionalDetails["Turnaround Time(TAT)"] = timeDifferenceInHours(
            createdAt,
            item.closedAt
          );
        }
        if (
          filterAfterClickingOnApply.status === "ALL" ||
          filterAfterClickingOnApply.status === "RESOLVED"
        ) {
          additionalDetails["Closing Reason"] = item.resolveMessage || null;
        }
        return {
          "Ticket Id": id,
          "Store Number": store?.storeNumber,
          "Store Name": store?.restaurantName || null,
          Region: store?.region
            ? convertToCamelCaseFromUnderScore(store?.region)
            : null,
          Status,
          SLA: sla,
          Assignee: assignedTo?.name,
          Reopened: Reopened ? "Yes" : "No",
          Category: category?.name,
          Closed: Closed ? "Yes" : "No",
          "Created on": createdAt
            ? `${new Date(createdAt).toLocaleDateString()} ${new Date(
                createdAt
              ).toLocaleTimeString()}`
            : "",
          ...additionalDetails,
          "Resolved Message": resolveMessage,
          "Hold Escalations": holdEscalations ? "Yes" : "No",
          "Ticket Description": complaintForm?.details || null,
          "Business Type": store?.storeType || null,
          Department: dept?.name,
          Store: store?.name,
          Email: complaintForm?.email,
          Severity: Severity
            ? Severity.slice(0, 1).toUpperCase() +
              Severity.slice(1).toLowerCase()
            : "",
        };
      });
    }
    exportToExcel(results || [], filterAfterClickingOnApply.statusLabel);
  };

  useEffect(() => {
    otherOptions();
  }, []);

  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  // filtering reports
  const fetchDataHandler = (filter = {}, getDataForPreviouslyAppliedFilter) => {
    const params = {
      ...filter,
      status: status.filterPayload.status,
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate ? getEndOfDay(dateRange.endDate) : null,
      sortBy: "-createdAt",
    };

    if (getDataForPreviouslyAppliedFilter) {
      params.status = filterAfterClickingOnApply.status;

      params.startDate = filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null;
    }

    if (!params.startDate) {
      if (params.status !== "ALL") {
        delete params.startDate;
      } else {
        params["startDate"] = lastMonthDate();
      }
    }
    if (!params.endDate) {
      if (params.status !== "ALL") {
        delete params.endDate;
      } else {
        params["endDate"] = new Date().toISOString();
      }
    }
    if (params.status === "ALL") {
      delete params.status;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s w-100">
          <div className="mw-30">
            <AutocompleteComponent
              value={status?.value ? status : null}
              classNamePrefix="si-select"
              options={TICKET_REPORT_STATUS}
              onChange={(e, option) => {
                setStatus(option);
              }}
              disableClear={true}
              fullWidth={true}
              label="Status"
            />
          </div>
          <div className="mw-30">
            <AutocompleteComponent
              value={
                department?.key
                  ? { label: department.label || "", value: department?.key }
                  : null
              }
              classNamePrefix="si-select"
              options={departmentsList}
              onChange={(e, option) => {
                setDepartment((prevVal) => ({
                  ...prevVal,
                  key: option.value,
                  label: option?.label,
                }));
              }}
              label="Department"
              disableClear={true}
              fullWidth={true}
            />
          </div>

          <div>
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </div>
          {Object.keys(status || {}).length !== 0 && (
            <SearchIcon
              onClick={() => {
                let filterDataAfterClickingOnApply = {
                  statusLabel: status?.label,
                };
                if (status?.filterPayload?.status) {
                  filterDataAfterClickingOnApply.status =
                    status?.filterPayload?.status;
                } else {
                  filterDataAfterClickingOnApply.status = null;
                }

                filterDataAfterClickingOnApply = {
                  ...filterDataAfterClickingOnApply,
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                };
                const newFilters = { ...filters, page: 1 };
                if (department?.key) {
                  filterDataAfterClickingOnApply.dept = department?.key;
                  newFilters.dept = department?.key;
                }
                setFilters((prevFilter) => ({ ...prevFilter, page: 1 }));
                setFilterAfterClickingOnApply(filterDataAfterClickingOnApply);

                fetchDataHandler(newFilters);
              }}
              sx={{ cursor: "pointer", marginBottom: 1 }}
            />
          )}
        </div>
        {showDownloadBtn && (reportData.results || []).length !== 0 && (
          <DownloadBtn className="mb-5" onClick={downloadReport} />
        )}
      </div>
      <MuiTable
        nestedValue={true}
        columnsList={getHeaderConfig(status)}
        dataList={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });

          const filter = pick(filterAfterClickingOnApply, [
            "dept",
            "status",
            "startDate",
            "endDate",
          ]);
          filter.page = page;
          if (!filter.status) {
            delete filter.status;
          }
          if (!filter.dept) {
            delete filter.dept;
          }
          if (!filter.startDate || !filter.endDate) {
            delete filter.startDate;
            delete filter.endDate;
          }
          Object.keys(filters).forEach((key) => {
            if (!filter.hasOwnProperty(key) && !!filters[key]) {
              filter[key] = filters[key];
            }
          });

          filter.page = page;

          fetchDataHandler(
            {
              ...filter,
            },
            true
          );
        }}
      ></MuiTable>
      {/* <SiTable
        header={getHeaderConfig(status)}
        data={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></SiTable> */}
      {!status.value && (
        <span className="user-info-msg">
          Please select the status to download reports
        </span>
      )}
    </div>
  );
};

export default TicketReports;
