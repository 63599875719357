import React, { useState, useEffect } from "react";
import "./index.scss";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/petty-expense";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { toast } from "react-toastify";
import { EXPENSE_HEAD_LIST } from "./config";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";

const CreateVoucher = () => {
  const [storeDetails, setStoreDetails] = useState({});
  const [vendors, setVendors] = useState([]);
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    voucherDate: new Date(),
    expensePeriodFrom: null,
    expensePeriodTo: null,
    billDate: null,
    vendor: "",
    expenseHead: "",
    amount: "",
    remarks: "",
  });
  const userData = getOfflineData("petty-expense", "user");
  const getStoreDetails = () => {
    const store = userData?.userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      {
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      }
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const getVendorList = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.VENDOR}`, null, {
      page: 0,
      limit: 1000,
    })
      .then((response) => {
        if (response && response.results) {
          const { results = [] } = response;
          let updateVendors = results.map((result) => {
            return {
              label: result.name,
              value: result.id,
            };
          });
          setVendors(updateVendors);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (e) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
  };
  const handleDateChange = (name) => (date) => {
    setFormData({
      ...formData,
      [name]: moment(date).toDate(),
    });
  };
  const createVoucherHandler = () => {
    const payload = { ...formData, store: storeDetails?.id, attachment: files };
    if (formData.amount < 100) {
      toast.error("Amount Should be greater than 100", { autoClose: 2000 });
    } else if (
      storeDetails?.properties?.pettyExpense?.physicalBalance < formData.amount
    ) {
      toast.error("Physical Balance Shouldn't be less than Voucher Amount", {
        autoClose: 2000,
      });
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.VOUCHER}`, payload)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Voucher Created Successfully", { autoClose: 2000 });
            getStoreDetails();
            clearHandler();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const draftVoucherHandler = () => {
    const payload = {
      ...formData,
      store: storeDetails?.id,
      attachment: files,
      status: "Draft",
    };
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.VOUCHER}`, payload)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Draft Created Successfully", { autoClose: 2000 });
          clearHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearHandler = () => {
    setFormData({
      voucherDate: new Date(),
      expensePeriodFrom: null,
      expensePeriodTo: null,
      billDate: null,
      vendor: "",
      expenseHead: "",
      amount: "",
      remarks: "",
    });
    setFiles([]);
  };
  useEffect(() => {
    getStoreDetails();
    getVendorList();
  }, []);
  return (
    <div className="create-voucher-container">
      <div className="voucher-btn-con">
        <Button
          size="small"
          variant="outlined"
          onClick={draftVoucherHandler}
          sx={{ mr: 2 }}
          disabled={
            storeDetails?.properties?.pettyExpense?.physicalBalance <=
              formData.amount ||
            files.length < 1 ||
            !formData.vendor ||
            !formData.expensePeriodFrom ||
            !formData.expensePeriodTo ||
            !formData.amount
              ? true
              : false
          }
        >
          Save as Draft
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={createVoucherHandler}
          disabled={
            storeDetails?.properties?.pettyExpense?.physicalBalance <=
              formData.amount ||
            files.length < 1 ||
            !formData.vendor ||
            !formData.expensePeriodFrom ||
            !formData.expensePeriodTo ||
            !formData.amount
              ? true
              : false
          }
        >
          Submit
        </Button>
      </div>
      <Grid
        container
        md={12}
        sx={{ backgroundColor: "#f9f9f9", p: 3, m: 0 }}
        spacing={3}
      >
        <Grid item md={6}>
          <Card sx={{ p: 2 }}>
            <h4>New Expense Voucher</h4>
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Voucher Date"
                  inputFormat="DD/MM/YYYY"
                  value={formData?.voucherDate}
                  name="voucherDate"
                  disabled
                  onChange={handleDateChange("voucherDate")}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
              <div className="expense-date-con">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Expense Period From*"
                    inputFormat="DD/MM/YYYY"
                    value={formData?.expensePeriodFrom}
                    name="expensePeriodFrom"
                    onChange={handleDateChange("expensePeriodFrom")}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        fullWidth
                        sx={{ mr: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Expense Period To*"
                    inputFormat="DD/MM/YYYY"
                    value={formData?.expensePeriodTo}
                    name="expensePeriodTo"
                    onChange={handleDateChange("expensePeriodTo")}
                    minDate={
                      formData?.expensePeriodFrom
                        ? moment(formData?.expensePeriodFrom).add(1, "days")
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        fullWidth
                        sx={{ ml: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <div>
                <h4>Imprest Details</h4>
                <div className="details-row">
                  <h6>Format</h6>
                  <h5>{storeDetails?.subFormat}</h5>
                </div>
                <div className="details-row">
                  <h6>Location Code</h6>
                  <h5>{storeDetails?.pinCode}</h5>
                </div>
                <div className="details-row">
                  <h6>Name</h6>
                  <h5>{storeDetails?.restaurantName}</h5>
                </div>
                <div className="details-row">
                  <h6>Region</h6>
                  <h5>{storeDetails?.state}</h5>
                </div>
                <div className="details-row">
                  <h6>Physical Balance</h6>
                  <h5>
                    {storeDetails?.properties?.pettyExpense?.physicalBalance ||
                      0}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Book Balance</h6>
                  <h5>
                    {storeDetails?.properties?.pettyExpense?.bookBalance || 0}
                  </h5>
                </div>
                <div className="details-row">
                  <h6>Inprocess Balance</h6>
                  <h5>
                    {storeDetails?.properties?.pettyExpense?.bookBalance ===
                      0 ||
                    storeDetails?.properties?.pettyExpense?.physicalBalance ===
                      0
                      ? 0
                      : storeDetails?.properties?.pettyExpense?.bookBalance -
                          storeDetails?.properties?.pettyExpense
                            ?.physicalBalance || ""}
                  </h5>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ backgroundColor: "#f9f9f9", p: 3, m: 0, mt: 5 }}
      >
        <Grid item md={12}>
          <Card>
            <CardContent>
              <div className="voucher-form">
                <Grid item md={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Bill Date"
                      inputFormat="DD/MM/YYYY"
                      value={formData?.billDate}
                      name="billDate"
                      onChange={handleDateChange("billDate")}
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2}>
                  <Autocomplete
                    options={vendors}
                    className="form-autocomplete"
                    size="small"
                    onChange={(event, values) => {
                      if (values) {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          vendor: values?.value,
                        }));
                      } else {
                        setFormData((prevVal) => ({ ...prevVal, vendor: "" }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Vendor Name"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2}>
                  <Autocomplete
                    options={EXPENSE_HEAD_LIST}
                    className="form-autocomplete"
                    size="small"
                    onChange={(event, values) => {
                      if (values) {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          expenseHead: values?.value,
                        }));
                      } else {
                        setFormData((prevVal) => ({
                          ...prevVal,
                          expenseHead: "",
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Expense Head"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    label="Amount"
                    required
                    fullWidth
                    value={formData?.amount}
                    size="small"
                    name="amount"
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label="Remarks"
                    required
                    fullWidth
                    value={formData?.remarks}
                    size="small"
                    name="remarks"
                    onChange={handleChange}
                  />
                </Grid>
              </div>
              <Grid md={12} item sx={{ mt: 2 }}>
                <SubwayFileUpload
                  title="Attachment"
                  url={`${HOSTNAME}${REST_URLS.UPLOAD}`}
                  tokenKey={tokenMainKeys.pettyExpense}
                  slug={appSlugs.pettyExpense}
                  callBack={(newFile) => {
                    setFiles([
                      ...files,
                      {
                        url: newFile[0].url,
                        title: newFile[0].originalname,
                      },
                    ]);
                  }}
                  providedLimit={15728640}
                  lmtMsg="File size cannot be more than 15 MB"
                />
                {files.length > 0 && (
                  <div className="file-con">
                    {files.map((ele) => {
                      return <div className="file-name">{ele?.title}</div>;
                    })}
                  </div>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateVoucher;
