import { convertDDMMYYYdateToIsoString } from "../../../../common-utilities/utils/date";

export const addPayloadDates = (formDetails, payload) => {
  if (formDetails?.startDate) {
    payload.details.startDate = convertDDMMYYYdateToIsoString(
      formDetails?.startDate
    );
  }
  if (formDetails?.expiryDate) {
    payload.details.expiryDate = convertDDMMYYYdateToIsoString(
      formDetails?.expiryDate
    );
  }

  if (formDetails?.documentDate) {
    payload.details.documentDate = convertDDMMYYYdateToIsoString(
      formDetails?.documentDate
    );
  }
  if (formDetails?.endDate) {
    payload.details.endDate = convertDDMMYYYdateToIsoString(
      formDetails?.endDate
    );
  }

  if (formDetails?.leaseSignDate) {
    payload.details.leaseSignDate = convertDDMMYYYdateToIsoString(
      formDetails?.leaseSignDate
    );
  }

  if (formDetails?.leaseEffectiveDate) {
    payload.details.leaseEffectiveDate = convertDDMMYYYdateToIsoString(
      formDetails?.leaseEffectiveDate
    );
  }
  if (formDetails?.leaseEndDate) {
    payload.details.leaseEndDate = convertDDMMYYYdateToIsoString(
      formDetails?.leaseEndDate
    );
  }

  if (formDetails?.lockInExpiryDate) {
    payload.details.lockInExpiryDate = convertDDMMYYYdateToIsoString(
      formDetails?.lockInExpiryDate
    );
  }
};
