import { DATE_FORMATS } from "./constants";
import { getOfflineData } from "./offline-services";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const gettokenParamsForRedirectionToDifferentApp = () => {
  const token = getOfflineData("tokens");
  const access = token?.access?.token || "";
  const refresh = token?.refresh?.token || "";
  const accessTokenExpiry = token?.access?.expires || "";
  const refreshTokenExpiry = token?.refresh?.expires || "";
  return `?access=${access}&refresh=${refresh}&accessTokenExpiry=${accessTokenExpiry}&refreshTokenExpiry=${refreshTokenExpiry}`;
};
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export const exportToExcel = (
  data,
  name,
  mutliple,
  removeDefaultFileNameStart
) => {
  const wb = {
    Sheets: {},
    SheetNames: [],
  };

  if (!mutliple) {
    const ws = XLSX.utils.json_to_sheet(data);
    wb.Sheets.data = ws;
    wb.SheetNames.push("data");
  } else {
    data.forEach((item) => {
      const { sheetName, details } = item;
      const ws = XLSX.utils.json_to_sheet(details);
      wb.Sheets[sheetName] = ws;
      wb.SheetNames.push(sheetName);
    });
  }

  const eb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(
    blob,
    `${removeDefaultFileNameStart ? "" : "file_"}` + name + EXCEL_EXTENSION
  );
};
export const appendZeroToTime = (number) => {
  if (number >= 0 && number < 10) {
    return `0${number}`;
  }
  return number;
};
export const formatDate = (isoDateString, type) => {
  if (!isoDateString) {
    return "-";
  }
  try {
    const dateObj = new Date(isoDateString);
    if (Number.isNaN(dateObj.getTime())) {
      return "-";
    }
    switch (type) {
      case "dateString":
        return isoDateString === "" ? "-" : dateObj.toLocaleDateString();
      case "timeString":
        return dateObj.toLocaleTimeString();
      case DATE_FORMATS["YYYY-MM-DD"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [date.getFullYear(), monthDisplay, dayDisplay].join("-");
      }
      case DATE_FORMATS["DD-MM-YYYY"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [dayDisplay, monthDisplay, date.getFullYear()].join("-");
      }
      default:
        return `${dateObj.toLocaleDateString()} 
                  ${dateObj.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`;
    }
  } catch (err) {
    return "Invalid Date";
  }
};
// Function for adding hosts to app urls for app list
export const addHostToAppRedirection = (apps, linkKey) => {
  const results =
    apps?.map((item) => {
      const link = item[linkKey];
      const linkWithHost = `${window.location.origin}/${link}`;
      return { ...item, [linkKey]: linkWithHost };
    }) || [];
  return results;
};
