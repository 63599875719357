import React, { useState, useEffect } from "react";
import { TextField, Drawer, IconButton } from "@mui/material";
import "./index.scss";
import Select from "react-select";
import { invokeApi } from "../../utils/http-service";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCommunication from "./add-communication";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const CommunicationDetails = ({
  communicationData,
  setPersonData,
  setCommunicationUpdateValue,
  isCreate,
  personNum,
  isPersonData,
  loadPersonDataById,
}) => {
  const [communicationType, setCommunicationType] = useState([]);
  const [communicationFlag, setCommunicationFlag] = useState(false);
  const [communicationValue, setCommunicationValue] = useState({});

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_COMMUNICATION_TYPE}`
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele, value: ele }));
        setCommunicationType(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (!communicationData.length) {
      setCommunicationValue({});
    }
  }, [communicationData]);
  useEffect(() => {
    if (communicationData.length > 0) {
      let homePhoneNumber = communicationData.filter(
        (ele) => ele.communicationType === "Home Phone Number"
      );
      if (homePhoneNumber.length > 0) {
        setCommunicationValue(homePhoneNumber);
      } else {
        setCommunicationValue({});
      }
    }
  }, [communicationData]);
  return (
    <div style={{ marginTop: "0.5em" }}>
      <div className="pd-title g-r-2">
        Communication Details
        {!isCreate && (
          <span>
            <IconButton onClick={() => setCommunicationFlag(true)}>
              <ArrowCircleRightIcon
                color="primary"
                size="large"
                fontSize="60"
              />
            </IconButton>
          </span>
        )}
      </div>

      <div className="input-form-container">
        <div className="g-r-2">
          <Select
            classNamePrefix="custom_select"
            isSearchable
            placeholder="Type"
            options={communicationType}
            value={
              communicationValue[0]?.communicationType
                ? {
                    label: communicationValue[0]?.communicationType,
                    value: communicationValue[0]?.communicationType,
                  }
                : null
            }
            onChange={(e) => {
              let addValue = communicationData.filter((ele) => {
                return ele.communicationType === e.value;
              });

              if (addValue.length > 0) {
                setCommunicationValue(addValue);
              } else {
                setCommunicationValue([
                  {
                    communicationType: e.value,
                  },
                ]);
              }
              // setCommunicationUpdateValue((prevVal) => ({
              //   ...prevVal,
              //   communicationType: e.value,
              // }));
            }}
            isDisabled={isCreate}
          />
          <TextField
            id="details"
            className="subs-text-field"
            label="Details"
            disabled={isCreate}
            fullWidth
            value={
              communicationValue[0]?.value ? communicationValue[0].value : ""
            }
            variant="outlined"
            // onChange={(e) => {
            //   setCommunicationUpdateValue((prevVal) => ({
            //     ...prevVal,
            //     value: e.target.value,
            //   }));
            //   setPersonData((prevVal) => ({
            //     ...prevVal,
            //     communicationDetails: {
            //       ...prevVal.communicationDetails,
            //       value: e.target.value,
            //     },
            //   }));
            // }}
          />
        </div>
      </div>
      <Drawer
        anchor="right"
        open={communicationFlag}
        onClose={() => setCommunicationFlag(false)}
      >
        <AddCommunication
          onSave={(details) => {
            loadPersonDataById(personNum);
            setCommunicationValue({});
            setCommunicationFlag(false);
          }}
          isPersonData={isPersonData}
          details={communicationData}
          onClose={() => setCommunicationFlag(false)}
          communicationType={communicationType}
          personNum={personNum}
        />
      </Drawer>
    </div>
  );
};
export default CommunicationDetails;
