/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { IconButton } from "@mui/material";
import { invokeUploadFile } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { customLmtErr } from "./utils";

const SiFileUpload = ({
  title,
  clsName,
  url,
  callBack,
  id,
  multiple,
  filesLimit,
  setMultipleFilesFunction,
  label = "Upload Documents",
  lmtMsg = "*Max upload file size is 1 MB.",
  customLmt,
  disableUpload,
  accept = "*",
  customUrl,
  noSuccessMsg,
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [multipleFilesList, setMultipleFilesList] = useState([]);
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };

  const onChange = (e) => {
    if (!multiple) {
      if (customLmtErr(customLmt, e.target.files[0])) {
        return;
      }
      if (
        typeof disableUpload === "function" &&
        disableUpload(e.target.files[0])
      ) {
        return;
      }
      setFile(e.target.files[0]);
      return;
    }
    const newFilesToBeAdded = [];

    Array.prototype.forEach.call(e.target.files, (item) => {
      if (multipleFilesList.length < filesLimit) {
        if (item.size > 1000000) {
          toast.error("File size should not exceed 1MB");
        } else {
          newFilesToBeAdded.push(item);
        }
      }
    });
    setMultipleFilesList((prevfiles) => {
      if (setMultipleFilesFunction) {
        setMultipleFilesFunction([...prevfiles, ...newFilesToBeAdded]);
      }
      return [...prevfiles, ...newFilesToBeAdded];
    });
  };

  const removeFilesFromMultipleFileList = (index) => {
    const filesAfterRemoval = multipleFilesList.filter((_, itemIndex) => {
      return index !== itemIndex;
    });
    setMultipleFilesList(filesAfterRemoval);
    if (setMultipleFilesFunction) {
      setMultipleFilesFunction((prevfiles) => {
        return prevfiles.filter((_, itemIndex) => index !== itemIndex);
      });
    }
  };

  const UploadDocumentLabel = ({ label = "Upload Documents" }) => {
    return (
      <IconButton
        title="Attach file"
        size="small"
        style={{
          background: "#008938",
          color: "#fff",
          borderRadius: 10,
          cursor: "pointer",
        }}
      >
        <label
          htmlFor="file-upload"
          className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
        >
          {label}
          <PublishIcon fontSize="small" />
        </label>
      </IconButton>
    );
  };

  const FileUploadInput = ({ accept }) => {
    return (
      <input
        id="file-upload"
        type="file"
        accept={accept}
        onChange={(event) => {
          onChange(event);
          event.target.value = null;
        }}
      />
    );
  };

  const fileUpload = () => {
    if (!customLmt && file.size > 1000000) {
      toast.error("File size should not exceed 1MB");
      return;
    }
    if (customLmtErr(customLmt, file)) {
      return;
    }
    const formData = new FormData();
    formData.append("upload", file);
    setUploadStatus("Uploading...");
    return invokeUploadFile(
      customUrl
        ? customUrl
        : `${HOSTNAME}${REST_URLS.UPLOAD_ATTACHMENT_FILES}${id}`,
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          if (!noSuccessMsg) {
            toast.info("Uploaded Successfully");
          }

          setFile(null);
          callBack && callBack(response);
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch((err) => {
        setFile(null);
        setUploadStatus("");
        toast.error("Upload Failed");
      });
  };

  return (
    <div className="relativePostion">
      <form className={`spidle-upload-file ${clsName}`}>
        {multiple ? (
          <>
            {multipleFilesList.length < filesLimit && (
              <UploadDocumentLabel label={label} />
            )}
            <FileUploadInput accept={accept} />
            {multipleFilesList.map((item, index) => {
              return (
                <div key={index}>
                  <label
                    title={(file && file.name) || ""}
                    className="uploaded-file-name"
                  >
                    {(item && item.name) || ""}
                  </label>
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => removeFilesFromMultipleFileList(index)}
                      title="Clear"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {file === null ? (
              <UploadDocumentLabel label={label} />
            ) : (
              <label
                title={(file && file.name) || ""}
                className="uploaded-file-name"
              >
                {(file && file.name) || ""}
              </label>
            )}
            <FileUploadInput accept={accept} />
            {file !== null && (
              <span>
                <IconButton
                  color="primary"
                  onClick={onFormSubmit}
                  title="Upload"
                  disabled={uploadStatus}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => setFile(null)}
                  title="Clear"
                  disabled={uploadStatus}
                >
                  <HighlightOffIcon />
                </IconButton>
              </span>
            )}
            {uploadStatus}
          </>
        )}
      </form>
      <div className="absoluteWarningContainer">{lmtMsg}</div>
    </div>
  );
};

export default SiFileUpload;
