import { formatDate, pick } from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../common-utilities/utils/constants';

export const formatExcelHeaders = (data) => {
  const finalResults = data?.reduce?.(
    ({ itemSheet, priceHistorySheet }, item) => {
      const { priceHistory } = item;
      const itemData = {
        Id: item?.id,
        'Item Code': item?.itemCode,
        'HSN Code': item?.hsnCode || null,
        Name: item?.name,
        Price: item?.price,
        UOM: item?.uom,
        'TAX(%)': item?.taxPercentage,
        Specification: item?.specification,
        Category: item?.category?.name,
        Subcategory: item?.subCategory?.name,
      };

      itemSheet.push(itemData);
      priceHistory?.forEach?.((history) => {
        priceHistorySheet.push({
          'Item Id': item?.id,
          'Item Name': item?.name,
          'Old Price': history.oldPrice,
          Update: history.newPrice,
          Date: formatDate(history.date, DATE_FORMATS['DD-MM-YYYY']),
          'Creator Name': history.creatorName,
        });
      });
      return { itemSheet, priceHistorySheet };
    },
    { itemSheet: [], priceHistorySheet: [] }
  );

  return [
    {
      sheetName: 'item-data',
      details: finalResults.itemSheet,
    },
    {
      sheetName: 'price-history',
      details: finalResults.priceHistorySheet,
    },
  ];
};
