import {
  modalActions,
  subDocumentTypeIcon,
  subDocumentTypeImages,
  subDocumentTypes,
} from "./constants";
import { getLabelsAndValuesForSelect, pick } from "../utils";
import { getAppDocuments, getFieldList } from "./main-apis";

export const downloadDoc = (data) => {
  window.open(data?.link, "_blank");
};

export const actionHandler = (data, type, setDataFunc) => {
  switch (type) {
    case modalActions.updateDocument:
      setDataFunc({
        type: modalActions.updateDocument,
        title: "Update Document",
        data,
      });
      break;
    case modalActions.createDocument:
      setDataFunc({
        type: modalActions.createDocument,
        title: "Create Document",
        data: null,
      });
      break;
    case modalActions.createCategory:
      setDataFunc({
        type: modalActions.createCategory,
        title: "Create Category",
        data: null,
      });
      break;
    case modalActions.updateCategory:
      setDataFunc({
        type: modalActions.updateCategory,
        title: "Update Category",
        data,
      });
      break;
    default:
      break;
  }
};

export const setFilterFunc = async (
  e,
  value,
  slug,
  setFolderList,
  setFilters,
  setTableData,
  setDeftVals = true
) => {
  const { name } = e.target;
  let newFilters = {};
  switch (name) {
    case "mainFolder":
      {
        let subFolderList = [];
        if (value?.value) {
          subFolderList = await getFieldList(
            slug,
            {
              parent: value?.value,
            },
            "select",
            setFolderList
          );
        } else {
          setFolderList([]);
        }

        newFilters[name] = value?.value;
        if (setDeftVals) {
          newFilters.subFolder = subFolderList?.[0]?.value || null;
        }
      }
      break;
    default:
      newFilters[name] = value?.value;
      break;
  }
  setFilters((prevFilters) => {
    const finalFilters = {
      ...prevFilters,
      ...newFilters,
    };
    const appFilters = pick(finalFilters, ["subFolder"]);

    if (appFilters.subFolder) {
      appFilters.documentCategory = appFilters.subFolder;
      delete appFilters.subFolder;
      if (typeof setTableData === "function") {
        getAppDocuments(appFilters, slug, setTableData);
      }
    } else {
      if (typeof setTableData === "function") {
        setTableData([]);
      }
    }

    return finalFilters;
  });
};

export const getDataAccordingToLimitAndPageSelectedInFilters = (
  filter,
  data
) => {
  const { limit, page } = filter;
  const offset = limit * (page - 1);

  return data.slice(offset, offset + filter.limit);
};

export const convertedData = (data) => {
  return (
    data?.map?.((item) => {
      const mainDocumentType = item.subType;
      const documentIcon = subDocumentTypeIcon[mainDocumentType];
      const documentImage = subDocumentTypeImages[mainDocumentType];
      const showIcon = !documentImage;
      return {
        folder: item.subFolder,
        name: item.fileName,
        link: item.link,
        Icon: documentIcon,
        documentType: mainDocumentType,
        image: documentImage,
        showIcon,
        ...item,
      };
    }) || []
  );
};
