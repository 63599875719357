/** @format */

export const userStates = {
  isProcessing: "Checking",
};
export const PETTY_EXPENSE_ROLES = {
  RM: "RM",
  AM: "AM",
  STORE: "STORE",
  FINANCE: "Finance",
  ADMIN: "admin",
  VERIFICATION: "Verification",
  PAYMENT: "Payment",
};
