import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Divider, TextField } from "@mui/material";
import Select from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { invokeApi, plainApi } from "../../utils/http-service";
import Grid from "@mui/material/Unstable_Grid2";
import { auditModelNames } from "../../utils/constants";
import moment from "moment";
import { toast } from "react-toastify";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { GlobalStoreContext } from "../../global-states/Context";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const StoreHistory = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [sequenceList, setSequenceList] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);

  const checkForValidStoreNumberSequenceNumberAndSatelliteNumber = (
    storeNo,
    sequenceNo,
    satelliteNum
  ) => {
    return (
      Number.isInteger(parseInt(storeNo)) &&
      Number.isInteger(parseInt(sequenceNo)) &&
      Number.isInteger(parseInt(satelliteNum))
    );
  };

  useEffect(() => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );

    if (totalPages === null) {
      if (validStoreCombination) {
        getTableData(storeInfo.storeNo, pages);
      }
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          if (validStoreCombination) {
            getTableData(storeInfo.storeNo, pages);
          }
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  const dataKeyValue = [
    {
      label: "Iso Currency Alpha Code",
      id: "isoCurrencyAlphaCode",
      type: "text",
    },
    { label: "Location Type", id: "locationType", type: "text" },
    { label: "GST", id: "gln", type: "text" },
    { label: "Initial Opening Date", id: "initialOpeningDate", type: "text" },
    { label: "Last Reopening Date", id: "lastReopeningDate", type: "text" },
    {
      label: "Temporary Closing Date",
      id: "temporaryClosingDate",
      type: "text",
    },
    { label: "Exception", id: "exception", type: "Checkbox" },
    { label: "Audit Hold", id: "auditHold", type: "Checkbox" },
    { label: "Has Active Note", id: "hasActiveNote", type: "Checkbox" },
    {
      label: "Final Closing Pending",
      id: "finalClosingPending",
      type: "Checkbox",
    },
    {
      label: "Anticipated Reopening Date",
      id: "anticipatedReopeningDate",
      type: "text",
    },
    { label: "Final Closing Date", id: "finalClosedDate", type: "text" },
    { label: "Equipment Order Date", id: "equipmentOrderDate", type: "text" },
    { label: "Currency", id: "currency", type: "text" },
    { label: "Transfer Date", id: "transferDate", type: "text" },
    {
      label: "Temporary Closing Reason",
      id: "temporaryClosingReason",
      type: "text",
    },
    { label: "Occupancy", id: "occupancy", type: "text" },
    {
      label: "Equipment Fund Record Date",
      id: "equipmentFundsRecdDate",
      type: "text",
    },
    { label: "Drawing One Page Date", id: "drawingOnePageDate", type: "text" },
    { label: "Drawing Full Set Date", id: "drawingFullSetDate", type: "text" },
    { label: "Status", id: "status", type: "text" },
    { label: "Ad Market", id: "adMarket", type: "text" },
    { label: "DA No", id: "daNo", type: "text" },
    { label: "Reopening Date", id: "reopenDate", type: "text" },
  ];
  const [data, setData] = useState([]);

  const previousController = useRef();
  const [storeOptions, setStoreOptions] = useState([]);

  const getSequenceAndSatelliteNumberForStorNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const validCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.sequenceNo,
        storeInfo.satelliteNo
      );
    if (validCombination) {
      getSequenceAndSatelliteNumberForStorNumber(storeInfo.storeNo);
    }
  }, []);
  const getTableData = (
    storeNo,
    page,
    hasToReset,
    providedSequenceAndSatellite
  ) => {
    let curStore = storeNo;
    if (typeof curStore === "string") {
      curStore = parseInt(curStore);
    }

    let checkingCondition = !pageArray.includes(page);

    if (hasToReset) {
      checkingCondition = true;
    }

    const params = {
      page: page,
      limit: 20,
      modelName: auditModelNames.store,
      storeNo,
      sortBy: "-createdAt",
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
    };
    const providedSatelliteNumber = providedSequenceAndSatellite?.satelliteNo;
    if (Number.isInteger(parseInt(providedSatelliteNumber))) {
      params.satelliteNo = providedSatelliteNumber;
    }

    const providedSequenceNumber = providedSequenceAndSatellite?.sequenceNo;

    if (Number.isInteger(parseInt(providedSequenceNumber))) {
      params.sequenceNo = providedSequenceNumber;
    }
    const isValidCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        params.storeNo,
        params.sequenceNo,
        params.satelliteNo
      );
    if (isValidCombination) {
      if (checkingCondition) {
        plainApi(
          HTTP_METHODS.GET,
          `${HOSTNAME}${REST_URLS.GET_HISTORY}`,
          null,
          params
        )
          .then((res) => {
            if (res?.message) {
              toast.error(res.message, { autoClose: 2000 });
              return;
            }
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              const { details } = item;
              delete details._id;
              delete details.__v;
              delete details.updatedAt;
              delete details.satelliteNum;
              delete details.sequenceNo;
              delete details.storeNo;
              const allDetails = {};

              for (let curVal in details) {
                const key = `${curVal}`.toLocaleLowerCase();
                let value = details[curVal];
                if (key.includes("date")) {
                  value = moment(value).format("yyyy-MM-DD");
                }
                allDetails[curVal] = value;
              }
              return allDetails;
            });
            if (page == 1) {
              setData(results);
              setPageArray([1]);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              setData([...data, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          })
          .catch((err) => {
            console.log(err);
            setData([]);
          });
      }
    }
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.storeNo,
          satelliteNum: p.satelliteNum,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const clearData = (reset = true) => {
    setSatteliteList([]);
    setSequenceList([]);
    if (reset) setStoreInfo({ storeNo: "", sequenceNo: "", satelliteNo: "" });
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setStoreOptions([]);
    setData([]);
  };

  const onInputChange = (event, value, reason) => {
    setSatteliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({ storeNo: value, sequenceNo: "", satelliteNo: "" });
    } else {
      clearData();
    }
  };

  return (
    <div className="store-memos-container">
      <div className="top-section">
        <Grid container spacing={1}>
          <Grid xs={3}>
            <Autocomplete
              id="free-solo-demo"
              className="subs-autocomplete"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              inputValue={storeInfo.storeNo + "" || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  getSequenceAndSatelliteNumberForStorNumber(val.storeNo);
                }
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Satellite"
              className="sel-item"
              options={satteliteList}
              value={
                ![undefined, null].includes(storeInfo.satelliteNo)
                  ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                const satelliteNo = val?.value;
                setStoreInfo({ ...storeInfo, satelliteNo });

                const validStoreCombination =
                  checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                    storeInfo.storeNo,
                    satelliteNo,
                    storeInfo.sequenceNo
                  );
                if (validStoreCombination) {
                  getTableData(storeInfo.storeNo, 1, true, { satelliteNo });
                }
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence"
              options={sequenceList}
              value={
                ![undefined, null].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo({ ...storeInfo, sequenceNo: val.value });
                const sequenceNo = val?.value;
                const validStoreCombination =
                  checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                    storeInfo.storeNo,
                    storeInfo.satelliteNo,
                    sequenceNo
                  );
                if (validStoreCombination) {
                  getTableData(storeInfo.storeNo, 1, true, { sequenceNo });
                }
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className="memo-list-container">
        <StickyHeaderCon
          dataList={data}
          columnsList={dataKeyValue}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          // containerHeight="table-con"
        />
      </div>
    </div>
  );
};
export default StoreHistory;
