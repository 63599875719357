import React, { useState, useEffect } from "react";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import ChronologyDetails from "../chronology/ChronologyDetails";
import UploadDocuments from ".";
import { StoreLabels } from "../../utils/document-constants";
import {
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import {
  setGetStoreInBatchParams,
  processRestaurantDetailsForDropdown,
} from "../../utils";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";

const SelectRestaurant = ({ menuType }) => {
  const [listIds, setlistIds] = useState([]);
  const [storeType, setStoreType] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 100,
  });
  const [resId, setResId] = useState(null);
  const [showChronology, setShowChronology] = useState(false);
  const [pageArray, setPageArray] = React.useState([]);
  const [showUpload, setShowUpload] = useState(false);

  const getRestaurantData = (params = {}) => {
    setGetStoreInBatchParams(params);
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.LIST_ALL_RESTAURANT_IDS}`,
      params
    )
      .then((response) => {
        if (!response?.message) {
          const allSelectValues = processRestaurantDetailsForDropdown(response);
          setlistIds(allSelectValues);
        }
      })
      .catch((error) => {
        setlistIds([]);
        console.log(error);
      });
  };
  useEffect(() => {
    if (menuType === "chronology") {
      getRestaurantData({});
    }
  }, []);
  useEffect(() => {
    if (filters.type) {
      if (menuType === "chronology") {
        setShowChronology(true);
        setResId(filters.type.value);
      } else if (filters?.storeType?.value) {
        setShowUpload(true);
        setResId(filters.type.value);
        setStoreType(filters.storeType.value);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (
      menuType !== "chronology" &&
      filters.storeType &&
      filters.storeType.value
    ) {
      getRestaurantData({ storeType: filters.storeType.value });
    }
  }, [menuType, filters.storeType]);

  return (
    <div className="select-restaurant-container">
      <p className="s-heading title">Select Restaurant ID</p>
      <div className="selectContainer">
        {menuType !== "chronology" && (
          <AutocompleteComponent
          label="Select Store type"
          options={StoreLabels}
          value={filters.storeType || null}
          className="doc-type"
          onChange={(e, value) => {
            let additionalChanges = {};
            if (!showChronology) {
              additionalChanges = { type: null };
            }
            if (value) {
              setFilters({
                ...filters,
                storeType: value,
                ...additionalChanges,
              });
              return;
            }
            setlistIds([])
            setFilters({
              ...filters,
              storeType: null,
              ...additionalChanges,
            });
            setStoreType(null);
          }}
        />
        )}
         <AutocompleteComponent
          label="Select Restaurant Id"
          options={listIds}
          value={filters.type || null}
          className="doc-type"
          onChange={(e,value) => {
            if (value) {
              setFilters({
                ...filters,
                type: value,
              });
            } else {
              setFilters((prevVal) => ({ ...prevVal, type: null }));
              setResId(null);
            }
            setPageArray([]);
          }}
        />
      </div>

      {showChronology && resId !== null && (
        <ChronologyDetails
          id={resId}
          pageArray={pageArray}
          setPageArray={setPageArray}
        />
      )}
      {showUpload && resId !== null && storeType !== null && filters.type && (
        <UploadDocuments
          id={resId}
          setStoreType={setStoreType}
          setFilters={setFilters}
          storeType={storeType}
          setResId={setResId}
          setlistIds={setlistIds}
        />
      )}
    </div>
  );
};

export default SelectRestaurant;
