/** @format */

import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import "./index.scss";

const ViewTicket = ({ details, onClose }) => {
  return (
    <div className="view-ticket-container">
      <div className="close-btn-title">
        <IconButton onClick={onClose}>
          <CancelIcon
            style={{
              color: "#fff",
              fontSize: 40,
            }}
          />
        </IconButton>
        <div>Ticket Complaint Form</div>
      </div>
      <div className="details-table">
        <div className="data-row">
          <span>Department</span>
          <span>{details.department}</span>
        </div>
        <div className="data-row">
          <span>Category</span>
          <span>{details.category}</span>
        </div>
        <div className="data-row">
          <span>Details </span>
          <span
            style={{
              display: "flex",
              "flex-wrap": "wrap",
              "word-break": "break-word",
            }}
          >
            {details.details}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewTicket;
