import React, { useState, useEffect } from "react";
import "./index.scss";
import { stageGateConfig, stageGateStatuses } from "./config";
import { Button, Box, TextField, Stack } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { BD_LEAD_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/bd-lead";
import { dateFun } from "../../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sortStageGatesByStageNumberInAscendingOrder } from "./utils";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import SaveIcon from "@mui/icons-material/Save";
const StageGate = ({
  setSatgeGateInforation,
  data,
  mainDataWithoutResetting,
}) => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allStagesMap, setAllStagesMap] = useState({});
  const { leadId } = useParams();
  const [modalDetails, setModalDetails] = useState({
    modalTitle: "",
    data: null,
  });
  const closeModal = () => {
    setIsModalOpen(false);
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      modalTitle: "",
      data: null,
    }));
  };

  const getAllStageNames = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${BD_LEAD_HOSTNAME}${REST_URLS.ALL_STAGE_NAMES}`
    )
      .then((res) => {
        if (res?.message) {
          return;
        }

        const stageIdToNameMap = {};
        res.forEach((item) => {
          stageIdToNameMap[item.id] = {
            stageName: item.stageName,
            stageNumber: item.stageNumber,
          };
        });
        setAllStagesMap(stageIdToNameMap);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllStageNames();
  }, []);
  useEffect(() => {
    let allStageIds = [];
    Object.keys(allStagesMap).forEach((id) => {
      const details = { ...allStagesMap[id], id };
      allStageIds.push(details);
    });

    allStageIds.sort(sortStageGatesByStageNumberInAscendingOrder);
    allStageIds = allStageIds.map((item) => {
      return item.id;
    });
    if (allStageIds.length > 0) {
      const { stageGates = [] } = data;
      const stageDataMap = {};
      stageGates.forEach((item) => {
        stageDataMap[item.stage] = item;
      });
      const stageDataWithoutResetMap = {};
      const mainStageGates = Array.isArray(mainDataWithoutResetting?.stageGates)
        ? mainDataWithoutResetting?.stageGates
        : [];
      mainStageGates.forEach((item) => {
        stageDataWithoutResetMap[item.stage] = item;
      });
      const allStages = [];
      allStageIds.forEach((item, index) => {
        const stageName = allStagesMap[item].stageName;
        const stageDetails = stageDataMap[item];
        const mainStageDetailsWithoutReset = stageDataWithoutResetMap[item];
        let disableEditingInitialEstimated = false;
        const status = "Yet to begin";
        if (Number.isInteger(parseInt(leadId))) {
          disableEditingInitialEstimated =
            !!mainStageDetailsWithoutReset?.initialEstimated;
          if (stageDetails) {
            allStages.push({
              status,
              ...stageDetails,
              stageName,
              stage: item,
              dataIndex: index,
              disableEditingInitialEstimated,
            });
          } else {
            allStages.push({
              stage: item,
              stageName,
              dataIndex: index,
              status,
              disableEditingInitialEstimated,
            });
          }
        } else {
          if (stageDetails) {
            allStages.push({
              status,
              ...stageDetails,
              stageName,
              stage: item,
              dataIndex: index,
              disableEditingInitialEstimated,
            });
          } else {
            allStages.push({
              stage: item,
              stageName,
              dataIndex: index,
              status,
              disableEditingInitialEstimated,
            });
          }
        }
      });
      setTableData(allStages);
    }
  }, [data, allStagesMap, mainDataWithoutResetting, leadId]);

  const editModalHandler = (data, type) => {
    setIsModalOpen(true);
    if (type === "edit") {
      setModalDetails((prevDetails) => ({
        ...prevDetails,
        modalTitle: "Add Information",
        data,
        type,
      }));
    }
  };

  const setModalDataOnChange = (e) => {
    const { name, value } = e.target;
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      data: { ...prevDetails.data, [name]: value },
    }));
  };

  const saveorAddModalData = () => {
    const { actualDate, status, dataIndex } = modalDetails.data;
    let convertedTableData = [...tableData];

    switch (modalDetails.type) {
      case "edit":
        if (actualDate) {
          convertedTableData[dataIndex].actualDate = actualDate;
        } else {
          delete convertedTableData[dataIndex].actualDate;
        }

        if (status) {
          convertedTableData[dataIndex].status = status;
        } else {
          delete convertedTableData[dataIndex].status;
        }
        convertedTableData = convertedTableData.map((item) => {
          delete item.stageName;
          delete item.dataIndex;
          return item;
        });
        setSatgeGateInforation((prevDetails) => ({
          ...prevDetails,
          stageGates: convertedTableData,
        }));
        break;
      default:
        break;
    }
    closeModal();
  };
  return (
    <div className="stage-gate-section">
      <MuiTable
        columnsList={stageGateConfig()}
        dataList={tableData || []}
        filters={page}
        pageCount={1}
        onClick={editModalHandler}
        onChange={(page) => {
          setPage(page);
        }}
      ></MuiTable>
      {isModalOpen && (
        <CustomModal
          title={modalDetails.modalTitle}
          onClose={closeModal}
          classesToPreventClosing={["poper-select", "MuiBackdrop-root"]}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
              display: "grid",
              gridGap: "1em",
            }}
          >
            <TextField
              fullWidth
              size="small"
              label="Stage"
              disabled
              className="new-textfield"
              value={modalDetails?.data?.stageName || ""}
            />
            <div>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={modalDetails?.data?.status || ""}
                  label="Status"
                  name="status"
                  onChange={setModalDataOnChange}
                >
                  {stageGateStatuses.map((format) => {
                    return (
                      <MenuItem className="poper-select" value={format.value}>
                        {format.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <TextField
              fullWidth
              type="date"
              size="small"
              name="actualDate"
              onChange={setModalDataOnChange}
              InputLabelProps={{ shrink: true }}
              label="Actual Date"
              className="new-textfield"
              value={dateFun(modalDetails?.data?.actualDate) || ""}
            />
            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <div className="end-con">
                <div>
                  <CommonBtn
                    variant="contained"
                    className="button-color"
                    fullWidth
                    onClick={saveorAddModalData}
                    size="small"
                    startIcon={<SaveIcon />}
                  />
                </div>

                <div>
                  <CommonBtn
                    variant="outlined"
                    fullWidth
                    onClick={closeModal}
                    size="small"
                    text="Cancel"
                  />
                </div>
              </div>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </div>
  );
};

export default StageGate;
