export const getHeaderConfig = () => {
  const headers = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'Code',
      id: 'code',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Vendor Gst',
      id: 'gst',
    },
    {
      label: 'Vendor PAN Number',
      id: 'pan',
    },
    {
      label: 'Country',
      id: 'country',
    },
    {
      label: 'Mobile Number',
      id: 'vendorOnboardDetails.mobile',
    },
    {
      label: 'PIN Code',
      id: 'pinCode',
    },
    {
      label: 'Email',
      id: 'vendorOnboardDetails.email',
    },
    {
      label: 'Bank Name',
      id: 'vendorOnboardDetails.bankDetails.bankName',
    },
    {
      label: 'IFSC-A/C No.',
      id: 'vendorOnboardDetails.bankDetails.accountNumber',
    },
    {
      label: 'MSME Status',
      id: 'vendorOnboardDetails.msme',
    },
    {
      label: 'Address',
      id: 'address',
      render: (data) => {
        return `${data?.address || ''} ${data.city || ''} ${data.state || ''} `;
      },
    },
  ];
  return headers;
};
