import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { dateFun } from "../../../utils";

export const stageGateConfig = () => {
  const config = [
    {
      label: "Stage",
      id: "stageName",
      key: "stageName",
      value: (data) => {
        return data?.stage?.stageName;
      },
    },
    { label: "Status", id: "status", key: "status"  },
    {
      label: "Actual Date",
      id: "actualDate",
      render: (data, _, index) => {
        return <div id={index}>{dateFun(data?.actualDate)}</div>;
      },
      value: (data) => {
        return dateFun(data?.actualDate);
      },
    },
    {
      label: "Actions",
      id: "Actions",
      render: (data, onclick) => {
        return (
          <div id={Math.random()}>
            <IconButton
              onClick={() => {
                onclick(data, "edit");
              }}
              sx={{ p: 0 }}
            >
              <EditIcon id="edit" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return config;
};
