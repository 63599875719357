export const uploadAttachmentTypes = {
  lineItemApproval: 'Create Line Item Approval',
  createGrn: 'New Grn is getting created',
  updateGrn: 'Grn is getting updated',
  enterPaymentDetails: 'Enter Payment Details',
  resendPoAdvance: 'Po Advance Is Resent for consideration',
  initiateAdvancePayment: 'Advance Payment is Initiated',
  createPo: 'New Po is getting created',
  poUpdate: 'Po is getting updated',
};

export const getUploadAttachmentQueryParams = (data, type) => {
  let description = '';
  let additionalDetails = {};

  switch (type) {
    case uploadAttachmentTypes.lineItemApproval:
    
      description = `Line Item Current Amount updated to ${data?.currentAmount}. For line item id - ${data?.id}, line item name - ${data?.name}`;
      additionalDetails = {
        budgetId: data?.budgetId,
        lineItemId: parseInt(data?.id),
        type: 'Line Item Modification',
      };
      break;
    case uploadAttachmentTypes.createGrn:
      description = `Attachment uploaded during grn creation. Attachment type - ${data.attachmentLabel}`;
      additionalDetails = {
        ...data,
        attachmentKey: data.attachmentKey,
        type: 'GRN Creation',
        attachmentLabel: data.attachmentLabel,
      };
      break;
    case uploadAttachmentTypes.updateGrn:
      description = `Attachment uploaded during grn updation. Attachment type - ${data.attachmentLabel}. GRN No : ${data.grnNo}`;
      additionalDetails = {
        ...data,
        type: 'GRN Updation',
      };
      break;
    case uploadAttachmentTypes.enterPaymentDetails:
      description = `Attachment uploaded during entering payment details. Attachment Type - ${data.attachmentType}`;
      additionalDetails = {
        ...data,
        type: 'Enter Payment Details',
      };
      break;
    case uploadAttachmentTypes.resendPoAdvance:
      description = `Attachment uploaded while resending advance payment. Attachment type: ${data.attachmentLabel}.`;
      additionalDetails = {
        ...data,
        type: 'Resend Advance Payment',
      };
      break;
    case uploadAttachmentTypes.initiateAdvancePayment:
      description = `Attachment uploaded while initaitating advance payment. Attachment type: ${data.attachmentLabel}.`;
      additionalDetails = {
        ...data,
        type: 'Initiate Advance Payment',
      };
      break;
    case uploadAttachmentTypes.createPo:
      description = `Attachment Uploaded during po creation`;
      additionalDetails = {
        ...data,
        type: 'PO is getting created',
      };
      break;
    case uploadAttachmentTypes.poUpdate:
      description = `Attachment Uploaded during po updation, po number - ${data.poNumber}`;
      additionalDetails = {
        ...data,
        type: 'PO is getting updated',
      };
      break;
    default:
      break;
  }
  additionalDetails = JSON.stringify(additionalDetails);
  return `?description=${description}&additionalDetails=${additionalDetails}`;
};
