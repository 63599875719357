import { createUpdateModalConfig } from '../../../config/create-update-modal-config';
import { Autocomplete, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
const MainFields = ({ config, setModalData, modalData }) => {
  const onInputChange = (e) => {
    setModalData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onAutocompleteChange = (key, val) => {
    const value = val?.hasOwnProperty?.('value') ? val?.value : null;

    setModalData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <>
      <Grid
        container
        md={12}
        sx={{
          display: 'flex',
          mt: 2,
          mb: 2,
          justifyContent: 'space-between',
          [`@media (max-width: 768px)`]: {
            gridGap: '20px',
          },
        }}
      >
        {createUpdateModalConfig(config.catList).map((fieldConfig, index) => {
          const { label, name, options, key } = fieldConfig;
          return (
            <Grid key={index} sx={{ mt: 2 }} item md={5}>
              {fieldConfig.type === 'text' ? (
                <TextField
                  label={label}
                  name={name}
                  onChange={onInputChange}
                  value={modalData?.[name]}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              ) : fieldConfig.type === 'autocomplete' ? (
                <Autocomplete
                  value={
                    modalData?.[key] || modalData?.[key] === 0
                      ? options.find((item) => {
                          const valueKey = item?.hasOwnProperty('value')
                            ? 'value'
                            : 'id';
                          return item.value === modalData?.[key];
                        })
                      : null
                  }
                  onChange={(e, val) => onAutocompleteChange(key, val)}
                  name={key}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={`${label}`}
                      sx={{
                        minWidth: 220,
                      }}
                    />
                  )}
                  options={options}
                />
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MainFields;
