import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getGRNReceivedQuantityAmount,
  getGRNReceivedQuantityBaseAmount,
  getGRNReceivedQuantityTaxAmount,
  getGRNCreateHeaderConfig,
} from "../create-grn/helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Button, Chip, IconButton, TextField, Typography } from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import MuiTable from "../../../../common-utilities/core/mui-table";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import { DATE_FORMATS } from "../../../../../utils/constants";
import { updateGRNPayloadMapper } from "./helper";
import {
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
} from "../../purchase-order/helper";
import GrnCreatedModal from "../modal/grn-created.modal";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { getOfflineData } from "../../../utils/offline-services";
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from "../../../utils/attachments";

const EditGRN = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [grnItems, setGRNItems] = useState([]);
  const [grnModalDetails, setGrnModalDetails] = useState({});
  const user = getOfflineData("user");
  const [poDetails, setPODetails] = useState([]);
  const [isUpdateDetails, setIsUpdateDetails] = useState(false);
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  const [formDetails, setFormDetails] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [invoiceUrls, setInvoiceUrls] = useState([]);
  const [config, setConfig] = useState({
    allInvLen: 0,
  });

  const onAttachmentsDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const onInvoiceUrlsDelete = (index) => {
    invoiceUrls.splice(index, 1);
    setInvoiceUrls([...invoiceUrls]);
  };

  const onInvoiceInputChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  const {
    budget = {},
    company = {},
    poType = "",
    poNumber = "",
    items = [],
    vendorName = "",
    vendorCode = "",
    vendorGst = "",
    vendorPancard = "",
  } = poDetails;
  const { siteCode = "", siteName = "" } = budget || {};

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}`,
      null,
      {
        _id: id,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          const {
            additionalDocUrls = [],
            invoiceUrls = [],
            receivedItems = [],
            purchaseOrderId = {},
          } = response.results[0];
          const { items = [] } = purchaseOrderId;
          let grnDetails = {
            ...response.results[0],
            invoiceDate: formatDate(
              response.results[0].invoiceDate,
              DATE_FORMATS["YYYY-MM-DD"]
            ),
            inwardDate: formatDate(
              response.results[0].inwardDate,
              DATE_FORMATS["YYYY-MM-DD"]
            ),
          };
          setGrnModalDetails({ mainGrnDetails: grnDetails });
          setFormDetails(grnDetails);
          setPODetails(purchaseOrderId || {});
          setAttachments(
            additionalDocUrls.map((invoiceUrl, i) => {
              return {
                docUrl: invoiceUrl,
                originalName: "attachment" + (i + 1),
              };
            })
          );

          setInvoiceUrls(
            invoiceUrls.map((invoiceUrl, i) => {
              return {
                docUrl: invoiceUrl,
                originalName: "attachment" + (i + 1),
              };
            })
          );
          setProperty("allInvLen", invoiceUrls?.length || 0, setConfig);

          setGRNItems(
            items.map((i) => {
              const receivedItem = receivedItems.find(
                (r) => r.poItemId === i._id
              );

              return {
                ...i,
                ...receivedItem,
                hsnCode: receivedItem?.hsnCode || i?.item?.hsnCode || "",
                poItemId: i._id,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    let payload = updateGRNPayloadMapper(
      poDetails,
      grnItems,
      attachments,
      invoiceUrls,
      formDetails
    );

    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}/${id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed Create grn");
        } else {
          setGrnModalDetails({ mainGrnDetails: response });
          setProperty("allInvLen", invoiceUrls?.length || 0, setConfig);
          toast.info("GRN details updated");
          setIsUpdateDetails(true);
        }
      })
      .catch((err) => {});
  };

  const onInputChange = (event, data) => {
    const index = grnItems.findIndex((d) => d._id === data._id);
    if (index !== -1) {
      grnItems[index][event.target.name] = event.target.value;
    }
    setGRNItems([...grnItems]);
  };

  const sendForApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SEND_GRN_FOR_APPROVAL}${id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          setGrnModalDetails({
            title: "GRN Sent For Approval",
            data: { ...grnModalDetails.mainGrnDetails, poDetails },
            onOk: () => {
              navigate(`/subway-finance/grn-order/${id}`);
            },
          });
        }
      })
      .catch((err) => {});
  };

  const resendForApproval = () => {
    const { approvalId = {} } = formDetails;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}${approvalId.id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to resend for approval");
        } else {
          navigate(`/subway-finance/grn-order/${id}`);
        }
      })
      .catch((err) => {});
  };
  console.log(isUpdateDetails);
  return (
    <div className="edit-grn-container">
      <div className="grn-edit-header">
        <div className="left-container">
          <div className="back-title">
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=2");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className="title">GRN Details</Typography>
          </div>
          <div className="grn-edit-info-section">
            <div className="info-box">
              <span>GRN Number </span>
              <span>{formDetails.grnNo}</span>
            </div>
            <div className="info-box">
              <span>PO Number </span>
              <span>{poNumber || ""}</span>
            </div>
            <div className="info-box">
              <span>Site Code </span>
              <span>{siteCode || ""}</span>
            </div>
            <div className="info-box">
              <span>Site Name</span>
              <span>{siteName || ""}</span>
            </div>
            <div className="info-box">
              <span>Company Name </span>
              <span>{company.name || ""}</span>
            </div>
            <div className="info-box">
              <span>Financial Year</span>
              <span>{formDetails.financialyear || ""}</span>
            </div>
            <div className="info-box">
              <span>PO Type </span>
              <span>{convertToCamelCaseFromUnderScore(poType)}</span>
            </div>
            <div className="info-box">
              <span>PO Amount </span>
              <span>{getPurchaseOrderAmount(items || [])}</span>
            </div>
            <div className="info-box">
              <span>PO Tax </span>
              <span>{getPurchaseOrderTaxAmount(items || [])}</span>
            </div>
          </div>
        </div>

        <div className="right-container">
          {parseInt(formDetails?.createdBy) ===
            parseInt(user?.id || user?._id) && (
            <>
              <Button variant="outlined" onClick={onSave}>
                Update Details
              </Button>
              {(["draft"].includes(formDetails.status) && config.allInvLen && (
                <Button variant="outlined" onClick={sendForApprove}>
                  Send for Approval
                </Button>
              )) ||
                null}
              {["reconsider"].includes(formDetails.status) && (
                <Button
                  variant="outlined"
                  onClick={resendForApproval}
                  disabled={!isUpdateDetails}
                >
                  Resend for Approval
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="form-container">
        <div className="grn-edit-info-section">
          <div className="info-box">
            <span>Vendor Code </span>
            <span>{vendorCode || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor Name </span>
            <span>{vendorName || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor GST </span>
            <span>{vendorGst || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor PAN Number</span>
            <span>{vendorPancard || ""}</span>
          </div>
          <div className="info-box">
            <span>GRN Status</span>
            <span>
              {convertToCamelCaseFromUnderScore(formDetails.status) || ""}
            </span>
          </div>

          <div className="info-box">
            <span>GRN Base Amt </span>
            <span>{getGRNReceivedQuantityBaseAmount(grnItems || [])}</span>
          </div>

          <div className="info-box">
            <span>GRN Tax Amt </span>
            <span>{getGRNReceivedQuantityTaxAmount(grnItems || [])}</span>
          </div>
          <div className="info-box">
            <span>GRN Total Amt </span>
            <span>{getGRNReceivedQuantityAmount(grnItems || [])}</span>
          </div>
        </div>
      </div>
      <div className="grn-invoice-details-container">
        <TextField
          label="Material/Service Received date *"
          fullWidth
          size="small"
          type="date"
          name="inwardDate"
          inputProps={{ max: "2123-05-31" }}
          value={formDetails.inwardDate || ""}
          InputLabelProps={{ shrink: true }}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Date *"
          fullWidth
          size="small"
          type="date"
          name="invoiceDate"
          inputProps={{ max: "2123-05-31" }}
          value={formDetails.invoiceDate || ""}
          InputLabelProps={{ shrink: true }}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Number *"
          fullWidth
          size="small"
          name="invoiceNumber"
          value={formDetails.invoiceNumber || ""}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Amount *"
          type="number"
          fullWidth
          size="small"
          name="invoiceAmount"
          value={formDetails.invoiceAmount || ""}
          onChange={onInvoiceInputChange}
          error={
            formDetails.invoiceAmount > 0 &&
            formDetails.invoiceAmount != getGRNReceivedQuantityAmount(grnItems)
          }
          helperText={
            formDetails.invoiceAmount > 0 &&
            formDetails.invoiceAmount != getGRNReceivedQuantityAmount(grnItems)
              ? `Invoice Amt is not same as GRN Total Amt(${getGRNReceivedQuantityAmount(
                  grnItems
                )})`
              : ""
          }
        />
        <TextField
          label="Remarks"
          fullWidth
          size="small"
          name="remarks"
          value={formDetails.remarks || ""}
          onChange={onInvoiceInputChange}
        />
      </div>
      <div className="grn-file-upload-container">
        <FileUpload
          uploadText="Upload Invoice Attachments *"
          id="invoice-attachments"
          clsName="invoice-additional-doc"
          url={`${FINANCE_HOSTNAME}${
            FINANCE_REST_URLS.UPLOAD_DOCUMENT
          }${getUploadAttachmentQueryParams(
            {
              attachmentKey: "invoiceUrls",
              attachmentLabel: "Upload Invoice Attachments",
              grnNo: formDetails.grnNo,
              poNumber: formDetails?.poNumber,
              status: formDetails.status,
              id: formDetails.id,
            },
            uploadAttachmentTypes.updateGrn
          )}`}
          callBack={(response) => {
            const { data } = response;
            setInvoiceUrls([...invoiceUrls, data]);
          }}
          formDataKey="file"
        />
        {invoiceUrls.map((attachment, index) => {
          return (
            <Chip
              label={attachment.originalName}
              onDelete={() => onInvoiceUrlsDelete(index)}
              onClick={() => window.open(attachment?.docUrl, "_blank")}
            />
          );
        })}
        <FileUpload
          uploadText="Upload Additional Documents"
          id="invoice-additional-doc"
          clsName="invoice-additional-doc"
          url={`${FINANCE_HOSTNAME}${
            FINANCE_REST_URLS.UPLOAD_DOCUMENT
          }${getUploadAttachmentQueryParams(
            {
              attachmentKey: "additionalDocUrls",
              attachmentLabel: "Upload Additional Documents",
              grnNo: formDetails.grnNo,
              poNumber: formDetails?.poNumber,
              status: formDetails.status,
              id: formDetails.id,
            },
            uploadAttachmentTypes.updateGrn
          )}`}
          callBack={(response) => {
            const { data } = response;
            setAttachments([...attachments, data]);
          }}
          formDataKey="file"
        />
        {attachments.map((attachment, index) => {
          return (
            <Chip
              label={attachment.originalName}
              onDelete={() => onAttachmentsDelete(index)}
              onClick={() => window.open(attachment?.docUrl, "_blank")}
            />
          );
        })}
      </div>
      <div className="po-add-items-container">
        <div className="added-po-items-list">
          <MuiTable
            columnsList={getGRNCreateHeaderConfig(onInputChange)}
            dataList={
              grnItems?.slice?.(
                ((filters?.page || 1) - 1) * 10,
                ((filters?.page || 1) - 1) * 10 + 10
              ) || []
            }
            pageCount={Math.ceil(grnItems.length / 10)}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      </div>
      <GrnCreatedModal grnDetails={grnModalDetails} />
    </div>
  );
};

export default EditGRN;
