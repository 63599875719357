import {
  startDateCellWithoutNa,
  storeFormatCell,
  formatDateCell,
} from "../../utils/report-table.utils";

export const FOFO_COLUMNS = [
  {
    id: "master",
    Header: "Master",
    columns: [
      {
        id: "masterStoreCode",
        Header: "Store Code",
        accessor: "restaurant.storeNumber",
      },
      {
        id: "masterStoreName",
        Header: "Store Name as per Posist",
        accessor: "restaurant.ownerName",
      },
      {
        id: "masterStoreRegion",
        Header: "State",
        accessor: "restaurant.state",
      },
      {
        id: "masterStoreCity",
        Header: "City",
        accessor: "restaurant.city",
      },
      {
        id: "masterStoreType",
        Header: "Store Type",
        accessor: "restaurant.storeType",
      },
      {
        id: "masterStoreFormat",
        Header: "Store Format",
        accessor: "restaurant",
        Cell: storeFormatCell,
      },
      {
        id: "masterStoreOpeningDate",
        Header: "Opening Date",
        accessor: "restaurantId.openingDate",
      },
    ],
  },
  {
    id: "franchiseAgreement",
    Header: "Franchise Agreement",
    columns: [
      {
        id: "franchiseAgreementDate",
        Header: "Start Date",
        accessor: "FRANCHISE_AGREEMENT.dateFile",
        Cell: startDateCellWithoutNa,
      },
      {
        id: "franchiseAgreementEndDate",
        Header: "End Date",
        accessor: "FRANCHISE_AGREEMENT.endDate",
        Cell: formatDateCell,
      },
    ],
  },
  {
    id: "operatingAgreement",
    Header: "Operating Assignment",
    columns: [
      {
        id: "operatingAgreementDate",
        Header: "Start Date",
        accessor: "OPERATING_ASSIGNMENT.dateFile",
        Cell: startDateCellWithoutNa,
      },
      {
        id: "operatingAgreementEndDate",
        Header: "End Date",
        accessor: "OPERATING_ASSIGNMENT.endDate",
        Cell: formatDateCell,
      },
    ],
  },
  {
    id: "subLeaseAgreement",
    Header: "Lease Agreement",
    columns: [
      {
        id: "subLeaseAgreementDate",
        Header: "Start Date",
        accessor: "SUB_LEASE_AGREEMENT.dateFile",
        Cell: startDateCellWithoutNa,
      },
      {
        id: "subLeaseAgreementEndDate",
        Header: "End Date",
        accessor: "SUB_LEASE_AGREEMENT.endDate",
        Cell: formatDateCell,
      },
    ],
  },
];
