import { Button } from '@mui/material';
import {
  exportToExcel,
  exportToExcelArray,
} from '../../../../common-utilities/utils';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { invokeApi, HTTP_METHODS } from '../../../utils/http-service';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import {
  defaultTemplates,
  singleLineTemplates,
  uploadTemplates,
} from '../constants';
import { processGrnTemplateData } from './utils';

// When user tries to download file for which bulk data can be uploaded
// On clicking the button user can download basic headers
const DownloadTemplateButton = ({
  type,
  data,
  title = 'Download Template',
}) => {
  const downloadUploadTemplate = (type) => {
    const singleLineTemplate = singleLineTemplates?.[type];
    if (singleLineTemplate) {
      exportToExcelArray(singleLineTemplate, type);
      return;
    }
    switch (type) {
      case uploadTemplates.grn:
        processGrnTemplateData(data);
        break;

      default:
        invokeApi(
          HTTP_METHODS.GET,
          `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.DOWNLOAD_UPLOAD_MASTER}${type}`
        )
          .then((res) => {
            if (!res?.message) {
              switch (type) {
                case uploadTemplates.company:
                  {
                    const companyList = res?.companyList?.length
                      ? res?.companyList
                      : defaultTemplates[uploadTemplates.company];
                    const parentList = res?.parentList?.length
                      ? res?.parentList
                      : defaultTemplates[uploadTemplates.company];
                    exportToExcel(
                      [
                        {
                          sheetName: uploadTemplates.company,
                          details: companyList,
                        },
                        {
                          sheetName: 'parent-list',
                          details: parentList,
                        },
                      ],
                      type,
                      true,
                      true
                    );
                  }
                  break;
                case uploadTemplates.item:
                  {
                    const items = res?.items?.length
                      ? res.items
                      : defaultTemplates[uploadTemplates.item];
                    const category = res?.categories?.length
                      ? res.categories
                      : defaultTemplates[uploadTemplates.category];
                    const subCategory = res.subCategories?.length
                      ? res.subCategories
                      : defaultTemplates[uploadTemplates.subCategory];
                    exportToExcel(
                      [
                        {
                          sheetName: uploadTemplates.item,
                          details: items,
                        },
                        {
                          sheetName: uploadTemplates.category,
                          details: category,
                        },
                        {
                          sheetName: uploadTemplates.subCategory,
                          details: subCategory,
                        },
                      ],
                      type,
                      true,
                      true
                    );
                  }
                  break;
                case uploadTemplates.subCategory:
                  {
                    const category = res?.categories?.length
                      ? res.categories
                      : defaultTemplates[uploadTemplates.category];
                    const subCategory = res.subCategories?.length
                      ? res.subCategories
                      : defaultTemplates[uploadTemplates.subCategory];
                    exportToExcel(
                      [
                        {
                          sheetName: uploadTemplates.subCategory,
                          details: subCategory,
                        },
                        {
                          sheetName: uploadTemplates.category,
                          details: category,
                        },
                      ],
                      type,
                      true,
                      true
                    );
                  }
                  break;
                default:
                  exportToExcel(res?.length ? res:defaultTemplates[type], type, null, true);
                  break;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
    }
  };
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          downloadUploadTemplate(type);
        }}
      >
        {title}
      </Button>
    </>
  );
};

export default DownloadTemplateButton;
