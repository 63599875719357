import { getAllDepartmentRoles } from "./apis";

export const selectHandler = (e, details, setDataFunc, setRolesList) => {
  const { name } = details;
  setDataFunc((prevDetails) => {
    switch (name) {
      case "department":
        delete prevDetails.departmentRole;
        getAllDepartmentRoles({ department: e?.value }, setRolesList);
        break;
      default:
        break;
    }

    return { ...prevDetails, [name]: e?.value };
  });
};

export const processEditData = (data) => {
  const { additionalRoles } = data;
  if (additionalRoles) {
    const roles = Object.keys(
      additionalRoles?.reduce?.((roleMap, item) => {
        roleMap[item] = true;
        return roleMap;
      }, {}) || {}
    ).map((item) => parseInt(item));
    data.additionalRoles = roles;
  }
  return data;
};
