export const validAddressTypes = {
  person: "Person",
  store: "Store",
  drawing: "drawing_address",
};

export const validAddressLabels = {
  person: "Person",
  store: "Store",
  drawing: "Drawing Address",
};

export const allAddresses = Object.values(validAddressTypes);

export const auditModelNames = {
  storeDrawing: "Store Drawing",
  store: "Store",
  address: "Address",
  franchise: "Franchise Agreement",
  communication: "Communication",
  user: "User",
  storeAttributes: "Store Attributes",
  storeInsurance: "Store Insurance",
  waiverAssignment: "Waiver Assignment",
  storeLease: "Store Lease",
  waiver: "Waivers",
  StoreTransfer: "Store Transfer",
};
