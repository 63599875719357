export const downloadReportDates = [
  "Start Date",
  "End Date",
  "Lease Sign Date",
  "Lease Effective Date",
  "Lease End Date",
  "Lock in expiry date",
  "Document Date",
  "Expiry Date",
  "LOI Date",
  "Letter Date",
  "LOI Sign Date",
];
