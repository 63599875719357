import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../routes/endpoints";
import { tokenMainKeys } from "../../../utils/constants";
import { userStates } from "../../common-utilities/utils/constants";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { SIDE_MENU } from "../core/side-menu/config";
// SIDE_MENU()
import {
  getUserDetailsFromIntranet,
  redirectToIntranetPortal,
} from "../utils/common-utils";
import { redirectToRoute } from "../../common-utilities/utils/end-points/docu-storage";
import MainContainer from "../container";

const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const [user, setUser] = useState(userStates.isProcessing);
  const redirectToUploadIfMainPageIsThere = () => {
    const docuStorageHomePage = getProjectEndPoint(
      endPointsForProject.docuStorage
    );
    const isHomePage = location.pathname === docuStorageHomePage;
    if (isHomePage) {
      navigate(redirectToRoute);
    }
  };
  const checkAndGrantUserAccess = (tokens) => {
    getUserDetailsFromIntranet(
      access,
      (userDetails) => {
        if (userDetails && !userDetails?.message) {
          setUser(userDetails);
        }
        setSearchParams({});
        redirectToUploadIfMainPageIsThere();
      },
      tokens
    );
  };

  useEffect(() => {
    const userDetails = getOfflineData(tokenMainKeys.docuStorage, "user");
    const userDetailsNotPresent = !!!userDetails;
    const accessAndRefreshTokensArePresent = !!access && !!refresh;
    const tokens = {
      access: {
        token: access,
        expires: accessTokenExpiry?.trim(),
      },
      refresh: {
        token: refresh,
        expires: refreshTokenExpiry?.trim(),
      },
    };
    if (userDetailsNotPresent) {
      if (accessAndRefreshTokensArePresent) {
        checkAndGrantUserAccess(tokens);
      } else {
        redirectToIntranetPortal();
      }
    } else if (accessAndRefreshTokensArePresent) {
      checkAndGrantUserAccess(tokens);
    } else {
      redirectToUploadIfMainPageIsThere();
      setUser(userDetails);
    }
  }, [access, refresh]);

  return user && user !== userStates.isProcessing ? (
    // SIDE_MENU
    <MainContainer>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
