import Grid from "@mui/material/Unstable_Grid2";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Select from "react-select";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";
import { invokeApi } from "../../utils/http-service";
import AddAddress from "./add-address";
import AddCommunication from "./add-communication";
import { checkValidStoreCombination } from "../../utils";

import { validAddressTypes, validAddressLabels } from "../../utils/constants";
import { Satellites, CONTACTS, STORE_STATUS, STORE_TYPE } from "./config";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import AddContact from "./add-contact";
import { GlobalStoreContext } from "../../global-states/Context";
import { CLOSING_REASON } from "./config.js";
import StoreOwner from "./add-store-owner";
import { allRegions } from "../../../common-utilities/utils/region";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";

const displayContainer = {
  display: "flex",
  gap: 20,
  alignItems: "center",
};

const StoreDetails = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [storeDetails, setStoreDetails] = useState({});
  const [addressTypes, setAddressTypes] = useState([]);
  const [communicationTypes, setCommunicationTypes] = useState([]);
  const [addressFlag, setAddressFlag] = useState(false);
  const [addressType, setaddressType] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [communicationFlag, setCommunicationFlag] = useState(false);
  const [contactFlag, setContactFlag] = useState(false);
  const [storeOwnerFlag, setStoreOwnerFlag] = useState(false);
  const [changedStoreDetails, setChangedStoreDetails] = useState({});
  const [type, setTypes] = useState([]);
  const [personInput, setPersonInput] = useState("");
  const [personOptions, setPersonOptions] = useState([]);
  const previousController = useRef();
  const previousPersonController = useRef();
  const [jobTypePersonNum, setJobTypePersonNum] = useState("");
  const [sequenceList, setSequenceList] = useState([]);
  const [communicationValue, setCommunicationValue] = useState({});
  const [addressValue, setAddressValue] = useState({});
  const [storeOwnersList, setStoreOwnersList] = useState([]);
  const [storeOwnerValue, setStoreOwnerValue] = useState("");
  const [editOwnerObj, setEditOwnerObj] = useState({});
  const [regionList, setRegionList] = useState({
    country: [],
    state: [],
  });
  const [userEmail, setUserEmail] = useState(false);
  useEffect(() => {
    const user = getOfflineData(tokenMainKeys.subs, "user")?.email;
    if (
      user === "ashwin@bellcorpstudio.com" ||
      user === "mbysack@subway.in" ||
      user === "abaseer@subway.in"
    ) {
      setUserEmail(true);
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    const countries = Object.keys(allRegions);
    const countriesLabelsAndSelect = getLabelsAndValuesForSelect(
      countries,
      countries
    );
    setRegionList((prevVal) => ({
      ...prevVal,
      country: countriesLabelsAndSelect,
    }));
    const addressLabels = [validAddressLabels.person, validAddressLabels.store];
    const addressTypes = [validAddressTypes.person, validAddressTypes.store];
    const allTypeLabels = getLabelsAndValuesForSelect(
      addressLabels,
      addressTypes
    );
    setTypes(allTypeLabels);
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.LIST_ADDRESS_TYPE}`)
      .then((response) => {
        if (response && !response?.message) {
          const data = getLabelsAndValuesForSelect(response, response);
          setaddressType(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    } else {
      return "";
    }
  };
  const loadData = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_ADDRESS_TYPES}`)
      .then((response) => {
        if (response.length > 0) {
          setAddressTypes(
            response.map((op) => ({ label: op, value: op })) || []
          );
        }
      })
      .catch((err) => console.log(err));

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_COMMUNICATION_TYPES}`
    )
      .then((response) => {
        if (response.length > 0) {
          setCommunicationTypes(
            response.map((op) => ({ label: op, value: op }))
          );
        } else {
          setCommunicationTypes([
            {
              label: "Business Communication",
              value: "Business Communication",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (storeDetails?.store?.owner?.length > 0) {
      let owners = storeDetails.store.owner
        .map((ele) => {
          if (ele?.id) {
            return {
              label: `${ele?.id?.firstName} ${ele?.id?.lastName}`,
              value: ele?.id?.id,
            };
          }
        })
        .filter((value) => value !== undefined);
      setStoreOwnersList(owners);
      setStoreOwnerValue("");
      setEditOwnerObj({});
    }
  }, [storeDetails.store]);
  useEffect(() => {
    if (storeOwnersList.length > 0) {
      const findPrimaryOwner = storeDetails?.store?.owner.find(
        (ele) => ele.primary === true
      );
      if (findPrimaryOwner) {
        setStoreOwnerValue({
          label: `${findPrimaryOwner?.id?.firstName} ${findPrimaryOwner?.id?.lastName}`,
          value: findPrimaryOwner?.id?.id,
        });
        setEditOwnerObj(findPrimaryOwner);
      }
    }
  }, [storeOwnersList]);
  const getStoreDetailsById = (id) => {
    // getSequenceListHandler(id);
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_STORES}${id}`,
      null,
      {
        satelliteNo: storeInfo.satelliteNo,
        sequenceNo: storeInfo.sequenceNo,
      }
    ).then((response) => {
      if (response?.message) {
        toast.error(response?.message, { autoClose: 2000 });
        clearData(false);
        return;
      }
      setStoreDetails(response);
      setRespData(response);
      setStoreInfo((prevVal) => ({
        ...prevVal,
        sequenceNo: response.store.sequenceNo,
      }));
      if (response?.store?.owner.length > 0) {
        let owners = response?.store?.owner
          .map((ele) => {
            if (ele?.id) {
              return {
                label: `${ele?.id?.firstName} ${ele?.id?.lastName}`,
                value: ele?.id?.id,
              };
            }
          })
          .filter((value) => value !== undefined);

        setStoreOwnersList(owners);
      }
    });
  };
  const updateDetails = () => {
    if (!Number.isInteger(parseInt(storeInfo.sequenceNo))) {
      toast.error("Please select Sequence Number", { autoClose: 2000 });
      return;
    }

    if (!Number.isInteger(parseInt(storeInfo.satelliteNo))) {
      toast.error("Please select Satellite Number", { autoClose: 2000 });
      return;
    }
    if (
      changedStoreDetails?.type === "COCO_ACQUIRED" &&
      !changedStoreDetails?.acquisitionDate
    ) {
      toast.error("Please provide Acquisition Date for COCO Acquired Store", {
        autoclose: 2000,
      });
      return;
    }
    let payload = {
      ...changedStoreDetails,
      sequenceNo: storeInfo.sequenceNo,
      satelliteNo: storeInfo.satelliteNo,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_STORE}${storeInfo.storeNo}`,
      payload
    )
      .then((response) => {
        if (!response.message) {
          toast.success("Updated Successfully", { autoClose: 2000 });
          setStoreDetails({});
          setStoreInfo({
            ...storeInfo,
            storeNo: "",
            satelliteNo: null,
            sequenceNo: null,
          });
          setCommunicationValue("");
          setAddressValue({});
          setJobTypePersonNum("");
          setStoreOwnersList([]);
          setStoreOwnerValue("");
          setEditOwnerObj({});
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }
      })
      .catch((err) => console.log(err));
  };

  const {
    store = {},
    address = [],
    communication = [],
    dates = {},
    misc = {},
    contacts = {},
    statuses = {},
  } = storeDetails;

  const onStoreDEtailsChange = (value, dataLabel) => {
    setChangedStoreDetails({
      ...changedStoreDetails,
      [dataLabel]: value,
    });

    setStoreDetails({
      ...storeDetails,
      store: {
        ...store,
        [dataLabel]: value,
      },
    });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.storeNo,
          satelliteNum: p.satelliteNum,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    setSatteliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({ storeNo: value, satelliteNo: null, sequenceNo: null });
    } else {
      clearData();
    }
  };
  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (storeInfo.storeNo) {
      listingLeaseHandler(storeInfo.storeNo);
    }
  }, []);
  useEffect(() => {
    if (
      Number.isInteger(parseInt(storeInfo.storeNo)) &&
      Number.isInteger(parseInt(storeInfo.satelliteNo)) &&
      Number.isInteger(parseInt(storeInfo.sequenceNo))
    ) {
      getStoreDetailsById(storeInfo.storeNo);
    }
  }, [storeInfo.satelliteNo, storeInfo.sequenceNo]);
  const getPersonData = (searchTerm) => {
    if (previousPersonController.current) {
      previousPersonController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousPersonController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions(updatedOptions);
      setPersonInput(searchTerm);
    });
  };
  const onPersonInputChange = (event, value, reason) => {
    if (!value) {
      setPersonInput("");
      setPersonOptions([]);
    }
    if (value) {
      getPersonData(value);
    } else {
      setPersonOptions([]);
    }
  };
  const clearData = (reset = true) => {
    setStoreDetails({});
    if (reset)
      setStoreInfo({ storeNo: "", satelliteNo: null, sequenceNo: null });
    setSatteliteList([]);
    setSequenceList([]);
    setAddressValue({});
    setCommunicationValue({});
    setStoreOwnersList([]);
    setStoreOwnerValue("");
    setEditOwnerObj({});
  };

  useEffect(() => {
    if (address.length > 0) {
      let storeAddressValue = address.filter(
        (ele) => ele.addressType === "Store Address"
      );
      if (storeAddressValue.length > 0) {
        setAddressValue(storeAddressValue);
      } else {
        setAddressValue({});
      }
    }
  }, [address]);
  useEffect(() => {
    if (communication.length > 0) {
      let storePhoneNumber = communication.filter(
        (ele) => ele.communicationType === "Store Phone Number"
      );
      if (storePhoneNumber.length > 0) {
        setCommunicationValue(storePhoneNumber);
      } else {
        setCommunicationValue({});
      }
    }
  }, [communication]);

  return (
    <div className="store-details-container">
      <div className="title">Store Selection</div>
      <Grid style={displayContainer} container>
        <Grid item md={1.5}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            className="subs-autocomplete"
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                const { storeNo } = val;
                listingLeaseHandler(val.id);
                // setStoreInfo({ ...storeInfo, id: storeNo });
                // getStoreDetailsById(storeNo);
              }
            }}
          />
        </Grid>

        <Grid item md={1.5}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo({ ...storeInfo, satelliteNo: val.value });
            }}
          />
        </Grid>

        <Grid item md={1.5}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo({ ...storeInfo, sequenceNo: val.value });
            }}
          />
        </Grid>

        {/* <Grid item md={1}>
          <TextField
            label="Market Area Code"
            value={store?.storeDetails?.MarketAreaCode || ""}
            onChange={(event) => {
              setChangedStoreDetails({
                ...changedStoreDetails,
                storeDetails: {
                  ...store.storeDetails,
                  MarketAreaCode: event.target.value,
                },
              });

              setStoreDetails({
                ...storeDetails,
                store: {
                  ...store,
                  storeDetails: {
                    ...store.storeDetails,
                    MarketAreaCode: event.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid item md={1.5}>
          <TextField
            label="Market Area Name"
            value={store?.storeDetails?.MarketAreaName || ""}
            onChange={(event) => {
              setChangedStoreDetails({
                ...changedStoreDetails,
                storeDetails: {
                  ...store.storeDetails,
                  MarketAreaName: event.target.value,
                },
              });

              setStoreDetails({
                ...storeDetails,
                store: {
                  ...store,
                  storeDetails: {
                    ...store.storeDetails,
                    MarketAreaName: event.target.value,
                  },
                },
              });
            }}
          />
        </Grid> */}
        <Grid item md={1.2}>
          <TextField
            label="DA Number"
            className="subs-text-field"
            value={storeDetails?.store?.daNo || ""}
            onChange={(event) => {
              setChangedStoreDetails({
                ...changedStoreDetails,
                daNo: event.target.value,
              });

              setStoreDetails({
                ...storeDetails,
                store: {
                  ...store,
                  daNo: event.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item md={1.7}>
          <TextField
            className="subs-text-field"
            label="DA Abbrevation Owner Name"
            value={storeDetails?.store?.storeDetails?.DAAbbrevOwnerName || ""}
            fullWidth
            onChange={(event) => {
              setChangedStoreDetails({
                ...changedStoreDetails,
                storeDetails: {
                  // ...store.storeDetails,
                  DAAbbrevOwnerName: event.target.value,
                },
              });

              setStoreDetails({
                ...storeDetails,
                store: {
                  ...store,
                  storeDetails: {
                    ...store.storeDetails,
                    DAAbbrevOwnerName: event.target.value,
                  },
                },
              });
            }}
          />
        </Grid>
        {userEmail && (
          <Grid item md={1.5}>
            <Select
              id="type"
              classNamePrefix="custom_select"
              placeholder="Store Type"
              value={
                storeDetails?.store?.type
                  ? {
                      label: storeDetails?.store?.type,
                      value: storeDetails?.store?.type,
                    }
                  : null
              }
              options={STORE_TYPE}
              onChange={(event) => {
                setChangedStoreDetails({
                  ...changedStoreDetails,
                  type: event.value,
                });
                setStoreDetails({
                  ...storeDetails,
                  store: {
                    ...storeDetails.store,
                    type: event.value,
                  },
                });
              }}
            />
          </Grid>
        )}

        <div className="v-divider"></div>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            setStoreDetails({});
            setStoreInfo({
              ...storeInfo,
              storeNo: "",
              sequenceNo: null,
              satelliteNo: null,
            });
            setPersonInput("");
            setJobTypePersonNum("");
            setCommunicationValue("");
            setAddressValue({});
            setStoreOwnersList([]);
            setStoreOwnerValue("");
            setEditOwnerObj({});
          }}
          startIcon={
            <AutorenewIcon style={{ fontSize: "15px", marginRight: "-5px" }} />
          }
        >
          Reset
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            excelDownload("store_details_" + storeInfo.storeNo, respData);
          }}
          disabled={respData === null}
          startIcon={
            <DownloadRoundedIcon
              style={{ fontSize: "20px", marginRight: "-5px" }}
            />
          }
        >
          Download
        </Button>
      </Grid>
      <Divider style={{ paddingBottom: 10 }} />
      <Grid container spacing={1}>
        <Grid xs={4}>
          <div>
            <span className="title">Address Details </span>
            <div className="address-details">
              <div className="f-r-2">
                <Select
                  classNamePrefix="custom_select"
                  options={addressTypes}
                  // disabled={true}
                  onChange={(e) => {
                    let addValue = address.filter((ele) => {
                      return ele.addressType === e.value;
                    });
                    if (addValue.length > 0) {
                      setAddressValue(addValue);
                    } else {
                      setAddressValue([
                        {
                          addressType: e.value,
                        },
                      ]);
                    }
                  }}
                  placeholder="Address Type"
                  value={
                    addressValue[0]?.addressType
                      ? {
                          label: addressValue[0]?.addressType,
                          value: addressValue[0]?.addressType,
                        }
                      : null
                  }
                />
                <IconButton onClick={() => setAddressFlag(true)}>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                  />
                </IconButton>
              </div>
              <div className="g-r-2">
                <TextField
                  className="subs-text-field"
                  label="Attention"
                  value={addressValue[0]?.attn || ""}
                />
                <TextField
                  className="subs-text-field"
                  label="Located In"
                  value={addressValue[0]?.address1 || ""}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  className="subs-text-field"
                  label="Street/PO Box"
                  value={addressValue[0]?.address2 || ""}
                />
                <TextField
                  className="subs-text-field"
                  label="Add'l Address"
                  value={addressValue[0]?.address3 || ""}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  className="subs-text-field"
                  label="City"
                  value={addressValue[0]?.city || ""}
                />
                <Select
                  classNamePrefix="custom_select"
                  isSearchable
                  name="country"
                  options={regionList.country}
                  value={
                    regionList.country.find(
                      (t) => t.value === addressValue[0]?.country
                    ) || ""
                  }
                  placeholder="Country"
                />
              </div>
              <div className="g-r-2">
                <Select
                  classNamePrefix="custom_select"
                  isSearchable
                  name="state"
                  options={regionList.state}
                  value={
                    addressValue[0]?.state && {
                      label: addressValue[0].state,
                      value: addressValue[0].state,
                    }
                  }
                  placeholder="State/Prov"
                />
                <TextField
                  className="subs-text-field"
                  label="Postal Code"
                  value={addressValue[0]?.zipCode || ""}
                />
              </div>
            </div>
          </div>
          <div>
            <span className="title" style={{ marginTop: "10px" }}>
              Communication Details
            </span>
            <div className="address-details">
              <div className="f-r-2">
                <Select
                  classNamePrefix="custom_select"
                  disabled
                  options={communicationTypes}
                  placeholder="Communication Type"
                  onChange={(e) => {
                    let comValue = communication.filter((ele) => {
                      return ele.communicationType === e.value;
                    });
                    if (comValue.length > 0) {
                      setCommunicationValue(comValue);
                    } else {
                      setCommunicationValue([
                        {
                          communicationType: e.value,
                        },
                      ]);
                    }
                  }}
                  value={
                    communicationValue[0]?.communicationType
                      ? {
                          label: communicationValue[0]?.communicationType,
                          value: communicationValue[0]?.communicationType,
                        }
                      : null
                  }
                />
                <IconButton onClick={() => setCommunicationFlag(true)}>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                  />
                </IconButton>
              </div>
              <div className="g-r-2">
                <TextField
                  className="subs-text-field"
                  label="Details"
                  value={communicationValue[0]?.value || ""}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={4}>
          <span className="title">Statuses </span>
          <div className="address-details">
            <div className="g-r-2">
              <div>
                <Select
                  id="storeStatus"
                  classNamePrefix="custom_select"
                  placeholder="Store Status"
                  value={
                    store?.status
                      ? {
                          label: store.status,
                          value: store.status,
                        }
                      : null
                  }
                  options={STORE_STATUS}
                  onChange={(event) => {
                    setChangedStoreDetails({
                      ...changedStoreDetails,
                      status: event.value,
                    });

                    setStoreDetails({
                      ...storeDetails,
                      store: {
                        ...store,
                        status: event.value,
                      },
                    });
                  }}
                />
              </div>
              {/* <TextField
                label="Store"
                value={store.status || ""}
                onChange={(event) => {
                  setChangedStoreDetails({
                    ...changedStoreDetails,
                    status: event.target.value,
                  });

                  setStoreDetails({
                    ...storeDetails,
                    store: {
                      ...store,
                      status: event.target.value,
                    },
                  });
                }}
              /> */}
              <TextField
                className="subs-text-field"
                label="Franchise Agreement"
                value={statuses.franchiseAgreement || ""}
                disabled
              />
            </div>
            <div className="g-r-2">
              <TextField
                className="subs-text-field"
                label="Lease"
                value={statuses.lease || ""}
                disabled
              />
            </div>
          </div>
          <span className="title" style={{ margin: "20px 0px 8px 0px" }}>
            Contact Details
          </span>
          <div className="address-details">
            <div className="f-r-2">
              {/* <TextField label="Job Type" value={contacts?.jobType || ""} /> */}
              <Select
                classNamePrefix="custom_select"
                placeholder="Job Type"
                className="sel-item"
                options={CONTACTS}
                onChange={(val) => {
                  setJobTypePersonNum("");
                  if (val.value === "storeDesigner") {
                    if (store?.storeContact?.storeDesigner?.firstName) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.storeDesigner?.firstName} ${store?.storeContact?.storeDesigner?.lastName}`
                      );
                    }
                  } else if (val.value === "constructionAssistant") {
                    if (store?.storeContact?.constructionAssistant?.firstName) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.constructionAssistant?.firstName} ${store?.storeContact?.constructionAssistant?.lastName}`
                      );
                    }
                  } else if (val.value === "coordinator") {
                    if (store?.storeContact?.coordinator?.firstName) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.coordinator?.firstName} ${store?.storeContact?.coordinator?.lastName}`
                      );
                    }
                  } else if (val.value === "collectionsRepresentative") {
                    if (
                      store?.storeContact?.collectionsRepresentative?.firstName
                    ) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.collectionsRepresentative?.firstName} ${store?.storeContact?.collectionsRepresentative?.lastName}`
                      );
                    }
                  } else if (val.value === "auditor") {
                    if (store?.storeContact?.auditor?.firstName) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.auditor?.firstName} ${store?.storeContact?.auditor?.lastName}`
                      );
                    }
                  } else if (val.value === "franchiseSalesManager") {
                    if (store?.storeContact?.franchiseSalesManager) {
                      setJobTypePersonNum(
                        `${store?.storeContact?.franchiseSalesManager?.firstName} ${store?.storeContact?.franchiseSalesManager?.lastName}`
                      );
                    }
                  }
                  // setStoreInfo({ ...storeInfo, satelliteNo: val.value });
                }}
              />
              <IconButton onClick={() => setContactFlag(true)}>
                <ArrowCircleRightIcon
                  color="primary"
                  size="large"
                  fontSize="60"
                />
              </IconButton>
            </div>
            <div className="g-r-2">
              <TextField
                className="subs-text-field"
                label="Person"
                value={jobTypePersonNum ? jobTypePersonNum : ""}
              />
            </div>
          </div>
          <span className="title" style={{ marginTop: "10px" }}>
            Misc
          </span>
          <div className="address-details">
            <div className="g-r-2 move-to-top">
              <TextField
                label="Currency"
                className="subs-text-field"
                value={misc.currency || ""}
                onChange={(event) => {
                  setChangedStoreDetails({
                    ...changedStoreDetails,
                    currency: event.target.value,
                  });

                  setStoreDetails({
                    ...storeDetails,
                    misc: {
                      ...misc,
                      currency: event.target.value,
                    },
                  });
                }}
              />
              <div className="f-r-2">
                <Select
                  placeholder="Store Owners"
                  options={storeOwnersList}
                  onChange={(e) => {
                    setStoreOwnerValue(e.value);
                    const editOwner = storeDetails?.store?.owner.find(
                      (ele) => ele?.id?.id === e.value
                    );
                    setEditOwnerObj(editOwner);
                  }}
                  value={
                    typeof storeOwnerValue === "object"
                      ? storeOwnerValue
                      : storeOwnerValue
                      ? storeOwnerValue.value
                      : null
                  }
                  classNamePrefix="custom_select"
                />
                <IconButton onClick={() => setStoreOwnerFlag(true)}>
                  <ArrowCircleRightIcon
                    color="primary"
                    size="large"
                    fontSize="60"
                  />
                </IconButton>
              </div>
            </div>
            <div className="g-r-2">
              <TextField
                label="Location Type"
                className="subs-text-field"
                value={misc.locationType || ""}
                onChange={(event) => {
                  setChangedStoreDetails({
                    ...changedStoreDetails,
                    locationType: event.target.value,
                  });

                  setStoreDetails({
                    ...storeDetails,
                    misc: {
                      ...misc,
                      locationType: event.target.value,
                    },
                  });
                }}
              />
              <TextField
                label="Ad Market"
                className="subs-text-field"
                value={misc.adMarket || ""}
                onChange={(event) => {
                  setChangedStoreDetails({
                    ...changedStoreDetails,
                    adMarket: event.target.value,
                  });

                  setStoreDetails({
                    ...storeDetails,
                    misc: {
                      ...misc,
                      adMarket: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={store?.exception || false}
                    onChange={() => {
                      onStoreDEtailsChange(!store?.exception, "exception");
                    }}
                  />
                }
                label="Exception"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={store?.auditHold || false}
                    onChange={() => {
                      onStoreDEtailsChange(!store?.auditHold, "auditHold");
                    }}
                  />
                }
                label="Audit Hold"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={store?.hasActiveNote || false}
                    onChange={() => {
                      onStoreDEtailsChange(
                        !store?.hasActiveNote,
                        "hasActiveNote"
                      );
                    }}
                  />
                }
                label="Has a active note"
                labelPlacement="end"
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="GST"
                className="subs-text-field"
                value={store?.gst || ""}
                onChange={(e) => {
                  onStoreDEtailsChange(e.target.value, "gst");
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid xs={4}>
          <div>
            <span className="title"></span>
            <div className="address-details">
              <div className="g-r-2">
                <TextField
                  label="Initial Open"
                  type="date"
                  className="subs-text-field"
                  value={dateFun(dates?.initialOpeningDate)}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setChangedStoreDetails({
                      ...changedStoreDetails,
                      initialOpeningDate: event.target.value,
                    });
                    setStoreDetails({
                      ...storeDetails,
                      dates: {
                        ...dates,
                        initialOpeningDate: event.target.value,
                      },
                    });
                  }}
                />
                <TextField
                  className="subs-text-field"
                  label="Transfer Date"
                  value={dateFun(dates.transferDate || "")}
                  onChange={(event) => {
                    setChangedStoreDetails({
                      ...changedStoreDetails,
                      transferDate: event.target.value,
                    });
                    setStoreDetails({
                      ...storeDetails,
                      dates: {
                        ...dates,
                        transferDate: event.target.value,
                      },
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
              </div>

              <div className="g-r-2">
                <TextField
                  className="subs-text-field"
                  label="Reopen Date"
                  value={dateFun(dates.lastReopeningDate || "")}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setChangedStoreDetails({
                      ...changedStoreDetails,
                      lastReopeningDate: event.target.value,
                    });
                    setStoreDetails({
                      ...storeDetails,
                      dates: {
                        ...dates,
                        lastReopeningDate: event.target.value,
                      },
                    });
                  }}
                  type="date"
                />
                <TextField
                  className="subs-text-field"
                  label="Temp Closing"
                  value={dateFun(dates.temporaryClosingDate || "")}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setChangedStoreDetails({
                      ...changedStoreDetails,
                      temporaryClosingDate: event.target.value,
                    });
                    setStoreDetails({
                      ...storeDetails,
                      dates: {
                        ...dates,
                        temporaryClosingDate: event.target.value,
                      },
                    });
                  }}
                  type="date"
                />
              </div>

              <div>
                {store?.type === "COCO_ACQUIRED" ? (
                  <div className="g-r-2">
                    <TextField
                      className="subs-text-field"
                      label="Acquisition Date"
                      value={dateFun(store?.acquisitionDate || "")}
                      onChange={(event) => {
                        onStoreDEtailsChange(
                          event.target.value,
                          "acquisitionDate"
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 2.5 }}
                      type="date"
                    />
                    <div>
                      {" "}
                      <label
                        htmlFor="closingReason"
                        style={{ fontSize: "smaller" }}
                      >
                        Temp Closing Reason
                      </label>
                      <Select
                        id="closingReason"
                        classNamePrefix="custom_select"
                        placeholder="Temp Closing Reason"
                        value={
                          dates?.temporaryClosingReason
                            ? {
                                label: dates.temporaryClosingReason,
                                value: dates.temporaryClosingReason,
                              }
                            : null
                        }
                        options={CLOSING_REASON}
                        onChange={(event) => {
                          setChangedStoreDetails({
                            ...changedStoreDetails,
                            temporaryClosingReason: event.value,
                          });
                          setStoreDetails({
                            ...storeDetails,
                            dates: {
                              ...dates,
                              temporaryClosingReason: event.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label
                      htmlFor="closingReason"
                      style={{ fontSize: "smaller" }}
                    >
                      Temp Closing Reason
                    </label>
                    <Select
                      id="closingReason"
                      classNamePrefix="custom_select"
                      placeholder="Temp Closing Reason"
                      value={
                        dates?.temporaryClosingReason
                          ? {
                              label: dates.temporaryClosingReason,
                              value: dates.temporaryClosingReason,
                            }
                          : null
                      }
                      options={CLOSING_REASON}
                      onChange={(event) => {
                        setChangedStoreDetails({
                          ...changedStoreDetails,
                          temporaryClosingReason: event.value,
                        });
                        setStoreDetails({
                          ...storeDetails,
                          dates: {
                            ...dates,
                            temporaryClosingReason: event.value,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>

              {store?.status === "Store Closed" && (
                <div className="g-r-2" style={{ marginTop: "-10px" }}>
                  <TextField
                    className="subs-text-field"
                    label="Final Closing Date"
                    value={dateFun(dates.finalClosedDate || "")}
                    onChange={(event) => {
                      setChangedStoreDetails({
                        ...changedStoreDetails,
                        finalClosedDate: event.target.value,
                      });
                      setStoreDetails({
                        ...storeDetails,
                        dates: {
                          ...dates,
                          finalClosedDate: event.target.value,
                        },
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                  />
                  <TextField
                    label="Anticipated Reopening"
                    className="subs-text-field"
                    value={dateFun(dates.anticipatedReopeningDate || "")}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={(event) => {
                      setChangedStoreDetails({
                        ...changedStoreDetails,
                        anticipatedReopeningDate: event.target.value,
                      });
                      setStoreDetails({
                        ...storeDetails,
                        dates: {
                          ...dates,
                          anticipatedReopeningDate: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
              )}
              {store?.status === "Store Closed" && (
                <div className="g-r-2">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        checked={dates?.finalClosingPending || false}
                        onChange={(event) => {
                          setChangedStoreDetails({
                            ...changedStoreDetails,
                            finalClosingPending: !dates.finalClosingPending,
                          });
                          setStoreDetails({
                            ...storeDetails,
                            dates: {
                              ...dates,
                              finalClosingPending: !dates.finalClosingPending,
                            },
                          });
                        }}
                      />
                    }
                    label="Final Closing Pending"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Occupancy"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    size="small"
                    value={dateFun(dates.occupancy || "")}
                    onChange={(event) => {
                      setChangedStoreDetails({
                        ...changedStoreDetails,
                        occupancy: event.target.value,
                      });
                      setStoreDetails({
                        ...storeDetails,
                        dates: {
                          ...dates,
                          occupancy: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
              )}
              {dates?.temporaryClosingReason === "Remodel" &&
                store?.status === "Store Closed" && (
                  <div className="g-r-2" style={{ paddingTop: "10px" }}>
                    <TextField
                      label="Equipment Funds Recd Date"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      value={dateFun(dates.equipmentFundsRecdDate || "")}
                      onChange={(event) => {
                        setChangedStoreDetails({
                          ...changedStoreDetails,
                          equipmentFundsRecdDate: event.target.value,
                        });
                        setStoreDetails({
                          ...storeDetails,
                          dates: {
                            ...dates,
                            equipmentFundsRecdDate: event.target.value,
                          },
                        });
                      }}
                    />
                    <TextField
                      label="Equipment Order Date"
                      value={dateFun(dates?.equipmentOrderDate || "")}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      onChange={(event) => {
                        setChangedStoreDetails({
                          ...changedStoreDetails,
                          equipmentOrderDate: event.target.value,
                        });
                        setStoreDetails({
                          ...storeDetails,
                          dates: {
                            ...dates,
                            equipmentOrderDate: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              {dates?.temporaryClosingReason === "Remodel" &&
                store?.status === "Store Closed" && (
                  <div className="g-r-2" style={{ paddingTop: "10px" }}>
                    <TextField
                      label="Drawing One Page Date"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      value={dateFun(dates.drawingOnePageDate || "")}
                      onChange={(event) => {
                        setChangedStoreDetails({
                          ...changedStoreDetails,
                          drawingOnePageDate: event.target.value,
                        });
                        setStoreDetails({
                          ...storeDetails,
                          dates: {
                            ...dates,
                            drawingOnePageDate: event.target.value,
                          },
                        });
                      }}
                    />
                    <TextField
                      label="Drawing Full Set Date"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      value={dateFun(dates.drawingFullSetDate || "")}
                      onChange={(event) => {
                        setChangedStoreDetails({
                          ...changedStoreDetails,
                          drawingFullSetDate: event.target.value,
                        });
                        setStoreDetails({
                          ...storeDetails,
                          dates: {
                            ...dates,
                            drawingFullSetDate: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Button
        size="small"
        variant="contained"
        sx={{ float: "right", mr: 2 }}
        onClick={updateDetails}
        disabled={
          !checkValidStoreCombination(
            storeInfo.storeNo,
            storeInfo.satelliteNo,
            storeInfo.sequenceNo
          )
        }
        startIcon={
          <SaveIcon style={{ fontSize: "15px", marginRight: "-5px" }} />
        }
      >
        Save
      </Button>
      <Drawer
        anchor="right"
        open={addressFlag}
        onClose={() => setAddressFlag(false)}
      >
        <AddAddress
          addressTypes={addressTypes}
          onSave={(details) => {
            toast.success("Saved Successfully", { autoClose: 2000 });
            setAddressValue({});
            getStoreDetailsById(storeInfo.storeNo);
            setAddressFlag(false);
          }}
          storeNo={store?.storeNo}
          addressDetails={address || {}}
          countries={regionList.country}
          onClose={() => setAddressFlag(false)}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={communicationFlag}
        onClose={() => setCommunicationFlag(false)}
      >
        <AddCommunication
          communicationTypes={communicationTypes}
          onSave={(details) => {
            setCommunicationFlag(false);
            getStoreDetailsById(storeInfo.storeNo);
            setCommunicationValue("");
            toast.success("Saved Successfully", { autoClose: 2000 });
          }}
          storeNo={store?.storeNo}
          details={communication}
          onClose={() => setCommunicationFlag(false)}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={storeOwnerFlag}
        onClose={() => setStoreOwnerFlag(false)}
      >
        <StoreOwner
          storeNo={store?.storeNo}
          onClose={() => setStoreOwnerFlag(false)}
          editOwner={storeOwnerValue}
          searchDetails={storeInfo}
          storeDetails={store}
          editOwnerObj={editOwnerObj}
          onSave={(details) => {
            setStoreDetails((prevVal) => ({
              ...prevVal,
              store: { ...store, owner: details },
            }));
            setStoreOwnerFlag(false);
          }}
        ></StoreOwner>
      </Drawer>
      <Drawer
        anchor="right"
        open={contactFlag}
        onClose={() => setContactFlag(false)}
      >
        <AddContact
          satelliteNo={storeInfo?.satelliteNo}
          sequenceNo={storeInfo?.sequenceNo}
          onSave={(details) => {
            setStoreDetails({
              ...storeDetails,
              contacts: details,
            });
            setContactFlag(false);
          }}
          storeNo={storeInfo?.storeNo}
          details={store.storeContact}
          onClose={() => setContactFlag(false)}
        />
      </Drawer>
    </div>
  );
};

export default StoreDetails;
