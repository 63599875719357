import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.scss";
import { Button, Grid, TextField } from "@mui/material";
import Select from "react-select";
import { invokeApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";

import { checkValidStoreCombination } from "../../utils";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const TransferDetails = () => {
  const [respData, setRespData] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [transferID, setTransferID] = useState(null);
  const [transferDetails, setTransferDetails] = useState({});
  const previousController = useRef();
  const [satelliteList, setSatelliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [personOptions, setPersonOptions] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
      buyerPersonNum: transferDetails.buyerPersonNum,
      transferType: transferDetails.transferType,
      transferStatusType: transferDetails.status,
      locationType: transferDetails.locationType,
      transferAppliedDate: transferDetails.transferAppliedDate,
      transferCompletedDate: transferDetails.transferCompletedDate,
      transferCancelledDate: transferDetails.transferCancelledDate,
      proposedClosingDate: transferDetails.proposedClosingDate,
      contractInfo: {
        contractDate: transferDetails.contractDate,
      },
      initiationInfo: {
        initiator: transferDetails.initiator,
        fileReceivedDate: transferDetails.fileReceivedDate,
        initiatorDate: transferDetails.initiatorDate,
        approverDate: transferDetails.approverDate,
        initiationFollowUpDate: transferDetails.initiationFollowUpDate,
        approvalGrantedDate: transferDetails.approvalGrantedDate,
        initiationCompletedDate: transferDetails.initiationCompletedDate,
      },
      researchInfo: {
        researcher: transferDetails.researcher,
        researcherDate: transferDetails.researcherDate,
        researcherFollowUpDate: transferDetails.researcherFollowUpDate,
        researcherCompletedDate: transferDetails.researcherCompletedDate,
      },
      consentInfo: {
        consentor: transferDetails.consentor,
        consentorDate: transferDetails.consentorDate,
        consentorFollowUpDate: transferDetails.consentorFollowUpDate,
        consentCompletedDate: transferDetails.consentCompletedDate,
      },
      processorInfo: {
        processor: transferDetails.processor,
        processorDate: transferDetails.processorDate,
        processorFollowUpDate: transferDetails.processorFollowUpDate,
      },
      checkerInfo: {
        checker: transferDetails.checker,
        checkerDate: transferDetails.checkerDate,
      },
      closerInfo: {
        closer: transferDetails.closer,
        preClosingDate: transferDetails.preClosingDate,
        preclosingCompleteDate: transferDetails.preClosingCompleteDate,
        proposedClosingDate: transferDetails.proposedClosingDate,
        storeClosingDate: transferDetails.storeClosingDate,
        takeOverDate: transferDetails.takeOverDate,
        closingMemoSent: transferDetails.closingMemoSentDate,
        documentsReceived: transferDetails.documentsReceivedDate,
        closingsFollowup: transferDetails.closingFollowUpDate,
      },
      transferFee: transferDetails.transferFee
        ? parseInt(transferDetails.transferFee)
        : 0,
      transferFeeRecieved: transferDetails.transferFeeRecieved
        ? parseInt(transferDetails.transferFeeRecieved)
        : 0,
      currency: transferDetails.currency,
    };

    if (transferID && !isCreate) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_TRANSFER_DETAILS + transferID,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully", { autoClose: 2000 });
            clearData(false);
          } else toast.error("Update Failed");
        })
        .catch((error) => {
          toast.error("Update Failed", { autoClose: 2000 });
        });
    } else {
      let maxSeqValue = sequenceList?.map((ele) => ele.value);
      maxSeqValue = maxSeqValue.sort((x, y) => y - x);
      if (body.storeNo) {
        if (body.satelliteNo !== null) {
          if (body.buyerPersonNum) {
            invokeApi(
              HTTP_METHODS.POST,
              HOSTNAME + REST_URLS.POST_TRANSFER_DETAILS,
              { ...body, sequenceNo: parseInt(maxSeqValue) + 1 }
            )
              .then((res) => {
                if (res.id) {
                  toast.success("Created Successfully", { autoClose: 2000 });
                  clearData(false);
                  setIsCreate(false);
                } else {
                  toast.error("Create Failed", { autoClose: 2000 });
                  setIsCreate(false);
                  clearData(false);
                }
              })
              .catch((error) => {
                toast.error("Create Failed", { autoClose: 2000 });
              });
          } else {
            toast.error("Buyer is required", {
              autoClose: 2000,
            });
          }
        } else {
          toast.error("Satellite Number is required", {
            autoClose: 2000,
          });
        }
      } else {
        toast.error("Store Number is required", {
          autoClose: 2000,
        });
      }
    }
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return val;
      }
    }
  };

  const stateUpdate = (updatedValues) => {
    setTransferDetails((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  const apply = (storeNo) => {
    setIsCreate(false);
    let curStore = storeNo;
    if (typeof curStore === "string") {
      curStore = parseInt(curStore);
    }
    if (typeof curStore === "number") {
      invokeApi(
        HTTP_METHODS.GET,
        HOSTNAME + REST_URLS.STORE_TRANSFER_DETAILS + storeNo,
        null,
        {
          satelliteNo: storeInfo.satelliteNo,
          sequenceNo: storeInfo.sequenceNo,
        }
      )
        .then((res = {}) => {
          if (!res?.message) {
            let obj = {};
            const { franchiseAgreement, storeTransfer } = res;
            setRespData(res);
            if (franchiseAgreement) {
              obj["franchisor"] = franchiseAgreement?.franchisor;
              obj["franchiseAgreement"] = franchiseAgreement?.businessName;
              obj["agreementAssigned"] = moment(
                franchiseAgreement?.assigned
              ).format("yyyy-MM-DD");
              obj["acknowledgement"] = moment(
                franchiseAgreement?.agreement
              ).format("yyyy-MM-DD");
              obj["redisclosure"] = moment(
                franchiseAgreement?.disclosure
              ).format("yyyy-MM-DD");
              obj["ofac"] = moment(franchiseAgreement?.ofac).format(
                "yyyy-MM-DD"
              );
            }

            if (storeTransfer) {
              obj["storeStatus"] = storeTransfer?.store?.status;
              let data = storeTransfer?.storeTransfer;
              obj["status"] = data?.transferStatusType;
              obj["processorDate"] = data?.processorInfo?.processorDate;
              obj["sequenceNo"] = data?.sequenceNo.toString();
              obj["contractDate"] = data?.contractInfo?.contractDate;
              obj["storeNo"] = data?.storeNo;
              obj["transferType"] = data?.transferType;
              obj["locationType"] = data?.locationType;
              obj["preClosingDate"] = dateFun(data?.closerInfo?.preClosingDate);
              obj["preClosingCompleteDate"] = dateFun(
                data?.closerInfo?.preclosingCompleteDate
              );
              obj["proposedClosingDate"] = dateFun(
                data?.closerInfo?.proposedClosingDate
              );
              obj["storeClosingDate"] = dateFun(
                data?.closerInfo?.storeClosingDate
              );
              obj["takeOverDate"] = dateFun(data?.closerInfo?.takeOverDate);
              obj["closingMemoSentDate"] = dateFun(
                data?.closerInfo?.closingMemoSent
              );
              obj["documentsReceivedDate"] = dateFun(
                data?.closerInfo?.documentsReceived
              );
              obj["closingFollowUpDate"] = dateFun(
                data?.closerInfo?.closingsFollowup
              );
              obj["transferAppliedDate"] = dateFun(data?.transferAppliedDate);
              obj["researcherDate"] = dateFun(
                data?.researchInfo?.researcherDate
              );
              obj["researcherFollowUpDate"] = dateFun(
                data?.researchInfo?.researcherFollowUpDate
              );
              obj["researcherCompletedDate"] = dateFun(
                data?.researchInfo?.researcherCompletedDate
              );
              obj["consentorDate"] = dateFun(data?.consentInfo?.consentorDate);
              obj["checkerDate"] = dateFun(data?.checkerInfo?.checkerDate);
              obj["consentorFollowUpDate"] = dateFun(
                data?.consentInfo?.consentorFollowUpDate
              );
              obj["consentCompletedDate"] = dateFun(
                data?.consentInfo?.consentCompletedDate
              );
              obj["fileReceivedDate"] = dateFun(
                data?.initiationInfo?.fileReceivedDate
              );
              obj["initiatorDate"] = dateFun(
                data?.initiationInfo?.initiatorDate
              );
              obj["approverDate"] = dateFun(data?.initiationInfo?.approverDate);
              obj["initiationFollowUpDate"] = dateFun(
                data?.initiationInfo?.initiationFollowUpDate
              );
              obj["approvalGrantedDate"] = dateFun(
                data?.initiationInfo?.approvalGrantedDate
              );
              obj["initiationCompletedDate"] = dateFun(
                data?.initiationInfo?.initiationCompletedDate
              );
              obj["processorFollowUpDate"] = dateFun(
                data?.processorInfo?.processorFollowUpDate
              );
              obj["transferCancelledDate"] = dateFun(
                data?.transferCancelledDate
              );
              obj["transferCompletedDate"] = dateFun(
                data?.transferCompletedDate
              );
              obj["transferFee"] = data?.transferFee;
              obj["transferFeeRecieved"] = data?.transferFeeRecieved;
              obj["initiator"] = data?.initiationInfo?.initiator;
              obj["researcher"] = data?.researchInfo?.researcher;
              obj["consentor"] = data?.consentInfo?.consentor;
              obj["checker"] = data?.checkerInfo?.checker;
              obj["processor"] = data?.processorInfo?.processor;
              obj["closer"] = data?.closerInfo?.closer;
              obj["currency"] = data?.currency;
              obj["buyerPersonNum"] = data?.buyerPersonNum;
              setTransferID(storeTransfer.storeTransfer.id);
            } else {
              toast.info("No Transfer is Available for the selected Store", {
                autoClose: 2000,
              });
            }
            stateUpdate(obj);
          } else {
            if (res?.message === "Store Transfer not found") {
              toast.info("No Transfer is Available for the selected Store", {
                autoClose: 2000,
              });
              clearData(false);
            } else {
              toast.error(res?.message, { autoClose: 2000 });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
    setTransferID(null);
    setTransferDetails({});
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...transferDetails };
    tempState[key] = value;
    setTransferDetails(tempState);
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value.trim()) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        sequenceNo: "",
        satelliteNo: "",
      });
    } else {
      clearData();
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNo };
      });
      setStoreOptions(updatedOptions);
    });
  };

  useEffect(() => {
    const validStoreCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (validStoreCombination) {
      apply(storeInfo.storeNo);
    }
    if (storeInfo.storeNo) listingLeaseHandler(storeInfo.storeNo);
  }, [storeInfo.satelliteNo, storeInfo.sequenceNo]);

  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`,
      null,
      { isTransfer: true }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPersonData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    let searchID = "_id/";
    if (searchTerm) {
      axios({
        method: HTTP_METHODS.GET,
        url: HOSTNAME + REST_URLS.PERSON_SEARCH + searchID + searchTerm,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        signal: signal,
      }).then((response) => {
        const updatedOptions = response.data.map((p) => {
          let title;
          title = p.id;
          return { title, id: p.id };
        });
        setPersonOptions(updatedOptions);
      });
    }
  };

  const setBuyerInputOnChange = (event, value, fieldName) => {
    if (value?.trim()) {
      getPersonData(value?.trim());
    } else {
      setPersonOptions([]);
    }
    setTransferDetails((prevDetails) => ({
      ...prevDetails,
      buyerPersonNum: value ? value : "",
    }));
  };

  return (
    <div className="trasfer-con">
      <Grid container md={12} sx={{ mt: 1, mb: 1 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            className="subs-autocomplete"
            getOptionLabel={(option) => {
              return option.title.toString();
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  storeNo: val.id,
                }));
                listingLeaseHandler(val.id);
                const validStoreCombination = checkValidStoreCombination(
                  val.id,
                  storeInfo.satelliteNo,
                  storeInfo.sequenceNo
                );
                if (validStoreCombination) {
                  apply(val.id);
                }
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            className="sel-item"
            placeholder="Satellite"
            // className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val.value,
              }));
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val.value,
                }));
              }}
            />
          )}
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3} xs={6} style={{ height: "35px" }}>
          <span
            style={{
              // backgroundColor: "#80808033",
              display: "flex",
              padding: "5px",
              borderRadius: "5px",
              float: "right",
              marginTop: "-5px",
            }}
          >
            {/* <Button
              size="small"
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => {
                setTransferDetails({ storeNo: null });
                setTransferID(null);
                setStoreInput("");
                if (isCreate) {
                  setIsCreate(false);
                }
              }}
            >
              {isCreate ? "Cancel" : "Reset"}
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{ mr: 2 }}
              disabled={
                isCreate
                  ? transferDetails.storeNo !== "" &&
                    transferDetails.satelliteNo1 == ""
                  : !checkValidStoreCombination(
                      transferDetails.storeNo,
                      transferDetails.sequenceNo,
                      transferDetails.satelliteNo
                    )
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button> */}
            {!isCreate && (
              <div style={{ display: "flex" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setTransferID(null);
                    setIsCreate(true);
                    clearData(false);
                  }}
                >
                  Create New
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    excelDownload(
                      "store_transfer_details_" + storeInfo.storeNo,
                      respData
                    );
                  }}
                  style={{ marginLeft: "20px" }}
                  disabled={respData === null}
                  startIcon={
                    <DownloadRoundedIcon
                      style={{ fontSize: "20px", marginRight: "-5px" }}
                    />
                  }
                >
                  Download
                </Button>
              </div>
            )}
          </span>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={2} sx={{ display: "grid", gridGap: "10px" }}>
          <div className="title header">Types and Statuses</div>
          <TextField
            label="Type"
            variant="outlined"
            fullWidth
            id="type"
            className="sel-item subs-text-field"
            value={transferDetails.transferType || ""}
            name="transferType"
            onChange={handleChange}
          />
          <TextField
            label="Status"
            variant="outlined"
            fullWidth
            id="store"
            className="sel-item subs-text-field"
            value={transferDetails.status || ""}
            name="status"
            onChange={handleChange}
          />
          <TextField
            label="Store Status"
            fullWidth
            className="sel-item subs-text-field"
            id="store-status"
            value={transferDetails.storeStatus || ""}
            name="storeStatus"
            disabled={true}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            className="sel-item subs-text-field"
            label="Location Type"
            id="location-type"
            value={transferDetails.locationType || ""}
            name="locationType"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            className="sel-item subs-text-field"
            label="Franchisor"
            id="franchisor"
            disabled
            value={transferDetails.franchisor || ""}
            name="franchisor"
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div className="title header">Closing Information</div>
          <div className="g-r-2">
            <div>
              <p className="input-title">With Closer-Pre Closing</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.preClosingDate || ""}
                name="preClosingDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Pre Closing Complete</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.preClosingCompleteDate || ""}
                name="preClosingCompleteDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">Proposed Closing</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.proposedClosingDate || ""}
                name="proposedClosingDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Store Closing</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.storeClosingDate || ""}
                name="storeClosingDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">Take Over</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.takeOverDate || ""}
                name="takeOverDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Closing Memo Sent</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.closingMemoSentDate || ""}
                name="closingMemoSentDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">Documents Received</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.documentsReceivedDate || ""}
                name="documentsReceivedDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Closings Follow-up</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.closingFollowUpDate || ""}
                name="closingFollowUpDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">Transfer Applied</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.transferAppliedDate || ""}
                name="transferAppliedDate"
                onChange={handleChange}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>
            <div className="title header">Research Information</div>
          </div>
          <div>
            <p className="input-title">With Researcher</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.researcherDate || ""}
              name="researcherDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Research Follow-up</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.researcherFollowUpDate || ""}
              name="researcherFollowUpDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Research Complete</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.researcherCompletedDate || ""}
              name="researcherCompletedDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <div className="title header">Contract Information</div>
            <div>
              <p className="input-title">Contract Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.contractDate || ""}
                name="contractDate"
                onChange={handleChange}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              {/* <Select placeholder="Currency" className="sel-item" classNamePrefix="custom_select" /> */}
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                label="Currency"
                id="Currency"
                value={transferDetails.currency || ""}
                name="currency"
                onChange={handleChange}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div className="title header">Consent Information</div>
          <div>
            <p className="input-title">With Consentor</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.consentorDate || ""}
              name="consentorDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">With Checker</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.checkerDate || ""}
              name="checkerDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Consent Follow-up</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.consentorFollowUpDate || ""}
              name="consentorFollowUpDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Consent Complete</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.consentCompletedDate || ""}
              name="consentCompletedDate"
              onChange={handleChange}
            />
          </div>
          <div style={{ height: "62px" }}></div>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div className="title header">Additional Dates</div>
          <TextField
            label="Franchise Agreement"
            variant="outlined"
            fullWidth
            className="sel-item subs-text-field"
            disabled
            value={transferDetails.franchiseAgreement || ""}
          />
          <TextField
            label="Agreement Assigned"
            variant="outlined"
            fullWidth
            className="sel-item subs-text-field"
            disabled
            value={transferDetails.agreementAssigned || ""}
          />
          <div>
            <p className="input-title">Acknowledgement</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              disabled
              type="date"
              value={transferDetails.acknowledgement || ""}
            />
          </div>
          <div>
            <p className="input-title">Redisclosure</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              disabled
              type="date"
              value={transferDetails.redisclosure || ""}
            />
          </div>
          <div>
            <p className="input-title">OFAC</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.ofac || ""}
              disabled
            />
          </div>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid
          item
          md={4}
          sx={{
            display: "grid",
            gridGap: "10px",
            marginTop: "5px",
          }}
        >
          <div className="title header">Initiation Information</div>
          <div className="g-r-2">
            <div>
              <p className="input-title">File Received</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.fileReceivedDate || ""}
                name="fileReceivedDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">With Initiator</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.initiatorDate || ""}
                name="initiatorDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">With Approver</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.approverDate || ""}
                name="approverDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Initiation Follow-up</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.initiationFollowUpDate || ""}
                name="initiationFollowUpDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <div>
              <p className="input-title">Approval Granted</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.approvalGrantedDate || ""}
                name="approvalGrantedDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="input-title">Initiation Complete</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetails.initiationCompletedDate || ""}
                name="initiationCompletedDate"
                onChange={handleChange}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <div className="title header pb10">Processing Information</div>
          <div className="pb10">
            <p className="input-title">With Processor</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.processorDate || ""}
              name="processorDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Processor Follow-up</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.processorFollowUpDate || ""}
              name="processorFollowUpDate"
              onChange={handleChange}
            />
          </div>
          <div className="title header pb10">Buyer Information</div>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            options={personOptions}
            onInputChange={setBuyerInputOnChange}
            inputValue={transferDetails.buyerPersonNum || ""}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonOptions([]);
                }}
              />
            }
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Person" />
            )}
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <div className="title header">Contacts</div>
          {/* <div className="g-r-2">
            <TextField
              label="Developer"
              
              variant="outlined"
              fullWidth
              id="developer"
              className="sel-item"
            />
          </div> */}
          <div className="g-r-2">
            <TextField
              label="Initiator"
              variant="outlined"
              fullWidth
              id="initiator"
              className="sel-item subs-text-field" 
              value={transferDetails.initiator || ""}
              name="initiator"
              onChange={handleChange}
            />
            <TextField
              label="Checker"
              variant="outlined"
              fullWidth
              id="checker"
              className="sel-item subs-text-field"
              value={transferDetails.checker || ""}
              name="checker"
              onChange={handleChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Researcher"
              variant="outlined"
              fullWidth
              id="researcher"
              className="sel-item subs-text-field"
              value={transferDetails.researcher || ""}
              name="researcher"
              onChange={handleChange}
            />
            {/* <Select placeholder="Closer" className="sel-item" classNamePrefix="custom_select" /> */}
            <TextField
              label="Closer"
              variant="outlined"
              fullWidth
              id="closer"
              className="sel-item subs-text-field"
              value={transferDetails.closer || ""}
              name="closer"
              onChange={handleChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Consentor"
              variant="outlined"
              fullWidth
              id="consentor"
              className="sel-item subs-text-field"
              value={transferDetails.consentor || ""}
              name="consentor"
              onChange={handleChange}
            />
            <TextField
              label="Processor"
              variant="outlined"
              fullWidth
              id="processor"
              className="sel-item subs-text-field"
              value={transferDetails.processor || ""}
              name="processor"
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <div>
            <p className="input-title">Transfer Cancelled</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.transferCancelledDate || ""}
              name="transferCancelledDate"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-title">Transfer Complete</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetails.transferCompletedDate || ""}
              name="transferCompletedDate"
              onChange={handleChange}
            />
          </div>
          <TextField
            label="Transfer Fee"
            variant="outlined"
            fullWidth
            type="number"
            className="sel-item subs-text-field"
            value={transferDetails.transferFee || ""}
            name="transferFee"
            onChange={handleChange}
          />
          <TextField
            label="Transfer Fee Received"
            variant="outlined"
            fullWidth
            type="number"
            className="sel-item subs-text-field"
            value={transferDetails.transferFeeRecieved || ""}
            name="transferFeeRecieved"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <span
        style={{
          // backgroundColor: "#80808033",
          display: "flex",
          padding: "5px",
          borderRadius: "5px",
          float: "right",
          marginTop: 2,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => {
            clearData(isCreate ? false : true);
            if (isCreate) {
              setIsCreate(false);
            }
          }}
        >
          {isCreate ? "Cancel" : "Reset"}
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ mr: 2 }}
          disabled={
            !isCreate
              ? !checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  storeInfo.satelliteNo
                )
              : false
          }
          onClick={() => {
            update();
          }}
        >
          Save
        </Button>
      </span>
    </div>
  );
};

export default TransferDetails;
