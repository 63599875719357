import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";

/** @format */
const intranetService = `/v1/central-service/`;

export const REST_URLS = {
  LOGIN: "/v1/docu-service/auth/login",
  LOGOUT: `${intranetService}auth/logout`,
  REFRESH_TOKEN: `${intranetService}/auth/refresh-tokens-app`,
  REGISTER: "/v1/docu-service/auth/register",
  GET_RESTAURANTS: "/v1/docu-service/restaurants",
  SAVE_DOCUMENT: "/v1/docu-service/docs/save-document",
  UPDATE_UPLOAD_DOCUMENT: "/v1/docu-service/docs/update-document",
  UPLOAD_DOC: "/v1/docu-service/docs/upload-document",
  DOCS_FETCH: "/v1/docu-service/docs/fetch",
  DOCS_STATS: "/v1/docu-service/docs/stats/",
  LIST_COUNTRY_DATA:
    "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json",
  LIST_ALL_RESTAURANT_IDS: `${intranetService}stores/by-store-in-batch`,
  TABLE_STATS: "/v1/docu-service/docs/table-stats-data",
  GET_COMPANY_DOCS: "/v1/docu-service/docs/fetch-company-document",
  GET_ONE_UPLOAD_DOCUMENT: "/v1/docu-service/docs/upload-document",
  SAVE_COMPANY_DOCUMENTS: "/v1/docu-service/docs/save-company-document",
  UPDATE_COMPANY_DOCUMENT: "/v1/docu-service/docs/update-company-document/",
  LIST_REPORTS: "/v1/docu-service/report/store-wise-reports",
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
};
export const redirectToRoute = `${getProjectEndPoint(
  endPointsForProject.docuStorage
)}/upload`;
