import React from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";
import {
  checkAndGrantUserAccess,
  mainRedirectToIntranetPortal,
  makeTokenObject,
} from "../utils/common-utils";
import MainContainer from "../../../container";
import { userStates } from "../utils/constants";
import { useState, useEffect } from "react";
import { getOfflineData } from "../utils/offline-services";

const PrivateRoute = ({ tokenKey, slug }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(userStates.isProcessing);
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");

  const setUserRemoveParams = (userDetails) => {
    if (userDetails && !userDetails?.message) {
      setUser(userDetails);
    }
    setSearchParams({});
  };

  useEffect(() => {
    const areTokensPresent = !!access && !!refresh;
    const userDetails = getOfflineData(tokenKey, "user");
    if (areTokensPresent) {
      const tokens = makeTokenObject(
        access,
        accessTokenExpiry,
        refresh,
        refreshTokenExpiry
      );
      checkAndGrantUserAccess(
        tokenKey,
        slug,
        tokens,
        setUserRemoveParams,
        null,
        true
      );
    } else if (userDetails) {
      setUser(userDetails);
    } else {
      mainRedirectToIntranetPortal(tokenKey);
    }
  }, [access, refresh]);
  return user && user !== userStates.isProcessing ? (
    <MainContainer>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
