import { getLabelsAndValuesForSelect } from "../../../../common-utilities/utils";
import { allRegions } from "../../../../common-utilities/utils/region";

export const getStateList = (country, convertToSelectValues = true) => {
  if (!country || !allRegions[country]) {
    return [];
  }
  const countryDataWithStateKeys = allRegions[country];
  let states = Object.keys(countryDataWithStateKeys);
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(states, states);
  }
  return states;
};

export const getCityListByStateAndCountry = (
  country,
  state,
  convertToSelectValues = true
) => {
  if (!allRegions?.[country]?.[state]) {
    return [];
  }
  const cityValues = allRegions?.[country]?.[state];
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(cityValues, cityValues);
  }
  return cityValues;
};

export const setSelectFiltersOnChange = (
  e,
  details,
  setDataFunction,
  setCityList
) => {
  const { name } = details;
  const isNameRegion = name === "state";
  const isValuePresent = !!e;
  const isCitySettingFunctionPresent = !!setCityList;
  if (isNameRegion) {
    if (isValuePresent && isCitySettingFunctionPresent) {
      const newCityList = getCityListByStateAndCountry("India", e.value);
      setCityList(newCityList);
    } else {
      setCityList([]);
    }
  }
  setDataFunction((prevFilters) => {
    if (isNameRegion) {
      delete prevFilters.city;
    }
    return { ...prevFilters, [name]: e, page: 1 };
  });
};
