import React, { useEffect, useState } from "react";
import "./index.scss";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CommonBtn from "../../core/common-button";
import { confirmDocument } from "./apis";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const PdfView = ({ pdf, pdfDetails, setPdfDetails, slug }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfChecked, setPdfChecked] = useState(false);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    setPdfChecked(false);
  }, [pdfDetails]);

  return (
    <div className="main-pdf-container">
      <div className="wrap">
        <div className="controls">
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            onContextMenu={(e) => e.preventDefault()}
            className="pdf-container"
          >
            <Page pageNumber={pageNumber} scale={1.5} />
          </Document>
        </div>
        <div className="pdf-btn-con">
          <Button
            onClick={prevPage}
            disabled={pageNumber === 1}
            variant="outlined"
            sx={{ mr: 4, mb: 2 }}
          >
            <ArrowBackIosIcon fontSize="medium" />
          </Button>
          <Button
            onClick={nextPage}
            disabled={pageNumber === numPages}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            <ArrowForwardIosIcon fontSize="medium" />
          </Button>
        </div>
      </div>
      {pdfDetails?.notConfirmed && (
        <div className="confirm-policy">
          <div className="i-agreeTxt">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pdfChecked}
                    // defaultChecked
                    onChange={(event) => {
                      setPdfChecked(event.target.checked);
                    }}
                  />
                }
                label="I hereby confirm, that I have read and understood details as
                mentioned above policy and adhere to it"
              />
            </FormGroup>
          </div>
          <CommonBtn
            onClick={() => {
              confirmDocument(pdfDetails, setPdfDetails, slug);
            }}
            disabled={!pdfChecked}
            text="Agree"
          />
        </div>
      )}
    </div>
  );
};

export default PdfView;
