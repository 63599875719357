import { exportToExcel } from "../../../common-utilities/utils";
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../utils/constants";
import { processExcelReport } from "./utils";

export const downloadSumryRept = (data) => {
  const results = data?.map?.((item) => {
    const convertedItem = { ...item };
    delete convertedItem.snapShot;
    delete convertedItem.uploadedDocuments;
    const {
      BDPOC,
      LegalPOC,
      ProjectsPOC,
      ads,
      cluster,
      deliveryPercentage,
      ebitdaPercentage,
      isApproved1,
      isApproved2,
      isApproved3,
      isApproved4,
      isApproved5,
      isApproved6,
      labourPercentage,
      occupancyPercentage,
      restaurantId,
      totalCapex,
      restaurantName,
      paybackInMonths,
      approver1Comment,
      approver2Comment,
      approver3Comment,
      approver4Comment,
      approver5Comment,
      approver6Comment,
      approvedDates,
      createdAt,
      storeType,
      rejectedBy,
      rejectedAt,
      businessDetails,
    } = item;

    const isCocoAcq = storeType === storeTypes.cocoAcquired;
    const keys = isCocoAcq ? { ...cocoAcqKeyNames } : { ...cocoBuiltKeyNames };

    const detailsConfig = {
      approverDetails: {
        "Approver 1 Date (Ranjeet Thakur)": approvedDates?.["Approver1 Date"],
        "Approver 2 Date (Dev)": approvedDates?.["Approver2 Date"],
        "Approver 3 Date (Rajat M)": approvedDates?.["Approver3 Date"],
        "Approver 4 Date (Sidhharth Arora)": approvedDates?.["Approver4 Date"],
        "Approver 5 Date (Nitin M)": approvedDates?.["Approver5 Date"],
        "Approver 6 Date (Tarun Bhasin)": approvedDates?.["Approver6 Date"],
        "Approver 1 (Ranjeet Thakur)": isApproved1 ? "Yes" : "Pending",
        "Approver 2 (Dev)": isApproved2 ? "Yes" : "Pending",
        "Approver 3 (Rajat M)": isApproved3 ? "Yes" : "Pending",
        "Approver 4 (Sidhharth Arora)": isApproved4 ? "Yes" : "Pending",
        "Approver 5 (Nitin M)": isApproved5 ? "Yes" : "Pending",
        "Approver 6 (Tarun Bhasin)": isApproved6 ? "Yes" : "Pending",
        "Approver1 (Ranjeet Thakur) Comment": approver1Comment,
        "Approver2 (Dev) Comment": approver2Comment,
        "Approver3 (Rajat M) Comment": approver3Comment,
        "Approver4 (Sidhharth Arora) Comment": approver4Comment,
        "Approver5 (Nitin M) Comment": approver5Comment,
        "Approver6 (Tarun Bhasin) Comment": approver6Comment,
      },
    };

    if (isCocoAcq) {
      detailsConfig.approverDetails = {
        "Approver 1 Date (Pulkit)": approvedDates?.["Approver1 Date"],
        "Approver 2 Date (Regional Ops Head)":
          approvedDates?.["Approver2 Date"],
        "Approver 3 Date (Rajat)": approvedDates?.["Approver3 Date"],
        "Approver 4 Date (Rajesh M)": approvedDates?.["Approver4 Date"],
        "Approver 5 Date (Nitin M)": approvedDates?.["Approver5 Date"],
        "Approver 6 Date (Dev)": approvedDates?.["Approver6 Date"],
        "Approver 1 (Pulkit)": isApproved1 ? "Yes" : "Pending",
        "Approver 2 (Regional Ops Head)": isApproved2 ? "Yes" : "Pending",
        "Approver 3 (Rajat)": isApproved3 ? "Yes" : "Pending",
        "Approver 4 (Rajesh M)": isApproved4 ? "Yes" : "Pending",
        "Approver 5 (Nitin M)": isApproved5 ? "Yes" : "Pending",
        "Approver 6 (Dev)": isApproved6 ? "Yes" : "Pending",
        "Approver1 (Pulkit) Comment": approver1Comment,
        "Approver2 (Regional Ops Head) Comment": approver2Comment,
        "Approver3 (Rajat) Comment": approver3Comment,
        "Approver4 (Rajesh M) Comment": approver4Comment,
        "Approver5 (Nitin M) Comment": approver5Comment,
        "Approver6 (Dev) Comment": approver6Comment,
      };
    }

    return {
      "Store Code": restaurantId,
      "Restaurant Name": restaurantName,
      Region: cluster,
      "Creation Date": createdAt,
      Type: storeType,
      ...detailsConfig.approverDetails,
      "Rejected By": rejectedBy?.name,
      "Rejected At": rejectedAt,
      [keys.ads]: ads,
      [keys.deliveryPercentage]: deliveryPercentage,
      [keys.occupancyPercentage]: occupancyPercentage,
      [keys.labourPercentage]: labourPercentage,
      [keys.ebitdaPercentage]: ebitdaPercentage,
      [keys.totalCapex]: totalCapex,
      "Payback (in months)": paybackInMonths,
      BDPOC,
      ProjectsPOC,
      LegalPOC,
    };
  });
  exportToExcel(
    results,
    "Documents",
    null,
    null,
    (workbook, worksheet, sheetName, headers) => {
      processExcelReport(workbook, worksheet, sheetName, headers, results);
    }
  );
};
