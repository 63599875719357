import { useState } from 'react';
import { useEffect } from 'react';
import CustomModal from '../../../../../common-utilities/core/modal';
import { createUpdateApprovalMapping, getCategoryList } from '../apis';
import { setProperty } from '../../../../../common-utilities/utils/misc';
import { Button } from '@mui/material';
import { setEditCreateData } from '../utils';
import MainFields from './sub-components/fields';
import './index.scss';

const EditCreateModal = ({ showModal, onClose, data, title, onSuccess }) => {
  const [config, setConfig] = useState({
    catList: [],
  });
  const [modalData, setModalData] = useState({});
  useEffect(() => {
    getCategoryList((list) => {
      setProperty('catList', list, setConfig);
    });
  }, []);

  useEffect(() => {
    setEditCreateData(data, setModalData);
  }, [data]);

  return (
    <>
      {showModal && (
        <CustomModal
          classesToPreventClosing={['MuiAutocomplete-option']}
          title={title}
          onClose={onClose}
        >
          <div className="finance-cf-mapping">
            <MainFields
              modalData={modalData}
              config={config}
              setModalData={setModalData}
            />
            <div className="update-crt-btns">
              <Button
                onClick={() => {
                  createUpdateApprovalMapping(modalData, onSuccess);
                }}
                variant="outlined"
              >
                {modalData?.id ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default EditCreateModal;
