import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Grid, TextareaAutosize, TextField } from "@mui/material";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const TransferDetails2 = () => {
  const [respData, setRespData] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [transferID, setTransferID] = useState(null);
  const [satelliteList, setSatelliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [personOptions, setPersonOptions] = useState([]);
  const [transferDetailsPage2, setTransferDetailsPage2] = useState({});

  const previousController = useRef();

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...transferDetailsPage2 };
    tempState[key] = value;
    setTransferDetailsPage2(tempState);
  };

  const stateUpdate = (updatedValues) => {
    setTransferDetailsPage2((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  const apply = (storeNo) => {
    const invalidCombination = !checkValidStoreCombination(
      storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );

    if (invalidCombination) {
      return;
    }

    invokeApi(
      HTTP_METHODS.GET,
      HOSTNAME + REST_URLS.STORE_TRANSFER_DETAILS + storeNo,
      null,
      {
        satelliteNo: storeInfo.satelliteNo,
        sequenceNo: storeInfo.sequenceNo,
      }
    ).then((res) => {
      if (res?.message) {
        if (res?.message === "Store Transfer not found") {
          toast.info("No Transfer is Available for the selected Store", {
            autoClose: 2000,
          });
        } else {
          toast.error(res?.message, { autoClose: 2000 });
        }
        return;
      }
      setRespData(res);
      let obj = {};
      obj["postalCode"] = res?.franchiseAgreement?.zipCode;
      obj["type"] = res?.franchiseAgreement?.addressType;
      obj["state"] = res?.franchiseAgreement?.state;
      obj["county"] = res?.franchiseAgreement?.county;
      obj["city"] = res?.franchiseAgreement?.city;
      obj["country"] = res?.franchiseAgreement?.country;
      obj["attention"] = res?.franchiseAgreement?.attn;
      obj["address1"] = res?.franchiseAgreement?.address3;
      obj["street"] = res?.franchiseAgreement?.address2;
      obj["buyerPersonNum"] = res?.storeTransfer?.storeTransfer?.buyerPersonNum;
      obj["locatedIn"] = res?.franchiseAgreement?.address1;
      obj["comType"] = res?.communication?.communicationType;
      obj["comDetails"] = res?.communication?.value;
      obj["sublease"] = res?.storeTransfer?.storeTransfer?.sublease?.sublease;
      obj["subleaseDate"] = dateFun(
        res?.storeTransfer?.storeTransfer?.sublease?.subleaseDate
      );
      obj["landLord"] = res?.storeTransfer?.storeTransfer?.landlord?.name;
      obj["landLordText"] =
        res?.storeTransfer?.storeTransfer?.landlord?.landlordTextForLetters;
      obj["tenant"] = res?.storeTransfer?.storeTransfer?.landlord?.tenant;
      obj["leaseDate"] = dateFun(
        res?.storeTransfer?.storeTransfer?.landlord?.leaseDate
      );
      obj["initialTerm"] =
        res?.storeTransfer?.storeTransfer?.landlord?.initialTerm;
      obj["leaseInReNegotiation"] =
        res?.storeTransfer?.storeTransfer?.landlord?.leaseInRegeneration;
      obj["schoolLunch"] =
        res?.storeTransfer?.storeTransfer?.indicators?.schoolLunch;
      obj["coBrand"] = res?.storeTransfer?.storeTransfer?.indicators?.coBrand;
      obj["audit"] = res?.storeTransfer?.storeTransfer?.indicators?.audit;
      obj["equipmentLease"] =
        res?.storeTransfer?.storeTransfer?.indicators?.equipmentLease;
      obj["posBillingDate"] = dateFun(
        res?.storeTransfer?.storeTransfer?.indicators?.nextPOSBillingDate
      );
      setTransferID(res?.storeTransfer?.storeTransfer?.id);
      stateUpdate(obj);
    });
  };

  const checkBoxChange = (name) => {
    let tempState = { ...transferDetailsPage2 };
    tempState[name] = tempState[name] === true ? false : true;
    setTransferDetailsPage2(tempState);
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
    setStoreOptions([]);
    setTransferDetailsPage2({});
  };

  const onInputChange = (event, value, reason) => {
    if (value?.trim()) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        sequenceNo: "",
        satelliteNo: "",
      });
    } else {
      clearData();
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
      buyerPersonNum: transferDetailsPage2.buyerPersonNum,
      landlord: {
        name: transferDetailsPage2.landLord,
        landlordTextForLetters: transferDetailsPage2.landLordText,
        tenant: transferDetailsPage2.tenant,
        leaseDate: transferDetailsPage2.leaseDate,
        initialTerm: transferDetailsPage2.initialTerm,
        leaseInRegeneration: transferDetailsPage2.leaseInReNegotiation,
      },
      indicators: {
        schoolLunch: transferDetailsPage2.schoolLunch,
        coBrand: transferDetailsPage2.coBrand,
        audit: transferDetailsPage2.audit,
        equipmentLease: transferDetailsPage2.equipmentLease,
        nextPOSBillingDate: transferDetailsPage2.posBillingDate,
      },
      sublease: {
        sublease: transferDetailsPage2.sublease,
        subleaseDate: transferDetailsPage2.subleaseDate,
      },
    };

    if (transferID && !isCreate) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_TRANSFER_DETAILS + transferID,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully", { autoClose: 2000 });
            clearData(false);
          } else {
            toast.error("Update Failed", { autoClose: 2000 });
            clearData(false);
          }
        })
        .catch((error) => {
          toast.error("Update Failed", { autoClose: 2000 });
        });
    } else {
      let maxSeqValue = sequenceList?.map((ele) => ele.value);
      maxSeqValue = maxSeqValue.sort((x, y) => y - x);
      if (body.storeNo) {
        if (body.satelliteNo !== null) {
          if (body.buyerPersonNum) {
            invokeApi(
              HTTP_METHODS.POST,
              HOSTNAME + REST_URLS.POST_TRANSFER_DETAILS,
              { ...body, sequenceNo: parseInt(maxSeqValue) + 1 }
            )
              .then((res) => {
                if (res.id) toast.success("Created Successfully");
                else toast.error("Create Failed");
                clearData(false);
                setIsCreate(false);
              })
              .catch((error) => {
                toast.error("Create Failed");
                clearData(false);
                setIsCreate(false);
              });
          } else {
            toast.error("Buyer is required", {
              autoClose: 2000,
            });
          }
        } else {
          toast.error("Satellite Number is required", {
            autoClose: 2000,
          });
        }
      } else {
        toast.error("Store Number is required", {
          autoClose: 2000,
        });
      }
    }
  };
  useEffect(() => {
    if (
      storeInfo.storeNo &&
      storeInfo.satelliteNo !== undefined &&
      !isNaN(parseInt(storeInfo.sequenceNo))
    ) {
      apply(storeInfo.storeNo);
    }
    if (storeInfo.storeNo) listingLeaseHandler(storeInfo.storeNo);
  }, [storeInfo.satelliteNo, storeInfo.sequenceNo]);

  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`,
      null,
      { isTransfer: true }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPersonData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    let searchID = "_id/";
    if (searchTerm) {
      axios({
        method: HTTP_METHODS.GET,
        url: HOSTNAME + REST_URLS.PERSON_SEARCH + searchID + searchTerm,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        signal: signal,
      }).then((response) => {
        const updatedOptions = response.data.map((p) => {
          let title;
          title = p.id;
          return { title, id: p.id };
        });
        setPersonOptions(updatedOptions);
      });
    }
  };

  const setBuyerInputOnChange = (event, value, fieldName) => {
    if (value?.trim()) {
      getPersonData(value?.trim());
    } else {
      setPersonOptions([]);
    }
    setTransferDetailsPage2((prevDetails) => ({
      ...prevDetails,
      buyerPersonNum: value ? value : "",
    }));
  };
  return (
    <div className="trasfer-con">
      <Grid container md={12} sx={{ mt: 1, mb: 2 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                listingLeaseHandler(val.id);
                // let temp = {};
                // temp["storeNo"] = val.id;
                // temp["satellite"] = val.satellite;
                // setTransferDetailsPage2(temp);
                // getAllSequenceNumber(val.id); todo
                setTransferDetailsPage2((prevVal) => ({
                  ...prevVal,
                  storeNo: val.id,
                }));
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            className="sel-item"
            placeholder="Satellite"
            // className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val.value,
              }));
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val.value,
                }));
              }}
            />
          )}
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3} xs={6} style={{ height: "35px" }}>
          <span
            style={{
              display: "flex",
              padding: "5px",
              borderRadius: "5px",
              float: "right",
              marginTop: "-5px",
            }}
          >
            {!isCreate && (
              <div style={{ display: "flex" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    clearData(false);
                    setTransferID(null);
                    setIsCreate(true);
                  }}
                >
                  Create New
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    excelDownload(
                      "store_transfer_details_" + transferDetailsPage2.storeNo,
                      respData
                    );
                  }}
                  disabled={respData === null}
                  startIcon={
                    <DownloadRoundedIcon
                      style={{ fontSize: "20px", marginRight: "-5px" }}
                    />
                  }
                >
                  Download
                </Button>
              </div>
            )}
          </span>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={4} className="add-gap">
          <div className="title header">Leasing Information</div>
          <TextField
            label="Landlord"
            variant="outlined"
            fullWidth
            className="subs-text-field"
            sx={{ width: "94%" }}
            value={transferDetailsPage2.landLord || ""}
            name="landLord"
            onChange={handleChange}
          />
          <TextareaAutosize
            placeholder="Landloard Text For Letters"
            minRows={2}
            style={{ width: "93%", maxWidth: "95%", marginTop: "1em" }}
            value={transferDetailsPage2.landLordText || ""}
            name="landLordText"
            onChange={handleChange}
          />
          <div className="g-r-2">
            <TextField
              label="Tenant"
              variant="outlined"
              fullWidth
              className="sel-item subs-text-field"
              value={transferDetailsPage2.tenant || ""}
              name="tenant"
              onChange={handleChange}
            />
            <div>
              <p className="input-title">Lease Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetailsPage2.leaseDate || ""}
                name="leaseDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-r-2">
            <TextField
              label="Initial Term"
              variant="outlined"
              fullWidth
              className="sel-item subs-text-field"
              value={transferDetailsPage2.initialTerm || ""}
              name="initialTerm"
              onChange={handleChange}
            />
            <div>
              <Checkbox
                checked={transferDetailsPage2.leaseInReNegotiation || false}
                onChange={() => {
                  checkBoxChange("leaseInReNegotiation");
                }}
              />
              <span className="input-title-paragrah">
                Lease In Renegotiation
              </span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} className="add-gap">
          <div className="title header">Address Details</div>
          <div className="g-r-2">
            {/* <Select isSearchable placeholder="Type" classNamePrefix="custom_select" /> */}
            <TextField
              id="type"
              label="Type"
              fullWidth
              variant="outlined"
              className="subs-text-field"
              value={transferDetailsPage2.type || ""}
              name="type"
              onChange={handleChange}
              disabled
            />
            <TextField
              id="attention"
              label="Attention"
              fullWidth
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.attention || ""}
              disabled
              name="attention"
              onChange={handleChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="located-in"
              label="Located In"
              fullWidth
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.locatedIn || ""}
              disabled
              name="locatedIn"
              onChange={handleChange}
            />
            <TextField
              id="street/po-box"
              label="Street/PO Box"
              fullWidth
              value={transferDetailsPage2.street || ""}
              disabled
              name="street"
              className="subs-text-field"
              onChange={handleChange}
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="address1"
              label="Address 1"
              fullWidth
              variant="outlined"
              className="subs-text-field"
              value={transferDetailsPage2.address1 || ""}
              disabled
              name="address1"
              onChange={handleChange}
            />
            <TextField
              id="county"
              label="county"
              fullWidth
              className="subs-text-field"
              value={transferDetailsPage2.county || ""}
              disabled
              name="county"
              onChange={handleChange}
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="city"
              label="City"
              fullWidth
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.city || ""}
              disabled
              name="city"
              onChange={handleChange}
            />
            <TextField
              id="postal-code"
              label="Postal Code"
              fullWidth
              type="text"
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.postalCode || ""}
              disabled
              name="postalCode"
              onChange={handleChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="state"
              label="State/Prov"
              fullWidth
              type="text"
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.state || ""}
              name="state"
              disabled
              onChange={handleChange}
            />
            <TextField
              id="country"
              label="Country"
              fullWidth
              type="text"
              className="subs-text-field"
              variant="outlined"
              value={transferDetailsPage2.country || ""}
              disabled
              name="country"
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item md={2} className="add-gap">
          <div className="title header" style={{ marginLeft: "12px" }}>
            Indicators
          </div>
          <div>
            <Checkbox
              checked={transferDetailsPage2.schoolLunch || false}
              onChange={() => {
                checkBoxChange("schoolLunch");
              }}
            />
            <span className="input-title-paragrah">School Lunch</span>
          </div>
          <div>
            <Checkbox
              checked={transferDetailsPage2.coBrand || false}
              onChange={() => {
                checkBoxChange("coBrand");
              }}
            />
            <span className="input-title-paragrah">Co-Brand</span>
          </div>
          <div>
            <Checkbox
              checked={transferDetailsPage2.audit || false}
              onChange={() => {
                checkBoxChange("audit");
              }}
            />
            <span className="input-title-paragrah">Audit</span>
          </div>
          <div>
            <Checkbox
              checked={transferDetailsPage2.equipmentLease || false}
              onChange={() => {
                checkBoxChange("equipmentLease");
              }}
            />
            <span className="input-title-paragrah">Equipment Lease</span>
          </div>
          <div className="shift-date">
            <p className="input-title bold">Next POS Billing Date</p>
            <TextField
              fullWidth
              className="sel-item subs-text-field"
              type="date"
              value={transferDetailsPage2.posBillingDate || ""}
              name="posBillingDate"
              onChange={handleChange}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container md={12} sx={{ mt: "2em" }}>
        <Grid item md={4} className="add-gap">
          <div className="title header">Transfer Assignment of Sublease</div>
          <div>
            <TextField
              label="Sublease"
              variant="outlined"
              fullWidth
              className="sel-item subs-text-field"
              value={transferDetailsPage2.sublease}
              name="sublease"
              onChange={handleChange}
            />
            <br />
            <br />
            <div>
              <p className="input-title">Sublease Date</p>
              <TextField
                fullWidth
                className="sel-item subs-text-field"
                type="date"
                value={transferDetailsPage2.subleaseDate}
                name="subleaseDate"
                onChange={handleChange}
              />
            </div>
          </div>
        </Grid>
        <Grid item md={3} className="add-gap">
          <div className="title header">Communication Details</div>
          <div>
            <div>
              <TextField
                id="details"
                label="Details"
                fullWidth
                className="subs-text-field"
                variant="outlined"
                value={transferDetailsPage2.comType || ""}
                disabled
                name="comType"
                onChange={handleChange}
              />
              {/* <Select isSearchable placeholder="Type" classNamePrefix="custom_select" /> */}
            </div>
            <br />
            <br />
            <div>
              <TextField
                id="details"
                label="Details"
                fullWidth
                className="subs-text-field"
                variant="outlined"
                value={transferDetailsPage2.comDetails || ""}
                disabled
                name="comDetails"
                onChange={handleChange}
              />
            </div>
          </div>
        </Grid>
        <Grid item md={3} sx={{ ml: 2 }}>
          <div className="title header">Buyer Information</div>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            options={personOptions}
            onInputChange={setBuyerInputOnChange}
            inputValue={transferDetailsPage2.buyerPersonNum || ""}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonOptions([]);
                }}
              />
            }
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Person" />
            )}
          />
        </Grid>
      </Grid>
      <span
        style={{
          display: "flex",
          padding: "5px",
          borderRadius: "5px",
          float: "right",
          marginTop: "-5px",
        }}
      >
        <Button
          size="small"
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => {
            clearData(isCreate ? false : true);
            if (isCreate) {
              setIsCreate(false);
            }
            // setTransferID(null);
            // setIsCreate(true);
          }}
        >
          {isCreate ? "Cancel" : "Reset"}
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={
            !isCreate
              ? !checkValidStoreCombination(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  storeInfo.satelliteNo
                )
              : false
          }
          onClick={() => {
            update();
          }}
        >
          Save
        </Button>
      </span>
    </div>
  );
};

export default TransferDetails2;
