import CustomModal from "../../core/modal";
import "./index.scss";
import AutocompleteComponent from "../../core/styled-autocomplete";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { setProperty } from "../../utils/misc";
import CommonBtn from "../../core/common-button";
import { disableDocSave, getParentList, setData } from "./utils-2";
import { createUpdateAppDocCat } from "./apis";
import { FormControlLabel, Checkbox } from "@mui/material";
import { getAllDepartmentNames } from "../../../../components/admin-screen/apis";
import AutoCompleteMultiSelect from "../../core/autocomplete-multiselect";
// When showModal is true, we show the modal
const CreateUpdateCategory = ({
  showModal,
  data,
  title,
  closeModal,
  slug,
  onSuccess,
  departmentMapping,
}) => {
  const [formDetails, setformDetails] = useState({});
  const [dropDownLists, setDropDownLists] = useState({
    parent: [],
    departments: [],
  });
  useEffect(() => {
    if (!showModal) {
      setformDetails({});
      return;
    }
  }, [showModal]);

  useEffect(() => {
    getParentList(showModal, formDetails, slug, (parentList) =>
      setProperty("parent", parentList, setDropDownLists)
    );
  }, [showModal, formDetails?.id]);

  useEffect(() => {
    setData(data, setformDetails);
  }, [data]);

  useEffect(() => {
    // Department list needs to be fetched only when we are showing the modal and departmentMapping is allowed for the app
    if (!departmentMapping || !showModal) {
      return;
    }
    getAllDepartmentNames((departmentList) =>
      setProperty("departments", departmentList, setDropDownLists)
    );
  }, [departmentMapping, showModal]);

  return (
    <>
      {showModal && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title={title}
          onClose={closeModal}
          contentClassName={{
            headerBackgroundColor: "#008952",
          }}
        >
          <div className="create-update-category">
            <FormControlLabel
              value="start"
              checked={!!formDetails?.isSubcategory}
              className="createNewParentlabel"
              control={
                <Checkbox
                  color="primary"
                  onChange={() => {
                    setProperty(
                      ["isSubcategory", "parent"],
                      [!formDetails?.isSubcategory, null],
                      setformDetails
                    );
                  }}
                />
              }
              label="Is this a Subcategory ?"
              labelPlacement="start"
            />
            {formDetails?.isSubcategory && (
              <AutocompleteComponent
                label={`Select Parent`}
                onChange={(e, val) =>
                  setProperty("parent", val?.value || null, setformDetails)
                }
                options={dropDownLists.parent}
                className="modal-field"
                name={`parent`}
                value={
                  dropDownLists.parent.find(
                    (e) => e.value === formDetails.parent
                  ) || null
                }
              />
            )}

            <div>
              <TextField
                label="Name"
                type="text"
                fullWidth
                className="new-textfield modal-field"
                size="small"
                variant="outlined"
                name="name"
                value={formDetails?.name || ""}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setProperty(name, value, setformDetails);
                }}
              />
            </div>

            {departmentMapping && (
              <div>
                <AutoCompleteMultiSelect
                  options={dropDownLists.departments}
                  onChange={(_, val) => {
                    setProperty("departments", val, setformDetails);
                  }}
                  value={formDetails?.departments || []}
                  placeholder="Select Category Departments"
                />
              </div>
            )}

            <div className="end-btns">
              <div>
                <CommonBtn
                  disabled={disableDocSave(formDetails)}
                  onClick={() => {
                    createUpdateAppDocCat(formDetails, slug, onSuccess);
                  }}
                  showSaveIcon={true}
                />
              </div>
              <div>
                <CommonBtn
                  variant="outlined"
                  text="Cancel"
                  onClick={closeModal}
                />
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default CreateUpdateCategory;
