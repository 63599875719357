import { Autocomplete, TextField } from "@mui/material";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import ClearIcon from "@mui/icons-material/Clear";
import { plainApi, HTTP_METHODS } from "../../utils/http-service";
import { useState } from "react";
import { appSlugs } from "../../../../utils/constants";

export const StoreAutoCompleteComponent = ({
  callBack,
  slug,
  token,
  clearValue,
  disabled = false,
}) => {
  const [storeList, setStoreList] = useState([]);
  const [storeVal, setStoreVal] = useState(null);

  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    let params = {
      storeNumber: searchTerm,
    };
    if (isNaN(searchTerm)) {
      delete params.storeNumber;
      const splittedName = searchTerm?.split?.(" - ");
      params.restaurantName = splittedName?.[1]
        ? splittedName?.[1]
        : splittedName?.[0];
    }
    if (slug === appSlugs.vendorInvoiceSubmission) {
      params.storeType = "COCO";
    }
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        params,
        slug,
        token
      ).then((res) => {
        const storeList = res?.results?.map((ele) => {
          let storeNo = ele?.storeNumber;
          if (ele?.restaurantName) {
            storeNo += ` - ${ele?.restaurantName}`;
          }
          return {
            storeNo,
            id: ele?.id,
            storeNumber: ele?.storeNumber,
          };
        });
        setStoreList(storeList);
      });
    }, 300);
  };

  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    } else {
      typeof callBack === "function" && callBack(value?.storeNo);
    }
  };

  return (
    <>
      <Autocomplete
        getOptionLabel={(option) => option?.storeNo}
        className="poper-select"
        disabled={disabled}
        value={storeVal}
        clearIcon={
          <ClearIcon
            fontSize="small"
            onClick={() => {
              typeof clearValue === "function" && clearValue();
              setStoreList([]);
            }}
          />
        }
        options={storeList}
        renderInput={(params) => (
          <TextField {...params} size="small" label="Store" />
        )}
        onInputChange={storeInputChange}
        onChange={(event, value) => {
          setStoreVal(value);
          if (value) {
            typeof callBack === "function" &&
              callBack(value?.storeNumber, value?.id);
          }
        }}
      />
    </>
  );
};
