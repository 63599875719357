import { Button } from '@mui/material';
import CustomModal from '../../../../common-utilities/core/modal';
import { getGRNOrderAmountWithTax } from '../grn-details/helper';

const GrnCreatedModal = ({ grnDetails, pendingWith }) => {
  const data = grnDetails?.data;

  return (
    <>
      {data && (
        <CustomModal title={grnDetails?.title} disabledOutsideClick={true}>
          <div className="po-info-container">
            <div className="po-info-section">
              <span>GRN No. </span>
              <span>{data?.grnNo}</span>
            </div>
            <div className="po-info-section">
              <span>PO Number </span>
              <span>{data?.poNumber}</span>
            </div>

            <div className="po-info-section">
              <span>Total GRN Amount </span>
              <span>
                {getGRNOrderAmountWithTax(
                  data?.poDetails?.items,
                  data?.receivedItems
                )}
              </span>
            </div>

            <div className="po-info-section">
              <span>Vendor Name </span>
              <span>{data?.poDetails?.vendorName}</span>
            </div>

            <div className="po-info-section">
              <span>Vendor Code </span>
              <span>{data?.poDetails?.vendorCode}</span>
            </div>

            <Button
              fullWidth
              variant="outlined"
              onClick={grnDetails?.onOk}
              size="small"
            >
              Ok
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default GrnCreatedModal;
