export const dateFilter = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const startYear = today.getMonth() >= 3 ? currentYear : currentYear - 1;
  const endYear = startYear + 1;

  const periods = {
    months: [
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
    ],
    quarters: ["Q1", "Q2", "Q3", "Q4"],
    financialYear: `FY ${startYear.toString().slice(2)}-${endYear
      .toString()
      .slice(2)}`,
  };

  const financialYearDates = periods.months.map((month, index) => {
    const year = index < 9 ? startYear : endYear;
    const startDate = new Date(year, index >= 9 ? index - 9 : index + 3, 1);
    const endDate = new Date(year, index >= 9 ? index - 8 : index + 4, 0);
    return {
      label: `${month}-${year.toString().slice(2)}`,
      value: `${month}-${year.toString().slice(2)}`,
      timePeriod: {
        startDate,
        endDate,
      },
    };
  });

  financialYearDates.push({
    label: periods.financialYear,
    value: periods.financialYear,
    timePeriod: {
      startDate: new Date(startYear, 3, 1),
      endDate: new Date(endYear, 2, 31),
    },
  });

  periods.quarters.forEach((quarter, index) => {
    const startQuarterMonth = index * 3;
    const endQuarterMonth = startQuarterMonth + 2;
    const startDate = new Date(
      startYear + Math.floor(startQuarterMonth / 12),
      (startQuarterMonth % 12) + 3,
      1
    );
    const endDate = new Date(
      startYear + Math.floor(endQuarterMonth / 12),
      (endQuarterMonth % 12) + 4,
      0
    );
    financialYearDates.push({
      label: `${quarter} ${periods.financialYear.slice(3)}`,
      value: `${quarter} ${periods.financialYear.slice(3)}`,
      timePeriod: {
        startDate,
        endDate,
      },
    });
  });

  return financialYearDates;
};
