export const profitAndLossSelectValues = {
  profitAndLossAll: "P&L All",
  year1ProfitAndLoss: "Yr1 P&L",
  rocePreTax: "ROCE (pre-tax)",
  paybackWithSdYears: "Payback with SD (Years)",
  paybackwithoutSDyears: "Payback w/o SD (Years)",
  irr5years: "IRR (5 years)",
  paybackYears: "Payback (Years)",
};

export const ttmSelectValues = {
  allTTMVsF1Y: "ALL TTM Vs F1Y",
};
