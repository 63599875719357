import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomBarChart from "./bar-chart";
import CustomPiChart from "./pi-chart";

const InfoOverview = ({ reportInfo, keyLabel, title }) => {
  const chartData = reportInfo[keyLabel] || [];

  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item md={6} xs={12}>
        {chartData.length > 0 && (
          <CustomBarChart
            title={title}
            data={[{ data: chartData.map((c) => c.count) }]}
            category={chartData.map((c) => c.name)}
          />
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        {chartData.length > 0 && (
          <CustomPiChart
            title={title}
            bDataKey="count"
            xDataKey="name"
            data={chartData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InfoOverview;
