import {
  accquiredPreAgreementDocsLabelsAndValues,
  agreementAndPostAgreementDocsLabelsAndValues,
  agreementAndPostAgreementDocsLabelsAndValuesCocoBuilt,
} from '../../../utils/coco-acquired.config';
import { preAgreementDocsLabelsAndValues as preAgreementDocsLabelsAndValuesForCocoBuilt } from '../../../utils/coco-built.config';
import {
  validDocumentTypes,
  validStoreTypes,
  backEndKeysToLabel,
  validDocumentTypesLabels,
  newNames,
} from '../../../utils/document-constants';
import { fieldValidation } from '../../../../common-utilities/utils/misc';

export const setDataForTables = (data, type, setViewDetails, setViewFiles) => {
  const { additionalDetails, storeType } = data;
  let details = [];
  let files = [];

  const {
    monthlyRentRevenueShareOnDineInStartYear,
    docs,
    startDate,
    endDate,
    monthlyRentMgStartYear,
    monthlyRentRevenueShareOnDeliveryInStartYear,
    landlord,
    licenseType,
    leaseAddress,
    fitOutPeriod,
    lockInPeriod,
    documentType,
    licenseName,
    cam,
    landlordInvestment,
    rsecdeposit,
    rsecdepositinmonths,
    rentRevShareDeliveryExcludesAggregatorsComission,
    leaseEffectiveDate,
    leaseEndDate,
    leaseSignDate,
    leaseType,
    licenseeFirm,
    licenseePartners,
    licensor,
    area,
    currentRent,
    locationAddress,
    lockInExpiryDate,
    renewalClause,
    rentEscalation,
    securityDeposit,
    stampedAndRegistered,
    na,
    nameOfLandload,
    address,
  } = additionalDetails;

  files = docs?.map((item) => {
    let label = backEndKeysToLabel[item.type] || item.type;

    if (
      storeType === validStoreTypes.cocoAcquired &&
      documentType ===
        accquiredPreAgreementDocsLabelsAndValues.projectFeasibility
    ) {
      label = newNames.tearSheet;
    }
    if (
      storeType === validStoreTypes.cocoAcquired &&
      documentType ===
        accquiredPreAgreementDocsLabelsAndValues.licenseFeasibility
    ) {
      label = newNames.taxReport;
    }
    if (
      storeType === validStoreTypes.fofo &&
      data.type === validDocumentTypes.subleaseAgreement
    ) {
      label = validDocumentTypes.leaseAgreement.split('_').join(' ');
    }
    return (
      {
        label,
        fileName: item.originalName,
        link: item.document,
      } || []
    );
  });

  switch (storeType) {
    case validStoreTypes.cocoAcquired:
      switch (data.type) {
        case validDocumentTypes.agreementAndPostAgreementDocs:
          switch (documentType) {
            case validDocumentTypesLabels.leaseAgreement:
            case validDocumentTypesLabels.optionAgreement:
              details = [
                { label: 'Sub Category', value: documentType },
                {
                  label: 'Monthly Rent - MG - Start year',
                  value: monthlyRentMgStartYear,
                },
                {
                  label: 'Monthly Rent - Revenue Share on Dine in - Start year',
                  value: !fieldValidation(
                    monthlyRentRevenueShareOnDineInStartYear
                  )
                    ? monthlyRentRevenueShareOnDineInStartYear
                    : '',
                },
                {
                  label:
                    'Monthly Rent - Revenue Share on Delivery in - Start year',
                  value: monthlyRentRevenueShareOnDeliveryInStartYear,
                },
                {
                  label: 'Landlord (Name GST & PAN)',
                  value: landlord,
                },
                {
                  label: 'Lease Address',
                  value: leaseAddress,
                },
                {
                  label: 'Monthly CAM',
                  value: cam,
                },
              ];

              documentType !== validDocumentTypesLabels.optionAgreement &&
                details.push({
                  label: 'Investment from Landlord(in Rs)',
                  value: landlordInvestment,
                });
              details.push(
                {
                  label: 'R Sec Deposit(in Rs)',
                  value: rsecdeposit,
                },
                {
                  label: 'R Sec deposit(in months)',
                  value: rsecdepositinmonths,
                },
                {
                  label:
                    'Rent Rev Share Delivery Excludes aggregators commission',
                  value: rentRevShareDeliveryExcludesAggregatorsComission,
                }
              );
              if (!na) {
                details.splice(
                  1,
                  0,
                  {
                    label:
                      documentType === validDocumentTypesLabels.optionAgreement
                        ? 'Start Date'
                        : 'Lease Start Date',
                    value: startDate,
                  },
                  {
                    label:
                      documentType === validDocumentTypesLabels.optionAgreement
                        ? 'Expiry Date'
                        : 'Lease End Date',
                    value: endDate,
                  }
                );
              }

              if (!fieldValidation(lockInPeriod)) {
                details.push({
                  label: 'Lock in period',
                  value: lockInPeriod,
                });
              }
              break;
            case agreementAndPostAgreementDocsLabelsAndValues.bta:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
              break;
            case agreementAndPostAgreementDocsLabelsAndValues.franchiseTermination:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
              break;
            case agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push({ label: 'Start Date', value: startDate });
              }
              break;
            case agreementAndPostAgreementDocsLabelsAndValues.deliveryMemo:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
              break;
            case agreementAndPostAgreementDocsLabelsAndValues.leaseTermination:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
              break;
            default:
              details = [{ label: 'Sub Category', value: documentType }];
              if (!na) {
                details.push(
                  { label: 'Start Date', value: startDate },
                  { label: 'Expiry Date', value: endDate }
                );
              }
          }
          break;
        case validDocumentTypes.preAgreementDocs:
          switch (documentType) {
            case validDocumentTypesLabels.LOI:
              details = [
                {
                  label: 'Sub Category',
                  value: documentType,
                },
              ];
              if (!na) {
                details.push({ label: 'LOI Date', value: startDate });
              }
              break;
            default:
              const docType =
                documentType ===
                accquiredPreAgreementDocsLabelsAndValues.projectFeasibility
                  ? newNames.tearSheet
                  : documentType ===
                    accquiredPreAgreementDocsLabelsAndValues.licenseFeasibility
                  ? newNames.taxReport
                  : documentType;
              details = [
                {
                  label: 'Sub Category',
                  value: docType,
                },
              ];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
          }

          break;
        case validDocumentTypes.license:
          details = [{ label: 'License Type', value: licenseType }];

          if (!na) {
            details.push(
              { label: 'Start Date', value: startDate },
              { label: 'End Date', value: endDate }
            );
          }
          if (licenseName) {
            details.push({
              label: 'Name of the license',
              value: licenseName,
            });
          }
          break;
        case validDocumentTypes.mfaRider:
          details = [{ label: 'Sub Category', value: documentType }];
          if (!na) {
            details.push(
              { label: 'Start Date', value: startDate },
              { label: 'Expiry Date', value: endDate }
            );
          }
          break;
        case validDocumentTypes.nocForGst:
          details = [];
          if (!na) {
            details.push(
              { label: 'Landload Name', value: nameOfLandload },
              { label: 'Address', value: address }
            );
          }
        case validDocumentTypes.electricityBillInNameOfLandload:
          details = [];
          if (!na) {
            details.push(
              { label: 'Landload Name', value: nameOfLandload },
              { label: 'Address', value: address }
            );
          }
          break;
      }
      break;
    case validStoreTypes.cocoBuilt:
      switch (data.type) {
        case validDocumentTypes.agreementAndPostAgreementDocs:
          switch (documentType) {
            case validDocumentTypesLabels.leaseAgreement:
            case validDocumentTypesLabels.optionAgreement:
              details = [
                { label: 'Category', value: documentType },
                {
                  label: 'Monthly Rent - MG - Start year',
                  value: monthlyRentMgStartYear,
                },
                {
                  label: 'Monthly Rent - Revenue Share on Dine in - Start year',
                  value: monthlyRentRevenueShareOnDineInStartYear,
                },
                {
                  label:
                    'Monthly Rent - Revenue Share on Delivery in - Start year',
                  value: monthlyRentRevenueShareOnDeliveryInStartYear,
                },
                {
                  label: 'Landlord (Name GST & PAN)',
                  value: landlord,
                },
                {
                  label: 'Lease Address',
                  value: leaseAddress,
                },
                {
                  label: 'Monthly CAM',
                  value: cam,
                },
              ];
              documentType !== validDocumentTypesLabels.optionAgreement &&
                details.push({
                  label: 'Investment from Landlord(in Rs)',
                  value: landlordInvestment,
                });
              details.push(
                {
                  label: 'R Sec Deposit(in Rs)',
                  value: rsecdeposit,
                },
                {
                  label: 'R Sec deposit(in months)',
                  value: rsecdepositinmonths,
                },
                {
                  label:
                    'Rent Rev Share Delivery Excludes aggregators commission',
                  value: rentRevShareDeliveryExcludesAggregatorsComission,
                }
              );

              if (!na) {
                details.splice(
                  1,
                  0,
                  {
                    label:
                      documentType === validDocumentTypesLabels.optionAgreement
                        ? 'Start Date'
                        : 'Lease Start Date',
                    value: startDate,
                  },
                  {
                    label:
                      documentType === validDocumentTypesLabels.optionAgreement
                        ? 'Expiry Date'
                        : 'Lease End Date',
                    value: endDate,
                  }
                );
              }

              if (!fieldValidation(fitOutPeriod)) {
                details.push({
                  label: 'Fit out period',
                  value: fitOutPeriod,
                });
              }
              if (!fieldValidation(lockInPeriod)) {
                details.push({
                  label: 'Lock in period',
                  value: lockInPeriod,
                });
              }
              break;

            default:
              details = [{ label: 'Category', value: documentType }];
              if (!na) {
                details.push({ label: 'Start Date', value: startDate });
              }
              if (
                documentType !==
                  agreementAndPostAgreementDocsLabelsAndValuesCocoBuilt.submissionOfRegHardCopy &&
                !na
              ) {
                details.push({
                  label: 'Expiry Date',
                  value: endDate,
                });
              }
          }
          break;
        case validDocumentTypes.preAgreementDocs:
          switch (documentType) {
            case validDocumentTypesLabels.LOI:
              details = [
                {
                  label: 'Sub Category',
                  value: documentType,
                },
              ];
              if (!na) {
                details.push({ label: 'LOI Date', value: startDate });
              }
              break;
            case preAgreementDocsLabelsAndValuesForCocoBuilt.possessionLetter:
              details = [
                {
                  label: 'Sub Category',
                  value: documentType,
                },
              ];
              if (!na) {
                details.push({
                  label: 'Letter Date',
                  value: startDate,
                });
              }
              break;
            default:
              details = [
                {
                  label: 'Sub Category',
                  value: documentType,
                },
              ];
              if (!na) {
                details.push({
                  label: 'Document Date',
                  value: startDate,
                });
              }
          }

          break;
        case validDocumentTypes.license:
          details = [{ label: 'License Type', value: licenseType }];

          if (!na) {
            details.push(
              { label: 'Start Date', value: startDate },
              { label: 'End Date', value: endDate }
            );
          }

          if (!fieldValidation(licenseName)) {
            details.push({
              label: 'Name of the license',
              value: licenseName,
            });
          }
          break;
        case validDocumentTypes.mfaRider:
          details = [{ label: 'Sub Category', value: documentType }];
          if (!na) {
            details.push(
              { label: 'Start Date', value: startDate },
              { label: 'Expiry Date', value: endDate }
            );
          }
          break;
        case validDocumentTypes.nocForGst:
          details = [];
          if (!na) {
            details.push(
              { label: 'Landload Name', value: nameOfLandload },
              { label: 'Address', value: address }
            );
          }
        case validDocumentTypes.electricityBillInNameOfLandload:
          details = [];
          if (!na) {
            details.push(
              { label: 'Landload Name', value: nameOfLandload },
              { label: 'Address', value: address }
            );
          }
          break;
      }
      break;
    case validStoreTypes.fofo:
      switch (data.type) {
        case validDocumentTypes.subleaseAgreement:
          details = [
            { label: 'Start Date', value: startDate },
            { label: 'End Date', value: endDate },
            { label: 'Licensor(Landlord)', value: licensor },
            { label: 'Licensee-Firm', value: licenseeFirm },
            { label: 'Licensee-Partners', value: licenseePartners },
            { label: 'Type of lease', value: leaseType },
            { label: 'Lease Sign Date', value: leaseSignDate },
            { label: 'Lease Effective Date', value: leaseEffectiveDate },
            { label: 'Lease End Date', value: leaseEndDate },
            { label: 'Area in Sq. Ft.', value: area },
            { label: 'Current rent per month (INR.)', value: currentRent },
            {
              label: 'Rent Escalation / Computation formula',
              value: rentEscalation,
            },
            { label: 'Security Deposit (INR)', value: securityDeposit },
            { label: 'Location Address', value: locationAddress },
            { label: 'Lock in period in Months', value: lockInPeriod },
            { label: 'Lock in expiry date', value: lockInExpiryDate },
            { label: 'Renewal clause', value: renewalClause },
            {
              label: 'Stamped & Registered',
              value: additionalDetails?.hasOwnProperty?.('stampedAndRegistered')
                ? stampedAndRegistered
                  ? 'Yes'
                  : 'No'
                : '',
            },
          ];
          break;
        default:
          details = [
            { label: 'Start Date', value: startDate },
            { label: 'End Date', value: endDate },
          ];
          break;
      }

      break;
    default:
      break;
  }
  if (type === 'View Details') {
    setViewDetails(details);
  } else {
    setViewFiles(files);
  }
};
