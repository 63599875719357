import { exportToExcel } from '../../../../common-utilities/utils';
import { uploadTemplates } from '../constants';

export const processGrnTemplateData = (data) => {
  const allData =
    data?.map?.((item) => {
      return {
        Id: item._id,
        'Item Name': item?.item?.name,
        'HSN Code': item?.hsnCode || null,
        'Order Quantity': item?.quantity,
        'Inwarded Quantity': item?.inwardedQuantity,
        UOM: item?.uom,
        'Received Quantity': null,
        Comments: null,
      };
    }) || [];

  exportToExcel(allData, uploadTemplates.grn, null, true);
};
