import React, { useEffect, useState } from "react";

import { Button, IconButton, TextField } from "@mui/material";
import Select from "react-select";
import { CloseOutlined } from "@mui/icons-material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { validAddressTypes } from "../../utils/constants";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const AddCommunication = ({
  communicationTypes,
  details = {},
  storeNo,
  onClose,
  onSave,
}) => {
  const [address, setAddress] = useState([]);
  const [communicationValue, setCommunicationValue] = useState("");
  const [buttonType, setButtonType] = useState("create");
  const [communicationType, setCommunicationType] = useState({});
  const [addCommunicationToPerson, setAddCommunicationToPerson] =
    useState(false);

  useEffect(() => {
    setAddress(details || []);
  }, [details]);

  const onChange = (event) => {
    setCommunicationValue(event.target.value);
    // setAddress({
    //   ...address,
    //   [event.target.name]: event.target.value,
    // });
  };
  const saveRecord = () => {
    let payload = {
      type: validAddressTypes.store,
      communicationType: communicationType.communicationType,
      value: communicationValue,
      entityId: storeNo,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add communication");
        } else {
          onSave(payload);
        }
        setCommunicationValue("");
        setButtonType("create");
        setCommunicationType({});
      })
      .catch((err) => console.log(err));
  };
  const updateRecord = () => {
    let payload = {
      type: validAddressTypes.store,
      communicationType: communicationType[0].communicationType,
      value: communicationValue,
      entityId: storeNo,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_COMMUNICATION}${communicationType[0].id}`,
      payload
    ).then((response) => {
      if (response.code) {
        toast.error("Failed to add address");
      } else {
        onSave(payload);
      }
      setCommunicationValue("");
      setButtonType("create");
      setCommunicationType({});
    });
  };

  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Communication Details </span>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <div className="f-r-2">
            <Select
              classNamePrefix="custom_select"
              options={communicationTypes}
              // value={
              //   address?.communicationType && {
              //     label: address.communicationType,
              //     value: address.communicationType,
              //   }
              // }
              onChange={(e) => {
                let comValue = address.filter((ele) => {
                  return ele.communicationType === e.value;
                });

                if (comValue.length > 0) {
                  setButtonType("update");
                  setCommunicationValue(comValue[0].value);
                  setCommunicationType(comValue);
                } else {
                  setCommunicationValue("");
                  setButtonType("create");
                  setCommunicationType((prev) => ({
                    ...prev,
                    communicationType: e.value,
                  }));
                }
                // setAddress({
                //   ...address,
                //   communicationType: e.value,
                // });
              }}
              placeholder="Communication Type"
            />
          </div>
          <div>
            <TextField
              label="Details"
              className="subs-text-field"
              fullWidth
              value={communicationValue}
              name="value"
              onChange={onChange}
            />
          </div>
          <div>
            <Button
              size="small"
              fullWidth
              disabled={!storeNo ? true : false}
              variant="contained"
              onClick={() =>
                buttonType === "update" ? updateRecord() : saveRecord()
              }
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCommunication;
