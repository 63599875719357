import React, { useState, useEffect } from "react";
import "./index.scss";
import { Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {  getLabelsAndValuesForSelect } from "../../utils";
import { getCityListByStateAndCountry, getStateList } from "./utils";
import { regionsList } from "../../utils/labels";
import { FormatSelect } from "./common-components/format-select";

const LocationInformation = ({ locationdata, updateLocationInfo }) => {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [allRegionsList, setAllRegionsList] = useState([]);
  useEffect(() => {
    if (locationdata?.state && cityList.length === 0) {
      const citySelectList = getCityListByStateAndCountry(
        "India",
        locationdata?.state
      );
      setCityList(citySelectList);
    }
  }, []);
  const handleChange = (event) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "state") {
      const citySelectList = getCityListByStateAndCountry("India", fieldValue);
      setCityList(citySelectList);
    }

    updateLocationInfo((prevData) => {
      const [mainKey, subKey] = fieldName.split(".");
      if (mainKey === "state") {
        delete prevData?.locationInfo?.city;
      }
      if (subKey) {
        if (!prevData.locationInfo[mainKey]) {
          prevData.locationInfo[mainKey] = {};
        }
        prevData.locationInfo[mainKey][subKey] = fieldValue;
      } else {
        prevData.locationInfo[mainKey] = fieldValue;
      }
      return {
        ...prevData,
      };
    });
  };

  useEffect(() => {
    const statesSelectList = getStateList("India");
    setStateList(statesSelectList);
    const allRegionSelectList = getLabelsAndValuesForSelect(
      regionsList,
      regionsList
    );
    setAllRegionsList(allRegionSelectList);
  }, []);

  return (
    <div className="main-location-info-container">
      <div className="doc-container">
        <Grid sx={{ width: "95%" }} container md={12}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              gridGap: "2em",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "center",
              [`@media (max-width: 768px)`]: {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                gridGap: "2em",
                display: "flex",
                justifyContent: "center",
                [`@media (max-width: 768px)`]: {
                  flexDirection: "column",
                },
              }}
            >
              <Grid item md={5} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Catchment"
                  className="new-textfield"
                  value={locationdata?.catchment || ""}
                  variant="outlined"
                  name="catchment"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationdata?.state ? locationdata.state : ""}
                    label="State"
                    name="state"
                    onChange={handleChange}
                  >
                    {stateList.map((format) => {
                      return (
                        <MenuItem value={format.value}>{format.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                gridGap: "2em",
                display: "flex",
                justifyContent: "center",
                [`@media (max-width: 768px)`]: {
                  flexDirection: "column",
                },
              }}
            >
              <Grid md={5} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationdata?.city ? locationdata.city : ""}
                    label="City"
                    name="city"
                    onChange={handleChange}
                  >
                    {cityList.map((format) => {
                      return (
                        <MenuItem value={format.value}>{format.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid md={5} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationdata?.region ? locationdata.region : ""}
                    label="Region"
                    name="region"
                    onChange={handleChange}
                  >
                    {allRegionsList.map((format) => {
                      return (
                        <MenuItem value={format.value}>{format.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                gridGap: "2em",
                display: "flex",
                justifyContent: "center",
                [`@media (max-width: 768px)`]: {
                  flexDirection: "column",
                },
              }}
            >
              <Grid item md={5}>
                <TextField
                  fullWidth
                  size="small"
                  className="new-textfield"
                  label="Carpet Area (Sq. Ft.)"
                  value={locationdata?.carperArea || ""}
                  variant="outlined"
                  name="carperArea"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={5}>
                <FormatSelect
                  value={locationdata?.format ? locationdata.format : ""}
                  name={"format"}
                  handleChange={handleChange}
                  removeValueFromMainData={() => {
                    updateLocationInfo((prevData) => {
                      delete prevData?.locationInfo?.format;
                      return { ...prevData };
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                gridGap: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item md={5}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  label="Built-Up Area (Sq. Ft.)"
                  variant="outlined"
                  className="new-textfield"
                  name="builtArea"
                  value={locationdata?.builtArea || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={5}>
                {/* <TextField
                  fullWidth
                  size="small"
                  label="Size"
                  value={locationdata?.size || ""}
                  variant="outlined"
                  name="size"
                  onChange={handleChange}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            {/* <Grid item md={9} sx={{ mb: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="current-status-autocomplete"
              options={currentStatusOptions}
              disabled={true}
              onChange={(_, value) => {
                updateLocationInfo((prevVal) => {
                  const status =
                    typeof prevVal.locationInfo.status === "object"
                      ? { ...prevVal.status }
                      : {};
                  if (!value) {
                    delete status.currentStatus;
                  } else {
                    status.currentStatus = value.value;
                  }
                  return {
                    ...prevVal,
                    locationInfo: {
                      ...prevVal.locationInfo,
                      status,
                    },
                  };
                });
              }}
              value={locationdata?.status?.currentStatus || null}
              renderInput={(params) => (
                <TextField {...params} label="Current Status" />
              )}
            />
          </Grid> */}
            <Grid md={9} item>
              {/* <table className="table-container">
              <tr className="table-header-row">
                <td className="status-table-cell">Status</td>
                <td className="status-table-cell">Date</td>
              </tr>
              <tr>
                <td className="table-body-cell">Lead</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="status.lead"
                    value={dateFun(locationdata?.status?.lead) || ""}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Site Evaluation</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="status.siteEvaluation"
                    value={dateFun(locationdata?.status?.siteEvaluation) || ""}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Commercial Discussion</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="status.commercialDiscussion"
                    value={
                      dateFun(locationdata?.status?.commercialDiscussion) || ""
                    }
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Term Sheet Discussion</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    value={
                      dateFun(locationdata?.status?.teamSheetDiscussion) || ""
                    }
                    name="status.teamSheetDiscussion"
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Term Sheet Signed</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    value={dateFun(locationdata?.status?.teamSheetSigned) || ""}
                    name="status.teamSheetSigned"
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Under construction</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="status.underConstruction"
                    value={
                      dateFun(locationdata?.status?.underConstruction) || ""
                    }
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-body-cell">Store Opened</td>
                <td className="table-body-cell">
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="status.storeOpened"
                    value={dateFun(locationdata?.status?.storeOpened) || ""}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </table> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LocationInformation;
