export const isFormValid = (ticketDetails) => {
    let errors = {};
    const {StoresList, department, details, ticketcategory} = ticketDetails
    if (!StoresList) {
      errors.storeslist = true
      
    }
    if (!department) {
      errors.department = true
    }
    if (!details) {
      errors.details = true
    }
    if (!ticketcategory) {
      errors.category = true
    }
    return errors;
  };

  export const convertedEventNamesToErrorObjectKeys = {
    StoresList:"storeslist",
    department:"department",
    details:"details",
    ticketcategory:"category"
  }

  export const CASE_TYPES = [
    {
      label: 'Product',
      value: 'PRODUCT',
    },
    {
      label: 'Service',
      value: 'SERVICE',
    },
  ];