import React from "react";
import "./index.scss";
import { Button, Grid, TextareaAutosize, TextField } from "@mui/material";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const LeaseRentCom = () => {
  const columns = [
    { id: "startDate", label: "Start Date" },
    { id: "endDate", label: "End Date" },
    { id: "calculationType", label: "Calculation Type" },
    { id: "rentAmount", label: "Rent Amount" },
    { id: "increasePercent", label: "Increase Percent" },
    { id: "increaseAmount", label: "Increase Amount" },
    { id: "capAmount", label: "Cap Amount" },
    { id: "baseDate", label: "Base Date" },
    { id: "effective Date", label: "Effective Date" },
    { id: "cpiPercent", label: "CPI Percent" },
    { id: "maxcpiPercent", label: "Max. CPI Percent" },
    { id: "mincpiPercent", label: "Min. CPI Percent" },
    { id: "cpi", label: "CPI" },
  ];
  return (
    <div className="lease-rent">
      <Grid container md={12} sx={{ mt: 4.5, mb: 0 }}>
        <Grid item md={2} xs={6}>
          <TextField
            id="store"
            label="Store"
            fullWidth
            variant="outlined"
            className="sel-item"
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Select
            placeholder="Satellite"
            className="sel-item"
            classNamePrefix="custom_select"
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Select
            placeholder="Lease ID"
            className="sel-item"
            classNamePrefix="custom_select"
          />
        </Grid>
        <Grid
          item
          md={5}
          xs={6}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className="search-p mt10">Pending Leases:0</p>
          <p className="search-p mt10">Legacy Leases:0</p>
          <p className="search-p mt10">
            Lease Search{" "}
            <span>
              <SearchIcon color="inherit" sx={{ mt: 1, ml: 1 }} />
            </span>
          </p>
        </Grid>
      </Grid>
      <div className="title">Realty Surcharge Program</div>
      <Grid container md={12} xs={12}>
        <Grid item md={2} sx={{ mt: ".3em" }}>
          <TextField
            label="Upcharge Percent"
            fullWidth
            className="sel-item"
            variant="outlined"
          />
        </Grid>
        <Grid item md={1.3}>
          <div>
            <span className="input-title-paragrah">Upcharge Ind</span>
            <Checkbox />
          </div>
        </Grid>

        <Grid item md={1.3}>
          <span className="input-title-paragrah">Upcharge Tax Ind</span>
          <Checkbox />
        </Grid>

        <Grid item md={2} sx={{ mt: ".3em" }}>
          <TextField
            label="Tax Rate Percent"
            fullWidth
            className="sel-item"
            variant="outlined"
          />
        </Grid>

        <Grid item md={2} sx={{ mt: "-1em" }}>
          <p className="input-title-paragrah search-p">Tax Registration Date</p>
          <TextField
            type="date"
            fullWidth
            variant="outlined"
            className="sel-item"
          />
        </Grid>
        <Grid item md={1.3}>
          <span className="input-title-paragrah">Rent Reduction</span>
          <Checkbox />
        </Grid>
        <Grid item md={2} sx={{ mt: ".3em" }}>
          <TextField
            label="Rent Differential"
            fullWidth
            className="sel-item"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={4} sx={{ display: "grid", gridGap: "10px" }}>
          <div className="title">Rent</div>
          <div className="g-r-2">
            <TextField
              label="Minimum Rent"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
            <TextField
              label="CAM Fees"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Taxes"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
            <TextField
              label="Est.%Rent"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Misc Rent"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
            <TextField
              label="Upcharge"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Upcharge Tax"
              fullWidth
              className="sel-item"
              variant="outlined"
            />
          </div>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Total Rent</span>
            <TextField
              label="Total Rent"
              className="total-tax"
              variant="outlined"
            />
          </Grid>
          <Grid>
            <div className="title">Cap</div>
            <TextField
              label="CAM"
              fullWidth
              variant="outlined"
              className="sel-item"
            />
          </Grid>
          <Grid>
            <div className="sel-item">
              <div className="g-r-2">
                <div>
                  <p className="input-title-paragrah search-p">
                    Rent Commencement
                  </p>
                  <TextField fullWidth type="date" />
                </div>
                <div>
                  <div style={{ height: "20px" }}></div>
                  <Select
                    placeholder="Increase Type"
                    classNamePrefix="custom_select"
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title-paragrah search-p">Start</p>
                  <TextField fullWidth type="date" />
                </div>
                <div>
                  <p className="input-title-paragrah search-p">End</p>
                  <TextField fullWidth type="date" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            sx={{
              display: "flex",
              width: "80%",
              gridGap: "10px",
              marginTop: 3,
              marginLeft: 5,
              marginBottom: 2,
            }}
          >
            <TextField
              label="Percent Increase"
              fullWidth
              variant="outlined"
              className="sel-item"
            />
            <TextField
              label="Dollar Increase"
              fullWidth
              variant="outlined"
              className="sel-item"
            />
            <TextField
              label="Rent Amount"
              fullWidth
              variant="outlined"
              className="sel-item"
            />
          </Grid>
          <Grid>
            <StickyHeaderCon columnsList={columns} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaseRentCom;
