import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: 'Item Code',
      id: 'itemCode'
    },
    {
      label: 'HSN Code',
      id: 'hsnCode'
    },
    {
      label: 'Name',
      id: 'name'
    },
    {
      label: 'Price',
      id: 'price'
    },
    {
      label: 'Price History',
      id: 'priceHistory',
      render: data => {
        return (
          <>
            <Button variant='text' onClick={() => editVendor(data, 'HISTORY')}>
              View History
            </Button>
          </>
        );
      }
    },
    {
      label: 'UOM',
      id: 'uom'
    },  
    {
      label: 'TAX(%)',
      id: 'taxPercentage'
    },
    {
      label: 'Specification',
      id: 'specification'
    },
    {
      label: 'Category',
      id: 'category',
      render: data => {
        const { category = {} } = data;
        return category?.name;
      }
    },
    {
      label: 'Subcategory',
      id: 'subCategory',
      render: data => {
        const { subCategory = {} } = data;
        return subCategory?.name;
      }
    },

    {
      label: 'Actions',
      id: 'actions',
      render: data => {
        return (
          <>
            <Button variant='text' disabled={!isUpdateAllowed("master_data")} onClick={() => editVendor(data, 'EDIT')}>
              Edit
            </Button>
            <Button variant='text' disabled={!isDeleteAllowed("master_data")} onClick={() => setDeleteDetails(data)}>
              Delete
            </Button>
          </>
        );
      }
    }
  ];
  return headers;
};
