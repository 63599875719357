import { Button } from '@mui/material';
import { exportToExcel, formatDate } from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../common-utilities/utils/constants';

const PaymentDetailsDownload = ({ paymentDetails }) => {
  return (
    <>
      <div className="payment-details-btn">
        <Button
          variant="outlined"
          onClick={() => {
            const results =
              paymentDetails?.map?.((item) => {
                return {
                  'Payment Amount': item.paymentAmount,
                  UTR: item.utr,
                  TDS: item.tdsAmount || '',
                  'Date Of Payment':
                    formatDate(
                      item.dateOfPayment,
                      DATE_FORMATS['DD-MM-YYYY']
                    ) || '',
                };
              }) || [];
            if (results.length) {
              exportToExcel(results, 'payment-details', null, true);
            }
          }}
        >
          Download Payment Details
        </Button>
      </div>
    </>
  );
};
export default PaymentDetailsDownload;
