import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import "./index.scss";
import logo from "../../resources/logo.png";
import { clearOfflineData } from "../../utils/offline-services";
import { logoutApplication } from "../../../../core/header/logout";
import { USER_ROLES } from "../../../../utils/roles";
import SideMenu from "../../../../core/side-menu";

const BasicHeader = ({ userDetails = {} }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    clearOfflineData("user");
    clearOfflineData("tokens");
    logoutApplication(navigate);
  };
  return (
    <div className="header-container">
      <div className="logo-con">
        {userDetails?.role === USER_ROLES.admin && <SideMenu />}
      </div>
      {/* <div className="menu">
        <Avatar>
          <AccountCircleIcon />
        </Avatar>
        <Button
          type="small"
          variant="text"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {userDetails.name}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div> */}
    </div>
  );
};

export default BasicHeader;
