import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MovieIcon from "@mui/icons-material/Movie";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export const mainDocumentTypes = {
  document: "Documents",
  video: "Videos",
};

export const subDocumentTypes = {
  pdf: "PDF File",
  mp4: "MP4 File",
  other: "Other",
};

export const subDocumentTypeIcon = {
  [subDocumentTypes.pdf]: PictureAsPdfIcon,
  [subDocumentTypes.mp4]: MovieIcon,
  [subDocumentTypes.other]: AttachFileIcon,
};

export const subDocumentTypeImages = {
  [subDocumentTypes.mp4]:
    "https://idev.blob.core.windows.net/technetv2/VLC_media_player-Logo.wine.png",
  [subDocumentTypes.pdf]:
    "https://idev.blob.core.windows.net/technetv2/download%20(1).jfif",
};

export const modalActions = {
  updateDocument: "Update Document",
  createDocument: "Create Document",
  createCategory: "Create Category",
  updateCategory: "Update  Category",
};

export const homeTabs = {
  category: "Category",
  subCategory: "Sub Category",
};
