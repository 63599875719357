import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import {
  mainInvokeApi,
  mainInvokeUploadFile,
  mainProgressiveInvokeApi,
} from "../../common-utilities/utils/http-service";
export { HTTP_METHODS } from "../../common-utilities/utils/http-service";
export const invokeApi = async (
  method,
  url,
  data,
  params,
  Authorization,
  additionalKey
) => {
  const key = additionalKey ? additionalKey : tokenMainKeys.qnet;
  return mainInvokeApi(
    key,
    appSlugs.qnet,
    method,
    url,
    data,
    params,
    Authorization
  );
};

export const progressiveInvokeApi = async (method, url, data, params) => {
  return mainProgressiveInvokeApi(
    tokenMainKeys.qnet,
    appSlugs.qnet,
    method,
    url,
    data,
    params
  );
};

export const invokeUploadFile = async (url, formData) => {
  return mainInvokeUploadFile(tokenMainKeys.qnet, appSlugs.qnet, url, formData);
};
