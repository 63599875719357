import { Autocomplete, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  approvalTypes,
  workFlowTypes,
  locationTypes,
} from '../../../config/constants';
import {
  clearProperty,
  setProperty,
} from '../../../../../../common-utilities/utils/misc';

const MainConfig = ({ modalData, setModalData, mainConfig }) => {
  const onInputChange = (e) => {
    setModalData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const textFieldList = [
    { label: 'Approval Name', key: 'name' },
    { label: 'Max Amount', key: 'maxAmount', type: 'number' },
  ];

  const autoCompleteLists = [
    { list: mainConfig.departmentList, key: 'department', label: 'Department' },
    { list: approvalTypes, key: 'approvalType', label: 'Approval Type' },
    { list: workFlowTypes, key: 'workFlowType', label: 'Work Flow Type' },
    { list: locationTypes, key: 'locationType', label: 'Location Type' },
  ];

  return (
    <>
      <Grid
        container
        md={12}
        sx={{
          display: 'flex',
          mt: 2,
          mb: 2,
          justifyContent: 'space-between',
          [`@media (max-width: 768px)`]: {
            gridGap: '20px',
          },
        }}
      >
        {textFieldList.map((textFieldConfig) => {
          const { key, label, type } = textFieldConfig;
          return (
            <Grid key={key} item md={5}>
              <TextField
                type={`${type ? type : 'text'}`}
                label={label}
                name={key}
                onChange={onInputChange}
                value={modalData?.[key]}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
          );
        })}

        {autoCompleteLists.map((listConfig) => {
          const { list, key, label } = listConfig;
          return (
            <Grid
              sx={{
                mt: 2,
              }}
              item
              md={5}
              key={key}
            >
              <Autocomplete
                value={
                  modalData?.[key] || modalData?.[key] === 0
                    ? list.find((item) => item.value === modalData?.[key])
                    : null
                }
                onChange={(e, val) => {
                  if (val?.value || val?.value === 0) {
                    setProperty(key, val?.value, setModalData);
                    return;
                  }

                  clearProperty(key, setModalData);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={`${label}`}
                    sx={{
                      minWidth: 220,
                    }}
                  />
                )}
                options={list}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MainConfig;
