import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "react-select";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { checkValidStoreCombination } from "../../utils";
import { GlobalStoreContext } from "../../global-states/Context";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { deleteObjectProperties } from "../../../common-utilities/utils/misc";

const StoreHours = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [storeInput, setStoreInput] = useState("");
  const previousController = useRef();
  const [storeOptions, setStoreOptions] = useState([]);
  const [storeHours, setstoreHours] = useState({});
  const [satelliteNum, setSatelliteNum] = useState(null);
  const [satelliteList, setSatelliteList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [sequenceList, setSequenceList] = useState([]);
  const [sequenceNo, setSequenceNo] = useState(null);
  const [timeDuration, settimeDuration] = useState({});

  const getTimeDifferenceBetweenDateInHours = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return ["", 0];
    }
    const value = Math.abs(new Date(endDate) - new Date(startDate)) / 36e5;
    return [`${value}`, value];
  };

  const setTimeDurationAfterChange = (givenStoreHours) => {
    let timeDurationValue = {};
    let totalHours = 0;
    const wednesday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.wednesday?.open,
      givenStoreHours?.wednesday?.close
    );

    timeDurationValue.wednesday = wednesday[0];
    totalHours += wednesday[1];

    const thursday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.thursday?.open,
      givenStoreHours?.thursday?.close
    );

    timeDurationValue.thursday = thursday[0];
    totalHours += thursday[1];

    const friday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.friday?.open,
      givenStoreHours?.friday?.close
    );

    timeDurationValue.friday = friday[0];
    totalHours += friday[1];

    const saturday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.saturday?.open,
      givenStoreHours?.saturday?.close
    );

    timeDurationValue.saturday = saturday[0];
    totalHours += saturday[1];

    const sunday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.sunday?.open,
      givenStoreHours?.sunday?.close
    );

    timeDurationValue.sunday = sunday[0];
    totalHours += sunday[1];

    const monday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.monday?.open,
      givenStoreHours?.monday?.close
    );

    timeDurationValue.monday = monday[0];
    totalHours += monday[1];

    const tuesday = getTimeDifferenceBetweenDateInHours(
      givenStoreHours?.tuesday?.open,
      givenStoreHours?.tuesday?.close
    );

    timeDurationValue.tuesday = tuesday[0];
    totalHours += tuesday[1];

    settimeDuration({ ...timeDurationValue, totalHours: `${totalHours}` });
  };
  const clearStoreHours = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        satelliteNo: "",
        sequenceNo: "",
      });
    setStoreOptions([]);
    setTimeDurationAfterChange({});
    setstoreHours({});
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value.trim());
    } else {
      setStoreOptions([]);
    }
    const finalValue = value ? value : "";
    setStoreInfo({ storeNo: finalValue, satelliteNo: "", sequenceNo: "" });
    if (!finalValue) {
      clearStoreHours();
    }
  };
  useEffect(() => {
    const isValidStoreCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (isValidStoreCombination) {
      getStoreHoursDetails(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    }
  }, []);
  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getStoreHoursDetails(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    }
  }, []);

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const setstoreHoursOnChange = (newValue, name) => {
    const [key, subKey] = name.split("?.");
    let mainKeyObject = storeHours?.[key] ? { ...storeHours?.[key] } : null;
    if (!mainKeyObject) {
      mainKeyObject = {};
    }
    if (newValue) {
      mainKeyObject[subKey] = new Date(newValue.$d);
    } else {
      mainKeyObject[subKey] = null;
    }
    setTimeDurationAfterChange({ ...storeHours, [key]: mainKeyObject });
    setstoreHours((prevVal) => ({ ...prevVal, [key]: mainKeyObject }));
  };

  const getStoreHoursDetails = (storeNo, satelliteNo, sequenceNo) => {
    const isValidStoreCombination = checkValidStoreCombination(
      storeNo,
      satelliteNo,
      sequenceNo
    );
    if (isValidStoreCombination) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_STORE_HOURS}${storeNo}`,
        null,
        { satelliteNo, sequenceNo }
      )
        .then((res) => {
          if (!res.message) {
            delete res.id;
            delete res.archive;

            let temp = { ...res };
            let days = {};
            Object.keys(temp).forEach((iter) => {
              if (typeof temp[iter] === "object" && temp[iter] !== null) {
                days = { ...days, [iter]: temp[iter] };
                delete temp[iter];
              }
            });
            temp["days"] = days;
            setRespData(temp);
            setstoreHours(res);
            setTimeDurationAfterChange(res);
          } else {
            // toast.error(res.message, { autoClose: 2000 });
            clearStoreHours(false);
          }
        })
        .catch((err) => {
          toast.error(err?.message, { autoClose: false });
        });
    }
  };

  const createStoreHours = () => {
    const payload = { ...storeHours };
    payload.satelliteNo = storeInfo.satelliteNo;
    payload.storeNo = storeInfo.storeNo;
    payload.sequenceNo = storeInfo.sequenceNo;
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_STORE_HOURS}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          setIsCreate(false);
          clearStoreHours(false);
          return;
        }
        toast.success("Basic fixed created successfully", { autoClose: 2000 });
        setIsCreate(false);
        clearStoreHours(false);
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };

  const saveDetails = () => {
    if (!Number.isInteger(parseInt(storeInfo.storeNo))) {
      toast.error("Please select store number", { autoClose: 2000 });
      return;
    }
    if (!Number.isInteger(parseInt(storeInfo.satelliteNo))) {
      toast.error("Please select satellite number", { autoClose: 2000 });
      return;
    }
    if (!Number.isInteger(parseInt(storeInfo.sequenceNo))) {
      toast.error("Please select sequence number", { autoClose: 2000 });
    }
    if (isCreate) {
      createStoreHours();
      return;
    }

    const payload = { ...storeHours };
    payload.satelliteNo = storeInfo.satelliteNo;
    payload.sequenceNo = storeInfo.sequenceNo;
    deleteObjectProperties(payload, [
      "archive",
      "id",
      "createdAt",
      "updatedAt",
    ]);
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_STORE_HOURS}${storeInfo.storeNo}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          clearStoreHours(false);
          return;
        }
        toast.success("Basic fixed updated successfully", { autoClose: 2000 });
        clearStoreHours(false);
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };

  return (
    <div className="store-details-container-att">
      <div className="search-container">
        <div style={{ minWidth: "250px" }}>
          <Autocomplete
            className="subs-autocomplete"
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearStoreHours();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumber(val.id);
              }
            }}
          />
        </div>

        <div style={{ minWidth: "250px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
              if (!isCreate) {
                getStoreHoursDetails(
                  storeInfo.storeNo,
                  val?.value,
                  storeInfo.sequenceNo
                );
              }
            }}
          />
        </div>

        <div style={{ minWidth: "250px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));
              if (!isCreate) {
                getStoreHoursDetails(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </div>

        <div style={{ minWidth: "250px" }}>
          <Select
            placeholder="Required Hours"
            classNamePrefix="custom_select"
          />
        </div>

        <div style={{ display: "flex" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => clearStoreHours()}
            startIcon={<AutorenewIcon />}
            style={{ marginRight: "20px" }}
          >
            Reset
          </Button>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  clearStoreHours(false);
                  setIsCreate(true);
                }}
                startIcon={<SaveIcon />}
                style={{ marginRight: "20px" }}
              >
                Create
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("store_hours_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </div>
      </div>
      <Divider style={{ paddingBottom: 10 }} />
      <div className="title">Required Hours</div>
      <Grid container>
        <Grid xs={6}>
          <div className="form-details">
            <div className="date-range-picker">
              <span>Wed</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.wednesday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "wednesday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.wednesday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "wednesday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.wednesday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Thurs</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.thursday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "thursday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.thursday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "thursday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.thursday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Fri</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.friday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "friday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.friday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "friday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.friday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Sat</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.saturday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "saturday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.saturday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "saturday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.saturday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Sun</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.sunday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "sunday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.sunday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "sunday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.sunday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Mon</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.monday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "monday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.monday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "monday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.monday || ""}
              />
            </div>

            <div className="date-range-picker">
              <span>Tues</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.tuesday?.open || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "tuesday?.open")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="subs-date-time-picker"
                  label="Time"
                  value={storeHours?.tuesday?.close || null}
                  onChange={(newValue) =>
                    setstoreHoursOnChange(newValue, "tuesday?.close")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                className="subs-text-field"
                value={timeDuration.tuesday || ""}
              />
            </div>

            <div className="date-range-picker-total">
              <span>Total Required Hours:</span>
              <TextField
                className="subs-text-field"
                value={timeDuration.totalHours || ""}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "white",
          pr: 5,
          paddingTop: "10px",
        }}
      >
        <div className="g-r-2">
          {isCreate && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsCreate(false);
                clearStoreHours(false);
              }}
            >
              Cancel
            </Button>
          )}

          <Button
            size="small"
            variant="contained"
            onClick={saveDetails}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      </Grid>
    </div>
  );
};
export default StoreHours;
