import { Button } from '@mui/material';
import { getFinanceApprovalData } from '../vendor-request-details/helper';
import {
  VENDOR_FORM_STATUS,
  VENDOR_ONBOARD_USERS,
} from '../../utils/constants';

export const getHeaderConfig = (activeTab, role) => {
  const headers = [
    {
      label: 'Form Id',
      id: 'id',
      render: (data, viewInvoice) => {
        return (
          <Button variant="text" onClick={() => viewInvoice(data, 'VIEW')}>
            {data.id}
          </Button>
        );
      },
    },
    {
      label: 'Creator Name',
      id: 'createdBy.name',
    },
    {
      label: 'Email',
      id: 'email',
    },
    {
      label: 'Created on',
      id: 'createdAt',
      render: (data) => {
        return new Date(data.createdAt).toLocaleDateString();
      },
    },
    {
      label: 'Expired On',
      id: 'expiredAt',
      render: (data) => {
        return new Date(data.expiredAt).toLocaleDateString();
      },
    },
    {
      label: 'Vendor',
      id: 'vendor',
      render: (data) => {
        return data?.vendorOnboardData?.nameOfLegalEntity;
      },
    },
    {
      label: 'Status',
      id: 'status',
      render: (data) => {
        const { approvers, status } = data;
        if (
          [VENDOR_FORM_STATUS.OPEN, VENDOR_FORM_STATUS.REOPENED].includes(
            status
          )
        ) {
          return 'Waiting Response';
        }
        if ([VENDOR_FORM_STATUS.CANCELED].includes(status)) {
          return 'CANCELED';
        }
        const obj = getFinanceApprovalData(approvers, status);
        return obj.status;
      },
    },
  ];
  if (activeTab !== 0) {
    headers.push({
      label: 'Submitted on',
      id: 'submittedAt',
      render: (data) => {
        if (data.submittedAt) {
          return new Date(data.submittedAt).toLocaleDateString();
        } else {
          return '-';
        }
      },
    });

    if (activeTab === 2 || activeTab === 3) {
      headers.push({
        label: 'Rejected on',
        id: 'rejectedAt',
        render: (data) => {
          if (data.rejectedAt) {
            return new Date(data.rejectedAt).toLocaleDateString();
          } else {
            return '-';
          }
        },
      });
    }
    if (activeTab === 2 && role === 'FINANCE_USER') {
      headers.push({
        label: 'Vendor Creation',
        id: 'vendor',
        render: (data, viewInvoice) => {
          if (!data?.vendor) {
            return (
              <Button
                variant="text"
                onClick={() => viewInvoice(data, 'CREATE_VENDOR')}
              >
                Create Vendor
              </Button>
            );
          } else {
            return <div>{data?.vendorCode}</div>;
          }
        },
      });
    }
    // if (role !== VENDOR_ONBOARD_USERS.HOD) {
    //   headers.push({
    //     label: "Actions",
    //     id: "name",
    //     render: (data, viewInvoice) => {
    //       return (
    //         <Button
    //           variant="text"
    //           disabled={data?.status === "APPROVED"}
    //           onClick={() => viewInvoice(data, "EDIT")}
    //         >
    //           Edit
    //         </Button>
    //       );
    //     },
    //   });
    // }
  }

  return headers;
};

export const FILTER_DATE_RANGE_TYPES = [
  {
    label: 'Created Date',
    value: 'createdAt',
  },
  {
    label: 'Invoice Date',
    value: 'invoiceDate',
  },
];

export const isFilterDisabled = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;
  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return !startDate && !endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return false;
};

export const clearFilter = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;

  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return startDate && endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return true;
};

export const TAB_NAME_BY_ROLE = {
  default: ['Open Request', 'Submitted Request', 'Rejected Request', 'All'],
  HOD: [
    'Review Pending Request',
    'Rejected Request',
    'Approved Request',
    'All',
  ],
  FINANCE_USER: [
    'Review Pending Request',
    'Rejected Request',
    'Approved Request',
    'All',
  ],
  VIVO_CREATOR: [
    'Review Pending Request',
    'Open Request',
    'Submitted Request',
    'Rejected Request',
    'Approved Request',
    'All',
  ],
};

export const FILTER_BY_TAB_NAME_BY_ROLE = {
  default: {
    0: 'SUBMITTED,RESUBMITTED,IN_PROGRESS',
    1: `OPEN,${VENDOR_FORM_STATUS.REOPENED}`,
    2: 'REJECTED',
    3: 'APPROVED',
    4: '',
  },
  HOD: {
    0: 'SUBMITTED,RESUBMITTED,IN_PROGRESS',
    1: 'REJECTED',
    2: 'APPROVED',
    3: '',
  },
  FINANCE_USER: {
    0: 'SUBMITTED,RESUBMITTED,IN_PROGRESS',
    1: 'REJECTED',
    2: 'APPROVED',
    3: '',
  },
  VIVO_CREATOR: {
    // 0: `OPEN,${VENDOR_FORM_STATUS.REOPENED}`,
    0: 'SUBMITTED,RESUBMITTED,IN_PROGRESS',
    1: `OPEN,${VENDOR_FORM_STATUS.REOPENED}`,
    2: 'SUBMITTED,RESUBMITTED,IN_PROGRESS',
    3: 'REJECTED',
    4: 'APPROVED',
    5: '',
  },
};
