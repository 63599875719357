import React, { useState, useEffect } from "react";
import "./index.scss";
import { budgetFilters, budgetStatus, getBudgetHeadersConfig } from "./helper";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import {
  AutoCompleteSelect,
  DateRange,
  NumberRange,
  getLast15DaysDateRange,
} from "./utils/utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import ClearIcon from "@mui/icons-material/Clear";
import { exportToExcel } from "../../../common-utilities/utils";
import CustomModal from "../../../common-utilities/core/modal";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../common-utilities/utils/time";
const BudgetReport = () => {
  const [budgetList, setBudgetList] = useState({});
  const [filterLabel, setFilterLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
  });
  const loadData = (filters, isDownload = false) => {
    const { startDate, endDate } = getLast15DaysDateRange();
    let params = {
      ...filters,
    };
    if (!filters?.startDate) {
      params["startDate"] = startDate;
    }
    if (!filters?.endDate) {
      params["endDate"] = endDate;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REPORTS}budget`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        }
        if (isDownload) {
          exportToExcel(response.results, "Budget Report");
        } else {
          setBudgetList(response);
        }
      })
      .catch((err) => console.log(err));
  };
  const addParams = (params) => {
    switch (filterLabel) {
      case "totalAmount":
        if (
          ![null, undefined, NaN, ""].includes(filterValue?.minVal) &&
          ![null, undefined, NaN, ""].includes(filterValue?.maxVal)
        ) {
          params["minVal"] = filterValue?.minVal || 0;
          params["maxVal"] = filterValue?.maxVal || 0;
          params["numberKey"] = filterLabel;
        }

        break;
      case "createdAt":
      case "updatedAt":
        if (
          ![null, undefined, NaN, ""].includes(filterValue?.startDate) &&
          ![null, undefined, NaN, ""].includes(filterValue?.endDate)
        ) {
          params["startDate"] = getStartOfDay(filterValue?.startDate, true);
          params["endDate"] = getEndOfDay(filterValue?.endDate, true);
          params["dateKey"] = filterLabel;
        }

        break;

      default:
        if (filterLabel && ![null, undefined, NaN, ""].includes(filterValue)) {
          params["keyName"] = filterLabel;
          params["keyValue"] = filterValue;
        }

        break;
    }
  };
  const searchHandler = () => {
    let params = { ...filters, page: 1 };
    addParams(params);
    loadData(params);
  };
  const getCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CATEGORY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele.name, value: ele.id }));
        setCategoryList(result);
      })
      .catch((err) => console.log(err));
  };
  const getLocationList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_LOCATION_LIST}`,
      null,
      { poBudgetLocations: true }
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele.name, value: ele.id }));
        setLocationList(result);
      })
      .catch((err) => console.log(err));
  };
  const getFilterValue = (type) => {
    switch (type) {
      case "totalAmount":
        return <NumberRange setFilterValue={setFilterValue} />;
      case "createdAt":
        return <DateRange setFilterValue={setFilterValue} />;
      case "status":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={budgetStatus}
          />
        );
      case "category":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={categoryList}
          />
        );
      case "updatedAt":
        return <DateRange setFilterValue={setFilterValue} />;
      case "siteName":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={locationList}
          />
        );
      default:
        break;
    }
  };
  const downloadReport = () => {
    const params = { ...filters, download: true };
    params.page = 1;
    params.limit = budgetList.totalResults;
    addParams(params);
    loadData(params, true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  useEffect(() => {
    if (filterLabel === "category") {
      getCategoryList();
    }
    if (filterLabel === "siteName") {
      getLocationList();
    }
  }, [filterLabel]);

  return (
    <div>
      <div className="reports-filter-con">
        <Grid
          container
          md={12}
          sx={{ mt: 2, display: "flex", alignItems: "center" }}
        >
          <Grid item md={2} sx={{ mr: 2 }}>
            <Autocomplete
              options={budgetFilters}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Select Filter" />
              )}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setFilterLabel("");
                    setFilterValue("");
                    loadData({ ...filters, page: 1 });
                  }}
                />
              }
              onChange={(e, val) => {
                if (val) {
                  setFilterValue("");
                  setFilterLabel(val.value);
                }
              }}
            />
          </Grid>

          {filterLabel && getFilterValue(filterLabel)}

          <Grid md={2} sx={{ ml: 2 }}>
            {filterLabel && (
              <Button
                variant="outlined"
                onClick={searchHandler}
                disabled={!filterValue || Object.keys(filterValue).length === 0}
              >
                Search
              </Button>
            )}
          </Grid>
        </Grid>
        <DownloadBtn
          onClick={() => {
            downloadReport();
          }}
        />
      </div>

      <MuiTable
        columnsList={getBudgetHeadersConfig()}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        showAllFunc={setShowLargeText}
        maxWords={3}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          let params = { ...filters };
          addParams(params);
          loadData({
            ...params,
            page,
          });
        }}
      />
      {showLargeText.isOpen && (
        <CustomModal
          onClose={() =>
            setShowLargeText({
              isOpen: false,
              text: "",
            })
          }
        >
          {showLargeText.text}
        </CustomModal>
      )}
    </div>
  );
};

export default BudgetReport;
