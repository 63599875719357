import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { TextField, Grid } from "@mui/material";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import { plainApi } from "../../utils/http-service";
import { GlobalStoreContext } from "../../global-states/Context";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const PersonHistory = () => {
  const [data, setData] = useState([]);
  const { personInfo, setPersonInfo } = useContext(GlobalStoreContext);
  const [personOptions, setPersonOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const previousController = useRef();
  const columns = [
    { id: "lastName", label: "Last Name" },
    { id: "firstName", label: "First Name" },
    { id: "middleName", label: "Middle Initial" },
    { id: "jobTitle", label: "Job Title" },
    { id: "nationalId", label: "National Id" },
    { id: "gender", label: "Gender" },
    { id: "status", label: "Status" },
    { id: "primaryCitizenship", label: "Primary Citizenship" },
    { id: "residencyProof", label: "Citizenship/Residency Proof" },
    { id: "ofacDate", label: "OFAC Date" },
    { id: "deceasedDate", label: "Deceased Date" },
  ];
  useEffect(() => {
    if (totalPages === null) {
      searchByPersonNum(personInfo.personId, pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          searchByPersonNum(personInfo.personId, pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (personInfo.personId) {
      searchByPersonNum(personInfo.personId, 1, true);
    }
  }, []);

  const searchByPersonNum = (id, pg, hasToReset) => {
    let params = {
      page: pg,
      limit: 50,
      modelName: "User",
      _id: id,
    };
    if (!Number.isInteger(parseInt(id))) {
      return;
    }
    let dataCheckingCondition = !pageArray.includes(pg);
    if (hasToReset) {
      dataCheckingCondition = true;
    }

    if (dataCheckingCondition) {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_HISTORY}`,
        null,
        params
      ).then((response) => {
        if (!response?.message) {
          setTotalPages(response.totalPages + 1);
          const results = response.results.map((ele) => ({
            lastName: ele?.details?.lastName,
            firstName: ele?.details?.firstName,
            middleName: ele?.details?.middleName,
            jobTitle: ele?.details?.jobTitle,
            nationalId: ele?.details?.demographics?.nationalId,
            gender: ele?.details?.demographics?.gender,
            status: ele?.details?.demographics?.status,
            primaryCitizenship: ele?.details?.demographics?.primaryCitizenship,
            residencyProof: ele?.details?.demographics?.residencyProof,
            ofacDate: ele?.details?.attributes?.ofacDate,
            deceasedDate: ele?.details?.demographics?.deceasedDate,
          }));
          let array = [...pageArray];
          array.push(pg);
          if (hasToReset) {
            setData(results);
            setPageArray([1]);
            if (response.totalPages === 1) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setPageArray(array);
            setData([...data, ...results]);
          }
        } else {
          setData([]);
        }
      });
    }
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions(updatedOptions);
    });
  };

  const clearData = () => {
    setPersonInfo({ personId: "" });
    setPersonOptions([]);
    setData([]);
    setPageArray([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
  };
  const onInputChange = (event, value, reason) => {
    if (!value) {
      clearData();
    }
    if (value) {
      getData(value);
      setPersonInfo({ personId: value });
    }
  };

  return (
    <div className="person-history-con ">
      <div className="search-div">
        <Grid container md={6}>
          <Grid item md={6}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              className="subs-autocomplete"
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              options={personOptions}
              onInputChange={onInputChange}
              inputValue={personInfo.personId}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Person" />
              )}
              onChange={(e, val) => {
                if (val) {
                  searchByPersonNum(val.id, 1, true);
                }
              }}
            />
          </Grid>
          {/* <Select
            classNamePrefix="custom_select"
            isSearchable
            placeholder="Listing Type"
          /> */}
        </Grid>
      </div>
      <div className="table-con">
        <StickyHeaderCon
          columnsList={columns}
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default PersonHistory;
