import React, { useState } from "react";
import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";
import TransferDetails from "./transfer-details";
import TransferDetails2 from "./transfer-details-2";
import TransferBuyerSellerInfo from "./transfer-buyer-seller-info";
import TransferEscrows from "./transfer-escrows";
import TransferTransaction from "./transfer-transaction";
import TransferMemos from "./transfer-memos";
import TransferHistory from "./transfer-history";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Transfer = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let a = { email: "newuser@fragodev.com", password: "Test1234" };

  return (
    <div className="main-person-container">
      <div className="subs-mainTabContainer">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="subs-tab-container"
        >
          <Grid container spacing={0}>
            <Grid item md={12} xs={12} sm={7} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Transfer Details" />
                <Tab label="Transfer Details Page 2" />
                {/* <Tab label="Transfer Buyer/Seller Info" />
                <Tab label="Transfer Escrows" />
                <Tab label="Transfer Transactions" />
                <Tab label="Transfer Memos" /> */}
                <Tab label="Transfer History" />
                <Tab label="Transfer Memos" />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <TransferDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TransferDetails2 />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <TransferBuyerSellerInfo />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TransferEscrows />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TransferTransaction />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TransferMemos />
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <TransferHistory />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TransferMemos />
        </TabPanel>
      </div>
    </div>
  );
};

export default Transfer;
