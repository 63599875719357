import React from "react";
import "./index.scss";
import { Markup } from "interweave";
const ShowFullBlog = ({ blogData, files }) => {
  return (
    <div className="blog">
      <Markup content={decodeURIComponent(blogData)} />

      {files?.length > 0 && (
        <div>
          <h5 className="attachment">Attachments</h5>

          <ul>
            {files.map((file) => {
              return (
                <li>
                  <a href={file.url} download={file.title} target="_blank">
                    {file.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ShowFullBlog;
