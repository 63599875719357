/** @format */

import React, { useEffect, useState } from "react";
import SubwayFileUpload from "../../core/file-uploader";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton } from "@mui/material";

const IMAGE_TYPES = ["image/png", "image/jpeg"];

const DocumentPreview = ({
  docs,
  setDocs,
  documentSavingKey,
  documentType,
  documentLimitationConfig,
  isEditingDoc,
}) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (isEditingDoc) {
      const convertedDocs =
        docs?.map((item) => {
          return {
            location: item.document,
            originalname: item.originalName,
            ...item,
          };
        }) || [];

      setFiles(convertedDocs);
    }
  }, [isEditingDoc, docs]);

  const onSucces = (response) => {
    const savedDocument = {};
    const documenturl = response.url.split("?")[0];
    savedDocument[documentSavingKey] = documenturl;
    savedDocument["type"] = documentType;
    savedDocument["originalName"] = response.originalname;
    const newfiles = [...files, { ...response, location: documenturl }];
    setFiles(newfiles);
    setDocs([...docs, savedDocument]);
  };
  const deleteDoc = (delindex) => {
    const { location } = files[delindex];
    const filteredDocs = docs.filter((item) => {
      return item[documentSavingKey] !== location;
    });
    const filteredFiles = files.filter((_, index) => {
      return index !== delindex;
    });
    setFiles(filteredFiles);
    setDocs(filteredDocs);
  };

  return (
    <div className="doc-preview">
      <div className="doc-list">
        {files.map((doc, index) => {
          return (
            <div key={doc.location} className="doc-conatiner">
              <div>
                {IMAGE_TYPES.includes(doc.mimetype) ? (
                  <img className="prev-img" src={doc.location} width="120" />
                ) : (
                  <FilePresentIcon />
                )}
              </div>
              <div className="file-name" title={doc.originalname}>
                {doc.originalname}
              </div>
              <IconButton onClick={() => deleteDoc(index)} className="close">
                <HighlightOffIcon />
              </IconButton>
            </div>
          );
        })}

        {files.length === 0 && <div>No documents attached</div>}
      </div>
      <SubwayFileUpload
        documentTypeConfig={documentLimitationConfig}
        title="Upload document"
        callBack={onSucces}
        customLimits={{
          customSizeLimit:7000000,
          customMbSize:"7 MB"
        }}
        acceptType=".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </div>
  );
};

export default DocumentPreview;
