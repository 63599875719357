import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getAvailableBudgetCost,
  getModifiedBudgetCost,
  getHeaderConfig,
  getInitialBudgetCost,
} from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { toast } from "react-toastify";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import LineItemCostHistory from "./history";
import CustomModal from "../../../../common-utilities/core/modal";
import CommentAttachments from "./comment-attachments";
import { checkUserRight } from "../../../utils/utils";
import DownloadTemplateButton from "../../master-data/common-components/upload-template";
import { uploadTemplates } from "../../master-data/constants";
import FileUpload from "../../../../common-utilities/core/file-uploader";

const BudgetDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [budgetDetails, setBudgetDetails] = useState({});
  const [lineItemHistory, setLineItemHistory] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const { lineItems = [], siteName = "", siteCode = "" } = budgetDetails;
  const [addAttachments, setAddAttachments] = useState(null);

  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      {
        _id: id,
      }
    )
      .then((response) => {
        const { results = [] } = response;
        if (results.length > 0) {
          setBudgetDetails(results[0]);
        }
      })
      .catch((err) => {});
  };

  const onFormChange = (event, data) => {
    let index = lineItems.findIndex((l) => l.id === data.id);
    if (index !== -1) {
      lineItems[index][event.target.name] = event.target.value;

      setBudgetDetails({
        ...budgetDetails,
        lineItems: lineItems,
      });
      setSelectedRow(lineItems[index]);
    }
  };

  const addAttachmentsToApproval = (data, type) => {
    if (type === "HOLD") {
      onHoldLineItems(data);
    } else {
      setAddAttachments(data);
    }
  };
  const updateLineItem = (comment, additionalDocs) => {
    let payload = {
      updatedAmount: selectedRow.currentAmount,
      comment,
      additionalDocs,
    };

    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.MODIFY_LINE_ITEM}/${addAttachments.id}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.info(response.message);
          loadData();
          setSelectedRow({});
          setAddAttachments(null);
        } else {
          toast.error("Failed to Update line item");
        }
      })
      .catch((err) => {});
  };

  const viewHistory = (data, filters) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LINE_ITEM_HISTORY}/${data.id}`,
      null,
      {
        page: filters?.page || 1,
        limit: 5,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.totalResults === 0) {
          toast.info("No History found");
        } else {
          setLineItemHistory({ ...response, mainData: data });
        }
      })
      .catch((err) => {});
  };

  const getDisplayData = () => {
    let offset = (filters.page - 1) * filters.limit;
    return lineItems.slice(offset, offset + filters.limit);
  };

  const onHoldLineItems = (data) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.HOLD_BUDGET_LINE_ITEM}${data.id}`,
      null,
      {
        status: data.status === "inactive" ? "approved" : "inactive",
      }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to update status");
        } else {
          toast.info("Updated status");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const onHold = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BUDGET_STATUS}${id}`,
      null,
      {
        status: budgetDetails.status === "inactive" ? "approved" : "inactive",
      }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to update status");
        } else {
          navigate("/subway-finance?index=0");
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="budget-details-container">
      <div className="bd-header">
        <div className="bd-r-container">
          <IconButton
            onClick={() => {
              navigate(-1);
              // navigate("/subway-finance?index=0");
            }}
          >
            <ArrowBack />
          </IconButton>
          <span className="info-c">
            <span className="label">Budget Id </span>
            <span className="value">{budgetDetails.id}</span>
          </span>
          <span className="info-c">
            <span className="label">Site Code </span>
            <span className="value">{siteCode}</span>
          </span>
          <span className="info-c">
            <span className="label">Site Name </span>
            <span className="value">{siteName}</span>
          </span>
          <span className="info-c">
            <span className="label">Status </span>
            <span className="value">
              {convertToCamelCaseFromUnderScore(budgetDetails.status || "-")}
            </span>
          </span>
          <span className="info-c">
            <span className="label">Initial Budget Cost </span>
            <span className="value">
              {getInitialBudgetCost(lineItems) || 0}
            </span>
          </span>
          <span className="info-c">
            <span className="label">Modified Budget Cost </span>
            <span className="value">
              {getModifiedBudgetCost(lineItems) || 0}
            </span>
          </span>

          <span className="info-c">
            <span className="label">Available Budget Cost </span>
            <span className="value">
              {getAvailableBudgetCost(lineItems) || 0}
            </span>
          </span>
        </div>
        <div>
          {checkUserRight("budget.update") && (
            <div className="budget-btns">
              {budgetDetails.status !== "inactive" && (
                <>
                  <DownloadTemplateButton
                    type={uploadTemplates.budgetLineItems}
                  />
                  <FileUpload
                    uploadText="Upload Budget Categories"
                    // clsName="upload-budget-container"
                    url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_BUDGET_LINE_ITEMS}${id}`}
                    callBack={(response) => {
                      const { data } = response;
                      if (data.success) {
                        loadData();
                      } else {
                        toast.error("Failed to create budget");
                      }
                    }}
                    formDataKey="file"
                    showInput={!addAttachments}
                  />
                </>
              )}

              <Button variant="outlined" onClick={onHold}>
                {budgetDetails.status === "inactive" ? "Approve" : "Hold/Drop"}
              </Button>
            </div>
          )}
        </div>
      </div>
      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <div className="approvals-line-items">
        <div>
          <MuiTable
            columnsList={getHeaderConfig(
              onFormChange,
              addAttachmentsToApproval,
              viewHistory,
              budgetDetails
            )}
            dataList={getDisplayData()}
            filters={filters}
            pageCount={Math.ceil(lineItems.length / filters.limit)}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
        {lineItemHistory?.results?.length > 0 && (
          <CustomModal
            title="Line Item History"
            disabledOutsideClick={true}
            onClose={() => {
              setLineItemHistory({});
            }}
          >
            <LineItemCostHistory
              loadData={viewHistory}
              lineItemHistory={lineItemHistory}
            />
          </CustomModal>
        )}

        {addAttachments && (
          <CustomModal
            title="Upload documents"
            disabledOutsideClick={true}
            onClose={() => {
              setAddAttachments(null);
            }}
          >
            <CommentAttachments
              addAttachments={addAttachments}
              onSave={updateLineItem}
            />
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default BudgetDetails;
