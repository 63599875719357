import "./index.scss";
import { useState, useEffect } from "react";
import { BellCorpStudioLogoContainer } from "../../common-utilities/Bellcorp-Studio-Logo";
import Header from "../core/header";
import IntranetHeader from "../../../core/header";
import { tokenMainKeys } from "../../../utils/constants";
import SideMenu from "../core/side-menu";
import OtherAppSideMenu from "../../common-utilities/core/other-app-side-menu";
import { SIDE_MENU } from "../core/side-menu/config";
const MainContainer = ({
  children,
  reloadPendingApprovals,
  setReloadPendingApprovals,
}) => {
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const [open, setOpen] = useState(window.innerWidth > 480);

  return (
    <>
      <div className="subway-app-main-container">
        <div className="subway-app-main-header">
          <IntranetHeader />
        </div>

        <div className="subway-app-current-app-container">
          <div className="main-right-container">
            <OtherAppSideMenu
              appName="Petty Expense"
              SideNavCom={SideMenu}
              keyName={tokenMainKeys.pettyExpense}
            />
          </div>
          <div className="main-left-container">
            <div className="content-div">
              <div>{children}</div>
              <BellCorpStudioLogoContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
