export const addressType = [
  { value: "Person", label: "person" },
  { value: "Restaurant", label: "Restaurant" },
];
export const memoCategoryModels = [
  { value: "Person", label: "Person Memo" },
  { value: "Store", label: "Store Memo" },
  { value: "StoreTransfer", label: "Store Transfer Memo" },
  { value: "StoreLease", label: "Lease Memo" },
];
