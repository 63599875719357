import React, { useState, useRef, useContext, useEffect } from "react";
import "./index.scss";
import { Button, Grid, TextField } from "@mui/material";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { invokeApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { checkValidStoreCombination } from "../../utils";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const monthsOption = Array(13)
  .fill()
  .map((_, index) => index + 1)
  .map((iter, index) => {
    return { label: index.toString(), value: index };
  });

const Lease4 = () => {
  const [respData, setRespData] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const [leaseDetails, setLeaseDetails] = useState({ store: null });
  const [leaseIdList, setLeaseIdList] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const previousController = useRef();

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        satelliteNo: "",
        sequenceNo: "",
      });
    setLeaseDetails({});
    setStoreOptions([]);
    setLeaseIdList([]);
    if (isCreate) {
      setIsCreate(false);
    }
  };

  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      firstRefusal: {
        rightTo1stRefusal: leaseDetails.rightTo1stRefusal,
        leaseSection: leaseDetails.firstRefusalLeaseSection,
      },
      restriction: {
        useClauseRestriction: leaseDetails.useClauseRestriction,
        new: leaseDetails.new,
        leaseSection: leaseDetails.restrictionLeaseSection,
      },
      exclusiveInfo: {
        clause: leaseDetails.exclusiveInfoClause,
        leaseSection: leaseDetails.exclusiveInfoLeaseSection,
      },
      breakClause: {
        notice: leaseDetails.notice,
        breakClauseDateToSend: leaseDetails.breakClauseDateToSend,
        leaseSignDate: leaseDetails.leaseSignDate,
      },
    };

    if (Number.isInteger(parseInt(leaseInfo.leaseId))) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_LEASE + leaseInfo.leaseId,
        body
      )
        .then((res) => {
          if (Number.isInteger(parseInt(res.id))) {
            toast.success("Updated Successfully", { autoClose: 2000 });
            clearData(false);
          } else toast.error("Update Failed", { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error("Update Failed", { autoClose: 2000 });
        });
    }
  };

  const createLeaseHandler = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      firstRefusal: {
        rightTo1stRefusal: leaseDetails.rightTo1stRefusal,
        leaseSection: leaseDetails.firstRefusalLeaseSection,
      },
      restriction: {
        useClauseRestriction: leaseDetails.useClauseRestriction,
        new: leaseDetails.new,
        leaseSection: leaseDetails.restrictionLeaseSection,
      },
      exclusiveInfo: {
        clause: leaseDetails.exclusiveInfoClause,
        leaseSection: leaseDetails.exclusiveInfoLeaseSection,
      },
      breakClause: {
        notice: leaseDetails.notice,
        breakClauseDateToSend: leaseDetails.breakClauseDateToSend,
        leaseSignDate: leaseDetails.leaseSignDate,
      },
    };
    invokeApi(HTTP_METHODS.POST, HOSTNAME + REST_URLS.POST_LEASE, body)
      .then((res) => {
        if (res.id) {
          toast.success("Created Successfully", { autoClose: 2000 });
          clearData(false);
        } else toast.error("Create Failed", { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error("Create Failed", { autoClose: 2000 });
      });
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const stateUpdate = (updatedValues) => {
    setLeaseDetails((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumberAndSatelliteNumbersForStoreNumber(storeInfo.storeNo);
      listingLeaseHandler(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
      apply(leaseInfo.leaseId);
    }
  }, []);

  const getAllSequenceNumberAndSatelliteNumbersForStoreNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apply = (id) => {
    if (id) {
      invokeApi(
        HTTP_METHODS.GET,
        HOSTNAME + REST_URLS.GET_LEASE + id + "/" + storeInfo.storeNo
      ).then((res) => {
        if (res.code === 404) {
          toast.info("No Lease is Avaialble for the selected Store", {
            autoClose: 2000,
          });
        } else {
          setRespData(res);
          let obj = {};
          obj["rightTo1stRefusal"] =
            res?.storeLease?.firstRefusal?.rightTo1stRefusal;
          obj["firstRefusalLeaseSection"] =
            res?.storeLease?.firstRefusal?.leaseSection;

          obj["useClauseRestriction"] =
            res?.storeLease?.restriction?.useClauseRestriction;
          obj["restrictionLeaseSection"] =
            res?.storeLease?.restriction?.leaseSection;
          obj["new"] = res?.storeLease?.restriction?.new;

          obj["exclusiveInfoClause"] = res?.storeLease?.exclusiveInfo?.clause;
          obj["exclusiveInfoLeaseSection"] =
            res?.storeLease?.exclusiveInfo?.leaseSection;

          obj["notice"] = res?.storeLease?.breakClause?.notice;
          obj["breakClauseDateToSend"] =
            res?.storeLease?.breakClause?.breakClauseDateToSend;
          obj["leaseSignDate"] = dateFun(
            res?.storeLease?.breakClause?.leaseSignDate
          );
          stateUpdate(obj);
        }
      });
    }
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...leaseDetails };
    tempState[key] = value;
    setLeaseDetails(tempState);
  };

  const onInputChange = (event, value, reason) => {
    setLeaseInfo({ leaseId: null });
    setSatteliteList([]);
    setSequenceList([]);
    setLeaseIdList([]);
    if (value) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        sequenceNo: "",
        satelliteNo: "",
      });
    } else {
      clearData();
    }
  };

  const selectHandle = (name, value, fieldsToClear = {}) => {
    const clearedData = fieldsToClear ? fieldsToClear : {};
    let tempState = { ...leaseDetails, ...clearedData };
    tempState[name] = value;
    setLeaseDetails(tempState);
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const listingLeaseHandler = (id, satelliteNo, sequenceNo) => {
    setStoreInfo({
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
      storeNo: id,
    });
    const invalidStoreCombination = !checkValidStoreCombination(
      id,
      satelliteNo,
      sequenceNo
    );

    if (invalidStoreCombination) {
      return;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_LEASES}${id}`,
      null,
      {
        satelliteNo,
        sequenceNo,
      }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
          setLeaseIdList(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="lease">
      <Grid container md={12} sx={{ mt: 5, mb: 0 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            id="free-solo-demo"
            className="subs-autocomplete"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumberAndSatelliteNumbersForStoreNumber(val?.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("satelliteNo", val?.value, { leaseId: null });
              listingLeaseHandler(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo
              );
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("sequenceNo", val?.value, { leaseId: null });
              if (!isCreate) {
                listingLeaseHandler(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="lease Id"
              className="sel-item"
              options={leaseIdList}
              value={
                ![undefined, null, ""].includes(leaseInfo.leaseId)
                  ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                  : ""
              }
              onChange={(val) => {
                setLeaseInfo({ leaseId: val?.value });
                apply(val?.value);
              }}
            />
          )}
        </Grid>
        <Grid item md={2} xs={6} style={{ height: "35px" }}>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2, float: "right" }}
                onClick={() => {
                  clearData(false);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("lease_details_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} xs={12} sx={{ mt: 1, mb: 0 }}>
        <Grid item md={6}>
          <div className="title">Use Restriction Information</div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "25%" }}>
              <span className="input-title-paragrah">
                Use Clause Restriction
              </span>
              <Checkbox
                style={{ padding: "0px 9px" }}
                checked={leaseDetails.useClauseRestriction || false}
                disabled
              />
            </div>
            <TextField
              label="Lease Section"
              className="sel-item subs-text-field"
              variant="outlined"
              style={{ width: "35%", marginRight: "10px" }}
              value={leaseDetails.restrictionLeaseSection || ""}
              name="restrictionLeaseSection"
              onChange={handleChange}
            />
            <TextField
              label="New"
              className="sel-item subs-text-field"
              variant="outlined"
              style={{ width: "35%" }}
              value={leaseDetails.new || ""}
              name="new"
              onChange={handleChange}
            />
          </div>
          <div className="lease-section-div"></div>
          <Grid sx={{ mt: 3 }}>
            <div className="title">Right of 1st Refusal</div>
            <div className="g-r-2">
              <div>
                <span className="input-title-paragrah">
                  Right of 1st Refusal
                </span>
                <Checkbox
                  style={{ padding: "0px 9px" }}
                  checked={leaseDetails.rightTo1stRefusal || false}
                  disabled
                />
              </div>
              <TextField
                label="Lease Section"
                className="sel-item subs-text-field"
                variant="outlined"
                value={leaseDetails.firstRefusalLeaseSection || ""}
                name="firstRefusalLeaseSection"
                onChange={handleChange}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <div className="title">Exclusive Information</div>
          <div className="g-r-2">
            <div>
              <span className="input-title-paragrah">Clause</span>
              <Checkbox
                style={{ padding: "0px 9px" }}
                checked={leaseDetails.exclusiveInfoClause || false}
                disabled
              />
            </div>
            <TextField
              label="Lease Section"
              className="sel-item subs-text-field"
              variant="outlined"
              value={leaseDetails.exclusiveInfoLeaseSection || ""}
              name="exclusiveInfoLeaseSection"
              onChange={handleChange}
            />
          </div>
          <Grid sx={{ mt: 3, display: "grid", gridGap: "10px" }}>
            <div className="title">Break Clause</div>
            <div className="g-r-2">
              <Select
                classNamePrefix="custom_select"
                className="sel-item"
                placeholder={<div>Notice (Months)</div>}
                options={monthsOption}
                value={
                  ![undefined, null].includes(leaseDetails.notice)
                    ? monthsOption.find((i) => i.value == leaseDetails.notice)
                    : ""
                }
                onChange={(val) => {
                  selectHandle("notice", val.value);
                }}
              />

              <TextField
                label="Break Clause Date To Send"
                className="sel-item subs-text-field"
                variant="outlined"
                value={leaseDetails.breakClauseDateToSend || ""}
                name="breakClauseDateToSend"
                onChange={handleChange}
              />
            </div>
            <div className="g-r-2">
              <div>
                <p className="input-title-paragrah">Lease Sign Date</p>
                <TextField
                  fullWidth
                  className="sel-item subs-text-field"
                  type="date"
                  value={dateFun(leaseDetails.leaseSignDate) || ""}
                  name="leaseSignDate"
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="input-title-paragrah">New Date</p>
                <TextField
                  fullWidth
                  className="sel-item subs-text-field"
                  type="date"
                  disabled
                  // value={leaseDetails.leaseSignDate || ""}
                  // name="leaseSignDate"
                  // onChange={handleChange}
                />
              </div>
            </div>
            <div className="lease-section-div"></div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2} xs={6} style={{ height: "35px" }}>
        <span
          style={{
            display: "flex",
            padding: "5px",
            borderRadius: "5px",
            float: "right",
            marginTop: "-5px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => {
              if (isCreate) clearData(false);
              else clearData();
            }}
          >
            {isCreate ? "Cancel" : "Reset"}
          </Button>
          {!isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                ![null, ""].includes(storeInfo.satelliteNo) &&
                ![null, ""].includes(storeInfo.sequenceNo) &&
                ![null, ""].includes(leaseInfo.leaseId)
                  ? false
                  : true
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button>
          )}

          {isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                storeInfo.satelliteNo !== null &&
                storeInfo.sequenceNo !== null
                  ? false
                  : true
              }
              onClick={() => {
                createLeaseHandler();
              }}
            >
              Save
            </Button>
          )}
        </span>
      </Grid>
    </div>
  );
};

export default Lease4;
