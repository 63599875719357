import React from "react";
import "./index.scss";
import HomeRedirect from "../home-redirect";
import { tokenMainKeys } from "../../../../utils/constants";

const OtherAppSideMenu = ({ appName, SideNavCom, keyName }) => {
  return (
    <div>
      <div className="other-app-side-nav-con">
        <div className="side-nav-header">
          <HomeRedirect localstorageKey={keyName} />
          <h4 className="app-name">{appName}</h4>
        </div>

        <div className="subway-current-app-header-container">
          <div></div>
          <SideNavCom />
        </div>
      </div>
    </div>
  );
};

export default OtherAppSideMenu;
