import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';

export const getApprovalList = (filter, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.QUERY_APPROVAL_CONFIG}`,
    null,
    filter
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      setDataFunc(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
