import UploadIcon from "@mui/icons-material/Upload";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";
import BallotIcon from "@mui/icons-material/Ballot";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PaymentsIcon from "@mui/icons-material/Payments";
import HistoryIcon from "@mui/icons-material/History";
import AddCardIcon from "@mui/icons-material/AddCard";
export const SIDE_MENU = () => {
  const user = getOfflineData(tokenMainKeys.pettyExpense, "user");
  const pettyExpenseEndpoint = getProjectEndPoint(
    endPointsForProject.pettyExpense
  );

  let sideMenuOptions = [
    {
      label: "Vouchers",
      url: "/",
      value: "/",
      logo: <BallotIcon sx={{ mr: 1 }} />,
      mapkey: 1,
    },
  ];
  if (user?.role === "STORE") {
    sideMenuOptions = [
      ...sideMenuOptions,
      {
        label: "Expense Voucher",
        url: "/create-voucher",
        value: "/create-voucher",
        logo: <CreateNewFolderIcon sx={{ mr: 1 }} />,
        mapkey: 5,
      },
      {
        label: "Cash Certificate",
        url: "/cash-certificate",
        value: "/cash-certificate",
        logo: <PaymentsIcon sx={{ mr: 1 }} />,
        mapkey: 9,
      },
    ];
  }
  if (user?.role === "STORE" || user.role === "Finance") {
    sideMenuOptions = [
      ...sideMenuOptions,
      {
        label: "Balance History",
        url: "/balance-history",
        value: "/balance-history",
        logo: <HistoryIcon sx={{ mr: 1 }} />,
        mapkey: 6,
      },
    ];
  }
  if (user.role === "Finance") {
    sideMenuOptions = [
      ...sideMenuOptions,
      {
        label: "Imprest Update",
        url: "/store-imprest",
        value: "/store-imprest",
        logo: <AddCardIcon sx={{ mr: 1 }} />,
        mapkey: 8,
      },
    ];
  }
  sideMenuOptions = sideMenuOptions.map((item) => {
    const { url } = item;
    return { ...item, url: `${pettyExpenseEndpoint}${url}` };
  });
  return sideMenuOptions;
};
export const SHOW_CERTIFICATE = () => {
  const user = getOfflineData(tokenMainKeys.pettyExpense, "user");
  const pettyExpenseEndpoint = getProjectEndPoint(
    endPointsForProject.pettyExpense
  );
  let sideMenuOptions;
  if (user?.role === "STORE") {
    sideMenuOptions = [
      {
        label: "Cash Certificate",
        url: "/cash-certificate",
        value: "/cash-certificate",
        logo: <PaymentsIcon sx={{ mr: 1 }} />,
        mapkey: 9,
      },
    ];
  }
  sideMenuOptions = sideMenuOptions.map((item) => {
    const { url } = item;
    return { ...item, url: `${pettyExpenseEndpoint}${url}` };
  });
  return sideMenuOptions;
};
