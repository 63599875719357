import React, { useState, useEffect } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import Select from "react-select";

const DigitalDirectDelivery = ({
  storeDeliveryAttributes,
  onChange,
  resetData,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(storeDeliveryAttributes);
    }
  }, [storeDeliveryAttributes]);

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    onChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onCheckboxChange = (value, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: value,
    });
    onChange({
      ...formData,
      [dataLabel]: value,
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    paymentTypeInfo = {},
    directDeliveryInfo = {},
    orderMethodTypeInfo = {},
  } = formData;

  return (
    <div className="basic-fixed-container">
      <Grid container>
        <Grid xs={6}>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Productivity Threshold"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.productivityThreshold || ""}
                name="productivityThreshold"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
              <TextField
                label="Sides Threshold"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.sidesThreshold || ""}
                name="sidesThreshold"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Order Cut Off Time(min)"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.orderCutOffTime || ""}
                name="orderCutOffTime"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
              <TextField
                label="Virtual Terminal ID"
                className="subs-text-field"
                fullWidth
                value={directDeliveryInfo?.virtualTerminalID || ""}
                name="virtualTerminalID"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={directDeliveryInfo?.tipping || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !directDeliveryInfo.tipping,
                        "directDeliveryInfo",
                        "tipping"
                      );
                    }}
                  />
                }
                label="Tipping"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={
                      directDeliveryInfo?.acceptFutureDateOrders || false
                    }
                    onChange={() => {
                      onInnerCheckboxChange(
                        !directDeliveryInfo.acceptFutureDateOrders,
                        "directDeliveryInfo",
                        "acceptFutureDateOrders"
                      );
                    }}
                  />
                }
                label="Accepted Future Date Orders"
                labelPlacement="end"
              />
            </div>

            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={directDeliveryInfo?.digitalDirectDelivery || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !directDeliveryInfo.digitalDirectDelivery,
                        "directDeliveryInfo",
                        "digitalDirectDelivery"
                      );
                    }}
                  />
                }
                label="Digital Direct Delivery"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={directDeliveryInfo?.cateringOptedIn || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !directDeliveryInfo.cateringOptedIn,
                        "directDeliveryInfo",
                        "cateringOptedIn"
                      );
                    }}
                  />
                }
                label="Catering Opted In"
                labelPlacement="end"
              />
            </div>
            <div>Payment Type</div>
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={paymentTypeInfo?.americanExpress || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !paymentTypeInfo.americanExpress,
                        "paymentTypeInfo",
                        "americanExpress"
                      );
                    }}
                  />
                }
                label="American Express"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={paymentTypeInfo?.masterCard || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !paymentTypeInfo.masterCard,
                        "paymentTypeInfo",
                        "masterCard"
                      );
                    }}
                  />
                }
                label="Master Card"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={paymentTypeInfo?.visa || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !paymentTypeInfo.visa,
                        "paymentTypeInfo",
                        "visa"
                      );
                    }}
                  />
                }
                label="Visa"
                labelPlacement="end"
              />
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Receipt Footer Line1"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.receiptFooterLine1 || ""}
                name="receiptFooterLine1"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
              <TextField
                label="Receipt Footer Line2"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.receiptFooterLine2 || ""}
                name="receiptFooterLine2"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Receipt Footer Line3"
                className="subs-text-field"
                fullWidth
                value={directDeliveryInfo?.receiptFooterLine3 || ""}
                name="receiptFooterLine3"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
              <TextField
                label="Receipt Footer Line4"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.receiptFooterLine4 || ""}
                name="receiptFooterLine4"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Receipt Footer Line5"
                fullWidth
                className="subs-text-field"
                value={directDeliveryInfo?.receiptFooterLine5 || ""}
                name="receiptFooterLine5"
                onChange={(event) =>
                  onInnerInputChange(event, "directDeliveryInfo")
                }
              />
              <Select
                classNamePrefix="custom_select"
                placeholder="Remote Order"
                value={
                  directDeliveryInfo?.remoteOrder
                    ? {
                        label: directDeliveryInfo?.remoteOrder,
                        value: directDeliveryInfo?.remoteOrder,
                      }
                    : null
                }
              />
            </div>
            <div>Order Method Type</div>
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={orderMethodTypeInfo?.curbSidePickUp || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !orderMethodTypeInfo.curbSidePickUp,
                        "orderMethodTypeInfo",
                        "curbSidePickUp"
                      );
                    }}
                  />
                }
                label="Curb Side Pick Up"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={orderMethodTypeInfo?.dineIn || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !orderMethodTypeInfo.dineIn,
                        "orderMethodTypeInfo",
                        "dineIn"
                      );
                    }}
                  />
                }
                label="Dine in"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={orderMethodTypeInfo?.groupOrdering || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !orderMethodTypeInfo.groupOrdering,
                        "orderMethodTypeInfo",
                        "groupOrdering"
                      );
                    }}
                  />
                }
                label="Group Ordering"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={orderMethodTypeInfo?.inStorePickup || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !orderMethodTypeInfo.inStorePickup,
                        "orderMethodTypeInfo",
                        "inStorePickup"
                      );
                    }}
                  />
                }
                label="In Store Pick up"
                labelPlacement="end"
              />
            </div>
          </div>
        </Grid>
        <Grid container>
          <Grid xs={12}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default DigitalDirectDelivery;
