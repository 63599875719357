import { getOfflineData } from "../../utils/offline-services";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { BD_LEAD_ROUTE } from "../../../common-utilities/utils/end-points/intranet";

export const SIDE_MENU = () => {
  const user = getOfflineData("user");

  let options = [
    {
      label: "Dashboard",
      url: `${BD_LEAD_ROUTE}/dashboard`,
      value: `${BD_LEAD_ROUTE}/dashboard`,
      logo: <DashboardIcon sx={{ mr: 1 }} />,
    },
    {
      label: "Lead Documents",
      url: `${BD_LEAD_ROUTE}`,
      value: `${BD_LEAD_ROUTE}`,
      logo: <PendingActionsIcon sx={{ mr: 1 }} />,
    },
  ];

  return [...options];
};
