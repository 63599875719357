/** @format */

import * as React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";
import { CF_APPROVAL_COMMON_ROUTE } from "../../../common-utilities/utils/end-points/cf-approval";

export default function SideMenuV2() {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState(`/`);
  React.useEffect(() => {
    const list = SIDE_MENU();
    setMenuItems(list);
  }, []);
  React.useEffect(() => {
    setActiveMenu(
      menuItems.find((m) => m.url === window.location.pathname) || {}
    );
  }, [menuItems]);
  const onMenuClick = () => {};

  return (
    <div className="side-menu-v2-items">
      {menuItems.map((menu, index) => {
        return (
          <div
            key={menu.value}
            className={
              menu.value === activeMenu.value ? " menu-item-active-strip" : ""
            }
            onClick={() => {
              onMenuClick();
              setActiveMenu(menu);
              navigate(`${CF_APPROVAL_COMMON_ROUTE}${menu.url}`);
            }}
          >
            <div
              className={
                menu.value === activeMenu.value
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
            >
              <span>
                {menu.value === activeMenu.value ? menu.activeLogo : menu.logo}
              </span>
              <span className="side-bar-menu-label">{menu.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
