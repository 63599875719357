import { setProperty } from '../../../../../../common-utilities/utils/misc';
import MuiTable from '../../../../../../common-utilities/core/mui-table';
import { headerConfig } from '../helper';
import { Button } from '@mui/material';
import '../index.scss';

const Stages = ({ filters, modalData, setModalData, config, setFilters }) => {
  const onStageChange = (val, index, key) => {
    const stageIndex = (filters?.page - 1) * filters?.limit + index;

    const stages = modalData?.stages || [];

    switch (key) {
      case 'allowedRole':
      case 'stageName':
      case 'stageRoleName':
        stages[stageIndex][key] = val?.hasOwnProperty('value')
          ? val?.value
          : null;
        setProperty('stages', stages, setModalData);
        break;
      case 'deleteStage': {
        const newStages = stages
          .filter((item) => item._id != stages[stageIndex]._id)
          .map((stage, index) => {
            stage.StageNumber = index + 1;
            return stage;
          });
        setProperty('stages', newStages, setModalData);
        return;
      }

      default:
        break;
    }
  };

  return (
    <>
      <div className="update-crt-btns">
        <Button
          onClick={() => {
            setProperty(
              ['stages', 'curId'],
              [
                [
                  ...(modalData?.stages || []),
                  {
                    _id: modalData?.curId || 1,
                    StageNumber: (modalData?.stages?.length || 0) + 1,
                  },
                ],
                (modalData?.curId || 1) + 1,
              ],

              setModalData
            );
          }}
          className="update-crt-btns"
          variant="outlined"
        >
          Add Stage
        </Button>
      </div>

      <MuiTable
        dataList={
          modalData?.stages?.slice(
            (filters?.page - 1) * filters?.limit,
            filters?.page * filters?.limit
          ) || []
        }
        onClick={onStageChange}
        columnsList={headerConfig(config.allowedRoleList)}
        pageCount={Math.ceil((modalData?.stages?.length || 0) / filters?.limit)}
        onChange={(page) => {
          setProperty('page', page, setFilters);
        }}
        filters={filters}
      />
    </>
  );
};

export default Stages;
