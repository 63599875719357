import {
  getAllExcelColumns,
  getExcelColumns,
  processExcelDates,
} from "../../../../common-utilities/utils/excel";
import { getLabelsAndValuesForSelect } from "../../../utils";
import { brandKeysToLabelsForCompetitorInfo } from "../../../utils/labels";
import {
  mainLeadDates,
  stageGatesDates,
} from "../config/download-reports-dates.config";

export const getBrandsSelectList = () => {
  const labels = [];
  const values = [];
  Object.keys(brandKeysToLabelsForCompetitorInfo).forEach((item) => {
    const brandName = brandKeysToLabelsForCompetitorInfo[item];
    labels.push(brandName);
    values.push(item);
  });
  return getLabelsAndValuesForSelect(labels, values);
};

export const sortStageGatesByStageNumberInAscendingOrder = (stage1, stage2) => {
  const stage1StageNumber = stage1.stageNumber;
  const stage2StageNumber = stage2.stageNumber;
  if (stage1StageNumber < stage2StageNumber) return -1;
  if (stage1StageNumber > stage2StageNumber) return 1;
  return 0;
};

export const processExcelData = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  switch (sheetName) {
    case "data":
      {
        const dateColumns = getExcelColumns(headers, mainLeadDates, columns);
        const convertedWorkSheet = processExcelDates(
          data,
          worksheet,
          dateColumns
        );
        workbook.Sheets[sheetName] = convertedWorkSheet;
        workbook.SheetNames.push(sheetName);
      }
      return;
    case "Stage Gates":
      {
        const dateColumns = getExcelColumns(headers, stageGatesDates, columns);
        const convertedWorkSheet = processExcelDates(
          data,
          worksheet,
          dateColumns
        );
        workbook.Sheets[sheetName] = convertedWorkSheet;
        workbook.SheetNames.push(sheetName);
      }
      return;
    default:
      workbook.Sheets[sheetName] = worksheet;
      workbook.SheetNames.push(sheetName);
      break;
  }
};

export const disableSaveButton = (data) => {
  return (
    !data?.locationInfo?.builtArea ||
    !data?.locationInfo?.carperArea ||
    !data?.locationInfo?.catchment ||
    !data?.locationInfo?.city ||
    !data?.locationInfo?.format ||
    !data?.locationInfo?.region ||
    !data?.locationInfo?.state ||
    data?.locationInfo?.status?.currentStatus === "Dropped"
  );
};
