const intranetService = `/v1/central-service/`;
const cfApprovalService = "/v1/cf-approval-service/";
const bdLeadService = "/v1/bd-leads-service/";
export const REST_URLS = {
  LOGIN: "/v1/cf-approval-service/auth/login",
  LOGOUT: `${intranetService}auth/logout`,
  // REFRESH_TOKEN: "/v1/cf-approval-service/auth/refresh-tokens",
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  CREATE_DOCUMENT: "/v1/cf-approval-service/form",
  UPLOAD_FILES: "/v1/cf-approval-service/misc/upload",
  APPROVED_FORMS: "/v1/cf-approval-service/form/approved",
  REJECTED_FORMS: `${cfApprovalService}form/reject-form`,
  UNAPPROVED_FORMS: "/v1/cf-approval-service/form/non-approved",
  APPROVE_FORM: "/v1/cf-approval-service/form/approve-form",
  INVITE_USER: "/v1/cf-approval-service/users/invite",
  LIST_USERS: "/v1/cf-approval-service/users",
  LIST_ALL_USERS: `${intranetService}other-app/all-user-names`,
  REPORTS: "/v1/cf-approval-service/form/all-restaurant",
  SET_PASSWORD: "/v1/cf-approval-service/users/save-password/",
  UPLOAD_ATTACHMENT_FILES: "/v1/cf-approval-service/tickets/upload/attachment/",
  ALL_RESTARANT_NAMES: "/v1/cf-approval-service/form/restaurant-names-list",
  EDIT_RESTAURANT_ID: "/v1/cf-approval-service/form/update-restaurantid",
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  GET_ALL_STORES: `${intranetService}stores`,
  DELETE_APPROVAL: `${cfApprovalService}form/`,
  REJECT_APPROVAL: `${cfApprovalService}form/reject-form/`,
  FIELD_NAME: `${cfApprovalService}form/field-list`,
  DASHBOARD: `${cfApprovalService}form/dashboard`,
  UPLOAD_FLATSHEET: `${cfApprovalService}form/upload-flatsheet/`,
  GET_LEAD_LIST: `${bdLeadService}lead/cf/get-leads-list`,
};

export const SLUG = "cf-approval";
export const CF_APPROVAL_COMMON_ROUTE = "/cf-approval";
export const userRedirectionRoute = `${CF_APPROVAL_COMMON_ROUTE}/approval`;
