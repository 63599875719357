export const cocoBuiltDateColumns = [
  'Pre Agreement Docs-Possession Letter-Letter Date',
  'Pre Agreement Docs-License Feasibility-Report Date',
  'Pre Agreement Docs-Title Deligence Report-Report Date',
  'Pre Agreement Docs-LOI-LOI Signing Date',
  'Pre Agreement Docs-Project Feasibility-Document Date',
  'Agreement And Post Agreement Documents-Lease Agreement-Date of Lease Agreement',
  'Agreement And Post Agreement Documents-Registration-Registration Date',
  'Agreement And Post Agreement Documents-Submisssion of Reg Hard copy-Date of Submission',
  'Licenses-FSSAI-Start Date',
  'Licenses-FSSAI-End Date',
  'Licenses-Fire-Start Date',
  'Licenses-Fire-End Date',
  'Licenses-Change of User-Start Date',
  'Licenses-Change of User-End Date',
  'Licenses-Trade-Start Date',
  'Licenses-Trade-End Date',
  'Licenses-Shop Establishment-Start Date',
  'Licenses-Shop Establishment-End Date',
  'Licenses-Others-Start Date',
  'Licenses-Others-End Date',
  'MFA Riders-Riders-Start Date',
  'MFA Riders-Riders-End Date',
  'Agreement And Post Agreement Documents-Option Agreement-Start Date',
];

export const cocoAcquiredDateColumns = [
  'Pre Agreement Docs-Tear Sheet-Document Date',
  'Pre Agreement Docs-LOI-LOI Signing Date',
  'Pre Agreement Docs-Title Deligence Report-Report Date',
  'Pre Agreement Docs-Tax Report-Report Date',
  'Agreement And Post Agreement Documents-BTA-Document Date',
  'Agreement And Post Agreement Documents-Franchise Termination-Date of Franchise Termination',
  'Agreement And Post Agreement Documents-Lease Agreement-Date of Lease Agreement',
  'Agreement And Post Agreement Documents-Registration-Registration Date',
  'Agreement And Post Agreement Documents-Submisssion of Reg Hard copy-Date of Submission',
  'Agreement And Post Agreement Documents-Delivery Memo-Document Date',
  'Agreement And Post Agreement Documents-Lease Termination-Document Date',
  'Licenses-FSSAI-Start Date',
  'Licenses-FSSAI-End Date',
  'Licenses-Fire-Start Date',
  'Licenses-Fire-End Date',
  'Licenses-Change of User-Start Date',
  'Licenses-Change of User-End Date',
  'Licenses-Trade-Start Date',
  'Licenses-Trade-End Date',
  'Licenses-Shop Establishment-Start Date',
  'Licenses-Shop Establishment-End Date',
  'Licenses-Others-Start Date',
  'Licenses-Others-End Date',
  'MFA Riders-Riders-Start Date',
  'MFA Riders-Riders-End Date',
  'Agreement And Post Agreement Documents-Option Agreement-Start Date',
];

export const fofoDataColumns = [
  'Franchise Agreement-Start Date',
  'Franchise Agreement-End Date',
  'Lease Agreement-Start Date',
  'Lease Agreement-End Date',
  'Operating Assignment-Start Date',
  'Operating Assignment-End Date',
];
