import React, { useEffect, useState } from "react";
import { getOfflineData } from "../../utils/offline-services";
import ShowUsers from "../admin-screen";
import { Navigate } from "react-router-dom";
import AllowedApps from "../allowed-apps";
import { ResetPasswordModal } from "../custom-modals/reset-password.modal";
const Home = () => {
  const userRole = getOfflineData("user")?.role;
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  return (
    <div>
      {userRole === "admin" ? <ShowUsers /> : <AllowedApps />}
      {/* {userRole !== "admin" && <AllowedApps />} */}

      {!userRole && <Navigate to="/login" />}
      <ResetPasswordModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
    </div>
  );
};

export default Home;
