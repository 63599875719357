import { appSlugs } from "../../../utils/constants";

const vendorInvoiceKeys = {
  amount: "Invoice Amount",
  invoiceStatus: "Invoice Status",
  grnAttachment: "Other Attachment",
};

const invoiceSubmissionKeys = {
  amount: "Amount",
  invoiceStatus: "Status",
  grnAttachment: "GRN Attachment",
};

export const getKeysBySlug = (slug) => {
  switch (slug) {
    case appSlugs.vendorInvoiceSubmission:
      return vendorInvoiceKeys;
    default:
      return invoiceSubmissionKeys;
  }
};
