import React from "react";
import "./index.scss";
import CustomLineChart from "./line-chart";
import { Grid } from "@mui/material";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
const Overview = (props) => {
  const {
    reOpen,
    total,
    open,
    raisedCount,
    topIssueCausingStore,
    topIssueCausingDept,
    topIssueCausingCategory,
    topPerformer,
    mttc,
    mttr,
    topSLABreachedDept,
    monthWiseTicketCounts,
    withoutResolution,
  } = props.data;
  let chartData = Array(12).fill(0);
  let chartCategory = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  monthWiseTicketCounts?.forEach((iter) => {
    chartData[chartCategory.indexOf(Object.keys(iter)[0])] =
      iter[Object.keys(iter)[0]];
  });
  return (
    <div className="p10 overview-Container">
      <Grid container spacing={0}>
        <Grid item md={8} xs={12}>
          <div className="heading">Ticket Summary</div>
          <Grid container spacing={0} className="m0">
            <Grid item md={6} xs={6} className="card-base ">
              <div className="card-conent">
                <OfflineBoltOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#982698" }}
                />
                <span className="icon-span">Open</span>
                <span className={"float-right bolder"}>{open ? open : 0}</span>
              </div>
            </Grid>
            <Grid item md={6} xs={6} className="card-base">
              <div className="card-conent">
                <FlareOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#0646c8" }}
                />
                <span className="icon-span">Without resolution</span>
                <span className={"float-right bolder"}>
                  {withoutResolution ? withoutResolution : 0}
                </span>
              </div>
            </Grid>
            <Grid item md={6} xs={6} className="card-base">
              <div className="card-conent">
                <ImportContactsOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#00a9f4" }}
                />
                <span className="icon-span">Reopened</span>
                <span className={"float-right bolder"}>
                  {reOpen ? reOpen : 0}
                </span>
              </div>
            </Grid>
            <Grid item md={6} xs={6} className="card-base">
              <div className="card-conent">
                <BugReportOutlinedIcon
                  className="plainIcon"
                  style={{ color: "orange" }}
                />
                <span className="icon-span">Total Tickets</span>
                <span className={"float-right bolder"}>
                  {total ? total : 0}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="heading">Ticket Mean Time</div>
          <Grid container spacing={0} className="p10">
            <Grid item md={6} xs={6} className="boder-grid left-radius">
              <Grid container spacing={0} className="height100">
                <Grid item md={5} xs={6}>
                  <div className="p30">
                    <div className="bolder f15">
                      {typeof mttr === "number" ? mttr.toFixed(2) : 0}
                    </div>
                    <div className="gray f15">Resolve</div>
                  </div>
                </Grid>
                <Grid item md={6} xs={6}>
                  <BarChartOutlinedIcon className="chart-icon-blue" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={6} className="boder-grid right-radius">
              <Grid container spacing={0} className="height100">
                <Grid item md={5} xs={6}>
                  <div className="p30">
                    <div className="bolder f15">
                      {typeof mttc === "number" ? mttc.toFixed(2) : 0}
                    </div>
                    <div className="gray f15">Close</div>
                  </div>
                </Grid>
                <Grid item md={6} xs={6}>
                  <BarChartOutlinedIcon className="chart-icon-orange" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item md={12} xs={12}>
              <div className="heading">Top Issue causing</div>
              <Grid container spacing={0}>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <OfflineBoltOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#982698" }}
                      />
                    </div>
                    <div className="pl10 f10">Top Issue Raising Store</div>
                    <div className="pl10 f15">{topIssueCausingStore?.name}</div>
                    <div className="pl10 f15 bolder">
                      {topIssueCausingStore?.count}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <FlareOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#0646c8" }}
                      />
                    </div>
                    <div className="pl10 f10">
                      Top Complaints Raised Department
                    </div>
                    <div className="pl10 f15">{topIssueCausingDept?.name}</div>
                    <div className="pl10 f15 bolder">
                      {topIssueCausingDept?.count}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <ImportContactsOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#f5915a" }}
                      />
                    </div>
                    <div className="pl10 f10">
                      Top Complaints Raised Category & Department
                    </div>
                    <div className="pl10 f15">
                      {topIssueCausingCategory?.name}
                    </div>
                    <div className="pl10 f15 bolder">
                      {topIssueCausingCategory?.count}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container spacing={0}>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <OfflineBoltOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#d33131" }}
                      />
                    </div>
                    <div className="pl10 f10">Max SLA breached department</div>
                    <div className="pl10 f15">{topSLABreachedDept?.name}</div>
                    <div className="pl10 f15 bolder">
                      {topSLABreachedDept?.count}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <FlareOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#0646c8" }}
                      />
                    </div>
                    <div className="pl10 f10">New Issues Raised</div>
                    <div className="pl10 f15">Last 30 days</div>
                    <div className="pl10 f15 bolder">
                      {raisedCount ? raisedCount : 0}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4} xs={6}>
                  <div className="card-conent">
                    <div>
                      <ImportContactsOutlinedIcon
                        className="icon"
                        style={{ backgroundColor: "#f5915a" }}
                      />
                    </div>
                    <div className="pl10 f10">Top Performer of the Month</div>
                    <div className="pl10 f15">{topPerformer?.personName}</div>
                    <div className="pl10 f15 bolder">{topPerformer?.count}</div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomLineChart
            title={"Tickets Raised"}
            data={[
              {
                name: "No.of Tickets Raised",
                data: chartData,
              },
            ]}
            xDataKey="Tickets_Raised_Each_Month"
            categories={chartCategory}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
