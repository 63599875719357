/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { ComplainHeaderConfig } from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";
import ConfirmModal from "../../../common-utilities/core/confirm-modal";
import Select from "react-select";
import { SEVERITY_OPTIONS } from "../view-case/helper";
import { toast } from "react-toastify";
import {
  complaintCategoryTypeOptions,
  complaintTypeOwners,
} from "../../utils/labels";
import SearchIcon from "@mui/icons-material/Search";
import MaterialUITable from "../../../common-utilities/core/material-ui-table";
import MuiTable from "../../../common-utilities/core/mui-table";

const ComplaintTypes = () => {
  const [records, setRecords] = useState({});
  const [recordDetails, setRecordDetails] = useState(null);
  const [products, setProducts] = useState([null]);
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    name: "",
  });

  const [filtersAfterClickingOnApply, setFiltersAfterClickingOnApply] =
    useState({
      name: "",
    });

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filtersObj, usePreviouslyAppliedFilters) => {
    const isFilterPresent = typeof filtersObj === "object" && filtersObj;
    const userFilters = isFilterPresent ? { ...filtersObj } : { ...filters };
    if (!userFilters.limit) {
      userFilters.limit = filters.limit;
    }
    if (!userFilters.sortBy) {
      userFilters.sortBy = filters.sortBy;
    }
    if (!userFilters.page) {
      userFilters.page = filters.page;
    }

    if (!userFilters.name) {
      userFilters.name = filters.name;
    }
    if (usePreviouslyAppliedFilters) {
      userFilters.name = filtersAfterClickingOnApply.name;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.COMPLAINT_TYPES}`,
      null,
      userFilters
    ).then((response) => {
      if (response.results) {
        const results = response.results.map((item) => {
          if (item.categoryType) {
            item.categoryType = {
              label: item.categoryType,
              value: item.categoryType,
            };
          }
          if (item.secondaryOwner) {
            item.secondaryOwner = {
              label: item.secondaryOwner,
              value: item.secondaryOwner,
            };
          }
          if (item.primaryOwner) {
            item.primaryOwner = {
              label: item.primaryOwner,
              value: item.primaryOwner,
            };
          }
          if (item.severity) {
            item.severity = {
              label: `${item.severity[0]}${item.severity
                .slice(1)
                .toLowerCase()}`,
              value: item.severity,
            };
          }
          return { ...item };
        });

        setRecords({ ...response, results });
      }
    });
  };

  const loadProducts = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_NAMES}`,
      null
    )
      .then((response) => {
        if (!response?.message) {
          setProducts(response || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRowClick = (details, actionType) => {
    if (actionType === "DELETE") {
      setDeleteDetails(details);
    } else {
      setRecordDetails(details);
    }
  };

  const onInputChange = (event) => {
    setRecordDetails({
      ...recordDetails,
      [event.target.name]: event.target.value,
    });
  };

  const createRecord = () => {
    let payload = {
      name: recordDetails.name,
      products: recordDetails.products.map((p) => p.id),
      severity: recordDetails.severity.value,
      primaryOwner: recordDetails.primaryOwner.value,
      secondaryOwner: recordDetails.secondaryOwner.value,
      categoryType: recordDetails.categoryType.value,
      supplierMessage: !!recordDetails.supplierMessage,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.COMPLAINT_TYPES}`,
      payload
    ).then((response) => {
      if (response?.message) {
        toast.error(response?.message, { autoClose: 2000 });
        return;
      }

      if (response.name) {
        toast.success("Complaint Type created succesfully", {
          autoClose: 2000,
        });
        setRecordDetails(null);
        loadData(filters);
      }
    });
  };

  const updateRecord = (details) => {
    let payload = {
      ...details,
      products: details.products.map((p) => p.id),
      severity: details.severity.value,
      primaryOwner: details.primaryOwner.value,
      secondaryOwner: details.secondaryOwner.value,
      categoryType: details.categoryType.value,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.COMPLAINT_TYPES}/${details.id}`,
      payload
    ).then((response) => {
      if (response?.message) {
        toast.error(response?.message, { autoClose: 2000 });
        return;
      }
      if (response.name) {
        toast.success("Complaint type updated successfully", {
          autoClose: 2000,
        });
        setRecordDetails(null);
        setDeleteDetails(null);
        loadData(filters, true);
      }
    });
  };

  const deleteComplaintType = (id) => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.COMPLAINT_TYPES}/${id}`
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }
        if (res === "ok") {
          toast.success("Complaint Type deleted successfully", {
            autoClose: 2000,
          });
          setRecordDetails(null);
          setDeleteDetails(null);
          loadData(filters, true);
        }
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };

  const isDisabled = () => {
    const {
      name,
      products = [],
      severity,
      categoryType,
      secondaryOwner,
      primaryOwner,
    } = recordDetails;
    return (
      !name ||
      products.length === 0 ||
      !severity ||
      !categoryType ||
      !secondaryOwner ||
      !primaryOwner
    );
  };

  return (
    <div className="ct-container">
      <div className="main-search-field">
        <div className="search-field">
          <TextField
            placeholder="Search by complaint type"
            variant="outlined"
            color="primary"
            size="small"
            className="complaint-type-field"
            value={filters.name || ""}
            name="name"
            onChange={(event) => {
              event.persist();
              setFilters((prevFilters) => ({
                ...prevFilters,
                name: event?.target?.value,
              }));
            }}
          />
          <SearchIcon
            className="main-search-icon"
            onClick={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page: 1,
              }));
              setFiltersAfterClickingOnApply((prevFilters) => ({
                ...prevFilters,
                name: filters.name,
              }));
              loadData({ ...filters, page: 1 }, false);
            }}
          />
        </div>

        <div className="create-button">
          <Button variant="contained" onClick={() => setRecordDetails({})}>
            Create
          </Button>
        </div>
      </div>
      <MuiTable
        columnsList={ComplainHeaderConfig}
        dataList={records.results || []}
        filters={filters}
        pageCount={records.totalPages}
        onClick={onRowClick}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
      {/* <MaterialUITable
        filters={filters}
        columnsList={ComplainHeaderConfig}
        dataList={records.results || []}
        pageCount={records.totalPages}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
        onClick={onRowClick}
      /> */}

      {recordDetails && (
        <CustomModal
          title={
            recordDetails.id ? "Edit Complaint Type" : "Add Complaint Type"
          }
          onClose={() => setRecordDetails(null)}
        >
          <div className="form-container">
            <TextField
              size="small"
              label="Complaint Type Name"
              value={recordDetails.name || ""}
              onChange={onInputChange}
              name="name"
            />
            <div className="category-grid">
              <div>Select products</div>
              <Select
                isMulti
                options={products}
                classNamePrefix="subway-select"
                getOptionLabel={(op) => `${op.name} - ${op.code}`}
                getOptionValue={(op) => op.id}
                placeholder="Products"
                value={recordDetails.products || null}
                onChange={(value) => {
                  setRecordDetails({
                    ...recordDetails,
                    products: value,
                  });
                }}
              />
            </div>
            <div className="category-grid">
              <div>Select Severity</div>
              <Select
                options={SEVERITY_OPTIONS}
                classNamePrefix="subway-select"
                value={recordDetails.severity}
                placeholder="Severity"
                onChange={(value) => {
                  setRecordDetails({
                    ...recordDetails,
                    severity: value,
                  });
                }}
              />
            </div>
            <div className="category-grid">
              <div>Select Category Type</div>
              <Select
                options={complaintCategoryTypeOptions}
                classNamePrefix="subway-select"
                value={recordDetails.categoryType}
                placeholder="category type"
                onChange={(value) => {
                  setRecordDetails({
                    ...recordDetails,
                    categoryType: value,
                  });
                }}
              />
            </div>
            <div className="category-grid">
              <div>Select Primary Owner</div>
              <Select
                options={complaintTypeOwners}
                classNamePrefix="subway-select"
                value={recordDetails.primaryOwner}
                placeholder="Primary Owner"
                onChange={(value) => {
                  setRecordDetails({
                    ...recordDetails,
                    primaryOwner: value,
                  });
                }}
              />
            </div>

            <div className="category-grid">
              <div>Select Secondary Owner</div>
              <Select
                options={complaintTypeOwners}
                classNamePrefix="subway-select"
                value={recordDetails.secondaryOwner}
                placeholder="Secondary Owner"
                onChange={(value) => {
                  setRecordDetails({
                    ...recordDetails,
                    secondaryOwner: value,
                  });
                }}
              />
            </div>

            <FormControlLabel
              value="start"
              checked={recordDetails.supplierMessage || false}
              control={
                <Checkbox
                  color="primary"
                  onChange={(value) => {
                    setRecordDetails({
                      ...recordDetails,
                      supplierMessage: !!!recordDetails.supplierMessage,
                    });
                  }}
                />
              }
              label="Show supplier message for item retrieval"
              labelPlacement="start"
            />

            <Button
              variant="contained"
              fullWidth
              disabled={isDisabled()}
              onClick={() => {
                {
                  recordDetails.id
                    ? updateRecord(recordDetails)
                    : createRecord();
                }
              }}
            >
              Save
            </Button>
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal
          onNo={() => setDeleteDetails(null)}
          onYes={() => deleteComplaintType(deleteDetails?.id)}
        />
      )}
    </div>
  );
};

export default ComplaintTypes;
