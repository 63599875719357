import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { getHeaderConfig } from "./config";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import MuiTable from "../../packages/common-utilities/core/mui-table";
import { Button, TextField, InputAdornment } from "@mui/material";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import { getOfflineData } from "../../utils/offline-services";
const ArTable = ({ showArDataFun }) => {
  const [tableData, setTableData] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [entity, setEntity] = useState("");
  const previousController = useRef();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 6,
    sortBy: "-weekEndingDate",
  });
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: INTRANETHOSTNAME + REST_URLS.ENTITY_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          entity: p._id,
        };
      });
      setEntityOptions(updatedOptions);
    });
  };
  const onInputChange = (event, value, reason) => {
    setEntityOptions([]);
    if (value?.trim()) {
      getData(value);
      setEntity(value);
    } else {
      setTableData([]);
      setEntity("");
      setEntityOptions([]);
      setFilters({ page: 1, limit: 6, sortBy: "-weekEndingDate" });
    }
  };

  const loadData = (filters) => {
    for (let key in filters) {
      if (!filters[key]) {
        delete filters[key];
      }
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.AR_DATA}`,
      null,
      filters
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => ({
            ...ele,
            weekEndingDate: moment(ele.weekEndingDate).format("DD/MM/YYYY"),
          }));
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const searchHandler = () => {
    setFilters((prevVal) => ({ ...prevVal, page: 1 }));
    loadData({ ...filters, page: 1 });
  };

  useEffect(() => {
    if (!entity) {
      setTableData([]);
    }
  }, [entity]);
  useEffect(() => {
    const stores = getOfflineData("user")?.storeList;
    setStoreList(stores);
  }, []);
  useEffect(() => {
    loadData(filters);
  }, []);
  return (
    <div className="ar-con">
      <div className="search-com">
        <div className="search-elememts-con">
          {/* <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.entity ? option.entity.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setTableData([]);
                  setEntity("");
                  setEntityOptions([]);
                  setFilters({ page: 1, limit: 10, sortBy: "-weekEndingDate" });
                  setAfterSearchFilters(null, null, true);
                  setDateRange(null);
                }}
              />
            }
            options={entityOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                type="number"
                label="Search By Entity"
                sx={{ width: "15em" }}
                size="small"
              />
            )}
            inputValue={entity + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                setEntity(val.entity);
                loadData({ ...filters, entity: val.entity, page: 1 });
                setAfterSearchFilters(val.entity, 1);
              }
            }}
          /> */}
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label=" From"
            onChange={(event) => {
              setFilters((prevVal) => ({
                ...prevVal,
                startDate: event.target.value,
              }));
            }}
          />
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label="to"
            onChange={(event) => {
              setFilters((prevVal) => ({
                ...prevVal,
                endDate: event.target.value,
              }));
            }}
          />
          <Button
            onClick={searchHandler}
            variant="contained"
            sx={{ ml: 2 }}
            // disabled={entity ? false : true}
          >
            Search
          </Button>
        </div>
        <CancelIcon
          color="error"
          onClick={() => showArDataFun(false)}
          sx={{ cursor: "pointer" }}
          fontSize="large"
        />
      </div>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
    </div>
  );
};

export default ArTable;
