import React, { useState, useContext, useRef, useEffect } from "react";
import "./index.scss";
import {
  Button,
  Grid,
  TextField,
  Box,
  Drawer,
  InputAdornment,
  Popover,
} from "@mui/material";
import Select from "react-select";
import { toast } from "react-toastify";
import { GlobalStoreContext } from "../../global-states/Context";
import Autocomplete from "@mui/material/Autocomplete";
import { invokeApi, plainApi } from "../../utils/http-service";
import axios from "axios";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import CreateMemo from "./create-memo";
import ShowMemo from "./show-memo";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import {
  dateFun,
  getLabelsAndValuesForSelect,
} from "../../../common-utilities/utils";
import {
  convertDateToIsoString,
  dateFormats,
} from "../../../common-utilities/utils/date";
const memoCols = [
  { id: "category", label: "Category" },
  { id: "enteredBy", label: "Entered By" },
  { id: "memoDate", label: "Created At" },
  { id: "memoDate", label: "memo Date" },
  { id: "memo", label: "Memo" },
  { id: "appliedDate", label: "Applied Date" },
  { id: "deposition", label: "Deposition" },
  { id: "closedAt", label: "Completed Date" },
  { id: "isClosed", label: "Completed" },
  {
    label: "Action",
    id: "action",
    render: (data, onClick) => {
      return (
        <TableCell align="center" padding="none" disabled={true}>
          {!data?.isClosed ? (
            <EditIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                onClick(data);
              }}
            />
          ) : (
            "-"
          )}
        </TableCell>
      );
    },
  },
];
const LeaseMemos = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const [data, setData] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [dateRange, setDateRange] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [memoFlag, setMemoFlag] = useState(false);
  const previousController = useRef();
  const [leaseIdList, setLeaseIdList] = useState([]);
  const [memoCategoryList, setMemoCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
    isClosed: false,
    isEdit: false,
    appliedDate: "",
    id: "",
    deposition: null,
  });
  const checkForValidStoreNumberSequenceNumberAndSatelliteNumber = (
    storeNo,
    sequenceNo,
    satelliteNum
  ) => {
    return (
      Number.isInteger(parseInt(storeNo)) &&
      Number.isInteger(parseInt(sequenceNo)) &&
      Number.isInteger(parseInt(satelliteNum))
    );
  };
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`, null, {
      typeModel: "StoreLease",
    }).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        const results = res.map((ele) => ({
          label: ele.category,
          value: ele.id,
        }));
        setMemoCategoryList(results);
      }
    });
  }, []);
  useEffect(() => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );

    if (validStoreCombination) {
      getLeaseList();
    }
  }, [storeInfo]);
  const getLeaseList = () => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    if (validStoreCombination) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_LEASES}${storeInfo.storeNo}`,
        null,
        {
          satelliteNo: storeInfo.satelliteNo,
          sequenceNo: storeInfo.sequenceNo,
        }
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
            setLeaseIdList(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );

    if (totalPages === null) {
      if (validStoreCombination) {
        if (leaseInfo.leaseId) {
          getTableData(pages);
        }
      }
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          if (validStoreCombination) {
            if (leaseInfo.leaseId) {
              getTableData(pages);
            }
          }
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.id,
          satelliteNum: p.satelliteNo,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });

      setStoreOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    setSatteliteList([]);
    setSequenceList([]);
    setLeaseIdList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        satelliteNo: null,
        sequenceNo: null,
      });
    } else {
      clearData();
    }
  };
  const clearData = () => {
    setData([]);
    setStoreInfo({
      storeNo: "",
      satelliteNo: "",
      sequenceNo: "",
    });
    setSatteliteList([]);
    setSequenceList([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setDateRange(null);
    setLeaseInfo({ leaseId: null });
    setLeaseIdList([]);
    setCategory("");
  };

  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (storeInfo.storeNo) {
      listingLeaseHandler(storeInfo.storeNo);
    }
  }, []);

  useEffect(() => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    if (validStoreCombination) {
      if (leaseInfo.leaseId) {
        getTableData(1, true);
      }
    }
  }, [leaseInfo.leaseId]);

  const getTableData = (page, hasToReset, providedSequenceAndSatellite) => {
    let checkingCondition = !pageArray.includes(page);
    let params = {
      page: page,
      limit: 50,
      sortBy: "-createdAt",
      typeModel: "StoreLease",
      storeNo: parseInt(storeInfo.storeNo),
      satelliteNo: storeInfo.satelliteNo,
      sequenceNo: storeInfo.sequenceNo,
      category: category,
    };
    if (dateRange?.startDate) {
      params["startDate"] = convertDateToIsoString(
        dateRange.startDate,
        dateFormats.yymmdd
      );
    }
    if (dateRange?.endDate) {
      params["endDate"] = convertDateToIsoString(
        dateRange.endDate,
        dateFormats.yymmdd
      );
    }
    if (searchWord) {
      params["word"] = searchWord;
    }
    if (leaseInfo.leaseId) {
      params["type"] = parseInt(leaseInfo.leaseId);
    }
    if (hasToReset) {
      checkingCondition = true;
    }
    const providedSatelliteNumber = providedSequenceAndSatellite?.satelliteNo;
    if (Number.isInteger(parseInt(providedSatelliteNumber))) {
      params.satelliteNo = providedSatelliteNumber;
    }

    const providedSequenceNumber = providedSequenceAndSatellite?.sequenceNo;

    if (Number.isInteger(parseInt(providedSequenceNumber))) {
      params.sequenceNo = providedSequenceNumber;
    }
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        params.storeNo,
        params.sequenceNo,
        params.satelliteNo
      );
    if (validStoreCombination && leaseInfo.leaseId && category) {
      if (checkingCondition) {
        plainApi(
          HTTP_METHODS.GET,
          `${HOSTNAME}${REST_URLS.MEMOS}`,
          null,
          params
        )
          .then((res) => {
            if (res?.message) {
              toast.error(res.message, { autoClose: 2000 });
              return;
            }
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              let details = {};
              details["id"] = item?.id;
              if (item?.createdBy) {
                details[
                  "enteredBy"
                ] = `${item.createdBy?.firstName} ${item.createdBy?.lastName}`;
              }
              if (item?.createdAt) {
                details["memoDate"] = moment(item.createdAt).format(
                  "DD-MM-YYYY"
                );
              }
              if (item?.appliedDate) {
                details["appliedDate"] = moment(item.appliedDate).format(
                  "DD-MM-YYYY"
                );
                details["isoFormatAppliedDate"] = item.appliedDate;
              }
              if (item?.createdAt) {
                details["createdAt"] = moment(item.createdAt).format(
                  "DD-MM-YYYY"
                );
              }
              if (item?.closedAt) {
                details["closedAt"] = moment(item.closedAt).format(
                  "DD-MM-YYYY"
                );
              }
              if (item?.memo) {
                details["memo"] = item.memo;
              }
              if (item?.deposition) {
                details["deposition"] = item.deposition;
              }
              details["isClosed"] = item.isClosed;
              if (item?.category?.category) {
                details["category"] = item.category.category;
              }
              return details;
            });
            let array = [...pageArray];
            array.push(page);
            if (hasToReset) {
              setPageArray([1]);
              setData(results);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              setPageArray(array);
              setData((prevData) => [...prevData, ...results]);
            }
          })
          .catch((err) => {
            console.log(err);
            setData([]);
          });
      }
    }
    // else {
    //   toast.error("Information Not Valid", { autoClose: 2000 });
    // }
  };
  useEffect(() => {
    if (category) {
      getTableData(1, true);
    }
  }, [category]);
  const handleClose = () => setAnchorEl(null);

  const resetDateRange = () => {
    setDateRange(null);
    handleClose();
  };
  const editMemoHandler = (data) => {
    setShowLargeText({
      isOpen: true,
      text: data.memo,
      id: data.id,
      isClosed: data.isClosed,
      isEdit: true,
      appliedDate: data?.isoFormatAppliedDate || "",
      deposition: data?.deposition,
    });
  };
  return (
    <div className="trasfer-con">
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item md={2}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                const { storeNo } = val;
                listingLeaseHandler(storeNo);
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value == storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo({ ...storeInfo, satelliteNo: val.value });
              const satelliteNo = val?.value;
              const validStoreCombination =
                checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  storeInfo.sequenceNo
                );
              if (validStoreCombination) {
                getLeaseList();
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value == storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo({ ...storeInfo, sequenceNo: val.value });
              const sequenceNo = val?.value;
              const validStoreCombination =
                checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  sequenceNo
                );
              if (validStoreCombination) {
                // getTableData(1, true, { sequenceNo });
                getLeaseList();
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="lease Id"
            className="sel-item"
            options={leaseIdList}
            value={
              ![undefined, null].includes(leaseInfo.leaseId)
                ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                : ""
            }
            onChange={(val) => {
              setLeaseInfo({ leaseId: val?.value });
              // getTableData(1, true);
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Select Category"
            options={memoCategoryList}
            value={
              memoCategoryList.find((ele) => ele.value === category) || null
            }
            onChange={(val) => {
              setCategory(val?.value);
            }}
          />
        </Grid>
        <Grid item md={2} sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            disabled={
              [null, ""].includes(leaseInfo.leaseId) || !category ? true : false
            }
            onClick={() => {
              setMemoFlag(true);
            }}
          >
            Create New
          </Button>
        </Grid>
      </Grid>
      <p className="memo-search-title">Searchable items</p>

      <Grid container spacing={1}>
        <Grid item md={3}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            className="subs-text-field subs-text-date"
            label=" From"
            value={dateRange?.startDate || null}
            onChange={(event) => {
              setDateRange((prevVal) => ({
                ...prevVal,
                startDate: event.target.value,
              }));
            }}
          />
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            className="subs-text-field subs-text-date"
            label="To"
            value={dateRange?.endDate || null}
            onChange={(event) => {
              setDateRange((prevVal) => ({
                ...prevVal,
                endDate: event.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            id="outlined-basic"
            label="Memo"
            className="sel-item subs-text-field"
            variant="outlined"
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mr: 5 }}
            size="small"
            onClick={() => {
              const validStoreCombination =
                checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
                  storeInfo.storeNo,
                  storeInfo.sequenceNo,
                  storeInfo.satelliteNo
                );
              if (validStoreCombination) {
                if (leaseInfo.leaseId) {
                  getTableData(1, true);
                }
              }
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ display: "flex", flexDirection: "column", mt: "1em" }}
      >
        <div className="title header">Memos</div>
        <StickyHeaderCon
          containerHeight="table-con"
          columnsList={memoCols}
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          maxWords={15}
          showAllFunc={setShowLargeText}
          onClick={editMemoHandler}
        />
      </Grid>
      <Drawer anchor="right" open={memoFlag} onClose={() => setMemoFlag(false)}>
        <CreateMemo
          onSave={(details) => {
            setMemoFlag(false);
            getTableData(1, true);
            toast.success("Memo Added Successfully", { autoClose: 2000 });
          }}
          onClose={() => setMemoFlag(false)}
          details={{
            typeModel: "StoreLease",
            storeNo: storeInfo.storeNo,
            satelliteNo: storeInfo.satelliteNo,
            sequenceNo: storeInfo.sequenceNo,
            storeId: leaseInfo.leaseId,
            category: category,
          }}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showLargeText.isOpen}
        onClose={() => setShowLargeText({ isOpen: false, text: "" })}
      >
        <ShowMemo
          showMemoText={showLargeText}
          onClose={() => {
            setShowLargeText({
              isOpen: false,
              text: "",
              isClosed: false,
              isEdit: false,
              id: "",
              deposition: null,
            });
            getTableData(1, true);
          }}
        />
      </Drawer>
    </div>
  );
};
export default LeaseMemos;
