import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';

export const getHeaderConfig = (editVendor, setDeleteConfirm) => {
  const headers = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'Category Code',
      id: 'categoryCode',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Capex',
      id: 'capex',
      render: (data) => {
        return data.capex ? 'Yes' : 'No';
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        return (
          <>
            <Button
              variant="text"
              disabled={!isUpdateAllowed('master_data')}
              onClick={() => editVendor(data, 'EDIT')}
            >
              Edit
            </Button>
            <Button
              variant="text"
              disabled={!isDeleteAllowed('master_data')}
              onClick={() => setDeleteConfirm(data)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};
