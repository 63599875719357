import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import {
  mainInvokeApi,
  mainInvokeUploadFile,
  mainProgressiveInvokeApi,
  mainPlainApi,
} from "../../common-utilities/utils/http-service";

export const invokeApi = async (
  method,
  url,
  data,
  params,
  Authorization,
  additionalKey
) => {
  const key = additionalKey ? additionalKey : tokenMainKeys.technet;
  return mainInvokeApi(
    key,
    appSlugs.technet,
    method,
    url,
    data,
    params,
    Authorization
  );
};

export const progressiveInvokeApi = async (method, url, data, params) => {
  return mainProgressiveInvokeApi(
    tokenMainKeys.technet,
    appSlugs.technet,
    method,
    url,
    data,
    params
  );
};

export const invokeUploadFile = async (url, formData) => {
  return mainInvokeUploadFile(
    tokenMainKeys.technet,
    appSlugs.technet,
    url,
    formData
  );
};

export const plainApi = async (method, url, data, params) => {
  return mainPlainApi(
    tokenMainKeys.technet,
    appSlugs.technet,
    method,
    url,
    data,
    params
  );
};
