import { dateFun } from '../../../common-utilities/utils';
import { storeTypes } from '../../utils/constants';

export const mainModalTypes = {
  approver: 'Approver',
};

export const getCorrectStatus = (data, approverNo) => {
  if (data?.storeType === storeTypes.cocoBuild && approverNo > 3) {
    return '';
  }
  const approvalKey = `isApproved${approverNo}`;
  return data?.[approvalKey] ? 'Yes' : 'Pending';
};
export const setApproverModalDetails = (
  data,
  setModalDetails,
  approverNo,
) => {
  const approverKey = `approver${approverNo}`;

  const approverCommentKey = `approver${approverNo}Comment`;
  const dateKey = `Approver${approverNo} Date`;
  const approverData = [
    ['Name', data?.[approverKey]?.name],
    ['Comment', data?.[approverCommentKey]],
    ['Status', getCorrectStatus(data, approverNo)],
    ['Approval Date', dateFun(data?.approvedDates?.[dateKey], true)],
  ];
  setModalDetails({
    type: mainModalTypes.approver,
    data: approverData,
    title: `Approver ${approverNo} Details`,
  });
};
