import React, { useState, useEffect } from "react";
import CustomBarChart from "./bar-chart";
import "./index.scss";
import CustomPiChart from "./pi-chart";

const SlaBreaches = (props) => {
  const { toggle, setToggle } = props;
  const [chartData, setChartData] = useState([]);
  const slaFun = (val) => {
    let data = [];
    props.data.forEach((iter) => {
      if (iter.level == val)
        data.push({
          name: iter.deptName,
          count: iter.count,
        });
    });
    return data;
  };

  useEffect(() => {
    let data = [];
    switch (toggle) {
      case "L0 Escalation":
        data = slaFun(0);
        break;
      case "L1 Escalation":
        data = slaFun(1);
        break;
      case "L2 Escalation":
        data = slaFun(2);
        break;
      case "L3 Escalation":
        data = slaFun(3);
        break;
      case "L4 Escalation":
        data = slaFun(4);
        break;
      default:
        let temp = {};
        props.data.forEach((iter) => {
          if (temp[iter.deptName] != undefined) {
            temp[iter.deptName] = temp[iter.deptName] + iter.count;
          } else {
            temp[iter.deptName] = iter.count;
          }
        });
        data = Object.keys(temp).map((iter) => {
          return {
            name: iter,
            count: temp[iter],
          };
        });

        break;
    }
    setChartData(data);
  }, [toggle]);

  return (
    <div className="p10">
      <div className="inner-tab">
        {[
          "Department-wise Escalation",
          "L0 Escalation",
          "L1 Escalation",
          "L2 Escalation",
          "L3 Escalation",
          "L4 Escalation",
        ].map((iter) => {
          return (
            <span
              onClick={() => {
                setToggle(iter);
              }}
              className={`${"subnavbar"} ${
                toggle === iter ? "active-inner-tab" : "in-active-inner-tab"
              }`}
            >
              {iter}
            </span>
          );
        })}
      </div>
      <br />
      <div className="other-info-container">
        <CustomBarChart
          title={toggle}
          data={[
            {
              name: toggle,
              data: chartData.map((iter) => iter.count),
            },
          ]}
          category={chartData.map((iter) => iter.name)}
          name={toggle}
          stacked={false}
          disableDownload={false}
          empty={chartData.length > 0 ? false : true}
          downloadKey="Department"
          downloadValue="Count"
        />
        <CustomPiChart
          title={
            toggle == "Department-wise Escalation"
              ? "Top Departments with Max Escalations"
              : "Top " + toggle
          }
          data={chartData}
          xDataKey={"name"}
          bDataKey={"count"}
        />
      </div>
    </div>
  );
};
export default SlaBreaches;
