import {
  validDocumentTypes,
  validStoreTypes,
} from "../../utils/document-constants";
import { addPayloadDates } from "./utils/payload-utils";

// restaurantId is replaced with Store on intranet migration
export const getPayload = (formDetails, docs, restaurantId, storeType) => {
  const { type = {} } = formDetails;
  const { value } = type;
  let allDocs;
  let payload = {
    type: value,
    store: restaurantId,
    storeType,
    details: {
      ...formDetails,
      docs,
    },
  };
  delete payload.details.type;
  delete payload.details.storeType;
  const na = payload.details.notApplicable
    ? { na: true, docs: [] }
    : { na: false };
  delete payload.details.notApplicable;
  addPayloadDates(formDetails, payload);
  switch (storeType) {
    case validStoreTypes.fofo:
      switch (value) {
        case validDocumentTypes.subleaseAgreement:
          payload.details = { ...payload.details, ...na };
          payload.details.leaseType = payload.details.leaseType?.value;
          payload.details.stampedAndRegistered =
            payload.details.stampedAndRegistered?.value;
          return payload;
        default:
          payload.details = { ...payload.details, ...na };
          return payload;
      }
    case validStoreTypes.cocoAcquired:
      switch (value) {
        case validDocumentTypes.license:
          allDocs = docs.map((item) => {
            return { ...item, type: payload.details.licenseType.value };
          });
          payload.details = {
            ...payload.details,
            licenseType: payload.details.licenseType.value,
            docs: allDocs,
            ...na,
          };
          const isApplied = payload?.details?.isApplied?.value;
          if (
            Object.prototype.hasOwnProperty.call(payload.details, "isApplied")
          ) {
            payload.details.isApplied = isApplied;
          }
          return payload;

        case validDocumentTypes.preAgreementDocs:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };
          return payload;

        case validDocumentTypes.agreementAndPostAgreementDocs:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          if (payload?.details?.rsecdepositinmonths) {
            payload.details.rsecdepositinmonths =
              payload.details.rsecdepositinmonths.value;
          }
          if (
            payload?.details?.rentRevShareDeliveryExcludesAggregatorsComission
          ) {
            payload.details.rentRevShareDeliveryExcludesAggregatorsComission =
              payload.details.rentRevShareDeliveryExcludesAggregatorsComission.value;
          }

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };

          return payload;

        case validDocumentTypes.mfaRider:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };
          return payload;

        case validDocumentTypes.nocForGst:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
          };
          return payload;
        case validDocumentTypes.electricityBillInNameOfLandload:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
          };
          return payload;
        default:
          payload.details = { ...payload.details, ...na };
          return payload;
      }
    case validStoreTypes.cocoBuilt:
      switch (value) {
        case validDocumentTypes.license:
          allDocs = docs.map((item) => {
            return { ...item, type: payload.details.licenseType.value };
          });
          payload.details = {
            ...payload.details,
            licenseType: payload.details.licenseType.value,
            docs: allDocs,
            ...na,
          };

          const isApplied = payload?.details?.isApplied?.value;
          if (
            Object.prototype.hasOwnProperty.call(payload.details, "isApplied")
          ) {
            payload.details.isApplied = isApplied;
          }

          return payload;

        case validDocumentTypes.preAgreementDocs:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };
          return payload;

        case validDocumentTypes.agreementAndPostAgreementDocs:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          if (payload?.details?.rsecdepositinmonths) {
            payload.details.rsecdepositinmonths =
              payload.details.rsecdepositinmonths.value;
          }
          if (
            payload?.details?.rentRevShareDeliveryExcludesAggregatorsComission
          ) {
            payload.details.rentRevShareDeliveryExcludesAggregatorsComission =
              payload.details.rentRevShareDeliveryExcludesAggregatorsComission.value;
          }

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };

          return payload;

        case validDocumentTypes.mfaRider:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });

          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
            ...na,
          };
          return payload;
        case validDocumentTypes.nocForGst:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
          };
          return payload;
        case validDocumentTypes.electricityBillInNameOfLandload:
          allDocs = docs.map((item) => {
            return { ...item, type: formDetails?.documentType?.value };
          });
          payload.details = {
            ...payload.details,
            docs: allDocs,
            documentType: formDetails?.documentType?.value,
          };
          return payload;
        default:
          payload.details = { ...payload.details, ...na };
          return payload;
      }
    default:
      break;
  }
  return {};
};
