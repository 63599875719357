/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import "./index.scss";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";

export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState("/");

  React.useEffect(() => {
    const list = SIDE_MENU();
    setMenuItems(list);
  }, []);
  React.useEffect(() => {
    setActiveMenu(
      menuItems.find((m) => m.url === window.location.pathname) || {}
    );
  }, [menuItems]);
  const onMenuClick = () => {};

  return (
    <div className="side-menu-v2-items">
      {menuItems.map((menu, index) => {
        return (
          <>
            {
              <div
                key={menu.value}
                className={
                  menu.value === activeMenu.value
                    ? "menu-item menu-item-active"
                    : "menu-item"
                }
                onClick={() => {
                  onMenuClick();
                  setActiveMenu(menu);
                  navigate(menu.url);
                }}
              >
                <div className="menu-name align-center">
                  <span>{menu.logo}</span>
                  <span className="side-bar-menu-label">{menu.label}</span>
                </div>
              </div>
            }
          </>
        );
      })}
    </div>
  );
}
