// App slugs for different apps
export const appSlugs = {
  docuStorage: "docu",
  technet: "tech-net",
  bdLead: "bd-lead",
  cfApproval: "cf-approval",
  subs: "subs",
  qnet: "qnet",
  pettyExpense: "petty-expense",
  trainingManual: "training-manual",
  policies: "policies",
  franchisePolicy: "franchise-policy",
  vendorInvoiceSubmission: "vendor_invoice_submission",
  invoiceTracking: "invoice_tracking",
  subwayFinace: "subway_finance",
  plReport: "pl-report",
  electricityConsumption: "electricity-consumption"
};
//name that we store in localstorage
export const tokenMainKeys = {
  docuStorage: "subway-docu",
  bdLead: "subwaylead",
  cfApproval: "subwayofapproval",
  technet: "tech-net",
  invoiceTracking: "subway-invoice-tracking",
  intranet: "subway",
  subs: "subs",
  qnet: "qnet",
  pettyExpense: "petty-expense",
  storeMaster: "subwayStoreMaster",
  vendorOnboard: "subway-vendor-onboard",
  subwayFinance: "subway-finance",
  trainingManual: "training-manual",
  policies: "policies",
  franchisePolicy: "franchise-policy",
  vendorInvoiceSubmission: "vendor-invoice-submission",
  plReport: "pl-report",
  electricityConsumptionTable: "electricity-consumption"
};

export const DATE_FORMATS = {
  "DD.MM.YYYY": "DD.MM.YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD.MM.YYYY hh:MM": "DD.MM.YYYY hh:MM",
  "DD MMM YY hh:MM": "DD MMM YY hh:MM",
  "MMM DD YYYY hh:MM": "MMM DD YYYY hh:MM",
  "YYYY-MM-DD": "YYYY-MM-DD"
};
