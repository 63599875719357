import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import PLTable from "../components/pl-table";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";

export const plReportsRouteConfig = {
  mainPath: {
    path: "/",
    element: <PrivateRoute />,
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: "/pl-report",
  routes: [
    {
      path: "/",
      Element: PLTable,
      element: <PLTable />,
      exact: true,
    }        
  ],
};

const PLReportsRoutes = ({
  token = tokenMainKeys.plReport,
  slug = appSlugs.plReport,
}) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<PrivateRoute token={token} slug={slug} />}
      >
        <Route path="/" element={<PLTable token={token} slug={slug} />} />                
      </Route>
    </Routes>
  );
};

export default PLReportsRoutes;
