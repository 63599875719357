import { useEffect, useState } from "react";
import { appSlugs } from "../../../../../utils/constants";
import AutoCompleteMultiSelect from "../../../../common-utilities/core/autocomplete-multiselect";
import { vendorList } from "../apis";
import {
  clearProperty,
  setProperty,
} from "../../../../common-utilities/utils/misc";
import { Autocomplete, TextField } from "@mui/material";
import { VENDOR_INVOICE_SUBMISSION_ROLES } from "../../../utils/constants";
import { getOfflineData } from "../../../utils/offline-services";
import { vendorInvPaymtStatusLst } from "../../common-components/utils";
import ClearIcon from "@mui/icons-material/Clear";
export const VendorInvoiceFilters = ({
  slug,
  token,
  filters,
  setFilters,
  role,
}) => {
  const [lists, setLists] = useState({
    vendorList: [],
    paymentStatusList: vendorInvPaymtStatusLst(),
  });
  useEffect(() => {
    if (slug !== appSlugs.vendorInvoiceSubmission) {
      return;
    }
    const user = getOfflineData("user", token);
    const filter = { mandatoryFields: JSON.stringify(["state"]) };
    if (user?.role === VENDOR_INVOICE_SUBMISSION_ROLES.VENDOR) {
      filter.userVendors = true;
    }
    vendorList(filter, slug, token, (list) =>
      setProperty("vendorList", list, setLists)
    );
  }, [slug]);
  return (
    <>
      {slug === appSlugs.vendorInvoiceSubmission && (
        <>
          <TextField
            label="Search Invoice Number"
            value={filters?.invoiceNumber || ""}
            onChange={(e) => {
              setProperty("invoiceNumber", e.target.value, setFilters);
            }}
            size="small"
          />
          <div className="vendor-name-list-container">
            <AutoCompleteMultiSelect
              fullWidth={true}
              options={lists.vendorList}
              onChange={(e, val) => {
                setProperty("vendor", val, setFilters);
              }}
              value={filters?.vendor || []}
              placeholder="Vendors"
            />
          </div>
          <Autocomplete
            getOptionLabel={(option) => option?.label}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearProperty("paymentStatus", setFilters);
                }}
              />
            }
            options={lists.paymentStatusList}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Payment Status"
                className="filter-width"
              />
            )}
            onChange={(event, val) => {
              if (val?.value) {
                setProperty("paymentStatus", val?.value || null, setFilters);
                return
              }
              
              clearProperty("paymentStatus", setFilters);
            }}
          />
        </>
      )}
    </>
  );
};
