import { dateFun } from "../../../common-utilities/utils";

export const getPLTableHeader = (response) => {
  const headers = [
    {
      label: "Store Name",
      id: "Store Name",
      render: (data) => {
        return <div style={{ width: "120px" }}>{data["Store Name"]}</div>;
      },
    },
    { label: "Store Code", id: "Store Code" },
    { label: "Type", id: "Type" },
    {
      label: "Opening Date",
      id: "Opening Date",
      render: (data) => {
        return dateFun(data["Opening Date"], true);
      },
    },
    { label: "Region", id: "Region" },
    { label: "Tier", id: "Tier" },
    { label: "Circle", id: "Circle" },
    { label: "City", id: "City" },
    { label: "Format", id: "Format" },
    { label: "Area Manager", id: "Area Manager" },
    { label: "P&L Item", id: "P&L Item" },
    { label: "uem", id: "uem" },
  ];
  response.forEach((key) => {
    if (!headers.find((item) => item.label === key)) {
      headers.push({
        label: key,
        id: key,
      });
    }
  });
  return headers;
};
export const MONTHS_LIST = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];
