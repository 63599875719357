import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import MainContainer from "../container";
import { getOfflineData } from "../utils/offline-services";
import {
  getUserDetailsFromIntranet,
  redirectToIntranetPortal,
} from "../utils/common-utils";
import { userStates } from "../utils/constants";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";

const InvoicePrivateRoute = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const [user, setUser] = useState(userStates.isProcessing);
  const checkAndGrantUserAccess = (tokens) => {
    getUserDetailsFromIntranet(
      access,
      (userDetails) => {
        if (userDetails && !userDetails?.message) {
          setUser(userDetails);
        }
        setSearchParams({});
      },
      tokens,
      token,
      slug
    );
  };

  useEffect(() => {
    const userDetails = getOfflineData("user", token);
    const userDetailsNotPresent = !!!userDetails;
    const accessAndRefreshTokensArePresent = !!access && !!refresh;
    const tokens = {
      access: {
        token: access,
        expires: accessTokenExpiry?.trim(),
      },
      refresh: {
        token: refresh,
        expires: refreshTokenExpiry?.trim(),
      },
    };
    if (userDetailsNotPresent) {
      if (accessAndRefreshTokensArePresent) {
        checkAndGrantUserAccess(tokens);
      } else {
        redirectToIntranetPortal(token);
      }
    } else if (accessAndRefreshTokensArePresent) {
      checkAndGrantUserAccess(tokens);
    } else {
      setUser(userDetails);
    }
  }, [access, refresh, token, slug]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer token={token} slug={slug}>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default InvoicePrivateRoute;
