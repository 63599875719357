import { DATE_FORMATS } from '../../../../../utils/constants';
import { formatDate } from '../../../../common-utilities/utils';

const PriceHistory = ({ priceHistoryDetails }) => {
  return (
    <div className="price-history">
      <div className="table-header">
        <span>Old Price</span>
        <span>Update Price</span>
        <span>User Name</span>
        <span>Date</span>
      </div>
      {priceHistoryDetails.map((history) => {
        return (
          <div className="table-rows">
            <span>{history.oldPrice}</span>
            <span>{history.newPrice}</span>
            <span>{history.creatorName}</span>
            <span>{formatDate(history.date, DATE_FORMATS['DD-MM-YYYY'])}</span>
          </div>
        );
      })}
    </div>
  );
};
export default PriceHistory;
