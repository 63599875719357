import { vendorInvoicePaymentStatuses } from "../../utils/constants";

export const vendorInvPaymtStatusLst = (setLists) => {
  const paymentStatuses = Object.keys(vendorInvoicePaymentStatuses);
  const paymentStatsusList = paymentStatuses.map((item) => {
    return { label: item, value: vendorInvoicePaymentStatuses[item] };
  });

  if (typeof setLists === "function") {
    setLists(paymentStatsusList);
  }

  return paymentStatsusList;
};
