import { pageView } from "../constants";

export const getHeaderConfig = () => {
    const config = [
      {
        label: "Status",
        id: "status",
      },
      {
        label: "East",
        id: "East",
        render: (data, onClick) => {
          return (
            <div key="East">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "East",
                    status: data.status,
                    limit: data["East"],
                  })
                }
                className={`${data["East"] && data?.status !== "Grand Total" && "hover"}`}
              >
                {data["East"] || 0}
              </span>
            </div>
          );
        },
      },
      {
        label: "North",
        id: "North",
        render: (data, onClick) => {
          return (
            <div key="North">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "North",
                    status: data.status,
                    limit: data["North"],
                  })
                }
                className={`${data["North"]  && data?.status !== "Grand Total" && "hover"}`}
              >
                {data["North"] || 0}
              </span>
            </div>
          );
        },
      },
      {
        label: "South",
        id: "South",
        render: (data, onClick) => {
          return (
            <div key="South">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "South",
                    status: data.status,
                    limit: data["South"],
                  })
                }
                className={`${data["South"] && data?.status !== "Grand Total" && "hover"}`}
              >
                {data["South"] || 0}
              </span>
            </div>
          );
        },
      },
      {
        label: "West",
        id: "West",
        render: (data, onClick) => {
          return (
            <div key="West">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "West",
                    status: data.status,
                    limit: data["West"],
                  })
                }
                className={`${data["West"] && data?.status !== "Grand Total" && "hover"}`}
              >
                {data["West"] || 0}
              </span>
            </div>
          );
        },
      },
      {
        label: "Central",
        id: "Central",
        render: (data, onClick) => {
          return (
            <div key="Central">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "Central",
                    status: data.status,
                    limit: data["Central"],
                  })
                }
                className={`${data["Central"] &&  data?.status !== "Grand Total" &&"hover"}`}
              >
                {data["Central"] || 0}
              </span>
            </div>
          );
        },
      },
      {
        label: "Grand Total",
        id: "GrandTotal",
        render: (data, onClick) => {
          return (
            <div key="grandTotal">
              <span
                onClick={() =>
                  onClick(pageView.statusView, {
                    region: "total",
                    status: data.status,
                    limit: data["total"],
                  })
                } 
                className={`${data["total"] && data?.status !== "Grand Total" && "hover"}`}
              >
                {data["total"] || 0}
              </span>
            </div>
          );
        },
      },
    ];
    return config;
  };
  