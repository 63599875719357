/** @format */

import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../packages/qnet/routes/private-route";
import Setup from "../packages/qnet/components/setup";
import Cases from "../packages/qnet/components/cases";
import ViewCase from "../packages/qnet/components/view-case";
import Dashboard from "../packages/qnet/components/dashboard";
import Reports from "../packages/qnet/components/reports";
import ComplaintForm from "../packages/qnet/components/form";

const QnetRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoute />}>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/cases" element={<Cases />} />
        <Route exact path="/case/:caseId/edit" element={<ComplaintForm />} />
        <Route exact path="/case/:caseId" element={<ViewCase />} />
        <Route exact path="/setup" element={<Setup />} />
        <Route exact path="/reports" element={<Reports />} />
      </Route>
    </Routes>
  );
};

export default QnetRoutes;
