/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import "./index.scss";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU, USER_SIDE_MEMU } from "./config";
import { getOfflineData } from "../../packages/common-utilities/utils/offline-services";
import Intranetlogout from "../header/logout";
import logoutIcon from "../../resources/icons/logout.png";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("/chronology");
  const user = getOfflineData("subway", "user");

  React.useEffect(() => {
    setActiveMenu(
      SIDE_MENU.find((m) => m.url === window.location.pathname) || {}
    );
  }, [SIDE_MENU, window.location.pathname]);
  const onMenuClick = () => {};
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "space-between",
      }}
      role="presentation"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      <List>
        <div className="side-menu-header">
          <img
            src={require("../../resources/logo.png")}
            width="90%"
            style={{ margin: "auto" }}
          />
        </div>

        <div className="intranet-menu-items">
          {user?.role === "admin" &&
            SIDE_MENU.map((menu, index) => {
              let showMenuOption = true;
              return (
                <>
                  {
                    <div
                      key={menu.value}
                      className={
                        menu.value === activeMenu.value
                          ? "menu-item menu-active"
                          : "menu-item"
                      }
                      onClick={() => {
                        onMenuClick();
                        setActiveMenu(menu);
                        navigate(menu.url);
                      }}
                    >
                      <div className="menu-name align-center">
                        <div>{menu.logo}</div>
                        <span className="sidebarmenulabel">{menu.label}</span>
                      </div>
                    </div>
                  }
                </>
              );
            })}
          {user?.role !== "admin" &&
            USER_SIDE_MEMU.map((menu, index) => {
              let showMenuOption = true;
              return (
                <>
                  {
                    <div
                      key={menu.value}
                      className={
                        menu.value === activeMenu.value
                          ? "menu-item menu-active"
                          : "menu-item"
                      }
                      onClick={() => {
                        onMenuClick();
                        setActiveMenu(menu);
                        navigate(menu.url);
                      }}
                    >
                      <div className="menu-name align-center">
                        <span>{menu.logo}</span>
                        <span className="sidebarmenulabel">{menu.label}</span>
                      </div>
                    </div>
                  }
                </>
              );
            })}
        </div>
      </List>
      <div className="user-action-con">
        <div
          onClick={() => navigate("/my-account")}
          className="logout-container"
        >
          <PermIdentityIcon className="logout-img " />
          <div>
            <p>My Account</p>
          </div>
        </div>
        <div
          onClick={() => Intranetlogout(navigate)}
          className="logout-container"
        >
          <img src={logoutIcon} className="logout-img " />
          <div>
            <p>Logout</p>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={() => setOpen(!open)}>
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {list("left")}
      </Drawer>
    </div>
  );
}
