/** @format */

import { convertToCamelCaseFromUnderScore } from '../../../common-utilities/utils';
import {
  dayDifference,
  timeDifferenceInHours,
} from '../../../common-utilities/utils/time';

export const REPORT_TABS = [
  'Tickets',
  'Support Person',
  'SLA',
  'Stores',
  'TAT',
  'Agent Productivity',
];

export const TICKET_REPORT_STATUS = [
  {
    label: 'All',
    value: 'All',
    filterPayload: {
      status: 'ALL',
    },
  },
  {
    label: 'Open Tickets',
    value: 'OPENED',
    filterPayload: {
      status: 'OPENED',
    },
  },
  {
    label: 'Closed Tickets',
    value: 'CLOSED',
    filterPayload: {
      status: 'CLOSED',
    },
  },
  {
    label: 'Reopend Tickets',
    value: 'REOPENED',
    filterPayload: {
      status: 'REOPENED',
    },
  },
  {
    label: 'Resolved Tickets',
    value: 'RESOLVED',
    filterPayload: {
      status: 'RESOLVED',
    },
  },
];

export const getHeaderConfig = (status) => {
  const config = [
    {
      label: 'Ticket ID',
      id: 'id',
    },
    {
      label: 'Store Name',
      id: 'store.restaurantName',
    },
    {
      label: 'Region',
      id: 'store.region',
      valueConversionLogic: (val) => convertToCamelCaseFromUnderScore(val),
    },
    {
      label: 'Created On',
      id: 'createdAt',
      render: (data) => {
        return (
          <span>
            {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{' '}
            {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
          </span>
        );
      },
    },
    {
      label: 'Assigned to',
      id: 'assignedTo',
      render: (data) => {
        return <div>{data?.assignedTo?.name}</div>;
      },
    },
    {
      label: 'Ticket Description',
      id: 'complaintForm.details',
    },
    {
      label: 'Business Type',
      id: 'store.storeType',
    },
    {
      label: 'Email',
      id: 'email',
      render: (data) => {
        return <div>{data?.complaintForm?.email}</div>;
      },
    },
    {
      label: 'Status',
      id: 'status',
    },
  ];
  const selectedStatus = status?.filterPayload?.status;
  if (selectedStatus === 'ALL' || selectedStatus === 'RESOLVED') {
    config.splice(3, 0, {
      label: 'Closing Reason',
      id: 'resolveMessage',
    });
  }

  if (selectedStatus === 'ALL' || selectedStatus === 'CLOSED') {
    config.splice(
      3,
      0,
      {
        label: 'ClosedAt',
        id: 'closedAt',
        render: (data) => {
          return (
            <span id={data.id}>
              {data.closedAt && new Date(data.closedAt).toLocaleDateString()}{' '}
              {data.closedAt && new Date(data.closedAt).toLocaleTimeString()}
            </span>
          );
        },
      },
      {
        label: 'Turnaround Time(TAT)',
        id: 'Turnaround Time(TAT)',
        render: (data) => {
          return (
            <span id={data.id}>
              {timeDifferenceInHours(data.createdAt, data.closedAt)}
            </span>
          );
        },
      }
    );
  }
  return config;
};
export const getSLAHeaderConfig = () => [
  {
    label: 'Level',
    id: 'level',
  },
  {
    label: 'Created On',
    id: 'createdAt',
    render: (data) => {
      return (
        <span>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{' '}
          {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: 'Department',
    id: 'dept',
    render: (data) => {
      return <div>{data.dept.name}</div>;
    },
  },
  {
    label: 'Category',
    id: 'category',
    render: (data) => {
      return <div>{data.category.name}</div>;
    },
  },
  {
    label: 'Ticket',
    id: 'ticket.id',
  },
];
export const getStoreHeaderConfig = () => [
  {
    label: 'Store Name',
    id: 'Store Name',
  },
  {
    label: 'Department',
    id: 'Department',
  },
  {
    label: 'Category',
    id: 'Category',
  },
  {
    label: 'Count',
    id: 'Count',
    render: (data, onClick) => {
      return (
        <span onClick={() => onClick(data)} className="clickable userClickable">
          {data.Count}
        </span>
      );
    },
  },
];

export const getHeaderConfigForTableItems = () => [
  {
    label: 'Id',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Resolve Message',
    key: 'resolveMessage',
  },
];

export const tatConfig = () => {
  const config = [
    {
      label: 'Ticket Id',
      id: 'id',
    },
    {
      label: 'Ticket Category',
      id: 'ticketCategory',
      render: (data) => {
        return <span key="createdAt">{data?.category?.name}</span>;
      },
    },
    {
      label: 'Created On',
      id: 'createdAt',
      render: (data) => {
        return (
          <span key="createdAt">
            {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{' '}
            {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
          </span>
        );
      },
    },
    {
      label: 'Resolved on',
      id: 'resolvedAt',
      render: (data) => {
        return (
          <span key="resolvedAt">
            {data.resolvedAt && new Date(data.resolvedAt).toLocaleDateString()}{' '}
            {data.resolvedAt && new Date(data.resolvedAt).toLocaleTimeString()}
          </span>
        );
      },
    },
    {
      label: 'Resolution Time',
      id: 'resolutionDuration',
      render: (data) => {
        return (
          <span key="resolutionDuration">
            {dayDifference(data.resolvedAt, data.createdAt)}
          </span>
        );
      },
    },
  ];

  return config;
};

export const agentProductivity = () => {
  const config = [
    {
      label: 'Agent',
      id: 'name',
    },
    {
      label: 'Created',
      id: 'created',
    },
    {
      label: 'Resolved',
      id: 'resolvedCount',
    },
    {
      label: 'Closed',
      id: 'closedCount',
    },
  ];
  return config;
};
