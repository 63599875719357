import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ApprovedForms from './approved-forms';
import { UnApprovedForms } from './unapproved-forms';
import Badge from '@mui/material/Badge';
import './index.scss';
import { Grid } from '@mui/material';
import RejectedApproval from './rejected-approvals';
import { mainModalTypes } from './utils';
import { ApproverDetailsModal } from '../customerModals';
import CommonFilters from './components/common-filters';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <p>{children}</p>
        </Box>
      )}
    </div>
  );
}

export const Approvals = ({ setReloadPendingApprovals }) => {
  const [value, setValue] = useState(0);
  const [approvedCount, setAppprovedCount] = useState(0);
  const [unapprovedCount, setUnApprovedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [filters, setFilters] = useState({});

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const [modalDetails, setModalDetails] = useState(null);
  const closeModal = () => {
    setModalDetails(null);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container spacing={0}>
          <Grid item md={9} xs={12} className="custom-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                label={
                  <Badge badgeContent={`${unapprovedCount}`} color="primary">
                    <span className="badgeMargin">Pending</span>
                  </Badge>
                }
              />
              <Tab
                label={
                  <Badge badgeContent={`${approvedCount}`} color="primary">
                    <span className="badgeMargin">Approved</span>
                  </Badge>
                }
              />
              <Tab
                label={
                  <Badge badgeContent={`${rejectedCount}`} color="primary">
                    <span className="badgeMargin">Rejected</span>
                  </Badge>
                }
              />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <CommonFilters filters={filters} setFilters={setFilters} />
      <TabPanel value={value} index={0}>
        <UnApprovedForms
          setCountFunction={setUnApprovedCount}
          setReloadPendingApprovals={setReloadPendingApprovals}
          setMainModalDetails={setModalDetails}
          commonFilters={filters}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApprovedForms
          setCountFunction={setAppprovedCount}
          setMainModalDetails={setModalDetails}
          commonFilters={filters}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RejectedApproval
          setCountFunction={setRejectedCount}
          setMainModalDetails={setModalDetails}
          commonFilters={filters}
        />
      </TabPanel>
      {modalDetails?.type === mainModalTypes.approver && (
        <ApproverDetailsModal
          title={modalDetails?.title || ''}
          closeModal={closeModal}
          data={modalDetails?.data || []}
        />
      )}
    </div>
  );
};
