import React, { useContext, useRef, useState, useEffect } from "react";
import { GlobalStoreContext } from "../../global-states/Context";
import { Grid, Autocomplete, TextField } from "@mui/material";
import Select from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import { invokeApi, plainApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import axios from "axios";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import FileUpload from "../../../common-utilities/core/file-uploader";
import moment from "moment";

const noticeCols = [
  { label: "Store Number", id: "storeNo" },
  { label: "Satellite Number", id: "satelliteNo" },
  { label: "Sequence Number", id: "sequenceNo" },
  { label: "Type Of Notice", id: "noticeType" },
  { label: "Reason", id: "reason" },
  { label: "Date of Notice", id: "noticeDate" },
];
const Notices = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [sequenceList, setSequenceList] = useState([]);
  const [satelliteList, setSatelliteList] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [noticeTypeOptions, setNoticeTypeOptions] = useState([]);
  const previousController = useRef();

  const getTableData = (page, seqNo, noticeType, isEmptyArray) => {
    const params = {
      sortBy: "-createdAt",
      page: page,
      limit: 20,
      storeNumber: parseInt(storeInfo?.storeNo),
      satelliteNumber: storeInfo?.satelliteNo,
    };
    if (seqNo || storeInfo?.sequenceNo) {
      params["sequenceNumber"] = seqNo || storeInfo?.sequenceNo;
    }
    if (noticeType) {
      params["type"] = noticeType;
    }
    if (!pageArray.includes(page) || isEmptyArray) {
      const checkStoreInfo =
        checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
          params?.storeNumber,
          params?.satelliteNumber,
          params?.sequenceNumber
        );
      if (checkStoreInfo) {
        plainApi(
          HTTP_METHODS.GET,
          `${HOSTNAME}${REST_URLS.GET_NOTICES}`,
          null,
          params
        )
          .then((response) => {
            if (response?.message) {
              toast.error(response.message, { autoClose: 2000 });
            }
            setTotalPages(response.totalPages + 1);
            const result = response.results.map((iter) => ({
              storeNo: iter?.storeNumber,
              satelliteNo: iter?.satelliteNumber,
              sequenceNo: iter?.sequenceNumber,
              noticeType: iter?.type,
              reason: iter?.reason,
              noticeDate: moment(iter?.noticeDate).format("DD-MM-YYYY"),
            }));
            if (page == 1) {
              setData(result);
              setPageArray([1]);
            } else {
              setData([...data, ...result]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  const checkForValidStoreNumberSequenceNumberAndSatelliteNumber = (
    storeNo,
    sequenceNo,
    satelliteNum
  ) => {
    return (
      Number.isInteger(parseInt(storeNo)) &&
      Number.isInteger(parseInt(sequenceNo)) &&
      Number.isInteger(parseInt(satelliteNum))
    );
  };
  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearData = () => {
    setStoreInfo({
      ...storeInfo,
      storeNo: "",
      satelliteNo: "",
      sequenceNo: "",
    });
    setData([]);
    setPageArray([]);
    setTotalPages(null);
  };
  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value);
    } else {
      clearData();
    }
    setStoreInfo({
      storeNo: value ? value : "",
      satelliteNo: null,
      sequenceNo: null,
    });
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: p.storeNo,
          id: p.id,
          satelliteNum: p.satelliteNo,
          sequenceNo: p.sequenceNo,
          locationType: p.locationType,
          storeNo: p.storeNo,
        };
      });

      setStoreOptions(updatedOptions);
    });
  };
  useEffect(() => {
    const validStoreCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );

    if (totalPages === null) {
      if (validStoreCombination) {
        getTableData(pages);
        getData(storeInfo.storeNo);
      }
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          if (validStoreCombination) {
            getTableData(pages);
            getData(storeInfo.storeNo);
          }
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getTableData(1);
    }
  }, []);
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_NOTICE_TYPES}`)
      .then((res) => {
        if (!res.message) {
          const results = res?.map((ele) => ({ label: ele, value: ele }));
          setNoticeTypeOptions(results);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Grid container md={12} spacing={3}>
        <Grid item md={2}>
          <Autocomplete
            className="subs-autocomplete"
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumber(val?.storeNo);
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : null
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : null
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));

              getTableData(1, val?.value);
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Select Type"
            className="sel-item mr"
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 10000000 }),
            }}
            options={noticeTypeOptions}
            onChange={(val) => {
              getTableData(1, storeInfo?.sequenceNo, val?.value, true);
            }}
          />
        </Grid>
        <Grid item md={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FileUpload
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            fileFormats={[
              ".csv",
              ".xls",
              ".xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            url={`${HOSTNAME}${REST_URLS.UPLOAD_NOTICES}`}
            callBack={() => getTableData(1, storeInfo?.sequenceNo, null, true)}
          />
        </Grid>
      </Grid>
      <Grid container md={12} sx={{ mt: 3 }}></Grid>
      <StickyHeaderCon
        columnsList={noticeCols}
        dataList={data}
        setPages={setPages}
        setRefresh={setRefresh}
        hasMore={hasMore}
      />
    </div>
  );
};

export default Notices;
