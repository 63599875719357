import {
  FINANCE_HOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';

export const vendorList = (setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`
  )
    .then((response) => {
      const results =
        response?.map?.((item) => {
          return { label: `${item.name} - ${item.code}`, value: item.code };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {});
};

export const locationList = (setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}/list`,
    null,
    {
      poBudgetLocations: true,
    }
  )
    .then((response) => {
      const results =
        response?.map?.((item) => {
          return {
            label: `${item.name}${item.code ? ` - ` + item.code : ''}`,
            value: item.id,
          };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {});
};
