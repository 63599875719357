import React, { useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LocationInformation from "./locationInformation";
import Commercial from "./commercial";
import CompetitorInformation from "./competitorInformation";
import StageGate from "./stageGate";
import { useNavigate, useParams } from "react-router-dom";
import NearbySubwayData from "./nearby-subway-data";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { BD_LEAD_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/bd-lead";
import { toast } from "react-toastify";
import { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { disableSaveButton } from "./utils/misc.utils";
import CommonBtn from "../../../common-utilities/core/common-button";
import SaveIcon from "@mui/icons-material/Save";
import { deleteObjectProperties } from "../../../common-utilities/utils/misc";
const Lead = () => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [data, setData] = useState({
    locationInfo: {},
    commercials: {},
    competitorInformation: {},
    nearbySubway: [],
    stageGates: [],
  });

  const [mainDataWithoutResetting, setmainDataWithoutResetting] = useState({
    locationInfo: {},
    commercials: {},
    competitorInformation: {},
    nearbySubway: [],
    stageGates: [],
  });

  const getLeaseDataById = (leadId) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${BD_LEAD_HOSTNAME}${REST_URLS.GET_LEAD_BY_ID}${leadId}`
    ).then((res) => {
      if (res?.message) {
        return;
      }
      const {
        commercials = {},
        stageGates = [],
        competitorInformation = {},
        nearbySubway = [],
        locationInfo = {},
      } = res;
      setData({
        commercials,
        stageGates,
        competitorInformation,
        nearbySubway,
        locationInfo,
      });
      setmainDataWithoutResetting({
        commercials,
        stageGates,
        competitorInformation,
        nearbySubway,
        locationInfo,
      });
    });
  };

  useEffect(() => {
    if (Number.isInteger(parseInt(leadId))) {
      setActiveStep(4);
      getLeaseDataById(leadId);
    }
  }, [leadId]);
  const leadDocumentFormSteps = [
    "Location Information *",
    "Commercials",
    "Competitor information",
    "Nearby Subway Data",
    "Stage Gates",
  ];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const formSubmitHandler = () => {
    const haveToUpdateLease = Number.isInteger(parseInt(leadId));
    const { stageGates = [] } = data;
    let convertedStageGates = [...stageGates];
    convertedStageGates = convertedStageGates.map((item) => {
      deleteObjectProperties(item, [
        "disableEditingInitialEstimated",
        "initialEstimated",
        "revisedProjectedDate",
      ]);
      return item;
    });
    if (haveToUpdateLease) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${BD_LEAD_HOSTNAME}${REST_URLS.UPDATE_LEAD_BY_ID}${leadId}`,
        { ...data, stageGates: convertedStageGates }
      )
        .then((res) => {
          if (res?.message) {
            toast.error(res?.message, { autoClose: 2000 });
            return;
          }
          toast.success("Lead updated successfully", { autoClose: 2000 });
          navigate("/bd-lead");
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${BD_LEAD_HOSTNAME}${REST_URLS.CREATE_LEASE}`,
      {
        ...data,
        stageGates: convertedStageGates,
      }
    ).then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success("Lead created successfully", { autoClose: 2000 });
      navigate("/bd-lead");
    });
  };
  const closeFormHandler = () => {
    navigate("/bd-lead");
  };
  return (
    <div>
      <div className="closeFormContainer">
        <div>
          {leadId ? (
            <>Catchment Name : {data.locationInfo?.catchment}</>
          ) : (
            <></>
          )}
        </div>
        <CancelIcon
          fontSize="large"
          color="error"
          sx={{ cursor: "pointer" }}
          onClick={closeFormHandler}
        />
      </div>
      <Box sx={{ width: "95%", margin: "0 auto" }}>
        <Stepper
          activeStep={activeStep}
          orientation={window.innerWidth <= 599 ? "vertical" : "horizontal"}
        >
          {leadDocumentFormSteps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step} {...stepProps}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === 0 && (
            <LocationInformation
              locationdata={data.locationInfo}
              updateLocationInfo={setData}
            />
          )}
          {activeStep === 3 && (
            <NearbySubwayData data={data} setNearbyInformation={setData} />
          )}
          {activeStep === 1 && (
            <Commercial commercials={data?.commercials} setInfo={setData} />
          )}
          {activeStep == 2 && (
            <CompetitorInformation
              data={data}
              setCompetitorInforation={setData}
            />
          )}
          {activeStep == 4 && (
            <StageGate
              data={data}
              setSatgeGateInforation={setData}
              mainDataWithoutResetting={mainDataWithoutResetting}
            />
          )}
        </>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "25%",
              justifyContent: "space-between",
            }}
          >
            <CommonBtn
              color={activeStep === 0 ? "inherit" : "primary"}
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
              text="Back"
              size="small"
            />
          </Box>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === leadDocumentFormSteps.length - 1 && (
            <CommonBtn
              onClick={formSubmitHandler}
              disabled={disableSaveButton(data)}
              variant="contained"
              size="small"
              color="primary"
              startIcon={<SaveIcon />}
            />
          )}
          {activeStep !== leadDocumentFormSteps.length - 1 && (
            <CommonBtn
              onClick={handleNext}
              className="outlined-btn"
              variant="outlined"
              text="Next"
              size="small"
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Lead;
