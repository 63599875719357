import React, { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import thankYouImage from "../../../common-utilities/resources/images/thank-you.png";
const VoucherResponse = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/petty-expense");
    }, 1000);
  }, []);
  return (
    <>
      <div className="main-voucher-res-div">
        <div className="voucher-thank-you-screen">
          <img src={thankYouImage} className="approval-icon" />
        </div>
      </div>
    </>
  );
};

export default VoucherResponse;
