import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import Select from "react-select";
import { dateFun } from "../../../common-utilities/utils";
import {
  bubbler,
  buildingSign,
  buildingSignLogo,
  buildingSignMaker,
  paniniPressType,
  poleSign,
  poleSignLogo,
  poleSignMaker,
  sodaType,
  speedOven,
} from "./config";

const MainAttributes = ({ storeAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!formData.id) {
      setFormData(storeAttributes);
    }
  }, [storeAttributes]);

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    onChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onCheckboxChange = (value, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: value,
    });
    onChange({
      ...formData,
      [dataLabel]: value,
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    remodelDatesInfo = {},
    speedOvensInfo = {},
    sodaInfo = {},
    signageInfo = {},
  } = formData;

  return (
    <div className="basic-fixed-container">
      <Grid container>
        <Grid xs={6}>
          <div className="title">Attributes</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Oven Manufacture"
                className="subs-text-field"
                value={formData.ovenManufacture || ""}
                name="ovenManufacture"
                onChange={onInputChange}
                fullWidth
              />
              <TextField
                label="Building Type"
                className="subs-text-field"
                value={formData.buildingType || ""}
                name="buildingType"
                onChange={onInputChange}
                fullWidth
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Population Est"
                fullWidth
                className="subs-text-field"
                value={formData.populationEst || ""}
                name="populationEst"
                onChange={onInputChange}
              />
              <TextField
                label="Window Size"
                fullWidth
                className="subs-text-field"
                value={formData.windows || ""}
                name="windows"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="# Of Front Line Units"
                fullWidth
                className="subs-text-field"
                value={formData.frontLineUnit || ""}
                name="frontLineUnit"
                onChange={onInputChange}
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={formData?.outdoorBanner || false}
                    onChange={() => {
                      onCheckboxChange(
                        !formData.outdoorBanner,
                        "outdoorBanner"
                      );
                    }}
                  />
                }
                label="Outdoor Banner"
                labelPlacement="end"
              />
            </div>

            <div className="g-r-2">
              <TextField
                label="# Of Drive thru Units"
                fullWidth
                className="subs-text-field"
                value={formData.driveThroughUnit || ""}
                name="driveThroughUnit"
                onChange={onInputChange}
              />
              <TextField
                label="# Of Windows"
                fullWidth
                className="subs-text-field"
                value={formData.ofWindows || ""}
                name="ofWindows"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={formData?.customerArea || false}
                      onChange={() => {
                        onCheckboxChange(
                          !formData.customerArea,
                          "customerArea"
                        );
                      }}
                    />
                  }
                  label="Customer Area"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={formData?.seasonal || false}
                      onChange={() => {
                        onCheckboxChange(!formData.seasonal, "seasonal");
                      }}
                    />
                  }
                  label="Seasonal"
                  labelPlacement="end"
                />
              </div>
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={formData?.storeLocator || false}
                      onChange={() => {
                        onCheckboxChange(
                          !formData.storeLocator,
                          "storeLocator"
                        );
                      }}
                    />
                  }
                  label="Store Locator"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={formData?.verticalBanner || false}
                      onChange={() => {
                        onCheckboxChange(
                          !formData.verticalBanner,
                          "verticalBanner"
                        );
                      }}
                    />
                  }
                  label="Vertical Banner"
                  labelPlacement="end"
                />
              </div>
            </div>

            <div className="g-r-2 m-b ">
              <div>
                <p className="dropdown-title ">Panini Press 1</p>
                <Select
                  classNamePrefix="custom_select"
                  value={
                    formData.paniniPress1
                      ? {
                          label: formData.paniniPress1,
                          value: formData.paniniPress1,
                        }
                      : null
                  }
                  placeholder="Panini Press 1"
                  options={paniniPressType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      paniniPress1: e?.value,
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      paniniPress1: e?.value,
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Panini Press 2</p>
                <Select
                  classNamePrefix="custom_select"
                  value={
                    formData?.paniniPress2
                      ? {
                          label: formData.paniniPress2,
                          value: formData.paniniPress2,
                        }
                      : null
                  }
                  placeholder="Panini Press 2"
                  options={paniniPressType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      paniniPress2: e?.value,
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      paniniPress2: e?.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Panini Press 3</p>
                <Select
                  classNamePrefix="custom_select"
                  value={
                    formData?.paniniPress3
                      ? {
                          label: formData.paniniPress3,
                          value: formData.paniniPress3,
                        }
                      : null
                  }
                  placeholder="Panini Press 3"
                  options={paniniPressType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      paniniPress3: e?.value,
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      paniniPress3: e?.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Remodel Dates</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="Last Remodel"
                type="date"
                className="subs-text-field"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(remodelDatesInfo?.lastRemodelDate) || ""}
                name="lastRemodelDate"
                onChange={(event) =>
                  onInnerInputChange(event, "remodelDatesInfo")
                }
              />
              <TextField
                label="Last Relocation"
                type="date"
                className="subs-text-field"
                InputLabelProps={{ shrink: true }}
                value={dateFun(remodelDatesInfo?.lastRelocationDate) || ""}
                name="lastRelocationDate"
                onChange={(event) =>
                  onInnerInputChange(event, "remodelDatesInfo")
                }
                fullWidth
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Next Remodel"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateFun(remodelDatesInfo?.nextRemodelDate) || ""}
                name="nextRemodelDate"
                onChange={(event) =>
                  onInnerInputChange(event, "remodelDatesInfo")
                }
                fullWidth
              />
            </div>

            <div className="g-r-2">
              <TextField
                label="Fresh Image"
                fullWidth
                className="subs-text-field"
                value={remodelDatesInfo?.freshImage || ""}
                name="freshImage"
                onChange={(event) =>
                  onInnerInputChange(event, "remodelDatesInfo")
                }
              />
              <TextField
                label="Remodel Extension Expiration"
                fullWidth
                className="subs-text-field"
                value={remodelDatesInfo?.remodelExtensionExpirationDate || ""}
                name="remodelExtensionExpirationDate"
                onChange={(event) =>
                  onInnerInputChange(event, "remodelDatesInfo")
                }
              />
            </div>
            <div className="title">Speed Ovens</div>
            <div className="g-r-2 m-b">
              <TextField
                label="No Of Overs"
                fullWidth
                className="subs-text-field"
                value={speedOvensInfo?.ofSpeedOven || ""}
                name="ofSpeedOven"
                onChange={(event) =>
                  onInnerInputChange(event, "speedOvensInfo")
                }
              />
              <div>
                <p className="dropdown-title ">Speed Oven 1</p>
                <Select
                  placeholder="Speed Oven 1"
                  classNamePrefix="custom_select"
                  value={
                    formData?.speedOvensInfo?.speedOven1
                      ? {
                          label: formData?.speedOvensInfo?.speedOven1,
                          value: formData?.speedOvensInfo?.speedOven1,
                        }
                      : null
                  }
                  options={speedOven}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven1: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven1: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Speed Oven 2</p>
                <Select
                  placeholder="Speed Oven 2"
                  classNamePrefix="custom_select"
                  value={
                    formData?.speedOvensInfo?.speedOven2
                      ? {
                          label: formData?.speedOvensInfo?.speedOven2,
                          value: formData?.speedOvensInfo?.speedOven2,
                        }
                      : null
                  }
                  options={speedOven}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven2: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven2: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Speed Oven 3</p>
                <Select
                  placeholder="Speed Oven 3"
                  classNamePrefix="custom_select"
                  value={
                    formData?.speedOvensInfo?.speedOven3
                      ? {
                          label: formData?.speedOvensInfo?.speedOven3,
                          value: formData?.speedOvensInfo?.speedOven3,
                        }
                      : null
                  }
                  options={speedOven}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven3: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven3: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Speed Oven 4</p>
                <Select
                  placeholder="Speed Oven 4"
                  classNamePrefix="custom_select"
                  value={
                    formData?.speedOvensInfo?.speedOven4
                      ? {
                          label: formData?.speedOvensInfo?.speedOven4,
                          value: formData?.speedOvensInfo?.speedOven4,
                        }
                      : null
                  }
                  options={speedOven}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven4: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      speedOvensInfo: {
                        ...formData.speedOvensInfo,
                        speedOven4: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <Grid container>
            <Grid xs={4}>
              <div className="form-details">
                <div>
                  <p className="dropdown-title ">Building sign 1</p>
                  <Select
                    placeholder="Building sign 1"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.buildingSign1
                        ? {
                            label: formData?.signageInfo?.buildingSign1,
                            value: formData?.signageInfo?.buildingSign1,
                          }
                        : null
                    }
                    options={buildingSign}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          buildingSign1: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          buildingSign1: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Building sign 2</p>
                  <Select
                    placeholder="Building sign 2"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.buildingSign2
                        ? {
                            label: formData?.signageInfo?.buildingSign2,
                            value: formData?.signageInfo?.buildingSign2,
                          }
                        : null
                    }
                    options={buildingSign}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          buildingSign2: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          buildingSign2: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Pole Sign</p>
                  <Select
                    placeholder="Pole Sign"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.poleSign
                        ? {
                            label: formData?.signageInfo?.poleSign,
                            value: formData?.signageInfo?.poleSign,
                          }
                        : null
                    }
                    options={poleSign}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSign: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSign: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={signageInfo?.outDoorPennants || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !signageInfo.outDoorPennants,
                          "signageInfo",
                          "outDoorPennants"
                        );
                      }}
                    />
                  }
                  label="Outdoor Pennants"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={signageInfo?.doorDecals || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !signageInfo.doorDecals,
                          "signageInfo",
                          "doorDecals"
                        );
                      }}
                    />
                  }
                  label="Door Decals"
                  labelPlacement="end"
                />
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="form-details">
                <div>
                  <p className="dropdown-title ">Marker 1</p>
                  <Select
                    placeholder="Marker 1"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.maker1
                        ? {
                            label: formData?.signageInfo?.maker1,
                            value: formData?.signageInfo?.maker1,
                          }
                        : null
                    }
                    options={buildingSignMaker}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          maker1: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          maker1: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Marker 2</p>
                  <Select
                    placeholder="Marker 2"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.maker2
                        ? {
                            label: formData?.signageInfo?.maker2,
                            value: formData?.signageInfo?.maker2,
                          }
                        : null
                    }
                    options={buildingSignMaker}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          maker2: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          maker2: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Pole Sign Maker</p>
                  <Select
                    placeholder="Pole Sign Maker"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.poleSignMaker
                        ? {
                            label: formData?.signageInfo?.poleSignMaker,
                            value: formData?.signageInfo?.poleSignMaker,
                          }
                        : null
                    }
                    options={poleSignMaker}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSignMaker: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSignMaker: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="form-details">
                <div>
                  <p className="dropdown-title ">Logo 1</p>
                  <Select
                    placeholder="Logo 1"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.logo1
                        ? {
                            label: formData?.signageInfo?.logo1,
                            value: formData?.signageInfo?.logo1,
                          }
                        : null
                    }
                    options={buildingSignLogo}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          logo1: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          logo1: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Logo 2</p>
                  <Select
                    placeholder="Logo 2"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.logo1
                        ? {
                            label: formData?.signageInfo?.logo2,
                            value: formData?.signageInfo?.logo2,
                          }
                        : null
                    }
                    options={buildingSignLogo}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          logo2: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          logo2: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">Pole Sign Logo</p>
                  <Select
                    placeholder="Pole Sign Logo"
                    classNamePrefix="custom_select"
                    value={
                      formData?.signageInfo?.poleSignLogo
                        ? {
                            label: formData?.signageInfo?.poleSignLogo,
                            value: formData?.signageInfo?.poleSignLogo,
                          }
                        : null
                    }
                    options={poleSignLogo}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSignLogo: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        signageInfo: {
                          ...formData.signageInfo,
                          poleSignLogo: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6}>
          <div className="form-details">
            <div className="g-r-2">
              <div>
                <p className="dropdown-title ">Soda Type</p>
                <Select
                  placeholder="Soda Type"
                  classNamePrefix="custom_select"
                  value={
                    formData?.sodaInfo?.type
                      ? {
                          label: formData?.sodaInfo?.type,
                          value: formData?.sodaInfo?.type,
                        }
                      : null
                  }
                  options={sodaType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      sodaInfo: {
                        ...formData.sodaInfo,
                        type: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      sodaInfo: {
                        ...formData.sodaInfo,
                        type: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <TextField
                label="Soda Date"
                type="date"
                className="subs-text-field"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={dateFun(sodaInfo?.date) || ""}
                name="date"
                onChange={(event) => onInnerInputChange(event, "sodaInfo")}
              />
              <div>
                <p className="dropdown-title ">Bubbler</p>
                <Select
                  placeholder="Bubbler"
                  classNamePrefix="custom_select"
                  value={
                    formData?.sodaInfo?.bubbler
                      ? {
                          label: formData?.sodaInfo?.bubbler,
                          value: formData?.sodaInfo?.bubbler,
                        }
                      : null
                  }
                  options={bubbler}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      sodaInfo: {
                        ...formData.sodaInfo,
                        bubbler: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      sodaInfo: {
                        ...formData.sodaInfo,
                        bubbler: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={sodaInfo?.selfServe || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !sodaInfo.selfServe,
                          "sodaInfo",
                          "selfServe"
                        );
                      }}
                    />
                  }
                  label="Self Serve"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={sodaInfo?.bottled || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !sodaInfo.bottled,
                          "sodaInfo",
                          "bottled"
                        );
                      }}
                    />
                  }
                  label="Bottled"
                  labelPlacement="end"
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default MainAttributes;
