import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Overview from "./Overview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography, Grid, Button, Box } from "@mui/material";
import "./index.scss";
import { formatDashboardResults } from "./helper";
import { getUserRole, USER_ROLES, redirection } from "../../utils";
import InfoOverview from "./info-overview";
import { useNavigate } from "react-router-dom";
import { DateRangePickerLabels } from "../../utils/labels";
import { DateRangePicker } from "rsuite";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ResetPasswordModal } from "../../../common-utilities/components/custom-modals/reset-password.modal";
import "rsuite/dist/rsuite.min.css";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const roleDetails = getUserRole();
  const navigate = useNavigate();
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [adminDashboard, setAdminDashboard] = useState({});
  const [dateRanges, setDateRanges] = useState([null, null]);
  const [value, setValue] = useState(0);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    redirection(navigate);
  }, []);

  useEffect(() => {
    loadUserDashboard(dateRanges);
    if (roleDetails) {
      (roleDetails?.type === USER_ROLES.ADMIN ||
        roleDetails?.type === USER_ROLES.RD) &&
        loadStatsByAdmin();
    } else {
      navigate("/");
    }
  }, []);

  const loadUserDashboard = (dates) => {
    const [startDate, endDate] = dates;
    let params = {};
    if (startDate && endDate) {
      params = {
        startDate,
        endDate,
      };
      addCorrectStartEndDate(params);
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.USER_DASHBOARD_STATUS}`,
      null,
      params
    )
      .then((response) => {
        if (response) {
          setDashboardDetails(formatDashboardResults(response));
        }
      })
      .catch((err) => console.log(err));
  };

  const loadStatsByAdmin = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.DASH_STATS_BY_ADMIN}`
    ).then((response) => {
      setAdminDashboard(response);
    });
  };

  const onDateRangeChange = (data) => {
    if (!data) {
      setDateRanges([null, null]);
      return;
    }
    setDateRanges([
      new Date(`${data[0]}`).toISOString(),
      new Date(`${data[1]}`).toISOString(),
    ]);
  };

  return (
    <>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginTop: "-1em" }}
        className="tab-container"
      >
        <Grid container spacing={0}>
          <Grid item md={8} xs={12} sm={7} className="custom-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="Overview" />
              <Tab label="Admin" />
              <Tab label="Complaint Type" />
              <Tab label="Dc" />
              {/* <Tab label="Store" />
              <Tab label="Vendor" /> */}
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <div className="main-date-container">
        <div className="dateFilter">
          {/* {roleDetails.type === USER_ROLES.ADMIN && (
            <span className="open-tickets">
              Open Tickets {adminDashboard.openTickets}
            </span>
          )} */}
        </div>

        <div className="main-dashboard-datepicker-container">
          <DateRangePicker
            cleanable={true}
            className="main-dashboard-datepicker"
            ranges={DateRangePickerLabels}
            value={[
              dateRanges[0] ? new Date(dateRanges[0]) : null,
              dateRanges[1] ? new Date(dateRanges[1]) : null,
            ]}
            placeholder="Select time period"
            onChange={onDateRangeChange}
          />

          <CheckCircleIcon
            onClick={() => loadUserDashboard(dateRanges)}
            className="clearicon"
            variant="contained"
            color="primary"
          />

          <HighlightOffIcon
            onClick={() => {
              setDateRanges([null, null]);
              loadUserDashboard([null, null]);
            }}
            className="clearicon"
          />
        </div>
      </div>

      <div className="dashboard-body">
        <TabPanel value={value} index={0}>
          <Overview data={dashboardDetails || {}} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InfoOverview
            reportInfo={dashboardDetails?.byEntitiesOrginal || {}}
            keyLabel="byAdmin"
            title="Admin with top case"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InfoOverview
            reportInfo={dashboardDetails?.byEntitiesOrginal || {}}
            keyLabel="byComplaintType"
            title="ComplaintType with top case"
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <InfoOverview
            reportInfo={dashboardDetails?.byEntitiesOrginal || {}}
            keyLabel="byDc"
            title="DC with top case"
          />
        </TabPanel>

        {/* <TabPanel value={value} index={4}>
          <InfoOverview
            reportInfo={dashboardDetails?.byEntitiesOrginal || {}}
            keyLabel="byStore"
            title="Stores with top case"
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <InfoOverview
            reportInfo={dashboardDetails?.byEntitiesOrginal || {}}
            keyLabel="byVendor"
            title="Vendors with top case"
          />
        </TabPanel> */}
      </div>

      <ResetPasswordModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
        tokenKey={tokenMainKeys.qnet}
        url={`${HOSTNAME}${REST_URLS.EDIT_USER}`}
        slug={appSlugs.qnet}
        resetPasswordKey="changePasswordOnFirstLogin"
      />
    </>
  );
};

export default Dashboard;
