import React, { useState, createContext } from "react";

export const StoreContext = () => {
  const [storeInfo, setStoreInfo] = useState({
    storeNo: "",
    sequenceNo: "",
    satelliteNo: "",
  });
  return [storeInfo, setStoreInfo];
};

export const PersonContext = () => {
  const [personInfo, setPersonInfo] = useState({
    personId: "",
  });
  return [personInfo, setPersonInfo];
};

export const LeaseContext = () => {
  const [leaseInfo, setLeaseInfo] = useState({
    leaseId: "",
  });
  return [leaseInfo, setLeaseInfo];
};

export const GlobalStoreContext = createContext();
