import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { exportToExcel } from '../../../../common-utilities/utils';
import { toast } from 'react-toastify';
import { getCreateUpdateDetails } from './utils';
import { uploadTemplates } from '../constants';
import {
  deleteObjectProperties,
  setProperty,
} from '../../../../common-utilities/utils/misc';

export const queryForeignCurrencies = (
  filters,
  setDataFunc,
  download,
  setFilters
) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.FOREIGN_CURRENCY}`,
    null,
    filters
  )
    .then((res) => {
      if (download) {
        if (res?.results?.length) {
          const results =
            res?.results?.map?.((item) => {
              return {
                Id: item?.id,
                Name: item?.name,
              };
            }) || [];
          if (results?.length) {
            exportToExcel(results, uploadTemplates.foreign_currency);
          }
        }
        return;
      }
      if (res?.message) {
        if (typeof setDataFunc == 'function') {
          setDataFunc({});
        }
        if (
          filters?.page > res?.totalPages &&
          typeof setFilters === 'function'
        ) {
          setProperty('page', res?.totalPages, setFilters);
        }

        return;
      }
      if (typeof setDataFunc == 'function') {
        setDataFunc(res);
      }
    })
    .catch((err) => {
      if (typeof setDataFunc == 'function') {
        setDataFunc({});
      }
      console.log(err);
    });
};

export const getCurrencyList = (
  setDataFunc,
  setDefaultCurrency,
  defaultCurrencyKey = 'currency'
) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.FOREIGN_CURRENCY}/list`,
    null
  )
    .then((res) => {
      let defaultCurrency = null;
      const list = res?.map?.((item) => {
        if (item?.name?.toLowerCase?.() === 'inr') {
          defaultCurrency = { label: item?.name, value: item?.id };
        }
        return { label: item?.name, value: item?.id };
      });
      setDataFunc(list);
      if (typeof setDefaultCurrency === 'function' && defaultCurrency) {
        setProperty(defaultCurrencyKey, defaultCurrency, setDefaultCurrency);
      }
    })
    .catch((err) => {
      setDataFunc([]);
      console.log(err);
    });
};

export const deleteForeignCurrency = (id, onSuccess) => {
  invokeApi(
    HTTP_METHODS.DELETE,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.FOREIGN_CURRENCY}/${id}`,
    null
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success('Currency deleted successfully', { autoClose: 2000 });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    })
    .catch((err) => {
      console.log(err?.message, { autoClose: 2000 });
    });
};

export const createUpdateForeignCurrency = (data, onSuccess) => {
  const { method, payload, url, message } = getCreateUpdateDetails(data);
  invokeApi(method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message);
        return;
      }
      toast.success(message, { autoClose: 2000 });
      onSuccess && onSuccess();
    })
    .catch((err) => {
      console.log(err?.message, { autoClose: 2000 });
    });
};
