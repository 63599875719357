import { TextField } from "@mui/material";
import React from "react";
import "./index.scss";
import Select from "react-select";
const FirstFranchiseInfo = ({ franchiseData, setPersonData }) => {
  return (
    <div style={{ marginTop: "4em" }}>
      <div className="pd-title">First Franchise Info</div>
      <div className="input-form-container">
        <div className="g-r-2">
          <TextField
            className="subs-text-field"
            id="first-franchise-num"
            label="First Franchise Num"
            fullWidth
            type="number"
            value={franchiseData.franchiseNum}
            onChange={(e) => {
              setPersonData((prevVal) => ({
                ...prevVal,
                franchiseDetails: {
                  ...prevVal.franchiseDetails,
                  franchiseNum: e.target.value,
                },
              }));
            }}
            variant="outlined"
          />
          <div className="row-div">
            <TextField
              className="subs-text-field"
              id="first-franchise-date"
              label="First Franchise Date"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={franchiseData.firstFranchiseDate}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  franchiseDetails: {
                    ...prevVal.franchiseDetails,
                    firstFranchiseDate: e.target.value,
                  },
                }));
              }}
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstFranchiseInfo;
