import { Autocomplete, Button, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { VENDOR_ONBOARD_REST_URLS } from "../../../common-utilities/utils/end-points/vendor-onboard";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";

import {
  FILTER_BY_TAB_NAME_BY_ROLE,
  TAB_NAME_BY_ROLE,
  getHeaderConfig,
} from "./helper";
import "./index.scss";
import { VENDOR_ONBOARD_USERS } from "../../utils/constants";
import { getOfflineData } from "../../utils/offline-services";
import MuiTable from "../../../common-utilities/core/mui-table";
import CustomModal from "../../../common-utilities/core/modal";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { exportToExcel } from "../../../common-utilities/utils";
import ClearIcon from "@mui/icons-material/Clear";

const VendorRequestList = () => {
  const navigate = useNavigate();
  const user = getOfflineData("user") || {};
  const { role } = user;
  const [activeTab, setActiveTab] = useState(0);
  const [vendorForms, setVendorForms] = useState({});
  const [allVendorForms, setAllVendorForms] = useState({});
  const [createVendor, setCreateVendor] = useState({ isOpen: false, data: {} });
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });
  const [vendorData, setVendorData] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [searchFormId, setSearchFormId] = useState("");
  const [formIdsList, setFormIdsList] = useState([]);
  useEffect(() => {
    loadData(filters);
  }, [activeTab]);

  const loadData = (filters, isDownload = false) => {
    let params = {
      ...filters,
    };
    if (searchFormId) {
      params["id"] = searchFormId;
    }
    if (
      [
        VENDOR_ONBOARD_USERS.HOD,
        VENDOR_ONBOARD_USERS.FINANCE_USER,
        VENDOR_ONBOARD_USERS.VIVO_CREATOR,
      ].includes(role) &&
      activeTab == 0
    ) {
      params.myPendingRequests = true;
    }
    if (isDownload) {
      params.isDownload = true;
      params.page = 1;
      params.limit = vendorForms?.totalResults || 100;
    }
    if (FILTER_BY_TAB_NAME_BY_ROLE?.[role]?.[activeTab]) {
      params.status = FILTER_BY_TAB_NAME_BY_ROLE[role][activeTab];
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS}`,
      null,
      params
    )
      .then((response) => {
        if (isDownload) {
          exportToExcel(response.results, "Vendor Requests");
        } else {
          setVendorForms(response);
        }
      })
      .catch((err) => {});
  };
  const getVendorName = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.GET_VENDOR_BY_FORM_ID}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setVendorData((prevVal) => ({
            ...prevVal,
            name: res?.nameOfLegalEntity,
          }));
        }
      })
      .catch((err) => console.log(err));
  };
  const getVendorList = () => {
    const payload = {
      page: 1,
      limit: vendorForms?.totalResults || 100,
    };
    if (
      [
        VENDOR_ONBOARD_USERS.HOD,
        VENDOR_ONBOARD_USERS.FINANCE_USER,
        VENDOR_ONBOARD_USERS.VIVO_CREATOR,
      ].includes(role) &&
      activeTab == 0
    ) {
      payload.myPendingRequests = true;
    }
    if (FILTER_BY_TAB_NAME_BY_ROLE?.[role]?.[activeTab]) {
      payload.status = FILTER_BY_TAB_NAME_BY_ROLE[role][activeTab];
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS}`,
      null,
      payload
    )
      .then((response) => {
        const vendorArr = response.results
          .map((ele) => ele?.vendorOnboardData?.nameOfLegalEntity)
          .filter((ele) => ele && ele);
        const result = Array.from(new Set(vendorArr));
        setVendorList(result);
        setAllVendorForms(response);
      })
      .catch((err) => {});
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const createVendorHandler = (id) => {
    const payload = { ...vendorData, formId: id };
    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.CREATE_VENDOR}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Vendor Created Successfully", { autoClose: 2000 });
          setVendorData({});
          setCreateVendor({ isOpen: false, data: {} });
          loadData(filters);
        }
      })
      .catch((err) => console.log(err));
  };
  const viewInvoice = (data, type) => {
    if (type === "EDIT") {
      navigate(`update/${data.id}`);
    } else if (type === "CREATE_VENDOR") {
      getVendorName(data.id);
      setCreateVendor({
        isOpen: true,
        data: data,
      });
    } else {
      navigate(`${data.id}`);
    }
  };
  const getFormIdList = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    const params = {
      limit: 20,
      id: searchCode,
    };
    if (
      [
        VENDOR_ONBOARD_USERS.HOD,
        VENDOR_ONBOARD_USERS.FINANCE_USER,
        VENDOR_ONBOARD_USERS.VIVO_CREATOR,
      ].includes(role) &&
      activeTab == 0
    ) {
      params.myPendingRequests = true;
    }
    if (FILTER_BY_TAB_NAME_BY_ROLE?.[role]?.[activeTab]) {
      params.status = FILTER_BY_TAB_NAME_BY_ROLE[role][activeTab];
    }
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS}`,
        null,
        params
      ).then((res) => {
        const idList = res?.results?.map((ele) => ({
          id: ele?.id,
        }));
        setFormIdsList(idList);
      });
    }, 300);
  };
  const formIdInputChange = (e, value) => {
    if (value?.trim()) {
      getFormIdList(value);
    }
  };
  const searchFilterHandler = () => {
    if (selectedVendor) {
      const filteredFormData = allVendorForms?.results.filter(
        (ele) => ele?.vendorOnboardData?.nameOfLegalEntity === selectedVendor
      );
      setVendorForms((prevVal) => ({
        ...prevVal,
        results: filteredFormData,
        totalPages: 1,
      }));
    } else {
      loadData({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    if (vendorForms?.totalResults) {
      getVendorList();
    }
  }, [vendorForms?.totalResults]);
  return (
    <div className="vendor-request-list">
      <div className="header-section">
        <Tabs value={activeTab} onChange={handleChange}>
          {(TAB_NAME_BY_ROLE[role] || TAB_NAME_BY_ROLE.default).map(
            (tabName) => {
              return <Tab label={tabName} />;
            }
          )}
        </Tabs>
        <div className="download-con">
          {role === VENDOR_ONBOARD_USERS.VIVO_CREATOR && (
            <Button
              variant="contained"
              size="small"
              sx={{ mr: 2 }}
              onClick={() => navigate("send")}
            >
              Create Request
            </Button>
          )}
          <DownloadBtn onClick={() => loadData(filters, true)} />
        </div>
      </div>
      <div className="vendor-filters-con">
        <Autocomplete
          getOptionLabel={(option) => option?.id?.toString()}
          className="poper-select"
          disabled={selectedVendor}
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                setSearchFormId("");
                setFormIdsList([]);
              }}
            />
          }
          options={formIdsList}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Form Id"
              className="filter-width"
              sx={{ mr: "15px" }}
            />
          )}
          onInputChange={formIdInputChange}
          onChange={(event, value) => {
            if (value) {
              setSearchFormId(value?.id);
            }
          }}
        />
        <Autocomplete
          getOptionLabel={(option) => option?.toString()}
          className="poper-select"
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                setSelectedVendor("");
              }}
            />
          }
          options={vendorList}
          disabled={searchFormId}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Vendor"
              className="filter-width"
              sx={{ mr: "15px" }}
            />
          )}
          onChange={(event, value) => {
            if (value) {
              setSelectedVendor(value);
            }
          }}
        />
        <Button variant="outlined" onClick={searchFilterHandler}>
          Search
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(activeTab, role)}
        dataList={vendorForms.results || []}
        filters={filters}
        pageCount={vendorForms.totalPages}
        onClick={viewInvoice}
        nestedValue={true}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {createVendor.isOpen && (
        <CustomModal
          title="Create Vendor"
          onClose={() => {
            setCreateVendor({
              isOpen: false,
              data: {},
            });
            setVendorData({});
          }}
        >
          <div>
            <div>
              <TextField
                label="Name"
                name="name"
                fullWidth
                size="small"
                disabled
                sx={{ mb: 2 }}
                variant="outlined"
                value={vendorData?.name || ""}
              />
              <TextField
                label="Code"
                name="code"
                required
                fullWidth
                size="small"
                sx={{ mb: 2 }}
                variant="outlined"
                onChange={(e) => {
                  setVendorData((prevVal) => ({
                    ...prevVal,
                    code: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="common-btn-ctr">
              <div>
                <Button
                  startIcon={<SaveIcon />}
                  onClick={() => createVendorHandler(createVendor?.data?.id)}
                  fullWidth
                  className="common-modal-crt-btn"
                  variant="outlined"
                  disabled={!vendorData?.code}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default VendorRequestList;
