import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { Grid, TextField } from "@mui/material";
import Select from "react-select";
import { invokeApi, plainApi } from "../../utils/http-service";
import { auditModelNames } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";
import { GlobalStoreContext } from "../../global-states/Context";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import {
  dateFun,
  getLabelsAndValuesForSelect,
} from "../../../common-utilities/utils";

const LeaseDateHistory = () => {
  const [tableData, settableData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const previousController = useRef();
  const [satelliteList, setSatelliteList] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [sequenceList, setSequenceList] = useState([]);
  const [leaseIdList, setLeaseIdList] = useState([]);

  useEffect(() => {
    if (totalPages === null) {
      getTableData(
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        leaseInfo.leaseId,
        pages
      );
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(
            storeInfo.satelliteNo,
            storeInfo.sequenceNo,
            leaseInfo.leaseId,
            pages
          );
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  const dataValueLabels = [
    { label: "Satellite Number", id: "satelliteNo" },
    { label: "Sequence Number", id: "sequenceNo" },
    { label: "DA Threshold Amount", id: "daThresholdAmt" },
    { label: "Military Contract", id: "militaryContract" },
    { label: "Relocation with center", id: "relocationWithinCenter" },
    { label: "Lease Representative", id: "representatives.leaseRep" },
    {
      label: "Renegotiation Representative",
      id: "representatives.renegotiationRep",
    },
    { label: "Settlement Representative", id: "representatives.settlementRep" },
    { label: "Completed by", id: "representatives.completedBy" },
    {
      label: "Lease Renegotiated By",
      id: "representatives.leaseRenegotiatedBy",
    },
    { label: "Status", id: "status" },
    { label: "Type", id: "type" },
    { label: "Size Type", id: "sizeType" },
    { label: "Comments", id: "comments" },
    { label: "Renewal Options", id: "renewalOptions" },
    { label: "Size", id: "size" },
    { label: "File Site Review", id: "fileSiteReview" },
    {
      label: "Foreign Territory Lease Approval",
      id: "foreignTerritoryLeaseApproval",
    },
    { label: "Incomplete date", id: "dates.incomplete" },
    { label: "Negotiation Date", id: "dates.negotiation" },
    { label: "Franchise Sold", id: "dates.franchiseSold" },
    { label: "Lease Received", id: "dates.leaseReceived" },
    { label: "Completed Site Evaluation", id: "dates.completedSiteEvaluation" },
    { label: "Intent to sublease Date", id: "dates.intentToSubLease" },
    { label: "Spec Authorization Date", id: "dates.specAuthorization" },
    {
      label: "Check for two months rent Date",
      id: "dates.checkForTwoMonthsRent",
    },
    { label: "Deal Completion Date", id: "dates.dealCompletion" },
    { label: "Renegotiation Start Date", id: "dates.renegotiationStart" },
    { label: "SREC Transfer Date", id: "dates.srecTransferDate" },
    { label: "DA Comitted Date", id: "dates.daCommittedDate" },
    { label: "Lease Record Date", id: "negotiation.dateLeaseRead" },
    { label: "Lease Received Date", id: "negotiation.dateLeaseReceived" },
    { label: "Mailed To DA", id: "negotiation.dateMailedToDA" },
    { label: "Revised", id: "negotiation.revisedBy" },
    {
      label: "Date Mailed to Landlord",
      id: "negotiation.dateMailedToLandLord",
    },
    {
      label: "Received Date from landlord",
      id: "negotiation.receivedDateFromLandLord",
    },
    { label: "Offer Received Date", id: "negotiation.offerReceivedDate" },
    { label: "Received from Landlord", id: "negotiation.receivedFromLandLord" },
    { label: "Offer received", id: "negotiation.offerReceived" },
    {
      label: "Notification letter requested",
      id: "notificationLetters.requested",
    },
    { label: "Notification letter sent", id: "notificationLetters.sent" },
    {
      label: "Notification not required",
      id: "notificationLetters.notRequired",
    },
    { label: "Initial Term Years", id: "indicators.initialTerm.years" },
    { label: "Initital Term Months", id: "indicators.initialTerm.months" },
    { label: "Initial Term Days", id: "indicators.initialTerm.days" },
    { label: "Notice In months", id: "indicators.noticeInMonths" },
    { label: "Automatic Renewal", id: "indicators.automaticRenewal" },
    { label: "Notice Sent Date", id: "indicators.noticeSentDate" },
    { label: "Date to send", id: "indicators.dateToSend" },
    { label: "Non RenewApproval Date", id: "indicators.nonRenewApproval" },
    { label: "NonRenew Letter Sent Date", id: "indicators.nonRenewLetterSent" },
    { label: "Indicators Lease Signed Date", id: "indicators.leaseSignDate" },
    {
      label: "Indicators Commencement Date",
      id: "indicators.commencementDate",
    },
    { label: "Indicators Expiration Date", id: "indicators.expirationDate" },
    { label: "Liability Clause", id: "liabilityInfo.clause" },
    { label: "Liability Lease Section", id: "liabilityInfo.leaseSection" },
    { label: "Liability Effective Date", id: "liabilityInfo.effectiveDate" },
    {
      label: "Excessive Liability Approval",
      id: "liabilityInfo.excessiveLiabilityApproval",
    },
    { label: "Liability cost", id: "liabilityInfo.cost" },
    { label: "Liability months", id: "liabilityInfo.months" },
    { label: "Liability clause", id: "kickOutInfo.clause" },
    { label: "Liability lease section", id: "kickOutInfo.leaseSection" },
    {
      label: "Kickout Information Effective Date",
      id: "kickOutInfo.effectiveDate",
    },
    { label: "Kickout Information Cost", id: "kickOutInfo.cost" },
    { label: "Kickout Information months", id: "kickOutInfo.months" },
    {
      label: "Kickout Information Excessive Liability Approval",
      id: "kickOutInfo.excessiveLiabilityApproval",
    },
    {
      label: "Kickout Information Received Date",
      id: "kickOutInfo.receivedDate",
    },
    { label: "Next Remodel Date", id: "misc.nextRemodelDate" },
    { label: "Break Clause Date", id: "misc.breakClauseDate" },
    { label: "Date of possession", id: "misc.dateOfPossession" },
    {
      label: "Landloard To Provide Notice",
      id: "misc.landLordToProvideNotice",
    },
    { label: "Radius", id: "misc.radius" },
    { label: "Option Type", id: "options.type" },
    { label: "Option Date", id: "options.date" },
    { label: "Option Year", id: "options.years" },
    { label: "Option Month", id: "options.months" },
    { label: "Use clause restriction", id: "restriction.useClauseRestriction" },
    { label: "Lease Section", id: "restriction.leaseSection" },
    { label: "New", id: "restriction.new" },
    { label: "Right to first refusal", id: "firstRefusal.rightTo1stRefusal" },
    { label: "Lease Section", id: "firstRefusal.leaseSection" },
    { label: "Clause", id: "exclusiveInfo.clause" },
    { label: "Lease Section", id: "exclusiveInfo.leaseSection" },
    { label: "Notice", id: "breakClause.notice" },
    {
      label: "Break Clause Date to Send",
      id: "breakClause.breakClauseDateToSend",
    },
    { label: "Break Clause Leas Sign Date", id: "breakClause.leaseSignDate" },
  ];

  const dateFields = [
    "fileSiteReview",
    "foreignTerritoryLeaseApproval",
    "excessiveLiabilityApproval",
  ];

  const nestedDateFields = {
    dates: [
      "incomplete",
      "negotiation",
      "franchiseSold",
      "leaseReceived",
      "completedSiteEvaluation",
      "intentToSubLease",
      "specAuthorization",
      "checkForTwoMonthsRent",
      "dealCompletion",
      "renegotiationStart",
      "srecTransferDate",
      "daCommittedDate",
      "dateMailedToLandLord",
    ],
    liabilityInfo: ["effectiveDate", "excessiveLiabilityApproval"],
    indicators: [
      "noticeSentDate",
      "dateToSend",
      "nonRenewApproval",
      "nonRenewLetterSent",
      "leaseSignDate",
      "commencementDate",
      "expirationDate",
    ],
    kickOutInfo: ["excessiveLiabilityApproval"],
    negotiation: ["receivedFromLandLord", "offerReceivedDate", "offerReceived"],
    notificationLetters: ["requested", "sent"],
  };

  const notDates = {
    breakClause: ["breakClauseDateToSend"],
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getSequenceAndSatelliteNumberForStorNumber(storeInfo.storeNo);
      listingLeaseHandler(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    }
  }, []);

  const getSequenceAndSatelliteNumberForStorNumber = (id) => {
    if (!Number.isInteger(parseInt(id))) {
      return;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTableData = (satelliteNo, sequenceNo, leaseId, page, hasToReset) => {
    if (!Number.isInteger(parseInt(leaseId))) {
      return;
    }
    const invalidCombination = !checkValidStoreCombination(
      storeInfo.storeNo,
      satelliteNo,
      sequenceNo
    );
    if (invalidCombination) {
      return;
    }
    let dataCheckingCondition = !pageArray.includes(page);
    if (hasToReset) {
      dataCheckingCondition = true;
    }
    if (dataCheckingCondition) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_HISTORY}`, null, {
        modelName: auditModelNames.storeLease,
        storeNo: storeInfo.storeNo,
        sortBy: "-createdAt",
        satelliteNo,
        page,
        limit: 20,
        sequenceNo,
        _id: leaseId,
      })
        .then((res) => {
          if (!res?.message) {
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              const { details } = item;
              const allDetails = {};

              for (let detail in details) {
                const mainKey = `${detail}`.toLowerCase();
                if (typeof details[detail] === "object") {
                  for (let subDetail in details[detail]) {
                    let value = details[detail][subDetail];
                    if (details[detail][subDetail] === "object") {
                      for (let nestedDetail in details[detail][subDetail]) {
                        allDetails[`${detail}.${subDetail}.${nestedDetail}`] =
                          details[detail][subDetail][nestedDetail];
                      }
                    } else {
                      const mainKey = `${subDetail}`.toLowerCase();
                      const subDateList = nestedDateFields?.[detail] || [];
                      const nonDate = notDates?.[detail] || [];
                      if (
                        (subDateList.includes(`${subDetail}`) ||
                          `${mainKey}`.includes("date")) &&
                        !nonDate.includes(`${subDetail}`)
                      ) {
                        if (`${subDetail}` !== "breakClauseDateToSend")
                          value = dateFun(value);
                      }
                      allDetails[`${detail}.${subDetail}`] = value;
                    }
                  }
                } else {
                  let value = details[detail];
                  if (
                    dateFields.includes(`${detail}`) ||
                    mainKey.includes("date")
                  ) {
                    value = dateFun(value);
                  }
                  allDetails[detail] = value;
                }
              }
              return allDetails;
            });
            if (page == 1) {
              settableData(results);
              setPageArray([1]);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              settableData([...tableData, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          } else {
            settableData([]);
            toast.error(res.mesage, { autoClose: 2000 });
          }
        })
        .catch((err) => console.log(err));
    } else {
      settableData([]);
    }
  };

  const listingLeaseHandler = (id, satelliteNo, sequenceNo) => {
    setStoreInfo({
      storeNo: id,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
    });
    const invalidStoreCombination = !checkValidStoreCombination(
      id,
      satelliteNo,
      sequenceNo
    );

    if (invalidStoreCombination) {
      return;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_LEASES}${id}`,
      null,
      {
        satelliteNo,
        sequenceNo,
      }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
          setLeaseIdList(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        const { sequenceNo } = p;

        return {
          title: p.storeNo,
          id: p.storeNo,
          satellite: p.satelliteNum,
          sequenceNo,
        };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value, reason) => {
    setLeaseInfo({ leaseId: null });
    setSequenceList([]);
    setSatelliteList([]);
    setLeaseIdList([]);
    if (value) {
      getData(value);
      setStoreInfo({
        storeNo: value,
        sequenceNo: "",
        satelliteNo: "",
      });
    } else {
      clearData();
    }
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
    setStoreOptions([]);
    settableData([]);
    setLeaseIdList([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
  };

  return (
    <div className="lease">
      <Grid container md={12} sx={{ mt: 5, mb: 3 }}>
        <Grid item sx={{ mr: 3 }} md={2} xs={6}>
          <Autocomplete
            id="free-solo-demo"
            className="subs-autocomplete"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            sx={{ width: "17em" }}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getSequenceAndSatelliteNumberForStorNumber(val.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              listingLeaseHandler(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo
              );
            }}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              listingLeaseHandler(
                storeInfo.storeNo,
                storeInfo.satelliteNo,
                val?.value
              );
            }}
          />
        </Grid>

        <Grid item md={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="lease Id"
            className="sel-item"
            options={leaseIdList}
            value={
              ![undefined, null, ""].includes(leaseInfo.leaseId)
                ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                : ""
            }
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
            onChange={(val) => {
              setLeaseInfo({ leaseId: val?.value });
              getTableData(
                storeInfo.satelliteNo,
                storeInfo.sequenceNo,
                val?.value,
                1,
                true
              );
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container md={12}>
        <Select
          placeholder="History Type"
          fullWidth
          classNamePrefix="custom_select"
        />
      </Grid> */}
      <Grid sx={{ mt: 3 }}>
        <div className="title">Lease History</div>
        <StickyHeaderCon
          dataList={tableData}
          columnsList={dataValueLabels}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          // containerHeight="table-con"
        />
      </Grid>
    </div>
  );
};

export default LeaseDateHistory;
