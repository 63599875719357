import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import BdLeadDocument from "../components/document";
import Lead from "../components/document/leadDocument";
import { BD_LEAD_ROUTE } from "../../common-utilities/utils/end-points/bd-lead";
import Dashboard from "../components/Dashboard";

export const bdLeadRouteConfig = {
  mainPath: {
    path: "/",
    // element: <PrivateRoute />,
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: BD_LEAD_ROUTE,
  routes: [
    {
      path: "/dashboard",
      Element: Dashboard,
      element: <Dashboard />,
      exact: true,
    },
    {
      path: "/",
      Element: BdLeadDocument,
      element: <BdLeadDocument />,
      exact: true,
    },
    {
      path: "/lead",
      Element: Lead,
      element: <Lead />,
      exact: true,
    },
    {
      path: "/lead/edit/:leadId",
      Element: Lead,
      element: <Lead />,
      exact: true,
    },
  ],
};
const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<BdLeadDocument />} />
            <Route exact path="/lead" element={<Lead />} />
            <Route exact path="/lead/edit/:leadId" element={<Lead />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
