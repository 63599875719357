import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import ElectricityConsumptionTable from "../components/electricity-consumption-table";

const ElectricityConsumptionRoutes = ({
  token = tokenMainKeys.electricityConsumptionTable,
  slug = appSlugs.electricityConsumption,
}) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<PrivateRoute token={token} slug={slug} />}
      >
        <Route
          path="/"
          element={<ElectricityConsumptionTable token={token} slug={slug} />}
        />
      </Route>
    </Routes>
  );
};

export default ElectricityConsumptionRoutes;
