import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/logIn";
import PrivateRoute from "./private-route";
import NotFound from "../components/not-found";
import Home from "../components/home";
import CreateUser from "../components/admin-screen/create-user";
import Apps from "../components/apps";
import Roles from "../components/roles";
import Blogs from "../components/blogs";
import { invoiceTrackingRouteConfig } from "../packages/invoice-tracking/routes";
import { bdLeadRouteConfig } from "../packages/bd-tool/routes";
import { storeMasterRouteConfig } from "../packages/store-master/routes";
import { useCFApprovalCount } from "../components/common-hooks/useCfApprovalCount";
import { cfApprovalRouteConfig } from "../packages/cf-approval/routes";
import TrainingManualRoutes from "./training-manual.route";
import { endPointsForProject } from "./endpoints";
import DocuStorageRoutes from "./docu-storage.route";
import AdminAccess from "../components/admin-access";
import QnetRoutes from "./qnet.route";
import TechnetRoutes from "./tech-net.route";
import SubsRoutes from "./subs.routes";
import UserProfile from "../components/user-profile";
import CentralReports from "../packages/central-reports/components";
import PoliciesRoutes from "./policies-route";
import FranchiseMemoRoutes from "./franchise-memo-route";
import VendorOnboardRoutes from "../packages/vendor-onboard/routes";
import DepartmentSection from "../components/departments";
import { pettyExpenseRouteConfig } from "../packages/petty-expense/routes";
import VoucherResponse from "../packages/petty-expense/components/petty-expense-response";
import InvalidResponse from "../packages/petty-expense/components/invalid-response";
import FranchisePolicyRoutes from "./franchise-policies-route";
import InvoiceTrackingRoutes from "../packages/invoice-tracking/routes";
import "./index.scss";
import { appSlugs, tokenMainKeys } from "../utils/constants";
import FinanceRoutes from "../packages/finance/routes";
import PLReportsRoutes from "../packages/pl-report/routes";
import ElectricityConsumptionRoutes from "../packages/electricity-consumption/routes";

const AppRoutes = () => {
  const InvoiceTrackingMainRoute = invoiceTrackingRouteConfig.mainPath.Element;
  const BdLeadMainRoute = bdLeadRouteConfig.mainPath.Element;
  const StoreMasterMainRoute = storeMasterRouteConfig.mainPath.Element;
  const CfApprovalMainRoute = cfApprovalRouteConfig.mainPath.Element;
  const PettyExpenseMainRoute = pettyExpenseRouteConfig.mainPath.Element;
  const [reloadPendingApprovals, setReloadPendingApprovals] =
    useCFApprovalCount();
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/' element={<PrivateRoute />}>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/create-user' element={<CreateUser />} />
            <Route exact path='/apps' element={<Apps />} />
            <Route exact path='/roles' element={<Roles />} />
            <Route exact path='/departments' element={<DepartmentSection />} />
            <Route exact path='/blogs' element={<Blogs />} />
            <Route exact path='/my-access' element={<AdminAccess />} />
            <Route exact path='/my-account' element={<UserProfile />} />
            <Route exact path='/central-reports' element={<CentralReports />} />
          </Route>
          <Route
            exact
            element={<InvoiceTrackingMainRoute />}
            path={invoiceTrackingRouteConfig.mainPath.path}
          >
            {invoiceTrackingRouteConfig.routes.map((item, index) => {
              const { Element } = item;
              return (
                <Route
                  exact
                  key={index}
                  element={<Element />}
                  path={`${invoiceTrackingRouteConfig.mainRoute}${item.path}`}
                />
              );
            })}
          </Route>
          <Route
            exact
            path={`${pettyExpenseRouteConfig.mainRoute}/response-recorded`}
            element={<VoucherResponse />}
          ></Route>
          <Route
            exact
            path={`${pettyExpenseRouteConfig.mainRoute}/invalid-response`}
            element={<InvalidResponse />}
          ></Route>
          <Route
            exact
            element={<PettyExpenseMainRoute />}
            path={pettyExpenseRouteConfig.mainPath.path}
          >
            {pettyExpenseRouteConfig.routes.map((item, index) => {
              const { Element } = item;
              return (
                <Route
                  exact
                  key={index}
                  element={<Element />}
                  path={`${pettyExpenseRouteConfig.mainRoute}${item.path}`}
                />
              );
            })}
          </Route>
          <Route
            exact
            element={<BdLeadMainRoute />}
            path={bdLeadRouteConfig.mainPath.path}
          >
            {bdLeadRouteConfig.routes.map((item, index) => {
              const { Element } = item;
              return (
                <Route
                  exact
                  key={index}
                  element={<Element />}
                  path={`${bdLeadRouteConfig.mainRoute}${item.path}`}
                />
              );
            })}
          </Route>

          <Route
            exact
            element={
              <CfApprovalMainRoute
                reloadPendingApprovals={reloadPendingApprovals}
                setReloadPendingApprovals={setReloadPendingApprovals}
              />
            }
            path={cfApprovalRouteConfig.mainPath.path}
          >
            {cfApprovalRouteConfig.routes.map((item, index) => {
              const { Element } = item;

              return (
                <Route
                  exact
                  key={index}
                  element={
                    <Element
                      reloadPendingApprovals={reloadPendingApprovals}
                      setReloadPendingApprovals={setReloadPendingApprovals}
                    />
                  }
                  path={`${cfApprovalRouteConfig.mainRoute}${item.path}`}
                />
              );
            })}
          </Route>
          <Route
            exact
            element={<StoreMasterMainRoute />}
            path={storeMasterRouteConfig.mainPath.path}
          >
            {storeMasterRouteConfig.routes.map((item, index) => {
              const { Element } = item;
              return (
                <Route
                  exact
                  key={index}
                  element={<Element />}
                  path={`${storeMasterRouteConfig.mainRoute}${item.path}`}
                />
              );
            })}
          </Route>
          <Route
            path={`${endPointsForProject.trainingManual}`}
            element={<TrainingManualRoutes />}
          />
          <Route
            path={`${endPointsForProject.memo}`}
            element={<FranchiseMemoRoutes />}
          />
          <Route
            path={`${endPointsForProject.policies}`}
            element={<PoliciesRoutes />}
          />
          <Route
            path={`${endPointsForProject.docuStorage}`}
            element={<DocuStorageRoutes />}
          />
          <Route
            path={`${endPointsForProject.technet}`}
            element={<TechnetRoutes />}
          />
          <Route
            path={`${endPointsForProject.vendorOnboard}`}
            element={<VendorOnboardRoutes />}
          />
          <Route
            path={`${endPointsForProject.subs}`}
            element={<SubsRoutes />}
          />
          <Route
            path={`${endPointsForProject.qnet}`}
            element={<QnetRoutes />}
          />
          <Route
            path={`${endPointsForProject.subwayFinance}`}
            element={<FinanceRoutes />}
          />
           <Route
            path={`${endPointsForProject.plReport}`}
            element={<PLReportsRoutes />}
          />
           <Route
            path={`${endPointsForProject.electricConsumption}`}
            element={<ElectricityConsumptionRoutes />}
          />
          <Route path='*' element={<NotFound />}></Route>
          <Route
            path={`${endPointsForProject.franchisePolicy}`}
            element={<FranchisePolicyRoutes />}
          />
          <Route
            path={endPointsForProject.vendorInvoiceSubmission}
            element={
              <InvoiceTrackingRoutes
                token={tokenMainKeys.vendorInvoiceSubmission}
                slug={appSlugs.vendorInvoiceSubmission}
              />
            }
          />
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
