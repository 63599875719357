/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState("/");
  React.useEffect(() => {
    const list = SIDE_MENU();
    setMenuItems(list);
  }, []);

  React.useEffect(() => {
    setActiveMenu(
      menuItems.find((m) => m.url === window.location.pathname) || {}
    );
  }, [menuItems]);

  const onMenuClick = (url) => {
    navigate(url);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      <List>
        <div className="side-menu-header-subs">
          <img
            src={require("../../../common-utilities/resources/logo.png")}
            width="120"
          />
          <IconButton onClick={() => setOpen(!open)}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
        <div className="menu-items-subs">
          <List>
            {menuItems.map((menu) => {
              const { Submenu } = menu;
              return (
                <>
                  {Submenu ? (
                    <Submenu
                      activeMenu={activeMenu}
                      onMenuClick={onMenuClick}
                      setActiveMenu={setActiveMenu}
                      menu={menu}
                    />
                  ) : (
                    <ListItem
                      className={
                        menu.value === activeMenu.value
                          ? "menu-item menu-item-active"
                          : "menu-item"
                      }
                      onClick={() => {
                        onMenuClick(menu.url);
                        setActiveMenu(menu);
                      }}
                      key={menu.value}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>{menu.logo}</ListItemIcon>
                          <ListItemText primary={menu.label} />
                        </ListItemButton>
                      </ListItem>
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </div>
      </List>
    </Box>
  );

  return (
    <div className="side-menu-web">
      <IconButton onClick={() => setOpen(!open)}>
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {list("left")}
      </Drawer>
    </div>
  );
}
