import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "react-select";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { STATUS } from "./config";
import "./index.scss";
import { getOfflineData } from "../../utils/offline-services";
import { dateFun } from "../../../common-utilities/utils";

const DemographicDetails = ({
  demographicData,
  setPersonData,
  personId,
  setPassword,
}) => {
  return (
    <div>
      <div className="pd-title">Demographic Details</div>
      <div className="input-form-container">
        <div className="content-container">
          <div className="g-r-2">
            <TextField
              id="outlined-basic"
              label="National ID"
              className="subs-text-field"
              fullWidth
              value={demographicData.nationalId}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  demographicDetails: {
                    ...prevVal.demographicDetails,
                    nationalId: e.target.value,
                  },
                }));
              }}
              variant="outlined"
            />
            <FormControl style={{ height: "30px", marginTop: "-15px" }}>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>Gender</span>
                <div>
                  <FormControlLabel
                    value="Female"
                    checked={demographicData.gender === "Female"}
                    control={<Radio />}
                    onChange={(e) => {
                      setPersonData((prevVal) => ({
                        ...prevVal,
                        demographicDetails: {
                          ...prevVal.demographicDetails,
                          gender: e.target.value,
                        },
                      }));
                    }}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Male"
                    checked={demographicData.gender === "Male"}
                    control={<Radio />}
                    label="Male"
                    onChange={(e) => {
                      setPersonData((prevVal) => ({
                        ...prevVal,
                        demographicDetails: {
                          ...prevVal.demographicDetails,
                          gender: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>

          <div className="g-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Status"
              value={
                demographicData?.status && {
                  label: demographicData.status,
                  value: demographicData.status,
                }
              }
              options={STATUS}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  demographicDetails: {
                    ...prevVal.demographicDetails,
                    status: e.value,
                  },
                }));
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <div className="row-div">
              <TextField
                id="outlined-basic"
                type="date"
                className="subs-text-field"
                fullWidth
                label="Deceased Date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={dateFun(demographicData.deceasedDate)}
                onChange={(e) => {
                  setPersonData((prevVal) => ({
                    ...prevVal,
                    demographicDetails: {
                      ...prevVal.demographicDetails,
                      deceasedDate: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="g-r-2">
            <TextField
              id="outlined-basic"
              label="Primary Citizenship"
              className="subs-text-field"
              fullWidth
              variant="outlined"
              value={demographicData.primaryCitizenship}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  demographicDetails: {
                    ...prevVal.demographicDetails,
                    primaryCitizenship: e.target.value,
                  },
                }));
              }}
            />
            <TextField
              id="outlined-basic"
              className="subs-text-field"
              label="Secondary Citizenship"
              fullWidth
              variant="outlined"
              value={demographicData.secondaryCitizenship}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  demographicDetails: {
                    ...prevVal.demographicDetails,
                    secondaryCitizenship: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="outlined-basic"
              className="subs-text-field"
              label="Citizenship/Residency Proof"
              fullWidth
              variant="outlined"
              value={demographicData.residencyProof}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  demographicDetails: {
                    ...prevVal.demographicDetails,
                    residencyProof: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DemographicDetails;
