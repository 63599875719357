import { HTTP_METHODS, invokeApi } from '../../utils/http-service';
import { HOSTNAME } from '../../../common-utilities/utils/end-points';
import { REST_URLS } from '../../../common-utilities/utils/end-points/cf-approval';
import { useEffect, useState } from 'react';
import './index.scss';
import { DownloadModal } from '../customerModals';
import { getHeaderConfig } from './config';
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from '../../utils/constants';
import MuiTable from '../../../common-utilities/core/mui-table';
import { setApproverModalDetails } from './utils';

const ApprovedForms = ({
  setCountFunction,
  setMainModalDetails,
  commonFilters,
}) => {
  const [approvedForms, setapprovedForms] = useState({});
  const [tableItems, setTableItems] = useState(null);

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });
  const loadData = (params) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.APPROVED_FORMS}`,
      null,
      { ...params, ...commonFilters }
    )
      .then((response) => {
        if (response) {
          const count = response?.totalResults;
          if (count) {
            setCountFunction(count);
          } else {
            setCountFunction(0);
          }
          setapprovedForms(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    loadData(filters);
  }, [filters, commonFilters]);

  const onClickShowDataFunction = (data, type, approverNo) => {
    if (data) {
      switch (type) {
        case 'Approver':
          setApproverModalDetails(data, setMainModalDetails, approverNo);
          break;
        default:
          {
            const isCocoAcquired = data?.storeType === storeTypes.cocoAcquired;
            const oddLabel = isCocoAcquired ? 'ODD' : 'Site approval pack';

            let finalData = [
              { label: 'Unit Economics Model', value: '' },
              { label: oddLabel, value: '' },
            ];
            const { uploadedDocuments, snapShot } = data;
            if (uploadedDocuments) {
              const { fiveyearplan, newStoreDetailsExcel, newStoreDetailsPPT } =
                uploadedDocuments;
              finalData[0].value = fiveyearplan;
              finalData[1].value = newStoreDetailsPPT;
              if (!isCocoAcquired) {
                finalData.push({
                  label: 'New Store Details (excel)',
                  value: newStoreDetailsExcel,
                });
              }
            }

            if (snapShot) {
              const {
                unitEconomicModel,
                competitionAndNearbyRestaurantSales,
                gapmapSnapshot,
              } = snapShot;
              finalData = [
                ...finalData,
                {
                  label: 'Unit Economics Model UEM Cover',
                  value: unitEconomicModel,
                },
                {
                  label: `${
                    isCocoAcquired
                      ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
                      : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
                  }`,
                  value: competitionAndNearbyRestaurantSales,
                },
                {
                  label: `${
                    isCocoAcquired
                      ? 'Total Acquisition Cost'
                      : 'Gapmap snapshot'
                  }`,
                  value: gapmapSnapshot,
                },
              ];
            }
            setTableItems(finalData);
          }
          break;
      }
    }
  };
  return (
    <div className="approval-container">
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={approvedForms.results || []}
        filters={filters}
        pageCount={approvedForms.totalPages}
        onClick={onClickShowDataFunction}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>

      {tableItems && (
        <DownloadModal
          title="Uploaded files"
          closeModal={setTableItems}
          data={tableItems}
          tableClassName
        />
      )}
    </div>
  );
};

export default ApprovedForms;
