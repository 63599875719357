import { useEffect, useState } from 'react';
import CustomModal from '../../../../../common-utilities/core/modal';
import { getApprovalConfigList, updateCreateConfig } from '../apis';
import { setProperty } from '../../../../../common-utilities/utils/misc';
import { setEditCreateData } from '../utils';
import { Button } from '@mui/material';
import './index.scss';
import Stages from './sub-components/stages';
import MainConfig from './sub-components/config';

const EditCreateModal = ({
  showModal,
  onClose,
  data,
  title,
  onSuccess,
  mainConfig,
  setMainConfig,
}) => {
  const [modalData, setModalData] = useState({});
  const defaultFilters = { page: 1, limit: 5 };
  const [filters, setFilters] = useState(defaultFilters);
  const [config, setConfig] = useState({
    allowedRoleList: [],
  });

  useEffect(() => {
    setEditCreateData(data, setModalData, setFilters);
  }, [data, showModal]);

  useEffect(() => {
    if (mainConfig?.refreshRoleList) {
      getApprovalConfigList((list) => {
        setProperty('allowedRoleList', list, setConfig);
      });
      setProperty('refreshRoleList', false, setMainConfig);
    }
  }, [mainConfig?.refreshRoleList]);

  useEffect(() => {
    if (showModal) {
      getApprovalConfigList((list) => {
        setProperty('allowedRoleList', list, setConfig);
      });
      return;
    }
    setFilters(defaultFilters);
    setModalData({});
  }, [showModal]);

  return (
    <>
      {showModal && (
        <>
          <CustomModal
            classesToPreventClosing={['MuiAutocomplete-option']}
            title={title}
            onClose={onClose}
          >
            <div className="edit-create-model">
              <MainConfig
                mainConfig={mainConfig}
                filters={filters}
                modalData={modalData}
                setModalData={setModalData}
                config={config}
                setFilters={setFilters}
              />
              <Stages
                filters={filters}
                modalData={modalData}
                setModalData={setModalData}
                config={config}
                setFilters={setFilters}
              />
              <div className="update-crt-btns">
                <Button
                  onClick={() => {
                    updateCreateConfig(modalData, onSuccess);
                  }}
                  variant="outlined"
                >
                  {modalData?.id ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </CustomModal>
        </>
      )}
    </>
  );
};

export default EditCreateModal;
