import {
  clearOfflineData,
  setOfflineData,
} from "../../common-utilities/utils/offline-services";
import { REST_URLS } from "../../common-utilities/utils/end-points/docu-storage";
import { HTTP_METHODS, invokeApi } from "./http-service";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { tokenMainKeys } from "../../../utils/constants";

export const redirectToIntranetPortal = () => {
  clearOfflineData(tokenMainKeys.docuStorage, "user");
  clearOfflineData(tokenMainKeys.docuStorage, "tokens");
};

export const getUserDetailsFromIntranet = (
  Authorization,
  customLogic,
  tokens
) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`,
    null,
    null,
    Authorization
  )
    .then((res) => {
      if (res?.message) {
        console.log(res.message);
        redirectToIntranetPortal();
        return;
      }
      if (tokens) {
        setOfflineData(tokenMainKeys.docuStorage, "tokens", tokens);
      }
      const role = res?.role;
      setOfflineData(tokenMainKeys.docuStorage, "user", { ...res, role });
      if (typeof customLogic === "function") {
        customLogic(res);
      }
    })
    .catch((err) => {
      console.log(err);
      redirectToIntranetPortal();
    });
};
