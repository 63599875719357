import { appSlugs } from "../../../../utils/constants";
import { pick } from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import { deleteObjectProperties } from "../../../common-utilities/utils/misc";
import { FINANCE_USER_ID, RM_APPROVE_REQUIRED } from "../../utils/constants";

export const isFormValid = (formDetails, storeError, slug) => {
  let flag = false;
  const { invoiceAttachment } = formDetails;

  return (
    !invoiceAttachment ||
    !formDetails.invoiceDate ||
    (slug === appSlugs.vendorInvoiceSubmission
      ? !formDetails.store || !Object.keys(formDetails.vendor || {}).length
      : storeError)
  );
};

export const getCrtDetailParams = (formDetails, storeDetails, slug) => {
  const {
    vendor = {},
    invoiceAttachment = {},
    invoiceType = {},
    poAttachment = {},
    grnAttachment = {},
  } = formDetails;

  const { rm = {}, am = {} } = storeDetails;

  let payload = {
    poNumber: formDetails.poNumber,
    grnNumber: formDetails.grnNumber,
    invoiceNumber: formDetails.invoiceNumber,
    invoiceType: invoiceType.value,
    invoiceDate: formDetails.invoiceDate,
    amount: formDetails.amount,
    invoiceAttachment: invoiceAttachment.url,
    grnAttachment: grnAttachment.url,
    poAttachment: poAttachment.url,
    remarks: formDetails.remarks,
    rmApprovalRequired: RM_APPROVE_REQUIRED.includes(invoiceType.value),
    amApprovalRequired: RM_APPROVE_REQUIRED.includes(invoiceType.value),
  };

  if (vendor.value === "NEW_VENDOR") {
    payload.vendorName = formDetails.vendorName;
  } else {
    payload.vendor = vendor.id;
  }

  if (RM_APPROVE_REQUIRED.includes(invoiceType.value)) {
    payload.am = am.id;
    payload.rm = rm.id;
  } else {
    payload.financeUser = FINANCE_USER_ID;
  }
  if (slug === appSlugs.vendorInvoiceSubmission) {
    deleteObjectProperties(payload, [
      "financeUser",
      "rm",
      "am",
      "invoiceType",
      "rmApprovalRequired",
      "amApprovalRequired",
    ]);
    const additionalDetails = pick(formDetails, [
      "paymentStatus",
      "utr",
      "paymentDate",
      "deliveryDate",
      "amountPaid",
      "store",
    ]);
    payload = { ...payload, ...additionalDetails };
  }
  return {
    payload,
    url:
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}`
        : `${HOSTNAME}${REST_URLS.INVOICE}`,
  };
};
