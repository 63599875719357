// Lease and Optional Agreement have many common fields
import {
  getrsecDepositInMonthsSelectList,
  getRentRevShareDeliveryExcludesAggregatorsCommissionSelectList,
  agreementAndPostAgreementDocsLabelsAndValues,
} from '../../../../../utils/coco-built.config';

export const leaseAndOptionAgreementConfig = (
  formDetails,
  errors,
  documentType
) => {
  const rsecDepositInMonthSelectList = getrsecDepositInMonthsSelectList();
  const rentRevShareDeliveryExcludesAggregatorsCommissionSelectList =
    getRentRevShareDeliveryExcludesAggregatorsCommissionSelectList();
  const config = [
    {
      label: 'Monthly Rent excluding GST- MG - Start year (In Rs)*',
      name: 'monthlyRentMgStartYear',
      type: 'Number',
      value: formDetails.monthlyRentMgStartYear,
      error: errors.monthlyRentMgStartYear,
      helperText: errors.monthlyRentMgStartYear ? 'Required' : '',
    },
    {
      label: 'Monthly Rent - Revenue Share on Dine in - Start year (%)*',
      name: 'monthlyRentRevenueShareOnDineInStartYear',
      type: 'Number',
      value: formDetails.monthlyRentRevenueShareOnDineInStartYear,
      error: errors.monthlyRentRevenueShareOnDineInStartYear,
      helperText: errors.monthlyRentRevenueShareOnDineInStartYear
        ? 'Required'
        : '',
    },
    {
      label: 'Monthly Rent- Revenue Share on Delivery- Start year (%)*',
      name: 'monthlyRentRevenueShareOnDeliveryInStartYear',
      type: 'Number',
      value: formDetails.monthlyRentRevenueShareOnDeliveryInStartYear,
      error: errors.monthlyRentRevenueShareOnDeliveryInStartYear,
      helperText: errors.monthlyRentRevenueShareOnDeliveryInStartYear
        ? 'Required'
        : '',
    },
    {
      label: 'Landlord* (Name GST & PAN)',
      name: 'landlord',
      type: 'text',
      value: formDetails.landlord,
      error: errors.landlord,
      helperText: errors.landlord ? 'Required' : '',
    },
    {
      label: 'Lease Address*',
      name: 'leaseAddress',
      type: 'text',
      value: formDetails.leaseAddress,
      error: errors.leaseAddress,
      helperText: errors.leaseAddress ? 'Required' : '',
    },
  ];

  documentType !==
    agreementAndPostAgreementDocsLabelsAndValues.optionAgreement &&
    config.push(
      {
        label: 'Fit out period (In Days)',
        name: 'fitOutPeriod',
        type: 'Number',
        value: formDetails.fitOutPeriod,
        error: errors.fitOutPeriod,
        helperText: errors.fitOutPeriod ? 'Required' : '',
      },
      {
        label: 'Lock in period (In Months)',
        name: 'lockInPeriod',
        type: 'Number',
        value: formDetails.lockInPeriod,
        error: errors.lockInPeriod,
        helperText: errors.lockInPeriod ? 'Required' : '',
      }
    );

  config.push({
    label: 'Monthly CAM - Start Year (in Rs)*',
    name: 'cam',
    type: 'Number',
    value: formDetails.cam,
    error: errors.cam,
    helperText: errors.cam ? 'Required' : '',
  });

  documentType !==
    agreementAndPostAgreementDocsLabelsAndValues.optionAgreement &&
    config.push({
      label: 'Investment from Landlord(in Rs)*',
      name: 'landlordInvestment',
      type: 'Number',
      value: formDetails.landlordInvestment,
      error: errors.landlordInvestment,
      helperText: errors.landlordInvestment ? 'Required' : '',
    });

  config.push(
    {
      label: 'R Sec Deposit(in Rs)*',
      name: 'rsecdeposit',
      type: 'Number',
      value: formDetails.rsecdeposit,
      error: errors.rsecdeposit,
      helperText: errors.rsecdeposit ? 'Required' : '',
    },
    {
      label: 'R Sec deposit(in months)*',
      name: 'rsecdepositinmonths',
      type: 'select',
      placeholder: 'Select R Sec deposit(in months)',
      value: formDetails.rsecdepositinmonths,
      error: errors.rsecdepositinmonths,
      options: rsecDepositInMonthSelectList,
      helperText: errors.rsecdepositinmonths ? 'Required' : '',
    },
    {
      label: 'Rent Rev Share Delivery Excludes aggregators commission*',
      name: 'rentRevShareDeliveryExcludesAggregatorsComission',
      type: 'select',
      placeholder:
        'Select Rent Rev Share Delivery Excludes aggregators commission',
      value: formDetails.rentRevShareDeliveryExcludesAggregatorsComission,
      error: errors.rentRevShareDeliveryExcludesAggregatorsComission,
      options: rentRevShareDeliveryExcludesAggregatorsCommissionSelectList,
      helperText: errors.rentRevShareDeliveryExcludesAggregatorsComission
        ? 'Required'
        : '',
    },
    {
      label: 'Upload Document',
      name: 'docs',
      type: 'upload',
      value: formDetails.docs,
      error: errors.docs,
      helperText: errors.docs ? 'Required' : '',
      documentSavingKey: 'document',
      uploadType: `${formDetails?.documentType?.value}`,
      notUploadCheckBox: true,
      notUploadKey: 'notApplicable',
    }
  );

  return config;
};
