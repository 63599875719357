import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: 'Vendor Name',
      id: 'name',
    },
    {
      label: 'Vendor Code',
      id: 'vendorCode',
    },
    {
      label: 'Item Name',
      id: 'categoryType',
      render: (data) => {
        const { item = {} } = data;
        return item?.name;
      },
    },
    {
      label: 'Item Code',
      id: 'item.itemCode',
    },
    {
      label: 'Price',
      id: 'price',
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        return (
          <>
            <Button
              disabled={!isUpdateAllowed('master_data')}
              variant="text"
              onClick={() => editVendor(data, 'EDIT')}
            >
              Edit
            </Button>
            <Button
              variant="text"
              disabled={!isDeleteAllowed('master_data')}
              onClick={() => setDeleteDetails(data)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};
