import { Button, Chip, TextField } from '@mui/material';
import FileUpload from '../../../../common-utilities/core/file-uploader';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { useState } from 'react';
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from '../../../utils/attachments';

const CommentAttachments = ({ addAttachments, onSave }) => {
  const [comment, setComment] = useState('');
  const [attachments, setAttachments] = useState([]);

  const [config, setConfig] = useState({
    disableSave: false,
  });

  const onDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  return (
    <div className="comment-attachments-container">
      <TextField
        label="Comments"
        fullWidth
        size="small"
        onChange={(e) => setComment(e.target.value)}
      />

      <FileUpload
        uploadText="Upload Attachments"
        clsName="add-additional-doc"
        url={`${FINANCE_HOSTNAME}${
          FINANCE_REST_URLS.UPLOAD_DOCUMENT
        }${getUploadAttachmentQueryParams(
          addAttachments,
          uploadAttachmentTypes.lineItemApproval
        )}`}
        callBack={(response) => {
          const { data } = response;
          setAttachments([...attachments, data]);
        }}
        formDataKey="file"
      />
      {attachments.map((attachment, index) => {
        return (
          <Chip
            label={attachment.originalName}
            onDelete={() => onDelete(index)}
            onClick={() => window.open(attachment?.docUrl, '_blank')}
          />
        );
      })}
      <Button
        fullWidth
        onClick={() => {
          setConfig({
            disableSave: true,
          });

          onSave(
            comment,
            attachments.map((a) => a.docUrl)
          );

          setTimeout(() => {
            setConfig({
              disableSave: false,
            });
          }, 5000);
        }}
        variant="contained"
        disabled={!comment || config.disableSave}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default CommentAttachments;
