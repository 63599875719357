import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import SiFileUpload from "../../core/file-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { storeTypes } from "../../utils/constants";

const Doc = ({
  fileName,
  deleteFile,
  setFileName,
  setfiveyearplan,
  setnewStoreDetailsPPT,
  documentData,
  setnewStoreDetailsExcel,
  setApprovalEmail,
}) => {
  return (
    <div>
      <Grid container md={12} className="details">
        <div className="input-con">
          <p className="label">Unit Economics Model</p>

          {fileName.fiveYearPlan !== "" && (
            <div className="file-name-con">
              {fileName.fiveYearPlan}
              <DeleteIcon
                className="del"
                onClick={() => deleteFile("Five Year Plan")}
              />
            </div>
          )}

          <SiFileUpload
            clsName="relative"
            inputclassName="absoluteinput"
            warningclass="cf-approval-warning"
            documentName="Five Year Plan"
            url={REST_URLS.UPLOAD_FILES}
            fileFormats={[
              "text/csv",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            showFileName={setFileName}
            setDataFunction={setfiveyearplan}
          />
        </div>
        <div className="input-con">
          <p className="label">
            {documentData?.storeType?.value === storeTypes.cocoAcquired
              ? "ODD"
              : "Site approval pack"}{" "}
          </p>

          {fileName.newStoreDetailsPPT !== "" && (
            <div className="file-name-con">
              {fileName.newStoreDetailsPPT}
              <DeleteIcon
                className="del"
                onClick={() => deleteFile("New Store Details")}
              />
            </div>
          )}
          <SiFileUpload
            clsName="relative"
            inputclassName="absoluteinput"
            warningclass="cf-approval-warning"
            url={REST_URLS.UPLOAD_FILES}
            fileFormats={["application/pdf"]}
            showFileName={setFileName}
            accept="application/pdf"
            setDataFunction={setnewStoreDetailsPPT}
            documentName="New Store Details"
          />
        </div>
        {documentData?.storeType?.value === storeTypes.cocoAcquired && (
          <div className="input-con">
            <p className="label">Approval Email: EM &gt; 5x</p>
            {fileName.approvalEmail !== "" && (
              <div className="file-name-con">
                {fileName.approvalEmail}
                <DeleteIcon
                  className="del"
                  onClick={() => deleteFile("Approval Email")}
                />
              </div>
            )}
            <SiFileUpload
              clsName="relative"
              inputclassName="absoluteinput"
              warningclass="cf-approval-warning"
              url={REST_URLS.UPLOAD_FILES}
              fileFormats={["application/pdf"]}
              accept="application/pdf"
              showFileName={setFileName}
              setDataFunction={setApprovalEmail}
              documentName="Approval Email"
            />
          </div>
        )}
        {documentData?.storeType?.value !== storeTypes.cocoAcquired && (
          <div className="input-con">
            <p className="label">New Store Details Excel</p>
            {fileName.newStoreDetailsExcel !== "" && (
              <div className="file-name-con">
                {fileName.newStoreDetailsExcel}
                <DeleteIcon
                  className="del"
                  onClick={() => deleteFile("New Store Details Excel")}
                />
              </div>
            )}
            <SiFileUpload
              clsName="relative"
              inputclassName="absoluteinput"
              warningclass="cf-approval-warning"
              url={REST_URLS.UPLOAD_FILES}
              fileFormats={[
                "text/csv",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
              showFileName={setFileName}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              setDataFunction={setnewStoreDetailsExcel}
              documentName="New Store Details Excel"
            />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Doc;
