import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
export const getHeaderConfig = (userRoles = []) => {
  const headerConfig = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "User Id",
      id: "userId",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Role",
      id: "RoleName",
      render: (data) => {
        return (
          <div key={Math.random()} className="si-cell">
            {data?.role?.roleName}
          </div>
        );
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onClick(data);
              }}
              sx={{ pt: 0.5, pb: 0.5 }}
            >
              <EditIcon id="edit" color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
  return headerConfig;
};

export const getManageDistributionCenterHeaderConfig = (userRoles = []) => {
  const headerConfig = [
    {
      label: "DC Name",
      id: "name",
    },
    {
      label: "DC Code",
      id: "dcCode",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Phone",
      id: "phone",
    },
    {
      label: "Address",
      id: "address",
    },
    {
      label: "City",
      id: "city",
    },
    {
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onClick(data);
              }}
              sx={{ pt: 0.5, pb: 0.5 }}
            >
              <EditIcon id="edit" color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
  return headerConfig;
};

export const getManageSupplierPlantHeaderConfig = () => {
  const headerConfig = [
    {
      label: "Manufacturer Name",
      id: "name",
    },
    {
      label: "Manufacturer Number",
      id: "manufacturerNumber",
    },
    {
      label: "Plant Location",
      id: "plantLocation",
    },
    {
      label: "SPL Code",
      id: "splCode",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Phone",
      id: "phone",
    },
    {
      label: "Address",
      id: "address",
    },
    {
      label: "City",
      id: "city",
    },
    {
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <>
            <IconButton
              onClick={() => {
                onClick(data);
              }}
              sx={{ pt: 0.5, pb: 0.5 }}
            >
              <EditIcon id="edit" color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return headerConfig;
};
