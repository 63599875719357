import { useEffect, useState } from "react";
import "./index.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  createUpdateDepartment,
  getAllDepartmentRoleList,
  getAllUsersList,
} from "./apis";
import Grid from "@mui/material/Grid";
import { TextField, Stack, Button } from "@mui/material";
import { disableSaveButton } from "./utils";
import AutoCompleteMultiSelect from "../../packages/common-utilities/core/autocomplete-multiselect";
import {
  clearProperty,
  setProperty,
} from "../../packages/common-utilities/utils/misc";
import Autocomplete from "../../packages/common-utilities/core/styled-autocomplete";
import SaveIcon from "@mui/icons-material/Save";

const CreateUpdateDepartment = ({
  showModal,
  mainTitle,
  closeModal,
  onSuccess,
  data,
}) => {
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [departmentRoleList, setDepartmentRoleList] = useState([]);

  useEffect(() => {
    if (!showModal) {
      setFormData({});
      return;
    }
    getAllUsersList(setUserList);
    getAllDepartmentRoleList(setDepartmentRoleList, {
      excludeDefaultRoles: true,
    });
  }, [showModal]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    setFormData((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }));
  };

  return (
    <>
      {showModal && (
        <div className="create-update-dept-con">
          <div className="cancel-div">
            <h1>{mainTitle}</h1>
            <CancelIcon
              color="error"
              onClick={() => closeModal()}
              sx={{ cursor: "pointer" }}
              fontSize="large"
            />
          </div>

          <div>
            <Grid
              container
              md={12}
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "space-between",
                [`@media (max-width: 768px)`]: {
                  gridGap: "5px",
                },
              }}
            >
              <Grid item md={5.5}>
                <TextField
                  label="Name"
                  size="small"
                  fullWidth
                  value={formData.name}
                  name="name"
                  className="new-textfield"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={5.5}>
                <Autocomplete
                  onClick={() => {
                    clearProperty("head", setFormData);
                  }}
                  label="Select Head Of The Department"
                  onChange={(e, val) => {
                    if (val) {
                      setProperty("head", val, setFormData);
                    } else {
                      clearProperty("head", setFormData);
                    }
                  }}
                  options={userList}
                  value={formData?.head || null}
                />
              </Grid>
            </Grid>

            <Grid
              container
              md={12}
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "space-between",
                [`@media (max-width: 768px)`]: {
                  gridGap: "5px",
                },
              }}
            >
              <Grid item md={5.5}>
                <AutoCompleteMultiSelect
                  options={departmentRoleList}
                  onChange={(e, value) => {
                    setProperty("roles", value, setFormData);
                  }}
                  value={formData.roles || []}
                  placeholder="Select Department Roles"
                />
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <div className="btn-container">
                <div>
                  <Button
                    className="modal-button"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    fullWidth
                    disabled={disableSaveButton(formData)}
                    onClick={() => {
                      createUpdateDepartment(formData, onSuccess);
                    }}
                  >
                    Save
                  </Button>
                </div>

                <div>
                  <Button
                    className="modal-button"
                    variant="outlined"
                    fullWidth
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateUpdateDepartment;
