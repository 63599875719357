import React, { useState, useEffect, useRef, useContext } from "react";
import TextField from "@mui/material/TextField";
import { IconButton, Divider } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Select from "react-select";
import { Grid } from "@mui/material";
import { checkValidStoreCombination } from "../../utils";

import { invokeApi } from "../../utils/http-service";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import moment from "moment";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
const StoreWorkersCompInsuranceDetails = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [data, setData] = useState({
    // storeNo: "",
    // sequenceNo: null,
    // satelliteNo: null,
    storeStatus: "",
    companyName: "",
    broker: "",
    effectiveDate: "",
    policyNumber: "",
    totalLimit: "",
    renewalDate: "",
    cancelledDate: "",
    comments: "",
    verification: "",
    lastUpdatedBy: "",
  });
  const [id, setId] = useState("");
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [storeInput, setStoreInput] = useState("");
  const [isCreate, setIsCreate] = useState(false);
  const previousController = useRef();

  useEffect(() => {
    const checkValidation = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (checkValidation) {
      listingLeaseHandler(storeInfo.storeNo);
    }
  }, []);

  const updateInsuranceHandler = () => {
    const payload = { ...storeInfo };
    for (let ele in data) {
      if (data[ele]) {
        payload[ele] = data[ele];
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.GET_WORKER_INSURANCE}${id}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          setId("");
          setData({
            storeStatus: "",
            companyName: "",
            broker: "",
            effectiveDate: "",
            policyNumber: "",
            totalLimit: "",
            renewalDate: "",
            cancelledDate: "",
            comments: "",
            verification: "",
            lastUpdatedBy: "",
          });
          setStoreOptions([]);
        } else {
          toast.success("Saved Successfully", { autoClose: 2000 });
          setId("");
          setData({
            storeStatus: "",
            companyName: "",
            broker: "",
            effectiveDate: "",
            policyNumber: "",
            totalLimit: "",
            renewalDate: "",
            cancelledDate: "",
            comments: "",
            verification: "",
            lastUpdatedBy: "",
          });
          setStoreOptions([]);
        }
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    } else {
      return "";
    }
  };
  const loadDataById = (id) => {
    if (id) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_WORKER_INSURANCE}${id}`,
        null,
        { satelliteNo: storeInfo.satelliteNo, sequenceNo: storeInfo.sequenceNo }
      )
        .then((res) => {
          if (res.message) {
            if (res.message === "Store Insurance not found") {
              toast.info("Store Insurance not available for given store", {
                autoClose: 2000,
              });
            } else {
              toast.error(res.message, { autoClose: 2000 });
            }
            // toast.error(res.message, { autoClose: 2000 });
            // setData({
            //   storeNo: "",
            //   sequenceNo: null,
            //   satelliteNo: null,
            //   storeStatus: "",
            //   companyName: "",
            //   broker: "",
            //   effectiveDate: "",
            //   policyNumber: "",
            //   totalLimit: "",
            //   renewalDate: "",
            //   cancelledDate: "",
            //   comments: "",
            //   verification: "",
            //   lastUpdatedBy: "",
            // });
          } else {
            setRespData(res);
            setId(res?.storeWorkerInsurances?.id);
            setData((prevVal) => ({
              ...prevVal,
              // sequenceNo: res?.store?.sequenceNo,
              // satelliteNo: res?.store?.satelliteNo,
              storeStatus: res?.store?.status,
              companyName: res?.storeWorkerInsurances?.companyName,
              totalLimit: res?.storeWorkerInsurances?.totalLimit,
              broker: res?.storeWorkerInsurances?.broker,
              effectiveDate: dateFun(res?.storeWorkerInsurances?.effectiveDate),
              policyNumber: res?.storeWorkerInsurances?.policyNumber,
              totalLiability: res?.storeWorkerInsurances?.totalLiability,
              renewalDate: dateFun(res?.storeWorkerInsurances?.renewalDate),
              cancelledDate: dateFun(res?.storeWorkerInsurances?.cancelledDate),
              comments: res?.storeWorkerInsurances?.comments,
              verification: res?.storeWorkerInsurances?.verification,
              lastUpdatedBy: res?.storeWorkerInsurances?.lastUpdatedBy,
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const createNewHandler = () => {
    setIsCreate(true);
    setId("");
    setData({
      storeStatus: "",
      companyName: "",
      broker: "",
      effectiveDate: "",
      policyNumber: "",
      totalLimit: "",
      renewalDate: "",
      cancelledDate: "",
      comments: "",
      verification: "",
      lastUpdatedBy: "",
    });
    setStoreOptions([]);
  };
  const createInsuranceHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele]) {
        payload[ele] = data[ele];
      }
    }
    payload["satelliteNo"] = storeInfo.satelliteNo;
    payload["sequenceNo"] = storeInfo.sequenceNo;
    payload["storeNo"] = storeInfo.storeNo;
    if (data.renewalDate) {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_WORKER_INSURANCE}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Created Successfully", { autoClose: 2000 });
            setId("");
            setData({
              storeStatus: "",
              companyName: "",
              broker: "",
              effectiveDate: "",
              policyNumber: "",
              totalLimit: "",
              renewalDate: "",
              cancelledDate: "",
              comments: "",
              verification: "",
              lastUpdatedBy: "",
            });
            setStoreOptions([]);
            setIsCreate(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Renewal Date is required", { autoClose: 2000 });
    }
  };
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo };
      });
      setStoreOptions(updatedOptions);
      setStoreInfo((prevVal) => ({ ...prevVal, storeNo: searchTerm }));
    });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      setStoreInfo({ storeNo: value, satelliteNo: "", sequenceNo: "" });
    } else {
      setStoreOptions([]);
      setId("");
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
      setData({
        storeStatus: "",
        companyName: "",
        broker: "",
        effectiveDate: "",
        policyNumber: "",
        totalLimit: "",
        renewalDate: "",
        cancelledDate: "",
        comments: "",
        verification: "",
        lastUpdatedBy: "",
      });
      setStoreOptions([]);
    }
    // setStoreInput(value ? value : "");
  };

  // const listingLeaseHandler = (id) => {
  //   invokeApi(
  //     HTTP_METHODS.GET,
  //     `${HOSTNAME}${REST_URLS.GET_STORE_SEQUENCE}${id}`
  //   )
  //     .then((res) => {
  //       if (res.message) {
  //         toast.error(res.message, { autoClose: 2000 });
  //       } else {
  //         const satList = res.reduce((prev, current) => {
  //           const isIncludes = prev.find(
  //             (ele) => ele.value === current.satelliteNo
  //           );
  //           if (!isIncludes) {
  //             return [
  //               ...prev,
  //               {
  //                 label: current.satelliteNo,
  //                 value: current.satelliteNo,
  //               },
  //             ];
  //           }
  //           return prev;
  //         }, []);
  //         setSatteliteList(satList);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   if (data.storeNo && data.satelliteNo !== undefined) {
  //     if (!isCreate) {
  //       loadDataById(data.storeNo);
  //     }
  //   }
  // }, [data.satelliteNo]);
  useEffect(() => {
    if (
      Number.isInteger(parseInt(storeInfo.storeNo)) &&
      Number.isInteger(parseInt(storeInfo.satelliteNo)) &&
      Number.isInteger(parseInt(storeInfo.sequenceNo))
    ) {
      if (!isCreate) {
        loadDataById(storeInfo.storeNo);
      }
    }
  }, [storeInfo.satelliteNo, storeInfo.sequenceNo]);
  // useEffect(() => {
  //   if (
  //     checkValidStoreCombination(
  //       data.storeNo,
  //       data.satelliteNo,
  //       data.sequenceNo
  //     )
  //   ) {
  //     if (!isCreate) {
  //       loadDataById(data.storeNo);
  //     }
  //   }
  // }, [data.satelliteNo, data.sequenceNo, data.storeNo]);
  const listingLeaseHandler = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );
          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="storeInsuranceDetails">
      <div className="header">Store Selection</div>
      <div>
        <Grid style={displayContainer} container md={12}>
          <Grid item md={2}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              className="subs-autocomplete"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setStoreOptions([]);
                    setId("");
                    setStoreInfo({
                      storeNo: "",
                      sequenceNo: "",
                      satelliteNo: "",
                    });
                    setData({
                      // storeNo: "",
                      // sequenceNo: null,
                      // satelliteNo: null,
                      storeStatus: "",
                      companyName: "",
                      broker: "",
                      effectiveDate: "",
                      policyNumber: "",
                      totalLimit: "",
                      renewalDate: "",
                      cancelledDate: "",
                      comments: "",
                      verification: "",
                      lastUpdatedBy: "",
                    });
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              // value={
              //   storeOptions.length > 0
              //     ? leaseDetails.store
              //       ? storeOptions.find((i) => i.id == leaseDetails.store)
              //       : null
              //     : null
              // }
              sx={{ width: "12em" }}
              inputValue={storeInfo.storeNo + "" || ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  listingLeaseHandler(val.id);
                  setStoreInfo((prevVal) => ({ ...prevVal, storeNo: val.id }));
                  // setData((prevVal) => ({ ...prevVal, storeNo: val.id }));
                }
              }}
              // onClose={() => {
              //   if (leaseDetails.store) {
              //   } else {
              //     setStoreInput("");
              //   }
              // }}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Satellite"
              // className="sel-item"
              options={satteliteList}
              value={
                ![undefined, null, ""].includes(storeInfo.satelliteNo)
                  ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  satelliteNo: val.value,
                }));
                // setData((prevVal) => ({ ...prevVal, satelliteNo: val.value }));
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              classNamePrefix="custom_select"
              placeholder="Sequence"
              options={sequenceList}
              value={
                ![undefined, null, ""].includes(storeInfo.sequenceNo)
                  ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                  : ""
              }
              onChange={(val) => {
                setStoreInfo((prevVal) => ({
                  ...prevVal,
                  sequenceNo: val.value,
                }));
                // setData((prevVal) => ({ ...prevVal, sequenceNo: val.value }));
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              className="subs-text-field"
              label="Store status"
              value={data.storeStatus || ""}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item md={3}>
            {!isCreate && (
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={createNewHandler}
                >
                  Create New
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    excelDownload(
                      "store_workers_comp_insurance_details_" +
                        storeInfo.storeNo,
                      respData
                    );
                  }}
                  disabled={respData === null}
                  startIcon={
                    <DownloadRoundedIcon
                      style={{ fontSize: "20px", marginRight: "-5px" }}
                    />
                  }
                >
                  Download
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <Divider style={{ paddingBottom: 10 }} />

      <Grid xs={4}>
        <div className="title">Insurance Coverage Details</div>

        <Grid container spacing={1}>
          <Grid item md={12} sx={{ display: "flex", mb: 2 }}>
            <Grid xs={4}>
              <TextField
                className="w-80 subs-text-field"
                label="Company Name"
                value={data.companyName || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    companyName: e.target.value,
                  }));
                }}
              />
            </Grid>

            <Grid xs={4}>
              <TextField
                label="Broker"
                className="w-80 subs-text-field"
                value={data.broker || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    broker: e.target.value,
                  }));
                }}
              />
            </Grid>

            <Grid xs={4}>
              <TextField
                label="Effective Date"
                type="date"
                className="w-80 subs-text-field"
                InputLabelProps={{ shrink: true }}
                value={dateFun(data.effectiveDate) || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    effectiveDate: e.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", mb: 2 }}>
            <Grid xs={4}>
              <TextField
                label="Policy Number"
                className="w-80 subs-text-field"
                value={data.policyNumber || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    policyNumber: e.target.value,
                  }));
                }}
              />
            </Grid>

            <Grid xs={4}>
              <TextField
                label="Total Limit"
                className="w-80 subs-text-field"
                type="number"
                value={data.totalLimit || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    totalLimit: e.target.value,
                  }));
                }}
              />
            </Grid>

            <Grid xs={4}>
              <TextField
                label="Renewal Date"
                type="date"
                className="w-80 subs-text-field"
                InputLabelProps={{ shrink: true }}
                value={dateFun(data.renewalDate) || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    renewalDate: e.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", mb: 2 }}>
            <Grid xs={4}>
              <TextField
                label="Cancelled Date"
                type="date"
                className="w-80 subs-text-field"
                InputLabelProps={{ shrink: true }}
                value={dateFun(data.cancelledDate) || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    cancelledDate: e.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ paddingBottom: 10 }} />

        <div className="title">Comments</div>

        <Grid xs={12} container spacing={1}>
          <Grid xs={12}>
            <TextareaAutosize
              className="commentText"
              aria-label="minimum height"
              minRows={3}
              placeholder="Policy Number updated with the certificate number"
              value={data.comments || ""}
              onChange={(e) => {
                setData((prevVal) => ({
                  ...prevVal,
                  comments: e.target.value,
                }));
              }}
            />
          </Grid>
        </Grid>

        <Divider style={{ paddingBottom: 10 }} />
        <div className="title">Modification</div>

        <Grid xs={12} container spacing={1}>
          <Grid item md={12} sx={{ display: "flex", mb: 2 }}>
            <Grid xs={4}>
              <TextField
                label="Verification"
                className="w-80 subs-text-field"
                value={data.verification || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    verification: e.target.value,
                  }));
                }}
              />
            </Grid>

            <Grid xs={4}>
              <TextField
                label="Last Updated By"
                className="w-80 subs-text-field"
                value={data.lastUpdatedBy || ""}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    lastUpdatedBy: e.target.value,
                  }));
                }}
              />
            </Grid>

            {!isCreate && (
              <Grid xs={3.7}>
                <Button
                  size="small"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={
                    !checkValidStoreCombination(
                      storeInfo.storeNo,
                      storeInfo.satelliteNo,
                      storeInfo.sequenceNo
                    ) || !id
                  }
                  sx={{ float: "right" }}
                  onClick={updateInsuranceHandler}
                >
                  Save
                </Button>
              </Grid>
            )}
            {isCreate && (
              <Grid xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setIsCreate(false);
                    setData({
                      storeStatus: "",
                      companyName: "",
                      broker: "",
                      effectiveDate: "",
                      policyNumber: "",
                      totalLimit: "",
                      renewalDate: "",
                      cancelledDate: "",
                      comments: "",
                      verification: "",
                      lastUpdatedBy: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={
                    !checkValidStoreCombination(
                      storeInfo.storeNo,
                      storeInfo.satelliteNo,
                      storeInfo.sequenceNo
                    )
                  }
                  onClick={createInsuranceHandler}
                  sx={{ ml: 5 }}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default StoreWorkersCompInsuranceDetails;
