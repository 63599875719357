import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export const competitorInformationConfig = () => {
  const config = [
    { label: "Brand", id: "brand", key: "brand" },
    // { label: "Region", id: "region" },
    { label: "Format", id: "format", key: "format" },
    { label: "Store Name/Location", id: "location", key: "location" },
    { label: "Distance(in kms)", id: "distance", key: "distance" },
    { label: "ADS", id: "ads", key: "ads" },
    { label: "Del %", id: "del", key: "del" },
    {
      label: "Actions",
      id: "Actions",
      render: (data, onclick) => {
        return (
          <div id={Math.random()}>
            <IconButton
              onClick={() => {
                onclick(data, "edit");
              }}
              sx={{ p: 0 }}
            >
              <EditIcon id="edit" />
            </IconButton>

            {Number.isInteger(parseInt(data.dataIndex)) && (
              <IconButton
                onClick={() => {
                  onclick(data, "delete");
                }}
                sx={{ p: 0 }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  return config;
};
