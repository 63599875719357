import { toast } from 'react-toastify';
import { FINANCE_HOSTNAME } from '../../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../../utils/http-service';

export const getCategoryList = (setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CATEGORY_LIST}`
  )
    .then((res) => {
      const result = res.map((ele) => ({ label: ele.name, value: ele.id }));
      setDataFunc(result);
    })
    .catch((err) => console.log(err));
};

export const createUpdateApprovalMapping = (data, onSuccess) => {
  const payload = { ...data };
  const { id } = payload;

  delete payload.id;
  const url = id
    ? `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPDATE_CF_APPROVAL_MAPPING_BY_ID}${id}`
    : `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CREATE_CF_APPROVAL_MAPPING}`;
  const method = id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const message = id
    ? 'Mapping Updated Successfully'
    : 'Mapping Created Successfully';
  invokeApi(method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      typeof onSuccess === 'function' && onSuccess();
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 2000 });
    });
};

export const deleteMappingById = (id, onSuccess) => {
  invokeApi(
    HTTP_METHODS.DELETE,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.DELETE_CF_APPROVAL_MAPPING_BY_ID}${id}`
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success('Mapping Deleted Successfully', { autoClose: 2000 });
      typeof onSuccess === 'function' && onSuccess();
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 2000 });
    });
};
