import { HTTP_METHODS, invokeApi } from "./http-service";
import { clearOfflineData, setOfflineData } from "./offline-services";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { REST_URLS } from "../../common-utilities/utils/end-points/petty-expense";

export const redirectToIntranetPortal = () => {
  clearOfflineData("user");
  clearOfflineData("tokens");
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const getUserDetailsFromIntranet = (
  Authorization,
  customLogic,
  tokens
) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`,
    null,
    null,
    Authorization
  )
    .then((res) => {
      if (res?.message) {
        redirectToIntranetPortal();
        return;
      }
      if (tokens) {
        setOfflineData("tokens", tokens);
      }
      const role = res?.role;
      setOfflineData("user", { ...res, role });
      if (typeof customLogic === "function") {
        customLogic(res);
      }
    })
    .catch(() => {
      redirectToIntranetPortal();
    });
};
