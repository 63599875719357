import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.scss";
import { Button, Grid, TextField } from "@mui/material";
import Select from "react-select";
import { invokeApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { checkValidStoreCombination } from "../../utils";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";

const LeaseCom = () => {
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [leaseDetails, setLeaseDetails] = useState({ store: null });
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const [leaseIdList, setLeaseIdList] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const previousController = useRef();
  const [respData, setRespData] = useState(null);

  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      daThresholdAmt: [null, undefined].includes(leaseDetails.daThresholdAmt)
        ? 0
        : parseInt(leaseDetails.daThresholdAmt),
      size: [null, undefined].includes(leaseDetails.size)
        ? 0
        : parseInt(leaseDetails.size),
      status: leaseDetails.status,
      type: leaseDetails.type,
      sizeType: leaseDetails.sizeType,
      representatives: {
        leaseRep: leaseDetails.leaseRep,
        renegotiationRep: leaseDetails.renegotiationRep,
        settlementRep: leaseDetails.settlementRep,
        completedBy: leaseDetails.completedBy,
        leaseRenegotiatedBy: leaseDetails.leaseRenegotiatedBy,
      },
      dates: {
        incomplete: leaseDetails.incomplete,
        negotiation: leaseDetails.negotiation,
        franchiseSold: leaseDetails.franchiseSold,
        leaseReceived: leaseDetails.leaseReceived,
        completedSiteEvaluation: leaseDetails.completedSiteEvaluation,
        intentToSubLease: leaseDetails.intentToSubLease,
        specAuthorization: leaseDetails.specAuthorization,
        checkForTwoMonthsRent: leaseDetails.checkForTwoMonthsRent,
        dealCompletion: leaseDetails.dealCompletion,
        renegotiationStart: leaseDetails.renegotiationStart,
        srecTransferDate: leaseDetails.srecTransferDate,
        daCommittedDate: leaseDetails.daCommittedDate,
      },
    };

    if (Number.isInteger(parseInt(leaseInfo.leaseId))) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_LEASE + leaseInfo.leaseId,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully", { autoClose: 2000 });
            clearData(false);
          } else toast.error("Update Failed", { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error("Update Failed", { autoClose: 2000 });
        });
    }
  };

  const createLeaseHandler = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      daThresholdAmt: [null, undefined].includes(leaseDetails.daThresholdAmt)
        ? 0
        : parseInt(leaseDetails.daThresholdAmt),
      size: [null, undefined].includes(leaseDetails.size)
        ? 0
        : parseInt(leaseDetails.size),
      status: leaseDetails.status,
      type: leaseDetails.type,
      sizeType: leaseDetails.sizeType,
      representatives: {
        leaseRep: leaseDetails.leaseRep,
        renegotiationRep: leaseDetails.renegotiationRep,
        settlementRep: leaseDetails.settlementRep,
        completedBy: leaseDetails.completedBy,
        leaseRenegotiatedBy: leaseDetails.leaseRenegotiatedBy,
      },
      dates: {
        incomplete: leaseDetails.incomplete,
        negotiation: leaseDetails.negotiation,
        franchiseSold: leaseDetails.franchiseSold,
        leaseReceived: leaseDetails.leaseReceived,
        completedSiteEvaluation: leaseDetails.completedSiteEvaluation,
        intentToSubLease: leaseDetails.intentToSubLease,
        specAuthorization: leaseDetails.specAuthorization,
        checkForTwoMonthsRent: leaseDetails.checkForTwoMonthsRent,
        dealCompletion: leaseDetails.dealCompletion,
        renegotiationStart: leaseDetails.renegotiationStart,
        srecTransferDate: leaseDetails.srecTransferDate,
        daCommittedDate: leaseDetails.daCommittedDate,
      },
    };
    invokeApi(HTTP_METHODS.POST, HOSTNAME + REST_URLS.POST_LEASE, body)
      .then((res) => {
        if (res.id) {
          toast.success("Created Successfully", { autoClose: 2000 });
          clearData(false);
        } else toast.error("Create Failed", { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error("Create Failed", { autoClose: 2000 });
      });
  };

  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const stateUpdate = (updatedValues) => {
    setLeaseDetails((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  const apply = (id) => {
    if (Number.isInteger(parseInt(id))) {
      invokeApi(
        HTTP_METHODS.GET,
        HOSTNAME + REST_URLS.GET_LEASE + id + "/" + storeInfo.storeNo
      ).then((res) => {
        if (res.code === 404) {
          toast.info(res.message, { autoClose: 2000 });
        } else {
          setRespData(res);
          let obj = {};
          obj["daThresholdAmt"] = res?.storeLease?.daThresholdAmt;
          obj["size"] = res?.storeLease?.size;
          obj["status"] = res?.storeLease?.status;
          obj["type"] = res?.storeLease?.type;
          obj["sizeType"] = res?.storeLease?.sizeType;
          obj["leaseRep"] = res?.storeLease?.representatives?.leaseRep;
          obj["renegotiationRep"] =
            res?.storeLease?.representatives?.renegotiationRep;
          obj["settlementRep"] =
            res?.storeLease?.representatives?.settlementRep;
          obj["completedBy"] = res?.storeLease?.representatives?.completedBy;
          obj["leaseRenegotiatedBy"] =
            res?.storeLease?.representatives?.leaseRenegotiatedBy;
          obj["incomplete"] = dateFun(res?.storeLease?.dates?.incomplete);
          obj["negotiation"] = dateFun(res?.storeLease?.dates?.negotiation);
          obj["franchiseSold"] = dateFun(res?.storeLease?.dates?.franchiseSold);
          obj["leaseReceived"] = dateFun(res?.storeLease?.dates?.leaseReceived);
          obj["completedSiteEvaluation"] = dateFun(
            res?.storeLease?.dates?.completedSiteEvaluation
          );
          obj["intentToSubLease"] = dateFun(
            res?.storeLease?.dates?.intentToSubLease
          );
          obj["specAuthorization"] = dateFun(
            res?.storeLease?.dates?.specAuthorization
          );
          obj["checkForTwoMonthsRent"] = dateFun(
            res?.storeLease?.dates?.checkForTwoMonthsRent
          );
          obj["dealCompletion"] = dateFun(
            res?.storeLease?.dates?.dealCompletion
          );
          obj["renegotiationStart"] = dateFun(
            res?.storeLease?.dates?.renegotiationStart
          );
          obj["srecTransferDate"] = dateFun(
            res?.storeLease?.dates?.srecTransferDate
          );
          obj["daCommittedDate"] = dateFun(
            res?.storeLease?.dates?.daCommittedDate
          );
          obj["postalCode"] = res?.address?.zipCode;
          obj["addType"] = res?.address?.type;
          obj["state"] = res?.address?.state;
          obj["county"] = res?.address?.county;
          obj["city"] = res?.address?.city;
          obj["country"] = res?.address?.country;
          obj["attention"] = res?.address?.attn;
          obj["address1"] = res?.address?.address3;
          obj["street"] = res?.address?.address2;
          obj["locatedIn"] = res?.address?.address1;
          stateUpdate(obj);
        }
      });
    }
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...leaseDetails };
    tempState[key] = value;
    setLeaseDetails(tempState);
  };

  const onInputChange = (event, value, reason) => {
    setLeaseInfo({ leaseId: "" });
    setSatteliteList([]);
    setSequenceList([]);
    setLeaseIdList([]);
    if (value?.trim()) {
      getData(value);
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  const selectHandle = (name, value, fieldsToClear = {}) => {
    const clearedData = fieldsToClear ? fieldsToClear : {};
    let tempState = { ...leaseDetails, ...clearedData };
    tempState[name] = value;
    setLeaseDetails(tempState);
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };

  const listingLeaseHandler = (id, satelliteNo, sequenceNo) => {
    setStoreInfo({
      storeNo: id,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
    });
    const invalidStoreCombination = !checkValidStoreCombination(
      id,
      satelliteNo,
      sequenceNo
    );

    if (invalidStoreCombination) {
      return;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_LEASES}${id}`,
      null,
      {
        satelliteNo,
        sequenceNo,
      }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
          setLeaseIdList(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumberAndSatelliteNumbersForStoreNumber(storeInfo.storeNo);
      listingLeaseHandler(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
      apply(leaseInfo.leaseId);
    }
  }, []);

  const getAllSequenceNumberAndSatelliteNumbersForStoreNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: "",
      });
    setLeaseDetails({});
    setStoreOptions([]);
    setLeaseIdList([]);
    if (isCreate) {
      setIsCreate(false);
    }
  };

  return (
    <div className="lease">
      <Grid container md={12} sx={{ mt: 5, mb: 0 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            className="subs-autocomplete"
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumberAndSatelliteNumbersForStoreNumber(val?.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("satelliteNo", val?.value, { leaseId: null });
              listingLeaseHandler(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo
              );
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("sequenceNo", val?.value, { leaseId: null });
              if (!isCreate) {
                listingLeaseHandler(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="lease Id"
              className="sel-item"
              options={leaseIdList}
              value={
                ![undefined, null, ""].includes(leaseInfo.leaseId)
                  ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                  : ""
              }
              onChange={(val) => {
                setLeaseInfo({ leaseId: val?.value });
                apply(val?.value);
              }}
            />
          )}
        </Grid>
        <Grid item md={2} xs={6} style={{ height: "35px" }}>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2, float: "right" }}
                onClick={() => {
                  clearData(false);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("lease_details_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <div className="title">Representatives</div>
      <Grid container md={12} xs={12}>
        <Grid item md={2}>
          <TextField
            label="Lease Rep"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            value={leaseDetails.leaseRep || ""}
            name="leaseRep"
            onChange={handleChange}
          />
        </Grid>

        <Grid item md={2}>
          <TextField
            label="Renegotiation Rep"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            value={leaseDetails.renegotiationRep || ""}
            name="renegotiationRep"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            label="Settlement Rep"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            value={leaseDetails.settlementRep || ""}
            name="settlementRep"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3}>
          {/* <Select placeholder="Lease Completed By" className="sel-item" classNamePrefix="custom_select" /> */}
          <TextField
            label="Lease Completed By"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            value={leaseDetails.completedBy || ""}
            name="completedBy"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3}>
          {/* <Select placeholder="Lease Renegotiated By" className="sel-item" classNamePrefix="custom_select" /> */}
          <TextField
            label="Lease Renegotiated By"
            fullWidth
            className="sel-item subs-text-field"
            variant="outlined"
            value={leaseDetails.leaseRenegotiatedBy || ""}
            name="leaseRenegotiatedBy"
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item md={6}>
          <div className="title">Location Address</div>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                {/* <Select isSearchable placeholder="Type" className="sel-item" classNamePrefix="custom_select" /> */}
                <TextField
                  id="type"
                  label="Type"
                  className="sel-item subs-text-field"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.addType || ""}
                  name="addType"
                  onChange={handleChange}
                  disabled
                />
                <TextField
                  id="attention"
                  label="Attention"
                  className="sel-item subs-text-field"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.attention || ""}
                  name="attention"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="g-r-2">
                <TextField
                  id="located-in"
                  label="Located In"
                  className="sel-item subs-text-field"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.locatedIn || ""}
                  name="locatedIn"
                  onChange={handleChange}
                  disabled
                />
                <TextField
                  id="street/po-box"
                  className="sel-item subs-text-field"
                  label="Street/PO Box"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.street || ""}
                  name="street"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="g-r-2">
                <TextField
                  id="address1"
                  className="sel-item subs-text-field"
                  label="Address 1"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.address1 || ""}
                  name="address1"
                  onChange={handleChange}
                  disabled
                />
                <TextField
                  id="county"
                  label="county"
                  className="sel-item subs-text-field"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.county || ""}
                  name="county"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="g-r-2">
                <TextField
                  id="city"
                  label="City"
                  className="sel-item subs-text-field"
                  fullWidth
                  variant="outlined"
                  value={leaseDetails.city || ""}
                  name="city"
                  onChange={handleChange}
                  disabled
                />
                <TextField
                  id="postal-code"
                  label="Postal Code"
                  fullWidth
                  className="sel-item subs-text-field"
                  type="number"
                  variant="outlined"
                  value={leaseDetails.postalCode || ""}
                  name="postalCode"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="g-r-2">
                {/* <Select
                  isSearchable
                  classNamePrefix="custom_select"
                  placeholder="State/Prov"
                  className="sel-item"
                />
                <Select
                  isSearchable
                  classNamePrefix="custom_select"s
                  placeholder="Country"
                  className="sel-item"
                /> */}
                <TextField
                  id="state"
                  label="State/Prov"
                  className="sel-item subs-text-field"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={leaseDetails.state || ""}
                  name="state"
                  disabled
                  onChange={handleChange}
                />
                <TextField
                  id="country"
                  label="Country"
                  className="sel-item subs-text-field"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={leaseDetails.country || ""}
                  disabled
                  name="country"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="title">General Details</div>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                <TextField
                  id="attention"
                  className="sel-item subs-text-field"
                  label="Franchise Status"
                  fullWidth
                  variant="outlined"
                  disabled
                />
                <Select
                  classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Development Agent"
                  className="sel-item"
                  isDisabled={true}
                />
              </div>
              <div className="g-r-2">
                {/* <Select
                classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Lease Status"
                  className="sel-item"
                /> */}
                <TextField
                  id="Lease_status"
                  label="Lease Status"
                  fullWidth
                  className="sel-item subs-text-field"
                  variant="outlined"
                  value={leaseDetails.status || ""}
                  name="status"
                  onChange={handleChange}
                />
                {/* <Select
                classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Lease Type"
                  className="sel-item"
                /> */}
                <TextField
                  id="Lease_Type"
                  label="Lease Type"
                  fullWidth
                  className="sel-item subs-text-field"
                  variant="outlined"
                  value={leaseDetails.type || ""}
                  name="type"
                  onChange={handleChange}
                />
              </div>
              <div className="g-r-2">
                <TextField
                  id="size"
                  label="Building Size"
                  fullWidth
                  className="sel-item subs-text-field"
                  variant="outlined"
                  value={leaseDetails.size || ""}
                  name="size"
                  type="number"
                  onChange={handleChange}
                />
                <TextField
                  id="daThresholdAmt"
                  label="DA Threshold Amt"
                  fullWidth
                  className="sel-item subs-text-field"
                  variant="outlined"
                  type="number"
                  value={leaseDetails.daThresholdAmt || ""}
                  name="daThresholdAmt"
                  onChange={handleChange}
                />
              </div>
              <div className="g-r-2">
                {/* <Select
                classNamePrefix="custom_select"
                  isSearchable
                  placeholder="Size Type"
                  className="sel-item"
                /> */}
                <TextField
                  id="sizeType"
                  label="Size Type"
                  fullWidth
                  className="sel-item subs-text-field"
                  variant="outlined"
                  value={leaseDetails.sizeType || ""}
                  name="sizeType"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={6}>
          <div className="title">General Dates</div>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Date Negotiated From
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.negotiation) || ""}
                    name="negotiation"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Lease In-Complete Date
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.incomplete) || ""}
                    name="incomplete"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Franchise Sold
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.franchiseSold) || ""}
                    name="franchiseSold"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Deal Complete
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.dealCompletion) || ""}
                    name="dealCompletion"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Renegotiation Start
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.renegotiationStart) || ""}
                    name="renegotiationStart"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    SREC Transfer Date
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.srecTransferDate) || ""}
                    name="srecTransferDate"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} sx={{ mt: "2em" }}>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Lease Received
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.leaseReceived) || ""}
                    name="leaseReceived"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Completed Site Evauation
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.completedSiteEvaluation) || ""}
                    name="completedSiteEvaluation"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Intent to Sublease
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.intentToSubLease) || ""}
                    name="intentToSubLease"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Spec Authorization
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.specAuthorization) || ""}
                    name="specAuthorization"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Check For 2 Months Rent
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.checkForTwoMonthsRent) || ""}
                    name="checkForTwoMonthsRent"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    DA Commited Date
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.daCommittedDate) || ""}
                    name="daCommittedDate"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item md={2} xs={6} style={{ height: "35px" }}>
        <span
          style={{
            display: "flex",
            padding: "5px",
            borderRadius: "5px",
            float: "right",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => {
              clearData(isCreate ? false : true);
            }}
          >
            {isCreate ? "Cancel" : "Reset"}
          </Button>
          {!isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                !["", null].includes(storeInfo.satelliteNo) &&
                !["", null].includes(storeInfo.sequenceNo) &&
                !["", null].includes(leaseInfo.leaseId)
                  ? false
                  : true
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button>
          )}

          {isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                storeInfo.satelliteNo !== null &&
                storeInfo.sequenceNo !== null
                  ? false
                  : true
              }
              onClick={() => {
                createLeaseHandler();
              }}
            >
              Save
            </Button>
          )}
        </span>
      </Grid>
    </div>
  );
};

export default LeaseCom;
