import React, { useState, useEffect } from "react";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";
import { Button, Grid, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CreateApp from "./create-app";
import CustomModal from "../../packages/common-utilities/core/modal";
import AppCard from "../../core/cards";
import AutoCompleteMultiSelect from "../../packages/common-utilities/core/autocomplete-multiselect";

const Apps = () => {
  const [tableData, setTableData] = useState([]);
  const [isCreateApp, setIsCreateApp] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleValue, setRoleValue] = useState([]);
  const [editPayload, setEditPayload] = useState({});

  const loadData = () => {
    invokeApi(HTTP_METHODS.GET, `${INTRANETHOSTNAME}${REST_URLS.GET_APPS}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRoles = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_ROLES_AND_APPS}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const results = res.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }));
          setRoleList(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRoleChange = (event) => {
    const roleIdList = event.map((role) => role.value);
    setEditPayload((prevVal) => ({ ...prevVal, roles: roleIdList }));
    setRoleValue(event);
  };
  useEffect(() => {
    if (Object.keys(editInfo).length > 0) {
      const roleLabelAndValue = editInfo?.roles.map((roleId) => {
        const role = roleList?.find((ele) => ele?.value === roleId);
        return role;
      });
      setRoleValue(roleLabelAndValue);
      setIsEdit(true);
    }
  }, [editInfo]);
  useEffect(() => {
    getRoles();
    loadData();
  }, []);
  const editHandler = () => {
    if (editInfo?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${INTRANETHOSTNAME}${REST_URLS.UPDATE_APP}${editInfo?.id}`,
        editPayload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          }
          loadData();
          setIsEdit(false);
          setEditInfo({});
          setEditPayload({});
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="roles-con all-apps">
      {!isCreateApp && (
        <div>
          <div className="btn-con">
            <Button
              className="common-rnd-btn"
              variant="contained"
              onClick={() => {
                setIsCreateApp(true);
              }}
            >
              Create App
            </Button>
          </div>
          <Grid md={12} container sx={{ mt: 2 }}>
            <div className="apps-con">
              {tableData?.map((app) => {
                return (
                  <AppCard
                    appDetails={app}
                    notOpenApp={true}
                    editInfo={setEditInfo}
                  />
                );
              })}
            </div>
          </Grid>
        </div>
      )}

      {isCreateApp && (
        <CreateApp closeModal={setIsCreateApp} loadData={loadData} />
      )}
      {isEdit && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option Mui-focused"]}
          title="Edit App"
          onClose={() => {
            setIsEdit(false);
            setEditInfo({});
            setEditPayload({});
          }}
        >
          <div className="edit-app-modal ">
            <div>
              <TextField
                label="App Name"
                name="name"
                required
                fullWidth
                className="new-textfield"
                size="small"
                sx={{ mb: 2 }}
                value={editInfo?.name}
                variant="outlined"
                onChange={(e) => {
                  setEditPayload((prevVal) => ({
                    ...prevVal,
                    name: e.target.value,
                  }));
                  setEditInfo((prevVal) => ({
                    ...prevVal,
                    name: e.target.value,
                  }));
                }}
              />
              <AutoCompleteMultiSelect
                value={roleValue}
                onChange={(e, val) => {
                  handleRoleChange(val);
                }}
                options={roleList}
                placeholder="Select Roles"
              />
            </div>
            <div className="common-btn-ctr">
              <div>
                <Button
                  startIcon={<SaveIcon />}
                  onClick={editHandler}
                  fullWidth
                  className="common-modal-crt-btn"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Apps;
