import { useEffect, useState } from "react";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getHeaderConfig } from "./config";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";

const Tags = () => {
  const [tags, setTags] = useState({});
  const [addTag, setAddTag] = useState(false);
  const [tagName, setTagName] = useState("");
  const [formData, setFormData] = useState({});
  const [editObj, setEditObj] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: "",
  });
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const loadData = (filter) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.TAGS}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTags(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const editFunc = (data, type) => {
    if (type === "EDIT") {
      setEditObj(data);
      setFormData({ name: data?.name });
      setAddTag(true);
    } else if (type === "DELETE") {
      setDeleteModal({ isOpen: true, id: data.id });
    }
  };
  const submitHandler = () => {
    if (editObj?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.TAGS}/${editObj.id}`,
        formData
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Updated Successfully", { autoClose: 2000 });
            setAddTag(false);
            setFormData({});
            setEditObj({});
            loadData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.TAGS}`, formData)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Created Successfully", { autoClose: 2000 });
            setAddTag(false);
            setFormData({});
            loadData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const DeleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.TAGS}/${deleteModal.id}`
    ).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        toast.info("Tag Deleted Successfully", { autoClose: 2000 });
        setDeleteModal({ isOpen: false, id: "" });
        loadData({ ...filters, page: 1 });
      }
    });
  };
  const addTagHandler = () => {
    setAddTag(true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  return (
    <div className="tag-con ">
      <div className="button-div">
        <div className="search-field">
          <TextField
            fullWidth
            sx={{ my: 1 }}
            label="Search by Tag name"
            onChange={(e) => setTagName(e?.target?.value)}
            value={tagName}
            size="small"
            className="new-textfield"
          />

          <SearchIcon
            className="main-search-icon"
            onClick={() => {
              loadData({ ...filters, page: 1, name: tagName });
            }}
          />
        </div>

        <Button variant="contained" className="button" onClick={addTagHandler}>
          Create Tag
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig}
        dataList={tags.results || []}
        filters={filters}
        pageCount={tags.totalPages}
        onClick={editFunc}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {addTag && (
        <CustomModal
          onClose={() => {
            setAddTag(false);
            setFormData({});
            if (editObj.id) {
              setEditObj({});
            }
          }}
          title={editObj?.id ? "Update Tag" : "New Tag"}
        >
          <TextField
            fullWidth
            label="Name"
            onChange={(e) => setFormData({ name: e.target.value })}
            value={formData?.name || ""}
            size="small"
          />
          <Button
            size="small"
            variant="contained"
            disabled={!formData.name}
            sx={{ mt: 3 }}
            fullWidth
            onClick={submitHandler}
          >
            Save
          </Button>
        </CustomModal>
      )}
      {deleteModal.isOpen && (
        <CustomModal
          title="Delete Tag"
          onClose={() => setDeleteModal({ isOpen: false, id: "" })}
        >
          <h4>Are You Sure?</h4>
          <Button
            size="small"
            variant="contained"
            sx={{ mt: 3, ml: 35 }}
            color="error"
            onClick={DeleteHandler}
          >
            Delete
          </Button>
        </CustomModal>
      )}
    </div>
  );
};

export default Tags;
