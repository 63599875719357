import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditModal from "./edit-modal";

const DailyReadingsTable = ({
  data,
  rowData,
  loadData,
  setReadingModal,
  role,
}) => {
  const [editReading, setEditReading] = useState({
    isOpen: false,
    data: {},
  });
  const [lastWorkingDay, setLastWorkingDay] = useState("");
  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;

  const findSecondLastWorkingDay = (document) => {
    const workingDays = Object.keys(document.dailyReadings);
    const sortedDays = workingDays.map(Number).sort((a, b) => a - b);

    if (sortedDays.length < 2) {
      return sortedDays[0] || null;
    }
    if (sortedDays.includes(currentDate)) {
      return sortedDays[sortedDays.length - 2];
    } else {
      return sortedDays[sortedDays.length - 1];
    }
  };

  const getLastWorkingDay = () => {
    const lastDay = findSecondLastWorkingDay(rowData);
    setLastWorkingDay(lastDay);
  };
  useEffect(() => {
    if (role === "STORE") {
      getLastWorkingDay();
    }
  }, []);

  return (
    <div>
      {editReading?.isOpen ? (
        <EditModal
          editReading={editReading}
          setEditReading={setEditReading}
          loadData={loadData}
          setReadingModal={setReadingModal}
        />
      ) : (
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table size="small">
            <TableHead sx={{ background: "#f3f0f0" }}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Previous Night Units</TableCell>
                <TableCell>Start Reading</TableCell>
                <TableCell>End Reading</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Total Unit Consumption</TableCell>
                <TableCell>Consumption Amount(INR)</TableCell>

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data).map((date) => {
                const reading = data[date];
                return (
                  <TableRow key={date}>
                    <TableCell>
                      {date} /{rowData.month} /{rowData.year}
                    </TableCell>
                    <TableCell>
                      {reading?.previousNightUnits ?? "N/A"}
                    </TableCell>
                    <TableCell>{reading?.startReading ?? "N/A"}</TableCell>
                    <TableCell>{reading?.endReading ?? "N/A"}</TableCell>
                    <TableCell>{reading?.difference ?? "N/A"}</TableCell>

                    <TableCell>
                      {reading?.difference + reading?.previousNightUnits ??
                        "N/A"}
                    </TableCell>
                    <TableCell>{reading?.consumptionAmount ?? "N/A"}</TableCell>
                    <TableCell>
                      {reading?.startReading && reading?.endReading && (
                        <IconButton
                          sx={{ padding: "0px" }}
                          color="info"
                          onClick={() =>
                            setEditReading({
                              isOpen: true,
                              data: {
                                ...reading,
                                date: `${rowData.month}/${date}/${rowData.year}`,
                                storeId: rowData?.storeId?.id,
                              },
                            })
                          }
                          disabled={
                            role === "STORE" &&
                            (currentMonth !== rowData.month ||
                              (parseInt(date) !== currentDate &&
                                parseInt(date) !== lastWorkingDay))
                          }
                        >
                          <EditIcon sx={{ cursor: "pointer" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DailyReadingsTable;
