import React, { useEffect, useState } from 'react';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import './index.scss';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Button, TextField } from '@mui/material';
import DateRangeFilter from '../../../../common-utilities/core/date-range-filter';
import { PO_TYPES } from '../helper';
import PoFilters from '../po-filters';
import { downloadReports } from './utils';
import { PO_STATUS } from '../../reports/helper';
import {
  getStartOfDay,
  getEndOfDay,
} from '../../../../common-utilities/utils/time';
const POList = ({ status }) => {
  const navigate = useNavigate();

  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [poType, setPoType] = useState(null);
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: '-createdAt',
  });

  useEffect(() => {
    if (status) {
      loadData(filters);
    }
  }, [status]);

  const loadData = (apiFilters, download) => {
    let params = {
      ...apiFilters,
    };
    if (status !== 'all') {
      params.status = status;
    }
    if (searchKey) {
      params.searchKey = searchKey;
    }

    if (poType) {
      params.poType = poType.value;
    }
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }

    if (download) {
      params.limit = budgetList.totalResults;
      params.page = 1;
    }
    params.populateSite = true;

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      null,
      params
    )
      .then((response) => {
        if (download) {
          downloadReports(response?.results || [], status);
          return;
        }
        setBudgetList(response);
      })
      .catch((err) => {});
  };

  const viewBudget = (data, type) => {
    const { approvalId = {} } = data;
    if (type === 'GRN') {
      navigate(`grn-order/create/${data.id}`);
    } else if (type === 'EDIT') {
      navigate(`purchase-order/edit/${data.id}`);
    } else if (approvalId && approvalId.id) {
      navigate(`purchase-order/${data.id}?approvalId=${approvalId.id}`);
    } else {
      navigate(`purchase-order/${data.id}`);
    }
  };

  return (
    <div className="po-list">
      <div className="filter-row">
        <TextField
          placeholder="Search by Po Number"
          value={searchKey}
          size="small"
          onChange={(e) => setSearchKey(e.target.value)}
        />

        <PoFilters
          filters={filters}
          setFilters={setFilters}
          showStatus={true}
          statusList={PO_STATUS}
          disableStatus={status !== 'all'}
        />
        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />
        <Autocomplete
          value={poType || ''}
          onChange={(e, val) => {
            if (val) {
              setPoType(val);
            } else {
              setPoType(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="PO Type"
              sx={{
                minWidth: 220,
              }}
            />
          )}
          options={PO_TYPES}
        />
      </div>
      <div className="end-btns">
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({
              ...filters,
              page: 1,
            });
            loadData({
              ...filters,
              page: 1,
            });
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          disabled={!budgetList.totalResults}
          onClick={() => {
            loadData(filters, true);
          }}
        >
          Download
        </Button>
      </div>

      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          filters,
          setFilters,
          status,
          loadData
        )}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default POList;
