import React from "react";
import Grid from "@mui/material/Grid";
import SiFileUpload from "../../core/file-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../utils/constants";

const Snapshots = ({
  snapShotFileName,
  deleteSnapshotFile,
  setSnapShotFileName,
  setUnitEconomicModel,
  setCompetitionAndNearbyRestaurantSales,
  setGapmapSnapshot,
  documentData,
}) => {
  return (
    <div>
      <Grid container md={12} className="details">
        <div className="input-con">
          <p className="label">Unit Economics Model UEM Cover</p>

          {snapShotFileName.unitEconomicModel !== "" && (
            <div className="file-name-con">
              {snapShotFileName.unitEconomicModel}
              <DeleteIcon
                className="del"
                onClick={() =>
                  deleteSnapshotFile("Unit Economics Model - Cover")
                }
              />
            </div>
          )}

          <SiFileUpload
            clsName="relative"
            inputclassName="absoluteinput"
            documentName="Unit Economics Model - Cover"
            url={REST_URLS.UPLOAD_FILES}
            fileFormats={[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/svg+xml",
            ]}
            accept="image/png, image/jpg, image/jpeg,image/svg+xml"
            showFileName={setSnapShotFileName}
            setDataFunction={setUnitEconomicModel}
          />
        </div>
        <div className="input-con">
          <p className="label">
            {documentData?.storeType?.value === storeTypes.cocoAcquired
              ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
              : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales}
          </p>
          {snapShotFileName.competitionAndNearbyRestaurantSales !== "" && (
            <div className="file-name-con">
              {snapShotFileName.competitionAndNearbyRestaurantSales}
              <DeleteIcon
                className="del"
                onClick={() =>
                  deleteSnapshotFile(
                    cocoAcqKeyNames.competitionAndNearbyRestaurantSales
                  )
                }
              />
            </div>
          )}
          <SiFileUpload
            clsName="relative"
            inputclassName="absoluteinput"
            url={REST_URLS.UPLOAD_FILES}
            showFileName={setSnapShotFileName}
            fileFormats={[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/svg+xml",
            ]}
            accept="image/png, image/jpg, image/jpeg, image/svg+xml"
            setDataFunction={setCompetitionAndNearbyRestaurantSales}
            documentName={
              documentData?.storeType?.value === storeTypes.cocoAcquired
                ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
                : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
            }
          />
        </div>
        <div className="input-con">
          <p className="label">
            {documentData?.storeType?.value === storeTypes.cocoAcquired
              ? "Total Acquisition Cost"
              : "Gapmap snapshot"}{" "}
          </p>
          {snapShotFileName.gapmapSnapshot !== "" && (
            <div className="file-name-con">
              {snapShotFileName.gapmapSnapshot}
              <DeleteIcon
                className="del"
                onClick={() => deleteSnapshotFile("Total Acquisition Cost")}
              />
            </div>
          )}
          <SiFileUpload
            clsName="relative"
            inputclassName="absoluteinput"
            warningclass="cf-approval-warning"
            url={REST_URLS.UPLOAD_FILES}
            showFileName={setSnapShotFileName}
            fileFormats={[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/svg+xml",
            ]}
            accept="image/png, image/jpg, image/jpeg,image/svg+xml"
            setDataFunction={setGapmapSnapshot}
            documentName="Total Acquisition Cost"
          />
        </div>
      </Grid>
    </div>
  );
};

export default Snapshots;
