import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import { Login } from "../components/login";
import Users from "../components/users";
import Document from "../components/document";
import { Approvals } from "../components/approvals";
import CfApprovalReports from "../components/reports";
import { CF_APPROVAL_COMMON_ROUTE } from "../../common-utilities/utils/end-points/cf-approval";
import Dashboard from "../components/dashboard";
import FinancialDashboard from "../components/Financial Dashboard";

export const cfApprovalRouteConfig = {
  mainPath: {
    path: "/",
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: CF_APPROVAL_COMMON_ROUTE,
  routes: [
    {
      path: "/",
      Element: Dashboard,
      exact: true,
    },
    {
      path: "/create-cf",
      Element: Document,
      exact: true,
    },
    // {
    //   path: "/users",
    //   Element: Users,
    //   exact: true,
    // },
    {
      path: "/reports",
      Element: CfApprovalReports,
      exact: true,
    },
    {
      path: "/approval",
      Element: Approvals,
      exact: true,
    },
    {
      path: "/financial-dashboard",
      Element: FinancialDashboard,
      exact: true,
    },
  ],
};

const AppRoutes = () => {
  const [reloadPendingApprovals, setReloadPendingApprovals] = useState(false);

  return (
    <div>
      <Router>
        <Routes>
          {/* <Route exact path="/login" element={<Login />}></Route> */}
          <Route
            exact
            path="/"
            element={
              <PrivateRoute
                reloadPendingApprovals={reloadPendingApprovals}
                setReloadPendingApprovals={setReloadPendingApprovals}
              />
            }
          >
            <Route exact path="/" element={<Document />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/reports" element={<CfApprovalReports />} />
            <Route
              exact
              path="/approval"
              element={
                <Approvals
                  setReloadPendingApprovals={setReloadPendingApprovals}
                />
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
