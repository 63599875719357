import BellcorpStudioLogo from "../resources/images/bellcorp-logo-transparent-background.png";
import "./index.scss";

export const BellCorpStudioLogoContainer = ({ customClass }) => {
  return (
    <div className={`BellcorpStudioLogo ${customClass ? customClass : ""}`}>
      Powered by
      <a href="https://www.bellcorpstudio.com/">
        <img src={BellcorpStudioLogo} alt="Bellcorp Studio Logo" />
      </a>
    </div>
  );
};
