export const TYPES = [{ label: "None", value: "none" }];
export const REUSEABLE = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export const Assocation = [
  { label: "Attomey", value: "Attomey" },
  { label: "Developer", value: "Developer" },
  { label: "Landlord", value: "Landlord" },
  { label: "Lease Completed By", value: "Lease Completed By" },
  { label: "Lease Renegotiated", value: "Lease Renegotiated" },
  { label: "Lease Revisor", value: "Lease Revisor" },
  { label: "Management", value: "Management" },
];
