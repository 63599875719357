import React, { useState, useRef, useContext, useEffect } from "react";
import "./index.scss";
import { Divider, Grid, TextField, Button } from "@mui/material";
import Select from "react-select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";

import { invokeApi } from "../../utils/http-service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { checkValidStoreCombination } from "../../utils";
import { GlobalStoreContext } from "../../global-states/Context";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const BasicFixed = () => {
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [respData, setRespData] = useState(null);
  const [value, setValue] = useState([null, null]);
  const previousController = useRef();
  const [storeOptions, setStoreOptions] = useState([]);
  const [satelliteList, setSatelliteList] = useState([]);
  const [basicFixedValues, setBasicFixedValues] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  const [sequenceList, setSequenceList] = useState([]);

  const clearBasicFixedDetails = (reset = true) => {
    if (reset) setStoreInfo({ storeNo: "", satelliteNo: "", sequenceNo: "" });
    setBasicFixedValues({});
    setStoreOptions([]);
  };

  function removeEmptyElements(obj) {
    if (Array.isArray(obj)) {
      obj.forEach((element, index) =>
        obj.splice(index, 1, removeEmptyElements(element))
      );
      return obj;
    }
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, v]) =>
          Array.isArray(v)
            ? v.length !== 0
            : v !== null && v !== "" && v !== undefined
        )
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyElements(v) : v])
    );
  }

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value?.trim()) {
      getData(value.trim());
    } else {
      setStoreOptions([]);
    }
    const finalValue = value ? value : "";
    setStoreInfo({ storeNo: finalValue, satelliteNo: "", sequenceNo: "" });
    if (!finalValue) {
      clearBasicFixedDetails();
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };
  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getBasicFixedDetails(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    }
  }, []);

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setBasicFixedValuesOnChange = (e) => {
    const { name, value } = e.target;
    setBasicFixedValues((prevVal) => ({ ...prevVal, [name]: value }));
  };

  useEffect(() => {
    const isValidStoreCombination = checkValidStoreCombination(
      storeInfo.storeNo,
      storeInfo.satelliteNo,
      storeInfo.sequenceNo
    );
    if (isValidStoreCombination) {
      getBasicFixedDetails(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
    }
  }, []);

  const getBasicFixedDetails = (storeNo, satelliteNo, sequenceNo) => {
    const isValidStoreCombination = checkValidStoreCombination(
      storeNo,
      satelliteNo,
      sequenceNo
    );
    if (isValidStoreCombination) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_BASIC_FIXED}${storeNo}`,
        null,
        { satelliteNo, sequenceNo }
      )
        .then((res) => {
          if (!res.message) {
            setRespData(res);
            setBasicFixedValues(res);
          } else {
            // toast.error(res.message, { autoClose: 2000 });
            clearBasicFixedDetails(false);
          }
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err?.message, { autoClose: 2000 });
        });
    }
  };

  const createBasicFixed = () => {
    const payload = { ...basicFixedValues };
    payload.satelliteNo = storeInfo.satelliteNo;
    payload.storeNo = storeInfo.storeNo;
    payload.sequenceNo = storeInfo.sequenceNo;
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_BASIC_FIXED}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          setIsCreate(false);
          clearBasicFixedDetails(false);
          return;
        }
        toast.success("Basic fixed created successfully", { autoClose: 2000 });
        clearBasicFixedDetails(false);
        setIsCreate(false);
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };

  const saveDetails = () => {
    if (!Number.isInteger(parseInt(storeInfo.storeNo))) {
      toast.error("Please select store number", { autoClose: 2000 });
      return;
    }
    if (!Number.isInteger(parseInt(storeInfo.satelliteNo))) {
      toast.error("Please select satellite number", { autoClose: 2000 });
      return;
    }

    if (!Number.isInteger(parseInt(storeInfo.sequenceNo))) {
      toast.error("Please select sequence number", { autoClose: 2000 });
      return;
    }

    if (isCreate) {
      createBasicFixed();
      return;
    }
    const payload = removeEmptyElements({ ...basicFixedValues });
    payload.satelliteNo = storeInfo.satelliteNo;
    delete payload.archive;
    delete payload.id;
    payload.sequenceNo = storeInfo.sequenceNo;
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_BASIC_FIXED}${storeInfo.storeNo}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          clearBasicFixedDetails(false);
          return;
        }

        toast.success("Basic fixed updated successfully", { autoClose: 2000 });
        clearBasicFixedDetails(false);
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };

  return (
    <div className="basic-fixed-container">
      <div className="search-container">
        <div style={{ minWidth: "250px" }}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearBasicFixedDetails();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumber(val.id);
              }
            }}
          />
        </div>
        <div style={{ minWidth: "250px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null, ""].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
              if (!isCreate) {
                getBasicFixedDetails(
                  storeInfo.storeNo,
                  val?.value,
                  storeInfo.sequenceNo
                );
              }
            }}
          />
        </div>

        <div style={{ minWidth: "250px" }}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null, ""].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));
              if (!isCreate) {
                getBasicFixedDetails(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </div>
        <div style={{ minWidth: "250px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              className="subs-date-time-picker"
              renderInput={(props) => <TextField className="subs-text-field" {...props} />}
              label="DateTimePicker"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => clearBasicFixedDetails()}
            startIcon={<AutorenewIcon />}
            style={{ marginRight: "20px" }}
          >
            Reset
          </Button>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  clearBasicFixedDetails(false);
                  setIsCreate(true);
                }}
                startIcon={<SaveIcon />}
              >
                Create
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("basic_fixed_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </div>
      </div>
      <Divider style={{ paddingBottom: 10 }} />
      <div className="title">Basic Fixed Details </div>
      <Grid container>
        <Grid xs={3}>
          <div className="form-details">
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.gas || ""}
              name="gas"
              type="number"
              label="Gas"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.electric || ""}
              name="electric"
              type="number"
              fullWidth
              label="Electric"
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.telephone || ""}
              name="telephone"
              type="number"
              label="Telephone"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.garbage || ""}
              name="garbage"
              type="number"
              label="Garbage"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.hoursOpened || ""}
              name="hoursOpened"
              type="number"
              label="Hours Opened"
              fullWidth
            />
          </div>
        </Grid>
        <Grid xs={3}>
          <div className="form-details">
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.insurance || ""}
              name="insurance"
              type="number"
              label="Insurance"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.rent || ""}
              name="rent"
              type="number"
              label="Rent"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.labor || ""}
              name="labor"
              type="number"
              label="Labor"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.breakEvenPercentage || ""}
              name="breakEvenPercentage"
              type="number"
              label="Break Even %"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.otherLoans || ""}
              name="otherLoans"
              type="number"
              label="Other Loans"
              fullWidth
            />
          </div>
        </Grid>
        <Grid xs={3}>
          <div className="form-details">
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.misc || ""}
              name="misc"
              type="number"
              label="Misc"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.repairs || ""}
              name="repairs"
              type="number"
              label="Repairs"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.basicFixed || ""}
              name="basicFixed"
              type="number"
              label="Basic Fixed"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.breakEven || ""}
              name="breakEven"
              type="number"
              label="Break Even"
              fullWidth
            />
            <TextField
              className="subs-text-field"
              onChange={setBasicFixedValuesOnChange}
              value={basicFixedValues.zipCode || ""}
              name="zipCode"
              type="number"
              label="Pin"
              fullWidth
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "white",
          pr: 5,
          paddingTop: "10px",
        }}
      >
        <div className="g-r-2">
          {isCreate && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsCreate(false);
              }}
            >
              Cancel
            </Button>
          )}

          <Button
            size="small"
            variant="contained"
            onClick={saveDetails}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      </Grid>
    </div>
  );
};
export default BasicFixed;
