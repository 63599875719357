import React, { useEffect, useState } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import Select from "react-select";
import {
  backDecor,
  backType,
  booth,
  ceilingCustomerArea,
  ceilingServiceArea,
  counter,
  counterFrontType,
  custAreaUpper,
  customerAreaLower,
  customerPendant,
  decorType,
  flooringCustomerArea,
  frontFinish,
  frontGlass,
  lightingCustomerCaegory,
  lightingInfoServiceAea,
  menuboard,
  paintedCeiling,
  selfServeBeverage,
  soffit,
  tableAndChairs,
  upholsteredFurniture,
  wallArt,
  wallArtType,
  wallCoveringServiceArea,
} from "./config";
import moment from "moment";

const DecAttributes = ({ decorAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(decorAttributes);
    }
  }, [decorAttributes]);

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    decorOptionInfo = {},
    flooringInfo = {},
    ceilingInfo = {},
    counterInfo = {},
    seatingInfo = {},
    lightingInfo = {},
    wallCoveringInfo = {},
  } = formData;

  return (
    <div className="basic-fixed-container">
      <Grid container>
        <Grid xs={6}>
          <div className="title">Decor</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Type</p>
                <Select
                  placeholder="Type"
                  classNamePrefix="custom_select"
                  value={
                    formData?.type
                      ? {
                          label: formData?.type,
                          value: formData?.type,
                        }
                      : null
                  }
                  options={decorType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      type: e?.value,
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      type: e?.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2">
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.crownMolding || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.crownMolding,
                          "decorOptionInfo",
                          "crownMolding"
                        );
                      }}
                    />
                  }
                  label="Crown moldings"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.corniceTrim || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.corniceTrim,
                          "decorOptionInfo",
                          "corniceTrim"
                        );
                      }}
                    />
                  }
                  label="Cornice Trim"
                  labelPlacement="end"
                />
              </div>
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.barHeightFurniture || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.barHeightFurniture,
                          "decorOptionInfo",
                          "barHeightFurniture"
                        );
                      }}
                    />
                  }
                  label="Bar Height Furniture"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.flooring || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.flooring,
                          "decorOptionInfo",
                          "flooring"
                        );
                      }}
                    />
                  }
                  label="Curved Pattern Flooring"
                  labelPlacement="end"
                />
              </div>
            </div>
            <div className="g-r-2">
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.stripLighting || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.stripLighting,
                          "decorOptionInfo",
                          "stripLighting"
                        );
                      }}
                    />
                  }
                  label="Strip Lighting"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.decorativeCeiling || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.decorativeCeiling,
                          "decorOptionInfo",
                          "decorativeCeiling"
                        );
                      }}
                    />
                  }
                  label="Decorative Ceiling"
                  labelPlacement="end"
                />
              </div>

              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.canvasWallArt || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.canvasWallArt,
                          "decorOptionInfo",
                          "canvasWallArt"
                        );
                      }}
                    />
                  }
                  label="Canvas Wall Art"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.fireplace || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.fireplace,
                          "decorOptionInfo",
                          "fireplace"
                        );
                      }}
                    />
                  }
                  label="Fireplace"
                  labelPlacement="end"
                />
              </div>
            </div>

            <div className="g-r-2">
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.banquetteSeating || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.banquetteSeating,
                          "decorOptionInfo",
                          "banquetteSeating"
                        );
                      }}
                    />
                  }
                  label="Banquette Seating(Metro)"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.cafeLocation || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.cafeLocation,
                          "decorOptionInfo",
                          "cafeLocation"
                        );
                      }}
                    />
                  }
                  label="Cafe Location"
                  labelPlacement="end"
                />
              </div>
              <div className="g-r-2">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.etchedCityscape || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.etchedCityscape,
                          "decorOptionInfo",
                          "etchedCityscape"
                        );
                      }}
                    />
                  }
                  label="Etched City Space"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      style={{ padding: "0px 9px" }}
                      checked={decorOptionInfo?.stoneWallLogo || false}
                      onChange={() => {
                        onInnerCheckboxChange(
                          !decorOptionInfo.stoneWallLogo,
                          "decorOptionInfo",
                          "stoneWallLogo"
                        );
                      }}
                    />
                  }
                  label="Stone Wall w/Logo"
                  labelPlacement="end"
                />
              </div>
            </div>

            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={decorOptionInfo?.redAccentWall || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !decorOptionInfo.redAccentWall,
                        "decorOptionInfo",
                        "redAccentWall"
                      );
                    }}
                  />
                }
                label="Red Accent Wall"
                labelPlacement="end"
              />
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Wall Art</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Wall Art"
                  value={
                    formData?.decorOptionInfo?.wallArt
                      ? {
                          label: formData?.decorOptionInfo?.wallArt,
                          value: formData?.decorOptionInfo?.wallArt,
                        }
                      : null
                  }
                  options={wallArtType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        wallArt: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        wallArt: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Upholsterd Furniture</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Upholsterd Furniture"
                  value={
                    formData?.decorOptionInfo?.upholsteredFurniture
                      ? {
                          label:
                            formData?.decorOptionInfo?.upholsteredFurniture,
                          value:
                            formData?.decorOptionInfo?.upholsteredFurniture,
                        }
                      : null
                  }
                  options={upholsteredFurniture}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        upholsteredFurniture: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        upholsteredFurniture: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Soffit</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Soffit"
                  value={
                    formData?.decorOptionInfo?.soffit
                      ? {
                          label: formData?.decorOptionInfo?.soffit,
                          value: formData?.decorOptionInfo?.soffit,
                        }
                      : null
                  }
                  options={soffit}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        soffit: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        soffit: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Painting Ceiling</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Painting Ceiling"
                  value={
                    formData?.decorOptionInfo?.paintedCeiling
                      ? {
                          label: formData?.decorOptionInfo?.paintedCeiling,
                          value: formData?.decorOptionInfo?.paintedCeiling,
                        }
                      : null
                  }
                  options={paintedCeiling}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        paintedCeiling: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      decorOptionInfo: {
                        ...formData.decorOptionInfo,
                        paintedCeiling: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Flooring Customer Area</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Flooring Customer Area"
                  value={
                    formData?.flooringInfo?.customerArea
                      ? {
                          label: formData?.flooringInfo?.customerArea,
                          value: formData?.flooringInfo?.customerArea,
                        }
                      : null
                  }
                  options={flooringCustomerArea}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      flooringInfo: {
                        ...formData.flooringInfo,
                        customerArea: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      flooringInfo: {
                        ...formData.flooringInfo,
                        customerArea: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="title">Ceiling</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Customer Area</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Customer Area"
                  value={
                    formData?.ceilingInfo?.customerArea
                      ? {
                          label: formData?.ceilingInfo?.customerArea,
                          value: formData?.ceilingInfo?.customerArea,
                        }
                      : null
                  }
                  options={ceilingCustomerArea}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      ceilingInfo: {
                        ...formData.ceilingInfo,
                        customerArea: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      ceilingInfo: {
                        ...formData.ceilingInfo,
                        customerArea: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <TextField
                label="Height (feet)"
                className="subs-text-field"
                fullWidth
                value={
                  ceilingInfo?.height
                    ? moment(
                        ceilingInfo.height,
                        "ddd MMM DD YYYY HH:mm:ss ZZ"
                      ).isValid()
                      ? moment(ceilingInfo.height).format("DD-MM-YYYY")
                      : ceilingInfo.height
                    : ""
                }
                name="height"
                onChange={(event) => onInnerInputChange(event, "ceilingInfo")}
              />
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Service Area</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Service Area"
                  value={
                    formData?.ceilingInfo?.serviceArea
                      ? {
                          label: formData?.ceilingInfo?.serviceArea,
                          value: formData?.ceilingInfo?.serviceArea,
                        }
                      : null
                  }
                  options={ceilingServiceArea}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      ceilingInfo: {
                        ...formData.ceilingInfo,
                        serviceArea: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      ceilingInfo: {
                        ...formData.ceilingInfo,
                        serviceArea: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <TextField
                className="subs-text-field"
                label="Height (feet)"
                fullWidth
                value={
                  ceilingInfo?.serviceAreaHeight
                    ? moment(
                        ceilingInfo.serviceAreaHeight,
                        "ddd MMM DD YYYY HH:mm:ss ZZ"
                      ).isValid()
                      ? moment(ceilingInfo.serviceAreaHeight).format(
                          "DD-MM-YYYY"
                        )
                      : ceilingInfo.serviceAreaHeight
                    : ""
                }
                name="serviceAreaHeight"
                onChange={(event) => onInnerInputChange(event, "ceilingInfo")}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Counter</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Front Type</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Front Type"
                  value={
                    formData?.counterInfo?.frontType
                      ? {
                          label: formData?.counterInfo?.frontType,
                          value: formData?.counterInfo?.frontType,
                        }
                      : null
                  }
                  options={counterFrontType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontType: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontType: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Front Finish</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Front Finish"
                  value={
                    formData?.counterInfo?.frontFinish
                      ? {
                          label: formData?.counterInfo?.frontFinish,
                          value: formData?.counterInfo?.frontFinish,
                        }
                      : null
                  }
                  options={frontFinish}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontFinish: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontFinish: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Front Glass</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Front Glass"
                  value={
                    formData?.counterInfo?.frontGlass
                      ? {
                          label: formData?.counterInfo?.frontGlass,
                          value: formData?.counterInfo?.frontGlass,
                        }
                      : null
                  }
                  options={frontGlass}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontGlass: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        frontGlass: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Back Finish</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Back Finish"
                  value={
                    formData?.counterInfo?.backType
                      ? {
                          label: formData?.counterInfo?.backType,
                          value: formData?.counterInfo?.backType,
                        }
                      : null
                  }
                  options={backType}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        backType: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        backType: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Back Decor</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Back Decor"
                  value={
                    formData?.counterInfo?.backDecor
                      ? {
                          label: formData?.counterInfo?.backDecor,
                          value: formData?.counterInfo?.backDecor,
                        }
                      : null
                  }
                  options={backDecor}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        backDecor: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        backDecor: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Self Serve Beverage</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Self Serve Beverage"
                  value={
                    formData?.counterInfo?.selfServeBeverage
                      ? {
                          label: formData?.counterInfo?.selfServeBeverage,
                          value: formData?.counterInfo?.selfServeBeverage,
                        }
                      : null
                  }
                  options={selfServeBeverage}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        selfServeBeverage: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      counterInfo: {
                        ...formData.counterInfo,
                        selfServeBeverage: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="title">Seating</div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Booth</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Booth"
                  value={
                    seatingInfo?.booth
                      ? { label: seatingInfo.booth, value: seatingInfo.booth }
                      : null
                  }
                  options={booth}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        booth: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        booth: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Table and Chairs</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Table and Chairs"
                  value={
                    seatingInfo?.tableAndChairs
                      ? {
                          label: seatingInfo.tableAndChairs,
                          value: seatingInfo.tableAndChairs,
                        }
                      : null
                  }
                  options={tableAndChairs}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        tableAndChairs: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        tableAndChairs: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Counter</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Counter"
                  value={
                    seatingInfo?.counter
                      ? {
                          label: seatingInfo.counter,
                          value: seatingInfo.counter,
                        }
                      : null
                  }
                  options={counter}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        counter: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      seatingInfo: {
                        ...formData.seatingInfo,
                        counter: e?.value,
                      },
                    }));
                  }}
                />
              </div>

              <TextField
                className="subs-text-field"
                label="Seating Capacity Customer Area"
                fullWidth
                value={seatingInfo?.customerArea || ""}
                name="customerArea"
                onChange={(event) => onInnerInputChange(event, "seatingInfo")}
              />
            </div>
            <div className="g-r-2">
              <TextField
                className="subs-text-field"
                label="Seating Capacity Outdoor"
                fullWidth
                value={seatingInfo?.outdoor || ""}
                name="outdoor"
                onChange={(event) => onInnerInputChange(event, "seatingInfo")}
              />
            </div>
          </div>
          <div className="title">Wall covering</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Service Area</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Service Area"
                  value={
                    wallCoveringInfo?.serviceArea
                      ? {
                          label: wallCoveringInfo.serviceArea,
                          value: wallCoveringInfo.serviceArea,
                        }
                      : null
                  }
                  options={wallCoveringServiceArea}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        serviceArea: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        serviceArea: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Cust Area Lower</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Cust Area Lower"
                  value={
                    wallCoveringInfo?.custAreaLower
                      ? {
                          label: wallCoveringInfo.custAreaLower,
                          value: wallCoveringInfo.custAreaLower,
                        }
                      : null
                  }
                  options={customerAreaLower}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        custAreaLower: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        custAreaLower: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Cust Area Upper</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Cust Area Upper"
                  value={
                    wallCoveringInfo?.custAreaUpper
                      ? {
                          label: wallCoveringInfo.custAreaUpper,
                          value: wallCoveringInfo.custAreaUpper,
                        }
                      : null
                  }
                  options={custAreaUpper}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        custAreaUpper: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      wallCoveringInfo: {
                        ...formData.wallCoveringInfo,
                        custAreaUpper: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={6}>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Lighting/Banner/Wall Art</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Lighting/Banner/Wall Art"
                  value={
                    formData?.lightingInfo?.wallArt
                      ? {
                          label: formData?.lightingInfo?.wallArt,
                          value: formData?.lightingInfo?.wallArt,
                        }
                      : null
                  }
                  options={wallArt}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        wallArt: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        wallArt: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Menuboard</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Menuboard"
                  value={
                    formData?.lightingInfo?.menuboard
                      ? {
                          label: formData?.lightingInfo?.menuboard,
                          value: formData?.lightingInfo?.menuboard,
                        }
                      : null
                  }
                  options={menuboard}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        menuboard: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        menuboard: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Service Area</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Service Area"
                  value={
                    formData?.lightingInfo?.serviceArea
                      ? {
                          label: formData?.lightingInfo?.serviceArea,
                          value: formData?.lightingInfo?.serviceArea,
                        }
                      : null
                  }
                  options={lightingInfoServiceAea}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        serviceArea: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        serviceArea: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Customer Category</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Customer Category"
                  value={
                    lightingInfo?.customerCategory
                      ? {
                          label: lightingInfo.customerCategory,
                          value: lightingInfo.customerCategory,
                        }
                      : null
                  }
                  options={lightingCustomerCaegory}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        customerCategory: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        customerCategory: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Customer Pendant</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Customer Pendant"
                  value={
                    lightingInfo?.customerPendant
                      ? {
                          label: lightingInfo.customerPendant,
                          value: lightingInfo.customerPendant,
                        }
                      : null
                  }
                  options={customerPendant}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        customerPendant: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      lightingInfo: {
                        ...formData.lightingInfo,
                        customerPendant: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default DecAttributes;
