import {
  getAllExcelColumns,
  processExcelDates,
} from "../../../../common-utilities/utils/excel";
import {
  validStoreTypes,
  validDocumentTypes,
} from "../../../utils/document-constants";
import { downloadReportDates } from "../config/download-reports-date.config";

export const getYesNAnoForUploadReports = (
  storeType,
  documentType,
  count,
  na,
  isApplied
) => {
  if (isApplied) {
    return "AF";
  }
  if (na) {
    return "NA";
  }
  if (count > 0) {
    return "YES";
  }

  switch (storeType) {
    case validStoreTypes.cocoBuilt:
      switch (documentType) {
        case validDocumentTypes.license:
          return "NO";
        default:
          return "NO";
      }
    case validStoreTypes.cocoAcquired:
      switch (documentType) {
        case validDocumentTypes.license:
          return "NO";
        default:
          return "NO";
      }

    default:
      return "NO";
  }
};

// We now get restaurants from intranet instead of docustorage, hence instead of using a filter of restaurant id we use store
export const changeRestaurantIdToStoreInFilter = (params) => {
  const { restaurantId } = params || {};
  if (restaurantId) {
    delete params.restaurantId;
    params.store = restaurantId;
  }
};

export const processDownloadReports = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateColumns = headers.reduce((columnList, header, index) => {
    const column = columns[index];
    if (downloadReportDates.includes(header)) {
      columnList.push(column);
    }
    return columnList;
  }, []);

  const convertedWorkSheet = processExcelDates(data, worksheet, dateColumns);
  workbook.Sheets[sheetName] = convertedWorkSheet;
  workbook.SheetNames.push(sheetName);
};
