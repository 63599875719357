import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { downloadFileFromUrl, getFileNameType } from '../../utils/common-utils';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import './index.scss';
import PdfViewer from '../pdf-viewer';

const IMAGE_TYPES = ['PNG', 'JPEG', 'JPG'];
const PDF_TYPES = ['PDF', 'pdf'];

const DocumentView = ({ className, type, url }) => {
  const typeFromUrl = getFileNameType(url);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  if (
    PDF_TYPES.includes((type || '').toUpperCase()) ||
    PDF_TYPES.includes((typeFromUrl || '').toUpperCase())
  ) {
    return (
      <div className={`${className} document-viewer`}>
        <div className='dv-download-icon'>
          <IconButton onClick={() => downloadFileFromUrl(url)}>
            <DownloadForOfflineIcon color='primary' />
          </IconButton>
        </div>
        <div className='document-viewer-pdf'>
          <PdfViewer pdf={url} />
        </div>
      </div>
    );
  }

  if (
    IMAGE_TYPES.includes((type || '').toUpperCase()) ||
    IMAGE_TYPES.includes((typeFromUrl || '').toUpperCase())
  ) {
    return (
      <div className={`${className} document-viewer`}>
        <div className='dv-download-icon'>
          <IconButton onClick={() => downloadFileFromUrl(url)}>
            <DownloadForOfflineIcon color='primary' />
          </IconButton>
        </div>
        <img src={url} className='document-viewer-image' />
      </div>
    );
  }

  return (
    <div className={`${className} document-viewer`}>
      <div className='dv-download-icon'>
        <IconButton onClick={() => downloadFileFromUrl(url)}>
          <DownloadForOfflineIcon color='primary' />
        </IconButton>
      </div>
      <span className='document-viewer-image'>
        Preview not available for type : {type}
      </span>
    </div>
  );
};

export default DocumentView;
