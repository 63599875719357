import { toast } from "react-toastify";
import { mainInvokeApi } from "../../utils/http-service";
import { getDocApiDetails } from "./utils";
import { getCatApiDetails } from "./utils-2";

export const createUpdateDoc = (formDetails, slug, onSuccess) => {
  const { method, url, payload, message } = getDocApiDetails(formDetails);
  mainInvokeApi("subway", slug, method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      onSuccess && onSuccess();
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};

export const createUpdateAppDocCat = (formDetails, slug, onSuccess) => {
  const { method, url, payload, message } = getCatApiDetails(formDetails);

  mainInvokeApi("subway", slug, method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      onSuccess && onSuccess();
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};
