import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.scss";
import { Button, Grid, TextareaAutosize, TextField } from "@mui/material";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { invokeApi } from "../../utils/http-service";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { checkValidStoreCombination } from "../../utils";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";
import excelDownload from "../../../common-utilities/utils/excelDownload";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";

const Lease2 = () => {
  const columns = [
    { id: "sno", label: "S.No" },
    { id: "approvalType", label: "Approval Type" },
    { id: "processDate", label: "Process Date" },
    { id: "approvalStatus", label: "Approval Status" },
    { id: "contingencies", label: "Contingencies" },
  ];
  const [respData, setRespData] = useState(null);
  const { leaseInfo, setLeaseInfo } = useContext(GlobalStoreContext);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [data, setData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [leaseDetails, setLeaseDetails] = useState({ store: null });
  const [leaseIdList, setLeaseIdList] = useState([]);
  const [satteliteList, setSatteliteList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const previousController = useRef();
  const update = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      negotiation: {
        dateLeaseRead: leaseDetails.dateLeaseRead,
        dateLeaseReceived: leaseDetails.dateLeaseReceived,
        dateMailedToDA: leaseDetails.dateMailedToDA,
        dateMailedToLandLord: leaseDetails.dateMailedToLandLord,
        receivedFromLandLord: leaseDetails.receivedFromLandLord,
        offerReceived: leaseDetails.offerReceived,
      },
      notificationLetters: {
        requested: leaseDetails.requested,
        sent: leaseDetails.sent,
        notRequired: leaseDetails.notRequired,
      },
      fileSiteReview: leaseDetails.fileSiteReview,
      foreignTerritoryLeaseApproval: leaseDetails.foreignTerritoryLeaseApproval,
      comments: leaseDetails.comments,
      relocationWithinCenter: leaseDetails.relocationWithinCenter,
    };

    if (Number.isInteger(parseInt(leaseInfo.leaseId))) {
      invokeApi(
        HTTP_METHODS.PUT,
        HOSTNAME + REST_URLS.PUT_LEASE + leaseInfo.leaseId,
        body
      )
        .then((res) => {
          if (res.id) {
            toast.success("Updated Successfully");
            clearData(false);
          } else toast.error("Update Failed");
        })
        .catch((error) => {
          toast.error("Update Failed");
        });
    }
  };
  const createLeaseHandler = () => {
    let body = {
      storeNo: storeInfo.storeNo,
      sequenceNo: parseInt(storeInfo.sequenceNo),
      satelliteNo: storeInfo.satelliteNo,
      negotiation: {
        dateLeaseRead: leaseDetails.dateLeaseRead,
        dateLeaseReceived: leaseDetails.dateLeaseReceived,
        dateMailedToDA: leaseDetails.dateMailedToDA,
        dateMailedToLandLord: leaseDetails.dateMailedToLandLord,
        receivedFromLandLord: leaseDetails.receivedFromLandLord,
        offerReceived: leaseDetails.offerReceived,
      },
      notificationLetters: {
        requested: leaseDetails.requested,
        sent: leaseDetails.sent,
        notRequired: leaseDetails.notRequired,
      },
      fileSiteReview: leaseDetails.fileSiteReview,
      foreignTerritoryLeaseApproval: leaseDetails.foreignTerritoryLeaseApproval,
      comments: leaseDetails.comments,
      relocationWithinCenter: leaseDetails.relocationWithinCenter,
    };

    invokeApi(HTTP_METHODS.POST, HOSTNAME + REST_URLS.POST_LEASE, body)
      .then((res) => {
        if (res.id) {
          toast.success("Created Successfully", { autoClose: 2000 });
          clearData(false);
        } else toast.error("Create Failed", { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error("Create Failed", { autoClose: 2000 });
      });
  };
  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    }
  };

  const stateUpdate = (updatedValues) => {
    setLeaseDetails((prevState) => {
      return { ...prevState, ...updatedValues };
    });
  };

  const checkBoxChange = (name) => {
    let tempState = { ...leaseDetails };
    tempState[name] = tempState[name] === true ? false : true;
    setLeaseDetails(tempState);
  };

  const apply = (id) => {
    if (Number.isInteger(parseInt(id))) {
      invokeApi(
        HTTP_METHODS.GET,
        HOSTNAME + REST_URLS.GET_LEASE + id + "/" + storeInfo.storeNo
      ).then((res) => {
        if (res.code === 404) {
          toast.info("No Lease is Avaialble for the selected Store");
        } else {
          setRespData(res);
          let obj = {};
          obj["dateLeaseRead"] = dateFun(
            res?.storeLease?.negotiation?.dateLeaseRead
          );
          obj["dateLeaseReceived"] = dateFun(
            res?.storeLease?.negotiation?.dateLeaseReceived
          );
          obj["dateMailedToDA"] = dateFun(
            res?.storeLease?.negotiation?.dateMailedToDA
          );
          obj["revisedBy"] = res?.storeLease?.negotiation?.revisedBy;
          obj["dateMailedToLandLord"] = dateFun(
            res?.storeLease?.negotiation?.dateMailedToLandLord
          );
          obj["receivedFromLandLord"] = dateFun(
            res?.storeLease?.negotiation?.receivedFromLandLord
          );
          obj["offerReceived"] = dateFun(
            res?.storeLease?.negotiation?.offerReceived
          );
          obj["requested"] = dateFun(
            res?.storeLease?.notificationLetters?.requested
          );
          obj["sent"] = dateFun(res?.storeLease?.notificationLetters?.sent);
          obj["notRequired"] =
            res?.storeLease?.notificationLetters?.notRequired;
          obj["fileSiteReview"] = dateFun(res?.storeLease?.fileSiteReview);
          obj["foreignTerritoryLeaseApproval"] = dateFun(
            res?.storeLease?.foreignTerritoryLeaseApproval
          );
          obj["comments"] = res?.storeLease?.comments;
          obj["relocationWithinCenter"] =
            res?.storeLease?.relocationWithinCenter;
          let tableData = res?.storeLease?.siteApprovals.map((iter, index) => {
            return {
              sno: index + 1,
              approvalType: iter.approvalType,
              processDate: dateFun(iter.processDate),
              approvalStatus: iter.approvalStatus,
              contingencies: iter.contingencies,
            };
          });
          stateUpdate(obj);
          setData(tableData);
        }
      });
    }
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    let tempState = { ...leaseDetails };
    tempState[key] = value;
    setLeaseDetails(tempState);
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: "",
      });
    setStoreOptions([]);
    setLeaseDetails({});
    setLeaseIdList([]);
    if (isCreate) {
      setIsCreate(false);
    }
  };

  const onInputChange = (event, value, reason) => {
    setLeaseInfo({ leaseId: "" });
    setSatteliteList([]);
    setSequenceList([]);
    setLeaseIdList([]);
    if (value) {
      getData(value);
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  const selectHandle = (name, value, fieldsToClear = {}) => {
    const clearedData = fieldsToClear ? fieldsToClear : {};
    let tempState = { ...leaseDetails, ...clearedData };
    tempState[name] = value;
    setLeaseDetails(tempState);
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.storeNo, id: p.storeNo, satellite: p.satelliteNum };
      });
      setStoreOptions(updatedOptions);
    });
  };
  const listingLeaseHandler = (id, satelliteNo, sequenceNo) => {
    setStoreInfo({
      storeNo: id,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
    });
    const invalidStoreCombination = !checkValidStoreCombination(
      id,
      satelliteNo,
      sequenceNo
    );

    if (invalidStoreCombination) {
      return;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_LEASES}${id}`,
      null,
      {
        sequenceNo,
        satelliteNo,
      }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => ({ label: ele.id, value: ele.id }));
          setLeaseIdList(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumberAndSatelliteNumbersForStoreNumber(storeInfo.storeNo);
      listingLeaseHandler(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo
      );
      apply(leaseInfo.leaseId);
    }
  }, []);

  const getAllSequenceNumberAndSatelliteNumbersForStoreNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatteliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="lease">
      <Grid container md={12} sx={{ mt: 5, mb: 0 }}>
        <Grid item md={2} xs={4}>
          <Autocomplete
            id="free-solo-demo"
            className="subs-autocomplete"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                font
                onClick={() => {
                  clearData();
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumberAndSatelliteNumbersForStoreNumber(val?.id);
              }
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satteliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satteliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("satelliteNo", val.value, { leaseId: null });
              listingLeaseHandler(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo
              );
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              selectHandle("sequenceNo", val.value, { leaseId: null });
              if (!isCreate) {
                listingLeaseHandler(
                  storeInfo.storeNo,
                  storeInfo.satelliteNo,
                  val?.value
                );
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          {!isCreate && (
            <Select
              classNamePrefix="custom_select"
              placeholder="lease Id"
              className="sel-item"
              options={leaseIdList}
              value={
                ![undefined, null, ""].includes(leaseInfo.leaseId)
                  ? leaseIdList.find((i) => i.value === leaseInfo.leaseId)
                  : ""
              }
              onChange={(val) => {
                setLeaseInfo({ leaseId: val?.value });
                apply(val.value);
              }}
            />
          )}
        </Grid>
        <Grid item md={2} xs={6} style={{ height: "35px" }}>
          {!isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mr: 2, float: "right" }}
                onClick={() => {
                  clearData(false);
                  setIsCreate(true);
                }}
              >
                Create New
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  excelDownload("lease_details_" + storeInfo.storeNo, respData);
                }}
                disabled={respData === null}
                startIcon={
                  <DownloadRoundedIcon
                    style={{ fontSize: "20px", marginRight: "-5px" }}
                  />
                }
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <div className="title">DA/Tenant</div>
      <Grid container md={12} xs={12}>
        <Grid item md={3}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Development Agent"
            className="sel-item"
            isDisabled={true}
          />
        </Grid>
        <Grid item md={3}>
          <Select
            placeholder="Tenant"
            className="sel-item"
            isDisabled={true}
            classNamePrefix="custom_select"
          />
        </Grid>
        <Grid item md={2}>
          <div style={{ marginTop: "-5px" }}>
            <span className="input-title-paragrah">
              Relocation Within Center
            </span>
            <Checkbox
              checked={leaseDetails.relocationWithinCenter || false}
              onChange={() => {
                checkBoxChange("relocationWithinCenter");
              }}
            />
          </div>
        </Grid>
        <Grid item md={2}>
          <div style={{ marginTop: "-5px" }}>
            <span className="input-title-paragrah">Concession Agreement</span>
            <Checkbox disabled />
          </div>
        </Grid>
        <Grid item md={2}>
          <div style={{ marginTop: "-5px" }}>
            <span className="input-title-paragrah">Current</span>
            <Checkbox disabled />
          </div>
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item md={6}>
          <div className="title">Negotiation</div>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Date Lease Read
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.dateLeaseRead) || ""}
                    name="dateLeaseRead"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Date Lease Revised
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.dateLeaseReceived) || ""}
                    name="dateLeaseReceived"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Date Mailed to Landloard
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.dateMailedToLandLord) || ""}
                    name="dateMailedToLandLord"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Date Mailed to DA
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.dateMailedToDA) || ""}
                    name="dateMailedToDA"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    Received Date (Landloard)
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.receivedFromLandLord) || ""}
                    name="receivedFromLandLord"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Offer Received Date
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.offerReceived) || ""}
                    name="offerReceived"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2">
                {/* <Select className="sel-item" placeholder="Revised By" classNamePrefix="custom_select" /> */}
                <TextField
                  fullWidth
                  className="sel-item subs-text-field"
                  type="text"
                  value={leaseDetails.revisedBy || ""}
                  label="Revised By"
                  name="revisedBy"
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="title">Notification</div>
          <div className="input-form-container">
            <div className="content-container">
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">Requested</p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.requested) || ""}
                    name="requested"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">Sent</p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={dateFun(leaseDetails.sent) || ""}
                    name="sent"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="g-r-2" style={{ marginTop: "22px" }}>
                <div>
                  <span className="input-title-paragrah">Not Required</span>
                  <Checkbox
                    checked={leaseDetails.notRequired || false}
                    onChange={() => {
                      checkBoxChange("notRequired");
                    }}
                  />
                </div>
              </div>
              <div className="g-r-2">
                <div>
                  <p className="input-title input-title-paragrah">
                    File Site Review
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    value={dateFun(leaseDetails.fileSiteReview) || ""}
                    name="fileSiteReview"
                    onChange={handleChange}
                    type="date"
                  />
                </div>
                <div>
                  <p className="input-title input-title-paragrah">
                    Foregion Territory Lease Approval
                  </p>
                  <TextField
                    fullWidth
                    className="sel-item subs-text-field"
                    type="date"
                    value={
                      dateFun(leaseDetails.foreignTerritoryLeaseApproval) || ""
                    }
                    name="foreignTerritoryLeaseApproval"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={3}>
          <TextareaAutosize
            placeholder="Comments"
            style={{ maxWidth: "100%", width: "90%", marginTop: "1em" }}
            minRows={6}
            value={leaseDetails.comments || ""}
            name="comments"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={9}>
          <p className="input-title input-title-paragrah">Site Approvals</p>
          <StickyHeaderCon
            columnsList={columns}
            containerHeight="table-con"
            dataList={data}
          />
        </Grid>
      </Grid>
      <Grid item md={2} xs={6} style={{ height: "35px" }}>
        <span
          style={{
            display: "flex",
            padding: "5px",
            borderRadius: "5px",
            float: "right",
            marginTop: "-5px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => {
              clearData(isCreate ? false : true);
            }}
          >
            {isCreate ? "Cancel" : "Reset"}
          </Button>
          {!isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                ![null, ""].includes(storeInfo.satelliteNo) &&
                ![null, ""].includes(storeInfo.sequenceNo) &&
                ![null, ""].includes(leaseInfo.leaseId)
                  ? false
                  : true
              }
              onClick={() => {
                update();
              }}
            >
              Save
            </Button>
          )}

          {isCreate && (
            <Button
              size="small"
              variant="contained"
              disabled={
                storeInfo.storeNo &&
                storeInfo.satelliteNo !== null &&
                storeInfo.sequenceNo !== null
                  ? false
                  : true
              }
              onClick={() => {
                createLeaseHandler();
              }}
            >
              Save
            </Button>
          )}
        </span>
      </Grid>
    </div>
  );
};

export default Lease2;
