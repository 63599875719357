import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  TextareaAutosize,
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { DEPOSITIONS } from "./config";
import Select from "react-select";
const ShowMemo = ({ showMemoText, onClose }) => {
  const [memo, setMemo] = useState(showMemoText?.text);
  const [isClosed, setIsClosed] = useState(showMemoText?.isClosed);
  const [closingDate, setClosingDate] = useState("");
  const [appliedDate, setAppliedDate] = useState(
    showMemoText?.appliedDate?.split("T")[0]
  );
  const [deposition, setDeposition] = useState(showMemoText?.deposition);
  const handleChange = (event) => {
    setIsClosed(event.target.checked);
  };
  const appliedDateHandler = (e) => {
    setAppliedDate(e.target.value);
  };
  const closingDateHandler = (e) => {
    setClosingDate(e?.target?.value);
  };

  const memoHandler = (e) => {
    setMemo(e.target.value);
  };
  const editHandler = () => {
    if (showMemoText?.id) {
      const payload = { memo: memo, isClosed: isClosed };
      if (appliedDate) {
        payload["appliedDate"] = appliedDate;
      }
      if (deposition) {
        payload["deposition"] = deposition;
      }
      if (isClosed) {
        payload["closedAt"] = closingDate;
      }
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.MEMOS}/${showMemoText.id}`,
        payload
      )
        .then((res) => {
          onClose();
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            onClose();
            console.log(res);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const depositionHandler = (e) => {
    setDeposition(e?.value);
  };
  useEffect(() => {
    if (!isClosed && closingDate) {
      setClosingDate("");
    }
  }, [isClosed]);
  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          {showMemoText?.isEdit ? (
            <span className="title">Edit Memo</span>
          ) : (
            <span className="title">Memo Description</span>
          )}
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          {showMemoText?.isEdit && (
            <>
              <Grid
                container
                md={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item md={3}>
                  {" "}
                  <FormControlLabel
                    control={
                      <Checkbox checked={isClosed} onChange={handleChange} />
                    }
                    label="Completed"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label="Completed Date"
                    type="date"
                    className="subs-text-field"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={!isClosed ? true : false}
                    onChange={closingDateHandler}
                    value={closingDate}
                    sx={{ mt: 2 }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {showMemoText?.isEdit && (
            <Grid
              container
              md={12}
              spacing={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item md={4}>
                <p style={{ margin: "0px", fontWeight: "lighter" }}>
                  Deposition
                </p>
                <Select
                  options={DEPOSITIONS}
                  placeholder="Select Deposition"
                  onChange={depositionHandler}
                  value={
                    deposition ? { label: deposition, value: deposition } : null
                  }
                />
              </Grid>
              <Grid md={4} item>
                <TextField
                  label="Applied Date"
                  className="subs-text-field"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={appliedDateHandler}
                  value={appliedDate}
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
          )}
          <TextareaAutosize
            minRows={40}
            placeholder="Memo"
            value={memo}
            style={{ width: "70rem", minWidth: "70rem" }}
            onChange={memoHandler}
          />
        </div>
        {showMemoText?.isEdit && (
          <>
            <Button
              variant="contained"
              onClick={editHandler}
              sx={{ mt: 2, float: "right" }}
              disabled={isClosed && !closingDate}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ShowMemo;
