import { IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import './index.scss';

export default function PdfViewer(props) {
  const pdfDocRef = useRef();
  const [numOfPages, setNumOfPages] = useState(null);
  const [fullView, setFullView] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show first page
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ _pdfInfo }) {
    const { numPages = 0 } = _pdfInfo;
    setNumOfPages(numPages);
    setPageNumber(1);
  }

  function zoomIn() {
    if (scale === 1) {
      return;
    }
    setScale(scale > 1 ? scale - 1 : 1);
  }

  function zoomOut() {
    if (scale === 10) {
      return;
    }
    setScale(scale < 10 ? scale + 1 : 10);
  }

  const { pdf, enableOverview, enableFullView } = props;
  const onPageClick = pageNo => {
    setPageNumber(pageNo);
    const { current = {} } = pdfDocRef;
    if (current.pages && current.pages.length > 0) {
      const pageElement = current.pages[pageNo - 1];
      if (pageElement) {
        pageElement.scrollIntoView();
      }
    }
  };

  return (
    <div
      className={
        fullView ? 'pdf-viewer-container pdf-full-view' : 'pdf-viewer-container'
      }
    >
      <div
        className={
          enableOverview ? 'pdf-preview' : ' pdf-preview pdf-no-preview'
        }
      >
        {enableOverview && (
          <div className='pdf-overview'>
            <Document
              className='thumbnail-viewer'
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numOfPages), (el, index) => (
                <Page
                  scale={scale}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className={index + 1 === pageNumber ? 'active-page' : ''}
                  onClick={() => onPageClick(index + 1)}
                />
              ))}
            </Document>
          </div>
        )}
        <div>
          <div className='pdf-document-header'>
            {enableFullView && (
              <IconButton onClick={() => setFullView(!fullView)}>
                {fullView ? <FullscreenIcon /> : <FullscreenExitIcon />}
              </IconButton>
            )}
            <div className='page-number-container'>
              <IconButton
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                <ArrowBackIosNewIcon style={{ color: '#fff' }} />
              </IconButton>
              <span className='page-numbers'>
                {pageNumber} of {numOfPages}
              </span>
              <IconButton
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numOfPages}
              >
                <ArrowForwardIosIcon style={{ color: '#fff' }} />
              </IconButton>
            </div>

            <IconButton onClick={zoomIn}>
              <ZoomOutIcon style={{ color: '#fff' }} />
            </IconButton>
            <IconButton onClick={zoomOut}>
              <ZoomInIcon style={{ color: '#fff' }} />
            </IconButton>
          </div>
          <div className='pdf-document'>
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              ref={pdfDocRef}
            >
              {Array.from(new Array(numOfPages), (el, index) => {
                if (pageNumber === index + 1) {
                  return (
                    <Page
                      scale={scale}
                      key={`page_${index + 1}`}
                      id={`page_${index + 1}`}
                      className={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  );
                }
              })}
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
}
