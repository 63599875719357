import { leadStatusMap, stageGatesMap } from "../../../utils/constants";
import { getActualStageDate } from "../utils";

export const getHeaderConfig = (status) => {
  const config = [
    {
      label: "Lead Id",
      id: "id",
    },
    {
      label: "Created by",
      id: "createdBy",
      render: (data, _, index) => {
        return <div key="createdBy">{data?.createdBy?.name}</div>;
      },
    },
    {
      label: "Region",
      id: "region",
      render: (data, _, index) => {
        return <div key="region">{data?.locationInfo?.region}</div>;
      },
    },
    {
      label: "Catchment",
      id: "catchment",
      render: (data, _, index) => {
        return <div key="catchment">{data?.locationInfo?.catchment}</div>;
      },
    },
    {
      label: "City",
      id: "city",
      render: (data, _, index) => {
        return <div key="city">{data?.locationInfo?.city}</div>;
      },
    },
    {
      label: "State",
      id: "state",
      render: (data, _, index) => {
        return <div key={index}>{data?.locationInfo?.state}</div>;
      },
    },
    {
      label: "Format",
      id: "format",
      render: (data, _, index) => {
        return <div key="format">{data?.locationInfo?.format}</div>;
      },
    },
    {
      label: "Status",
      id: "status",
      render: (data, _, index) => {
        return (
          <div key="status">{data?.locationInfo?.status?.currentStatus}</div>
        );
      },
    },
  ];
  switch (status) {
    case leadStatusMap.lead:
      break;
    case leadStatusMap.cfApproved:
      config.push({
        label: "CF Approval Date",
        id: "cfApprovatedDate",
        render: (data) => {
          return (
            <div key="cfApprovatedDate">
              {getActualStageDate(data?.stageGates, stageGatesMap.cfApproval)}
            </div>
          );
        },
      });
      break;
    case leadStatusMap.opened:
      // Need to add store opened date here
      config.push({
        label: "Store Opened Date",
        id: "opened",
        render: (data) => {
          return (
            <div key="opened">
              {getActualStageDate(data?.stageGates, stageGatesMap.storeOpened)}
            </div>
          );
        },
      });
      break;
    case leadStatusMap.termSheetSigned:
      // Need to add term sheet signed date here
      config.push({
        label: "LOI Signing Date",
        id: "loiSigningDate",
        render: (data) => {
          return (
            <div key="loiSigningDate">
              {getActualStageDate(data?.stageGates, stageGatesMap.loiSigning)}
            </div>
          );
        },
      });
      break;
    case leadStatusMap.underConstruction:
      // Need to add possession date here
      config.push({
        label: "Possession Date",
        id: "possessionDate",
        render: (data) => {
          return (
            <div key="possessionDate">
              {getActualStageDate(
                data?.stageGates,
                stageGatesMap.possessionDate
              )}
            </div>
          );
        },
      });
      break;

    default:
      break;
  }
  return config;
};
