import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Select from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";

import { invokeApi } from "../../utils/http-service";
import { IconButton, Divider } from "@mui/material";
import { TYPES, REUSEABLE, Assocation } from "./config";
import { toast } from "react-toastify";
import { allRegions } from "../../../common-utilities/utils/region";
import {
  dateFun,
  getLabelsAndValuesForSelect,
} from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const initialData = {
  storeNo: "",
  satelliteNo: "",
  sequenceNo: "",
  leaseId: "",
  type: "",
  date: "",
  dateSentToLandLord: "",
  checkNo: "",
  amount: "",
  securityDeposit: "",
  firstMonthRent: "",
  landLordFranchiseLease: false,
  leaseSettlement: {
    status: "",
    amount: "",
    srecCheckNumber: "",
    backRent: "",
    franchiseSettlement: "",
    reusable: "",
    evictionInd: false,
    settleDate: "",
    srecCheckDate: "",
    backRentChangeDate: "",
    dateFranchisePaid: "",
    reusableByDate: "",
  },
  association: {
    association: "",
    name: "",
    streetPoBox: "",
    address: "",
    city: "",
    county: "",
    state: "",
    country: "",
    postalCode: "",
  },
  dealComplete: "",
};
const LeaseMaintenanceContainer = () => {
  const [data, setData] = useState(initialData);
  const [communicationType, setCommunicationType] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [leaseInput, setLeaseInput] = useState("");
  const [leaseOptions, setLeaseOptions] = useState([]);
  const previousController = useRef();

  const [stateList, setStateList] = useState([]);
  const [communication, setcommunication] = useState({
    type: "",
    value: "",
  });
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };

  useEffect(() => {
    const allCountries = Object.keys(allRegions);
    const countriesSelectList = getLabelsAndValuesForSelect(
      allCountries,
      allCountries
    );
    setCountryList(countriesSelectList);
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_COMMUNICATION_TYPE}`
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele, value: ele }));
        setCommunicationType(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const loadDataHandler = (id) => {
    if (id) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_LEASE_MAINTENANCE}${id}`
      )
        .then((res) => {
          if (res.message) {
            // toast.error(res.message, { autoClose: 2000 });
            setData(initialData);
            setLeaseInput("");
            setcommunication({
              type: "",
              value: "",
            });
          } else {
            setData((prevVal) => ({
              ...prevVal,
              storeNo: res?.store?.storeNo,
              satelliteNo: res?.store?.satelliteNum,
              sequenceNo: res?.store?.sequenceNo,
              type: res?.leaseMaintenance?.type,
              date: res?.leaseMaintenance?.date?.split("T")[0],
              dateSentToLandLord:
                res?.leaseMaintenance?.dateSentToLandLord?.split("T")[0],
              checkNo: res?.leaseMaintenance?.checkNo,
              amount: res?.leaseMaintenance?.amount,
              securityDeposit: res?.leaseMaintenance?.securityDeposit,
              firstMonthRent: res?.leaseMaintenance?.firstMonthRent,
              landLordFranchiseLease:
                res?.leaseMaintenance?.landLordFranchiseLease,
              leaseSettlement: {
                status: res?.leaseMaintenance?.leaseSettlement?.status,
                amount: res?.leaseMaintenance?.leaseSettlement?.amount,
                srecCheckNumber:
                  res?.leaseMaintenance?.leaseSettlement?.srecCheckNumber,
                backRent: res?.leaseMaintenance?.leaseSettlement?.backRent,
                franchiseSettlement:
                  res?.leaseMaintenance?.leaseSettlement?.franchiseSettlement,
                reusable: res?.leaseMaintenance?.leaseSettlement?.reusable,
                evictionInd:
                  res?.leaseMaintenance?.leaseSettlement?.evictionInd,
                settleDate:
                  res?.leaseMaintenance?.leaseSettlement?.settleDate?.split(
                    "T"
                  )[0],
                srecCheckDate:
                  res?.leaseMaintenance?.leaseSettlement?.srecCheckDate?.split(
                    "T"
                  )[0],
                backRentChangeDate:
                  res?.leaseMaintenance?.leaseSettlement?.backRentChangeDate?.split(
                    "T"
                  )[0],
                dateFranchisePaid:
                  res?.leaseMaintenance?.leaseSettlement?.dateFranchisePaid?.split(
                    "T"
                  )[0],
                reusableByDate:
                  res?.leaseMaintenance?.leaseSettlement?.reusableByDate?.split(
                    "T"
                  )[0],
              },
              association: {
                association: res?.leaseMaintenance?.association?.association,
                name: res?.leaseMaintenance?.association?.name,
                streetPoBox: res?.leaseMaintenance?.association?.streetPoBox,
                address: res?.leaseMaintenance?.association?.address,
                city: res?.leaseMaintenance?.association?.city,
                county: res?.leaseMaintenance?.association?.county,
                state: res?.leaseMaintenance?.association?.state,
                country: res?.leaseMaintenance?.association?.country,
                postalCode: res?.leaseMaintenance?.association?.postalCode,
              },
              dealComplete: res?.leaseMaintenance?.dealComplete?.split("T")[0],
            }));
            setcommunication((prevVal) => ({
              ...prevVal,
              type: res?.communication?.communicationType,
              value: res?.communication?.value,
            }));
            setIsDisabled(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const editHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele] !== "" && data[ele] !== undefined && data[ele] !== null) {
        payload[ele] = data[ele];
      }
      if (ele === "leaseSettlement") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["leaseSettlement"] = obj;
      }
      if (ele === "association") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["association"] = obj;
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_LEASE_MAINTENANCE}${data.leaseId}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Updated Successfully", { autoClose: 2000 });
          setData(initialData);
          setLeaseInput("");
          setcommunication({
            type: "",
            value: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const createHandler = () => {
    const payload = {};
    for (let ele in data) {
      if (data[ele]) {
        payload[ele] = data[ele];
      }
      if (ele === "leaseSettlement") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["leaseSettlement"] = obj;
      }
      if (ele === "association") {
        let obj = {};
        for (let item in data[ele]) {
          if (data[ele][item]) {
            obj[item] = data[ele][item];
          }
        }

        payload["association"] = obj;
      }
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_LEASE_MAINTENANCE}${data.leaseId}`,
      payload
    ).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        toast.success("Created Successfully", { autoClose: 2000 });
        setIsCreate(false);
        setData(initialData);
        setLeaseInput("");
        setcommunication({
          type: "",
          value: "",
        });
      }
    });
  };
  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.LEASE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.leaseId, id: p.leaseId };
      });
      setLeaseOptions(updatedOptions);
      setLeaseInput(searchTerm);
    });
  };
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      setData((prevVal) => ({ ...prevVal, leaseId: value }));
    } else {
      setLeaseInput("");
      setData(initialData);
      setIsDisabled(true);
      setLeaseOptions([]);
    }
  };
  return (
    <div className="lease-maintenance-details">
      <div style={displayContainer}>
        <TextField
          label="Store"
          onChange={(e) => {
            setData((prevVal) => ({ ...prevVal, storeNo: e.target.value }));
          }}
          value={data.storeNo}
        />
        <TextField
          label="Satellite"
          value={data.satelliteNo}
          onChange={(e) => {
            setData((prevVal) => ({ ...prevVal, satelliteNo: e.target.value }));
          }}
        />
        <TextField label="Sequence" value={data.sequenceNo} disabled={true} />
        {/* <TextField
          label="LeaseId"
          disabled={isCreate}
          onChange={(e) => {
            setData((prevVal) => ({ ...prevVal, leaseId: e.target.value }));
          }}
          value={data.leaseId}
        /> */}
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          getOptionLabel={(option) => {
            return option.title ? option.title.toString() : "";
          }}
          disabled={isCreate}
          options={leaseOptions}
          onInputChange={onInputChange}
          inputValue={leaseInput}
          sx={{ width: "12em" }}
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                setLeaseInput("");
                setLeaseOptions([]);
                setData(initialData);
              }}
            />
          }
          renderInput={(params) => (
            <TextField {...params} className="sel-item" label="Lease Id" />
          )}
          onChange={(e, val) => {
            if (val) {
              loadDataHandler(val.id);
            }
          }}
        />
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "blue",
          }}
        >
          <div>Pending Leases:0</div>
          <div>Legacy Leases:0</div>
        </div> */}
        <div
          style={{
            display: "flex",
            color: "blue",
            alignItems: "center",
          }}
        >
          {/* Lease Search: */}

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setData(initialData);
              setcommunication({
                type: "",
                value: "",
              });
              setIsCreate(true);
              setLeaseInput("");
            }}
          >
            Create New
          </Button>
        </div>
      </div>
      <Divider style={{ paddingBottom: 10 }} />
      <Grid container spacing={1}>
        <Grid spacing={1} xs={6}>
          <Grid xs={12}>
            <div className="title">Landlord Check Information</div>
          </Grid>

          <Grid container xs={12}>
            <Grid xs={6}>
              <TextField
                label="Date Sent To Landlord"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dateSentToLandLord: e.target.value,
                  }));
                }}
                value={dateFun(data.dateSentToLandLord) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="Check Number"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    checkNo: e.target.value,
                  }));
                }}
                value={data.checkNo}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="Amount"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    amount: e.target.value,
                  }));
                }}
                value={data.amount}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                type="number"
                label="Security Deposit"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    securityDeposit: e.target.value,
                  }));
                }}
                value={data.securityDeposit}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="First Month's Rent"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    firstMonthRent: e.target.value,
                  }));
                }}
                value={data.firstMonthRent}
              />
            </Grid>
            <Grid xs={6}>
              <div style={displayContainer}>
                <span>Landlord Franchise Lease</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      landLordFranchiseLease: !data.landLordFranchiseLease,
                    }));
                  }}
                  checked={data.landLordFranchiseLease}
                />
              </div>
            </Grid>
          </Grid>

          <Divider style={{ paddingBottom: 10 }} />

          <div className="title">Lease Settlements </div>

          <Grid container xs={12}>
            <Grid xs={6}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                options={TYPES}
                placeholder="Settle Status"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      status: e.value,
                    },
                  }));
                }}
                value={
                  data?.leaseSettlement?.status && {
                    label: data.leaseSettlement.status,
                    value: data.leaseSettlement.status,
                  }
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="Settle amount"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      amount: e.target.value,
                    },
                  }));
                }}
                value={data.leaseSettlement.amount}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Settle Date"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      settleDate: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.leaseSettlement.settleDate) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="SREC Check Num"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      srecCheckNumber: e.target.value,
                    },
                  }));
                }}
                value={data.leaseSettlement.srecCheckNumber}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="SREC Check Date"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      srecCheckDate: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.leaseSettlement.srecCheckDate) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="Back Rent"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      backRent: e.target.value,
                    },
                  }));
                }}
                value={data.leaseSettlement.backRent}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Back rent Change"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      backRentChangeDate: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.leaseSettlement.backRentChangeDate) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                type="number"
                label="Franchise Settlement"
                className="w-80"
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      franchiseSettlement: e.target.value,
                    },
                  }));
                }}
                value={data.leaseSettlement.franchiseSettlement}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Date Franchise Paid"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      dateFranchisePaid: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.leaseSettlement.dateFranchisePaid) || ""}
              />
            </Grid>

            <Grid xs={6}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                placeholder="Reusable"
                options={REUSEABLE}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      reusable: e.value,
                    },
                  }));
                }}
                value={
                  data?.leaseSettlement?.reusable && {
                    label: data.leaseSettlement.reusable,
                    value: data.leaseSettlement.reusable,
                  }
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                label="Reusable by Date"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    leaseSettlement: {
                      ...data.leaseSettlement,
                      reusableByDate: e.target.value,
                    },
                  }));
                }}
                value={dateFun(data.leaseSettlement.reusableByDate) || ""}
              />
            </Grid>
            <Grid xs={6}>
              <div style={displayContainer}>
                <span>Eviction Ind:</span>
                <Checkbox
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      leaseSettlement: {
                        ...data.leaseSettlement,
                        evictionInd: !data.leaseSettlement.evictionInd,
                      },
                    }));
                  }}
                  checked={data.leaseSettlement.evictionInd}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid xs={12}>
            <div className="title">Lease Maintenance*</div>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={6}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                placeholder="Type"
                options={TYPES}
                onChange={(e) => {
                  setData((prevVal) => ({ ...prevVal, type: e.value }));
                }}
                value={data?.type && { label: data.type, value: data.type }}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                label="Date"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({ ...prevVal, date: e.target.value }));
                }}
                value={dateFun(data.date) || ""}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="title">Assocation Details</div>
            </Grid>
            <Grid item xs={6}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                placeholder="Association"
                options={Assocation}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      association: e.value,
                    },
                  }));
                }}
                value={
                  data?.association?.association && {
                    label: data.association.association,
                    value: data.association.association,
                  }
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Name"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      name: e.target.value,
                    },
                  }));
                }}
                value={data.association.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Street/PO Box"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      streetPoBox: e.target.value,
                    },
                  }));
                }}
                value={data.association.streetPoBox}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Add'l Address"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      address: e.target.value,
                    },
                  }));
                }}
                value={data.association.address}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      city: e.target.value,
                    },
                  }));
                }}
                value={data.association.city}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="County"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      county: e.target.value,
                    },
                  }));
                }}
                value={data.association.county}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                className="w-80"
                classNamePrefix="custom_select"
                placeholder="Country"
                options={countryList}
                onChange={(e) => {
                  const states = allRegions[e?.value] || {};
                  if (states) {
                    const allStates = Object.keys(states);
                    const stateValues = getLabelsAndValuesForSelect(
                      allStates,
                      allStates
                    );
                    setStateList(stateValues);
                  } else {
                    setStateList([]);
                  }
                  const association = { ...data.association };
                  delete association.state;
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...association,
                      country: e?.value,
                    },
                  }));
                }}
                value={
                  data?.association?.country && {
                    label: data?.association?.country,
                    value: data?.association?.country,
                  }
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                className="w-80"
                classNamePrefix="custom_select"
                placeholder="State/Prov"
                options={stateList}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      state: e?.value,
                    },
                  }));
                }}
                value={
                  data?.association?.state
                    ? {
                        label: data?.association?.state,
                        value: data?.association?.state,
                      }
                    : null
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Postal Code"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    association: {
                      ...data.association,
                      postalCode: e.target.value,
                    },
                  }));
                }}
                value={data.association.postalCode}
              />
            </Grid>
          </Grid>
          <Divider style={{ paddingBottom: 10 }} />

          <Grid xs={12}>
            <div className="title">Communication Details</div>
          </Grid>
          <div className="g-r-2">
            <Grid xs={12}>
              <Select
                classNamePrefix="custom_select"
                className="w-80"
                options={communicationType}
                placeholder="Comm Type"
                onChange={(e) => {
                  setcommunication((prevVal) => ({
                    ...prevVal,
                    type: e.value,
                  }));
                }}
                value={
                  communication?.type && {
                    label: communication.type,
                    value: communication.type,
                  }
                }
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                label="Communication"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setcommunication((prevVal) => ({
                    ...prevVal,
                    value: e.target.value,
                  }));
                }}
                value={communication.value}
              />
            </Grid>
          </div>

          {/* <Grid xs={3}>
              <div style={displayContainer}>
                <TextField
                  label="Ext"
                  className="w-80"
                  InputLabelProps={{ shrink: true }}
                  
                  onChange={(e) => {
                    setData((prevVal) => ({
                      ...prevVal,
                      date: e.target.value,
                    }));
                  }}
                  value={data.date}
                />
                <IconButton>
                  <FindInPageIcon color="primary" size="large" fontSize="60" />
                </IconButton>
              </div>
            </Grid> */}
        </Grid>
        <Divider style={{ paddingBottom: 10 }} />
        <Grid spacing={1} container md={12}>
          <Grid md={3}>
            <div className="w-100" style={displayContainer}>
              <TextField
                label="Deal Complete"
                type="date"
                className="w-80"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setData((prevVal) => ({
                    ...prevVal,
                    dealComplete: e.target.value,
                  }));
                }}
                value={dateFun(data.dealComplete) || ""}
              />
            </div>
          </Grid>
          <Grid md={6.5}></Grid>
          <Grid md={2}>
            {isCreate && (
              <div>
                <Button
                  size="small"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  sx={{ float: "right" }}
                  disabled={data.storeNo == "" || data.satelliteNo == ""}
                  onClick={createHandler}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ float: "right", mr: 3 }}
                  onClick={() => {
                    setData(initialData);
                    setLeaseInput("");
                    setcommunication({
                      type: "",
                      value: "",
                    });
                    setIsCreate(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {!isCreate && (
              <Button
                size="small"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{ float: "right" }}
                disabled={isDisabled || data.leaseId == ""}
                onClick={editHandler}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaseMaintenanceContainer;
