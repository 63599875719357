import { tokenMainKeys } from "../../../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { userTypes } from "../../utils/constants";
import { technetEndPoint } from "../../../common-utilities/utils/end-points/tech-net";

export const SIDE_MENU = () => {
  const user = getOfflineData(tokenMainKeys.technet, "user");
  let menuOptions = [
    {
      label: `Dashboard`,
      url: `${technetEndPoint}`,
      value: `${technetEndPoint}`,
      logo: <DashboardIcon sx={{ mr: 1 }} />,
    },
    {
      label: `Tickets`,
      url: `${technetEndPoint}/tickets`,
      value: `${technetEndPoint}/tickets`,
      logo: <ConfirmationNumberIcon sx={{ mr: 1 }} />,
    },
  ];

  if (user && user.type === userTypes.ADMIN) {
    menuOptions.push({
      label: "Setup",
      url: `${technetEndPoint}/setup`,
      value: `${technetEndPoint}/setup`,
      logo: <SettingsIcon sx={{ mr: 1 }} />,
    });
  }
  if (user && user.type === userTypes.ADMIN) {
    menuOptions.push({
      label: "Inward sales",
      url: `${technetEndPoint}/sales`,
      value: `${technetEndPoint}/sales`,
      logo: <ReceiptIcon sx={{ mr: 1 }} />,
    });
  }
  if (user && user.type !== userTypes.STORE_OWNER) {
    menuOptions = [
      ...menuOptions,
      {
        label: "Reports",
        url: `${technetEndPoint}/reports`,
        value: `${technetEndPoint}/reports`,
        logo: <SummarizeIcon sx={{ mr: 1 }} />,
      },
    ];
  }
  return menuOptions;
};
