import SideMenu from "../core/side-menu";
import "./index.scss";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/Menu";
import { IconButton, styled } from "@mui/material";
import { BellCorpStudioLogoContainer } from "../components/Bellcorp-Studio-Logo";
import Header from "../core/header";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys, appSlugs } from "../../../utils/constants";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../routes/endpoints";
import { useLocation } from "react-router-dom";
const MainContainer = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
  children,
}) => {
  const location = useLocation();
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const [open, setOpen] = useState(window.innerWidth > 480);
  const MyMenuIcon = styled(open ? MenuOpenIcon : MenuIcon)({
    color: "white",
  });
  const vendoInvoiceSubmissionEndPoint = getProjectEndPoint(
    endPointsForProject.vendorInvoiceSubmission
  );

  return (
    <>
      <div className="main-container">
        <IntranetHeader />
        <div className="side-nav-header">
          <HomeRedirect localstorageKey={token} />
          <h4>
            {location.pathname.startsWith(
              `/${vendoInvoiceSubmissionEndPoint}`
            ) && "Vendor"}{" "}
            Invoice Submission
          </h4>
        </div>

        <div className="main-right-container">
          <Header token={token} slug={slug} />
          <div className="content-div">
            <div>{children}</div>
            <BellCorpStudioLogoContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
