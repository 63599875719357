import { tokenMainKeys } from "../../../utils/constants";
import { getValue } from "../../common-utilities/utils";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { USERROLES } from "./constants";

// When we get response from api we process it
export const processRestaurantDetailsForDropdown = (restaurantData) => {
  const results =
    restaurantData?.map((restaurantDetails) => {
      const { satelliteNumber, sequenceNumber, storeNumber, ownerName, id } =
        restaurantDetails;

      return {
        label: `${storeNumber}-${sequenceNumber}-${satelliteNumber}-${getValue(ownerName, true)}`,
        value: id,
      };
    }) || [];
  return results;
};

//When we get data related to intranet store by batch api we set body here
export const setGetStoreInBatchParams = (body) => {
  const { storeType } = body;
  if (storeType) {
    body.storeType = storeType.split(" ").join("_");
  }
  const user = getOfflineData(tokenMainKeys.docuStorage, "user")
  
  if (user?.role === USERROLES.user) {
    body.checkRm = true
  }
};
