import "./index.scss";
import { BellCorpStudioLogoContainer } from "../../common-utilities/Bellcorp-Studio-Logo";
import BasicHeader from "../../common-utilities/core/basic-header";
import { getOfflineData } from "../utils/offline-services";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys } from "../../../utils/constants";

const MainContainer = ({ children }) => {
  const user = getOfflineData("user");

  return (
    <>
      <div className="main-container">
        <IntranetHeader />
        <div className="side-nav-header">
          <HomeRedirect localstorageKey={tokenMainKeys.subwayFinance} />

          <h5 className="app-name" style={{ marginLeft: 10 }}>
            Subway Finance - Sub Ka Pulse
          </h5>
        </div>

        <div className="main-right-container">
          <BasicHeader userDetails={getOfflineData("user")} />
          <div className="content-div">
            <div>{children}</div>
            <BellCorpStudioLogoContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
