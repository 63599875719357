import React, { useEffect, useState } from "react";
import { invokeApi } from "../../utils/http-service";
import { Button, IconButton, TextField } from "@mui/material";
import Select from "react-select";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { validAddressTypes } from "../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import {
  HOSTNAME,
  REST_URLS,
} from "../../../common-utilities/utils/end-points";

const AddCommunication = ({
  communicationTypes,
  details = {},
  entityId,
  onClose,
  onSave,
  isPersonData,
  communicationType = [],
  personNum,
}) => {
  const [address, setAddress] = useState({});
  const [communicationList, setCommunicationList] = useState([]);
  const [addCommunicationToPerson, setAddCommunicationToPerson] =
    useState(false);

  useEffect(() => {
    setCommunicationList(details || []);
  }, [details]);
  useEffect(() => {
    for (let ele in details) {
      if (
        details[ele] === undefined ||
        details[ele] === null ||
        (details[ele] === "" && isPersonData)
      ) {
        if (personNum !== "") {
          setAddCommunicationToPerson(true);
        }
      } else {
        setAddCommunicationToPerson(false);
        return;
      }
    }
  }, []);
  const onChange = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };
  const addCommunicationHandler = () => {
    let communicationPayload = {};
    for (let ele in address) {
      if (address["id"]) {
        delete address["id"];
      }
      if (address[ele]) {
        communicationPayload[ele] = address[ele];
      }
    }
    if (Object.keys(communicationPayload).length !== 0) {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION}`,
        {
          type: validAddressTypes.person,
          entityId: personNum,
          ...communicationPayload,
        }
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            onSave(address);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const updateRecord = () => {
    let payload = {
      type: validAddressTypes.person,
      communicationType: address.communicationType,
      value: address.value,
      entityId: personNum,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_COMMUNICATION}${address.id}`,
      payload
    ).then((response) => {
      if (response.code) {
        toast.error("Failed to add address");
      } else {
        onSave(address);
      }

      setAddCommunicationToPerson(false);
      setAddress({});
    });
  };
  const saveRecord = () => {
    let payload = {
      type: validAddressTypes.person,
      communicationType: address.communicationType,
      value: address.value,
      entityId: personNum,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add communication");
        } else {
          onSave(address);
        }
        setAddress({});
        setAddCommunicationToPerson(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Communication Details </span>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <div className="f-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Type"
              options={communicationType}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              // value={
              //   address?.communicationType && {
              //     label: address.communicationType,
              //     value: address.communicationType,
              //   }
              // }
              onChange={(e) => {
                let addValue = communicationList.filter((ele) => {
                  return ele.communicationType === e.value;
                });
                if (addValue.length > 0) {
                  setAddCommunicationToPerson(false);
                  setAddress(addValue[0]);
                } else {
                  setAddress({});
                  setAddCommunicationToPerson(true);
                  setAddress((prevVal) => ({
                    ...prevVal,
                    communicationType: e.value,
                  }));
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="Details"
              value={address.value || ""}
              name="value"
              className="subs-text-field"
              fullWidth
              onChange={onChange}
            />
          </div>
          <Button
            size="small"
            fullWidth
            disabled={!personNum ? true : false}
            variant="contained"
            onClick={() =>
              !addCommunicationToPerson ? updateRecord() : saveRecord()
            }
          >
            Save
          </Button>
          {/* {!addCommunicationToPerson && (
            <div>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={() => onSave(address)}
              >
                Save
              </Button>
            </div>
          )}

          {addCommunicationToPerson && (
            <div>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={addCommunicationHandler}
              >
                Save
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
export default AddCommunication;
