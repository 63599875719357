import {
  accquiredPreAgreementDocsLabelsAndValues,
  agreementAndPostAgreementDocsLabelsAndValues,
} from "../../../utils/coco-acquired.config";
import {
  newNames,
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import { validCocoAcquiredLicenses } from "../../../utils/coco-acquired.config";

export const preAgreementDocsSelectList = () => {
  const list = Object.values(accquiredPreAgreementDocsLabelsAndValues);

  return list.map((item) => {
    let label;

    switch (item) {
      case accquiredPreAgreementDocsLabelsAndValues.projectFeasibility:
        label = newNames.tearSheet;
        break;
      case accquiredPreAgreementDocsLabelsAndValues.licenseFeasibility:
        label = newNames.taxReport;
        break;
      default:
        label = item;
        break;
    }

    const value = item;
    return { label, value };
  });
};

export const getPreAgreementDocsDateLabel = (value) => {
  switch (value) {
    case accquiredPreAgreementDocsLabelsAndValues.LOI:
      return "LOI Signing Date";
    case accquiredPreAgreementDocsLabelsAndValues.titleDeligenceReport:
      return "Report Date";
    case accquiredPreAgreementDocsLabelsAndValues.licenseFeasibility:
      return "Report Date";
    case accquiredPreAgreementDocsLabelsAndValues.possessionLetter:
      return "Possession Handover Date";
    default:
      return "Document Date";
  }
};

export const showCocoAcquiredLicenseTypeBoxInForm = (
  formDetails,
  storeType
) => {
  let showCocoAcquiredLicenseTypeBox = true;

  if (
    storeType === validStoreTypes.cocoAcquired &&
    formDetails?.type?.value === validDocumentTypes.license &&
    formDetails?.licenseType?.value === validCocoAcquiredLicenses.leaseRider
  ) {
    showCocoAcquiredLicenseTypeBox = false;
    delete formDetails.notApplicable;
  }
  return showCocoAcquiredLicenseTypeBox;
};

export const getAgreementAndPostAgreementDocsDateLabel = (value) => {
  switch (value) {
    case agreementAndPostAgreementDocsLabelsAndValues.registration:
      return "Registration Date";
    case agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy:
      return "Date Of Submission";
    default:
      return "Document Date";
  }
};
