export const formatOptions = [
  { label: "High street", value: "High street" },
  { label: "Food Court ", value: "Food Court " },
  { label: "Enclosed Mall", value: "Enclosed Mall" },
  { label: "Highway", value: "Highway" },
  { label: "Institution", value: "Institution" },
  { label: "IT Park", value: "IT Park" },
  { label: "Delco", value: "Delco" },
];
export const stageGateStatuses = [
  { label: "Yet to begin", value: "Yet to begin" },
  { label: "In process", value: "In process" },
  { label: "Completed", value: "Completed" },
];
export const currentStatusOptions = [
  { label: "Lead", value: "Lead" },
  { label: "Site Evaluation", value: "Site Evaluation" },
  { label: "Commercial Discussion", value: "Commercial Discussion" },
  { label: "Term Sheet Discussion", value: "Term Sheet Discussion" },
  { label: "Term Sheet Signed", value: "Term Sheet Signed" },
  { label: "Under construction", value: "Under construction" },
  { label: "Store opened", value: "Store opened" },
];

export { mainDataTableConfig } from "./main-table.config";

export { competitorInformationConfig } from "./competitor-info.config";

export { stageGateConfig } from "./stage-gates.config";

export { nearbySubwayDataConfig } from "./nearby-subway.config";

export { statusInformationConfig } from "./status-information.config";
