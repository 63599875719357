import React from "react";
import Box from "@mui/material/Box";
import DetailedReport from "./detailed-report";

const Reports = ({ tokenKey }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <DetailedReport intranetTokenKey={tokenKey} />
    </Box>
  );
};

export default Reports;
