import React from "react";
import IntranetHeader from "../../../../core/header";

const MainIntranetHeader = () => {
  return (
    <div>
      <IntranetHeader />
    </div>
  );
};

export default MainIntranetHeader;
