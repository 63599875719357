import SideMenu from "../core/side-menu";
// import Header from "../core/header";
import "./index.scss";
import { SIDE_MENU } from "../core/side-menu/config";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tokenMainKeys } from "../../../utils/constants";
import { BellCorpStudioLogoContainer } from "../../common-utilities/Bellcorp-Studio-Logo";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import OtherAppSideMenu from "../../common-utilities/core/other-app-side-menu";

const MainContainer = ({ children }) => {
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = getOfflineData(tokenMainKeys.technet, "user");
    const { pathname } = location;

    if (user) {
      const { type } = user;
      if (type === "STORE_OWNER") {
        // const validPathForStoreUser = pathname === '/tickets' || pathname === '/create' || pathname.includes('details')
        const invalidPathForUser = pathname.includes("report");
        changeviewSettingsForSideMenu(false);
        if (invalidPathForUser) {
          navigate("/tickets");
        }
      } else {
        changeviewSettingsForSideMenu(true);
        if (pathname === "create") {
          navigate("/tickets");
        }
      }
    }
  }, [location, navigate]);

  return (
    <>
      <div className="subway-app-main-container">
        <div className="subway-app-main-header">
          <IntranetHeader />
        </div>
        <div className="subway-app-current-app-container">
          <div className="main-right-container">
            <OtherAppSideMenu
              appName="Service Request"
              SideNavCom={SideMenu}
              keyName={tokenMainKeys.technet}
            />
          </div>
          <div className="main-left-container">
            <div className="content-div">
              <div>{children}</div>
              <BellCorpStudioLogoContainer />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="si-hide-web mobile-header-container">
        <Header />
      </div>
      <div className="main-container">
        <div className="main-left-container si-hide-mobile">
          <SideMenu
            showReportIcon={showSideMenu}
            options={SIDE_MENU()}
            onMenuCLick={() => {}}
          />
        </div>

        <div className="main-right-container">
          <div>{children}</div>
          <BellCorpStudioLogoContainer />
        </div>
      </div> */}
    </>
  );
};

export default MainContainer;
