/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import "./index.scss";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";
import { redirectToRoute } from "../../../common-utilities/utils/end-points/docu-storage";

export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(redirectToRoute);
  React.useEffect(() => {
    setActiveMenu(
      SIDE_MENU().find((m) => m.url === window.location.pathname) || {}
    );
  }, [SIDE_MENU, window.location.pathname]);
  const onMenuClick = () => {};
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      <List>
        <div className="side-menu-header">
          <img
            src={require("../../../common-utilities/resources/logo.png")}
            width="80%"
          />
          <IconButton onClick={() => setOpen(!open)}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
        <div className="menu-items">
          {SIDE_MENU()?.map((menu, index) => {
            let showMenuOption = true;
            return (
              <>
                {
                  <div
                    key={menu.mapkey}
                    className={
                      menu.value === activeMenu.value
                        ? "menu-item menu-item-active"
                        : "menu-item"
                    }
                    onClick={() => {
                      onMenuClick();
                      setActiveMenu(menu);
                      navigate(menu.url);
                    }}
                  >
                    <div className="menu-name align-center">
                      <span>{menu.logo}</span>
                      <span className="sidebarmenulabel">{menu.label}</span>
                    </div>
                  </div>
                }
              </>
            );
          })}
        </div>
      </List>
    </Box>
  );
  return (
    <div>
      <div className="side-menu-v2-items">
        {SIDE_MENU().map((menu, index) => {
          let showMenuOption = true;
          return (
            <>
              {
                <div
                  key={menu.mapkey}
                  className={
                    menu.value === activeMenu.value
                      ? "menu-item menu-item-active"
                      : "menu-item"
                  }
                  onClick={() => {
                    onMenuClick();
                    setActiveMenu(menu);
                    navigate(menu.url);
                  }}
                >
                  <div className="menu-name align-center">
                    <span>{menu.logo}</span>
                    <span className="side-bar-menu-label">{menu.label}</span>
                  </div>
                </div>
              }
            </>
          );
        })}
      </div>
      {/* <IconButton onClick={() => setOpen(!open)}>
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {list("left")}
      </Drawer> */}
    </div>
  );
}
