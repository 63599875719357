export const getHeaderConfig = () => {
  return [
    {
      label: "Customer No.",
      id: "customerNum",
    },
    {
      label: "Entity",
      id: "entity",
    },
    {
      label: "Week Ending Date",
      id: "weekEndingDate",
    },
    {
      label: "Type",
      id: "type",
    },
    {
      label: "Net Sales",
      id: "netSales",
    },
    {
      label: "RY Fee",
      id: "ryFee",
    },
    {
      label: "FAF Fee",
      id: "fafFees",
    },
    {
      label: "Additional Funds",
      id: "additionalFunds",
    },
    {
      label: "Corrections",
      id: "corrections",
    },
    {
      label: "FAF Finance",
      id: "fafFinance",
    },
    {
      label: "RY Finance",
      id: "ryFinance",
    },
    {
      label: "WH Taxes",
      id: "whTaxes",
    },
    {
      label: "VAT Taxes",
      id: "vatTaxes",
    },
    {
      label: "Other Taxes",
      id: "otherTaxes",
    },
    {
      label: "Cash Receipts",
      id: "cashReceipt",
    },
    {
      label: "Invoices",
      id: "invoices",
    },
    {
      label: "Bad Checks",
      id: "badChecks",
    },
    {
      label: "End Balance",
      id: "endBalance",
    },
  ];
};
