import {
  getAllExcelColumns,
  processExcelDates,
} from "../../../common-utilities/utils/excel";
import { getLabelsAndValuesForSelect } from "../../utils";
import { cocoAcqKeyNames, cocoBuiltKeyNames } from "../../utils/constants";
import { allRegions } from "../../utils/regions";
import { excelDateColumns } from "./config";

export const getStateList = (country, convertToSelectValues = true) => {
  if (!country || !allRegions[country]) {
    return [];
  }
  const countryDataWithStateKeys = allRegions[country];
  let states = Object.keys(countryDataWithStateKeys);
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(states, states);
  }
  return states;
};

export const getCityListByStateAndCountry = (
  country,
  state,
  convertToSelectValues = true
) => {
  if (!allRegions?.[country]?.[state]) {
    return [];
  }
  const cityValues = allRegions?.[country]?.[state];
  if (convertToSelectValues) {
    return getLabelsAndValuesForSelect(cityValues, cityValues);
  }
  return cityValues;
};

export const processExcelReport = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateColumns = headers.reduce((columnsList, header, index) => {
    const column = columns[index];
    if (excelDateColumns.includes(header)) {
      columnsList.push(column);
    }
    return columnsList;
  }, []);

  const convertedWorkSheet = processExcelDates(data, worksheet, dateColumns);
  workbook.Sheets[sheetName] = convertedWorkSheet;
  workbook.SheetNames.push(sheetName);
};

export const decimalXValidation = (value) => {
  if (!value || typeof value !== "string") {
    return false;
  }
  const validation = /^[0-9]+(\.[0-9]+)?x$/;
  return validation.test(value);
};

export const businessDetailsValidation = (isCocoAcquired, documentData) => {
  const keys = isCocoAcquired
    ? { ...cocoAcqKeyNames }
    : { ...cocoBuiltKeyNames };
  const businessDetails = documentData?.businessDetails || {};
  const {
    adsTtm,
    ttmEbitdaPercentage,
    ttmAdjustedEbitdaMultiple,
    forward1YearEbitdaMultiple,
    grossOfferExtendedA,
  } = businessDetails;
  if (!isCocoAcquired) {
    return {};
  }

  return {
    error:
      !adsTtm ||
      !ttmEbitdaPercentage ||
      !decimalXValidation(ttmAdjustedEbitdaMultiple) ||
      !decimalXValidation(forward1YearEbitdaMultiple) ||
      !grossOfferExtendedA,
    message:
      ttmAdjustedEbitdaMultiple &&
      !decimalXValidation(ttmAdjustedEbitdaMultiple)
        ? `${keys.ttmAdjustedEbitdaMultiple} is not of proper format`
        : forward1YearEbitdaMultiple &&
          !decimalXValidation(forward1YearEbitdaMultiple)
        ? `${keys.forward1YearEbitdaMultiple} is not of proper format`
        : null,
  };
};
