import { profitAndLossSelectValues, ttmSelectValues } from ".";

export const storeDetails = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Format",
    value: "format",
  },
  {
    label: "Lease Type",
    value: "leaseType",
  },
  {
    label: "Lease Start Date",
    value: "leaseStartDate",
  },
  {
    label: "Lease End Date",
    value: "leaseEndDate",
  },
  {
    label: "Carpet Area - Sq ft",
    value: "carpetAreaSqft",
  },
];

export const investmentOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: "Gross Offer extended -A",
    value: "grossOfferExtendedA",
  },
  {
    label: "Remodel adj to Franchisee",
    value: "remodelAdjToFranchisee",
  },
  {
    label: "Net offer extended",
    value: "netOfferExtended",
  },
  {
    label: "EIPL Remodel Adj Cost",
    value: "eiplRemodelAdjCost",
  },
  {
    label: "LCM fees incl Legal",
    value: "lcmFeesInclLegal",
  },
  {
    label: "Title DD",
    value: "titleDD",
  },
  {
    label: "Valuation Fees",
    value: "valuationFees",
  },
  {
    label: "Stamp Duty & Rgstn Cost",
    value: "stampDutyRgstnCost",
  },
  {
    label: "Transfer fee",
    value: "transferFee",
  },
  {
    label: "Change Licensing costs",
    value: "changeLicensingCosts",
  },
  {
    label: "TOTAL OTHER COST",
    value: "totalOtherCost",
  },
  {
    label: "Total Acquisition Cost",
    value: "totalAcquisitionCost",
  },
];

export const profitAndLossOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: profitAndLossSelectValues.profitAndLossAll,
    value: profitAndLossSelectValues.profitAndLossAll,
  },
  {
    label: profitAndLossSelectValues.year1ProfitAndLoss,
    value: profitAndLossSelectValues.year1ProfitAndLoss,
  },
  {
    label: profitAndLossSelectValues.paybackYears,
    value: profitAndLossSelectValues.paybackYears,
  },
  {
    label: profitAndLossSelectValues.irr5years,
    value: profitAndLossSelectValues.irr5years,
  },
];

export const occupanyYear1Options = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: "MG Yr1 permonth",
    value: "mgYr1Permonth",
  },
  {
    label: "RS Dine In Yr1 %",
    value: "rsDineInYr1Percentage",
  },
  {
    label: "RS Del In Yr1 %",
    value: "rsDelInYr1Percentage",
  },
  {
    label: "CAM Yr1 pm in Rs",
    value: "camYr1PmInRs",
  },
  {
    label: "Other Property charges Yr1 pm",
    value: "otherPropertyChargesYr1Pm",
  },
];

export const ttmOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "All",
    value: "All",
  },
  {
    label: ttmSelectValues.allTTMVsF1Y,
    value: ttmSelectValues.allTTMVsF1Y,
  },
];
