import { useState } from "react";
import AutocompleteComponent from "../../../../common-utilities/core/styled-autocomplete";
import { allStores, storeList } from "../../../utils/constants";
import { regionsList } from "../../../utils/label";
import { getCityListByStateAndCountry } from "../../document/utils";
import { getStoreNumberList } from "../apis";
import {
  clearProperty,
  deleteObjectProperties,
} from "../../../../common-utilities/utils/misc";
import { setDashboardFltrs } from "../utils/filters.utils";

const DashboardFilters = ({
  filters,
  setFilters,
  selectVals,
  setSelectVals,
}) => {
  const [storeOptions, setStoreOptions] = useState([]);
  const setAutoCompleteVals = (e, val) => {
    const { name } = e.target;
    switch (name) {
      case "state":
        {
          const cities = getCityListByStateAndCountry("India", val, false);
          setSelectVals((preVVal) => ({ ...preVVal, city: cities }));
        }
        break;
      default:
        break;
    }
    setFilters((prevFilters) => {
      setDashboardFltrs(name, prevFilters, val);

      return {
        ...prevFilters,
        [name]: val,
      };
    });
  };

  const inputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value, setStoreOptions);
      setFilters((prevFilters) => ({ ...prevFilters, restaurantId: value }));
      return;
    }
    setStoreOptions([]);
    clearProperty("restaurantId", setFilters);
  };

  return (
    <div className="filters-1">
      <AutocompleteComponent
        disableClear={true}
        options={["All", ...regionsList]}
        name="cluster"
        onChange={setAutoCompleteVals}
        label="Select Region*"
        value={filters.cluster || null}
      />
      <AutocompleteComponent
        label="Select Type*"
        options={allStores}
        onChange={setAutoCompleteVals}
        name="storeType"
        disableClear={true}
        value={filters.storeType || null}
      />

      <AutocompleteComponent
        label="Select State"
        options={selectVals.state}
        onChange={setAutoCompleteVals}
        name="state"
        value={filters.state || null}
      />

      <AutocompleteComponent
        label="Select City"
        options={selectVals.city}
        onChange={setAutoCompleteVals}
        name="city"
        value={filters.city || null}
      />
      <AutocompleteComponent
        label="Search Restaurant Id"
        onInputChange={inputChange}
        noValue={true}
        options={storeOptions}
      />
    </div>
  );
};

export default DashboardFilters;
