import { Button } from "@mui/material";
import {
  getInitialBudgetCost,
  getModifiedBudgetCost,
  getAvailableBudgetCost,
} from "../helper";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import { Checkbox } from "@mui/material";

export const getHeaderConfig = (
  editVendor,
  handleRowSelection,
  selectedBudgetList,
  dataList
) => {
  const headers = [
    {
      id: "checkbox",
      label: (
        <Checkbox
          onChange={(event) => handleRowSelection(event, null, "selectAll")}
          checked={
            selectedBudgetList.length === dataList.length &&
            dataList.length !== 0
          }
        />
      ),
      sortable: false,
      render: (data, index) => {
        return (
          <Checkbox
            checked={
              selectedBudgetList.find((ele) => ele.id === data.id)
                ? true
                : false
            }
            onChange={(event) => handleRowSelection(event, data)}
          />
        );
      },
    },
    {
      label: "Budget Id",
      id: "document.budgetId.id",
    },
    {
      label: "Site Code",
      id: "site.code",
    },
    {
      label: "Site Name",
      id: "site.name",
    },
    {
      label: "Budget Type",
      id: "budgetType",
      render: (data) => {
        const { document = {} } = data;
        const { budgetId = {} } = document || {};
        return convertToCamelCaseFromUnderScore(budgetId.budgetType);
      },
    },
    {
      label: "Initial Budget",
      id: "initial",
      render: (data) => {
        const { document = {} } = data;
        const { budgetId = {} } = document || {};
        const { lineItems = [] } = budgetId || {};
        return getInitialBudgetCost(lineItems);
      },
    },
    {
      label: "Modified",
      id: "modified",
      render: (data) => {
        const { document = {} } = data;
        const { budgetId = {} } = document || {};
        const { lineItems = [] } = budgetId || {};
        return getModifiedBudgetCost(lineItems);
      },
    },
    {
      label: "Available Budget",
      id: "available",
      render: (data) => {
        const { document = {} } = data;
        const { budgetId = {} } = document || {};
        const { lineItems = [] } = budgetId || {};
        return getAvailableBudgetCost(lineItems);
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        const { status } = data;
        return (
          <Button variant="text" onClick={() => editVendor(data, "VIEW")}>
            View
          </Button>
        );
      },
    },
  ];
  return headers;
};
