import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function CustomRadioGroup({ title, value, onChange, options }) {
  return (
    <FormControl>
      <FormLabel>{title}</FormLabel>
      <RadioGroup row value={value} onChange={onChange}>
        {options.map((option) => {
          return (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
              disabled={option.disabled || false}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
