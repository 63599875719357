import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { REPORT_TABS } from "./helper";
import TicketReports from "./tickets";
import Stores from "./stores";
import SLA from "./Sla";
import SupportPerson from "./SupportPerson";
import TatReports from "./tat";
import AgentProductivity from "./agent-productivity";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const TechNetReports = ({ tokenKey }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {REPORT_TABS.map((tab) => {
            return <Tab label={tab} />;
          })}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <TicketReports intranetTokenKey={tokenKey} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SupportPerson intranetTokenKey={tokenKey} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SLA intranetTokenKey={tokenKey} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Stores intranetTokenKey={tokenKey} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TatReports intranetTokenKey={tokenKey} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AgentProductivity intranetTokenKey={tokenKey} />
      </TabPanel>
    </Box>
  );
};

export default TechNetReports;
