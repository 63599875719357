import "./index.scss";
import { getHeaderConfig } from "./config";
import MaterialUITable from "../../../common-utilities/core/material-ui-table";
import MuiTable from "../../../common-utilities/core/mui-table";

const ManagerUsersTable = ({
  filters,
  users,
  loadData,
  userRoles,
  setFilters,
  getData,
}) => {
  return (
    <>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={users.results || []}
        filters={filters}
        pageCount={users.totalPages}
        onClick={loadData}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          getData(
            {
              ...filters,
              page,
            },
            true,
            0
          );
        }}
      ></MuiTable>
      {/* <MaterialUITable
        columnsList={getHeaderConfig()}
        dataList={users.results || []}
        pageCount={users.totalPages}
        filters={filters}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          getData(
            {
              ...filters,
              page,
            },
            true,
            0
          );
        }}
        onClick={loadData}
      /> */}
    </>
  );
};

export default ManagerUsersTable;
