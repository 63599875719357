import { ArrowBack } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './index.scss';
import {
  DEFAULT_VENDOR_FORM_EMAIL_TEMPLATE,
  DEFAULT_VENDOR_FORM_SUBJECT,
  isFormValid
} from './helper';
import { HTTP_METHODS, invokeApi } from '../../utils/http-service';
import {
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME
} from '../../../common-utilities/utils/end-points';
import { VENDOR_ONBOARD_REST_URLS } from '../../../common-utilities/utils/end-points/vendor-onboard';
import { toast } from 'react-toastify';
import { getOfflineData } from '../../utils/offline-services';

const VendorRequest = () => {
  const user = getOfflineData('user') || {};
  const navigate = useNavigate();
  const [emailBody, setEmailBody] = useState(
    DEFAULT_VENDOR_FORM_EMAIL_TEMPLATE
  );
  const [formDetails, setFormDetails] = useState({
    subject: DEFAULT_VENDOR_FORM_SUBJECT
  });
  const [departments, setDepartments] = useState([]);

  useEffect(() => {    
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${VENDOR_ONBOARD_REST_URLS.GET_DEPARTMENTS}`
    )
      .then(response => {
        if (response && response.length > 0) {
          const formatted = response.map(d => {
            return { label: d.name, value: d._id };
          });
          setDepartments(formatted);          
          setFormDetails({
            ...formDetails,
            department: formatted.find(d => d.value === user.department)
          });
        } else {
          toast.error(response.message || 'Failed to send request');
        }
      })
      .catch(err => {});
  }, []);

  const sendRequest = () => {
    let payload = {
      ...formDetails,
      template: emailBody,
      department: (formDetails.department && formDetails.department.value) || 0
    };
    
    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORMS}`,
      payload
    )
      .then(response => {
        if (response.id) {
          navigate('/vendor-onboard');
        } else {
          toast.error(response.message || 'Failed to send request');
        }
      })
      .catch(err => {        
        console.log("err",err);
      });
  };

  const onInputChange = event => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div className='invoice-request-container'>
      <div className='header-section'>
        <div>
          <IconButton
            variant='contained'
            onClick={() => navigate('/vendor-onboard')}
          >
            <ArrowBack />
          </IconButton>
          <span className='title'>Send Request </span>
        </div>
      </div>
      <div className='invoice-request-details'>
        <Card>
          <CardContent>
            <div className='form-row'>
              <Autocomplete
                options={departments}
                className='form-autocomplete'
                size='small'
                value={formDetails.department || {}}
                inputValue={
                  (formDetails.department && formDetails.department.label) || ''
                }
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || '',
                      name: 'department'
                    }
                  });
                }}
                disabled={true}
                renderInput={params => (
                  <TextField size='small' {...params} label='Department *' />
                )}
              />
              <TextField
                label='Vendor Email *'
                name='email'
                size='small'
                value={formDetails.email}
                onChange={onInputChange}
                helperText={'Should be example@example.com'}
              />
              <TextField
                label='Email Subject *'
                name='subject'
                size='small'
                value={formDetails.subject}
                onChange={onInputChange}
              />
              <CKEditor
                editor={ClassicEditor}
                data={emailBody}
                onChange={(event, editor) => {
                  setEmailBody(editor.getData());
                }}
              />
              <Button
                variant='contained'
                onClick={sendRequest}
                disabled={isFormValid(formDetails)}
              >
                Send Request
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default VendorRequest;
