import { getOfflineData } from './offline-services';

// Function for checking that current user logged in has right
// to approve the configuration depending on its additional role
// and stage's allowed role
// True means we show Approve/ Reject Button, otherwise they are disabled
export const checkIsApprover = (approverDetails) => {
  const user = getOfflineData('user');
  const userId = parseInt(user?.id || user?._id);
  const pendingWith = parseInt(approverDetails?.pendingWithUserId);

  if (approverDetails.stages && approverDetails.stages.length > 0) {
    const stageRole =
      approverDetails?.stages?.[approverDetails.stages.length - 1]?.allowedRole;
    return user?.additionalRoles?.includes(stageRole) && userId == pendingWith
      ? true
      : false;
  }

  return false;
};

export const checkUserRight = (right) => {
  const user = getOfflineData('user') || {};
  const { userRights } = user;
  let hasRight = false;
  let curObj = { ...userRights };
  const length = right?.split?.('.')?.length || 0;
  right?.split?.('.')?.forEach((rightKey, index) => {
    if (curObj?.[rightKey] && index === length - 1) {
      hasRight = true;
    }
    curObj = curObj?.[rightKey];
  });
  return hasRight;
};
