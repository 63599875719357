import { clearOfflineData, setOfflineData } from "./offline-services";
import { REST_URLS } from "./end-points";
import { HTTP_METHODS, mainInvokeApi } from "./http-service";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";

export const mainRedirectToIntranetPortal = (mainKey) => {
  clearOfflineData(mainKey, "user");
  clearOfflineData(mainKey, "tokens");
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const getUserAccessDetails = (
  tokenKey,
  slug,
  Authorization,
  customLogic,
  tokens,
  setUserToken,
  doNotSetToken
) => {
  mainInvokeApi(
    tokenKey,
    slug,
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`,
    null,
    null,
    Authorization
  )
    .then((res) => {
      if (res?.message) {
        mainRedirectToIntranetPortal(tokenKey);
        return;
      }
      if (tokens && !doNotSetToken) {
        setOfflineData(tokenKey, "tokens", tokens);
      }
      const role = res?.role;
      // For changing user toek as per requirement
      if (setUserToken) {
        setUserToken(res);
      } else {
        setOfflineData(tokenKey, "user", { ...res, role });
      }

      if (typeof customLogic === "function") {
        customLogic(res);
      }
    })
    .catch(() => {
      mainRedirectToIntranetPortal(tokenKey);
    });
};

export const makeTokenObject = (
  access,
  accessTokenExpiry,
  refresh,
  refreshTokenExpiry
) => {
  return {
    access: {
      token: access,
      expires: accessTokenExpiry?.trim(),
    },
    refresh: {
      token: refresh,
      expires: refreshTokenExpiry?.trim(),
    },
  };
};

export const checkAndGrantUserAccess = (
  tokenKey,
  slug,
  tokens,
  customLogic,
  setUserToken,
  doNotSetToken
) => {
  const access = tokens?.access?.token;
  getUserAccessDetails(
    tokenKey,
    slug,
    access,
    customLogic,
    tokens,
    setUserToken,
    doNotSetToken
  );
};
