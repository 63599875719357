import { toast } from "react-toastify";

export const customLmtErr = (customLmt, file) => {
  if (customLmt && file?.size > customLmt) {
    toast.error(`File size should not exceed ${customLmt / 1000000} MB`, {
      autoClose: 2000,
    });
    return true;
  }
  return;
};
