import React from "react";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "./index.scss";
import Select from "react-select";
import moment from "moment";

const Attributes = ({ attributeData, setPersonData }) => {
  const dateFun = (val) => {
    if (val) {
      if (new Date(val) == "Invalid Date") {
        let split = val?.split("-");
        let d =
          split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
        if (d) {
          return moment(d).format("yyyy-MM-DD");
        } else {
          return "";
        }
      } else {
        return moment(val).format("yyyy-MM-DD");
      }
    } else {
      return "";
    }
  };
  return (
    <div style={{ marginTop: "1em" }}>
      <div className="pd-title g-r-2">Attributes</div>
      <div className="input-form-container">
        <div className="content-container">
          <div className="g-r-2">
            <TextField
              id="nda-date"
              InputLabelProps={{ shrink: true }}
              label="NDA Date"
              className="subs-text-field"
              type="date"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  attributesDetails: {
                    ...prevVal.attributesDetails,
                    ndaDate: e.target.value,
                  },
                }));
              }}
              value={dateFun(attributeData.ndaDate)}
            />
            <TextField
              id="opt-out-date"
              type="date"
              className="subs-text-field"
              InputLabelProps={{ shrink: true }}
              label="Opt Out Date"
              fullWidth
              value={dateFun(attributeData.optOutDate)}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  attributesDetails: {
                    ...prevVal.attributesDetails,
                    optOutDate: e.target.value,
                  },
                }));
              }}
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              id="ofac-date"
              InputLabelProps={{ shrink: true }}
              className="subs-text-field"
              label="OFAC Date"
              type="date"
              fullWidth
              value={dateFun(attributeData.ofacDate)}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  attributesDetails: {
                    ...prevVal.attributesDetails,
                    ofacDate: e.target.value,
                  },
                }));
              }}
              variant="outlined"
            />

            <div>
              <span className="input-title-paragrah">Disclosure Opt Out</span>
              <Checkbox
                onChange={(e) => {
                  setPersonData((prevVal) => ({
                    ...prevVal,
                    attributesDetails: {
                      ...prevVal.attributesDetails,
                      disclosureOptOut: !attributeData.disclosureOptOut,
                    },
                  }));
                }}
                checked={attributeData.disclosureOptOut}
              />
            </div>
          </div>
          <div className="g-r-2" style={{ paddingTop: "5px" }}>
            <Select
              isSearchable
              placeholder="Media"
              classNamePrefix="custom_select"
            />

            <TextField
              id="hw-username"
              className="subs-text-field"
              label="HW Username"
              fullWidth
              variant="outlined"
              value={attributeData.hwUsername}
              onChange={(e) => {
                setPersonData((prevVal) => ({
                  ...prevVal,
                  attributesDetails: {
                    ...prevVal.attributesDetails,
                    hwUsername: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Attributes;
