import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';
export const getHeaderConfig = () => {
  const config = [
    {
      label: 'Id',
      id: 'id',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data, onClick) => {
        return (
          <>
            <Button
              disabled={!isUpdateAllowed('master_data')}
              variant="text"
              onClick={() => onClick(data, 'EDIT')}
            >
              Edit
            </Button>
            <Button
              disabled={!isDeleteAllowed('master_data')}
              variant="text"
              onClick={() => onClick(data, 'DELETE')}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return config;
};
