/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import "./subway-upload.scss";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BackupIcon from "@mui/icons-material/Backup";
import { mainInvokeUploadFile } from "../../utils/http-service";
import { invokeUploadFile } from "../../../../utils/http-service";

const SubwayFileUpload = ({
  title,
  url,
  clsName,
  callBack,
  onFileUpload,
  tokenKey,
  slug,
  providedLimit,
  lmtMsg,
  preventUpload,
  accept,
  fileFormats,
  isIntranetUpload = false,
}) => {
  const [file, setFile] = useState(null);
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };

  const onChange = (e) => {
    if (fileFormats && !fileFormats.includes(e.target.files[0].type.trim())) {
      toast.error("File provided is not of correct format", {
        autoClose: 2000,
      });
      return;
    }
    const limit = providedLimit ? providedLimit : 10000000;
    const msg = lmtMsg
      ? lmtMsg
      : `File size cannot be more than ${limit / 1000000} MB`;

    if (e?.target?.files[0]?.size > limit) {
      toast.error(msg, { autoClose: 2000 });
      return;
    }
    if (
      typeof preventUpload === "function" &&
      preventUpload(e?.target?.files[0])
    ) {
      return;
    }
    setFile(e.target.files[0]);
    if (onFileUpload) {
      onFileUpload(e.target.files[0]);
    }
  };
  const fileUpload = () => {
    const formData = new FormData();
    formData.append("upload", file);
    return isIntranetUpload
      ? invokeUploadFile(url, formData)
          .then((response) => {
            if (
              response.status === 200 ||
              response.message === "Event Logged"
            ) {
              setFile(null);
              callBack && callBack(response.data);
            } else {
              toast.error(
                (response.data && response.data.message) || "Upload Failed"
              );
            }
          })
          .catch((err) => {
            toast.error("Upload failed err");
          })
      : mainInvokeUploadFile(tokenKey, slug, `${url}`, formData)
          .then((response) => {
            if (
              response.status === 200 ||
              response.message === "Event Logged"
            ) {
              setFile(null);
              callBack && callBack(response.data);
            } else {
              toast.error(
                (response.data && response.data.message) || "Upload Failed"
              );
            }
          })
          .catch((err) => {
            toast.error("Upload failed err");
          });
  };

  return (
    <div>
      <form className={`subway-upload-file-uploader ${clsName}`}>
        {file === null ? (
          <>
            <label
              htmlFor="file-upload"
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
            >
              <span className="fr-hide-mobile">{`${title}`}</span>
              <BackupIcon />
            </label>
          </>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        <input
          id="file-upload"
          type="file"
          onChange={(event) => {
            onChange(event);
            event.target.value = null;
          }}
        />
        {file !== null && (
          <span>
            {!onFileUpload && (
              <IconButton color="primary" onClick={onFormSubmit} title="Upload">
                <CheckCircleOutlineIcon />
              </IconButton>
            )}
            <IconButton
              color="primary"
              onClick={() => setFile(null)}
              title="Clear"
            >
              <ClearIcon />
            </IconButton>
          </span>
        )}
      </form>
    </div>
  );
};

export default SubwayFileUpload;
