import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import MainContainer from "../../common-utilities/container";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import { getOfflineData, setOfflineData } from "../utils/offline-services";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Header from "../core/header";
import {
  checkAndGrantUserAccess,
  makeTokenObject,
} from "../../common-utilities/utils/common-utils";
import { userStates } from "../../common-utilities/utils/constants";
import { redirectToIntranetPortal } from "../utils/common-utils";
import { Theme } from "../../common-utilities/utils/theme-config";
import { invokeApi } from "../utils/http-service";
import { HTTP_METHODS } from "../../common-utilities/utils/http-service";
import { REST_URLS } from "../../common-utilities/utils/end-points/subway-subs";
import { HOSTNAME } from "../../common-utilities/utils/end-points";
const theme = createTheme(Theme);
const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(userStates.isProcessing);
  const [processingUser, setprocessingUser] = useState(false);
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const setUserRemoveParams = (userDetails) => {
    setSearchParams({});
  };

  const setLocalStorageUser = (user) => {
    setprocessingUser(true);
    const userId = user?.properties?.subs?.personId;

    if (!userId) {
      redirectToIntranetPortal();
      return;
    }
    invokeApi(
      HTTP_METHODS.get,
      `${HOSTNAME}${REST_URLS.GET_PERSON_DETAILS}${userId}`
    )
      .then((res) => {
        if (res?.message) {
          redirectToIntranetPortal();
          return;
        }
        const userDetails = res?.personDetails || {};
        setOfflineData("user", userDetails);
        setUser(userDetails);
        setprocessingUser(false);
      })
      .catch((err) => {
        redirectToIntranetPortal();
        setprocessingUser(false);
      });
  };

  useEffect(() => {
    const areTokensPresent = !!access && !!refresh;
    const userDetails = getOfflineData("user");
    if (areTokensPresent) {
      const tokens = makeTokenObject(
        access,
        accessTokenExpiry,
        refresh,
        refreshTokenExpiry
      );
      checkAndGrantUserAccess(
        tokenMainKeys.subs,
        appSlugs.subs,
        tokens,
        setUserRemoveParams,
        setLocalStorageUser
      );
    } else if (userDetails) {
      setUser(userDetails);
    } else if (!processingUser) {
      redirectToIntranetPortal();
    }
  }, [access, refresh, processingUser]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer Header={Header}>
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
