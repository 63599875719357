import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

const camelcaseToSpace = (str) => {
  let newStr = "";
  let wasUpper = false;
  for (let i = 0; i < str.length; i++) {
    if (!wasUpper && str[i] == str.toUpperCase()[i] && str[i] !== " ") {
      newStr = newStr + " ";
      wasUpper = true;
    } else {
      wasUpper = false;
    }
    let tempStr = str[i];
    if (i === 0 || str[i - 1] === " ") tempStr = tempStr.toUpperCase();
    newStr = newStr + tempStr;
  }
  return newStr.trim();
};

const excelDownload = (name, res) => {
  return new Promise((resolve) => {
    let arrayObj = {};
    let flatObj = {};
    let keys = Object.keys(res).forEach((iter) => {
      if (Array.isArray(res[iter])) {
        if (
          res[iter].length > 0 &&
          typeof res[iter][0] === "object" &&
          res[iter][0] !== null
        )
          arrayObj[iter] = res[iter];
      } else if (typeof res[iter] === "object" && res[iter] !== null) {
        Object.keys(res[iter]).forEach((i) => {
          if (Array.isArray(res[iter][i])) {
            if (
              res[iter][i].length > 0 &&
              typeof res[iter][i][0] === "object" &&
              res[iter][i][0] !== null
            )
              arrayObj[i] = res[iter][i];
          } else if (
            typeof res[iter][i] === "object" &&
            res[iter][i] !== null
          ) {
            Object.keys(res[iter][i]).forEach((j) => {
              if (Array.isArray(res[iter][i][j])) {
                if (
                  res[iter][i][j].length > 0 &&
                  typeof res[iter][i][j][0] === "object" &&
                  res[iter][i][j][0] !== null
                )
                  arrayObj[i + " " + j] = res[iter][i][j];
              } else if (
                typeof res[iter][i][j] === "object" &&
                res[iter][i][j] !== null
              ) {
                // do nothing
              } else if (!["", null].includes(res[iter][i][j])) {
                if (flatObj[i + " " + j])
                  flatObj[iter + " " + i + " " + j] = res[iter][i][j];
                else flatObj[i + " " + j] = res[iter][i][j];
              }
            });
          } else if (!["", null].includes(res[iter][i])) {
            flatObj[i] = res[iter][i];
          }
        });
      } else if (!["", null].includes(res[iter])) {
        flatObj[iter] = res[iter];
      }
    });
    let template = [];
    let flatObjCol = [];
    let flatObjRow = [];
    Object.keys(flatObj).forEach((k) => {
      flatObjCol.push(camelcaseToSpace(k));
      flatObjRow.push(flatObj[k]);
    });
    template.push(flatObjCol);
    template.push(flatObjRow);
    let arraytemplate = [];
    Object.keys(arrayObj).forEach((l) => {
      let innerObj = { name: l };
      let innerObjCol = [];
      let innerObjRow = [];
      arrayObj[l].forEach((m, index) => {
        let bufferRow = [];
        Object.keys(m).forEach((n) => {
          if (
            !Array.isArray(m[n]) &&
            (typeof m[n] !== "object" || m[n] === null)
          ) {
            if (index === 0) {
              innerObjCol.push(camelcaseToSpace(n));
            }
            bufferRow.push(m[n]);
          }
        });
        innerObjRow.push(bufferRow);
      });
      let subArray = [];
      subArray.push(innerObjCol);
      innerObjRow.forEach((buff) => {
        subArray.push(buff);
      });
      innerObj["data"] = subArray;
      arraytemplate.push(innerObj);
    });
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      if (arraytemplate.length > 0) {
        let sheetName = name;
        sheetName = workbook.sheet(0).name(sheetName);
        sheetName.cell("A1").value(template);
        arraytemplate.forEach((temp) => {
          let subSheetName = temp.name;
          subSheetName = workbook.addSheet(subSheetName);
          subSheetName.cell("A1").value(temp.data);
        });
      } else {
        let sheetName = name;
        sheetName = workbook.sheet(0).name(sheetName);
        sheetName.cell("A1").value(template);
      }
      return workbook.outputAsync().then((res) => {
        saveAs(res, name + ".xlsx");
        resolve(true);
      });
    });
  });
};

export default excelDownload;
