import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const MultipleSelectCheckMarks = ({
  options,
  multiple,
  label,
  selectedOptions = [],
  onChange,
  onSearch,
  className
}) => {
  const handleChange = option => {
    let optionIndex = selectedOptions.findIndex(s => s.value === option.value);
    if (multiple) {
      if (optionIndex === -1) {
        onChange([...selectedOptions, option]);
      } else {
        selectedOptions.splice(optionIndex, 1);
        onChange([...selectedOptions]);
      }
    } else {
      onChange(optionIndex === -1 ? [option] : []);
    }
  };

  const isSelected = option => {
    if (multiple) {
      return !!selectedOptions.find(v => v.value === option.value);
    } else {
      return !!selectedOptions.find(v => v.value === option.value);
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200, width: '100%' }} className={className}>
        <InputLabel size='small' key={label}>
          {label}
        </InputLabel>
        <Select
          size='small'
          className={`subway-select ${className}`}
          multiple={multiple}
          value={selectedOptions.map(s => s.label)}
          input={<OutlinedInput label={label} />}
          renderValue={(selected = []) =>
            multiple ? selected.join(', ') : selected
          }
          onChange={onSearch}
          MenuProps={MenuProps}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleChange(option)}
            >
              <Checkbox checked={isSelected(option)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckMarks;
