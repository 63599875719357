import { Button, IconButton } from '@mui/material';
import { convertToCamelCaseFromUnderScore } from '../../../../common-utilities/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadPO } from './utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { setProperty } from '../../../../common-utilities/utils/misc';
import {
  getPurchaseOrderWithoutTaxAmount,
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
} from '../helper';
import { checkUserRight } from '../../../utils/utils';
import { getOfflineData } from '../../../utils/offline-services';

export const getHeaderConfig = (
  editVendor,
  filters,
  setFilters,
  status,
  loadData
) => {
  const headers = [
    {
      label: 'PO Number',
      id: 'poNumber',
      headerRender: () => {
        return (
          <div className="header-column-arrow">
            PO Number
            {filters?.sortBy === '-createdAt' ? (
              <KeyboardArrowDownIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: 'createdAt' });
                  setProperty('sortBy', 'createdAt', setFilters);
                }}
                className="download-icon"
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: '-createdAt' });
                  setProperty('sortBy', '-createdAt', setFilters);
                }}
                className="download-icon"
              />
            )}
          </div>
        );
      },
    },
    {
      label: 'Site Code & Name',
      id: 'siteCode',
      render: (data) => {
        const { site = {} } = data;
        return (site && `${site.code} - ${site.name}`) || '';
      },
    },
    {
      label: 'Vendor code',
      id: 'vendorCode',
      render: (data) => {
        const { vendorCode = '' } = data;
        return vendorCode;
      },
    },
    {
      label: 'Vendor Name',
      id: 'vendorName',
      render: (data) => {
        const { vendorName = '' } = data;
        return vendorName;
      },
    },
    {
      label: 'PO Type',
      id: 'poType',
      render: (data) => {
        const { items = [] } = data;
        return convertToCamelCaseFromUnderScore(data.poType);
      },
    },
    {
      label: 'Base Amt',
      id: 'baseAMount',
      render: (data) => {
        const { items = [] } = data;
        return getPurchaseOrderWithoutTaxAmount(items);
      },
    },
    {
      label: 'GST Amt',
      id: 'gstAMount',
      render: (data) => {
        const { items = [] } = data;
        return getPurchaseOrderTaxAmount(items);
      },
    },
    {
      label: 'Total Amt',
      id: 'totalAMount',
      render: (data) => {
        const { items = [] } = data;
        return getPurchaseOrderAmount(items);
      },
    },
  ];

  if (status === 'all' || status === 'approval_in_progress') {
    headers.push({
      label: 'Pending With',
      id: 'status',
      render: (data) => {
        const { approvalId = {} } = data;
        return approvalId && approvalId.pendingWith;
      },
    });
  }

  if (status !== 'approval_in_progress') {
    headers.push({
      label: 'PO Status',
      id: 'status',
      render: (data) => {
        if (data.isShortClosed) {
          return 'Short Close';
        }
        return convertToCamelCaseFromUnderScore(data.status);
      },
    });
  }

  headers.push(
    {
      label: 'Created By',
      id: 'creatorName',
    },
    {
      label: 'View',
      id: 'view',
      render: (data) => {
        return (
          <>
            <div className="po-action-btns">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  downloadPO(data);
                }}
              >
                <DownloadIcon
                  color="primary"
                  className="download-icon"
                  fontSize="medium"
                />
              </IconButton>

              <Button variant="text" onClick={() => editVendor(data, 'VIEW')}>
                View
              </Button>
            </div>
          </>
        );
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        const { status, advance } = data;
        const user = getOfflineData('user');
        return (
          <>
            <div className="po-action-btns">
              {data.status === 'approved' &&
                data.allowInward &&
                checkUserRight('grn.create') && (
                  <Button
                    variant="text"
                    onClick={() => editVendor(data, 'GRN')}
                  >
                    Create GRN
                  </Button>
                )}

              {parseInt(data?.createdBy) === parseInt(user?._id || user?.id) &&
                ['draft', , 'reconsider'].includes(status) && (
                  <Button
                    variant="text"
                    onClick={() => editVendor(data, 'EDIT')}
                  >
                    Edit
                  </Button>
                )}
            </div>
          </>
        );
      },
    }
  );

  return headers;
};
