import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Button } from '@mui/material';

const ConfirmModal = ({
  title,
  onYes,
  onNo,
  contentClassName,
  secondaryLabel,
}) => {
  // to disable the scroll on body
  useEffect(() => {
    let element = document.getElementsByTagName('body');
    if (element && element.length > 0) {
      element[0].style.overflow = 'hidden';
    }
    return () => {
      if (element && element.length > 0) {
        element[0].style.overflow = 'scroll';
      }
    };
  });

  return (
    <div className='confirm-modal-container'>
      <div className={`${contentClassName} modal-content`}>
        <div className='modal-body'>
          <div>Are you sure ?</div>
          <div>{secondaryLabel || 'Do you want to delete'}</div>
          <div className='btn-container'>
            <Button variant='outlined' onClick={() => onYes()}>
              Yes
            </Button>
            <Button variant='contained' onClick={() => onNo()}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.defaultProps = {};
ConfirmModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default ConfirmModal;
