import { pick } from '../../../../common-utilities/utils';

export const setEditCreateData = (data, setData, setFilters) => {
  if (data) {
    const allData = pick(data, [
      'name',
      'id',
      'approvalType',
      'department',
      'maxAmount',
      'locationType',
      'workFlowType'
    ]);

    const stages = data?.stages ? [...data?.stages].map((stage) => ({...stage})) : [];
    allData.stages = stages;
    setData(allData);
  } else {
    setData({});
  }
  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
};
