import React, { useState, useEffect } from "react";
import "./index.scss";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { allStores, storeTypes } from "../../utils/constants";
import { getCityListByStateAndCountry, getStateList } from "./utils";
import { getLabelsAndValuesForSelect } from "../../utils";
import { regionsList } from "../../utils/label";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import {
  clearProperty,
  setProperty,
} from "../../../common-utilities/utils/misc";

const Details = ({
  setDocumentData,
  documentData,
  setApprover4,
  setApprover5,
  setApprover6,
  leadList,
  leadData,
}) => {
  const [allRegionsList, setAllRegionsList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const storeList = allStores.map((item) => {
    return { label: item, value: item };
  });
  useEffect(() => {
    const statesSelectList = getStateList("India");
    setStateList(statesSelectList);
    const allRegionSelectList = getLabelsAndValuesForSelect(
      regionsList,
      regionsList
    );
    setAllRegionsList(allRegionSelectList);
  }, []);
  useEffect(() => {
    if (documentData?.lead) {
      const selectedLead = leadData.find(
        (ele) => ele.id === documentData?.lead?.value
      );
      if (selectedLead) {
        const state = selectedLead?.locationInfo?.state && {
          label: selectedLead?.locationInfo?.state,
          value: selectedLead?.locationInfo?.state,
        };
        const region = selectedLead?.locationInfo?.region && {
          label: selectedLead?.locationInfo?.region,
          value: selectedLead?.locationInfo?.region,
        };
        const city = selectedLead?.locationInfo?.city && {
          label: selectedLead?.locationInfo?.city,
          value: selectedLead?.locationInfo?.city,
        };

        const format = selectedLead?.locationInfo?.format;
        setDocumentData((prevVal) => ({
          ...prevVal,
          state,
          cluster: region,
          city,
          format,
        }));
      }
    }
  }, [documentData?.lead]);

  return (
    <div>
      <Grid container md={12} className="grid-con ">
        <Grid item md={4} xs={12}>
          <div className="input-con">
            <p className="label">Type</p>
            <AutocompleteComponent
              label="Select Type"
              onClick={() => {
                setDocumentData((prevData) => {
                  delete prevData.businessDetails;
                  delete prevData.approver4;
                  delete prevData.approver5;
                  delete prevData.approver6;
                  setApprover4("");
                  setApprover5("");
                  setApprover6("");
                  delete prevData.storeType;
                  return {
                    ...prevData,
                  };
                });
              }}
              options={storeList}
              onChange={(e, value) => {
                setDocumentData((prevData) => {
                  delete prevData.approver4;
                  delete prevData.approver5;
                  delete prevData.approver6;
                  delete prevData.lead;
                  setApprover4("");
                  setApprover5("");
                  setApprover6("");
                  return {
                    ...prevData,
                    storeType: value,
                  };
                });
              }}
              defaultValue={documentData?.storeType?.label || ""}
              value={documentData?.storeType || null}
            />
          </div>

          <div className="input-con">
            <p className="label">Restaurant Id</p>
            <TextField
              size="small"
              name="restaurantId"
              type="number"
              fullWidth
              disabled={
                documentData.storeType?.value !== storeTypes.cocoAcquired
              }
              id="restaurantId"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              value={documentData.restaurantId}
              placeholder="Restaurant Id"
              variant="outlined"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <div className="input-con">
            <p className="label">Restaurant Name*</p>
            <TextField
              size="small"
              name="restaurantName"
              fullWidth
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="restaurantName"
              value={documentData.restaurantName}
              placeholder="Restaurant Name"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          {/* <div className="input-con">
            <p className="label">Cluster*</p>
            <TextField
              name="cluster"
              fullWidth
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="cluster"
              value={documentData.cluster}
              placeholder="Cluster"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div> */}

          <div className="input-con">
            <p className="label">State*</p>

            <AutocompleteComponent
              onClick={() => {
                setDocumentData((prevData) => {
                  delete prevData.city;
                  delete prevData.state;
                  return {
                    ...prevData,
                  };
                });
                setCityList([]);
              }}
              options={stateList}
              onChange={(e, value) => {
                const citySelectList = getCityListByStateAndCountry(
                  "India",
                  value?.value
                );
                setCityList(citySelectList);
                setDocumentData((prevData) => {
                  delete prevData.city;
                  return {
                    ...prevData,
                    state: value,
                  };
                });
              }}
              label="Select State"
              defaultValue={documentData?.state?.label || ""}
              value={documentData?.state || null}
            />
          </div>
          <div className="input-con">
            <p className="label">Region*</p>
            <AutocompleteComponent
              onClick={() => {
                clearProperty("cluster", setDocumentData);
              }}
              options={allRegionsList}
              onChange={(e, value) => {
                setProperty("cluster", value, setDocumentData);
              }}
              label="Select Region"
              defaultValue={documentData.cluster?.label || ""}
              value={documentData.cluster || null}
            />
          </div>
          {documentData?.storeType?.value === storeTypes.cocoBuild && (
            <div className="input-con">
              <p className="label">City*</p>

              <AutocompleteComponent
                onClick={() => {
                  clearProperty("city", setDocumentData);
                }}
                options={cityList}
                onChange={(e, value) => {
                  setProperty("city", value, setDocumentData);
                }}
                label="Select City"
                defaultValue={documentData.city?.label || ""}
                value={documentData?.city || null}
              />
            </div>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          {documentData?.storeType?.value === storeTypes.cocoBuild && (
            <div className="input-con">
              <p className="label">BD Lead*</p>
              <AutocompleteComponent
                label="Select Lead"
                options={leadList}
                onChange={(e, value) => {
                  setDocumentData((prevData) => {
                    delete prevData.city;
                    delete prevData.state;
                    delete prevData.cluster;
                    delete prevData.format;
                    return {
                      ...prevData,
                      lead: value,
                    };
                  });
                }}
                value={documentData?.lead || null}
              />
            </div>
          )}
          <div className="input-con">
            <p className="label">BDPOC*</p>
            <TextField
              size="small"
              name="BDPOC"
              fullWidth
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="BDPOC"
              value={documentData.BDPOC}
              placeholder="BDPOC"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">Projects POC*</p>
            <TextField
              size="small"
              fullWidth
              name="ProjectsPOC"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="ProjectsPOC"
              value={documentData.ProjectsPOC}
              placeholder="Projects POC"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">Legal POC*</p>
            <TextField
              size="small"
              fullWidth
              name="LegalPOC"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="LegalPOC"
              value={documentData.LegalPOC}
              placeholder="Legal POC"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">Format*</p>
            <TextField
              size="small"
              fullWidth
              name="format"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="format"
              value={documentData.format}
              placeholder="Format"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          {documentData?.storeType?.value !== storeTypes.cocoBuild && (
            <div className="input-con">
              <p className="label">City*</p>

              <AutocompleteComponent
                onClick={() => {
                  clearProperty("city", setDocumentData);
                }}
                options={cityList}
                onChange={(e, value) => {
                  setProperty("city", value, setDocumentData);
                }}
                label="Select City"
                defaultValue={documentData.city?.label || ""}
                value={documentData?.city || null}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
