import React from "react";
import "./index.scss";
import WarningIcon from "@mui/icons-material/Warning";
const CertificateWarning = () => {
  return (
    <div className="warning-con">
      <div className="warn-title-con">
        <WarningIcon color="error" />
        <p className="title">Warning</p>
      </div>

      <p>Oops! Your Last Month Certificate is missing. Let's create it now!</p>
    </div>
  );
};

export default CertificateWarning;
