import React, { useEffect, useState } from 'react';
import FileUpload from '../../../../common-utilities/core/file-uploader';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { Autocomplete, TextField } from '@mui/material';
import './index.scss';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { toast } from 'react-toastify';
import { BUDGET_TYPES } from '../../../utils/constants';

const UploadBudget = ({ onSuccess }) => {
  const [siteList, setSiteList] = useState([]);
  const [formDetails, setFormDetails] = useState({});

  const loadData = budgetType => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}/list`,
      null,
      {
        budgetType
      }
    )
      .then(response => {
        const results = response?.map?.(item => {
          return {
            ...item,
            restaurantId: item.code,
            restaurantName: item.name,
            id: item.id
          };
        });
        setSiteList(results);
      })
      .catch(err => {});
  };

  const { budgetType = '', selectedSite = {} } = formDetails;
  const { id = '', restaurantId, restaurantName } = selectedSite;

  return (
    <div className='upload-budget'>
      <Autocomplete
        value={formDetails.budgetType || ''}
        fullWidth
        onChange={(e, val) => {
          if (val) {
            loadData(val.value);
            setFormDetails({ ...formDetails, budgetType: val });
          } else {
            setFormDetails({});
          }
        }}
        renderInput={params => (
          <TextField {...params} size='small' label='Budget Type' />
        )}
        options={BUDGET_TYPES}
      />

      <Autocomplete
        value={formDetails.selectedSite}
        fullWidth
        getOptionLabel={op =>
          `${op.restaurantId || ''} ${op.restaurantName || ''}`
        }
        onChange={(e, val) => {
          e.stopPropagation();
          setFormDetails({ ...formDetails, selectedSite: val || {} });
        }}
        renderInput={params => (
          <TextField {...params} size='small' fullWidth label='Select Site' />
        )}
        options={siteList}
      />
      {id && (
        <FileUpload
          uploadText='Upload budget'
          clsName='upload-budget-container'
          url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_BUDGET}?site=${id}&siteCode=${restaurantId}&budgetType=${budgetType.value}&siteName=${restaurantName}`}
          callBack={response => {
            const { data } = response;
            if (data.success) {
              onSuccess(data);
            } else {
              toast.error('Failed to create budget');
            }
          }}
          formDataKey='file'
        />
      )}
    </div>
  );
};
export default UploadBudget;
