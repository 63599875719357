import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { getOfflineData, clearOfflineData } from "../../utils/offline-services";
import DefaultSideMenu from "../side-menu";
import Intranetlogout from "../../../../core/header/logout";
import Logout from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation } from "react-router-dom";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";
import logo from "../../resources/logo.png";
import { mainRedirectToIntranetPortal } from "../../utils/common-utils";

const Header = ({ tokenKey, SideMenu, sideMenuProps = {} }) => {
  const { pathname } = useLocation();
  let history = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [showLogout, setShowLogout] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const subsEndPoint = getProjectEndPoint(endPointsForProject.subs);
    const docuStorageEnpoint = getProjectEndPoint(
      endPointsForProject.docuStorage
    );
    const qnetNetEndPoint = getProjectEndPoint(endPointsForProject.qnet);
    if (
      pathname.startsWith(subsEndPoint) ||
      pathname.startsWith(docuStorageEnpoint) ||
      pathname.startsWith(qnetNetEndPoint)
    ) {
      setShowLogout(true);
      return;
    }
    setShowLogout(false);
  }, [pathname]);
  useEffect(() => {
    let user = getOfflineData(tokenKey, "user");
    setUserDetails(user);
  }, []);

  const logout = () => {
    clearOfflineData(tokenKey, "user");
    clearOfflineData(tokenKey, "tokens");
    Intranetlogout(history);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={`header-container ${showLogout && "non-changed"}`}>
      <div className="logo-con">
        {SideMenu ? (
          <SideMenu {...sideMenuProps} />
        ) : (
          <DefaultSideMenu {...sideMenuProps} />
        )}
        {
          showLogout &&
          <img
          className="logo"
          src={logo}
          onClick={() => {
            if (tokenKey) {
              mainRedirectToIntranetPortal(tokenKey);
            } else {
              history("/");
            }
          }}
          title="Go Back"
        />
        }
      </div>
      {showLogout && (
        <div className="menu">
          <Avatar className="si-hide-mobile">
            <AccountCircleIcon />
          </Avatar>
          <Button
            type="small"
            variant="text"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {userDetails?.name}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Header;
