import { Chip } from '@mui/material';
export const getHeaderConfig = () => {
  const config = [
    {
      label: 'PO Number',
      id: 'PO Number',
    },
    {
      label: 'Invoice Document Number',
      id: 'Invoice Document Number',
    },
    {
      label: 'Date Of Payment',
      id: 'Date Of Payment',
    },
    {
      label: 'Payment Amount',
      id: 'Payment Amount',
    },
    {
      label: 'UTR',
      id: 'UTR',
    },

    {
      label: 'TDS Amount',
      id: 'TDS Amount',
    },
    {
      label: 'Attachments',
      id: 'name',
      render: (data) => {
        const { Urls = [] } = data;
        return (
          (Urls || [])?.map?.((attachment, i) => {
            return (
              <Chip
                label={'attachment' + (i + 1)}
                onClick={() => window.open(attachment, '_blank')}
              />
            );
          }) || <></>
        );
      },
    },
  ];
  return config;
};
