import {
  getAllExcelColumns,
  processExcelDates,
} from "../../../common-utilities/utils/excel";
import { validStoreTypes } from "../../utils/document-constants";
import {
  cocoBuiltDateColumns,
  cocoAcquiredDateColumns,
  fofoDataColumns,
} from "./config/download-report-date-columns.config";

export const dateColumnsByStoreType = (headers, columns, storeType) => {
  const dateHeaders = headers.reduce((columnList, header, index) => {
    const column = columns[index];
    switch (storeType) {
      case validStoreTypes.cocoBuilt:
        if (cocoBuiltDateColumns.includes(header)) {
          columnList.push(column);
        }
        break;
      case validStoreTypes.cocoAcquired:
        if (cocoAcquiredDateColumns.includes(header)) {
          columnList.push(column);
        }
        break;
      case validStoreTypes.fofo:
        if (fofoDataColumns.includes(header)) {
          columnList.push(column);
        }
        break;
      default:
        break;
    }
    return columnList;
  }, []);
  return dateHeaders;
};

export const processTatDates = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data,
  storeType
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateColumns = dateColumnsByStoreType(headers, columns, storeType);
  const convertedWorkSheet = processExcelDates(data, worksheet, dateColumns);
  workbook.Sheets[sheetName] = convertedWorkSheet;
  workbook.SheetNames.push(sheetName);
};
