import React, { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";

import { isActionAllowed } from "../../utils/common-utils";
import BudgetApprovalList from "../budget/budget-approval-list";
import POApprovalList from "../purchase-order/po-approval-list";
import GRNApprovalList from "../goods-receive-notes/grn-approval-list";
import InvoiceApprovalList from "../invoices-data/invoice-approval-list";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const MyPendingActions = ({ getCountOfPendingRequest }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("subIndex");
  const mainTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [noOfPendingPo, setNoOfPendingPo] = useState(0);
  const [noOfPendingGrn, setNoOfPendingGrn] = useState(0);
  const [noOfPendingPayment, setNoOfPendingPayment] = useState(0);
  const [noOfPendingBudget, setNoOfPendingBudget] = useState(0);

  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue,
    });
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Budget",
      component: (
        <BudgetApprovalList
          searchPlaceHolder="Search by Budget Id"
          status="lineitem_approval"
        />
      ),
      badgeCount: noOfPendingBudget,
    },
    {
      label: "PO",
      component: (
        <POApprovalList
          searchPlaceHolder="Search by Po Number"
          approvalType="po_approval"
          status="approval_in_progress"
        />
      ),
      badgeCount: noOfPendingPo,
    },
    {
      label: "GRN",
      component: (
        <GRNApprovalList
          approvalType="invoice_approval"
          usedIn="GRN"
          status="approval_in_progress"
        />
      ),
      badgeCount: noOfPendingGrn,
    },
    {
      label: "Advance Payment",
      component: (
        <InvoiceApprovalList
          approvalType="invoice_approval"
          usedIn="INVOICE_DATA"
          status="approval_in_progress"
          invoiceType="advance_invoice"
        />
      ),
      badgeCount: noOfPendingPayment,
    },
  ];

  // Filter tabs based on user's access rights
  const accessibleTabs = tabs.filter((tab, index) => {
    switch (tab.label) {
      case "Budget":
        return isActionAllowed("budget");
      case "PO":
        return isActionAllowed("po");
      case "GRN":
        return isActionAllowed("grn");
      case "Advance Payment":
        return isActionAllowed("advancePayment");

      default:
        break;
    }
  });
  const loadPendingData = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "invoice_approval",
        invoiceType: "advance_invoice",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingPayment(response?.length || 0);
        }
      })
      .catch((err) => {});
  };
  const getPendingPo = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "po_approval",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingPo(response?.length);
        }
      })
      .catch((err) => {});
  };
  const getPendingGrn = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "invoice_approval",
        invoiceType: "grn_invoice",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingGrn(response?.length);
        }
      })
      .catch((err) => {});
  };
  const getPendingBudget = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approved: false,
        rejected: false,
        approvalType: "lineitem_approval",
        sortBy: "-createdAt",
        populateSite: true,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingBudget(response?.length);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (isActionAllowed("budget")) {
      getPendingBudget();
    }
    if (isActionAllowed("po")) {
      getPendingPo();
    }
    if (isActionAllowed("grn")) {
      getPendingGrn();
    }
    if (isActionAllowed("advancePayment")) {
      loadPendingData();
    }
  }, []);
  useEffect(() => {
    const count =
      noOfPendingPo + noOfPendingGrn + noOfPendingPayment + noOfPendingBudget;
    getCountOfPendingRequest(count);
  }, [noOfPendingPo, noOfPendingGrn, noOfPendingPayment, noOfPendingBudget]);

  return (
    <div className="rm-pt budget-list ">
      <div className="tabs-section">
        {accessibleTabs.length > 0 ? (
          <Box>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              // TabIndicatorProps={{
              //   sx: {
              //     height: 7,
              //     borderColor: "#33ab54",
              //   },
              // }}
              sx={{
                "& button": {
                  minWidth: 100,
                },
              }}
            >
              {accessibleTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={
                    tab.badgeCount ? (
                      <Badge
                        badgeContent={tab.badgeCount}
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            marginRight: "-10px",
                            marginTop: "2px",
                          },
                        }}
                      >
                        {tab.label}
                      </Badge>
                    ) : (
                      tab.label
                    )
                  }
                />
              ))}
            </Tabs>
          </Box>
        ) : (
          <p>Not authorized to take actions </p>
        )}
      </div>
      {accessibleTabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default MyPendingActions;
