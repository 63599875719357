/** @format */

export const DATE_FORMATS = {
  "DD.MM.YYYY": "DD.MM.YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD.MM.YYYY hh:MM": "DD.MM.YYYY hh:MM",
  "DD MMM YY hh:MM": "DD MMM YY hh:MM",
  "MMM DD YYYY hh:MM": "MMM DD YYYY hh:MM",
  "DD/MM/YYYY hh:MM": "DD/MM/YYYY hh:MM",
  "YYYY-MM-DD": "YYYY-MM-DD",
};

export const COUNTRY = [
  { value: "India", label: "India" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Bangladesh", label: "Bangladesh" },
];

export const MONTHS = [
  {
    value: 1,
    label: "January",
  },
  {
    value: 2,
    label: "February",
  },
  {
    value: 3,
    label: "March",
  },
  {
    value: 4,
    label: "April",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "June",
  },
  {
    value: 7,
    label: "July",
  },
  {
    value: 8,
    label: "August",
  },
  {
    value: 9,
    label: "September",
  },
  {
    value: 10,
    label: "October",
  },
  {
    value: 11,
    label: "November",
  },
  {
    value: 12,
    label: "December",
  },
];

export const monthNumberMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const userTypes = {
  admin: "ADMIN",
  user: "USER",
};

export const userRoles = {
  admin: "admin",
  user: "user",
};

export const storeTypes = {
  cocoBuild: "COCO BUILT",
  cocoAcquired: "COCO ACQUIRED",
};
export const userStates = {
  isProcessing: "Checking",
};

export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const allStores = Object.values(storeTypes);

export const INDIA_STATE_LIST = [
  {
    value: "Andhra Pradesh",
    label: "Andhra Pradesh",
    code: "37",
  },
  {
    value: "Andaman and Nicobar Islands",
    label: "Andaman and Nicobar Islands",
    code: "35",
  },
  {
    value: "Arunachal Pradesh",
    label: "Arunachal Pradesh",
    code: "12",
  },
  {
    value: "Assam",
    label: "Assam",
    code: "18",
  },
  {
    value: "Bihar",
    label: "Bihar",
    code: "10",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
    code: "04",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
    code: "22",
  },
  {
    value: "Dadar and Nagar Haveli",
    label: "Dadar and Nagar Haveli",
    code: "26",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
    code: "97",
  },
  {
    value: "Delhi",
    label: "Delhi",
    code: "07",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
    code: "31",
  },
  {
    value: "Puducherry",
    label: "Puducherry",
    code: "34",
  },
  {
    value: "Goa",
    label: "Goa",
    code: "30",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
    code: "24",
  },
  {
    value: "Haryana",
    label: "Haryana",
    code: "06",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
    code: "02",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
    code: "01",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
    code: "20",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
    code: "29",
  },
  {
    value: "Kerala",
    label: "Kerala",
    code: "32",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
    code: "23",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
    code: "27",
  },
  {
    value: "Manipur",
    label: "Manipur",
    code: "14",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
    code: "17",
  },
  {
    value: "Mizoram",
    label: "Mizoram",
    code: "15",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
    code: "13",
  },
  {
    value: "Odisha",
    label: "Odisha",
    code: "21",
  },
  {
    value: "Punjab",
    label: "Punjab",
    code: "03",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
    code: "08",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
    code: "11",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
    code: "33",
  },
  {
    value: "Telangana",
    label: "Telangana",
    code: "36",
  },
  {
    value: "Tripura",
    label: "Tripura",
    code: "16",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
    code: "09",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
    code: "05",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
    code: "19",
  },
];

export const TYPE_OF_VENDOR = [
  "Marketing",
  "Capex Supply",
  "Brokerage",
  "License Vendor",
  "Stamp Duty Payment",
  "R & M Vendor",
  "Lease Rent",
  "Utility",
  "Franchisee Acquisition",
  "IT",
  "Raw material",
  "Other",
];
export const VENDOR_TENURE = ["One time vendor", "Regular Vendor"];
export const TYPE_OF_MSME = ["Micro", "Small", "Medium"];
