import React, { useEffect, useState } from "react";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { Button, TextField, Checkbox, TextareaAutosize } from "@mui/material";
import { Box, Drawer, Menu } from "@mui/material";
import { toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Grid";
import CustomModal from "../../packages/common-utilities/core/modal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PersonIcon from "@mui/icons-material/Person";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import ShowFullBlog from "../display-blogs/showFullBlog";
import { BLOG_EMAIL_LIST } from "./config";
import moment from "moment";
import AutocompleteComponent from "../../packages/common-utilities/core/styled-autocomplete";
import {
  clearProperty,
  setProperty,
} from "../../packages/common-utilities/utils/misc";
import EditIcon from "@mui/icons-material/Edit";
import AutoCompleteMultiSelect from "../../packages/common-utilities/core/autocomplete-multiselect";
import CommonBtn from "../../packages/common-utilities/core/common-button";
import SubwayFileUpload from "../../packages/common-utilities/core/file-uploader/subway-file-upload";

const Blogs = () => {
  const [tableData, setTableData] = useState([]);
  const [blogDetails, setBlogDetails] = useState({
    id: null,
    title: "",
    category: [],
    link: "",
    showToFranchise: true,
    isSendEmail: true,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState(null);
  const [blogFilters, setBlogFilters] = useState({
    startDate: "",
    endDate: "",
    category: "",
  });
  const [catModalOpen, setCatModalOpen] = useState(false);
  const [emailSendTo, setEmailSendTo] = useState([]);
  const [blogContent, setBlogContent] = useState("");
  const [isBlogContentChange, setIsBlogContentChange] = useState(false);
  const [blog, setBlog] = useState("");
  const [summary, setSummary] = useState("");
  const [blogFlag, setBlogFlag] = useState(false);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const openFilters = Boolean(anchorFilter);

  const loadData = () => {
    const params = {
      sortBy: "-createdAt",
      limit: 50,
    };
    if (blogFilters?.category) {
      params["category"] = blogFilters.category;
    }
    if (blogFilters?.startDate) {
      params["startDate"] = blogFilters.startDate;
    }
    if (blogFilters?.endDate) {
      params["endDate"] = blogFilters.endDate;
    }
    invokeApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_BLOGS}`, null, params)
      .then((response) => {
        if (response) {
          setTableData(response);
        }
      })
      .catch((err) => {
        toast.error("Fetching blogs failed");
      });
  };
  const initializeCall = () => {
    invokeApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_CATEGORY}`)
      .then((response) => {
        if (response) {
          let cat = response.map((iter) => {
            return {
              label: iter.name,
              value: iter.id,
            };
          });
          setCategoryList(cat);
        }
      })
      .catch((err) => {
        toast.error("Fetching category list failed");
      });
  };
  useEffect(() => {
    initializeCall();
  }, []);
  const checkError = () => {
    if (!blogDetails.title) {
      toast.error("Title is Required..!", { autoClose: 2000 });
      return false;
    }
    if (!blogContent) {
      toast.error("Body is Required..!", { autoClose: 2000 });
      return false;
    }
    if (blogDetails.category.length === 0) {
      toast.error("Category is Required..!", { autoClose: 2000 });
      return false;
    }
    if (!summary) {
      toast.error("Summary is Required", { autoClose: 2000 });
      return false;
    }
    return true;
  };
  const blogCall = () => {
    if (checkError()) {
      let body = {
        title: blogDetails.title,
        categories: blogDetails.category.map((iter) => {
          return {
            categoryId: parseInt(iter.value),
          };
        }),
        showToFranchise: blogDetails.showToFranchise,
        isSendEmail: blogDetails.isSendEmail,
        publishedDate: publishedDate,
      };
      if (isBlogContentChange) {
        body["body"] = encodeURIComponent(blogContent);
      }
      if (summary) {
        body["summary"] = summary;
      }
      if (files.length > 0 && !blogDetails.id) {
        body["attachment"] = files;
      }
      if (blogDetails.id) {
        invokeApi(
          "PUT",
          `${INTRANETHOSTNAME}${REST_URLS.UPDATE_BLOG}${blogDetails.id}`,
          body
        ).then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setModalOpen(false);
            toast.success("Updated Successfully", { autoClose: 2000 });
            loadData();
            setBlogDetails({
              id: null,
              title: "",
              category: [],
              showToFranchise: true,
              isSendEmail: true,
            });
            setBlogContent("");
            setSummary("");
            setIsBlogContentChange(false);
            setFiles([]);
            setPublishedDate(new Date());
          }
        });
      } else {
        if (emailSendTo.length >= 1) {
          const senderType = emailSendTo.map((ele) => ele?.value);
          body["sendTo"] = senderType;
        }
        invokeApi("POST", `${INTRANETHOSTNAME}${REST_URLS.CREATE_BLOG}`, body)
          .then((response) => {
            if (!response.message) {
              setModalOpen(false);
              toast.success("Blog Created Successfully");
              loadData();
              setBlogDetails({
                id: null,
                title: "",
                category: [],
                showToFranchise: true,
                isSendEmail: true,
              });
              setBlogContent("");
              setSummary("");
              setEmailSendTo([]);
              setIsBlogContentChange(false);
              setPublishedDate(new Date());
              setFiles([]);
            } else {
              toast.error(response.message, { autoClose: 2000 });
            }
          })
          .catch((err) => {
            toast.error("Blog Creation failed");
          });
      }
    }
  };
  const categoryCall = () => {
    if (category != null) {
      invokeApi("POST", `${INTRANETHOSTNAME}${REST_URLS.CREATE_CATEGORY}`, {
        name: category,
      })
        .then((response) => {
          if (!response.message) {
            setCatModalOpen(false);
            toast.success("Category Created Successfully");
            initializeCall();
            setCategory(null);
          } else {
            toast.error(response.message, { autoClose: 2000 });
          }
        })
        .catch((err) => {
          toast.error("Category Creation failed");
        });
    }
  };
  const editBlogHandler = (iter) => {
    setModalOpen(true);
    const category = iter.categories.map((ele) => ({
      label: ele.categoryId.name,
      value: ele.categoryId.id,
    }));
    setBlogDetails({
      id: parseInt(iter.id),
      title: iter.title,
      category: category,
      showToFranchise: iter.showToFranchise,
    });
    setBlogContent(decodeURIComponent(iter?.body));
    setSummary(iter?.summary);
    setPublishedDate(iter.publishedDate);
  };

  useEffect(() => {
    if (modalOpen) {
      setBlogFlag(false);
    }
  }, [modalOpen]);
  const handleClickFilter = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFilter(null);
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="blogs">
      <div className={!modalOpen && "header"}>
        <span>
          {!modalOpen && (
            <>
              <Grid
                md={12}
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Grid item md={10}>
                  <h3>Bulletins</h3>
                </Grid>
                <Grid item md={0.3} sx={{ display: "flex" }}>
                  <FilterAltIcon
                    sx={{ cursor: "pointer", mr: 2 }}
                    onClick={handleClickFilter}
                  />
                  <Menu
                    anchorEl={anchorFilter}
                    open={openFilters}
                    onClose={handleCloseFilter}
                    PaperProps={{
                      style: {
                        borderRadius: "0.7rem",
                        marginTop: "1rem",
                        width: "20rem",
                        height: "20rem",
                        padding: "0.2rem 1rem",
                      },
                    }}
                  >
                    <div>
                      <h4 style={{ textAlign: "center" }}>Filters</h4>
                      <Grid
                        item
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 2,
                          mt: 1,
                        }}
                      >
                        <Grid
                          item
                          md={5.8}
                          sx={{
                            mb: 0.5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            fullWidth
                            className="subs-text-field new-textfield"
                            label=" From"
                            sx={{ backgroundColor: "white" }}
                            value={blogFilters?.startDate || ""}
                            onChange={(event) => {
                              setBlogFilters((prevVal) => ({
                                ...prevVal,
                                startDate: event.target.value,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={5.8}
                          sx={{
                            mb: 0.5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            className="subs-text-field new-textfield"
                            label="To"
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                            value={blogFilters?.endDate || ""}
                            onChange={(event) => {
                              setBlogFilters((prevVal) => ({
                                ...prevVal,
                                endDate: event.target.value,
                              }));
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{
                          mb: 2,
                        }}
                      >
                        <AutocompleteComponent
                          onClick={() => {
                            clearProperty("category", setBlogFilters);
                          }}
                          label="Select Category"
                          onChange={(e, val) => {
                            if (val) {
                              setProperty(
                                "category",
                                val?.value,
                                setBlogFilters
                              );
                            } else {
                              clearProperty("category", setBlogFilters);
                            }
                          }}
                          options={categoryList}
                          value={
                            categoryList.find(
                              (item) => item.value === blogFilters?.category
                            ) || null
                          }
                        />
                      </Grid>
                      <Button
                        variant="contained"
                        className="common-rnd-btn-10"
                        onClick={() => {
                          loadData();
                          handleCloseFilter();
                        }}
                        sx={{
                          float: "right",
                          marginTop: "7rem",
                          borderRadius: "10px",
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </Menu>
                </Grid>
                <Grid item md={1.2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setFiles([]);
                      setModalOpen(true);
                    }}
                  >
                    Create New
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  pb: 1,
                }}
              ></Grid>
            </>
          )}
        </span>
      </div>
      {!modalOpen ? (
        <div className="blogsList">
          {tableData?.results?.map((iter, pos) => {
            const encodeBody = decodeURIComponent(iter.body);
            const bodyText = document.createElement("div");
            bodyText.innerHTML = encodeBody;

            return (
              <div
                className="blog-con"
                onClick={() => {
                  setBlog(iter.body);
                  setFiles(iter?.attachment);
                  setBlogFlag(true);
                }}
              >
                <div className="blog-info-con">
                  <div className="blog-title ">{iter.title}</div>
                  <div className="blog-right-con">
                    {iter?.isSendEmail && iter?.isEmailSent === false ? (
                      <span className="email-error">
                        Email failed. Contact support.
                      </span>
                    ) : (
                      <EditIcon onClick={() => editBlogHandler(iter)} />
                    )}
                  </div>
                </div>
                <div className="creation-info bulletin-creation">
                  <PersonIcon fontSize="small" sx={{ mr: "0.5rem" }} />
                  <p className="name">{iter?.createdBy?.name}</p>
                  <FiberManualRecordIcon
                    fontSize="x-small"
                    sx={{ mr: "0.5rem", ml: "0.5rem" }}
                  />
                  <p>{moment(iter?.publishedDate).format("DD-MM-YYYY")}</p>
                </div>
                <p>{iter?.summary}</p>
              </div>
            );
          })}
          <Drawer
            anchor="right"
            open={blogFlag}
            onClose={() => setBlogFlag(false)}
          >
            <ShowFullBlog blogData={blog} files={files} />
          </Drawer>
        </div>
      ) : (
        <div className="form">
          <div>
            <Grid
              container
              md={12}
              spacing={2}
              sx={{ display: "flex", alignItems: "stretch", mb: 3 }}
            >
              <Grid item md={2.5}>
                <TextField
                  label="Title"
                  value={blogDetails.title}
                  size="small"
                  fullWidth
                  className="new-textfield"
                  onChange={(val) => {
                    setBlogDetails({
                      ...blogDetails,
                      title: val.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <AutoCompleteMultiSelect
                  value={blogDetails.category || []}
                  onChange={(e, val) => {
                    setBlogDetails({
                      ...blogDetails,
                      category: val,
                    });
                  }}
                  options={categoryList}
                  placeholder="Select Category"
                  className="bullentin-autocomplete"
                />
              </Grid>
              {!blogDetails?.id && (
                <Grid item md={3}>
                  <AutoCompleteMultiSelect
                    value={emailSendTo || []}
                    onChange={(e, val) => {
                      setEmailSendTo(val);
                    }}
                    options={BLOG_EMAIL_LIST}
                    placeholder="Function"
                    className="bullentin-autocomplete"
                  />
                </Grid>
              )}

              <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Publish Date"
                    inputFormat="DD/MM/YYYY"
                    value={publishedDate ? publishedDate : null}
                    onChange={(value) => {
                      setPublishedDate(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="new-textfield"
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={1.5}>
                <Button
                  variant="contained"
                  className="common-rnd-btn-10"
                  color="primary"
                  onClick={() => {
                    setCatModalOpen(true);
                  }}
                >
                  Add Category
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              md={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item md={2.5}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={blogDetails.showToFranchise}
                        onChange={(event) => {
                          setBlogDetails((prevVal) => ({
                            ...prevVal,
                            showToFranchise: event.target.checked,
                          }));
                        }}
                      />
                    }
                    label="Show to Franchise"
                  />
                </FormGroup>
              </Grid>
              {!blogDetails?.id && (
                <Grid item md={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={blogDetails.isSendEmail}
                          onChange={(event) => {
                            setBlogDetails((prevVal) => ({
                              ...prevVal,
                              isSendEmail: event.target.checked,
                            }));
                          }}
                        />
                      }
                      label="Send Email"
                    />
                  </FormGroup>
                </Grid>
              )}
              {!blogDetails?.id && (
                <Grid
                  item
                  md={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <SubwayFileUpload
                    title="Attachment"
                    url={`${INTRANETHOSTNAME}${REST_URLS.UPLOAD}`}
                    callBack={(newFile) => {
                      setFiles([
                        ...files,
                        {
                          url: newFile[0].url,
                          title: newFile[0].originalname,
                        },
                      ]);
                    }}
                    isIntranetUpload={true}
                    providedLimit={15728640}
                    fileFormats={[
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "application/pdf",
                    ]}
                    accept=".pdf, image/*"
                    lmtMsg="File size cannot be more than 15 MB"
                  />
                  {files.length > 0 && (
                    <div className="blog-attachment">
                      {files.map((ele, index) => {
                        return <div>{ele?.title}</div>;
                      })}
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid md={12} container sx={{ mb: 2 }}>
              <TextareaAutosize
                className="new-textfield"
                minRows={6}
                placeholder="Summary"
                style={{ width: "100%" }}
                value={summary}
                onChange={(e) => {
                  setSummary(e?.target?.value);
                }}
              />
            </Grid>
          </div>
          <div>
            <FormLabel>Body</FormLabel>
            <CKEditor
              editor={ClassicEditor}
              data={blogContent}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "Table", // Add "Table" button here
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              onChange={(event, editor) => {
                setIsBlogContentChange(true);
                setBlogContent(editor.getData());
              }}
            />
          </div>
          <div className="btn-quill-con">
            <CommonBtn size="small" onClick={blogCall} sx={{ mr: "1rem" }} />
            <CommonBtn
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(false);
                setBlogDetails({
                  id: null,
                  title: "",
                  project: [],
                  category: [],
                  attachments: [],
                  link: "",
                  showToFranchise: true,
                  isSendEmail: true,
                });
                setBlogContent("");
                setSummary("");
                setIsBlogContentChange(false);
                setPublishedDate(new Date());
                setFiles([]);
              }}
              text="Cancel"
            />
          </div>
        </div>
      )}
      {catModalOpen && (
        <CustomModal
          title="Create Category"
          onClose={() => setCatModalOpen(false)}
        >
          <Box>
            <Grid container spacing={1} className="g1">
              <Grid item xs={12} className="pt0">
                <TextField
                  label="Category"
                  size="small"
                  fullWidth
                  value={category}
                  onChange={(val) => {
                    setCategory(val.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <center className="mt10">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  categoryCall();
                }}
                className="mr20 common-rnd-btn-10"
              >
                Save
              </Button>

              <Button
                variant="outlined"
                color="error"
                className="common-rnd-btn-10"
                onClick={() => {
                  setCatModalOpen(false);
                  setCategory(null);
                  setIsBlogContentChange(false);
                }}
              >
                Cancel
              </Button>
            </center>
          </Box>
        </CustomModal>
      )}
    </div>
  );
};

export default Blogs;
