/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import Select from "react-select";
import { Button } from "@mui/material";
import { getHeaderConfig, tatConfig, TICKET_REPORT_STATUS } from "./helper";
import SiTable from "../../../common-utilities/core/table";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import "./index.scss";
import { exportToExcel } from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { dayDifference, getEndOfDay, getStartOfDay } from "../../../common-utilities/utils/time";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};
const TicketReports = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState({});
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  useEffect(() => {
    if ((reportData.results || []).length !== 0) {
      setShowDownloadBtn(true);
    } else {
      setShowDownloadBtn(false);
    }
  }, [reportData]);

  useEffect(() => {
    fetchDataHandler({ page: 1, limit: 12, sortBy: "-createdAt" });
  }, []);

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      status: "RESOLVED",
      startDate: filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null,
    };

    const response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const { id, createdAt, category, resolvedAt, store } = item;
        const createdOn = createdAt
          ? `${new Date(createdAt).toLocaleDateString()} ${new Date(
              createdAt
            ).toLocaleTimeString()}`
          : null;
        const resolvedOn = resolvedAt
          ? `${new Date(resolvedAt).toLocaleDateString()} ${new Date(
              resolvedAt
            ).toLocaleTimeString()}`
          : null;
        return {
          "Ticket Id": id,
          "Store Number": store?.storeNumber,
          "Ticket Category": category?.name,
          "Created On": createdOn,
          "Resolved on": resolvedOn,
          "Resolution Time": dayDifference(createdAt, resolvedAt),
        };
      });
    }
    exportToExcel(results || [], "TAT", null, true);
  };

  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  // filtering reports
  const fetchDataHandler = (filter = {}, getDataForPreviouslyAppliedFilter) => {
    const params = {
      ...filter,
      status: "RESOLVED",
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate
        ? getEndOfDay(dateRange.endDate)
        : null,
      sortBy: "-createdAt",
    };
    if (getDataForPreviouslyAppliedFilter) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s">
          <div>
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </div>
          <SearchIcon
            onClick={() => {
              let filterDataAfterClickingOnApply = {};
              filterDataAfterClickingOnApply = {
                ...filterDataAfterClickingOnApply,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              };
              const newFilters = { ...filters, page: 1 };
              setFilters((prevFilter) => ({ ...prevFilter, page: 1 }));
              setFilterAfterClickingOnApply(filterDataAfterClickingOnApply);

              fetchDataHandler(newFilters);
            }}
            sx={{ cursor: "pointer", marginBottom: 1 }}
          />
        </div>
        {showDownloadBtn && (reportData.results || []).length !== 0 && (
          <DownloadBtn className="mb-5" onClick={downloadReport} />
        )}
      </div>

      <MuiTable
        columnsList={tatConfig()}
        dataList={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
    </div>
  );
};

export default TicketReports;
