import React, { useMemo, useState, useEffect } from "react";
import "./index.scss";
import { useTable } from "react-table";
import { Pagination } from "@mui/material";

const GroupHeadersTable = ({
  columns = [],
  data = [],
  pageCount,
  onChange,
  filters,
}) => {
  const [page, setPage] = useState(1);
  const tableColumns = useMemo(() => columns, []);
  const tableData = useMemo(() => data, []);
  const tableInstance = useTable({
    columns: columns,
    data: data,
  });
  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <>
      <div className="react-table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="si-pagination">
        {pageCount > 1 && (
          <Pagination
            page={page}
            count={pageCount}
            color="primary"
            onChange={(e, page) => {
              setPage(page);
              if (onChange) {
                onChange(e, page);
              }
            }}
          ></Pagination>
        )}
      </div>
    </>
  );
};

export default GroupHeadersTable;
