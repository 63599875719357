import React from "react";
import "./index.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BasicFixed from "./basic-fixed";
import AttributesHistory from "./attributes-history";
import StoreHours from "./store-hours";
import Attributes from "./attributes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "70vh" }}
    >
      {value === index && (
        <Box style={{ padding: "10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StoreAttributes = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={"store"}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ minHeight: "20px" }}
        >
          <Tab label="Store Attributes" className="tab" />
          <Tab label="Attributes History" className="tab" />
          <Tab label="Basic fixed" className="tab" />
          <Tab label="Store Hours" className="tab" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Attributes />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AttributesHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BasicFixed />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StoreHours />
      </TabPanel>
    </Box>
  );
};

export default StoreAttributes;
