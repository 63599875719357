import { belongsToInUserRoles, USER_ROLES } from ".";
import { getOfflineData } from "./offline-services";

export const showSelectDCAccordingToRoles = () => {
  const user = getOfflineData(null, "user");
  return (
    user?.role?.belongsTo !== USER_ROLES.DC ||
    (user?.role?.belongsTo === USER_ROLES.DC &&
      user?.role?.type === USER_ROLES.ACTION_TAKER) ||
    (user?.role?.belongsTo === USER_ROLES.DC &&
      user?.role?.type === USER_ROLES.CREDIT_ISSUER)
  );
};

export const checkForUserRoles = (navigateToLogin) => {
  const user = getOfflineData(null, "user");
  const userIsNotPresent = !user;
  if (userIsNotPresent && navigateToLogin) {
    navigateToLogin("/");
    return {};
  }
  if (userIsNotPresent) {
    return {};
  }

  const role = user?.role;
  const isStoreOwner =
    role?.type === USER_ROLES.STORE_OWNER &&
    role?.belongsTo === belongsToInUserRoles.subway;

  const isStore =
    role?.type === USER_ROLES.STORE &&
    role?.belongsTo === belongsToInUserRoles.subway;

  const isDC =
    role?.type === USER_ROLES.DC && role?.belongsTo === belongsToInUserRoles.dc;

  const isDCQA =
    role?.type === USER_ROLES.QA && role?.belongsTo === belongsToInUserRoles.dc;

  const isDCActionTaker =
    role?.type === USER_ROLES.ACTION_TAKER &&
    role?.belongsTo === belongsToInUserRoles.dc;

  const isDCCreditIssuer =
    role?.type === USER_ROLES.CREDIT_ISSUER &&
    role?.belongsTo === belongsToInUserRoles.dc;

  const isVendor =
    role?.type === USER_ROLES.VENDOR &&
    role?.belongsTo === belongsToInUserRoles.vendor;

  const isVendorQA =
    role?.type === USER_ROLES.QA &&
    role?.belongsTo === belongsToInUserRoles.vendor;
  const isRDUser =
    role?.type === USER_ROLES.RD &&
    role?.belongsTo === belongsToInUserRoles.subway;
  const isAdminUser =
    role?.type === USER_ROLES.ADMIN &&
    role?.belongsTo === belongsToInUserRoles.subway;

  return {
    isStoreOwner,
    isStore,
    isDC,
    isDCQA,
    isDCActionTaker,
    isDCCreditIssuer,
    isVendor,
    isVendorQA,
    isRDUser,
    isAdminUser,
  };
};
