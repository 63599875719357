//Note:  Please keep keys of validDocumentTypes and validDocumentTypesLabels same
import { agreementAndPostAgreementDocsNa } from "./coco-acquired.config";
import {
  accquiredPreAgreementDocsLabelsAndValues,
  agreementAndPostAgreementDocsLabelsAndValues,
  mfaRidersSubCategoriesList as mfaRidersSubCategoriesListForCocoAcquired,
  validCocoAcquiredLicenses,
  agreementAndPostAgreementDocsLabelsAndValuesCocoBuilt,
} from "./coco-acquired.config";
import {
  mfaRidersSubCategoriesList as mfaRidersSubCategoriesListForCocoBuilt,
  preAgreementDocsLabelsAndValues,
  nocForGstSubCategoriesList,
  electricityBillInNameOfLandloadSubCategoriesList,
} from "./coco-built.config";
import { endDateCell, startDateCell } from "./common.config";

export const validDocumentTypes = {
  leaseAgreement: "LEASE_AGREEMENT",
  LOI: "LOI",
  license: "LICENSES",
  acquisition: "ACQUISITION",
  faAgreement: "FA_AGREEMENT",
  preAgreementDocs: "PRE_AGREEMENT_DOCS",
  agreementAndPostAgreementDocs: "AGREEMENT_AND_POST_AGREEMENT_DOCUMENTS",
  mfaRider: "MFA_RIDER",
  franchiseAgreement: "FRANCHISE_AGREEMENT",
  subleaseAgreement: "SUB_LEASE_AGREEMENT",
  operatingAssignment: "OPERATING_ASSIGNMENT",
  nocForGst: "NOC_FOR_GST",
  electricityBillInNameOfLandload: "ELECTRICITY_BILL_IN_NAME_OF_LANDLOAD",
};

export const newNames = {
  tearSheet: "Tear Sheet",
  taxReport: "Tax Report",
};

export const validDocumentTypesLabels = {
  acquisition: "Acquisition Related Documents",
  license: "License",
  leaseAgreement: "Lease Agreement",
  faAgreement: "FA Agreement",
  LOI: "LOI",
  preAgreementDocs: "Pre Agreement Documents",
  agreementAndPostAgreementDocs: "Agreement & Post Agreement Documents",
  mfaRider: "MFA Rider",
  franchiseAgreement: "Franchise Agreement",
  subleaseAgreement: "Sub Lease Agreement",
  operatingAssignment: "Operating Assignment",
  nocForGst: "NOC For GST",
  electricityBillInNameOfLandload: "Electricity Bill In Name Of Landload",
  optionAgreement: 'Option Agreement'
};

export const validStoreTypes = {
  cocoBuilt: "COCO BUILT",
  cocoAcquired: "COCO ACQUIRED",
  fofo: "FOFO",
};

export const StoreLabels = [
  { label: validStoreTypes.cocoBuilt, value: validStoreTypes.cocoBuilt },
  { label: validStoreTypes.cocoAcquired, value: validStoreTypes.cocoAcquired },
  { label: validStoreTypes.fofo, value: validStoreTypes.fofo },
];

export const validCocoAcquiredAcquistionRelatedDocuments = {
  bta: "BTA",
  faCancellation: "FA Cancellation",
  subLeaseCancellation: "Sub Lease Cancellation",
};

export function getAcquistionRelaetdDocuments() {
  return [...Object.values(validCocoAcquiredAcquistionRelatedDocuments)];
}

export const validAcquistionRelatedDocuments = getAcquistionRelaetdDocuments();

export const DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE = {
  [validStoreTypes.cocoBuilt]: [
    {
      label: validDocumentTypesLabels.preAgreementDocs,
      value: validDocumentTypes.preAgreementDocs,
    },
    {
      label: validDocumentTypesLabels.agreementAndPostAgreementDocs,
      value: validDocumentTypes.agreementAndPostAgreementDocs,
    },
    {
      label: validDocumentTypesLabels.license,
      value: validDocumentTypes.license,
    },
    {
      label: validDocumentTypesLabels.mfaRider,
      value: validDocumentTypes.mfaRider,
    },
    {
      label: validDocumentTypesLabels.nocForGst,
      value: validDocumentTypes.nocForGst,
    },
    {
      label: validDocumentTypesLabels.electricityBillInNameOfLandload,
      value: validDocumentTypes.electricityBillInNameOfLandload,
    },
  ],
  [validStoreTypes.cocoAcquired]: [
    {
      label: validDocumentTypesLabels.preAgreementDocs,
      value: validDocumentTypes.preAgreementDocs,
    },
    {
      label: validDocumentTypesLabels.agreementAndPostAgreementDocs,
      value: validDocumentTypes.agreementAndPostAgreementDocs,
    },
    {
      label: validDocumentTypesLabels.license,
      value: validDocumentTypes.license,
    },
    {
      label: validDocumentTypesLabels.mfaRider,
      value: validDocumentTypes.mfaRider,
    },
    {
      label: validDocumentTypesLabels.nocForGst,
      value: validDocumentTypes.nocForGst,
    },
    {
      label: validDocumentTypesLabels.electricityBillInNameOfLandload,
      value: validDocumentTypes.electricityBillInNameOfLandload,
    },
  ],
  [validStoreTypes.fofo]: [
    {
      label: validDocumentTypesLabels.franchiseAgreement,
      value: validDocumentTypes.franchiseAgreement,
    },
    {
      label: validDocumentTypesLabels.leaseAgreement,
      value: validDocumentTypes.subleaseAgreement,
    },
    {
      label: validDocumentTypesLabels.operatingAssignment,
      value: validDocumentTypes.operatingAssignment,
    },
  ],
};

export const validCocoBuiltLicenses = {
  fssai: "FSSAI",
  firenoc: "Fire NOC",
  changeOfUser: "Change of User",
  trade: "Trade",
  shopEstablishment: "Shop Establishment",
  // leaseRider: "Lease Rider",
  others: "Others",
};
export const validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType =
  {
    [validStoreTypes.cocoBuilt]: {
      [validDocumentTypes.agreementAndPostAgreementDocs]: Object.values(
        agreementAndPostAgreementDocsLabelsAndValuesCocoBuilt
      ),
    },
    [validStoreTypes.cocoAcquired]: {
      [validDocumentTypes.agreementAndPostAgreementDocs]: Object.values(
        agreementAndPostAgreementDocsLabelsAndValues
      ),
    },
  };
export const validPreAgreementTypesOnTheBasisOfStoreAndDocumentType = {
  [validStoreTypes.cocoBuilt]: {
    [validDocumentTypes.preAgreementDocs]: Object.values(
      preAgreementDocsLabelsAndValues
    ),
  },
  [validStoreTypes.cocoAcquired]: {
    [validDocumentTypes.preAgreementDocs]: Object.values(
      accquiredPreAgreementDocsLabelsAndValues
    ),
  },
};
export const validLicenseTypesOnTheBasisOfStoreAndDocumentType = {
  [validStoreTypes.cocoBuilt]: {
    [validDocumentTypes.license]: Object.values(validCocoBuiltLicenses),
  },
  [validStoreTypes.cocoAcquired]: {
    [validDocumentTypes.license]: Object.values(validCocoAcquiredLicenses),
  },
};

export const fofoLeaseAgreementLeaseTypes = [
  {
    label: "Data Validation - Direct Lease",
    value: "Data Validation - Direct Lease",
  },
  { label: "EIPL Lease", value: "EIPL Lease" },
];

export const validDocumentTypeForTypeofDocumentAndStoreType = {
  [validStoreTypes.cocoBuilt]: {
    [validDocumentTypes.preAgreementDocs]: [
      ...validPreAgreementTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.preAgreementDocs],
    ],
    [validDocumentTypes.license]: [
      ...validLicenseTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.license],
    ],
    [validDocumentTypes.agreementAndPostAgreementDocs]: [
      ...validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.agreementAndPostAgreementDocs],
    ],
    [validDocumentTypes.mfaRider]: mfaRidersSubCategoriesListForCocoBuilt,
    [validDocumentTypes.nocForGst]: nocForGstSubCategoriesList,
    [validDocumentTypes.electricityBillInNameOfLandload]:
      electricityBillInNameOfLandloadSubCategoriesList,
  },
  [validStoreTypes.cocoAcquired]: {
    [validDocumentTypes.preAgreementDocs]: [
      ...validPreAgreementTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.preAgreementDocs],
    ],
    [validDocumentTypes.license]: [
      ...validLicenseTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.license],
    ],
    [validDocumentTypes.agreementAndPostAgreementDocs]: [
      ...validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.agreementAndPostAgreementDocs],
    ],
    [validDocumentTypes.mfaRider]: mfaRidersSubCategoriesListForCocoAcquired,
    [validDocumentTypes.nocForGst]: nocForGstSubCategoriesList,
    [validDocumentTypes.electricityBillInNameOfLandload]:
      electricityBillInNameOfLandloadSubCategoriesList,
  },
  [validStoreTypes.fofo]: {
    [validDocumentTypes.franchiseAgreement]: [
      {
        label: validDocumentTypesLabels.franchiseAgreement,
        value: validDocumentTypes.franchiseAgreement,
      },
    ],
    [validDocumentTypes.subleaseAgreement]: [
      {
        label: validDocumentTypesLabels.subleaseAgreement,
        value: validDocumentTypes.subleaseAgreement,
      },
    ],
    [validDocumentTypes.operatingAssignment]: [
      {
        label: validDocumentTypesLabels.operatingAssignment,
        value: validDocumentTypes.operatingAssignment,
      },
    ],
  },
};

export const allvalidDocumentsForTypeofDocumentAndStoreType = {
  [validStoreTypes.cocoBuilt]: {
    [validDocumentTypes.preAgreementDocs]: [
      ...validPreAgreementTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.preAgreementDocs],
    ],
    [validDocumentTypes.license]: [
      ...validLicenseTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.license],
    ],
    [validDocumentTypes.agreementAndPostAgreementDocs]: [
      ...validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoBuilt
      ][validDocumentTypes.agreementAndPostAgreementDocs],
    ],
    [validDocumentTypes.mfaRider]: [...mfaRidersSubCategoriesListForCocoBuilt],
    [validDocumentTypes.nocForGst]: [...nocForGstSubCategoriesList],
    [validDocumentTypes.electricityBillInNameOfLandload]: [
      ...electricityBillInNameOfLandloadSubCategoriesList,
    ],
  },
  [validStoreTypes.cocoAcquired]: {
    [validDocumentTypes.preAgreementDocs]: [
      ...validPreAgreementTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.preAgreementDocs],
    ],
    [validDocumentTypes.license]: [
      ...validLicenseTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.license],
    ],
    [validDocumentTypes.agreementAndPostAgreementDocs]: [
      ...validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType[
        validStoreTypes.cocoAcquired
      ][validDocumentTypes.agreementAndPostAgreementDocs],
    ],
    [validDocumentTypes.mfaRider]: mfaRidersSubCategoriesListForCocoAcquired,
    [validDocumentTypes.nocForGst]: nocForGstSubCategoriesList,
    [validDocumentTypes.electricityBillInNameOfLandload]:
      electricityBillInNameOfLandloadSubCategoriesList,
  },
  [validStoreTypes.fofo]: {
    [validDocumentTypes.franchiseAgreement]: [
      {
        label: validDocumentTypesLabels.franchiseAgreement,
        value: validDocumentTypes.franchiseAgreement,
      },
    ],
    [validDocumentTypes.subleaseAgreement]: [
      {
        label: validDocumentTypesLabels.subleaseAgreement,
        value: validDocumentTypes.subleaseAgreement,
      },
    ],
    [validDocumentTypes.operatingAssignment]: [
      {
        label: validDocumentTypesLabels.operatingAssignment,
        value: validDocumentTypes.operatingAssignment,
      },
    ],
  },
};

export const getAllDocumentsDetailsForStoreTypeAndDocumentType = (
  storeType,
  type,
  valueKey = "value"
) => {
  let details = [];
  switch (storeType) {
    case validStoreTypes.cocoAcquired:
      switch (type) {
        case validDocumentTypes.preAgreementDocs:
          details = [startDateCell(valueKey, null, true, "Document Date")];
          // details = [
          //   leaseStart,
          //   leaseEnd,
          //   monthlyRentMgStartYear,
          //   monthlyRentRevenueShareOnDineInStartYear,
          //   monthlyRentRevenueShareOnDeliveryInStartYear,
          //   landlord,
          //   leaseAddress,
          //   fitOutPeriod,
          //   lockInPeriod,
          // ];
          break;
        case validDocumentTypes.license:
          details = [
            startDateCell(valueKey, null, true),
            endDateCell(valueKey, null, true),
            { label: "License Type", [valueKey]: "licenseType" },
          ];
          break;

        case validDocumentTypes.agreementAndPostAgreementDocs:
          details = [
            startDateCell(valueKey, true, (data) => {
              return agreementAndPostAgreementDocsNa.includes(
                data?.additionalDetails?.documentType
              );
            }),
            endDateCell(valueKey, true, (data) => {
              return agreementAndPostAgreementDocsNa.includes(
                data?.additionalDetails?.documentType
              );
            }),
            { label: "Document Date", [valueKey]: "startDate" },
            (data) => {
              return agreementAndPostAgreementDocsNa.includes(
                data?.additionalDetails?.documentType
              );
            },
          ];
          break;
        case validDocumentTypes.mfaRider:
          details = [
            startDateCell(valueKey, null, true),
            endDateCell(valueKey, null, true),
          ];
          break;
        default:
          break;
      }
      break;
    case validStoreTypes.cocoBuilt:
      switch (type) {
        case validDocumentTypes.preAgreementDocs:
          details = [startDateCell(valueKey, null, true, "Document Date")];
          // details = [
          //   leaseStart,
          //   leaseEnd,
          //   monthlyRentMgStartYear,
          //   monthlyRentRevenueShareOnDineInStartYear,
          //   monthlyRentRevenueShareOnDeliveryInStartYear,
          //   landlord,
          //   leaseAddress,
          //   fitOutPeriod,
          //   lockInPeriod,
          // ];
          break;
        case validDocumentTypes.license:
          details = [
            startDateCell(valueKey, null, true),
            endDateCell(valueKey, null, true),
            { label: "License Type", [valueKey]: "licenseType" },
          ];
          break;

        case validDocumentTypes.agreementAndPostAgreementDocs:
          details = [
            startDateCell(valueKey, null, true),
            endDateCell(valueKey, null, true),
          ];
          break;
        case validDocumentTypes.mfaRider:
          details = [
            startDateCell(valueKey, true),
            endDateCell(valueKey, null, true),
          ];
          break;
        default:
          break;
      }
      break;

    case validStoreTypes.fofo:
      switch (type) {
        case validDocumentTypes.franchiseAgreement:
          details = [
            { label: "Start Date", [valueKey]: "startDate" },
            { label: "End Date", [valueKey]: "endDate" },
          ];
          break;
        case validDocumentTypes.subleaseAgreement:
          details = [
            { label: "Start Date", [valueKey]: "startDate" },
            { label: "End Date", [valueKey]: "endDate" },
          ];
          break;
        case validDocumentTypes.operatingAssignment:
          details = [
            { label: "Start Date", [valueKey]: "startDate" },
            { label: "End Date", [valueKey]: "endDate" },
          ];
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return details;
};

export const FOFOLeaseTypes = ["Data Validation - Direct Lease", "EIPL Lease"];

export const reportLabels = [
  { label: "General Reports", value: "General Reports" },
  { label: "Upload Status Reports", value: "Upload Status Reports" },
  { label: "Informational Reports", value: "Informational Reports" },
];

export const reportLabelKeys = {
  general: "General Reports",
  upload: "Upload Status Reports",
  informational: "Informational Reports",
};

const getAllLabelKeyValues = () => {
  let keys = {};
  Object.keys(validDocumentTypes).forEach((item) => {
    const key = validDocumentTypes[item];
    const label = validDocumentTypesLabels[item];
    keys[key] = label;
  });
  return keys;
};

export const backEndKeysToLabel = getAllLabelKeyValues();
