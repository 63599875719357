import { pick } from '../../../../../common-utilities/utils';

export const setEditCreateData = (data, setData) => {
  if (data) {
    const allData = pick(data, ['id', 'label', 'cfKey', 'storeType']);
    allData.category = data?.category?.id || null
    setData(allData);
  } else {
    setData({});
  }
};

