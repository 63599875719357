import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { INTRANETHOSTNAME } from '../../../packages/common-utilities/utils/end-points';
import { REST_URLS } from '../../../packages/common-utilities/utils/end-points/intranet';
import { toast } from 'react-toastify';
import { appSlugs } from '../../../utils/constants';
import { setProperty } from '../../../packages/common-utilities/utils/misc';

export const getAllDepartmentNames = (setDataFunc, addNoDepartment) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.GET_DEPARTMENT_LIST}`
  )
    .then((res) => {
      if (res?.message) {
        return;
      }
      const results =
        res?.map?.((item) => {
          return {
            label: item?.name,
            value: item?._id,
          };
        }) || [];
      if (addNoDepartment) {
        results.unshift({
          label: 'No Department',
          value: 0,
        });
      }
      setDataFunc([...results]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllDepartmentRoles = (filter, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.GET_DEPARTMENT_ROLE_LIST}`,
    null,
    filter
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc([]);
        return;
      }
      const results =
        res?.map?.((item) => {
          return {
            label: item?.name,
            value: parseInt(item.id),
          };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {
      setDataFunc([]);
      console.log(err);
    });
};

export const nonCreatedVendorList = (setDataFunc, filter) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INTRANETHOSTNAME}${REST_URLS.NON_CREATE_INVOICE_VENDORS}`,
    null,
    filter
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc([]);
        return;
      }
      const results =
        res?.map?.((item) => {
          return {
            label: `${item?.name} - ${item?.code}`,
            value: parseInt(item.id),
          };
        }) || [];
      setDataFunc(results);
    })
    .catch((err) => {
      setDataFunc([]);
      console.log(err);
    });
};

export const getUserRoleList = (setUserRolesList, setConfig) => {
  invokeApi(HTTP_METHODS.GET, `${INTRANETHOSTNAME}${REST_URLS.GET_USER_ROLES}`)
    .then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
        return;
      }
      let financeAppId = null;
      const allLists = res?.reduce(
        ({ userRoleList, financeRoleList }, role) => {
          if (role?.app) {
            const financeApp = role?.app?.appKey === appSlugs.subwayFinace;
            if (financeApp) {
              financeRoleList.push({
                label: role?.name,
                value: parseInt(role?.id),
              });
              financeAppId = role?.app.appId;
            }
          } else {
            userRoleList.push({
              label: role?.name,
              value: parseInt(role?.id),
            });
          }
          return { userRoleList, financeRoleList };
        },
        { userRoleList: [], financeRoleList: [] }
      ) || { userRoleList: [], financeRoleList: [] };

      const { userRoleList, financeRoleList } = allLists;
      setProperty(
        ['financeRoleList', 'financeAppId'],
        [financeRoleList, financeAppId],
        setConfig
      );
      setUserRolesList(userRoleList);
    })
    .catch((err) => console.log(err));
};
