import { tokenMainKeys } from "../../../../utils/constants";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { userTypes } from "../../utils/constants";
import { invokeApi } from "../../utils/http-service";

export const ticketActions = {
  commentModal: "Ticket Comment Modal",
};

export const actionHandler = (data, type, setDataFunc) => {
  switch (type) {
    case ticketActions.commentModal:
      setDataFunc({
        type: ticketActions.commentModal,
        data,
        title: "Ticket Comments",
      });
      break;
    default:
      break;
  }
};

export const checkTicketCreation = (setCreateTicketOption) => {
  const user = getOfflineData(tokenMainKeys.technet, "user");

  if (user?.type?.toUpperCase() === userTypes.AGENT) {
    setCreateTicketOption(true);
    return
  }
  invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.ALLOW_TICKET_CREATION}`)
    .then((res) => {
      if (res?.allowCreateTicket && setCreateTicketOption) {
        setCreateTicketOption(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
