/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import PersonNameDetails from "./person-name-details";
import { Button } from "@mui/material";
import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";
import PersonStoreListing from "../personStoreListing";
import PersonHistory from "../person-history";
import PersonAddressCommunication from "./person-address-communication";
import PersonMemo from "./person-memo";
export const ActionButtons = () => {
  return (
    <div>
      <Button variant="contained" sx={{ float: "right", mt: 2 }} size="small">
        Save
      </Button>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box style={{ padding: "10px", backgroundColor: "white" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ProfileDetails = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-person-container">
      <div className="subs-mainTabContainer">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="subs-tab-container subs-tab-container-none-border"
        >
          <Grid container spacing={0}>
            <Grid item md={12} xs={12} sm={7} className="custom-tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab label="Person Details" />
                {/* <Tab label="Person Details 2" /> */}
                <Tab label="Person Store Listing" />

                <Tab label="Person Address/Communication Listing" />
                <Tab label="Person History" />
                <Tab label="Person Memos" />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="tab-panel-container">
        <TabPanel value={value} index={0}>
          <PersonNameDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PersonStoreListing />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PersonAddressCommunication />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PersonHistory />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PersonMemo />
        </TabPanel>
      </div>
    </div>
  );
};

export default ProfileDetails;
