import { pick } from "../../../../common-utilities/utils";
import { BD_LEAD_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../../common-utilities/utils/end-points/bd-lead";
import { deleteObjectProperties } from "../../../../common-utilities/utils/misc";
import { HTTP_METHODS } from "../../../utils/http-service";

import {
  competitorInformationConfig,
  nearbySubwayDataConfig,
  stageGateConfig,
  statusInformationConfig,
} from "../config";

export const actionIconHandler = (data, type, navigate, setModalDetails) => {
  const {
    locationInfo = {},
    competitorInformation = {},
    nearbySubway = [],
    stageGates = [],
  } = data;

  switch (type) {
    case "edit":
      navigate(`/bd-lead/lead/edit/${data.id}`);
      break;

    case "drop lead":
      setModalDetails({
        data: {
          delete: true,
          url: `${BD_LEAD_HOSTNAME}${REST_URLS.DROP_LEAD_BY_ID}${data?.id}`,
          message: "Lead Dropped Successfully",
          method: HTTP_METHODS.PUT,
        },
        title: "Drop Lead",
        useSiTable: false,
        labelMap: [],
      });
      break;
    case "show status information":
      {
        let status =
          typeof locationInfo === "object" ? locationInfo.status || {} : {};
        if (typeof status !== "object") {
          status = {};
        }
        setModalDetails({
          data: status,
          title: "Status Information",
          useSiTable: false,
          labelMap: statusInformationConfig(),
        });
      }
      break;
    case "show competitor information":
      {
        let allData = [];
        let allInformation =
          typeof competitorInformation === "object"
            ? { ...competitorInformation }
            : {};
        Object.keys(allInformation).forEach((item) => {
          const value = allInformation[item];
          if (!Array.isArray(value)) {
            allData.push(value);
          } else {
            value.forEach((data) => {
              allData.push(data);
            });
          }
        });

        let allLabels = competitorInformationConfig();
        setModalDetails({
          data: allData,
          title: "Competitor Information",
          useSiTable: true,
          labelMap: allLabels.slice(0, allLabels.length - 1),
        });
      }
      break;
    case "show nearby subway data":
      {
        const allData = Array.isArray(nearbySubway) ? [...nearbySubway] : [];
        let allLabels = nearbySubwayDataConfig();
        setModalDetails({
          data: allData,
          title: "Nearby Subway Data",
          useSiTable: true,
          labelMap: allLabels.slice(0, allLabels.length - 1),
        });
      }
      break;
    case "show stage gates":
      {
        let allData = Array.isArray(stageGates) ? [...stageGates] : [];
        allData = allData.map((item) => {
          return { ...item, stageName: item?.stage?.stageName };
        });
        let allLabels = stageGateConfig();
        setModalDetails({
          data: allData,
          title: "Stage Gates",
          useSiTable: true,
          labelMap: allLabels.slice(0, allLabels.length - 1),
        });
      }
      break;
    case "delete lead":
      setModalDetails({
        data: {
          delete: true,
          url: `${BD_LEAD_HOSTNAME}${REST_URLS.DELETE_LEAD}${data?.id}`,
          message: "Lead Deleted Successfully",
        },
        title: "Delete Lead",
        useSiTable: false,
        labelMap: [],
      });
      break;
    case "create-lead-store":
      {
        const addressKeys = ["catchment", "city", "state", "country"];

        const storeData = pick(data?.locationInfo || {}, [
          ...addressKeys,
          "region",
        ]);

        const addressObj = pick(storeData, addressKeys);

        const addressLength = Object.keys(addressObj).length;
        const address = Object.keys(addressObj).reduce(
          (finalAddress, addressKey, index) => {
            const addressPart = addressObj?.[addressKey];
            if (index === addressLength - 1) {
              return finalAddress + addressPart;
            }
            return finalAddress + addressPart + ",";
          },
          ""
        );

        if (address) {
          storeData.address = address;
        }
        if (storeData?.region) {
          storeData.region = storeData?.region.toLowerCase();
        }
        deleteObjectProperties(storeData, ["catchment"]);
        setModalDetails({
          type: "create-lead-store",
          url: `${BD_LEAD_HOSTNAME}${REST_URLS.CREATE_LEAD_STORE}${data?.id}`,
          storeData,
        });
      }
      break;
    default:
      break;
  }
};
