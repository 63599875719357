import { Button } from '@mui/material';
import { modalHandler } from '../utils';
import { modalConstants } from '../config/constants';

const MainConfigButtons = ({ setConfig }) => {
  return (
    <div className="create-tab">
      <Button
        onClick={() => {
          modalHandler(null, modalConstants.createFinanceRole, setConfig);
        }}
        variant="outlined"
      >
        Create Role
      </Button>
      <Button
        onClick={() => {
          modalHandler(null, modalConstants.create, setConfig);
        }}
        variant="outlined"
      >
        Create Config
      </Button>
    </div>
  );
};

export default MainConfigButtons;
