import React, { useState, useEffect, useRef } from "react";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getPendingVoucherHeaderConfig } from "./config";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  REST_URLS,
  SLUG,
} from "../../../common-utilities/utils/end-points/petty-expense";
import { getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Drawer,
  Button,
  TextareaAutosize,
  FormControlLabel,
  Radio,
  TextField,
  FormControl,
  Autocomplete,
  RadioGroup,
  Grid,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ViewVoucher from "./viewVoucher";
import CustomModal from "../../../common-utilities/core/modal";
import SearchIcon from "@mui/icons-material/Search";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { exportToExcel } from "../../../common-utilities/utils";
import ViewComment from "./view-comment";
const PendingVouchers = () => {
  const [tableData, setTableData] = useState([]);
  const [showFiles, setShowFiles] = useState({ isShow: false, data: [] });
  const [storeDetails, setStoreDetails] = useState({});
  const [viewVoucher, setViewVoucher] = useState({ isView: false, data: {} });
  const [searchVoucherId, setSearchVoucherId] = useState("");
  const [voucherIdList, setVoucherIdList] = useState([]);
  const [searchVendorCode, setSearchVendorCode] = useState("");
  const [vendorOptions, setVendorOptions] = useState([]);
  const [viewComments, setViewComments] = useState({ isView: false, data: {} });
  const [storeList, setStoreList] = useState([]);
  const [searchStore, setSearchStore] = useState("");
  const [selectedVouchersList, setSelectedVouchersList] = useState([]);
  const [isApproveAll, setIsApproveAll] = useState(false);
  const [approvedModal, setApprovedModal] = useState({
    isOpen: false,
    data: {},
    comment: "",
    payment: "Adjust",
    paidAmount: "",
  });
  const [reconsiderModal, setReconsiderModal] = useState({
    isOpen: false,
    data: {},
    comment: "",
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [bulkComment, setBulkComment] = useState("");
  const buttonRef = useRef(null);
  const userData = getOfflineData("user");
  const getStoreDetails = () => {
    const store = userData?.userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_DETAILS}`,
      null,
      {
        storeNumber: store[0],
        sequenceNumber: store[1],
        satelliteNumber: store[2],
      }
    )
      .then((response) => {
        if (response) {
          setStoreDetails(response);
        }
      })
      .catch((err) => {});
  };
  const viewVoucherHandler = (data, type) => {
    switch (type) {
      case "view files":
        setShowFiles({ isShow: true, data: data?.attachment });
        break;
      case "view voucher":
        setViewVoucher({ isView: true, data: data });
        break;
      case "Approved":
        setApprovedModal({ isOpen: true, data: data });
        break;
      case "Reconsider":
        setReconsiderModal({ isOpen: true, data: data });
        break;
      case "view comments":
        setViewComments({ isView: true, data: data });
        break;
      default:
        break;
    }
  };
  const loadData = (filters) => {
    const payload = { ...filters, status: "Pending" };
    if (storeDetails?.id) {
      payload["store"] = storeDetails.id;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.VOUCHER}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => ({
            ...ele,
            // weekEndingDate: moment(ele.weekEndingDate).format("DD/MM/YYYY"),
          }));
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRowSelection = (event, data, type) => {
    const isChecked = event.target.checked;
    if (type === "selectAll") {
      let selectedVouchers = [];

      if (isChecked) {
        selectedVouchers = [...tableData.results];
      }

      setSelectedVouchersList(selectedVouchers);
    } else {
      let updatedSelectedVouchers = [...selectedVouchersList];
      if (isChecked) {
        updatedSelectedVouchers.push(data);
      } else {
        updatedSelectedVouchers = updatedSelectedVouchers.filter(
          (ele) => ele.id !== data.id
        );
      }
      setSelectedVouchersList(updatedSelectedVouchers);
    }
  };

  const onApproveRejectAction = (isReconsider = false) => {
    const role = userData?.role;
    let payload = {};
    const id = isReconsider
      ? reconsiderModal?.data?._id
      : approvedModal?.data?._id;
    if (!isReconsider) {
      switch (role) {
        case "AM":
          payload["amApproved"] = "Approved";
          payload["amApprovedAt"] = new Date();
          payload["amComments"] = approvedModal?.comment;
          break;
        case "RM":
          payload["rmApproved"] = "Approved";
          payload["rmApprovedAt"] = new Date();
          payload["rmComments"] = approvedModal?.comment;

          break;
        case "Verification":
          payload["verificationAccountApproved"] = "Approved";
          payload["verificationAccountApprovedAt"] = new Date();
          payload["verificationAccountComment"] = approvedModal?.comment;

          break;
        case "Payment":
          payload["paymentAccountApproved"] = "Approved";
          payload["paymentAccountApprovedAt"] = new Date();
          payload["paymentAccountComment"] = approvedModal?.comment;

          break;
        case "Finance":
          payload["financeAccountApproved"] = "Approved";
          payload["financeAccountApprovedAt"] = new Date();
          payload["financeAccountComment"] = approvedModal?.comment;

          break;
        default:
          break;
      }
    } else {
      switch (role) {
        case "AM":
          payload["amApproved"] = "Reconsider";
          payload["amComments"] = reconsiderModal?.comment;
          break;
        case "RM":
          payload["rmApproved"] = "Reconsider";
          payload["rmComments"] = reconsiderModal?.comment;

          break;
        case "Verification":
          payload["verificationAccountApproved"] = "Reconsider";
          payload["verificationAccountComment"] = reconsiderModal?.comment;

          break;
        case "Payment":
          payload["paymentAccountApproved"] = "Reconsider";
          payload["paymentAccountComment"] = reconsiderModal?.comment;

          break;
        case "Finance":
          payload["financeAccountApproved"] = "Reconsider";
          payload["financeAccountComment"] = reconsiderModal?.comment;

          break;
        default:
          break;
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_VOUCHER_STATUS}${id}`,
      payload
    ).then((res) => {
      if (res?.message) {
        toast.error(res.message, { autoClose: 2000 });
        if (isReconsider) {
          setReconsiderModal({ isOpen: false, data: {}, comment: "" });
        } else {
          setApprovedModal({
            isOpen: false,
            data: {},
            comment: "",
            payment: "",
            paidAmount: "",
          });
        }
      } else {
        if (isReconsider) {
          toast.info("Voucher Reconsider Successfully", { autoClose: 2000 });
          setReconsiderModal({ isOpen: false, data: {}, comment: "" });
          loadData(filters);
        } else {
          toast.info("Voucher Approved Successfully", { autoClose: 2000 });

          setApprovedModal({
            isOpen: false,
            data: {},
            comment: "",
            payment: "",
            paidAmount: "",
          });
          loadData(filters);
        }
      }
    });
  };

  const getVoucherIds = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_VOUCHER_IDS}`,
        null,
        {
          page: 1,
          limit: 1000,
          voucherId: searchCode,
        }
      ).then((res) => {
        const voucherList = res?.results?.map((ele) => ele.voucherId);

        setVoucherIdList(voucherList);
      });
    }, 300);
  };
  const voucherInputChange = (e, value) => {
    if (value?.trim()) {
      getVoucherIds(value);
    }
  };
  const getVendor = (searchCode) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.VENDOR}`, null, {
        page: 0,
        limit: 1000,
        name: searchCode,
      }).then((res) => {
        const vendorList = res?.results?.map((ele) => ({
          vendor: `${ele?.code}-${ele?.name}`,
          id: ele?.id,
        }));
        setVendorOptions(vendorList);
      });
    }, 300);
  };
  const inputChange = (e, value) => {
    if (value?.trim()) {
      getVendor(value);
    }
  };
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    const storeNo = searchTerm?.split("-")[0];
    clearTimeout(timeoutId);

    const payload = {};

    if (!isNaN(parseInt(storeNo))) {
      payload.storeNumber = storeNo;
    } else if (searchTerm && typeof searchTerm === "string") {
      payload.restaurantName = searchTerm;
    }

    if (Object.keys(payload).length > 0) {
      setTimeout(() => {
        plainApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
          null,
          payload
        ).then((res) => {
          const storeList = res?.results?.map((ele) => ({
            storeNo: ele?.storeNumber,
            satelliteNo: ele?.satelliteNumber,
            sequenceNo: ele?.sequenceNumber,
            restaurantName: ele?.restaurantName,
            id: ele?.id,
          }));
          setStoreList(storeList);
        });
      }, 300);
    }
  };

  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const onFilterChange = (value, dataLabel) => {
    setFilters({
      ...filters,
      [dataLabel]: value,
    });
  };
  const searchHandler = (pg) => {
    let payload = { ...filters, page: pg };
    if (searchVoucherId) {
      payload["voucherId"] = searchVoucherId;
    }
    if (searchVendorCode) {
      payload["vendor"] = searchVendorCode;
    }
    if (searchStore) {
      payload["store"] = searchStore;
    }
    loadData(payload);
  };
  const approveOrReconsiderInBulk = (isReconsider) => {
    const role = userData?.role;
    const voucherIds = selectedVouchersList
      .filter((ele) => ele.voucherId)
      .map((ele) => ele.voucherId);
    let payload = {
      additionalInfo: {},
      voucherIds,
      type: isReconsider ? "Reconsider" : "Approved",
    };
    switch (role) {
      case "AM":
        payload["additionalInfo"]["amComments"] = bulkComment;
        break;
      case "RM":
        payload["additionalInfo"]["rmComments"] = bulkComment;

        break;
      case "Verification":
        payload["additionalInfo"]["verificationAccountComment"] = bulkComment;

        break;
      case "Payment":
        payload["additionalInfo"]["paymentAccountComment"] = bulkComment;

        break;
      case "Finance":
        payload["additionalInfo"]["financeAccountComment"] = bulkComment;

        break;
      default:
        break;
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_VOUCHER_IN_BULK}`,
      payload
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          if (isReconsider) {
            toast.info("Reconsider Selected Vouchers Successfully");
            setSelectedVouchersList([]);
            setBulkComment("");
            loadData(filters);
          } else {
            toast.success("Approved Selected Vouchers Successfully");
            setSelectedVouchersList([]);
            setBulkComment("");
            loadData(filters);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const downloadVouchersInExcel = () => {
    const payload = { ...filters, status: "Pending" };
    if (storeDetails?.id) {
      payload["store"] = storeDetails.id;
    }
    if (searchVoucherId) {
      payload["voucherId"] = searchVoucherId;
    }
    if (searchVendorCode) {
      payload["vendor"] = searchVendorCode;
    }
    if (searchStore) {
      payload["store"] = searchStore;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.DOWNLOAD_VOUCHERS}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          exportToExcel(res, "Pending Vouchers");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);
  useEffect(() => {
    if (userData?.role === "STORE") {
      getStoreDetails();
    }
  }, [storeDetails]);
  useEffect(() => {
    loadData(filters);
  }, [storeDetails]);
  useEffect(() => {
    if (selectedVouchersList.length > 0) {
      setIsApproveAll(true);
    } else {
      setIsApproveAll(false);
    }
  }, [selectedVouchersList]);
  return (
    <div>
      <Grid md={12} container>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <FileUpload
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            fileFormats={[
              ".csv",
              ".xls",
              ".xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            url={`${HOSTNAME}${REST_URLS.UPLOAD_VOUCHER_EXCEL}`}
            callBack={() => loadData({ ...filters, page: 1 })}
            slug={SLUG}
          />
        </Grid>
      </Grid>
      <Grid md={12} container spacing={1}>
        <Grid md={2} item>
          <Autocomplete
            getOptionLabel={(option) => option?.toString()}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setSearchVoucherId("");
                  setVoucherIdList([]);
                }}
              />
            }
            options={voucherIdList}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Voucher Id"
                sx={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
            onInputChange={voucherInputChange}
            onChange={(event, value) => {
              if (value) {
                setSearchVoucherId(value);
              }
            }}
          />
        </Grid>
        <Grid md={2} item>
          <Autocomplete
            getOptionLabel={(option) => option?.vendor?.toString()}
            className="poper-select"
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setSearchVendorCode("");
                  setVendorOptions([]);
                }}
              />
            }
            options={vendorOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Vendor Name"
                sx={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
            onInputChange={inputChange}
            onChange={(event, value) => {
              if (value) {
                setSearchVendorCode(value?.id);
              }
            }}
          />
        </Grid>
        {userData?.role !== "STORE" && (
          <Grid md={2} item>
            <Autocomplete
              getOptionLabel={(option) =>
                option
                  ? `${option.storeNo}-${option.sequenceNo}-${
                      option.satelliteNo
                    }${
                      option.restaurantName ? `-${option.restaurantName}` : ""
                    }`
                  : ""
              }
              className="poper-select"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    if (filters?.store) {
                      delete filters?.store;
                    }
                    setStoreList([]);
                    setSearchStore("");
                  }}
                />
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              options={storeList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Store"
                  sx={{ backgroundColor: "white" }}
                />
              )}
              onInputChange={storeInputChange}
              onChange={(event, value) => {
                if (value) {
                  setSearchStore(value?.id);
                }
              }}
            />
          </Grid>
        )}
        <Grid md={2} item>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label=" From"
            fullWidth
            sx={{ backgroundColor: "white" }}
            value={filters.startDate || ""}
            onChange={(event) =>
              onFilterChange(event.target.value, "startDate")
            }
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            label="to"
            value={filters.endDate || ""}
            fullWidth
            sx={{ backgroundColor: "white" }}
            onChange={(event) => onFilterChange(event.target.value, "endDate")}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            ref={buttonRef}
            sx={{ borderRadius: "15px" }}
            variant="outlined"
            onClick={() => searchHandler(1)}
            startIcon={<SearchIcon sx={{ cursor: "pointer" }} />}
          >
            Search
          </Button>
        </Grid>
        {tableData?.results?.length > 0 && (
          <Grid
            item
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <DownloadBtn onClick={downloadVouchersInExcel} />
          </Grid>
        )}
      </Grid>
      <MuiTable
        columnsList={getPendingVoucherHeaderConfig(
          "pending",
          handleRowSelection,
          selectedVouchersList,
          tableData?.results || [],
          isApproveAll
        )}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onClick={viewVoucherHandler}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          searchHandler(page);
        }}
        selectedRows={selectedVouchersList}
      ></MuiTable>
      {showFiles?.isShow && (
        <CustomModal
          title="Files"
          onClose={() => {
            setShowFiles({ isShow: false, data: [] });
          }}
        >
          {showFiles?.data.length > 0 ? (
            showFiles.data.map((item, index) => {
              return (
                <div className="downloadgrid" key={index}>
                  <div>{item?.title}</div>
                  <div>
                    <a target="_blank" download={true} href={item?.url}>
                      <DownloadForOfflineIcon color="action" />
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Files Attached</p>
          )}
        </CustomModal>
      )}
      <Drawer
        anchor="right"
        open={viewVoucher?.isView}
        onClose={() => setViewVoucher({ isView: false, data: {} })}
      >
        <ViewVoucher data={viewVoucher.data} setViewVoucher={setViewVoucher} />
      </Drawer>
      {approvedModal?.isOpen && (
        <CustomModal
          open={approvedModal?.isOpen}
          title=" Approve Voucher"
          contentClassName="approve-invoice-modal"
          onClose={() => {
            setApprovedModal({
              isOpen: false,
              data: {},
              comment: "",
              payment: "",
              paidAmount: "",
            });
          }}
        >
          <div>
            <TextareaAutosize
              placeholder="Comments"
              minRows={6}
              value={approvedModal?.comment}
              style={{ width: "100%" }}
              onChange={(e) =>
                setApprovedModal((prevVal) => ({
                  ...prevVal,
                  comment: e.target.value,
                }))
              }
            />
            {userData?.role === "Finance" && (
              <div>
                <p>
                  The voucher amount will be reconciled with the physical
                  balance.
                </p>
                {/* <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment"
                    value={approvedModal.payment}
                    onChange={handlePaymentChange}
                  >
                    <FormControlLabel
                      value="Paid"
                      control={<Radio />}
                      label="Paid"
                    />
                    <FormControlLabel
                      value="Adjust"
                      control={<Radio />}
                      label="Adjust"
                    />
                  </RadioGroup>
                </FormControl>
                {approvedModal.payment === "Paid" && (
                  <TextField
                    label="Paid Amount"
                    variant="outlined"
                    size="small"
                    value={approvedModal.paidAmount}
                    onChange={handlePaidAmountChange}
                    style={{ marginBottom: "16px" }}
                  />
                )} */}
              </div>
            )}
            <div className="footer-buttons">
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  onApproveRejectAction();
                  setApprovedModal({
                    isOpen: false,
                    data: {},
                    comment: "",
                    payment: "",
                    paidAmount: "",
                  });
                }}
              >
                Approved
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {reconsiderModal?.isOpen && (
        <CustomModal
          open={reconsiderModal?.isOpen}
          title="Reconsider Voucher"
          contentClassName="approve-invoice-modal"
          onClose={() => {
            setReconsiderModal({ isOpen: false, data: {}, comment: "" });
          }}
        >
          <div>
            <TextareaAutosize
              placeholder="Comments"
              minRows={6}
              value={reconsiderModal?.comment}
              style={{ width: "100%" }}
              onChange={(e) =>
                setReconsiderModal((prevVal) => ({
                  ...prevVal,
                  comment: e.target.value,
                }))
              }
            />

            <div className="footer-buttons">
              <Button
                variant="contained"
                fullWidth
                color="error"
                onClick={() => {
                  onApproveRejectAction(true);
                  setReconsiderModal({ isOpen: false, data: {}, comment: "" });
                }}
              >
                Reconsider
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {viewComments?.isView && (
        <ViewComment
          data={viewComments.data}
          setViewComments={setViewComments}
        />
      )}
      {isApproveAll && (
        <div className="approve-all-con">
          <TextareaAutosize
            placeholder="Comments"
            minRows={6}
            value={bulkComment}
            style={{ width: "100%" }}
            onChange={(e) => setBulkComment(e.target.value)}
          />
          <div className="approve-btn-con">
            <Button
              size="small"
              color="success"
              variant="outlined"
              sx={{ borderRadius: "15px", mt: 3, mr: 3 }}
              onClick={() => approveOrReconsiderInBulk(false)}
            >
              Approve All
            </Button>
            <Button
              size="small"
              color="error"
              variant="outlined"
              sx={{ borderRadius: "15px", mt: 3 }}
              onClick={() => approveOrReconsiderInBulk(true)}
            >
              Reconsider All
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingVouchers;
