import React, { useEffect, useState } from "react";
import "./index.scss";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import Select from "react-select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { broadband, provider, system1OS, system1makeOrModel } from "./config";

const PosTechAttributes = ({ techAttributes, onChange, resetData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (resetData) {
      setFormData({});
    }
  }, [resetData]);

  useEffect(() => {
    if (!formData.id) {
      setFormData(techAttributes);
    }
  }, [techAttributes]);

  const onInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    onChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onInnerInputChange = (event, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onCheckboxChange = (value, dataLabel) => {
    setFormData({
      ...formData,
      [dataLabel]: value,
    });
    onChange({
      ...formData,
      [dataLabel]: value,
    });
  };

  const onInnerCheckboxChange = (event, dataLabel, key) => {
    setFormData({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
    onChange({
      ...formData,
      [dataLabel]: {
        ...formData[dataLabel],
        [key]: event,
      },
    });
  };

  const {
    broadBandInfo = {},
    posLicensingInfoInfo = {},
    paymentManagerInfo = {},
  } = formData;
  return (
    <div className="basic-fixed-container">
      <Grid container>
        <Grid xs={6}>
          <div className="title">POS Attributes</div>
          <div className="form-details">
            <div className="g-r-2">
              <TextField
                label="SoftWare"
                className="subs-text-field"
                fullWidth
                value={formData.posSoftware || ""}
                name="posSoftware"
                onChange={onInputChange}
              />
              <TextField
                label="SoftWare Version"
                className="subs-text-field"
                fullWidth
                value={formData.softwareVersion || ""}
                name="softwareVersion"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="Primary OP System"
                className="subs-text-field"
                fullWidth
                value={formData.primaryOPSystem || ""}
                name="primaryOPSystem"
                onChange={onInputChange}
              />
              <TextField
                label="System"
                className="subs-text-field"
                fullWidth
                value={formData.posSystem || ""}
                name="posSystem"
                onChange={onInputChange}
              />
            </div>
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={formData?.homeOffice || false}
                    onChange={() => {
                      onCheckboxChange(!formData.homeOffice, "homeOffice");
                    }}
                  />
                }
                label="Home Office"
                labelPlacement="end"
              />
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={formData?.productionPreviewMenu || false}
                    onChange={() => {
                      onCheckboxChange(
                        !formData.productionPreviewMenu,
                        "productionPreviewMenu"
                      );
                    }}
                  />
                }
                label="Product Preview Menu"
                labelPlacement="end"
              />
            </div>
            <div className="g-r-2">
              <TextField
                label="User ID"
                className="subs-text-field"
                fullWidth
                value={formData.userID || ""}
                name="userID"
                onChange={onInputChange}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="title">Online Ordering</div>
          <div className="form-details">
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={formData?.onlineOrderingCertified || false}
                    onChange={() => {
                      onCheckboxChange(
                        !formData.onlineOrderingCertified,
                        "onlineOrderingCertified"
                      );
                    }}
                  />
                }
                label="Online Ordering Certified"
                labelPlacement="end"
              />
            </div>
          </div>
          <div className="title">Broadband</div>
          <div className="form-details">
            <div className="g-r-2 m-b">
              <div>
                <p className="dropdown-title ">Type</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Type"
                  value={
                    formData?.broadBandInfo?.type
                      ? {
                          label: formData?.broadBandInfo?.type,
                          value: formData?.broadBandInfo?.type,
                        }
                      : null
                  }
                  options={broadband}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      broadBandInfo: {
                        ...formData.broadBandInfo,
                        type: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      broadBandInfo: {
                        ...formData.broadBandInfo,
                        type: e?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div>
                <p className="dropdown-title ">Provider</p>
                <Select
                  classNamePrefix="custom_select"
                  placeholder="Provider"
                  value={
                    formData?.broadBandInfo?.provider
                      ? {
                          label: formData?.broadBandInfo?.provider,
                          value: formData?.broadBandInfo?.provider,
                        }
                      : null
                  }
                  options={provider}
                  onChange={(e) => {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      broadBandInfo: {
                        ...formData.broadBandInfo,
                        provider: e?.value,
                      },
                    }));
                    onChange((prevVal) => ({
                      ...prevVal,
                      broadBandInfo: {
                        ...formData.broadBandInfo,
                        provider: e?.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={broadBandInfo?.EBTApproved || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !broadBandInfo.EBTApproved,
                        "broadBandInfo",
                        "EBTApproved"
                      );
                    }}
                  />
                }
                label="EBT Approved"
                labelPlacement="end"
              />
            </div>
          </div>
        </Grid>

        <Grid xs={6}>
          <div className="title">Payment Manager Info</div>
          <div className="form-details">
            <div className="g-r-2">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    style={{ padding: "0px 9px" }}
                    checked={paymentManagerInfo?.reset || false}
                    onChange={() => {
                      onInnerCheckboxChange(
                        !paymentManagerInfo.reset,
                        "paymentManagerInfo",
                        "reset"
                      );
                    }}
                  />
                }
                label="Reset"
                labelPlacement="end"
              />
            </div>
            <div className="g-r-2">
              {/* <TextField
                label="User ID"
                fullWidth
                value={formData.userID || ""}
                name="userID"
                onChange={onInputChange}
              /> */}
            </div>
          </div>
        </Grid>
        <Grid container>
          <Grid xs={4}>
            <div className="title">POS System 1</div>
            <div className="form-details">
              <div className="g-r-2 m-b">
                <div>
                  <p className="dropdown-title ">Operating System</p>
                  <Select
                    placeholder="Operating System"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system1OS
                        ? {
                            label: formData?.posSystemInfo?.system1OS,
                            value: formData?.posSystemInfo?.system1OS,
                          }
                        : null
                    }
                    options={system1OS}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system1OS: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system1OS: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">POS Make/Model</p>
                  <Select
                    placeholder="POS Make/Model"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system1makeOrModel
                        ? {
                            label: formData?.posSystemInfo?.system1makeOrModel,
                            value: formData?.posSystemInfo?.system1makeOrModel,
                          }
                        : null
                    }
                    options={system1makeOrModel}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system1makeOrModel: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system1makeOrModel: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={4}>
            <div className="title">POS System 2</div>
            <div className="form-details">
              <div className="g-r-2 m-b">
                <div>
                  <p className="dropdown-title ">Operating System</p>
                  <Select
                    placeholder="Operating System"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system2OS
                        ? {
                            label: formData?.posSystemInfo?.system2OS,
                            value: formData?.posSystemInfo?.system2OS,
                          }
                        : null
                    }
                    options={system1OS}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system2OS: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system2OS: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">POS Make/Model</p>
                  <Select
                    placeholder="POS Make/Model"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system2makeOrModel
                        ? {
                            label: formData?.posSystemInfo?.system2makeOrModel,
                            value: formData?.posSystemInfo?.system2makeOrModel,
                          }
                        : null
                    }
                    options={system1makeOrModel}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system2makeOrModel: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system2makeOrModel: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={4}>
            <div className="title">POS System 3</div>
            <div className="form-details">
              <div className="g-r-2 m-b">
                <div>
                  <p className="dropdown-title ">Operating System</p>
                  <Select
                    placeholder="Operating System"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system3OS
                        ? {
                            label: formData?.posSystemInfo?.system3OS,
                            value: formData?.posSystemInfo?.system3OS,
                          }
                        : null
                    }
                    options={system1OS}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system3OS: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system3OS: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="dropdown-title ">POS Make/Model</p>
                  <Select
                    placeholder="POS Make/Model"
                    classNamePrefix="custom_select"
                    value={
                      formData?.posSystemInfo?.system3makeOrModel
                        ? {
                            label: formData?.posSystemInfo?.system3makeOrModel,
                            value: formData?.posSystemInfo?.system3makeOrModel,
                          }
                        : null
                    }
                    options={system1makeOrModel}
                    onChange={(e) => {
                      setFormData((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system3makeOrModel: e?.value,
                        },
                      }));
                      onChange((prevVal) => ({
                        ...prevVal,
                        posSystemInfo: {
                          ...formData.posSystemInfo,
                          system3makeOrModel: e?.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} sx={{ mt: 2 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ backgroundColor: "#008a52", color: "white" }}>
                  <TableRow>
                    <TableCell className="table-cell">License Type</TableCell>
                    <TableCell className="table-cell">License</TableCell>
                    <TableCell className="table-cell">Name</TableCell>
                    <TableCell className="table-cell">Entity Type</TableCell>
                    <TableCell className="table-cell">
                      POS Product Type
                    </TableCell>
                    <TableCell className="table-cell">Invoice Date</TableCell>
                    <TableCell className="table-cell">Invoice Number</TableCell>
                    <TableCell className="table-cell">PO Number</TableCell>
                    <TableCell className="table-cell">Billing Date</TableCell>
                    <TableCell className="table-cell">
                      License Cancelled Ind
                    </TableCell>
                    <TableCell className="table-cell">
                      License Cancelled Date
                    </TableCell>
                    <TableCell className="table-cell">Key Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={posLicensingInfoInfo.store}>
                    <TableCell>{posLicensingInfoInfo.licenseType}</TableCell>
                    <TableCell>{posLicensingInfoInfo.license}</TableCell>
                    <TableCell>{posLicensingInfoInfo.name}</TableCell>
                    <TableCell>{posLicensingInfoInfo.entityType}</TableCell>
                    <TableCell>{posLicensingInfoInfo.productType}</TableCell>
                    <TableCell>{posLicensingInfoInfo.invoiceDate}</TableCell>
                    <TableCell>{posLicensingInfoInfo.invoiceNum}</TableCell>
                    <TableCell>{posLicensingInfoInfo.PONum}</TableCell>
                    <TableCell>{posLicensingInfoInfo.billingDate}</TableCell>
                    <TableCell>
                      {posLicensingInfoInfo.licenseCancelledInd}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>{posLicensingInfoInfo.keyCode}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default PosTechAttributes;
