import Grid from "@mui/material/Unstable_Grid2";
import Select from "react-select";
import { useState, useRef, useEffect, useContext } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { invokeApi, plainApi } from "../../utils/http-service";
import { auditModelNames } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { checkValidStoreCombination } from "../../utils";

import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";

const FranchiseAgreementHistory = () => {
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };
  const [tableData, settableData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const previousController = useRef();
  const [satelliteList, setSatelliteList] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [sequenceList, setSequenceList] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);

  useEffect(() => {
    if (totalPages === null) {
      getTableData(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        pages
      );
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(
            storeInfo.storeNo,
            storeInfo.satelliteNo,
            storeInfo.sequenceNo,
            pages
          );
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (storeInfo.storeNo) {
      getTableData(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        1,
        true
      );
      getAllSequenceNumber(storeInfo.storeNo);
    }
  }, []);

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataKeyValue = [
    { label: "Developer Contract", id: "developerContract" },
    { label: "Franchisor", id: "franchisor" },
    { label: "Status", id: "status" },
    { label: "Business Name", id: "businessName" },
    { label: "Tax ID", id: "taxID" },
    { label: "Fee", id: "fee" },
    { label: "Currency", id: "currency" },
    { label: "Payment Date", id: "paymentDate" },
    { label: "Sequence Type", id: "sequenceType" },
    { label: "Country", id: "country" },
    { label: "Drink Option", id: "drinkOption" },
    { label: "Payment Program", id: "paymentProgram" },
    { label: "Amend Agreement", id: "amendAgreement" },
    { label: "AF Language", id: "afLanguage" },
    { label: "Franchise Xref", id: "franchiseXref" },
    { label: "Disclosure", id: "disclosure" },
    { label: "OFAC", id: "ofac" },
    { label: "Agreement", id: "agreement" },
    { label: "Term In Month", id: "termsInMonths" },
    { label: "Expiry", id: "expiry" },
    { label: "Renewal", id: "renewal" },
    { label: "Amended", id: "amended" },
    { label: "Assigned", id: "assigned" },
    { label: "Reusable", id: "reusable" },
    { label: "SLR Expiry", id: "slrExpiry" },
    { label: "Termination Date", id: "termination.date" },
    { label: "Termination Reason", id: "termination.reason" },
    { label: "Cancellation Date", id: "cancellation.date" },
    { label: "Cancellation Reason", id: "cancellation.reason" },
  ];

  const dateFields = [
    "disclosure",
    "ofac",
    "agreement",
    "expiry",
    "amended",
    "assigned",
    "reusable",
    "slrExpiry",
  ];

  const getTableData = (storeNo, satelliteNo, sequenceNo, page, hasToReset) => {
    let curStore = storeNo;
    if (typeof curStore === "string") {
      curStore = parseInt(curStore);
    }
    const isValidCombination = checkValidStoreCombination(
      storeNo,
      satelliteNo,
      sequenceNo
    );
    let dataCheckingCondition = !pageArray.includes(page);
    if (hasToReset) {
      dataCheckingCondition = true;
    }
    if (dataCheckingCondition && isValidCombination) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_HISTORY}`, null, {
        modelName: auditModelNames.franchise,
        storeNo,
        satelliteNo,
        sortBy: "-createdAt",
        limit: 20,
        page,
        sequenceNo,
      })
        .then((res) => {
          if (!res?.message) {
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              const { details } = item;
              const allDetails = {};

              for (let detail in details) {
                const mainKey = `${detail}`.toLowerCase();

                if (typeof details[detail] === "object") {
                  for (let subDetail in details[detail]) {
                    let value = details[detail][subDetail];
                    const mainKey = `${subDetail}`.toLowerCase();
                    if (
                      dateFields.includes(`${subDetail}`) ||
                      `${mainKey}`.includes("date")
                    ) {
                      value = moment(value).format("yyyy-MM-DD");
                    }
                    allDetails[`${detail}.${subDetail}`] = value;
                  }
                } else {
                  let value = details[detail];
                  if (
                    dateFields.includes(`${detail}`) ||
                    mainKey.includes("date")
                  ) {
                    value = moment(value).format("yyyy-MM-DD");
                  }
                  allDetails[detail] = value;
                }
              }
              return allDetails;
            });
            if (page == 1) {
              settableData(results);
              setPageArray([1]);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              settableData([...tableData, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          } else {
            toast.error(res.mesage, { autoClose: 2000 });
          }
        })
        .catch((err) => console.log(err));
    } else {
      settableData([]);
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        const { sequenceNo } = p;
        return {
          title: p.storeNo,
          id: p.storeNo,
          satellite: p.satelliteNum,
          sequenceNo,
        };
      });
      setStoreOptions(updatedOptions);
      setStoreInfo((prevVal) => ({
        ...prevVal,
        storeNo: searchTerm,
      }));
    });
  };

  const onInputChange = (event, value) => {
    if (value) {
      getData(value);
      setStoreInfo({
        satelliteNo: null,
        sequenceNo: null,
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  const clearData = (reset = true) => {
    if (reset)
      setStoreInfo({
        storeNo: "",
        sequenceNo: "",
        satelliteNo: "",
      });
    setStoreOptions([]);
    settableData([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
  };

  return (
    <div className="mainFranchiseDetails">
      <Grid container spacing={1} md={10} sx={{ alignItems: "flex-end" }}>
        <Grid xs={3}>
          <div className="title">Criteria</div>
          <div style={displayContainer}>
            <Autocomplete
              className="subs-autocomplete"
              id="free-solo-demo"
              freeSolo
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    clearData();
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Store" />
              )}
              sx={{ width: "20em" }}
              inputValue={storeInfo.storeNo ? `${storeInfo.storeNo}` : ""}
              onInputChange={onInputChange}
              onChange={(e, val) => {
                if (val) {
                  getAllSequenceNumber(val.id);
                }
              }}
            />
          </div>
        </Grid>
        <Grid xs={2.5}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
              getTableData(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo,
                1,
                true
              );
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>

        <Grid xs={2.5}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence Number"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));
              getTableData(
                storeInfo.storeNo,
                storeInfo.satelliteNo,
                val?.value,
                1,
                true
              );
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
      </Grid>
      <div>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <div className="title">
              {tableData.length} Franchise Agreement Details History
              Modifications
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="memo-list-container">
        <StickyHeaderCon
          dataList={tableData}
          columnsList={dataKeyValue}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          // containerHeight="table-height"
        />
      </div>
    </div>
  );
};

export default FranchiseAgreementHistory;
