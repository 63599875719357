import {
  agrAndPostAgrDocsDateConfig,
  agreementAndPostAgreementDocsLabelsAndValues,
} from '../../../../../utils/coco-acquired.config';
import { validDocumentTypes } from '../../../../../utils/document-constants';
import { leaseAndOptionAgreementConfig } from './lease-agreeement-options-agreement';

export const agreementAndPostAgreementDocsConfig = (
  formDetails,
  config,
  errors
) => {
  const documentType = formDetails?.documentType?.value;
  const dateConfig = agrAndPostAgrDocsDateConfig(
    documentType,
    formDetails,
    errors
  );

  if (documentType) {
    if (
      documentType ===
        agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
      documentType ===
        agreementAndPostAgreementDocsLabelsAndValues.optionAgreement
    ) {
      config[validDocumentTypes.agreementAndPostAgreementDocs] = [
        ...config[validDocumentTypes.agreementAndPostAgreementDocs],
        ...leaseAndOptionAgreementConfig(formDetails, errors, documentType),
      ];
    } else {
      config[validDocumentTypes.agreementAndPostAgreementDocs] = [
        ...config[validDocumentTypes.agreementAndPostAgreementDocs],
        {
          label: 'Upload Document',
          name: 'docs',
          type: 'upload',
          value: formDetails.docs,
          error: errors.docs,
          helperText: errors.docs ? 'Required' : '',
          documentSavingKey: 'document',
          uploadType: `${formDetails?.documentType?.value}`,
          notUploadCheckBox: true,
          notUploadKey: 'notApplicable',
        },
      ];
    }
    config[validDocumentTypes.agreementAndPostAgreementDocs].splice(
      1,
      0,
      ...dateConfig
    );

    if (formDetails.notApplicable) {
      delete formDetails.startDate;
      delete formDetails.endDate;
      config[validDocumentTypes.agreementAndPostAgreementDocs].splice(
        1,
        dateConfig.length
      );
    }
  }
};
