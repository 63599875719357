import { tokenMainKeys } from "../../../utils/constants";
import {
  setOfflineData as setMainOfflineData,
  getOfflineData as getMainOfflineData,
  clearOfflineData as clearMainOfflineData,
} from "../../common-utilities/utils/offline-services";

export const setOfflineData = (key, data) => {
  return setMainOfflineData(tokenMainKeys.subs, key, data);
};

export const getOfflineData = (key) => {
  return getMainOfflineData(tokenMainKeys.subs, key);
};

export const clearOffllineData = (key) => {
  return clearMainOfflineData(tokenMainKeys.subs, key);
};
