import { intranetService } from ".";

export const REST_URLS = {
  FIELD_LIST: `${intranetService}app-document-category/list/`,
  APP_DOCUMENTS: `${intranetService}app-document`,
  UPLOAD_DOCUMENT: `${intranetService}app-document/upload`,
  UPDATE_DOCUMENT: `${intranetService}app-document/update/`,
  CREATE_DOCUMENT: `${intranetService}app-document/`,
  APP_DETAILS: `${intranetService}app-document/app-details`,
  CONFIRMATION_STATUS: `${intranetService}app-document/confirmation-status/`,
  CONFIRM_DOCUMENT: `${intranetService}app-document/agree-document/`,
  DOWNLOAD_REPORTS: `${intranetService}app-document/confirmation-reports/`,
  QUERY_DOCUMENT_CATEGORIES: `${intranetService}app-document-category`,
  CREATE_APP_DOCUMENT_CATEGORY: `${intranetService}app-document-category`,
  UPDATE_APP_DOCUMENT_CATEGORY: `${intranetService}app-document-category/`,
};
