import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import InvoiceList from "../components/invoice-list";
import InvoiceForm from "../components/invoice-form";
import InvoiceDetails from "../components/invoice-details";
import InvoiceEditForm from "../components/invoice-edit-form";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";

export const invoiceTrackingRouteConfig = {
  mainPath: {
    path: "/",
    element: <PrivateRoute />,
    exact: true,
    Element: PrivateRoute,
  },
  mainRoute: "/invoice-tracking",
  routes: [
    {
      path: "/",
      Element: InvoiceList,
      element: <InvoiceList />,
      exact: true,
    },
    {
      path: "/invoice",
      Element: InvoiceForm,
      element: <InvoiceForm />,
      exact: true,
    },
    {
      path: "/invoice-details/:id",
      Element: InvoiceDetails,
      element: <InvoiceDetails />,
      exact: true,
    },
    {
      path: "/invoice-edit/:id",
      Element: InvoiceEditForm,
      element: <InvoiceEditForm />,
      exact: true,
    },
  ],
};

const InvoiceTrackingRoutes = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<PrivateRoute token={token} slug={slug} />}
      >
        <Route path="/" element={<InvoiceList token={token} slug={slug} />} />
        <Route
          exact
          path="/invoice"
          element={<InvoiceForm token={token} slug={slug} />}
        />
        <Route
          exact
          path="/invoice-details/:id"
          element={<InvoiceDetails token={token} slug={slug} />}
        />
        <Route
          exact
          path="/invoice-edit/:id"
          element={<InvoiceEditForm token={token} slug={slug} />}
        />
      </Route>
    </Routes>
  );
};

export default InvoiceTrackingRoutes;
