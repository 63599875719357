import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Button } from "@mui/material";
import "./index.scss";
import { invokeApi, plainApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AddCommunicationAddressType } from "./add-communication-type";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
const CommunicationAddressType = () => {
  const [data, setData] = useState([]);
  const [addressFlag, setAddressFlag] = useState(false);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [editName, setEditName] = useState("");
  const [editId, setEditId] = useState("");
  const columns = [
    { id: "name", label: "Name" },
    { id: "actions", label: "Actions" },
  ];
  useEffect(() => {
    if (totalPages == null) {
      getTableData(pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  const createData = (id, name) => {
    let actions = (
      <div>
        <DeleteOutlineIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            deleteHandler(id);
          }}
        />
        <EditIcon
          style={{
            cursor: "pointer",
          }}
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            editHandler(id, name);
          }}
        />
      </div>
    );
    return {
      name,
      actions,
    };
  };
  const editHandler = (id, name) => {
    setAddressFlag(true);
    setEditId(id);
    setEditName(name);
  };
  const getTableData = (pg, reset=false) => {
    const params = {
      sortBy: "-createdAt",
      page: pg,
      limit: 50,
    };
    let check  = !pageArray.includes(pg)
    if (reset) {
      check = true
    }

    if (check) {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_COMMUNICATION_ADDRESS_TYPE_WITH_ID}`,
        null,
        params
      ).then((response) => {
        setTotalPages(response.totalPages + 1);
        const result = response.results.map((iter) => {
          return createData(iter.id, iter.name);
        });
        if (pg == 1) {
          setData(result);
          setPageArray([1]);
        } else {
          setData([...data, ...result]);
        }
        let array = [...pageArray];
        array.push(pg);
        setPageArray(array);
      });
    }
  };

  useEffect(() => {
    getTableData(1);
  }, []);
  const deleteHandler = (id) => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.UPDATE_COMMUNICATION_ADDRESS_TYPE}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Deleted Successfully", { autoClose: 2000 });
          setPageArray([]);
          getTableData(1);
        }
      })
      .catch((err) => console.log(err));
  };
  // const getCommunicationAddressTypeList = () => {
  //   invokeApi(
  //     HTTP_METHODS.GET,
  //     `${HOSTNAME}${REST_URLS.LIST_COMMUNICATION_ADDRESS_TYPE_WITH_ID}`
  //   )
  //     .then((res) => {
  //       if (res?.message) {
  //         toast.error(res?.message, { autoClose: 2000 });
  //         return;
  //       }
  //       const results = res.map((item) => {
  //         return { label: item.name, value: item.id };
  //       });
  //       setTypes(results);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getCommunicationAddressTypeList();
  // }, []);

  // const getCommunicationAddressTypeById = (id) => {
  //   invokeApi(
  //     HTTP_METHODS.GET,
  //     `${HOSTNAME}${REST_URLS.GET_COMMUNICATION_ADDRESS_BY_ID}${id}`
  //   )
  //     .then((res) => {
  //       if (res?.message) {
  //         toast.error(res.message, { autoClose: 2000 });
  //         return;
  //       }
  //       setCommunicationAddressTypeDetails((prevDetails) => ({
  //         ...prevDetails,
  //         name: res.name,
  //       }));
  //     })
  //     .catch((err) => {
  //       toast.error(err?.message, { autoClose: 2000 });
  //     });
  // };

  // const setCommunicationAddressTypeDetailsOnChange = (e) => {
  //   const { name, value } = e.target;

  //   setCommunicationAddressTypeDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  // const disableInputCheck = () => {
  //   if (
  //     !communicationAddressTypeDetails?.name ||
  //     !communicationAddressTypeDetails?.name.trim()
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const getPayload = () => {
  //   let payload = {};
  //   if (communicationAddressTypeDetails?.name) {
  //     payload.name = communicationAddressTypeDetails?.name;
  //   }
  //   let id = null;
  //   if (communicationAddressTypeDetails?.id?.value) {
  //     id = communicationAddressTypeDetails?.id?.value;
  //   }
  //   return [payload, id];
  // };

  // const createcommunicationAddressTypeDetails = () => {
  //   if (
  //     !communicationAddressTypeDetails?.name ||
  //     !communicationAddressTypeDetails?.name.trim()
  //   ) {
  //     return;
  //   }
  //   const [payload, id] = getPayload();
  //   if (id) {
  //     invokeApi(
  //       HTTP_METHODS.PUT,
  //       `${HOSTNAME}${REST_URLS.UPDATE_COMMUNICATION_ADDRESS_TYPE}${id}`,
  //       payload
  //     )
  //       .then((res) => {
  //         if (res?.message) {
  //           toast.error(res?.message, { autoClose: 2000 });
  //           return;
  //         }
  //         toast.success("Address Type updated successfullly", {
  //           autoClose: 2000,
  //         });
  //         setCommunicationAddressTypeDetails(() => ({}));
  //         getCommunicationAddressTypeList();
  //       })
  //       .catch((err) => {
  //         toast.error(err.message, { autoClose: 2000 });
  //       });
  //     return;
  //   }
  //   invokeApi(
  //     HTTP_METHODS.POST,
  //     `${HOSTNAME}${REST_URLS.CREATE_COMMUNICATION_ADDRESS_TYPE}`,
  //     payload
  //   )
  //     .then((response) => {
  //       if (response && !response.message) {
  //         toast.success("Communication details created successfully", {
  //           autoClose: 2000,
  //         });
  //         getCommunicationAddressTypeList();
  //         setCommunicationAddressTypeDetails({});
  //       } else {
  //         toast.error(response?.message, { autoClose: 2000 });
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.message, { autoClose: 2000 });
  //     });
  // };

  return (
    <div>
      <div className="createCorporation-container">
        <div className="button-container-start">
          <Button
            onClick={() => setAddressFlag(true)}
            variant="contained"
            size="small"
          >
            Create New
          </Button>
        </div>
        <StickyHeaderCon
          columnsList={columns}
          // containerHeight="table-con"
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
        />
        {addressFlag && (
          <AddCommunicationAddressType
            onSuccess={() => {
              setPageArray([]);
              getTableData(1, true);
            }}
            addressFlag={addressFlag}
            onClose={() => {
              setAddressFlag(false);
              setEditId("");
              setEditName("");
            }}
            id={editId}
            name={editName}
          />
        )}
      </div>
    </div>
  );
};

export default CommunicationAddressType;
