import { Button, Chip, TextField } from '@mui/material';
import { useState } from 'react';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import FileUpload from '../../../../common-utilities/core/file-uploader';
import { useEffect } from 'react';
import { pickData } from './utils';
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from '../../../utils/attachments';

// import {
//   getUploadAttachmentQueryParams,
//   ,
// } from '../../../utils/attachments';

const InitAdvPayment = ({
  advanceAmount,
  onSave,
  data,
  attachmentData,
  attachmentType,
  invoiceUrlCheck,
}) => {
  const [formDetails, setFormDetails] = useState({
    invoiceAmount: advanceAmount,
  });
  const [attachments, setAttachments] = useState([]);
  const [invoiceUrls, setInvoiceUrls] = useState([]);
  const [config, setConfig] = useState({
    disableSave: false,
  });

  const onDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const onInvoiceUrlsDelete = (index) => {
    invoiceUrls.splice(index, 1);
    setInvoiceUrls([...invoiceUrls]);
  };

  const onInputChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    pickData(data, setFormDetails, setInvoiceUrls, setAttachments);
  }, [data]);

  const isDisabled = () => {
    return !formDetails.invoiceDate ||
      !formDetails.invoiceNumber ||
      !formDetails.invoiceAmount ||
      invoiceUrlCheck
      ? !invoiceUrls.length
      : false;
  };

  return (
    <div className="comment-attachments-container">
      <TextField
        label="Invoice Date *"
        fullWidth
        size="small"
        type="date"
        inputProps={{ max: '2123-05-31' }}
        name="invoiceDate"
        value={formDetails.invoiceDate}
        InputLabelProps={{ shrink: true }}
        onChange={onInputChange}
      />
      <TextField
        label="Proforma invoice no *"
        fullWidth
        size="small"
        name="invoiceNumber"
        value={formDetails.invoiceNumber}
        onChange={onInputChange}
      />
      <TextField
        label="Invoice Amount *"
        type="number"
        fullWidth
        size="small"
        name="invoiceAmount"
        helperText={`Max allow amount ${advanceAmount}`}
        value={formDetails.invoiceAmount}
        error={
          parseInt(formDetails.invoiceAmount) === 0 ||
          (formDetails.invoiceAmount > 0 && advanceAmount < 0) ||
          (formDetails.invoiceAmount < 0 && advanceAmount > 0)
        }
        onChange={(event) => {
          if (+event.target.value <= advanceAmount) {
            onInputChange(event);
          }
        }}
      />
      <FileUpload
        uploadText="Upload Invoice Attachments"
        id="invoice-attachments"
        clsName="add-additional-doc"
        url={`${FINANCE_HOSTNAME}${
          FINANCE_REST_URLS.UPLOAD_DOCUMENT
        }${getUploadAttachmentQueryParams(
          {
            ...attachmentData,
            attachmentKey: 'invoiceUrls',
            attachmentLabel: 'Upload Invoice Attachments',
          },
          attachmentType
        )}`}
        callBack={(response) => {
          const { data } = response;
          setInvoiceUrls([...invoiceUrls, data]);
        }}
        formDataKey="file"
      />
      {invoiceUrls.map((attachment, index) => {
        return (
          <Chip
            label={attachment.originalName}
            onDelete={() => onInvoiceUrlsDelete(index)}
            onClick={() => window.open(attachment?.docUrl, '_blank')}
          />
        );
      })}
      <FileUpload
        uploadText="Upload Additional Documents"
        id="invoice-additional-doc"
        clsName="add-additional-doc"
        url={`${FINANCE_HOSTNAME}${
          FINANCE_REST_URLS.UPLOAD_DOCUMENT
        }${getUploadAttachmentQueryParams(
          {
            ...attachmentData,
            attachmentKey: 'additionalDocUrls',
            attachmentLabel: 'Upload Additional Attachments',
          },
          attachmentType
        )}`}
        callBack={(response) => {
          const { data } = response;
          setAttachments([...attachments, data]);
        }}
        formDataKey="file"
      />
      {attachments.map((attachment, index) => {
        return (
          <Chip
            label={attachment.originalName}
            onDelete={() => onDelete(index)}
            onClick={() => window.open(attachment?.docUrl, '_blank')}
          />
        );
      })}
      <Button
        fullWidth
        disabled={isDisabled() || config.disableSave}
        onClick={() => {
          setConfig({
            disableSave: true,
          });
          onSave({
            ...formDetails,
            invoiceUrls: invoiceUrls.map((a) => a.docUrl),
            additionalDocUrls: attachments.map((a) => a.docUrl),
          });
          setTimeout(() => {
            setConfig({
              disableSave: false,
            });
          }, 5000);
        }}
        variant="contained"
      >
        Save Changes
      </Button>
    </div>
  );
};

export default InitAdvPayment;
