const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  LOGIN: `${intranetService}auth/login`,
  LOGOUT: `${intranetService}auth/logout`,
  // REFRESH_TOKEN: `${service}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  GET_ALL_STORES: `${intranetService}stores`,
  UPLOAD_STORES: `${intranetService}stores/upload-store-data`,
  DOWNLOAD_STORES: `${intranetService}stores/download-store-master`,
  USER_LIST: `${intranetService}users/user-list`,
  UPDATE_STORE: `${intranetService}stores/`,
  CREATE_STORE: `${intranetService}stores`,
  DC_LIST: `${intranetService}dc/dc-list`,
};

export const STORE_MASTER_ROUTE = "/store-master";
export const redirectToRoute = `${STORE_MASTER_ROUTE}`;
export const SLUG = "store-master";
