import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

import { invokeApi } from "../../utils/http-service";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";

const StoreOwner = ({
  onClose,
  editOwner,
  searchDetails,
  storeDetails,
  onSave,
  editOwnerObj,
}) => {
  const previousPersonController = useRef();
  const [personOptions, setPersonOptions] = useState([]);
  const [personInput, setPersonInput] = useState("");
  const [personNum, setPersonNum] = useState("");
  const [primaryValue, setPrimaryValue] = useState(editOwnerObj?.primary);
  const [isCreate, setIsCreate] = useState(false);
  const getPersonData = (searchTerm) => {
    if (previousPersonController.current) {
      previousPersonController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousPersonController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return {
          title: `${p.firstName} ${p?.lastName} - ${p.id}`,
          id: p.id,
          firstName: p.firstName,
          lastName: p?.lastName,
        };
      });
      setPersonOptions(updatedOptions);
      setPersonInput(searchTerm);
    });
  };

  useEffect(() => {
    if (typeof editOwner === "number") {
      setPersonInput(editOwner + "");
      setPersonNum(editOwner);
    }
    if (typeof editOwner === "object") {
      setPersonInput(editOwner.value + "");
      setPersonNum(editOwner.value);
    }
  }, []);
  const onPersonInputChange = (event, value, reason) => {
    if (!value) {
      setPersonInput("");
      setPersonOptions([]);
      setPersonNum("");
    }
    if (value?.trim()) {
      getPersonData(value);
    } else {
      setPersonOptions([]);
    }
  };
  useEffect(() => {
    if (!editOwner) {
      setIsCreate(true);
    }
  }, []);

  const updateDetails = () => {
    if (!Number.isInteger(parseInt(searchDetails.sequenceNo))) {
      toast.error("Please select Sequence Number", { autoClose: 2000 });
      return;
    }

    if (!Number.isInteger(parseInt(searchDetails.satelliteNo))) {
      toast.error("Please select Satellite Number", { autoClose: 2000 });
      return;
    }
    let payload = {
      sequenceNo: searchDetails.sequenceNo,
      satelliteNo: searchDetails.satelliteNo,
    };
    if (!isCreate) {
      if (storeDetails.owner.length > 0) {
        let ownersResult = storeDetails.owner.map((ele) => ({
          ...ele,
          id: ele?.id?.id,
        }));
        let ownerList = storeDetails.owner.map((ele) => ele?.id?.id);
        let checkPrimary = storeDetails.owner.map((ele) => ele.primary);
        if (personNum !== editOwner) {
          if (ownerList.includes(personNum)) {
            toast.error("Already Present as Owner of this Store", {
              autoClose: 2000,
            });
            return;
          }
        }
        if (primaryValue !== editOwnerObj?.primary) {
          if (primaryValue) {
            if (checkPrimary.includes(primaryValue)) {
              toast.error(
                "Another Person already set as Primary Owner for this store",
                {
                  autoClose: 2000,
                }
              );
              return;
            }
          }
        }
        const updatedArr = ownersResult.map((ele) => {
          if (ele.id === editOwner || ele.id === editOwner.value) {
            return { id: personNum, primary: primaryValue };
          } else {
            return ele;
          }
        });
        payload = { ...payload, owner: updatedArr };
      }
    }
    if (isCreate) {
      if (storeDetails.owner.length > 0) {
        let ownersResult = storeDetails.owner.map((ele) => ({
          ...ele,
          id: ele?.id?.id,
        }));
        let ownerList = storeDetails.owner.map((ele) => ele?.id?.id);
        let checkPrimary = storeDetails.owner.map((ele) => ele.primary);

        if (ownerList.includes(personNum)) {
          toast.error("Already Present as Owner of this Store", {
            autoClose: 2000,
          });
          return;
        }

        if (primaryValue) {
          if (checkPrimary.includes(primaryValue)) {
            toast.error(
              "Another Person already set as Primary Owner for this store",
              {
                autoClose: 2000,
              }
            );
            return;
          }
        }
        ownersResult.push({ id: personNum, primary: primaryValue });
        payload = { ...payload, owner: ownersResult };
      } else {
        payload = {
          ...payload,
          owner: [{ id: personNum, primary: primaryValue }],
        };
      }
    }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_STORE}${searchDetails.storeNo}`,
      payload
    )
      .then((response) => {
        if (!response.message) {
          toast.success("Updated Successfully", { autoClose: 2000 });
          onSave(response.owner);
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          {isCreate ? (
            <span className="title">Create New Store Owner</span>
          ) : (
            <span className="title">Change Store Owner</span>
          )}
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>
        <div className="address-details">
          <div>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              getOptionLabel={(option) => {
                return option.title ? option.title.toString() : "";
              }}
              options={personOptions}
              onInputChange={onPersonInputChange}
              inputValue={personInput || ""}
              // value={personInput || ""}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setPersonInput("");
                    setPersonOptions([]);
                    setPersonNum("");
                  }}
                />
              }
              className="subs-autocomplete"
              renderInput={(params) => (
                <TextField {...params} className="sel-item" label="Owner" />
              )}
              onChange={(e, val) => {
                if (val) {
                  setPersonNum(val.id);
                }
              }}
            />
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={primaryValue || false}
                  onChange={(event) => {
                    setPrimaryValue(!primaryValue);
                  }}
                />
              }
              label="Is Primary"
              labelPlacement="end"
            />
          </div>
          <div>
            {!isCreate && (
              <Button
                size="small"
                fullWidth
                disabled={
                  typeof editOwner === "number" || editOwner?.value
                    ? false
                    : true
                }
                variant="contained"
                onClick={updateDetails}
              >
                Save
              </Button>
            )}
            {isCreate && (
              <div className="address-details">
                <Button
                  size="small"
                  fullWidth
                  disabled={personNum ? false : true}
                  variant="contained"
                  onClick={updateDetails}
                >
                  Save
                </Button>
                {editOwner && (
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setIsCreate(false);
                      setPersonInput(editOwner + "");
                      setPrimaryValue(editOwnerObj?.primary);
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            )}
          </div>
          {!isCreate && (
            <div>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={() => {
                  setIsCreate(true);
                  setPersonNum("");
                  setPersonInput("");
                  setPrimaryValue(false);
                }}
              >
                Create New Owner
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreOwner;
