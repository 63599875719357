/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Products from "./products";
import { getOfflineData } from "../../utils/offline-services";
import { useNavigate } from "react-router-dom";
import ComplaintTypes from "./complaint-types";
import Users from "../users";
import ProductCategory from "./product-category";
import { redirection } from "../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (!getOfflineData(null, "user")) {
      navigate("/login");
    }
  }, []);

  React.useEffect(() => {
    redirection(navigate);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Products" {...a11yProps(0)} />
          <Tab label="Product Category" {...a11yProps(1)} />
          <Tab label="Complaint Types" {...a11yProps(2)} />
          <Tab label="Users" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <div className="main-setup-tab-container">
        <TabPanel value={value} index={0}>
          <Products />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProductCategory />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ComplaintTypes />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Users />
        </TabPanel>
      </div>
    </Box>
  );
}
