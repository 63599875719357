/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { Button, IconButton } from "@mui/material";
import { invokeUploadFile } from "../../../../utils/http-service";

const FileUpload = ({
  id,
  clsName,
  url, //Api Url on which file get uploaded (backend Api)
  accept, // file format which are allowed to upload
  callBack, // function to call after successfully upload file
  inputclassName,
  fileFormats, // array of types of file format which are allowed
  setDataFunction,
  showSuccessMsg = true,
  formDataKey = "upload",
  uploadText,
  uploadCallback,
  componentClass,
  borderColor = "",
  mainButtonClass = "",
  showInput = true
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const onFormSubmit = e => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };
  const onChange = e => {
    if (fileFormats && !fileFormats.includes(e.target.files[0].type.trim())) {
      toast.error("File provided is not of correct format", {
        autoClose: 2000
      });
      return;
    }

    setFile(e.target.files[0]);
  };
  const fileUpload = async () => {
    if (file !== null) {
    }
    const formData = new FormData();
    formData.append(formDataKey, file);
    setUploadStatus("Uploading...");

    if (typeof uploadCallback === "function") {
      uploadCallback(
        formData,
        () => {
          toast.success("Uploaded Successfully", { autoClose: 1000 });
          setFile(null);

          callBack && callBack();
          setUploadStatus("");
        },
        () => {
          setUploadStatus("");
        }
      );
      return;
    }
    return invokeUploadFile(url, formData)
      .then(response => {
        if (response.status === 200) {
          if (showSuccessMsg) {
            toast.success("Uploaded Successfully", { autoClose: 1000 });
          }
          setFile(null);

          callBack && callBack(response);
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch(err => {
        setUploadStatus("");
        setFile(null);
        toast.error(`Upload Failed ${err?.response?.data?.message}`);
      });
  };

  return (
    <div className={`relativePosition ${componentClass ? componentClass : ""}`}>
      <form className={`spidle-upload-file ${clsName}`}>
        {file === null ? (
          <Button
            title="Attach file"
            size="small"
            className={`${mainButtonClass}`}
            endIcon={<PublishIcon fontSize="small" />}
            variant="outlined"
            fullWidth
          >
            <label
              htmlFor={`${id}-file-upload`}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
            >
              {uploadText ? uploadText : "Upload Document"}{" "}
              
            </label>
          </Button>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        {showInput && (
          <input
            id={`${id}-file-upload`}
            type="file"
            accept={accept}
            className={`${inputclassName ? inputclassName : ""}`}
            onChange={event => {
              onChange(event);
              event.target.value = null;
            }}
          />
        )}

        {file !== null &&
          (!uploadStatus ? (
            <span>
              <IconButton
                color="primary"
                onClick={onFormSubmit}
                title="Upload"
                disabled={uploadStatus}
              >
                <CheckCircleOutlineIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  if (setDataFunction) {
                    setDataFunction(null);
                  }
                  setFile(null);
                }}
                title="Clear"
                disabled={uploadStatus}
              >
                <HighlightOffIcon />
              </IconButton>
            </span>
          ) : (
            <p>{uploadStatus}</p>
          ))}
      </form>
    </div>
  );
};

export default FileUpload;
