import React, { useState, useEffect } from 'react';
import './index.scss';
import { getHeaderConfig, ROLES } from './config';
import { invokeApi, HTTP_METHODS, plainApi } from '../../utils/http-service';
import { INTRANETHOSTNAME } from '../../packages/common-utilities/utils/end-points';
import { REST_URLS } from '../../packages/common-utilities/utils/end-points/intranet';
import { toast } from 'react-toastify';
import ViewAllowedApps from './viewAllowedApps';
import { Button, Grid } from '@mui/material';
import CreateUser from './create-user';
import SearchIcon from '@mui/icons-material/Search';

import MuiTable from '../../packages/common-utilities/core/mui-table';
import { exportToExcel } from '../../utils';
import moment from 'moment';
import { processEditData } from './handlers';
import Autocomplete from '../../packages/common-utilities/core/styled-autocomplete';
import { DownloadBtn } from '../../packages/common-utilities/core/download-btn';
import { setProperty } from '../../packages/common-utilities/utils/misc';

const ShowUsers = () => {
  const [tableData, setTableData] = useState([]);
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [editUserData, setEditUserData] = useState(null);
  const [nameOptions, setNameOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const [searchUserEmail, setSearchUserEmail] = useState('');
  const [searchUserName, setSearchUserName] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [userVals, setUserVals] = useState({
    name: null,
    email: null,
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });

  const loadData = () => {
    const payload = { ...filters };
    if (userVals.name) {
      payload['name'] = userVals.name;
    }
    if (userVals.email) {
      payload['email'] = userVals.email;
    }
    if (filterRole) {
      payload['role'] = filterRole;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_USERS}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const camelcaseToSpace = (str) => {
    let newStr = '';
    let wasUpper = false;
    for (let i = 0; i < str.length; i++) {
      if (!wasUpper && str[i] == str.toUpperCase()[i] && str[i] !== ' ') {
        newStr = newStr + ' ';
        wasUpper = true;
      } else {
        wasUpper = false;
      }
      let tempStr = str[i];
      if (i === 0 || str[i - 1] === ' ') tempStr = tempStr.toUpperCase();
      newStr = newStr + tempStr;
    }
    return newStr.trim();
  };
  const downloadFile = () => {
    const params = {};
    if (searchUserName) {
      params['name'] = searchUserName;
    }
    if (searchUserEmail) {
      params['email'] = searchUserEmail;
    }
    if (filterRole) {
      params['role'] = filterRole;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.DOWNLOAD_USERS}`,
      null,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.map((ele) => {
            let obj = {};
            for (let i in ele) {
              const key = camelcaseToSpace(i);
              obj[key] = ele[i];
              if (i === 'createdAt' || i === 'updatedAt') {
                obj[key] = moment(obj[key]).format(
                  'dddd, MMMM Do YYYY, h:mm:ss a'
                );
              }
            }
            return obj;
          });
          const data = [{ sheetName: 'Users Details', details: result }];
          exportToExcel(data, 'Reports', true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDataForTables = (data, type) => {
    if (data) {
      switch (type) {
        case 'View Files':
          setViewDetails(data?.limits || []);
          break;
        case 'Edit':
          {
            const finalData = processEditData(data);
            setEditUserData(finalData);
          }

          break;
        default:
          break;
      }
    }
  };
  const getuserNameList = (searchTerm, fieldName) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    let params = {
      [fieldName]: searchTerm,
    };
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.SEARCH_BY_USER_NAME}`,
        null,
        params
      ).then((res) => {
        if (fieldName === 'name') {
          const result = res.map((ele) => ele?.name);
          setNameOptions(result);
        }
        if (fieldName === 'email') {
          const result = res.map((ele) => ele?.email);
          setEmailOptions(result);
        }
      });
    }, 100);
  };

  const onEmailInputChange = (e, value) => {
    if (value?.trim() && e?.target?.value) {
      if (value === searchUserEmail) {
        return;
      }
      getuserNameList(value, 'email');
      setSearchUserEmail(value);
    } else {
      if (searchUserEmail === '') {
        return;
      }
      setSearchUserEmail('');
      setEmailOptions([]);
    }
  };
  const onInputChange = (e, value) => {
    if (value?.trim() && e?.target?.value) {
      if (value === searchUserName) {
        return;
      }
      getuserNameList(value, 'name');

      setSearchUserName(value);
    } else {
      if (searchUserName === '') {
        return;
      }
      getuserNameList(value, 'name');

      setSearchUserName('');
      setNameOptions([]);
    }
  };
  useEffect(() => {
    loadData();
  }, [filters]);
  return (
    <div className="user-con">
      {!isCreateUser && !editUserData && (
        <div>
          <Grid conatiner md={12} sx={{ display: 'flex', mb: 3 }}>
            <Grid md={9} container sx={{ display: 'flex', gap: '20px' }}>
              <Grid md={3} item>
                <Autocomplete
                  label="Search By Name"
                  onInputChange={onInputChange}
                  onChange={(e, val) => {
                    setProperty('name', val, setUserVals);
                  }}
                  options={nameOptions}
                  value={userVals.name}
                />
              </Grid>

              <Grid md={3} item>
                <Autocomplete
                  onClick={() => {
                    setSearchUserEmail('');
                  }}
                  label="Search By Email"
                  onInputChange={onEmailInputChange}
                  onChange={(e, val) => {
                    setProperty('email', val, setUserVals);
                  }}
                  inputValue={searchUserEmail || ''}
                  options={emailOptions}
                  value={userVals.email}
                />
              </Grid>
              <Grid md={3} item>
                <Autocomplete
                  label="Search By Role"
                  onClick={() => {
                    setFilterRole(null);
                  }}
                  onChange={(e, val) => {
                    setFilterRole(val?.value);
                  }}
                  value={
                    filterRole
                      ? ROLES.find((role) => role.value === filterRole)
                      : null
                  }
                  options={ROLES}
                />
              </Grid>

              <Grid
                container
                md={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Button
                  className="rounded-btn"
                  variant="outlined"
                  onClick={() => {
                    setFilters({ page: 1, limit: 10, sortBy: '-createdAt' });
                    loadData();
                  }}
                  startIcon={<SearchIcon sx={{ cursor: 'pointer' }} />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              md={2.5}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Button
                className="rounded-btn"
                variant="outlined"
                onClick={() => setIsCreateUser(true)}
                startIcon={
                  <img
                    src="https://idev.blob.core.windows.net/technetv2/User_add_alt_fill%20(1).svg"
                    alt="Icon"
                  />
                }
              >
                Create User
              </Button>
            </Grid>
            <Grid
              container
              md={0.5}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <DownloadBtn onClick={downloadFile} />
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData.results || []}
            filters={filters}
            pageCount={tableData?.totalPages}
            onClick={setDataForTables}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          ></MuiTable>
          {viewDetails && (
            <ViewAllowedApps
              title="Allowed Apps"
              closeModal={setViewDetails}
              data={viewDetails}
            />
          )}
        </div>
      )}
      {isCreateUser && (
        <CreateUser
          mainTitle={`Create New User`}
          closeModal={setIsCreateUser}
          onSuccess={() => {
            loadData();
          }}
        />
      )}
      {editUserData && (
        <CreateUser
          mainTitle={`Edit User`}
          userData={editUserData}
          closeModal={setEditUserData}
          onSuccess={() => {
            loadData();
          }}
        />
      )}
    </div>
  );
};

export default ShowUsers;
