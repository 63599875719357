import React, { useState } from "react";
import "./index.scss";
import { Button, TextField } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
const WaiverType = () => {
  const [data, setData] = useState({});
  const waiverTypeHandler = (e) => {
    setData((prevVal) => ({ ...prevVal, name: e.target.value }));
  };
  const submitHandler = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_WAIVER_TYPE}`,
      data
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Created Successfully", { autoClose: 2000 });
          setData({});
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="memo-category-con">
      <TextField
        className="subs-text-field"
        label="Waiver Type"
        variant="outlined"
        onChange={waiverTypeHandler}
        value={data?.name || ""}
        sx={{ width: "50%" }}
      />
      <Button
        variant="contained"
        sx={{ margin: "1em", float: "right" }}
        disabled={!data?.name ? true : false}
        onClick={submitHandler}
      >
        Save
      </Button>
    </div>
  );
};

export default WaiverType;