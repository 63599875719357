import React, { useEffect, useState } from 'react';
import { Pagination, TablePagination } from '@mui/material';
import './index.scss';

const SiTable = ({
  header,
  data,
  pageCount,
  onClick,
  onChange,
  customSiRowClass = '',
  filters
}) => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters?.page]);
  return (
    <div className='si-common-table-container'>
      <table className='si-common-table-container'>
        <tr className='si-header'>
          {header.map(h => {
            return <th key={h.label}>{h.label}</th>;
          })}
        </tr>
        {data.map((rowData, dataIndex) => {
          return (
            <tr
              className={`si-content-row ${customSiRowClass}`}
              key={dataIndex}
            >
              {header.map((h, i) => {
                if (h.render) {
                  return (
                    <td className='si-cell' key={i}>
                      {h.render(rowData, onClick)}
                    </td>
                  );
                }

                return (
                  <td className='si-cell' key={i}>
                    {rowData[h.key]}
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <td colSpan={header.length}>
            {pageCount > 1 && (
              // <TablePagination
              //   rowsPerPageOptions={[]}
              //   component="div"
              //   rowsPerPage={filters?.limit}
              //   page={page}
              //   count={pageCount}
              //   color="primary"
              //   onPageChange={(e, page) => {
              //     setPage(page);
              //     if (onChange) {
              //       onChange(e, page);
              //     }
              //   }}
              // ></TablePagination>
              <Pagination
                count={pageCount}
                color='primary'
                className='si-pagination'
                page={page}
                onChange={(e, page) => {
                  setPage(page);
                  if (onChange) {
                    onChange(e, page);
                  }
                }}
              ></Pagination>
            )}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default SiTable;
