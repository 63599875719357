import React, { useState, useEffect } from "react";
import "./index.scss";
import { Grid, Drawer } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import ShowMemo from "./show-memo";
import TableCell from "@mui/material/TableCell";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { invokeApi, plainApi } from "../../../../utils/http-service";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import MemoTimeline from "./memo-timeline";
import Select from "react-select";
import SelectComponent from "../../../common-utilities/core/select-component";
const memoCols = [
  { id: "category", label: "Category" },
  { id: "enteredBy", label: "Entered By" },
  { id: "memoDate", label: "Memo Date" },
  { id: "memo", label: "Memo" },
  { id: "appliedDate", label: "Applied Date" },
  { id: "deposition", label: "Deposition" },
  { id: "closedAt", label: "Completed Date" },
  { id: "isClosed", label: "Completed" },
  {
    label: "Timline",
    id: "timeline",
    render: (data, onClick) => {
      return (
        <TableCell align="center" padding="none" disabled={true}>
          <QueryStatsIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick(data, "timeline");
            }}
          />
        </TableCell>
      );
    },
  },
];
const Memos = () => {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [category, setCategory] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [memoCategoryList, setMemoCategoryList] = useState([]);
  const [storeInfo, setStoreInfo] = useState({
    storeNo: "",
    sequenceNo: "",
    satelliteNo: "",
  });
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
    isClosed: false,
    isEdit: false,
    appliedDate: "",
    id: "",
    deposition: null,
  });
  const [timelineData, setTimelineData] = useState([]);
  const [showTimeLine, setShowTimeline] = useState(false);
  const [currentMemo, setCurrentMemo] = useState("");

  const checkForValidStoreNumberSequenceNumberAndSatelliteNumber = (
    storeNo,
    sequenceNo,
    satelliteNum
  ) => {
    return (
      Number.isInteger(parseInt(storeNo)) &&
      Number.isInteger(parseInt(sequenceNo)) &&
      Number.isInteger(parseInt(satelliteNum))
    );
  };
  const getTableData = (page, hasToReset) => {
    let checkingCondition = !pageArray.includes(page);
    let params = {
      page: page,
      limit: 20,
      sortBy: "-createdAt",
      typeModel: "Store",
      storeNo: parseInt(storeInfo?.storeNo),
      satelliteNo: storeInfo?.satelliteNo,
      sequenceNo: storeInfo?.sequenceNo,
    };
    if (hasToReset) {
      checkingCondition = true;
    }
    if (category) {
      params["category"] = category;
    }
    const checkCombination =
      checkForValidStoreNumberSequenceNumberAndSatelliteNumber(
        storeInfo?.storeNo,
        storeInfo?.sequenceNo,
        storeInfo?.satelliteNo
      );
    if (checkingCondition && checkCombination) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMOS}`, null, params)
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
            return;
          }
          setTotalPages(res.totalPages + 1);
          const results = res?.results?.map((item) => {
            let details = {};
            details["id"] = item?.id;
            if (item?.createdBy) {
              details[
                "enteredBy"
              ] = `${item.createdBy?.firstName} ${item.createdBy?.lastName}`;
            }
            if (item?.createdAt) {
              details["memoDate"] = moment(item.createdAt).format("DD-MM-YYYY");
            }
            if (item?.appliedDate) {
              details["appliedDate"] = moment(item.appliedDate).format(
                "DD-MM-YYYY"
              );
              details["isoFormatAppliedDate"] = item.appliedDate;
            }
            if (item?.closedAt) {
              details["closedAt"] = moment(item.closedAt).format("DD-MM-YYYY");
            }
            if (item?.memo) {
              details["memo"] = item.memo;
            }
            if (item?.deposition) {
              details["deposition"] = item.deposition;
            }
            details["isClosed"] = item.isClosed;
            if (item?.category?.category) {
              details["category"] = item.category.category;
            }
            return details;
          });
          let array = [...pageArray];
          array.push(page);
          if (hasToReset) {
            setPageArray([1]);
            setData(results);
            if (res.totalPages === 1) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setPageArray(array);
            setData((prevData) => [...prevData, ...results]);
          }
        })
        .catch((err) => {
          console.log(err);
          setData([]);
        });
    }
  };
  const getTimelineData = (memoId) => {
    if (memoId) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_TIMELINE}${memoId}`
      )
        .then((res) => {
          if (res?.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setTimelineData(res);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const editMemoHandler = (data, action) => {
    switch (action) {
      case "timeline":
        getTimelineData(data?.id);
        setShowTimeline(true);
        setCurrentMemo(data);
      default:
        break;
    }
  };

  useEffect(() => {
    if (totalPages === null) {
      getTableData(pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);
  useEffect(() => {
    const userId = getOfflineData("subway", "user")?.userId;
    const store = userId.split("-");
    setStoreInfo({
      storeNo: parseInt(store[0]),
      sequenceNo: parseInt(store[1]),
      satelliteNo: parseInt(store[2]),
    });
    setRefresh(Math.random());
  }, []);
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEMO_CATEGORY}`, null, {
      typeModel: "Store",
    }).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        const results = res.map((ele) => ({
          label: ele.category,
          value: ele.id,
        }));
        setMemoCategoryList(results);
      }
    });
  }, []);
  useEffect(() => {
    getTableData(1, true);
  }, [category]);

  return (
    <div className="franchise-memo-con ">
      <h3>Memos</h3>
      <Grid container md={12}>
        <Grid item md={3}>
          <SelectComponent
            label="Select Category"
            onChange={(e) => {
              const value = e.target.value;
              const label =
                memoCategoryList?.find((ele) => ele.value === value) || {};
              setCategory(value);
              if (label) {
                setCategoryName(`${label?.value}` || "");
              } else {
                setCategoryName("");
              }
            }}
            options={memoCategoryList}
            value={categoryName}
          />
        </Grid>
      </Grid>

      <Grid
        container
        md={12}
        sx={{ display: "flex", flexDirection: "column", mt: "1em" }}
      >
        <StickyHeaderCon
          columnsList={memoCols}
          dataList={data}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
          maxWords={15}
          onClick={editMemoHandler}
          showAllFunc={setShowLargeText}
        />
      </Grid>
      <Drawer
        anchor="right"
        open={showLargeText.isOpen}
        onClose={() => setShowLargeText({ isOpen: false, text: "" })}
      >
        <ShowMemo
          showMemoText={showLargeText}
          onClose={() => setShowLargeText({ isOpen: false, text: "" })}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showTimeLine}
        onClose={() => {
          setShowTimeline(false);
          setTimelineData([]);
          setCurrentMemo("");
        }}
      >
        <MemoTimeline
          timelineData={timelineData}
          getTimelineData={getTimelineData}
          memo={currentMemo}
        />
      </Drawer>
    </div>
  );
};
export default Memos;
