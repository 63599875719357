import "./index.scss";

import { BellCorpStudioLogoContainer } from "../components/Bellcorp-Studio-Logo";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys, appSlugs } from "../../../utils/constants";
const MainContainer = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
  children
}) => {
  return (
    <>
      <div className="main-container">
        <IntranetHeader />
        <div className="side-nav-header">
          <HomeRedirect localstorageKey={token} />
          <h4>Profit And Loss Report</h4>
        </div>

        <div className="main-right-container">          
          <div className="content-div">
            <div>{children}</div>
            <BellCorpStudioLogoContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
