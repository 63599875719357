import * as XLSX from 'xlsx';

export const processExcelDates = (data, excelData, dateFields) => {
  const convertData = { ...excelData };
  data.forEach((item, index) => {
    dateFields.forEach((cell) => {
      const cellValue = convertData[`${cell}${index + 2}`]?.v;
      if (!cellValue) {
        return;
      }

      const dateValue = new Date(cellValue);
      if (`${dateValue}` === 'Invalid Date') {
        return;
      }

      const formattedDate = dateValue.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      convertData[`${cell}${index + 2}`].t = 'd';
      convertData[`${cell}${index + 2}`].v = formattedDate;
      convertData[`${cell}${index + 2}`].z = 'dd-mmm-yy;@';
      convertData[`${cell}${index + 2}`].s = {
        numFmt: 'dd-mm-yyyy;@',
        formatCode: 'dd-mm-yyyy;@',
      };
    });
  });

  return convertData;
};

export const processExcelPercentage = (data, excelData, numFields) => {
  const convertData = { ...excelData };
  data.forEach((item, index) => {
    numFields.forEach((cell) => {
      const cellValue = convertData[`${cell}${index + 2}`]?.v;
      if (!cellValue) {
        return;
      }
      convertData[`${cell}${index + 2}`].t = 'n';
      convertData[`${cell}${index + 2}`].z = '0.00%';
    });
  });

  return convertData;
};

export const getAllExcelColumns = (workSheet, headers) => {
  try {
    let range = XLSX.utils.decode_range(workSheet['!ref']);
    let columns = [];

    for (let i = range.s.c; i <= range.e.c; i++) {
      let cell = XLSX.utils.encode_cell({ r: range.s.r, c: i });
      if (headers.includes(workSheet[cell].v)) {
        columns.push(XLSX.utils.encode_col(i));
      }
    }
    return columns;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getExcelColumns = (headers, desiredHeaders, columns) => {
  const headersMap = headers.reduce((unqiueMap, elem, index) => {
    unqiueMap[elem] = index;
    return unqiueMap;
  }, {});

  return desiredHeaders.reduce((list, header) => {
    if (headersMap.hasOwnProperty(header)) {
      const index = headersMap[header];
      const column = columns[index];
      list.push(column);
    }
    return list;
  }, []);
};

export const convertTimeForSheet = (data, worksheet, headers, dateColumns) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateHeaders = getExcelColumns(headers, dateColumns, columns);
  return processExcelDates(data, worksheet, dateHeaders);
};

// function for adding dates directly to worksheet
export const excelWorksheetDates = (
  workbook,
  worksheet,
  sheetName,
  headers,
  data,
  dateColumns
) => {
  const columns = getAllExcelColumns(worksheet, headers);
  const dateHeaders = getExcelColumns(headers, dateColumns, columns);
  const convertedWorkSheet = processExcelDates(data, worksheet, dateHeaders);
  workbook.Sheets[sheetName] = convertedWorkSheet;
  workbook.SheetNames.push(sheetName);
};

export const removeExcelDecimal = (
  columns,
  headers,
  data,
  excelData,
  noDecimalFields
) => {
  const convertData = { ...excelData };
  const noDecimalColumns = getExcelColumns(headers, noDecimalFields, columns);
  data?.forEach?.((item, index) => {
    noDecimalColumns?.forEach?.((cell) => {
      const cellValue = convertData[`${cell}${index + 2}`]?.v;
      if (!cellValue) {
        return;
      }

      convertData[`${cell}${index + 2}`].t = 'n';
      convertData[`${cell}${index + 2}`].z = '0';
    });
  });

  return convertData;
};
