import { Button } from '@mui/material';
import { isDeleteAllowed, isUpdateAllowed } from '../../../utils/common-utils';

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: 'Id',
      id: 'id',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Parent',
      id: 'parent.name',
    },
    {
      label: 'GSTIN',
      id: 'gstin',
    },
    {
      label: 'Address',
      id: 'companyAddress',
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        return (
          <>
            <Button
              disabled={!isUpdateAllowed('master_data')}
              variant="text"
              onClick={() => editVendor(data, 'EDIT')}
            >
              Edit
            </Button>
            <Button
              disabled={!isDeleteAllowed('master_data')}
              variant="text"
              onClick={() => setDeleteDetails(data)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};
