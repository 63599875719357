/** @format */

import { convertToCamelCaseFromUnderScore } from "../../utils";
import { getOfflineData } from "../../utils/offline-services";
import { USER_ROLES } from "../../utils";
import moment from "moment";
import { qnetEndPoint } from "../../utils/endpoints";
export const HeaderConfig = () => {
  const user = getOfflineData(null, "user");
  const isUserNotStoreOwner =
    // user?.role?.type === USER_ROLES.STORE_OWNER ||
    user?.role?.type !== USER_ROLES.STORE &&
    user?.role?.type !== USER_ROLES.STORE_OWNER;

  let config = [
    {
      label: "Case ID",
      id: "id",
      render: (data, onClick) => {
        const id = data.id || data._id;
        return (
          <span
            id={Math.random()}
            className="clickable"
            // onClick={() => onClick({ ...data, id }, "VIEW")}
          >
            <a href={`${qnetEndPoint}/case/${id}`}>{id}</a>
            {/* {data.id || data._id} */}
          </span>
        );
      },
    },
    {
      label: "Case Type",
      id: "type",
      render: (data) => {
        const { type = {} } = data;
        return (
          <span key={Math.random()}>
            {convertToCamelCaseFromUnderScore(type)}
          </span>
        );
      },
    },
    {
      label: "Store Number",
      id: "store",
      render: (data) => {
        return (
          <span key={Math.random()}>
            {data?.store?.storeNumber || data?.storeDetails?.storeNumber}
          </span>
        );
      },
    },
    {
      label: "Status",
      id: "status",
      render: (data) => {
        const { status = {}, isCaseReopened = false } = data;
        return (
          <span key={Math.random()}>
            {convertToCamelCaseFromUnderScore(status)}
            {isCaseReopened &&
              status === "PENDING_SUPPLIER_ASSIGNED" &&
              `  (Re Opened)`}
          </span>
        );
      },
    },
    {
      label: "Created By",
      id: "createdBy",
      render: (data) => {
        const { createdBy = {} } = data;
        return <span key={Math.random()}>{createdBy.name}</span>;
      },
    },
    {
      label: "Product Name",
      id: "productName",
      render: (data) => {
        const productDetails = data.productDetails || {};
        return <span key={Math.random()}>{productDetails?.name}</span>;
      },
    },
    // {
    //   label:"Vendor",
    //   key:"vendor",
    //   render:(data,_, index) => {
    //     return (
    //       <div  key={index} >
    //         {data?.vendor?.name}
    //       </div>
    //     )
    //   }
    // },
    {
      label: "Updated By",
      id: "lastUpdatedBy",
      render: (data) => {
        const { lastUpdatedBy = {} } = data;
        return <span key={Math.random()}>{lastUpdatedBy?.name}</span>;
      },
    },
  ];

  if (isUserNotStoreOwner) {
    config = [
      ...config.slice(0, config.length - 1),
      {
        label: "Creation Date",
        id: "createdAt",
        render: (data, _, index) => {
          return (
            <div key={index}>{moment(data.createdAt).format("DD-MM-YYYY")}</div>
          );
        },
      },
      config[config.length - 1],
    ];
  }
  return config;
};
