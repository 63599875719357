import { useState } from 'react';
import './index.scss';
import { DateRangePicker } from 'rsuite';
import AutocompleteComponent from '../../../common-utilities/core/styled-autocomplete';
import { storeList } from '../../utils/constants';
import {
  clearProperty,
  setProperty,
} from '../../../common-utilities/utils/misc';
import { defaultData, getHeaderConfig } from './config';
import MuiTable from '../../../common-utilities/core/mui-table';
import CommonBtn from '../../../common-utilities/core/common-button';
import { toast } from 'react-toastify';
import { pick } from '../../../common-utilities/utils';
import { getDashboardData } from './apis';
import { actionHandler } from './utils';
import { defaultFltrDates } from '../../utils';

const Dashboard = () => {
  const defaultDates = defaultFltrDates();
  const [filters, setFilters] = useState({
    startDate: defaultDates[0],
    endDate: defaultDates[1],
  });
  const [data, setData] = useState(defaultData);

  const dateRangeHandler = (e) => {
    setFilters((prevVal) => ({
      ...prevVal,
      startDate: e?.[0] ? new Date(`${e[0]}`).toISOString() : null,
      endDate: e?.[1] ? new Date(`${e[1]}`).toISOString() : null,
    }));
  };

  const getData = (filter) => {
    const params = pick(filter, ['storeType', 'startDate', 'endDate'], true);
    getDashboardData(params, setData);
  };

  return (
    <div className="cf-approval-dashboard">
      <div className="filter">
        <DateRangePicker
          cleanable={true}
          placeholder="Select Date Range"
          onChange={dateRangeHandler}
          value={[
            filters.startDate ? new Date(filters.startDate) : null,
            filters.endDate ? new Date(filters.endDate) : null,
          ]}
        />

        <AutocompleteComponent
          label="Select Type"
          options={storeList}
          onChange={(e, value) => {
            if (value) {
              getData({ ...filters, storeType: value?.value });
              setProperty('storeType', value?.value, setFilters);
            } else {
              clearProperty('storeType', setFilters);
            }
          }}
          fullWidth={true}
          value={storeList.find((item) => item.value === filters.storeType)}
        />

        <div>
          <CommonBtn
            onClick={() => {
              if (!filters?.storeType) {
                toast.error('Please select type', { autoClose: 2000 });
                return;
              }
              getData(filters);
            }}
          >
            Apply
          </CommonBtn>
        </div>
      </div>
      {filters?.storeType && (
        <div>
          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={data}
            onClick={(type, data) => {
              actionHandler(type, data, filters);
            }}
          ></MuiTable>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
