import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'rsuite';
import CustomModal from '../../../common-utilities/core/modal';
import SiTable from '../../../common-utilities/core/table';
import { INVOICE_TRACKING_HOSTNAME } from '../../../common-utilities/utils/end-points';
import { VENDOR_ONBOARD_REST_URLS } from '../../../common-utilities/utils/end-points/vendor-onboard';
import { HTTP_METHODS, invokeApi } from '../../utils/http-service';
import { getHeaderConfig } from './helper';
import './index.scss';
import MuiTable from '../../../common-utilities/core/mui-table';
import {
  setProperty,
  clearProperty,
} from '../../../common-utilities/utils/misc';
import { getVendorList } from './api';
import ClearIcon from '@mui/icons-material/Clear';

const VendorList = () => {
  const [vendorDetails, setVendorDetails] = useState(null);
  const [vendorList, setVendorList] = useState({});
  const [filterLists, setFilterLists] = useState({
    vendorList: [],
  });
  const [updateData, setUpdateData] = useState({});
  const [filters, setFilters] = useState({
    limit: 10,
    page: 0,
    sortBy: '-createdAt',
  });

  useEffect(() => {
    loadData();
    getVendorList((list) => {
      setProperty('vendorList', list, setFilterLists);
    });
  }, []);

  const loadData = (providedFilters) => {
    const loadFilters = providedFilters ? {...providedFilters} :{...filters}
    let params = {
      ...loadFilters
    };
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR}`,
      null,
      params
    )
      .then((response) => {
        setVendorList(response);
      })
      .catch((err) => {});
  };

  const editVendor = (data) => {
    setVendorDetails(data);
  };

  const saveVendor = () => {
    let payload = {
      ...updateData,
    };

    invokeApi(
      HTTP_METHODS.PUT,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.UPDATE_VENDOR}/${vendorDetails.id}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setVendorDetails(null);
          loadData();
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="vendor-list">
      <div>
        <Autocomplete
          getOptionLabel={(option) => option?.label}
          className="poper-select"
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                clearProperty('vendor', setFilters);
              }}
            />
          }
          options={filterLists.vendorList}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Vendors"
              className="filter-width"
            />
          )}
          onChange={(event, val) => {
            if (val?.value) {
              setProperty(['searchKey', 'page'], [val?.value || null,1], setFilters);
              
              loadData({...filters, page:1, searchKey:val?.value})
              return;
            }
            const filter = {...filters}
            delete filter.searchKey
            filter.page = 1
            setProperty([ 'page'], [1], setFilters);
            clearProperty('searchKey', setFilters);
            loadData(filter)
          }}
        />
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editVendor)}
        dataList={vendorList.results || []}
        filters={filters}
        pageCount={vendorList.totalPages}
        onClick={editVendor}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {vendorDetails && (
        <CustomModal
          onClose={() => setVendorDetails(null)}
          title={`Update vendor ${vendorDetails.code}`}
        >
          <div className="update-vendor-form">
            <TextField
              size="small"
              name="name"
              value={vendorDetails.name || ''}
              label="Name"
              onChange={(e) => {
                setVendorDetails({
                  ...vendorDetails,
                  [e.target.name]: e.target.value,
                });
                setUpdateData((prevVal) => ({
                  ...prevVal,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
            <TextField
              size="small"
              name="code"
              value={vendorDetails.code || ''}
              label="Code"
              onChange={(e) => {
                setVendorDetails({
                  ...vendorDetails,
                  [e.target.name]: e.target.value,
                });
                setUpdateData((prevVal) => ({
                  ...prevVal,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
            <Button
              onClick={saveVendor}
              variant="contained"
              color="primary"
              disabled={!vendorDetails.name || !vendorDetails.code}
            >
              Save
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default VendorList;
