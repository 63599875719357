import React, { useState, useEffect } from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
import { getLimitsForUserLoggedIn } from "../../utils/common-apis";
import AppCard from "../../core/cards";
import { getOfflineData } from "../../utils/offline-services";

const AdminAccess = () => {
  const [listAllowedApps, setAllowedApps] = useState([]);
  useEffect(() => {
    getLimitsForUserLoggedIn((limits) => {
      setAllowedApps(limits);
    });
  }, []);
  const user = getOfflineData("user");
  return (
    <div className="admin-allowed-apps">
      {/* <Typography className="my-access-title" sx={{ marginBottom: 2 }}>
        Dashboard
      </Typography>
      <Typography className="user-intro" sx={{ marginBottom: 2 }}>
        Hi, {user?.name}. Welcome back to Subway!
      </Typography> */}
      <div className="apps-con">
        {listAllowedApps?.length > 0 ? (
          listAllowedApps.map((app) => {
            return (
              <Grid item md={2} sx={{ mb: 5 }}>
                <AppCard appDetails={app} />
              </Grid>
            );
          })
        ) : (
          <h2>No Apps Available</h2>
        )}
      </div>
    </div>
  );
};

export default AdminAccess;
