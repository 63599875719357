import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  masterPoFilters,
  budgetStatus,
  getBudgetHeadersConfig,
  getMasterPoHeadersConfig,
} from "./helper";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import {
  AutoCompleteSelect,
  DateRange,
  NumberRange,
  RegexAutoComplete,
  getLast15DaysDateRange,
} from "./utils/utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import ClearIcon from "@mui/icons-material/Clear";
import { exportToExcel } from "../../../common-utilities/utils";
import CustomModal from "../../../common-utilities/core/modal";

const PoMaster = () => {
  const [dataList, setDataList] = useState({});
  const [filterLabel, setFilterLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [vendorList, setVendorList] = useState([]);
  const [vendorNameList, setVendorNameList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
  });

  const loadData = (filters, isDownload = false) => {
    const { startDate, endDate } = getLast15DaysDateRange();
    let params = {
      ...filters,
      startDate,
      endDate,
    };

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REPORTS}combined_reports`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        }
        if (isDownload) {
          exportToExcel(response.results, "Budget Report");
        } else {
          const results = response?.results?.map((ele) => ({
            ...ele,
            address: ele?.storeData?.address,
          }));
          setDataList({ ...response, results });
        }
      })
      .catch((err) => console.log(err));
  };
  const searchHandler = () => {
    let params = { ...filters, page: 1 };
    if (filterLabel && filterValue) {
      params["keyName"] = filterLabel;
      params["keyValue"] = filterValue;
    }
    loadData(params);
  };
  const getVendorList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`
    )
      .then((response) => {
        if (response.code) {
        } else {
          if (filterLabel === "vendorCode") {
            const result = response?.map((ele) => ({
              label: ele.code,
              value: ele.code,
            }));
            setVendorList(result);
          }
          if (filterLabel === "vendorName") {
            const result = response?.map((ele) => ({
              label: ele.name,
              value: ele.code,
            }));
            setVendorNameList(result);
          }
        }
      })
      .catch((err) => {});
  };

  const getMainLocationList = () => {
    let params = {
      poBudgetLocations: true,
    };

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.STORE_DATA_LIST}`,
      null,
      params
    )
      .then((res) => {
        let results = [];
        switch (filterLabel) {
          case "siteCode":
            results =
              res?.map?.((item) => {
                return {
                  label: item.code,
                  value: item.id,
                };
              }) || [];
            break;
          case "restaurantName":
            results =
              res?.map?.((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              }) || [];
            break;
          default:
            break;
        }
        setStoreList(results);
      })
      .catch((err) => console.log(err));
  };

  const getStoreList = (searchTerm, field, optionListFun) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${FINANCE_REST_URLS.GET_ALL_STORES}`,
        null,
        {
          [field]: searchTerm,
        }
      ).then((res) => {
        if (field === "storeNumber") {
          const storeList = res.results?.map((ele) => ele.storeNumber);
          optionListFun(storeList);
        }
        if (field === "restaurantName") {
          const storeList = res.results?.map((ele) => ele.restaurantName);
          optionListFun(storeList);
        }
        if (field === "format") {
          const storeList = res.results?.map((ele) => ele.format);
          optionListFun(storeList);
        }
      });
    }, 300);
  };
  const getFilterValue = (type) => {
    switch (type) {
      case "siteCode":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={storeList}
            filterLabel={filterLabel}
          />
        );
      case "restaurantName":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={storeList}
            filterLabel={filterLabel}
          />
        );
      case "format":
        return (
          <RegexAutoComplete
            getOptionsListFun={getStoreList}
            setFilterValue={setFilterValue}
            field="format"
            filterLabel={filterLabel}
          />
        );
      case "vendorCode":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={vendorList}
            filterLabel={filterLabel}
          />
        );

      case "vendorName":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={vendorNameList}
            filterLabel={filterLabel}
          />
        );
      default:
        break;
    }
  };
  const downloadReport = () => {
    const params = { ...filters, download: true };
    params.page = 1;
    params.limit = dataList.totalResults;
    if (filterLabel && filterValue) {
      params["keyName"] = filterLabel;
      params["keyValue"] = filterValue;
    }
    loadData(params, true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  useEffect(() => {
    if (filterLabel === "vendorCode" || filterLabel === "vendorName") {
      getVendorList();
    }
    if (filterLabel === "siteCode" || filterLabel === "restaurantName") {
      getMainLocationList();
    }
  }, [filterLabel]);

  return (
    <div>
      <div className="reports-filter-con">
        <Grid
          container
          md={12}
          sx={{ mt: 2, display: "flex", alignItems: "center" }}
        >
          <Grid item md={2} sx={{ mr: 2 }}>
            <Autocomplete
              options={masterPoFilters}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Select Filter" />
              )}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setFilterLabel("");
                    setFilterValue("");
                    loadData({ ...filters, page: 1 });
                  }}
                />
              }
              onChange={(e, val) => {
                if (val) {
                  setFilterValue("");
                  setFilterLabel(val.value);
                }
              }}
            />
          </Grid>

          {filterLabel && getFilterValue(filterLabel)}

          <Grid md={2} sx={{ ml: 2 }}>
            {filterLabel && (
              <Button
                variant="outlined"
                onClick={searchHandler}
                disabled={!filterValue || Object.keys(filterValue).length === 0}
              >
                Search
              </Button>
            )}
          </Grid>
        </Grid>
        <DownloadBtn
          onClick={() => {
            downloadReport();
          }}
        />
      </div>

      <MuiTable
        columnsList={getMasterPoHeadersConfig()}
        dataList={dataList.results || []}
        filters={filters}
        pageCount={dataList.totalPages}
        maxWords={3}
        showAllFunc={setShowLargeText}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          let params = { ...filters, page };
          if (filterLabel && filterValue) {
            params["keyName"] = filterLabel;
            params["keyValue"] = filterValue;
          }
          loadData({
            ...params,
            page,
          });
        }}
      />
      {showLargeText.isOpen && (
        <CustomModal
          onClose={() =>
            setShowLargeText({
              isOpen: false,
              text: "",
            })
          }
        >
          {showLargeText.text}
        </CustomModal>
      )}
    </div>
  );
};

export default PoMaster;
