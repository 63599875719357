export const SIDE_MENU = [
  {
    label: "Users",
    url: "/",
    value: "/",
  },
  {
    label: "Apps",
    url: "/apps",
    value: "/apps",
  },
  {
    label: "Roles",
    url: "/roles",
    value: "/roles",
  },
];
export const MOBILE_MENU = [
  {
    label: "Users",
    url: "/",
    value: "/",
  },
];

export const pathNames = {
  home: "/",
};
