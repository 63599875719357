import React from "react";
import { Grid, TextField } from "@mui/material";
const Commercial = ({ commercials, setInfo }) => {
  const handleChange = (event) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    setInfo((prevData) => {
      let finalValue = {};
      if (event.target.type === "number") {
        if (!Number.isInteger(parseInt(fieldValue))) {
          delete prevData.commercials?.[fieldName];
        } else {
          finalValue = { [fieldName]: fieldValue };
        }
      } else {
        finalValue = { [fieldName]: fieldValue };
      }
      return {
        ...prevData,
        commercials: {
          ...prevData.commercials,
          ...finalValue,
        },
      };
    });
  };
  return (
    <div className="doc-container">
      <div className="main-commercial-container">
        <Grid xs={12} item md={5}>
          <Grid
            item
            md={12}
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              gridGap: "2em",
              flexWrap: "wrap",
            }}
          >
            <Grid xs={12} item md={5}>
              <TextField
                fullWidth
                size="small"
                type="number"
                className="new-textfield"
                label="Expected Rent(In Rs)"
                variant="outlined"
                name="expectedRent"
                value={commercials?.expectedRent || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid xs={12} item md={5}>
              <TextField
                fullWidth
                size="small"
                label="MG(In Rs)"
                type="number"
                variant="outlined"
                className="new-textfield"
                name="mg"
                value={commercials?.mg || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={5}>
              <TextField
                fullWidth
                size="small"
                label="RS% Dine in"
                type="number"
                variant="outlined"
                name="dineIn"
                className="new-textfield"
                value={commercials?.dineIn || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={5}>
              <TextField
                fullWidth
                size="small"
                label="RS% Del"
                variant="outlined"
                name="del"
                type="number"
                className="new-textfield"
                value={commercials?.del || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                fullWidth
                size="small"
                label="CAM"
                variant="outlined"
                name="cam"
                className="new-textfield"
                value={commercials?.cam || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={5}></Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Commercial;
