import { Button } from '@mui/material';
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from '../../../../common-utilities/utils';
import {
  getPurchaseOrderAmount,
  getPurchaseOrderWithoutTaxAmount,
} from '../../purchase-order/helper';
import { getGRNOrderAmountWithTax } from '../../goods-receive-notes/grn-details/helper';
import { DATE_FORMATS } from '../../../../common-utilities/utils/constants';

export const getHeaderConfig = (editVendor, status) => {
  const ADVANCE_HEADER = [
    {
      label: 'PO Number',
      id: 'poNumber',
      render: (data) => {
        const { poId = {} } = data;
        const { poNumber = '' } = poId || {};
        return poNumber;
      },
    },
    {
      label: 'Invoice Document Number',
      id: 'invoiceDocumentNumber',
    },
    {
      label: 'Site Code & Name',
      id: 'siteCode',
      render: (data) => {
        const { site = {} } = data;
        return (site && `${site.code} - ${site.name}`) || '';
      },
    },
    {
      label: 'Vendor code',
      id: 'vendorCode',
    },
    {
      label: 'Vendor Name',
      id: 'vendorName',
      render: (data) => {
        const { poId = {} } = data;
        const { vendorName = '' } = poId || {};
        return vendorName;
      },
    },
    {
      label: 'PO Base Amt',
      id: 'baseAMount',
      render: (data) => {
        const { poId = {} } = data;
        const { items = [] } = poId || {};
        return getPurchaseOrderWithoutTaxAmount(items);
      },
    },
    {
      label: 'Advance %',
      id: 'gstAMount',
      render: (data) => {
        const { poId = {} } = data;
        const { advance = {} } = poId || {};
        return advance && advance.advancePercentage;
      },
    },
    {
      label: 'PO Requested Advance Amt',
      id: 'totalAMount',
      render: (data) => {
        const { poId = {} } = data;
        const { advance = {} } = poId || {};
        return advance && advance.advanceAmount;
      },
    },
    {
      label: 'Actual Advance Amount',
      id: 'invoiceAmount',
    },
    {
      label: 'Received Advance Amount',
      id: 'sentAmount',
    },
    {
      label: status === 'approval_in_progress' ? 'Pending With' : 'Adv Status',
      id: 'status',
      render: (data) => {
        if (status === 'approval_in_progress') {
          const { approvalId = {} } = data;
          return approvalId && approvalId.pendingWith;
        }

        const { poId = {} } = data;
        const { advance = {} } = poId || {};
        return (
          advance && convertToCamelCaseFromUnderScore(advance.advanceStatus)
        );
      },
    },
    {
      label: 'Pending with',
      id: 'approvalId.pendingWith',
    },
    {
      label: 'Created By',
      id: 'creatorName',
      render: (data) => {
        const { poId = {} } = data;
        return (poId && poId.creatorName) || '-';
      },
    },
    {
      label: 'Created on',
      id: 'createdAt',
      render: (data) => {
        const { createdAt } = data;
        return formatDate(createdAt, DATE_FORMATS['DD-MM-YYYY']);
      },
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        const { status } = data;
        return (
          <>
            <Button variant="text" onClick={() => editVendor(data, 'VIEW')}>
              View
            </Button>
            {status === 'draft' && (
              <Button variant="text" onClick={() => editVendor(data, 'EDIT')}>
                Edit
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const headers = [
    {
      label: 'GRN Number',
      id: 'grnNo',
      render: (data) => {
        const { grnId = {} } = data;
        const { grnNo = '' } = grnId || {};
        return grnNo;
      },
    },
    {
      label: 'Invoice Document Number',
      id: 'invoiceDocumentNumber',
    },
    {
      label: 'PO Number',
      id: 'poNumber',
      render: (data) => {
        const { poId = {} } = data;
        const { poNumber = '' } = poId || {};
        return poNumber;
      },
    },
    {
      label: 'Site Code',
      id: 'siteCode',
      render: (data) => {
        const { poId = {} } = data;
        const { budget = {} } = poId || {};
        return (budget && budget.siteCode) || '';
      },
    },
    {
      label: 'Site Name',
      id: 'siteName',
      render: (data) => {
        const { poId = {} } = data;
        const { budget = {} } = poId || {};
        return (budget && budget.siteName) || '';
      },
    },
    {
      label: 'Vendor Code',
      id: 'vendorCode',
      render: (data) => {
        const { poId = {} } = data;
        return poId && poId.vendorCode;
      },
    },
    {
      label: 'Vendor Name',
      id: 'vendorName',
      render: (data) => {
        const { poId = {} } = data;
        return poId && poId.vendorName;
      },
    },
    {
      label: status === 'approval_in_progress' ? 'Pending With' : 'GRN Status',
      id: 'status',
      render: (data) => {
        if (status === 'approval_in_progress') {
          const { approvalId = {} } = data;
          return approvalId && approvalId.pendingWith;
        }
        return convertToCamelCaseFromUnderScore(data.status);
      },
    },
    {
      label: 'Total PO Value',
      id: 'poValue',
      render: (data) => {
        const { poId = {} } = data;
        const { items = {} } = poId || {};
        return getPurchaseOrderAmount(items);
      },
    },
    {
      label: 'Total GRN Value',
      id: 'poValue',
      render: (data) => {
        const { poId = {}, grnId = [] } = data;
        const { items = [] } = poId || {};
        const { receivedItems = [] } = grnId || {};
        return getGRNOrderAmountWithTax(items, receivedItems);
      },
    },
    {
      label: 'Invoice Amount',
      id: 'invoiceAmount',
    },
    {
      label: 'Paid Amount',
      id: 'sentAmount',
    },
    {
      label: 'Payment Status',
      id: 'paymentStatus',
      valueConversionLogic: (val) =>
        convertToCamelCaseFromUnderScore(val || ''),
    },
    {
      label: 'Actions',
      id: 'actions',
      render: (data) => {
        const { status } = data;
        return (
          <>
            <Button variant="text" onClick={() => editVendor(data, 'VIEW')}>
              View
            </Button>
            {status === 'draft' && (
              <Button variant="text" onClick={() => editVendor(data, 'EDIT')}>
                Edit
              </Button>
            )}
          </>
        );
      },
    },
  ];
  if (status === 'advance_invoice') {
    return ADVANCE_HEADER;
  }
  return headers;
};
