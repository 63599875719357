import { Route, Routes } from "react-router-dom";
import FinancePrivateRoute from "./private-route";
import BudgetDetails from "../components/budget/budget-details";
import Home from "../components/home";
import CreatePO from "../components/purchase-order/create-po";
import PODetails from "../components/purchase-order/po-details";
import BudgetApprovalDetails from "../components/budget/budget-approval-details";
import CreateGRN from "../components/goods-receive-notes/create-grn";
import GRNDetails from "../components/goods-receive-notes/grn-details";
import EditGRN from "../components/goods-receive-notes/edit-grn";
import InvoiceDataDetails from "../components/invoices-data/invoice-data-details";
import EditPO from "../components/purchase-order/edit-po";

const FinanceRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<FinancePrivateRoute />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="budget-details/:id" element={<BudgetDetails />} />
        <Route
          exact
          path="approve-budget-details/:id"
          element={<BudgetApprovalDetails />}
        />
        <Route
          exact
          path="purchase-order/create/:budgetId"
          element={<CreatePO />}
        />
        <Route exact path="grn-order/create/:poId" element={<CreateGRN />} />
        <Route exact path="grn-order/edit/:id" element={<EditGRN />} />
        <Route exact path="purchase-order/:id" element={<PODetails />} />
        <Route exact path="purchase-order/edit/:id" element={<EditPO />} />
        <Route exact path="grn-order/:id" element={<GRNDetails />} />
        <Route exact path="invoice-data/:id" element={<InvoiceDataDetails />} />
      </Route>
    </Routes>
  );
};

export default FinanceRoutes;
