import { useState, useEffect } from 'react';
import { locationList, vendorList } from './apis';
import {
  clearProperty,
  setProperty,
} from '../../../../common-utilities/utils/misc';
import { Autocomplete, TextField } from '@mui/material';
const PoFilters = ({
  vendorKey = 'vendorCode',
  siteKey = 'site',
  setFilters,
  filters,
  showStatus,
  statusKey = 'status',
  statusList = [],
  showVendorDropDown = true,
  disableStatus=false
}) => {
  const [lists, setLists] = useState({
    vendorList: [],
    siteList: [],
  });

  useEffect(() => {
    if (showVendorDropDown) {
      vendorList((vendorLst) => {
        setProperty('vendorList', vendorLst, setLists);
      });
    }

    locationList((siteLst) => {
      setProperty('siteList', siteLst, setLists);
    });
  }, [showVendorDropDown]);

  return (
    <>
      {showVendorDropDown && (
        <Autocomplete
          value={
            filters?.[vendorKey]
              ? lists.vendorList.find(
                  (item) => item.value === filters?.[vendorKey]
                )
              : null
          }
          onChange={(e, val) => {
            if (val) {
              setProperty(vendorKey, val?.value, setFilters, false);
            } else {
              clearProperty(vendorKey, setFilters, false);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Vendor Name/ Code"
              sx={{
                minWidth: 220,
              }}
            />
          )}
          options={lists.vendorList}
        />
      )}

      <Autocomplete
        value={
          filters?.[siteKey]
            ? lists.siteList.find((item) => item.value === filters?.[siteKey])
            : null
        }
        onChange={(e, val) => {
          if (val) {
            setProperty(siteKey, val?.value, setFilters);
          } else {
            clearProperty(siteKey, setFilters);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Site Name/ Code"
            sx={{
              minWidth: 220,
            }}
          />
        )}
        options={lists.siteList}
      />

      {showStatus && (
        <Autocomplete
          value={
            filters?.[statusKey]
              ? statusList.find((item) => item.value === filters?.[statusKey])
              : null
          }
          disabled={disableStatus}
          onChange={(e, val) => {
            if (val) {
              setProperty(statusKey, val?.value, setFilters, false);
            } else {
              clearProperty(statusKey, setFilters, false);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Status"
              sx={{
                minWidth: 220,
              }}
            />
          )}
          options={statusList}
        />
      )}
    </>
  );
};

export default PoFilters;
