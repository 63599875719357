import "./index.scss";
import { BellCorpStudioLogoContainer } from "../components/Bellcorp-Studio-Logo";
import Header from "../core/header";
import IntranetHeader from "../../../core/header";
import HomeRedirect from "../../common-utilities/core/home-redirect";
import { tokenMainKeys } from "../../../utils/constants";
import OtherAppSideMenu from "../../common-utilities/core/other-app-side-menu";
import SideMenu from "../core/side-menu";
import { useLocation } from "react-router-dom";
const MainContainer = ({
  children,
  reloadPendingApprovals,
  setReloadPendingApprovals,
}) => {
  const location = useLocation()
  
  return (
    <>
      <div className="subway-app-main-container">
        <div className="subway-app-main-header">
          <IntranetHeader />
        </div>
        <div className="subway-app-current-app-container">
          <div className="main-right-container">
            <OtherAppSideMenu
              appName="BD Lead"
              SideNavCom={SideMenu}
              keyName={tokenMainKeys.bdLead}
            />
          </div>
          <div className="main-left-container">
            <div className="content-div">
              <div>{children}</div>
              <BellCorpStudioLogoContainer customClass={`${location.pathname.includes('/bd-lead/lead') && "lower-logo" } `} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
