import { allCasesStatues, getUserRole } from "../../utils";
import { getOfflineData } from "../../utils/offline-services";

export const FAULT_RESPONSE_OPTIONS = (caseDetails) => {
  const pendingResearchDisabled = caseDetails?.pendingResearch || false;
  // const pendingResearchDisabled =  false
  return [
    {
      label: "Accepted",
      value: "FAULT_ACCEPTED",
    },
    {
      label: "Rejected",
      value: "FAULT_REJECTED",
    },
    {
      label: "Pending Addition Research",
      value: "PENDING_ADDITION_RESEARCH",
      disabled: pendingResearchDisabled,
    },
  ];
};

export const RESU_RESPONSE_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const CREDIT_METHOD = [
  {
    label: "CARD",
    value: "CARD",
  },
  {
    label: "NEFT",
    value: "NEFT",
  },
  {
    label: "CASH",
    value: "CASH",
  },
  {
    label: "UPI",
    value: "UPI",
  },
];

export const DEBIT_FROM = [
  {
    label: "DC",
    value: "DC",
  },
  {
    label: "Vendor",
    value: "VENDOR",
  },
];

export const formatFaultPayload = (formDetails, caseDetails) => {
  const {
    status = "",
    cause = "",
    correctiveAction = "",
    credit = "",
    isContacted = "",
    contact = "",
    rejectedAgainst = {},
    notes = "",
    contactedPerson,
    contactDate,
  } = formDetails;
  const role = getUserRole();
  const user = getOfflineData(null, "user");

  let payload = {
    status: status,
    caseId: caseDetails.id,
    adoptedBy: user.id,
    qa: user.id,
    fault: {},
  };

  let additionalConversationDetails = {};
  if (isContacted !== false && isContacted !== "false") {
    additionalConversationDetails.contact = contact;
    additionalConversationDetails.contactedPerson = contactedPerson;
    additionalConversationDetails.contactDate = contactDate;
  }

  const conversationWithStore = {
    isContacted: isContacted || false,
    ...additionalConversationDetails,
  };

  if (status === "PENDING_ADDITION_RESEARCH") {
    payload = {
      ...payload,
      fault: {
        notes,
      },
    };
  } else if (status === "FAULT_ACCEPTED") {
    payload = {
      ...payload,
      fault: {
        acceptedBy: role.belongsTo,
        cause: cause,
        correctiveAction: correctiveAction,
        notes: notes,
        credit: credit.value,
        conversationWithStore,
      },
    };
  } else if (status === "FAULT_REJECTED") {
    payload = {
      ...payload,
      fault: {
        rejectedBy: role.belongsTo,
        rejectedAgainst: rejectedAgainst.value,
        notes,
        conversationWithStore,
      },
    };
  }

  return payload;
};

export const formatAssignToPayload = (formDetails) => {
  const { type = {}, details = {} } = formDetails;

  let payload = {
    status: type.bl,
  };

  if (payload.status === "ASSIGNED_TO_VENDOR") {
    payload.vendor = details.id;
  } else {
    payload.dc = details.id;
  }

  return payload;
};

export const CREDIT_OPTIONS = [
  {
    label: "Full",
    value: "FULL",
  },
  {
    label: "Partial",
    value: "PARTIAL",
  },
  {
    label: "None",
    value: "NONE",
  },
];

export const pendingCreditMethods = [
  { label: "Credit Invoice", value: "Credit Invoice" },
  {
    label: "Insufficient Balance",
    value: "Insufficent Balance",
  },
  {
    label: "Issue Check",
    value: "Issue Check",
  },
  {
    label: "Product Replacement",
    value: "Product Replacement",
  },
];

export const creditCurrency = [
  { label: "INR", value: "INR" },
  { label: "USD", value: "USD" },
];

export const REJECTED_TO = [
  {
    label: "Store",
    value: "STORE",
  },
  {
    label: "Vendor",
    value: "VENDOR",
  },
  {
    label: "dc",
    value: "DC",
  },
];

export const isSaveDisabled = (status, formDetails, caseDetails) => {
  const {
    cause = "",
    correctiveAction = "",
    credit = "",
    isContacted = "",
    contact = "",
    rejectedAgainst = "",
    notes = "",
    finalFaultDetermination = "",
    finalCreditAssignment = "",
    mediationNotes = "",
    creditMethod,
    invoiceNumber,
    creditAmount,
    creditCurrency,
    creditNotes,
    contactedPerson,
    contactDate,
    creditNotesIssuanceDate,
    type,
    creditNoteNumber,
  } = formDetails || {};
  // formDetails.creditNoteNumber
  const storeContacted =
    isContacted !== false && isContacted !== "false" && isContacted;
  const contactStoreValidation =
    !contactedPerson?.trim() || !!!contactDate || !contact;
  switch (status) {
    case "PENDING_SUPPLIER_ASSIGNED":
      return !type ||
        (type?.bl === "ASSIGNED_TO_VENDOR" && !caseDetails?.vendor)
        ? !formDetails?.details
        : false;
    case "ASSIGNED_TO_DC":
      if (formDetails.status === "FAULT_ACCEPTED") {
        if (storeContacted) {
          return (
            !cause ||
            !correctiveAction ||
            contactStoreValidation ||
            !credit ||
            !notes?.trim()
          );
        }

        return (
          !cause?.trim() ||
          !correctiveAction?.trim() ||
          !credit ||
          !notes?.trim()
        );
      }
      if (formDetails.status === "FAULT_REJECTED") {
        const faultRejectedValidation = !rejectedAgainst || !notes?.trim();
        if (storeContacted) {
          return contactStoreValidation || faultRejectedValidation;
        }
        return faultRejectedValidation;
      }
      if (formDetails.status === "PENDING_ADDITION_RESEARCH") {
        return !notes?.trim();
      }
      return !formDetails.status;

    case "ASSIGNED_TO_VENDOR":
      if (formDetails.status === "FAULT_ACCEPTED") {
        if (isContacted !== false && isContacted !== "false" && isContacted) {
          return (
            !contact ||
            !cause ||
            !correctiveAction ||
            !!!contactDate ||
            !contactedPerson?.trim() ||
            !credit ||
            !notes?.trim()
          );
        }
        return (
          !cause?.trim() ||
          !correctiveAction?.trim() ||
          !credit ||
          !notes?.trim()
        );
      }
      if (formDetails.status === "FAULT_REJECTED") {
        return !rejectedAgainst || !notes?.trim();
      }
      if (formDetails.status === "PENDING_ADDITION_RESEARCH") {
        return !notes?.trim();
      }
      return !formDetails.status;

    case "PENDING_CREDIT":
      return (
        !creditMethod ||
        invoiceNumber?.length < 9 ||
        !creditAmount ||
        !creditCurrency ||
        !creditNotes ||
        !creditNotesIssuanceDate ||
        !creditNoteNumber
      );
    case allCasesStatues.mediation:
      return (
        !finalFaultDetermination || !finalCreditAssignment || !mediationNotes
      );
    default:
      return false;
  }
};

export const formatFileObj = (attachment) => {
  let fileNameSplits = attachment.title.split(".");
  return {
    ...attachment,
    type: fileNameSplits[fileNameSplits.length - 1],
  };
};

export const SEVERITY_OPTIONS = [
  {
    label: "Medium",
    value: "MEDIUM",
  },
  {
    label: "Low",
    value: "LOW",
  },
];

export const formatCreditInfo = (formDetails) => {
  const {
    creditMethod,
    invoiceNumber,
    creditAmount,
    creditCurrency,
    creditNotes,
    creditNotesIssuanceDate,
    creditNoteNumber,
  } = formDetails;

  let payload = {
    status: "CREDIT_ISSUED",
    creditInfo: {
      details: {
        invoiceNumber,
        creditMethod: creditMethod?.value,
        creditAmount: parseInt(creditAmount),
        creditCurrency: creditCurrency?.value,
        creditNotes,
        creditNotesIssuanceDate,
        creditNoteNumber,
      },
    },
  };

  return payload;
};
export const CREDIT_ISSUED_STATUS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
