import GroupIcon from "@mui/icons-material/Group";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AppsIcon from "@mui/icons-material/Apps";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WebIcon from "@mui/icons-material/Web";
import SummarizeIcon from "@mui/icons-material/Summarize";
export const SIDE_MENU = [
  {
    label: "Users",
    url: "/",
    value: "/",
    logo: <GroupIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Departments",
    url: "/departments",
    value: "/departments",
    logo: <GroupIcon sx={{ mr: 2 }} />,
  },
  {
    label: "My Access",
    url: "/my-access",
    value: "/my-access",
    logo: <AccessibilityIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Apps",
    url: "/apps",
    value: "/apps",
    logo: <AppsIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Roles",
    url: "/roles",
    value: "/roles",
    logo: <AdminPanelSettingsIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Bulletins",
    url: "/blogs",
    value: "/blogs",
    logo: <WebIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Reports",
    url: "/central-reports",
    value: "/central-reports",
    logo: <SummarizeIcon sx={{ mr: 2 }} />,
  },
];
export const USER_SIDE_MEMU = [
  {
    label: "My Access",
    url: "/",
    value: "/",
    logo: <AccessibilityIcon sx={{ mr: 2 }} />,
  },
  {
    label: "Reports",
    url: "/central-reports",
    value: "/central-reports",
    logo: <SummarizeIcon sx={{ mr: 2 }} />,
  },
];
export const MOBILE_MENU = [
  {
    label: "Users",
    url: "/",
    value: "/",
  },
];

export const pathNames = {
  home: "/",
  blog: "/blogs",
};
