import React, { useState } from "react";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { PETTY_EXPENSE_ROLES } from "../../utils/constants";
import { dateFun } from "../../../common-utilities/utils";
import { downloadFile } from "../../../invoice-tracking/utils/common-utils";
import { CardContent, Card, Button, Chip, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
const ViewVoucher = ({ data, setViewVoucher }) => {
  const userDetails = getOfflineData("pretty-expense", "user");
  const role = userDetails?.role;
  const [isApproveModal, setIsApproveModal] = useState({
    isOpen: false,
    actionBy: "",
  });
  return (
    <div className="invoice-details-container">
      <div className="header-section">
        <div>
          <IconButton
            variant="contained"
            onClick={() => setViewVoucher({ isView: false, data: {} })}
          >
            <ArrowBack />
          </IconButton>
          <span className="title">Voucher Details : {data?.voucherId} </span>
        </div>
        <div className="right-section">
          {role === PETTY_EXPENSE_ROLES.AM &&
            data?.assignedTo === PETTY_EXPENSE_ROLES.AM &&
            data?.am === userDetails?.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsApproveModal({
                    isOpen: true,
                    actionBy: PETTY_EXPENSE_ROLES.AM,
                  });
                }}
              >
                Approve / Reconsider Voucher
              </Button>
            )}
          {role === PETTY_EXPENSE_ROLES.RM &&
            data?.assignedTo === PETTY_EXPENSE_ROLES.RM &&
            data?.rm === userDetails?.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsApproveModal({
                    isOpen: true,
                    actionBy: PETTY_EXPENSE_ROLES.RM,
                  });
                }}
              >
                Approve / Reconsider Voucher
              </Button>
            )}
          {role === PETTY_EXPENSE_ROLES.VERIFICATION &&
            data?.assignedTo === PETTY_EXPENSE_ROLES.VERIFICATION &&
            data?.verificationAccount === userDetails?.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsApproveModal({
                    isOpen: true,
                    actionBy: PETTY_EXPENSE_ROLES.VERIFICATION,
                  });
                }}
              >
                Approve / Reconsider Voucher
              </Button>
            )}
          {role === PETTY_EXPENSE_ROLES.PAYMENT &&
            data?.assignedTo === PETTY_EXPENSE_ROLES.PAYMENT &&
            data?.paymentAccount === userDetails?.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsApproveModal({
                    isOpen: true,
                    actionBy: PETTY_EXPENSE_ROLES.PAYMENT,
                  });
                }}
              >
                Approve / Reconsider Voucher
              </Button>
            )}
          {role === PETTY_EXPENSE_ROLES.FINANCE &&
            data?.assignedTo === PETTY_EXPENSE_ROLES.FINANCE &&
            data?.financeAccount === userDetails?.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsApproveModal({
                    isOpen: true,
                    actionBy: PETTY_EXPENSE_ROLES.FINANCE,
                  });
                }}
              >
                Approve / Reconsider Voucher
              </Button>
            )}
          {data?.assignedTo && (
            <Chip
              label={`Current Status : Assigned to ${data?.assignedTo}`}
              component="span"
              variant="outlined"
            />
          )}
        </div>
      </div>
      <div className="invoice-details">
        <Card>
          <CardContent>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Voucher Id</span>
                <span className="i-value">{data?.voucherId}</span>
              </div>

              <div className="i-key-value">
                <span className="i-key">AM Approve Status</span>
                {data?.assignedTo === "AM" ? (
                  <span className="i-value">Approval Pending </span>
                ) : (
                  <span className="i-value">{data?.amApproved}</span>
                )}
              </div>

              {data.rmApprovalRequired && (
                <div className="i-key-value">
                  <span className="i-key">RM Approve Status</span>
                  {!data?.rmApproved ? (
                    "-"
                  ) : data?.rmApproved === "Open" ? (
                    <span className="i-value">Approval Pending </span>
                  ) : (
                    <span className="i-value">{data?.rmApproved}</span>
                  )}
                </div>
              )}
              <div className="i-key-value">
                <span className="i-key">Verification Status</span>
                {!data?.verificationAccountApproved ? (
                  "-"
                ) : data?.verificationAccountApproved === "Open" ? (
                  <span className="i-value">Approval Pending </span>
                ) : (
                  <span className="i-value">
                    {data?.verificationAccountApproved}
                  </span>
                )}
              </div>
              <div className="i-key-value">
                <span className="i-key">Payment Status</span>
                {!data?.paymentAccountApproved ? (
                  "-"
                ) : data?.paymentAccountApproved === "Open" ? (
                  <span className="i-value">Approval Pending </span>
                ) : (
                  <span className="i-value">
                    {data?.paymentAccountApproved}
                  </span>
                )}
              </div>
              <div className="i-key-value">
                <span className="i-key">Finance Status</span>
                {!data?.financeAccountApproved ? (
                  "-"
                ) : data?.financeAccountApproved === "Open" ? (
                  <span className="i-value">Approval Pending </span>
                ) : (
                  <span className="i-value">
                    {data?.financeAccountApproved}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Amount</span>
                <span className="i-value">{data?.amount || "-"}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Bill Date</span>
                <span className="i-value">
                  {dateFun(data.billDate, "DD-MM-YYYY") || "-"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Expense Period From</span>
                <span className="i-value">
                  {dateFun(data?.expensePeriodFrom, "DD-MM-YYYY") || "-"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Expense Period To</span>
                <span className="i-value">
                  {dateFun(data?.expensePeriodTo) || "-"}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Vendor</span>
                <span className="i-value">
                  {`${data?.vendor?.code}-${data?.vendor?.name}` || "-"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Remarks</span>
                <span className="i-value">{data?.remarks || "-"}</span>
              </div>
              <div className="i-key-value">
                <span className="i-key">voucher Date</span>
                <span className="i-value">
                  {dateFun(data?.voucherDate, "DD-MM-YYYY") || "-"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Expense Head</span>
                <span className="i-value">{data?.expenseHead || "-"}</span>
              </div>
            </div>

            <div className="form-comments-row">
              {data?.amComments && (
                <div className="i-key-value">
                  <span className="i-key">AM comments</span>
                  <span className="i-value">{data.amComments || "-"}</span>
                </div>
              )}
              {data.rmComments && (
                <div className="i-key-value">
                  <span className="i-key">RM comments</span>
                  <span className="i-value">{data.rmComments || ""}</span>
                </div>
              )}
              {data.verificationAccountComment && (
                <div className="i-key-value">
                  <span className="i-key">Verification Account comments</span>
                  <span className="i-value">
                    {data.verificationAccountComment || ""}
                  </span>
                </div>
              )}
              {data.paymentAccountComment && (
                <div className="i-key-value">
                  <span className="i-key">Payment Account comments</span>
                  <span className="i-value">
                    {data.paymentAccountComment || ""}
                  </span>
                </div>
              )}
              {data.financeAccountComment && (
                <div className="i-key-value">
                  <span className="i-key">Finance Account comments</span>
                  <span className="i-value">
                    {data.financeAccountComment || ""}
                  </span>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ViewVoucher;
