import { tokenMainKeys } from "../../../utils/constants";
import { mainRedirectToIntranetPortal } from "../../common-utilities/utils/common-utils";
import { setOfflineData } from "../../common-utilities/utils/offline-services";
import { userTypes } from "./constants";

export const redirectToIntranetPortal = () => {
  return mainRedirectToIntranetPortal(tokenMainKeys.technet);
};

export const convertUserTypeFromIntranet = (type) => {
  let userType = type?.toLowerCase();
  if (userType?.includes("franchise")) {
    return userTypes.STORE_OWNER;
  }
  return userType?.toUpperCase()?.split(" ")?.join("_");
};

export const setUserToken = ( user) => {
  const type = convertUserTypeFromIntranet(user?.role);
  setOfflineData(tokenMainKeys.technet, "user", { ...user, type });
};
