import { DATE_FORMATS } from '../../../../../utils/constants';
import {
  convertToCamelCaseFromUnderScore,
  exportToExcel,
  formatDate,
} from '../../../../common-utilities/utils';
import { getGRNOrderAmountWithTax } from '../../goods-receive-notes/grn-details/helper';
import {
  getPurchaseOrderAmount,
  getPurchaseOrderWithoutTaxAmount,
} from '../../purchase-order/helper';

const formatData = (data, paymentData) => {
  const {
    invoiceNumber,
    invoiceAmount,
    invoiceType,
    invoiceDate,
    paymentStatus,
    status,
    grnNo,
    invoiceDocumentNumber,
    poId,
  } = data;

  const { dateOfPayment, paymentAmount, tdsAmount, utr } = paymentData;

  return {
    'Po Number': poId?.poNumber || null,
    'Invoice Document Number': invoiceDocumentNumber || null,
    'GRN No': grnNo || null,
    'Invoice Number': invoiceNumber,
    'Invoice Amount': invoiceAmount,
    'Invoice Type': convertToCamelCaseFromUnderScore(invoiceType),
    'Invoice Date': formatDate(invoiceDate, DATE_FORMATS['DD-MM-YYYY']),
    'Payment Status': convertToCamelCaseFromUnderScore(paymentStatus),
    Status: convertToCamelCaseFromUnderScore(status),
    'Date Of Payment': formatDate(dateOfPayment, DATE_FORMATS['DD-MM-YYYY']),
    'Payment Amount': paymentAmount,
    'TDS Amount': tdsAmount,
    UTR: utr,
  };
};

export const downloadInvoiceData = (data, status) => {
  const finalResults = data?.results?.reduce?.(
    ({ dataSheet, paymentDetailsArr }, item) => {
      const { paymentDetails, poId, site, approvalId, createdAt, grnId } = item;
      const poItems = poId?.items || [];
      const advance = poId?.advance;
      const grnItems = grnId?.receivedItems || [];

      if (paymentDetails?.length) {
        paymentDetails.forEach((paymentDetailItem) => {
          paymentDetailsArr.push(formatData(item, paymentDetailItem));
        });
      } else {
        paymentDetailsArr.push(formatData(item, {}));
      }

      if (status === 'advance_invoice') {
        dataSheet.push({
          'PO Number': poId?.poNumber || null,
          'Invoice Document Number': item?.invoiceDocumentNumber || null,
          'Site Code & Name': (site && `${site.code} - ${site.name}`) || '',
          'Vendor code': item?.vendorCode || null,
          'Vendor Name': poId?.vendorName || null,
          'PO Base Amt': getPurchaseOrderWithoutTaxAmount(poItems),
          'Advance %':
            advance?.advancePercentage != undefined
              ? advance?.advancePercentage
              : null,
          'PO Requested Advance Amt':
            advance?.advanceAmount != undefined ? advance.advanceAmount : null,
          'Actual Advance Amount':
            item?.invoiceAmount != undefined ? item?.invoiceAmount : null,
          'Received Advance Amount': item?.sentAmount,
          [status === 'approval_in_progress' ? 'Pending With' : 'Adv Status']:
            status === 'approval_in_progress'
              ? approvalId?.pendingWith || null
              : convertToCamelCaseFromUnderScore(advance?.advanceStatus || ''),
          'Pending with': approvalId?.pendingWith || null,
          'Created By': poId?.creatorName || null,
          'Created on': formatDate(createdAt, DATE_FORMATS['DD-MM-YYYY']),
        });
      } else {
        dataSheet.push({
          'GRN Number': item?.grnNo || null,
          'Invoice Document Number': item?.invoiceDocumentNumber || null,
          'PO Number': poId?.poNumber || null,
          'Site Code': poId?.budget?.siteCode || null,
          'Site Name': poId?.budget?.siteName || null,
          'Vendor Code': poId?.vendorCode || null,
          'Vendor Name': poId?.vendorName || null,
          [status === 'approval_in_progress' ? 'Pending With' : 'GRN Status']:
            status === 'approval_in_progress'
              ? approvalId?.pendingWith || null
              : convertToCamelCaseFromUnderScore(item?.status || ''),
          'Total PO Value': getPurchaseOrderAmount(poItems),
          'Total GRN Value': getGRNOrderAmountWithTax(poItems, grnItems),
          'Invoice Amount':
            data?.invoiceAmount != undefined ? data?.invoiceAmount : null,
          'Sent Amount':
            data?.sentAmount != undefined ? data?.sentAmount : null,
          'Payment Status':
            data?.paymentStatus != undefined
              ? convertToCamelCaseFromUnderScore(data?.paymentStatus)
              : null,
        });
      }
      return {
        dataSheet,
        paymentDetailsArr,
      };
    },
    {
      dataSheet: [],
      paymentDetailsArr: [],
    }
  );
  const { dataSheet, paymentDetailsArr } = finalResults;
  const sheets = [];
  if (dataSheet?.length) {
    sheets.push({
      sheetName: 'data',
      details: dataSheet,
    });
  }
  if (paymentDetailsArr?.length) {
    sheets.push({
      sheetName: 'payment-details',
      details: paymentDetailsArr,
    });
  }

  if (sheets?.length) {
    exportToExcel(sheets, 'reports', true);
  }
};
