import { profitAndLossSelectValues } from "../../constants";
import {
  formatedPercentageRender,
  getConfigFields,
  getSlicedNum,
} from "../../utils";

export const profitlossConfig = (filters, subHeader, mainHeader) => {
  const config = [];

  switch (subHeader) {
    case profitAndLossSelectValues.year1ProfitAndLoss:
      config.push(
        {
          label: "Yr 1 ADT",
          id: "yr1Adt",
          render: (data) => getSlicedNum(data, "yr1Adt"),
        },
        {
          label: "Yr 1 APC",
          id: "yr1Apc",
        },
        {
          label: "Yr1 Del%",
          id: "yr1DelPercentage",
          render: (data) => formatedPercentageRender(data, "yr1DelPercentage"),
          value: (data) =>
            formatedPercentageRender(data, "yr1DelPercentage", true),
          percentageConversion: true,
        },
        {
          label: "Yr1 ADS in Rs",
          id: "yr1AdsInRs",
          render: (data) => getSlicedNum(data, "yr1AdsInRs"),
        },
        {
          label: "Month Sale - Rs",
          id: "monthSaleRs",
          render: (data) => getSlicedNum(data, "monthSaleRs"),
        },
        {
          label: "Yearly 1Revenue",
          id: "yearly1Revenue",
          render: (data) => getSlicedNum(data, "yearly1Revenue"),
        },
        {
          label: "Yearly 1Cost of Sales",
          id: "yearly1CostOfSales",
          render: (data) => getSlicedNum(data, "yearly1CostOfSales"),
        },
        {
          label: "Yearly 1Gross Profit",
          id: "yearly1GrossProfit",
          render: (data) => getSlicedNum(data, "yearly1GrossProfit"),
        },
        {
          label: "Yearly 1Royalty",
          id: "yearly1Royalty",
          render: (data) => getSlicedNum(data, "yearly1Royalty"),
        },
        {
          label: "Yearly 1Occupancy",
          id: "yearly1Occupancy",
          render: (data) => getSlicedNum(data, "yearly1Occupancy"),
        },
        {
          label: "Yearly 1Labour",
          id: "yearly1Labour",
          render: (data) => getSlicedNum(data, "yearly1Labour"),
        },
        {
          label: "Yearly 1Tech (allocation)",
          id: "yearly1TechAllocation",
          render: (data) => getSlicedNum(data, "yearly1Commission"),
        },
        {
          label: "Yearly 1Utilities",
          id: "yearly1Utilities",
        },
        {
          label: "Yearly 1Commission",
          id: "yearly1Commission",
          render: (data) => getSlicedNum(data, "yearly1Commission"),
        },
        {
          label: "Yearly 1Marketing",
          id: "yearly1Marketing",
          render: (data) => getSlicedNum(data, "yearly1Marketing"),
        },
        {
          label: "Yearly 1Other Opex",
          id: "yearly1OtherOpex",
          render: (data) => getSlicedNum(data, "yearly1OtherOpex"),
        },
        {
          label: "Yearly 1Store EBITDA",
          id: "yearly1StoreEbitda",
          render: (data) => getSlicedNum(data, "yearly1StoreEbitda"),
        }
      );
      break;
    case `${profitAndLossSelectValues.profitAndLossAll}-1`:
      config.push(
        {
          label: "Yearly 1Depreciation",
          id: "yearly1Depreciation",
          render: (data) => getSlicedNum(data, "yearly1Depreciation"),
        },
        {
          label: "Yearly 1Store EBIT",
          id: "yearly1StoreEbit",
          render: (data) => getSlicedNum(data, "yearly1StoreEbit"),
        }
      );
      break;
    case profitAndLossSelectValues.rocePreTax:
      config.push({
        label: "ROCE (pre-tax)",
        id: "rocePreTax",
        render: (data) => formatedPercentageRender(data, "rocePreTax"),
        value: (data) => formatedPercentageRender(data, "rocePreTax", true),
        percentageConversion: true,
      });
      break;
    case profitAndLossSelectValues.paybackWithSdYears:
      config.push({
        label: "Payback with SD (Years)",
        id: "paybackWithSdYears",
        render: (data) => getSlicedNum(data, "paybackWithSdYears"),
      });
      break;
    case profitAndLossSelectValues.paybackwithoutSDyears:
      config.push({
        label: "Payback w/o SD (Years)",
        id: "paybackWithoutSdYears",
        render: (data) => getSlicedNum(data, "paybackWithoutSdYears"),
      });
      break;
    case profitAndLossSelectValues.irr5years:
      config.push({
        label: "IRR (5 years)",
        id: "irr5Years",
        render: (data) => formatedPercentageRender(data, "irr5Years"),
        value: (data) => formatedPercentageRender(data, "irr5Years", true),
        percentageConversion: true,
      });
      break;
    case `${profitAndLossSelectValues.profitAndLossAll}-2`:
      config.push(
        {
          label: "Monthly Sales Yr1 M1",
          id: "monthlySalesYr1M1",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M1"),
        },
        {
          label: "Monthly Sales Yr1 M2",
          id: "monthlySalesYr1M2",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M2"),
        },
        {
          label: "Monthly Sales Yr1 M3",
          id: "monthlySalesYr1M3",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M3"),
        },
        {
          label: "Monthly Sales Yr1 M4",
          id: "monthlySalesYr1M4",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M4"),
        },
        {
          label: "Monthly Sales Yr1 M5",
          id: "monthlySalesYr1M5",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M5"),
        },
        {
          label: "Monthly Sales Yr1 M6",
          id: "monthlySalesYr1M6",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M6"),
        },
        {
          label: "Monthly Sales Yr1 M7",
          id: "monthlySalesYr1M7",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M7"),
        },
        {
          label: "Monthly Sales Yr1 M8",
          id: "monthlySalesYr1M8",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M8"),
        },
        {
          label: "Monthly Sales Yr1 M9",
          id: "monthlySalesYr1M9",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M9"),
        },
        {
          label: "Monthly Sales Yr1 M10",
          id: "monthlySalesYr1M10",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M10"),
        },
        {
          label: "Monthly Sales Yr1 M11",
          id: "monthlySalesYr1M11",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M11"),
        },
        {
          label: "Monthly Sales Yr1 M12",
          id: "monthlySalesYr1M12",
          render: (data) => getSlicedNum(data, "monthlySalesYr1M12"),
        },
        {
          label: "Yearly Annual Sales Yr1",
          id: "yearlyAnnualSalesYr1",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr1"),
        },
        {
          label: "Monthly Sales Yr2 M1",
          id: "monthlySalesYr2M1",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M1"),
        },
        {
          label: "Monthly Sales Yr2 M2",
          id: "monthlySalesYr2M2",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M2"),
        },
        {
          label: "Monthly Sales Yr2 M3",
          id: "monthlySalesYr2M3",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M3"),
        },
        {
          label: "Monthly Sales Yr2 M4",
          id: "monthlySalesYr2M4",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M4"),
        },
        {
          label: "Monthly Sales Yr2 M5",
          id: "monthlySalesYr2M5",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M5"),
        },
        {
          label: "Monthly Sales Yr2 M6",
          id: "monthlySalesYr2M6",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M6"),
        },
        {
          label: "Monthly Sales Yr2 M7",
          id: "monthlySalesYr2M7",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M7"),
        },
        {
          label: "Monthly Sales Yr2 M8",
          id: "monthlySalesYr2M8",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M8"),
        },
        {
          label: "Monthly Sales Yr2 M9",
          id: "monthlySalesYr2M9",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M9"),
        },
        {
          label: "Monthly Sales Yr2 M10",
          id: "monthlySalesYr2M10",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M10"),
        },
        {
          label: "Monthly Sales Yr2 M11",
          id: "monthlySalesYr2M11",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M11"),
        },
        {
          label: "Monthly Sales Yr2 M12",
          id: "monthlySalesYr2M12",
          render: (data) => getSlicedNum(data, "monthlySalesYr2M12"),
        },
        {
          label: "Yearly Annual Sales Yr2",
          id: "yearlyAnnualSalesYr2",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr2"),
        },
        {
          label: "Yearly Annual Sales Yr3",
          id: "yearlyAnnualSalesYr3",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr3"),
        },
        {
          label: "Yearly Annual Sales Yr4",
          id: "yearlyAnnualSalesYr4",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr4"),
        },
        {
          label: "Yearly Annual Sales Yr5",
          id: "yearlyAnnualSalesYr5",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr5"),
        },
        {
          label: "Yearly Annual Sales Yr6",
          id: "yearlyAnnualSalesYr6",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr6"),
        },
        {
          label: "Yearly Annual Sales Yr7",
          id: "yearlyAnnualSalesYr7",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr7"),
        },
        {
          label: "Yearly Annual Sales Yr8",
          id: "yearlyAnnualSalesYr8",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr8"),
        },
        {
          label: "Yearly Annual Sales Yr9",
          id: "yearlyAnnualSalesYr9",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr9"),
        },
        {
          label: "Yearly Annual Sales Yr10",
          id: "yearlyAnnualSalesYr10",
          render: (data) => getSlicedNum(data, "yearlyAnnualSalesYr10"),
        },
        {
          label: "Monthly EBITDA Yr1 M1",
          id: "monthlyEbitdaYr1M1",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M1"),
        },
        {
          label: "Monthly EBITDA Yr1 M2",
          id: "monthlyEbitdaYr1M2",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M2"),
        },
        {
          label: "Monthly EBITDA Yr1 M3",
          id: "monthlyEbitdaYr1M3",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M3"),
        },
        {
          label: "Monthly EBITDA Yr1 M4",
          id: "monthlyEbitdaYr1M4",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M4"),
        },
        {
          label: "Monthly EBITDA Yr1 M5",
          id: "monthlyEbitdaYr1M5",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M5"),
        },
        {
          label: "Monthly EBITDA Yr1 M6",
          id: "monthlyEbitdaYr1M6",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M6"),
        },
        {
          label: "Monthly EBITDA Yr1 M7",
          id: "monthlyEbitdaYr1M7",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M7"),
        },
        {
          label: "Monthly EBITDA Yr1 M8",
          id: "monthlyEbitdaYr1M8",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M8"),
        },
        {
          label: "Monthly EBITDA Yr1 M9",
          id: "monthlyEbitdaYr1M9",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M9"),
        },
        {
          label: "Monthly EBITDA Yr1 M10",
          id: "monthlyEbitdaYr1M10",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M10"),
        },
        {
          label: "Monthly EBITDA Yr1 M11",
          id: "monthlyEbitdaYr1M11",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M11"),
        },
        {
          label: "Monthly EBITDA Yr1 M12",
          id: "monthlyEbitdaYr1M12",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr1M12"),
        },
        {
          label: "Yearly EBITDA Yr1",
          id: "yearlyEbitdaYr1",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr1"),
        },
        {
          label: "Monthly EBITDA Yr2 M1",
          id: "monthlyEbitdaYr2M1",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M1"),
        },
        {
          label: "Monthly EBITDA Yr2 M2",
          id: "monthlyEbitdaYr2M2",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M2"),
        },
        {
          label: "Monthly EBITDA Yr2 M3",
          id: "monthlyEbitdaYr2M3",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M3"),
        },
        {
          label: "Monthly EBITDA Yr2 M4",
          id: "monthlyEbitdaYr2M4",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M4"),
        },
        {
          label: "Monthly EBITDA Yr2 M5",
          id: "monthlyEbitdaYr2M5",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M5"),
        },
        {
          label: "Monthly EBITDA Yr2 M6",
          id: "monthlyEbitdaYr2M6",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M6"),
        },
        {
          label: "Monthly EBITDA Yr2 M7",
          id: "monthlyEbitdaYr2M7",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M7"),
        },
        {
          label: "Monthly EBITDA Yr2 M8",
          id: "monthlyEbitdaYr2M8",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M8"),
        },
        {
          label: "Monthly EBITDA Yr2 M9",
          id: "monthlyEbitdaYr2M9",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M9"),
        },
        {
          label: "Monthly EBITDA Yr2 M10",
          id: "monthlyEbitdaYr2M10",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M10"),
        },
        {
          label: "Monthly EBITDA Yr2 M11",
          id: "monthlyEbitdaYr2M11",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M11"),
        },
        {
          label: "Monthly EBITDA Yr2 M12",
          id: "monthlyEbitdaYr2M12",
          render: (data) => getSlicedNum(data, "monthlyEbitdaYr2M12"),
        },
        {
          label: "Yearly EBITDA Yr2",
          id: "yearlyEbitdaYr2",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr2"),
        },
        {
          label: "Yearly EBITDA Yr3",
          id: "yearlyEbitdaYr3",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr3"),
        },
        {
          label: "Yearly EBITDA Yr4",
          id: "yearlyEbitdaYr4",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr4"),
        },
        {
          label: "Yearly EBITDA Yr5",
          id: "yearlyEbitdaYr5",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr5"),
        },
        {
          label: "Yearly EBITDA Yr6",
          id: "yearlyEbitdaYr6",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr6"),
        },
        {
          label: "Yearly EBITDA Yr7",
          id: "yearlyEbitdaYr7",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr7"),
        },
        {
          label: "Yearly EBITDA Yr8",
          id: "yearlyEbitdaYr8",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr8"),
        },
        {
          label: "Yearly EBITDA Yr9",
          id: "yearlyEbitdaYr9",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr9"),
        },
        {
          label: "Yearly EBITDA Yr10",
          id: "yearlyEbitdaYr10",
          render: (data) => getSlicedNum(data, "yearlyEbitdaYr10"),
        }
      );
      break;
    default:
      break;
  }
  return getConfigFields(config, filters, "profitLoss", mainHeader);
};
