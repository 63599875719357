import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { DATE_FORMATS } from "./constants";
import moment from "moment";
export const appendZeroToTime = (number) => {
  if (number >= 0 && number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDate = (isoDateString, type) => {
  if (!isoDateString) {
    return "-";
  }
  try {
    const dateObj = new Date(isoDateString);
    if (Number.isNaN(dateObj.getTime())) {
      return "-";
    }
    switch (type) {
      case "dateString":
        return isoDateString === "" ? "-" : dateObj.toLocaleDateString();
      case "timeString":
        return dateObj.toLocaleTimeString();
      case DATE_FORMATS["YYYY-MM-DD"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [date.getFullYear(), monthDisplay, dayDisplay].join("-");
      }
      case DATE_FORMATS["DD-MM-YYYY"]: {
        const date = new Date(dateObj);
        const month = `${appendZeroToTime(date.getMonth() + 1)}`;
        const monthDisplay = `${month.slice(-2)}`;
        const day = `${appendZeroToTime(date.getDate())}`;
        const dayDisplay = `${day.slice(-2)}`;
        return [dayDisplay, monthDisplay, date.getFullYear()].join("-");
      }
      default:
        return `${dateObj.toLocaleDateString()} 
                  ${dateObj.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`;
    }
  } catch (err) {
    return "Invalid Date";
  }
};

export const convertCamelCaseToDisplayText = (txt) => {
  let strTmp = txt.replace(/([a-z])([A-Z])/g, "$1 $2");
  if (strTmp && strTmp.length > 0) {
    strTmp = `${strTmp[0].toUpperCase()}${strTmp.slice(1)}`;
  }
  return strTmp;
};

export const convertToCamelCaseFromUnderScore = (str) => {
  return convertCamelCaseToDisplayText(
    (str || "").toLowerCase().replace(/_+([a-z])/g, (m, w) => w.toUpperCase())
  );
};

export const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== "string") {
    return string;
  }

  return string[0].toUpperCase() + string.slice(1);
};

export const removeUnderScore = (value) => {
  if (!value || !value.includes("_")) {
    return value;
  }
  return value.split("_").join(" ");
};

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export const exportToExcel = (
  data,
  name,
  mutliple,
  removeDefaultFileNameStart
) => {
  const wb = {
    Sheets: {},
    SheetNames: [],
  };

  if (!mutliple) {
    const ws = XLSX.utils.json_to_sheet(data);
    wb.Sheets.data = ws;
    wb.SheetNames.push("data");
  } else {
    data.forEach((item) => {
      const { sheetName, details } = item;
      const ws = XLSX.utils.json_to_sheet(details);
      wb.Sheets[sheetName] = ws;
      wb.SheetNames.push(sheetName);
    });
  }

  const eb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(
    blob,
    `${removeDefaultFileNameStart ? "" : "file_"}` + name + EXCEL_EXTENSION
  );
};

export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};

export const dateFun = (val) => {
  if (val) {
    if (new Date(val) == "Invalid Date") {
      let split = val?.split("-");
      let d =
        split.length > 0 ? split[2] + "/" + split[1] + "/" + split[0] : null;
      if (d) {
        return moment(d).format("yyyy-MM-DD");
      } else {
        return "";
      }
    } else {
      return moment(val).format("yyyy-MM-DD");
    }
  }
};

export const getLabelsAndValuesForSelect = (
  labels,
  values,
  valueKey = "value"
) => {
  return labels?.map?.((item, index) => {
    if (typeof item === "object") {
      const { label, value } = item;
      return { label, [valueKey]: value };
    }
    const finalLabel = item.split("_").join(" ");
    return { label: finalLabel, [valueKey]: values[index] };
  }) || [];
};

export const getSortedStagGates = (stagesGates) => {
  stagesGates?.sort?.(function (a, b) {
    const aStageNo = a?.stage?.stageNumber;
    const bStageNo = b?.stage?.stageNumber;
    if (!aStageNo || !bStageNo) {
      return 0;
    }
    if (aStageNo < bStageNo) {
      return -1;
    } else if (aStageNo > bStageNo) {
      return 1;
    }
    return 0;
  });

  return [...stagesGates];
};
