import {
  getLabelsAndValuesForSelect,
  USER_ROLES,
  belongsToInUserRoles,
} from ".";
import { tokenMainKeys } from "../../../utils/constants";
import { mainRedirectToIntranetPortal } from "../../common-utilities/utils/common-utils";
import { setOfflineData } from "../../common-utilities/utils/offline-services";
import { HOSTNAME, INTRANETHOSTNAME, REST_URLS } from "./endpoints";
import { HTTP_METHODS, invokeApi } from "./http-service";

export const redirectToIntranetPortal = () => {
  return mainRedirectToIntranetPortal(tokenMainKeys.qnet);
};

export const setComplaintNumberList = (list, setDataFunction) => {
  const duplicationIdChecker = {};
  const complainIdsList = [];
  list.forEach((caseId) => {
    const caseIdIsNotPresent = !duplicationIdChecker[caseId];
    if (caseIdIsNotPresent) {
      duplicationIdChecker[caseId] = true;
      complainIdsList.push(caseId);
    }
  });

  const complaintIdSelectList = getLabelsAndValuesForSelect(
    complainIdsList,
    complainIdsList
  );

  if (setDataFunction) {
    setDataFunction(complaintIdSelectList);
  }
  return complainIdsList;
};

export const convertIntranetRole = (role) => {
  const upperCaseRole = role?.toUpperCase?.() || "";

  if (upperCaseRole.includes("ACTION")) {
    return {
      type: USER_ROLES.ACTION_TAKER,
      belongsTo: belongsToInUserRoles.dc,
    };
  }

  if (upperCaseRole.includes("CREDIT")) {
    return {
      type: USER_ROLES.CREDIT_ISSUER,
      belongsTo: belongsToInUserRoles.dc,
    };
  }

  if (upperCaseRole.includes("FRANCHISE")) {
    return {
      type: USER_ROLES.STORE,
      belongsTo: belongsToInUserRoles.subway,
    };
  }

  if (upperCaseRole.includes("STORE")) {
    return {
      type: USER_ROLES.STORE_OWNER,
      belongsTo: belongsToInUserRoles.subway,
    };
  }

  if (upperCaseRole.includes("SUPPLIER")) {
    return {
      type: USER_ROLES.QA,
      belongsTo: belongsToInUserRoles.vendor,
    };
  }

  if (upperCaseRole.includes("DISTRIBUTOR")) {
    return {
      type: USER_ROLES.QA,
      belongsTo: belongsToInUserRoles.dc,
    };
  }

  if (upperCaseRole.includes("ADMIN")) {
    return {
      type: USER_ROLES.ADMIN,
      belongsTo: belongsToInUserRoles.subway,
    };
  }

  if (upperCaseRole.includes("R&D")) {
    return {
      type: USER_ROLES.RD,
      belongsTo: belongsToInUserRoles.subway,
    };
  }
  if (upperCaseRole === "AM") {
    return {
      type: USER_ROLES.AM,
      belongsTo: belongsToInUserRoles.subway,
    };
  }
  return;
};

export const setUserToken = async(user) => {
  const role = convertIntranetRole(user?.role);
  const qnetProperties = user?.properties?.qnet || {}
  if (!qnetProperties?.distributors?.length) {
    delete qnetProperties?.distributors
  }
  delete user?.properties
  setOfflineData(tokenMainKeys.qnet, "user", { ...user, role, ...qnetProperties });
  const vendor = qnetProperties?.vendor
  try {
    if (vendor) {
      const vendorData = await invokeApi(HTTP_METHODS.GET, `${INTRANETHOSTNAME}${REST_URLS.VENDOR_BY_ID}${vendor}`)
      if (!!vendorData?.isVegetableVendor) {
        qnetProperties.isVegetableVendor = true
      }
    }
    setOfflineData(tokenMainKeys.qnet, "user", { ...user, role, ...qnetProperties });
  } catch(error) {
    setOfflineData(tokenMainKeys.qnet, "user", { ...user, role, ...qnetProperties });
  }
  
};
