import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import "./index.scss";
import {
  clearOfflineData,
  clearTokenDataAndRedirectToLogin,
  getOfflineData,
} from "../../utils/offline-services";
import SideMenu from "../side-menu";
import bellLogo from "../../resources/icons/Icon_Dashboard.png";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import TimeAgo from "react-timeago";
import moment from "moment";
import Intranetlogout from "./logout";

const IntranetHeader = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const [nameAnchorEl, setNameAnchorEl] = useState(null);
  const [notificationBadgeCount, setNotificationBadgeCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [anchorNoti, setAnchorNoti] = useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(null);
  const observer = React.useRef();
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = React.useState(1);
  const [pageArray, setPageArray] = React.useState([]);
  const [unseen, setUnseen] = React.useState([]);
  const openNotification = Boolean(anchorNoti);
  const user = getOfflineData("user");

  const handleNameClose = () => {
    setNameAnchorEl(null);
  };

  useEffect(() => {
    invokeApi("GET", `${INTRANETHOSTNAME}${REST_URLS.NOTIFICATIONS_UNSEEN}`)
      .then((response) => {
        if (response) {
          setNotificationBadgeCount(response.counts);
        }
      })
      .catch((err) => {
        console.log("Fetching notification failed");
      });
  }, []);

  const notificationTypeFun = (val) => {
    switch (val) {
      default:
        return "#0c53f36b";
    }
  };

  const getNotification = (pg) => {
    const params = {
      page: pg,
      eventType: JSON.stringify([
        "MEMO_CREATED",
        "USER_CREATED",
        "INTRANET_ROLE",
        "PASSWORD_CHANGED",
        "APP_CREATED",
        "ROLE_CREATED",
        "APP_ACCESS",
      ]),
    };
    if (!pageArray.includes(pg)) {
      plainApi(
        "GET",
        `${INTRANETHOSTNAME}${REST_URLS.NOTIFICATIONS}`,
        null,
        params
      )
        .then((response) => {
          if (response) {
            setTotalPages(response.totalPages + 1);
            let array = [...pageArray];
            let unseenTemp = [...unseen];
            array.push(pg);
            setPageArray(array);
            response.results.forEach((iter) => {
              if (iter.seen === false) {
                unseenTemp.push(iter.id);
              }
            });
            setUnseen(unseenTemp);
            if (pages == 1) {
              setNotifications(response.results);
            } else {
              setNotifications([...notifications, ...response.results]);
            }
          } else {
            console.log("Fetching notification failed");
          }
        })
        .catch((err) => {
          console.log("Fetching notification failed");
        });
    }
  };

  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  useEffect(() => {
    if (openNotification) {
      if (totalPages == null) {
        getNotification(pages);
      } else {
        if (pages < totalPages) {
          setHasMore(true);
          getNotification(pages);
        } else {
          setHasMore(false);
        }
      }
    } else {
      if (unseen.length > 0) {
        notificationSeenCall();
      }
    }
  }, [refresh, openNotification]);

  const notificationSeenCall = () => {
    let markSeen = unseen.length;
    if (markSeen > 0 && notificationBadgeCount != 0) {
      plainApi("POST", `${INTRANETHOSTNAME}${REST_URLS.NOTIFICATIONS_SEEN}`, {
        ids: unseen,
      })
        .then((response) => {
          setNotificationBadgeCount(notificationBadgeCount - markSeen);
          setUnseen([]);
        })
        .catch((err) => {
          console.log("Notification Mark Seen failed", err);
        });
    }
  };

  const handleClickNoti = (event) => {
    setAnchorNoti(event.currentTarget);
  };

  const handleCloseNoti = () => {
    setAnchorNoti(null);
  };

  const logout = () => {
    const refreshToken = getOfflineData("tokens")?.refresh?.token || "";
    const accessToken = getOfflineData("tokens")?.access?.token || "";
    invokeApi(HTTP_METHODS.POST, `${INTRANETHOSTNAME}${REST_URLS.LOGOUT}`, {
      refreshToken,
      accessToken,
    })
      .then(() => {
        clearTokenDataAndRedirectToLogin(navigate);
      })
      .catch((err) => {
        clearTokenDataAndRedirectToLogin(navigate);
      });
  };
  return (
    <div className="header-container">
      <div className="logo-con">{<SideMenu />}</div>
      <div>
        <div className="menu">
          <Badge
            onClick={handleClickNoti}
            sx={{
              "& .MuiBadge-badge": {
                top: "-8px",
                color: "white",
                backgroundColor: "#2d9cdb ",
              },
            }}
            badgeContent={
              notificationBadgeCount >= 1 ? notificationBadgeCount : "0"
            }
          >
            <div className="bell-icon-con">
              <img src={bellLogo} className="bell-icon " />
            </div>
          </Badge>

          <Menu
            id="basic-menu"
            anchorEl={anchorNoti}
            open={openNotification}
            onClose={handleCloseNoti}
            style={{ left: "-3%", height: "50%" }}
          >
            <div
            // style={{
            //   height: "70vh",
            //   overflow: "auto",
            // }}
            >
              {notifications.length > 0 ? (
                notifications.map((iter, index) => {
                  if (index + 1 === notifications.length && hasMore) {
                    return (
                      <div
                        ref={lastItemRef}
                        style={{
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        Loading...
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          borderRadius: "10px",
                          backgroundColor: "#cbcbd530",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: notificationTypeFun(
                              iter.eventType
                            ),
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            margin: "0px 10px 0px 0px",
                            // height: "38px",
                          }}
                        >
                          <NotificationsIcon fontSize="medium" color="light" />
                        </div>
                        <div>
                          <div
                            style={{
                              overflow: "hidden",
                              fontSize: "12px",
                            }}
                          >
                            {iter?.details}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              top: "5px",
                              fontSize: "9px",
                              color: "gray",
                            }}
                          >
                            <TimeAgo
                              date={moment(iter.updatedAt).local().valueOf()}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    padding: "50px",
                  }}
                >
                  No Messages Found
                </div>
              )}
            </div>
          </Menu>

          {/* <Avatar className="si-hide-mobile">
          <AccountCircleIcon />
        </Avatar>
        <Button
          type="small"
          variant="text"
          onClick={(event) => setNameAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {user?.name}
        </Button>
        <Menu
          anchorEl={nameAnchorEl}
          open={!!nameAnchorEl}
          onClose={handleNameClose}
          onClick={handleNameClose}
        >
          <MenuItem onClick={() => navigate("/my-account")}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Account
          </MenuItem>
          <MenuItem onClick={() => Intranetlogout(navigate)}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu> */}
        </div>
        <p className="user-name">{user?.name}</p>
      </div>
    </div>
  );
};

export default IntranetHeader;
