export const COUNTRY = [
  { value: "India", label: "India" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Bangladesh", label: "Bangladesh" },
];
export const TYPES = [
  { value: "Office Staff", label: "Office Staff" },
  { value: "Others", label: "Others" },
];
export const OFFICE_STAFF_JOB_TITLES = [
  { value: "Operations", label: "Operations" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Accounting", label: "Accounting" },
  { value: "Technology", label: "Technology" },
  { value: "Executive", label: "Executive" },
  { value: "Training", label: "Training" },
  { value: "Research & Development", label: "Research & Development" },
  { value: "Administration", label: "Administration" },
  { value: "Operations", label: "Operations" },
  { value: "Marketing", label: "Marketing" },
  { value: "Field Consultant", label: "Field Consultant" },
];
export const OTHERS_JOB_TITLES = [
  { value: "Employee", label: "Employee" },
  { value: "Franchise", label: "Franchise" },
];
export const DEPOSITIONS = [
  { value: "AR", label: "AR" },
  { value: "Remodel", label: "Remodel" },
  { value: "Relocation", label: "Relocation" },
  { value: "SAP", label: "SAP" },
  { value: "Restaurant Status", label: "Restaurant Status" },
  { value: "Lease", label: "Lease" },
  { value: "Operating Assignment", label: "Operating Assignment" },
  { value: "Restaurant Transfer", label: "Restaurant Transfer" },
  { value: "REV", label: "REV" },
  { value: "Others", label: "Others" },
];
export const LISTING_TYPE = [
  {
    value: "address",
    label: "Address",
  },
  {
    value: "communication",
    label: "Communication",
  },
];
export const STATUS = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];
