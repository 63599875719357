import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import {
  downloadFile,
  getFileName,
  getRedirectPointBySlug,
} from "../../utils/common-utils";
import CustomModal from "../../../common-utilities/core/modal";
import DocumentView from "../../core/document-viewer";
import { getOfflineData } from "../../utils/offline-services";
import { FINANCE_USER_ID, INVOICE_TRACKING_USERS } from "../../utils/constants";
import { convertToCamelCaseFromUnderScore } from "../../../bd-tool/utils";
import { toast } from "react-toastify";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";
import { VendorInvoiceDetails } from "../common-components/vendor-invoice.details";
import { getKeysBySlug } from "../../utils/keys";

const InvoiceDetails = ({
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
}) => {
  const keys = getKeysBySlug(slug);
  const { id } = useParams();
  const userDetails = getOfflineData("user", token) || {};
  const { role } = userDetails;

  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});
  const [documentUrl, setDocumentUrl] = useState(null);
  const [approvedModalType, setApprovedModalType] = useState("");
  const [rmComments, setRMComments] = useState("");

  const { assignedTo = "" } = formDetails;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      slug !== appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.INVOICE}/${id}`
        : `${HOSTNAME}${REST_URLS.VENDOR_INVOICE}/${id}`,
      null,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        setFormDetails(response);
      })
      .catch((err) => {});
  };

  const approveRequest = (isApproved) => {
    let payload = {
      isApproved: isApproved,
      rmComments: rmComments,
    };

    if (isApproved) {
      payload.financeUser = FINANCE_USER_ID;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.APPROVE_INVOICE}${id}`,
      payload,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        if (response.message) {
          toast.error("Approved / Reject request failed");
        } else {
          setRMComments("");
          setApprovedModalType("");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const amApproveRequest = (isApproved) => {
    let payload = {
      amApproved: isApproved,
      amComments: rmComments,
    };

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.AM_APPROVE_INVOICE}${id}`,
      payload,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        if (response.message) {
          toast.error("Approved / Reject request failed");
        } else {
          setRMComments("");
          setApprovedModalType("");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const financeRequest = (financeUserApproved) => {
    let payload = {
      financeUserApproved: financeUserApproved,
      financeUserComments: rmComments,
    };
    if (financeUserApproved === "cancelled") {
      delete payload.financeUserApproved;
      payload.cancelled = true;
    }

    invokeApi(
      HTTP_METHODS.POST,
      slug === appSlugs.vendorInvoiceSubmission
        ? `${HOSTNAME}${REST_URLS.VENDOR_FINANCE_INVOICE}${id}`
        : `${HOSTNAME}${REST_URLS.FINANCE_INVOICE}${id}`,
      payload,
      null,
      null,
      slug,
      token
    )
      .then((response) => {
        if (response.message) {
          toast.error("Approved / Reject request failed");
        } else {
          setRMComments("");
          setApprovedModalType("");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const { vendor = {} } = formDetails;

  const onApproveRejectAction = (action) => {
    if (approvedModalType === INVOICE_TRACKING_USERS.AM) {
      amApproveRequest(action ? "Approved" : "Rejected");
    } else if (approvedModalType === INVOICE_TRACKING_USERS.RM) {
      approveRequest(action);
    } else if (approvedModalType === INVOICE_TRACKING_USERS.FINANCE_USER) {
      financeRequest(
        action === "cancelled" ? "cancelled" : action ? "Approved" : "Rejected"
      );
    }
    setApprovedModalType("");
  };

  return (
    <div className="invoice-details-container">
      <div className="header-section">
        <div>
          <IconButton
            variant="contained"
            onClick={() => navigate(getRedirectPointBySlug(slug))}
          >
            <ArrowBack />
          </IconButton>
          <span className="title">Invoice Details : {id} </span>
        </div>
        <div className="right-section">
          {role === INVOICE_TRACKING_USERS.RM &&
            assignedTo === INVOICE_TRACKING_USERS.RM && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => setApprovedModalType(INVOICE_TRACKING_USERS.RM)}
              >
                Approve / Reject Invoice
              </Button>
            )}
          {role === INVOICE_TRACKING_USERS.AM &&
            assignedTo === INVOICE_TRACKING_USERS.AM && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => setApprovedModalType(INVOICE_TRACKING_USERS.AM)}
              >
                Approve / Reject Invoice
              </Button>
            )}
          {role === INVOICE_TRACKING_USERS.FINANCE_USER &&
            assignedTo === "Finance" &&
            (slug === appSlugs.vendorInvoiceSubmission
              ? !formDetails?.cancelled
              : true) && (
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  setApprovedModalType(INVOICE_TRACKING_USERS.FINANCE_USER)
                }
              >
                Approve /{" "}
                Reject Invoice
              </Button>
            )}
          {assignedTo && (
            <Chip
              label={`Current Status : ${
                formDetails?.cancelled
                  ? "Cancelled"
                  : `Assigned to ${assignedTo}`
              }`}
              component="span"
              variant="outlined"
            />
          )}
        </div>
      </div>
      <div className="invoice-details">
        <Card>
          <CardContent>
            <div className="form-row">
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <div className="i-key-value">
                  <span className="i-key">Vendor Code</span>
                  <span className="i-value">
                    {vendor.code
                      ? `${vendor.code} - ${vendor.name}`
                      : formDetails.vendorName}
                  </span>
                </div>
              )}

              {formDetails.amApprovalRequired && (
                <div className="i-key-value">
                  <span className="i-key">AM Approve Status</span>
                  {assignedTo === "AM" ? (
                    <span className="i-value">Review Pending </span>
                  ) : (
                    <span className="i-value">{formDetails.amApproved}</span>
                  )}
                </div>
              )}
              {formDetails.rmApprovalRequired && (
                <div className="i-key-value">
                  <span className="i-key">RM Approve Status</span>
                  {formDetails.amApproved === "Open" ? (
                    <span className="i-value">Review Pending </span>
                  ) : (
                    <span>
                      {assignedTo === "RM" ? (
                        <span className="i-value">Review Pending </span>
                      ) : (
                        <span className="i-value">
                          {formDetails.rmApproved === null
                            ? "Review Pending"
                            : formDetails.rmApproved
                            ? "Approved"
                            : "Rejected"}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              )}
              <div className="i-key-value">
                <span className="i-key">Finance Status</span>
                {assignedTo === "RM" ? (
                  <span className="i-value">Waiting for RM approve</span>
                ) : (
                  <span className="i-value">
                    {formDetails.financeUserApproved}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Store</span>
                <span className="i-value">{formDetails.store || ""}</span>
              </div>
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <div className="i-key-value">
                  <span className="i-key">GRN Number</span>
                  <span className="i-value">{formDetails.grnNumber || ""}</span>
                </div>
              )}
              {slug === appSlugs.vendorInvoiceSubmission && (
                <>
                  <div className="i-key-value">
                    <span className="i-key">Vendor Name</span>
                    <span className="i-value">
                      {formDetails?.vendor?.name || ""}
                    </span>
                  </div>
                  <div className="i-key-value">
                    <span className="i-key">Vendor Code</span>
                    <span className="i-value">
                      {formDetails?.vendor?.code || ""}
                    </span>
                  </div>
                </>
              )}

              <div className="i-key-value">
                <span className="i-key">PO Number</span>
                <span className="i-value">{formDetails.poNumber || ""}</span>
              </div>
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <div className="i-key-value">
                  <span className="i-key">Remarks</span>
                  <span className="i-value">{formDetails.remarks || ""}</span>
                </div>
              )}
            </div>
            <div className="form-row">
              {slug !== appSlugs.vendorInvoiceSubmission && (
                <div className="i-key-value">
                  <span className="i-key">Invoice Type</span>
                  <span className="i-value">
                    {convertToCamelCaseFromUnderScore(
                      formDetails.invoiceType || ""
                    )}
                  </span>
                </div>
              )}

              <div className="i-key-value">
                <span className="i-key">Invoice Date</span>
                <span className="i-value">
                  {(formDetails.invoiceDate &&
                    new Date(formDetails.invoiceDate).toLocaleDateString()) ||
                    ""}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Invoice Number</span>
                <span className="i-value">
                  {formDetails.invoiceNumber || ""}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">Created On</span>
                <span className="i-value">
                  {(formDetails.createdAt &&
                    new Date(formDetails.createdAt).toLocaleDateString()) ||
                    ""}
                </span>
              </div>
              {slug === appSlugs.vendorInvoiceSubmission && (
                <VendorInvoiceDetails formDetails={formDetails} />
              )}
            </div>

            <div className="form-comments-row">
              {formDetails.amComments && (
                <div className="i-key-value">
                  <span className="i-key">AM comments</span>
                  <span className="i-value">
                    {formDetails.amComments || ""}
                  </span>
                </div>
              )}
              {formDetails.rmComments && (
                <div className="i-key-value">
                  <span className="i-key">RM comments</span>
                  <span className="i-value">
                    {formDetails.rmComments || ""}
                  </span>
                </div>
              )}
              {formDetails.financeUserComments && (
                <div className="i-key-value">
                  <span className="i-key">Finance comments</span>
                  <span className="i-value">
                    {formDetails.financeUserComments || ""}
                  </span>
                </div>
              )}
            </div>

            {slug === appSlugs.vendorInvoiceSubmission && (
              <div className="form-row">
                <div className="i-key-value">
                  <span className="i-key">Remarks</span>
                  <span className="i-value">{formDetails.remarks || ""}</span>
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="i-key-value">
                <span className="i-key">Invoice Attachment</span>
                <span className="i-value">
                  {(formDetails.invoiceAttachment && (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() =>
                        downloadFile(formDetails.invoiceAttachment)
                      }
                    >
                      {getFileName(formDetails.invoiceAttachment)}
                    </Button>
                  )) ||
                    "No Attachment"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">PO Attachment</span>
                <span className="i-value">
                  {(formDetails.poAttachment && (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => downloadFile(formDetails.poAttachment)}
                    >
                      {getFileName(formDetails.poAttachment)}
                    </Button>
                  )) ||
                    "No Attachment"}
                </span>
              </div>
              <div className="i-key-value">
                <span className="i-key">{keys.grnAttachment}</span>
                <span className="i-value">
                  {(formDetails.grnAttachment && (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => downloadFile(formDetails.grnAttachment)}
                    >
                      {getFileName(formDetails.grnAttachment)}
                    </Button>
                  )) ||
                    "No Attachment"}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>

        {documentUrl && (
          <CustomModal
            open={!!documentUrl}
            title="Document"
            contentClassName="invoice-tracking-document-modal"
            onClose={() => setDocumentUrl(null)}
          >
            <DocumentView url={documentUrl} />
          </CustomModal>
        )}

        {approvedModalType && (
          <CustomModal
            open={!!approvedModalType}
            title={`Approve / Reject Invoice`}
            contentClassName={{ customClass: "approve-invoice-modal" }}
            onClose={() => {
              setApprovedModalType("");
            }}
          >
            <div>
              <TextareaAutosize
                placeholder="Approver comments"
                minRows={6}
                value={rmComments}
                style={{ width: "100%" }}
                onChange={(e) => setRMComments(e.target.value)}
              />

              <div className="footer-buttons footer-approver-buttons">
                <div>
                  <Button
                    color="error"
                    variant="outlined"
                    fullWidth
                    disabled={!rmComments}
                    onClick={() => {
                      onApproveRejectAction(false);
                    }}
                  >
                    Reject
                  </Button>
                </div>

                <div>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      onApproveRejectAction(true);
                    }}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetails;
