import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { IconButton, Divider } from "@mui/material";
import Select from "react-select";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
const DrawingHistory = () => {
  const displayContainer = {
    display: "flex",
    gap: 20,
    alignItems: "center",
  };

  const [data, setData] = useState([
    {
      store: 70116,
      satellite: 0,
      leaseId: 257885,
      drawingType: "New Location",
      developer: 550,
      developerName: "SSIPL/INDIA",
      requestedDate: "17-Apr-2019",
      comment: "",
      userStamp: "attebery_su",
      date: "03-May-2019 01:05:14 P.M.",
      change: "Insert",
    },
  ]);
  return (
    <div className="storeDrawingDetails">
      <div className="title">Drawing Selection</div>
      <div className="mb-1" style={displayContainer}>
        <TextField label="Store" />
        <Select placeholder="Satellite" classNamePrefix="custom_select" />

        <IconButton>
          <InfoIcon color="primary" size="large" fontSize="60" />
        </IconButton>
        <IconButton>
          <ArrowCircleRightIcon color="primary" size="large" fontSize="60" />
        </IconButton>
        <Select placeholder="Lease" classNamePrefix="custom_select" />
        <IconButton>
          <ArrowCircleRightIcon color="primary" size="large" fontSize="60" />
        </IconButton>
        <Select placeholder="Drawing" classNamePrefix="custom_select" />
        <IconButton>
          <ImagesearchRollerIcon
            color="primary"
            size="large"
            fontSize="60"
            className="rotate-roller"
          />
        </IconButton>
        <IconButton>
          <ArrowCircleRightIcon color="primary" size="large" fontSize="60" />
        </IconButton>
      </div>
      <div>
        <Grid container>
          <Select placeholder="History Type" classNamePrefix="custom_select" />
        </Grid>
      </div>
      <Divider style={{ paddingBottom: 10 }} />

      <div style={{ height: "1rem" }}></div>

      <Grid container spacing={1}>
        <Grid xs={12}>
          <div>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <div>Drag a column header here to group by that column</div>
                <div>1 record(s)</div>
                <div className="table-con">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead
                        sx={{ backgroundColor: "#008a52", color: "white" }}
                      >
                        <TableRow>
                          <TableCell className="table-cell">Store</TableCell>
                          <TableCell className="table-cell">
                            Satellite
                          </TableCell>
                          <TableCell className="table-cell">Lease ID</TableCell>

                          <TableCell className="table-cell">
                            Drawing Type
                          </TableCell>
                          <TableCell className="table-cell">
                            Developer#
                          </TableCell>
                          <TableCell className="table-cell">
                            Developer Name
                          </TableCell>
                          <TableCell className="table-cell">
                            Request Date
                          </TableCell>
                          <TableCell className="table-cell">Comment</TableCell>
                          <TableCell className="table-cell">
                            User Stamp
                          </TableCell>
                          <TableCell className="table-cell">
                            Date/Time
                          </TableCell>
                          <TableCell className="table-cell">Change</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((ele, index) => (
                          <TableRow key={index}>
                            <TableCell>{ele.store}</TableCell>
                            <TableCell>{ele.satellite}</TableCell>
                            <TableCell>{ele.leaseId}</TableCell>
                            <TableCell>{ele.drawingType}</TableCell>
                            <TableCell>{ele.developer}</TableCell>
                            <TableCell>{ele.developerName}</TableCell>
                            <TableCell>{ele.requestedDate}</TableCell>
                            <TableCell>{ele.comment}</TableCell>
                            <TableCell>{ele.userStamp}</TableCell>
                            <TableCell>{ele.date}</TableCell>
                            <TableCell>{ele.change}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default DrawingHistory;
