import { useEffect, useState } from 'react';
import CustomModal from '../../../../../common-utilities/core/modal';
import MuiTable from '../../../../../common-utilities/core/mui-table';
import { setProperty } from '../../../../../common-utilities/utils/misc';
import { getHeaderConfig } from './config';

const PaymentDetailsModal = ({ showModal, title, data, closeModal }) => {
  const [config, setConfig] = useState({
    page: 1,
  });

  useEffect(() => {
    if (!showModal) {
      setProperty('page', 1, setConfig);
    }
  }, [showModal]);

  return (
    <>
      {showModal && (
        <>
          <CustomModal title={title} onClose={closeModal}>
            <MuiTable
              page={config.page}
              filters={config}
              columnsList={getHeaderConfig()}
              dataList={
                data.slice((config.page - 1) * 2, (config.page - 1) * 2 + 2) ||
                []
              }
              pageCount={Math.ceil(data.length / 2)}
              onChange={(page) => {
                setProperty('page', page, setConfig);
              }}
            />
          </CustomModal>
        </>
      )}
    </>
  );
};

export default PaymentDetailsModal;
