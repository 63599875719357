import moment from "moment";
import { DATE_FORMATS } from "../../../../utils/constants";
import { formatDate } from "../../../../utils";
import { getCorrectDay } from "../../utils/common-utils";

export const VendorInvoiceDetails = ({ formDetails }) => {
  return (
    <>
      <div className="i-key-value">
        <span className="i-key">UTR</span>
        <span className="i-value">{formDetails?.utr}</span>
      </div>

      <div className="i-key-value">
        <span className="i-key">Payment Status</span>
        <span className="i-value first-uppercase">
          {formDetails?.paymentStatus}
        </span>
      </div>

      <div className="i-key-value">
        <span className="i-key">Date of Payment</span>
        <span className="i-value ">
          {getCorrectDay(formDetails.paymentDate )}
        </span>
      </div>
      {/* dateValue */}
      <div className="i-key-value">
        <span className="i-key">Delivery Date</span>
        <span className="i-value ">
          {getCorrectDay(formDetails.deliveryDate)}
        </span>
      </div>

      <div className="i-key-value">
        <span className="i-key">Amount Paid</span>
        <span className="i-value">{formDetails?.amountPaid}</span>
      </div>
    </>
  );
};
