import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getBudgetTableColumns } from "../helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, TextField } from "@mui/material";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import { BUDGET_TYPES } from "../../../utils/constants";
import PoFilters from "../../purchase-order/po-filters";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
import { downloadReports } from "./utils";
const BudgetList = ({ status, refresh }) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [budgetType, setBudgetType] = useState(null);
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    if (status) {
      loadData();
    }
  }, [status, refresh]);

  const loadData = (providedFilters, download) => {
    let params = providedFilters ? { ...providedFilters } : { ...filters };
    params.status = status;
    if (searchKey) {
      params.searchKey = searchKey;
    }

    if (budgetType) {
      params.budgetType = budgetType.value;
    }
    params.populateLocation = true;
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      params
    )
      .then((response) => {
        if (download) {
          downloadReports(response?.results || []);
          return;
        }
        setBudgetList(response);
      })
      .catch((err) => {});
  };

  const viewBudget = (data) => {
    navigate(`budget-details/${data.id}`);
  };

  return (
    <div className="budget-list">
      <div className="filter-row">
        <TextField
          placeholder="Search by Budget Id"
          value={searchKey}
          size="small"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Autocomplete
          value={budgetType || ""}
          onChange={(e, val) => {
            if (val) {
              setBudgetType(val);
            } else {
              setBudgetType(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Budget Type"
              sx={{
                minWidth: 220,
              }}
            />
          )}
          options={BUDGET_TYPES}
        />
        <PoFilters
          filters={filters}
          showVendorDropDown={false}
          setFilters={setFilters}
        />

        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />

        <Button
          variant="outlined"
          onClick={() => {
            loadData({ ...filters, page: 1 });
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          disabled={!budgetList.totalResults}
          onClick={() => {
            loadData(
              {
                ...filters,
                page: 1,
                limit: budgetList.totalResults,
              },
              true
            );
          }}
        >
          Download
        </Button>
      </div>

      <MuiTable
        columnsList={getBudgetTableColumns(viewBudget)}
        nestedValue={true}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default BudgetList;
