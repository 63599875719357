import React, { useEffect, useState } from "react";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { Button, IconButton, TextField } from "@mui/material";
import Select from "react-select";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { validAddressTypes } from "../../utils/constants";
import { allRegions } from "../../../common-utilities/utils/region";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";

const AddAddress = ({
  addressDetails = {},
  onClose,
  onSave,
  addressTypes,
  isPersonData,
  countries,
  countryDataList,
  personNum,
}) => {
  const [addressList, setAddressList] = useState([]);
  const [address, setAddress] = useState({});
  const [states, setStates] = useState([]);
  const [addAddressToPerson, setAddAddressToPerson] = useState(false);
  useEffect(() => {
    setAddressList(addressDetails);
  }, [addressDetails]);
  // useEffect(() => {
  //   for (let ele in addressDetails) {
  //     if (
  //       addressDetails[ele] === undefined ||
  //       addressDetails[ele] === null ||
  //       (addressDetails[ele] === "" && isPersonData)
  //     ) {
  //       if (personNum !== "") {
  //         setAddAddressToPerson(true);
  //       }
  //     } else {
  //       setAddAddressToPerson(false);
  //       return;
  //     }
  //   }
  // }, []);

  const onChange = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };
  const countryHandler = (e) => {
    let allStates = allRegions[e?.value] || {};
    allStates = Object.keys(allStates).sort();
    allStates = getLabelsAndValuesForSelect(allStates, allStates);

    setAddress((prevVal) => {
      delete prevVal?.state;
      return {
        ...prevVal,
        country: e.value,
      };
    });
    setStates(allStates);
  };
  const addAddressHandler = () => {
    let payload = {
      addressType: address?.addressType,
      type: validAddressTypes.person,
      attn: address?.attn,
      address1: address?.address1,
      address2: address?.address2,
      address3: address?.address3,
      city: address?.city,
      country: address?.country,
      state: address?.state,
      personId: personNum,
      zipCode: address?.zipCode,
      county: address?.county,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_ADDRESS}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add address");
        } else {
          onSave(address);
        }
        setAddress({});
        setAddAddressToPerson(false);
      })
      .catch((err) => console.log(err));
  };
  const updateAddress = () => {
    let payload = {
      addressType: address?.addressType,
      type: validAddressTypes.person,
      attn: address?.attn,
      address1: address?.address1,
      address2: address?.address2,
      address3: address?.address3,
      city: address?.city,
      country: address?.country,
      zipCode: address?.zipCode,
      state: address?.state,
      county: address?.county,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_ADDRESS}${address.id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to add address");
        } else {
          onSave(address);
        }
        setAddress({});
        setAddAddressToPerson(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Address Details</span>
          <IconButton
            onClick={() => {
              onClose();
              setAddAddressToPerson(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <div className="g-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Type"
              // value={
              //   address?.addressType && {
              //     label: address.addressType,
              //     value: address.addressType,
              //   }
              // }

              onChange={(e) => {
                let addValue = addressList.filter((ele) => {
                  return ele.addressType === e.value;
                });
                if (addValue.length > 0) {
                  setAddAddressToPerson(false);
                  setAddress(addValue[0]);
                } else {
                  setAddress({});
                  setAddAddressToPerson(true);
                  setAddress((prevVal) => ({
                    ...prevVal,

                    addressType: e.value,
                  }));
                }
              }}
              options={addressTypes}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <TextField
              id="county"
              className="subs-text-field"
              label="county"
              name="county"
              value={address.county || ""}
              onChange={onChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Attention"
              className="subs-text-field"
              value={address.attn || ""}
              name="attn"
              onChange={onChange}
            />
            <TextField
              label="Located In"
              className="subs-text-field"
              value={address.address2 || ""}
              name="address2"
              onChange={onChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="Street/PO Box"
              className="subs-text-field"
              value={address.address3 || ""}
              name="address3"
              onChange={onChange}
            />
            <TextField
              label="Add'l Address"
              className="subs-text-field"
              value={address.address1 || ""}
              name="address1"
              onChange={onChange}
            />
          </div>
          <div className="g-r-2">
            <TextField
              label="City"
              className="subs-text-field"
              value={address.city || ""}
              name="city"
              onChange={onChange}
            />
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="Country"
              options={countries}
              value={
                address?.country && {
                  label: address.country,
                  value: address.country,
                }
              }
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onChange={countryHandler}
            />
            {/* <TextField
              label="Country"
              value={address.countryName || ""}
              
              name="countryName"
              onChange={onChange}
            /> */}
          </div>
          <div className="g-r-2">
            <Select
              classNamePrefix="custom_select"
              isSearchable
              placeholder="State/Prov"
              value={
                address?.state
                  ? {
                      label: address.state,
                      value: address.state,
                    }
                  : null
              }
              onChange={(e) => {
                setAddress((prevVal) => ({
                  ...prevVal,

                  state: e.value,
                }));
              }}
              options={states}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {/* <TextField
              label="State/Prov"
              value={address.stateProvCode || ""}
              
              name="stateProvCode"
              onChange={onChange}
            /> */}
            <TextField
              label="Postal Code"
              className="subs-text-field"
              value={address.zipCode || ""}
              name="zipCode"
              onChange={onChange}
            />
          </div>
          <Button
            size="small"
            fullWidth
            variant="contained"
            disabled={!personNum ? true : false}
            onClick={() => {
              !addAddressToPerson ? updateAddress() : addAddressHandler();
            }}
          >
            Save
          </Button>
          {/* {!addAddressToPerson && (
            <div>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={() => {
                  const indexToUpdate = addressList.findIndex(
                    (ele) => ele.addressType === address.addressType
                  );
                  addressList[indexToUpdate] = address;
                  onSave(addressList);
                }}
              >
                Save
              </Button>
            </div>
          )}
          {addAddressToPerson && (
            <div>
              <Button
                fullWidth
                variant="contained"
                onClick={addAddressHandler}
                size="small"
              >
                Save
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
export default AddAddress;
