import { Button } from "@mui/material";
import "./index.scss";
import SaveIcon from "@mui/icons-material/Save";
const CommonBtn = ({
  variant = "contained",
  color = "primary",
  onClick,
  className,
  text = "Save",
  btnClass = "rnd-btn-10",
  sx = {},
  disabled = false,
  startIcon: StartIcon,
  showSaveIcon = false,
  size = "medium",
  children,
  fullWidth=false
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        variant={variant}
        fullWidth={fullWidth}
        startIcon={
          <>
            {showSaveIcon ? (
              <SaveIcon />
            ) : (
              <>{StartIcon ? StartIcon : <> </>}</>
            )}
          </>
        }
        color={color}
        onClick={onClick}
        className={`${className ? className : ""} ${btnClass}`}
        sx={{ ...sx }}
        // size={size}
        size={size}
      >
        {children ? <>{children}</> : <>{text}</>}
      </Button>
    </>
  );
};

export default CommonBtn;
