import {
  setOfflineData as mainSetOfflineData,
  getOfflineData as mainGetOfflineData,
  clearOfflineData as mainClearOfflineData,
} from "../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../utils/constants";

export const setOfflineData = (key, data) => {
  return mainSetOfflineData(tokenMainKeys.qnet, key, data);
};

export const getOfflineData = (intranetTokenKey, key) => {
  return mainGetOfflineData(
    intranetTokenKey ? intranetTokenKey : tokenMainKeys.qnet,
    key
  );
};

export const clearOfflineData = (key) => {
  return mainClearOfflineData(tokenMainKeys.qnet, key);
};
