import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {
  getOfflineData,
  setOfflineData,
  clearOfflineData,
} from "./offline-services";
import { toast } from "react-toastify";
import {
  REST_URLS,
  SLUG,
} from "../../common-utilities/utils/end-points/bd-lead";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { mainInvokeApi } from "../../common-utilities/utils/http-service";
import { tokenMainKeys, appSlugs } from "../../../utils/constants";

const redirectToIntranetPortal = () => {
  clearOfflineData("user");
  clearOfflineData("tokens");
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const HTTP_METHODS = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "PATCH",
};

const getToken = async () => {
  let tokens = getOfflineData("tokens");
  if (tokens === "") {
    return;
  }
  let expiredAt =
    (tokens && tokens.access && new Date(tokens.access.expires)) ||
    new Date(1970);

  expiredAt.setMinutes(expiredAt.getMinutes() - 1);
  if (expiredAt > new Date()) {
    return (tokens && tokens.access && tokens.access.token) || "";
  } else {
    return await getRefreshToken(tokens.refresh.token);
  }
};

const getRefreshToken = async (refreshToken) => {
  try {
    const response = await trackPromise(
      fetch(`${INTRANETHOSTNAME}${REST_URLS.REFRESH_TOKEN}`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
          slug: SLUG,
        },
      })
    );
    const tokens = await response.json();
    if (tokens.code === 401 || tokens.code) {
      redirectToIntranetPortal();
    } else {
      setOfflineData("tokens", tokens);
    }
    return tokens.access.token || "";
  } catch (error) {
    redirectToIntranetPortal();
    return "";
  }
};
export const invokeApi = async (
  method,
  url,
  data,
  params,
  Authorization,
  additionalKey
) => {
  const key = additionalKey ? additionalKey : tokenMainKeys.bdLead;
  return mainInvokeApi(
    key,
    appSlugs.bdLead,
    method,
    url,
    data,
    params,
    Authorization
  );
};
// export const invokeApi = async (method, url, data, params, Authorization) => {
//   let headers = {
//     "Content-Type": "application/json",
//     slug: SLUG,
//   };
//   if (Authorization) {
//     headers["Authorization"] = `Bearer ${Authorization}`;
//   } else {
//     headers["Authorization"] = `Bearer ${await getToken()}`;
//   }
//   if (params) {
//     let query = Object.keys(params)
//       .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
//       .join("&");
//     url = url + "?" + query;
//   }

//   return trackPromise(
//     fetch(url, {
//       method: method,
//       body: (data && JSON.stringify(data)) || undefined,
//       headers: headers,
//       params: params,
//     }).then((response) => {
//       if (
//         response.status === 401 &&
//         url !== `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`
//       ) {
//         redirectToIntranetPortal();
//         return "";
//       }

//       if (response.status === 204) {
//         return Promise.resolve("ok");
//       }
//       return response.json();
//     })
//   );
// };

export const progressiveInvokeApi = async (method, url, data, params) => {
  let headers = {
    Authorization: `Bearer ${await getToken()}`,
    slug: SLUG,
  };

  return axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
    params: params,
  }).then((response) => {
    return response;
  });
};

export const invokeUploadFile = async (url, formData) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${await getToken()}`,
      slug: SLUG,
    },
  };
  return trackPromise(axios.post(url, formData, config));
};
