import React, { useState } from "react";
import "./index.scss";
import {
  Button,
  IconButton,
  TextField,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { DEPOSITIONS } from "./config";
import Select from "react-select";
const CreateMemo = ({ onClose, onSave, details }) => {
  const [memo, setMemo] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [deposition, setDeposition] = useState(null);

  const createHandler = () => {
    if (!details.storeNo) {
      toast.error("Select Store Number", { autoClose: 2000 });
      return;
    } else if (details.satelliteNo == null && details.satelliteNo == "") {
      toast.error("Select Satellite Number", { autoClose: 2000 });
      return;
    } else if (details.sequenceNo == null && details.satelliteNo == "") {
      toast.error("Select Sequence Number", { autoClose: 2000 });
      return;
    } else {
      let payload = {
        typeModel: details.typeModel,
        storeNo: parseInt(details.storeNo),
        sequenceNo: parseInt(details.sequenceNo),
        satelliteNo: parseInt(details.satelliteNo),
        type: details.storeId,
        memo: memo,
        category: details.category,
        deposition: deposition,
      };
      if (appliedDate) {
        payload["appliedDate"] = appliedDate;
      }
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.MEMOS}`, payload)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            onSave();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const appliedDateHandler = (e) => {
    setAppliedDate(e.target.value);
  };
  const depositionHandler = (e) => {
    setDeposition(e?.value);
  };
  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Create New Memo </span>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <Grid
            container
            md={12}
            spacing={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item md={4}>
              <Select
                options={DEPOSITIONS}
                placeholder="Select Deposition"
                onChange={depositionHandler}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Applied Date"
                className="subs-text-field"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={appliedDateHandler}
              />
            </Grid>
          </Grid>
          <TextareaAutosize
            minRows={40}
            placeholder="Memo"
            style={{ width: "70rem" }}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
          />
          <div>
            <Button
              disabled={!memo || !deposition ? true : false}
              sx={{ float: "right" }}
              variant="contained"
              onClick={() => createHandler()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMemo;
