import React, { useState, useRef, useEffect, useContext } from "react";
import { Grid, TextField } from "@mui/material";
import Select from "react-select";
import { auditModelNames } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { invokeApi, plainApi } from "../../utils/http-service";

import moment from "moment";
import { checkValidStoreCombination } from "../../utils";
import { GlobalStoreContext } from "../../global-states/Context";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { getLabelsAndValuesForSelect } from "../../../common-utilities/utils";

const TransferHistory = () => {
  const [tableData, settableData] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const { storeInfo, setStoreInfo } = useContext(GlobalStoreContext);
  const [sequenceList, setSequenceList] = useState([]);
  const [satelliteList, setSatelliteList] = useState([]);
  const previousController = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (totalPages === null) {
      getTableData(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        pages
      );
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getTableData(
            storeInfo.storeNo,
            storeInfo.satelliteNo,
            storeInfo.sequenceNo,
            pages
          );
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  const dataValueLabels = [
    { label: "Franchise Number", id: "franchiseNum" },
    { label: "Sequence Number", id: "sequenceNum" },
    { label: "Transfer Type", id: "transferType" },
    { label: "Transfer Status Type", id: "transferStatusType" },
    { label: "Transfer Closing Date", id: "transferClosingDate" },
    { label: "Transfer Applied Date", id: "transferAppliedDate" },
    { label: "Transfer Complete Date", id: "transferCompletedDate" },
    { label: "Transfer Cancellation Date", id: "transferCancelledDate" },
    { label: "Proposed Closing Date", id: "proposedClosingDate" },
    { label: "Buyer Person Number", id: "buyerPersonNum" },
    { label: "Entity Type", id: "entityType" },
    { label: "Transfer Applied Ind", id: "transferAppliedInd" },
    {
      label: "Buyer Existing Franchisee Ind",
      id: "buyerExistingFranchiseeInd",
    },
    { label: "Person", id: "person" },
    { label: "User Stamp", id: "userStamp" },
    { label: "Location Type", id: "locationType" },
    { label: "Transfer Fee", id: "transferFee" },
    { label: "Transfer fee received", id: "transferFeeRecieved" },
    { label: "Currency", id: "currency" },
    { label: "Initiator", id: "initiationInfo.initiator" },
    { label: "File Received Date", id: "initiationInfo.fileReceivedDate" },
    { label: "Initiator Date", id: "initiationInfo.initiatorDate" },
    { label: "Approver Date", id: "initiationInfo.approverDate" },
    {
      label: "Initiation Follow up Date",
      id: "initiationInfo.initiationFollowUpDate",
    },
    {
      label: "Approval Granted Date",
      id: "initiationInfo.approvalGrantedDate",
    },
    {
      label: "Initiation Complete Date",
      id: "initiationInfo.initiationCompletedDate",
    },
    { label: "Researcher", id: "researchInfo.researcher" },
    { label: "Researcher Date", id: "researchInfo.researcherDate" },
    {
      label: "Researcher Follow Up Date",
      id: "researchInfo.researcherFollowUpDate",
    },
    {
      label: "Researcher Completed Date",
      id: "researchInfo.researcherCompletedDate",
    },
    { label: "Consentor", id: "consentInfo.consentor" },
    { label: "Consentor Date", id: "consentInfo.consentorDate" },
    {
      label: "Consentor Follow up Date",
      id: "consentInfo.consentorFollowUpDate",
    },
    { label: "Consent Completed Date", id: "consentInfo.consentCompletedDate" },
    { label: "Processor", id: "processorInfo.processor" },
    { label: "Processor Date", id: "processorInfo.processorDate" },
    {
      label: "Processor Follow Up Date",
      id: "processorInfo.processorFollowUpDate",
    },
    { label: "Checker", id: "checkerInfo.checker" },
    { label: "Checker Date", id: "checkerInfo.checkerDate" },
    { label: "Closer", id: "closerInfo.closer" },
    { label: "Closer Date", id: "closerInfo.closerDate" },
    { label: "Pre Closing Date", id: "closerInfo.preClosingDate" },
    {
      label: "Pre Closing Complete Date",
      id: "closerInfo.preclosingCompleteDate",
    },
    { label: "Proposed Closing Date", id: "closerInfo.proposedClosingDate" },
    { label: "Store Closing Date", id: "closerInfo.storeClosingDate" },
    { label: "Take over date", id: "closerInfo.takeOverDate" },
    { label: "Closing Memo Sent", id: "closerInfo.closingMemoSent" },
    { label: "Document Received", id: "closerInfo.documentsReceived" },
    { label: "Closing Follow Up", id: "closerInfo.closingsFollowup" },
    { label: "Contract Date", id: "contractInfo.contractDate" },
    { label: "Landlord Name", id: "landlord.name" },
    {
      label: "Landlord text for letters",
      id: "landlord.landlordTextForLetters",
    },
    { label: "Tenant", id: "landlord.tenant" },
    { label: "Initial Term", id: "landlord.initialTerm" },
    { label: "Lease in Regeneration", id: "landlord.leaseInRegeneration" },
    { label: "Indicators School Lunch", id: "indicators.schoolLunch" },
    { label: "Indicators Co Brand", id: "indicators.coBrand" },
    { label: "Indicators Audit", id: "indicators.audit" },
    { label: "Indicators Equipment Lease", id: "indicators.equipmentLease" },
    { label: "Next POS Billing Date", id: "indicators.nextPOSBillingDate" },
  ];

  const columns = [
    { id: "transferType", label: "Transfer Type" },
    { id: "transferStatusType", label: "Transfer Status Type" },
    { id: "contractDate", label: "Contract Date" },
    { id: "contractPrice", label: "Contract Price" },
    { id: "currency", label: "Currency" },
    { id: "current", label: "Current" },
    { id: "transferApplied", label: "Transfer Applied" },
    { id: "closingFeesCollected", label: "Closing Fees Collected" },
    { id: "landlord", label: "Landlord" },
    { id: "ofacDate", label: "OFAC Date" },
    { id: "notifiedPrior", label: "Notified Prior" },
    { id: "newFranchiseId", label: "New Franchise Id" },
    { id: "userStamp", label: "UserStamp" },
    { id: "date", label: "Date/Time" },
    { id: "change", label: "Change" },
  ];

  const getTableData = (storeNo, satelliteNo, sequenceNo, page, hasToReset) => {
    const invalidStoreCombination = !checkValidStoreCombination(
      storeNo,
      satelliteNo,
      sequenceNo
    );
    if (invalidStoreCombination) {
      return;
    }

    let dataCheckingCondition = !pageArray.includes(page);
    if (hasToReset) {
      dataCheckingCondition = true;
    }
    if (dataCheckingCondition) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_HISTORY}`, null, {
        modelName: auditModelNames.StoreTransfer,
        storeNo,
        satelliteNo,
        sortBy: "-createdAt",
        page,
        sequenceNo,
        limit: 20,
      })
        .then((res) => {
          if (!res?.message) {
            setTotalPages(res.totalPages + 1);
            const results = res?.results?.map((item) => {
              const { details } = item;
              const allDetails = {};

              for (let detail in details) {
                const mainKey = `${detail}`.toLowerCase();

                if (typeof details[detail] === "object") {
                  for (let subDetail in details[detail]) {
                    let value = details[detail][subDetail];
                    const mainKey = `${subDetail}`.toLowerCase();
                    if (`${mainKey}`.includes("date")) {
                      value = moment(value).format("yyyy-MM-DD");
                    }
                    allDetails[`${detail}.${subDetail}`] = value;
                  }
                } else {
                  let value = details[detail];
                  if (mainKey.includes("date")) {
                    value = moment(value).format("yyyy-MM-DD");
                  }
                  allDetails[detail] = value;
                }
              }
              return allDetails;
            });
            if (page == 1) {
              settableData(results);
              setPageArray([1]);
              if (res.totalPages === 1) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              settableData([...tableData, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          } else {
            toast.error(res.mesage, { autoClose: 2000 });
          }
        })
        .catch((err) => console.log(err));
    } else {
      settableData([]);
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.STORE_SEARCH + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        const { sequenceNo } = p;

        return {
          title: p.storeNo,
          id: p.storeNo,
          satellite: p.satelliteNum,
          sequenceNo,
        };
      });
      setStoreOptions(updatedOptions);
      setStoreInfo((prevVal) => ({
        ...prevVal,
        storeNo: searchTerm,
      }));
    });
  };

  const onInputChange = (event, value, reason) => {
    setSatelliteList([]);
    setSequenceList([]);
    if (value) {
      getData(value);
      setStoreInfo({
        sequenceNo: "",
        satelliteNo: "",
        storeNo: value,
      });
    } else {
      clearData();
    }
  };

  const clearData = () => {
    setStoreInfo({
      storeNo: "",
      sequenceNo: "",
      satelliteNo: "",
    });
    setStoreOptions([]);
    settableData([]);
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
  };

  useEffect(() => {
    if (storeInfo.storeNo) {
      getAllSequenceNumber(storeInfo.storeNo);
      getTableData(
        storeInfo.storeNo,
        storeInfo.satelliteNo,
        storeInfo.sequenceNo,
        1,
        true
      );
    }
  }, []);

  const getAllSequenceNumber = (id) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ALL_SEQUENCE_NUMBER_AND_SATELLITE_NUMBER_FOR_STORE_NUMBER}${id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const { satelliteNumbers, sequenceNumbers } = res;
          const satelliteLabelAndValuesForSelect = getLabelsAndValuesForSelect(
            satelliteNumbers,
            satelliteNumbers
          );

          const sequenceNumberLabelAndValuesForSelect =
            getLabelsAndValuesForSelect(sequenceNumbers, sequenceNumbers);
          setSatelliteList(satelliteLabelAndValuesForSelect);
          setSequenceList(sequenceNumberLabelAndValuesForSelect);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="trasfer-con">
      <Grid container md={6} sx={{ mt: 3, mb: 3 }}>
        <Grid item md={3} xs={6}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  clearData();
                }}
              />
            }
            className="auto-complete-input subs-autocomplete"
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Store" />
            )}
            sx={{ width: "12em" }}
            inputValue={storeInfo.storeNo ? storeInfo.storeNo : ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                getAllSequenceNumber(val.id);
              }
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Satellite"
            className="sel-item"
            options={satelliteList}
            value={
              ![undefined, null].includes(storeInfo.satelliteNo)
                ? satelliteList.find((i) => i.value === storeInfo.satelliteNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                satelliteNo: val?.value,
              }));
              getTableData(
                storeInfo.storeNo,
                val?.value,
                storeInfo.sequenceNo,
                1,
                true
              );
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Select
            classNamePrefix="custom_select"
            placeholder="Sequence"
            className="sel-item"
            options={sequenceList}
            value={
              ![undefined, null].includes(storeInfo.sequenceNo)
                ? sequenceList.find((i) => i.value === storeInfo.sequenceNo)
                : ""
            }
            onChange={(val) => {
              setStoreInfo((prevVal) => ({
                ...prevVal,
                sequenceNo: val?.value,
              }));
              getTableData(
                storeInfo.storeNo,
                storeInfo.satelliteNo,
                val?.value,
                1,
                true
              );
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </Grid>
      </Grid>
      {/* <div className="title header">History Details</div>

      <Grid container md={12}>
        <Grid item md={3} sx={{ my: "1em" }}>
          <Select classNamePrefix="custom_select" />
        </Grid>
      </Grid> */}
      <StickyHeaderCon
        dataList={tableData}
        columnsList={dataValueLabels}
        setPages={setPages}
        setRefresh={setRefresh}
        hasMore={hasMore}
        // containerHeight="table-con"
      />
    </div>
  );
};

export default TransferHistory;
