/** @format */

export const DATE_FORMATS = {
  "DD.MM.YYYY": "DD.MM.YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD.MM.YYYY hh:MM": "DD.MM.YYYY hh:MM",
  "DD MMM YY hh:MM": "DD MMM YY hh:MM",
  "MMM DD YYYY hh:MM": "MMM DD YYYY hh:MM",
  "YYYY-MM-DD": "YYYY-MM-DD",
};

export const userTypes = {
  admin: "ADMIN",
  user: "USER",
};

export const userRoles = {
  admin: "admin",
  user: "user",
};

export const storeTypes = {
  cocoBuild: "COCO BUILT",
  cocoAcquired: "COCO ACQUIRED",
};
export const userStates = {
  isProcessing: "Checking",
};
export const allStores = Object.values(storeTypes);
export const leadStatusMap = {
  lead: "Lead",
  cfApproved: "CF Approved",
  termSheetSigned: "Term Sheet Signed",
  underConstruction: "Under Construction",
  opened: "Opened",
  dropped: "Dropped",
};

export const leadStatuses = Object.values(leadStatusMap);

export const stageGatesMap = {
  siteIdentification: "Site identification post discussion with Ops",
  siteFeasabilty: "Site Feasibility",
  cfApproval: "CF Approval",
  loiSigning: "LOI Signing",
  securityDepositPaymentTranche: "Security Deposit Payement Tranche 1",
  layoutDesign: "Layout Design",
  possessionDate: "Possession Date",
  titleDeligence: "Title Diligence",
  fssaiLicenseReceipt: "FSSAI License receipt",
  handOverToOperations: "Handover to operations",
  storeOpened: "Store Opened",
};

export const stageGatesDatesInOrder = [
  "Site identification post discussion with Ops Date",
  "Site Feasibility Date",
  "CF Approval Date",
  "LOI Signing Date",
  "Sec Deposit Payment Tranche1 Date",
  "Layout Design Date",
  "Possession Date",
  "Title Diligence Date",
  "FSSAI License receipt Date",
  "Handover to operations Date",
  "Store Opened Date",
];
