import React from "react";
import WaiverAssignment from "./waiver-assignment";
import "./index.scss";

const Waiver = () => {
  return (
    <div className="main-person-container">
      <WaiverAssignment />
    </div>
  );
};

export default Waiver;
