import React, { useState, useEffect } from "react";
import "./index.scss";
const ReportCard = ({ appInfo, appChangeFun }) => {
  const [cardImage, setCardImage] = useState("");
  useEffect(() => {
    setCardImage(appInfo?.img);
  }, []);

  return (
    <div className="report-card-container">
      <div onClick={() => appChangeFun(appInfo?.id)} className="report-card">
        <div className="con-div">
          <div className="app-logo">
            <img src={cardImage} alt="Card" className="card-app-image" />
          </div>

          <p className="title">{appInfo?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
