import React, { useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import MainContainer from '../container';
import { getOfflineData, setOfflineData } from '../utils/offline-services';
import {
  getUserDetailsFromIntranet,
  redirectToIntranetPortal
} from '../utils/common-utils';
import { userStates } from '../utils/constants';
import { redirectToRoute } from '../../common-utilities/utils/end-points/invoice-tracking';

const VendorOnboardPrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  const access = searchParams.get('access');
  const refresh = searchParams.get('refresh');
  const accessTokenExpiry = searchParams.get('accessTokenExpiry');
  const refreshTokenExpiry = searchParams.get('refreshTokenExpiry');
  const slug = searchParams.get('slug');

  if (slug) {
    setOfflineData('slug', slug);
  }

  const [user, setUser] = useState(userStates.isProcessing);
  const redirectToUploadIfMainPageIsThere = () => {
    const isHomePage = location.pathname === '/vendor-onboarding';
    if (isHomePage) {
      navigate(redirectToRoute);
    }
  };
  const checkAndGrantUserAccess = tokens => {
    getUserDetailsFromIntranet(
      access,
      userDetails => {
        if (userDetails && !userDetails?.message) {
          setUser(userDetails);
        }
        setSearchParams({});
        redirectToUploadIfMainPageIsThere();
      },
      tokens
    );
  };

  useEffect(() => {
    const userDetails = getOfflineData('user');
    const userDetailsNotPresent = !!!userDetails;
    const accessAndRefreshTokensArePresent = !!access && !!refresh;
    const tokens = {
      access: {
        token: access,
        expires: accessTokenExpiry?.trim()
      },
      refresh: {
        token: refresh,
        expires: refreshTokenExpiry?.trim()
      }
    };
    if (userDetailsNotPresent) {
      if (accessAndRefreshTokensArePresent) {
        checkAndGrantUserAccess(tokens);
      } else {
        redirectToIntranetPortal();
      }
    } else if (accessAndRefreshTokensArePresent) {
      checkAndGrantUserAccess(tokens);
    } else {
      redirectToUploadIfMainPageIsThere();
      setUser(userDetails);
    }
  }, [access, refresh]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer>
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default VendorOnboardPrivateRoute;
