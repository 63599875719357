import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import POList from "./po-list";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import POApprovalList from "./po-approval-list";
import { PO_TYPES } from "./helper";
import { isCreateAllowed } from "../../utils/common-utils";
import { clearProperty } from "../../../common-utilities/utils/misc";
import { checkUserRight } from "../../utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const PurchaseOrder = () => {
  const navigate = useNavigate();
  const [poModal, setPOModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("subIndex");
  const mainTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [noOfPendingPo, setNoOfPendingPo] = useState(0);
  const [noOfReconsiderPo, setNoOfReconsiderPo] = useState(0);
  const [formDetails, setFormDetails] = useState({});
  const [budgetLineItems, setBudgetLineItems] = useState([]);
  const [sites, setSites] = useState([]);
  const { site = "", lineItems = [], poType = {} } = formDetails;
  const lineItemIds = lineItems.map((l) => l.id);

  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue,
    });
    setValue(newValue);
  };
  const getPendingPo = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "po_approval",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingPo(response?.length);
        }
      })
      .catch((err) => {});
  };
  const getReconsiderPo = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      null,
      { status: "reconsider", limit: 1000 }
    )
      .then((response) => {
        if (!response.message) {
          setNoOfReconsiderPo(response?.results?.length || 0);
        }
      })
      .catch((err) => {});
  };

  const onCreate = () => {
    let budgetId = "";
    if (lineItems.length > 0) {
      budgetId = lineItems[0].budgetId;
    } else {
      return;
    }

    navigate(
      `purchase-order/create/${budgetId}?poType=${
        poType.value
      }&lineItemIds=${lineItemIds.join(
        ","
      )}&index=${mainTabIndex}&subIndex=${value}`
    );
  };

  const loadSites = (poType) => {
    let url = `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}/list`;
    let params = {
      poType,
      budgetStatus: "approved",
    };

    clearProperty("site", setFormDetails);
    invokeApi(HTTP_METHODS.GET, url, null, params)
      .then((response) => {
        if (response.message) {
        } else {
          const results =
            response?.map?.((item) => {
              return {
                ...item,
                restaurantId: item.code,
                restaurantName: item.name,
                id: item.id,
              };
            }) || [];
          setSites(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadBudgets = (site, budgetType) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      {
        page: 1,
        status: "approved",
        site,
        limit: 1000,
        budgetType,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.totalResults > 0) {
          setBudgetLineItems(
            response.results[0].lineItems.filter((l) => l.status === "approved")
          );
        }
      })
      .catch((err) => {});
  };

  const isDisabled = () => {
    return !poType || !site || !lineItems || lineItems.length === 0;
  };

  useEffect(() => {
    const openTabIndex = searchParams.get("subIndex");
    if (+openTabIndex === 5 && !checkUserRight("po.approveReject")) {
      setValue(4);
    }
  }, []);
  useEffect(() => {
    getPendingPo();
    getReconsiderPo();
  }, []);
  return (
    <div className=" rm-pt budget-list">
      <div className="tabs-section">
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange}>
            <Tab label="Draft PO List" />
            <Tab label="Open PO List" />
            <Tab label="Approved  PO List" />
            <Tab
              label={
                <Badge badgeContent={noOfReconsiderPo} color="primary">
                  Reconsider PO List
                </Badge>
              }
            />
            <Tab label="All PO List" />
            <Tab
              label={
                <Badge badgeContent={noOfPendingPo} color="primary">
                  Pending Actions
                </Badge>
              }
            />
          </Tabs>
        </Box>
        {isCreateAllowed("po") && (
          <Button
            variant="outlined"
            onClick={() => {
              setPOModal(true);
            }}
          >
            Create PO
          </Button>
        )}
      </div>
      <TabPanel value={value} index={0}>
        <POList status="draft" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <POList status="approval_in_progress" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <POList status="approved" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <POList status="reconsider" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <POList status="all" />
      </TabPanel>
      <TabPanel value={value} index={5}>
        {checkUserRight("po.approveReject") ? (
          <POApprovalList
            searchPlaceHolder="Search by Po Number"
            approvalType="po_approval"
            status="approval_in_progress"
          />
        ) : (
          "Not authorized to take action on purchase order"
        )}
      </TabPanel>

      {poModal && (
        <CustomModal
          title="Create Purchase Order"
          disabledOutsideClick={true}
          onClose={() => {
            setFormDetails({});
            setPOModal(false);
          }}
        >
          <div className="po-modal-content">
            <Autocomplete
              value={formDetails.poType || ""}
              onChange={(e, val) => {
                if (val) {
                  loadSites(val.value);
                  setFormDetails({ poType: val });
                } else {
                  setFormDetails({});
                }
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" label="PO Type *" />
              )}
              options={PO_TYPES}
            />
            <Autocomplete
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({
                    ...formDetails,
                    site: val,
                    lineItems: [],
                  });

                  loadBudgets(
                    val.id,
                    formDetails?.poType?.value === "opex_po"
                      ? "opex_budget"
                      : "capex_budget"
                  );
                } else {
                  setFormDetails({
                    ...formDetails,
                    lineItems: [],
                    site: null,
                  });
                  setBudgetLineItems([]);
                }
              }}
              value={formDetails.site || {}}
              getOptionLabel={(op) =>
                `${op.code || ""} - ${op.name || ""}` || ""
              }
              renderInput={(params) => (
                <TextField {...params} size="small" label="Site Name *" />
              )}
              options={sites}
            />
            <Autocomplete
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({
                    ...formDetails,
                    budgetId: val.budgetId,
                    lineItems: val,
                  });
                } else {
                  setFormDetails({
                    ...formDetails,
                    lineItems: {},
                  });
                }
              }}
              multiple
              value={formDetails.lineItems || []}
              getOptionLabel={(op) => op.name || ""}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Budget Category" />
              )}
              options={budgetLineItems}
            />

            <Button
              variant="outlined"
              onClick={onCreate}
              disabled={isDisabled()}
            >
              Create Order
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PurchaseOrder;
