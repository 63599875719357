import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { formatOptions } from "../config";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
export const FormatSelect = ({
  value,
  name,
  handleChange,
  removeValueFromMainData,
}) => {
  const [listFormatOptions, setListFormatOptions] = useState(formatOptions);
  const [isOtherFormat, setIsOtherFormat] = useState(false);
  useEffect(() => {
    if (value) {
      const dataLength = listFormatOptions.filter((item) => {
        return item.value === value;
      }).length;

      if (dataLength === 0) {
        setListFormatOptions([
          ...formatOptions,
          { label: value, value: value },
        ]);
      }
    }
  }, [value]);

  const otherFormatHandler = () => {
    setListFormatOptions([...formatOptions, { label: value, value }]);
    setIsOtherFormat(false);
  };

  const cancelOtherFormatHandler = () => {
    if (removeValueFromMainData) {
      removeValueFromMainData();
    }
    setIsOtherFormat(false);
  };
  return (
    <>
      {!isOtherFormat && (
        <FormControl  fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Format</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Format"
            name={name}
            onChange={handleChange}
          >
            {listFormatOptions.map((format) => {
              return (
                <MenuItem className="poper-select" value={format.value}>
                  {format.label}
                </MenuItem>
              );
            })}

            <MenuItem
              className="poper-select"
              onClick={() => setIsOtherFormat(true)}
              value=""
            >
              Others
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {isOtherFormat && (
        <TextField
          size="small"
          fullWidth
          label="Format"
          variant="outlined"
          name={name}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckIcon
                  fontSize="small"
                  color="success"
                  sx={{ cursor: "pointer" }}
                  onClick={otherFormatHandler}
                />
                <CloseIcon
                  fontSize="small"
                  color="error"
                  sx={{ cursor: "pointer" }}
                  onClick={() => cancelOtherFormatHandler()}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};
