import {
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import { validCocoBuiltLicenses } from "../../../utils/coco-built.config";
export const showCocoBuiltLicenseTypeBoxInForm = (formDetails, storeType) => {
  let showCocoBuiltLicenseTypeBox = true;

  if (
    storeType === validStoreTypes.cocoBuilt &&
    formDetails?.type?.value === validDocumentTypes.license &&
    formDetails?.licenseType?.value === validCocoBuiltLicenses.leaseRider
  ) {
    showCocoBuiltLicenseTypeBox = false;
    delete formDetails.notApplicable;
  }
  return showCocoBuiltLicenseTypeBox;
};
