import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
export const getHeaderConfig = [
  {
    label: "Client Id",
    id: "clientId",
  },
  {
    label: "Created At",
    id: "createdAt",
    render: (data) => {
      return <div>{moment(data.createdAt).format("DD-MM-YYYY")}</div>;
    },
  },
  {
    label: "Actions",
    id: "actions",
    render: (data, onClick) => {
      return (
        <>
          <EditIcon
            onClick={() => onClick(data, "EDIT")}
            style={{ cursor: "pointer" }}
          />
        </>
      );
    },
  },
];
