import ActionIcons from "../actionIcons";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { userTypes } from "../../utils/constants";
import { tokenMainKeys } from "../../../../utils/constants";

export const getHeaderConfig = (
  loadData,
  deptList,
  getAllDepartmentNamesByStoreType
) => {
  const headerConfig = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Email",
      id: "useremail",
    },
    {
      label: "Business Type",
      id: "storeType",
      render: (data) => {
        return (
          <div id={Math.random()}>{data?.storeType?.split("_")?.join(" ")}</div>
        );
      },
    },
    {
      label: "Owner",
      id: "owner",
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        let selectedDeptList;
        if (data.departments) {
          selectedDeptList = data.departments.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
        } else {
          selectedDeptList = [];
        }

        return (
          <div className="store-icon-container">
            <ActionIcons
              type={data.type}
              loadData={loadData}
              id={data.id}
              name={data.name}
              email={data.email}
              departmentList={deptList}
              selectedDepartments={selectedDeptList}
              owner={data.owner}
              ownerId={data.ownerId}
              getAllDepartmentNamesByStoreType={
                getAllDepartmentNamesByStoreType
              }
              modalType="STORE"
            />
          </div>
        );
      },
    },
  ];

  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData(tokenMainKeys.technet, "user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }
  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Email",
    id: "useremail",
  },
  {
    label: "Business Type",
    id: "type",
  },
  {
    label: "Owner",
    id: "owner",
  },
];
export const selectStoreType = [
  { label: "FOFO", value: "FOFO" },
  { label: "COCO BUILT", value: "COCO_BUILT" },
  { label: "COCO ACQUIRED", value: "COCO_ACQUIRED" },
  { label: "ALL", value: "ALL" },
];

export const storeTypeList = selectStoreType.map((item) => {
  return item.value;
});

export const selectStoreTypeMap = () => {
  const backendToFrontEndMapping = {};
  selectStoreType.forEach((item) => {
    backendToFrontEndMapping[item.value] = item.label;
  });
  return backendToFrontEndMapping;
};
