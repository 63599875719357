export const setOfflineData = (mainKey, key, data) => {
  if (data !== "" && data !== null && data !== undefined) {
    localStorage.setItem(`${mainKey}:${key}`, JSON.stringify(data));
  }
};

export const getOfflineData = (mainKey, key) => {
  const data = localStorage.getItem(`${mainKey}:${key}`);
  if (data !== "" && data !== null && data !== undefined) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const clearOfflineData = (mainKey, key) => {
  localStorage.removeItem(`${mainKey}:${key}`);
};
