import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, IconButton, TextField } from "@mui/material";
import Select from "react-select";
import { CloseOutlined } from "@mui/icons-material";
import { invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
const AddContact = ({
  details = {},
  storeNo,
  onClose,
  onSave,
  sequenceNo,
  satelliteNo,
}) => {
  const [contacts, setContacts] = useState({});
  const [personOptions1, setPersonOptions1] = useState([]);
  const [personOptions2, setPersonOptions2] = useState([]);
  const [personOptions3, setPersonOptions3] = useState([]);
  const [personOptions4, setPersonOptions4] = useState([]);
  const [personOptions5, setPersonOptions5] = useState([]);
  const [personOptions6, setPersonOptions6] = useState([]);

  const [personInput1, setPersonInput1] = useState(
    "storeDesigner" in details ? details?.storeDesigner?.id + "" : ""
  );
  const [personInput2, setPersonInput2] = useState(
    "constructionAssistant" in details
      ? details?.constructionAssistant?.id + ""
      : ""
  );
  const [personInput3, setPersonInput3] = useState(
    "coordinator" in details ? details?.coordinator?.id + "" : ""
  );
  const [personInput4, setPersonInput4] = useState(
    "collectionsRepresentative" in details
      ? details?.collectionsRepresentative?.id + ""
      : ""
  );
  const [personInput5, setPersonInput5] = useState(
    "auditor" in details ? details?.auditor?.id + "" : ""
  );
  const [personInput6, setPersonInput6] = useState(
    "franchiseSalesManager" in details
      ? details?.franchiseSalesManager?.id + ""
      : ""
  );
  const [data, setData] = useState({
    storeDesigner: "",
    constructionAssistant: "",
    coordinator: "",
    collectionsRepresentative: "",
    auditor: "",
    franchiseSalesManager: "",
  });
  const previousController1 = useRef();
  const previousController2 = useRef();
  const previousController3 = useRef();
  const previousController4 = useRef();
  const previousController5 = useRef();
  const previousController6 = useRef();

  // useEffect(() => {
  //   setContacts(details || {});
  // }, [details]);
  const onChange = (event) => {
    setContacts({
      ...contacts,
      [event.target.name]: event.target.value,
    });
  };
  const getData = (searchTerm) => {
    if (previousController1.current) {
      previousController1.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController1.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions1(updatedOptions);
      setPersonInput1(searchTerm);
    });
  };
  const getData2 = (searchTerm) => {
    if (previousController2.current) {
      previousController2.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController2.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions2(updatedOptions);
      setPersonInput2(searchTerm);
    });
  };
  const getData3 = (searchTerm) => {
    if (previousController3.current) {
      previousController3.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController3.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions3(updatedOptions);
      setPersonInput3(searchTerm);
    });
  };
  const getData4 = (searchTerm) => {
    if (previousController4.current) {
      previousController4.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController4.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions4(updatedOptions);
      setPersonInput4(searchTerm);
    });
  };
  const getData5 = (searchTerm) => {
    if (previousController5.current) {
      previousController5.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController5.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions5(updatedOptions);
      setPersonInput5(searchTerm);
    });
  };
  const getData6 = (searchTerm) => {
    if (previousController6.current) {
      previousController6.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController6.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions6(updatedOptions);
      setPersonInput6(searchTerm);
    });
  };
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setPersonInput1("");
      setPersonInput2("");
      setPersonInput3("");
      setPersonInput4("");
      setPersonInput5("");
      setPersonInput6("");

      setPersonOptions1([]);
      setPersonOptions2([]);
      setPersonOptions3([]);
      setPersonOptions4([]);
      setPersonOptions5([]);
      setPersonOptions6([]);
    }
  };
  function removeEmptyElements(obj) {
    if (Array.isArray(obj)) {
      obj.forEach((element, index) =>
        obj.splice(index, 1, removeEmptyElements(element))
      );
      return obj;
    }
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, v]) =>
          Array.isArray(v)
            ? v.length !== 0
            : v !== null && v !== "" && v !== undefined
        )
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyElements(v) : v])
    );
  }
  const saveRecord = () => {
    let storeContactData = removeEmptyElements(data);
    // for (let ele in data) {
    //   storeContactData["ele"] = parseInt(data[ele]);
    // }
    let payload = {
      storeNo: storeNo,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
      storeContact: storeContactData,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_CONTACTS}`,
      payload
    ).then((response) => {
      if (response.code) {
        toast.error("Failed to add Contact details", { autoClose: 2000 });
      } else {
        onSave(payload);
      }
    });
  };
  const updateRecord = () => {
    let storeContactData = removeEmptyElements(data);
    let payload = {
      storeNo: storeNo,
      sequenceNo: sequenceNo,
      satelliteNo: satelliteNo,
      storeContact: storeContactData,
    };
    // if (personInput) {
    //   payload["person"] = personInput;
    // }
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_CONTACTS}`,
      payload
    ).then((response) => {
      if (response.code) {
        toast.error("Failed to update Contact details", { autoClose: 2000 });
      } else {
        onSave(payload);
      }
    });
  };

  return (
    <div className="store-add-address">
      <div>
        <div className="drawer-header">
          <span className="title">Contacts Details </span>
          <IconButton
            onClick={() => {
              onClose();
              setData({
                storeDesigner: "",
                constructionAssistant: "",
                coordinator: "",
                collectionsRepresentative: "",
                auditor: "",
                franchiseSalesManager: "",
              });
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>

        <div className="address-details">
          <div className="f-r-2"></div>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            className="subs-autocomplete"
            options={personOptions1}
            onInputChange={(event, value) => {
              if (value) {
                getData(value);
              } else {
                setPersonInput1("");
                setPersonOptions1([]);
              }
            }}
            inputValue={personInput1}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput1("");
                  setPersonOptions1([]);
                  setData((prevVal) => ({ ...prevVal, storeDesigner: "" }));
                }}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                label="Store Designer"
              />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({ ...prevVal, storeDesigner: val.id }));
              }
            }}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            // disabled={isCreate}
            options={personOptions2}
            onInputChange={(event, value) => {
              if (value) {
                getData2(value);
              } else {
                setPersonInput2("");
                setPersonOptions2([]);
              }
            }}
            inputValue={personInput2}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput2("");
                  setPersonOptions2([]);
                  setData((prevVal) => ({
                    ...prevVal,
                    constructionAssistant: "",
                  }));
                }}
              />
            }
            className="subs-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                label="Construction Assistant"
              />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({
                  ...prevVal,
                  constructionAssistant: val.id,
                }));
              }
            }}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            // disabled={isCreate}
            options={personOptions3}
            onInputChange={(event, value) => {
              if (value) {
                getData3(value);
              } else {
                setPersonInput3("");
                setPersonOptions3([]);
              }
            }}
            inputValue={personInput3}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput3("");
                  setPersonOptions3([]);
                  setData((prevVal) => ({ ...prevVal, coordinator: "" }));
                }}
              />
            }
            className="subs-autocomplete"
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Coordinator" />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({ ...prevVal, coordinator: val.id }));
              }
            }}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            // disabled={isCreate}
            options={personOptions4}
            onInputChange={(event, value) => {
              if (value) {
                getData4(value);
              } else {
                setPersonInput4("");
                setPersonOptions4([]);
              }
            }}
            inputValue={personInput4}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput4("");
                  setPersonOptions4([]);
                  setData((prevVal) => ({
                    ...prevVal,
                    collectionsRepresentative: "",
                  }));
                }}
              />
            }
            className="subs-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                label="Collections Representative"
              />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({
                  ...prevVal,
                  collectionsRepresentative: val.id,
                }));
              }
            }}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            // disabled={isCreate}
            options={personOptions5}
            onInputChange={(event, value) => {
              if (value) {
                getData5(value);
              } else {
                setPersonInput5("");
                setPersonOptions5([]);
              }
            }}
            inputValue={personInput5}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput5("");
                  setPersonOptions5([]);
                  setData((prevVal) => ({
                    ...prevVal,
                    auditor: "",
                  }));
                }}
              />
            }
            className="subs-autocomplete"
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Auditor" />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({
                  ...prevVal,
                  auditor: val.id,
                }));
              }
            }}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            // disabled={isCreate}
            options={personOptions6}
            onInputChange={(event, value) => {
              if (value) {
                getData6(value);
              } else {
                setPersonInput6("");
                setPersonOptions6([]);
              }
            }}
            inputValue={personInput6}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setPersonInput6("");
                  setPersonOptions6([]);
                  setData((prevVal) => ({
                    ...prevVal,
                    franchiseSalesManager: "",
                  }));
                }}
              />
            }
            className="subs-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                className="sel-item"
                label="Franchise Sales Manager"
              />
            )}
            onChange={(e, val) => {
              if (val) {
                setData((prevVal) => ({
                  ...prevVal,
                  franchiseSalesManager: val.id,
                }));
              }
            }}
          />
          <div>
            <Button
              size="small"
              fullWidth
              disabled={!storeNo ? true : false}
              variant="contained"
              onClick={() => (details ? updateRecord() : saveRecord())}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
