import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import MainContainer from "../container";
import {
  getUserDetailsFromIntranet,
  redirectToIntranetPortal,
} from "../utils/common-utils";
import { userStates } from "../utils/constants";
import { getOfflineData } from "../utils/offline-services";

const PrivateRoute = ({
  reloadPendingApprovals,
  setReloadPendingApprovals,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const [user, setUser] = useState(userStates.isProcessing);
  const checkAndGrantUserAccess = (tokens) => {
    getUserDetailsFromIntranet(
      access,
      (userDetails) => {
        if (userDetails && !userDetails?.message) {
          setUser(userDetails);
        }
        setSearchParams({});
      },
      tokens
    );
  };
  useEffect(() => {
    const userDetails = getOfflineData("user");
    const userDetailsNotPresent = !!!userDetails;

    const accessAndRefreshTokensArePresent = !!access && !!refresh;
    const tokens = {
      access: {
        token: access,
        expires: accessTokenExpiry?.trim(),
      },
      refresh: {
        token: refresh,
        expires: refreshTokenExpiry?.trim(),
      },
    };
    if (userDetailsNotPresent) {
      if (accessAndRefreshTokensArePresent) {
        checkAndGrantUserAccess(tokens);
      } else {
        redirectToIntranetPortal();
      }
    } else if (accessAndRefreshTokensArePresent) {
      checkAndGrantUserAccess(tokens);
    } else {
      setUser(userDetails);
    }
  }, [access, refresh]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer
      reloadPendingApprovals={reloadPendingApprovals}
      setReloadPendingApprovals={setReloadPendingApprovals}
    >
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
