import { toast } from "react-toastify";
import { deleteObjProp, pick } from "../../utils";
import { mainDocumentTypes, subDocumentTypes } from "../constants";
import { HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS } from "../../utils/end-points/app-document";
import { INTRANETHOSTNAME } from "../../utils/end-points";
import { getFieldList } from "../main-apis";

export const setDocData = (data, setFormDetails, setSubFolderList, slug) => {
  if (data) {
    const documentCategory = data?.documentCategory || {};
    const mainFolder = documentCategory?.parent?.id || null;
    const subFolder = documentCategory?.id || null;
    if (mainFolder) {
      getFieldList(slug, { parent: mainFolder }, "select", setSubFolderList);
    }

    const allData = pick({ ...data, mainFolder, subFolder }, [
      "mainFolder",
      "subFolder",
      "fileName",
      "id",
      "link",
    ]);
    setFormDetails(allData);
  }
};

export const disableFileUpload = (file, allowedFormats) => {
  if (!allowedFormats?.length) {
    return false;
  }
  let disabledUpload = true;

  allowedFormats?.forEach?.((item) => {
    switch (item) {
      case "pdf":
        if (file?.type === "application/pdf") {
          disabledUpload = false;
        }
        break;
      case "images":
        if (file?.type?.startsWith?.("image/")) {
          disabledUpload = false;
        }
        break;
      default:
        break;
    }
  });

  if (disabledUpload) {
    const fmtList =
      allowedFormats?.reduce?.((string, format, index) => {
        if (index && index === allowedFormats.length - 1) {
          return `${string} and ${format}`;
        }
        if (!index) {
          return format;
        }

        return `${string}, ${format}`;
      }, "") || "";
    toast.error(`You can only upload only files which are ${fmtList}`, {
      autoClose: 2000,
    });
    return true;
  }

  // if (file?.type !== "application/pdf" && !file?.type?.startsWith("video/")) {
  //   toast.error("Only pdf and videos can be uploaded");
  //   return true;
  // }
  return false;
};

export const disableDocSave = (formDetails) => {
  return (
    !formDetails.mainFolder ||
    !formDetails.subFolder ||
    !formDetails.fileName ||
    !formDetails.link
  );
};

export const getMainSubType = (fileName, mimetype) => {
  const splittedFile = fileName?.split(".");
  const length = splittedFile.length;
  if (splittedFile?.[length - 1]?.includes("pdf")) {
    return [mainDocumentTypes.document, subDocumentTypes.pdf];
  }
  if (mimetype?.startsWith?.("video/")) {
    return [mainDocumentTypes.video, subDocumentTypes.mp4];
  }
  return [mainDocumentTypes.document, subDocumentTypes.other];
};

export const getFileName = (name, removeExt = true) => {
  const splittedName = name?.split?.(".") || [];
  const length = splittedName.length || 0;
  if (removeExt) {
    return splittedName.slice(0, length - 1).join(".");
  }
};

export const getExt = (link) => {
  const splittedLink = link?.split?.(".") || [];
  const length = splittedLink.length;
  return splittedLink[length - 1] || "";
};

export const getDocApiDetails = (data) => {
  const payload = pick(data, [
    "subFolder",
    "fileName",
    "id",
    "link",
    "mainType",
    "subType",
  ]);
  payload.documentCategory = payload.subFolder;
  const method = payload.id ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  const { id } = payload;
  const url = id
    ? `${INTRANETHOSTNAME}${REST_URLS.UPDATE_DOCUMENT}${id}`
    : `${INTRANETHOSTNAME}${REST_URLS.CREATE_DOCUMENT}`;
  const message = id
    ? "Document Updated Successfully"
    : "Document created successfully";
  deleteObjProp(["id", "subFolder"], payload);
  return { method, url, payload, message };
};
