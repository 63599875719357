export const modalConstants = {
  update: 'update',
  delete: 'delete',
  create: 'create',
  createFinanceRole: 'createFinanceRole',
};

export const approvalTypes = [
  { label: 'Line Item Approval', value: 'lineitem_approval' },
  { label: 'PO Approval', value: 'po_approval' },
  { label: 'Invoice Approval', value: 'invoice_approval' },
];

export const locationTypes = [
  { label: 'HO', value: 'HO' },
  { label: 'Store', value: 'STORE' },
];

export const workFlowTypes = [
  { label: 'Capex', value: 'capex' },
  {
    label: 'Opex',
    value: 'opex',
  },
];
