import moment from "moment";

export const fileCell = ({ cell: { value } }) => {
  return (
    <div>
      {value &&
        value.map((ele) => (
          <>
            <a href={ele?.document}>{ele?.originalName}</a>
            <br />
          </>
        ))}
    </div>
  );
};

export const formatDateCell = ({ cell: { value } }) => {
  return <div>{value && moment(value).format("DD-MM-yyyy")}</div>;
};

export const keyDateCell = ({ cell: { value } }) => {
  const key = value?.key;
  const date = value?.[key];

  if ((value && !date) || value?.na) {
    return <div></div>;
  }
  return <div>{date && moment(date).format("DD-MM-yyyy")}</div>;
};

export const startDateCell = ({ cell: { value } }) => {
  if ((value && !value?.startDate) || value?.na) {
    return <div></div>;
  }
  if (value?.startDate && value?.docUrl) {
    return (
      <a href={value.docUrl} target="_blank">
        {moment(value?.startDate).format("DD-MM-yyyy")}
      </a>
    );
  } else {
    return (
      <div>
        {value?.startDate && moment(value?.startDate).format("DD-MM-yyyy")}
      </div>
    );
  }
};

export const startDateCellWithoutNa = ({ cell: { value } }) => {
  if ((value && !value?.startDate)) {
    return <div></div>;
  }
  if (value?.startDate && value?.docUrl) {
    return (
      <a href={value.docUrl} target="_blank">
        {moment(value?.startDate).format("DD-MM-yyyy")}
      </a>
    );
  } else {
    return (
      <div>
        {value?.startDate && moment(value?.startDate).format("DD-MM-yyyy")}
      </div>
    );
  }
};


export const storeFormatCell = ({ cell: { value } }) => (
  <div>
    <p>{value?.format}</p>
    <p>{value?.format2}</p>
  </div>
);
