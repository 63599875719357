import React, { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Chart from "react-apexcharts";
import "./index.scss";
import { IconButton } from "@mui/material";
import { exportToExcel } from "../../../common-utilities/utils";

const CustomBarChart = ({
  title,
  data = [],
  category,
  name,
  stacked,
  disableDownload,
  empty,
  dataLabelDisable,
  downloadKey,
  downloadValue,
}) => {
  const width = window.innerWidth > 420 ? 500 : 300;
  const state = {
    options: {
      chart: {
        stacked: stacked,
      },
      labels: category,
      type: "bar",
      legend: {
        position: "right",
      },
      xaxis: {
        categories: category,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      dataLabels: {
        enabled: dataLabelDisable === true ? false : window.innerWidth > 450,
        formatter: function (val) {
          // return val.toFixed(2) + "%";
          return val;
        },
      },
    },
    series: data,
    labels: category,
  };

  return (
    <div className="bar-chart-container">
      <div className="title">
        {title}
        <IconButton
          style={{ display: disableDownload ? "none" : "" }}
          disabled={data.length === 0}
          onClick={() => {
            let obj = {};
            if (downloadKey && downloadValue) {
              const allItems =
                data[0]?.data?.map((iter, index) => {
                  return {
                    [downloadKey]: category[index],
                    [downloadValue]: iter,
                  };
                }) || [];

              exportToExcel(allItems, name);
              return;
            }
            if (stacked && downloadKey) {
              const allItems =
                category?.map?.((item, index) => {
                  const itemDetails = {};
                  data?.forEach?.((dataItem) => {
                    const { name, data } = dataItem;
                    if (name) {
                      itemDetails[name] = data?.[index] || 0;
                    }
                  });
                  return { [downloadKey]: item, ...itemDetails };
                }) || [];
              exportToExcel(allItems, name);
              return;
            }
            data[0]?.data.forEach((iter, index) => {
              obj[category[index]] = iter;
            });

            exportToExcel([obj], name);
          }}
        >
          <DownloadIcon />
        </IconButton>
      </div>
      {data.length > 0 && empty !== true ? (
        <div>
          <Chart
            type="bar"
            options={state.options}
            labels={state.labels}
            series={state.series}
            height={300}
            width={window.innerWidth > 450 ? undefined : width || "400px"}
          />
        </div>
      ) : (
        <div
          style={{
            width: `${width}px`,
          }}
        >
          No data to display
        </div>
      )}
    </div>
  );
};

export default CustomBarChart;
