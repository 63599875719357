import { useEffect, useState } from "react";
import { queryDocumentCategories } from "../apis";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { getCategoryHeaderConfig } from "../config";
import CommonBtn from "../../../core/common-button";
import { actionHandler } from "../../utils";
import { modalActions } from "../../constants";
import CreateUpdateCategory from "../../modals/create-update-category";

export const CategoriesTab = ({
  slug,
  setModalDetails,
  modalDetails,
  closeModal,
  departmentMapping,
}) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    queryDocumentCategories(filters, setTableData, slug);
  }, [filters, slug]);

  return (
    <div className="category-container">
      <div className="crt-btn">
        <CommonBtn
          onClick={() => {
            actionHandler(null, modalActions.createCategory, setModalDetails);
          }}
        >
          Create Category
        </CommonBtn>
      </div>
      <MuiTable
        columnsList={getCategoryHeaderConfig()}
        dataList={tableData?.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onChange={(page) => {
          setFilters({ ...filters, page: page });
        }}
        onClick={(data, type) => {
          actionHandler(data, type, setModalDetails);
        }}
      />
      <CreateUpdateCategory
        showModal={
          modalDetails?.type === modalActions.createCategory ||
          modalDetails?.type === modalActions.updateCategory
        }
        data={modalDetails?.data}
        title={modalDetails?.title}
        closeModal={closeModal}
        slug={slug}
        onSuccess={() => {
          closeModal();
          queryDocumentCategories(filters, setTableData, slug);
        }}
        departmentMapping={departmentMapping}
      />
    </div>
  );
};
