import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.scss";
import { TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { invokeApi } from "../../utils/http-service";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { GlobalStoreContext } from "../../global-states/Context";
import StickyHeaderCon from "../../../common-utilities/core/sticky-header-table";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/subway-subs";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";

const PersonStoreListing = () => {
  const { personInfo, setPersonInfo } = useContext(GlobalStoreContext);
  const [pages, setPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [personOptions, setPersonOptions] = useState([]);
  const previousController = useRef();
  const [filter] = useState({
    limit: 10,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    if (totalPages === null) {
      getStoreDataByPersonNum(personInfo.personId, pages);
    } else {
      if (totalPages) {
        if (pages < totalPages) {
          setHasMore(true);
          getStoreDataByPersonNum(personInfo.personId, pages);
        } else {
          setHasMore(false);
        }
      }
    }
  }, [refresh]);

  const dataKeyValue = [
    {
      label: "Store Number",
      id: "storeNo",
    },
    {
      label: "Sequence Number",
      id: "sequenceNo",
    },
    {
      label: "Satellite Number",
      id: "satelliteNo",
    },
    {
      label: "DA Number",
      id: "daNo",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Currency",
      id: "currency",
    },
    {
      label: "Location Type",
      id: "locationType",
    },
    {
      label: "Ad Market",
      id: "adMarket",
    },
    {
      label: "Exeption",
      id: "exception",
    },
    {
      label: "Audit Hold",
      id: "auditHold",
    },
    {
      label: "GLN",
      id: "gln",
    },
    {
      label: "Has Active Note",
      id: "hasActiveNote",
    },
    {
      label: "Temporary Closing Reason",
      id: "temporaryClosingReason",
    },
    {
      label: "Temporary Closing Date",
      id: "temporaryClosingDate",
    },
    {
      label: "Aniticipated Reopening Date",
      id: "anticipatedReopeningDate",
    },
    {
      label: "Final Closed Date",
      id: "finalClosedDate",
    },
    {
      label: "Occupancy",
      id: "occupancy",
    },
    {
      label: "Drawing One Page Date",
      id: "drawingOnePageDate",
    },
    {
      label: "Drawing Full Set Date",
      id: "drawingFullSetDate",
    },
    {
      label: "",
      id: "equipmentFundsRecdDate",
    },
    {
      label: "Equipment Order Date",
      id: "equipmentOrderDate",
    },
    {
      label: "Initial Opening Date",
      id: "initialOpeningDate",
    },
    {
      label: "Reopen Date",
      id: "reopenDate",
    },
    {
      label: "Transfer Date",
      id: "transferDate",
    },
    {
      label: "Last Reopening Date",
      id: "lastReopeningDate",
    },
    {
      label: "Final Closing Date",
      id: "finalClosingPending",
    },
    // {
    //   label: "Iso Currency Alpha Code",
    //   id: "isoCurrencyAlphaCode",
    //   type: "text",
    // },
  ];
  const [data, setData] = useState([]);

  const getStoreDataByPersonNum = (personId, page, hasToReset) => {
    if (!Number.isInteger(parseInt(personId))) {
      return;
    }

    let dataCheckingCondition = !pageArray.includes(page);

    if (hasToReset) {
      dataCheckingCondition = true;
    }

    if (dataCheckingCondition) {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.QUERY_STORES}`,
        null,
        {
          ...filter,
          page,
          owner: personId,
        }
      )
        .then((res) => {
          if (!res?.message) {
            setTotalPages(res.totalPages + 1);
            const results =
              res?.results?.map((item) => {
                const details = {};
                for (let key in item) {
                  let value = item[key];
                  const lowerCasedKey = `${key}`.toLocaleLowerCase();
                  if (lowerCasedKey.includes("date")) {
                    value = moment(value).format("yyyy-MM-DD");
                  }
                  details[key] = value;
                }
                return details;
              }) || [];

            if (page == 1) {
              setData(results);
              setPageArray([1]);
            } else {
              setData([...data, ...results]);
            }

            let array = [...pageArray];
            array.push(page);
            setPageArray(array);
          } else {
            setData([]);
            toast.error(res.message, { autoClose: 2000 });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const clearData = () => {
    setData([]);
    setPersonInfo({ personId: "" });
    setTotalPages(null);
    setPageArray([]);
    setHasMore(false);
    setPages(1);
    setPersonOptions([]);
  };

  useEffect(() => {
    if (personInfo.personId) {
      getStoreDataByPersonNum(personInfo.personId, 1, true);
    }
  }, []);

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios({
      method: HTTP_METHODS.GET,
      url: HOSTNAME + REST_URLS.PERSON_SEARCH + "_id/" + searchTerm,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: signal,
    }).then((response) => {
      const updatedOptions = response.data.map((p) => {
        return { title: p.id, id: p.id };
      });
      setPersonOptions(updatedOptions);
    });
  };

  const onInputChange = (event, value) => {
    if (value) {
      getData(value);
    } else {
      clearData();
    }
    setPersonInfo({ personId: value ? value : "" });
  };

  return (
    <div className="person-store-listing-con ">
      <div className="search-div">
        <Grid container xs={2}>
          <Autocomplete
            freeSolo
            className="autoComplete subs-autocomplete"
            id="free-solo-2-demo"
            getOptionLabel={(option) => {
              return option.title ? option.title.toString() : "";
            }}
            options={personOptions}
            onInputChange={onInputChange}
            inputValue={personInfo.personId}
            clearIcon={<ClearIcon fontSize="small" onClick={clearData} />}
            renderInput={(params) => (
              <TextField {...params} className="sel-item" label="Person123" />
            )}
            onChange={(e, val) => {
              if (val) {
                getStoreDataByPersonNum(val.id, 1, true);
              }
            }}
          />
        </Grid>
      </div>
      <StickyHeaderCon
        dataList={data}
        columnsList={dataKeyValue}
        setPages={setPages}
        setRefresh={setRefresh}
        hasMore={hasMore}
        containerHeight="table-con"
      />
    </div>
  );
};

export default PersonStoreListing;
