import "./index.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BellCorpStudioLogoContainer } from "../../../common-utilities/Bellcorp-Studio-Logo";

const Thankyou = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);
  return (
    <>
      <div className="main-thankyou-container">
        <div className="thankyou">
          <div>
            <header class="site-header" id="header">
              <div className="approval-container">
                <CheckCircleIcon color="primary" className="approval-icon" />
              </div>
              <h1 class="site-header__title" data-lead-id="site-header-title">
                THANK YOU!
              </h1>
            </header>

            <div class="main-content">
              <p class="main-content__body" data-lead-id="main-content-body">
                Your response is recorded
              </p>
            </div>
          </div>
        </div>
        <BellCorpStudioLogoContainer customClass="main-thankyou-container" />
      </div>
    </>
  );
};
export default Thankyou;
