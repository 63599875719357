const intranetService = `/v1/central-service/`;
const commonPrefix = "/v1/fininv-service/";
export const REST_URLS = {
  LOGIN: `${intranetService}auth/login`,
  LOGOUT: `${intranetService}auth/logout`,
  // REFRESH_TOKEN: `${service}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  STORE_DETAILS: `${intranetService}stores/by-store-number`,
  VENDOR: `${commonPrefix}vendor`,
  VOUCHER: `${commonPrefix}voucher`,
  UPLOAD: `${commonPrefix}invoices/upload/attachment`,
  UPDATE_VOUCHER_STATUS: `${commonPrefix}voucher/update-voucher-status/`,
  UPDATE_BALANCE: `${commonPrefix}voucher/update-balance-amount/`,
  PROCESS_DRAFT: `${commonPrefix}voucher/process-draft-voucher/`,
  REJECT_VOUCHER: `${commonPrefix}voucher/reject-voucher/`,
  LIST_VOUCHER_IDS: `${commonPrefix}voucher/voucher-ids-list`,
  GET_ALL_STORES: `${intranetService}stores`,
  UPDATE_VOUCHER_IN_BULK: `${commonPrefix}voucher/update-voucher-in-bulk`,
  UPLOAD_VOUCHER_EXCEL: `${commonPrefix}voucher/upload-vouchers-excel`,
  DOWNLOAD_VOUCHERS: `${commonPrefix}voucher/download-vouchers`,
  GET_BALANCE_HISTORY: `${commonPrefix}voucher/store-balance-timeline`,
  IMPREST: `${commonPrefix}imprest`,
  CASH_CERTIFICATE: `${commonPrefix}petty-exp-crt`,
  CHECK_CERTIFICATE: `${commonPrefix}petty-exp-crt/check-month-end-certificate`,
};

export const PETTY_EXPENSE_ROUTE = "/petty-expense";
export const redirectToRoute = `${PETTY_EXPENSE_ROUTE}`;
export const SLUG = "petty-expense";
