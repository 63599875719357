/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { Button, IconButton } from "@mui/material";
import { invokeUploadFile } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";

const SiFileUpload = ({
  title,
  clsName,
  url,
  callBack,
  id,
  token = tokenMainKeys.invoiceTracking,
  slug = appSlugs.invoiceTracking,
  inputclassName,
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  const fileUpload = () => {
    const formData = new FormData();
    formData.append("upload", file);
    setUploadStatus("Uploading...");
    const apiUrl = url || `${HOSTNAME}${REST_URLS.INVOICE_ATTACHMENT}`;
    return invokeUploadFile(apiUrl, formData, slug, token)
      .then((response) => {
        if (response.status === 200) {
          toast.info("Uploaded Successfully");
          setFile(null);
          callBack && callBack(response.data);
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch((err) => {
        setUploadStatus("Upload Failed");
        toast.error("Upload Failed");
      });
  };

  return (
    <div className="relativePosition">
      <form className={`relative spidle-upload-file ${clsName}`}>
        {file === null ? (
          <Button size="small" fullWidth variant="outlined">
            <label htmlFor={`${id}-file-upload`} className="upload-button">
              {title} <PublishIcon fontSize="small" />
            </label>
          </Button>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        <input
          id={`${id}-file-upload`}
          className={`absoluteinput ${inputclassName ? inputclassName : ""}`}
          type="file"
          onChange={(event) => {
            onChange(event);
            event.target.value = null;
          }}
        />
        {file !== null && (
          <span>
            <IconButton
              color="primary"
              onClick={onFormSubmit}
              title="Upload"
              disabled={uploadStatus}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                setFile(null);
                setUploadStatus("");
              }}
              title="Clear"
            >
              <HighlightOffIcon />
            </IconButton>
          </span>
        )}
        {uploadStatus}
      </form>
    </div>
  );
};

export default SiFileUpload;
