import { pick } from '../../../../common-utilities/utils';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';

export const formatDownloadReports = (data) => {
  return (
    data?.map?.((item) => {
      return {
        Id: item?.id,
        Name: item?.name,
        Parent: item?.parent?.name || null,
        GSTIN: item?.gstin,
        Address: item?.companyAddress,
      };
    }) || []
  );
};

export const companyList = (setDataFunc, filter) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_COMPANY_LIST}`,
    null,
    filter
  )
    .then((res) => {
      const result = res.map((ele) => ({
        label: ele?.name,
        value: ele?.id,
      }));
      setDataFunc(result);
    })
    .catch((err) => console.log(err));
};
